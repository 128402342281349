<!--
 * @Author: Betty
 * @Date: 2021-07-05 13:42:31
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-14 19:34:40
 * @Description: 会议详情
-->
<template>
  <div class="meeting-detail-box">
    <el-tabs v-model="meetingActive" class="detail-tab">
      <!-- 第一块，会议信息 -->
      <el-tab-pane label="会议信息" name="meetingInfo">
        <!-- 编辑会议信息的表单组件 -->
        <edit-meeting-form
          ref="editMeeting"
          :meetingId="meetingId"
          @edit-success="editMeetingSuccess"
          @close-modal="closeEditMeeting"
          :isMeetingDetail="true"
          :isEditable="isEditable"
          :isEditContent="isEditContent"
          @edit-meeting-name="editMeetingName($event)"
          @edit-start-time="editStartTime($event)"
          @edit-end-time="editEndTime($event)"
          @edit-address="editMeetingAddress($event)"
          @edit-host="editHost($event)"
          @edit-recorder="editRecorder($event)"
          @edit-member="editMeetingMember($event)"
          @upload-appendix="uploadMeetingAttachment($event)"
          @begin-edit-content="beginEditMeetingContent"
          @close-edit-content="closeEditContent"
          @to-save-content="saveMeetingContent($event)"
          @set-detail="getDetail($event)"
          @get-clerk-data="getClerkTree($event)"
        ></edit-meeting-form>
      </el-tab-pane>
      <!-- 第二块，会议发言 -->
      <el-tab-pane
        label="会议发言"
        name="meetingSpeak"
        v-if="detail && detail.meeting_status > 1"
      >
        <div class="speck-box flex">
          <!-- 左半边，职员列表 -->
          <div class="clerk-list-box flex-shrink">
            <div
              class="clerk-item flex flex-y-center"
              :class="{ active: clerk.clerk_id === currentSpeaker }"
              v-for="(clerk, index) in speakClerkList"
              :key="index"
              @click="chooseSpeaker(clerk.clerk_id)"
            >
              <avatar
                :userInfo="clerk"
                :distanceRight="10"
                :isShowMe="true"
                myText="我"
              ></avatar>
              <p class="clerk-name e">
                {{ clerk.clerk_alias || clerk.clerk_name }}
              </p>
              <!-- 编辑按钮 -->
              <button
                class="edit-btn"
                v-if="
                  clerk.clerk_id === myClerkId && detail.meeting_status === 2
                "
                @click="beginEditSpeak"
              >
                <span class="iconfont icon-Edit"></span>
              </button>
            </div>
          </div>
          <!-- 右半边，发言的富文本和附件 -->
          <div class="flex1 speck-main">
            <!-- 显示富文本的结果 -->
            <div
              class="htmledit_views"
              v-html="currentSpeak"
              @click="imageChgange($event)"
              v-show="!isWatchMe || !isEditSpeak"
            ></div>
            <!-- 没有发言的提示 -->
            <no-data
              :isShow="!currentSpeak && !isEditSpeak"
              tipText="该职员还没有发言"
            ></no-data>
            <!-- 富文本编辑框 -->
            <meeting-speech-editor
              :value="mySpeak || ''"
              ref="meetingSpeak"
              v-if="isWatchMe"
              v-show="isEditSpeak"
            ></meeting-speech-editor>
            <!-- 附件列表 -->
            <div class="flex flex-wrap">
              <attachment-item
                v-for="(attachment, index) in speakAttachmentList"
                :key="index"
                :appendix="attachment"
                :meetingId="meetingId"
                fileType="meetingSpeak"
                :isDeletable="isWatchMe"
                @fresh-attachment="getClerkSpeakAttachment"
                style="margin: 12px 0 0 0"
              ></attachment-item>
            </div>
            <!-- 上传附件的按钮 -->
            <span
              class="to-upload"
              @click="beginUploadFile"
              v-if="isWatchMe && detail.meeting_status === 2"
              >上传附件</span
            >
            <input
              type="file"
              ref="uploadSpeakAttachmentRef"
              @change="uploadSpeakAttachment"
              v-show="false"
            />
            <!-- 保存按钮和取消按钮 -->
            <div
              class="button-line edit-box flex flex-y-center"
              v-if="isWatchMe && isEditSpeak"
            >
              <button
                class="meeting-btn active"
                type="button"
                @click="saveMySpeak"
              >
                保存
              </button>
              <button
                class="meeting-btn cancel"
                type="button"
                @click="cancelMySpeak"
              >
                取消
              </button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 第三块，会议任务 -->
      <el-tab-pane
        :label="renderTaskTip"
        name="meetingTask"
        v-if="detail && detail.meeting_status > 2"
      >
        <!-- 显示会议任务数据 -->
        <el-table
          :data="meetingTaskList"
          class="customer-table task-table"
          style="
            width: 100%;
            max-height: calc(100vh - 60px - 24px - 70px - 65px);
          "
          row-key="task_id"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column width="4%">
            <template slot-scope="scope">
              <div
                class="check-box"
                :style="{
                  cursor: isCheckMe(scope.row) ? 'pointer' : 'default'
                }"
                @click.stop="modifyTaskStatus(scope.row.task_id, scope.row)"
              >
                <svg class="icon" aria-hidden="true">
                  <use
                    :xlink:href="
                      scope.row.task_status !== 3 && scope.row.task_status !== 4
                        ? '#icon-TickSquare'
                        : '#icon-TickSquare_1'
                    "
                  ></use>
                </svg>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="任务名称" width="45%">
            <template slot-scope="scope">
              <p
                style="width: 100%"
                class="e task-input"
                v-show="currentEditTask !== scope.row.task_id"
                :class="{
                  over:
                    scope.row.task_status === 3 || scope.row.task_status === 4,
                  task_orange: scope.row.task_status === 4
                }"
                @click="showTaskDetail(scope.row)"
                @dblclick="
                  toEditMeetingTask(scope.row.task_id, scope.row.task_name)
                "
              >
                {{ scope.row.task_name }}
                <template v-if="scope.row.child_total_count > 0">
                  <span
                    class="child-info"
                    style="display: inline-block"
                    v-if="timeoutCount(scope.row) > 0"
                  >
                    (<span style="color: red">{{
                      timeoutCount(scope.row)
                    }}</span
                    >/{{ scope.row.child_complete_count }}/{{
                      scope.row.child_total_count
                    }})
                  </span>
                  <sapn class="child-info" style="display: inline-block" v-else>
                    ({{ scope.row.child_complete_count }}/{{
                      scope.row.child_total_count
                    }})
                  </sapn>
                </template>
              </p>
              <input
                type="text"
                v-model.trim="editTaskName"
                :ref="`meetingTask${scope.row.task_id}`"
                v-show="currentEditTask === scope.row.task_id"
                @keyup.enter="toBlur(scope.row.task_name)"
                @blur="confirmEditTaskName(scope.row.task_name)"
                @keyup.esc="cancelEditTaskName"
                class="task-input task-edit-input"
              />
            </template>
          </el-table-column>
          <el-table-column label="截至时间" width="13%">
            <template slot-scope="scope">
              <el-date-picker
                style="width: 100%"
                class="time-box"
                :clearable="false"
                :class="{
                  'is-late': scope.row.progress_status === 2
                }"
                v-model="scope.row.end_time"
                v-if="isCheckMe(scope.row)"
                align="right"
                type="date"
                placeholder=""
                size="small"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                @change="editLastTime(scope.row)"
              >
              </el-date-picker>
              <span v-else :class="{ late: scope.row.progress_status === 2 }">
                {{ renderTime(scope.row.end_time) }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="complete_time" label="完成时间" width="13%">
            <template slot-scope="scope">
              {{ renderTime(scope.row.complete_time) }}
            </template>
          </el-table-column>
          <el-table-column label="负责人" width="10%">
            <template slot-scope="scope">
              <div class="flex flex-y-center flex-x-center">
                <avatar
                  style="margin-right: 2px"
                  :userInfo="{
                    clerk_avatar: scope.row.director_clerk_avatar,
                    clerk_id: scope.row.director_clerk_id
                  }"
                ></avatar>
                <span class="e">{{
                  scope.row.director_clerk_alias ||
                  scope.row.director_clerk_name
                }}</span>
                <!-- 添加成员按钮 -->
                <!-- <div
                  class="add-member-btn"
                  @click.stop="toAddMember(scope.row)"
                >
                  <span class="iconfont icon-AddUser"></span>
                </div> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column label="完成进度" width="10%">
            <template slot-scope="scope">
              <div
                class="flex flex-y-center flex-x-center hidden-xs-only"
                @click.stop="beginSetProgress(scope.row, $event)"
                :style="{
                  cursor: isCheckMe(scope.row) ? 'pointer' : 'default'
                }"
              >
                <el-progress
                  :color="
                    scope.row.progress_status === 1 ? '#0080FF' : '#FF5454'
                  "
                  type="circle"
                  :percentage="scope.row.progress_number"
                  :width="20"
                  :show-text="false"
                  :stroke-width="2"
                ></el-progress>
                <span class="progress-text">{{
                  scope.row.progress_number + '%'
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="5%">
            <template slot-scope="scope">
              <el-dropdown v-if="isCheckMe(scope.row)">
                <span class="iconfont icon-gengduo2 more-icon"></span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div
                      @click="
                        toEditMeetingTask(
                          scope.row.task_id,
                          scope.row.task_name
                        )
                      "
                    >
                      编辑任务
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="delete-btn"
                    v-if="myClerkId === scope.row.create_clerk_id"
                  >
                    <div @click="toRemoveMeetingTask(scope.row.task_id)">
                      删除
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!-- 添加任务这一行 -->
        <div class="add-task-line flex flex-y-center">
          <div class="add-task flex flex-y-center flex-x-between">
            <button class="add-btn" v-show="!isAddTask" @click="toAddTask">
              添加任务
            </button>
            <input
              type="text"
              ref="addMeetingTask"
              class="add-task-input"
              v-model.trim="taskName"
              placeholder="添加任务"
              v-show="isAddTask"
              @keyup.enter="toBlur"
              @keyup.esc="cancelAddTask"
              @blur="addMeetingTask"
            />
          </div>
        </div>
        <!-- 添加成员的弹窗 -->
        <add-member
          :dataList="clerkTree"
          :isShowAddMember="isChooseMember"
          :memberList="memberList"
          @to-add-member="addMember($event)"
          @confirm-add-member="confirmAddMember($event)"
          @ready-remove-member="toRemoveMember($event)"
          @close-add-member="closeAddMember"
        ></add-member>
      </el-tab-pane>
      <!-- 第四块，会议纪要 -->
      <el-tab-pane
        label="会议纪要"
        name="meetingMinute"
        v-if="detail && detail.meeting_status > 2"
      >
        <div class="meeting-minute-box">
          <!-- 第一块，纪要内容 -->
          <div class="meeting-minute-items">
            <!-- 头部 -->
            <div class="meeting-minute-header flex flex-y-center">
              <span class="iconfont icon-a-EditSquare"></span>
              <h6 class="meeting-minute-title">纪要内容：</h6>
              <span
                class="edit-btn"
                @click="beginEditMeetingMinute"
                v-show="!isEditMinute && isAllowEditMinute"
                >编辑</span
              >
            </div>
            <!-- 展示编辑出来的结果 -->
            <div
              class="htmledit_views"
              v-html="meetingMinuteContent"
              v-show="!isEditMinute"
              @click="imageChgange($event)"
            ></div>
            <!-- 编辑会议纪要的组件 -->
            <meeting-minute-editor
              :value="meetingMinuteContent"
              v-show="isEditMinute && isAllowEditMinute"
              ref="meetingMinuteEditor"
              @input="handlerMinuteInput"
            ></meeting-minute-editor>
            <!-- 保存按钮和取消按钮 -->
            <div
              class="button-line edit-box flex flex-y-center"
              v-show="isEditMinute && isAllowEditMinute"
            >
              <button
                class="meeting-btn active"
                type="button"
                @click="editMeetingMinute"
              >
                保存
              </button>
              <button
                class="meeting-btn cancel"
                type="button"
                @click="cancelEditMinute"
              >
                取消
              </button>
            </div>
          </div>
          <!-- 第二块，附件信息 -->
          <div class="meeting-minute-items">
            <!-- 头部 -->
            <div class="meeting-minute-header flex flex-y-center">
              <span class="iconfont icon-a-Frame5"></span>
              <h6 class="meeting-minute-title">附件信息：</h6>
            </div>
            <div class="flex flex-wrap">
              <!-- 显示组件列表 -->
              <attachment-item
                v-for="(attachment, index) in minuteAttachmentList"
                :key="index"
                :appendix="attachment"
                fileType="meeting"
                :isMeetingDetail="true"
                :isDeletable="!isEditMinute && isAllowEditMinute"
                :meetingId="meetingId"
                @fresh-attachment="getMeetingMinuteAttachment"
              ></attachment-item>
            </div>
            <!-- 上传附件按钮 -->
            <button
              class="upload"
              v-show="!isEditMinute && isAllowEditMinute"
              @click="beginUploadMinuteAttachment"
            >
              上传附件
            </button>
            <!-- 上传文件的输入框 -->
            <input
              type="file"
              class="upload-file"
              ref="uploadMeetingAttachmentRef"
              @change="uploadMinuteAttachtment"
            />
          </div>
          <!-- 第三块，记录人员 -->
          <div class="meeting-minute-items">
            <!-- 头部 -->
            <div class="meeting-minute-header flex flex-y-center">
              <span class="iconfont icon-Profile"></span>
              <h6 class="meeting-minute-title">记录人员：</h6>
            </div>
            <p class="recorder-name">
              {{
                detail &&
                (detail.upload_minutes_clerk_alias ||
                  detail.upload_minutes_clerk_name)
              }}/{{ detail && detail.upload_minutes_dept_name }}
            </p>
          </div>
          <!-- 第四块，修改记录 -->
          <div class="minute-records" v-if="false">
            <!-- 里面的每一条记录 -->
            <div class="miniute-record-item flex flex-y-center">
              <!-- 编号 -->
              <div class="record-num">1</div>
              <!-- 日期 -->
              <p class="record-date">2021.05.13</p>
              <!-- 名字 -->
              <p class="record-name">张三</p>
              <!-- 具体的事情 -->
              <p class="record-detail">上传了文件：JAVA高级编程.pdf</p>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 上传文件的进度条 -->
      <div
        class="progress-area flex flex-x-end flex-y-reverse"
        v-if="uploadProgressList.length > 0"
      >
        <!-- 这里摆放进度条组件 -->
        <upload-progress
          v-for="(item, index) in uploadProgressList"
          :key="item.id"
          :uploadProgress="item.uploadProgress"
          :uploadFileName="item.uploadFileName"
          :index="index"
          :cancel="item.cancel"
          @close-upload-progress="closeUploadProgress(index)"
        ></upload-progress>
      </div>
    </el-tabs>
    <!-- 右上角的更多菜单 -->
    <div
      class="tab-more-btn"
      v-if="detail && detail.meeting_status < 3 && detail.is_cancel == 0"
    >
      <el-dropdown>
        <!-- 更多按钮 -->
        <button>
          <span class="iconfont icon-gengduo2 pc"></span>
        </button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="toCancelMeeting">取消会议</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="toEditMeeting">编辑会议</div>
          </el-dropdown-item>
          <el-dropdown-item style="color: red">
            <div @click="toDeleteMeeting">删除会议</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="tab-more-btn" v-if="detail && detail.is_cancel == 1">
      <el-dropdown>
        <!-- 更多按钮 -->
        <button>
          <span class="iconfont icon-gengduo2 pc"></span>
        </button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item style="color: red">
            <div @click="toDeleteMeeting">删除会议</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 任务详情弹窗 -->
    <task-detail
      :isShow="isShowTaskDetail"
      :taskId="detailTaskId"
      :meetingId="meetingId"
      :isMe="isMe"
      @change-task-id="changeTaskDetail"
      @close-detail="closeTaskDetail($event)"
      :nowTime="nowTime"
    ></task-detail>
    <el-image
      ref="previewImageRef"
      :preview-src-list="[previewImageSrc]"
      v-show="false"
    ></el-image>
    <!-- 任务的进度弹窗 -->
    <div
      class="task-process"
      v-show="isShowTaskProgress && currentTask !== -1"
      :style="{
        left: taskProgressLeft + 'px',
        top: taskProgressTop + 'px'
      }"
      @click.stop
    >
      <h6 class="process-title">当前进度</h6>
      <div class="flex flex flex-y-center">
        <input
          type="number"
          class="input"
          min="0"
          max="100"
          v-model="currentProgress"
          ref="editProgressRef"
          @blur="readyToEditProgress(currentTask)"
          @keyup.enter="toBlur($event, currentTask)"
        />
        <span class="percent">%</span>
      </div>
      <h6 class="process-title">状态</h6>
      <div class="radio-item">
        <label
          for=""
          class="label normal flex flex-y-center"
          @click="setTaskProgressStatus(1, currentTask)"
        >
          <svg class="icon radio-icon" aria-hidden="true">
            <use
              :xlink:href="
                currentProgressStatus === 1
                  ? '#icon-Group706'
                  : '#icon-Group592'
              "
            ></use>
          </svg>
          <input
            type="radio"
            name="task"
            :checked="currentProgressStatus === 1"
            id=""
            class="radio"
          />
          <span>正常</span>
        </label>
      </div>
      <div class="radio-item">
        <label
          for=""
          class="label late flex flex-y-center"
          @click="setTaskProgressStatus(2, currentTask)"
        >
          <svg class="icon radio-icon" aria-hidden="true">
            <use
              :xlink:href="
                currentProgressStatus === 2
                  ? '#icon-Group705'
                  : '#icon-Group593'
              "
            ></use>
          </svg>
          <input
            type="radio"
            name="task"
            :checked="currentProgressStatus === 2"
            id=""
            class="radio"
          />
          <span>已延期</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../common/Avatar.vue'
// 编辑会议的组件
import EditMeetingForm from '../meeting/EditMeetingForm.vue'
// 进度条
import UploadProgress from '../common/UploadProgress.vue'
// 无数据的提示
import NoData from '../common/NoData.vue'
// // 富文本输入框
// import MeetingDescribeEditor from '../meeting/MeetingDescribeEditor1.vue'
// 富文本输入框
import MeetingSpeechEditor from '../meeting/MeetingSpeechEditor1.vue'
// 附件
import AttachmentItem from '../common/AttachmentItem.vue'
import { mapState } from 'vuex'
// 添加成员弹窗
import AddMember from '../common/AddMember.vue'
// 任务详情
import TaskDetail from '../task/TaskDetail.vue'
// 会议纪要编辑
import MeetingMinuteEditor from '../meeting/MeetingMinuteEditor1.vue'
export default {
  name: 'meeting-detail',
  components: {
    EditMeetingForm,
    UploadProgress,
    Avatar,
    NoData,
    // MeetingDescribeEditor,
    AttachmentItem,
    AddMember,
    TaskDetail,
    MeetingMinuteEditor,
    MeetingSpeechEditor
  },
  props: {
    // 当前的会议id
    meetingId: {
      type: Number,
      default: -1
    },
    // 当前选中哪个tab
    defaultActive: {
      type: String,
      default: 'meetingInfo'
    }
  },
  provide() {
    return {
      Parent: this
    }
  },
  data() {
    return {
      // 当前选中的tab
      meetingActive: '',
      // 会议详情
      meetingDetail: null,
      // 进度条状态组件
      uploadProgressList: [],
      // 当前上传了几个文件
      uploadCount: 0,
      // 是否正在编辑内容
      isEditContent: false,
      // 会议发言的职员列表
      speakClerkList: [],
      // 当前看到的发言
      currentSpeak: '',
      // 我的发言
      mySpeak: '',
      // 是否在编辑发言
      isEditSpeak: false,
      // 正在发言的人的id
      currentSpeaker: -1,
      // 会议详情数据
      detail: null,
      // 发言附件列表
      speakAttachmentList: [],
      // 会议任务列表
      meetingTaskList: [],
      // 被编辑的任务名字
      editTaskName: '',
      // 正在输入的任务的名字
      taskName: '',
      // 当前正在被编辑的任务的id
      currentEditTask: -1,
      // 当前正在添加人员的任务的id
      currentAddMemberTask: -1,
      // 是否正在添加会议任务
      isAddTask: false,
      // 编辑的任务原本的的名字
      tempName: '',
      // 职员树形
      clerkTree: [],
      // 是否选择成员
      isChooseMember: false,
      // 成员列表
      memberList: [],
      // 成员id集合
      memberIdList: [],
      // 当前任务创建人id
      currentCreateId: -1,
      // 是否展示任务详情
      isShowTaskDetail: false,
      // 当前看的是哪个任务的详情
      detailTaskId: -1,
      // 当前任务的创建人id
      taskCreater: -1,
      // 判断是否是单击
      flag: false,
      // 当前的时间
      nowTime: null,
      // 会议纪要的附件列表
      minuteAttachmentList: [],
      // 会议纪要的内容
      meetingMinuteContent: '',
      // 是否正在编辑会议纪要
      isEditMinute: false,
      previewImageSrc: '',
      // 进度值
      currentProgress: '',
      // 是否展示任务进度
      isShowTaskProgress: false,
      currentTask: -1,
      // 任务的进度弹窗的位置
      taskProgressTop: 0,
      taskProgressLeft: 0,
      currentProgressStatus: -1,
      taskInfo: {},
      // 快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '清空',
            onClick(picker) {
              picker.$emit('pick', '')
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState(['myClerkId', 'timeOffset', 'clickBodyIndex']),
    renderTaskTip() {
      return this.meetingTaskList.length === 0
        ? '会议任务'
        : '会议任务(' + this.meetingTaskList.length + ')'
    },
    // 当前选中的是不是我自己
    isWatchMe() {
      return this.currentSpeaker === this.myClerkId
    },
    isMe() {
      return this.myClerkId === this.taskCreater
    },
    // 现在能不能编辑会议
    isEditable() {
      if (this.detail == null) {
        return false
      }
      // 已取消不能修改
      if (this.detail.is_cancel === 1) {
        return false
      }
      // 进行中已结算不能修改
      if (
        this.detail.meeting_status === 3 ||
        this.detail.meeting_status === 4
      ) {
        return false
      }
      // 不是创建人或主持人不能修改
      if (
        this.detail.is_create_clerk === 0 &&
        this.detail.is_compere_clerk === 0
      ) {
        return false
      }
      return true
      // return this.detail && this.detail.meeting_status < 3
    },
    // 是否允许修改会议纪要
    isAllowEditMinute() {
      switch (this.detail.meeting_status || 0) {
        // 进行中
        case 3:
          if (this.detail.is_upload_minutes_clerk === 1) {
            return true
          }
          return false
        // 已结束
        case 4:
          if (this.detail.is_create_clerk === 1) {
            return true
          }
          if (this.detail.is_compere_clerk === 1) {
            return true
          }
          if (this.detail.is_upload_minutes_clerk === 1) {
            return true
          }
          return false
      }

      return false
    }
  },
  mounted() {
    // 监听屏幕滚动事件
    window.addEventListener('scroll', this.clickOut)
    this.getMeetingTask()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.clickOut)
  },
  watch: {
    clickBodyIndex() {
      this.clickOut()
    },
    // 切换到发言，获取会议与会人员列表
    meetingActive(val) {
      if (val === 'meetingSpeak' && this.speakClerkList.length === 0) {
        // 获取职员列表
        this.getSpeckClerkList()
      } else if (val === 'meetingTask' && this.meetingTaskList.length === 0) {
        // 获取任务列表
        this.getMeetingTask()
      } else if (val === 'meetingMinute') {
        // 获取会议纪要的附件
        this.getMeetingMinuteAttachment()
      }
    },
    // 父组件决定它当前的tab
    defaultActive: {
      // 想让监听prop生效
      immediate: true,
      handler(val) {
        this.meetingActive = val
      }
    },
    // 切换当前发言的人
    currentSpeaker(val, old) {
      if (old !== -1) {
        this.getClerkSpeakContent()
        this.getClerkSpeakAttachment()
      }
    }
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(
            reg,
            `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
          )
        : url
    },
    changeTaskDetail(id) {
      this.detailTaskId = id
    },
    // 超时数量
    timeoutCount(task) {
      if (!task.children) {
        return 0
      }
      let len = 0
      console.log(task.children)
      const count = (list) => {
        list.forEach((e) => {
          if (e.progress_status === 2) {
            len++
          }
          if (e.children) {
            count(e.children)
          }
        })
      }
      count(task.children)
      return len
    },
    // 编辑截止日期
    async editLastTime(task) {
      if (this.renderTime(task.end_date) === task.end_time) {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editEndTime, {
          task_id: task.task_id,
          end_time: task.end_time
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '截止时间修改成功！',
          type: 'success'
        })
        this.$set(task, 'end_date', task.end_time)
      } else {
        this.$set(task, 'end_time', task.end_time_tmp)
      }
    },
    // 点击单选框，修改任务的进度状态
    async setTaskProgressStatus(val, id) {
      if (this.currentProgressStatus === val) {
        return
      }
      // 发请求，修改任务的进度状态
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskProgressStatus, {
          task_id: id,
          progress_status: val
        })
      )
      if (err) {
        console.log(res)
      } else {
        console.log(res)
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务进度状态成功！',
            type: 'success'
          })
          this.clickOut()
          this.getMeetingTask()
        }
      }
    },
    clickOut() {
      this.currentProgressStatus = -1
      this.currentTask = -1
      this.isShowTaskProgress = false
      this.taskInfo = {}
    },
    // 输入进度，准备修改
    async readyToEditProgress(id) {
      if (
        !this.isCheckMe(this.taskInfo) ||
        this.taskInfo.progress_number === this.currentProgress
      ) {
        return
      }
      const progresValue = this.currentProgress
      // 假如把里面的数字删干净了，视为没有修改
      if (progresValue.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.$set(this.taskInfo, 'progress_number', this.temp)
        // this.currentProgress = this.temp
      } else if (Number(progresValue) < 0 || Number(progresValue) > 100) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.$set(this.taskInfo, 'progress_number', this.temp)
        this.$refs.editProgressRef.blur()
      } else {
        // 假如输入正确，那就发起修改请求
        this.$refs.editProgressRef.blur()
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: this.currentProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '任务的进度修改成功！',
              showClose: true
            })
            // 清除数据刷新数据
            this.currentProgress = ''
            this.temp = ''
            this.getMeetingTask()
          }
        }
      }
    },
    // 开始设置任务进度
    beginSetProgress(task, e) {
      if (!this.isCheckMe(task)) return
      this.isShowTaskProgress = true
      // 临时记录进度
      this.temp = task.progress_number
      this.currentTask = task.task_id
      this.taskInfo = task
      this.currentProgressStatus = task.progress_status
      // 根据子组件传过来的进度数据，回填到input上
      this.currentProgress = task.progress_number
      // 定位弹窗坐标
      const { clientX, clientY } = e
      this.taskProgressTop = clientY + 25
      this.taskProgressLeft = clientX - 120
    },
    isCheckMe(task) {
      return (
        this.myClerkId === task.create_clerk_id ||
        this.myClerkI === task.director_clerk_id
      )
    },
    // 点击修改任务状态
    async modifyTaskStatus(id, task) {
      if (!this.isCheckMe(task)) {
        return
      }
      let target = 0
      // 如果已完成，就让他未完成。否则让它已完成
      if (task.task_status === 3 || task.task_status === 4) {
        target = 2
      } else {
        target = 3
      }
      // 发送请求修改
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTaskStatus, {
          task_id: id,
          task_status: target
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(task, 'task_status', target)
        // 如果把状态切换到已完成，那么还要发请求修改进度值为100
        if (target === 3) {
          const res = await this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: 100
          })
          if (res.state === 'ok') {
            this.$set(task, 'progress_number', 100)
          }
        }
        this.getMeetingTask()
      }
    },
    // 是否可以修改进度值
    progressIsMe(e) {},
    imageChgange(e) {
      if (e.target.nodeName === 'IMG') {
        this.previewImageSrc = e.target.src
        this.$refs.previewImageRef.showViewer = true
        console.log(e.target.src)
      }
    },
    // 提交成功后
    editMeetingSuccess() {
      this.$emit('edit-success')
    },
    // 关闭弹窗后
    closeEditMeeting() {
      this.$emit('close-modal')
    },
    // 获取职员列表
    getClerkTree(val) {
      this.clerkTree = val
    },
    // 单独修改字段的提交
    // 修改之前判断现在是否可以修改
    // 修改会议名称
    async editMeetingName(val) {
      if (!this.isEditable) return
      if (this.detail.meeting_name === val) return
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingName, {
          meeting_id: this.meetingId,
          meeting_name: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改会议名称成功!',
          type: 'success'
        })
      }
    },
    // 修改会议的开始时间
    async editStartTime(val) {
      if (!this.isEditable) return
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingStartTime, {
          meeting_id: this.meetingId,
          start_time: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改开始时间成功!',
          type: 'success'
        })
        this.$refs.editMeeting.editStartTimeCallback(true)
      } else {
        this.$refs.editMeeting.editStartTimeCallback(false)
      }
    },
    // 修改会议的结束时间
    async editEndTime(val) {
      if (!this.isEditable) return
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingEndTime, {
          meeting_id: this.meetingId,
          end_time: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改结束时间成功!',
          type: 'success'
        })
        this.$refs.editMeeting.editEndTimeCallback(true)
      } else {
        this.$refs.editMeeting.editEndTimeCallback(false)
      }
    },
    // 修改会议地点
    async editMeetingAddress({ placeType, address }) {
      if (!this.isEditable) return
      const formObj = {
        meeting_id: this.meetingId,
        meeting_place_type: placeType
      }
      // 如果是内部
      if (placeType === 1) {
        formObj.meeting_room_id = address
      } else {
        if (this.detail.meeting_address === address) return
        formObj.meeting_address = address
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingAddress, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改会议地点成功!',
          type: 'success'
        })
      }
    },
    // 修改会议主持人
    async editHost(val) {
      if (!this.isEditable) return
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingHost, {
          meeting_id: this.meetingId,
          clerk_ids: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改会议主持人成功!',
          type: 'success'
        })
      }
    },
    // 修改会议纪要人
    async editRecorder(val) {
      if (!this.isEditable) return
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingUploadMinutesClerk, {
          meeting_id: this.meetingId,
          upload_minutes_clerk_id: val.clerk_id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改会议纪要人成功!',
          type: 'success'
        })
      }
    },
    // 修改会议的与会人员
    async editMeetingMember(val) {
      if (!this.isEditable) return
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingMember, {
          meeting_id: this.meetingId,
          clerk_ids: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改与会人员成功!',
          type: 'success'
        })
      }
    },
    // 上传一个附件（这是一个通用函数,传入要上传的文件，提交接口，回调函数，附件类型）
    async uploadAttachment(file, link, fn, attachmentType) {
      const formObj = new FormData()
      formObj.append('meeting_id', this.meetingId)
      if (attachmentType) {
        formObj.append('attachment_type', attachmentType)
      }
      formObj.append('file1', file)
      // 上传进度对象
      const uploadProgress = {}
      uploadProgress.uploadFileName = file.name
      uploadProgress.uploadProgress = 0
      uploadProgress.id = ++this.uploadCount
      this.uploadProgressList.push(uploadProgress)
      // 准备一个回调函数来获取上传进度
      const updateUploadProgress = (progressEvent) => {
        const complete =
          ((progressEvent.loaded / progressEvent.total) * 100) | 0
        uploadProgress.uploadProgress = complete
      }
      // 发送上传文件的请求
      const { result } = this.$util.uploadFile(
        link,
        formObj,
        updateUploadProgress
      )
      result.then((res) => {
        // 上传成功了，那就调用回调函数
        if (res.state === 'ok') {
          fn.call(this)
        }
      })
    },
    // 上传一个新的会议附件
    async uploadMeetingAttachment(file) {
      if (!this.isEditable) return
      this.uploadAttachment(
        file,
        this.$api.uploadMeetingAttachment,
        this.getMeetingAttachmentList,
        1
      )
    },
    // 获取会议附件列表
    async getMeetingAttachmentList() {
      this.$refs.editMeeting.getMeetingAttachmentList()
    },
    // 处理文件上传成功的事件
    closeUploadProgress(index) {
      this.uploadProgressList.splice(index, 1)
    },
    // 开始编辑会议内容
    beginEditMeetingContent() {
      if (!this.isEditable) return
      this.isEditContent = true
    },
    // 关闭会议内容的编辑状态
    closeEditContent() {
      this.isEditContent = false
    },
    // 保存编辑会议内容
    async saveMeetingContent({ html, text }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingContent, {
          meeting_id: this.meetingId,
          meeting_content_html: html,
          meeting_content_text: text
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '会议内容修改成功!',
          type: 'success'
        })
        window.localStorage.removeItem(
          `edit_meeting_describe_content_cache_${this.meetingId}`
        )
        // 关闭编辑状态
        this.closeEditContent()
        // 刷新数据
        this.$refs.editMeeting.meetingDetail.meeting_content_html = html
      }
    },
    // 获取职员列表
    async getSpeckClerkList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingSpeakClerk, {
          meeting_id: this.meetingId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 对数据进行处理，把自己移到第一个
        const myIndex = res.list.findIndex(
          (item) => item.clerk_id === this.myClerkId
        )
        if (myIndex !== -1) {
          const me = res.list[myIndex]
          res.list.splice(myIndex, 1)
          res.list.unshift(me)
        }
        this.speakClerkList = res.list
        // 默认查看第一个人的发言
        this.currentSpeaker = this.speakClerkList[0].clerk_id
        await this.getClerkSpeakContent()
        if (this.currentSpeak) {
          this.isEditSpeak = false
        }
        this.getClerkSpeakAttachment()
      }
    },
    // 获取某职员的发言
    async getClerkSpeakContent() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSomeMeetingSpeak, {
          meeting_id: this.meetingId,
          clerk_id: this.currentSpeaker
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (res.info) {
          this.currentSpeak = res.info.speech_content_html
        } else {
          this.currentSpeak = ''
        }
        if (this.currentSpeaker === this.myClerkId) {
          this.mySpeak = this.currentSpeak
        }
      }
    },
    // 获取某职员发言的附件
    async getClerkSpeakAttachment() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingSpeckAttachment, {
          meeting_id: this.meetingId,
          clerk_id: this.currentSpeaker
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 找到当前查看的职员的名字，把这个信息加到每个附件的数据里去
        const index = this.speakClerkList.findIndex(
          (item) => item.clerk_id === this.currentSpeaker
        )
        const clerkName =
          this.speakClerkList[index].clerk_alias ||
          this.speakClerkList[index].clerk_name
        res.list.forEach((item) => {
          item.clerk_name = clerkName
        })
        this.speakAttachmentList = res.list
      }
    },
    // 准备上传发言附件
    beginUploadFile() {
      this.$refs.uploadSpeakAttachmentRef.click()
    },
    // 确定上传发言附件
    async uploadSpeakAttachment(e) {
      const file = e.target.files[0]
      // 上传以后，获取这个人的发言附件
      await this.uploadAttachment(
        file,
        this.$api.uploadMeetingSpeckAttachment,
        this.getClerkSpeakAttachment
      )
      this.$refs.uploadSpeakAttachmentRef.value = ''
    },
    // 点击选择会议发言人
    chooseSpeaker(id) {
      this.currentSpeaker = id
    },
    // 开始编辑我的发言
    beginEditSpeak() {
      this.isEditSpeak = true
    },
    // 保存我的发言
    async saveMySpeak() {
      const { html, text } = this.$refs.meetingSpeak.getContent()
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMyMeetingSpeak, {
          meeting_id: this.meetingId,
          speech_content_html: html,
          speech_content_text: text
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          type: 'success',
          message: '发言提交成功!',
          showClose: true
        })
        this.cancelMySpeak()
        // 马上获取我的发言
        this.getClerkSpeakContent()
      }
    },
    // 取消修改发言
    cancelMySpeak() {
      this.isEditSpeak = false
    },
    // 获取详情信息
    getDetail(obj) {
      this.detail = obj
      if (this.detail.meeting_status <= 2) {
        this.isEditSpeak = true
      }
      // 初始化会议纪要的内容
      this.meetingMinuteContent = this.detail.meeting_minutes_html
      if (this.detail.is_upload_minutes === 0) {
        this.isEditMinute = true
        const cache = window.localStorage.getItem(
          `edit_meeting_minute_content_cache_${this.meetingId}`
        )
        if (cache) {
          this.meetingMinuteContent = cache
        }
      }
    },
    // 取消会议
    toCancelMeeting() {
      this.$parent.$parent.showCancelConfirm(this.detail, this)
    },
    // 删除会议
    toDeleteMeeting() {
      this.$parent.$parent.deleteMettingInfo(this.detail)
    },
    // 让子组件去获取最新的详情数据，好让我这里也拿到
    getMeetingDetail() {
      this.$refs.editMeeting.getMeetingDetail(this.meetingId)
    },
    // 编辑会议（通知父组件去做）
    toEditMeeting() {
      this.$parent.$parent.beginEditMeeting(this.meetingId)
    },
    // 获取会议任务
    async getMeetingTask() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingTaskList, {
          meeting_id: this.meetingId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 对任务的成员进行调整
        res.list.forEach((item) => {
          // 把创建人加到成员的第一个
          item.task_participant_list.unshift({
            clerk_avatar: item.create_clerk_avatar,
            clerk_id: item.create_clerk_id,
            clerk_name: item.create_clerk_alias || item.create_clerk_name
          })
        })
        this.meetingTaskList = this.$util.handleTreeData(res.list)
        console.log('转换前的原始数据', res.list)
        console.log('任务列表获取完毕', this.meetingTaskList)
      }
    },
    // 点击添加任务
    toAddTask() {
      this.isAddTask = true
      // 让输入框聚焦
      this.$nextTick(() => {
        this.$refs.addMeetingTask.focus()
      })
    },
    toBlur(e, arg) {
      e.target.blur(arg)
    },
    // 取消新增任务
    cancelAddTask() {
      this.isAddTask = false
      this.taskName = ''
    },
    // 确定新增会议任务
    async addMeetingTask() {
      if (this.taskName) {
        // 获取一下现在的时间
        this.nowTime = new Date(new Date().getTime() + this.timeOffset)
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addNewTask, {
            meeting_id: this.meetingId,
            'task.task_name': this.taskName,
            'task.period_date': this.$util.getDateStr(this.nowTime)
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '会议任务新增成功',
            type: 'success'
          })
          this.cancelAddTask()
          this.getMeetingTask()
        }
      } else {
        this.cancelAddTask()
      }
    },
    // 准备编辑会议任务
    toEditMeetingTask(id, name) {
      this.flag = false
      // 设置当前编辑哪个任务
      this.currentEditTask = id
      // 聚焦
      this.$nextTick(() => {
        this.$refs[`meetingTask${id}`].focus()
      })
      // 回填
      this.editTaskName = name
      this.tempName = name
    },
    // 取消编辑会议任务
    cancelEditTaskName() {
      this.currentEditTask = -1
      this.editTaskName = ''
      this.tempName = ''
    },
    // 确定编辑会议任务
    async confirmEditTaskName() {
      if (this.editTaskName !== this.tempName) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskName, {
            task_id: this.currentEditTask,
            task_name: this.editTaskName
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '会议名称编辑成功',
            type: 'success'
          })
          this.cancelEditTaskName()
          this.getMeetingTask()
        }
      } else {
        // 如果我并没有修改人物名，那么直接取消，不发请求了
        this.cancelEditTaskName()
      }
    },
    // 删除会议任务
    toRemoveMeetingTask(id) {
      this.$util.showConfirm('确认要删除这个任务吗？').then(async (result) => {
        if (result) {
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.removeTask, {
              task_id: id
            })
          )
          if (err) {
            console.log(err)
          } else if (res.state === 'ok') {
            this.$message({
              showClose: true,
              message: '会议任务删除成功',
              type: 'success'
            })
            this.getMeetingTask()
          }
        }
      })
    },
    // 准备添加会议任务的成员
    toAddMember(task) {
      this.currentAddMemberTask = task.task_id
      this.isChooseMember = true
      // 记录当前任务是谁创建的
      this.currentCreateId = task.create_clerk_id
      this.memberList = task.task_participant_list.slice(1)
      this.memberIdList = this.memberList.map((item) => item.clerk_id)
    },
    // 添加一个任务成员
    addMember(clerk) {
      if (this.memberIdList.includes(clerk.clerk_id)) {
        this.$message({
          showClose: true,
          message: '请不要添加重复的成员',
          type: 'error'
        })
        return
      }
      if (clerk.clerk_id === this.currentCreateId) {
        this.$message({
          showClose: true,
          message: '请不要添加任务创建者',
          type: 'error'
        })
        return
      }
      this.memberList.push(clerk)
      this.memberIdList.push(clerk.clerk_id)
    },
    // 确定添加成员
    async confirmAddMember() {
      // 用逗号连接
      const ids = this.memberIdList.join()
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskMember, {
          task_id: this.currentAddMemberTask,
          clerk_ids: ids
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加任务成员成功',
          type: 'success'
        })
        // 关闭
        this.closeAddMember()
        this.getMeetingTask()
      }
    },
    // 准备删除一个任务成员
    toRemoveMember(id) {
      const index = this.memberIdList.indexOf(id)
      if (index > -1) {
        this.memberIdList.splice(index, 1)
        this.memberList.splice(index, 1)
      }
    },
    // 关闭添加成员的弹窗
    closeAddMember() {
      this.isChooseMember = false
      this.memberList = []
      this.currentCreateId = -1
    },
    // 展示出任务详情
    showTaskDetail(task) {
      this.flag = true
      setTimeout(() => {
        if (this.flag) {
          this.isShowTaskDetail = true
          this.detailTaskId = task.task_id
          // 记录一下当前任务创建人的id
          this.taskCreater = task.create_clerk_id
          // 获取一下当前的时间
          this.nowTime = new Date(new Date().getTime() + this.timeOffset)
          this.$emit('showTaskDetail')
        }
      }, 300)
    },
    // 关闭任务详情
    closeTaskDetail(obj) {
      this.isShowTaskDetail = false
      // 如果我刚才修改了任务详情，那么要刷新任务列表
      if (obj.is_update_data) {
        this.getMeetingTask()
      }
      this.$emit('closeTaskDetail')
    },
    // 准备上传会议纪要附件
    beginUploadMinuteAttachment() {
      this.$refs.uploadMeetingAttachmentRef.click()
    },
    // 获取会议纪要附件
    async getMeetingMinuteAttachment() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingAttachmentList, {
          meeting_id: this.meetingId,
          attachment_type: 2
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.minuteAttachmentList = res.list
      }
    },
    // 上传会议纪要附件
    async uploadMinuteAttachtment() {
      const file = this.$refs.uploadMeetingAttachmentRef.files[0]
      await this.uploadAttachment(
        file,
        this.$api.uploadMeetingAttachment,
        this.getMeetingMinuteAttachment,
        2
      )
      this.$refs.uploadMeetingAttachmentRef.value = ''
    },
    // 开始修改会议纪要
    beginEditMeetingMinute() {
      this.isEditMinute = true
      this.$refs.meetingMinuteEditor.setContent(
        this.detail.meeting_minutes_html
      )
      const cache = window.localStorage.getItem(
        `edit_meeting_minute_content_cache_${this.meetingId}`
      )
      if (cache) {
        setTimeout(() => {
          this.$confirm('发现本地缓存是否导入?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.$refs.meetingMinuteEditor.setContent(cache)
            })
            .catch(() => {})
        }, 500)
      }
    },
    // 取消修改会议纪要
    cancelEditMinute() {
      this.isEditMinute = false
    },
    // 修改会议纪要
    async editMeetingMinute() {
      const { html, text } = this.$refs.meetingMinuteEditor.getContent()
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editMeetingMinute, {
          meeting_id: this.meetingId,
          meeting_minutes_html: html,
          meeting_minutes_text: text
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '会议纪要修改成功',
          type: 'success'
        })
        window.localStorage.removeItem(
          `edit_meeting_minute_content_cache_${this.meetingId}`
        )
        this.cancelEditMinute()
        this.getMeetingDetail()
      }
    },
    // 缓存
    handlerMinuteInput(val) {
      if (this.isEditMinute) {
        window.localStorage.setItem(
          `edit_meeting_minute_content_cache_${this.meetingId}`,
          val
        )
      }
    },
    renderTime(v) {
      if (v === null) {
        return ''
      }
      return this.$util.getDateStr(new Date(v))
    }
  }
}
</script>

<style lang="scss" scoped>
.time-box.is-late::v-deep .el-input__inner {
  color: $red !important;
}
.late {
  color: $red !important;
}
.time-box::v-deep .el-input__prefix {
  display: none;
}
.time-box::v-deep .el-input__inner {
  padding: 0 !important;
  text-align: center;
}
.task_orange {
  color: orange !important;
}
.task-input {
  &.task-edit-input {
    width: 100% !important;
  }
  &.over {
    text-decoration: line-through;
    color: #999;
  }
  text-align: left;
}
.task-table::v-deep {
  .el-table__row .cell {
    padding-left: 0 !important;
  }
  .is-leaf:nth-child(2) {
    text-align: left !important;
  }
}
.check-box {
  display: inline-block;
  .icon {
    width: 20px;
    height: 20px;
  }
}
.task-info-value {
  position: relative;
  text-align: center;
  line-height: 1.5;
  min-height: 22px;
  color: #333;
  &.sumbit {
    width: 88px;
  }
  &.priority {
    width: 10%;
  }
  &.progress {
    display: flex;
    width: 14%;
  }
  &.last-time {
    width: 140px;
    width: 10%;
  }
  &.leader-box {
    width: 120px;
    width: 10%;
  }
}

// 设置进度的弹窗
.task-process {
  position: fixed;
  // @include pos-center(absolute);
  // top: 100%;
  .process-title {
    text-align: left;
  }
  &::before {
    position: absolute;
    left: 70%;
    transform: translateX(-50%);
    // @include pos-center(absolute);
  }
}
.progress-text {
  width: 45px;
  text-align: right;
  display: block;
}

@media screen and (min-width: 767px) {
  .meeting-detail-box {
    position: relative;
  }
  .to-upload {
    display: block;
    margin-top: 25px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5;
    color: #999;
  }
  .delete-btn {
    color: $red;
    &:hover {
      color: $red !important;
      background: rgba($red, 0.1) !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .meeting-detail-box {
    position: relative;
  }
  .to-upload {
    display: block;
    margin-top: 25px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5;
    color: #999;
  }
  .delete-btn {
    color: $red;
    &:hover {
      color: $red !important;
      background: rgba($red, 0.1) !important;
    }
  }
}
</style>
