<template>
  <div class="push-people">
    <div class="push-header flex flex-x-between flex-y-center">
      <p class="push-tips">
        可设置圈子推送消息的推送账号，推送的圈子动态将同步至该账号的圈子列表内，推送账号可在主页对动态进行编辑、修改等。
      </p>
      <el-button
        type="primary"
        size="small"
        @click="addVisible = true"
        v-if="perInfo.add"
        >添加成员</el-button
      >
    </div>
    <div class="people-list">
      <div class="table-header flex flex-x-between">
        <div>推送账号</div>
        <div style="width: 80px">操作</div>
      </div>
      <div class="table-main">
        <div
          class="table-item flex flex-x-between flex-y-center"
          v-for="item in list"
          :key="item.clerk_id"
        >
          <div class="flex flex-y-center">
            <el-image :src="item.clerk_avatar">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <span
              >{{ item.clerk_alias || item.clerk_name }} /
              <span style="color: #bbb">{{ item.main_dept_name }}</span></span
            >
          </div>
          <div style="width: 100px">
            <div
              class="delete-button"
              @click="
                deleteVisible = true
                currentDeleteInfo = item
              "
              v-if="perInfo.delete"
            >
              <i class="icon-trash1"></i>
              移除
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; position: absolute; bottom: 22px">
      <el-pagination
        background
        layout="prev, pager, next"
        hide-on-single-page
        @current-change="
          pageNumber = $event
          getPage()
        "
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :page-count="totalPage"
      >
      </el-pagination>
    </div>
    <!-- 添加 -->
    <el-dialog
      class="chooseClerk"
      title="添加推送账号"
      :visible.sync="addVisible"
      width="500px"
      @close="addVisible = false"
    >
      <div class="choose-person-box">
        <search-person
          :isShow="true"
          @choose-person="addClerk($event)"
        ></search-person>
        <member-tree
          :treeNode="treeNode"
          v-for="(treeNode, cindex) in clerkTree"
          :key="cindex"
          @clerk-choose="addClerk($event)"
        ></member-tree>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog
      :title="`确定要将${
        currentDeleteInfo.clerk_alias || currentDeleteInfo.clerk_name
      }移除吗?`"
      :visible.sync="deleteVisible"
      width="300px"
    >
      <span>移除后，已通过该账号发布的圈子动态推送人将不可编辑，仅展示。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteClerk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MemberTree from '@/components/common/MemberTree.vue'
import SearchPerson from '@/components/common/SearchPerson.vue'
export default {
  components: {
    MemberTree,
    SearchPerson
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
      list: [],
      clerkTree: [],
      // 添加展示
      addVisible: false,
      // 删除展示
      deleteVisible: false,
      // 删除信息
      currentDeleteInfo: {}
    }
  },
  computed: {
    perInfo() {
      return {
        add: this.$hasPer(63),
        delete: this.$hasPer(64)
      }
    }
  },
  methods: {
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 获取列表
    async getPage() {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemMomentPushClerkPage, formObj)
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        this.pageNumber = page.pageNumber
        this.totalPage = page.totalPage
        this.totalRow = page.totalRow
        this.list = page.list
      }
    },
    // 刷新列表
    async refreshPage() {
      this.pageNumber = 1
      this.getPage()
    },
    // 删除职员
    async deleteClerk() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemMomentPushClerk, {
          clerk_id: this.currentDeleteInfo.clerk_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.deleteVisible = false
        this.currentDeleteInfo = {}
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        this.refreshPage()
      }
    },
    // 添加
    async addClerk(e) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addSystemMomentPushClerk, {
          clerk_id: e.clerk_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        this.addVisible = false
        this.refreshPage()
      }
    }
  },
  mounted() {
    this.getClerkTree()
    this.getPage()
  }
}
</script>

<style lang="scss" scoped>
.push-people {
  height: 100%;
  .push-header {
    margin-bottom: 38px;
    .push-tips {
      color: #bbb;
    }
  }

  .people-list {
    .table-header {
      margin-bottom: 15px;
    }
    .table-item {
      height: 66px;

      &:hover {
        background: #f6f6f6;
      }

      .el-image {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 16px;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
      }

      .delete-button {
        cursor: pointer;
        width: 68px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #ff5454;
        border-radius: 4px;

        &:hover {
          background: rgba(255, 84, 84, 0.1);
        }
      }
    }
  }
}

.chooseClerk ::v-deep {
  .el-dialog__body {
    padding: 10px 20px;
  }
}

.el-pagination {
  text-align: center;
}
</style>
