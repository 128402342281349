<!--
 * @Author: hzh
 * @Date: 2021-08-09 14:35:47
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-13 14:29:55
-->
<template>
  <div class="flex attention-item">
    <div class="flex" style="flex: 1; margin-right: 24px">
      <el-image :src="avatar" @click="$emit('clickAvatar')">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <div style="flex: 1; overflow: hidden;
    word-break: break-all;" class="flex flex-y-center">
        <slot></slot>
      </div>
    </div>
    <div
      v-if="clerkId !== myClerkId"
      class="attention-button"
      :class="{ active: primary }"
      @click="$emit('clickButton')"
    >
      {{ buttonTitle }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MemberAttentionItem',
  props: {
    buttonTitle: {
      type: String,
      default: '按钮'
    },
    primary: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: String
    },
    clerkId: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapState(['myClerkId'])
  }
}
</script>

<style lang="scss" socped>
.attention-item {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  &:hover {
    background-color: #f0f0f0;
  }

  .el-image {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    background-color: $light-grey-bg;
    border-radius: 100%;
    text-align: center;
    margin-right: 20px;
  }

  .attention-button {
    cursor: pointer;
    padding: 8px 18px;
    height: 20px;
    border-radius: 36px;
    border: 1px solid #bbb;
    color: #bbb;

    &.active {
      border: 1px solid $main-color;
      color: $main-color;
    }
  }
}
</style>
