<template>
  <div class="editor ck-content">
    <!-- <div id="ckeditor"></div> -->
    <!-- <ckeditor id="ckeditor"  :editor="editor" ></ckeditor> -->
    <ckeditor
      id="ckeditor"
      v-model="myValue"
      :editor="editor"
      :config="editorConfig"
      @ready="onReady"
    ></ckeditor>
  </div>
</template>

<script>
// import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

// import '@ckeditor/ckeditor5-ui/lang/translations/zh-cn'

import viewToPlainText from '@/util/ckeditor/viewtoplaintext.js'

// import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository'
import CodeBlockPlugin from '@ckeditor/ckeditor5-code-block/src/codeblock'

// import '@ckeditor/ckeditor5-basic-styles/lang/translations/zh-cn'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import Font from '@ckeditor/ckeditor5-font/src/font'

import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
// import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'

// import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
// ClassicEditor.builtinPlugins = [CodeBlock]

export default {
  name: 'task-describe-editor',
  components: {
    // ckeditor: CKEditor.component
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        language: {
          ui: 'zh-cn'
        },
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          HeadingPlugin,
          CodeBlockPlugin,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          ImageUpload,
          ListStyle,
          Indent,
          IndentBlock,
          Alignment,
          Table,
          Font,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          Strikethrough
          // EasyImage
        ],
        image: {
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'toggleImageCaption',
            'imageTextAlternative'
            // '|',
            // 'linkImage'
          ]
        },
        fontSize: {
          options: ['default', 12, 14, 16, 18, 20, 22, 24]
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties'
          ]
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'fontSize',
            'fontColor',
            'bold',
            'italic',
            'alignment',
            'indent',
            'outdent',
            'numberedList',
            'codeBlock',
            'uploadImage',
            'insertTable',
            'bulletedList',
            'redo',
            'undo',
            'Strikethrough',
            'link'
          ]
        }
      },
      myValue: this.value,
      instance: null
    }
  },
  computed: {},
  methods: {
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
      // CodeBlock = null
    },
    getContent() {
      // console.log(this.instance.data.toView())
      return {
        html: this.instance.getData(),
        text: viewToPlainText(this.instance.editing.view.document.getRoot())
      }
    },
    setContent(val) {
      this.instance.setData(val)
    },
    // 自定义图片上传适配器
    myUploadAdapter(loader) {
      const upload = () => {
        return new Promise((resolve) => {
          loader.file.then((file) => {
            console.log(file)
            const formdata = new FormData()
            formdata.set('file', file)
            const { result } = this.$util.uploadFile(
              this.$api.uploadMeetingMinutesRichTextFile,
              formdata
            )
            return result.then((res) => {
              console.log(res)
              if (res.state === 'ok') {
                resolve({
                  default: res.data
                })
              }
            })
          })
        })
      }
      return {
        upload
      }
    },
    onReady(e) {
      this.instance = e
      this.myValue = this.value
      // 绑定图片上传适配器
      e.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return this.myUploadAdapter(loader)
      }
    }
  },
  mounted() {
    // ClassicEditor.create(document.querySelector('#ckeditor'))
    //   .then((editor) => {
    //     this.instance = editor
    //     console.log(editor)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
    // this.editorData = this.value
  },
  watch: {
    value(val) {
      this.myValue = val
    },
    myValue(val, old) {
      this.$emit('input', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.ck-content ::v-deep {
  .ck-editor__editable.ck-rounded-corners {
    height: 600px;
  }
  .ck-editor__editable {
    h1,
    h2,
    h3,
    h4,
    h4,
    h5,
    h6 {
      padding-top: 5px;
    }
    body,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    pre,
    form,
    fieldset,
    input,
    p,
    blockquote,
    th,
    td {
      padding-top: 5px;
    }
    ol,
    ul {
      list-style: decimal;
      padding-left: 30px;
    }
    h6 {
      font-size: 16px !important;
      margin: 0;
    }
    h5 {
      font-size: 18px !important;
      margin: 0;
    }
    h4 {
      font-size: 20px !important;
      margin: 0;
    }
    h3 {
      font-size: 22px !important;
      margin: 0;
    }
    h2 {
      font-size: 24px !important;
      margin: 0;
    }
    h2 {
      font-size: 26px !important;
      margin: 0;
    }
    h1 {
      font-size: 28px !important;
      margin: 0;
    }
  }
  .ck-focused {
    box-shadow: none !important;
  }
  .ck-toolbar {
    background: transparent;
  }

  pre[data-language] {
    overflow: scroll !important;
  }
}
</style>
