<template>
  <!-- 创建知识库弹窗 -->
  <div class="add-pop-mask flex flex-x-center flex-y-center">
    <div class="add-base-pop flex flex-y-positive flex-y-between">
      <!-- 输入框 -->
      <el-input v-model="input" placeholder="输入知识库标题"></el-input>
      <!-- 按钮 -->
      <div class="button-box flex ">
        <button id="create">创建知识库</button>
        <el-button @click="$emit('cancle')">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'add-base-pop',
  components: {
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.add-pop-mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  .add-base-pop{
    width: 500px;
    height: 160px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 26px 26px 28px 26px;
    .button-box{
      margin-top: 24px;
      height: 32px;
      #create{
        width: 98px;
        height: 32px;
        background: #0080FF;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}
.button-box::v-deep .el-button{
  margin-left: 10px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  font-size: 14px;
  color: #999999;
  &:hover{
    color: #0080FF;
  }
}
.add-base-pop::v-deep .el-input{
  width: 448px;
  height: 50px;
  .el-input__inner{
    height: 50px;
  }
}
</style>
