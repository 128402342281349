<template>
  <scroll-fresh
    className="topic-container"
    :page="pageNumber"
    :lastPage="totalPage"
    :showNoMore="false"
    @get-next-page="getNextCommentPage"
  >
    <div class="flex member-info">
      <div class="member-avatar">
        <el-image
          :src="info.create_clerk_avatar | httpToHttps"
          @click="clickAvatar(info.create_clerk_id)"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="member-content">
        <div class="member-name">
          <span>{{
            info.create_clerk_alias || info.create_clerk_name || ''
          }}</span>
        </div>
        <div class="member-remark">{{ info.create_clerk_motto || '' }}</div>
      </div>
      <div class="more-button" @click.stop="handerShowMenu">
        <i class="icon-gengduo2"></i>
      </div>
      <transition name="el-fade-in-linear" v-if="showMenu">
        <div class="message-menu">
          <div class="menu-item" v-if="false">编辑</div>
          <div class="menu-item red" v-if="isShowDelete" @click="handerDelete">
            删除
          </div>
          <div class="menu-item" @click="copyLink">复制链接</div>
        </div>
      </transition>
    </div>
    <div class="topic-content">
      <div class="topic-video" v-if="info.content_type === 2">
        <video :src="info.video_info.cover_video_path" controls></video>
      </div>
      <div
        class="topic-title"
        v-if="info.moment_title"
        @click="addLink"
        v-html="renderMomentTitle"
      ></div>
      <div
        class="topic-text text-warp"
        v-if="info.moment_content"
        @click="addLink"
        v-html="
          handlerContentToUrl(getContenHtml(info)).replace(
            /\[[^\[\]]*\]/gi,
            emotion
          )
        "
      ></div>
      <div
        class="topic-image"
        v-for="(item, index) in info.image_list"
        :key="index"
      >
        <el-image
          v-if="screenWidth > 768"
          :src="item.cover_access_path"
        ></el-image>
        <el-image
          v-else
          @click="handlerPreviewImg(info.image_list, index)"
          :src="item.cover_access_path"
        ></el-image>
      </div>
      <div class="flex topic-interactive">
        <div>{{ info.create_time }}</div>
        <message-interactive
          :browseNum="info.visit_number"
          :commentNum="info.comment_number"
          :likeNum="info.praise_number"
          :isPraise="info.is_praise"
          @trigger="handlerInteractive"
        ></message-interactive>
      </div>
      <div class="topic-comment">
        <div class="title-bar">评论</div>
        <div class="comment-list">
          <div
            class="comment-item"
            v-for="(item, index) in commentList"
            :key="item.comment_id"
          >
            <comment-member
              :avatar="item.clerk_avatar"
              :name="item.clerk_alias || item.clerk_name"
              :time="item.create_time"
              :likeNum="item.praise_number"
              :isLike="item.is_praise == 1"
              @onReply="onReply(index)"
              @onPraise="commentPraise(index)"
              @onDelete="onDelete(index)"
              :showDelete="item.comment_id === showCommentDeleteId"
              @mouseenter.native="showCommentDelete(item)"
              @mouseleave.native="hideDelete()"
              @clickAvatar="clickAvatar(item.create_clerk_id)"
            ></comment-member>
            <div class="comment-content">
              <comment-reply
                v-for="(cItem, cIndex) in item.child_list_pack_up
                  ? item.child_list.slice(0, 3 + item.show_child_list_offset)
                  : item.child_list"
                :key="cItem.comment_id"
                :show-reply-to-name="cItem.pid !== cItem.tid"
                :replyInfo="cItem"
                :showDelete="showCommentDeleteId == cItem.comment_id"
                @onReply="onChildReply(index, cIndex)"
                @onDelete="onChildDelete(index, cIndex)"
                @onPraise="commentChildPraise(index, cIndex)"
                @mouseenter.native="showCommentDelete(cItem)"
                @mouseleave.native="hideDelete()"
                @appenLink="addLink($event)"
                @clickAvatar="clickAvatar"
              ></comment-reply>
              <template
                v-if="item.reply_number - item.show_child_list_offset > 3"
              >
                <comment-total-bar
                  v-if="item.child_list_pack_up"
                  :replyNumber="item.reply_number"
                  :lastestName="
                    item.lastest_clerk_alias || item.lastest_clerk_name
                  "
                  @open="getCommentChildNextPage(index)"
                ></comment-total-bar>
                <div
                  class="line-top flex flex-x-between comment-pack-load-bar"
                  v-if="!item.child_list_pack_up"
                >
                  <span
                    style="color: #0080ff; cursor: pointer"
                    @click="closeChildCommentList(index)"
                  >
                    <i class="icon-zhankai pack-up"></i>
                    收起
                  </span>
                  <span
                    style="color: #999; cursor: pointer"
                    @click.stop="getCommentChildNextPage(index)"
                    v-show="
                      item.child_list_page_number < item.child_list_total_page
                    "
                  >
                    <i class="icon-zhankai"></i>
                    加载更多
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <comment-no-more></comment-no-more>
      </div>
    </div>
    <!-- <div class="send-comment" v-show="isShowReplyText" @click.stop="">
        <textarea
          v-model="replyText"
          maxlength="-1"
          :placeholder="placeholderText"
          placeholder-style="color: #BBBBBB;"
          ref="commentRef"
        />
        <div class="add-comment-innder flex flex-x-end flex-y-end">
          <expression-box
            :isShow="isShowCommentExpression"
            :expressList="$emoji.slice(0, 100)"
            @add-express="addExpress($event)"
            @toggle-express-box="toggleCommentExpression($event)"
          ></expression-box>
          <button type="default" class="send" @click="sendComment">
            发表评论
          </button>
        </div>
      </div> -->
    <comment-dialog
      v-if="commentDetail !== -1"
      :info="commentList[commentDetail]"
      @close="commentDetail = -1"
      @praise="dialogPraise"
      @comment="dialogComment"
    ></comment-dialog>
    <reply-dialog
      v-if="isShowReply"
      :title="replyTitle"
      v-model="replyText"
      @close="isShowReply = false"
      @send="sendComment"
    ></reply-dialog>
  </scroll-fresh>
</template>

<script>
import MessageInteractive from '@/components/circle/MessageInteractive.vue'
import CommentMember from '@/components/circle/CommentMember.vue'
import CommentReply from '@/components/circle/CommentReply.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
// 导入表情组件
// import ExpressionBox from '@/components/common/ExpressionBox.vue'
import ReplyDialog from '@/components/circle/ReplyDialog.vue'
// 评论弹窗
import CommentDialog from '@/components/circle/CommentDialog.vue'
import CommentTotalBar from '@/components/circle/CommentTotalBar.vue'
import CommentNoMore from '@/components/circle/CommentNoMore.vue'
import ImagePreview from 'vant/lib/image-preview'
// import { emojisMap } from '@/components/common/ExpressionBox.vue'
import 'vant/lib/image-preview/style'
import { mapState } from 'vuex'
export default {
  components: {
    MessageInteractive,
    CommentMember,
    CommentReply,
    ScrollFresh,
    ReplyDialog,
    CommentDialog,
    CommentTotalBar,
    CommentNoMore
    // ExpressionBox
  },
  data() {
    return {
      momentId: '',
      info: {},
      pageNumber: 1,
      totalPage: 2,
      pageSize: 10,
      commentList: [],
      showMenu: false,
      placeholderText: '发表评论',
      // 展示评论删除id
      showCommentDeleteId: -1,
      // 回复id
      replyCommentId: '',
      // 回复内容
      replyText: '',
      replyChildIndex: -1,
      isShowReplyText: false,
      isShowCommentExpression: false,
      isShowReply: false,
      commentDetail: -1,
      replyTitle: '评论',
      childCommentLoading: false,
      commentLoading: false,
      // 偏移评论id
      offsetCommentId: '',
      // 偏移
      offset: 0
    }
  },
  computed: {
    ...mapState(['myClerkId', 'screenWidth']),
    // 是否展示删除按钮
    isShowDelete() {
      if (this.info.create_clerk_id === this.myClerkId) {
        return true
      }
      return false
    },
    renderMomentTitle() {
      const content = this.info.moment_title
      let str = ''
      let index = 0
      if (this.info.topic_title_list.length !== 0) {
        this.info.topic_title_list.forEach((e, i) => {
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${e.topic_id}" data-class="topic" data-name="${e.topic_name}" style=" color: #0080FF;cursor: pointer;font-weight:600">#${e.topic_name}#</a>`
          index = e.end_index + 1
          if (i === this.info.topic_title_list.length - 1) {
            str += content.slice(index)
          }
        })
      } else {
        str = content
      }
      console.log(str)
      return str
    }
  },
  methods: {
    // 获取评论内容html
    getCommentHtml(e) {
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = e.comment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;cursor: pointer;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 获取内容html
    getContenHtml(e) {
      // 提及列表
      const clerList = e.clerk_list.map((c) => {
        c.type = 1
        return c
      })
      // 话题列表
      const topicList = e.topic_list.map((t) => {
        t.type = 2
        return t
      })
      const list = clerList.concat(topicList).sort((a, b) => {
        return a.start_index - b.start_index
      })
      const content = e.moment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name =
            e.type === 1
              ? '@' + (e.clerk_alias || e.clerk_name) + ' '
              : '#' + e.topic_name + '#' + ' '
          const id = e.type === 1 ? e.clerk_id : e.topic_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="${
              e.type === 1 ? 'mention' : 'topic'
            }" style=" color: #0080FF;cursor: pointer;font-weight:600"> ${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 手机端图片预览
    handlerPreviewImg(arr, startPosition) {
      const images = []
      arr.forEach((item) => {
        images.push(item.cover_access_path)
      })
      ImagePreview({ images, startPosition })
    },
    // 获取详情
    async getDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentInfo, {
          moment_id: this.momentId
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.info = res.info
      }
    },
    // 触发互动
    async handlerInteractive(e) {
      switch (e.type) {
        // 评论
        case 'comment':
          this.handlerComment()
          break
        // 圈子点赞
        case 'like': {
          this.handlerPraise()
          break
        }
      }
    },
    // 触发点赞
    async handlerPraise() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.praiseMoment, {
          moment_id: this.momentId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.info, 'is_praise', res.is_praise ? 1 : 0)
        res.is_praise ? this.info.praise_number++ : this.info.praise_number--
      }
    },
    // 触发评论
    async handlerComment() {
      this.replyChildIndex = -1
      this.replyCommentId = ''
      this.isShowReply = true
      this.isShowReply = true
      // this.isShowReplyText = !this.isShowReplyText
      // if (this.isShowReplyText) {
      //   this.$nextTick(() => {
      //     this.$refs.commentRef.focus()
      //   })
      // }
    },
    // 重新获取评论列表
    async reloadCommentList() {
      if (this.commentLoading) {
        return
      }
      this.pageNumber = 1
      this.offset = 0
      // this.commentList = []
      this.commentLoading = true
      await this.getCommentList(true)
      this.commentLoading = false
    },
    // 获取评论列表
    async getCommentList(reset = false) {
      const formObj = {
        moment_id: this.momentId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        offset: this.offset
        // offset_comment_id: this.offsetCommentId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const list = res.page.list.map((e, i) => {
          if (e.child_list) {
            return {
              ...e,
              show_child_list_offset: 0,
              query_child_list_offset: e.child_list.length,
              child_list_pack_up: true,
              child_list_page_number: 0
            }
          }
          return e
        })
        if (reset) {
          this.commentList = list
        } else {
          this.commentList = this.commentList.concat(list)
        }
        // if (res.page.pageNumber === 1) {
        //   this.offsetCommentId = res.page.list[0].comment_id
        // }
        this.totalPage = res.page.totalPage
      }
    },
    // 获取下一页
    async getNextCommentPage() {
      this.pageNumber++
      await this.getCommentList()
    },
    // 显示或隐藏菜单
    handerShowMenu() {
      this.showMenu = !this.showMenu
    },
    // 显示删除按钮
    showCommentDelete(item) {
      let hasPer = false
      if (this.info.create_clerk_id === this.myClerkId) {
        hasPer = true
      }
      if (hasPer || item.create_clerk_id === this.myClerkId) {
        hasPer = true
      }

      if (hasPer) {
        this.showCommentDeleteId = item.comment_id
      }
    },
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`) : url
    },
    // 隐藏删除按钮
    hideDelete() {
      this.showCommentDeleteId = -1
    },
    // 回复
    onReply(index) {
      this.replyChildIndex = index
      this.isShowReply = true
      this.replyTitle =
        '回复 ' +
        (this.commentList[index].clerk_alias ||
          this.commentList[index].clerk_name)
      this.replyCommentId = this.commentList[index].comment_id
      // this.placeholderText = '回复 ' + this.commentList[index].clerk_name
      // this.replyCommentId = this.commentList[index].comment_id
      // this.isShowReplyText = true
      // this.$nextTick(() => {
      //   this.$refs.commentRef.focus()
      // })
      // this.$refs.commentInput.focus()
    },
    // 子评论回调
    onChildReply(index, cIndex) {
      this.replyChildIndex = index
      this.isShowReply = true
      this.replyTitle =
        '回复 ' +
        (this.commentList[index].child_list[cIndex].clerk_alias ||
          this.commentList[index].child_list[cIndex].clerk_name)
      this.replyCommentId =
        this.commentList[index].child_list[cIndex].comment_id
      // this.placeholderText = '回复 ' + name
      // this.replyCommentId = id
      // this.isShowReplyText = true
      // this.$nextTick(() => {
      //   this.$refs.commentRef.focus()
      // })
      // this.$refs.commentInput.focus()
    },
    // 回复评论
    async sendComment() {
      if (this.replyText === '') {
        this.$message.error('请输入评论内容')
        return
      }
      const formObj = {
        moment_id: this.momentId,
        comment_content: this.replyText,
        pid: this.replyCommentId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentComment, formObj)
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        if (this.replyChildIndex === -1) {
          this.commentList.unshift(res.info)
          this.offset++
          // this.reloadCommentList()
        } else {
          // 回复评论
          // 没有子列表时初始化参数
          if (!this.commentList[this.replyChildIndex].child_list) {
            this.$set(this.commentList[this.replyChildIndex], 'child_list', [])
            this.$set(
              this.commentList[this.replyChildIndex],
              'child_list_pack_up',
              true
            )
          }
          // 设置子列表最后更新人名称
          this.$set(
            this.commentList[this.replyChildIndex],
            'lastest_clerk_name',
            res.info.clerk_name
          )
          // 设置子列表最后更新人花名
          this.$set(
            this.commentList[this.replyChildIndex],
            'lastest_clerk_alias',
            res.info.clerk_alias
          )
          // 设置子列表最后回复数量
          this.$set(
            this.commentList[this.replyChildIndex],
            'reply_number',
            (this.commentList[this.replyChildIndex].reply_number || 0) + 1
          )
          // 设置子列表查询偏移数量
          this.$set(
            this.commentList[this.replyChildIndex],
            'query_child_list_offset',
            (this.commentList[this.replyChildIndex].query_child_list_offset ||
              0) + 1
          )
          // 如果子列表未展开,设置子列表展示偏移数量
          if (this.commentList[this.replyChildIndex].child_list_pack_up) {
            this.$set(
              this.commentList[this.replyChildIndex],
              'show_child_list_offset',
              (this.commentList[this.replyChildIndex].show_child_list_offset ||
                0) + 1
            )
          }
          this.commentList[this.replyChildIndex].child_list.unshift(res.info)
        }
        this.replyChildIndex = -1
        this.$set(this.info, 'comment_number', this.info.comment_number + 1)
        // this.$message({
        //   showClose: true,
        //   message: '评论成功',
        //   type: 'success'
        // })
      }
    },
    // 删除评论
    async onDelete(index) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentComment, {
          comment_id: this.commentList[index].comment_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        this.reloadCommentList()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 删除子级评论
    async onChildDelete(index, cIndex) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentComment, {
          comment_id: this.commentList[index].child_list[cIndex].comment_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        this.reloadCommentChildList(index)
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 父级评论点赞
    async commentPraise(index) {
      const flag = await this.onPraise(this.commentList[index].comment_id)
      flag
        ? this.commentList[index].praise_number++
        : this.commentList[index].praise_number--
      this.$set(this.commentList[index], 'is_praise', flag ? 1 : 0)
    },
    // 子级评论点赞
    async commentChildPraise(index, cIndex) {
      const info = this.commentList[index].child_list[cIndex]
      const flag = await this.onPraise(info.comment_id)
      flag ? info.praise_number++ : info.praise_number--
      this.$set(info, 'is_praise', flag ? 1 : 0)
    },
    // 重置回复数据
    resetReplyData() {
      this.placeholderText = '发表评论'
      this.replyCommentId = ''
      this.replyText = ''
      this.isShowReplyText = false
      this.replyTitle = '评论'
      this.isShowReply = false
    },
    // 复制链接
    copyLink() {
      this.$copyText(
        `${this.$config.frontHome}topicInfo/${this.momentId}`
      ).then(
        () => {
          this.$message({
            showClose: true,
            message: '已成功复制，可直接去粘贴',
            type: 'success'
          })
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // 删除
    async handerDelete() {
      this.$confirm('此操作将删除该圈子, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.deleteMoment, {
              moment_id: this.momentId
            })
          )
          if (err) {
            this.$message.error(err)
          } else if (res.state === 'ok') {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.push('/')
            }, 1000)
          }
        })
        .catch(() => {})
    },
    // 处理回复移除
    hideReplyText() {
      if (this.replyText === '') {
        this.placeholderText = '发表评论'
        this.replyCommentId = ''
        this.replyText = ''
        this.isShowReplyText = false
      }
    },
    // 点击表情
    addExpress(express) {
      this.replyText += express.char
      this.isShowCommentExpression = false
      this.$refs.commentRef.focus()
    },
    // 显示隐藏表情
    toggleCommentExpression(val) {
      this.isShowCommentExpression = !val
    },
    clickOut() {
      this.hideReplyText()
      if (this.showMenu === true) {
        this.showMenu = false
      }
    },
    // 点击头像
    clickAvatar(clerkId) {
      console.log(clerkId)
      console.log(this.myClerkId)
      console.log(this.myClerkId === clerkId)
      if (this.myClerkId === clerkId) {
        this.$router.push({
          name: 'memberHome',
          query: { clerk_id: clerkId }
        })
      } else {
        this.$router.push({
          name: 'circleHome',
          query: { clerk_id: clerkId }
        })
      }
    },
    // 弹窗点赞
    dialogPraise({ comment_id: commentId, is_praise: isPraise }) {
      const index = this.commentList.findIndex((e) => {
        return e.comment_id === commentId
      })
      if (isPraise === 1) {
        this.commentList[index].praise_number++
      } else {
        this.commentList[index].praise_number--
      }
      this.commentList[index].is_praise = isPraise
    },
    // 弹窗评论
    dialogComment() {
      this.reloadCommentList()
      this.$set(this.info, 'comment_number', this.info.comment_number + 1)
    },
    // 获取评论列表
    async getCommentChildList(index, reset = false) {
      const formObj = {
        moment_id: this.momentId,
        tid: this.commentList[index].comment_id,
        pageNumber: this.commentList[index].child_list_page_number || 1,
        pageSize: 5,
        offset: this.commentList[index].query_child_list_offset || 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentChildPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.$set(this.commentList[index], 'child_list', res.page.list)
        } else {
          this.$set(
            this.commentList[index],
            'child_list',
            (this.commentList[index].child_list || []).concat(res.page.list)
          )
        }
        this.$set(
          this.commentList[index],
          'child_list_page_number',
          res.page.pageNumber
        )
        this.$set(
          this.commentList[index],
          'child_list_total_page',
          res.page.totalPage
        )
      }
    },
    // 获取下一页
    async getCommentChildNextPage(index) {
      if (this.childCommentLoading) {
        return
      }
      const info = this.commentList[index]
      if (info.child_list_pack_up) {
        this.$set(this.commentList[index], 'child_list_pack_up', false)
        if (info.child_list.length - info.show_child_list_offset > 3) {
          return
        }
      }
      this.$set(
        this.commentList[index],
        'child_list_page_number',
        (info.child_list_page_number || 0) + 1
      )
      this.childCommentLoading = true
      await this.getCommentChildList(index)
      this.childCommentLoading = false
    },
    // 刷新列表
    async reloadCommentChildList(index) {
      this.$set(this.commentList[index], 'child_list_page_number', 1)
      this.$set(this.commentList[index], 'child_list_total_page', 2)
      this.$set(this.commentList[index], 'query_child_list_offset', 0)
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      // this.$set(this.commentList[index], 'childList', [])
      this.childCommentLoading = true
      await this.getCommentChildList(index, true)
      this.childCommentLoading = false
    },
    // 打开下级评论列表
    openChildCommentList(index) {
      // 清空子列表展示偏移数量
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      if (this.commentList[index].child_list_pack_up == null) {
        this.getCommentChildList(index)
      }
      this.$set(this.commentList[index], 'child_list_pack_up', false)
    },
    // 收起下级评论列表
    closeChildCommentList(index) {
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      this.$set(this.commentList[index], 'child_list_pack_up', true)
    },
    addLink(e) {
      const cb = e.target.dataset
      if (cb.class === 'topic') {
        this.$router.push({
          name: 'circleIndex',
          query: { topic_id: cb.id }
        })
      }
      if (cb.class === 'mention') {
        this.$router.push({
          name: 'circleHome',
          query: { clerk_id: cb.id }
        })
      }
    }
  },
  mounted() {
    // 菜单显示时隐藏菜单
    document.addEventListener('click', this.clickOut)
    this.momentId = this.$route.params.id
  },
  watch: {
    async momentId(val) {
      if (this.momentId !== -1) {
        await this.getDetail()
        if (this.info.moment_id) {
          this.reloadCommentList()
        } else {
          setTimeout(() => {
            this.$router.push('/')
          }, 1000)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-container {
  padding: 24px 0 50px;
  width: 780px;
  margin: 0 auto;
  height: calc(100vh - 60px);
  overflow: scroll;

  .member-info {
    position: relative;
    padding: 36px 42px;
    background-color: #fff;
    border-radius: 6px;

    .member-avatar {
      margin-right: 24px;

      .el-image {
        width: 88px;
        height: 88px;
        line-height: 88px;
        font-size: 24px;
        text-align: center;
        background-color: #f6f6f6;
        border-radius: 66px;
      }
    }

    .member-content {
      flex: 1;
    }

    .member-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }

    .member-remark {
      font-size: 14px;
      line-height: 21px;
      color: #999999;
      word-wrap: break-word;
      white-space: pre-wrap;
    }

    .more-button {
      margin-left: 38px;
      i {
        font-size: 22px;
      }
    }

    .message-menu {
      position: absolute;
      width: 92px;
      background-color: #fff;
      z-index: 100;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
      right: 50px;
      top: 65px;
      border-radius: 4px;
      padding: 6px 0;

      .menu-item {
        cursor: pointer;
        width: 76px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        text-align: center;
        margin: 0 auto;

        &:hover {
          color: $main-color;
          background-color: rgba(0, 128, 255, 0.1);
          border-radius: 4px;
        }

        &.red {
          color: $red;

          &:hover {
            background-color: rgba(255, 84, 84, 0.1);
          }
        }
      }
    }
  }

  .topic-content {
    padding: 40px 42px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 6px;

    .topic-video {
      background-color: #000;
      border-radius: 15px;
      overflow: hidden;
      margin-bottom: 24px;

      video {
        width: 100%;
        height: 340px;
      }
    }

    .topic-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #333333;
    }

    .topic-text {
      font-size: 14px;
      line-height: 26px;
      color: #666666;
    }

    .topic-image {
      margin: 10px 0;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }

    .topic-interactive {
      justify-content: space-between;
      align-items: center;
      color: #999999;
      font-size: 14px;
    }

    .topic-comment {
      position: relative;
      padding: 0 12px;
      font-size: 14px;
      line-height: 24px;
      color: #333333;

      &::after {
        position: absolute;
        content: '';
        height: 20px;
        width: 4px;
        top: 2px;
        left: 0;
        background-color: #0080ff;
      }

      .title-bar {
        margin-bottom: 24px;
      }

      .comment-list {
        .comment-item {
          .comment-content {
            cursor: auto;
            margin-left: 48px;
            white-space: inherit;
          }
        }
      }
    }
  }
  .send-comment {
    width: 780px;
    height: 190px;
    position: fixed;
    bottom: 0px;
    background: #ffffff;
    box-shadow: 0px -2px 15px rgba(102, 102, 102, 0.1);
    // padding: 27px 32px 33px 28px;
    display: flex;
    flex-direction: column;
    textarea {
      margin-top: 27px;
      margin-left: 32px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      border-radius: 6px;
      width: 703px;
      height: 85px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #333333;
      padding: 10px 14px 0 14px;
    }
    .add-comment-innder {
      margin-top: 14px;
      margin-left: 32px;
      width: 703px;
    }
    .send {
      margin-left: 10px;
      width: 90px;
      height: 36px;
      background: #0080ff;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      border: none;
    }
  }
}
</style>
