<!--
 * @Date: 2021-07-23 22:53:35
 * @LastEditTime: 2021-12-01 14:30:27
 * @Description: 动态-宫格组件
 * @Author: lyq
 * @LastEditors: hzh
-->
<template>
  <div>
    <ul class="flex image-container">
      <li class="flex image-item" v-for="(item, index) in imgs" :key="index">
        <el-image
          v-if="screenWidth > 768"
          fit="cover"
          :src="renderSrc(item.cover_access_path)"
          :preview-src-list="getPrivewImages(index)"
        ></el-image>
        <el-image
          v-else
          style="width: 100%; height: 100%; max-height: 400px"
          fit="cover"
          :src="renderSrc(item.cover_access_path)"
          @click.native="handlerPreviewImg(renderImgs, index)"
        ></el-image>
      </li>
    </ul>
  </div>
</template>

<script>
import ImagePreview from 'vant/lib/image-preview'
import 'vant/lib/image-preview/style'
import { mapState } from 'vuex'
export default {
  props: ['imgs'],
  computed: {
    ...mapState(['screenWidth']),
    renderImgs() {
      return this.imgs.map((e, i) => {
        return `${e.cover_access_path}?x-oss-process=style/moment_detail_image`
      })
    }
  },
  methods: {
    renderSrc(src) {
      if (this.imgs.length > 1) {
        return `${src}?x-oss-process=style/square230`
      }
      // return src
      return `${src}?x-oss-process=style/moment_single_image`
    },
    handlerPreviewImg(images, startPosition) {
      ImagePreview({ images, startPosition })
    },
    getPrivewImages(index) {
      const tempImgList = [...this.renderImgs]
      if (index === 0) return tempImgList
      const start = tempImgList.splice(index)
      const remain = tempImgList.splice(0, index)
      return start.concat(remain)
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin square($count: 2) {
  @media screen and (min-width: 767px) {
    $length: calc((493px - #{$count} * 15px) / #{$count});
    width: $length;
    height: $length;
  }

  @media screen and (max-width: 767px) {
    $length: calc((279px - #{$count} * 15px) / #{$count});
    width: $length;
    height: $length;
  }
}

.image-container {
  padding: 4px 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;

  .image-item {
    margin: 6px;
    overflow: hidden;
    justify-content: center;
    background-color: #f0f0f0;
    @include square(3);
    border-radius: 6px;
  }

  @media screen and (max-width: 767px) {
    .image-item {
      margin: 3px;
    }
    .image-item:only-child {
      max-height: 400px;
    }
  }

  .el-image {
    width: 100%;
    height: 100%;
  }

  // 一个元素
  .image-item:only-child {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 600px;
  }

  // 两个元素
  .image-item:first-child:nth-last-child(2),
  .image-item:first-child:nth-last-child(2) ~ .image-item:nth-child(2) {
    @include square(2);
  }
  // 三个元素
  .image-item:first-child:nth-last-child(3),
  .image-item:first-child:nth-last-child(3) ~ .image-item:nth-child(2),
  .image-item:first-child:nth-last-child(3) ~ .image-item:nth-child(3) {
    @include square(3);
  }

  // .image-item:first-child:nth-last-child(3) ~ .image-item:nth-child(-n + 3) {
  //     margin-top: 0;
  // }

  // .image-item:first-child:nth-last-child(3)
  //   ~ .image-item:nth-last-child(-n + 3) {
  //   margin-bottom: 0;
  // }

  // 四个元素
  .image-item:first-child:nth-last-child(4),
  .image-item:first-child:nth-last-child(4) ~ .image-item:nth-child(2),
  .image-item:first-child:nth-last-child(4) ~ .image-item:nth-child(3),
  .image-item:first-child:nth-last-child(4) ~ .image-item:nth-child(4) {
    @include square(2);
  }
}
</style>
