<template>
  <div class="editor ck-content">
    <!-- <div id="ckeditor"></div> -->
    <!-- <ckeditor id="ckeditor"  :editor="editor" ></ckeditor> -->
    <ckeditor
      id="ckeditor"
      v-model="myValue"
      :editor="editor"
      :config="editorConfig"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @ready="onReady"
      :key="key"
    ></ckeditor>
  </div>
</template>

<script>
// import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

// import '@ckeditor/ckeditor5-ui/lang/translations/zh-cn'

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'

import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import '@ckeditor/ckeditor5-code-block/build/translations/zh-cn'
import '@ckeditor/ckeditor5-basic-styles/build/translations/zh-cn'
import '@ckeditor/ckeditor5-link/build/translations/zh-cn'
import '@ckeditor/ckeditor5-heading/build/translations/zh-cn'
import '@ckeditor/ckeditor5-table/build/translations/zh-cn'
import '@ckeditor/ckeditor5-alignment/build/translations/zh-cn'
import '@ckeditor/ckeditor5-indent/build/translations/zh-cn'
import '@ckeditor/ckeditor5-image/build/translations/zh-cn'
import '@ckeditor/ckeditor5-list/build/translations/zh-cn'
import Mention from '@ckeditor/ckeditor5-mention/src/mention'
// import { emojisMap } from '@/common/ExpressionBox.vue'
// import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
// ClassicEditor.builtinPlugins = [CodeBlock]

export default {
  components: {
    // ckeditor: CKEditor.component
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '请输入内容'
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        language: {
          ui: 'zh-cn'
        },
        forcePasteAsPlainText: true,
        extraPlugins: [this.MentionLinks],
        isContextPlugin: true,
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          Mention
          // EasyImage
        ],
        toolbar: {},
        placeholder: this.title,
        startupOutlineBlocks: true,
        mention: {
          feeds: [
            {
              marker: '@',
              feed: this.getClerkList,
              itemRenderer: this.customItemRenderer
            }
          ]
        }
      },
      myValue: '',
      instance: null,
      key: 1
    }
  },
  computed: {},
  methods: {
    // emotion(name) {
    //   if (emojisMap[name] != null) {
    //     return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width:20px"}">`
    //   } else {
    //     return name
    //   }
    //   // const word = nane.replace(/\[|\]/gi, '')
    //   // const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
    //   // const indon = list.indexOf(word)
    //   // return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${indon}.gif" align="middle">`
    // },
    // handlerContentToUrl(url) {
    //   const reg = new RegExp(
    //     '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
    //   )
    //   const urlRes = reg.exec(url)
    //   const hasLink =
    //     Object.prototype.toString.call(urlRes) === '[object Array]'
    //   return hasLink
    //     ? url.replace(
    //       reg,
    //       `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
    //     )
    //     : url
    // },
    // getContenHtml(e) {
    //   // 提及列表
    //   const clerList = e.clerk_list.map((c) => {
    //     c.type = 1
    //     return c
    //   })
    //   // 话题列表
    //   const topicList = e.topic_list.map((t) => {
    //     t.type = 2
    //     return t
    //   })
    //   const list = clerList.concat(topicList).sort((a, b) => {
    //     return a.start_index - b.start_index
    //   })
    //   const content = e.moment_content || ''
    //   let str = ''
    //   let index = 0
    //   if (list.length !== 0) {
    //     list.forEach((e, i) => {
    //       const name =
    //         e.type === 1
    //           ? '@' + (e.clerk_alias || e.clerk_name) + ' '
    //           : '#' + e.topic_name + '#' + ' '
    //       const id = e.type === 1 ? e.clerk_id : e.topic_id
    //       str +=
    //         content.substring(index, e.start_index) +
    //         `<a data-id="${id}" data-name="${name}" data-class="${
    //           e.type === 1 ? 'mention' : 'topic'
    //         }" style=" color: #0080FF;cursor: pointer;font-weight:600"> ${name}</a>`
    //       index = e.end_index + 1
    //       if (i === list.length - 1) {
    //         str += content.slice(index)
    //       }
    //     })
    //     return str
    //   } else {
    //     return content
    //   }
    // },
    setFocus() {
      this.instance.focus()
      this.instance.model.change((writer) => {
        console.log(writer)
        writer.setSelection(
          writer.createPositionAt(this.instance.model.document.getRoot(), 'end')
        )
      })
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
      // CodeBlock = null
    },
    // getContent() {
    //   // console.log(this.instance.data.toView())
    //   return {
    //     html: this.instance.getData(),
    //     text: viewToPlainText(this.instance.editing.view.document.getRoot())
    //   }
    // },
    setContent(val) {
      this.instance.setData(val)
    },
    onReady(e) {
      this.instance = e
      this.myValue = this.value
    },
    async getClerkList(e) {
      let list = []
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkPage, {
          search_name: e,
          pageNumber: 1,
          pageSize: 20,
          is_leave: 0,
          clerk_status: 1
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        list = res.page.list.map((t) => {
          t.type_id = t.clerk_id
          t.id = '@' + (t.clerk_alias || t.clerk_name)
          return t
        })
      }
      return list
    },
    customItemRenderer(item) {
      const itemElement = document.createElement('span')
      const avatar = document.createElement('img')
      const userNameElement = document.createElement('span')

      itemElement.classList.add('mention__item')

      avatar.src = `${item.clerk_avatar || ''}`

      userNameElement.classList.add('mention__item__user-name')
      userNameElement.textContent = item.id

      itemElement.appendChild(avatar)
      itemElement.appendChild(userNameElement)

      return itemElement
    },
    MentionLinks(editor) {
      // The upcast converter will convert a view
      //
      //		<a href="..." class="mention" data-mention="...">...</a>
      //
      // element to the model "mention" text attribute.
      editor.conversion.for('upcast').elementToAttribute({
        view: {
          name: 'a',
          key: 'data-mention',
          classes: 'mention',
          attributes: {
            href: true
          }
        },
        model: {
          key: 'mention',
          value: (viewItem) => {
            let typeId = ''
            viewItem._attrs.forEach((e, i) => {
              if (i === 'data-id') {
                typeId = e
              }
            })
            // The mention feature expects that the mention attribute value
            // in the model is a plain object with a set of additional attributes.
            // In order to create a proper object use the toMentionAttribute() helper method:
            const mentionAttribute = editor.plugins
              .get('Mention')
              .toMentionAttribute(viewItem, {
                // Add any other properties that you need.
                type_id: typeId
              })
            return mentionAttribute
          }
        },
        converterPriority: 'high'
      })

      // Downcast the model "mention" text attribute to a view
      //
      //		<a href="..." class="mention" data-mention="...">...</a>
      //
      // element.
      editor.conversion.for('downcast').attributeToElement({
        model: 'mention',
        view: (modelAttributeValue, { writer }) => {
          // Do not convert empty attributes (lack of value means no mention).
          if (!modelAttributeValue) {
            return
          }
          // User mentions are downcasted as mailto: links. Tags become normal URLs.

          return writer.createAttributeElement(
            'a',
            {
              class: 'mention',
              'data-mention': modelAttributeValue.id,
              'data-id': modelAttributeValue.type_id,
              'data-type': modelAttributeValue.id[0] === '#' ? 2 : 1,
              href: ''
            },
            {
              // Make mention attribute to be wrapped by other attribute elements.
              priority: 20,
              // Prevent merging mentions together.
              id: modelAttributeValue.uid
            }
          )
        },
        converterPriority: 'high'
      })
    }
  },
  mounted() {
    // ClassicEditor.create(document.querySelector('#ckeditor'))
    //   .then((editor) => {
    //     this.instance = editor
    //     console.log(editor)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
    // this.editorData = this.value
  },
  watch: {
    value(val) {
      this.myValue = val
    },
    myValue(val, old) {
      this.$emit('input', val)
    },
    title(val) {
      this.editorConfig.placeholder = val
      this.key++
      // console.log(this.instance.config.get('placeholder'))
      // this.instance.config.set('placeholder', val)
    }
  }
}
</script>
<style lang="scss">
.ck-mentions .mention__item {
  display: block;
}

.ck-mentions .mention__item img {
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.ck-mentions .mention__item span {
  margin-left: 0.5em;
}

.ck-mentions .mention__item.ck-on span {
  color: var(--ck-color-base-background);
}

.ck-mentions .mention__item .mention__item__full-name {
  color: hsl(0, 0%, 45%);
}

.ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
  color: hsl(0, 0%, 40%);
}
.ck-body-wrapper {
  .ck-balloon-panel {
    .ck-mentions {
      button.ck-button {
        width: 300px !important;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .ck-button:hover:not(.ck-disabled) {
        background: #f1f4ff !important;
      }
      .ck-button.ck-on:hover:not(.ck-disabled) {
        background: #60b5ff !important;
      }
    }
    box-shadow: 0px 2px 6px rgb(153 153 153 / 25%);
    border: 1px solid #f7f7f7;
  }
  // .ck-button.ck-on {
  //   background: #92ccff !important;
  // }
}
// .ck-content .mention {
//   color: blue!important;
// }
</style>
<style lang="scss" scoped>
.ck-content ::v-deep {
  .ck-editor__editable {
    line-height: 26px;
    height: 85px;
    // border: 1px solid #DCDFE6 !important;
    border-radius: 4px !important;
  }
  .ck-editor__editable_inline > * {
    margin: 0 7px;
  }
  .ck-editor__editable.ck-blurred {
    border: 1px solid #dcdfe6 !important;
  }
  .ck-toolbar {
    border: none;
  }

  .ck-focused {
    box-shadow: none !important;
  }
  .mention {
    color: $main-color !important;
    background: transparent !important;
  }
  .ck-placeholder,
  .ck-placeholder:before {
    color: #9999 !important;
  }
  pre[data-language] {
    overflow: scroll !important;
  }
}
#ckeditor {
  height: 85px;
}
</style>
