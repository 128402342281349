<!--
 * @Date: 2021-06-01 10:01:35
 * @LastEditTime: 2021-08-23 17:13:35
 * @Description: 选择人员（带多选）
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <!-- 选择成员弹窗 -->
  <el-dialog
    class="add-member"
    :title="modalTitle"
    :modal="true"
    :visible.sync="isShowAddMember"
    width="600"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <!-- 这个是弹窗内容 -->
    <div class="add-member-box flex">
      <!-- 左边 -->
      <div class="flex1 left-box">
        <!-- 查找联系人的弹窗 -->
        <div class="search-box flex flex-y-center flex-x-between">
          <input
            type="text"
            class="flex1 input"
            v-model="searchName"
            placeholder="查找联系人"
            @input="triggerSearch"
          />
          <!-- 清空按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length > 0"
            class="clear-btn"
            @click="clearSearch"
          >
            <span class="iconfont icon-decline"></span>
          </button>
          <!-- 搜索按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length === 0"
            class="search-btn"
            @click="searchClerkList"
          >
            <span class="iconfont icon-search"></span>
          </button>
        </div>
        <!-- 数据区域 -->
        <div class="tree-list">
          <!-- 使用树形组件，显示树形结构 -->
          <template v-if="searchName.trim().length === 0">
            <div v-for="item in dataList" :key="item.id">
              <member-tree-by-dept
                :treeNode="item"
                :isShowCheckAll="isShowCheckAll"
                @to-add-members="chooseMore($event)"
                @to-remove-member="cancelMore($event)"
                :memberList="selectIdList"
              ></member-tree-by-dept>
            </div>
          </template>
          <!-- 搜索结果显示在这里 -->
          <template v-else>
            <div class="search-result">
              <div
                class="member-item flex flex-y-center"
                v-for="(clerk, index) in searchResultList"
                :key="index"
                @click="chooseMore([clerk.clerk_id])"
              >
                <el-checkbox
                  @change="changeChecked($event, [clerk.clerk_id])"
                  v-model="clerk.checked"
                  style="margin-right: 15px"
                ></el-checkbox>
                <avatar
                  :userInfo="clerk"
                  :size="30"
                  :distanceRight="10"
                ></avatar>
                <p class="name e">
                  {{ clerk.clerk_alias || clerk.clerk_name }}
                </p>
              </div>
              <!-- 假如没有搜索结果 -->
              <p class="no-data" v-show="searchResultList.length === 0">
                暂无搜索结果，请换个关键词吧。
              </p>
            </div>
          </template>
        </div>
      </div>
      <!-- 右边 -->
      <div class="flex1 right-box">
        <!-- 显示出已选择的成员 -->
        <p class="member-title">
          已选择（{{ leaveClerkList.length + selectIdList.length }}）
        </p>
        <!-- 显示成员的地方 -->
        <div class="members-area">
          <!-- 显示出已有成员列表 -->
          <div
            class="member-item flex flex-y-center"
            v-for="item in selectList"
            :key="item.clerk_id"
          >
            <avatar :userInfo="item" :size="30" :distanceRight="10"></avatar>
            <p class="name e">{{ item.clerk_alias || item.clerk_name }}</p>
            <!-- 准备一个删除按钮 -->
            <button
              class="remove-member-btn"
              type="button"
              @click="cancelChoose([item.clerk_id])"
            >
              <span class="iconfont icon-Delete"></span>
            </button>
          </div>
          <div
            class="member-item flex flex-y-center"
            v-for="item in leaveClerkList"
            :key="item.clerk_id"
          >
            <avatar :userInfo="item" :size="30" :distanceRight="10"></avatar>
            <p class="name e">{{ item.clerk_alias || item.clerk_name }}</p>
            <!-- 准备一个删除按钮 -->
            <button
              class="remove-member-btn"
              type="button"
              @click="cancelMore([item.clerk_id])"
            >
              <span class="iconfont icon-Delete"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">返回</el-button>
      <el-button type="primary" @click="confirmAddMember">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Avatar from './Avatar.vue'
import MemberTreeByDept from './MemberTreeByDept.vue'
export default {
  name: 'add-member-by-dept',
  components: {
    Avatar,
    MemberTreeByDept
  },
  props: {
    // 是否展示这个弹窗
    isShowAddMember: {
      type: Boolean,
      default: false
    },
    // 树形用户数据
    dataList: {
      type: Array
    },
    // 已有成员
    memberList: {
      type: Array
    },
    // 标题
    modalTitle: {
      type: String,
      default: '选择成员'
    },
    // 是否要做部门全选
    isShowCheckAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 搜索输入的文字
      searchName: '',
      // 搜索结果
      searchResultList: [],
      searchAntiShare: null,
      // 记录选择的id集合
      selectIdList: [],
      // 记录选择的成员集合
      toSelectList: [],
      memberListTmp: []
    }
  },
  computed: {
    // 把树形的职员列表转成一维数组
    clerkList() {
      const list = []
      const idList = []
      const fn = (n) => {
        if (n.clerk_list) {
          n.clerk_list.forEach((e) => {
            if (!list.includes(e.clerk_id)) {
              idList.push(e.clerk_id)
              list.push(e)
            }
          })
        }
        if (n.children) {
          n.children.forEach((e) => {
            fn(e)
          })
        }
      }
      this.dataList.forEach((e) => {
        fn(e)
      })
      return list
    },
    // 把职员列表数组转成map对象，id与对象本身对应
    clerkIdMap() {
      const map = {}
      this.clerkList.forEach((e) => {
        map[e.clerk_id] = e
      })
      return map
    },
    // 职员id集合
    clerkIdList() {
      return this.clerkList.map((e) => e.clerk_id)
    },
    // 已选择的人的对象数组（根据已选id和id与对象的对应关系来得到）
    selectList() {
      const list = []
      this.selectIdList.forEach((e) => {
        if (this.clerkIdMap[e]) {
          list.push(this.clerkIdMap[e])
        }
      })
      return list
    },
    leaveClerkList() {
      return (
        this.memberListTmp.filter(
          (e) =>
            this.clerkIdMap[e.clerk_id] == null &&
            this.selectIdList.includes(e.clerk_id)
        ) || []
      )
    }
  },
  watch: {
    // 一显示弹窗，就初始化
    isShowAddMember(val) {
      if (val) {
        this.init()
      }
    }
  },
  mounted() {
    this.searchAntiShare = this.$util.antiShare(this.searchClerkList)
    this.init()
  },
  methods: {
    // 初始化
    init() {
      if (this.isShowCheckAll) {
        this.memberListTmp = [...this.memberList]
        this.selectIdList = this.memberList.map((item) => item.clerk_id)
      }
    },
    // 点击关闭按钮
    handleClose() {
      this.closeModal()
    },
    // 关闭按钮
    closeModal() {
      this.clearSearch()
      this.$emit('close-add-member')
    },
    // 准备添加成员
    toAddMember(clerkInfo) {
      this.$emit('to-add-member', clerkInfo)
    },
    // 确定要添加成员
    confirmAddMember() {
      // 把已选的信息列表和id列表一起传递出去
      this.$emit('confirm-add-member', {
        list: this.selectList,
        idList: this.selectIdList
      })
      // this.closeModal()
    },
    // 准备删除某个成员
    removeMember(id) {
      this.$emit('ready-remove-member', id)
    },
    // 清空搜索
    clearSearch() {
      this.searchName = ''
      this.searchResultList = []
    },
    // 防抖搜索
    triggerSearch() {
      this.searchAntiShare()
    },
    // 搜索同事
    searchClerkList() {
      const val = this.searchName.trim()
      if (val.length > 0) {
        this.$util.searchClerkList(val).then((res) => {
          if (res) {
            console.log('搜索结果：', res)
            this.searchResultList = res.map((item) => {
              this.$set(item, 'checked', false)
              this.selectIdList.forEach((it) => {
                if (it === item.clerk_id) {
                  this.$set(item, 'checked', true)
                }
              })
              return item
            })
          }
        })
      }
    },
    // 去添加多个成员
    toAddManyMembers(clerkList) {
      this.$emit('to-add-members', clerkList)
    },
    // 去删除多个成员
    toRemoveManyMembers(clerkList) {
      this.$emit('to-remove-member', clerkList)
    },
    // 选择部门或职员
    chooseMore(clerkIdList) {
      // 拿到子组件传递来的值，把原先并没有选择的选择一下
      clerkIdList.forEach((e) => {
        if (!this.selectIdList.includes(e)) {
          this.selectIdList.push(e)
        }
      })
      // this.$emit('to-add-members', clerkList)
    },
    // 取消选择部门或职员
    cancelMore(clerkIdList) {
      // 拿到子组件传递来的值，在已选的id中删掉这些
      clerkIdList.forEach((e) => {
        if (this.selectIdList.includes(e)) {
          this.selectIdList.splice(this.selectIdList.indexOf(e), 1)
        }
      })
      // this.$emit('to-remove-member', clerkList)
    },
    // 搜索后出现的列表，复选框选中和取消选中后执行的操作
    changeChecked(value, clerkIdList) {
      if (value) {
        this.chooseMore(clerkIdList)
      } else {
        this.cancelMore(clerkIdList)
      }
    },
    cancelChoose(clerkIdList) {
      this.searchResultList.forEach((item) => {
        clerkIdList.forEach((it) => {
          if (it === item.clerk_id) {
            this.$set(item, 'checked', false)
          }
        })
        return item
      })
      this.cancelMore(clerkIdList)
    }
    // 复选框被点击后，接收到的值
    // checkItem({ obj, val }) {
    //   const isDept = obj.dept_name && obj.dept_name.length > 0
    //   // 假如现在是选择，那么就判断是员工还是部门
    //   if (val) {
    //     // 然后判断是否有子部门，如果有，把所有子部门的员工列表都选择了
    //     if (isDept) {
    //       // 假如现在选择的是部门，那么先把这个部门的员工列表选择一下
    //       for (const clerk of obj.clerk_list) {
    //         if (!this.selectIdList.includes(clerk.clerk_id)) {
    //           this.toSelectList.push(clerk)
    //           this.selectIdList.push(clerk.clerk_id)
    //         }
    //       }
    //     } else {
    //       // 假如是员工，那么先把这个员工选择一下
    //       if (!this.selectIdList.includes(obj.clerk_id)) {
    //         this.toSelectList.push(obj)
    //         this.selectIdList.push(obj.clerk_id)
    //       }
    //       // 然后判断，当前部门的员工是否都选中了，是就设置部门选中
    //     }
    //   } else {
    //     // 假如现在是取消选择
    //     if (isDept) {
    //       // 它自己的取消选中
    //       // 如果是取消了部门的选择，那么部门里的所有员工都要取消选择
    //       // 它的父级取消选中
    //     } else {
    //       // 取消选中这个员工
    //       // 让他所在的部门取消选中
    //     }
    //   }
    //   console.log('复选框被点击了，数据是', obj)
    // }
  }
}
</script>

<style lang="scss" scope></style>

<style lang="scss">
@import '@/assets/scss/choose_member_style.scss';
@import '@/assets/scss/add_member_style.scss';
</style>
