<!--
 * @Author: hzh
 * @Date: 2021-12-17 11:59:24
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-30 17:26:32
-->
<template>
  <div class="report-box flex flex-y-positive">
    <template v-if="!isXs">
      <el-tabs v-model="currentTab">
        <el-tab-pane label="个人周报" name="clerk"></el-tab-pane>
        <el-tab-pane
          label="部门周报"
          name="dept"
          v-if="myClerkInfo.is_leader"
        ></el-tab-pane>
      </el-tabs>
    </template>
    <div class="content-box flex1 flex flex-y-positive">
      <work-report-week-clerk
        ref="workReportWeekClerk"
        class="flex1"
        v-show="currentTab === 'clerk'"
      />
      <work-report-week-dept
        ref="workReportWeekDept"
        class="flex1"
        v-if="myClerkInfo.is_leader"
        v-show="currentTab === 'dept'"
      />
    </div>
    <template v-if="isXs">
      <div class="tab-box flex">
        <div
          class="
            tab-item
            flex1
            flex flex-y-positive flex-y-center flex-x-center
          "
          :class="{ active: currentTab === 'clerk' }"
          @click="currentTab = 'clerk'"
        >
          <i class="icon-geren"></i>
          <span class="text">个人周报</span>
        </div>
        <div
          class="
            tab-item
            flex1
            flex flex-y-positive flex-y-center flex-x-center
          "
          v-if="myClerkInfo.is_leader"
          :class="{ active: currentTab === 'dept' }"
          @click="currentTab = 'dept'"
        >
          <i class="icon-bumen"></i>
          <span class="text">部门周报</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import WorkReportWeekClerk from './components/work-report-week-clerk'
import WorkReportWeekDept from './components/work-report-week-dept'
import { mapState, mapGetters } from 'vuex'

import store from '@/store'

const TAB_OPTION = ['clerk', 'dept']

export default {
  components: {
    WorkReportWeekClerk,
    WorkReportWeekDept
  },
  data() {
    return {
      currentTab: 'clerk'
    }
  },
  computed: {
    ...mapState(['myClerkId', 'myClerkInfo']),
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl'])
  },
  methods: {},
  mounted() {},
  beforeRouteEnter(to, from, next) {
    const toName = to.name
    const tab = to.params.tab
    const myClerkId = `${store.state.myClerkId}`
    const clerkReportId = to.params.clerkReportId
    // 没有tab默认个人
    if (tab == null) {
      next({
        name: toName,
        params: {
          tab: 'clerk',
          clerkReportId: myClerkId
        }
      })
      return
    }
    // tab不在选项里
    if (!TAB_OPTION.includes(tab)) {
      next({
        name: toName,
        params: {
          tab: 'clerk',
          clerkReportId: myClerkId
        }
      })
      return
    }
    // 个人
    if (tab === 'clerk') {
      // 没有职员报告id 默认自己的职员id
      if (clerkReportId == null) {
        next({
          name: toName,
          params: {
            tab: 'clerk',
            clerkReportId: myClerkId
          }
        })
        return
      }
      const hasReportId = clerkReportId.indexOf('_') !== -1
      const clerkId = clerkReportId.split('_')[0]
      // 没有报告id 并且职员不是自己 设置职员id为自己
      if (!hasReportId && clerkId !== myClerkId) {
        next({
          name: toName,
          params: {
            tab: 'clerk',
            clerkReportId: myClerkId
          }
        })
        return
      }
      // 有报告id 但是职员不是自己 跳转到独立页
      if (hasReportId && clerkId !== myClerkId) {
        next({
          name: 'workReportWeekDetail',
          params: {
            clerkReportId: clerkReportId
          }
        })
        return
      }
    }
    // 部门
    if (tab === 'dept') {
      if (clerkReportId != null) {
        next({
          name: 'workReportWeekDetail',
          params: {
            clerkReportId: clerkReportId
          }
        })
        return
      }
    }
    next()
  },
  beforeRouteUpdate(to, from, next) {
    const toName = to.name
    const tab = to.params.tab
    const myClerkId = `${store.state.myClerkId}`
    const clerkReportId = to.params.clerkReportId
    // 没有tab默认个人
    if (tab == null) {
      next({
        name: toName,
        params: {
          tab: 'clerk',
          clerkReportId: myClerkId
        }
      })
      return
    }
    // tab不在选项里
    if (!TAB_OPTION.includes(to.params.tab)) {
      next({
        name: toName,
        params: {
          tab: 'clerk',
          clerkReportId: myClerkId
        }
      })
      return
    }
    if (tab === 'clerk') {
      // 没有职员报告id 默认自己的职员id
      if (clerkReportId == null) {
        next({
          name: toName,
          params: {
            tab: 'clerk',
            clerkReportId: myClerkId
          }
        })
        return
      }
      const hasReportId = clerkReportId.indexOf('_') !== -1
      const clerkId = clerkReportId.split('_')[0]
      // 没有报告id 并且职员不是自己 设置职员id为自己
      if (!hasReportId && clerkId !== myClerkId) {
        next({
          name: toName,
          params: {
            tab: 'clerk',
            clerkReportId: myClerkId
          }
        })
        return
      }
      // 有报告id 但是职员不是自己 跳转到独立页
      if (hasReportId && clerkId !== myClerkId) {
        next({
          name: 'workReportWeekDetail',
          params: {
            clerkReportId: clerkReportId
          }
        })
        return
      }
    }
    next()
  },
  watch: {
    currentTab(val) {
      if (val === 'clerk') {
        this.$router.push({
          name: this.$route.name,
          params: {
            tab: 'clerk',
            clerkReportId: `${this.myClerkId}_${this.$refs.workReportWeekClerk.routeReportId}`
          }
        })
      } else {
        this.$router.push({
          name: this.$route.name,
          params: {
            tab: 'dept'
          }
        })
      }
    },
    $route: {
      handler(val) {
        const tab = val.params.tab
        this.currentTab = tab
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.report-box {
  min-height: 100%;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .report-box {
    padding-bottom: 55px;
    // height: 100vh;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
  }
  .tab-box {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 55px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -1px 1px 0px rgba(31, 35, 41, 0.1);
    .tab-item {
      color: #999;
      i {
        font-size: 22px;
      }
      .text {
        margin-top: 5px;
      }
      &.active {
        color: #2362fb;
      }
    }
  }
  .content-box {
    height: 100%;
  }
}
</style>
