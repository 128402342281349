<template>
  <message-box
    :title="`${isEdit ? '修改' : '添加'}管理员`"
    top="8%"
    :mask="true"
    @handleClose="$emit('close')"
  >
    <div class="admin-message-box">
      <!-- 管理员搜索 -->
      <div class="admin-search">
        <div class="admin-label">管理员</div>
        <el-autocomplete
          popper-class="search-input"
          size="medium"
          v-model="clerkName"
          :fetch-suggestions="querySearch"
          placeholder="请输入姓名/别名"
          :disabled="isEdit"
          @select="handleSelect"
        >
          <i class="el-icon-search el-input__icon" slot="prefix"> </i>
          <template #default="{ item }">
            <div class="flex flex-y-center result-item">
              <el-image :src="item.clerk_avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <span class="name">{{
                item.clerk_alias || item.clerk_name
              }}</span>
              <span class="type">{{ item.main_dept_name }}</span>
            </div>
          </template>
        </el-autocomplete>
      </div>
      <!-- 权限配置 -->
      <div class="admin-set">
        <div class="admin-label">权限配置</div>
        <div class="permission-group">
          <div class="title line-bottom">权限组</div>
          <div class="check-group">
            <el-checkbox-group v-model="checkPerGroupList">
              <el-checkbox
                v-for="item in perGroupList"
                :key="item.per_group_id"
                :label="item.per_group_id"
                >{{ item.per_group_name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="premission-list">
          <div class="title">权限</div>
          <div
            class="group-table"
            v-for="(item, index) in perTree"
            :key="index"
          >
            <div class="table-title">{{ item.name }}</div>
            <el-tree
              show-checkbox
              default-expand-all
              :expand-on-click-node="false"
              :data="item.children"
              node-key="id"
              :ref="`perTreeRef_${index}`"
            >
              <template #default="{ data }">
                <span class="table-item">
                  {{ data.name }}
                </span>
              </template>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="box-footer flex flex-x-end" style="padding: 20px 0">
        <el-button size="small" @click="$emit('close')">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </div>
    </template>
  </message-box>
</template>

<script>
import MessageBox from '@/components/common/MessageBox.vue'
import { perTree as perTreeData } from '@/util/permission.js'
export default {
  components: {
    MessageBox
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      perTreeData: perTreeData,
      clerkName: '',
      myClerkInfo: {},
      checkPerGroupList: [],
      perGroupList: []
    }
  },
  computed: {
    // 是否为修改
    isEdit() {
      return this.info.clerk_id != null
    },
    // 权限树处理禁用状态
    perTree() {
      const temp = [...this.perTreeData]
      const check = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            this.$set(e, 'disabled', this.checkGroupTreeIdList.includes(e.id))
          }
          if (e.children) {
            check(e.children)
          }
        })
      }
      check(temp)
      return temp
    },
    perTreeMap() {
      const map = {}
      const add = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            map[e.id] = {
              id: e.id,
              name: e.name,
              per_list: e.perList
            }
          }
          if (e.children) {
            add(e.children)
          }
        })
      }
      this.perTreeData.forEach((e) => {
        add(e.children)
      })
      return map
    },
    perTreeList() {
      const l = []
      const add = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            l.push({
              id: e.id,
              name: e.name,
              per_list: e.perList
            })
          }
          if (e.children) {
            add(e.children)
          }
        })
      }
      this.perTreeData.forEach((e) => {
        add(e.children)
      })
      return l
    },
    perGroupMap() {
      const map = {}
      this.perGroupList.forEach((e) => {
        map[e.per_group_id] = {
          ...e,
          treeKeyList: this.getPerList(e.per_id_list).map((e) => e.id)
        }
      })
      return map
    },
    // 已经选择的权限组 权限id列表
    checkGroupPerIdList() {
      let list = []
      this.checkPerGroupList.forEach((e) => {
        list = list.concat(this.perGroupMap[e].per_id_list)
      })
      const set = [...new Set(list)]

      return set
    },
    // 已经选择的权限组 权限树id列表
    checkGroupTreeIdList() {
      let list = []
      this.checkPerGroupList.forEach((e) => {
        list = list.concat(this.perGroupMap[e].treeKeyList)
      })
      const set = [...new Set(list)]

      return set
    }
  },
  methods: {
    // list里是否包含checkList
    checkAllIncludesList(list, checkList) {
      if (list.length === 0 || checkList.length === 0) {
        return false
      }
      for (const e of checkList) {
        if (!list.includes(e)) {
          return false
        }
      }
      return true
    },
    async querySearch(quertString, cb) {
      if (quertString === '') {
        const empty = []
        cb(empty)
        return
      }
      const list = await this.$util.searchClerkList(quertString)
      cb(list)
    },
    handleSelect(item) {
      this.clerkName = item.clerk_alias || item.clerk_name
      this.myClerkInfo = item
    },
    confirm() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (this.myClerkInfo.clerk_id == null) {
        errmsg('请选择职员')
        return
      }
      // 权限
      let perList = []
      this.perTree.forEach((e, i) => {
        this.$refs[`perTreeRef_${i}`][0].getCheckedKeys().forEach((ee, ii) => {
          if (this.perTreeMap[ee]) {
            perList = perList.concat(this.perTreeMap[ee].per_list || [])
          }
        })
      })
      this.$emit('confirm', {
        clerk_id: this.myClerkInfo.clerk_id,
        per_list: perList,
        per_group_list: this.checkPerGroupList
      })
    },
    // 获取权限组列表
    async getPerGroupList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkPerGroupSelectList, {})
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.perGroupList = res.list
      }
    },
    // 获取包含的列表
    getPerList(list) {
      if (list.length === 0) {
        return []
      }
      return this.perTreeList.filter((e) => {
        console.log(e)
        const perList = e.per_list
        if (perList.length > list.length) {
          return false
        }

        for (const per of perList) {
          if (!list.includes(per)) {
            return false
          }
        }
        return true
      })
    }
  },
  async mounted() {
    await this.getPerGroupList()
    if (this.info.clerk_id != null) {
      this.myClerkInfo = this.info
      this.clerkName = this.info.clerk_name
      this.checkPerGroupList = this.info.per_group_id_list
      const perList = this.getPerList(this.info.per_id_list).map((e) => e.id)
      this.perTree.forEach((e, i) => {
        this.$refs[`perTreeRef_${i}`][0].setCheckedKeys(perList)
      })
    }
  },
  watch: {
    checkPerGroupList(val, old) {
      let deleteList = []
      let addList = []
      // 计算删除的treeKey列表
      old.forEach((e) => {
        if (this.perGroupMap[e]) {
          deleteList = deleteList.concat(this.perGroupMap[e].treeKeyList)
        }
      })
      // 计算添加的treeKey列表
      val.forEach((e) => {
        if (this.perGroupMap[e]) {
          addList = addList.concat(this.perGroupMap[e].treeKeyList)
        }
      })
      this.perTree.forEach((pe, i) => {
        const check = (l) => {
          l.forEach((ce) => {
            if (ce.perList) {
              if (deleteList.includes(ce.id)) {
                this.$refs[`perTreeRef_${i}`][0].setChecked(ce.id, false)
              }
              if (addList.includes(ce.id)) {
                this.$refs[`perTreeRef_${i}`][0].setChecked(ce.id, true)
              }
            }
            if (ce.children) {
              check(ce.children)
            }
          })
        }
        check(pe.children)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-message-box {
  height: 644px;
  padding: 14px 0 0;
  overflow: scroll;

  .admin-label {
    margin-bottom: 10px;
  }

  .el-autocomplete::v-deep {
    margin: 0 0 24px;
    width: 88%;
    .el-input__inner {
      width: 100%;
      height: 50px;
      line-height: 50px;
      border: 1px solid #eee;
    }
  }

  .permission-group {
    border: 1px solid #eee;
    padding: 0 24px;
    margin-bottom: 20px;
    .title {
      height: 49px;
      line-height: 49px;
      &::after {
        left: 0;
      }
    }
    .check-group {
      height: 65px;
      line-height: 65px;
    }
  }

  .premission-list {
    border: 1px solid #eee;
    height: 100%;
    .title {
      height: 49px;
      line-height: 49px;
      padding: 0 24px;
    }

    .group-table {
      .table-title {
        height: 42px;
        line-height: 42px;
        padding: 0 24px;
        color: #999999;
        background-color: #f6f6f6;
      }

      .el-tree::v-deep {
        .el-icon-caret-right {
          display: none;
        }
        .el-tree-node__content {
          height: 42px;
          padding-left: 24px !important;
        }

        .el-tree-node__children {
          .el-tree-node__content {
            padding-left: 42px !important;
          }
        }
      }
    }
  }
}

.result-item {
  height: 55px;
  .el-image {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    margin-right: 16px;
  }

  .name {
    margin-right: 20px;
  }

  .type {
    color: #bbb;
  }
}

.box-footer {
  .el-button {
    width: 80px;
  }
}
</style>
