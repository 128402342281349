import { render, staticRenderFns } from "./MemberMessageDel.vue?vue&type=template&id=4250b50a&scoped=true&"
import script from "./MemberMessageDel.vue?vue&type=script&lang=js&"
export * from "./MemberMessageDel.vue?vue&type=script&lang=js&"
import style0 from "./MemberMessageDel.vue?vue&type=style&index=0&id=4250b50a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4250b50a",
  null
  
)

export default component.exports