<!--
 * @Author: hzh
 * @Date: 2021-08-06 15:52:04
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-07 15:09:59
-->
<template>
  <div>
    <draggable
      v-model="imageListTemp"
      draggable=".img-content"
      class="flex img-container"
    >
      <div
        class="img-content"
        v-for="(item, index) in imageListTemp"
        :key="index"
      >
        <img :src="item.url" alt="" />
        <i class="icon-decline delete-button" @click="onDelte(index)"></i>
      </div>
      <el-upload
        action="#"
        :show-file-list="false"
        multiple
        :on-change="imageChange"
        :auto-upload="false"
      >
        <div
          class="add-content"
          v-if="imageListTemp.length > 0 && imageListTemp.length < 9"
        >
          <i class="icon-add"></i>
        </div>
      </el-upload>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
export default {
  components: {
    Draggable
  },
  props: {
    imageList: {
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    imageListTemp: {
      get() {
        return this.imageList
      },
      set(val) {
        this.$emit('editSort', val)
      }
    }
  },
  methods: {
    onDelte(index) {
      this.$emit('onDelte', index)
    },
    imageChange(file, fileList) {
      this.$emit('imageChange', file.raw)
    }
  }
}
</script>

<style lang="scss" scoped>
.img-container {
  flex-wrap: wrap;

  .img-content {
    position: relative;
    margin-bottom: 20px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      margin-right: 20px;
    }

    .delete-button {
      position: absolute;
      padding: 6px;
      color: $main-color;
      font-weight: 400;
      font-size: 14px;
      background: #e6f3ff;
      border-radius: 0 5px 0 5px;
      right: 20px;
      z-index: 2;
    }
  }

  .add-content {
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 6px;
    margin-right: 20px;
    text-align: center;
    border: 1px solid #eee;

    i {
      font-size: 32px;
      color: #ddd;
    }
  }
}

@media screen and (max-width: 767px) {
  .img-container {
    .img-content {
      img {
        width: 90px;
        height: 90px;
      }
    }

    .add-content {
      width: 90px;
      height: 90px;
      line-height: 90px;
    }
  }
}
</style>
