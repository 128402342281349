<!--
 * @Date: 2021-06-17 17:05:35
 * @LastEditTime: 2021-08-17 21:34:55
 * @Description: 滚动刷新组件
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div
    id="scroll-container"
    @scroll="getScroll"
    :class="[className]"
    class="content-box"
  >
    <slot></slot>
    <p v-if="page >= lastPage && showNoMore" class="no-more-tip">
      没有更多数据了
    </p>
    <div
      @click="handleBackTop"
      v-if="scroll > 800 && showBackTop"
      class="icon-dingbu back-top"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'scroll-fresh',
  props: {
    // 类名
    className: {
      type: String,
      default: 'data-box'
    },
    // 数据的名字，方便找到谁是谁
    dataName: {
      type: String
    },
    // 当前查看第几页
    page: {
      type: Number,
      default: 1
    },
    // 数据总共有几页
    lastPage: {
      type: Number
    },
    // 展示没有更多
    showNoMore: {
      type: Boolean,
      default: true
    },
    // 显示回顶部按钮
    showBackTop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrollBottom: -10,
      scroll: 0
    }
  },
  watch: {
    // 这里监听 scrollBottom 等于0 触发获取数据的方法 否则不触发
    scrollBottom(val) {
      // console.log('滚动', val)
      // console.log('我是watch监听的距离底部的距离', val)
      if (Math.floor(val) !== 0) return false
      // console.log('滚动刷新准备启动了，当前是第几页', this.page)
      if (this.lastPage > 0) {
        // 判断当前页数大于最后一页 return false 加载完毕
        if (this.page >= this.lastPage) return false
      }
      // 通知父组件加载数据
      this.$emit('get-next-page', {
        id: this.dataName,
        page: this.page + 1
      })
    }
  },
  mounted() {
    // this.getCartMusics()
    // 首次加载页面的时候给列表元素添加 滚动的事件监听
    document
      .querySelector(`.${this.className}`)
      .addEventListener('scroll', this.hanleScroll, true)
  },
  methods: {
    handleBackTop() {
      const t = setInterval(() => {
        // 如果已经回到0了，就清空定时器
        if (document.querySelector(`.${this.className}`).scrollTop < 20) {
          document.querySelector(`.${this.className}`).scrollTop = 0
          clearInterval(t)
          return
        }
        // 每次滚回之前的五分之一
        document.querySelector(`.${this.className}`).scrollTop -=
          document.querySelector(`.${this.className}`).scrollTop / 5
      }, 5)
    },
    getScroll(e) {
      this.scroll = e.target.scrollTop
    },
    hanleScroll() {
      const { scrollTop, clientHeight, scrollHeight } = document.querySelector(
        `.${this.className}`
      )
      // console.log('滚动条距离顶部的距离', scrollTop)
      // console.log('监听的元素的高度', clientHeight)
      // console.log('监听元素的滚动条的高度', scrollHeight)
      // 每次这个元素滚动的时候，就记录一下“还有多少距离滚到底部”
      this.scrollBottom = scrollHeight - clientHeight - scrollTop
    }
  },
  beforeDestroy() {
    if (document.querySelector(`.${this.className}`)) {
      document
        .querySelector(`.${this.className}`)
        .removeEventListener('scroll', this.hanleScroll, true)
    }
  }
}
</script>
<style lang="scss">
.no-more-tip {
  padding: 20px 0 0;
  text-align: center;
  line-height: 1.5;
  color: #999;
}

#scroll-container {
  overscroll-behavior: contain;
  -ms-scroll-chaining: contain;
}

.back-top {
  cursor: pointer;
  position: fixed;
  margin-left: -90px;
  bottom: 50px;
  font-size: 28px !important;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  text-align: center;
  color: #bbbbbb;
  border-radius: 6px;
}
</style>
