<!--
 * @Date: 2021-06-01 10:01:35
 * @LastEditTime: 2021-11-27 15:07:30
 * @Description: 选择人员（带多选）
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <!-- 选择成员弹窗 -->
  <el-dialog
    class="add-member"
    :title="modalTitle"
    :modal="true"
    :visible.sync="isShowAddMember"
    width="600"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <!-- 这个是弹窗内容 -->
    <div class="add-member-box flex">
      <!-- 左边 -->
      <div class="flex1 left-box">
        <!-- 查找联系人的弹窗 -->
        <div class="search-box flex flex-y-center flex-x-between">
          <input
            type="text"
            class="flex1 input"
            v-model="searchName"
            placeholder="查找联系人"
            @input="triggerSearch"
          />
          <!-- 清空按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length > 0"
            class="clear-btn"
            @click="clearSearch"
          >
            <span class="iconfont icon-decline"></span>
          </button>
          <!-- 搜索按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length === 0"
            class="search-btn"
            @click="searchClerkList"
          >
            <span class="iconfont icon-search"></span>
          </button>
        </div>
        <!-- 数据区域 -->
        <div class="tree-list">
          <!-- 使用树形组件，显示树形结构 -->
          <template v-if="searchName.trim().length === 0">
            <div v-for="item in dataList" :key="item.id">
              <member-tree-and-dept
                :treeNode="item"
                @to-add-members="chooseMore($event)"
                @to-remove-member="cancelMore($event)"
                :selectClerkIdList="selectClerkIdList"
                :selectDeptIdList="selectDeptIdList"
                :disableClerkIdList="disableClerkIdList"
                :disableDeptIdList="disableDeptIdList"
              ></member-tree-and-dept>
            </div>
          </template>
          <!-- 搜索结果显示在这里 -->
          <template v-else>
            <div class="search-result">
              <div
                class="member-item flex flex-y-center"
                v-for="(clerk, index) in searchResultList"
                :key="index"
                @click="
                  chooseMore({
                    is_dept: false,
                    clerk_id: clerk.clerk_id
                  })
                "
              >
                <avatar
                  :userInfo="clerk"
                  :size="30"
                  :distanceRight="10"
                ></avatar>
                <p class="name e">
                  {{ clerk.clerk_alias || clerk.clerk_name }}
                </p>
              </div>
              <!-- 假如没有搜索结果 -->
              <p class="no-data" v-show="searchResultList.length === 0">
                暂无搜索结果，请换个关键词吧。
              </p>
            </div>
          </template>
        </div>
      </div>
      <!-- 右边 -->
      <div class="flex1 right-box">
        <!-- 显示出已选择的成员 -->
        <p class="member-title">
          已选择（{{ selectClerkIdList.length + selectDeptIdList.length }}）
        </p>
        <!-- 显示成员的地方 -->
        <div class="members-area">
          <!-- 显示出已有成员列表 -->
          <div
            class="member-item flex flex-y-center"
            v-for="item in selectClerkList"
            :key="`c_${item.clerk_id}`"
          >
            <avatar :userInfo="item" :size="30" :distanceRight="10"></avatar>
            <p class="name e">{{ item.clerk_alias || item.clerk_name }}</p>
            <!-- 准备一个删除按钮 -->
            <button
              class="remove-member-btn"
              type="button"
              @click="
                cancelMore({
                  is_dept: false,
                  clerk_id: item.clerk_id
                })
              "
            >
              <span class="iconfont icon-Delete"></span>
            </button>
          </div>
          <div
            class="member-item flex flex-y-center"
            v-for="item in selectDeptList"
            :key="`d_${item.dept_id}`"
          >
            <p class="name e">{{ item.dept_name }}</p>
            <!-- 准备一个删除按钮 -->
            <button
              class="remove-member-btn"
              type="button"
              @click="
                cancelMore({
                  is_dept: true,
                  dept_id: item.dept_id
                })
              "
            >
              <span class="iconfont icon-Delete"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">返回</el-button>
      <el-button type="primary" @click="confirmAddMember">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Avatar from './Avatar.vue'
import MemberTreeAndDept from './MemberTreeAndDept.vue'
export default {
  name: 'add-member-by-dept',
  components: {
    Avatar,
    MemberTreeAndDept
  },
  props: {
    // 是否展示这个弹窗
    isShowAddMember: {
      type: Boolean,
      default: false
    },
    // 树形用户数据
    dataList: {
      type: Array
    },
    // 初始职员
    initialClerkList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 初始部门
    initialDeptList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 标题
    modalTitle: {
      type: String,
      default: '选择成员'
    },
    // 禁用职员id列表
    disableClerkIdList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 禁用部门id列表
    disableDeptIdList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // 搜索输入的文字
      searchName: '',
      // 搜索结果
      searchResultList: [],
      searchAntiShare: null,
      // 记录选择的职员id集合
      selectClerkIdList: [],
      // 记录选择的部门id集合
      selectDeptIdList: [],
      //
      clerkListTmp: [],
      deptListTmp: []
    }
  },
  computed: {
    // 把树形的职员列表转成一维数组
    clerkList() {
      const list = []
      const idList = []
      const fn = (n) => {
        if (n.clerk_list) {
          n.clerk_list.forEach((e) => {
            if (!idList.includes(e.clerk_id)) {
              idList.push(e.clerk_id)
              list.push(e)
            }
          })
        }
        if (n.children) {
          n.children.forEach((e) => {
            fn(e)
          })
        }
      }
      this.dataList.forEach((e) => {
        fn(e)
      })
      return list
    },
    // 把职员列表数组转成map对象，id与对象本身对应
    clerkIdMap() {
      const map = {}
      this.clerkList.forEach((e) => {
        map[e.clerk_id] = e
      })
      return map
    },
    // 职员id集合
    clerkIdList() {
      return this.clerkList.map((e) => e.clerk_id)
    },
    // 部门列表
    deptList() {
      const list = []
      const fn = (n) => {
        list.push(n)
        if (n.children) {
          n.children.forEach((e) => {
            fn(e)
          })
        }
      }
      this.dataList.forEach((e) => {
        fn(e)
      })
      return list
    },
    deptIdMap() {
      const map = {}
      this.deptList.forEach((e) => {
        map[e.dept_id] = e
      })
      return map
    },
    // 职员id集合
    deptIdList() {
      return this.deptList.map((e) => e.dept_id)
    },
    // 已选择的人的对象数组（根据已选id和id与对象的对应关系来得到）
    selectClerkList() {
      const list = []
      this.selectClerkIdList.forEach((e) => {
        if (this.clerkIdMap[e]) {
          list.push(this.clerkIdMap[e])
        }
      })
      return list
    },
    // 已选择的部门的对象数组（根据已选id和id与对象的对应关系来得到）
    selectDeptList() {
      const list = []
      this.selectDeptIdList.forEach((e) => {
        if (this.deptIdMap[e]) {
          list.push(this.deptIdMap[e])
        }
      })
      return list
    }
  },
  watch: {
    // 一显示弹窗，就初始化
    isShowAddMember(val) {
      if (val) {
        this.init()
      }
    }
  },
  mounted() {
    console.log(this.dataList)
    this.searchAntiShare = this.$util.antiShare(this.searchClerkList)
    this.init()
  },
  methods: {
    // 初始化
    init() {
      this.clerkListTmp = [...this.initialClerkList]
      this.deptListTmp = [...this.initialDeptList]
      this.selectClerkIdList = this.initialClerkList.map((e) => e.clerk_id)
      this.selectDeptIdList = this.initialDeptList.map((e) => e.dept_id)
    },
    // 点击关闭按钮
    handleClose() {
      this.closeModal()
    },
    // 关闭按钮
    closeModal() {
      this.clearSearch()
      this.$emit('close-add-member')
    },
    // 确定要添加成员
    confirmAddMember() {
      // 把已选的信息列表和id列表一起传递出去
      this.$emit('confirm-add-member', {
        clerkIdList: this.selectClerkIdList,
        deptIdList: this.selectDeptIdList
      })
      // this.closeModal()
    },
    // 清空搜索
    clearSearch() {
      this.searchName = ''
      this.searchResultList = []
    },
    // 防抖搜索
    triggerSearch() {
      this.searchAntiShare()
    },
    // 搜索同事
    searchClerkList() {
      console.log(this.clerkList)
      const val = this.searchName.trim()
      if (val.length > 0) {
        this.searchResultList = this.clerkList.filter((e) => {
          if (this.disableClerkIdList.includes(e.clerk_id)) {
            return false
          }
          if (e?.clerk_name?.indexOf(val) !== -1) {
            return true
          }
          if (e?.clerk_name_py?.indexOf(val) !== -1) {
            return true
          }
          if (e?.clerk_alias?.indexOf(val) !== -1) {
            return true
          }
          if (e?.clerk_alias_py?.indexOf(val) !== -1) {
            return true
          }
          return false
        })
        // this.$util.searchClerkList(val).then((res) => {
        //   if (res) {
        //     console.log('搜索结果：', res)
        //     this.searchResultList = [...res]
        //   }
        // })
      }
    },
    // 选择部门或职员
    chooseMore({ is_dept: isDept, dept_id: deptId, clerk_id: clerkId }) {
      // 拿到子组件传递来的值，把原先并没有选择的选择一下
      if (isDept) {
        if (!this.selectDeptIdList.includes(deptId)) {
          this.selectDeptIdList.push(deptId)
        }
      } else {
        if (!this.selectClerkIdList.includes(clerkId)) {
          this.selectClerkIdList.push(clerkId)
        }
      }
    },
    // 取消选择部门或职员
    cancelMore({ is_dept: isDept, dept_id: deptId, clerk_id: clerkId }) {
      // 拿到子组件传递来的值，在已选的id中删掉这些
      if (isDept) {
        if (this.selectDeptIdList.includes(deptId)) {
          this.selectDeptIdList.splice(this.selectDeptIdList.indexOf(deptId), 1)
        }
      } else {
        if (this.selectClerkIdList.includes(clerkId)) {
          this.selectClerkIdList.splice(
            this.selectClerkIdList.indexOf(clerkId),
            1
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" scope></style>

<style lang="scss">
@import '@/assets/scss/choose_member_style.scss';
@import '@/assets/scss/add_member_style.scss';
</style>
