<!--
 * @Date: 2021-06-08 11:15:56
 * @LastEditTime: 2021-08-23 14:27:44
 * @Description: 头像组件
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div
    :data-id="'user' + userInfo.clerk_id"
    class="avatar-box"
    :style="{ 'padding-right': distanceRight + 'px' }"
  >
    <el-tooltip
      class="item"
      effect="dark"
      :disabled="!isShowTooltip"
      :content="userInfo.clerk_alias || userInfo.clerk_name"
      placement="top"
    >
    <!-- 自己的头像 -->
      <div
        class="avatar myself"
        :style="{
          width: size + 'px',
          height: size + 'px',
          lineHeight: size + 'px'
        }"
        v-if="userInfo.clerk_id === myClerkId && isShowMe"
      >
        {{myText ? myText : '自己'}}
      </div>
      <!-- 图片头像 -->
      <img
        v-else
        :src="userInfo.clerk_avatar ? $options.filters.httpToHttps(userInfo.clerk_avatar): require('@/assets/img/default_header.jpg')"
        alt="用户头像"
        class="avatar"
        :style="{ width: size + 'px', height: size + 'px' }"
      />
    </el-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'avatar',
  props: {
    // 用户信息
    userInfo: {
      type: Object,
      require: true
    },
    // 头像大小
    size: {
      type: Number,
      default: 34
    },
    // 头像与右边的距离
    distanceRight: {
      type: Number,
      default: 8
    },
    // 是否展示自己的头像
    isShowMe: {
      type: Boolean,
      default: false
    },
    // 是否展示提示
    isShowTooltip: {
      type: Boolean,
      default: false
    },
    // 我头像的文字
    myText: {
      typeof: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['myClerkId'])
  }
}
</script>

<style lang="scss">
.avatar-box {
  display: inline-block;
  vertical-align: middle;
  .avatar {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.myself {
  cursor: default;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: $main-color;
  border-radius: 50%;
  user-select: none;
}
</style>
