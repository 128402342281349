<!--
 * @Author: hzh
 * @Date: 2021-12-22 17:34:06
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-10 11:03:22
-->
<template>
  <el-drawer
    :visible="visible"
    :append-to-body="true"
    :show-close="false"
    :size="isXs ? '100%' : 650"
    :withHeader="false"
    @close="close"
    custom-class="work-report-week-dept-detail"
  >
    <template #default>
      <div class="content-box flex flex-y-positive">
        <div class="header-box flex flex-y-center flex-x-between">
          <div class="left flex flex-y-center">
            <img
              :src="reportData.clerk_avatar"
              alt="用户头像"
              class="avatar"
            /><span class="clerk-name"
              >{{ reportData.clerk_alias || reportData.clerk_name }} / {{ reportData.main_dept_name}}
            </span>
          </div>
          <button type="button" class="close-btn" title="关闭" @click="close">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
          </button>
        </div>
        <div class="tab-box flex flex-y-center">
          <i
            class="iconfont icon-a-arrowaltright1"
            title="前一个"
            :class="{ disabled: prevDisabled}"
            @click="prevIndex"
          ></i>
          <div class="flex1 flex flex-y-center">
            <div
              class="tab-item flex1 flex flex-x-center"
              :class="{ active: currentTab === 'task' }"
              @click="currentTab = 'task'"
            >
              <span class="text">任务</span>
            </div>
            <div
              class="tab-item flex1 flex flex-x-center"
              :class="{ active: currentTab === 'target' }"
              @click="currentTab = 'target'"
            >
              <span class="text">目标</span>
            </div>
            <div
              class="tab-item flex1 flex flex-x-center"
              :class="{ active: currentTab === 'comment' }"
              @click="currentTab = 'comment'"
            >
              <span class="text">
                评论{{ commentCount > 0 ? `(${commentCount})` : '' }}
              </span>
            </div>
          </div>
          <i
            class="iconfont icon-a-arrowaltright"
            title="下一个"
            :class="{ disabled: nextDisabled }"
            @click="nextIndex"
          ></i>
        </div>
        <div class="tab-content-box flex1" :class="[currentTab]">
          <work-report-month-clerk-task
            ref="workReportMonthDeptDetailTask"
            :data="{
              report_data: reportData,
              prev_report_data: prevReportData,
              data_list: taskDataList,
              columnar_list: taskColumnarList
            }"
            :widthType="isXs ? 'xs' : 'sm'"
            v-show="currentTab === 'task'"
          />
          <work-report-month-clerk-target
            ref="workReportMonthDeptDetailTarget"
            :data="{
              report_data: reportData,
              prev_report_data: prevReportData,
              data_list: objectiveDataList,
              columnar_list: objectiveColumnarList
            }"
            :widthType="isXs ? 'xs' : 'sm'"
            v-show="currentTab === 'target'"
          />
          <work-report-comment
            :reportId="currentReportId"
            v-show="currentTab === 'comment'"
          />
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import WorkReportMonthClerkTask from './work-report-month-clerk-task'
import WorkReportMonthClerkTarget from './work-report-month-clerk-target'
import WorkReportComment from './work-report-comment.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'workReportMonthDeptDetail',
  components: {
    WorkReportMonthClerkTask,
    WorkReportMonthClerkTarget,
    WorkReportComment
  },
  props: {
    // 展示
    visible: Boolean,
    reportList: {
      type: Array,
      default: () => []
    },
    specified: Object
  },
  data() {
    return {
      // 当前第几个职员
      currentIndex: 0,
      currentTab: 'task',
      // 图表数据
      taskDataList: [],
      taskColumnarList: [],
      objectiveDataList: [],
      objectiveColumnarList: [],
      reportData: {},
      prevReportData: {},
      // 评论数量
      commentCount: 0
    }
  },
  computed: {
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl']),
    // 周期id
    currentReportId() {
      return this.reportData?.report_id || null
    },
    // 前一个按钮禁用
    prevDisabled() {
      if (this.specified != null) {
        if (
          !this.reportList.find((e) => e.report_id === this.specified.report_id)
        ) {
          return true
        }
      }
      return this.currentIndex === 0
    },
    // 下一个按钮禁用
    nextDisabled() {
      if (this.specified != null) {
        if (
          !this.reportList.find((e) => e.report_id === this.specified.report_id)
        ) {
          return true
        }
      }
      return this.currentIndex === this.reportList.length - 1
    },
    watchVisableIndexObj() {
      return {
        visible: this.visible,
        current_index: this.currentIndex
      }
    }
  },
  methods: {
    // 获取数据
    async getData() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportClerkData, {
          report_id: this.specified.report_id,
          clerk_id: this.specified.clerk_id
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.taskDataList = res.task_data_list
          this.taskColumnarList = res.task_columnar_list
          this.objectiveDataList = res.objective_data_list
          this.objectiveColumnarList = res.objective_columnar_list
          this.reportData = res.report || {}
          this.prevReportData = res.prev_report || {}
          this.commentCount = res.comment_count || 0
          this.renderChart()
        }
      }
    },
    // 刷新数据
    async refreshData() {
      await this.getData()
    },
    // 前一个
    prevIndex() {
      if (this.prevDisabled) {
        return
      }
      this.currentIndex--
    },
    // 下一个
    nextIndex() {
      if (this.nextDisabled) {
        return
      }
      this.currentIndex++
    },
    close() {
      this.$emit('update:visible', false)
    },
    // 渲染图表
    renderChart() {
      switch (this.currentTab) {
        case 'task': {
          this.$refs.workReportMonthDeptDetailTask.renderChart()
          break
        }
        case 'target': {
          this.$refs.workReportMonthDeptDetailTarget.renderChart()
          break
        }
      }
    }
  },
  mounted() {},
  watch: {
    currentTab() {
      this.renderChart()
    },
    currentIndex(val) {
      this.$emit('update:specified', this.reportList[val])
    },
    // 根据传进来的指定报告 获取index
    visible(val) {
      if (val) {
        if (this.specified != null) {
          const index = this.reportList.findIndex(
            (e) => e.report_id === this.specified.report_id
          )
          if (index !== -1) {
            this.currentIndex = index
          }
        }
      }
    },
    watchVisableIndexObj({ visible, current_index: currentIndex }) {
      this.currentTab = 'task'
      if (visible) {
        this.refreshData()
      } else {
        this.taskDataList = []
        this.taskColumnarList = []
        this.objectiveDataLis = []
        this.objectiveColumnarList = []
        this.reportData = {}
        this.prevReportData = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  height: 100%;
}
.header-box {
  height: 75px;
  border-bottom: 1px solid #e5e8ee;
  padding: 0 30px;
  z-index: 10;
  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .close-btn,
  .close-btn .icon {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
}
.tab-box {
  height: 60px;
  box-shadow: 0px 1px 1px 0px rgba(31, 35, 41, 0.1);
  z-index: 10;

  > .iconfont {
    margin: 0 15px;
    width: 20px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    &.disabled {
      color: rgb(223, 223, 223);
      cursor: no-drop;
    }
  }
  .tab-item {
    color: #333333;
    text-align: center;
    font-size: 14px;
    border-right: 2px solid #eee;

    &:last-child {
      border-right: 0;
    }
    .text {
      position: relative;
      cursor: pointer;
    }

    &.active {
      color: #5578eb;
      .text::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -8px;
        left: -5px;
        width: calc(100% + 10px);
        height: 3px;
        background-color: #5578eb;
        border-radius: 2px;
      }
    }
  }
  .el-tabs ::v-deep {
    .el-tabs__header,
    .el-tabs__nav {
      width: 100%;
    }
    .el-tabs__nav-wrap::after {
      content: none;
    }
    .el-tabs__item {
      width: 50px;
      padding: 0 calc((100% - 150px) / 6);
      box-sizing: content-box;
      text-align: center;
    }
    .el-tabs__item.is-top:last-child,
    .el-tabs__item.is-top:nth-child(2) {
      padding: 0 calc((100% - 150px) / 6);
    }
  }
}
.tab-content-box {
  padding: 10px 30px;
  overflow-y: scroll;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .header-box {
    height: 50px;
    padding: 0 15px;
    .avatar {
      width: 26px;
      height: 26px;
    }
    .close-btn,
    .close-btn .icon {
      width: 30px;
      height: 30px;
    }
  }
  .tab-box {
    height: 50px;
  }
  .tab-content-box {
    background-color: #f6f6f6;
    padding: 10px 15px;
    &.comment {
      background-color: #fff;
    }
  }
}
</style>
