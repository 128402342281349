<!--
 * @Author: hzh
 * @Date: 2021-08-27 11:27:13
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-16 18:07:55
-->
<template>
  <!-- 添加导师类型弹窗 -->
  <div
    class="add-type-mask flex flex-x-center flex-y-center"
    @click.stop="$emit('cancle')"
  >
    <div class="add-type flex flex-y-positive flex-y-between" @click.stop="">
      <div class="add-type-title">添加导师类型</div>
      <!-- 输入框 -->
      <p>类型名称<span>*</span></p>
      <el-input v-model="input" placeholder="填写导师类型名称"></el-input>
      <!-- 添加勋章 -->
      <div class="add-medal">
        <div class="add-medal-title flex flex-y-center">
          <div class="medal-title">勋章</div>
          <span>勋章图片仅支持PNG格式，建议像素不低于200*200 px，文件大小不超过500 KB</span>
        </div>
        <ul class="add-medal-box flex flex-x-between">
          <li
            class="flex flex-y-positive flex-x-between flex-y-center"
            v-for="(item, index) in medalType"
            :key="index"
          >
            <el-upload
              action="#"
              :show-file-list="false"
              multiple
              :on-change="trainerImageChange(item.type)"
              accept=".png"
              :auto-upload="false"
            >
              <div
                class="medal-img flex flex-x-center flex-y-center"
                :class="imageUrlInfo[item.type] ? 'hiden_border' : ''"
              >
                <div v-if="imageUrlInfo[item.type]">
                  <img class="image-add-medal" :src="imageUrlInfo[item.type]" />
                  <i
                    class="icon-decline delete-button"
                    @click.stop="onDelte(item.type)"
                  ></i>
                </div>
                <i v-else class="iconfont icon-add"></i>
              </div>
            </el-upload>
            <div class="medal-name">{{ item.medalTitle }}</div>
          </li>
          <!-- <li class="flex flex-y-positive flex-x-between flex-y-center">
            <div class="medal-img flex flex-x-center flex-y-center">
              <i class="iconfont icon-add"></i>
            </div>
            <div class="medal-name">中级勋章</div>
          </li>
          <li class="flex flex-y-positive flex-x-between flex-y-center">
            <div class="medal-img flex flex-x-center flex-y-center">
              <i class="iconfont icon-add"></i>
            </div>
            <div class="medal-name">高级勋章</div>
          </li> -->
        </ul>
      </div>
      <!-- 按钮 -->
      <div class="button-box flex flex-x-end">
        <button id="add" @click.stop="confirm">添 加</button>
        <el-button @click.stop="$emit('cancle')">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'knowledge-add-type',
  components: {},
  data() {
    return {
      input: '',
      medalType: [
        {
          medalTitle: '初级勋章',
          type: 1
        },
        {
          medalTitle: '中级勋章',
          type: 2
        },
        {
          medalTitle: '高级勋章',
          type: 3
        }
      ],
      // 勋章url
      imageUrlInfo: {},
      // 图片file
      imageRawInfo: {}
    }
  },
  methods: {
    confirm() {
      if (this.input === '') {
        this.$message({
          showClose: true,
          message: '请输入内容',
          type: 'error'
        })
        return
      }
      this.$emit('confirm', this.input, this.imageRawInfo)
    }, // 校验图片格式
    beforeAvatarUpload(file) {
      return ['image/png'].includes(file.type)
    },
    // 上传图片
    trainerImageChange(index) {
      const func = (file, fileList) => {
        if (!this.beforeAvatarUpload(file.raw)) {
          this.$message.error('选择图片格式有误，勋章图片仅支持PNG格式')
          return
        }
        if (file.size > 500 * 1024) {
          this.$message.error('图片不可大于500 KB')
          return
        }
        this.$set(this.imageUrlInfo, index, URL.createObjectURL(file.raw))
        this.$set(this.imageRawInfo, index, file.raw)
      }
      return func
    },
    // 删除图片
    onDelte(index) {
      this.$set(this.imageUrlInfo, index, null)
      this.$set(this.imageRawInfo, index, null)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-type-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  .add-type {
    width: 610px;
    height: 574px;
    background: #ffffff;
    border-radius: 4px;
    p {
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      margin: 16px 0 0 26px;
      span {
        font-size: 14px;
        line-height: 21px;
        color: #ff5454;
        margin-left: 6px;
      }
    }
    .add-type-title {
      border-radius: 4px 4px 0 0;
      width: 100%;
      height: 44px;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      box-sizing: border-box;
      padding: 11px 0 0 26px;
      border: 1px solid #eeeeee;
    }
    .add-medal {
      .add-medal-title {
        margin: 42px 0 0 26px;
        .medal-title {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
        span {
          font-size: 12px;
          color: #bbbbbb;
          margin-left: 14px;
        }
      }
      .add-medal-box {
        height: 208px;
        width: 528px;
        margin: 20px 0 0 41px;
        li {
          width: 156px;
          height: 208px;
          .medal-img {
            width: 156px;
            height: 156px;
            border: 1px dashed #dddddd;
            box-sizing: border-box;
            border-radius: 4px;
            position: relative;
            .image-add-medal {
              width: 156px;
              height: 156px;
              border-radius: 4px;
            }
            .delete-button {
              display: inline;
              position: absolute;
              padding: 6px;
              color: $main-color;
              font-weight: 400;
              font-size: 14px;
              border-top-right-radius: 4px;
              background: #e6f3ff;
              right: -1px;
              z-index: 2;
            }
            .icon-add {
              font-size: 50px;
              color: #eeeeee;
            }
          }
          .hiden_border {
            border: none !important;
          }
          .medal-name {
            width: 92px;
            height: 32px;
            background: #0080ff;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 32px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
    .button-box {
      margin: 74px 26px 0 26px;
      height: 32px;
      #add {
        width: 80px;
        height: 32px;
        background: #0080ff;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.button-box::v-deep .el-button {
  margin-left: 10px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  font-size: 14px;
  color: #999999;
  &:hover {
    color: #0080ff;
  }
}
.add-type::v-deep .el-input {
  width: 340px;
  height: 50px;
  margin: 12px 0 0 26px;
  .el-input__inner {
    height: 50px;
  }
}
</style>
