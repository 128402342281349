<template>
  <div class="super-admin">
    <div class="super-admin_header flex flex-x-between flex-y-center">
      <p class="push-tips">权限组包含多个权限，可以同时赋予管理员多个权限</p>
    </div>
    <div class="action-bar flex flex-x-between">
      <el-input
        prefix-icon="el-icon-search"
        style="width: 280px"
        size="small"
        placeholder="请输入权限组名称"
        v-model="searchKey"
        @input="handlerChangeSearchKey"
      ></el-input>
      <el-button
        type="primary"
        size="small"
        @click="addVisible = true"
        v-if="perInfo.add"
        >添加权限组</el-button
      >
    </div>
    <div class="people-list">
      <div class="table-header flex flex-x-between">
        <div style="width: 20%">权限组</div>
        <div class="flex1 text-clamp-1">描述</div>
        <div style="width: 30%">权限</div>
        <div style="width: 120px">操作</div>
      </div>
      <div class="table-main">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="table-item flex flex-x-between flex-y-center"
        >
          <div style="width: 20%">{{ item.per_group_name }}</div>
          <div class="flex flex-y-center flex1">
            {{ item.per_group_describe }}
          </div>
          <div style="width: 30%">
            权限：{{
              getPerList(item.per_id_list)
                .map((e) => e.name)
                .join('、')
            }}
          </div>
          <div style="width: 120px">
            <el-button
              class="edit-button"
              type="text"
              size="mini"
              @click="editId = item.per_group_id"
              v-if="perInfo.edit"
              >编辑</el-button
            >
            <el-button
              class="delete-button"
              style="color: red"
              type="text"
              size="mini"
              v-if="perInfo.delete"
              @click="
                currentDeleteInfo = item
                deleteVisible = true
              "
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; position: absolute; bottom: 22px">
      <el-pagination
        background
        layout="prev, pager, next"
        hide-on-single-page
        @current-change="
          pageNumber = $event
          getPage()
        "
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :page-count="totalPage"
      >
      </el-pagination>
    </div>
    <!-- 添加权限组 -->
    <add-permissions
      v-if="addVisible"
      @close="addVisible = false"
      @confirm="addPerGroup"
    ></add-permissions>
    <!-- 修改权限组 -->
    <add-permissions
      v-if="editId != -1"
      @close="editId = -1"
      @confirm="editPerGroup"
      :info="currentEditInfo"
    ></add-permissions>
    <!-- 删除 -->
    <el-dialog
      :title="`确定要删除 “${currentDeleteInfo.per_group_name}” 权限组吗？`"
      :visible.sync="deleteVisible"
      width="300px"
    >
      <span>权限组删除后，管理员将失去该权限组的管理权限，请谨慎操作！</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deletePerGroup">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddPermissions from './AddPermissions.vue'
import { perTree } from '@/util/permission.js'
export default {
  components: {
    AddPermissions
  },
  data() {
    return {
      perTree: perTree,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
      list: [],
      // 搜索
      searchKey: '',
      searchTimer: null,
      // 添加展示
      addVisible: false,
      // 修改id
      editId: -1,
      // 删除展示
      deleteVisible: false,
      currentDeleteInfo: {}
    }
  },
  computed: {
    perInfo() {
      return {
        add: this.$hasPer(78),
        edit: this.$hasPer(79),
        delete: this.$hasPer(80)
      }
    },
    currentEditInfo() {
      if (this.editId === -1) {
        return {}
      }
      const info = this.list.find((e) => e.per_group_id === this.editId)
      return {
        per_group_id: info.per_group_id,
        per_group_name: info.per_group_name,
        per_id_list: info.per_id_list,
        per_group_describe: info.per_group_describe
      }
    },
    perTreeList() {
      const l = []
      const add = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            l.push({
              id: e.id,
              name: e.name,
              per_list: e.perList
            })
          }
          if (e.children) {
            add(e.children)
          }
        })
      }
      this.perTree.forEach((e) => {
        add(e.children)
      })
      return l
    }
  },
  filters: {},
  methods: {
    // 获取包含的列表
    getPerList(list) {
      if (list.length === 0) {
        return []
      }
      return this.perTreeList.filter((e) => {
        const perList = e.per_list
        if (perList.length > list.length) {
          return false
        }

        for (const per of perList) {
          if (!list.includes(per)) {
            return false
          }
        }
        return true
      })
    },
    // 获取列表
    async getPage() {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        search_key: this.searchKey
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkPerGroupPage, formObj)
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        this.pageNumber = page.pageNumber
        this.totalPage = page.totalPage
        this.totalRow = page.totalRow
        this.list = page.list
      }
    },
    // 刷新列表
    async refreshPage() {
      this.pageNumber = 1
      this.getPage()
    },
    // 监听修改搜索关键字
    handlerChangeSearchKey() {
      if (this.searchTimer != null) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.refreshPage()
      }, 500)
    },
    // 添加
    async addPerGroup({ name, describe, per_list: perList }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addSystemClerkPerGroup, {
          per_ids: perList.join(),
          per_group_name: name,
          per_group_describe: describe
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        this.addVisible = false
        this.refreshPage()
      }
    },
    // 修改
    async editPerGroup({ name, describe, per_list: perList }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editSystemClerkPerGroup, {
          per_group_id: this.editId,
          per_ids: perList.join(),
          per_group_name: name,
          per_group_describe: describe
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
        this.editId = -1
        this.refreshPage()
      }
    },
    // 删除职员
    async deletePerGroup() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemClerkPerGroup, {
          per_group_id: this.currentDeleteInfo.per_group_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.currentDeleteInfo = {}
        this.deleteVisible = false
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        this.refreshPage()
      }
    }
  },
  mounted() {
    this.getPage()
  }
}
</script>

<style lang="scss" scoped>
.super-admin {
  height: 100%;
  .super-admin_header {
    margin-bottom: 20px;
    .push-tips {
      color: #bbb;
    }
  }

  .action-bar {
    margin-bottom: 38px;
    // .el-input::v-deep {
    //   .el-input__inner {
    //     border: 1px solid #eee;
    //   }
    // }
  }

  .people-list {
    .table-header {
      margin-bottom: 15px;
    }
    .table-item {
      height: 66px;

      &:hover {
        background: #f6f6f6;
      }

      .el-image {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 16px;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
      }

      .el-button {
        width: 40px;
        border-radius: 4px;
        &.delete-button:hover {
          background: rgba(255, 84, 84, 0.1);
        }
        &.edit-button:hover {
          background: rgba(0, 128, 255, 0.1);
        }
      }
    }
  }
}
.el-pagination {
  text-align: center;
}
</style>
