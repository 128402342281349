/*
 * @Author: Betty
 * @Date: 2021-07-22 09:34:34
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-15 11:09:26
 * @Description:水印的自定义指令
 */
import Vue from 'vue'

Vue.directive('watermark', (el, binding) => {
  function addWaterMarker(str, parentNode, font, textColor) {
    // 删除id为waterMark的元素
    const mark = document.getElementById('waterMark')
    if (mark) {
      mark.parentNode.removeChild(mark)
    }
    // 水印文字，父元素，字体，文字颜色
    var can = document.createElement('canvas')
    parentNode.appendChild(can)
    const len = str.length < 15 ? 15 : str.length
    can.width = (len * 15) * 2
    can.height = (len * 15) * 2
    can.style.display = 'none'
    var cans = can.getContext('2d')
    cans.rotate(-20 * Math.PI / 180)
    cans.font = font || '32px Microsoft JhengHei'
    // cans.fillStyle = 'rgba(0, 0, 0, 1)'
    cans.fillStyle = textColor || 'rgba(180, 180, 180, 0.2)'
    cans.textAlign = 'left'
    cans.textBaseline = 'Middle'
    cans.fillText(str, 0, can.height / 2)
    // cans.moveTo(0, 0)
    // cans.lineTo(0, can.height)
    // cans.lineTo(can.width, can.height)
    // cans.lineTo(can.width, 0)
    // cans.lineTo(0, 0)
    // cans.stroke()

    // 水印文字，父元素，字体，文字颜色
    // var can2 = document.createElement('canvas')
    // parentNode.appendChild(can2)
    // const len = str.length < 15 ? 15 : str.length
    // can2.width = (len * 20) * 2
    // can2.height = (len * 10) * 2
    // can2.style.display = 'none'
    // var cans2 = can2.getContext('2d')
    // // cans.rotate(-20 * Math.PI / 180)
    // cans2.font = font || '32px Microsoft JhengHei'
    // // cans.fillStyle = 'rgba(0, 0, 0, 1)'
    // cans.fillStyle = textColor || 'rgba(180, 180, 180, 0.2)'
    // cans2.textAlign = 'left'
    // cans2.textBaseline = 'Middle'
    // cans2.fillText(str, 0, can2.height)
    // // cans2.strokeStyle = 'rgba(255, 0, 0,1)'
    // // cans2.moveTo(0, 0)
    // // cans2.lineTo(0, can2.height)
    // // cans2.lineTo(can2.width, can2.height)
    // // cans2.lineTo(can2.width, 0)
    // // cans2.lineTo(0, 0)

    // cans2.stroke()
    // 创建一个存放水印背景的元素，设置样式，并且追加到页面上
    const node = document.createElement('div')
    node.id = 'waterMark'
    node.style.cssText = 'position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1000; pointer-events: none;background-size: ' + (can.width / 2) + 'px ' + (can.height / 2) + 'px;'
    node.style.backgroundImage = 'url(' + can.toDataURL('image/png') + '),url(' + can.toDataURL('image/png') + ')'
    node.style.backgroundPosition = '0 0, left ' + (can.width / 4) + 'px top ' + (can.height / 4) + 'px'
    // node.style.backgroundRepeat = 'no-repeat,no-repeat '
    parentNode.appendChild(node)
    parentNode.removeChild(can)
  }
  addWaterMarker(binding.value.text, el, binding.value.font, binding.value.textColor)
})
