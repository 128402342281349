<template>
  <div class="super-admin">
    <div class="super-admin_header flex flex-x-between flex-y-center">
      <p class="push-tips">超级管理员可以使用管理后台的所有功能</p>
    </div>
    <div class="action-bar flex flex-x-between">
      <el-input
        prefix-icon="el-icon-search"
        style="width: 280px"
        size="small"
        placeholder="请输入姓名/部门查找"
        v-model="searchKey"
        @input="handleChangeSearchKey"
      ></el-input>
      <el-button type="primary" size="small" @click="addVisible = true">添加超级管理员</el-button>
    </div>
    <div class="people-list">
      <div class="table-header flex flex-x-between">
        <div>姓名</div>
        <div style="width: 80px">操作</div>
      </div>
      <div class="table-main">
        <div
          v-for="item in list"
          :key="item.clerk_id"
          class="table-item flex flex-x-between flex-y-center"
        >
          <div class="flex flex-y-center">
            <el-image :src="item.clerk_avatar">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <span
              >{{ item.clerk_alias || item.clerk_name }} /
              <span style="color: #bbb">{{ item.main_dept_name }}</span></span
            >
          </div>
          <div style="width: 100px">
            <div
              class="delete-button"
              v-if="item.admin_type == 2 && item.clerk_id !== myClerkId"
              @click="
                deleteVisible = true
                currentDeleteInfo = item
              "
            >
              <i class="icon-trash1"></i>
              移除
            </div>
            <div v-if="item.admin_type == 3" style="color: #999; padding-left: 10px">系统默认</div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; position: absolute; bottom: 22px">
      <el-pagination
        background
        layout="prev, pager, next"
        hide-on-single-page
        @current-change="
          pageNumber = $event
          getPage()
        "
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :page-count="totalPage"
      >
      </el-pagination>
    </div>
    <add-super-admin v-if="addVisible" @close="addVisible = false" @confirm="addClerk"></add-super-admin>
    <!-- 删除 -->
    <el-dialog
      title="移除超级管理员"
      :visible.sync="deleteVisible"
      width="300px"
    >
      <span
        >确定要将“{{
          currentDeleteInfo.clerk_alias || currentDeleteInfo.clerk_name
        }}”从超级管理员中移除吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteClerk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddSuperAdmin from './AddSuperAdmin.vue'
import { mapState } from 'vuex'
export default {
  components: {
    AddSuperAdmin
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
      list: [],
      // 搜索
      searchKey: '',
      searchTimer: null,
      // 添加展示
      addVisible: false,
      // 删除展示
      deleteVisible: false,
      // 当前删除
      currentDeleteInfo: {}
    }
  },
  computed: {
    ...mapState(['myClerkId'])
  },
  methods: {
    // 获取列表
    async getPage() {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        search_key: this.searchKey
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkAdminSuperPage, formObj)
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        this.pageNumber = page.pageNumber
        this.totalPage = page.totalPage
        this.totalRow = page.totalRow
        this.list = page.list
      }
    },
    // 刷新列表
    async refreshPage() {
      this.pageNumber = 1
      this.getPage()
    },
    // 删除职员
    async deleteClerk() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemClerkSuperAdmin, {
          clerk_id: this.currentDeleteInfo.clerk_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.currentDeleteInfo = {}
        this.deleteVisible = false
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        this.refreshPage()
      }
    },
    // 添加
    async addClerk(e) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addSystemClerkSuperAdmin, {
          clerk_id: e.clerk_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        this.addVisible = false
        this.refreshPage()
      }
    },
    handleChangeSearchKey() {
      if (this.searchTimer != null) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.refreshPage()
      }, 500)
    }
  },
  mounted() {
    this.getPage()
  }
}
</script>

<style lang="scss" scoped>
.super-admin {
  height: 100%;
  .super-admin_header {
    margin-bottom: 20px;
    .push-tips {
      color: #bbb;
    }
  }

  .action-bar {
    margin-bottom: 38px;
    // .el-input::v-deep {
    //   .el-input__inner {
    //     border: 1px solid #eee;
    //   }
    // }
  }

  .people-list {
    .table-header {
      margin-bottom: 15px;
    }
    .table-item {
      height: 66px;

      &:hover {
        background: #f6f6f6;
      }

      .el-image {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 16px;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
      }

      .delete-button {
        cursor: pointer;
        width: 68px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #ff5454;
        border-radius: 4px;

        &:hover {
          background: rgba(255, 84, 84, 0.1);
        }
      }
    }
  }
}

.el-pagination{
  text-align: center;
}
</style>
