<template>
  <message-box
    title="添加超级管理员"
    @handleClose="$emit('close')"
    :mask="true"
  >
    <div class="message-lable">超级管理员</div>
    <div>
      <el-autocomplete
        popper-class="search-input"
        size="medium"
        v-model="clerkName"
        :fetch-suggestions="querySearch"
        placeholder="请输入姓名/别名"
        @select="handleSelect"
      >
        <i class="el-icon-search el-input__icon" slot="prefix"> </i>
        <template #default="{ item }">
          <div class="flex flex-y-center result-item">
            <el-image :src="item.clerk_avatar">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <span class="name">{{ item.clerk_alias || item.clerk_name }}</span>
            <span class="type">{{ item.main_dept_name }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
    <template #footer>
      <div class="box-footer flex flex-x-end" style="padding: 20px 0">
        <el-button size="small" @click="$emit('close')">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </div>
    </template>
  </message-box>
</template>

<script>
import MessageBox from '@/components/common/MessageBox.vue'
export default {
  components: {
    MessageBox
  },
  data() {
    return {
      clerkName: '',
      myClerkInfo: {}
    }
  },
  methods: {
    async querySearch(quertString, cb) {
      if (quertString === '') {
        const empty = []
        cb(empty)
        return
      }
      const list = await this.$util.searchClerkList(quertString)
      cb(list)
    },
    handleSelect(item) {
      this.clerkName = item.clerk_alias || item.clerk_name
      this.myClerkInfo = item
    },
    confirm() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }

      if (this.myClerkInfo.clerk_id == null) {
        errmsg('请输入职员')
        return
      }
      this.$emit('confirm', this.myClerkInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-lable {
  padding: 14px 0 10px;
}

.el-autocomplete::v-deep {
  width: 100%;
  .el-input__inner {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 1px solid #eee;
  }
}

.result-item {
  height: 55px;
  .el-image {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    margin-right: 16px;
  }

  .name {
    margin-right: 20px;
  }

  .type {
    color: #bbb;
  }
}

.box-footer {
  .el-button {
    width: 80px;
  }
}
</style>
