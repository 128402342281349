<template>
  <div class="click main-container">
    <el-row :gutter="25" class="row-a">
      <el-col :span="3" class="col-a">
        <div class="click-roa">
          <div style="height: 40px">
            <span class="span-a">数据分析</span>
          </div>
          <div class="mol-row">
            <!-- :collapse="isCollapse", 默认false, 导航菜单的缩放, router 开启路由 -->
            <el-menu default-active="2" class="el-menu-vertical-demo">
              <el-menu-item index="2" class="menu-roa">
                <i class="el-icon-pie-chart"></i>
                <span slot="title">周报</span>
              </el-menu-item>
              <el-menu-item index="1" class="menu-rob">
                <i class="el-icon-pie-chart"></i>
                <span slot="title">月报</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="click-rob">
          <div id="card-a">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="个人周报" name="first">
                <div class="flex-tk">
                  <el-select v-model="valup" id="select-roa">
                    <el-option
                      v-for="item in optionw"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="selected"
                    placeholder="请选择"
                    id="select-rob"
                  >
                    <el-option
                      label="任务"
                      value="shanghai"
                      @click="kikoi"
                    ></el-option>
                    <el-option
                      label="目标"
                      value="beijing"
                      @click="jijo"
                    ></el-option>
                  </el-select>
                  <el-button
                    plain
                    id="select-roc"
                    @click="drawer = true"
                    type="primary"
                    style="margin-left: 16px"
                  >
                    <i class="el-icon-s-comment"></i>
                    <span>评论</span>
                  </el-button>
                  <el-drawer
                    title="评论消息"
                    :visible.sync="drawer"
                    :with-header="false"
                  >
                    <span>我来啦!</span>
                  </el-drawer>
                </div>
                <div class="flep">
                  <div class="rlep-roa">
                    <div class="flex-roa-a">
                      <span class="flex-rrr-a">总目标</span>
                      <span class="flex-rrr-b">10</span>
                      <span class="flex-rrr-c">较上周期</span>
                    </div>
                    <div class="flex-roa-a">
                      <span class="flex-rrr-a">新增任务</span>
                      <span class="flex-rrr-b">56</span>
                      <span class="flex-rrr-c">较上周期 {{ loj }}</span>
                    </div>
                    <div class="flex-roa-a">
                      <span class="flex-rrr-a"> 延期任务</span>
                      <span class="flex-rrr-b">56</span>
                      <span class="flex-rrr-c">较上周期</span>
                    </div>
                    <div class="flex-roa-a">
                      <span class="flex-rrr-a"> 完成任务</span>
                      <span class="flex-rrr-b">1</span>
                      <span class="flex-rrr-c">较上周期</span>
                    </div>
                  </div>
                  <div class="rleq-rob">
                    <p class="zhiti-li">负责/协同任务占比</p>
                    <div id="containe"></div>
                  </div>
                  <div class="rleq-roc">
                    <p class="zhiti-li">完成进度概况</p>
                    <div id="container"></div>
                  </div>
                </div>
                <div class="rlep-rob">
                  <div class="rlew-rob-a">
                    <p class="zhiti-li">延期任务概况</p>
                    <div id="contain"></div>
                  </div>
                  <div class="rlew-rob-b">
                    <p class="zhiti-li">本月任务概况</p>
                    <div
                      id="containa"
                      style="width: 50%"
                      class="rlew-bbb-a"
                    ></div>
                    <div
                      id="containb"
                      style="width: 50%"
                      class="rlew-bbb-b"
                    ></div>
                  </div>
                </div>
                <div class="rlez-roc">
                  <div class="rlee-roc-a">
                    <p class="zhiti-li">完成进度概况</p>
                    <div id="contaa"></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="部门周报" name="second">
                <div class="loko">
                  <div class="loko-a">
                    <el-select v-model="valup" id="select-roa">
                      <el-option
                        v-for="item in optionw"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="selecteo"
                      placeholder="请选择"
                      id="select-rob"
                    >
                      <el-option label="技术部" value="shanghao-a"></el-option>
                      <el-option label="运营部" value="beijinn-a"></el-option>
                    </el-select>
                  </div>
                  <div class="loko-b">
                    <el-table
                      :data="tableData"
                      style="margin-left: 16px"
                      @click="drawer = true"
                      type="primary"
                      class="loko-v"
                    >
                      <el-table-column prop="mola" label="姓名" width="180">
                      </el-table-column>
                      <el-table-column prop="molb" label="总目标" width="180">
                      </el-table-column>
                      <el-table-column prop="molc" label="完成目标">
                      </el-table-column>
                      <el-table-column prop="mold" label="延期目标">
                      </el-table-column>
                      <el-table-column prop="mole" label="总任务">
                      </el-table-column>
                      <el-table-column prop="molf" label="完成任务">
                      </el-table-column>
                      <el-table-column prop="molg" label="延期任务">
                      </el-table-column>
                      <el-table-column prop="molh" label="终止任务">
                      </el-table-column>
                      <el-table-column prop="moll" label="任务完成率">
                      </el-table-column>
                      <el-table-column prop="molk" label="操作">
                      </el-table-column>
                    </el-table>
                    <el-brawer></el-brawer>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
import { Bar } from '@antv/g2plot'
export default {
  data() {
    return {
      activeName: 'first',
      selected: 'shanghai',
      selecteo: 'shanghao-a',
      valup: '',
      options: [
        {
          value: '1',
          label: '任务'
        },
        {
          value: '2',
          label: '目标'
        }
      ],
      optionw: [
        {
          valup: '1',
          label: '2020:8 - 2020.9'
        },
        {
          valup: '1',
          label: '2020:8 - 2020.9'
        },
        {
          valup: '1',
          label: '2020:8 - 2020.9'
        },
        {
          valup: '1',
          label: '2020:8 - 2020.9'
        }
      ],
      tableData: [
        {
          mola: '黑崎一护',
          molb: '5',
          molc: '0',
          mold: '1',
          mole: '36',
          molf: '15',
          molg: '3',
          molh: '0',
          moll: '41.7%',
          molk: '查看详细'
        }
      ]
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    initComponent() {
      const data = [
        { item: '0-30%', count: 40, percent: 0.4 },
        { item: '31-60%', count: 21, percent: 0.21 },
        { item: '61-99%', count: 17, percent: 0.17 },
        { item: '100%', count: 13, percent: 0.13 }
      ]
      const chart = new Chart({
        container: 'container',
        autoFit: true,
        height: 300
      })
      chart.data(data)
      chart.scale('percent', {
        formatter: (val) => {
          val = val * 100 + '%'
          return val
        }
      })
      chart.coordinate('theta', {
        radius: 0.75,
        innerRadius: 0.6
      })
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      chart
        .interval()
        .adjust('stack')
        .position('percent')
        .color('item', ['#3aa1ff', '#36cbcb', '#4ecb73', '#fbd437'])
        .label('percent', (percent) => {
          return {
            content: (data) => {
              return `${data.item}: ${percent * 100}%`
            }
          }
        })
        .tooltip('item*percent', (item, percent) => {
          percent = percent * 100 + '%'
          return {
            name: item,
            value: percent
          }
        })
      chart.interaction('element-active')
      chart.render()
    },
    components() {
      const data = [{ type: '男性', value: 56.4 }]
      const chart = new Chart({
        container: 'containe',
        autoFit: true,
        height: 300
      })
      chart.data(data)
      chart.legend(false)
      chart.tooltip({
        showMarkers: true
      })
      chart.facet('rect', {
        fields: ['type'],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data
          let color
          if (data[0].type === '男性') {
            color = '#0a9afe'
          } else {
            color = '#f56'
          }
          data.push({ type: '其他', value: 100 - data[0].value })
          view.data(data)
          view.coordinate('theta', {
            radius: 0.8,
            innerRadius: 0.5
          })
          view
            .interval()
            .adjust('stack')
            .position('value')
            .color('type', [color, '#eceef1', '#f56'])
            .style({
              opacity: 1
            })
          view.annotation().text({
            position: ['50%', '50%'],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: '#8c8c8c',
              fontWeight: 300,
              textBaseline: 'bottom',
              textAlign: 'center'
            },
            offsetY: -12
          })
          view.annotation().text({
            position: ['50%', '50%'],
            content: data[0].value,
            style: {
              fontSize: 18,
              fill: '#000',
              fontWeight: 500,
              textAlign: 'center'
            },
            offsetY: 10
          })
          view.interaction('element-active')
        }
      })
      chart.render()
    },
    inComponent() {
      const data = [{ type: '男性', value: 56.4 }]
      const chart = new Chart({
        container: 'contain',
        autoFit: true,
        height: 300
      })
      chart.data(data)
      chart.legend(false)
      chart.tooltip({
        showMarkers: true
      })
      chart.facet('rect', {
        fields: ['type'],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data
          let color
          if (data[0].type === '男性') {
            color = '#0a9afe'
          } else {
            color = '#f0657d'
          }
          data.push({ type: '其他', value: 100 - data[0].value })
          view.data(data)
          view.coordinate('theta', {
            radius: 0.8,
            innerRadius: 0.5
          })
          view
            .interval()
            .adjust('stack')
            .position('value')
            .color('type', [color, '#eceef1'])
            .style({
              opacity: 1
            })
          view.annotation().text({
            position: ['50%', '50%'],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: '#8c8c8c',
              fontWeight: 300,
              textBaseline: 'bottom',
              textAlign: 'center'
            },
            offsetY: -12
          })
          view.annotation().text({
            position: ['50%', '50%'],
            content: data[0].value,
            style: {
              fontSize: 18,
              fill: '#000',
              fontWeight: 500,
              textAlign: 'center'
            },
            offsetY: 10
          })
          view.interaction('element-active')
        }
      })
      chart.render()
    },
    iniComponent() {
      const data = [
        { item: '已完成', count: 32, percent: 0.4 },
        { item: '已终止', count: 9, percent: 0.21 },
        { item: '进行中', count: 41, percent: 0.17 },
        { item: '待启动', count: 16, percent: 0.13 }
      ]
      const chart = new Chart({
        container: 'containa',
        autoFit: true,
        height: 300
      })
      chart.data(data)
      chart.scale('percent', {
        formatter: (val) => {
          val = val * 100 + '%'
          return val
        }
      })
      chart.coordinate('theta', {
        radius: 0.75,
        innerRadius: 0
      })
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      chart
        .interval()
        .adjust('stack')
        .position('percent')
        .color('item', ['#68d388', '#fd578e', '#ffc343', '#dddddd'])
        .label('percent', (percent) => {
          return {
            content: (data) => {
              return `${data.item}: ${percent * 100}%`
            }
          }
        })
        .tooltip('item*percent', (item, percent) => {
          percent = percent * 100 + '%'
          return {
            name: item,
            value: percent
          }
        })
      chart.interaction('element-active')
      chart.render()
    },
    inioComponent() {
      const data = [
        { time: '03-19', type: '1-20', value: 15 },
        { time: '03-19', type: '3-20', value: 2 },
        { time: '03-19', type: '5-20', value: 2 },
        { time: '03-19', type: '7-20', value: 2 },
        { time: '03-20', type: '1-20', value: 2 },
        { time: '03-20', type: '3-20', value: 2 },
        { time: '03-20', type: '5-20', value: 2 },
        { time: '03-20', type: '7-20', value: 2 },
        { time: '03-21', type: '1-20', value: 2 },
        { time: '03-21', type: '3-20', value: 2 },
        { time: '03-21', type: '5-20', value: 2 },
        { time: '03-21', type: '7-20', value: 2 },
        { time: '03-22', type: '1-20', value: 2 },
        { time: '03-22', type: '1-20', value: 2 },
        { time: '03-22', type: '1-20', value: 2 },
        { time: '03-22', type: '1-20', value: 2 },
        { time: '03-23', type: '1-20', value: 2 },
        { time: '03-23', type: '1-20', value: 2 },
        { time: '03-23', type: '1-20', value: 2 },
        { time: '03-23', type: '1-20', value: 2 },
        { time: '03-24', type: '1-20', value: 2 },
        { time: '03-24', type: '1-20', value: 2 },
        { time: '03-24', type: '1-20', value: 2 },
        { time: '03-24', type: '1-20', value: 2 },
        { time: '03-25', type: '1-20', value: 2 },
        { time: '03-25', type: '1-20', value: 2 },
        { time: '03-25', type: '1-20', value: 2 },
        { time: '03-25', type: '1-20', value: 2 }
      ]
      const chart = new Chart({
        container: 'containb',
        autoFit: true,
        height: 300
      })
      chart.data(data)
      chart.axis('time', {
        tickLine: null
      })

      chart.axis({
        label: {
          formatter: (text) => {
            return text.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
          }
        },
        title: {
          offset: 80,
          style: {
            fill: '#aaaaaa'
          }
        }
      })
      chart.legend({
        position: 'right'
      })
      chart.tooltip({
        shared: true,
        showMarkers: false
      })
      chart.interaction('active-region')

      chart
        .interval()
        .adjust('stack')
        .position('time*value')
        .color('type', ['#40a9ff', '#1890ff', '#096dd9', '#0050b3'])
      chart.render()
    },
    icoComponent() {
      const data = [
        {
          type: '大苏打大福大简单快捷登录',
          sales: 65
        },
        {
          type: '2',
          sales: 80
        },
        {
          type: '3',
          sales: 15
        },
        {
          type: '4',
          sales: 14.5
        },
        {
          type: '5',
          sales: 18.5
        },
        {
          type: '6',
          sales: 20
        },
        {
          type: '7',
          sales: 75
        },
        {
          type: '8',
          sales: 30
        },
        {
          type: '9',
          sales: 30
        },
        {
          type: '10',
          sales: 80
        },
        {
          type: '11',
          sales: 100
        },
        {
          type: '12',
          sales: 100
        }
      ]
      const barPlot = new Bar('contaa', {
        data,
        xField: 'sales',
        yField: 'type',
        height: 425,
        barWidthRatio: 0.6,
        meta: {
          type: {
            alias: '类别'
          },
          sales: {
            alias: '销售额'
          }
        }
      })
      barPlot.render()
    }
  },
  mounted() {
    this.initComponent()
    this.components()
    this.inComponent()
    this.iniComponent()
    this.inioComponent()
    this.icoComponent()
  }
}
</script>
<style lang="scss" scoped>
element.style {
  margin-left: 60px;
}
.main-container {
  width: 100%;
  height: 100%;
  background-color: #f5f6f7;
  margin-left: 70px;
  .row-a {
    width: 100%;
    margin-top: 20px;
    height: 850px;
    .click-roa {
      height: 1370px;
      background-color: #fff;
    }
    .click-roa {
      position: relative;
      .span-a {
        position: absolute;
        top: 20px;
        left: 19px;
        color: #99a6c4;
      }
      .mol-row {
        margin-top: 20px;
        .menu-roa,
        .menu-rob {
          height: 42px;
          line-height: 42px;
          // border-right: solid 3px #f56;
        }
      }
    }
  }
  .el-menu-vertical-demo::shadow {
    width: 100%;
    border-left: 5px solid #0167ff;
  }
}
#select-roa {
  width: 220px;
  height: 32px;
}
#select-rob {
  width: 120px;
  height: 32px;
  line-height: 32px;
  margin-left: 20px;
}
#select-roc {
  width: 75px;
  height: 32px;
  position: absolute;
  right: 0;
}
.flep {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  .rlep-roa {
    width: 367px;
    .flex-roa-a {
      height: 80px;
      margin: 10px auto;
      background-color: #fff;
      border-radius: 3px;
      position: relative;
      .flex-rrr-a {
        position: absolute;
        top: 15px;
        left: 30px;
        font-weight: 700;
      }
      .flex-rrr-b {
        position: absolute;
        bottom: 15px;
        left: 30px;
        font-size: 20px;
        font-weight: 700;
        color: #5578eb;
      }
      .flex-rrr-c {
        position: absolute;
        bottom: 18px;
        left: 120px;
      }
    }
  }
  .rleq-rob {
    width: 535px;
    height: 350px;
    background-color: #fff;
    margin: auto 25px;
  }
  .rleq-roc {
    width: 535px;
    height: 350px;
    background-color: #fff;
    position: absolute;
    right: 0;
  }
}
.rlep-rob {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  .rlew-rob-a {
    width: 367px;
    height: 350px;
    background-color: #fff;
  }
  .rlew-rob-b {
    width: 75%;
    height: 350px;
    background-color: #fff;
    margin-left: 25px;
    position: relative;
    .rlew-bbb-b {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.rlez-roc {
  width: 100%;
  margin: 20px auto;
  .rlee-roc-a {
    width: 100%;
    height: 500px;
    background-color: #fff;
  }
}
.loko {
  width: 100%;
  height: 100%;
}
.zhiti-li {
  font-weight: 700;
  padding: 15px 30px;
}
#contaa {
  padding-left: 30px;
}
.loko-b {
  width: 100%;
  .loko-v {
    width: 100%;
  }
}
.el-input__icon {
  line-height: 32px;
}
</style>
