<template>
  <message-dialog :visible="visible" @close="close">
    <div slot="content">
      <div class="message-title">
        <input
          placeholder="编辑标题（选填）"
          v-model="momentTitle"
          type="text"
        />
      </div>
      <div class="message-content">
        <!-- <textarea
          placeholder=""
          v-model="momentContent"
          ref="contentRef"
          @paste="handlerPaste"
          @input="test"
          @click="test"
        ></textarea> -->
        <AMention
          ref="contentRef"
          @paste="handlerPaste"
          v-model="momentInfo.moment_content"
        ></AMention>
      </div>
      <message-knowledge
        v-if="momentInfoData.content_type === 3"
        :info="momentInfoData.knowl_info"
      ></message-knowledge>
      <edit-message-image
        v-if="momentInfo.content_type === 1"
        @imageChange="imageChange($event)"
        @onDelte="onDeleteImage"
        :imageList="imageFileList"
        @editSort="editImageSort"
      ></edit-message-image>
      <edit-message-video
        :url="renderVideoUrl"
        v-if="momentInfo.content_type === 2"
        @onDelete="onDeleteVideo"
      ></edit-message-video>
    </div>
    <div slot="footer" class="flex toolbar">
      <div class="flex">
        <expression-box
          class="expression-edit-index"
          :isShow="isShowExpressBox"
          :expressList="$emoji.slice(0, 100)"
          :left="'0'"
          @add-express="addContentExpress($event)"
        >
          <div
            class="flex toolbar-item"
            :class="{ active: isShowExpressBox }"
            @click.stop="toggleExpressBox"
          >
            <i style="font-size: 26px" class="icon-a-1"></i>
            <!-- 按钮 -->
          </div>
        </expression-box>
        <el-upload
          action="#"
          :show-file-list="false"
          multiple
          accept=".jpg,.jpeg,.bmp,.gif,.png,.heif,.heic"
          :disabled="
            momentInfoData.content_type === 3 ||
            momentInfo.content_type === 2 ||
            videoFile != null
          "
          :on-change="momentImageChange"
          :auto-upload="false"
          class="flex toolbar-item"
          :class="{
            'toolbar-disabled':
              momentInfoData.content_type === 3 ||
              momentInfo.content_type === 2 ||
              videoFile != null
          }"
        >
          <div class="flex flex-y-center">
            <i
              style="font-size: 26px"
              :style="{
                color:
                  momentInfoData.content_type === 3 ||
                  momentInfo.content_type === 2 ||
                  videoFile != null
                    ? '#dddddd'
                    : ''
              }"
              class="icon-a-2"
            ></i>
          </div>
        </el-upload>
        <el-upload
          action="#"
          :show-file-list="false"
          accept=".mp4,.m4v,.ogm,.wmv,.mpg,.webm,.ogv,.mov,.asx,.mpeg,.avi,.mkv"
          :on-change="momentVideoChange"
          :disabled="
            momentInfoData.content_type === 3 ||
            (momentInfo.content_type === 1 && imageFileList.length > 0)
          "
          :auto-upload="false"
          multiple
          class="flex toolbar-item"
          :class="{
            'toolbar-disabled':
              momentInfoData.content_type === 3 ||
              (momentInfo.content_type === 1 && imageFileList.length > 0)
          }"
        >
          <div class="flex flex-y-center">
            <i
              style="font-size: 26px"
              :style="{
                color:
                  momentInfoData.content_type === 3 ||
                  (momentInfo.content_type === 1 && imageFileList.length > 0)
                    ? '#dddddd'
                    : ''
              }"
              class="icon-a-3"
            ></i>
          </div>
        </el-upload>
        <!-- <div class="flex toolbar-item">
          <i style="font-size: 26px" class="icon-a-4"></i>
        </div> -->
      </div>
      <el-button
        type="primary"
        size="small"
        @click="releaseDynamics"
        :loading="adding"
        >{{ adding ? '发布中' : '发布' }}</el-button
      >
    </div>
  </message-dialog>
</template>

<script>
import MessageDialog from '@/components/circle/MessageDialog.vue'
import EditMessageImage from '@/components/circle/EditMessageImage.vue'
import EditMessageVideo from './EditMessageVideo.vue'
import MessageKnowledge from '@/components/circle/MessageKnowledge.vue'
import AMention from '@/components/circle/CicleEditEditor1.vue'
import ExpressionBox from '@/components/common/ExpressionBox.vue'
export default {
  components: {
    MessageDialog,
    EditMessageImage,
    EditMessageVideo,
    MessageKnowledge,
    AMention,
    ExpressionBox
  },
  props: {
    momentId: {
      typeof: Number,
      default: -1
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageIndex: 0,
      momentInfo: {
        moment_title: '',
        moment_content: '',
        content_type: 1
      },
      momentInfoData: {},
      videoUrl: '',
      imageFileRaw: {},
      imageFileList: [],
      videoFile: null,
      // 是否显示表情的框
      isShowExpressBox: false,
      adding: false,
      hasCache: false
    }
  },
  computed: {
    // 视频链接
    renderVideoUrl() {
      if (this.videoUrl !== '') {
        return this.videoUrl
      } else {
        if (this.videoFile) {
          return URL.createObjectURL(this.videoFile)
        }
      }
      return ''
    },
    momentContent() {
      return this.momentInfo.moment_content
    },
    momentTitle: {
      get() {
        return this.momentInfo.moment_title
      },
      set(val) {
        this.momentInfo.moment_title = val
        if (this.momentId !== -1) {
          if (val !== '' && val !== this.momentInfoData.moment_title) {
            window.localStorage.setItem(
              `edit_circle_title_cache_${this.momentId}`,
              val
            )
          } else {
            window.localStorage.removeItem(
              `edit_circle_title_cache_${this.momentId}`
            )
          }
        } else {
          if (val !== '') {
            window.localStorage.setItem('add_circle_title_cache', val)
          } else {
            window.localStorage.removeItem('add_circle_title_cache')
          }
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOut)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOut)
  },
  methods: {
    // 获取内容html
    getContenHtml(e) {
      // 提及列表
      const clerList = e.clerk_list.map((c) => {
        c.type = 1
        return c
      })
      // 话题列表
      const topicList = e.topic_list.map((t) => {
        t.type = 2
        return t
      })
      const list = clerList.concat(topicList).sort((a, b) => {
        return a.start_index - b.start_index
      })
      const content = e.moment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name =
            e.type === 1
              ? '@' + (e.clerk_alias || e.clerk_name) + ' '
              : '#' + e.topic_name + '#' + ' '
          const id = e.type === 1 ? e.clerk_id : e.topic_id
          str +=
            content.substring(index, e.start_index) +
            `<a class="mention" data-mention="${name}" data-id="${id}" data-type="${e.type}" href=""> ${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 点到外面
    clickOut() {
      this.isShowExpressBox = false
    },
    // 清除数据
    clearData() {
      this.imageIndex = 0
      this.imageFileRaw = {}
      this.imageFileList = []
      this.videoFile = null
      this.videoUrl = ''
      this.momentInfo = {
        moment_title: '',
        moment_content: '',
        content_type: 1
      }
    },
    // 圈子详情
    async getDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentInfo, {
          moment_id: this.momentId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (res.info.image_list != null) {
          this.imageFileList = res.info.image_list.map((e) => {
            return {
              url: e.cover_access_path,
              imageId: `${e.image_id}`
            }
          })
        }
        const obj = this.getContenHtml(res.info).trim()
        const objList = obj.split('\n')
        let str = ''
        objList.forEach((e) => {
          str += '<p>' + e + '</p>'
        })
        this.hasCache = !!window.localStorage.getItem(
          `edit_circle_content_cache_${this.momentId}`
        )
        console.log('--------------------1')
        this.momentInfo = {
          moment_title: res.info.moment_title,
          moment_content: str || '',
          content_type: res.info.content_type
        }
        this.momentInfoData = res.info
        if (res.info.content_type === 2) {
          this.videoUrl = res.info.video_info.cover_video_path
        }
      }
    },
    // 校验图片格式
    beforeAvatarUpload(file) {
      return [
        'image/jpeg',
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/gif',
        'image/heif',
        'image/heic'
      ].includes(file.type)
    },
    // 上传图片
    momentImageChange(file) {
      this.imageChange(file.raw)
      // if (this.imageFileList.length >= 9) {
      //   return
      // }
      // if (!this.beforeAvatarUpload(file.raw)) {
      //   return
      // }
      // const key = 'image_' + this.imageIndex++
      // this.imageFileList.push({
      //   url: URL.createObjectURL(file.raw),
      //   imageId: key
      // })
      // this.imageFileRaw[key] = file.raw
      // if (this.momentInfo.content_type !== 1) {
      //   this.momentInfo.content_type = 1
      // }
    },
    // 删除图片
    onDeleteImage(index) {
      const info = this.imageFileList[index]
      this.imageFileList.splice(index, 1)
      if (info.imageId.indexOf('image_') !== -1) {
        delete this.imageFileRaw[info.imageId]
      }
    },
    // 删除视频
    onDeleteVideo() {
      this.videoFile = null
      this.momentInfo.content_type = 1
    },
    // 校验视频格式
    beforeVideoUpload(file) {
      return [
        'video/mp4',
        'video/m4v',
        'video/ogm',
        'video/wmv',
        'video/mpg',
        'video/webm',
        'video/ogv',
        'video/mov',
        'video/asx',
        'video/mpeg',
        'video/avi',
        'video/mkv'
      ].includes(file.type)
    },
    // 上传视频
    momentVideoChange(file) {
      console.log(file.raw)
      if (!this.beforeVideoUpload(file.raw)) {
        this.$message.error('视频格式有误！')
        return
      }
      this.videoFile = file.raw
      this.momentInfo.content_type = 2
      this.videoUrl = ''
    },
    // 发布
    async releaseDynamics() {
      if (this.adding) {
        return
      }
      if (!this.checkMoemntInfo()) {
        this.$message.error('圈子内容不能为空')
        return
      }
      const formObj = new FormData()
      formObj.append('moment_title', this.momentInfo.moment_title)
      formObj.append('moment_content', this.momentInfo.moment_content)
      formObj.append('content_type', this.momentInfo.content_type)
      switch (this.momentInfo.content_type) {
        // 图文
        case 1: {
          const imageKey = []
          this.imageFileList.forEach((e) => {
            imageKey.push(e.imageId)
            if (e.imageId.indexOf('image_') !== -1) {
              formObj.append(e.imageId, this.imageFileRaw[e.imageId])
            }
          })
          formObj.append('image_sign', imageKey.join(','))
          break
        }
        // 视频
        case 2:
          formObj.append('file', this.videoFile)
          break
      }
      if (this.momentId !== -1) {
        formObj.append('moment_id', this.momentId)
        // 修改
        this.adding = true
        await this.editDynamics(formObj)
        this.adding = false
      } else {
        // 添加
        this.adding = true
        await this.addDynamics(formObj)
        this.adding = false
      }
    },
    // 添加圈子
    async addDynamics(obj) {
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.addMoment, obj).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '发布圈子成功！',
          type: 'success'
        })
        this.clearData()
        window.localStorage.removeItem('add_circle_title_cache')
        window.localStorage.removeItem('add_circle_content_cache')
        this.$emit('addComplete')
      }
    },
    // 修改圈子
    async editDynamics(obj) {
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.editMoment, obj).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改圈子成功！',
          type: 'success'
        })
        this.clearData()
        window.localStorage.removeItem(
          `edit_circle_content_cache_${this.momentId}`
        )
        window.localStorage.removeItem(
          `edit_circle_title_cache_${this.momentId}`
        )
        this.$emit('editComplete')
      }
    },
    // 校验数据
    checkMoemntInfo() {
      let flag = false
      if (this.momentInfo.moment_content !== '') {
        flag = true
      }
      if (this.imageFileList.length !== 0) {
        flag = true
      }
      if (this.videoUrl !== '' || this.videoFile != null) {
        flag = true
      }
      return flag
    },
    // 修改图片排序
    editImageSort(val) {
      this.imageFileList = val
    },
    close() {
      // if (
      //   this.momentInfo.moment_title === '' &&
      //   this.momentInfo.moment_content === '' &&
      //   this.videoFile == null &&
      //   this.imageFileList.length === 0
      // ) {
      this.$emit('close')
      this.clearData()
      //   return
      // }
      // this.$confirm('将退出动态编辑，无法保证，是否继续？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      //   this.$emit('close')
      //   if (this.momentId !== -1) {
      //     window.localStorage.removeItem(
      //       `edit_circle_content_cache_${this.momentId}`
      //     )
      //   } else {
      //     window.localStorage.removeItem('add_circle_content_cache')
      //   }
      //   this.clearData()
      // })
    },
    // 组件添加图片
    imageChange(file) {
      if (this.imageFileList.length >= 9) {
        return
      }
      if (!this.beforeAvatarUpload(file)) {
        return
      }
      const key = 'image_' + this.imageIndex++
      this.imageFileList.push({
        url: URL.createObjectURL(file),
        imageId: key
      })
      this.imageFileRaw[key] = file
      if (this.momentInfo.content_type !== 1) {
        this.momentInfo.content_type = 1
      }
    },
    // 黏贴事件
    handlerPaste(e) {
      if (e.files.length > 0) {
        e.files.forEach((e) => {
          if (this.beforeAvatarUpload(e)) {
            this.imageChange(e)
          }
        })
      }
    },
    // 替换表情图片
    // emotion (obj) {
    //   const word = obj.replace(/|/gi, '')
    //   const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
    //   const index = list.indexOf(word)
    //   return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif">`
    // },
    // 显示隐藏表情框
    toggleExpressBox() {
      this.isShowExpressBox = !this.isShowExpressBox
    },
    // 在内容里添加一个表情
    addContentExpress(obj) {
      console.log(obj)
      console.log(this.momentInfo.moment_content)
      this.momentInfo.moment_content = this.momentInfo.moment_content.replace(
        '<p>&nbsp;',
        '<p>'
      )
      const index = this.momentInfo.moment_content.lastIndexOf('</p>')
      if (index === -1) {
        // this.momentInfo.moment_content = '<p>' + `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif">` + '</p>'
        this.momentInfo.moment_content = '<p>' + obj.name + '</p>'
      } else {
        this.momentInfo.moment_content =
          // this.momentInfo.moment_content.substring(0, index) + `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" >` + '</p>'
          this.momentInfo.moment_content.substring(0, index) + obj.name + '</p>'
      }
      // this.momentInfo.moment_content += obj.char
      // 关闭白框
      this.isShowExpressBox = false
      // 让输入框重新聚焦
      setTimeout(() => {
        // 让输入框重新聚焦
        this.$refs.contentRef.setFocus()
      }, 50)
    },
    test() {
      const input = this.$refs
      console.log(input)
    }
  },
  watch: {
    'momentInfo.moment_content'(val, old) {
      console.log('changeContent', val)
      if (this.momentId !== -1) {
        if (
          old !== '' &&
          val !== '' &&
          val !== this.momentInfoData.moment_content
        ) {
          window.localStorage.setItem(
            `edit_circle_content_cache_${this.momentId}`,
            val
          )
          if (!this.hasCache) {
            window.localStorage.removeItem(
              `edit_circle_content_cache_${this.momentId}`
            )
            this.hasCache = true
          }
        } else {
          window.localStorage.removeItem(
            `edit_circle_content_cache_${this.momentId}`
          )
        }
      } else {
        if (val !== '') {
          window.localStorage.setItem('add_circle_content_cache', val)
        } else {
          if (this.visible) {
            window.localStorage.removeItem('add_circle_content_cache')
          }
        }
      }
    },
    visible(val) {
      if (val) {
        if (this.momentId !== -1) {
          const contentCache = window.localStorage.getItem(
            `edit_circle_content_cache_${this.momentId}`
          )
          const titleCache = window.localStorage.getItem(
            `edit_circle_title_cache_${this.momentId}`
          )
          if (titleCache || contentCache) {
            setTimeout(() => {
              this.$confirm('发现本地缓存是否导入?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  if (contentCache) {
                    this.momentInfo.moment_content = contentCache || ''
                  }
                  if (titleCache) {
                    this.momentInfo.moment_title = titleCache || ''
                  }
                })
                .catch(() => {})
            }, 500)
          }
        } else {
          const contentCache = window.localStorage.getItem(
            'add_circle_content_cache'
          )
          if (contentCache) {
            this.momentInfo.moment_content = contentCache || ''
          }
          const titleCache = window.localStorage.getItem(
            'add_circle_title_cache'
          )
          if (titleCache) {
            this.momentInfo.moment_title = titleCache
          }
        }
      } else {
        this.momentInfo = {
          moment_title: '',
          moment_content: '',
          content_type: 1
        }
      }
    },
    momentId(val) {
      this.clearData()
      if (val !== -1) {
        this.getDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-title {
  padding: 17px 0 12px;

  input {
    background: none;
    outline: none;
    border: none;
    height: 27px;
    width: 100%;
    font-size: 18px;

    &:focus {
      outline: none;
    }
  }
}

.message-content {
  margin-bottom: 20px;
  textarea {
    background: none;
    outline: none;
    border: none;
    width: 100%;
    font-size: 14px;
    height: 300px;
    line-height: 26px;
    resize: vertical;

    &:focus {
      outline: none;
    }
  }
}

.toolbar {
  justify-content: space-between;
  padding: 24px 0;

  .toolbar-item {
    cursor: pointer;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    color: #bbbbbb;
    &.active,
    &:hover {
      color: $main-color;
      border-radius: 32px;
      background: rgba(0, 128, 255, 0.1);
    }

    &.toolbar-disabled {
      color: #ddd;
      &:hover {
        color: #ddd;
        border-radius: none;
        background: #fff;
      }
    }
  }
}
.expression-edit-index::v-deep .expression-box {
  z-index: 2 !important;
}
</style>
