<!--
 * @Author: Betty
 * @Date: 2021-07-05 15:13:20
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-08 13:57:42
 * @Description: 编辑会议的表单部分
-->
<template>
  <div class="add-meeting">
    <!-- 第一行，会议主题1 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-Filter"></span>
        <h4 class="add-meeting-title">会议主题</h4>
      </div>
      <input
        type="text"
        class="form-input"
        :class="{ disabled: !isEditable }"
        placeholder="编辑会议主题"
        v-model.trim="meetingTopic"
        @blur="toEditMeetingName"
        @keyup.enter="toBlur"
        :readonly="!isEditable"
      />
    </div>
    <!-- 第二行，会议地点 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-Location"></span>
        <h4 class="add-meeting-title">会议地点</h4>
        <el-tooltip effect="dark" content="会议室日程" placement="top-start">
          <i
            class="iconfont icon-richeng meeting-room-occupy-btn"
            @click="meetingRoomOccupyVisible = true"
          ></i>
        </el-tooltip>
      </div>
      <div class="radio-box">
        <!-- 单选框 -->
        <el-radio v-model="meetingPostion" :label="1" :disabled="!isEditable"
          >内部</el-radio
        >
        <el-radio v-model="meetingPostion" :label="2" :disabled="!isEditable"
          >外部</el-radio
        >
      </div>
      <!-- 选择会议室 -->
      <div v-if="meetingPostion === 1">
        <span class="no-choose" v-show="isShowRoomTip">选择会议室</span>
        <div v-show="!isShowRoomTip" class="choose-room">
          <input
            type="text"
            class="form-input"
            :class="{ disabled: !isEditable }"
            readonly
            placeholder="选择会议室"
            v-model="meetingRoom"
            @click.stop="toggleChooseRoom"
          />
          <!-- 选择会议室 -->
          <div class="choose-meeting-room" v-show="isChooseRooms">
            <div
              class="meeting-room-item"
              v-for="(item, index) in meetRoomList"
              :key="index"
              @click="chooseRoom(item)"
            >
              {{ item.room_name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 选择会议地点 -->
      <div v-else>
        <input
          type="text"
          class="form-input"
          :class="{ disabled: !isEditable }"
          :readonly="!isEditable"
          placeholder="编辑会议地址"
          v-model="meetingAddress"
          @blur="toEditMeetingAddress([2, meetingAddress])"
          @keyup.enter="toBlur($event, 2, meetingAddress)"
        />
      </div>
    </div>
    <!-- 第三行，会议时间 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-a-TimeCircle"></span>
        <h4 class="add-meeting-title">会议时间</h4>
      </div>
      <!-- 选择会议开始和结束时间 -->
      <div class="flex flex-y-center">
        <el-date-picker
          v-model="meetingStartTime"
          type="datetime"
          placeholder="会议开始时间"
          align="right"
          :picker-options="pickerOptions"
          @change="toEditStartTime"
          :disabled="!isEditable"
          :readonly="!isEditable"
          :editable="isEditable"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
        <span class="time-tip">至</span>
        <el-date-picker
          v-model="meetingEndTime"
          type="datetime"
          placeholder="会议结束时间"
          align="right"
          :picker-options="pickerOptions"
          @change="toEditEndTime"
          :disabled="!isEditable"
          :readonly="!isEditable"
          :editable="isEditable"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </div>
    </div>
    <!-- 第四行，选择会议主持 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-a-Frame1"></span>
        <h4 class="add-meeting-title">会议主持</h4>
      </div>
      <!-- 显示选择的人员 -->
      <div
        class="person-result form-input people-input"
        :class="{ 'no-choose': hostList.length === 0, disabled: !isEditable }"
        @click="beginChooseHost"
      >
        <span v-if="hostList.length === 0">选择会议主持</span>
        <div v-else>
          <span
            v-for="(host, index) in hostList"
            :key="index"
            class="people-name"
          >
            {{ host.clerk_alias || host.clerk_name }}
            <span
              class="delete-btn iconfont icon-a-addfill"
              @click.stop="deleteHost(host.clerk_id)"
            ></span>
          </span>
        </div>
      </div>
      <!-- 选择会议主持的弹窗 -->
      <add-member
        :isShowAddMember="isChooseHost"
        :dataList="clerkTree"
        :memberList="hostTempList"
        modalTitle="选择主持人"
        @to-add-member="chooseHost($event)"
        @ready-remove-member="removeTempHost($event)"
        @confirm-add-member="chooseHostConfirm"
        @close-add-member="closeHostModal"
      ></add-member>
    </div>
    <!-- 第五行，会议记录 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-a-Frame2"></span>
        <h4 class="add-meeting-title">会议记录</h4>
      </div>
      <!-- 显示选择的人员 -->
      <div
        class="person-result form-input people-input"
        :class="{ 'no-choose': !recorder, disabled: !isEditable }"
        @click.stop="beginChooseRecorder"
      >
        {{
          recorder
            ? recorder.clerk_alias || recorder.clerk_name
            : '选择会议记录员'
        }}
      </div>
      <!-- 选择会议记录人员弹窗 -->
      <div v-show="isChooseRecorder" @click.stop="" class="choose-person-box">
        <search-person
          :isShow="isChooseRecorder"
          @choose-person="chooseRecorder($event)"
        ></search-person>
        <member-tree
          :treeNode="treeNode"
          v-for="(treeNode, index) in clerkTree"
          :key="index"
          @clerk-choose="chooseRecorder($event)"
        ></member-tree>
      </div>
    </div>
    <!-- 第六行，选择会议与会人员 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-a-Frame3"></span>
        <h4 class="add-meeting-title">与会人员</h4>
      </div>
      <!-- 显示选择的人员 -->
      <div
        class="person-result form-input people-input"
        :class="{ 'no-choose': memberList.length === 0, disabled: !isEditable }"
        @click="beginChooseMember"
        style="width: 100%"
      >
        <span v-if="memberList.length === 0">选择参会人员</span>
        <div v-else>
          <span
            v-for="(member, index) in memberList"
            :key="index"
            class="people-name"
          >
            {{ member.clerk_alias || member.clerk_name }}
            <span
              class="delete-btn iconfont icon-a-addfill"
              @click.stop="deleteMember(member.clerk_id)"
            ></span>
          </span>
        </div>
      </div>
      <!-- 选择与会人员的弹窗 -->
      <add-member-by-dept
        :isShowAddMember="isChooseMember"
        :dataList="clerkTree"
        :memberList="memberTempList"
        modalTitle="选择参会人员"
        @to-add-member="chooseMember($event)"
        @ready-remove-member="removeTempMember($event)"
        @confirm-add-member="chooseMemberConfirm($event)"
        @close-add-member="closeMemberModal"
      ></add-member-by-dept>
    </div>
    <!-- 第七行，会议内容 -->
    <div class="add-meeting-item meeting-content-box">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-Frame7"></span>
        <h4 class="add-meeting-title">会议内容</h4>
        <!-- 编辑按钮，在会议详情这里才显示 -->
        <button
          class="edit-content-btn"
          v-if="isMeetingDetail && isEditable"
          v-show="!isEditContent"
          @click="beginEditContent"
        >
          编辑
        </button>
      </div>
      <!-- 富文本编辑器 -->
      <meeting-describe-editor
        :value="meetingContent || ''"
        ref="meetingContent"
        v-show="(isMeetingDetail && isEditContent) || !isMeetingDetail"
        @input="handlerDescribeInput"
      ></meeting-describe-editor>
      <!-- 显示编辑的结果 -->
      <div
        class="htmledit_views"
        v-html="meetingDetail && meetingDetail.meeting_content_html"
        v-if="isMeetingDetail"
        v-show="!isEditContent"
        @click="imageChgange($event)"
      ></div>
      <!-- 显示保存和取消按钮 -->
      <div
        class="button-line edit-box flex flex-y-center"
        v-if="isMeetingDetail"
        v-show="isEditContent"
      >
        <button class="meeting-btn active" type="button" @click="saveContent">
          保存
        </button>
        <button class="meeting-btn cancel" type="button" @click="cancelContent">
          取消
        </button>
      </div>
    </div>
    <!-- 第八行，会议附件 -->
    <div class="add-meeting-item">
      <div class="add-meeting-header flex flex-y-center">
        <span class="iconfont icon-a-Frame5"></span>
        <h4 class="add-meeting-title">会议附件</h4>
      </div>
      <!-- 刚才上传的附件预览 -->
      <div class="flex flex-wrap">
        <div v-for="(attachment, index) in appendixList" :key="index">
          <!-- 旧的附件 -->
          <attachment-item
            v-if="attachment.attachment_id && attachment.attachment_id > 0"
            :appendix="attachment"
            fileType="meeting"
            :meetingId="meetingId"
            :isMeetingDetail="isMeetingDetail"
            :isDeletable="isEditable"
            @to-delete="removeOldAttachment(index, $event)"
            @fresh-attachment="toFresh"
            style="margin: 12px 0 0 0"
          >
          </attachment-item>
          <!-- 新的附件 -->
          <new-attachment-item
            v-else
            :appendix="attachment"
            @remove-file="removeAttachment(index)"
          ></new-attachment-item>
        </div>
      </div>

      <!-- 上传附件的输入框 -->
      <input
        type="file"
        class="upload-file"
        ref="uploadInputRef"
        @change="chooseFile"
      />
      <!-- 上传附件的按钮 -->
      <span class="no-choose" @click="beginUploadFile" v-if="isEditable"
        >上传附件</span
      >
    </div>
    <!-- 最后一行，创建会议等按钮 -->
    <div class="button-line flex flex-y-center" v-if="!isMeetingDetail">
      <button class="meeting-btn active" type="button" @click="editMeeting(2)">
        提交发布
      </button>
      <button class="meeting-btn" type="button" @click="editMeeting(1)">
        保存草稿
      </button>
      <button
        class="meeting-btn cancel"
        type="button"
        @click="closeEditMeeting"
      >
        取消
      </button>
    </div>
    <el-image
      ref="previewImageRef"
      :preview-src-list="[previewImageSrc]"
      v-show="false"
    ></el-image>
    <meeting-room-occupy :visible.sync="meetingRoomOccupyVisible" />
  </div>
</template>

<script>
import AddMember from '../common/AddMember.vue'
import AddMemberByDept from '../common/AddMemberByDept.vue'
import MeetingDescribeEditor from '../meeting/MeetingDescribeEditor1.vue'
import MemberTree from '../common/MemberTree.vue'
import SearchPerson from '../common/SearchPerson.vue'
import NewAttachmentItem from '../common/NewAttachmentItem.vue'
import AttachmentItem from '../common/AttachmentItem.vue'
import MeetingRoomOccupy from '@/views/Meeting/components/meeting-room-occupy'
export default {
  name: 'edit-meeting-form',
  components: {
    AddMember,
    AddMemberByDept,
    MemberTree,
    SearchPerson,
    MeetingDescribeEditor,
    NewAttachmentItem,
    AttachmentItem,
    MeetingRoomOccupy
  },
  props: {
    // 当前会议的id
    meetingId: {
      type: Number,
      default: -1
    },
    // 是否是会议详情
    isMeetingDetail: {
      type: Boolean,
      default: false
    },
    // 是否正在编辑内容
    isEditContent: {
      type: Boolean,
      default: false
    },
    // 是否可以编辑
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 会议详情
      meetingDetail: null,
      // 输入的会议标题
      meetingTopic: '',
      // 添加会议
      addMeetingActive: 'addMeeting',
      // 会议时间的开始时间
      meetingStartTime: '',
      // 会议时间的结束时间
      meetingEndTime: '',
      // 临时记录开始时间和结束时间
      tempStartTimeStr: '',
      tempEndTimeStr: '',
      // 选择日期组件的快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 选择地点是内部还是外部
      meetingPostion: 1,
      // 选择的会议室
      meetingRoom: null,
      // 选择的会议室id
      meetingRoomId: -1,
      // 会议室列表
      meetRoomList: [],
      // 是否展示会议室列表
      isChooseRooms: false,
      // 是否展示选择会议室的提示
      isShowRoomTip: false,
      // 会议地址
      meetingAddress: '',
      // 选择的会议主持人
      hostList: [],
      // 是否在选择主持人
      isChooseHost: false,
      // 职员数据
      clerkTree: [],
      // 选择主持人的临时数据，用来预览
      hostTempList: [],
      // 选择主持人的临时id集合，用来提交
      hostTempIdList: [],
      // 用来提交的主持人id集合
      hostIdList: [],
      // 选择的记录人
      recorder: null,
      // 是否在选择记录人
      isChooseRecorder: false,
      // 选择的参会人员
      memberList: [],
      // 临时选择的参会人员
      memberTempList: [],
      // 选择的参会人员id集合
      memberTempIdList: [],
      memberIdList: [],
      // 是否在选择参会人员
      isChooseMember: false,
      // 附件列表
      appendixList: [],
      // 会议内容
      meetingContent: '',
      // 要删除的附件
      toDeleteAttachmentId: [],
      // 是否提交中
      isSubmiting: false,
      previewImageSrc: '',
      // 会议室日程
      meetingRoomOccupyVisible: false
    }
  },
  computed: {
    // 主持人id字符串
    hostIds() {
      return this.hostIdList.join()
    },
    // 与会人员id字符串
    memberIds() {
      return this.memberIdList.join()
    }
  },
  watch: {
    // 监听主持人id的改变
    hostIds(val, old) {
      // 如果是从无到有，说明还在初始化，这个时候不触发请求
      if (old) {
        this.$emit('edit-host', val)
      }
    },
    // 切换会议室id
    meetingRoomId(val, old) {
      // 如果值为-1，就认为它没有变化
      if (old !== -1) {
        this.toEditMeetingAddress([1, val])
      }
    },
    // 监听与会人员id的改变
    memberIds(val, old) {
      if (old) {
        this.$emit('edit-member', val)
      }
    },
    // 监听编辑
    isEditContent(val) {
      if (val) {
        const cache = window.localStorage.getItem(
          `edit_meeting_describe_content_cache_${this.meetingId}`
        )
        if (cache) {
          setTimeout(() => {
            this.$confirm('发现本地缓存是否导入?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.$refs.meetingContent.setContent(cache)
              })
              .catch(() => {})
          }, 500)
        }
      }
    }
  },
  mounted() {
    // 获取职员数据
    this.getClerkTree()
    // 获取会议室列表
    this.getRoomList()
    document.addEventListener('click', this.clickOut)
    if (this.meetingId !== -1) {
      // 获取当前会议的数据
      this.getMeetingDetail(this.meetingId)
    }
    // 编辑回填本地缓存描述
    if (!this.isMeetingDetail) {
      const cache = window.localStorage.getItem(
        `edit_meeting_describe_content_cache_${this.meetingId}`
      )
      if (cache) {
        setTimeout(() => {
          this.$confirm('发现本地缓存是否导入?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.$refs.meetingContent.setContent(cache)
            })
            .catch(() => {})
        }, 500)
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOut)
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      if (hasLink) {
        return url.replace(
          reg,
          `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
        )
      } else {
        return url
      }
    },
    imageChgange(e) {
      if (e.target.nodeName === 'IMG') {
        this.previewImageSrc = e.target.src
        this.$refs.previewImageRef.showViewer = true
        console.log(e.target.src)
      }
    },
    // 获取会议详情
    async getMeetingDetail(id) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingDetail, {
          meeting_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.meetingDetail = res.info
        if (this.meetingDetail) {
          this.$emit('set-detail', this.meetingDetail)
          // 回填表单
          // 初始化会议名称
          this.meetingTopic = this.meetingDetail.meeting_name || ''
          // 初始化会议地点
          this.meetingPostion = this.meetingDetail.meeting_place_type
          // 初始化会议室
          this.meetingRoomId = this.meetingDetail.meeting_room_id || ''
          this.meetingRoom = this.meetingDetail.meeting_room_name || ''
          this.meetingAddress = this.meetingDetail.meeting_address || ''
          // 初始化开始时间和结束时间
          this.meetingStartTime = this.meetingDetail.start_time || ''
          this.meetingEndTime = this.meetingDetail.end_time || ''
          this.tempStartTimeStr = this.meetingStartTime
          this.tempEndTimeStr = this.meetingEndTime
          // 回填会议主持人
          this.hostList = this.meetingDetail.compere_list
          this.hostTempIdList = this.hostList.map((item) => item.clerk_id)
          this.hostIdList = this.hostList.map((item) => item.clerk_id)
          // 回填会议记录人
          this.recorder = {
            clerk_id: this.meetingDetail.upload_minutes_clerk_id || '',
            clerk_name: this.meetingDetail.upload_minutes_clerk_name || '',
            clerk_alias: this.meetingDetail.upload_minutes_clerk_alias || ''
          }
          // 回填与会人员
          this.memberList = this.meetingDetail.participant_list
          this.memberTempIdList = this.memberList.map((item) => item.clerk_id)
          this.memberIdList = this.memberList.map((item) => item.clerk_id)
          // 回填会议内容
          this.meetingContent = this.meetingDetail.meeting_content_html
          // 回填附件
          this.appendixList = this.meetingDetail.meeting_content_attachment_list
        }
      }
    },
    // 记录要删除的附件的id
    removeOldAttachment(index, id) {
      this.$util.showConfirm('确定要删除这个文件吗？').then((result) => {
        if (result) {
          this.toDeleteAttachmentId.push(id)
          this.appendixList.splice(index, 1)
        }
      })
    },
    // 触发blur
    toBlur(e, ...arg) {
      e.target.blur(arg)
    },
    // 会议内容的逻辑
    // 开始编辑内容
    beginEditContent() {
      this.$emit('begin-edit-content')
    },
    // 保存内容修改
    saveContent() {
      const content = this.$refs.meetingContent.getContent()
      this.$emit('to-save-content', content)
    },
    // 取消内容的修改
    cancelContent() {
      this.$emit('close-edit-content')
    },
    // 修改单个字段
    // 修改会议名字
    toEditMeetingName() {
      this.$emit('edit-meeting-name', this.meetingTopic)
    },
    // 修改开始时间
    toEditStartTime(e) {
      this.meetingStartTime = e
      this.$emit('edit-start-time', e)
    },
    // 修改开始时间回调
    editStartTimeCallback(isSuccess) {
      if (isSuccess) {
        this.tempStartTimeStr = this.meetingStartTime
      } else {
        this.meetingStartTime = this.tempStartTimeStr
      }
    },
    // 修改结束时间
    toEditEndTime(e) {
      this.$emit('edit-end-time', e)
    },
    // 修改结束时间回调
    editEndTimeCallback(isSuccess) {
      if (isSuccess) {
        this.tempEndTimeStr = this.meetingEndTime
      } else {
        this.meetingEndTime = this.tempEndTimeStr
      }
    },
    // 修改会议地点
    toEditMeetingAddress([type, val]) {
      this.$emit('edit-address', {
        placeType: type,
        address: val
      })
    },
    // 获取会议室列表
    async getRoomList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingRommList, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.meetRoomList = res.list
      }
    },
    // 展开收起选择会议室的弹窗
    toggleChooseRoom() {
      if (!this.isEditable) return
      this.isChooseRooms = !this.isChooseRooms
    },
    // 点击选择会议室
    chooseRoom(room) {
      this.meetingRoom = room.room_name
      this.isChooseRooms = false
      // 记录选择的会议室id，并且关掉弹窗
      this.meetingRoomId = room.room_id
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
        this.$emit('get-clerk-data', res)
      })
    },
    // 开始选择主持人
    beginChooseHost() {
      if (!this.isEditable) return
      this.isChooseHost = true
      this.hostTempList = [...this.hostList]
      this.hostIdList = this.hostList.map((item) => item.clerk_id)
      this.hostTempIdList = [...this.hostTempIdList]
    },
    // 点击名字，选择主持人
    chooseHost(clerk) {
      if (!this.hostTempIdList.includes(clerk.clerk_id)) {
        this.hostTempList.push(clerk)
        this.hostTempIdList.push(clerk.clerk_id)
      }
    },
    // 点击删除一个主持人
    removeTempHost(clerk) {
      console.log('要删除的主持人id为', clerk.clerk_id)
      const index = this.hostTempIdList.indexOf(clerk.clerk_id)
      console.log(index)
      this.hostTempList.splice(index, 1)
      this.hostTempIdList.splice(index, 1)
      console.log(this.hostTempIdList)
    },
    // 删除一个主持人
    deleteHost(id) {
      if (!this.isEditable) return
      const index = this.hostTempIdList.indexOf(id)
      this.hostList.splice(index, 1)
      this.hostTempIdList.splice(index, 1)
      this.hostIdList.splice(index, 1)
    },
    // 删除一个参会人员
    deleteMember(id) {
      if (!this.isEditable) return
      const index = this.memberTempIdList.indexOf(id)
      this.memberList.splice(index, 1)
      this.memberTempIdList.splice(index, 1)
      this.memberIdList.splice(index, 1)
    },
    // 确定选择这些主持人
    chooseHostConfirm() {
      this.hostList = [...this.hostTempList]
      this.hostIdList = [...this.hostTempIdList]
      this.closeHostModal()
    },
    // 开始选择记录人
    beginChooseRecorder() {
      if (!this.isEditable) return
      this.isChooseRecorder = true
    },
    // 关闭主持人弹窗
    closeHostModal() {
      this.isChooseHost = false
    },
    // 选择记录人员
    chooseRecorder(clerk) {
      this.recorder = clerk
      this.isChooseRecorder = false
      if (this.isMeetingDetail) {
        this.$emit('edit-recorder', this.recorder)
      }
    },
    // 开始选择参会成员
    beginChooseMember() {
      if (!this.isEditable) return
      this.isChooseMember = true
      this.memberTempList = [...this.memberList]
      this.memberTempIdList = this.memberList.map((item) => item.clerk_id)
    },
    // 点击选择成员
    chooseMember(clerk) {
      if (!this.memberTempIdList.includes(clerk.clerk_id)) {
        this.memberTempList.push(clerk)
        this.memberTempIdList.push(clerk.clerk_id)
      }
    },
    // 点击删除一个已选择的成员
    removeTempMember(clerk) {
      const index = this.memberTempIdList.indexOf(clerk.clerk_id)
      this.memberTempIdList.splice(index, 1)
      this.memberTempList.splice(index, 1)
    },
    // 确定选择参会人员
    chooseMemberConfirm({ list, idList }) {
      this.memberList = [...list]
      this.memberIdList = [...idList]
      this.closeMemberModal()
    },
    // 关闭选择参会人员弹窗
    closeMemberModal() {
      this.isChooseMember = false
    },
    // 开始上传文件
    beginUploadFile() {
      this.$refs.uploadInputRef.click()
    },
    // 获取会议附件列表
    async getMeetingAttachmentList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingAttachmentList, {
          meeting_id: this.meetingId,
          attachment_type: 1
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.appendixList = res.list
      }
    },
    // 选择要上传的文件
    chooseFile(e) {
      const file = e.target.files[0]
      if (!this.isMeetingDetail) {
        this.appendixList.push(file)
      } else {
        // 如果不是详情，那就把新文件加到数组中
        this.$emit('upload-appendix', file)
        this.$refs.uploadInputRef.value = ''
      }
    },
    // 删除这个新文件
    removeAttachment(index) {
      this.appendixList.splice(index, 1)
    },
    // 提交修改
    async editMeeting(val) {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      const isRelease = val === 2
      const formObj = new FormData()
      // 会议id
      formObj.append('info.meeting_id', this.meetingDetail.meeting_id)
      // 会议名称
      if (this.meetingTopic.trim() === '') {
        errmsg('请输入会议名称')
        return
      } else {
        formObj.append('info.meeting_name', this.meetingTopic)
      }
      // 会议状态是草稿还是发布
      formObj.append('info.meeting_status', val)
      // 地点类型
      formObj.append('info.meeting_place_type', this.meetingPostion)
      // 会议室和会议地点
      if (this.meetingPostion === 1) {
        if (this.meetingRoomId === -1) {
          if (isRelease) {
            console.log(isRelease)
            console.log('请选择会议室')
            errmsg('请选择会议室')
            return
          }
        } else {
          formObj.append('info.meeting_room_id', this.meetingRoomId)
        }
      } else {
        if (this.meetingAddress.trim() === '') {
          if (isRelease) {
            errmsg('请输入会议地点')
            return
          }
        } else {
          formObj.append('detail.meeting_address', this.meetingAddress)
        }
      }
      // 会议开始时间和结束时间
      if (this.meetingStartTime.trim() === '') {
        if (isRelease) {
          errmsg('请选择开始时间')
          return
        }
      } else {
        formObj.append('info.start_time', this.meetingStartTime)
      }
      if (this.meetingEndTime.trim() === '') {
        if (isRelease) {
          errmsg('请选择结束时间')
          return
        }
      } else {
        formObj.append('info.end_time', this.meetingEndTime)
      }
      // 会议主持人id
      if (this.hostTempIdList.length === 0) {
        if (isRelease) {
          errmsg('请选择主持人')
          return
        }
      } else {
        formObj.append('compere_clerk_ids', this.hostTempIdList.join())
      }
      // 会议记录者
      if (this.recorder == null) {
        if (isRelease) {
          errmsg('请选择会议记录者')
          return
        }
      } else {
        formObj.append('info.upload_minutes_clerk_id', this.recorder.clerk_id)
      }
      // 会议与会人员id
      if (this.memberIdList.length === 0) {
        if (isRelease) {
          errmsg('请选择会议与会人员')
          return
        }
      } else {
        formObj.append('participant_clerk_ids', this.memberIdList.join())
      }

      const content = this.$refs.meetingContent.getContent()
      // 会议内容
      if (!(content != null && content.text !== '')) {
        if (isRelease) {
          errmsg('请输入会议内容')
          return
        }
      } else {
        formObj.append('detail.meeting_content_html', content.html)
        formObj.append('detail.meeting_content_text', content.text)
      }
      // 会议附件
      // 新增
      if (this.appendixList.length > 0) {
        for (let i = 0; i < this.appendixList.length; i++) {
          if (!this.appendixList[i].attachment_id) {
            formObj.append(`file${i + 1}`, this.appendixList[i])
          }
        }
      }
      // 记录要删除的附件id
      formObj.append('delete_attachment_ids', this.toDeleteAttachmentId.join())
      // 发请求，添加会议
      if (this.isSubmiting) {
        errmsg('请勿重复提交')
        return
      }
      this.isSubmiting = true
      // 发请求，修改
      const { result } = this.$util.uploadFile(
        this.$api.editMeetingInfo,
        formObj
      )
      result.then((res) => {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '编辑会议成功！',
            type: 'success'
          })
          window.localStorage.removeItem(
            `edit_meeting_describe_content_cache_${this.meetingId}`
          )
          // 通知父组件，关闭弹窗，刷新会议列表
          this.$emit('edit-success')
        }
        this.isSubmiting = false
      })
    },
    // 删除附件后，刷新附件列表
    toFresh() {
      if (this.isMeetingDetail) {
        this.getMeetingDetail(this.meetingId)
      }
    },
    // 点击取消按钮
    closeEditMeeting() {
      this.$emit('close-modal')
    },
    // 关闭掉弹窗
    clickOut() {
      this.isChooseRooms = false
      this.isChooseRecorder = false
    },
    // 监听描述变化
    handlerDescribeInput(val) {
      if (this.isEditContent) {
        if (val !== '') {
          window.localStorage.setItem(
            `edit_meeting_describe_content_cache_${this.meetingId}`,
            val
          )
        } else {
          window.localStorage.removeItem(
            `edit_meeting_describe_content_cache_${this.meetingId}`
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 767px) {
  .wap {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
  .add-meeting {
    height: auto !important;
    padding: 0px 0 10px 0px !important;
  }
  .add-meeting .add-meeting-title {
    font-size: 14px !important;
    padding-left: 0px !important;
  }
  .add-meeting .add-meeting-item {
    padding-left: 0px !important;
    margin-bottom: 4px !important;
  }
  .add-meeting .add-meeting-header {
    margin-left: 0px !important;
    padding: 0 0 10px !important;
  }
  .add-meeting .add-meeting-header .iconfont {
    // display: none !important;
  }
  .add-meeting .form-input {
    position: relative;
    z-index: 1;
    margin: 0;
    width: 100% !important;
    padding: 0px !important;
    outline: none;
    border: 1px solid #fff;
    color: #333;
    line-height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .add-member-box .left-box {
    width: 49%;
  }
  .add-meeting .form-input {
    width: 100%;
  }
  .el-picker-panel [slot='sidebar'],
  .el-picker-panel__sidebar {
    width: 80px !important;
  }
  .el-picker-panel [slot='sidebar'] + .el-picker-panel__body,
  .el-picker-panel__sidebar + .el-picker-panel__body {
    margin-left: 80px !important;
  }
  .el-date-picker__time-header {
    width: 90% !important;
  }
  .el-date-picker__header {
    width: 80% !important;
  }
  .el-picker-panel__footer {
    width: 90% !important;
  }
  .el-dialog {
    width: 95% !important;
  }
  .add-member-box .input {
    width: 40% !important;
  }
  .add-meeting .choose-person-box {
    width: 90% !important;
  }
}
.meeting-room-occupy-btn {
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
}
</style>
