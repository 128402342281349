<template>
  <div class="notic-info">
    <div
      class="flex flex-x-between flex-y-center notic-edit_header line-bottom"
    >
      <div>
        <el-button size="small" @click="back()">返回</el-button>
      </div>
      <div>
        <el-button class="button-delete" size="small" @click="deleteNotice"
          >删除</el-button
        >
        <el-button
          class="button-release"
          size="small"
          @click="$emit('edit')"
          v-if="
            noticeInfo.notice_status !== 3 && noticeInfo.notice_status !== 4
          "
          >编辑</el-button
        >
      </div>
    </div>
    <div class="notic-container">
      <h1 class="notic-title">{{ noticeInfo.notice_name }}</h1>
      <div
        v-if="noticeInfo.start_date || noticeInfo.end_date"
        class="notic-time"
      >
        公告时间： {{ getDateStr(noticeInfo.start_date) }} 至
        {{ getDateStr(noticeInfo.end_date) }}
      </div>
      <div
        class="notic-contant htmledit_views"
        v-html="noticeInfo.notice_content_html"
        @click="imageChgange($event)"
      ></div>
      <!-- 刚才上传的附件预览 -->
      <div class="flex flex-wrap notice-file">
        <div v-for="(attachment, index) in appendixList" :key="index">
          <!-- 旧的附件 -->
          <attachment-item
            v-if="attachment.attachment_id && attachment.attachment_id > 0"
            :appendix="attachment"
            fileType="notice"
            :isDeletable="false"
            :noticeId="noticeId"
            style="margin: 12px 0 0 0"
          >
          </attachment-item>
        </div>
      </div>
      <ol class="notic-record">
        <li
          class="record-item"
          v-for="(item, index) in changeLogList"
          :key="index"
        >
          <span class="record-time">
            {{
              item.create_time ? $util.getTimeStr(item.create_time) : ''
            }}</span
          >
          <span class="record-member"
            >{{ item.create_clerk_alias || item.create_clerk_name }}~</span
          >
          <span class="record-event">{{ item.change_type | noticeTip }}</span>
          <span class="record-title">{{ item.after_name || '' }}</span>
        </li>
        <span
          class="look-more"
          @click="getChangeLogList()"
          v-if="changeTotalpage > changePageNumber"
        >
          查看更多>>
        </span>
      </ol>
      <el-image
        ref="previewImageRef"
        :preview-src-list="[previewImageSrc]"
        v-show="false"
      ></el-image>
    </div>
  </div>
</template>

<script>
import AttachmentItem from '../common/AttachmentItem.vue'
export default {
  components: {
    AttachmentItem
  },
  props: {
    noticeInfo: {
      typeof: Object,
      default: () => {
        return {
          notice_name: '',
          start_date: '',
          end_date: '',
          notice_content_html: ''
        }
      }
    },
    noticeId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // 图片预览
      previewImageSrc: '',
      // 记录信息
      changePageNumber: 1,
      changePageSize: 5,
      // 记录信息总页数
      changeTotalpage: 1,
      // 记录列表
      changeLogList: [],
      // 附件列表
      appendixList: []
    }
  },
  mounted() {
    this.refreshChangeLogList()
    console.log(this.noticeId)
    if (this.noticeId !== -1) {
      this.getNoticeDetail()
    }
  },
  filters: {
    noticeTip(v) {
      switch (v) {
        case 1:
          return '创建了通知'
        case 2:
          return '修改了通知'
        case 3:
          return '发布了通知'
        default:
          return ''
      }
    }
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink = Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`)
        : url
    },
    // 记录列表
    async getChangeLogList(rescet = false) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemNoticeChangePage, {
          notice_id: this.noticeInfo.notice_id,
          pageNumber: rescet ? this.changePageNumber : ++this.changePageNumber,
          pageSize: this.changePageSize
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.changeTotalpage = res.page.totalPage
        if (rescet) {
          this.changeLogList = res.page.list
        } else {
          this.changeLogList = this.changeLogList.concat(res.page.list)
        }
      }
    },
    // 刷新修改记录列表
    refreshChangeLogList() {
      this.changePageNumber = 1
      this.getChangeLogList(true)
    },
    // 时间转格式
    getDateStr(v) {
      if (v === '') {
        return ''
      }
      return this.$util.getDateStr(new Date(v))
    },
    // 返回
    back(currentFlag = false) {
      this.$emit('back', currentFlag)
    },
    // 删除
    async deleteNotice() {
      if (this.noticeInfo.notice_id === '') {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemNotice, {
          notice_id: this.noticeInfo.notice_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        })
        this.$emit('deleteBack')
      }
    },
    imageChgange(e) {
      console.log(e.target.src)
      if (e.target.nodeName === 'IMG') {
        this.previewImageSrc = e.target.src
        this.$refs.previewImageRef.showViewer = true
      }
    },
    // 获取通知详情
    async getNoticeDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemNoticeDetailL, {
          notice_id: this.noticeId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.appendixList = res.info.notice_attachment_list
      }
    }
  },
  watch: {
    noticeId(val) {
      this.appendixList = []
      if (val !== -1) {
        this.getNoticeDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notic-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  overflow: scroll;

  .notic-edit_header {
    height: 64px;
    padding: 0 50px 0 24px;
    &::after {
      left: 0;
    }
    .el-button {
      width: 80px;

      &.button-delete {
        background-color: #f6f6f6;
        color: #999;
        border: none;
        &:hover {
          background-color: #ff5454;
          color: #fff;
        }
      }

      &.button-draft {
        border: none;
        color: #fff;
        background-color: #c0c0c0;
      }

      &.button-release {
        border: none;
        color: #fff;
        background-color: $main-color;
      }
    }
  }

  .notic-container {
    width: 863px;
    margin: 28px auto 0;
    .notice-file {
      &::v-deep .appendix-item {
        width: 13.625vw;
      }
      margin-bottom: 20px !important;
    }
    .look-more {
      cursor: pointer;
      font-size: 14px;
      color: $main-color;
      line-height: 28px;
      margin-top: 10px;
      display: inline-block;
    }
    .notic-title {
      margin-bottom: 24px;
    }

    .notic-time {
      margin-bottom: 40px;
    }

    .notic-contant {
      // padding-bottom: 54px;
      // margin-bottom: 44px;
      &::after {
        left: 0;
      }
    }

    .notic-record {
      list-style-type: none;
      counter-reset: sectioncounter;
      height: 30px;
      line-height: 30px;

      .record-item {
        &:before {
          display: inline-block;
          content: counter(sectioncounter) '';
          counter-increment: sectioncounter;
          height: 30px;
          width: 30px;
          color: #fff;
          text-align: center;
          border-radius: 30px;
          margin-right: 16px;
        }
        &:nth-child(odd):before {
          background-color: #ff9854;
        }
        &:nth-child(even):before {
          background-color: #23d4bf;
        }

        span {
          color: #bbb;
          margin-right: 12px;

          &:nth-child(2) {
            color: #333;
          }

          &:last-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
