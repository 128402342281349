/*
 * @Author: hzh
 * @Date: 2021-08-06 14:18:37
 * @LastEditors: hzh
 * @LastEditTime: 2022-02-22 13:54:37
 */
/* eslint-disable */
// 基础链接
// const baseUrl = 'https://cc.zhangzhongyun.com'
// const baseUrl = 'http://zzy.wanxidi.com'
// const baseUrl = 'http://www.nbfurja.com:8035'
// const baseUrl = 'http://127.0.0.1:8080/zzy'
// const baseUrl = 'http://192.168.3.116:8080/zzy'

// 前端链接
// const frontHome = `${baseUrl}/pc/#/`
// const frontHome = 'http://127.0.0.1:8080/pc/#/'

const local = {
  baseUrl: 'http://192.168.110.51:8080/zzy',
  frontHome: 'http://192.168.110.51:8080/pc/#/'
}

const zzy = {
  baseUrl: 'https://cc.zhangzhongyun.com',
  frontHome: `https://cc.zhangzhongyun.com/pc/#/`
}

const wxd = {
  baseUrl: 'http://zzy.wanxidi.com',
  frontHome: `http://zzy.wanxidi.com/pc/#/`
}

const fj = {
  baseUrl: 'http://okr.nbfurja.com',
  frontHome: `http://okr.nbfurja.com/pc/#/`
}

const render = () => {
  switch (process.env.VUE_APP_MODE) {
    case '1': return local
    case '2': return zzy
    case '3': return wxd
    case '4': return fj
  }
}
export default render()

// export default {
//   baseUrl,
//   frontHome
// }
