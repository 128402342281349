<!--
 * @Author: hzh
 * @Date: 2021-12-17 11:59:24
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-30 16:10:43
-->
<template>
  <div class="detail-box">
    <div class="detail-content-box">
      <div class="header-box flex flex-y-center">
        <i
          class="icon-a-arrowaltright1 back-btn"
          @click="toHome"
          v-if="isXs"
        ></i>
        <img
          :src="reportData.clerk_avatar"
          alt="用户头像"
          class="avatar"
        /><span class="text"
          >{{ reportData.clerk_alias || reportData.clerk_name }} 【月报：{{
            month
          }}】</span
        >
      </div>
      <div class="tab-box flex flex-y-center flex-x-between" v-if="!isXs">
        <div class="flex">
          <div
            class="tab-item"
            :class="{ active: currentTab === 'task' }"
            @click="currentTab = 'task'"
          >
            任务
          </div>
          <div
            class="tab-item"
            :class="{ active: currentTab === 'target' }"
            @click="currentTab = 'target'"
          >
            目标
          </div>
        </div>
        <el-button
          plain
          id="select-roc"
          @click.stop="showComment = true"
          type="primary"
          style="margin-left: 16px"
          size="small"
        >
          <i class="el-icon-s-comment"></i>
          <span>评论</span>
        </el-button>
      </div>
      <div class="tab-box flex flex-y-center flex-x-between" v-if="isXs">
        <div
          class="tab-item flex1"
          :class="{ active: currentTab === 'task' }"
          @click="currentTab = 'task'"
        >
          <span class="text">任务</span>
        </div>
        <div
          class="tab-item flex1"
          :class="{ active: currentTab === 'target' }"
          @click="currentTab = 'target'"
        >
          <span class="text">目标</span>
        </div>
        <div
          class="tab-item flex1"
          :class="{ active: currentTab === 'comment' }"
          @click="currentTab = 'comment'"
        >
          <span class="text">评论</span>
        </div>
      </div>
      <div class="tab-content-box" :class="[currentTab]">
        <work-report-month-clerk-task
          ref="workReportMonthClerkTask"
          :data="{
            report_data: reportData,
            prev_report_data: prevReportData,
            data_list: taskDataList,
            columnar_list: taskColumnarList
          }"
          :widthType="isXs ? 'xs' : 'lg'"
          v-show="currentTab === 'task'"
        />
        <work-report-month-clerk-target
          ref="workReportMonthClerkTarget"
          :data="{
            report_data: reportData,
            prev_report_data: prevReportData,
            data_list: objectiveDataList,
            columnar_list: objectiveColumnarList
          }"
          :widthType="isXs ? 'xs' : 'lg'"
          v-show="currentTab === 'target'"
        />
        <work-report-comment
          v-if="isXs"
          :reportId="reportId"
          v-show="currentTab === 'comment'"
        />
      </div>
    </div>
    <work-report-comment-drawer
      v-if="!isXs"
      :reportId="reportId"
      :visible.sync="showComment"
    />
  </div>
</template>

<script>
import WorkReportMonthClerkTask from './components/work-report-month-clerk-task'
import WorkReportMonthClerkTarget from './components/work-report-month-clerk-target'
import WorkReportCommentDrawer from './components/work-report-comment-drawer'
import WorkReportComment from './components/work-report-comment'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    WorkReportMonthClerkTask,
    WorkReportMonthClerkTarget,
    WorkReportCommentDrawer,
    WorkReportComment
  },
  data() {
    return {
      currentTab: 'task',
      showComment: false,
      // 图表数据
      taskDataList: [],
      taskColumnarList: [],
      objectiveDataList: [],
      objectiveColumnarList: [],
      reportData: {},
      prevReportData: {}
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl']),
    // 报表id
    reportId() {
      return Number(this.$route.params.clerkReportId.split('_')[1])
    },
    // 职员id
    clerkId() {
      return this.$route.params.clerkReportId.split('_')[0]
    },
    // 月
    month() {
      if (!this.reportData.start_date) {
        return ''
      }
      const startDate = new Date(
        this.reportData.start_date.replaceAll('-', '/')
      )
      return this.$util.toDateString(startDate, 'yyyy年MM月')
    }
  },
  methods: {
    // 获取数据
    async getData() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportClerkData, {
          report_id: this.reportId,
          clerk_id: this.clerkId,
          cycle_type: 2
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.taskDataList = res.task_data_list
          this.taskColumnarList = res.task_columnar_list
          this.objectiveDataList = res.objective_data_list
          this.objectiveColumnarList = res.objective_columnar_list
          this.reportData = res.report || {}
          this.prevReportData = res.prev_report || {}
          this.renderChart()
        }
      }
    },
    // 刷新数据
    async refreshData() {
      await this.getData()
    },
    // 渲染图表
    renderChart() {
      switch (this.currentTab) {
        case 'task': {
          this.$refs.workReportMonthClerkTask.renderChart()
          break
        }
        case 'target': {
          this.$refs.workReportMonthClerkTarget.renderChart()
          break
        }
      }
    },
    toHome() {
      this.$router.push({
        name: 'home'
      })
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler(val) {
        this.refreshData()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-box {
  width: 100%;
}
.detail-content-box {
  width: 1300px;
  padding: 0 50px 50px 50px;
  margin: 0 auto;
}
.header-box {
  height: 60px;
  border-bottom: 1px solid #d7d7d7;

  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    margin-right: 15px;
  }
  .text {
    color: #333333;
  }
}
.tab-box {
  padding: 13px 0;

  .tab-item {
    width: 80px;
    height: 32px;
    padding: 0 2px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #fffefe;
    color: #333333;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    &.active {
      background-color: #5578eb;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .detail-box {
    padding: 0 !important;
    overflow-x: hidden;
    width: 100%;
    background-color: #f6f6f6;
    height: 100vh;
  }
  .detail-content-box {
    width: 100%;
    padding: 100px 0 0 0;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  .header-box {
    position: fixed;
    width: 100%;
    height: 50px;
    top: 0;
    z-index: 10;
    padding: 0 10px;
    background-color: #fff;
    border-bottom: 0;

    .back-btn {
      height: 32px;
      width: 20px;
      line-height: 32px;
      margin-right: 5px;
      text-align: center;
    }

    .avatar {
      width: 26px;
      height: 26px;
    }
  }
  .tab-box {
    position: fixed;
    top: 50px;
    height: 50px;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(31, 35, 41, 0.1);

    .tab-item {
      color: #333333;
      text-align: center;
      font-size: 14px;
      border-top: 0;
      border-left: 0;
      border-bottom: 0;
      border-right: 2px solid #eee;
      border-radius: 0;
      background-color: #fff;
      margin-right: 0;

      &:last-child {
        border-right: 0;
      }
      .text {
        position: relative;
      }

      &.active {
        color: #5578eb;
        background-color: #fff;
        .text::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -8px;
          left: -5px;
          width: calc(100% + 10px);
          height: 3px;
          background-color: #5578eb;
          border-radius: 2px;
        }
      }
    }
  }
  .tab-content-box {
    padding: 10px;
    min-height: 100%;
    box-sizing: border-box;
    &.comment {
      height: 100%;
      padding: 10px 15px;
      background-color: #fff;
    }
  }
  .comment-box::v-deep {
    .expression-box {
      left: -73px !important;
    }
  }
}
</style>
