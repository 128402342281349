<template>
  <div
    class="flex knowledge-move-mask flex flex-x-center flex-y-center"
    @click.stop="$emit('cancle')"
  >
    <div
      class="knowledge-move-pop flex flex-y-positive flex-y-between"
      @click.stop
    >
      <span>移动到</span>
      <div class="move-box">
        <!-- 知识库树形结构可移动 -->
        <el-tree
          :data="data"
          node-key="id"
          :load="load"
          :props="{ isLeaf: 'leaf' }"
          lazy
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span class="flex flex-y-center">
              <i class="iconfont" :class="data.icon"> </i>
              <p>
                {{ data.label }}
              </p>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="flex button-box">
        <button class="move" @click.stop="confirm">移 动</button>
        <el-button @click.stop="$emit('cancle')">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'knowledge-move-pop',
  components: {},
  props: {
    // 移动的文件夹id列表
    folderIdList: {
      typeof: Array,
      default: () => {
        return []
      }
    },
    // 移动的文件id列表
    fileIdList: {
      typeof: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      data: [
        {
          id: '1',
          label: '公司知识库',
          icon: 'icon-a-icon11',
          children: [
            {
              id: '4',
              label: '行政',
              children: [
                {
                  id: '7',
                  label: '人力资源',
                  children: [
                    {
                      label: '入职须知'
                    },
                    {
                      label: '什么是OKR'
                    }
                  ]
                },
                {
                  label: '人力资源',
                  children: [
                    {
                      label: '入职须知'
                    },
                    {
                      label: '什么是OKR'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: '2',
          label: '团队知识库',
          icon: 'icon-a-3User',
          children: [
            {
              id: '5',
              label: '人力资源',
              children: [
                {
                  id: '9',
                  label: '入职须知'
                },
                {
                  id: '10',
                  label: '薪酬考勤制度'
                }
              ]
            },
            {
              id: '6',
              label: '行政管理',
              children: [
                {
                  id: '11',
                  label: '行政制度',
                  children: [
                    {
                      id: '7',
                      label: '办公室管理制度'
                    },
                    {
                      label: '固定资产管理制度'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      currentKey: ''
    }
  },
  computed: {},
  methods: {
    handleDragStart(node, ev) {
      console.log('drag start', node)
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.label)
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.label)
    },
    handleDragOver(draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.label)
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.label, dropType)
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', dropNode.label, dropType)
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === '二级 3-1') {
        return type !== 'inner'
      } else {
        return true
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf('三级 3-2-2') === -1
    },
    // 确定
    confirm() {
      if (this.currentKey === '') {
        this.$message({
          showClose: true,
          message: '请选择文件夹',
          type: 'error'
        })
        return
      }
      this.$emit('confirm', this.currentKey)
    },
    async load(node, resolve) {
      const getList = async (folderId) => {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.getKnowlAllowMoveList, {
            move_folder_ids: this.folderIdList.join(),
            move_knowl_ids: this.fileIdList.join(),
            folder_id: folderId
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          resolve(
            res.list.map((e) => {
              return {
                id: e.folder_id,
                label: e.folder_name
              }
            })
          )
        }
      }
      if (node.level === 0) {
        getList(0)
        return
      }
      getList(node.key)
    },
    handleNodeClick(data) {
      this.currentKey = data.id
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .wap {
    display: none !important;
  }
  .show {
    display: none;
  }

  .knowledge-move-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    .knowledge-move-pop {
      width: 572px;
      height: 442px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 28px 0 28px 26px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #333333;
      }
      .move-box {
        width: 520px;
        height: 280px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        margin-top: 20px;
        overflow: hidden;
        overflow-y: scroll;
      }
      .button-box {
        margin-top: 24px;
        height: 32px;
        .move {
          width: 80px;
          height: 32px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
  }
  .el-tree::v-deep {
    width: 290px;
    padding: 10px 0 0 10px;
    background: #ffffff;
    .el-tree-node__content {
      height: 50px;
      background: #ffffff;
      // padding-left: 30px !important;
      &:hover {
        background: #f0f0f0;
      }
    }
    .el-tree-node__children {
      .iconfont {
        display: none;
      }
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: #f0f0f0 !important;
    }
    .custom-tree-node {
      .iconfont {
        margin-left: 6px;
        font-size: 23px;
        color: #999999;
      }
      p {
        margin-left: 6px;
        font-style: normal;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  // 方向图标修改
  .el-tree::v-deep .el-icon-caret-right:before {
    content: '\e63a'; //在引入的iconfont文件夹找到iconfont.css
    font-size: 16px;
    font-style: normal;
    font-family: 'iconfont'; //想要显示icon这个必须加
    margin-left: 20px;
  }
  .el-tree::v-deep
    .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: '\e63b';
    font-size: 6px;
    font-style: normal;
    font-family: 'iconfont';
  }
  .el-tree::v-deep .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree::v-deep
    .el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    padding: 0;
    margin: 0;
  }
  .el-tree::v-deep .el-tree-node__expand-icon.is-leaf {
    padding: 0;
    margin: 4px;
  }
  .el-tree::v-deep .el-tree-node__expand-icon.el-icon-caret-right {
    padding: 0;
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none !important;
  }

  .knowledge-move-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    .knowledge-move-pop {
      width: 90%;
      height: 442px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 28px 0 28px 26px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #333333;
      }
      .move-box {
        width: 90%;
        height: 280px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        margin-top: 20px;
        overflow: hidden;
        overflow-y: scroll;
      }
      .button-box {
        margin-top: 24px;
        height: 32px;
        .move {
          width: 80px;
          height: 32px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
  }
  .el-tree::v-deep {
    width: 100%;
    padding: 10px 0 0 10px;
    background: #ffffff;
    .el-tree-node__content {
      height: 50px;
      background: #ffffff;
      // padding-left: 30px !important;
      &:hover {
        background: #f0f0f0;
      }
    }
    .el-tree-node__children {
      .iconfont {
        display: none;
      }
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: #f0f0f0 !important;
    }
    .custom-tree-node {
      .iconfont {
        margin-left: 6px;
        font-size: 23px;
        color: #999999;
      }
      p {
        margin-left: 6px;
        font-style: normal;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  // 方向图标修改
  .el-tree::v-deep .el-icon-caret-right:before {
    content: '\e63a'; //在引入的iconfont文件夹找到iconfont.css
    font-size: 16px;
    font-style: normal;
    font-family: 'iconfont'; //想要显示icon这个必须加
    margin-left: 20px;
  }
  .el-tree::v-deep
    .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: '\e63b';
    font-size: 6px;
    font-style: normal;
    font-family: 'iconfont';
  }
  .el-tree::v-deep .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree::v-deep
    .el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    padding: 0;
    margin: 0;
  }
  .el-tree::v-deep .el-tree-node__expand-icon.is-leaf {
    padding: 0;
    margin: 4px;
  }
  .el-tree::v-deep .el-tree-node__expand-icon.el-icon-caret-right {
    padding: 0;
  }
}
</style>
