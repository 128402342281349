<template>
  <div
    class="comment-dialog flex flex-x-center flex-y-center"
    @click.stop="$emit('close', $event)"
  >
    <div class="dialog-container" @click.stop="">
      <div class="dialog-header flex flex-x-between flex-y-center line-bottom">
        <div>{{ title }}</div>
        <div class="close-button">
          <i class="icon-decline" @click="$emit('close', $event)"></i>
        </div>
      </div>
      <div class="dialog-content">
        <!-- <el-input
          type="textarea"
          :placeholder="placeholder"
          :value="content"
          @input="$emit('input', $event)"
          ref="contentRef"
          resize="none"
          :autofocus="true"
        >
        </el-input> -->
        <CommentEditEditor
          @input="handellInput"
          :title="placeholder"
          :value="commentContent"
          ref="contentRef"
        ></CommentEditEditor>
      </div>
      <div class="flex dialog-footer flex-x-between">
        <expression-box
          :isShow="isShowExpressBox"
          :expressList="$emoji.slice(0, 100)"
          :left="'0'"
          @add-express="addContentExpress($event)"
        >
          <div
            class="icon-a-1 icon-button"
            @click.stop="toggleExpressBox"
          ></div>
        </expression-box>

       <div class="reply-button" @click="$emit('send')">
          {{ sendBtnText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入表情组件
import ExpressionBox from '@/components/common/ExpressionBox.vue'
// 导入编辑器
import CommentEditEditor from '@/components/common/CommentEditEditor1.vue'
export default {
  components: {
    ExpressionBox,
    CommentEditEditor
  },
  model: {
    prop: 'commentContent',
    event: 'input'
  },
  props: {
    title: {
      type: String,
      default: '评论'
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    content: {
      type: String,
      default: ''
    },
    sendBtnText: {
      type: String,
      default: '回复'
    }
  },
  data() {
    return {
      isShowExpressBox: false,
      commentContent: ''
    }
  },
  mounted() {
    // esc关闭
    this.$nextTick(() => {
      document.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          this.$emit('close')
        }
      })
    })
  },
  methods: {
    handellInput(val) {
      this.commentContent = val
      this.$emit('input', this.commentContent)
    },
    // 在内容里添加一个表情
    addContentExpress(obj) {
      this.commentContent = this.commentContent.replace('<p>&nbsp;', '<p>')
      const index = this.commentContent.lastIndexOf('</p>')
      if (index === -1) {
        this.commentContent = '<p>' + obj.name + '</p>'
      } else {
        this.commentContent =
          this.commentContent.substring(0, index) + obj.name + '</p>'
      }
      this.$emit('input', this.commentContent)
      // 关闭白框
      this.isShowExpressBox = false
      setTimeout(() => {
        // 让输入框重新聚焦
        this.$refs.contentRef.setFocus()
      }, 50)
    },
    // 显示隐藏表情框
    toggleExpressBox() {
      this.isShowExpressBox = !this.isShowExpressBox
    }
  },
  watch: {
    content(val) {
      this.commentContent = val
    }
  }
  // handlerContentToUrl(url) {
  //   const reg = new RegExp(
  //     '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
  //   )
  //   const urlRes = reg.exec(url)
  //   const hasLink = Object.prototype.toString.call(urlRes) === '[object Array]'
  //   return hasLink
  //     ? url.replace(
  //       reg,
  //       `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
  //     )
  //     : url
  // }
}
</script>

<style lang="scss" scoped>
.comment-dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;

  .dialog-container {
    width: 620px;
    height: 210px;
    background-color: #fff;
    border-radius: 6px;

    .dialog-header {
      height: 21px;
      padding: 11px 17px 11px 26px;
      .close-button {
        i {
          padding: 6px;
          color: $main-color;
          font-weight: 400;
          font-size: 14px;
          background: rgba(0, 128, 255, 0.1);
          border-radius: 5px;
        }
      }

      &.line-bottom::after {
        left: 0;
      }
    }

    .dialog-content {
      padding: 16px 26px;

      .el-textarea {
        ::v-deep textarea {
          height: 85px;
        }
      }
    }

    .dialog-footer {
      padding: 0 26px;

      .icon-button {
        cursor: pointer;
        color: #ffb72c;
        font-size: 28px;
      }

      .reply-button {
        cursor: pointer;
        padding: 6px 23px;
        background-color: $main-color;
        border-radius: 26px;
        color: #fff;
      }
    }
  }
}
</style>
