<!--
 * @Author: hzh
 * @Date: 2021-12-17 11:32:31
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-30 14:42:32
-->
<template>
  <div class="main-container " :class="{ 'hide-header': !showNav }">
    <template v-if="showNav">
      <template v-if="!isXs">
        <el-menu :default-active="active" class="menu-box" router>
          <template v-for="(item, index) in menuList">
            <template v-if="item.groupName">
              <div class="menu-group-item" :key="index">
                {{ item.groupName }}
              </div>
              <template v-for="citem in item.children">
                <el-menu-item
                  :index="citem.id"
                  class="menu-item"
                  :route="citem.route"
                  :key="citem.id"
                >
                  <i :class="citem.icon"></i>
                  <span>{{ citem.name }}</span>
                </el-menu-item>
              </template>
            </template>
            <template v-else>
              <el-menu-item
                :index="item.id"
                class="menu-item"
                :route="item.route"
                :key="item.id"
              >
                <i :class="item.icon"></i>
                <span>{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </template>
      <template v-if="isXs">
        <img src="@/assets/img/more-banner.png" class="banner" />
        <el-menu
          :default-active="active"
          class="menu-box-xs flex flex-wrap"
          router
        >
          <template v-for="item in menuList">
            <template v-if="item.groupName">
              <!-- <div class="menu-group-item" :key="index">{{ item.groupName }}</div> -->
              <template v-for="citem in item.children">
                <el-menu-item
                  :index="citem.id"
                  class="menu-item flex flex-y-positive flex-y-center"
                  :route="citem.route"
                  :key="citem.id"
                >
                  <img class="img" :src="citem.iconImg" />
                  <span class="text">{{ citem.name }}</span>
                </el-menu-item>
              </template>
            </template>
            <!-- <template v-else>
            <el-menu-item
              :index="item.id"
              class="menu-item"
              :route="item.route"
              :key="item.id"
            >
              <i :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template> -->
          </template>
        </el-menu>
      </template>
    </template>
    <template v-if="showContent">
      <div class="content-box">
        <router-view></router-view>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store'
export default {
  data() {
    return {
      active: 'workReportWeek'
    }
  },
  computed: {
    ...mapState(['screenWidth']),
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl']),
    menuList() {
      return [
        {
          groupName: '数据分析',
          children: [
            {
              id: 'workReportWeek',
              icon: 'icon-zhoubao',
              name: '周报',
              route: {
                name: 'workReportWeek'
              },
              childRouteName: ['workReportWeek'],
              iconImg: require('@/assets/img/icon-zhoubao.png')
            },
            {
              id: 'workReportMonth',
              icon: 'icon-yuebao',
              name: '月报',
              route: {
                name: 'workReportMonth'
              },
              childRouteName: ['workReportMonth'],
              iconImg: require('@/assets/img/icon-yuebao.png')
            }
          ]
        }
      ]
    },
    menuRouteNameList() {
      const list = []
      this.menuList.forEach((e) => {
        if (e.groupName) {
          e.children.forEach((ce) => {
            list.push({
              id: ce.id,
              routeName: ce.childRouteName
            })
          })
        } else {
          list.push({
            id: e.id,
            routeName: e.childRouteName
          })
        }
      })
      return list
    },
    showNav() {
      if (this.isXs) {
        if (
          this.$route.matched.length === 2 &&
          this.$route.matched[1].name === 'more'
        ) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    showContent() {
      if (this.isXs) {
        if (
          this.$route.matched.length === 2 &&
          this.$route.matched[1].name === 'more'
        ) {
          return false
        } else {
          return true
        }
      }
      return true
    }
  },
  methods: {},
  mounted() {
    // if (!this.isXs) {
    //   this.$router.replace({
    //     name: 'workReportWeek'
    //   })
    // }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'more') {
      if (!store.getters.isXs) {
        next({
          name: 'workReportWeek'
        })
        return
      }
    }
    next()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'more') {
      if (!this.isXs) {
        next({
          name: 'workReportWeek'
        })
        return
      }
    }
    next()
  },
  watch: {
    $route: {
      handler(val) {
        const info = this.menuRouteNameList.find((e) => {
          return e.routeName.includes(val.name)
        })
        if (info) {
          this.active = info.id
        } else {
          this.active = 'workReportWeek'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  min-height: calc(100vh - 62px);
  padding: 15px 70px 20px 300px;
  box-sizing: border-box;
  width: 100%;
  min-width: 1570px;
}
.menu-box {
  position: fixed;
  top: 75px;
  left: 70px;
  height: calc(100vh - 60px - 15px - 20px);
  width: 210px;
  border-right: 0;
  // margin-right: 20px;
  .menu-item {
    height: 45px;
    line-height: 45px;
    &.is-active {
      background-color: #ecf5ff;
      border-right: 2px solid rgba(0, 102, 255, 1);
    }
  }
  .menu-group-item {
    height: 45px;
    line-height: 45px;
    color: #999999;
    padding-left: 20px;
  }
  .el-menu-item i[class^='icon-'] {
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
  }
}
.content-box {
  // min-width: 1200px;
  height: 100%;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .main-container {
    min-height: calc(100vh - 65px);
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    min-width: auto;
    &.hide-header {
      height: 100vh;
    }
  }
  .banner {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    // height: 165px;
  }
  .menu-box-xs {
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    border: 0;
    .menu-item {
      width: 25%;
      height: auto;
      padding: 0 !important;
      .img {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        margin-bottom: 10px;
      }
      .text {
        line-height: 1;
        text-align: center;
        color: #333333;
      }

      &.is-active {
        .text {
          color: #333333;
        }
      }
    }
  }
  .content-box {
    min-width: auto;
    width: 100%;
  }
}
</style>
