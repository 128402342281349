var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scroll-fresh',{attrs:{"className":"topic-container","page":_vm.pageNumber,"lastPage":_vm.totalPage,"showNoMore":false},on:{"get-next-page":_vm.getNextCommentPage}},[_c('div',{staticClass:"flex member-info"},[_c('div',{staticClass:"member-avatar"},[_c('el-image',{attrs:{"src":_vm._f("httpToHttps")(_vm.info.create_clerk_avatar)},on:{"click":function($event){return _vm.clickAvatar(_vm.info.create_clerk_id)}}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1),_c('div',{staticClass:"member-content"},[_c('div',{staticClass:"member-name"},[_c('span',[_vm._v(_vm._s(_vm.info.create_clerk_alias || _vm.info.create_clerk_name || ''))])]),_c('div',{staticClass:"member-remark"},[_vm._v(_vm._s(_vm.info.create_clerk_motto || ''))])]),_c('div',{staticClass:"more-button",on:{"click":function($event){$event.stopPropagation();return _vm.handerShowMenu.apply(null, arguments)}}},[_c('i',{staticClass:"icon-gengduo2"})]),(_vm.showMenu)?_c('transition',{attrs:{"name":"el-fade-in-linear"}},[_c('div',{staticClass:"message-menu"},[(false)?_c('div',{staticClass:"menu-item"},[_vm._v("编辑")]):_vm._e(),(_vm.isShowDelete)?_c('div',{staticClass:"menu-item red",on:{"click":_vm.handerDelete}},[_vm._v(" 删除 ")]):_vm._e(),_c('div',{staticClass:"menu-item",on:{"click":_vm.copyLink}},[_vm._v("复制链接")])])]):_vm._e()],1),_c('div',{staticClass:"topic-content"},[(_vm.info.content_type === 2)?_c('div',{staticClass:"topic-video"},[_c('video',{attrs:{"src":_vm.info.video_info.cover_video_path,"controls":""}})]):_vm._e(),(_vm.info.moment_title)?_c('div',{staticClass:"topic-title",domProps:{"innerHTML":_vm._s(_vm.renderMomentTitle)},on:{"click":_vm.addLink}}):_vm._e(),(_vm.info.moment_content)?_c('div',{staticClass:"topic-text text-warp",domProps:{"innerHTML":_vm._s(
        _vm.handlerContentToUrl(_vm.getContenHtml(_vm.info)).replace(
          /\[[^\[\]]*\]/gi,
          _vm.emotion
        )
      )},on:{"click":_vm.addLink}}):_vm._e(),_vm._l((_vm.info.image_list),function(item,index){return _c('div',{key:index,staticClass:"topic-image"},[(_vm.screenWidth > 768)?_c('el-image',{attrs:{"src":item.cover_access_path}}):_c('el-image',{attrs:{"src":item.cover_access_path},on:{"click":function($event){return _vm.handlerPreviewImg(_vm.info.image_list, index)}}})],1)}),_c('div',{staticClass:"flex topic-interactive"},[_c('div',[_vm._v(_vm._s(_vm.info.create_time))]),_c('message-interactive',{attrs:{"browseNum":_vm.info.visit_number,"commentNum":_vm.info.comment_number,"likeNum":_vm.info.praise_number,"isPraise":_vm.info.is_praise},on:{"trigger":_vm.handlerInteractive}})],1),_c('div',{staticClass:"topic-comment"},[_c('div',{staticClass:"title-bar"},[_vm._v("评论")]),_c('div',{staticClass:"comment-list"},_vm._l((_vm.commentList),function(item,index){return _c('div',{key:item.comment_id,staticClass:"comment-item"},[_c('comment-member',{attrs:{"avatar":item.clerk_avatar,"name":item.clerk_alias || item.clerk_name,"time":item.create_time,"likeNum":item.praise_number,"isLike":item.is_praise == 1,"showDelete":item.comment_id === _vm.showCommentDeleteId},on:{"onReply":function($event){return _vm.onReply(index)},"onPraise":function($event){return _vm.commentPraise(index)},"onDelete":function($event){return _vm.onDelete(index)},"clickAvatar":function($event){return _vm.clickAvatar(item.create_clerk_id)}},nativeOn:{"mouseenter":function($event){return _vm.showCommentDelete(item)},"mouseleave":function($event){return _vm.hideDelete()}}}),_c('div',{staticClass:"comment-content"},[_vm._l((item.child_list_pack_up
                ? item.child_list.slice(0, 3 + item.show_child_list_offset)
                : item.child_list),function(cItem,cIndex){return _c('comment-reply',{key:cItem.comment_id,attrs:{"show-reply-to-name":cItem.pid !== cItem.tid,"replyInfo":cItem,"showDelete":_vm.showCommentDeleteId == cItem.comment_id},on:{"onReply":function($event){return _vm.onChildReply(index, cIndex)},"onDelete":function($event){return _vm.onChildDelete(index, cIndex)},"onPraise":function($event){return _vm.commentChildPraise(index, cIndex)},"appenLink":function($event){return _vm.addLink($event)},"clickAvatar":_vm.clickAvatar},nativeOn:{"mouseenter":function($event){return _vm.showCommentDelete(cItem)},"mouseleave":function($event){return _vm.hideDelete()}}})}),(item.reply_number - item.show_child_list_offset > 3)?[(item.child_list_pack_up)?_c('comment-total-bar',{attrs:{"replyNumber":item.reply_number,"lastestName":item.lastest_clerk_alias || item.lastest_clerk_name},on:{"open":function($event){return _vm.getCommentChildNextPage(index)}}}):_vm._e(),(!item.child_list_pack_up)?_c('div',{staticClass:"line-top flex flex-x-between comment-pack-load-bar"},[_c('span',{staticStyle:{"color":"#0080ff","cursor":"pointer"},on:{"click":function($event){return _vm.closeChildCommentList(index)}}},[_c('i',{staticClass:"icon-zhankai pack-up"}),_vm._v(" 收起 ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                    item.child_list_page_number < item.child_list_total_page
                  ),expression:"\n                    item.child_list_page_number < item.child_list_total_page\n                  "}],staticStyle:{"color":"#999","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.getCommentChildNextPage(index)}}},[_c('i',{staticClass:"icon-zhankai"}),_vm._v(" 加载更多 ")])]):_vm._e()]:_vm._e()],2)],1)}),0),_c('comment-no-more')],1)],2),(_vm.commentDetail !== -1)?_c('comment-dialog',{attrs:{"info":_vm.commentList[_vm.commentDetail]},on:{"close":function($event){_vm.commentDetail = -1},"praise":_vm.dialogPraise,"comment":_vm.dialogComment}}):_vm._e(),(_vm.isShowReply)?_c('reply-dialog',{attrs:{"title":_vm.replyTitle},on:{"close":function($event){_vm.isShowReply = false},"send":_vm.sendComment},model:{value:(_vm.replyText),callback:function ($$v) {_vm.replyText=$$v},expression:"replyText"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }