<template>
  <div class="flex crop-dialog-mask" @click.stop="$emit('close')">
    <div class="crop-dialog" @click.stop="">
      <div class="flex dialog-header">
        <div class="title">{{ title }}</div>
        <div @click="$emit('close')" class="icon-decline close-button"></div>
      </div>
      <div class="line-top">
        <div class="croper-container">
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.outputSize"
            :outputType="option.outputType"
            :info="option.info"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :autoCropWidth="autoCropWidth"
            :autoCropHeight="autoCropHeight"
            :fixedBox="option.fixedBox"
            :high="option.high"
            :enlarge="option.enlarge"
            :mode="option.mode"
          ></vue-cropper>
        </div>
      </div>
      <el-upload
        class="upload-demo"
        action="#"
        accept=".jpg,.jpeg,.bmp,.gif,.png,.heif,.heic"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleChangeImage"
      >
        <div class="select-button">{{funtionName}}</div>
      </el-upload>
      <div class="flex button-bar">
        <div @click="$emit('close')">取消</div>
        <div @click="handleCubeImgAndSave">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  components: {
    VueCropper
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '选择图片'
    },
    // 截取宽度
    autoCropWidth: {
      type: Number,
      default: 400
    },
    // 截取高度
    autoCropHeight: {
      type: Number,
      default: 200
    },
    // 功能说明
    funtionName: {
      type: String,
      default: '更换背景'
    }
  },
  data() {
    return {
      option: {
        img: '',
        info: true,
        outputSize: 1,
        full: false,
        outputType: 'png',
        canMove: true,
        original: false,
        autoCrop: true,
        fixedBox: true,
        high: true,
        enlarge: 8,
        mode: '100% auto'
      }
    }
  },
  methods: {
    handleChangeImage(file) {
      if (!this.beforeAvatarUpload(file.raw)) {
        this.$message.error('图片格式有误！')
        return
      }
      this.option.img = URL.createObjectURL(file.raw)
    },
    // 校验图片格式
    beforeAvatarUpload(file) {
      return [
        'image/jpeg',
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/gif',
        'image/heif',
        'image/heic'
      ].includes(file.type)
    },
    handleCubeImgAndSave() {
      this.$refs.cropper.getCropData((data) => {
        // this.option.img = ''
        // base64转file
        const file = this.$util.handleBase64ToBlob(data)
        this.$emit('confirm', {
          file,
          base64: data
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.crop-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .crop-dialog {
    width: 700px;
    height: 484px;
    background-color: #fff;
    border-radius: 4px;

    .dialog-header {
      justify-content: space-between;
      align-items: center;
      padding: 11px 18px 11px 26px;

      .title {
        font-weight: normal;
      }

      .close-button {
        cursor: pointer;
        color: $main-color;
        padding: 8px;
        font-size: 12px;
        border-radius: 6px;
        background-color: rgba(0, 128, 255, 0.1);
      }
    }

    .croper-container {
      padding: 16px 26px 0;
      width: calc(100% - 52px);
      height: 315px;
      margin-bottom: 5px;
    }

    .select-button {
      color: $main-color;
      line-height: 21px;
      padding: 0 26px;
    }

    .button-bar {
      justify-content: center;

      div {
        padding: 5px 26px;
        font-weight: 500px;
        height: 21px;
        line-height: 21px;
        border-radius: 4px;
        cursor: pointer;

        &:first-child {
          border: 1px solid #eeeeee;
          color: #bbb;
          margin-right: 20px;
        }

        &:last-child {
          color: #fff;
          background-color: $main-color;
        }
      }
    }
  }
}
</style>
