<template>
    <div v-if="show" class="el-zoom-in-bottom filtter-pop" @click="show = false">
        <div class="filtter-content">
            <div v-for="(item, index) in menuList" :key="index" :class="{'line-bottom': index < menuList.length - 1}" class="filtter-item" @click="$emit('change', index)">{{item}}</div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.filtter-pop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  .filtter-content {
    position: absolute;
    width: calc(100% - 30px);
    bottom: 0;
    padding: 0 15px;
    background-color: #fff;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .filtter-item {
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      width: 100%;

      &.line-bottom:after {
        left: 0;
      }
    }
  }
}
</style>
