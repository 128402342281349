<!--
 * @Author: Betty
 * @Date: 2021-08-11 17:34:57
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-09 13:49:40
 * @Description: 表情组件
-->
<template>
  <div class="expression-btn-box">
    <!-- 表情盒子 -->
    <!-- <div
      id="emojiText"
      contenteditable="true"
      ref="edit"
      placeholder="请输入内容"
    ></div> -->
    <div class="expression-box" v-if="isShow">
      <!-- <emotion @emotion="handleEmotion"></emotion> -->
      <ul>
        <li v-for="item in emojis" :key="item.name">
          <img :src="item.path" alt="" :title="item.name" @click="getEmojis(item)" />
        </li>
      </ul>
    </div>
    <!-- 插槽，里面的东西是表情按钮，有默认样式，点击控制白框的显示隐藏 -->
    <slot>
      <button class="express-btn" @click.stop="toggleCommentExpression">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-icon"></use>
        </svg>
      </button>
    </slot>
  </div>
</template>

<script>
// import Emotion from '../common/Emotion/index.vue'
export const emojis = [
  {
    name: '[咧嘴]',
    path: require('../../assets/img/face/53.gif')
  },
  {
    name: '[大眼]',
    path: require('../../assets/img/face/54.gif')
  },
  {
    name: '[笑脸]',
    path: require('../../assets/img/face/55.gif')
  },
  {
    name: '[灿烂]',
    path: require('../../assets/img/face/56.gif')
  },
  {
    name: '[满意]',
    path: require('../../assets/img/face/57.gif')
  },
  {
    name: '[汗笑]',
    path: require('../../assets/img/face/58.gif')
  },
  {
    name: '[大笑]',
    path: require('../../assets/img/face/59.gif')
  },
  {
    name: '[笑哭]',
    path: require('../../assets/img/face/60.gif')
  },
  {
    name: '[微笑]',
    path: require('../../assets/img/face/61.gif')
  },
  {
    name: '[倒脸]',
    path: require('../../assets/img/face/35.gif')
  },
  {
    name: '[眨眼]',
    path: require('../../assets/img/face/67.gif')
  },
  {
    name: '[脸红]',
    path: require('../../assets/img/face/103.gif')
  },
  {
    name: '[天使]',
    path: require('../../assets/img/face/64.gif')
  },
  {
    name: '[恋爱]',
    path: require('../../assets/img/face/68.gif')
  },
  {
    name: '[花痴]',
    path: require('../../assets/img/face/69.gif')
  },
  {
    name: '[星光]',
    path: require('../../assets/img/face/70.gif')
  },
  {
    name: '[飞吻]',
    path: require('../../assets/img/face/71.gif')
  },
  {
    name: '[亲亲]',
    path: require('../../assets/img/face/72.gif')
  },
  {
    name: '[放松]',
    path: require('../../assets/img/face/73.gif')
  },
  {
    name: '[亲吻]',
    path: require('../../assets/img/face/74.gif')
  },
  {
    name: '[亲脸]',
    path: require('../../assets/img/face/75.gif')
  },
  {
    name: '[感动]',
    path: require('../../assets/img/face/76.gif')
  },
  {
    name: '[美味]',
    path: require('../../assets/img/face/77.gif')
  },
  {
    name: '[调皮]',
    path: require('../../assets/img/face/78.gif')
  },
  {

    name: '[俏皮]',
    path: require('../../assets/img/face/80.gif')
  },
  {
    name: '[滑稽]',
    path: require('../../assets/img/face/98.gif')
  },
  {
    name: '[眯眼]',
    path: require('../../assets/img/face/81.gif')
  },
  {
    name: '[钱眼]',
    path: require('../../assets/img/face/82.gif')
  },
  {
    name: '[拥抱]',
    path: require('../../assets/img/face/83.gif')
  },
  {
    name: '[捂嘴]',
    path: require('../../assets/img/face/84.gif')
  },
  {
    name: '[嘘]',
    path: require('../../assets/img/face/85.gif')
  },
  {
    name: '[思考]',
    path: require('../../assets/img/face/86.gif')
  },
  {
    name: '[闭嘴]',
    path: require('../../assets/img/face/87.gif')
  },
  {
    name: '[怀疑]',
    path: require('../../assets/img/face/88.gif')
  },
  {
    name: '[面无表情]',
    path: require('../../assets/img/face/97.gif')
  },
  {
    name: '[发呆]',
    path: require('../../assets/img/face/91.gif')
  },
  {
    name: '[沉默]',
    path: require('../../assets/img/face/92.gif')
  },
  {
    name: '[坏笑]',
    path: require('../../assets/img/face/93.gif')
  },
  {
    name: '[无趣]',
    path: require('../../assets/img/face/94.gif')
  },
  {
    name: '[白眼]',
    path: require('../../assets/img/face/95.gif')
  },
  {
    name: '[鬼脸]',
    path: require('../../assets/img/face/104.gif')
  },
  {
    name: '[匹诺曹]',
    path: require('../../assets/img/face/62.gif')
  },
  {
    name: '[轻松]',
    path: require('../../assets/img/face/102.gif')
  },
  {
    name: '[沉思]',
    path: require('../../assets/img/face/3.gif')
  },
  {
    name: '[困倦]',
    path: require('../../assets/img/face/5.gif')
  },
  {
    name: '[流口水]',
    path: require('../../assets/img/face/7.gif')
  },
  {
    name: '[睡觉]',
    path: require('../../assets/img/face/6.gif')
  },
  {
    name: '[口罩]',
    path: require('../../assets/img/face/8.gif')
  },
  {
    name: '[生病]',
    path: require('../../assets/img/face/9.gif')
  },
  {
    name: '[绷带]',
    path: require('../../assets/img/face/10.gif')
  },
  {
    name: '[恶心]',
    path: require('../../assets/img/face/11.gif')
  },
  {
    name: '[呕吐]',
    path: require('../../assets/img/face/4.gif')
  },
  {
    name: '[喷嚏]',
    path: require('../../assets/img/face/13.gif')
  },
  {
    name: '[热]',
    path: require('../../assets/img/face/14.gif')
  },
  {
    name: '[冷]',
    path: require('../../assets/img/face/15.gif')
  },
  {
    name: '[昏沉]',
    path: require('../../assets/img/face/16.gif')
  },
  {
    name: '[难受]',
    path: require('../../assets/img/face/17.gif')
  },
  {
    name: '[头晕]',
    path: require('../../assets/img/face/18.gif')
  },
  {
    name: '[爆炸头]',
    path: require('../../assets/img/face/19.gif')
  },
  {
    name: '[牛仔]',
    path: require('../../assets/img/face/20.gif')
  },
  {
    name: '[派对]',
    path: require('../../assets/img/face/21.gif')
  },
  {
    name: '[伪装]',
    path: require('../../assets/img/face/22.gif')
  },
  {
    name: '[墨镜]',
    path: require('../../assets/img/face/24.gif')
  },
  {
    name: '[呆子]',
    path: require('../../assets/img/face/25.gif')
  },
  {
    name: '[闷]',
    path: require('../../assets/img/face/26.gif')
  },
  {
    name: '[困惑]',
    path: require('../../assets/img/face/27.gif')
  },
  {
    name: '[担心]',
    path: require('../../assets/img/face/101.gif')
  },
  {
    name: '[苦脸]',
    path: require('../../assets/img/face/28.gif')
  },
  {
    name: '[皱眉]',
    path: require('../../assets/img/face/29.gif')
  },
  {
    name: '[张嘴]',
    path: require('../../assets/img/face/30.gif')
  },
  {
    name: '[安静]',
    path: require('../../assets/img/face/31.gif')
  },
  {
    name: '[震惊]',
    path: require('../../assets/img/face/32.gif')
  },
  {
    name: '[茫然]',
    path: require('../../assets/img/face/33.gif')
  },
  {
    name: '[可伶]',
    path: require('../../assets/img/face/34.gif')
  },
  {
    name: '[傻]',
    path: require('../../assets/img/face/1.gif')
  },
  {
    name: '[痛苦]',
    path: require('../../assets/img/face/36.gif')
  },
  {
    name: '[害怕]',
    path: require('../../assets/img/face/37.gif')
  },
  {
    name: '[冷汗]',
    path: require('../../assets/img/face/2.gif')
  },
  {
    name: '[悲伤]',
    path: require('../../assets/img/face/99.gif')
  },
  {
    name: '[伤心]',
    path: require('../../assets/img/face/39.gif')
  },
  {
    name: '[大哭]',
    path: require('../../assets/img/face/40.gif')
  },
  {
    name: '[惊恐]',
    path: require('../../assets/img/face/41.gif')
  },
  {
    name: '[怒]',
    path: require('../../assets/img/face/42.gif')
  },
  {
    name: '[坚持]',
    path: require('../../assets/img/face/43.gif')
  },
  {
    name: '[失望]',
    path: require('../../assets/img/face/45.gif')
  },
  {
    name: '[滴汗]',
    path: require('../../assets/img/face/105.gif')
  },
  {
    name: '[疲惫]',
    path: require('../../assets/img/face/46.gif')
  },
  {
    name: '[疲劳]',
    path: require('../../assets/img/face/47.gif')
  },
  {
    name: '[哈欠]',
    path: require('../../assets/img/face/48.gif')
  },
  {
    name: '[出气]',
    path: require('../../assets/img/face/49.gif')
  },
  {
    name: '[生气]',
    path: require('../../assets/img/face/50.gif')
  },
  {
    name: '[愤怒]',
    path: require('../../assets/img/face/51.gif')
  }
]
const emojisMapTmp = {}
emojis.forEach((e) => {
  emojisMapTmp[e.name] = e.path
})
export const emojisMap = emojisMapTmp

export default {
  name: 'expresssion-box',
  data() {
    return {
      emojis: emojis
    }
  },
  mounted() {},
  props: {
    // 是否展示弹窗
    isShow: {
      type: Boolean,
      default: false
    },
    // 设置框的定位
    top: {
      type: String,
      default: ''
    },
    right: {
      type: String,
      default: ''
    },
    bottom: {
      type: String,
      default: ''
    },
    left: {
      type: String,
      default: ''
    },
    // 表情数据
    expressList: {
      type: Array
    }
  },
  methods: {
    getEmojis(item) {
      this.$emit('add-express', item)
      // const Img = `<img src="${event.target.src}" style='height:27px;'>`
      // let range, node
      // if (window.getSelection && window.getSelection().getRangeAt) {
      //   range = window.getSelection().getRangeAt(0)
      //   range.collapse(false) // 光标移至最后
      //   node = range.createContextualFragment(Img)
      //   const c = node.lastChild
      //   range.insertNode(node)
      //   if (c) {
      //     range.setEndAfter(c)
      //     range.setStartAfter(c)
      //   }
      //   const j = window.getSelection()
      //   j.removeAllRanges()
      //   j.addRange(range)
      // } else if (document.selection && document.selection.createRange) {
      //   document.selection.createRange().pasteHTML(Img)
      // }
    },
    senMsg() {
      console.log(this.$refs.edit.innerHTML)
    },
    // 显示隐藏表情盒子
    toggleCommentExpression() {
      this.$emit('toggle-express-box', this.isShow)
    }
  }
  // clickHandler (i) {
  //   const emotion = `#${i};`
  //   this.$emit('emotion', emotion)
  // },
  // 输入一个表情
  // handleEmotion(obj) {
  //   this.$emit('add-express', obj)
  // },
}
</script>

<style lang="scss" scoped>
.expression-btn-box {
  position: relative;
}
// 白框
.expression-box {
  position: absolute;
  width: 400px;
  height: 219px;
  overflow: auto;
  bottom: 40px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgb(153 153 153 / 25%);
  border: 1px solid #ebebeb;
  background-color: #fff;
  right: 0;
  &::v-deep {
    ul {
      justify-content: inherit;
      width: 100%;
    }
    li {
      width: 10%;
      box-sizing: border-box;
      height: 36px;
      padding: 2px;
    }
  }
  // 里面的每一个小格子
  .express-item {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    cursor: pointer;
    border-right: 1px solid #666;
    background: #fff;
    box-sizing: border-box;

    @media screen and (min-width: 768px) {
      &:nth-child(10n) {
        border-right: none;
      }
      &:nth-child(n + 11) {
        border-top: 1px solid #666;
      }
    }
  }
}

// 表情按钮
.express-btn {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
  // border: 1px solid #eee;
  border-radius: 5px;
  .icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .expression-box {
    width: 333px;
    box-sizing: border-box;
    left: -13% !important;
    li {
      position: relative;
      left: 6px;
      width: 12%;
    }

    .express-item {
      &:nth-child(8n) {
        border-right: none;
      }

      &:nth-last-child(n) {
        border-bottom: 1px solid #666;
      }
    }
  }
}
.expression-box {
  left: 15%;
}
img {
  width: 27px;
  height: 27px;
  padding: 3px;
}
ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  // flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}
li {
  width: 27px;
  height: 28px;
  padding: 5px;
  white-space: nowrap;
  list-style: none;
}
</style>
