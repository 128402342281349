/*
 * @Date: 2021-06-01 15:40:09
 * @LastEditTime: 2021-12-25 10:01:21
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
 */

import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
import { downloadInstance } from '@/util/my_axios.js'

// 给小于0的数字前面补0
export const handleNumber = (val) => {
  return val < 10 ? '0' + val : val
}

// 把数组处理成树形结构，返回处理结果
export function handleTreeData(list, idProp = 'task_id') {
  // 复制数组
  const newList = [...new Set(list)]
  // 存放结果
  const treeResult = []
  const pidMap = {}
  const idList = []
  // 遍历集合
  for (let i = 0; i < newList.length; i++) {
    const item = newList[i]
    idList.push(item[idProp] || '')
    if (pidMap[item.pid] == null) {
      pidMap[item.pid] = [item]
    } else {
      pidMap[item.pid] = [...pidMap[item.pid], item]
    }
    // console.log(pidMap)
    // console.log(idList)
    // // 把它们的children属性设为空数组
    // Vue.prototype.$set(item, 'children', [])
    // if (item.pid === 0) {
    //   Vue.prototype.$set(item, 'level', 1)
    //   const children = this.getChildren(item.id, newList, item.level)
    //   Vue.prototype.$set(item, 'children', children)
    //   treeResult.push(item)
    //   // 从新数组中删除这个元素
    //   setTimeout((i) => {
    //     newList.splice(i, 1)
    //   }, 0)
    // }
  }
  for (let i = 0; i < newList.length; i++) {
    const item = newList[i]
    if (idList.indexOf(item.pid) === -1) {
      treeResult.push(item)
    }
  }
  console.log('--------------------------------------------')
  console.log(treeResult)
  const find = function (item, level) {
    item._LEVEL_ = level
    if (pidMap[item[idProp] || ''] != null) {
      item.children = pidMap[item[idProp] || '']
      for (const t of pidMap[item[idProp] || '']) {
        find(t, level + 1)
      }
    }
  }
  for (let i = 0; i < treeResult.length; i++) {
    find(treeResult[i], 1)
  }
  return treeResult
}

// 找到它的孩子
export function getChildren(id, list, level) {
  const children = []
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    // 说明item是我要找的孩子之一
    if (item.pid === id) {
      // 给这个孩子设置level
      Vue.prototype.$set(item, 'level', level + 1)
      children.push(item)
      // 找到了，就从list中删除这个元素
      setTimeout((i) => {
        list.splice(i, 1)
      }, 0)
      // 接着去找我孩子的孩子，这里要等一下才能赋值成功
      setTimeout(() => {
        Vue.prototype.$set(
          item,
          'children',
          this.getChildren(item.id, list, item.level)
        )
      }, 0)
    }
  }
  return children
}

// 把日期转字符串
export function getDateStr(val) {
  if (val) {
    return `${val.getFullYear()}-${(handleNumber(val.getMonth() + 1))}-${(handleNumber(val.getDate()))}`
  }
  return null
}

// 把日期改成字符串
export function getDateStrNoYear(val) {
  if (val) {
    return `${val.getMonth() + 1}月${val.getDate()}日`
  }
  return null
}

// 把日期转成日期和时间
export const getDateAndTimeStr = (val) => {
  return `${val.getFullYear()}-${(handleNumber(val.getMonth() + 1))}-${(handleNumber(val.getDate()))} ${handleNumber(val.getHours())}:${handleNumber(val.getMinutes())}`
}

// 处理异步函数的错误
export const to = (promise) => {
  if (!promise || !(promise instanceof Promise)) {
    return new Promise((resolve, reject) => {
      reject(new Error('requires promises as the param'))
    }).catch((err) => {
      return [err, null]
    })
  }
  return promise.then(function () {
    return [null, ...arguments]
  }).catch(err => {
    return [err, null]
  })
}

// 获取周一的日期，传入今天
export const getMondayDate = (date) => {
  // 今天是周几
  const day = date.getDay()
  // 周一跟今天距离几天
  const distance = (day === 0) ? 6 : day - 1
  return new Date(date.getTime() - distance * 86400000)
}

// 获取周日的日期，传入今天
export const getSundayDate = (date) => {
  // 今天是周几
  const day = date.getDay()
  // 周日跟今天距离几天
  const distance = (day === 0) ? 0 : 7 - day
  return new Date(date.getTime() + distance * 86400000)
}

// 获取这个月第一天的日期，传入今天
export const getMonthFirstDate = (date) => {
  // 根据现在的年月判断
  const year = date.getFullYear()
  const month = date.getMonth()
  return new Date(year, month, 1)
}

// 获取这个月最后一天日期，传入今天
export const getMonthLastDate = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth()
  // 下个月的第0天 = 这个月最后一天
  return new Date(year, month + 1, 0)
}

// 获取这个季度第一天，传入今天
export const getSeasonFirstDate = (date) => {
  // 判断今天是几月
  const month = date.getMonth()
  // 判断今天是哪一年
  const year = date.getFullYear()
  if (month < 3) {
    // 如果在3月以前，就返回1月1日的日期
    return new Date(year, 0, 1)
  } else if (month < 6) {
    // 如果在6月以前，就返回4月1日的日期
    return new Date(year, 3, 1)
  } else if (month < 9) {
    // 如果在9月以前，就返回7月1日的日期
    return new Date(year, 6, 1)
  } else {
    // 否则返回10月1日的日期
    return new Date(year, 9, 1)
  }
}

// 获取这个季度最后一天
export const getSeasonLastDate = (date) => {
  // 判断今天是几月
  const month = date.getMonth()
  // 判断今天是哪一年
  const year = date.getFullYear()
  if (month < 3) {
    // 如果在3月以前，就返回3月31日的日期
    return new Date(year, 2, 31)
  } else if (month < 6) {
    // 如果在6月以前，就返回6月30日的日期
    return new Date(year, 5, 30)
  } else if (month < 9) {
    // 如果在9月以前，就返回9月30日的日期
    return new Date(year, 8, 30)
  } else {
    // 否则返回12月31日的日期
    return new Date(year, 11, 31)
  }
}

// 确认删除，返回布尔值
export const showConfirm = async function (text) {
  const isConfrim = await Vue.prototype.$confirm(text, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).catch(err => {
    console.log(err)
  })

  if (isConfrim) {
    return Promise.resolve(true)
  } else {
    return Promise.resolve(false)
  }
}

// 添加子任务的方法，传入父任务id，子任务名字和子任务的开始时间
export const addChildTaskOf = async (pid, name, time, meetingId = -1) => {
  // 发起添加子任务的请求
  const formObj = {
    'task.task_name': name,
    'task.period_date': Vue.prototype.$util.getDateStr(time),
    'task.pid': pid
  }
  // 如果有会议id，给它加上去
  if (meetingId !== -1) {
    formObj.meeting_id = meetingId
  }
  const [err, res] = await Vue.prototype.$util.to(
    Vue.prototype.$http.post(Vue.prototype.$api.addNewTask, formObj)
  )
  if (err) {
    console.log(err)
  } else {
    if (res.state === 'ok') {
      // Vue.prototype.$message({
      //   showClose: true,
      //   message: '新增子任务成功',
      //   type: 'success'
      // })
      return true
    }
  }
}

// 搜索同事（得到的是列表，而不是树形结构）
export const searchClerkList = async (name) => {
  // 发请求，搜索同事
  const [err, res] = await Vue.prototype.$util.to(
    Vue.prototype.$http.post(Vue.prototype.$api.getClerkList, {
      search_name: name,
      clerk_status: 1
    })
  )
  if (err) {
    console.log(err)
  } else {
    if (res.state === 'ok') {
      return res.list
    } else {
      return false
    }
  }
}

// 获取职员的树形结构
export const getClerkTree = async () => {
  if (store.state.clerkTree != null) {
    return store.state.clerkTree
  }
  // 职员状态传入1，获取的是在职员工
  const [err, res] = await Vue.prototype.$util.to(
    Vue.prototype.$http.post(Vue.prototype.$api.getClerkTree, {
      clerk_status: 1
    })
  )
  if (err) {
    console.log(err)
  } else {
    if (res.state === 'ok') {
      const clerkTree = Vue.prototype.$util.handleTreeData(res.list, 'dept_id')
      store.commit('setClerkTree', clerkTree)
      return clerkTree
    }
  }
}

// 获取下属职员的树形结构
export const getChildClerkTree = async () => {
  if (store.state.childClerkTree != null) {
    return store.state.childClerkTree
  }
  // 职员状态传入1，获取的是在职员工
  const [err, res] = await Vue.prototype.$util.to(
    Vue.prototype.$http.post(Vue.prototype.$api.getSubordinateTree, {
      clerk_status: 1
    })
  )
  if (err) {
    console.log(err)
  } else {
    if (res.state === 'ok') {
      const clerkTree = Vue.prototype.$util.handleTreeData(res.list, 'dept_id')
      store.commit('setChildClerkTree', clerkTree)
      return clerkTree
    }
  }
}

// 判断是否闰年
export const isLeapYear = (year) => {
  if (year % 400 === 0) {
    return true
  } else if (year % 4 === 0 && year % 100 !== 0) {
    return true
  } else {
    return false
  }
}

// 获取这个月有多少天，输入今天
export const getMonthDays = (val) => {
  // 获取一下年和月
  const year = val.getFullYear()
  const month = val.getMonth() + 1
  // 根据月来判断这个月有多少天
  let dayCount = 0
  switch (month) {
    case 12:
    case 10:
    case 8:
    case 7:
    case 5:
    case 3:
    case 1: {
      dayCount = 31
      break
    }
    case 11:
    case 9:
    case 6:
    case 4: {
      dayCount = 30
      break
    }
    case 2: {
      dayCount = 28
      break
    }
  }
  // 判断一下是不是闰年二月，是就加1
  if (month === 2 && isLeapYear(year)) {
    dayCount++
  }
  // 返回计算结果
  return dayCount
}

// 获取这个季度有多少天，输入今天
export const getSeasonDays = (val) => {
  // 先判断这是几月
  const month = val.getMonth() + 1
  const year = val.getFullYear()
  let dayCount = 0
  // 如果是1到3月，就计算这一年1月到3月每个月的天数，并返回结果
  if (month <= 3) {
    dayCount += 31 + getMonthDays(new Date(year, 1, 1)) + 31
  } else if (month <= 6) {
    dayCount += 30 + 31 + 30
  } else if (month <= 9) {
    dayCount += 31 + 31 + 30
  } else {
    dayCount += 31 + 30 + 31
  }
  return dayCount
}

// 得到cancelToken的构造函数
const CancelToken = axios.CancelToken

// 上传文件
export const uploadFile = (url, data, fn) => {
  let cancel
  // 设置一下请求头的Content-type
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 3600000,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
      // console.log('cancel被赋值了：', cancel)
    })
  }
  // 添加改变进度的回调函数
  if (fn) {
    config.onUploadProgress = function (e) {
      fn(e)
    }
  }
  return {
    result: Vue.prototype.$http.post(url, data, config),
    cancel
  }
}

// 创建一个几到几的随机整数
export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// 防抖方法，传入的是要使用的函数
// 参数为要防抖的函数，要绑定的this，返回的函数接收参数
export const antiShare = (fn, that, wait = 500, ...args) => {
  // 使用闭包，让t能在外层函数执行完后还能用
  let t = null
  return function () {
    // 如果t不为空，先清空定时器
    if (t) {
      clearTimeout(t)
    }
    // 然后再弄一个新的定时器
    t = setTimeout(() => {
      fn.call(that, ...args)
    }, wait)
  }
}

// 把时间处理成字符串，传入的是描述时间的字符串
export const getTimeStr = (str) => {
  const date = new Date(str.replace(/-/g, '/'))
  // 获取一下现在的时间，判断现在的时间与date的距离，根据距离转成不同的字符串
  const now = new Date(new Date().getTime() - store.state.timeOffset)
  const distance = now.getTime() - date.getTime()
  // 时间单位
  const min = 60 * 1000
  const hour = min * 60
  const day = hour * 24
  const week = day * 7
  if (distance < min) {
    return '刚刚'
  } else if (distance < hour) {
    return Math.floor(distance / min) + '分钟前'
  } else if (distance < day) {
    if (date.getDate() === now.getDate()) {
      return `今天${handleNumber(date.getHours())}:${handleNumber(date.getMinutes())}`
    } else {
      return `昨天${handleNumber(date.getHours())}:${handleNumber(date.getMinutes())}`
    }
  } else if (distance < week) {
    return Math.floor(distance / day) + '天前'
  } else {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
  }
}

// 把文件大小改成字符串形式
export const filterSize = (size) => {
  if (!size) return ''
  if (size < pow1024(1)) return size + ' B'
  if (size < pow1024(2)) return (size / pow1024(1)).toFixed(2) + ' KB'
  if (size < pow1024(3)) return (size / pow1024(2)).toFixed(2) + ' MB'
  if (size < pow1024(4)) return (size / pow1024(3)).toFixed(2) + ' GB'
  return (size / pow1024(4)).toFixed(2) + ' TB'
}

// 求次幂
function pow1024(num) {
  return Math.pow(1024, num)
}

// 设置时间格式的限制
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

// 下载文件
export const downloadFile = (url, data) => {
  // 设置一下请求头的responseType
  const config = {
    responseType: 'blob'
  }
  downloadInstance.post(url, data, config).then(res => {
    console.log(res)
    const blob = res.data
    if (res.type === 'application/json') {
      const reader = new FileReader()
      reader.onload = function (event) {
        // 内容就在这里
        const content = JSON.parse(reader.result)
        Vue.prototype.$message({
          showClose: true,
          message: content.errmsg,
          type: 'error'
        })
      }
      reader.readAsText(blob)
    } else {
      const filename = res.headers['content-disposition']
      // const filename = res.headers['content-disposition']
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = decodeURIComponent(filename.split('filename=')[1])
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  })
}

// base64转file
export const handleBase64ToBlob = (url) => {
  const bytes = window.atob(url.split(',')[1])
  const arrayBuff = new ArrayBuffer(bytes.length)
  const unitArr = new Uint8Array(arrayBuff)
  for (let i = 0; i < bytes.length; i++) {
    unitArr[i] = bytes.charCodeAt(i)
  }
  return new Blob([arrayBuff], { type: 'image/png' })
}

/**
 * 加
 */
export const floatAdd = (arg1, arg2) => {
  let r1, r2
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  const m = Math.pow(10, Math.max(r1, r2))
  return (floatMul(arg1, m) + floatMul(arg2, m)) / m
}

/**
 * 减
 */
export const floatSub = (arg1, arg2) => {
  let r1, r2
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  const m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  const n = (r1 >= r2) ? r1 : r2
  return ((floatMul(arg1, m) - floatMul(arg2, m)) / m).toFixed(n)
}

/**
 * 乘
 */
export const floatMul = (arg1, arg2) => {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  // eslint-disable-next-line no-empty
  try { m += s1.split('.')[1].length } catch (e) { }
  // eslint-disable-next-line no-empty
  try { m += s2.split('.')[1].length } catch (e) { }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

/**
 * 除
 */
export const floatDiv = (arg1, arg2) => {
  let t1 = 0
  let t2 = 0
  // eslint-disable-next-line no-empty
  try { t1 = arg1.toString().split('.')[1].length } catch (e) { }
  // eslint-disable-next-line no-empty
  try { t2 = arg2.toString().split('.')[1].length } catch (e) { }

  const r1 = Number(arg1.toString().replace('.', ''))

  const r2 = Number(arg2.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}

/**
 * 格式化时间
 */
export const toDateString = (time, format = 'yyyy-MM-dd HH:mm:ss') => {
  if (!time) {
    return ''
  }
  let date = null
  if (time instanceof Date) {
    date = time
  } else if (typeof time === 'number' && String(time).length === 10) {
    time = time * 1000
    date = new Date(time)
  } else {
    date = new Date(time.replaceAll('-', '/'))
  }
  const obj = {
    yyyy: () => date.getFullYear(),
    yy: () => `${date.getFullYear()}`.slice(-2),
    M: () => date.getMonth() + 1,
    MM: () => `0${date.getMonth() + 1}`.slice(-2),
    d: () => date.getDate(),
    dd: () => `0${date.getDate()}`.slice(-2),
    H: () => date.getHours(),
    HH: () => `0${date.getHours()}`.slice(-2),
    h: () => date.getHours() % 12,
    hh: () => `0${date.getHours() % 12}`.slice(-2),
    m: () => date.getMinutes(),
    mm: () => `0${date.getMinutes()}`.slice(-2),
    s: () => date.getSeconds(),
    ss: () => `0${date.getSeconds()}`.slice(-2),
    w: () => ['日', '一', '二', '三', '四', '五', '六'][date.getDay()],
    ww: () => `星期${['日', '一', '二', '三', '四', '五', '六'][date.getDay()]}`
  }
  return format.replace(/([a-zA-Z]{1,4})/g, item => obj[item]())
}

export default {
  handleTreeData,
  getChildren,
  getDateStr,
  to,
  getMondayDate,
  getSundayDate,
  getMonthFirstDate,
  getMonthLastDate,
  getSeasonFirstDate,
  getSeasonLastDate,
  showConfirm,
  handleNumber,
  addChildTaskOf,
  searchClerkList,
  getClerkTree,
  getDateStrNoYear,
  getMonthDays,
  getSeasonDays,
  uploadFile,
  getRandomInt,
  antiShare,
  getTimeStr,
  filterSize,
  getDateAndTimeStr,
  parseTime,
  downloadFile,
  getChildClerkTree,
  handleBase64ToBlob,
  floatAdd,
  floatSub,
  floatMul,
  floatDiv,
  toDateString
}
