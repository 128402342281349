<template>
  <div>
    <div class="flex message-member">
      <div :class="{ flex: screenWidth > 767 }">
        <div class="member-name">{{ memberInfo.create_clerk_name }}</div>
        <div class="add-time">{{ memberInfo.create_time }}</div>
      </div>
      <div @click.stop="handerShowMenu">
        <i class="iconfont icon-gengduo2"></i>
      </div>
      <transition name="el-fade-in-linear" v-if="showMenu">
        <div class="message-menu">
          <div
            class="menu-item"
            v-if="isShowEdit"
            @click="$emit('change', { type: 'edit' })"
          >
            编辑
          </div>
          <div
            class="menu-item delete-btn"
            v-if="isShowDelete"
            @click="$emit('change', { type: 'delete' })"
          >
            删除
          </div>
          <div class="menu-item" @click="$emit('change', { type: 'copy' })">
            复制链接
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      showMenu: false // 是否现实菜单
    }
  },
  props: ['memberInfo', 'clerkId'],
  mounted() {
    document.addEventListener('click', () => {
      if (this.showMenu === true) {
        this.showMenu = false
      }
    })
  },
  computed: {
    ...mapState(['myClerkId', 'screenWidth']),
    // 是否展示修改
    isShowEdit() {
      if (this.clerkId === this.myClerkId) {
        return true
      }
      return false
    },
    // 是否展示删除
    isShowDelete() {
      if (this.clerkId === this.myClerkId) {
        return true
      }
      if (this.$hasPer(43)) {
        return true
      }
      return false
    }
  },
  methods: {
    handerShowMenu() {
      if (this.showMenu === true) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-member {
  justify-content: space-between;
  margin-bottom: 10px;

  .member-name {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    margin-right: 14px;
  }

  .add-time {
    font-size: 14px;
    line-height: 21px;
    color: #bbb;
  }
}

.message-menu {
  position: absolute;
  width: 92px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  right: 32px;
  top: 42px;
  border-radius: 4px;
  padding: 6px 0;

  .menu-item {
    cursor: pointer;
    width: 76px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    margin: 0 auto;

    &:hover {
      color: $main-color;
      background-color: rgba(0, 128, 255, 0.1);
      border-radius: 4px;
    }

    &.delete-btn {
      color: $red;

      &:hover {
        background-color: rgba(255, 84, 84, 0.1);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .message-member {
    margin-bottom: 5px;
  }
}
</style>
