<template>
  <message-dialog visible>
    <template #header>
      <div class="dialog-title">权限管理</div>
      <div class="dialog-sub-title">
        允许成员新建、编辑、删除、共享文件，管理团队空间知识库。
      </div>
    </template>
    <template #content>
      <div
        class="flex flex-x-between"
        style="padding-top: 20px;margin-bottom: 16px;"
      >
        <span>团队成员</span>
        <span class="add-button">添加成员</span>
      </div>
      <div class="list-container">
        <el-input
          prefix-icon="el-icon-search"
          placeholder="请输入团队成员名称搜索"
        ></el-input>
        <div class="list-content">
          <structure-tree :data-list="dataList"></structure-tree>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <el-button type="primary">保存</el-button>
        <el-button>取消</el-button>
      </div>
    </template>
  </message-dialog>
</template>

<script>
import MessageDialog from '@/components/circle/MessageDialog.vue'
import StructureTree from './StructureTree.vue'
export default {
  components: {
    MessageDialog,
    StructureTree
  },
  data() {
    return {
      dataList: [
        {
          id: 4
        },
        {
          id: 1,
          child: [
            {
              id: 2,
              child: [
                {
                  id: 3
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dialog-sub-title {
  color: #bbb;
}

.add-button {
  cursor: pointer;
  color: $main-color;
}

.list-container {
  background-color: #f6f6f6;
  border-radius: 6px;
  height: calc(100vh - 220px);
  margin-bottom: 20px;
  padding: 18px 24px 0;

  .list-content {
    height: calc(100% - 40px);
    overflow: scroll;
  }

  .el-input {
    margin-bottom: 8px;
  }
}

.el-button {
  width: 84px;
}
</style>
