<template>
  <div class="flex comment-member">
    <div class="flex member-bar">
      <div>
        <el-image
          class="member-avatar"
          :src="avatar"
          @click="$emit('clickAvatar')"
        >
          <div slot="error" class="flex image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div>
        <div class="user-name">{{ name }}</div>
        <div class="comment-time">{{ getCurrentTime(time) }}</div>
      </div>
    </div>
    <div class="operation-bar">
      <span
        v-show="showDelete"
        :class="{ 'delete-show': showDelete }"
        class="delete"
        @click="$emit('onDelete', $event)"
        >删除</span
      >
      <span class="reply" @click.stop="$emit('onReply', $event)" v-if="replyShow">回复</span>
      <span class="like" @click="$emit('onPraise', $event)">
        <i :class="isLike ? 'icon-a-Frame12 active' : 'icon-Frame13'"></i>
        {{ likeNum }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    likeNum: {
      type: [String, Number],
      default: 0
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    isLike: {
      type: Boolean,
      default: false
    },
    replyShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    // 获取创建时间格式
    getCurrentTime(time) {
      return this.$util.getTimeStr(time)
    }
  }
}
</script>

<style lang="scss">
.comment-member {
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .member-bar {
    .member-avatar {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      margin-right: 10px;

      .image-slot {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #f6f6f6;
      }
    }

    .user-name {
      font-size: 14px;
      font-weight: 700;
      height: 20px;
      line-height: 20px;
    }

    .comment-time {
      font-size: 12px;
      color: #999999;
      height: 18px;
      line-height: 18px;
    }
  }

  .operation-bar {
    span {
      cursor: pointer;
      font-size: 14px;
      margin-left: 14px;
    }

    .delete {
      color: $red;
    }

    .reply {
      color: $main-color;
    }

    .like {
      .active {
        color: #ff5454;
      }
    }
  }
}
</style>
