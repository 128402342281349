<template>
  <!-- 添加编辑培训师 -->
  <div class="create-trainer flex flex-y-positive">
    <image-crop-dialog
      title="选择照片"
      funtionName="上传照片"
      :autoCropWidth="200"
      :autoCropHeight="243"
      @close="cropVisible = false"
      @confirm="chooseImage($event)"
      v-if="cropVisible"
    ></image-crop-dialog>
    <div class="create-trainer-header flex flex-y-center flex-x-between">
      <i class="icon-a-arrowaltdown" @click.stop="$emit('close')"></i>
      <button class="send" @click.stop="confirm">发布</button>
    </div>
    <div class="trainer-content-scroll">
      <div class="create-trainer-box flex-y-positive">
        <div class="trainer-content-top flex flex-x-between">
          <div class="trainer-content-left flex flex-y-positive">
            <div class="add-trainer-name flex">
              <div class="create-trainer-title flex flex-x-end">
                <p>*</p>
                <span>培训师名称：</span>
              </div>
              <div class="trainer-name" v-if="isEdit">
                {{ info.clerk_name || '' }}
              </div>
              <!-- 输入框 -->
              <el-input
                v-if="!isEdit"
                :value="info.clerk_name || ''"
                placeholder="点击选择导师"
                :readonly="true"
                @click.native.stop="isChooseClerk = true"
              ></el-input>
              <div
                v-if="!isEdit"
                v-show="isChooseClerk"
                @click.stop=""
                class="choose-person-box"
              >
                <search-person
                  :isShow="isChooseClerk"
                  @choose-person="chooseClerk($event)"
                ></search-person>
                <member-tree
                  :treeNode="treeNode"
                  v-for="(treeNode, index) in clerkTree"
                  :key="index"
                  @clerk-choose="chooseClerk($event)"
                ></member-tree>
              </div>
            </div>
            <div class="add-department flex">
              <div class="create-trainer-title flex flex-x-end">
                <p>*</p>
                <span>部门：</span>
              </div>
              <div class="department-name" v-if="isEdit">
                {{ info.main_dept_name || '' }}
              </div>
              <!-- 输入框 -->
              <el-input
                v-if="!isEdit"
                :value="info.main_dept_name || ''"
                placeholder="培训师所在部门"
                :readonly="true"
              ></el-input>
            </div>
            <div class="add-trainer-type flex">
              <div class="create-trainer-title flex flex-x-end">
                <p>*</p>
                <span>培训类型：</span>
              </div>
              <div class="trainer-type-list">
                <div
                  class="trainer-type-item flex flex-y-center"
                  v-for="(item, index) in selectedTypeList"
                  :key="index"
                >
                  <el-select
                    v-model="selectedTypeList[index].type"
                    class="select-type"
                    placeholder="请选择培训师类型"
                  >
                    <el-option-group>
                      <el-option
                        v-for="tItem in clerkTutorTypeList"
                        :key="tItem.type_id"
                        :label="tItem.type_name"
                        :value="tItem.type_id"
                        :disabled="
                          item.type != tItem.type_id &&
                          selectedTypeIdList.includes(tItem.type_id)
                        "
                      >
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <el-select
                    v-model="selectedTypeList[index].level"
                    class="select-grade"
                    placeholder="请选择等级"
                  >
                    <el-option
                      v-for="item in grade"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <span
                    v-show="selectedTypeList.length > 1"
                    @click="deleteTypeItem(index)"
                    >删除</span
                  >
                </div>
                <span class="add-trainer-type-btn" @click="addTypeItem">
                  添加类型
                </span>
              </div>
            </div>
          </div>
          <div class="trainer-content-right flex">
            <div class="create-trainer-title flex flex-x-end">
              <p>*</p>
              <span>照片：</span>
            </div>
            <div
              class="add-picture flex flex-x-center flex-y-center"
              @click="cropVisible = true"
            >
              <i
                class="icon-add"
                v-if="info.cover_access_path == null && imageFile == null"
              ></i>
              <img
                v-if="info.cover_access_path != null || imageFile != null"
                :src="info.cover_access_path || imageFileBase64"
              />
            </div>
          </div>
        </div>
        <div class="trainer-content-input">
          <div class="add-trainer-introduction flex">
            <div class="create-trainer-title flex flex-x-end">
              <p>*</p>
              <span>简介：</span>
            </div>
            <!-- 导师介绍输入框 -->
            <div class="trainer-introduction-input">
              <trainer-describe-editor
                :value="myValue"
                ref="editor"
              ></trainer-describe-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 调用编辑器
import TrainerDescribeEditor from '@/components/knowledge/TrainerDescribeEditor1.vue'
import MemberTree from '@/components/common/MemberTree.vue'
import SearchPerson from '@/components/common/SearchPerson.vue'
// 图片截取
import ImageCropDialog from '@/components/common/ImageCropDialog.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    TrainerDescribeEditor,
    MemberTree,
    SearchPerson,
    ImageCropDialog
  },
  props: {
    // 职员id
    trainerId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // 等级
      grade: [
        {
          value: 10,
          label: '无'
        },
        {
          value: 1,
          label: '初级'
        },
        {
          value: 2,
          label: '中级'
        },
        {
          value: 3,
          label: '高级'
        }
      ],
      // 类型列表
      selectedTypeList: [
        {
          type: '',
          level: ''
        }
      ],
      // 是否选择职员
      isChooseClerk: false,
      // 信息
      info: {
        clerk_name: '',
        main_dept_name: ''
      },
      // 职员数据
      clerkTree: [],
      // 截图展示
      cropVisible: false,
      // 图片文件
      imageFile: null,
      imageFileBase64: null,
      myValue: ''
    }
  },
  computed: {
    ...mapState(['myClerkId', 'clerkTutorTypeList']),
    // 是否编辑
    isEdit() {
      return this.trainerId !== -1
    },
    // 已选择的类型id列表
    selectedTypeIdList() {
      return this.selectedTypeList.map((e) => {
        return e.type
      })
    }
  },
  methods: {
    ...mapActions(['getClerkTutorTypeList']),
    // 初始化修改
    async initEdit() {
      await this.getDetail()
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 选择导师
    chooseClerk(clerk) {
      console.log(clerk)
      this.info = clerk
      this.isChooseClerk = false
    },
    // 获取详情
    async getDetail() {
      const formObj = {
        clerk_id: this.trainerId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkTutorDetail, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.info = {
          clerk_name: res.info.clerk_name,
          clerk_alias: res.info.clerk_alias,
          main_dept_name: res.info.main_dept_name,
          cover_access_path: res.info.cover_access_path
        }
        this.myValue = res.info.tutor_describe_html || ''
        this.$refs.editor.setContent(res.info.tutor_describe_html || '')
        this.selectedTypeList = res.info.type_list.map((e) => {
          return {
            type: e.type_id,
            level: e.type_level
          }
        })
      }
    },
    // 修改背景图
    async chooseImage({ file, base64 }) {
      delete this.info.cover_access_path
      this.imageFile = file
      this.imageFileBase64 = base64
      this.cropVisible = false
    },
    // 添加一个类型项目
    addTypeItem() {
      this.selectedTypeList.push({
        type: '',
        level: ''
      })
    },
    // 删除一个类型选项
    deleteTypeItem(index) {
      this.selectedTypeList.splice(index, 1)
    },
    // 保存
    async confirm() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      const formObj = new FormData()
      // 添加
      if (!this.isEdit) {
        if (this.info.clerk_id == null) {
          errmsg('请选择导师')
          return
        }
        if (this.imageFile == null) {
          errmsg('请选择图片')
          return
        }
        formObj.append('file', this.imageFile)
        formObj.append('clerk_id', this.info.clerk_id)
      } else {
        // 修改
        if (this.imageFile != null) {
          formObj.append('file', this.imageFile)
        }
        formObj.append('clerk_id', this.trainerId)
      }
      if (this.selectedTypeList.length === 0) {
        errmsg('请选择类型')
        return
      }
      for (const i in this.selectedTypeList) {
        if (this.selectedTypeList[i].type === '') {
          errmsg(`第${i + 1}行,请选择类型`)
          return
        }
        if (this.selectedTypeList[i].level === '') {
          errmsg(`第${i + 1}行,请选择等级`)
          return
        }
      }
      formObj.append('type_list', JSON.stringify(this.selectedTypeList))
      const content = this.$refs.editor.getContent()
      console.log(content)
      if (content.html === '') {
        errmsg('请填写描述')
        return
      }
      formObj.append('tutor_describe_html', content.html)
      formObj.append('tutor_describe_text', content.text)
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(
          this.isEdit ? this.$api.editClerkTutor : this.$api.addClerkTutor,
          formObj
        ).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$emit('success')
      }
    },
    outClick() {
      this.isChooseClerk = false
    }
  },
  mounted() {
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
    document.addEventListener('click', this.outClick)
    // 获取职员数据
    this.getClerkTree()
    // 获取职员导师类型
    this.getClerkTutorTypeList()

    // 初始化修改
    if (this.trainerId !== -1) {
      console.log(this.trainerId)
      this.initEdit()
    }
  },
  watch: {
    trainerId(val) {
      if (val !== -1) {
        this.initEdit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-trainer {
  width: 100%;
  height: 100%;

  background-color: #fff;

  .create-trainer-header {
    flex-shrink: 0;
    width: 100%;
    height: 70px;
    padding: 0 34px 0 34px;
    box-sizing: border-box;
    .icon-a-arrowaltdown {
      transform: rotate(180deg);
      font-size: 32px;
      color: #999999;
      cursor: pointer;
    }
    .send {
      cursor: pointer;
      width: 84px;
      height: 34px;
      background: #0080ff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
    }
  }
  .trainer-content-scroll {
    width: 100%;
    height: 100%;
    padding: 40px 0 100px 0;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .create-trainer-box {
    width: 838px;
    margin: 0 auto;
    .create-trainer-title {
      height: 44px;
      width: 96px;
      p {
        color: #ff5454;
        font-weight: 500;
        font-size: 14px;
        line-height: 44px;
      }
      span {
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        line-height: 44px;
        margin-left: 5px;
      }
    }
    .trainer-content-top {
      width: 100%;
      .trainer-content-left {
        width: 448px;
        .add-trainer-name {
          position: relative;
          width: 448px;
          height: 44px;

          .trainer-name {
            margin-left: 28px;
            line-height: 44px;
          }
        }
        .add-department {
          width: 448px;
          height: 44px;
          margin-top: 24px;

          .department-name {
            margin-left: 28px;
            line-height: 44px;
          }
        }
        .add-trainer-type {
          width: 600px;
          margin-top: 24px;

          .trainer-type-list {
            margin-left: 14px;

            .trainer-type-item {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: #ff5454;
                margin-left: 13px;
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }

          .add-trainer-type-btn {
            font-size: 14px;
            line-height: 21px;
            color: #0080ff;
            margin-top: 16px;
            cursor: pointer;
          }
        }
      }
      .trainer-content-right {
        .add-picture {
          margin-left: 14px;
          width: 148px;
          height: 180px;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          border-radius: 4px;
          .icon-add {
            font-size: 44px;
            color: #dddddd;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .trainer-content-input {
    width: 100%;
    height: 537px;

    .add-trainer-introduction {
      width: 100%;
      // height: 500px;

      .trainer-introduction-input {
        margin: 16px 0 0 14px;
        width: 730px;
        // height: 500px;
        // border: 1px solid #e0e0e0;
        border-radius: 4px;
      }

      .trainer-introduction-input ::v-deep {
        .ck-focused {
          box-shadow: none;
        }
        .ck-blurred {
          border-top: none;
        }
        .ck-toolbar {
          background: transparent;
        }
        .ck-editor__editable {
          h1,
          h2,
          h3,
          h4,
          h4,
          h5,
          h6 {
            padding-top: 5px;
          }
          body,
          dl,
          dt,
          dd,
          ul,
          ol,
          li,
          pre,
          form,
          fieldset,
          input,
          p,
          blockquote,
          th,
          td {
            padding-top: 5px;
          }
          ol,
          ul {
            list-style: decimal;
            padding-left: 30px;
          }
        }
      }
    }
  }
}
// 导师姓名、部门输入框样式
.add-trainer-name::v-deep .el-input {
  margin-left: 14px;
  width: 340px;
  height: 44px;
  .el-input__inner {
    height: 44px;
  }
}
.add-department::v-deep .el-input {
  margin-left: 14px;
  width: 340px;
  height: 44px;
  .el-input__inner {
    height: 44px;
  }
}
// 种类选择器样式
.add-trainer-type::v-deep .el-select.select-type {
  width: 178px;
  height: 44px;
  .el-input__inner {
    width: 178px;
    height: 44px;
  }
  .el-scrollbar {
    height: 140px;
    .el-select-dropdown__wrap {
      max-height: 140px;
    }
  }
}
// 等级选择器样式
.add-trainer-type::v-deep .el-select.select-grade {
  margin-left: 14px;
  width: 148px;
  height: 44px;
  .el-input__inner {
    width: 148px;
    height: 44px;
  }
}
// 导师介绍输入框样式
.trainer-introduction-input::v-deep .quill-editor {
  .ql-active {
    color: #0080ff;
  }
  .ql-editor.ql-blank {
    p {
      font-size: 14px;
      line-height: 21px;
      color: #999999;
    }
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-formats {
    margin: 0;
  }
  .ql-syntax {
    background: #f0f0f0;
    color: #333333;
    font-size: 14px;
  }
  .ql-toolbar.ql-snow {
    text-align: left;
    height: 40px;
    border: none;
  }
  .ql-font,
  .ql-direction,
  .ql-size,
  // .ql-clean,
  // .ql-background,
  .ql-script {
    display: none;
  }
}

.choose-person-box {
  position: absolute;
  top: 50px;
  left: 110px;
  z-index: 2;
  margin: 10px 0;
  width: 340px;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  border-radius: 4px;
}

.create-trainer::v-deep .crop-dialog {
  width: 466px;
}
</style>
