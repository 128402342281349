<template>
  <div style="width: 100%; height: 100%">
    <div
      class="file-folder flex flex-y-positive"
      v-if="folderVisible"
      v-loading="loading"
    >
      <div class="folder-top flex flex-x-between flex-y-center pc">
        <div class="base-path flex flex-y-center">
          <template v-for="(item, index) in breadcrumbList">
            <p :key="index" @click.stop="toFolder(item.folder_id)">
              {{ item != null ? item.folder_name : '' }}
              <i class="el-icon-caret-bottom show"></i>
            </p>
            <i
              v-if="index < breadcrumbList.length - 1"
              :key="`icon_${index}`"
              class="iconfont icon-a-arrowaltdown"
            ></i>
          </template>
        </div>
        <div class="funtion-box flex">
          <!-- 复制 -->
          <div class="copy-box flex flex-y-center">
            <el-tooltip
              class="item"
              effect="dark"
              content="复制链接"
              placement="bottom"
            >
              <p class="id-text" @click="copyLink">#{{ folderId }}</p>
            </el-tooltip>
            <p class="tip-text" v-if="isShowCopyLinkTip">链接已复制</p>
          </div>
          <!-- 编辑 -->
          <el-tooltip
            class="item"
            effect="dark"
            content="编辑"
            placement="bottom"
          >
            <i
              class="iconfont icon-a-EditSquare"
              v-if="perInfo.editFolder"
              @click="
                showEditFolder({
                  folder_id: folderId,
                  folder_name: info.folder_name || '',
                  folder_describe_html: info.folder_describe_html
                })
              "
            ></i>
          </el-tooltip>
          <!-- 收藏 -->
          <!-- <div class="share-box flex flex-y-center">
            <el-tooltip
              class="item"
              effect="dark"
              content="收藏"
              placement="bottom"
            >
              <i class="iconfont icon-Star1" @click="collect"></i>
            </el-tooltip>
            <i class="iconfont icon-yishoucang" v-if="info.is_collect"></i>
          </div> -->
          <!-- 分享至圈子 -->
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="分享至圈子"
            placement="bottom"
          >
            <i class="iconfont icon-a-sharearrow" @click.stop="
            outClick()
            shareVisible = true
            "></i>
          </el-tooltip> -->
          <i
            class="iconfont icon-gengduo3"
            @click.stop="
              outClick()
              isShowFolderMore = true
            "
            v-if="!this.isMainFolder && perInfo.deleteFolder"
          ></i>
          <!-- 更多弹窗 -->
          <ul
            class="gengduo-pop flex flex-y-positive"
            @click.stop
            v-if="
              !this.isMainFolder && perInfo.deleteFolder && isShowFolderMore
            "
          >
            <li
              class="flex flex-y-end flex-x-center"
              v-if="!collectMode && !isMainFolder && perInfo.moveFolder"
            >
              <div
                class="li-node flex flex-y-center flex-x-between"
                @click.stop="showMoveFolder()"
              >
                <i class="iconfont icon-Logout"></i>
                <span>移动</span>
              </div>
            </li>
            <li
              class="flex flex-y-start flex-x-center"
              v-if="!this.isMainFolder && perInfo.deleteFolder"
            >
              <div
                class="li-node flex flex-y-center flex-x-between"
                @click.stop="showDeleteFolder(folderId)"
              >
                <i class="iconfont icon-Delete"></i>
                <span>删除</span>
              </div>
            </li>
            <li
              class="flex flex-y-center flex-x-center"
              v-if="isTeamAdmin && folderLevel === 2 && folderType == 2"
              @click="
                outClick()
                teamPerVisible = true
              "
            >
              <div class="li-node flex flex-y-center flex-x-between">
                <i class="iconfont icon-Setting2"></i>
                <span>权限</span>
              </div>
            </li>
            <li
              class="flex flex-y-center flex-x-center"
              v-if="folderLevel === 3 && folderType == 2"
              @click="
                outClick()
                teamShareVisible = true
              "
            >
              <div class="li-node flex flex-y-center flex-x-between">
                <i class="iconfont icon-Setting2"></i>
                <span>共享</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="perInfo.batchDelete || perInfo.batchMove">
        <batch-operation-pop
          v-if="selectedNumber > 0"
          :number="selectedNumber"
          :showDelete="perInfo.batchDelete"
          :showMove="perInfo.batchMove"
          @delete="checkBatchDeleteVisible = true"
          @move="showBatchMove"
          @cancel="selectAll(false)"
        ></batch-operation-pop>
      </template>
      <div
        class="file-operator flex flex-y-positive"
        v-if="perInfo.addFile || perInfo.addFolder"
      >
        <div
          class="create-dom flex flex-y-center flex-x-between"
          @click="
            addDocumentVisible = true
            folderVisible = false
          "
          v-if="perInfo.addFile"
        >
          <i class="iconfont icon-add3"></i>
          <span>创建文档</span>
        </div>
        <ul class="flex flex-y-positive flex-x-start">
          <li
            class="flex flex-y-center"
            @click="$refs.uploadFileInput.click()"
            v-if="perInfo.addFile"
          >
            <i class="iconfont icon-Upload"></i>
            <span>上传文件</span>
          </li>
          <li
            class="flex flex-y-center"
            @click="$refs.uploadFolderInput.click()"
            v-if="perInfo.addFile && perInfo.addFolder"
          >
            <i class="iconfont icon-Upload"></i>
            <span>上传文件夹</span>
          </li>
          <li
            class="flex flex-y-center"
            v-if="perInfo.addFolder && folderId != 2"
            @click.stop="addFolderVisible = true"
          >
            <i class="iconfont icon-Folder1"></i>
            <span>创建文件夹</span>
          </li>
        </ul>
        <!-- <div class="recycle-bin flex flex-y-center" @click="$emit('trash')">
          <i class="iconfont icon-Delete2"></i>
          <span>回收站</span>
        </div> -->
      </div>
      <div class="file-folder-scroll pc">
        <div
          class="introduce-text htmledit_views"
          v-if="info.folder_describe_html"
          v-html="info.folder_describe_html"
          :style="{
            paddingRight:
              perInfo.addFile || perInfo.addFolder ? '315px' : '50px'
          }"
        ></div>
        <ul
          class="file-list"
          :style="{
            paddingRight:
              perInfo.addFile || perInfo.addFolder ? '315px' : '50px'
          }"
        >
          <div class="ul-top flex">
            <div class="check-box" v-if="!(folderType === 2 && isMainFolder)">
              <svg class="icon" aria-hidden="true" @click.stop="selectAll()">
                <use
                  :xlink:href="
                    isAllSelected ? '#icon-TickSquare_1' : '#icon-TickSquare'
                  "
                ></use>
              </svg>
            </div>
            <span>名称</span>
            <span>著作人</span>
            <span>最后修改人</span>
            <span>最后修改时间</span>
            <div class="operator">
              {{
                perInfo.editFolder ||
                perInfo.deleteFolder ||
                perInfo.editFile ||
                perInfo.deleteFile
                  ? '操作'
                  : ''
              }}
            </div>
          </div>
          <!-- 文件夹 -->
          <li
            class="flex flex-y-center"
            v-for="(item, index) in folderList"
            :key="`folder_${item.folder_id}`"
          >
            <div class="check-box" v-if="!(folderType === 2 && isMainFolder)">
              <svg
                class="icon"
                aria-hidden="true"
                @click.stop="selectFolder(item.folder_id)"
              >
                <use
                  :xlink:href="
                    selectedFolderList.includes(item.folder_id)
                      ? '#icon-TickSquare_1'
                      : '#icon-TickSquare'
                  "
                ></use>
              </svg>
            </div>
            <div class="name flex flex-y-center">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="'#icon-Folder1'"></use>
              </svg>
              <div class="folder-progress flex flex-y-positive">
                <p @click.stop="toFolder(item.folder_id)">
                  {{ item.folder_name || '' }}
                </p>
              </div>
            </div>
            <div class="author"></div>
            <div class="last-author">
              {{
                item.update_clerk_alias ||
                item.update_clerk_name ||
                item.create_clerk_alias ||
                item.create_clerk_name ||
                ''
              }}
            </div>
            <div class="last-time">
              {{
                item.update_time || item.create_time
                  ? $util.getTimeStr(item.update_time || item.create_time)
                  : ''
              }}
            </div>
            <div style="width: 6%; text-align: end">
              <i
                class="icon-gengduo3"
                v-if="perInfo.editFolder || perInfo.deleteFolder"
                @click.stop="
                  outClick()
                  currentShowMoreKey = `folder_${item.folder_id}`
                "
              ></i>
            </div>
            <!-- 更多弹窗 -->
            <div
              class="gengduo-pop flex flex-y-positive flex-x-center"
              v-if="
                (perInfo.editFolder || perInfo.deleteFolder) &&
                currentShowMoreKey === `folder_${item.folder_id}`
              "
            >
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.editFolder"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="
                    showEditFolder({
                      folder_id: item.folder_id,
                      folder_name: item.folder_name || '',
                      folder_describe_html: item.folder_describe_html
                    })
                  "
                >
                  <i class="iconfont icon-a-EditSquare2"></i>
                  <span>编辑</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.editFolder"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="showMoveFolder(index)"
                >
                  <i class="iconfont icon-Logout"></i>
                  <span>移动</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.deleteFolder"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click.stop="showDeleteFolder(item.folder_id)"
                >
                  <i class="iconfont icon-Delete"></i>
                  <span>删除</span>
                </div>
              </li>
            </div>
          </li>
          <!-- 文件 -->
          <li
            class="flex flex-y-center"
            v-for="(item, index) in fileList"
            :key="`fild_${item.knowl_id}`"
          >
            <div class="check-box">
              <svg
                class="icon"
                aria-hidden="true"
                @click.stop="selectFile(item.knowl_id)"
              >
                <use
                  :xlink:href="
                    selectedFileList.includes(item.knowl_id)
                      ? '#icon-TickSquare_1'
                      : '#icon-TickSquare'
                  "
                ></use>
              </svg>
            </div>
            <div class="name flex flex-y-center">
              <template v-if="item.content_type === 1">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="'#icon-Frame12'"></use>
                </svg>
              </template>
              <!-- 文件类型 -->
              <template v-if="item.content_type === 2">
                <el-image
                  v-if="isPic(item.attachment_info)"
                  fit="cover"
                  :src="item.attachment_info.attachment_path"
                  :preview-src-list="[item.attachment_info.attachment_path]"
                  style="width: 30px; height: 30px"
                >
                </el-image>
                <svg v-else class="icon" aria-hidden="true">
                  <use :xlink:href="getFileIcon(item.attachment_info)"></use>
                </svg>
              </template>
              <div class="folder-progress flex flex-y-positive">
                <p @click.stop="toFile(item.knowl_id)">
                  {{ item != null ? item.knowl_name : '' }}
                </p>
                <!-- 进度条 -->
                <el-progress
                  v-if="item.progress != null"
                  :percentage="item.progress"
                ></el-progress>
              </div>
            </div>
            <div class="author">
              <div
                class="show"
                :class="{ 'allow-edit': perInfo.editFile }"
                @click.stop="
                  perInfo.editFile ? (chooseAuthorIndex = index) : ''
                "
              >
                {{ item.author_clerk_alias || item.author_clerk_name }}
              </div>
              <div
                v-if="perInfo.editFile && chooseAuthorIndex === index"
                @click.stop=""
                class="choose-person-box"
              >
                <search-person
                  :isShow="chooseAuthorIndex === index"
                  @choose-person="chooseAuthor($event)"
                ></search-person>
                <member-tree
                  :treeNode="treeNode"
                  v-for="(treeNode, index) in clerkTree"
                  :key="index"
                  @clerk-choose="chooseAuthor($event)"
                ></member-tree>
              </div>
            </div>
            <div class="last-author">
              {{
                item.update_clerk_alias ||
                item.update_clerk_name ||
                item.create_clerk_alias ||
                item.create_clerk_name
              }}
            </div>
            <div class="last-time">
              {{
                item.update_time || item.create_time
                  ? $util.getTimeStr(item.update_time || item.create_time)
                  : ''
              }}
            </div>
            <div style="width: 6%; text-align: end">
              <i
                class="icon-gengduo3"
                v-if="perInfo.editFile || perInfo.deleteFile"
                @click.stop="
                  outClick()
                  currentShowMoreKey = `file_${item.knowl_id}`
                "
              ></i>
            </div>
            <!-- 更多弹窗 -->
            <div
              class="gengduo-pop flex flex-y-positive flex-x-center"
              v-if="
                (perInfo.editFile || perInfo.deleteFile) &&
                currentShowMoreKey === `file_${item.knowl_id}`
              "
            >
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.editFile && !collectMode"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="editFile(index)"
                >
                  <i class="iconfont icon-a-EditSquare2"></i>
                  <span>编辑</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.editFile && !collectMode"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="showMoveFile(index)"
                >
                  <i class="iconfont icon-Logout"></i>
                  <span>移动</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.deleteFile"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="showDeleteFile(item.knowl_id)"
                >
                  <i class="iconfont icon-Delete"></i>
                  <span>删除</span>
                </div>
              </li>
            </div>
          </li>
        </ul>
      </div>
      <div class="file-folder-scroll wap">
        <div
          class="introduce-text htmledit_views"
          v-if="info.folder_describe_html"
          v-html="info.folder_describe_html"
        ></div>
        <ul class="file-list">
          <!-- 文件夹 -->
          <li
            class="flex flex-y-center"
            v-for="(item, index) in folderList"
            :key="`folder_${item.folder_id}`"
          >
            <div class="name flex flex-y-center">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="'#icon-Folder1'"></use>
              </svg>
              <div class="folder-progress flex flex-y-positive">
                <p @click.stop="toFolder(item.folder_id)">
                  {{ item.folder_name || '' }}
                </p>
                <p>
                  {{
                    item.update_clerk_alias ||
                    item.update_clerk_name ||
                    item.create_clerk_alias ||
                    item.create_clerk_name ||
                    ''
                  }}

                  {{
                    item.update_time || item.create_time
                      ? $util.getTimeStr(item.update_time || item.create_time)
                      : ''
                  }}
                </p>
              </div>
            </div>
            <div class="author"></div>
            <div style="width: 28px; text-align: center">
              <i
                class="icon-gengduo3"
                v-if="perInfo.editFolder || perInfo.deleteFolder"
                @click.stop="
                  outClick()
                  currentShowMoreKey = `folder_${item.folder_id}`
                "
              ></i>
            </div>
            <!-- 更多弹窗 -->
            <div
              class="gengduo-pop flex flex-y-positive flex-x-center"
              v-if="
                (perInfo.editFolder || perInfo.deleteFolder) &&
                currentShowMoreKey === `folder_${item.folder_id}`
              "
            >
              <li
                class="flex flex-y-center flex-x-center pc"
                v-if="perInfo.editFolder"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="
                    showEditFolder({
                      folder_id: item.folder_id,
                      folder_name: item.folder_name || '',
                      folder_describe_html: item.folder_describe_html
                    })
                  "
                >
                  <i class="iconfont icon-a-EditSquare2"></i>
                  <span>编辑</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center pc"
                v-if="perInfo.editFolder"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="showMoveFolder(index)"
                >
                  <i class="iconfont icon-Logout"></i>
                  <span>移动</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.deleteFolder"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click.stop="showDeleteFolder(item.folder_id)"
                >
                  <i class="iconfont icon-Delete"></i>
                  <span>删除</span>
                </div>
              </li>
            </div>
          </li>
          <!-- 文件 -->
          <li
            class="flex flex-y-center"
            v-for="(item, index) in fileList"
            :key="`fild_${item.knowl_id}`"
          >
            <div class="name flex flex-y-center">
              <template v-if="item.content_type === 1">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="'#icon-Frame12'"></use>
                </svg>
              </template>
              <!-- 文件类型 -->
              <template v-if="item.content_type === 2">
                <el-image
                  v-if="isPic(item.attachment_info)"
                  fit="cover"
                  :src="item.attachment_info.attachment_path"
                  :preview-src-list="[item.attachment_info.attachment_path]"
                  style="width: 30px; height: 30px"
                >
                </el-image>
                <svg v-else class="icon" aria-hidden="true">
                  <use :xlink:href="getFileIcon(item.attachment_info)"></use>
                </svg>
              </template>
              <div class="folder-progress flex flex-y-positive">
                <p @click.stop="toFile(item.knowl_id)">
                  {{ item != null ? item.knowl_name : '' }}
                </p>
                <p>
                  {{
                    item.update_clerk_alias ||
                    item.update_clerk_name ||
                    item.create_clerk_alias ||
                    item.create_clerk_name
                  }}

                  {{
                    item.update_time || item.create_time
                      ? $util.getTimeStr(item.update_time || item.create_time)
                      : ''
                  }}
                </p>

                <!-- 进度条 -->
                <el-progress
                  v-if="item.progress != null"
                  :percentage="item.progress"
                ></el-progress>
              </div>
            </div>
            <div class="author">
              <div
                :class="{ 'allow-edit': perInfo.editFile }"
                @click.stop="
                  perInfo.editFile ? (chooseAuthorIndex = index) : ''
                "
              >
                <p>{{ item.author_clerk_alias || item.author_clerk_name }}</p>
              </div>
              <div
                v-if="perInfo.editFile && chooseAuthorIndex === index"
                @click.stop=""
                class="choose-person-box"
              >
                <search-person
                  :isShow="chooseAuthorIndex === index"
                  @choose-person="chooseAuthor($event)"
                ></search-person>
                <member-tree
                  :treeNode="treeNode"
                  v-for="(treeNode, index) in clerkTree"
                  :key="index"
                  @clerk-choose="chooseAuthor($event)"
                ></member-tree>
              </div>
            </div>

            <div style="width: 28px; text-align: center">
              <i
                class="icon-gengduo3"
                v-if="perInfo.editFile || perInfo.deleteFile"
                @click.stop="
                  outClick()
                  currentShowMoreKey = `file_${item.knowl_id}`
                "
              ></i>
            </div>
            <!-- 更多弹窗 -->
            <div
              class="gengduo-pop flex flex-y-positive flex-x-center"
              v-if="
                (perInfo.editFile || perInfo.deleteFile) &&
                currentShowMoreKey === `file_${item.knowl_id}`
              "
            >
              <!-- <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.editFile && !collectMode"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="editFile(index)"
                >
                  <i class="iconfont icon-a-EditSquare2"></i>
                  <span>编辑</span>
                </div>
              </li>
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.editFile && !collectMode"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="showMoveFile(index)"
                >
                  <i class="iconfont icon-Logout"></i>
                  <span>移动</span>
                </div>
              </li> -->
              <li
                class="flex flex-y-center flex-x-center"
                v-if="perInfo.deleteFile"
              >
                <div
                  class="li-node flex flex-y-center flex-x-between"
                  @click="showDeleteFile(item.knowl_id)"
                >
                  <i class="iconfont icon-Delete"></i>
                  <span>删除</span>
                </div>
              </li>
            </div>
          </li>
        </ul>
      </div>
      <!-- 上传文件用的input -->
      <input
        ref="uploadFileInput"
        type="file"
        v-show="false"
        multiple
        @change="addAttachment"
      />
      <!-- 上传文件夹用的input -->
      <input
        ref="uploadFolderInput"
        type="file"
        v-show="false"
        multiple
        webkitdirectory
        @change="uploadFolder"
      />
      <!-- 修改文件夹名称弹窗 -->
      <Knowledge-edit-name-pop
        v-if="editFileNameIndex !== -1"
        @cancle="editFileNameIndex = -1"
        @confirm="editFileName"
        :value="fileList[editFileNameIndex].knowl_name || ''"
      ></Knowledge-edit-name-pop>
      <!-- 删除文件夹弹窗 -->
      <knowledge-delete-pop
        msg="确定要删除文件夹么？"
        @cancle="deleteFolderId = -1"
        @confirm="
          collectMode ? deleteCollectFolder($event) : deleteFolder($event)
        "
        v-if="deleteFolderId !== -1"
      ></knowledge-delete-pop>
      <!-- 删除文件弹窗 -->
      <knowledge-delete-pop
        msg="确定要删除文件么？"
        @cancle="deleteFileId = -1"
        @confirm="deleteFile"
        v-if="deleteFileId !== -1"
      ></knowledge-delete-pop>
      <!-- 批量删除弹窗 -->
      <knowledge-delete-pop
        msg="确定要删除文件么？"
        @cancle="checkBatchDeleteVisible = false"
        @confirm="batchDelete"
        v-if="checkBatchDeleteVisible"
      ></knowledge-delete-pop>
      <!-- 移动文件夹弹窗 -->
      <!-- <knowledge-move
        @cancle="moveFolderVisible = false"
        @confirm="moveFolder"
        :folderIdList="moveFolderIdList"
        v-if="moveFolderVisible"
      ></knowledge-move> -->
      <!-- 移动文件弹窗 -->
      <!-- <knowledge-move
        @cancle="moveFileVisible = false"
        @confirm="moveFile"
        :fileIdList="moveFileIdList"
        v-if="moveFileVisible"
      ></knowledge-move> -->
      <!-- 批量移动弹窗 -->
      <knowledge-move
        @cancle="checkBatchMoveVisible = false"
        @confirm="batchMove"
        :fileIdList="moveFileIdList"
        :folderIdList="moveFolderIdList"
        v-if="checkBatchMoveVisible"
      ></knowledge-move>
      <!-- 知识库文件分享弹窗 -->
      <Knowledge-share-pop
        @cancle="shareVisible = false"
        v-if="shareVisible"
      ></Knowledge-share-pop>
      <!-- 修改文件夹 -->
      <knowledge-edit-folder
        @cancle="editFolderInfo = null"
        @confirm="editFolder"
        :title="editFolderInfo.folder_name || ''"
        :describe="editFolderInfo.folder_describe_html"
        v-if="editFolderInfo !== null"
      ></knowledge-edit-folder>
      <!-- 添加文件夹 -->
      <knowledge-edit-folder
        @cancle="addFolderVisible = false"
        @confirm="collectMode ? addCollectFolder($event) : addFolder($event)"
        v-if="addFolderVisible"
      ></knowledge-edit-folder>
      <!-- 创建知识库弹窗 -->
      <knowledge-add-base
        @cancle="createVisible = false"
        v-if="createVisible"
      ></knowledge-add-base>
      <!-- 上传进度弹窗 -->
      <upload-document
        :current="uploadFolderTip.current"
        :total="uploadFolderTip.total"
        :name="uploadFolderTip.name"
        :progress="uploadFolderTip.progress"
        v-if="uploadFolderTip != null"
      ></upload-document>

      <!-- 在线文档编辑页面 -->
    </div>
    <!-- 添加文本 -->
    <online-document
      v-if="addDocumentVisible"
      @close="
        addDocumentVisible = false
        folderVisible = true
      "
      @confirm="addDocument"
    ></online-document>
    <!-- 编辑文本 -->
    <online-document
      v-if="editDocumentIndex !== -1"
      @close="
        editDocumentIndex = -1
        folderVisible = true
      "
      :title="fileList[editDocumentIndex].knowl_name || ''"
      :describe="fileList[editDocumentIndex].text_info.knowl_content_html || ''"
      @confirm="editDocument"
    ></online-document>
    <!-- 文件夹共享设置 -->
    <folder-share-setting
      :folderId="folderId"
      @cancel="teamShareVisible = false"
      v-if="teamShareVisible"
    ></folder-share-setting>
    <!-- 文件夹权限设置 -->
    <folder-per-setting
      :folderId="folderId"
      @cancel="teamPerVisible = false"
      v-if="teamPerVisible"
    ></folder-per-setting>
  </div>
</template>

<script>
// 导入知识库文件树组件
// import KnowledgeTree from '../components/knowledge/KnowledgeFolderTree.vue'
// 导入修改文件夹弹窗组件
import KnowledgeEditFolder from '@/components/knowledge/KnowledgeEditFolder.vue'
// 导入文件移动弹窗组件
import KnowledgeMove from '@/components/knowledge/KnowlegeMove.vue'
// 导入删除弹窗组件
import KnowledgeDeletePop from '@/components/knowledge/KnowledgeDeletePop.vue'
// 导入创建知识库弹窗组件
import KnowledgeAddBase from '@/components/knowledge/KnowledgeAddBase.vue'
// 导入分享知识库弹窗组件
import KnowledgeSharePop from '@/components/knowledge/KnowledgeSharePop.vue'
// 导入修改知识库名称弹窗组件
import KnowledgeEditNamePop from '@/components/knowledge/KnowledgeEditNamePop.vue'
// 导入在线文档编辑组件
import OnlineDocument from '@/components/knowledge/OnlineDocument.vue'
// 批量操作弹窗
import BatchOperationPop from '@/components/knowledge/BatchOperationPop.vue'
// 上传进度弹窗
import UploadDocument from '@/components/knowledge/UploadDocument.vue'
import MemberTree from '@/components/common/MemberTree.vue'
import SearchPerson from '@/components/common/SearchPerson.vue'
// 文件夹共享设置
import FolderShareSetting from '@/components/knowledge/FolderShareSetting.vue'
// 文件夹权限设置
import FolderPerSetting from '@/components/knowledge/FolderPerSetting.vue'
import { mapState } from 'vuex'
export default {
  name: 'knowledge-file-folder',
  components: {
    // KnowledgeTree,
    KnowledgeEditFolder,
    KnowledgeMove,
    KnowledgeDeletePop,
    KnowledgeAddBase,
    KnowledgeSharePop,
    KnowledgeEditNamePop,
    OnlineDocument,
    MemberTree,
    SearchPerson,
    BatchOperationPop,
    UploadDocument,
    FolderShareSetting,
    FolderPerSetting
  },
  props: {
    // 文件夹id
    folderId: {
      type: Number,
      default: -1
    },
    // 收藏模式
    collectMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      folderVisiblewap: false,
      // 是否显示更多弹窗
      currentShowMoreKey: '',
      // 拷贝链接提示展示
      isShowCopyLinkTip: false,
      // 是否显示文件夹更多弹窗
      isShowFolderMore: false,
      // 信息
      info: {},
      // 文件夹列表
      folderList: [],
      // 文件列表
      fileList: [],
      // 文件夹链列表
      folderChainList: [],
      // 创建文件夹弹窗
      addFolderVisible: false,
      // 创建文件弹窗
      createVisible: false,
      // 移动文件夹弹窗
      moveFolderVisible: false,
      // 移动文件弹窗
      moveFileVisible: false,
      // 批量移动弹窗
      checkBatchMoveVisible: false,
      // 分享文件夹弹窗
      shareVisible: false,
      // 修改文件名称index
      editFileNameIndex: -1,
      // 删除文件夹id
      deleteFolderId: -1,
      // 修改文件夹信息
      editFolderInfo: null,
      // 删除文件id
      deleteFileId: -1,
      // 添加文档弹窗
      addDocumentVisible: false,
      // 修改文档index
      editDocumentIndex: -1,
      // 文件夹页面
      folderVisible: true,
      // 选择导师index
      chooseAuthorIndex: -1,
      // 职员数据
      clerkTree: [],
      // 选中的文件列表
      selectedFileList: [],
      // 选中的文件夹列表
      selectedFolderList: [],
      // 加载
      loading: false,
      // 团队允许修改
      isTeamAllowEdit: false,
      // 团队允许管理员
      isTeamAdmin: false,
      // 确定批量删除
      checkBatchDeleteVisible: false,
      // 上传文件夹提示
      uploadFolderTip: null,
      // 移动的文件夹id列表
      moveFolderIdList: [],
      // 移动的文件id列表
      moveFileIdList: [],
      // 团队共享设置展示
      teamShareVisible: false,
      // 团队权限设置展示
      teamPerVisible: false
    }
  },
  computed: {
    ...mapState(['myClerkId', 'clickBodyIndex']),
    // 当前文件夹类型
    folderType() {
      return this.info.folder_type || ''
    },
    // 文件夹层级
    folderLevel() {
      return this.folderChainList.length || 0
    },
    // 文件夹链的翻转列表
    reverseFolderChainList() {
      return [...this.folderChainList].reverse()
    },
    // // 下拉选择
    // eliconcaretbottom() {
    //   console.log('222')
    // },
    // 面包屑列表
    breadcrumbList() {
      // if (this.info.folder_id == null) {
      //   return []
      // }
      let top = null
      // 收藏模式
      if (this.collectMode) {
        top = { folder_id: 0, folder_name: '收藏知识库' }
        if (this.folderChainList.length === 0) {
          return [top]
        } else {
          if (this.reverseFolderChainList.length < 3) {
            return [top, ...this.reverseFolderChainList].slice(-3)
          } else {
            return this.reverseFolderChainList.slice(-3)
          }
        }
      } else {
        if (this.folderType === 1) {
          top = { folder_id: 1, folder_name: '公司知识库' }
        }
        if (this.folderType === 2) {
          top = { folder_id: 2, folder_name: '团队知识库' }
        }
        if (this.folderChainList.length === 0) {
          return [top]
        } else {
          return this.reverseFolderChainList.slice(-3)
        }
      }
    },
    // 已选择数量
    selectedNumber() {
      return this.selectedFileList.length + this.selectedFolderList.length
    },
    // 全部数量
    totalNumber() {
      return this.folderList.length + this.fileList.length
    },
    // 是否全部选中
    isAllSelected() {
      if (this.totalNumber === 0) {
        return false
      }
      return this.totalNumber === this.selectedNumber
    },
    // 权限信息
    perInfo() {
      // 添加文件
      let addFile = false
      // 修改文件
      let editFile = false
      // 删除文件
      let deleteFile = false
      // 移动文件
      let moveFile = false
      // 添加文件夹
      let addFolder = false
      // 修改文件夹
      let editFolder = false
      // 删除文件夹
      let deleteFolder = false
      // 移动文件夹
      let moveFolder = false
      // 批量删除
      let batchDelete = false
      // 批量移动
      let batchMove = false

      if (this.collectMode) {
        addFolder = true
        editFolder = true
        deleteFolder = true
      } else {
        switch (this.folderType) {
          // 公司
          case 1: {
            if (this.$hasPer(6)) addFile = true
            if (this.$hasPer(7)) editFile = true
            if (this.$hasPer(8)) deleteFile = true
            if (this.$hasPer(29)) moveFile = true
            if (this.$hasPer(3)) addFolder = true
            if (this.$hasPer(4)) editFolder = true
            if (this.$hasPer(5)) deleteFolder = true
            if (this.$hasPer(27)) moveFolder = true
            if (this.$hasAllPer(5, 8)) batchDelete = true
            if (this.$hasPer(27, 29)) batchMove = true
            break
          }
          // 公司
          case 2: {
            if (this.isTeamAllowEdit) {
              addFile = true
              editFile = true
              deleteFile = true
              moveFile = true
              addFolder = true
              editFolder = true
              deleteFolder = true
              moveFolder = true
              batchDelete = true
              batchMove = true
              break
            }
            if (this.folderId !== 2) {
              if (this.$hasPer(23)) addFile = true
              if (this.$hasPer(24)) editFile = true
              if (this.$hasPer(25)) deleteFile = true
              if (this.$hasPer(30)) moveFile = true
              if (this.$hasPer(20)) addFolder = true
              if (this.$hasPer(21)) editFolder = true
              if (this.$hasPer(22)) deleteFolder = true
              if (this.$hasPer(28)) moveFolder = true
              if (this.$hasAllPer(25, 22)) batchDelete = true
              if (this.$hasAllPer(28, 30)) batchMove = true
            }

            break
          }
        }
      }

      return {
        addFile,
        editFile,
        deleteFile,
        moveFile,
        addFolder,
        editFolder,
        deleteFolder,
        moveFolder,
        batchDelete,
        batchMove
      }
    },
    // 是否为主目录
    isMainFolder() {
      if (this.collectMode) {
        return this.info.folder_id == null
      } else {
        return this.folderId === 1 || this.folderId === 2
      }
    }
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      if (hasLink) {
        return url.replace(
          reg,
          `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
        )
      } else {
        return url
      }
    },
    // 初始化
    async init() {
      this.loading = true
      this.outClick()
      this.selectedFolderList = []
      this.selectedFileList = []
      this.moveFolderIdList = []
      this.moveFileIdList = []
      // 收藏模式
      if (this.collectMode) {
        await this.getCollectFolderDetail()
      } else {
        await this.getFolderDetail()
      }
      setTimeout(() => {
        this.loading = false
      }, 100)
    },
    // 获取收藏详情
    async getCollectFolderDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlCollectFolderDetail, {
          folder_id: this.folderId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this, 'info', res.info || {})
        this.folderList = res.folder_list
        this.fileList = res.file_list
        this.folderChainList = res.folder_chain_list || []
        console.log(this.folderChainList)
      }
    },
    // 获取详情
    async getFolderDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlFolderDetail, {
          folder_id: this.folderId
        })
      )
      if (err) {
        console.log(err)
        this.$router.back()
      } else if (res.state === 'ok') {
        this.$set(this, 'info', res.info)
        this.folderList = res.folder_list
        this.fileList = res.file_list
        this.folderChainList = res.folder_chain_list || []
        this.isTeamAllowEdit = res.team_allow_edit || false
        this.isTeamAdmin = res.team_admin || false
      } else {
        this.$router.back()
      }
    },
    // 获取列表
    async getList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlList, {
          folder_id: this.folderId,
          detail: 1
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.folderList = res.folder_list
        this.fileList = res.file_list
      }
    },
    // 获取收藏列表
    async getCollectList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlCollectList, {
          folder_id: this.folderId,
          detail: 1
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.folderList = res.folder_list
        this.fileList = res.file_list
      }
    },
    // 复制链接
    copyLink() {
      this.$copyText(
        `${this.$config.frontHome}knowledge/folder/${this.folderId}`
      ).then(
        () => {
          // this.$message({
          //   showClose: true,
          //   message: '已成功复制，可直接去粘贴',
          //   type: 'success'
          // })
          this.isShowCopyLinkTip = true
          // 2秒后提示隐藏
          setTimeout(() => {
            this.isShowCopyLinkTip = false
          }, 2000)
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // 展示删除文件夹弹窗
    showDeleteFolder(id) {
      this.outClick()
      this.deleteFolderId = id
    },
    // 展示修改文件夹弹窗
    showEditFolder(info) {
      this.outClick()
      this.editFolderInfo = info
    },
    // 展示删除文件弹窗
    showDeleteFile(id) {
      this.outClick()
      this.deleteFileId = id
    },
    // 展示修改文件
    showEditFile(index) {
      const item = this.fileList[index]
      console.log(item)
      switch (item.content_type) {
        // 文本
        case 1: {
          break
        }
        // 文件
        case 2: {
          this.editFileNameIndex = index
          break
        }
      }
    },
    // 跳转文件夹
    toFolder(id) {
      this.$emit('toFolder', {
        folder_id: id,
        collect_mode: this.collectMode
      })
      this.folderVisiblewap = true
    },
    // 跳转文件
    toFile(id) {
      this.$emit('toFile', {
        file_id: id,
        collect_mode: this.collectMode
      })
    },
    // 删除文件夹
    async deleteFolder() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.batchDeleteKnowl, {
          folder_ids: this.deleteFolderId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (this.folderId === this.deleteFolderId) {
          this.toFolder(this.folderChainList[1].folder_id)
        } else {
          this.getList()
        }
        this.deleteFolderId = -1
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 删除收藏文件夹
    async deleteCollectFolder() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteKnowlCollectFolder, {
          folder_id: this.deleteFolderId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.deleteFolderId = -1
        this.getCollectList()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 删除文件
    async deleteFile() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.batchDeleteKnowl, {
          knowl_ids: this.deleteFileId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.deleteFileId = -1
        this.getList()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 修改文件名称
    async editFileName(value) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlName, {
          knowl_id: this.fileList[this.editFileNameIndex].knowl_id,
          knowl_name: value
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.editFileNameIndex = -1
        this.getList()
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
    },
    // 添加文件夹
    async addFolder({
      title,
      describe_html: describeHtml,
      describe_text: describeText
    }) {
      await this.addOneFolder({
        folder_id: this.folderId,
        title,
        describe_html: describeHtml,
        describe_text: describeText
      })
      this.addFolderVisible = false
      this.getList()
      this.$message({
        showClose: true,
        message: '添加成功',
        type: 'success'
      })
    },
    // 添加一个文件夹
    async addOneFolder({
      folder_id: folderId,
      title,
      describe_html: describeHtml,
      describe_text: describeText
    }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addKnowlFolder, {
          folder_name: title,
          pid: folderId,
          folder_describe_html: describeHtml,
          folder_describe_text: describeText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        return res
      }
    },
    // 添加收藏文件夹
    async addCollectFolder({
      title,
      describe_html: describeHtml,
      describe_text: describeText
    }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addKnowlCollectFolder, {
          folder_name: title,
          folder_describe_html: describeHtml,
          folder_describe_text: describeText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.addFolderVisible = false
        this.getCollectList()
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
      }
    },
    // 修改文件夹
    async editFolder({
      title,
      describe_html: describeHtml,
      describe_text: describeText
    }) {
      const isEditDetail = this.editFolderInfo.filder_id === this.folderId
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlFolder, {
          folder_name: title,
          folder_id: this.editFolderInfo.folder_id,
          folder_describe_html: describeHtml,
          folder_describe_text: describeText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.editFolderInfo = null
        if (isEditDetail) {
          this.init()
        } else {
          this.getList()
        }
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
    },
    // 修改收藏文件夹
    async editCollectFolder({
      title,
      describe_html: describeHtml,
      describe_text: describeText
    }) {
      const isEditDetail = this.editFolderInfo.filder_id === this.folderId
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlFolder, {
          folder_name: title,
          folder_id: this.editFolderInfo.filder_id,
          folder_describe_html: describeHtml,
          folder_describe_text: describeText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.editFolderInfo = null
        if (isEditDetail) {
          this.init()
        } else {
          this.getCollectList()
        }
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
    },
    // 点到外面，就关闭更多和进度弹窗
    outClick() {
      this.isShowFolderMore = false
      this.currentShowMoreKey = ''
      this.chooseAuthorIndex = -1
    },
    // 判断是否是图片
    isPic(file) {
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      return imgTypes.includes(type)
    },
    // 获取文件的图标
    getFileIcon(file) {
      const type = file.file_ext
      switch (type) {
        // 如果是doc文件
        case 'doc':
        case 'docx':
          return '#icon-Frame3'
        // 如果是PDF
        case 'pdf':
          return '#icon-Frame4'
        // 如果是Excel
        case 'xls':
        case 'xlsx':
          return '#icon-Frame2'
        // 如果是图片，返回图片本身
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'png':
        case 'gif':
          return file.attachment_path
        // 如果是PPT
        case 'ppt':
        case 'pptx':
          return '#icon-Frame5'
        // 如果是TXT
        case 'txt':
          return '#icon-Frame12'
        // 如果是其他
        default:
          return '#icon-Frame21'
      }
    },
    // 添加文件
    async addAttachment(e) {
      // e.target.files.forEach(async (ee, i) => {
      //   await this.addOneAttachment(ee)
      //   console.log(i)
      // })
      const len = e.target.files.length
      const list = e.target.files
      const upload = async (i) => {
        await this.addOneAttachment(list[i], this.folderId)
        if (i < len - 1) {
          await upload(++i)
        }
      }
      await upload(0)
      this.$refs.uploadFileInput.value = ''
    },
    // 上传1个文件
    async addOneAttachment(file, folderId) {
      const formdata = new FormData()
      formdata.set('file', file)
      formdata.set('folder_id', folderId)
      this.fileList.push({
        knowl_id: '',
        knowl_name: file.name,
        progress: 0,
        content_type: 2,
        attachment_info: {
          file_ext: 'xxx'
        }
      })
      const index = this.fileList.length - 1
      const progressFn = (e) => {
        this.$set(
          this.fileList[index],
          'progress',
          Number(((e.loaded / e.total) * 100).toFixed(0))
        )
      }
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(
          this.$api.addKnowlAttachment,
          formdata,
          progressFn
        ).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$set(this.fileList, index, res.info)
        // this.fileList.push(res.info)
        return true
      }
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 选择导师
    async chooseAuthor(clerk) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlAuthorClerk, {
          knowl_id: this.fileList[this.chooseAuthorIndex].knowl_id,
          clerk_id: clerk.clerk_id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.fileList, this.chooseAuthorIndex, res.info)

        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
      this.chooseAuthorIndex = -1
    },
    // 选中文件夹
    selectFolder(id) {
      const index = this.selectedFolderList.indexOf(id)
      if (index === -1) {
        this.selectedFolderList.push(id)
      } else {
        this.selectedFolderList.splice(index, 1)
      }
    },
    // 选中文件
    selectFile(id) {
      const index = this.selectedFileList.indexOf(id)
      if (index === -1) {
        this.selectedFileList.push(id)
      } else {
        this.selectedFileList.splice(index, 1)
      }
    },
    // 全选
    selectAll(checked = null) {
      if (checked != null) {
        if (!checked) {
          this.selectedFolderList = []
          this.selectedFileList = []
        } else {
          this.selectedFolderList = this.folderList.map((e) => e.folder_id)
          this.selectedFileList = this.fileList.map((e) => e.knowl_id)
        }
      } else {
        if (this.isAllSelected) {
          this.selectedFolderList = []
          this.selectedFileList = []
        } else {
          this.selectedFolderList = this.folderList.map((e) => e.folder_id)
          this.selectedFileList = this.fileList.map((e) => e.knowl_id)
        }
      }
    },
    // 添加文本类型
    async addDocument({
      title,
      content_html: contentHtml,
      content_text: contentText
    }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addKnowlContent, {
          folder_id: this.folderId,
          knowl_name: title,
          knowl_content_html: contentHtml,
          knowl_content_text: contentText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.addDocumentVisible = false
        this.folderVisible = true
        this.fileList.push(res.info)
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
      }
    },
    // 修改文本类型
    async editDocument({
      title,
      content_html: contentHtml,
      content_text: contentText
    }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlContent, {
          knowl_id: this.fileList[this.editDocumentIndex].knowl_id,
          knowl_name: title,
          knowl_content_html: contentHtml,
          knowl_content_text: contentText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.editDocumentIndex = -1
        this.folderVisible = true
        this.$set(this.fileList, this.editDocumentIndex, res.info)
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
    },
    // 修改文件
    editFile(index) {
      switch (this.fileList[index].content_type) {
        case 1: {
          this.editDocumentIndex = index
          this.folderVisible = false
          break
        }
        case 2: {
          this.showEditFile(index)
          break
        }
      }
    },
    // 批量删除
    async batchDelete() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (
        this.selectedFileList.length === 0 &&
        this.selectedFolderList.length === 0
      ) {
        errmsg('请选择知识库或文件夹')
        return
      }

      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.batchDeleteKnowl, {
          knowl_ids: this.selectedFileList.join(','),
          folder_ids: this.selectedFolderList.join(',')
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.checkBatchDeleteVisible = false
        this.selectedFolderList = []
        this.selectedFileList = []
        this.getList()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 上传文件夹
    async uploadFolder(e) {
      // 保存文件夹路径id
      const folderMap = {}
      const len = e.target.files.length
      const list = e.target.files
      // 设置提示
      this.$set(this, 'uploadFolderTip', {
        total: len,
        current: 0,
        name: '',
        progress: 0
      })
      // 上传文件
      const uploadFile = async (index, folderId) => {
        const formdata = new FormData()
        const file = list[index]
        formdata.set('file', file)
        formdata.set('folder_id', folderId)
        this.$set(this.uploadFolderTip, 'current', index + 1)
        this.$set(this.uploadFolderTip, 'name', file.name)
        this.$set(this.uploadFolderTip, 'progress', 0)
        const progressFn = (e) => {
          this.$set(
            this.uploadFolderTip,
            'progress',
            Number(((e.loaded / e.total) * 100).toFixed(0))
          )
        }
        const [err, res] = await this.$util.to(
          this.$util.uploadFile(
            this.$api.addKnowlAttachment,
            formdata,
            progressFn
          ).result
        )
        if (err) {
          this.$message.error(err)
        } else if (res.state === 'ok') {
          // this.fileList.push(res.info)
          return true
        }
      }
      // 上传
      const upload = async (i) => {
        const file = list[i]
        // 文件
        const fileName = file.name
        // 带文件名称的路径
        const webkitRelativePath = file.webkitRelativePath
        // 文件夹路径
        const folderPath = webkitRelativePath.substr(
          0,
          webkitRelativePath.length - fileName.length - 1
        )
        // 判断路径是否存在
        let folderId = folderMap[folderPath]
        // 不存在时创建文件夹
        if (folderId == null) {
          // 拆分成数组
          const folderNames = folderPath.split('/')

          for (let fi = 0; fi < folderNames.length; fi++) {
            // 当前层级文件夹路径
            const folder = folderNames.slice(0, fi + 1).join('/')
            // 当前层级文件夹名称
            const folderName = folderNames[fi]
            // 文件夹不存在时创建新文件夹
            if (folderMap[folder] == null) {
              let pid = this.folderId
              if (fi > 0) {
                pid = folderMap[folderNames.slice(0, fi).join('/')]
              }
              const res = await this.addOneFolder({
                folder_id: pid,
                title: folderName
              })
              if (res.state === 'ok') {
                folderMap[folder] = res.folder_id
              }
            }
          }
          folderId = folderMap[folderPath]
        }
        await uploadFile(i, folderId)
        if (i < len - 1) {
          await upload(++i)
        }
      }
      await upload(0)
      this.$refs.uploadFolderInput.value = ''
      this.uploadFolderTip = null
      this.getList()
      this.$message({
        showClose: true,
        message: '上传成功',
        type: 'success'
      })
    },
    // 展示移动文件
    showMoveFile(index) {
      this.moveFolderIdList = []
      this.moveFileIdList = [this.fileList[index].knowl_id]
      this.checkBatchMoveVisible = true
    },
    // 展示移动文件夹
    showMoveFolder(index) {
      this.isShowFolderMore = false
      this.moveFileIdList = []
      if (index != null) {
        this.moveFolderIdList = [this.folderList[index].folder_id]
      } else {
        this.moveFolderIdList = [this.folderId]
      }
      this.checkBatchMoveVisible = true
    },
    // 展示批量移动
    showBatchMove() {
      this.moveFileIdList = this.selectedFileList
      this.moveFolderIdList = this.selectedFolderList
      this.checkBatchMoveVisible = true
    },
    // 移动文件
    async batchMove(id) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.batchMoveKnowl, {
          from_folder_ids: this.moveFolderIdList.join(),
          from_knowl_ids: this.moveFileIdList.join(),
          to_folder_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.selectedFolderList = []
        this.selectedFileList = []
        this.moveFileIdList = []
        this.moveFolderIdList = []
        this.checkBatchMoveVisible = false
        this.getList()
        this.$message({
          showClose: true,
          message: '移动成功',
          type: 'success'
        })
      }
    }
  },
  mounted() {
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
    // document.addEventListener('click', this.outClick)
    // 获取职员数据
    this.getClerkTree()
    if (this.folderId !== -1) {
      this.init()
    }
  },
  watch: {
    clickBodyIndex() {
      this.outClick()
    },
    folderId(val) {
      if (val !== -1) {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .pc {
  }
  .show {
    display: none;
  }
  .wap {
    display: none !important;
  }
  .file-folder {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    // padding: 90px 314px 0 50px;
    padding-top: 70px;
    height: calc(100vh - 60px);
    background-color: #fff;

    .file-folder-scroll {
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 120px;
      box-sizing: border-box;
    }

    .folder-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 70px;
      padding: 0 50px 0 48px;
      box-sizing: border-box;
      background-color: #fff;
      border-bottom: 1px solid #eeeeee;
      z-index: 1;

      .base-path {
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          color: #333333;
          cursor: pointer;
        }
        .iconfont {
          margin: 0 6px 0 6px;
          font-size: 22px;
          color: #999999;
        }
      }

      .funtion-box {
        position: relative;
        .copy-box {
          position: relative;
          // 提示文字
          .tip-text {
            right: 0;
            position: absolute;
            font-size: 14px;
            line-height: 1.5;
            white-space: nowrap;
            background: #fff;
            color: $main-color;
          }
          .id-text {
            margin-right: 12px;
            font-size: 14px;
            line-height: 1.5;
            cursor: pointer;
            color: #bbb;
          }
        }
        .share-box {
          position: relative;
          .icon-yishoucang {
            position: absolute;
            font-size: 28px;
            color: #ffab2e;
            background: #ffffff;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #999999;
          margin-left: 16px;
          cursor: pointer;
        }
        // 更多弹窗
        .gengduo-pop {
          z-index: 100;
          position: absolute;
          top: 30px;
          right: -6px;
          width: 100px;
          // height: 80px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          li {
            width: 100%;
            height: 50%;
            .li-node {
              cursor: pointer;
              width: 76px;
              height: 33px;
              box-sizing: border-box;
              padding: 0 10px 0 10px;
              .iconfont {
                font-size: 16px;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
              &:hover {
                background: rgba(0, 128, 255, 0.1);
                border-radius: 4px;
                .iconfont {
                  color: #0080ff;
                }
                span {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }

    .introduce-text {
      padding: 10px 50px 10px 50px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: #333333;
    }
    .file-list {
      padding: 0 50px 20px 50px;
      margin-top: 30px;
      .icon {
        font-size: 20px;
      }
      .ul-top {
        // margin-bottom: 2px;
        padding: 0 10px 18px 10px;

        span {
          text-align: start;
          width: 13%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          &:first-of-type {
            margin-left: 20px;
            width: 63%;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #646c9a;
          }
        }
        .operator {
          text-align: end;
          width: 6%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
      }
      > li {
        // margin-top: 38px;
        padding: 13px 10px 13px 10px;
        position: relative;

        &:hover {
          background-color: #f6f6f6;
        }

        .name {
          margin-left: 20px;
          width: 63%;
          color: #646c9a;
          .icon {
            font-size: 30px;
          }
          .folder-progress {
            margin-left: 12px;
            p {
              width: 100%;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              cursor: pointer;
            }
          }
        }
        .author,
        .last-author,
        .last-time {
          position: relative;
          text-align: start;
          width: 13%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }

        .author .show {
          display: block;
          min-height: 21px;

          &.allow-edit {
            cursor: pointer;
          }
        }

        .icon-gengduo3 {
          text-align: end;
          width: 6%;
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 21px;
          color: #999999;
          cursor: pointer;
        }
        // 更多弹窗
        .gengduo-pop {
          z-index: 100;
          position: absolute;
          top: 30px;
          right: -6px;
          width: 100px;
          // height: 120px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          li {
            margin: 0;
            width: 100%;
            height: 33px;
            .li-node {
              cursor: pointer;
              width: 76px;
              height: 33px;
              box-sizing: border-box;
              padding: 0 10px 0 10px;
              .iconfont {
                font-size: 16px;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
              &:hover {
                background: rgba(0, 128, 255, 0.1);
                border-radius: 4px;
                .iconfont {
                  color: #0080ff;
                }
                span {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }
    .file-operator {
      width: 180px;
      position: absolute;
      top: 100px;
      right: 34px;
      background: #ffffff;

      .create-dom {
        cursor: pointer;
        width: 180px;
        height: 62px;
        background: linear-gradient(0deg, #0080ff, #0080ff),
          linear-gradient(90deg, #0080ff 0%, #00a3ff 100%);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 40px 0 40px;
        .icon-add3 {
          font-size: 22px;
          color: #ffffff;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
        }
      }
      ul {
        width: 100%;
        margin-top: 14px;
        padding-bottom: 26px;
        // border-bottom: 1px solid #eeeeee;
        li {
          margin-top: 18px;
          cursor: pointer;
          .iconfont {
            color: #0080ff;
            font-size: 24px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #0080ff;
            margin-left: 12px;
          }
        }
      }
      .recycle-bin {
        cursor: pointer;
        margin-top: 26px;
        .iconfont {
          color: #0080ff;
          font-size: 24px;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #0080ff;
          margin-left: 12px;
        }
      }
    }
  }

  .choose-person-box {
    position: absolute;
    top: 20px;
    left: -100px;
    z-index: 2;
    margin: 10px 0;
    width: 200px;
    background: #fff;
    box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
    border-radius: 4px;
  }

  // 进度条样式
  .folder-progress::v-deep .el-progress {
    width: 180px;
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none !important;
  }
  .wap {
    display: block !important;
  }
  .file-folder {
    position: relative;
    box-sizing: border-box;
    padding-top: 50px;
    height: calc(100vh - 60px);
    background-color: #fff;

    .folder-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 70px;
      padding: 0 50px 0 48px;
      box-sizing: border-box;
      background-color: #fff;
      border-bottom: 1px solid #eeeeee;
      z-index: 1;

      .base-path {
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          color: #333333;
          cursor: pointer;
        }
        .iconfont {
          margin: 0 6px 0 6px;
          font-size: 22px;
          color: #999999;
        }
      }

      .funtion-box {
        position: relative;
        .copy-box {
          position: relative;
          // 提示文字
          .tip-text {
            right: 0;
            position: absolute;
            font-size: 14px;
            line-height: 1.5;
            white-space: nowrap;
            background: #fff;
            color: $main-color;
          }
          .id-text {
            margin-right: 12px;
            font-size: 14px;
            line-height: 1.5;
            cursor: pointer;
            color: #bbb;
          }
        }
        .share-box {
          position: relative;
          .icon-yishoucang {
            position: absolute;
            font-size: 28px;
            color: #ffab2e;
            background: #ffffff;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #999999;
          margin-left: 16px;
          cursor: pointer;
        }
        // 更多弹窗
        .gengduo-pop {
          z-index: 100;
          position: absolute;
          top: 30px;
          right: -6px;
          width: 100px;
          // height: 80px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          li {
            width: 100%;
            height: 50%;
            .li-node {
              cursor: pointer;
              width: 76px;
              height: 33px;
              box-sizing: border-box;
              padding: 0 10px 0 10px;
              .iconfont {
                font-size: 16px;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
              &:hover {
                background: rgba(0, 128, 255, 0.1);
                border-radius: 4px;
                .iconfont {
                  color: #0080ff;
                }
                span {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }

    .introduce-text {
      padding: 10px 50px 10px 50px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: #333333;
    }
    .file-list {
      .icon {
        font-size: 20px;
      }
      .ul-top {
        // margin-bottom: 2px;
        padding: 0 10px 18px 10px;

        span {
          text-align: start;
          width: 13%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          &:first-of-type {
            margin-left: 20px;
            width: 63%;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #646c9a;
          }
        }
        .operator {
          text-align: end;
          width: 6%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
      }
      > li {
        &:hover {
          background: none;
        }

        .name {
          .icon {
            font-size: 30px;
            position: absolute;
            top: 15px;
            left: 0;
          }
          .folder-progress {
            margin-left: 35px;
            margin-right: 20px;
            p {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              cursor: pointer;
            }
          }
        }
        .author,
        .last-author,
        .last-time {
          display: none;
          position: relative;
          text-align: start;
          width: 13%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }

        .author .show {
          display: block;
          min-height: 21px;

          &.allow-edit {
            cursor: pointer;
          }
        }

        .icon-gengduo3 {
          text-align: end;
          width: 6%;
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 21px;
          color: #999999;
          cursor: pointer;
        }
        // 更多弹窗
        .gengduo-pop {
          z-index: 100;
          position: absolute;
          top: 30px;
          right: -6px;
          width: 100px;
          // height: 120px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          li {
            margin: 0;
            width: 100%;
            height: 33px;
            .li-node {
              cursor: pointer;
              width: 76px;
              height: 33px;
              box-sizing: border-box;
              padding: 0 10px 0 10px;
              .iconfont {
                font-size: 16px;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
              &:hover {
                background: rgba(0, 128, 255, 0.1);
                border-radius: 4px;
                .iconfont {
                  color: #0080ff;
                }
                span {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }
    .file-operator {
      width: 180px;
      position: absolute;
      top: 100px;
      right: 34px;
      background: #ffffff;

      .create-dom {
        cursor: pointer;
        width: 180px;
        height: 62px;
        background: linear-gradient(0deg, #0080ff, #0080ff),
          linear-gradient(90deg, #0080ff 0%, #00a3ff 100%);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 40px 0 40px;
        .icon-add3 {
          font-size: 22px;
          color: #ffffff;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
        }
      }
      ul {
        width: 100%;
        margin-top: 14px;
        padding-bottom: 26px;
        // border-bottom: 1px solid #eeeeee;
        li {
          margin-top: 18px;
          cursor: pointer;
          .iconfont {
            color: #0080ff;
            font-size: 24px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #0080ff;
            margin-left: 12px;
          }
        }
      }
      .recycle-bin {
        cursor: pointer;
        margin-top: 26px;
        .iconfont {
          color: #0080ff;
          font-size: 24px;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #0080ff;
          margin-left: 12px;
        }
      }
    }
  }

  .choose-person-box {
    position: absolute;
    top: 20px;
    left: -100px;
    z-index: 2;
    margin: 10px 0;
    width: 200px;
    background: #fff;
    box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
    border-radius: 4px;
  }

  // 进度条样式
  .folder-progress::v-deep .el-progress {
    width: 180px;
  }

  .file-operator {
    display: none;
  }
  .file-folder .folder-top {
    padding: 0 10px 0 10px;
  }
  .file-folder .introduce-text {
    padding: 10px 10px 10px 10px;
    width: 100%;
    display: none;
  }
  .file-folder .file-list {
    padding: 5px 5px 5px 5px;
    margin-top: 1px;
    width: 95%;
  }

  .file-folder .file-list > li .name {
    margin-left: 5px;
    width: 100%;
    color: #646c9a;
  }
  .file-folder .file-folder-scroll {
    height: 100%;
    overflow-x: hidden;
    padding: 0 10px 10px;
    // padding-bottom: 10px;
    box-sizing: border-box;
  }
  .file-folder .file-list > li {
    padding: 13px 2px 13px 2px;
    position: relative;
  }
  .file-folder .folder-top .funtion-box .copy-box {
    display: none;
  }
  .file-folder .folder-top .funtion-box .iconfont {
    display: none;
  }
  .file-folder .folder-top {
    height: 50px;
  }
  .file-folder .folder-top .base-path {
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: #333333;
      cursor: pointer;
    }
    .iconfont {
      margin: 0 6px 0 6px;
      font-size: 22px;
      color: #999999;
    }
  }

  .folder-progress p:nth-child(1) {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .folder-progress p:nth-child(2) {
    font-size: 10px !important;
    color: #9b9b9b;
  }
}
</style>
