<!--
 * @Author: Betty
 * @Date: 2021-07-02 17:42:16
 * @LastEditors: Betty
 * @LastEditTime: 2021-07-05 15:39:23
 * @Description: 编辑
-->
<template>
  <el-tabs v-model="addMeetingActive">
    <el-tab-pane label="我的会议" name="addMeeting">
      <!-- 主要部分，编辑表单 -->
      <slot @close-modal="closeModal"></slot>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: 'edit-meeting-modal',
  data() {
    return {
      addMeetingActive: 'addMeeting'
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss">
</style>
