<!--
 * @Date: 2021-06-01 10:01:35
 * @LastEditTime: 2021-12-20 10:04:32
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <!-- 选择成员弹窗 -->
  <el-dialog
    class="add-member"
    :title="modalTitle"
    :modal="true"
    :visible.sync="isShowAddMember"
    width="600"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <!-- 这个是弹窗内容 -->
    <div class="add-member-box flex">
      <!-- 左边 -->
      <div class="flex1 left-box">
        <!-- 查找联系人的弹窗 -->
        <div class="search-box flex flex-y-center flex-x-between">
          <input
            type="text"
            class="flex1 input"
            v-model="searchName"
            placeholder="查找联系人"
            @input="triggerSearch"
          />
          <!-- 清空按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length > 0"
            class="clear-btn"
            @click="clearSearch"
          >
            <span class="iconfont icon-decline"></span>
          </button>
          <!-- 搜索按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length === 0"
            class="search-btn"
            @click="searchClerkList"
          >
            <span class="iconfont icon-search"></span>
          </button>
        </div>
        <!-- 数据区域 -->
        <div class="tree-list">
          <!-- 使用树形组件，显示树形结构 -->
          <template v-if="searchName.trim().length === 0">
            <div v-for="item in dataList" :key="item.id">
              <member-tree
                :treeNode="item"
                @clerk-choose="toAddMember($event)"
              ></member-tree>
            </div>
          </template>
          <!-- 搜索结果显示在这里 -->
          <template v-else>
            <div class="search-result">
              <div
                class="member-item flex flex-y-center"
                v-for="(clerk, index) in searchResultList"
                :key="index"
                @click="toAddMember(clerk)"
              >
                <avatar
                  :userInfo="clerk"
                  :size="30"
                  :distanceRight="10"
                ></avatar>
                <p class="name e">
                  {{ clerk.clerk_alias || clerk.clerk_name }}
                </p>
              </div>
              <!-- 假如没有搜索结果 -->
              <p class="no-data" v-show="searchResultList.length === 0">
                暂无搜索结果，请换个关键词吧。
              </p>
            </div>
          </template>
        </div>
      </div>
      <!-- 右边 -->
      <div class="flex1 right-box">
        <!-- 显示出已选择的成员 -->
        <p class="member-title">已选择（{{ memberList.length }}）</p>
        <!-- 显示成员的地方 -->
        <div class="members-area">
          <!-- 显示出已有成员列表 -->
          <div
            class="member-item flex flex-y-center"
            v-for="(item, index) in memberList"
            :key="index"
          >
            <avatar :userInfo="item" :size="30" :distanceRight="10"></avatar>
            <p class="name e">{{ item.clerk_name }}</p>
            <!-- 准备一个删除按钮 -->
            <button
              class="remove-member-btn"
              type="button"
              @click="removeMember(item)"
            >
              <span class="iconfont icon-Delete"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">返回</el-button>
      <el-button type="primary" @click="confirmAddMember">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Avatar from '../common/Avatar.vue'
import MemberTree from '../common/MemberTree.vue'
export default {
  name: 'add-member',
  components: {
    MemberTree,
    Avatar
  },
  props: {
    // 是否展示这个弹窗
    isShowAddMember: {
      type: Boolean,
      default: false
    },
    // 树形用户数据
    dataList: {
      type: Array
    },
    // 已有成员
    memberList: {
      type: Array
    },
    // 标题
    modalTitle: {
      type: String,
      default: '选择成员'
    }
  },
  data() {
    return {
      // 搜索输入的文字
      searchName: '',
      // 搜索结果
      searchResultList: [],
      searchAntiShare: null
    }
  },
  mounted() {
    this.searchAntiShare = this.$util.antiShare(this.searchClerkList)
  },
  methods: {
    // 点击关闭按钮
    handleClose() {
      this.closeModal()
    },
    // 关闭按钮
    closeModal() {
      this.clearSearch()
      this.$emit('close-add-member')
    },
    // 准备添加成员
    toAddMember(clerkInfo) {
      this.$emit('to-add-member', clerkInfo)
    },
    // 确定要添加成员
    confirmAddMember() {
      this.$emit('confirm-add-member')
      this.closeModal()
    },
    // 准备删除某个成员
    removeMember(id) {
      this.$emit('ready-remove-member', id)
    },
    // 清空搜索
    clearSearch() {
      this.searchName = ''
      this.searchResultList = []
    },
    // 防抖搜索
    triggerSearch() {
      this.searchAntiShare()
    },
    // 搜索同事
    searchClerkList() {
      const val = this.searchName.trim()
      if (val.length > 0) {
        this.$util.searchClerkList(val).then((res) => {
          if (res) {
            console.log('搜索结果：', res)
            this.searchResultList = [...res]
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope></style>

<style lang="scss">
@import '@/assets/scss/choose_member_style.scss';
@import '@/assets/scss/add_member_style.scss';
// @import '@/assets/scss/tree_style.scss';
</style>
