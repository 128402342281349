<!--
 * @Date: 2021-06-23 17:50:09
 * @LastEditTime: 2021-11-13 16:21:31
 * @Description: 目标组组件
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div class="task-group-box" :class="{ others: !isMe }">
    <div class="flex flex-y-start flex-y-center" style="padding-left: 20px">
      <!-- 左边 -->
      <div class="tag">{{ groupIndex }}</div>
      <!-- 右边 -->
      <div class="flex1">
        <!-- 第一行 -->
        <div class="group-header">
          <div class="flex flex-y-center group-status">
            <p
              class="group-name group-name-tel"
              :style="{ cursor: isMe ? 'pointer' : 'default' }"
              v-if="!isShowName"
              @click="isShowGroupName"
            >
              {{ groupSendName }}
            </p>
            <el-input
              type="text"
              v-show="isShowName"
              class="group-name"
              ref="taskGroupRef"
              v-model="groupSendName"
              @keyup.enter.native="enterKey"
              @keyup.esc.native="isEscShow = true"
              @blur="editName(group.key)"
            ></el-input>
            <!-- <div class="group-th target-name">目标名称</div> -->
            <div class="group-th relative hidden-xs-only">目标关联</div>
            <div class="group-th target-type hidden-xs-only">目标类型</div>
            <div class="group-th last-time hidden-xs-only">截止时间</div>
            <div class="group-th progress hidden-xs-only">完成进度</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 遍历数据 -->
    <div class="task-group-data">
      <!-- 渲染组内的目标数组 -->
      <draggable
        :list="group.list"
        animation="300"
        :scroll="true"
        :move="onMove"
        @end="choose"
        chosenClass="chosen"
        group="demoDragTree"
        handle=".move-target-rank"
      >
        <transition-group>
          <target-group-item
            v-for="target in group.list"
            :key="target.objective_id"
            :target="target"
            :isMe="isMe"
            @to-fresh-target="toFreshTarget"
            @to-choose-relative="toSetRelative($event)"
            @to-target-detail="toShowDetail($event)"
            @changeLabel="$emit('changeLabel', $event)"
          ></target-group-item>
        </transition-group>
      </draggable>
    </div>
    <!-- 在这一行，新增一条目标 -->
    <div
      class="add-line flex flex-y-center task-add-box"
      v-if="Parent.myClerkId === Parent.currentClerkId"
    >
      <!-- 按钮 -->
      <button
        class="add-btn flex flex-y-center"
        v-if="!isAddTarget"
        @click="beginAddTarget(index)"
      >
        <span class="iconfont icon-add add-icon"></span>
        添加目标
      </button>
      <!-- 输入框 -->
      <input
        v-show="isAddTarget"
        type="text"
        class="task-input flex1"
        v-model="newTarget"
        placeholder="输入目标名称，按回车保存，按ESC取消"
        :ref="'addTargetInput_' + index"
        @keyup.13="toBlur($event, group.key)"
        @keyup.229="empty"
        @keyup.esc=";(isEscAdd = true), (escAddRef = 'addTargetInput_' + index)"
        @blur="handleNewTarget(group.key)"
      />
    </div>
  </div>
</template>

<script>
import TargetGroupItem from './TargetGroupItem.vue'
import draggable from 'vuedraggable'
export default {
  components: { TargetGroupItem, draggable },
  name: 'target-group-box',
  inject: ['Parent'],
  props: {
    // 组数据
    group: {
      type: Object
    },
    // 索引
    index: {
      type: Number
    },
    // 组的说明文字
    groupName: {
      type: String,
      default: '目标名称'
    },
    // 当前是否是我
    isMe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 新目标
      newTarget: '',
      // 是否要在这个组新增任务
      isAddTarget: false,
      // 目标名称
      groupSendName: '',
      // 是否展示目标组名称
      isShowName: false,
      // 更新前目标组名称
      oldGroupSendName: '',
      // 是否触发esc
      isEscShow: false,
      // 是否触发添加esc
      isEscAdd: false,
      escAddRef: '',
      currentInfo: {}
    }
  },
  computed: {
    groupIndex() {
      if (this.group.list.length > 0) {
        switch (this.group.list[0].group_type) {
          case 2:
            return '团队'
          case 3:
            return '协同'
        }
      }
      return this.index < 10 ? '0' + this.index : this.index
    }
  },
  methods: {
    onMove(e) {
      if (!this.isMe) {
        return false
      }
      console.log(e)
      this.currentInfo = e
    },
    choose(e) {
      if (!this.isMe) {
        return
      }
      if (Object.keys(this.currentInfo).length === 0) {
        return
      }
      this.insertBefore()
      this.currentInfo = {}
    },
    // 插入到某任务之前
    async insertBefore() {
      const draggedContext = this.currentInfo.draggedContext
      const relatedContext = this.currentInfo.relatedContext
      const formObj = { objective_id: draggedContext.element.objective_id }
      // 拖拽后相对于列表位置
      const positionIndex = relatedContext.list.findIndex((e) => {
        return e.objective_id === draggedContext.element.objective_id
      })
      // 判断长度是否是最后一个
      if (positionIndex === relatedContext.list.length - 1) {
        if (relatedContext.element._LEVEL_ === 1) {
          formObj.group_id = relatedContext.element.group_id
        } else {
          formObj.pid = relatedContext.element.pid
        }
      } else {
        formObj.next_objective_id =
          relatedContext.list[positionIndex + 1].objective_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.trgetMove, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
      // 刷新任务
      this.Parent.getTargetList()
    },
    // 让输入框显示并聚焦
    beginAddTarget(index) {
      this.isAddTarget = true
      this.$nextTick(() => {
        this.$refs[`addTargetInput_${index}`].focus()
      })
      this.$emit('begin-add-target', index)
    },
    // 提交新目标
    async handleNewTarget(id) {
      console.log('id', id)
      const val = this.newTarget.trim()
      if (val.length === 0) {
        this.cancelNewTarget()
        this.isEscAdd = false
        return
      }
      if (this.isEscAdd) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.cancelNewTarget()
          this.isEscAdd = false
          return
        }
      }
      const formObj = {
        'objective.objective_name': val,
        'objective.period_date': this.Parent.currentTimeStartAndEnd[0]
      }
      if (id) {
        formObj.group_id = id
      }
      console.log(formObj)
      // console.log('准备添加新任务', formObj)
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addTarget, formObj)
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            type: 'success',
            message: '新增目标成功！',
            showClose: true
          })
          this.cancelNewTarget()
          // 刷新任务列表
          this.Parent.getTargetList()
        }
      }
      this.isEscAdd = false
    },
    // 取消提交
    cancelNewTarget() {
      this.isAddTarget = false
      this.newTarget = ''
    },
    // 通知父级刷新目标列表
    toFreshTarget() {
      this.$emit('to-fresh-target')
    },
    // 展示详情
    toShowDetail(id) {
      this.$emit('to-target-detail', id)
    },
    // 设置目标关联
    toSetRelative(target) {
      this.$emit('show-relative-modal', target)
    },
    // 回车提交
    toBlur(e, args) {
      e.target.blur(args)
    },
    // 空函数
    empty() {},
    // 回车提交
    enterKey() {
      event.target.blur()
    },
    // 修改任务组
    async editName(key) {
      if (this.oldGroupSendName === this.groupSendName) {
        this.groupSendName = this.oldGroupSendName
        this.isShowName = false
        this.isEscShow = false
        return
      }
      if (this.isEscShow) {
        let flag = false
        if (this.groupSendName === '') {
          flag = true
        }
        if (!flag && this.oldGroupSendName !== this.groupSendName) {
          flag = true
        }
        if (flag) {
          try {
            await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
          } catch (error) {
            this.groupSendName = this.oldGroupSendName
            this.isShowName = false
            this.isEscShow = false
            return
          }
        }
      }
      const formObj = {
        group_id: key,
        group_name: this.groupSendName
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editObjectiveGroupName, formObj)
      )
      if (err) {
        this.$message.error(err)
      } else {
        if (res.state === 'ok') {
          this.isShowName = false
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          if (this.groupSendName === '') {
            this.groupSendName = '目标名称'
          }
          this.oldGroupSendName = this.groupSendName
        }
      }
      this.isEscShow = false
    },
    isShowGroupName() {
      if (!this.isMe) {
        return
      }
      this.isShowName = true
      this.$nextTick(() => {
        this.$refs.taskGroupRef.focus()
      })
    }
  },
  mounted() {
    this.groupSendName = this.groupName
    this.oldGroupSendName = this.groupName
  },
  watch: {
    groupName(val) {
      this.groupSendName = val
      this.oldGroupSendName = this.groupName
    },
    isEscShow(val) {
      if (val) {
        this.$refs.taskGroupRef.blur()
      }
    },
    isEscAdd(val) {
      if (val) {
        this.$refs[this.escAddRef].blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/group_box_style.scss';
.chosen {
  background-color: #f6f6f6 !important;
}
.task-group-box .group-header .group-th {
  line-height: 30px;
}

.task-group-box .group-header .group-th.last-time {
  width: 10%;
}

.task-group-box .group-header .group-th.progress {
  width: 14%;
}

.task-group-box .group-header .group-th.last-time,
.task-group-box .group-header .group-th.progress {
  margin-left: 0;
}

.group-header {
  padding-top: 15px;
}
// 设置一下头部各个字段的宽度
.task-group-box .group-header .group-th.target-name {
  width: 56%;
  box-sizing: border-box;
  text-align: left;
}

.group-th.relative {
  width: 11%;
}

.group-th.target-type {
  cursor: pointer;
  width: 10%;
}

.group-th.last-time {
  width: 11%;
}

.group-th.progress {
  position: relative;
  width: 12%;
}
.group-name {
  cursor: pointer;
  width: 54% !important;
  ::v-deep .el-input__inner {
    height: 32px;
    padding: 0 7px !important;
  }
}

@media screen and (max-width: 768px) {
  .task-group-data {
    padding-left: 20px !important;
  }
  .target-item {
    .target-item {
      margin-left: 0;
    }
  }
  .task-group-box {
    margin-top: 10px !important;
  }
  .group-name {
    width: 100% !important;
    padding-right: 20px;
  }
  .group-name.group-name-tel {
    padding-top: 5px;
  }
}
</style>
