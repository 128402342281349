<!--
 * @Author: hzh
 * @Date: 2021-09-28 09:45:57
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-27 17:56:13
-->
<template>
  <div class="structure-child-item flex flex-x-between flex-y-center">
    <div class="flex flex-y-center">
      <el-image :src="info.clerk_avatar">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <span>{{ info.clerk_alias || info.clerk_name }}</span>
    </div>
    <div class="flex action-bar" v-if="!isAdmin">
      <edit-menu
        :active="info.active"
        :menuList="menuList"
        @change="$emit('change', { info: info, index: $event })"
      ></edit-menu>
      <div class="delete-button">
        <i
          :class="[deleteDisable ? 'icon-a-addfill' : 'icon-trash1']"
          v-if="showDelete"
          @click="$emit('delete', { info, disable: deleteDisable })"
        ></i>
      </div>
    </div>
    <div class="admin-tag" v-else>
      <span>超级管理员</span>
    </div>
  </div>
</template>

<script>
import EditMenu from './EditMenu.vue'
export default {
  components: {
    EditMenu
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    menuList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 是否展示删除按钮
    showDelete: {
      type: Boolean,
      default: true
    },
    // 删除禁用
    deleteDisable: {
      type: Boolean,
      default: false
    },
    // 是否为管理员
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.structure-child-item {
  padding: 10px 0;
  .el-image {
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    margin-right: 12px;
  }

  .edit-select {
    cursor: pointer;
    padding: 4px 7px;
    margin-right: 9px;
    border-radius: 4px;
    i {
      margin-left: 13px;
    }

    &:hover {
      background-color: #ececec;
    }
  }

  .delete-button {
    padding: 4px 0 4px 20px;
    border-left: 1px solid #ddd;
    width: 20px;
    i {
      cursor: pointer;
      color: #bbb;
      &:hover {
        color: red;
      }
    }
  }
}

.admin-tag {
  width: 135px;
  padding-left: 7px;
  text-align: left;
  box-sizing: content-box;
  color: #aaaaaa;
}
</style>
