<template>
  <div style="width: 100%; height: 100%" v-loading="loading">
    <!-- 文件页面 -->
    <div class="file-content flex flex-y-positive" v-if="contentVisable">
      <div class="file-top flex flex-x-between flex-y-center">
        <div class="base-path flex flex-y-center pc">
          <template v-for="(item, index) in breadcrumbList">
            <p :key="`break_${index}`" @click.stop="toFolder(item.folder_id)">
              {{ item != null ? item.folder_name : '' }}
            </p>
            <i
              v-if="index < breadcrumbList.length - 1"
              :key="`icon_${index}`"
              class="iconfont icon-a-arrowaltdown"
            ></i>
          </template>
        </div>
        <el-breadcrumb separator="/" class="wap separator">
          <template v-for="(item, index) in breadcrumbList">
            <p :key="`break_${index}`" @click.stop="toFolder(item.folder_id)">
              {{ item != null ? item.folder_name : '' }}
            </p>
            <i
              v-if="index < breadcrumbList.length - 1"
              :key="`icon_${index}`"
              class="iconfont icon-a-arrowaltdown"
            ></i>
          </template>
        </el-breadcrumb>
        <div class="funtion-box flex">
          <!-- 复制 -->
          <div class="copy-box flex flex-y-center">
            <el-tooltip
              class="item"
              effect="dark"
              content="复制链接"
              placement="bottom"
            >
              <p class="id-text" @click="copyLink">#{{ fileId }}</p>
            </el-tooltip>
            <p class="tip-text" v-if="isShowCopyLinkTip">链接已复制</p>
          </div>
          <!-- 编辑 -->
          <el-tooltip
            class="item"
            effect="dark"
            content="编辑"
            placement="bottom"
          >
            <i
              class="iconfont icon-a-EditSquare"
              @click="showEdit"
              v-if="perInfo.editFile"
            ></i>
          </el-tooltip>
          <!-- 收藏 -->
          <el-tooltip
            class="item"
            effect="dark"
            content="收藏"
            placement="bottom"
          >
            <i
              class="iconfont"
              :class="[info.is_collect == 1 ? 'icon-yishoucang' : 'icon-Star1']"
              @click="collect"
            ></i>
          </el-tooltip>
          <!-- 分享至圈子 -->
          <el-tooltip
            class="item"
            effect="dark"
            content="分享至圈子"
            placement="bottom"
            v-if="folderType == 1 && perInfo.shareFile"
          >
            <i
              class="iconfont icon-a-sharearrow"
              @click="shareVisible = true"
            ></i>
          </el-tooltip>
          <i
            class="iconfont icon-gengduo3"
            @click.stop="isShowMore = true"
            v-if="perInfo.moveFile || perInfo.deleteFile"
          ></i>
          <!-- 更多弹窗 -->
          <ul
            class="gengduo-pop flex flex-y-positive flex-x-center"
            v-if="isShowMore"
          >
            <li
              class="flex flex-y-center flex-x-center"
              v-if="perInfo.moveFile"
            >
              <div
                class="li-node flex flex-y-center flex-x-between"
                @click.stop="
                  outClick()
                  moveVisible = true
                "
              >
                <i class="iconfont icon-Logout"></i>
                <span>移动</span>
              </div>
            </li>
            <li
              class="flex flex-y-center flex-x-center"
              v-if="perInfo.deleteFile"
            >
              <div
                class="li-node flex flex-y-center flex-x-between"
                @click.stop="
                  outClick()
                  deleteFileVisible = true
                "
              >
                <i class="iconfont icon-Delete"></i>
                <span>删除</span>
              </div>
            </li>
            <!-- <li class="flex flex-y-center flex-x-center">
            <div
              class="li-node flex flex-y-center flex-x-between"
              @click.stop="showDeleteFolder"
            >
              <i class="iconfont icon-Setting2"></i>
              <span>权限</span>
            </div>
          </li> -->
          </ul>
        </div>
      </div>
      <!-- 知识库右测文件操作 -->
      <div
        class="file-operator flex flex-y-positive pc"
        v-if="info.content_type === 2"
      >
        <div class="download-dom flex flex-y-center flex-x-between"  v-if="hasDodownload">
          <a
            :href="attachmentInfo.attachment_path"
            :download="attachmentInfo.file_original_name"
          >
            <i class="iconfont icon-Download1"></i>
            <span>下载文件</span>
          </a>
        </div>
        <ul class="flex flex-y-positive flex-x-start">
          <li
            class="flex flex-y-center"
            @click="onlineExamineFile"
            v-if="isAllowPreview"
          >
            <i class="iconfont icon-Show"></i>
            <span>在线预览</span>
          </li>
          <li
            class="flex flex-y-center"
            @click="$refs.uploadFileInput.click()"
            v-if="perInfo.editFile"
          >
            <i class="iconfont icon-Upload"></i>
            <span>上传新版本</span>
          </li>
        </ul>
      </div>
      <div
        class="file-operator flex-y-positive wap"
        v-if="info.content_type === 2"
      >
        <ul class="flex flex-y-positive flex-x-start">
          <table>
            <tr>
              <td>
                <li
                  class="flex-y-center flex1"
                  @click.stop="
                    outClick()
                    pinglunVisible = true
                  "
                >
                  <i class="el-icon-chat-dot-round"></i>
                  <span>评论</span>
                </li>
              </td>
              <td>
                <li class="flex-y-center">
                  <a
                    :href="attachmentInfo.attachment_path"
                    :download="attachmentInfo.file_original_name"
                  >
                    <i class="iconfont icon-Download1"></i>
                    <span>下载</span>
                  </a>
                </li>
              </td>
            </tr>
          </table>
          <!-- <li class="flex-y-center">
            <i class="iconfont icon-Show"></i>
            <span>在线预览</span>
          </li> -->
        </ul>
      </div>

      <div class="file-show-scroll wap">
        <div class="file-show-content">
          <!-- 目录 -->
          <template v-if="info.content_type === 1">
            <div class="catalogue-box pc">
              <div class="catalogue">
                <div class="title">目录</div>
                <div class="catelog-content">
                  <outline-tree :treeData="treeData" class="tree" ref="tree">
                    <template v-slot:default="{ data }">
                      <div
                        class="node-render-content"
                        @click.stop="scrollIntoView(data.el)"
                      >
                        <span>{{ data.title }}</span>
                      </div>
                    </template>
                  </outline-tree>
                </div>
              </div>
            </div>
          </template>
          <!-- 文档类型 -->
          <template v-if="info.content_type === 1">
            <div class="document flex flex-y-positive">
              <div class="document-top">
                <div class="document-title">{{ info.knowl_name || '' }}</div>
                <div class="document-usertime flex">
                  <span>{{
                    info.update_clerk_alias ||
                    info.update_clerk_name ||
                    info.create_clerk_alias ||
                    info.create_clerk_name
                  }}</span>
                  <p>
                    {{
                      info.update_time || info.create_time
                        ? $util.getTimeStr(info.update_time || info.create_time)
                        : ''
                    }}
                  </p>
                </div>
              </div>
              <div
                class="document-content htmledit_views"
                v-outline="{
                  callback: refreshNavTree,
                  selectors: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                  exceptSelector: '[un-nav]'
                }"
                @click="imageChgange($event)"
                v-html="handlerContentToUrl(info.text_info.knowl_content_html)"
              ></div>
            </div>
          </template>
          <!-- 文件类型 -->
          <template v-if="info.content_type === 2">
            <div
              class="media flex flex-y-positive"
              v-if="isPic || isVideo || isAudio"
            >
              <div class="media-content">
                <el-image
                  v-if="isPic"
                  :src="info.attachment_info.attachment_path"
                  :preview-src-list="[info.attachment_info.attachment_path]"
                  style="width: 100%; height: 100%"
                >
                </el-image>
                <video
                  v-if="isVideo"
                  :src="info.attachment_info.attachment_path"
                  controls
                  style="width: 100%; height: 100%"
                ></video>
                <audio
                  v-if="isAudio"
                  controls
                  :src="info.attachment_info.attachment_path"
                  style="width: 100%"
                ></audio>
              </div>
              <div class="media-title">
                <span>{{ info.knowl_name || '' }}</span>
                <p class="flex">
                  {{
                    info.update_clerk_alias ||
                    info.update_clerk_name ||
                    info.create_clerk_alias ||
                    info.create_clerk_name
                  }}
                  {{
                    info.update_time || info.create_time
                      ? $util.getTimeStr(info.update_time || info.create_time)
                      : ''
                  }}
                  上传
                  {{
                    attachmentInfo.file_size === 0
                      ? '0 KB'
                      : attachmentInfo.file_size_readable
                  }}
                </p>
              </div>
            </div>

            <div class="file-title flex" v-else>
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="fileIcon"></use>
              </svg>
              <div class="file-title-text flex flex-y-positive flex-x-between">
                <!-- 文件名 -->
                <p>{{ info.knowl_name || '' }}</p>
                <!-- 文件上传作者及时间 -->
                <p>
                  {{
                    info.update_clerk_alias ||
                    info.update_clerk_name ||
                    info.create_clerk_alias ||
                    info.create_clerk_name
                  }}
                  {{
                    info.update_time || info.create_time
                      ? $util.getTimeStr(info.update_time || info.create_time)
                      : ''
                  }}
                  上传
                  {{
                    attachmentInfo.file_size === 0
                      ? '0 KB'
                      : attachmentInfo.file_size_readable
                  }}
                </p>
              </div>
            </div>
          </template>
          <div class="line"></div>
          <!-- 文件上传状态 -->
          <knowledge-log-item
            v-for="(loItem, loIndex) in changeLogList"
            :changeInfo="loItem"
            :changeKey="loIndex + 1"
            :key="'change_' + loIndex"
          >
          </knowledge-log-item>
          <span
            class="look-more"
            @click="getChangeLogList()"
            v-if="changeTotalpage > changePageNumber"
          >
            查看更多>>
          </span>
          <!-- 文件评论内容 -->
          <div
            class="file-reply flex flex-y-positive"
            v-for="coItem in commentList"
            :key="'comment_' + coItem.comment_id"
          >
            <div class="file-reply-top flex flex-y-center">
              <!-- 评论用户头像 -->
              <div class="user-img">
                <img :src="coItem.clerk_avatar" alt="" />
              </div>
              <!-- 评论用户名 -->
              <div class="user-name">
                {{ coItem.clerk_alias || coItem.clerk_name }}
              </div>
              <!-- 评论时间 -->
              <div class="reply-time">
                {{
                  coItem.create_time ? $util.getTimeStr(coItem.create_time) : ''
                }}
              </div>
            </div>
            <!-- 评论内容 -->
            <div
              class="file-reply-content"
              v-html="
                (coItem.comment_content || '').replace(
                  /\[[^\[\]]*\]/gi,
                  emotion
                )
              "
            ></div>
          </div>
          <span
            class="look-more"
            @click="getCommentList()"
            v-if="commentTotalpage > commentPageNumber"
          >
            查看更多>>
          </span>
          <!-- 文件评论 -->
          <div class="file-comment pc">
            <!-- 评论输入框 -->
            <el-input
              type="textarea"
              placeholder="输入评论内容"
              v-model="textarea"
              ref="contentRef"
            >
            </el-input>
            <div
              class="flex flex-y-center flex-x-between pc"
              style="width: 580px"
            >
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>

            <div class="flex flex-y-center flex-x-between wap">
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>
          </div>
          <div
            v-if="pinglunVisible == true"
            class="file-comment wap"
            @click.stop=""
          >
            <!-- 评论输入框 wap -->
            <el-input
              type="textarea"
              placeholder="输入评论内容"
              v-model="textarea"
              ref="contentRef"
            >
            </el-input>
            <div
              class="flex flex-y-center flex-x-between pc"
              style="width: 580px"
            >
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>

            <div class="flex flex-y-center flex-x-between wap">
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="file-show-scroll pc"
        :style="{ paddingRight: info.content_type === 2 ? '300px' : '' }"
      >
        <div
          class="file-show-content"
          :style="{ paddingRight: info.content_type === 1 ? '360px' : '' }"
        >
          <!-- 目录 -->
          <template v-if="info.content_type === 1">
            <div class="catalogue-box pc">
              <div class="catalogue">
                <div class="title">目录</div>
                <div class="catelog-content">
                  <outline-tree :treeData="treeData" class="tree" ref="tree">
                    <template v-slot:default="{ data }">
                      <div
                        class="node-render-content"
                        @click.stop="scrollIntoView(data.el)"
                      >
                        <span>{{ data.title }}</span>
                      </div>
                    </template>
                  </outline-tree>
                </div>
              </div>
            </div>
          </template>
          <!-- 文档类型 -->
          <template v-if="info.content_type === 1">
            <div class="document flex flex-y-positive">
              <div class="document-top">
                <div class="document-title">{{ info.knowl_name || '' }}</div>
                <div class="document-usertime flex">
                  <span>{{
                    info.update_clerk_alias ||
                    info.update_clerk_name ||
                    info.create_clerk_alias ||
                    info.create_clerk_name
                  }}</span>
                  <p>
                    {{
                      info.update_time || info.create_time
                        ? $util.getTimeStr(info.update_time || info.create_time)
                        : ''
                    }}
                  </p>
                </div>
              </div>
              <div
                class="document-content htmledit_views"
                v-outline="{
                  callback: refreshNavTree,
                  selectors: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                  exceptSelector: '[un-nav]'
                }"
                @click="imageChgange($event)"
                v-html="handlerContentToUrl(info.text_info.knowl_content_html)"
              ></div>
            </div>
          </template>
          <!-- 文件类型 -->
          <template v-if="info.content_type === 2">
            <div
              class="media flex flex-y-positive"
              v-if="isPic || isVideo || isAudio"
            >
              <div class="media-content">
                <el-image
                  v-if="isPic"
                  :src="info.attachment_info.attachment_path"
                  :preview-src-list="[info.attachment_info.attachment_path]"
                  style="width: 100%; height: 100%"
                >
                </el-image>
                <video
                  v-if="isVideo"
                  :src="info.attachment_info.attachment_path"
                  controls
                  style="width: 100%; height: 100%"
                ></video>
                <audio
                  v-if="isAudio"
                  controls
                  :src="info.attachment_info.attachment_path"
                  style="width: 100%"
                ></audio>
              </div>
              <div class="media-title">
                <span>{{ info.knowl_name || '' }}</span>
                <p class="flex">
                  {{
                    info.update_clerk_alias ||
                    info.update_clerk_name ||
                    info.create_clerk_alias ||
                    info.create_clerk_name
                  }}
                  {{
                    info.update_time || info.create_time
                      ? $util.getTimeStr(info.update_time || info.create_time)
                      : ''
                  }}
                  上传
                  {{
                    attachmentInfo.file_size === 0
                      ? '0 KB'
                      : attachmentInfo.file_size_readable
                  }}
                </p>
              </div>
            </div>

            <div class="file-title flex" v-else>
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="fileIcon"></use>
              </svg>
              <div class="file-title-text flex flex-y-positive flex-x-between">
                <!-- 文件名 -->
                <p>{{ info.knowl_name || '' }}</p>
                <!-- 文件上传作者及时间 -->
                <p>
                  {{
                    info.update_clerk_alias ||
                    info.update_clerk_name ||
                    info.create_clerk_alias ||
                    info.create_clerk_name
                  }}
                  {{
                    info.update_time || info.create_time
                      ? $util.getTimeStr(info.update_time || info.create_time)
                      : ''
                  }}
                  上传
                  {{
                    attachmentInfo.file_size === 0
                      ? '0 KB'
                      : attachmentInfo.file_size_readable
                  }}
                </p>
              </div>
            </div>
          </template>
          <div class="line"></div>
          <!-- 文件上传状态 -->
          <knowledge-log-item
            v-for="(loItem, loIndex) in changeLogList"
            :changeInfo="loItem"
            :changeKey="loIndex + 1"
            :key="'change_' + loIndex"
          >
          </knowledge-log-item>
          <span
            class="look-more"
            @click="getChangeLogList()"
            v-if="changeTotalpage > changePageNumber"
          >
            查看更多>>
          </span>
          <!-- 文件评论内容 -->
          <div
            class="file-reply flex flex-y-positive"
            v-for="coItem in commentList"
            :key="'comment_' + coItem.comment_id"
          >
            <div class="file-reply-top flex flex-y-center">
              <!-- 评论用户头像 -->
              <div class="user-img">
                <img :src="coItem.clerk_avatar" alt="" />
              </div>
              <!-- 评论用户名 -->
              <div class="user-name">
                {{ coItem.clerk_alias || coItem.clerk_name }}
              </div>
              <!-- 评论时间 -->
              <div class="reply-time">
                {{
                  coItem.create_time ? $util.getTimeStr(coItem.create_time) : ''
                }}
              </div>
            </div>
            <!-- 评论内容 -->
            <div
              class="file-reply-content"
              v-html="
                (coItem.comment_content || '').replace(
                  /\[[^\[\]]*\]/gi,
                  emotion
                )
              "
            ></div>
          </div>
          <span
            class="look-more"
            @click="getCommentList()"
            v-if="commentTotalpage > commentPageNumber"
          >
            查看更多>>
          </span>
          <!-- 文件评论 -->
          <div class="file-comment pc">
            <!-- 评论输入框 -->
            <el-input
              type="textarea"
              placeholder="输入评论内容"
              v-model="textarea"
              ref="contentRef"
            >
            </el-input>
            <div
              class="flex flex-y-center flex-x-between pc"
              style="width: 580px"
            >
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>

            <div class="flex flex-y-center flex-x-between wap">
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>
          </div>
          <div
            v-if="pinglunVisible == true"
            class="file-comment wap"
            @click.stop=""
          >
            <!-- 评论输入框 wap -->
            <el-input
              type="textarea"
              placeholder="输入评论内容"
              v-model="textarea"
              ref="contentRef"
            >
            </el-input>
            <div
              class="flex flex-y-center flex-x-between pc"
              style="width: 580px"
            >
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>

            <div class="flex flex-y-center flex-x-between wap">
              <expression-box
                :isShow="isShowExpressBox"
                :expressList="$emoji.slice(0, 100)"
                :left="'0'"
                @add-express="addContentExpress($event)"
              >
                <div
                  class="icon-a-1 icon-button"
                  @click.stop="toggleExpressBox"
                ></div>
              </expression-box>
              <!-- 按钮 -->
              <div class="button-box flex">
                <button id="send" @click="addComment">发表评论</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-image
        ref="previewImageRef"
        :preview-src-list="[previewImageSrc]"
        v-show="false"
      ></el-image>
      <!-- 上传文件用的input -->
      <input
        ref="uploadFileInput"
        type="file"
        v-show="false"
        @change="editAttachment"
      />
      <!-- 分享文件弹窗 -->
      <Knowledge-edit-name-pop
        @cancle="editNameVisible = false"
        @confirm="editName"
        :value="info.knowl_name"
        v-if="editNameVisible"
      ></Knowledge-edit-name-pop>
      <!-- 分享文件弹窗 -->
      <Knowledge-share-pop
        @cancle="shareVisible = false"
        @confirm="shareFile"
        :info="info"
        v-if="shareVisible"
      ></Knowledge-share-pop>
      <!-- 知识库文件移动弹窗 -->
      <knowledge-move
        @cancle="moveVisible = false"
        @confirm="moveFile"
        :fileIdList="[fileId]"
        v-if="moveVisible"
      ></knowledge-move>
      <!-- 删除文件弹窗 -->
      <knowledge-delete-pop
        msg="确定要删除文件么？"
        @cancle="deleteFileVisible = false"
        @confirm="deleteFile"
        v-if="deleteFileVisible"
      ></knowledge-delete-pop>
    </div>
    <!-- 编辑文本 -->
    <online-document
      v-if="editDocumentVisable"
      @close="
        editDocumentVisable = false
        contentVisable = true
      "
      v-loading.fullscreen.lock="loadding"
      element-loading-background="rgba(242, 246, 252, 0.3)"
      :title="info.knowl_name || ''"
      :describe="info.text_info.knowl_content_html || ''"
      @confirm="editDocument"
    ></online-document>
  </div>
</template>

<script>
// 导入文件移动弹窗组件
import KnowledgeMove from '@/components/knowledge/KnowlegeMove.vue'
// 导入删除弹窗组件
import KnowledgeDeletePop from '@/components/knowledge/KnowledgeDeletePop.vue'
// 导入分享知识库弹窗组件
import KnowledgeSharePop from '@/components/knowledge/KnowledgeSharePop.vue'
// 导入修改知识库名称弹窗组件
import KnowledgeEditNamePop from '@/components/knowledge/KnowledgeEditNamePop.vue'
// 导入表情组件
import ExpressionBox, { emojisMap } from '@/components/common/ExpressionBox'
import { mapState } from 'vuex'
// 导入修改记录组件
import KnowledgeLogItem from '@/components/knowledge/KnowledgeLogItem.vue'
// 导入在线文档编辑组件
import OnlineDocument from '@/components/knowledge/OnlineDocument.vue'
export default {
  name: 'knowledge-file-content',
  components: {
    KnowledgeMove,
    KnowledgeDeletePop,
    KnowledgeSharePop,
    KnowledgeEditNamePop,
    ExpressionBox,
    KnowledgeLogItem,
    OnlineDocument
  },
  props: {
    // 文件id
    fileId: {
      type: Number,
      default: -1
    },
    // 收藏模式
    collectMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 评论内容
      textarea: '',
      // 评论显示
      pinglunVisible: false,
      // 分享弹窗显示
      shareVisible: false,
      // 删除弹窗显示
      deleteFileVisible: false,
      // 移动弹窗展示
      moveVisible: false,
      // 修改名称弹窗展示
      editNameVisible: false,
      // 拷贝链接提示展示
      isShowCopyLinkTip: false,
      // 文件夹链列表
      folderChainList: [],
      // 信息
      info: {},
      // 是否显示更多弹窗
      isShowMore: false,
      // 展示表情输入
      isShowExpressBox: false,
      // 修改记录信息
      changePageNumber: 1,
      changePageSize: 5,
      // 记录列表
      changeLogList: [],
      // 记录信息总页数
      changeTotalpage: 1,
      // 评论信息
      commentPageNumber: 1,
      commentPageSize: 5,
      // 评论列表
      commentList: [],
      // 评论信息总页数
      commentTotalpage: 1,
      // 内容展示
      contentVisable: true,
      // 修改文档
      editDocumentVisable: false,
      // 目录数据
      treeData: [],
      // 加载
      loading: false,
      // 团队允许修改
      isTeamAllowEdit: false,
      // 等待lodding
      loadding: false,
      // 图片预览
      previewImageSrc: '',
      // 下载权限
      hasDodownload: false
    }
  },
  computed: {
    isAllowPreview() {
      return ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'pptx'].includes(
        this.info.attachment_info.file_ext
      )
    },
    ...mapState(['myClerkId', 'clickBodyIndex']),
    // 文件夹类型
    folderType() {
      return this.info.folder_type || 0
    },
    // 附件信息
    attachmentInfo() {
      return this.info.attachment_info || {}
    },
    // 文件夹链的翻转列表
    reverseFolderChainList() {
      return [...this.folderChainList].reverse()
    },
    // 面包屑列表
    breadcrumbList() {
      // if (this.info.folder_id == null) {
      //   return []
      // }
      let top = null
      // 收藏模式
      if (this.collectMode) {
        top = { folder_id: 0, folder_name: '收藏知识库' }
        if (this.folderChainList.length === 0) {
          return [top]
        } else {
          if (this.reverseFolderChainList.length < 3) {
            return [top, ...this.reverseFolderChainList].slice(-3)
          } else {
            return this.reverseFolderChainList.slice(-3)
          }
        }
      } else {
        if (this.folderType === 1) {
          top = { folder_id: 1, folder_name: '公司知识库' }
        }
        if (this.folderType === 2) {
          top = { folder_id: 2, folder_name: '团队知识库' }
        }
        if (this.folderChainList.length === 0) {
          return [top]
        } else {
          return this.reverseFolderChainList.slice(-3)
        }
      }
    },
    // 判断是否是图片
    isPic() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      return imgTypes.includes(type)
    },
    // 判断是否为图片
    isVideo() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const videoTypes = ['mp4']
      return videoTypes.includes(type)
    },
    // 是否为音频
    isAudio() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const audioTypes = ['mp3']
      return audioTypes.includes(type)
    },
    // 获取文件的图标
    fileIcon() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      const type = file.file_ext
      switch (type) {
        // 如果是doc文件
        case 'doc':
        case 'docx':
          return '#icon-Frame3'
        // 如果是PDF
        case 'pdf':
          return '#icon-Frame4'
        // 如果是Excel
        case 'xls':
        case 'xlsx':
          return '#icon-Frame2'
        // 如果是图片，返回图片本身
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'png':
        case 'gif':
          return file.attachment_path
        // 如果是PPT
        case 'ppt':
        case 'pptx':
          return '#icon-Frame5'
        // 如果是TXT
        case 'txt':
          return '#icon-Frame12'
        // 如果是其他
        default:
          return '#icon-Frame21'
      }
    },
    // 权限信息
    perInfo() {
      // 修改文件
      let editFile = false
      // 删除文件
      let deleteFile = false
      // 分享文件
      let shareFile = false
      // 移动文件
      let moveFile = false

      switch (this.folderType) {
        case 1: {
          shareFile = true
          if (!editFile && this.$hasPer(7)) editFile = true
          if (!deleteFile && this.$hasPer(8)) deleteFile = true
          if (!moveFile && this.$hasPer(29)) moveFile = true
          break
        }
        case 2: {
          // 是否允许修改
          if (this.isTeamAllowEdit) {
            editFile = true
            deleteFile = true
          }
          if (!editFile && this.$hasPer(24)) editFile = true
          if (!deleteFile && this.$hasPer(25)) deleteFile = true
          if (!moveFile && this.$hasPer(30)) moveFile = true
          break
        }
      }

      shareFile = true
      return {
        editFile,
        deleteFile,
        shareFile,
        moveFile
      }
    }
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink = Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`)
        : url
    },
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
    },
    // 在线查看文件
    onlineExamineFile() {
      window.open(
        this.$router.resolve({
          name: 'Preview',
          params: {
            id: this.fileId
          }
        }).href,
        '_blank'
      )
    },
    imageChgange(e) {
      if (e.target.nodeName === 'IMG') {
        this.previewImageSrc = e.target.src
        this.$refs.previewImageRef.showViewer = true
        console.log(e.target.src)
      }
    },
    // 目录树形结构更新
    refreshNavTree(treeData) {
      this.treeData = treeData
    },
    scrollIntoView(el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    },
    // 初始化
    async init() {
      this.loading = true
      this.outClick()
      // 收藏模式
      await this.getDetail()
      await this.refreshChangeLogList()
      await this.refreshCommentList()
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    // 获取详情
    async getDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlDetail, {
          knowl_id: this.fileId,
          collect: this.collectMode ? 1 : 0
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this, 'info', res.info)
        this.folderChainList = res.folder_chain_list
        this.isTeamAllowEdit = res.team_allow_edit || false
        this.hasDodownload = res.downloadHasPer
      }
    },
    // 获取修改记录
    async getChangeLogList(rescet = false) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlChangeLogPage, {
          knowl_id: this.fileId,
          pageNumber: rescet ? this.changePageNumber : ++this.changePageNumber,
          pageSize: this.changePageSize
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.changeTotalpage = res.page.totalPage
        if (rescet) {
          this.changeLogList = res.page.list
        } else {
          this.changeLogList = this.changeLogList.concat(res.page.list)
        }
      }
    },
    // 刷新修改记录列表
    refreshChangeLogList() {
      this.changePageNumber = 1
      this.getChangeLogList(true)
    },
    // 获取评论列表
    async getCommentList(rescet = false) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlCommentPage, {
          knowl_id: this.fileId,
          pageNumber: rescet
            ? this.commentPageNumber
            : ++this.commentPageNumber,
          pageSize: this.commentPageSize
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.commentTotalpage = res.page.totalPage
        if (rescet) {
          this.commentList = res.page.list
        } else {
          this.commentList = this.commentList.concat(res.page.list)
        }

        console.log(res)
      }
    },
    // 刷新评论列表
    refreshCommentList() {
      this.commentPageNumber = 1
      this.textarea = ''
      this.getCommentList(true)
    },
    // 添加评论
    async addComment() {
      if (this.textarea === '') {
        this.$message.error('请输入评论内容')
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addKnowComment, {
          knowl_id: this.fileId,
          comment_content: this.textarea
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.$message({
        //   showClose: true,
        //   message: '评论成功！',
        //   type: 'success'
        // })
        this.refreshCommentList()
      }
    },
    // 复制链接
    copyLink() {
      this.$copyText(
        `${this.$config.frontHome}knowledge/file/${this.fileId}`
      ).then(
        () => {
          // this.$message({
          //   showClose: true,
          //   message: '已成功复制，可直接去粘贴',
          //   type: 'success'
          // })
          this.isShowCopyLinkTip = true
          // 2秒后提示隐藏
          setTimeout(() => {
            this.isShowCopyLinkTip = false
          }, 2000)
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    //
    outClick() {
      this.moveVisible = false
      this.deleteFileVisible = false
      this.isShowMore = false
    },
    // 跳转文件夹
    toFolder(id) {
      this.$emit('toFolder', {
        folder_id: id,
        collect_mode: this.collectMode
      })
    },
    // 展示修改
    showEdit() {
      switch (this.info.content_type) {
        // 文本
        case 1: {
          this.editDocumentVisable = true
          this.contentVisable = false
          break
        }
        // 文件
        case 2: {
          this.editNameVisible = true
          break
        }
      }
    },
    // 修改名称
    async editName(value) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlName, {
          knowl_id: this.fileId,
          knowl_name: value
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.editNameVisible = false
        this.$set(this.info, 'knowl_name', value)
        this.refreshChangeLogList()
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
    },
    // 收藏
    async collect() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.collectKnowl, {
          knowl_id: this.fileId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.info, 'is_collect', res.is_collect ? 1 : 0)
        this.$message({
          showClose: true,
          message: res.is_collect ? '收藏成功' : '已取消收藏',
          type: 'success'
        })
      }
    },
    // 在内容里添加一个表情
    addContentExpress(obj) {
      this.textarea = this.textarea + obj.name
      // 关闭白框
      this.isShowExpressBox = false
      // 让输入框重新聚焦
      this.$refs.contentRef.focus()
    },
    // 显示隐藏表情框
    toggleExpressBox() {
      this.isShowExpressBox = !this.isShowExpressBox
    },
    // 删除文件
    async deleteFile() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.batchDeleteKnowl, {
          knowl_ids: this.fileId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.outClick()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        // 跳上级文件夹
        this.toFolder(
          this.breadcrumbList[this.breadcrumbList.length - 1].folder_id
        )
      }
    },
    // 移动文件
    async moveFile(id) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.batchMoveKnowl, {
          from_knowl_ids: this.fileId,
          to_folder_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.outClick()
        this.$message({
          showClose: true,
          message: '移动成功',
          type: 'success'
        })
        // 跳上级文件夹
        this.toFolder(
          this.breadcrumbList[this.breadcrumbList.length - 1].folder_id
        )
      }
    },
    // 更新文件
    async editAttachment(e) {
      const formdata = new FormData()
      formdata.set('file', e.target.files[0])
      formdata.set('knowl_id', this.fileId)
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.editKnowlAttachment, formdata).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        return true
      }
      this.$refs.uploadFileInput.value = ''
    },
    // 修改文本类型
    async editDocument({
      title,
      content_html: contentHtml,
      content_text: contentText
    }) {
      this.loading = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlContent, {
          knowl_id: this.fileId,
          knowl_name: title,
          knowl_content_html: contentHtml,
          knowl_content_text: contentText
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.loading = false
        this.editDocumentVisable = false
        this.contentVisable = true
        this.getDetail()
        this.refreshChangeLogList()
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
      }
    },
    // 分享文件夹
    async shareFile({ content }) {
      const formObj = new FormData()
      // formObj.append('moment_title', this.momentInfo.moment_title)
      formObj.append('moment_content', content)
      formObj.append('content_type', 3)
      formObj.append('knowl_id', this.fileId)
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.addMoment, formObj).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '分享圈子成功！',
          type: 'success'
        })
        this.shareVisible = false
      }
    }
  },
  mounted() {
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
    document.addEventListener('click', this.outClick)
    if (this.fileId !== -1) {
      this.init()
    }
  },
  watch: {
    clickBodyIndex(val) {
      this.pinglunVisible = false
    },
    fileId(val) {
      if (val !== -1) {
        this.init()
      }
    }
  },
  selectorString: {
    immediate: true,
    handler() {
      this.refreshContent()
      const list = this.selectorString.split(';').filter((i) => i)
      console.log('list', list)
      this.selectors.splice(0, this.selectors.length, ...list)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-reply-content::v-deep .emoji-img {
  position: relative;
  top: -6px;
}
@media screen and (min-width: 960px) {
  .wap {
    display: none !important;
  }
  .show {
    display: none;
  }
  .catelog-content ::v-deep .node-render-content {
    overflow-x: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    cursor: pointer;
  }
  .file-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // padding: 60px 0 0 300px;

    padding: 70px 0 0 0;
    .file-show-scroll {
      width: 100%;
      height: 100%;
      padding: 60px 50px 20px 50px;
      overflow-y: scroll;
      box-sizing: border-box;
    }
    .file-show-content {
      width: 630px;
      margin: 0 auto;

      // border: 1px solid red;
      position: relative;
      .look-more {
        cursor: pointer;
        font-size: 14px;
        color: $main-color;
        line-height: 28px;
        margin-top: 10px;
        display: inline-block;
      }
      .catalogue-box {
        position: absolute;
        right: 180px;
        .catalogue {
          width: 180px;
          margin-top: 63px;
          position: fixed;
          // right: 66px;
          top: 130px;
          .title {
            width: 180px;
            border-bottom: 1px solid #eeeeee;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            box-sizing: border-box;
            padding-bottom: 10px;
          }
          .catelog-content {
            width: 180px;
            height: 600px;
            margin-top: 12px;
            font-size: 14px;
            line-height: 21px;
            overflow: scroll;
            color: #333333;
            .node-render-content {
              line-height: 24px;
            }
          }
        }
      }
    }

    .file-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 70px;
      padding: 0 50px 0 48px;
      box-sizing: border-box;
      background-color: #fff;
      border-bottom: 1px solid #eeeeee;
      z-index: 1;

      .base-path {
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 30px;
          color: #999999;
          cursor: pointer;
        }
        .iconfont {
          margin: 0 6px 0 6px;
          font-size: 22px;
          color: #999999;
        }
      }

      .funtion-box {
        position: relative;
        .copy-box {
          position: relative;
          // 提示文字
          .tip-text {
            right: 0;
            position: absolute;
            font-size: 14px;
            line-height: 1.5;
            white-space: nowrap;
            background: #fff;
            color: $main-color;
          }
          .id-text {
            margin-right: 12px;
            font-size: 14px;
            line-height: 1.5;
            cursor: pointer;
            color: #bbb;
          }
        }

        .iconfont {
          font-size: 28px;
          color: #999999;
          margin-left: 16px;
          cursor: pointer;

          &.icon-yishoucang {
            font-size: 28px;
            color: #ffab2e;
            background: #ffffff;
          }
        }
        // 更多弹窗
        .gengduo-pop {
          z-index: 100;
          position: absolute;
          top: 30px;
          right: -6px;
          width: 100px;
          // height: 120px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          li {
            width: 100%;
            height: 33px;
            .li-node {
              cursor: pointer;
              width: 76px;
              height: 33px;
              box-sizing: border-box;
              padding: 0 10px 0 10px;
              .iconfont {
                font-size: 16px;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
              &:hover {
                background: rgba(0, 128, 255, 0.1);
                border-radius: 4px;
                .iconfont {
                  color: #0080ff;
                }
                span {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }

    .file-title {
      height: 90px;
      margin-bottom: 50px;
      .icon {
        font-size: 90px;
      }
      .file-title-text {
        padding: 13px 0 13px 0;
        box-sizing: border-box;
        margin-left: 26px;
        span {
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
          color: #333333;
        }
        p {
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
      }
    }
    // 文本样式
    .document {
      .document-top {
        .document-title {
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          color: #333333;
        }
        .document-usertime {
          font-size: 16px;
          line-height: 24px;
          color: #999999;
          p {
            margin-left: 18px;
          }
        }
      }

      .document-content ::v-deep .ql-syntax {
        white-space: break-spaces;
      }
      .htmledit_views ::v-deep {
        p {
          line-height: 26px !important;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          padding-top: 5px !important;
          margin: 0 !important;
        }
      }
    }
    // 媒体头部样式
    .media {
      .media-content {
        width: 630px;
        // height: 400px;
        // background: #bbb;
        border-radius: 2px;
        overflow: hidden;
      }
      .media-title {
        margin: 40px 0 20px 0;
        span {
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
          color: #333333;
        }
        p {
          font-size: 14px;
          color: #999999;
        }
      }
    }

    .line {
      width: 100%;
      // width: 770px;
      height: 1px;
      background: #eeeeee;
      margin-bottom: 32px;
      padding: 0;
    }
    .file-state-box {
      .file-state {
        margin-top: 20px;
        .num {
          width: 26px;
          height: 26px;
          background: #ff9854;
          border-radius: 50%;
          font-weight: 500;
          font-size: 12px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
        }
        .upload-date {
          margin-left: 16px;
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
        .upload-author {
          margin-left: 12px;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
        .upload-name {
          margin-left: 12px;
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
      }
    }
    .file-reply {
      margin-top: 20px;
      .file-reply-top {
        height: 36px;
        .user-img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .user-name {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #333333;
          margin-left: 18px;
        }
        .reply-time {
          font-size: 14px;
          line-height: 21px;
          color: #999999;
          margin-left: 10px;
        }
      }
      .file-reply-content {
        margin: 12px 0 0 54px;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
    }
    .file-comment {
      margin-top: 34px;
      .button-box {
        margin-top: 24px;
        height: 34px;
        #send {
          width: 84px;
          height: 34px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
    // 右侧文件操作
    .file-operator {
      width: 180px;
      position: absolute;
      top: 100px;
      right: 34px;
      background: #ffffff;

      // 下载文件
      .download-dom {
        cursor: pointer;
        width: 180px;
        height: 62px;
        background: linear-gradient(0deg, #0080ff, #0080ff),
          linear-gradient(90deg, #0080ff 0%, #00a3ff 100%);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 40px 0 40px;
        .icon-Download1 {
          font-size: 22px;
          color: #ffffff;
        }
        span {
          margin-left: 6px;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
        }
      }
      ul {
        width: 100%;
        margin-top: 14px;
        padding-bottom: 26px;
        li {
          margin-top: 18px;
          cursor: pointer;
          .iconfont {
            color: #0080ff;
            font-size: 24px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #0080ff;
            margin-left: 12px;
          }
        }
      }
    }
  }
  // 取消按钮
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 84px;
    height: 34px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
    &:focus {
      color: #0080ff;
    }
  }
  // 评论输入框
  .file-comment::v-deep .el-textarea {
    width: 580px;
    height: 110px;
    .el-textarea__inner {
      width: 580px;
      height: 110px;
      resize: none;
    }
  }

  .icon-button {
    cursor: pointer;
    color: #ffb72c;
    font-size: 28px;
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none !important;
  }
  .catelog-content ::v-deep .node-render-content {
    overflow-x: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    cursor: pointer;
  }
  .file-content {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 50px 0 0 0;
    .file-show-scroll {
      width: 100%;
      height: calc(100vh - 50px - 60px) !important;
      padding: 0 10px 0px 10px;
      overflow-y: scroll;
      box-sizing: border-box;
      padding-right: 0px !important;
      // margin-bottom: 100px;
    }
    .file-show-content {
      width: 100%;
      margin: 0 auto;

      // border: 1px solid red;
      position: relative;
      .look-more {
        cursor: pointer;
        font-size: 14px;
        color: $main-color;
        line-height: 28px;
        margin-top: 10px;
        display: inline-block;
      }
      .catalogue-box {
        position: absolute;
        right: 180px;
        .catalogue {
          width: 180px;
          margin-top: 63px;
          position: fixed;
          // right: 66px;
          top: 130px;
          .title {
            width: 180px;
            border-bottom: 1px solid #eeeeee;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            box-sizing: border-box;
            padding-bottom: 10px;
          }
          .catelog-content {
            width: 180px;
            height: 600px;
            margin-top: 12px;
            font-size: 14px;
            line-height: 21px;
            overflow: scroll;
            color: #333333;
            .node-render-content {
              line-height: 24px;
            }
          }
        }
      }
    }

    .file-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 50px;
      padding: 0 10px 0 10px;
      box-sizing: border-box;
      background-color: #ffffff;
      z-index: 1;

      .base-path {
        p {
          font-style: normal;
          font-weight: normal;
          line-height: 30px;
          color: #999999;
          cursor: pointer;
        }
        .iconfont {
          margin: 0 6px 0 6px;
          font-size: 22px;
          color: #999999;
        }
      }

      .funtion-box {
        position: relative;
        display: none;
        .copy-box {
          position: relative;
          // 提示文字
          .tip-text {
            right: 0;
            position: absolute;
            font-size: 14px;
            line-height: 1.5;
            white-space: nowrap;
            background: #fff;
            color: $main-color;
          }
          .id-text {
            margin-right: 12px;
            font-size: 14px;
            line-height: 1.5;
            cursor: pointer;
            color: #bbb;
          }
        }

        .iconfont {
          font-size: 28px;
          color: #999999;
          margin-left: 16px;
          cursor: pointer;

          &.icon-yishoucang {
            font-size: 28px;
            color: #ffab2e;
            background: #ffffff;
          }
        }
        // 更多弹窗
        .gengduo-pop {
          z-index: 100;
          position: absolute;
          top: 30px;
          right: -6px;
          width: 100px;
          // height: 120px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          li {
            width: 100%;
            height: 33px;
            .li-node {
              cursor: pointer;
              width: 76px;
              height: 33px;
              box-sizing: border-box;
              padding: 0 10px 0 10px;
              .iconfont {
                font-size: 16px;
                margin: 0;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
              &:hover {
                background: rgba(0, 128, 255, 0.1);
                border-radius: 4px;
                .iconfont {
                  color: #0080ff;
                }
                span {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }

    .file-title {
      .icon {
        font-size: 90px;
      }
      .file-title-text {
        padding: 13px 0 13px 0;
        box-sizing: border-box;
        margin-left: 20px;
        width: 100%;
        span {
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
          color: #333333;
        }
        p:nth-child(1) {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          font-weight: bold;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 90%;
          word-break: break-all;
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 21px;
          color: #999999;
        }
      }
    }
    // 文本样式
    .document {
      .document-top {
        .document-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 36px;
          color: #333333;
        }
        .document-usertime {
          font-size: 12px;
          line-height: 24px;
          color: #999999;
          p {
            margin-left: 18px;
          }
        }
      }

      .document-content ::v-deep .ql-syntax {
        white-space: break-spaces;
      }
      .htmledit_views ::v-deep p {
        line-height: 26px !important;
      }
    }
    // 媒体头部样式
    .media {
      .media-content {
        width: 97%;
        // height: 200px;
        // background: #bbb;
        border-radius: 2px;
        overflow: hidden;
      }
      .media-title {
        margin: 20px 0 20px 0;
        span {
          font-weight: bold;
          font-size: 14px;
          line-height: 33px;
          color: #333333;
        }
        p {
          font-size: 12px;
          color: #999999;
        }
      }
    }

    .line {
      width: 100%;
      // width: 770px;
      height: 1px;
      background: #eeeeee;
      margin-bottom: 32px;
      padding: 0;
    }
    .file-state-box {
      .file-state {
        margin-top: 20px;
        .num {
          width: 22px;
          height: 22px;
          background: #ff9854;
          border-radius: 50%;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          color: #ffffff;
        }
        .upload-date {
          margin-left: 16px;
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
        .upload-author {
          margin-left: 12px;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
        .upload-name {
          margin-left: 12px;
          font-size: 14px;
          line-height: 21px;
          color: #999999;
        }
      }
    }
    .file-reply {
      margin-top: 20px;
      .file-reply-top {
        height: 36px;
        .user-img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 26px;
            height: 26px;
          }
        }
        .user-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 27px;
          color: #333333;
          margin-left: 18px;
        }
        .reply-time {
          font-size: 10px;
          line-height: 21px;
          color: #999999;
          margin-left: 10px;
        }
      }
      .file-reply-content {
        margin: 02px 0 0 40px;
        font-size: 12px;
        color: #333333;
      }
    }
    .file-comment {
      margin-top: 34px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0px;
      z-index: 99999;
      padding: 20px 10px;
      border: 1px solid #eee;
      background: #ffffff;
      .button-box {
        margin-top: 4px;
        height: 34px;
        margin-right: 12px;
        #send {
          width: 84px;
          height: 34px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
    // 右侧文件操作
    .file-operator {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #ffffff;
      z-index: 99999;
      width: 99%;
      height: 50px;
      line-height: 45px;
      // 下载文件
      ul {
        text-align: center;
        li {
          width: 100%;
          cursor: pointer;
          float: left;
          .iconfont,
          [class*=' el-icon-'],
          [class^='el-icon-'] {
            color: #0080ff;
            font-size: 16px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #0080ff;
            margin-left: 5px;
          }
        }
      }
    }
  }
  // 取消按钮
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 84px;
    height: 34px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
    &:focus {
      color: #0080ff;
    }
  }
  // 评论输入框
  .file-comment::v-deep .el-textarea {
    width: 100%;
    height: 60px;
    .el-textarea__inner {
      width: 100%;
      height: 50px;
      resize: none;
    }
  }
  .el-row {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
  .icon-button {
    cursor: pointer;
    color: #ffb72c;
    font-size: 28px;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .separator {
    width: 100%;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    border-bottom: 1px solid rgb(230, 230, 230);
    p {
      display: inline-block;
      color: #999999;
    }
  }
}
</style>
