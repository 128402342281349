<template>
  <div>
    <div class="my-message">
      <div class="my-message-top">
        <el-tabs :value="currentTab" @tab-click="changeTab">
          <el-tab-pane name="first">
            <span slot="label">
              评论消息
              <i v-if="commentNum > 0 && isShowCommentNum">{{
                commentNum | renderNum
              }}</i>
            </span>
            <scroll-fresh
              className="first-list"
              :class="{
                'no-message': !commentLoading && commentList.length == 0
              }"
              dataName="first-data"
              :page="commentPageNumber"
              :lastPage="commentTotalPage"
              :showBackTop="true"
              @get-next-page="getNextCommentPage"
              :showNoMore="!commentLoading && commentList.length > 0"
            >
              <el-skeleton :loading="commentLoading" animated :count="4">
                <template slot="template">
                  <div class="el-skeleton-item">
                    <div class="el-skeleton-item-header flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 50px;
                          height: 50px;
                          margin-right: 16px;
                          border-radius: 100%;
                        "
                      />
                      <div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              width: 50px;
                              height: 5px;
                              margin-bottom: 10px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="height: 5px; width: 130px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="el-skeleton-item-text">
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                    </div>
                    <div class="el-skeleton-item-content flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 110px;
                          height: 110px;
                          border-radius: 6px 0 0 6px;
                        "
                      />
                      <div style="padding-left: 16px">
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 50px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <li v-for="(cItem, cIndex) in commentList" :key="cIndex">
                    <div class="my-message-info flex flex-y-center">
                      <img
                        :src="cItem.clerk_avatar"
                        @click="clickAvatar(cItem.clerk_id)"
                      />
                      <div class="nameTime flex flex-y-positive flex-x-between">
                        <span>{{ cItem.clerk_alias || cItem.clerk_name }}</span>
                        <p>{{ getCurrentTime(cItem.create_time) }}</p>
                      </div>
                    </div>
                    <div class="replay">
                      <p v-if="cItem.reply_create_clerk_id !== null">
                        回复
                        <span class="replay-color"
                          >@{{
                            cItem.reply_clerk_alias || cItem.reply_clerk_name
                          }}</span
                        >
                        ：<span
                          @click="addLink"
                          v-html="
                            getCommentHtml(cItem, cItem.comment_content || '').replace(/\[[^\[\]]*\]/gi, emotion)
                          "
                        ></span>
                      </p>
                      <p
                        v-if="cItem.reply_create_clerk_id === null"
                        @click="addLink"
                        v-html="
                          getCommentHtml(cItem, cItem.comment_content || '').replace(/\[[^\[\]]*\]/gi, emotion)
                        "
                      ></p>
                      <p v-if="cItem.reply_create_clerk_id !== null">
                        <span class="replay-color"
                          >@{{
                            cItem.reply_clerk_alias || cItem.reply_clerk_name
                          }}</span
                        >
                        ：<span
                          @click="addLink"
                          v-html="
                            getPidCommentHtml(
                              cItem,
                              cItem.reply_comment_content || ''
                            )
                          "
                        ></span>
                      </p>
                    </div>
                    <div
                      class="content-box flex flex-y-center"
                      @click="openDetail(cItem.moment_id)"
                    >
                      <img :src="cItem.moment_clerk_avatar" />
                      <div class="content flex flex-y-positive">
                        <p>
                          @{{
                            cItem.moment_clerk_alias || cItem.moment_clerk_name
                          }}
                        </p>
                        <span>{{ cItem | renderContent }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </el-skeleton>
              <member-empty
                v-if="commentList.length === 0 && commentIsShow"
                icon="#icon-a-Frame32"
                title="暂无消息，快去多多发表动态吧！"
              ></member-empty>
            </scroll-fresh>
            <!-- 暂无消息 -->
            <!-- <div class="no-message-box flex  flex-x-center ">
              <div class="no-message flex flex-y-positive flex-x-between  flex-y-center">
                <img src="../assets/img/no-message.png" />
                <span>暂无消息，快去多多发表动态吧！</span>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane name="second">
            <span slot="label">
              点赞消息
              <i v-if="praiseNum > 0 && isShowPraiseNum">{{
                praiseNum | renderNum
              }}</i>
            </span>
            <scroll-fresh
              className="second-list"
              :class="{
                'no-message': !praiseLoading && praiseList.length == 0
              }"
              dataName="second-data"
              :page="praisePageNumber"
              :lastPage="praiseTotalPage"
              :showBackTop="true"
              @get-next-page="getNextPraisePage"
              :showNoMore="!praiseLoading && praiseList.length > 0"
            >
              <el-skeleton :loading="praiseLoading" animated :count="4">
                <template slot="template">
                  <div class="el-skeleton-item">
                    <div class="el-skeleton-item-header flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 50px;
                          height: 50px;
                          margin-right: 16px;
                          border-radius: 100%;
                        "
                      />
                      <div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              width: 50px;
                              height: 5px;
                              margin-bottom: 10px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="height: 5px; width: 130px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="el-skeleton-item-text">
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                    </div>
                    <div class="el-skeleton-item-content flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 110px;
                          height: 110px;
                          border-radius: 6px 0 0 6px;
                        "
                      />
                      <div style="padding-left: 16px">
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 50px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <li v-for="(pItem, pIndex) in praiseList" :key="pIndex">
                    <div class="my-message-info flex flex-y-center">
                      <img :src="pItem.clerk_avatar || pItem.clerk_avatar" />
                      <div class="nameTime flex flex-y-positive flex-x-between">
                        <span>{{ pItem.clerk_alias || pItem.clerk_name }}</span>
                        <p>{{ getCurrentTime(pItem.create_time) }}</p>
                      </div>
                    </div>
                    <div class="replay">
                      <p>
                        赞了这条{{
                          pItem.reply_create_clerk_id ? '评论' : '圈子'
                        }}
                      </p>
                      <p
                        v-if="
                          pItem.reply_clerk_name !== null ||
                          pItem.reply_clerk_name !== null
                        "
                      >
                        <span
                          >@{{
                            pItem.reply_clerk_alias || pItem.reply_clerk_name
                          }}</span
                        >
                        ：{{ pItem.reply_comment_content || '' }}
                      </p>
                    </div>
                    <div
                      class="content-box flex flex-y-center"
                      @click="openDetail(pItem.moment_id)"
                    >
                      <img :src="pItem.moment_clerk_avatar" />
                      <div class="content flex flex-y-positive">
                        <p>
                          @{{
                            pItem.moment_clerk_alias || pItem.moment_clerk_name
                          }}
                        </p>
                        <span>{{ pItem | renderContent }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </el-skeleton>
              <member-empty
                v-if="praiseList.length === 0 && praiseIsShow"
                icon="#icon-a-Frame32"
                title="暂无消息，快去多多发表动态吧！"
              ></member-empty>
            </scroll-fresh>
          </el-tab-pane>
          <el-tab-pane name="third">
            <span slot="label">
              @我的圈子
              <i v-if="mentionNum > 0 && isShowMentionNum">{{
                mentionNum | renderNum
              }}</i>
            </span>
            <scroll-fresh
              className="second-list"
              :class="{
                'no-message': !mentionLoading && mentionList.length == 0
              }"
              dataName="second-data"
              :page="mentionPageNumber"
              :lastPage="mentionTotalPage"
              :showBackTop="true"
              @get-next-page="getNextMentionPage"
              :showNoMore="!mentionLoading && mentionList.length > 0"
            >
              <el-skeleton :loading="mentionLoading" animated :count="4">
                <template slot="template">
                  <div class="el-skeleton-item">
                    <div class="el-skeleton-item-header flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 50px;
                          height: 50px;
                          margin-right: 16px;
                          border-radius: 100%;
                        "
                      />
                      <div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              width: 50px;
                              height: 5px;
                              margin-bottom: 10px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="height: 5px; width: 130px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="el-skeleton-item-text">
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                    </div>
                    <div class="el-skeleton-item-content flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 110px;
                          height: 110px;
                          border-radius: 6px 0 0 6px;
                        "
                      />
                      <div style="padding-left: 16px">
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 50px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <li v-for="(tItem, tIndex) in mentionList" :key="tIndex">
                    <div class="my-message-info flex flex-y-center">
                      <img :src="tItem.moment_clerk_avatar || ''" />
                      <div class="nameTime flex flex-y-positive flex-x-between">
                        <span>{{
                          tItem.moment_clerk_alias || tItem.moment_clerk_name
                        }}</span>
                        <p>{{ getCurrentTime(tItem.create_time) }}</p>
                      </div>
                    </div>
                    <div class="replay">
                      <p
                        @click="addLink"
                        ref="messageContent"
                        v-html="getContenHtml(tItem)"
                        class="message-content-mentions"
                      ></p>
                    </div>

                    <div
                      class="content-box flex flex-y-center"
                      @click="openDetail(tItem.moment_id)"
                    >
                      <img :src="tItem.moment_clerk_avatar" />
                      <div class="content flex flex-y-positive">
                        <span>{{ tItem | renderContent }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </el-skeleton>
              <member-empty
                v-if="mentionList.length === 0 && mentionIsShow"
                icon="#icon-a-Frame32"
                title="暂无数据，快去多多发表动态吧！"
              ></member-empty>
            </scroll-fresh>
          </el-tab-pane>
          <el-tab-pane name="four">
            <span slot="label">
              @我的评论
              <i v-if="commentMentionNum > 0 && isShowCommentMentionNum">{{
                commentMentionNum | renderNum
              }}</i>
            </span>
            <scroll-fresh
              className="first-list"
              :class="{
                'no-message':
                  !commentMentionLoading && commentMentionList.length == 0
              }"
              dataName="first-data"
              :page="commentMentionPageNumber"
              :lastPage="commentMentionTotalPage"
              :showBackTop="true"
              @get-next-page="getNextCommnetMentionPage"
              :showNoMore="
                !commentMentionLoading && commentMentionList.length > 0
              "
            >
              <el-skeleton :loading="commentMentionLoading" animated :count="4">
                <template slot="template">
                  <div class="el-skeleton-item">
                    <div class="el-skeleton-item-header flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 50px;
                          height: 50px;
                          margin-right: 16px;
                          border-radius: 100%;
                        "
                      />
                      <div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              width: 50px;
                              height: 5px;
                              margin-bottom: 10px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="height: 5px; width: 130px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="el-skeleton-item-text">
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; margin-bottom: 15px; width: 400px"
                      />
                    </div>
                    <div class="el-skeleton-item-content flex flex-y-center">
                      <el-skeleton-item
                        variant="image"
                        style="
                          width: 110px;
                          height: 110px;
                          border-radius: 6px 0 0 6px;
                        "
                      />
                      <div style="padding-left: 16px">
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 50px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                        <div>
                          <el-skeleton-item
                            variant="p"
                            style="
                              height: 5px;
                              margin-bottom: 15px;
                              width: 300px;
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <li
                    v-for="(kItem, kIndex) in commentMentionList"
                    :key="kIndex"
                  >
                    <div class="my-message-info flex flex-y-center">
                      <img
                        :src="kItem.clerk_avatar"
                        @click="clickAvatar(kItem.clerk_id)"
                      />
                      <div class="nameTime flex flex-y-positive flex-x-between">
                        <span>{{ kItem.clerk_alias || kItem.clerk_name }}</span>
                        <p>{{ getCurrentTime(kItem.create_time) }}</p>
                      </div>
                    </div>
                    <div class="replay">
                      <p v-if="kItem.reply_create_clerk_id !== null">
                        回复
                        <span class="replay-color"
                          >@{{
                            kItem.reply_clerk_alias || kItem.reply_clerk_name
                          }}</span
                        >
                        ：<span
                          @click="addLink"
                          v-html="
                            getCommentHtml(kItem, kItem.comment_content || '').replace(/\[[^\[\]]*\]/gi, emotion)
                          "
                        ></span>
                      </p>
                      <p v-if="kItem.reply_create_clerk_id === null">
                        <span
                          @click="addLink"
                          v-html="
                            getCommentHtml(kItem, kItem.comment_content || '').replace(/\[[^\[\]]*\]/gi, emotion)
                          "
                        ></span>
                      </p>
                      <p v-if="kItem.reply_create_clerk_id !== null">
                        <span class="replay-color"
                          >@{{
                            kItem.reply_clerk_alias || kItem.reply_clerk_name
                          }}</span
                        >
                        ：<span
                          @click="addLink"
                          v-html="
                            getPidCommentHtml(
                              kItem,
                              kItem.reply_comment_content || ''
                            )
                          "
                        ></span>
                      </p>
                    </div>
                    <div
                      class="content-box flex flex-y-center"
                      @click="openDetail(kItem.moment_id)"
                    >
                      <img :src="kItem.moment_clerk_avatar" />
                      <div class="content flex flex-y-positive">
                        <p>
                          @{{
                            kItem.moment_clerk_alias || kItem.moment_clerk_name
                          }}
                        </p>
                        <span>{{ kItem | renderContent }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </el-skeleton>
              <member-empty
                v-if="commentMentionList.length === 0 && commnetMentionIsShow"
                icon="#icon-a-Frame32"
                title="暂无消息，快去多多发表动态吧！"
              ></member-empty>
            </scroll-fresh>
            <!-- 暂无消息 -->
            <!-- <div class="no-message-box flex  flex-x-center ">
              <div class="no-message flex flex-y-positive flex-x-between  flex-y-center">
                <img src="../assets/img/no-message.png" />
                <span>暂无消息，快去多多发表动态吧！</span>
              </div>
            </div> -->
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <edit-message
      :visible="editVisible"
      @addComplete="addComplete"
      @editComplete="editComplete"
      :momentId="editMomentId"
      @close="handlerCloseDialog"
    ></edit-message>
    <!-- 目标详情弹窗 -->
    <message-detail-pop
      @searchTopic="searchTopic"
      @searchMention="searchMention"
      :visible="detailVisible"
      :momentId="detailMomentId"
      @close="closeDetail"
      @toEdit="detailToEdit"
    ></message-detail-pop>
  </div>
</template>

<script>
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
// 侧边详情
import MessageDetailPop from '@/components/circle/MessageDetailPop.vue'
import MemberEmpty from '@/components/member/MemberEmpty.vue'
import { EventBusEmit } from '@/util/EventBus.js'
import { emojisMap } from '@/components/common/ExpressionBox.vue'
import { mapState } from 'vuex'

export default {
  components: { ScrollFresh, EditMessage, MessageDetailPop, MemberEmpty },
  data() {
    return {
      commentMentionNum: 0,
      commentNum: 0,
      praiseNum: 0,
      mentionNum: 0,
      currentTab: 'first',
      commentPageNumber: 1,
      praisePageNumber: 1,
      mentionPageNumber: 1,
      commentMentionPageNumber: 1,
      pageSize: 10,
      commentTotalPage: 2,
      praiseTotalPage: 2,
      mentionTotalPage: 2,
      commentMentionTotalPage: 2,
      // 详情展示
      detailVisible: false,
      // 修改的id
      editMomentId: -1,
      // 详情的id
      detailMomentId: -1,
      // 修改展示
      editVisible: false,
      praiseList: [],
      mentionList: [],
      commentMentionList: [],
      commentList: [],
      // 是否展示评论数量
      isShowCommentNum: true,
      // 是否展示点赞数量
      isShowPraiseNum: true,
      // 是否展示@数量
      isShowMentionNum: true,
      // 是否展示@评论数量
      isShowCommentMentionNum: true,
      // 点赞缺省页是否展示
      praiseIsShow: false,
      mentionIsShow: false,
      commnetMentionIsShow: false,
      // 评论缺省页是否展示
      commentIsShow: false,
      commentLoading: false,
      commentMentionLoading: false,
      praiseLoading: false,
      mentionLoading: false
    }
  },
  computed: {
    ...mapState(['myClerkId'])
  },
  filters: {
    renderNum(v) {
      if (v > 99 && v < 1000) {
        return '99+'
      }
      if (v > 999) {
        return '999+'
      }
      return v
    },
    // 处理文字
    renderContent(item) {
      if (item.moment_content?.trim().length > 0) {
        return item.moment_content
      }
      switch (item.content_type) {
        case 1:
          return '[图片]'
        case 2:
          return '[视频]'
      }
    }
  },
  methods: {
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
    },
    // 搜索话题
    searchTopic(e) {
      this.closeDetail()
      this.detailVisible = false
      this.$router.push({
        name: 'circleIndex',
        query: { topic_id: e }
      })
    },
    // 进入提及人主页
    searchMention(e) {
      this.closeDetail()
      this.detailVisible = false
      if (this.myClerkId === e) {
        this.$router.push({
          name: 'memberHome',
          params: { clerkId: this.elementId }
        })
      } else {
        this.$router.push({
          name: 'circleHome',
          params: { clerkId: e }
        })
      }
    },
    addLink(e) {
      const cb = e.target.dataset
      if (cb.class === 'topic') {
        this.$router.push({
          name: 'circleIndex',
          query: { topic_id: cb.id }
        })
      }
      if (cb.class === 'mention') {
        if (this.myClerkId === cb.id) {
          this.$router.push({
            name: 'memberHome',
            params: { clerkId: cb.id }
          })
        } else {
          this.$router.push({
            name: 'circleHome',
            params: { clerkId: cb.id }
          })
        }
      }
    },
    // 获取评论内容html
    getCommentHtml(e, te) {
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = te
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;cursor: pointer;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 获取评论父级内容html
    getPidCommentHtml(e, te) {
      // 提及列表
      const list = e.pid_clerk_list.map((c) => {
        return c
      })
      const content = te
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;cursor: pointer;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 获取内容html
    getContenHtml(e) {
      // 提及列表
      const clerList = e.clerk_list.map((c) => {
        c.type = 1
        return c
      })
      // 话题列表
      const topicList = e.topic_list.map((t) => {
        t.type = 2
        return t
      })
      const list = clerList.concat(topicList).sort((a, b) => {
        return a.start_index - b.start_index
      })
      const content = e.moment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name =
            e.type === 1
              ? '@' + (e.clerk_alias || e.clerk_name) + ' '
              : '#' + e.topic_name + '#' + ' '
          const id = e.type === 1 ? e.clerk_id : e.topic_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="${
              e.type === 1 ? 'mention' : 'topic'
            }" style=" color: #0080FF;cursor: pointer;font-weight:600"> ${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    changeTab(e) {
      let tab = ''
      switch (e.name) {
        case 'second':
          tab = 'praise'
          break
        case 'third':
          tab = 'mention'
          break
        case 'four':
          tab = 'commnetMention'
          break
      }
      this.$router.replace({
        name: this.$route.name,
        query: tab ? { tab: tab } : {}
      })
    },
    andleClick(tab, event) {
      console.log(tab, event)
    },
    // 获取点赞消息
    async getPraiseList(reset = false) {
      const formObj = {
        pageNumber: this.praisePageNumber,
        pageSize: this.pageSize
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getPraiseList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.praiseList = res.page.list
        } else {
          this.praiseList = this.praiseList.concat(res.page.list)
        }
        this.praisePageNumber = res.page.pageNumber
        if (res.page.list.length < this.pageSize) {
          this.praiseTotalPage = this.praisePageNumber
        } else {
          this.praiseTotalPage = this.praisePageNumber + 1
        }
        if (this.praiseList.length === 0) {
          this.praiseIsShow = true
        }
      }
    },
    // 获取点赞下一页
    async getNextPraisePage() {
      this.praisePageNumber++
      this.getPraiseList()
    },
    // 刷新点赞列表
    async refreshPraiseList() {
      this.praisePageNumber = 1
      this.praiseTotalPage = 2
      this.praiseList = []
      this.praiseLoading = true
      await this.getPraiseList(true)
      setTimeout(() => {
        this.praiseLoading = false
      }, 800)
    },
    // 获取@我的圈子下一页
    async getNextMentionPage() {
      this.mentionPageNumber++
      this.getMentionList()
    },
    // 获取@我的圈子消息
    async getMentionList(reset = false) {
      const formObj = {
        pageNumber: this.mentionPageNumber,
        pageSize: this.pageSize
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMentionList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.mentionList = res.page.list
        } else {
          this.mentionList = this.mentionList.concat(res.page.list)
        }
        this.mentionPageNumber = res.page.pageNumber
        if (res.page.list.length < this.pageSize) {
          this.mentionTotalPage = this.mentionPageNumber
        } else {
          this.mentionTotalPage = this.mentionPageNumber + 1
        }
        if (this.mentionList.length === 0) {
          this.mentionIsShow = true
        }
      }
    },
    // 刷新@我的圈子列表
    async refreshMentionList() {
      this.mentionPageNumber = 1
      this.mentionTotalPage = 2
      this.mentionList = []
      this.mentionLoading = true
      await this.getMentionList(true)
      setTimeout(() => {
        this.mentionLoading = false
      }, 800)
    },
    // 获取评论消息
    async getCommentList(reset = false) {
      const formObj = {
        pageNumber: this.commentPageNumber,
        pageSize: this.pageSize
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.commentList = res.page.list
        } else {
          this.commentList = this.commentList.concat(res.page.list)
        }
        this.commentPageNumber = res.page.pageNumber
        if (res.page.list.length < this.pageSize) {
          this.commentTotalPage = this.commentPageNumber
        } else {
          this.commentTotalPage = this.commentPageNumber + 1
        }
        if (this.commentList.length === 0) {
          this.commentIsShow = true
        }
      }
    },
    // 获取评论下一页
    async getNextCommentPage() {
      this.commentPageNumber++
      this.getCommentList()
    },
    // 刷新@我的评论列表
    async refreshCommentMentionList() {
      this.commentMentionPageNumber = 1
      this.commentMentionTotalPage = 2
      this.commentMentionList = []
      this.commentMentionLoading = true
      await this.getCommentMentionList(true)
      setTimeout(() => {
        this.commentMentionLoading = false
      }, 800)
    },
    // 获取@评论下一页
    async getNextCommnetMentionPage() {
      this.commentMentionPageNumber++
      this.getCommentMentionList()
    },
    // 获取@我的评论消息
    async getCommentMentionList(reset = false) {
      const formObj = {
        pageNumber: this.commentMentionPageNumber,
        pageSize: this.pageSize
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentMentionList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.commentMentionList = res.page.list
        } else {
          this.commentMentionList = this.commentMentionList.concat(
            res.page.list
          )
        }
        this.commentMentionPageNumber = res.page.pageNumber
        if (res.page.list.length < this.pageSize) {
          this.commentMentionTotalPage = this.commentMentionPageNumber
        } else {
          this.commentMentionTotalPage = this.commentMentionPageNumber + 1
        }
        if (this.commentMentionList.length === 0) {
          this.commnetMentionIsShow = true
        }
      }
    },
    // 刷新点赞列表
    async refreshCommentList() {
      this.commentPageNumber = 1
      this.commentTotalPage = 2
      this.commentList = []
      this.commentLoading = true
      await this.getCommentList(true)
      setTimeout(() => {
        this.commentLoading = false
      }, 800)
    },
    // 获取创建时间格式
    getCurrentTime(time) {
      return this.$util.getTimeStr(time)
    },
    // 关闭详情
    closeDetail() {
      this.detailMomentId = -1
      this.detailVisible = false
    },
    // 详情跳转修改
    detailToEdit() {
      this.editMomentId = this.detailMomentId
      this.detailMomentId = -1
      this.detailVisible = false
      this.editVisible = true
    },
    // 添加圈子回调
    addComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.handleClick()
    },
    // 修改圈子回调
    editComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.handleClick()
    },
    // 关闭编辑弹窗
    handlerCloseDialog() {
      this.editVisible = false
      this.editMomentId = -1
    },
    // 点击详情
    openDetail(momentId) {
      this.detailMomentId = momentId
      this.detailVisible = true
    },
    // 圈子消息评论数量
    async getCommentCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.commentNum = res.count
        if (this.commentNum > 0 && this.currentTab === 'first') {
          EventBusEmit('clearMomentMessage', 'comment')
          this.clearMomentMessageCommentCount()
          this.commentNum = 0
        }
      }
    },
    // 圈子消息点赞数量
    async getPraiseCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getPraiseCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.praiseNum = res.count
        if (this.praiseNum > 0 && this.currentTab === 'second') {
          EventBusEmit('clearMomentMessage', 'praise')
          this.clearMomentMessagePraiseCount()
          this.praiseNum = 0
        }
      }
    },
    // 圈子消息@评论数量
    async getCommentMntionCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentMntionCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.commentMentionNum = res.count
        if (this.commentMentionNum > 0 && this.currentTab === 'four') {
          EventBusEmit('clearCommentMessage', 'commentMention')
          this.clearCommentMessageMentionCount()
          this.commentMentionNum = 0
        }
      }
    },
    // 圈子消息@数量
    async getMntionCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMntionCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.mentionNum = res.count
        if (this.mentionNum > 0 && this.currentTab === 'third') {
          EventBusEmit('clearMomentMessage', 'mention')
          this.clearMomentMessageMentionCount()
          this.mentionNum = 0
        }
      }
    },
    // 清除圈子消息评论统计
    async clearMomentMessageCommentCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.clearMomentMessageCommentCount, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 清除圈子消息@统计
    async clearMomentMessageMentionCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.clearMomentMessageMentionCount, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 清除圈子消息@评论统计
    async clearCommentMessageMentionCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.clearCommentMessageMentionCount, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 清除圈子消息点赞统计
    async clearMomentMessagePraiseCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.clearMomentMessagePraiseCount, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 点击头像
    clickAvatar(clerkId) {
      this.$router.push({
        name: 'memberHome',
        params: { clerkId: clerkId }
      })
    }
  },
  mounted() {
    // 切换tab
    if (this.$route?.query?.tab) {
      switch (this.$route.query.tab) {
        case 'praise':
          this.currentTab = 'second'
          this.refreshPraiseList()
          this.isShowPraiseNum = false
          break
        case 'mention':
          this.currentTab = 'third'
          this.refreshMentionList()
          this.isShowMentionNum = false
          break
        case 'commnetMention':
          this.currentTab = 'four'
          this.refreshCommentMentionList()
          this.isShowCommentMentionNum = false
          break
      }
    } else {
      this.currentTab = 'first'
      this.refreshCommentList()
      this.isShowCommentNum = false
    }
    // 获取数量
    this.getCommentCount()
    this.getPraiseCount()
    this.getMntionCount()
    this.getCommentMntionCount()
  },
  watch: {
    '$route.query'(val, old) {
      // 切换tab
      if (val?.tab !== old?.tab) {
        switch (val.tab) {
          case 'praise':
            this.currentTab = 'second'
            this.refreshPraiseList()
            if (this.isShowPraiseNum && this.praiseNum > 0) {
              EventBusEmit('clearMomentMessage', 'praise')
              this.clearMomentMessagePraiseCount()
              this.praiseNum = 0
            }
            this.isShowPraiseNum = false
            break
          case 'mention':
            this.currentTab = 'third'
            this.refreshMentionList()
            if (this.isShowMentionNum && this.mentionNum > 0) {
              EventBusEmit('clearMomentMessage', 'mention')
              this.clearMomentMessageMentionCount()

              this.mentionNum = 0
            }
            this.isShowMentionNum = false
            break
          case 'commnetMention':
            this.currentTab = 'four'
            this.refreshCommentMentionList()
            if (this.isShowCommentMentionNum && this.commentMentionNum > 0) {
              EventBusEmit('clearMomentMessage', 'commnetMention')
              this.clearCommentMessageMentionCount()

              this.commentMentionNum = 0
            }
            this.isShowCommentMentionNum = false
            break
          default:
            this.currentTab = 'first'
            this.refreshCommentList()
            if (this.isShowCommentNum && this.commentNum > 0) {
              EventBusEmit('clearMomentMessage', 'comment')
              this.clearMomentMessageCommentCount()
              this.commentNum = 0
            }
            this.isShowCommentNum = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-skeleton-item {
  border-radius: 6px;
  list-style: none;
  background: #ffffff;
  padding: 20px 0 28px 0;
  margin-bottom: 18px;
  .el-skeleton-item-header {
    height: 50px;
    margin-left: 24px;
  }
  .el-skeleton-item-text {
    margin-left: 90px;
    margin-top: 14px;
  }
  .el-skeleton-item-content {
    border-radius: 6px;
    height: 110px;
    margin: 16px 20px 0 90px;
    background: #f6f6f6;
  }
}
</style>

<style lang="scss" >
.my-message {
  .empty-container {
    margin-top: 208px;
  }
  .my-message-top {
    border-radius: 6px;
    height: 56px;
    background: #ffffff;
    padding-left: 36px;
    .el-tabs__nav,
    .el-tabs__nav-scroll {
      width: 604px;
      height: 56px;
      background: white;
    }
    .el-tabs__header {
      margin-bottom: 20px;
      border-radius: 6px;
      overflow: hidden;
    }
    .el-tabs__item {
      // width: 115px;
      height: 56px;
      line-height: 56px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #999999;
      // padding: 0;
      // text-align: center;
      position: relative;
      i {
        width: 30px;
        height: 18px;
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
        background: #ff5454;
        border-radius: 24px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        // position: absolute;
        // right: 0;
        // top: 50%;
        // transform: translateY(-50%);
        // margin-right: 18px;
      }
    }
    .el-tabs__item.is-active {
      color: $main-color;
    }
    .el-tabs__item:hover {
      color: $main-color;
    }
    .el-tabs__active-bar {
      left: -2px;
      height: 2px;
    }
    .el-tabs__content {
      height: 868px;
      overflow: hidden;
      overflow-y: scroll;
      margin-left: -36px;
      background: $light-grey-bg;
      li {
        border-radius: 6px;
        list-style: none;
        background: #ffffff;
        padding: 20px 0 28px 0;
        margin-bottom: 18px;
        .my-message-info {
          height: 50px;
          margin-left: 24px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
          .nameTime {
            height: 50px;
            margin-left: 16px;
            span {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #333333;
            }
            p {
              font-size: 14px;
              line-height: 21px;
              color: #bbbbbb;
            }
          }
        }
        .replay {
          margin-left: 90px;
          margin-top: 14px;
          p {
            margin-top: 10px;
            font-size: 14px;
            line-height: 21px;
            color: #333333;
            white-space: break-spaces;
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 2;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            .replay-color {
              color: $main-color;
            }
          }
        }
        .content-box {
          border-radius: 6px;
          height: 110px;
          margin: 16px 20px 0 90px;
          background: $light-grey-bg;
          img {
            width: 110px;
            height: 110px;
            border-radius: 6px 0 0 6px;
          }
          .content {
            border-radius: 0 6px 6px 0;
            box-sizing: border-box;
            padding-left: 16px;
            p {
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              color: #333333;
            }
            span {
              margin-top: 6px;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              line-clamp: 2;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .system-message {
        height: 195px;
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 28px 24px;
        border-radius: 6px;
        .system-message-title {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
        .content-box {
          border-radius: 6px;
          height: 110px;
          background: $light-grey-bg;
          img {
            width: 110px;
            height: 110px;
            border-radius: 6px 0 0 6px;
          }
          .content {
            border-radius: 0 6px 6px 0;
            background: $light-grey-bg;
            box-sizing: border-box;
            padding-left: 16px;
            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #333333;
            }
            span {
              width: 380px;
              margin-top: 6px;
              font-size: 14px;
              line-height: 21px;
              color: #666666;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              line-clamp: 2;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .no-message-box {
        width: 100%;
        height: 868px;
        background: #ffffff;
        padding-top: 230px;
        .no-message {
          width: 210px;
          height: 149px;
          img {
            width: 210px;
            height: 100px;
          }
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #bbbbbb;
            line-height: 21px;
          }
        }
      }
    }

    .el-tab-pane {
      height: calc(100vh - 158px);

      .empty-container {
        margin-top: 200px;
      }
    }
    .second-list,
    .first-list {
      height: 100%;
      overflow: scroll;
    }
    .no-message {
      background-color: #fff;
      border-radius: 6px;
    }
  }
}

// @media screen and (max-width: 767px) {
//   .my-message {
//     position: fixed;
//     top: 0;
//     height: calc(100vh - 50px);
//     width: 100vw;
//   }
// }
</style>
