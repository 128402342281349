<template>
  <div class="tips-dialog-container flex">
    <div class="tips-dialog">
      <div class="tips-title">{{title}}</div>
      <div class="flex tips-button-container">
        <div @click="$emit('cancle')">取消</div>
        <div @click="$emit('confirm')">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style lang="scss" scoped>
.tips-dialog-container {
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.4);

  .tips-dialog {
    padding: 38px 45px;
    border-radius: 4px;
    text-align: center;
    background-color: #fff;

    .tips-title {
      font-size: 16px;
      margin-bottom: 26px;
    }

    .tips-button-container {
      justify-content: center;

      div {
        padding: 5px 26px;
        font-weight: 500px;
        height: 21px;
        line-height: 21px;
        border-radius: 4px;

        &:first-child {
          border: 1px solid #EEEEEE;
          color: #bbb;
          margin-right: 20px;
        }

        &:last-child {
          color: #fff;
          background-color: $main-color;
        }
      }
    }
  }
}
</style>
