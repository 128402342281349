<!--
 * @Author: Betty
 * @Date: 2021-07-16 22:25:02
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-23 17:31:43
 * @Description: 工作台页面的目标内层组件，每一个目标
-->
<template>
  <div>
    <!-- 它自己 -->
    <div class="target-item target-group-item flex flex-y-center">
      <div
        class="more-menu-box flex flex-y-center move-target-rank hidden-xs-only"
        :style="{ cursor: isMe ? 'move' : 'default !important' }"
      >
        <i class="iconfont icon-tuozhuai target-more" v-if="isMe"></i>
      </div>
      <!-- 展开收起子元素 -->
      <span
        class="show-child iconfont icon-zhankai"
        @click.stop="toggleChildren"
        :class="{ active: isShowChild }"
        :style="{ left: layer * 20 + renderWidth + 'px' }"
        v-if="target.children && target.children.length > 0"
      ></span>
      <!-- 目标名称 -->
      <div
        class="target-item-td target-name-box flex flex-y-center name-box"
        :style="{
          'padding-left': layer * 20 + 20 + renderWidth + 'px'
        }"
      >
        <div @click="setTargetStatus(target.objective_id)">
          <svg class="icon" aria-hidden="true">
            <use
              :xlink:href="
                target.objective_status === 3 || target.objective_status === 4
                  ? '#icon-TickSquare_1'
                  : '#icon-TickSquare'
              "
            ></use>
          </svg>
        </div>
        <!-- 编辑任务名字 -->
        <input
          type="text"
          placeholder="请输入目标名称"
          :ref="editNameRef"
          v-model="tempName"
          v-if="isEditTarget"
          class="edit-input"
          @blur="handleEditTarget"
          @keyup.229="empty"
          @keyup.13="toBlur($event)"
          @keyup.esc=";(escIsShow = true), (escShowRef = editNameRef)"
        />
        <div
          class="target-name"
          :class="{
            over: target.objective_status === 3,
            end_over: target.objective_status === 4
          }"
          v-else
          @click="toTargetDetail(target.objective_id)"
        >
          <!-- 把目标名称和完成情况的文字放进来 -->
          <div class="target-name-info flex flex-y-center">
            <p class="target-name-text e">
              <!-- 标签 -->
              <span
                class="target-label"
                v-if="infoLabel.gth > 0"
                :style="{
                  color: '#FF5454',
                  background: '#fff',
                  fontWeight: '600',
                  fontSize: '15px'
                }"
                >{{ infoLabel.gthStr }}</span
              >
              <span
                class="target-label"
                v-for="(item, index) in infoLabel.list"
                :key="index"
                @click.stop="$emit('changeLabel', item.id)"
                >{{ item.name }}</span
              >{{ infoLabel.showName }}
            </p>
            <template v-if="target.child_total_count > 0">
              <p class="child-info" v-if="timeoutCount > 0">
                (<span style="color: red">{{ timeoutCount }}</span
                >/{{ target.child_complete_count }}/{{
                  target.child_total_count
                }})
              </p>
              <p class="child-info" v-else>
                ({{ target.child_complete_count }}/{{
                  target.child_total_count
                }})
              </p>
            </template>
          </div>
          <!-- 更多按钮和菜单 -->
          <!-- 当前是我的情况下，才能打开更多弹窗 -->
          <div class="more-menu-box" v-if="isMe">
            <span
              class="iconfont icon-gengduo3 target-more hidden-xs-only"
              @click.stop="showMoreMenu"
            ></span>
            <!-- 菜单 -->
            <div class="target-more-menu" v-show="isShowTargetMore">
              <div class="target-more-item" @click.stop="toMoveItem(target)">
                移动
              </div>
              <div
                class="target-more-item"
                @click.stop="beginToEditTarget(target)"
              >
                编辑目标
              </div>
              <div
                class="target-more-item delete"
                @click.stop="deleteTarget(target)"
                v-if="target.create_clerk_id === myClerkId"
              >
                删除
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 目标关联 -->
      <div class="target-item-td relative-box">
        <span
          class="iconfont relative-icon"
          :class="[
            { active: target.pid > 0 },
            target.pid === 0 ? 'icon-to_relative' : 'icon-relatived'
          ]"
          :style="{ cursor: isMe ? 'pointer' : 'default' }"
          @click="toChooseRelative(target)"
        ></span>
      </div>
      <!-- 目标类型 -->
      <div
        class="target-item-td type-box hidden-xs-only"
        :style="{ cursor: isMe ? 'pointer' : 'default' }"
        @click.stop="toChooseTargetType(target.objective_id)"
      >
        <div
          class="target-type-box"
          :style="{ cursor: isMe ? 'pointer' : 'default' }"
          :class="[getTargetTypeInfo(target.objective_type).color]"
          v-if="getTargetTypeInfo(target.objective_type)"
        >
          {{ getTargetTypeInfo(target.objective_type).text }}
        </div>
        <!-- 选择目标类型的弹窗 -->
        <div
          v-show="
            isShowTargetTypes && Parent.currentShowType === target.objective_id
          "
          class="target-types"
        >
          <div
            class="target-type-item"
            v-for="(item, index) in targetTypeList"
            :key="index"
          >
            <div
              class="target-type-box"
              :class="[item.color]"
              @click="chooseTargetType(item.value)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      <!-- 截止时间 -->
      <div
        class="target-item-td time-box hidden-xs-only"
        :class="{ 'is-late': isLate }"
      >
        <el-date-picker
          v-model="lastTime"
          align="right"
          type="date"
          @blur="setLastTime(target)"
          v-if="isMe"
          placeholder=""
          size="small"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <p v-if="!isMe" class="last-time-text" :class="{ 'is-late': isLate }">
          {{ getLastTime }}
        </p>
      </div>
      <!-- 完成进度 -->
      <div
        class="
          target-item-td
          hidden-xs-only
          flex flex-y-center flex-x-center
          progress-box
        "
        @click.stop="toSetTargetProgress(target, $event)"
      >
        <el-progress
          :color="target.progress_status === 1 ? '#0080FF' : '#FF5454'"
          type="circle"
          :percentage="target.progress_number"
          :width="20"
          :show-text="false"
          :stroke-width="2"
        ></el-progress>
        <span class="progress">{{ target.progress_number + '%' }}</span>
        <!-- 目标的进度弹窗 -->
        <div
          class="task-process"
          v-show="
            isShowTargetProgress &&
            Parent.currentShowTargetProgress === target.objective_id
          "
          @click.stop
        >
          <h6 class="process-title">当前进度</h6>
          <div class="flex flex flex-y-center">
            <input
              type="number"
              class="input"
              min="0"
              max="100"
              v-model="currentTargetProgress"
              ref="editTargetProgressRef"
              @blur="readyToEditTargetProgress()"
              @keyup.enter="toBlur($event)"
            />
            <span class="percent">%</span>
          </div>
          <h6 class="process-title">状态</h6>
          <div class="radio-item">
            <label
              for=""
              class="label normal flex flex-y-center"
              @click="setTargetProgressStatus(1)"
            >
              <svg class="icon radio-icon" aria-hidden="true">
                <use
                  :xlink:href="
                    currentTargetProgressStatus === 1
                      ? '#icon-Group706'
                      : '#icon-Group592'
                  "
                ></use>
              </svg>
              <input
                type="radio"
                name="task"
                :checked="currentTargetProgressStatus === 1"
                id=""
                class="radio"
              />
              <span>正常</span>
            </label>
          </div>
          <div class="radio-item">
            <label
              for=""
              class="label late flex flex-y-center"
              @click="setTargetProgressStatus(2)"
            >
              <svg class="icon radio-icon" aria-hidden="true">
                <use
                  :xlink:href="
                    currentTargetProgressStatus === 2
                      ? '#icon-Group705'
                      : '#icon-Group593'
                  "
                ></use>
              </svg>
              <input
                type="radio"
                name="task"
                :checked="currentTargetProgressStatus === 2"
                id=""
                class="radio"
              />
              <span>已延期</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- 它孩子 -->
    <div v-show="isShowChild">
      <draggable
        :list="target.children"
        animation="300"
        :scroll="true"
        :move="onMove"
        chosenClass="chosen"
        @end="choose"
        group="demoDragTree"
        handle=".move-target-rank"
      >
        <transition-group>
          <target-group-item
            v-for="item in target.children"
            :key="item.objective_id"
            :target="item"
            :layer="layer + 1"
            :isMe="isMe"
            @to-choose-relative="toChooseRelative(item)"
            @to-fresh-target="toFresh"
            @to-target-detail="toTargetDetail($event)"
            @changeLabel="$emit('changeLabel', $event)"
          ></target-group-item>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// 导入自己
import TargetGroupItem from './TargetGroupItem.vue'
import draggable from 'vuedraggable'
export default {
  name: 'target-group-item',
  props: {
    // 当前目标数据
    target: {
      type: Object
    },
    // 层级
    layer: {
      type: Number,
      default: 0
    },
    // 当前是否是我
    isMe: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TargetGroupItem,
    draggable
  },
  data() {
    return {
      // 编辑的时候输入的任务名字
      tempName: '',
      // 是否正在编辑
      isEditTarget: false,
      // 是否展示出目标的更多菜单
      isShowTargetMore: false,
      // 是否要选择目标类型
      isShowTargetTypes: false,
      // 是否显示选择目标进度的弹窗
      isShowTargetProgress: false,
      // 当前的进度数据
      currentTargetProgress: 0,
      // 临时记录进度数据
      tempProgress: 0,
      // 当前目标是否延期
      currentTargetProgressStatus: 1,
      // 截止时间
      lastTime: '',
      // 快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 是否显示子目标
      isShowChild: false,
      // esc编辑是否启用
      escIsShow: false,
      escShowRef: '',
      currentInfo: {}
    }
  },
  computed: {
    renderWidth() {
      return this.screenWidth > 767 ? 20 : 0
    },
    // 解析标签
    infoLabel() {
      if (!this.target || !this.target.objective_id) {
        return {}
      }
      return this.parseLabel(
        this.target.objective_name,
        this.target.objective_label_list
      )
    },
    // 截止时间
    getLastTime() {
      return this.target.end_time && this.target.end_time.slice(0, 10)
    },
    // 编辑输入框ref
    editNameRef() {
      return `editTarget${this.target.objective_id}`
    },
    // 获取当前目标的类型对应的文字和颜色
    getTargetTypeInfo() {
      return function (type) {
        return this.targetTypeList.find((item) => type === item.value)
      }
    },
    // 是否延期
    isLate() {
      return this.target.progress_status === 2
    },
    // 超时数量
    timeoutCount() {
      if (!this.target.children) {
        return 0
      }
      let len = 0
      console.log(this.target.children)
      const count = (list) => {
        list.forEach((e) => {
          if (e.progress_status === 2) {
            len++
          }
          if (e.children) {
            count(e.children)
          }
        })
      }
      count(this.target.children)
      return len
    },
    ...mapState(['targetTypeList', 'myClerkId', 'screenWidth'])
  },
  inject: ['Parent'],
  mounted() {
    document.addEventListener('click', this.outClick)
    this.lastTime = this.target.end_date
  },
  beforeDestroy() {
    document.removeEventListener('click', this.outClick)
  },
  watch: {
    // 如果数据的end_date改变了，那么让input框里的值也跟着改变
    'target.end_date'() {
      this.lastTime = this.target.end_date
    },
    escIsShow(val) {
      if (val) {
        this.$refs[this.escShowRef].blur()
      }
    }
  },
  methods: {
    onMove(e) {
      if (!this.isMe) {
        return false
      }
      console.log(e)
      this.currentInfo = e
    },
    choose(e) {
      if (!this.isMe) {
        return
      }
      if (Object.keys(this.currentInfo).length === 0) {
        return
      }
      this.insertBefore()
      this.currentInfo = {}
    },
    // 插入到某目标之前
    async insertBefore() {
      const draggedContext = this.currentInfo.draggedContext
      const relatedContext = this.currentInfo.relatedContext
      const formObj = { objective_id: draggedContext.element.objective_id }
      // 拖拽后相对于列表位置
      const positionIndex = relatedContext.list.findIndex((e) => {
        return e.objective_id === draggedContext.element.objective_id
      })
      // 判断长度是否是最后一个
      if (positionIndex === relatedContext.list.length - 1) {
        if (relatedContext.element._LEVEL_ === 1) {
          formObj.group_id = relatedContext.element.group_id
        } else {
          formObj.pid = relatedContext.element.pid
        }
      } else {
        formObj.next_objective_id =
          relatedContext.list[positionIndex + 1].objective_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.trgetMove, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
      // 刷新任务
      this.Parent.getTargetList()
    },
    // 点击开始选择目标的类型
    toChooseTargetType(id) {
      // 如果当前操作的职员是我自己，那么就可以显示出这个白框
      if (!this.isMe) return
      if (this.Parent.currentShowType !== id) {
        this.Parent.showTargetModal(id)
        this.isShowTargetTypes = true
      } else {
        this.Parent.showTargetModal(-1)
        this.isShowTargetTypes = false
      }
    },
    // 点击选择目标类型
    async chooseTargetType(val) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetType, {
          objective_id: this.target.objective_id,
          objective_type: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '设置目标类型成功',
          type: 'success'
        })
        this.isShowTargetTypes = false
        this.$emit('to-fresh-target')
      }
    },
    // 让父级刷新
    toFresh() {
      this.$emit('to-fresh-target')
    },
    // 触发失焦事件
    toBlur(e, arg) {
      e.target.blur(arg)
    },
    // 点击开始设置目标进度
    toSetTargetProgress() {
      // 如果当前操作的职员是我自己，那么就可以显示出这个白框
      if (!this.isMe) return
      if (this.Parent.currentShowTargetProgress !== this.target.objective_id) {
        this.Parent.showTargetProgress(this.target.objective_id)
        this.isShowTargetProgress = true
        // 回填原有数据
        this.currentTargetProgress = this.target.progress_number
        this.tempProgress = this.target.progress_number
        this.currentTargetProgressStatus = this.target.progress_status
      } else {
        this.Parent.showTargetProgress(-1)
        this.isShowTargetProgress = false
      }
    },
    // 设置目标进度
    async readyToEditTargetProgress() {
      // 假如把里面的数字删干净了，视为没有修改
      if (this.currentTargetProgress.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentTargetProgress = this.tempProgress
        this.$refs.editTargetProgressRef.blur()
      } else if (
        Number(this.currentTargetProgress) < 0 ||
        Number(this.currentTargetProgress) > 100
      ) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentTargetProgress = this.tempProgress
        this.$refs.editTargetProgressRef.blur()
      } else {
        // 假如输入正确，那就发起修改请求
        this.$refs.editTargetProgressRef.blur()
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTargetProgressNumber, {
            objective_id: this.target.objective_id,
            progress_number: this.currentTargetProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '目标的进度修改成功！',
              showClose: true
            })
            // 刷新目标列表
            this.$emit('to-fresh-target')
          }
        }
      }
    },
    // 设置目标是否已延期
    async setTargetProgressStatus(val) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetProgressStatus, {
          objective_id: this.target.objective_id,
          progress_status: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          type: 'success',
          message: '目标的进度状态修改成功！',
          showClose: true
        })
        this.currentTargetProgressStatus = val
        // 刷新目标列表
        this.$emit('to-fresh-target')
      }
    },
    // 展示出目标的更多弹窗
    showMoreMenu() {
      this.isShowTargetMore = true
    },
    // 开始编辑
    beginToEditTarget() {
      // 让对应的输入框显示出来
      this.isEditTarget = true
      this.tempName = this.target.objective_name
      // 让输入框回填和聚焦
      this.$nextTick(() => {
        console.log(this.$refs)
        if (this.$refs[this.editNameRef]) {
          this.$refs[this.editNameRef].focus()
        }
      })
    },
    // 处理编辑目标名字
    async handleEditTarget() {
      if (this.tempName === this.target.objective_name) {
        // 如果字没有改变，直接关闭，不发请求
        this.cancelEditTarget()
        this.escIsShow = false
        return
      }
      if (this.escIsShow) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.cancelEditTarget()
          this.escIsShow = false
          return
        }
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetName, {
          objective_id: this.target.objective_id,
          objective_name: this.tempName
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '目标名字修改成功',
          type: 'success'
        })
        this.cancelEditTarget()
        this.$emit('to-fresh-target')
      }
      this.escIsShow = false
    },
    // 取消编辑目标名字
    cancelEditTarget() {
      this.isEditTarget = false
      this.tempName = ''
    },
    // 删除目标
    deleteTarget(target) {
      this.isShowTargetMore = false
      this.$util.showConfirm('确定要删除这个目标吗？').then(async (result) => {
        if (result) {
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.deleteTarget, {
              objective_id: target.objective_id
            })
          )
          if (err) {
            console.log(err)
          } else if (res.state === 'ok') {
            this.$message({
              showClose: true,
              message: '目标删除成功',
              type: 'success'
            })
            this.$emit('to-fresh-target')
          }
        }
      })
    },
    // 准备选择关联
    toChooseRelative(target) {
      if (this.isMe) {
        this.$emit('to-choose-relative', target)
      }
    },
    // 点击复选框
    async setTargetStatus(id) {
      if (!this.isMe) return
      let val
      if (
        this.target.objective_status === 3 ||
        this.target.objective_status === 4
      ) {
        val = 2
      } else {
        val = 3
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTargetStatus, {
          objective_id: id,
          objective_status: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.$message({
        //   showClose: true,
        //   message: '设置目标状态成功',
        //   type: 'success'
        // })
        // 修改成功后不刷新，而是直接改值
        this.target.objective_status = val
        // 如果是选择“已完成”
        if (val === 3) {
          // 发请求把进度值也修改为100%
          const res = await this.$http.post(
            this.$api.editTargetProgressNumber,
            {
              objective_id: id,
              progress_number: 100
            }
          )
          if (res.state === 'ok') {
            this.target.progress_number = 100
          }
        }
        // this.$emit('to-fresh-target')
      }
    },
    // 去详情
    toTargetDetail(id) {
      this.$emit('to-target-detail', id)
    },
    // 设置截止时间
    async setLastTime() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetEndTime, {
          objective_id: this.target.objective_id,
          end_time: this.lastTime
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '截止时间修改成功！',
          type: 'success'
        })
      }
    },
    // 展开收起子元素
    toggleChildren() {
      this.isShowChild = !this.isShowChild
    },
    // 点到外面去
    outClick() {
      this.isShowTargetMore = false
      this.isShowTargetTypes = false
      this.isShowTargetProgress = false
    },
    // 开始移动
    toMoveItem() {
      this.Parent.showMoveTarget(this.target.objective_id)
    },
    // 空函数
    empty() {},
    // 解析标签
    parseLabel(name, labelList) {
      const labelMap = {}
      labelList.forEach((e) => {
        labelMap[e.label_name] = e.label_id
      })
      let showName = ''
      const list = []
      let status = 0
      let gth = 0
      let item = ''
      name.split('').forEach((e) => {
        let te = e
        // 处理下中文符号
        switch (te) {
          case '！': {
            te = '!'
          }
        }
        switch (status) {
          // 开始
          case 0: {
            switch (te) {
              case '!': {
                if (gth === 0) {
                  gth++
                  status = 1
                } else {
                  showName += e
                  status = 11
                }
                break
              }
              case ' ': {
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                showName += e
                status = 11
                break
              }
            }
            break
          }
          case 1: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                status = 0
                showName += e
                break
              }
            }
            break
          }
          case 2: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              default: {
                status = 11
                showName += e
                break
              }
            }
            break
          }
          case 10: {
            switch (te) {
              case '!': {
                if (item !== '') {
                  list.push(item)
                } else {
                  showName += '#'
                }
                item = ''
                if (gth === 0) {
                  gth++
                  status = 2
                } else {
                  status = 11
                }
                break
              }
              case ' ': {
                if (item !== '') {
                  list.push(item)
                }
                item = ''
                status = 0
                break
              }
              default: {
                item += e
                break
              }
            }
            break
          }
          // 直接加
          case 11: {
            showName += e
            break
          }
        }
      })
      if (status === 10) {
        if (item.length > 0) {
          list.push(item)
        }
      }
      let gthStr = ''
      for (let i = 0; i < gth; i++) {
        gthStr += '!'
      }
      return {
        showName,
        list: list.map((e) => {
          return {
            id: labelMap[e] || '',
            name: e
          }
        }),
        gth,
        gthStr
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/home_target_style.scss';
// .target-item.target-group-item {
//   margin-left: 88px;
// }
.target-group-item .target-item-td.target-name-box {
  position: relative;
  box-sizing: border-box;
  width: 56%;
}
.target-group-item .target-item-td.name-box {
  padding-left: 0;
}

// 目标的更多菜单区域
.more-menu-box {
  @include pos-middle(absolute);
  z-index: 2;
  right: 0;
  width: 20px;
  height: 20px;
  // 更多按钮
  .target-more {
    display: none;
    font-size: 20px;
    line-height: 1;
    color: #999;
  }
  // 目标的更多菜单
  .target-more-menu {
    position: absolute;
    top: 30px;
    left: 0;
    width: 140px;
    background: #fff;
    box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
    border-radius: 4px;
    .target-more-item {
      line-height: 40px;
      padding: 0 18px;
      font-size: 14px;
      color: #333;
      &.delete {
        color: $red;
      }
      &:hover {
        background: $light-grey-bg;
      }
    }
  }
}

// 悬浮出现更多按钮
.target-group-item:hover .target-more {
  display: block;
}
.target-item-td {
  position: relative;
}

.target-item-td .target-types {
  top: 45px;
}

.target-item:hover {
  background: #fff;
}

.target-item:hover .target-name-info p {
  color: $main-color;
}

// 名称和子目标完成情况的外层
.target-name-info {
  max-width: 100%;
  width: min-content;
  > p {
    font-size: 14px;
    line-height: 1.5;
    // color: #333;
  }
  // 目标名称本身
  .target-name-text {
    flex: 1;
  }
  // 子目标的完成情况
  .child-info {
    margin-left: 5px;
  }
}
.target-name {
  width: calc(100% - 28px - 40px);
}
</style>
<style lang="scss" scoped>
.chosen {
  background-color: #f6f6f6 !important;
}
.move-target-rank {
  width: 20px;
  position: absolute;
  padding-top: 0;
  left: 0;
  i {
    font-size: 14px;
    padding: 0 3px !important;
  }
}
.target-item {
  position: relative;
}
/* 选择目标类型的小弹窗 */
.target-types .target-type-item .target-type-box {
  margin: 0 auto;
}

/* 设置进度的小弹窗 */
.target-item-td .task-process {
  left: -20px;
  top: 100%;
  text-align: left;
}

.target-item-td .progress {
  width: 45px !important;
}

.target-item-td ::v-deep .el-date-editor .el-input__inner {
  text-align: center;
  padding: 0;
}

.time-box.is-late ::v-deep .el-date-editor .el-input__inner {
  color: $red;
}

.target-item-td ::v-deep .el-input__icon {
  display: none;
}

.last-time-text {
  font-size: 13px;
  &.is-late {
    color: $red;
  }
}

@media screen and (max-width: 768px) {
  .target-item {
    min-height: 48px;
    height: auto;

    .target-name-box {
      .target-name {
        &.e {
          text-overflow: inherit !important;
          overflow: inherit !important;
          white-space: pre-line !important;
        }
      }
    }
  }

  .target-group-item {
    margin-left: 0;

    .target-item-td.target-name-box {
      flex: 1;
    }
  }
}
.time-box ::v-deep {
  .el-input--prefix .el-input__inner {
    border: 1px solid #fff;
  }
}
.time-box ::v-deep {
  .el-input--prefix .el-input__inner {
    border: 1px solid #fff;
  }
  .el-input--prefix .el-input__inner:hover,
  .el-input--prefix .el-input__inner:focus {
    border-color: $main-color;
  }
}
.target-name.end_over {
  text-decoration: line-through;
  color: orange !important;
}

// 标签
.target-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: min-content;
  padding: 0 3px;
  white-space: nowrap;
  line-height: 21px;
  color: #2b6cff;
  background: #e2ebff;
  border-radius: 3px;
  font-size: 12px;
}
</style>
