<template>
  <div>
    <message-dialog visible @close="$emit('cancel')" @click="clickBody()">
      <template #header>
        <div class="dialog-title">文件夹共享设置</div>
      </template>
      <template #content>
        <div class="flex">
          <div
            class="flex-shrink"
            style="width: 70px; margin-right: 16px; line-height: 38px"
          >
            共享类型
          </div>
          <div>
            <share-select
              @change="changeShareVisible = true"
              :active="isTeamShare ? 0 : 1"
            ></share-select>
            <p style="margin-top: 12px" v-if="isTeamShare">
              团队成员都能查看此文件夹及其子文件，只有特定的成员能够编辑。成员需要有团队文件的编辑权限，才能编辑当前文件夹及其子文件。
            </p>
            <p style="margin-top: 12px" v-if="!isTeamShare">
              只有指定的团队成员都能查看此文件夹及其子文件，其他团队成员不可访问，且只有特定的成员能够编辑。成员需要有团队文件的编辑权限，才能编辑当前文件夹及其子文件。
            </p>
          </div>
        </div>
        <div
          class="flex flex-x-between"
          style="padding-top: 20px; margin-bottom: 16px"
        >
          <span>团队成员</span>
          <span
            class="add-button"
            v-show="!isTeamShare"
            @click="addMemberVisible = true"
            >添加成员</span
          >
        </div>
        <div class="list-container">
          <el-input
            prefix-icon="el-icon-search"
            placeholder="请输入团队成员名称搜索"
            v-if="false"
          ></el-input>
          <div style="width: 100%">
            <el-autocomplete
              popper-class="search-input"
              size="medium"
              v-model="searchClerkName"
              :fetch-suggestions="querySearch"
              placeholder="请输入姓名/别名"
              @select="handleSelect"
              style="width: 100%"
            >
              <i class="el-icon-search el-input__icon" slot="prefix"> </i>
              <template #default="{ item }">
                <div class="flex flex-y-center result-item">
                  <el-image :src="item.clerk_avatar">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <span class="name">{{
                    item.clerk_alias || item.clerk_name
                  }}</span>
                  <span class="type">{{ item.main_dept_name }}</span>
                </div>
              </template>
            </el-autocomplete>
          </div>
          <div class="list-content">
            <structure-tree
              :data-list="dataList"
              :menuList="menuList"
              :skipDeleteDeptIdList="skipDeleteDeptIdList"
              :skipDeleteTopDeptIdList="skipDeleteTopDeptIdList"
              :skipDeleteClerkIdList="skipDeleteClerkIdList"
              :adminClerkIdList="adminClerkIdList"
              :searchClerkIdList="searchClerkIdList"
              :isShare="isTeamShare"
              @delete="handleDelete"
              @change="handleChange"
            ></structure-tree>
          </div>
        </div>
      </template>
    </message-dialog>
    <add-member-and-dept
      @close-add-member="addMemberVisible = false"
      @confirm-add-member="chooseMember"
      :isShowAddMember="addMemberVisible"
      :dataList="clerkTree"
      :disableClerkIdList="
        clerkList.map((e) => e.clerk_id).concat(adminClerkIdList)
      "
      :disableDeptIdList="deptList.map((e) => e.dept_id)"
    ></add-member-and-dept>
    <add-member-and-dept
      @close-add-member="firstAddMemberVisible = false"
      @confirm-add-member="firstChooseMember"
      :isShowAddMember="firstAddMemberVisible"
      :dataList="clerkTree"
      :disableClerkIdList="adminClerkIdList"
    ></add-member-and-dept>
    <!-- 切换共享弹窗 -->
    <el-dialog :visible.sync="changeShareVisible" width="30%">
      <template #title>
        <span style="color: red; font-size: 20px"
          ><i class="el-icon-warning-outline"></i> 提示
        </span>
      </template>
      <span v-show="isTeamShare"
        >变更为“不共享”权限后，除管理员外，成员需要另行添加，且子级文件夹为“不共享”权限时，需要重新配置权限成员，请确认是否变更权限设置。</span
      >
      <span v-show="!isTeamShare"
        >变更为“共享”权限后，将继承上级文件夹可查看人员及权限，清除已配置的不共享权限，请确认是否变更权限设置。</span
      >
      <template #footer>
        <el-button @click="changeShareVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            changeShareVisible = false
            editTeamShare()
          "
          >确 定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import AddMemberAndDept from '@/components/common/AddMemberAndDept.vue'
import MessageDialog from '@/components/circle/MessageDialog.vue'
import StructureTree from './StructureTree.vue'
import ShareSelect from './ShareSelect.vue'

import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    MessageDialog,
    StructureTree,
    ShareSelect,
    AddMemberAndDept
  },
  props: {
    folderId: {
      type: Number
    }
  },
  data() {
    return {
      dataList: [],
      info: {},
      // 禁止职员id列表
      disableClerkIdList: [],
      // 职员信息
      clerkList: [],
      // 部门信息
      deptList: [],
      // 添加展示
      addMemberVisible: false,
      // 职员数据
      clerkTree: [],
      // 是否共享
      isTeamShare: false,
      // 改变共享
      changeShareVisible: false,
      // 团队部门
      teamDept: {},
      // 团队职员
      teamClerk: {},
      // 跳过删除
      skipDeleteDeptIdList: [],
      skipDeleteTopDeptIdList: [],
      skipDeleteClerkIdList: [],
      // 管理员职员
      adminClerkList: [],
      // 搜索职员名称
      searchClerkName: '',
      searchClerkInfo: {},
      // 共享改不共享首次添加展示
      firstAddMemberVisible: false
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    adminClerkIdList() {
      return this.adminClerkList.map((e) => e.clerk_id)
    },
    isAdmin() {
      return this.adminClerkIdList.includes(this.myClerkId)
    },
    clerkIdList() {
      return this.clerkList.map((e) => e.clerk_id) || []
    },
    clerkMap() {
      const map = {}
      this.clerkList.forEach((e) => {
        map[e.clerk_id] = e
      })
      return map
    },
    deptIdList() {
      return this.deptList.map((e) => e.dept_id) || []
    },
    deptMap() {
      const map = {}
      this.deptList.forEach((e) => {
        map[e.dept_id] = e
      })
      return map
    },
    menuList() {
      return [
        {
          title: '管理员',
          info: '可修改成员权限及编辑文件',
          value: 4,
          clerk_disable: !this.isAdmin,
          dept_disable: true
        },
        {
          title: '仅查看',
          info: '可查看、不可下载文件',
          value: 1
        },
        {
          title: '可下载',
          info: '可查看、下载文件',
          value: 2
        },
        {
          title: '可编辑',
          info: '可编辑、更新、查看、下载文件',
          value: 3
        }
      ]
    },
    menuIndexMap() {
      const map = {}
      this.menuList.forEach((e, i) => {
        map[e.value] = i
      })
      return map
    },
    searchClerkId() {
      if (this.searchClerkInfo.clerk_id) {
        return this.searchClerkInfo.clerk_id
      }
      return 0
    },
    searchClerkIdList() {
      if (this.searchClerkId !== 0) {
        return [this.searchClerkId]
      }
      return []
    },
    allClerkList() {
      const list = []
      const idList = []
      // 管理员
      this.adminClerkList.forEach((e) => {
        idList.push(e.clerk_id)
        list.push({
          clerk_id: e.clerk_id,
          clerk_name: e.clerk_name,
          clerk_status: e.clerk_status,
          clerk_avatar: e.clerk_avatar,
          clerk_alias: e.clerk_alias,
          clerk_mobile: e.clerk_mobile,
          clerk_name_py: e.clerk_name_py,
          clerk_alias_py: e.clerk_alias_py,
          clerk_type: e.clerk_type
        })
      })
      // 职员
      this.clerkList.forEach((e) => {
        if (!idList.includes(e.clerk_id)) {
          idList.push(e.clerk_id)
          list.push({
            clerk_id: e.clerk_id,
            clerk_name: e.clerk_name,
            clerk_status: e.clerk_status,
            clerk_avatar: e.clerk_avatar,
            clerk_alias: e.clerk_alias,
            clerk_mobile: e.clerk_mobile,
            clerk_name_py: e.clerk_name_py,
            clerk_alias_py: e.clerk_alias_py,
            clerk_type: e.clerk_type
          })
        }
      })

      const eachDept = (dept) => {
        dept.clerk_list.forEach((e) => {
          if (!idList.includes(e.clerk_id)) {
            idList.push(e.clerk_id)
            list.push({
              clerk_id: e.clerk_id,
              clerk_name: e.clerk_name,
              clerk_status: e.clerk_status,
              clerk_avatar: e.clerk_avatar,
              clerk_alias: e.clerk_alias,
              clerk_mobile: e.clerk_mobile,
              clerk_name_py: e.clerk_name_py,
              clerk_alias_py: e.clerk_alias_py,
              clerk_type: e.clerk_type
            })
          }
        })
        if (dept.children) {
          dept.children.forEach((e) => {
            eachDept(e)
          })
        }
      }
      this.deptList.forEach((e) => {
        eachDept(e)
      })
      return list
    }
  },
  methods: {
    ...mapMutations(['clickBody']),
    // 渲染data列表
    renderDataList() {
      let list = []
      // 管理员
      if (this.teamDept.dept_id) {
        list = list.concat(
          this.adminClerkList.map((e) => {
            return {
              id: `c_${e.clerk_id}`,
              type: 1,
              active: this.menuIndexMap[1],
              per_type: 1,
              clerk_id: e.clerk_id,
              clerk_name: e.clerk_name,
              clerk_avatar: e.clerk_avatar,
              clerk_alias: e.clerk_alias
            }
          })
        )
      }
      // 职员
      list = list.concat(
        this.clerkList
          .filter((e) => {
            return !this.adminClerkIdList.includes(e.clerk_id)
          })
          .map((e) => {
            return {
              id: `c_${e.clerk_id}`,
              type: 1,
              active: this.menuIndexMap[e.per_type],
              per_type: e.per_type,
              clerk_id: e.clerk_id,
              clerk_name: e.clerk_name,
              clerk_avatar: e.clerk_avatar,
              clerk_alias: e.clerk_alias
            }
          })
      )
      // 部门
      // 获取子级列表
      const getDeptChildren = (e) => {
        let clist = []
        // 获取职员列表
        const getClerkList = (clerkList, perType) => {
          return clerkList
            .filter((fe) => !this.disableClerkIdList.includes(fe.clerk_id))
            .map((cce) => {
              const info = {
                id: `dc_${cce.clerk_id}`,
                type: 1,
                clerk_id: cce.clerk_id,
                clerk_name: cce.clerk_name,
                clerk_avatar: cce.clerk_avatar,
                clerk_alias: cce.clerk_alias
              }
              // 有独立设置的读取独立设置
              if (this.clerkMap[cce.clerk_id] != null) {
                info.active =
                  this.menuIndexMap[this.clerkMap[cce.clerk_id].per_type]
                info.per_type = this.clerkMap[cce.clerk_id].per_type
              } else {
                info.active = this.menuIndexMap[perType]
                info.per_type = perType
              }
              return info
            })
        }
        // 部门下的职员
        if (e.clerk_list) {
          clist = clist.concat(getClerkList(e.clerk_list, e.per_type))
        }
        // 有下级部门的
        if (e.children) {
          const getChildrenDeptLoop = (pid, perType) => {
            const dlist = e.children
              .filter((fe) => fe.pid === pid)
              .map((fe) => {
                const info = {
                  id: `d_${fe.dept_id}`,
                  type: 2,
                  dept_id: fe.dept_id,
                  dept_name: fe.dept_name
                }
                // 有独立设置的读取独立设置
                if (this.deptMap[fe.dept_id] != null) {
                  info.active =
                    this.menuIndexMap[this.deptMap[fe.dept_id].per_type]
                  info.per_type = this.deptMap[fe.dept_id].per_type
                } else {
                  info.active = this.menuIndexMap[perType]
                  info.per_type = perType
                }
                // 下属
                let cclist = []
                if (fe.clerk_list) {
                  cclist = cclist.concat(
                    getClerkList(fe.clerk_list, info.per_type)
                  )
                }
                if (e.children.findIndex((e) => e.pid === fe.dept_id) !== -1) {
                  cclist = cclist.concat(
                    getChildrenDeptLoop(fe.dept_id, info.per_type)
                  )
                }
                if (cclist.length > 0) {
                  info.children = cclist
                }
                return info
              })
            return dlist
          }
          clist = clist.concat(getChildrenDeptLoop(e.dept_id, e.per_type))
        }
        return clist
      }
      list = list.concat(
        this.deptList.map((e) => {
          const info = {
            id: `d_${e.dept_id}`,
            type: 2,
            active: this.menuIndexMap[e.per_type],
            per_type: e.per_type,
            dept_id: e.dept_id,
            dept_name: e.dept_name
          }
          const clist = getDeptChildren(e)
          if (clist.length > 0) {
            info.children = clist
          }
          return info
        })
      )
      this.$set(this, 'dataList', list)
    },
    // 渲染职员列表
    renderClerkTree(
      clerkList,
      deptList,
      disableClerkIdList,
      disableDeptIdList
    ) {
      const list = []

      // 处理后的部门列表
      const deptListChecked = []
      // 处理下部门
      const checkDept = (dept) => {
        // 排除禁用的
        if (disableDeptIdList.includes(dept.dept_id)) {
          return
        }
        for (let i = 0; i < deptListChecked.length; i++) {
          if (dept.dept_id === deptListChecked[i].dept_id) {
            return
          }
        }
        deptListChecked.push({
          dept_id: dept.dept_id,
          dept_name: dept.dept_name,
          dept_name_en: '',
          pid: dept.pid,
          clerk_list: dept.clerk_list.filter((c) => {
            return !disableClerkIdList.includes(c.clerk_id)
          })
        })
      }
      deptList.forEach((d) => {
        checkDept(d)
        if (d.children) {
          d.children.forEach((cd) => {
            checkDept(cd)
          })
        }
      })

      console.log(deptListChecked)
      // 头id
      const topDeptIdList = []

      deptListChecked.forEach((d) => {
        let flag = true
        for (let i = 0; i < deptListChecked.length; i++) {
          if (d.pid === deptListChecked[i].dept_id) {
            flag = false
            break
          }
        }
        if (flag) {
          topDeptIdList.push(d.dept_id)
        }
      })

      console.log(topDeptIdList)

      // 转树
      const toTree = (id) => {
        return deptListChecked
          .filter((d) => {
            return d.pid === id
          })
          .map((e) => {
            const childrenList = toTree(e.dept_id)
            if (childrenList && childrenList.length > 0) {
              return { ...e, children: childrenList }
            }
            return e
          })
      }

      topDeptIdList.forEach((id) => {
        const info = deptListChecked.find((d) => {
          return d.dept_id === id
        })
        const childrenList = toTree(id)
        if (childrenList && childrenList.length > 0) {
          info.children = childrenList
        }
        list.push(info)
      })

      console.log(list)

      this.$set(this, 'clerkTree', list)
    },
    // 获取详情
    async getDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlFolderTeamPerDetail, {
          folder_id: this.folderId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.isTeamShare = res.is_team_share === 1
        this.$set(this, 'clerkList', res.clerk_list)
        this.$set(this, 'deptList', res.dept_list)
        this.$set(this, 'disableClerkIdList', res.disable_clerk_id_list)
        this.teamDept = res.team_dept || {}
        this.teamClerk = res.team_clerk || {}
        this.adminClerkList = res.admin_clerk_list
        if (this.teamDept.dept_id) {
          // this.skipDeleteDeptIdList = [this.teamDept.dept_id]
          // this.skipDeleteTopDeptIdList = [this.teamDept.dept_id]
        }
        if (this.teamClerk.clerk_id) {
          this.skipDeleteClerkIdList = [this.teamClerk.clerk_id]
        }
        this.renderDataList()
        this.renderClerkTree(
          res.parent_clerk_list,
          res.parent_dept_list,
          res.parent_disable_clerk_id_list,
          res.parent_disable_dept_id_list
        )
      }
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        console.log(res)
        this.clerkTree = res
      })
    },
    changeTeamShare(index) {
      if (this.isTeamShare === (index === 0)) {
        return
      }
      this.changeShareVisible = true
    },
    // 修改共享状态
    async editTeamShare() {
      // 共享改不共享
      if (!this.isTeamShare) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editKnowlFolderTeamShareStatus, {
            folder_id: this.folderId,
            is_team_share: 1
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.isTeamShare = !this.isTeamShare
          this.getDetail()
        }
      } else {
        // 不共享改共享
        this.firstAddMemberVisible = true
      }
    },
    // 选择职员
    async chooseMember({ clerkIdList, deptIdList }) {
      // 职员
      // const clerkList = this.clerkList.map((e) => {
      //   return {
      //     clerk_id: e.clerk_id,
      //     per_type: e.per_type
      //   }
      // })
      // clerkIdList.forEach((e) => {
      //   if (!this.clerkIdList.includes(e)) {
      //     clerkList.push({
      //       clerk_id: e,
      //       per_type: 1
      //     })
      //   }
      //   if (this.disableClerkIdList.includes(e)) {
      //     this.disableClerkIdList.splice(this.disableClerkIdList.indexOf(e, 1))
      //   }
      // })

      // // 部门
      // const deptList = this.deptList.map((e) => {
      //   return {
      //     dept_id: e.dept_id,
      //     per_type: e.per_type
      //   }
      // })
      // deptIdList.forEach((e) => {
      //   if (!this.deptIdList.includes(e)) {
      //     deptList.push({
      //       dept_id: e,
      //       per_type: 1
      //     })
      //   }
      // })
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addKnowlFolderTeamPer, {
          folder_id: this.folderId,
          clerk_ids: clerkIdList.join(),
          dept_ids: deptIdList.join()
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // // 职员信息
        // this.clerkList = []
        // // 部门信息
        // this.deptList = []
        await this.getDetail()
        this.$forceUpdate()
      }
      this.addMemberVisible = false
    },
    // 删除
    async handleDelete({ info, disable }) {
      // 职员
      // const clerkList = this.clerkList
      //   .filter((e) => {
      //     if (info.type === 1 && e.clerk_id === info.clerk_id) {
      //       return false
      //     }
      //     return true
      //   })
      //   .map((e) => {
      //     return {
      //       clerk_id: e.clerk_id,
      //       per_type: e.per_type
      //     }
      //   })

      // if (info.type === 1 && !this.disableClerkIdList.includes(info.clerk_id)) {
      //   this.disableClerkIdList.push(info.clerk_id)
      // }

      // // 部门
      // const deptList = this.deptList
      //   .filter((e) => {
      //     if (info.type === 2 && e.dept_id === info.dept_id) {
      //       return false
      //     }
      //     return true
      //   })
      //   .map((e) => {
      //     return {
      //       dept_id: e.dept_id,
      //       per_type: e.per_type
      //     }
      //   })

      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteKnowlFolderTeamPer, {
          folder_id: this.folderId,
          clerk_ids: info.type === 1 ? info.clerk_id : '',
          dept_ids: info.type === 2 ? info.dept_id : '',
          disable: disable ? '1' : ''
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // // 职员信息
        // this.clerkList = []
        // // 部门信息
        // this.deptList = []
        await this.getDetail()
        this.$forceUpdate()
      }
    },
    // 更新
    async handleChange({ info, index }) {
      // 职员
      // const clerkList = this.clerkList.map((e) => {
      //   const cinfo = { clerk_id: e.clerk_id }
      //   if (info.type === 1 && e.clerk_id === info.clerk_id) {
      //     cinfo.per_type = this.menuList[index].value
      //   } else {
      //     cinfo.per_type = e.per_type
      //   }
      //   return cinfo
      // })
      // // 新增单独职员
      // if (info.type === 1 && this.clerkMap[info.clerk_id] == null) {
      //   clerkList.push({
      //     clerk_id: info.clerk_id,
      //     per_type: this.menuList[index].value
      //   })
      // }
      // // 部门
      // const deptList = this.deptList.map((e) => {
      //   const cinfo = { dept_id: e.dept_id }
      //   if (info.type === 2 && e.dept_id === info.dept_id) {
      //     cinfo.per_type = this.menuList[index].value
      //   } else {
      //     cinfo.per_type = e.per_type
      //   }
      //   return cinfo
      // })
      // // 新增单独部门
      // if (info.type === 2 && this.deptMap[info.dept_id] == null) {
      //   deptList.push({
      //     dept_id: info.dept_id,
      //     per_type: this.menuList[index].value
      //   })
      // }
      const clerkList = []
      const deptList = []
      if (info.type === 1) {
        clerkList.push({
          clerk_id: info.clerk_id,
          per_type: this.menuList[index].value
        })
      }
      if (info.type === 2) {
        deptList.push({
          dept_id: info.dept_id,
          per_type: this.menuList[index].value
        })
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlFolderTeamPer, {
          folder_id: this.folderId,
          clerk_list: JSON.stringify(clerkList),
          dept_list: JSON.stringify(deptList)
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // this.clerkList = []
        // this.deptList = []
        await this.getDetail()
        this.$forceUpdate()
      }
    },
    async querySearch(quertString, cb) {
      if (quertString === '') {
        const empty = []
        cb(empty)
        this.searchClerkInfo = {}
        return
      }

      // const [, res] = await this.$util.to(
      //   this.$http.post(this.$api.searchKnowlFolderTeamPerClerk, {
      //     folder_id: this.folderId,
      //     search_key: quertString
      //   })
      // )
      cb(
        this.allClerkList.filter((e) => {
          if (e?.clerk_name?.indexOf(quertString) !== -1) {
            return true
          }
          console.log(e.clerk_name)
          console.log(e?.clerk_name_py?.indexOf(quertString))
          if (e?.clerk_name_py?.indexOf(quertString) !== -1) {
            return true
          }
          if (e?.clerk_alias?.indexOf(quertString) !== -1) {
            return true
          }
          if (e?.clerk_alias_py?.indexOf(quertString) !== -1) {
            return true
          }
          return false
        })
      )
    },
    // 搜索监听
    handleSelect(item) {
      this.searchClerkName = item.clerk_alias || item.clerk_name
      this.searchClerkInfo = item
    },
    // 共享改不共享选择职员
    async firstChooseMember({ clerkIdList, deptIdList }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlFolderTeamShareStatus, {
          folder_id: this.folderId,
          is_team_share: 0,
          first_clerk_ids: clerkIdList.join(),
          first_dept_ids: deptIdList.join()
        })
      )

      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // // 职员信息
        // this.clerkList = []
        // // 部门信息
        // this.deptList = []
        this.firstAddMemberVisible = false
        this.isTeamShare = !this.isTeamShare
        await this.getDetail()
      }
      this.addMemberVisible = false
    }
  },
  mounted() {
    this.getDetail()
    // this.getClerkTree()
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.dialog-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.dialog-sub-title {
  color: #bbb;
}

.add-button {
  cursor: pointer;
  color: $main-color;
}

.list-container {
  background-color: #f6f6f6;
  border-radius: 6px;
  height: calc(100vh - 320px);
  margin-bottom: 20px;
  padding: 18px 24px 0;

  .list-content {
    height: calc(100% - 40px);
    overflow: scroll;
  }

  .el-input {
    margin-bottom: 8px;
  }
}

.el-button {
  width: 84px;
}

.result-item {
  height: 55px;
  .el-image {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    margin-right: 16px;
  }

  .name {
    margin-right: 20px;
  }

  .type {
    color: #bbb;
  }
}
</style>
