<template>
  <message-box
    title="设置上级"
    top="12%"
    :mask="true"
    @handleClose="$emit('handleClose')"
  >
    <div class="text-warp set-explain">
      设置当前部门上级的上级目标关联对象，可跨组织架构设置；若当前部门无上级时，部门成员可将目标关联至该上级对象。
    </div>

    <!-- 当前部门上级 -->
    <div class="now-title">
      当前部门上级<span v-if="deptInfo && deptInfo.dept_name"
        >（{{ deptInfo.dept_name || '' }}）</span
      >
    </div>
    <div class="now-superior flex flex-y-center">
      {{ dptLeaderNameList.join('; ') }}
    </div>

    <!-- 添加上级 -->
    <div class="add-title">添加上级</div>
    <el-autocomplete
      popper-class="my-autocomplete"
      v-model="clerkName"
      :fetch-suggestions="querySearch"
      placeholder="输入姓名/别名"
      prefix-icon="el-icon-search"
      :disabled="false"
      @select="handleSelect"
    >
      <i
        class="el-icon-close el-input__icon"
        slot="suffix"
        @click="clearClerkName"
        v-if="clerkName !== '' && Object.keys(this.myClerkInfo).length !== 0"
      >
      </i>
      <template slot-scope="{ item }">
        <div class="flex flex-y-center result-item">
          <el-image :src="item.clerk_avatar">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <span class="name">{{ item.clerk_alias || item.clerk_name }}</span>
          <span class="type">{{ item.main_dept_name }}</span>
        </div>
      </template>
      <!-- <template slot="append">
        <i @click="addClerkLeader" class="el-icon-plus"></i>
      </template> -->
      <template slot="append">
        <el-button
          :disabled="
            clerkName === '' || Object.keys(this.myClerkInfo).length === 0
          "
          :class="
            clerkName === '' || Object.keys(this.myClerkInfo).length === 0
              ? 'disable-color'
              : 'open-color'
          "
          @click="addClerkLeader"
          icon="el-icon-plus"
        ></el-button>
      </template>
    </el-autocomplete>

    <!-- 表格 -->
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th style="width: 20%">姓名</th>
            <th>部门</th>
            <th style="width: 20%">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in deptList" :key="index">
            <td>{{ item.clerk_alias || item.clerk_name }}</td>
            <td>{{ item.dept_name }}</td>
            <td>
              <div class="delete-button" @click="removeClerk(index)">
                <i class="el-icon-delete"></i>
                <span>移除</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <div class="box-footer flex flex-x-end" style="padding: 20px 0">
        <el-button size="small" @click="$emit('handleClose')">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </div>
    </template>
  </message-box>
</template>

<script>
import MessageBox from '@/components/common/MessageBox.vue'
export default {
  components: {
    MessageBox
  },
  props: {
    deptId: {
      type: Number,
      default: -1 // 部门 dept，员工 people
    }
  },
  data() {
    return {
      dataList: [],
      clerkName: '',
      leaderList: [],
      deptList: [],
      myClerkInfo: {},
      deptInfo: {}
    }
  },
  mounted() {
    console.log(this.deptId)
    this.inint()
  },
  computed: {
    // 上级名称
    dptLeaderNameList() {
      if (!this.leaderList) {
        return []
      }
      return this.leaderList.map((e) => e.clerk_alias || e.clerk_name)
    }
  },
  methods: {
    async querySearch(quertString, cb) {
      if (quertString === '') {
        const empty = []
        cb(empty)
        return
      }
      const list = await this.$util.searchClerkList(quertString)
      cb(list)
    },
    // 初始化
    inint() {
      this.getLeaderList()
      this.getDeptList()
    },
    // 获取领导列表
    async getLeaderList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemDeptLeaderList, {
          dept_id: this.deptId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.leaderList = res.list
        this.deptInfo = res.info
      }
    },
    // 获取添加的上级人员列表
    async getDeptList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemDeptList, {
          dept_id: this.deptId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.deptList = res.list
      }
    },
    handleSelect(item) {
      const index = this.leaderList.findIndex((e) => {
        return e.clerk_id === item.clerk_id
      })
      if (index !== -1) {
        this.$message.error('不能添加当前部门上级')
        return
      }
      const ste = this.deptList.findIndex((e) => {
        return e.clerk_id === item.clerk_id
      })
      if (ste !== -1) {
        this.$message.error('添加的上级已存在')
        return
      }
      this.clerkName = item.clerk_alias || item.clerk_name
      this.myClerkInfo = item
    },
    // 保存
    async confirm() {
      const formObj = {
        dept_id: this.deptId
      }
      if (this.deptList.length !== 0) {
        const idList = []
        this.deptList.forEach((e) => {
          idList.push(e.clerk_id)
        })
        formObj.clerk_id_list = idList.join(',')
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addSystemLeader, formObj)
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '保存成功',
          type: 'success'
        })
        this.$emit('handleClose')
      }
    },
    // 清除选择的人员
    clearClerkName() {
      this.clerkName = ''
      this.myClerkInfo = {}
    },
    // 添加人员
    addClerkLeader() {
      console.log(this.myClerkInfo)
      if (this.clerkName === '' || Object.keys(this.myClerkInfo).length === 0) {
        this.$message.error('请选择职员')
        return
      }
      const index = this.deptList.findIndex((e) => {
        return e.clerk_id === this.myClerkInfo.clerk_id
      })
      if (index !== -1) {
        this.$message.error('添加的上级已存在')
        return
      }
      this.myClerkInfo.dept_name = this.myClerkInfo.main_dept_name
      this.deptList = this.deptList.concat(this.myClerkInfo)
      this.clearClerkName()
    },
    // 移除人员
    removeClerk(index) {
      this.deptList.splice(index, 1)
    }
  },
  watch: {
    deptId(val) {
      if (val !== -1) {
        this.inint()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.set-explain {
  color: #999999;
  font-size: 14px;
  margin-top: 24px;
}
.el-icon-close {
  cursor: pointer;
}

.now-title {
  font-size: 14px;
  color: #333333;
  margin-top: 23px;
  margin-bottom: 10px;
  span {
    color: #999999;
  }
}

.now-superior {
  background-color: #f6f6f6;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
}

.add-title {
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}

.el-autocomplete::v-deep {
  width: 100%;
  .el-button {
    border-radius: 0;
  }
  .el-input-group__append {
    color: #fff;
    background-color: $main-color;
    border-left: none;
  }

  .el-button.is-disabled {
    color: #fff;
  }

  .el-button.is-disabled:hover {
    background-color: $main-color;
    border-color: $main-color;
  }
}

.result-item {
  height: 55px;
  .el-image {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    margin-right: 16px;
  }

  .name {
    margin-right: 20px;
  }

  .type {
    color: #bbb;
  }
}

.table-container {
  margin-top: 20px;
  height: 200px;
  overflow: scroll;
  border: 1px solid #eeeeee;

  table {
    width: 100%;
    thead {
      height: 38px;
      color: #999999;
      background: #f6f6f6;
      th {
        padding: 0 20px;
      }
    }
    tbody {
      tr {
        td {
          padding: 7px 20px;
          .delete-button {
            cursor: pointer;
            width: 68px;
            height: 28px;
            line-height: 28px;
            border-radius: 4px;
            color: #ff5454;
            text-align: center;
            i {
              margin-right: 8px;
            }

            &:hover {
              background: rgba(255, 84, 84, 0.1);
            }
          }
        }
      }
    }
  }
}

.el-button {
  width: 80px;
  &:last-child {
    background-color: $main-color;
  }
}
.disable-color {
  background-color: #dad8d8 !important;
  border-color: #dad8d8 !important;
}
.open-color {
  background-color: $main-color !important;
  border-color: $main-color !important;
}
</style>
