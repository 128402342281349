<!--
 * @Author: hzh
 * @Date: 2021-10-24 01:21:23
 * @LastEditors: hzh
 * @LastEditTime: 2021-10-27 17:07:43
-->
<template>
    <div class="flex tab-container">
        <div class="tab-item" :class="{'is-active': current === index}" v-for="(item, index) in dataList" :key="index" @click="handlerChange(index)">
          {{item}}
        </div>
    </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: function() {
        return []
      }
    },
    current: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handlerChange(e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style lang="scss" scoped>
    .tab-container {
        background-color: #fff;
        padding: 13px 20px;
        border-radius: 6px;

        .tab-item {
            cursor: pointer;
            min-width: 42px;
            font-size: 14px;
            text-align: center;
            margin: 0 12px;
            padding: 4px 17px;

            &.is-active {
                color: $main-color;
            }

            &:hover {
                color: $main-color;
                background-color: rgba(0, 128, 255, 0.1);
                border-radius: 36px;
            }
        }

        @media screen and (max-width: 767px) {
          .tab-item {
            margin: 0;
          }
        }
    }
</style>
