<!--
 * @Author: Betty
 * @Date: 2021-07-02 16:07:37
 * @LastEditors: hzh
 * @LastEditTime: 2021-07-14 20:14:29
 * @Description: 未上传过的附件预览
-->
<template>
  <div class="appendix-item flex">
    <!-- 左边，图标 -->
    <div class="icon-box">
      <!-- 是图片就显示图片的内容 -->
      <img
        :src="getFileIcon(appendix)"
        alt=""
        class="icon"
        v-if="isPic(appendix)"
      />
      <!-- 否则显示文件类型的图标 -->
      <svg class="icon" aria-hidden="true" v-else>
        <use :xlink:href="getFileIcon(appendix)"></use>
      </svg>
    </div>
    <!-- 右边，文字信息 -->
    <div class="flex1 flex flex-y-positive flex-x-between">
      <p class="name e" :title="appendix.name">
        {{ appendix.name }}
      </p>
      <p class="size">{{ filterSize(appendix.size) }}</p>
      <div class="flex flex-y-center flex-x-between">
        <p class="from e">来自自己</p>
        <div class="flex flex-y-center">
          <!-- 删除按钮 -->
          <button
            type="button"
            class="btn delete-btn"
            @click="removeAttachment()"
          >
            <span class="iconfont icon-Delete"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'new-attachment-item',
  props: {
    // 附件信息
    appendix: {
      type: File
    }
  },
  data() {
    return {
      // 预览图片的路径
      imgPath: ''
    }
  },
  computed: {
    // 判断是否是图片
    isPic() {
      return function (file) {
        // 判断文件的扩展名是否是图片扩展名注意
        const type = file.name.slice(file.name.lastIndexOf('.') + 1)
        const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
        return imgTypes.includes(type)
      }
    },
    // 获取文件的图标
    getFileIcon() {
      return function (file) {
        const type = file.name.slice(file.name.lastIndexOf('.') + 1)
        switch (type) {
          // 如果是doc文件
          case 'doc':
          case 'docx':
            return '#icon-Frame3'
          // 如果是PDF
          case 'pdf':
            return '#icon-Frame4'
          // 如果是Excel
          case 'xls':
          case 'xlsx':
            return '#icon-Frame2'
          // 如果是图片，返回图片本身
          case 'jpg':
          case 'jpeg':
          case 'bmp':
          case 'png':
          case 'gif': {
            return this.imgPath
          }
          // 如果是PPT
          case 'ppt':
          case 'pptx':
            return '#icon-Frame5'
          // 如果是TXT
          case 'txt':
            return '#icon-Frame12'
          // 如果是其他
          default:
            return '#icon-Frame21'
        }
      }
    }
  },
  mounted() {
    this.getImgPath()
  },
  methods: {
    // 计算文件的大小
    filterSize(size) {
      return this.$util.filterSize(size)
    },
    // 读取一下图片路径并返回
    getImgPath() {
      const reads = new FileReader()
      reads.readAsDataURL(this.appendix)
      const that = this
      reads.onload = function (e) {
        that.imgPath = this.result
      }
    },
    // 删除附件
    removeAttachment() {
      this.$util.showConfirm('确定要删除这个文件吗？').then((result) => {
        if (result) {
          // 确认删除
          this.$emit('remove-file')
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/attachment_style.scss';
</style>
