var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"target-item target-group-item flex flex-y-center"},[_c('div',{staticClass:"more-menu-box flex flex-y-center move-target-rank hidden-xs-only",style:({ cursor: _vm.isMe ? 'move' : 'default !important' })},[(_vm.isMe)?_c('i',{staticClass:"iconfont icon-tuozhuai target-more"}):_vm._e()]),(_vm.target.children && _vm.target.children.length > 0)?_c('span',{staticClass:"show-child iconfont icon-zhankai",class:{ active: _vm.isShowChild },style:({ left: _vm.layer * 20 + _vm.renderWidth + 'px' }),on:{"click":function($event){$event.stopPropagation();return _vm.toggleChildren.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"target-item-td target-name-box flex flex-y-center name-box",style:({
        'padding-left': _vm.layer * 20 + 20 + _vm.renderWidth + 'px'
      })},[_c('div',{on:{"click":function($event){return _vm.setTargetStatus(_vm.target.objective_id)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.target.objective_status === 3 || _vm.target.objective_status === 4
                ? '#icon-TickSquare_1'
                : '#icon-TickSquare'}})])]),(_vm.isEditTarget)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tempName),expression:"tempName"}],ref:_vm.editNameRef,staticClass:"edit-input",attrs:{"type":"text","placeholder":"请输入目标名称"},domProps:{"value":(_vm.tempName)},on:{"blur":_vm.handleEditTarget,"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==229){ return null; }return _vm.empty.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }return _vm.toBlur($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; };(_vm.escIsShow = true), (_vm.escShowRef = _vm.editNameRef)}],"input":function($event){if($event.target.composing){ return; }_vm.tempName=$event.target.value}}}):_c('div',{staticClass:"target-name",class:{
          over: _vm.target.objective_status === 3,
          end_over: _vm.target.objective_status === 4
        },on:{"click":function($event){return _vm.toTargetDetail(_vm.target.objective_id)}}},[_c('div',{staticClass:"target-name-info flex flex-y-center"},[_c('p',{staticClass:"target-name-text e"},[(_vm.infoLabel.gth > 0)?_c('span',{staticClass:"target-label",style:({
                color: '#FF5454',
                background: '#fff',
                fontWeight: '600',
                fontSize: '15px'
              })},[_vm._v(_vm._s(_vm.infoLabel.gthStr))]):_vm._e(),_vm._l((_vm.infoLabel.list),function(item,index){return _c('span',{key:index,staticClass:"target-label",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('changeLabel', item.id)}}},[_vm._v(_vm._s(item.name))])}),_vm._v(_vm._s(_vm.infoLabel.showName)+" ")],2),(_vm.target.child_total_count > 0)?[(_vm.timeoutCount > 0)?_c('p',{staticClass:"child-info"},[_vm._v(" ("),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.timeoutCount))]),_vm._v("/"+_vm._s(_vm.target.child_complete_count)+"/"+_vm._s(_vm.target.child_total_count)+") ")]):_c('p',{staticClass:"child-info"},[_vm._v(" ("+_vm._s(_vm.target.child_complete_count)+"/"+_vm._s(_vm.target.child_total_count)+") ")])]:_vm._e()],2),(_vm.isMe)?_c('div',{staticClass:"more-menu-box"},[_c('span',{staticClass:"iconfont icon-gengduo3 target-more hidden-xs-only",on:{"click":function($event){$event.stopPropagation();return _vm.showMoreMenu.apply(null, arguments)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTargetMore),expression:"isShowTargetMore"}],staticClass:"target-more-menu"},[_c('div',{staticClass:"target-more-item",on:{"click":function($event){$event.stopPropagation();return _vm.toMoveItem(_vm.target)}}},[_vm._v(" 移动 ")]),_c('div',{staticClass:"target-more-item",on:{"click":function($event){$event.stopPropagation();return _vm.beginToEditTarget(_vm.target)}}},[_vm._v(" 编辑目标 ")]),(_vm.target.create_clerk_id === _vm.myClerkId)?_c('div',{staticClass:"target-more-item delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteTarget(_vm.target)}}},[_vm._v(" 删除 ")]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"target-item-td relative-box"},[_c('span',{staticClass:"iconfont relative-icon",class:[
          { active: _vm.target.pid > 0 },
          _vm.target.pid === 0 ? 'icon-to_relative' : 'icon-relatived'
        ],style:({ cursor: _vm.isMe ? 'pointer' : 'default' }),on:{"click":function($event){return _vm.toChooseRelative(_vm.target)}}})]),_c('div',{staticClass:"target-item-td type-box hidden-xs-only",style:({ cursor: _vm.isMe ? 'pointer' : 'default' }),on:{"click":function($event){$event.stopPropagation();return _vm.toChooseTargetType(_vm.target.objective_id)}}},[(_vm.getTargetTypeInfo(_vm.target.objective_type))?_c('div',{staticClass:"target-type-box",class:[_vm.getTargetTypeInfo(_vm.target.objective_type).color],style:({ cursor: _vm.isMe ? 'pointer' : 'default' })},[_vm._v(" "+_vm._s(_vm.getTargetTypeInfo(_vm.target.objective_type).text)+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isShowTargetTypes && _vm.Parent.currentShowType === _vm.target.objective_id
        ),expression:"\n          isShowTargetTypes && Parent.currentShowType === target.objective_id\n        "}],staticClass:"target-types"},_vm._l((_vm.targetTypeList),function(item,index){return _c('div',{key:index,staticClass:"target-type-item"},[_c('div',{staticClass:"target-type-box",class:[item.color],on:{"click":function($event){return _vm.chooseTargetType(item.value)}}},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)]),_c('div',{staticClass:"target-item-td time-box hidden-xs-only",class:{ 'is-late': _vm.isLate }},[(_vm.isMe)?_c('el-date-picker',{attrs:{"align":"right","type":"date","placeholder":"","size":"small","picker-options":_vm.pickerOptions,"value-format":"yyyy-MM-dd"},on:{"blur":function($event){return _vm.setLastTime(_vm.target)}},model:{value:(_vm.lastTime),callback:function ($$v) {_vm.lastTime=$$v},expression:"lastTime"}}):_vm._e(),(!_vm.isMe)?_c('p',{staticClass:"last-time-text",class:{ 'is-late': _vm.isLate }},[_vm._v(" "+_vm._s(_vm.getLastTime)+" ")]):_vm._e()],1),_c('div',{staticClass:"\n        target-item-td\n        hidden-xs-only\n        flex flex-y-center flex-x-center\n        progress-box\n      ",on:{"click":function($event){$event.stopPropagation();return _vm.toSetTargetProgress(_vm.target, $event)}}},[_c('el-progress',{attrs:{"color":_vm.target.progress_status === 1 ? '#0080FF' : '#FF5454',"type":"circle","percentage":_vm.target.progress_number,"width":20,"show-text":false,"stroke-width":2}}),_c('span',{staticClass:"progress"},[_vm._v(_vm._s(_vm.target.progress_number + '%'))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isShowTargetProgress &&
          _vm.Parent.currentShowTargetProgress === _vm.target.objective_id
        ),expression:"\n          isShowTargetProgress &&\n          Parent.currentShowTargetProgress === target.objective_id\n        "}],staticClass:"task-process",on:{"click":function($event){$event.stopPropagation();}}},[_c('h6',{staticClass:"process-title"},[_vm._v("当前进度")]),_c('div',{staticClass:"flex flex flex-y-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentTargetProgress),expression:"currentTargetProgress"}],ref:"editTargetProgressRef",staticClass:"input",attrs:{"type":"number","min":"0","max":"100"},domProps:{"value":(_vm.currentTargetProgress)},on:{"blur":function($event){return _vm.readyToEditTargetProgress()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toBlur($event)},"input":function($event){if($event.target.composing){ return; }_vm.currentTargetProgress=$event.target.value}}}),_c('span',{staticClass:"percent"},[_vm._v("%")])]),_c('h6',{staticClass:"process-title"},[_vm._v("状态")]),_c('div',{staticClass:"radio-item"},[_c('label',{staticClass:"label normal flex flex-y-center",attrs:{"for":""},on:{"click":function($event){return _vm.setTargetProgressStatus(1)}}},[_c('svg',{staticClass:"icon radio-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.currentTargetProgressStatus === 1
                    ? '#icon-Group706'
                    : '#icon-Group592'}})]),_c('input',{staticClass:"radio",attrs:{"type":"radio","name":"task","id":""},domProps:{"checked":_vm.currentTargetProgressStatus === 1}}),_c('span',[_vm._v("正常")])])]),_c('div',{staticClass:"radio-item"},[_c('label',{staticClass:"label late flex flex-y-center",attrs:{"for":""},on:{"click":function($event){return _vm.setTargetProgressStatus(2)}}},[_c('svg',{staticClass:"icon radio-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.currentTargetProgressStatus === 2
                    ? '#icon-Group705'
                    : '#icon-Group593'}})]),_c('input',{staticClass:"radio",attrs:{"type":"radio","name":"task","id":""},domProps:{"checked":_vm.currentTargetProgressStatus === 2}}),_c('span',[_vm._v("已延期")])])])])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowChild),expression:"isShowChild"}]},[_c('draggable',{attrs:{"list":_vm.target.children,"animation":"300","scroll":true,"move":_vm.onMove,"chosenClass":"chosen","group":"demoDragTree","handle":".move-target-rank"},on:{"end":_vm.choose}},[_c('transition-group',_vm._l((_vm.target.children),function(item){return _c('target-group-item',{key:item.objective_id,attrs:{"target":item,"layer":_vm.layer + 1,"isMe":_vm.isMe},on:{"to-choose-relative":function($event){return _vm.toChooseRelative(item)},"to-fresh-target":_vm.toFresh,"to-target-detail":function($event){return _vm.toTargetDetail($event)},"changeLabel":function($event){return _vm.$emit('changeLabel', $event)}}})}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }