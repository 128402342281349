<!--
 * @Author: hzh
 * @Date: 2021-09-28 09:45:57
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-30 15:57:19
-->
<template>
  <div class="edit-menu-item" :class="{ disable: disable }" @click="handleCilck">
    <div
      class="flex flex-x-between flex-y-center"

    >
      <div>{{ title }}</div>
      <i v-if="isActive" class="icon-a-Group203"></i>
    </div>
    <div style="color: #999999; font-size: 12px">{{ info }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCilck(e) {
      if (!this.disable) {
        this.$emit('change', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-menu-item {
  cursor: pointer;
  padding: 9px 16px;
  i {
    color: $main-color;
  }
  &.disable {
    background-color: #f1f1f1;
    cursor: not-allowed;
  }
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>
