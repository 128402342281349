<!--
 * @Author: hzh
 * @Date: 2021-09-10 17:59:01
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-10 18:06:48
-->
<template>
  <!-- 上传进度弹窗 -->
  <div class="upload-document-pop flex flex-x-center flex-y-center">
    <div class="upload-document-content flex flex-y-positive flex-x-between">
      <span>上传进度：{{ current }}/{{ total }}，正在上传[{{ name }}]</span>
      <el-progress
        :stroke-width="5"
        :percentage="progress"
      ></el-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: 'upload-document',
  components: {},
  props: {
    progress: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.upload-document-pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  .upload-document-content {
    height: 50px;
    background: #ffffff;
    padding: 20px 40px;
    border-radius: 6px;
  }
}
.upload-document-content::v-deep
  .el-progress.el-progress--line.el-progress--text-inside {
  .el-progress-bar__outer {
    width: 300px;
  }
}
</style>
