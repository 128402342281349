<!--
 * @Author: hzh
 * @Date: 2021-08-11 19:51:59
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-19 18:04:15
-->
<template>
  <div class="topic-video">
    <video controls="controls" :src="url"></video>
  </div>
</template>

<script>
export default {
  props: ['url']
}
</script>

<style lang="scss" scoped>
.topic-video {
  position: relative;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 24px;

  video {
    width: 100%;
    height: 340px;
  }

  @media screen and (max-width: 767px) {
    video {
      height: calc(100vw * 0.562);
    }
  }

}
</style>
