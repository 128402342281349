<!--
 * @Date: 2021-07-23 22:53:35
 * @LastEditTime: 2021-12-28 10:06:13
 * @Description: 消息动态组件
 * @Author: lyq
 * @LastEditors: hzh
-->
<template>
  <div class="flex message-item">
    <div>
      <el-image
        class="member-avatar"
        @click="clickAvatar(messageInfo.create_clerk_id)"
        :src="messageInfo.create_clerk_avatar | httpToHttps"
      ></el-image>
    </div>
    <div class="message-container">
      <message-header
        :memberInfo="renderInfo"
        :clerkId="messageInfo.create_clerk_id"
        @change="headlerHeadChage"
      ></message-header>
      <div class="message-content">
        <div
          class="message-text"
          v-if="messageInfo.moment_title || messageInfo.moment_content"
        >
          <h4
            @click="addLink"
            class="message-title"
            v-if="messageInfo.moment_title"
            v-html="renderMomentTitle"
          ></h4>
          <!-- <span class="message-topic">#Letme和女友分手#</span> -->
          <p
            @click="addLink"
            ref="messageContent"
            v-if="messageInfo.moment_content"
            v-html="handlerContentToUrl(getContenHtml(messageInfo)).replace(/\[[^\[\]]*\]/gi,emotion)"
            class="text-clamp-10"
          >
            <!-- {{ messageInfo.moment_content || '' }} -->
          </p>
          <span
            class="look-more"
            @click="openDetail"
            v-if="contentHeight >= 274"
          >
            查看更多>>
          </span>
        </div>
        <!-- 图片 -->
        <message-image
          :imgs="messageInfo.image_list"
          v-if="messageInfo.content_type == 1"
        ></message-image>
        <!-- 视频 -->
        <message-video
          :url="messageInfo.video_info.cover_video_path"
          v-if="messageInfo.content_type == 2"
        ></message-video>
        <!-- 知识库 -->
        <message-knowledge
          :info="messageInfo.knowl_info"
          v-if="messageInfo.content_type == 3"
        ></message-knowledge>
        <message-interactive
          :browseNum="messageInfo.visit_number"
          :commentNum="messageInfo.comment_number"
          :likeNum="messageInfo.praise_number"
          :isPraise="messageInfo.is_praise"
          @trigger="handlerInteractive"
        ></message-interactive>
      </div>
      <div class="comment-container line-top" v-if="commentList.length > 0">
        <!-- <div class="send-comment">
          <el-input
            :placeholder="placeholderText"
            v-model="replyText"
            ref="commentRef"
            @blur="handlerReplyBlur"
            type="textarea"
            autosize
          ></el-input>
          <div class="send-button flex flex-x-between">
            <expression-box
              :left="'0'"
              :isShow="isShowCommentExpression"
              :expressList="$emoji.slice(0, 100)"
              @add-express="addExpress($event)"
              @toggle-express-box="toggleCommentExpression($event)"
            ></expression-box>
            <el-button size="small" type="primary" @click="sendComment"
              >发送</el-button
            >
          </div>
        </div> -->
        <div
          class="comment-list"
          v-for="(item, index) in packUp
            ? commentList.slice(0, 3 + showOffset)
            : commentList"
          :key="item.comment_id"
        >
          <div class="comment-item">
            <div>
              <comment-member
                :avatar="item.clerk_avatar"
                :name="item.clerk_alias || item.clerk_name || ''"
                :time="item.create_time"
                :likeNum="item.praise_number"
                :isLike="item.is_praise == 1"
                @clickAvatar="clickAvatar(item.create_clerk_id)"
                @onReply="onReply(index)"
                @onPraise="commentPraise(index)"
                @onDelete="onDelete(index)"
                :showDelete="item.comment_id === showCommentDeleteId"
                @mouseenter.native="showCommentDelete(item)"
                @mouseleave.native="hideDelete()"
              ></comment-member>
            </div>
            <div class="comment-content">
              <span
                class="text-warp"
                @click="addLink"
                v-html="handlerContentToUrl(getCommentHtml(item)).replace(/\[[^\[\]]*\]/gi,emotion)"
              ></span>
              <comment-reply
                v-for="(cItem, cIndex) in item.child_list_pack_up
                  ? item.child_list.slice(0, 3 + item.show_child_list_offset)
                  : item.child_list"
                :key="cItem.comment_id"
                :show-reply-to-name="cItem.pid !== cItem.tid"
                :replyInfo="cItem"
                :showDelete="showCommentDeleteId == cItem.comment_id"
                @onReply="onChildReply(index, cIndex)"
                @onDelete="onChildDelete(index, cIndex)"
                @onPraise="commentChildPraise(index, cIndex)"
                @mouseenter.native="showCommentDelete(cItem)"
                @mouseleave.native="hideDelete()"
                @appenLink="addLink($event)"
                @clickAvatar="clickAvatar"
              ></comment-reply>
              <template
                v-if="item.reply_number - item.show_child_list_offset > 3"
              >
                <comment-total-bar
                  v-if="item.child_list_pack_up"
                  :replyNumber="item.reply_number"
                  :lastestName="
                    item.lastest_clerk_alias || item.lastest_clerk_name
                  "
                  @open="getCommentChildNextPage(index)"
                ></comment-total-bar>
                <div
                  class="line-top flex flex-x-between comment-pack-load-bar"
                  v-if="!item.child_list_pack_up"
                >
                  <span
                    style="color: #0080ff; cursor: pointer"
                    @click="closeChildCommentList(index)"
                  >
                    <i class="icon-zhankai pack-up"></i>
                    收起
                  </span>
                  <span
                    style="color: #999; cursor: pointer"
                    @click.stop="getCommentChildNextPage(index)"
                    v-show="
                      item.child_list_page_number < item.child_list_total_page
                    "
                  >
                    <i class="icon-zhankai"></i>
                    加载更多
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <comment-more
          :showPackUp="!packUp"
          :showUnwind="pageNumber < totalPage || packUp"
          @unwind="getCommentNextPage"
          @packUp="clickPackUp"
          v-if="topMomentCount - showOffset > 3"
        ></comment-more>
      </div>
    </div>
    <comment-dialog
      v-if="false"
      :info="commentList[commentDetail]"
      @close="commentDetail = -1"
      @praise="dialogPraise"
      @comment="dialogComment"
    ></comment-dialog>
    <reply-dialog
      v-if="isShowReply"
      :title="replyTitle"
      v-model="replyText"
      @close="isShowReply = false"
      @send="sendComment"
    ></reply-dialog>
  </div>
</template>

<script>
import MessageHeader from '@/components/circle/MessageHeader.vue'
import MessageVideo from '@/components/circle/MessageVideo.vue'
import MessageImage from '@/components/circle/MessageImage.vue'
import MessageKnowledge from '@/components/circle/MessageKnowledge.vue'
import MessageInteractive from '@/components/circle/MessageInteractive.vue'
import CommentMember from '@/components/circle/CommentMember.vue'
import CommentReply from '@/components/circle/CommentReply.vue'
import CommentMore from '@/components/circle/CommentMore.vue'
import ReplyDialog from '@/components/circle/ReplyDialog.vue'
// 评论弹窗
import CommentDialog from '@/components/circle/CommentDialog.vue'
import CommentTotalBar from '@/components/circle/CommentTotalBar.vue'
import { mapState } from 'vuex'
import { emojisMap } from '../common/ExpressionBox.vue'
export default {
  components: {
    MessageHeader,
    MessageImage,
    MessageVideo,
    MessageInteractive,
    CommentMember,
    CommentReply,
    CommentMore,
    CommentDialog,
    ReplyDialog,
    CommentTotalBar,
    MessageKnowledge
  },
  props: ['messageInfo'],
  data() {
    return {
      // 展示评论
      isShowComment: false,
      pageNumber: 0,
      pageSize: 3,
      totalPage: 2,
      placeholderText: '发表评论',
      showCommentDeleteId: -1,
      replyCommentId: '',
      replyText: '',
      contentHeight: 0,
      isShowCommentExpression: false,
      commentDetail: -1,
      // 是否展示回复
      isShowReply: false,
      replyTitle: '评论',
      replyChildIndex: -1,
      // 刷新的评论列表
      commentList: [],
      childCommentLoading: false,
      commentLoading: false,
      // 是否收起
      packUp: true,
      // 一级评论数量
      topMomentCount: 0,
      // 偏移
      queryOffset: 0,
      // 展示偏移
      showOffset: 0
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    renderInfo() {
      return {
        create_clerk_name:
          this.messageInfo.create_clerk_alias ||
          this.messageInfo.create_clerk_name,
        create_time: this.messageInfo.create_time
      }
    },
    renderMomentTitle() {
      const content = this.messageInfo.moment_title
      let str = ''
      let index = 0
      if (this.messageInfo.topic_title_list.length !== 0) {
        this.messageInfo.topic_title_list.forEach((e, i) => {
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${e.topic_id}" data-class="topic" data-name="${e.topic_name}" style=" color: #0080FF;cursor: pointer;font-weight:600">#${e.topic_name}#</a>`
          index = e.end_index + 1
          if (i === this.messageInfo.topic_title_list.length - 1) {
            str += content.slice(index)
          }
        })
      } else {
        str = content
      }
      return str
    },
    momentId() {
      return this.messageInfo.moment_id
    }
  },
  mounted() {
    this.commentList = [...this.messageInfo.comment_list] || []
    this.queryOffset = this.commentList.length
    this.commentList.forEach((e, i) => {
      if (e.child_list) {
        this.$set(this.commentList[i], 'show_child_list_offset', 0)
        this.$set(
          this.commentList[i],
          'query_child_list_offset',
          e.child_list.length
        )
        this.$set(this.commentList[i], 'child_list_pack_up', true)
        this.$set(this.commentList[i], 'child_list_page_number', 0)
      }
    })
    this.topMomentCount = this.messageInfo.top_moment_count
    // console.log(11111, this.$refs.messageContent.clientHeight)
    if (this.messageInfo.moment_content) {
      this.$nextTick(() => {
        this.contentHeight = parseInt(
          window
            .getComputedStyle(this.$refs.messageContent)
            .height.replace('px', '')
        )
        // console.log(this.contentHeight)
      })
    }
  },
  methods: {
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width:20px"}">`
      } else {
        return name
      }
      // const word = nane.replace(/\[|\]/gi, '')
      // const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
      // const indon = list.indexOf(word)
      // return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${indon}.gif" align="middle">`
    },
    // 获取评论内容html
    getCommentHtml(e) {
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = e.comment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;cursor: pointer;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 获取内容html
    getContenHtml(e) {
      // 提及列表
      const clerList = e.clerk_list.map((c) => {
        c.type = 1
        return c
      })
      // 话题列表
      const topicList = e.topic_list.map((t) => {
        t.type = 2
        return t
      })
      const list = clerList.concat(topicList).sort((a, b) => {
        return a.start_index - b.start_index
      })
      const content = e.moment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name =
            e.type === 1
              ? '@' + (e.clerk_alias || e.clerk_name) + ' '
              : '#' + e.topic_name + '#' + ' '
          const id = e.type === 1 ? e.clerk_id : e.topic_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="${
              e.type === 1 ? 'mention' : 'topic'
            }" style=" color: #0080FF;cursor: pointer;font-weight:600"> ${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 触发互动
    async handlerInteractive(e) {
      switch (e.type) {
        // 评论
        case 'comment':
          this.handlerComment()
          break
        // 圈子点赞
        case 'like': {
          this.handlerPraise()
          break
        }
      }
    },
    // 触发点赞
    async handlerPraise() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.praiseMoment, {
          moment_id: this.momentId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.messageInfo, 'is_praise', res.is_praise ? 1 : 0)
        res.is_praise
          ? this.messageInfo.praise_number++
          : this.messageInfo.praise_number--
      }
    },
    // 触发评论
    async handlerComment() {
      this.replyChildIndex = -1
      this.replyTitle = '评论'
      this.replyCommentId = ''
      this.isShowReply = true
      // this.isShowComment = !this.isShowComment
      // if (this.isShowComment) {
      //   this.getCommentList()
      // }
    },
    // 获取评论列表
    async getCommentList(reset = false) {
      const formObj = {
        moment_id: this.momentId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        offset: this.queryOffset
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const list = res.page.list.map((e, i) => {
          if (e.child_list) {
            return {
              ...e,
              show_child_list_offset: 0,
              query_child_list_offset: e.child_list.length,
              child_list_pack_up: true,
              child_list_page_number: 0
            }
          }
          return e
        })
        if (reset) {
          this.commentList = list
        } else {
          this.commentList = this.commentList.concat(list)
        }

        this.totalPage = res.page.totalPage
        this.pageNumber = res.page.pageNumber
        this.topMomentCount = res.page.totalRow + this.queryOffset
      }
    },
    // 获取下一页
    async getCommentNextPage() {
      if (this.commentLoading) {
        return
      }
      if (this.packUp) {
        this.packUp = false
        if (this.commentList.length - this.showOffset > 3) {
          return
        }
      }
      this.pageNumber++
      this.commentLoading = true
      await this.getCommentList()
      this.commentLoading = false
    },
    // 刷新列表
    async reloadCommentList() {
      if (this.commentLoading) {
        return
      }
      this.packUp = true
      this.pageNumber = 1
      this.totalPage = 2
      this.queryOffset = 0
      this.showOffset = 0
      // this.commentList = []
      this.commentLoading = true
      await this.getCommentList(true)
      this.commentLoading = false
    },
    // 显示删除按钮
    showCommentDelete(item) {
      let hasPer = false
      if (this.messageInfo.create_clerk_id === this.myClerkId) {
        hasPer = true
      }
      if (hasPer || item.create_clerk_id === this.myClerkId) {
        hasPer = true
      }

      if (hasPer) {
        this.showCommentDeleteId = item.comment_id
      }
    },
    // 隐藏删除按钮
    hideDelete(index) {
      this.showCommentDeleteId = -1
    },
    // 回复
    onReply(index) {
      this.replyChildIndex = index
      this.isShowReply = true
      this.replyTitle =
        '回复 ' +
        (this.commentList[index].clerk_alias ||
          this.commentList[index].clerk_name)
      this.replyCommentId = this.commentList[index].comment_id
      // this.commentDetail = index
      // this.placeholderText = '回复 ' + this.commentList[index].clerk_name
      // this.$refs.commentRef.focus()
    },
    // 子评论回调
    onChildReply(index, cIndex) {
      this.replyChildIndex = index
      this.isShowReply = true
      this.replyTitle =
        '回复 ' +
        (this.commentList[index].child_list[cIndex].clerk_alias ||
          this.commentList[index].child_list[cIndex].clerk_name)
      this.replyCommentId =
        this.commentList[index].child_list[cIndex].comment_id
    },
    // 重置回复数据
    resetReplyData() {
      this.placeholderText = '发表评论'
      this.replyCommentId = ''
      this.replyText = ''
      this.replyTitle = '评论'
      this.isShowReply = false
    },
    // 回复评论
    async sendComment() {
      if (this.replyText === '') {
        this.$message.error('请输入评论内容')
        return
      }
      const formObj = {
        moment_id: this.momentId,
        comment_content: this.replyText,
        pid: this.replyCommentId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentComment, formObj)
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        // 回复圈子
        if (this.replyChildIndex === -1) {
          this.commentList.unshift(res.info)
          this.topMomentCount++
          this.queryOffset++
          if (this.packUp) {
            this.showOffset++
          }
          // this.reloadCommentList()
        } else {
          // 回复评论
          // 没有子列表时初始化参数
          if (!this.commentList[this.replyChildIndex].child_list) {
            this.$set(this.commentList[this.replyChildIndex], 'child_list', [])
            this.$set(
              this.commentList[this.replyChildIndex],
              'child_list_pack_up',
              true
            )
          }
          // 设置子列表最后更新人名称
          this.$set(
            this.commentList[this.replyChildIndex],
            'lastest_clerk_name',
            res.info.clerk_name
          )
          // 设置子列表最后更新人花名
          this.$set(
            this.commentList[this.replyChildIndex],
            'lastest_clerk_alias',
            res.info.clerk_alias
          )
          // 设置子列表最后回复数量
          this.$set(
            this.commentList[this.replyChildIndex],
            'reply_number',
            (this.commentList[this.replyChildIndex].reply_number || 0) + 1
          )
          // 设置子列表查询偏移数量
          this.$set(
            this.commentList[this.replyChildIndex],
            'query_child_list_offset',
            (this.commentList[this.replyChildIndex].query_child_list_offset ||
              0) + 1
          )
          // 如果子列表未展开,设置子列表展示偏移数量
          if (this.commentList[this.replyChildIndex].child_list_pack_up) {
            this.$set(
              this.commentList[this.replyChildIndex],
              'show_child_list_offset',
              (this.commentList[this.replyChildIndex].show_child_list_offset ||
                0) + 1
            )
          }
          this.commentList[this.replyChildIndex].child_list.unshift(res.info)
        }
        this.replyChildIndex = -1
        this.$emit('incrementComment')
        // this.$message({
        //   showClose: true,
        //   message: '评论成功',
        //   type: 'success'
        // })
      }
    },
    // 删除评论
    async onDelete(index) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentComment, {
          comment_id: this.commentList[index].comment_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        this.reloadCommentList()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 删除子级评论
    async onChildDelete(index, cIndex) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentComment, {
          comment_id: this.commentList[index].child_list[cIndex].comment_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        this.reloadCommentChildList(index)
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 处理头部事件
    headlerHeadChage(type) {
      this.$emit('changeMenu', type)
    },
    // 评论点赞
    async praise(commentId) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.praiseMomentComment, {
          comment_id: commentId
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        console.log(res.is_praise)
        return res.is_praise
      }
    },
    // 父级评论点赞
    async commentPraise(index) {
      const flag = await this.praise(this.commentList[index].comment_id)
      flag
        ? this.commentList[index].praise_number++
        : this.commentList[index].praise_number--
      this.$set(this.commentList[index], 'is_praise', flag ? 1 : 0)
    },
    // 子级评论点赞
    async commentChildPraise(index, cIndex) {
      const info = this.commentList[index].child_list[cIndex]
      const flag = await this.praise(info.comment_id)
      flag ? info.praise_number++ : info.praise_number--
      this.$set(info, 'is_praise', flag ? 1 : 0)
    },
    // 打开详情
    openDetail() {
      this.$emit('openDetail')
    },
    // 处理回复移除
    handlerReplyBlur() {
      if (this.replyText === '') {
        this.placeholderText = '发表评论'
        this.replyCommentId = ''
        this.replyText = ''
      }
    },
    // 点击头像
    clickAvatar(clerkId) {
      this.$emit('clickAvatar', clerkId)
    },
    // 点击表情
    addExpress(express) {
      this.replyText += express.char
      this.isShowCommentExpression = false
      this.$refs.commentRef.focus()
    },
    // 显示隐藏表情
    toggleCommentExpression(val) {
      this.isShowCommentExpression = !val
    },
    // 弹窗点赞
    dialogPraise({ comment_id: commentId, is_praise: isPraise }) {
      const index = this.commentList.findIndex((e) => {
        return e.comment_id === commentId
      })
      if (isPraise === 1) {
        this.commentList[index].praise_number++
      } else {
        this.commentList[index].praise_number--
      }
      this.commentList[index].is_praise = isPraise
    },
    // 弹窗评论
    dialogComment() {
      this.reloadCommentList()
      this.$emit('incrementComment')
    },
    // 获取评论列表
    async getCommentChildList(index, reset = false) {
      const formObj = {
        moment_id: this.momentId,
        tid: this.commentList[index].comment_id,
        pageNumber: this.commentList[index].child_list_page_number || 1,
        pageSize: 5,
        offset: this.commentList[index].query_child_list_offset || 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentChildPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.$set(this.commentList[index], 'child_list', res.page.list)
        } else {
          this.$set(
            this.commentList[index],
            'child_list',
            (this.commentList[index].child_list || []).concat(res.page.list)
          )
        }
        this.$set(
          this.commentList[index],
          'child_list_page_number',
          res.page.pageNumber
        )
        this.$set(
          this.commentList[index],
          'child_list_total_page',
          res.page.totalPage
        )
      }
    },
    // 获取下一页
    async getCommentChildNextPage(index) {
      if (this.childCommentLoading) {
        return
      }
      const info = this.commentList[index]
      if (info.child_list_pack_up) {
        this.$set(this.commentList[index], 'child_list_pack_up', false)
        if (info.child_list.length - info.show_child_list_offset > 3) {
          return
        }
      }
      this.$set(
        this.commentList[index],
        'child_list_page_number',
        (info.child_list_page_number || 0) + 1
      )
      this.childCommentLoading = true
      await this.getCommentChildList(index)
      this.childCommentLoading = false
    },
    // 刷新列表
    async reloadCommentChildList(index) {
      this.$set(this.commentList[index], 'child_list_page_number', 1)
      this.$set(this.commentList[index], 'child_list_total_page', 2)
      this.$set(this.commentList[index], 'query_child_list_offset', 0)
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      // this.$set(this.commentList[index], 'childList', [])
      this.childCommentLoading = true
      await this.getCommentChildList(index, true)
      this.childCommentLoading = false
    },
    // 打开下级评论列表
    openChildCommentList(index) {
      // 清空子列表展示偏移数量
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      if (this.commentList[index].child_list_pack_up == null) {
        this.getCommentChildList(index)
      }
      this.$set(this.commentList[index], 'child_list_pack_up', false)
    },
    // 收起下级评论列表
    closeChildCommentList(index) {
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      this.$set(this.commentList[index], 'child_list_pack_up', true)
    },
    // 点击收起
    clickPackUp() {
      this.packUp = true
      this.showOffset = 0
    },
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink = Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`)
        : url
    },
    addLink(e) {
      const cb = e.target.dataset
      if (cb.class === 'topic') {
        this.$emit('searchTopic', cb.id)
      }
      if (cb.class === 'mention') {
        this.$emit('searchMention', cb.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-item {
  margin-bottom: 24px;
  ::v-deep .emoji-img {
    width: 24px;
    position: relative;
    top: -6px;
  }
  .member-avatar {
    width: 64px;
    height: 64px;
    border-radius: 6px;
  }

  .message-container {
    position: relative;
    width: 493px;
    background-color: #fff;
    padding: 18px 24px;
    border-radius: 0px 6px 6px 6px;

    .message-content {
      font-size: 16px;
      .message-text {
        margin-bottom: 10px;

        .message-title {
          margin-bottom: 10px;
        }
        .message-topic {
          color: $main-color;
          margin-right: 5px;
          line-height: 28px;
        }

        p {
          line-height: 28px;
          word-wrap: break-word;
         white-space: pre-line;
        }

        .look-more {
          cursor: pointer;
          font-size: 14px;
          color: $main-color;
          line-height: 28px;
          margin-top: 10px;
        }
      }
    }

    .comment-container {
      padding-top: 20px;

      .send-comment {
        .el-input {
          height: 44px;
        }
      }

      .send-button {
        position: relative;
        width: 100%;
        height: 32px;
        padding: 14px 0;

        .el-button {
          position: absolute;
          right: 0;
        }
      }
    }

    .comment-list {
      .comment-item {
        .comment-content {
          cursor: auto;
          margin-left: 46px;
          white-space: inherit;

          .comment-pack-load-bar {
            padding: 5px 0;
            height: 32px;
            line-height: 32px;
            margin: 10px 0;

            .pack-up {
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .message-item {
    justify-content: space-between;
    .message-container::after {
      position: absolute;
      content: '';
      height: 0px;
      width: 0px;
      top: 0;
      left: -15px;
      border-right: 15px solid #fff;
      border-bottom: 15px solid $light-grey-bg;
    }
  }
}

@media screen and (max-width: 767px) {
  .message-item {
    margin-bottom: 8px;
    background-color: #fff;
    .member-avatar {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      margin-top: 10px;
      left: 10px;
      z-index: 2;
      margin-right: 10px;
    }

    .message-container {
      width: calc(100vw - 82px);
      padding: 10px 20px 10px 10px;
      .message-content {
        .message-text {
          margin-bottom: 5px;
          width: calc(100vw - 78px);
          .message-title {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
// .message-text {
//   display: flex;
//   align-items: center;
// }

img.emoji-img {
    height: 24px;
    width: 24px;
}
</style>
