<template>
  <!-- 创建文件夹弹窗 -->
  <div
    class="create-folder-mask flex flex-x-center flex-y-center"
    @click.stop="$emit('cancle')"
  >
    <div
      class="create-folder-pop flex flex-y-positive flex-y-between"
      @click.stop
    >
      <p class="wap create-title">创建知识库</p>
      <!-- 输入框 -->
      <el-input v-model="myTitle" placeholder="输入知识库标题"></el-input>
      <!-- 编辑框 -->
      <div class="edit_container">
        <knowledge-edit-folder-editor
          ref="editor"
          :value="describe || ''"
        ></knowledge-edit-folder-editor>
      </div>
      <!-- 按钮 -->
      <div class="button-box flex pc">
        <button class="save" @click="confirm">保 存</button>
        <el-button @click="$emit('cancle')">取 消</el-button>
      </div>
      <div class="button-box flex wap">
        <el-button @click="$emit('cancle')">取消</el-button>
        <button class="save" @click="confirm">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
// 调用编辑器
import KnowledgeEditFolderEditor from '@/components/knowledge/KnowledgeEditFolderEditor1.vue'
export default {
  name: 'create-folder',
  components: {
    KnowledgeEditFolderEditor
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    describe: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      myTitle: ''
    }
  },
  computed: {},
  methods: {
    confirm() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (this.myTitle === '') {
        errmsg('请输入名称')
        return
      }
      const content = this.$refs.editor.getContent()
      console.log(content)
      this.$emit('confirm', {
        title: this.myTitle,
        describe_html: content.html,
        describe_text: content.text
      })
    }
  },
  mounted() {
    this.myTitle = this.title
  },
  watch: {
    title(val) {
      this.myTitle = val
    },
    describe(val) {
      this.$refs.editor.setContent(val)
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .wap,.show{
    display: none;
  }
  .create-folder-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    .create-folder-pop {
      width: 682px;
      min-height: 350px;
      max-height: 750px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 26px 26px 28px 26px;
      .edit_container ::v-deep {
        .ck-toolbar {
          background: transparent;
        }
        .ck-focused {
          box-shadow: none;
        }
        .ck-blurred {
          border-top: none;
        }
        .ck-editor__editable {
          max-height: 550px;
          overflow: scroll;
          h1,
          h2,
          h3,
          h4,
          h4,
          h5,
          h6 {
            padding-top: 5px;
          }
          body,
          dl,
          dt,
          dd,
          ul,
          ol,
          li,
          pre,
          form,
          fieldset,
          input,
          p,
          blockquote,
          th,
          td {
            padding-top: 5px;
          }
          ol,
          ul {
            list-style: decimal;
            padding-left: 30px;
          }
        }
      }
      .edit_container {
        margin-top: 20px;
        width: 630px;
        // border: 1px solid #dcdfe6;
        border-radius: 6px;
        .quill-editor {
          height: 124px;
        }
      }
      .button-box {
        margin-top: 24px;
        height: 32px;
        .save {
          width: 80px;
          height: 32px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
  }
  .create-folder-pop::v-deep .el-input {
    width: 630px;
    height: 50px;
    .el-input__inner {
      height: 50px;
    }
  }
  // 编辑器修改
  .edit_container::v-deep .quill-editor {
    .ql-active {
      color: #0080ff;
    }
    .ql-editor.ql-blank {
      p {
        font-size: 14px;
        line-height: 21px;
        color: #999999;
      }
    }
    .ql-container.ql-snow {
      border: none;
    }
    .ql-formats {
      margin: 0;
    }
    .ql-syntax {
      background: #f0f0f0;
      color: #333333;
      font-size: 14px;
    }
    .ql-toolbar.ql-snow {
      text-align: left;
      height: 40px;
      border: none;
    }
    .ql-direction,
    .ql-clean,
    .ql-header,
    ql-picker-label,
    .ql-font,
    .ql-size {
      display: none;
    }

    .show {
      display: none;
    }
    .wap {
      display: none !important;
    }
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none;
  }
  .create-title {
    text-align: center;
    font-size: 16px;
    height: 66px;
    line-height: 66px;
    font-weight: bold;
  }
  .create-folder-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    .create-folder-pop {
      width: 90%;
      min-height: 191px;
      max-height: 300px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      text-align: center;
      .edit_container ::v-deep {
        .ck-toolbar {
          background: transparent;
        }
        .ck-focused {
          box-shadow: none;
        }
        .ck-blurred {
          border-top: none;
        }
        .ck-editor__editable {
          max-height: 550px;
          overflow: scroll;
          h1,
          h2,
          h3,
          h4,
          h4,
          h5,
          h6 {
            padding-top: 5px;
          }
          body,
          dl,
          dt,
          dd,
          ul,
          ol,
          li,
          pre,
          form,
          fieldset,
          input,
          p,
          blockquote,
          th,
          td {
            padding-top: 5px;
          }
          ol,
          ul {
            list-style: decimal;
            padding-left: 30px;
          }
        }
      }
      .edit_container {
        width: 100%;

        border-radius: 6px;
        .quill-editor {
          height: 124px;
        }
      }
      .button-box {
        margin-top: 24px;
        height: 44px;
        border-top: 1px solid #dcdfe6;
        width: 100%;
        .save {
          width: 49%;
          height: 50px;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 50px;
          text-align: center;
          color: #0080ff;
          cursor: pointer;
        }
      }
    }
  }
  .button-box::v-deep .el-button {
    width: 49%;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 14px;
    border: none;
    border-right: 1px solid #dcdfe6;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
  }
  .create-folder-pop::v-deep .el-input {
    width: 85%;
    height: 50px;
    margin: 0px 15px 0px 15px;
    .el-input__inner {
      height: 50px;
      border: none;
      border-bottom: 1px solid #dcdfe6;
    }
  }
  // 编辑器修改
  .edit_container::v-deep .quill-editor {
    .ql-active {
      color: #0080ff;
    }
    .ql-editor.ql-blank {
      p {
        font-size: 14px;
        line-height: 21px;
        color: #999999;
      }
    }
    .ql-container.ql-snow {
      border: none;
    }
    .ql-formats {
      margin: 0;
    }
    .ql-syntax {
      background: #f0f0f0;
      color: #333333;
      font-size: 14px;
    }
    .ql-toolbar.ql-snow {
      text-align: left;
      height: 40px;
      border: none;
    }
    .ql-direction,
    .ql-clean,
    .ql-header,
    ql-picker-label,
    .ql-font,
    .ql-size {
      display: none;
    }
    .pc {
      display: none !important;
    }
  }
}
</style>
