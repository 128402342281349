<template>
  <div class="more-button">
    <i class="icon-gengduo2" @click.stop="handerShowMenu"></i>
    <transition name="el-fade-in-linear" v-if="showMenu">
      <div class="message-menu">
        <div
          class="menu-item"
          v-if="showEdit"
          @click="$emit('change', { type: 'edit' })"
        >
          编辑
        </div>
        <div
          class="menu-item delete-btn "
          v-if="showDelete"
          @click="$emit('change', { type: 'delete' })"
        >
          删除
        </div>
        <div class="menu-item" @click="$emit('change', { type: 'copy' })">
          复制链接
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      }
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false
    }
  },
  mounted() {
    document.addEventListener('click', () => {
      if (this.showMenu === true) {
        this.showMenu = false
      }
    })
  },
  methods: {
    handerShowMenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style lang="scss" socped>
.more-button {
  display: inline;
  position: relative;
  z-index: 10;

  .message-menu {
    position: absolute;
    width: 92px;
    background-color: #fff;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    right: 10px;
    border-radius: 4px;
    padding: 6px 0;

    .menu-item {
      cursor: pointer;
      width: 76px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      text-align: center;
      margin: 0 auto;

      &:hover {
        color: $main-color;
        background-color: rgba(0, 128, 255, 0.1);
        border-radius: 4px;
      }
      &.delete-btn {
        color: $red;

        &:hover {
          background-color: rgba(255, 84, 84, 0.1);
        }
      }
    }
  }
}
</style>
