var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"add-member",attrs:{"title":_vm.modalTitle,"modal":true,"visible":_vm.isShowAddMember,"width":"600","before-close":_vm.handleClose,"append-to-body":true},on:{"update:visible":function($event){_vm.isShowAddMember=$event}}},[_c('div',{staticClass:"add-member-box flex"},[_c('div',{staticClass:"flex1 left-box"},[_c('div',{staticClass:"search-box flex flex-y-center flex-x-between"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchName),expression:"searchName"}],staticClass:"flex1 input",attrs:{"type":"text","placeholder":"查找联系人"},domProps:{"value":(_vm.searchName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchName=$event.target.value},_vm.triggerSearch]}}),(_vm.searchName.trim().length > 0)?_c('button',{staticClass:"clear-btn",attrs:{"type":"button"},on:{"click":_vm.clearSearch}},[_c('span',{staticClass:"iconfont icon-decline"})]):_vm._e(),(_vm.searchName.trim().length === 0)?_c('button',{staticClass:"search-btn",attrs:{"type":"button"},on:{"click":_vm.searchClerkList}},[_c('span',{staticClass:"iconfont icon-search"})]):_vm._e()]),_c('div',{staticClass:"tree-list"},[(_vm.searchName.trim().length === 0)?_vm._l((_vm.dataList),function(item){return _c('div',{key:item.id},[_c('member-tree-and-dept',{attrs:{"treeNode":item,"selectClerkIdList":_vm.selectClerkIdList,"selectDeptIdList":_vm.selectDeptIdList,"disableClerkIdList":_vm.disableClerkIdList,"disableDeptIdList":_vm.disableDeptIdList},on:{"to-add-members":function($event){return _vm.chooseMore($event)},"to-remove-member":function($event){return _vm.cancelMore($event)}}})],1)}):[_c('div',{staticClass:"search-result"},[_vm._l((_vm.searchResultList),function(clerk,index){return _c('div',{key:index,staticClass:"member-item flex flex-y-center",on:{"click":function($event){return _vm.chooseMore({
                  is_dept: false,
                  clerk_id: clerk.clerk_id
                })}}},[_c('avatar',{attrs:{"userInfo":clerk,"size":30,"distanceRight":10}}),_c('p',{staticClass:"name e"},[_vm._v(" "+_vm._s(clerk.clerk_alias || clerk.clerk_name)+" ")])],1)}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchResultList.length === 0),expression:"searchResultList.length === 0"}],staticClass:"no-data"},[_vm._v(" 暂无搜索结果，请换个关键词吧。 ")])],2)]],2)]),_c('div',{staticClass:"flex1 right-box"},[_c('p',{staticClass:"member-title"},[_vm._v(" 已选择（"+_vm._s(_vm.selectClerkIdList.length + _vm.selectDeptIdList.length)+"） ")]),_c('div',{staticClass:"members-area"},[_vm._l((_vm.selectClerkList),function(item){return _c('div',{key:("c_" + (item.clerk_id)),staticClass:"member-item flex flex-y-center"},[_c('avatar',{attrs:{"userInfo":item,"size":30,"distanceRight":10}}),_c('p',{staticClass:"name e"},[_vm._v(_vm._s(item.clerk_alias || item.clerk_name))]),_c('button',{staticClass:"remove-member-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelMore({
                is_dept: false,
                clerk_id: item.clerk_id
              })}}},[_c('span',{staticClass:"iconfont icon-Delete"})])],1)}),_vm._l((_vm.selectDeptList),function(item){return _c('div',{key:("d_" + (item.dept_id)),staticClass:"member-item flex flex-y-center"},[_c('p',{staticClass:"name e"},[_vm._v(_vm._s(item.dept_name))]),_c('button',{staticClass:"remove-member-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelMore({
                is_dept: true,
                dept_id: item.dept_id
              })}}},[_c('span',{staticClass:"iconfont icon-Delete"})])])})],2)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeModal}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmAddMember}},[_vm._v("确认")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }