<!--
 * @Date: 2021-06-23 17:50:09
 * @LastEditTime: 2022-01-11 15:23:31
 * @Description: 任务组组件
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div
    class="task-group-box"
    :class="{ others: !isMe, flex: screenWidth >= 768 }"
  >
    <!-- 左边 -->
    <!-- 右边 -->
    <div class="task-group-list">
      <!-- 第一行 -->
      <div class="flex flex-x-between flex-y-center group-header">
        <div class="tag">{{ groupIndex }}</div>
        <p
          class="group-name"
          :style="{ cursor: isMe ? 'pointer' : 'default' }"
          v-if="!isShowName"
          @click="isShowGroupName"
        >
          {{ groupSendName }}
        </p>
        <el-input
          type="text"
          v-show="isShowName"
          class="group-name"
          ref="taskGroupRef"
          v-model="groupSendName"
          @keyup.enter.native="enterKey"
          @keyup.esc.native="isEscShow = true"
          @blur="editName(group.key)"
        ></el-input>
        <!-- <div class="group-th sumbit">提交</div> -->
        <div class="group-th director hidden-xs-only">负责人</div>
        <div class="group-th last-time hidden-xs-only">截止时间</div>
        <div class="group-th priority hidden-xs-only">优先级</div>
        <div class="group-th progress hidden-xs-only">完成进度</div>
      </div>
      <!-- 遍历数据 -->
      <div class="task-group-data">
        <!-- 渲染任务列表 -->
        <template v-if="group.list.length > 0">
          <draggable
            :list="group.list"
            animation="300"
            :scroll="true"
            :move="onMove"
            @end="choose"
            chosenClass="chosen"
            group="demoDragTree"
            handle=".move-task-rank"
          >
            <transition-group>
              <task-list-tree
                v-for="task in group.list"
                :task="task"
                :isMe="isMe"
                :key="task.task_id"
                :nowTime="Parent.todayDate"
                :currentShowMore="Parent.currentShowMore"
                @changeLabel="$emit('changeLabel', $event)"
              ></task-list-tree>
            </transition-group>
          </draggable>
        </template>
      </div>
      <!-- 在这一行，新增一条任务 -->
      <div
        class="add-line flex flex-y-center task-add-box"
        v-if="Parent.myClerkId === Parent.currentClerkId"
      >
        <!-- 按钮 -->
        <button
          class="add-btn flex flex-y-center"
          v-if="!isAddTask"
          @click="beginAddTask(index)"
        >
          <span class="iconfont icon-add add-icon"></span>
          添加任务
        </button>
        <!-- 输入框 -->
        <input
          v-show="isAddTask"
          type="text"
          class="task-input flex1"
          v-model="newTask"
          placeholder="输入任务名称，按回车保存，按ESC取消"
          :ref="'addTaskInput_' + index"
          @keyup.13="toBlur($event, group.key)"
          @keyup.229="empty"
          @keyup.esc=";(isEscAdd = true), (escAddRef = 'addTaskInput_' + index)"
          @blur="handleNewTask(group.key)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// 导入树形任务列表组件
import TaskListTree from './TaskListTree.vue'
import draggable from 'vuedraggable'
export default {
  name: 'task-group-box',
  components: {
    TaskListTree,
    draggable
  },
  inject: ['Parent'],
  props: {
    // 组数据
    group: {
      type: Object
    },
    // 索引
    index: {
      type: Number
    },
    // 组的说明文字
    groupName: {
      type: String,
      default: '工作任务'
    },
    // 当前是否是我
    isMe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 新任务
      newTask: '',
      // 是否要在这个组新增任务
      isAddTask: false,
      // 任务名称
      groupSendName: '',
      // 是否展示任务组名称
      isShowName: false,
      // 更新前任务组名称
      oldGroupSendName: '',
      // 是否触发esc
      isEscShow: false,
      // 是否触发添加esc
      isEscAdd: false,
      escAddRef: '',
      currentInfo: {}
    }
  },
  computed: {
    ...mapState(['screenWidth']),
    groupIndex() {
      if (this.group.list.length > 0) {
        switch (this.group.list[0].group_type) {
          case 2:
            return '团队'
          case 3:
            return '协同'
        }
      }
      return this.index < 10 ? '0' + this.index : this.index
    }
  },
  methods: {
    onMove(e) {
      if (!this.isMe) {
        return false
      }
      console.log(e)
      this.currentInfo = e
    },
    choose(e) {
      if (!this.isMe) {
        return
      }
      if (Object.keys(this.currentInfo).length === 0) {
        return
      }
      this.insertBefore()
      this.currentInfo = {}
    },
    // 插入到某任务之前
    async insertBefore() {
      const draggedContext = this.currentInfo.draggedContext
      const relatedContext = this.currentInfo.relatedContext
      const formObj = { task_id: draggedContext.element.task_id }
      // 拖拽后相对于列表位置
      const positionIndex = relatedContext.list.findIndex((e) => {
        return e.task_id === draggedContext.element.task_id
      })
      // 判断长度是否是最后一个
      if (positionIndex === relatedContext.list.length - 1) {
        if (relatedContext.element._LEVEL_ === 1) {
          formObj.group_id = relatedContext.element.group_id
        } else {
          formObj.pid = relatedContext.element.pid
        }
      } else {
        formObj.next_task_id = relatedContext.list[positionIndex + 1].task_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.taskMove, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
      // 刷新任务
      this.Parent.refreshTaskList()
    },
    // 让输入框显示并聚焦
    beginAddTask(index) {
      this.isAddTask = true
      this.$nextTick(() => {
        this.$refs[`addTaskInput_${index}`].focus()
      })
      this.$emit('begin-add-task', index)
    },
    // 提交新任务
    async handleNewTask(id) {
      const val = this.newTask.trim()
      if (val.length === 0) {
        this.cancelNewTask()
        this.isEscAdd = false
        return
      }
      if (this.isEscAdd) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.cancelNewTask()
          this.isEscAdd = false
          return
        }
      }
      const formObj = {
        'task.task_name': val,
        'task.period_date': this.Parent.currentTimeStartAndEnd[0]
      }
      if (id) {
        formObj.group_id = id
      }
      console.log(formObj)
      // console.log('准备添加新任务', formObj)
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addNewTask, formObj)
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.cancelNewTask()
          // 刷新任务列表
          this.Parent.refreshTaskList()
        }
      }
      this.isEscAdd = false
    },
    // 取消提交
    cancelNewTask() {
      this.isAddTask = false
      this.newTask = ''
    },
    // 按下回车提交
    toBlur(e, args) {
      e.target.blur(args)
    },
    // 空函数
    empty() {},
    // 回车提交
    enterKey() {
      event.target.blur()
    },
    // 修改任务组
    async editName(key) {
      if (this.oldGroupSendName === this.groupSendName) {
        this.isShowName = false
        this.isEscShow = false
        return
      }
      console.log(this.isEscShow)
      if (this.isEscShow) {
        let flag = false
        if (this.groupSendName === '') {
          flag = true
        }
        if (!flag && this.oldGroupSendName !== this.groupSendName) {
          flag = true
        }
        if (flag) {
          try {
            await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
          } catch (error) {
            this.groupSendName = this.oldGroupSendName
            this.isShowName = false
            this.isEscShow = false
            return
          }
        }
      }
      const formObj = {
        group_id: key,
        group_name: this.groupSendName
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskGroupName, formObj)
      )
      if (err) {
        this.$message.error(err)
      } else {
        if (res.state === 'ok') {
          this.isShowName = false
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          if (this.groupSendName === '') {
            this.groupSendName = '工作任务'
          }
          this.oldGroupSendName = this.groupSendName
        }
      }
      this.isEscShow = false
    },
    isShowGroupName() {
      if (!this.isMe) {
        return
      }
      this.isShowName = true
      this.$nextTick(() => {
        this.$refs.taskGroupRef.focus()
      })
    }
  },
  mounted() {
    this.groupSendName = this.groupName
    this.oldGroupSendName = this.groupName
  },
  watch: {
    groupName(val) {
      this.groupSendName = val
      this.oldGroupSendName = this.groupName
    },
    isEscShow(val) {
      if (val) {
        this.$refs.taskGroupRef.blur()
      }
    },
    isEscAdd(val) {
      if (val) {
        this.$refs[this.escAddRef].blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task-group-list {
  width: 100%;
}
@import '@/assets/scss/group_box_style.scss';
.chosen {
  background-color: #f6f6f6 !important;
}
.group-header .group-name {
  width: 49% !important;
}
.group-name {
  ::v-deep .el-input__inner {
    height: 32px;
    padding: 0 7px !important;
  }
}
@media screen and (max-width: 768px) {
  .task-group-data {
    padding-left: 20px !important;
  }
  .task-group-box {
    padding-top: 10px !important;
    margin-top: 10px !important;
    padding-bottom: 10px !important;
    .tag {
      margin-top: 0;
    }
    .task-group-list {
      width: calc(100vw - 40px) !important;
    }
  }
  .group-header {
    margin-top: 0 !important;
    .group-name {
      width: 100% !important;
      padding-bottom: 10px;
    }
    &.flex-x-between {
      justify-content: start;
    }
  }
}
.group-header {
  margin-top: 5px !important;
  padding-left: 20px !important;
}
</style>
