<!--
 * @Author: hzh
 * @Date: 2021-12-30 19:13:49
 * @LastEditors: hzh
 * @LastEditTime: 2022-07-11 11:33:02
-->
<template>
  <el-dialog
    custom-class="meeting-room-occupy"
    :append-to-body="true"
    :show-close="false"
    :visible="visible"
    :top="'10vh'"
    :width="'1040px'"
    @close="close"
  >
    <template #default>
      <div class="occupy-box flex">
        <button type="button" class="close-btn" title="关闭" @click="close">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-guanbi"></use>
          </svg>
        </button>
        <div class="room-list-box flex-shrink flex flex-y-positive">
          <div class="header flex-shrink">会议室</div>
          <div class="scroll flex1">
            <div
              class="list-item e"
              :class="{ active: item.room_id === currentRoomId }"
              v-for="item in roomList"
              :key="item.room_id"
              @click="currentRoomId = item.room_id"
            >
              {{ item.room_name }}
            </div>
          </div>
        </div>
        <div class="flex1">
          <meeting-room-occupy-calendar
            ref="calendar"
            :roomId="currentRoomId"
          />
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import MeetingRoomOccupyCalendar from './meeting-room-occupy-calendar'
export default {
  name: 'meetingRoomOccupy',
  components: {
    MeetingRoomOccupyCalendar
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roomList: [],
      currentRoomId: null
    }
  },
  computed: {},
  methods: {
    // 获取会议室列表
    async getRoomList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingRommList, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.roomList = res.list
        if (res.list.length > 0) {
          this.currentRoomId = res.list[0].room_id
        }
      }
    },
    refreshRoomList() {
      this.getRoomList()
    },
    close() {
      this.$emit('update:visible', false)
    }
  },
  mounted() {
    this.refreshRoomList()
  },
  watch: {
    visible(val) {
      this.$nextTick(() => {
        if (val) {
          this.$refs.calendar.closeAllMoreList()
          this.$refs.calendar.closeAllTimeline()
        } else {
          this.$refs.calendar.toNow()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.meeting-room-occupy.el-dialog {
  width: 1040px !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0 50px 20px 0;
    box-sizing: border-box;
    height: 730px;
  }
}
</style>

<style lang="scss" scoped>
.occupy-box {
  height: 100%;
  position: relative;
}
.close-btn,
.close-btn .icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.close-btn {
  position: absolute;
  right: -36px;
  top: 13px;
}
.room-list-box {
  width: 190px;
  .header {
    height: 60px;
    line-height: 60px;
    color: #333333;
    font-weight: 700;
    padding-left: 30px;
    font-size: 16px;
    box-sizing: border-box;
  }
  .list-item {
    padding: 0 10px 0 30px;
    color: #333333;
    height: 40px;
    width: 100%;
    line-height: 40px;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
      color: #5578eb;
      font-weight: bold;
    }
  }
}
</style>
