<template>
  <div>
    <p class="arch-state line-bottom">
      对企业内部成员进行管理，可设置成员身份，或进行批量操作；试用期成员工作台通讯录将只能查看其所在的一级组织架构部门下的部门成员，会议、圈子、知识库与正式员工权限相同。
    </p>
    <div class="flex" style="position: relative">
      <!-- 左侧部门 -->
      <div class="arch-dept line-right flex-shrink">
        <div style="margin-bottom: 10px">
          <el-input
            placeholder="搜索成员和部门"
            prefix-icon="el-icon-search"
            v-model="searchKey"
            @input="handleChangeSearchKey"
          >
          </el-input>
        </div>
        <template v-if="searchKey === ''">
          <div
            style="height: calc(100% - 65px); overflow: scroll"
            v-for="item in deptData"
            :key="item.dept_id"
          >
            <div
              class="flex menu-title"
              :class="{ active: deptId == item.dept_id }"
              @click="handleChangeDept(item.dept_id)"
            >
              <i class="icon-playfill menu-wind"></i>
              <i class="icon-a-icon11 menu-icon"></i>
              {{ item.dept_name }}
            </div>
            <department-tree
              :active-id="deptId"
              :data-list="item.children"
              @change="handleChangeDept"
            ></department-tree>
          </div>
        </template>
        <template v-else>
          <search-item
            :dataList="searchDeptList"
            @change="handlerChangeSearchDept"
            v-if="searchDeptList.length > 0"
          ></search-item>
          <search-item
            :dataList="searchClerkList"
            @change="handlerChangeSearchClerk"
            v-if="searchClerkList.length > 0"
            type="people"
          ></search-item>
        </template>
      </div>
      <!-- 右侧表单内容 -->
      <div class="arch-content">
        <div class="flex flex-x-between flex-y-center arch-title">
          <div>
            <span>组织结构名称（{{ totalRow }}人）</span>
            <el-select v-model="searchForm.clerk_status" style="margin-left: 10px">
              <el-option label="全部" value=""></el-option>
              <el-option label="已激活" value="1"></el-option>
              <el-option label="已禁用" value="2"></el-option>
              <el-option label="未激活" value="4"></el-option>
              <el-option label="退出企业" value="5"></el-option>
            </el-select>
            <el-select v-model="searchForm.is_leave" style="margin-left: 10px">
              <el-option label="在职" value="0"></el-option>
              <el-option label="离职" value="1"></el-option>
            </el-select>
          </div>
          <div>
            <el-button
              size="small"
              :disabled="!selectedHasFormal"
              @click="batchProbation(selectedIdList)"
              v-if="perInfo.probation"
              >批量转试用</el-button
            >
            <el-button
              size="small"
              :disabled="!selectedHasIntern"
              @click="batchPositive(selectedIdList)"
              v-if="perInfo.positive"
              >批量转正</el-button
            >
            <!-- <el-button size="small" type="danger" plain>操作离职</el-button> -->
          </div>
        </div>
        <el-table
          class="table"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :height="'calc(100vh - 300px)'"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="clerk_name" label="姓名" width="120">
          </el-table-column>
          <el-table-column prop="clerk_alias" label="别名" width="120">
          </el-table-column>
          <el-table-column
            prop="clerk_position"
            label="职务"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="main_dept_name"
            label="主部门"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="clerk_mobile"
            label="手机"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="成员类型" width="120">
            <template #default="{ row }">{{
              row.clerk_type | renderClerkType
            }}</template>
          </el-table-column>
          <el-table-column width="180">
            <template #default="{ row, $index }">
              <el-dropdown
                @command="handleCommand($event, row, $index)"
                trigger="click"
              >
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="positive"
                    v-if="
                      perInfo.positive &&
                      row.clerk_type === 2 &&
                      row.is_leave === 0
                    "
                    >员工转正</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="probation"
                    v-if="
                      perInfo.probation &&
                      row.clerk_type === 1 &&
                      row.is_leave === 0
                    "
                    >员工转试用</el-dropdown-item
                  >
                  <el-dropdown-item command="info">查看详情</el-dropdown-item>
                  <el-dropdown-item
                    command="departure"
                    style="color: red"
                    v-if="
                      perInfo.leave &&
                      row.is_leave === 0 &&
                      row.clerk_id !== myClerkId
                    "
                    >操作离职</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="width: 100%; position: absolute; bottom: 90px">
          <el-pagination
            background
            hide-on-single-page
            @current-change="
              pageNumber = $event
              getClerkPage()
            "
            layout="prev, pager, next"
            :current-page.sync="pageNumber"
            :page-size="pageSize"
            :page-count="totalPage"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 成员详情 -->
      <member-info
        v-if="detailId != -1"
        :clerkId="detailId"
        @close="detailId = -1"
      ></member-info>
    </div>

    <!-- 操作离职弹窗 -->
    <departure-dialog
      :visible="leaveId !== -1"
      :info="leaveInfo"
      @close="leaveId = -1"
      @success="
        leaveId = -1
        this.refreshClerkPage()
      "
    ></departure-dialog>
    <!-- 转正 -->
    <el-dialog
      :title="`确定要将 “${
        currentPositiveInfo.clerk_alias || currentPositiveInfo.clerk_name
      }” 的账号设为转正吗？`"
      :visible="true"
      v-if="positiveId !== -1"
      @close="positiveId = -1"
      width="500px"
    >
      <span>确认转正后将转为正式员工，可享有正式员工的权限。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="positiveId = -1">取 消</el-button>
        <el-button type="primary" @click="batchPositive([positiveId])"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 转试用 -->
    <el-dialog
      :title="`确定要将 “${
        currentProbationInfo.clerk_alias || currentProbationInfo.clerk_name
      }” 的账号设为试用吗？`"
      :visible="true"
      v-if="probationId !== -1"
      width="500px"
      @close="positiveId = -1"
    >
      <span
        >转为试用后，工作台通讯录将只能查看其所在的一级组织架构部门，会议、圈子、知识库与正式员工权限相同。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="probationId = -1">取 消</el-button>
        <el-button type="primary" @click="batchProbation([probationId])"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DepartmentTree from '@/components/system/DepartmentTree.vue'
import DepartureDialog from '@/components/system/DepartureDialog.vue'
import SearchItem from '@/components/system/SearchItem.vue'
import MemberInfo from '@/components/system/MemberInfo.vue'
import { mapState } from 'vuex'
export default {
  components: {
    DepartmentTree,
    DepartureDialog,
    SearchItem,
    MemberInfo
  },
  data() {
    return {
      // 搜索关键字
      searchKey: '',
      // 搜索定时器
      searchTimer: null,
      // 搜索到的部门列表
      searchDeptList: [],
      // 搜索到的职员列表
      searchClerkList: [],
      // 指定职员
      specifiedClerkId: -1,
      tableData: [],
      // 选中列
      selectedList: [],
      deptData: [],
      deptId: -1,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
      searchForm: {
        is_leave: '0',
        clerk_status: '1'
      },
      // 详情id
      detailId: -1,
      // 离职id
      leaveId: -1,
      // 转正id
      positiveId: -1,
      // 转试用id
      probationId: -1
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    perInfo() {
      return {
        leave: this.$hasPer(48),
        positive: this.$hasPer(47),
        probation: this.$hasPer(85)
      }
    },
    // 选中id列表
    selectedIdList() {
      return this.selectedList.map((e) => e.clerk_id)
    },
    // 选择人员有试用人员
    selectedHasIntern() {
      if (this.selectedList.length === 0) {
        return false
      }
      return this.selectedList.some((e) => e.clerk_type === 2)
    },
    // 选择人员有正式人员
    selectedHasFormal() {
      if (this.selectedList.length === 0) {
        return false
      }
      return this.selectedList.some((e) => e.clerk_type === 1)
    },
    leaveInfo() {
      if (this.leaveId === -1) {
        return {}
      }
      const info = this.tableData.find((e) => e.clerk_id === this.leaveId)
      return {
        main_dept_name: info.main_dept_name,
        clerk_alias: info.clerk_alias,
        clerk_name: info.clerk_name,
        clerk_avatar: info.clerk_avatar,
        clerk_id: info.clerk_id
      }
    },
    // 当前转正信息
    currentPositiveInfo() {
      return this.tableData.find((e) => e.clerk_id === this.positiveId)
    },
    // 当前转试用信息
    currentProbationInfo() {
      return this.tableData.find((e) => e.clerk_id === this.probationId)
    }
  },
  filters: {
    renderClerkType(val) {
      switch (val) {
        case 1:
          return '正式员工'
        case 2:
          return '试用员工'
      }
    }
  },
  methods: {
    handleCommand(e, row, index) {
      switch (e) {
        case 'info': {
          this.detailId = row.clerk_id
          break
        }
        case 'positive': {
          this.positiveId = row.clerk_id
          // this.batchPositive([row.clerk_id])
          break
        }
        case 'probation': {
          this.probationId = row.clerk_id
          // this.batchProbation([row.clerk_id])
          break
        }
        case 'departure': {
          this.leaveId = row.clerk_id
          break
        }
      }
    },
    handleSelectionChange(val) {
      this.selectedList = val
    },
    handleChangeDept(val) {
      this.deptId = val
      this.specifiedClerkId = -1
      this.refreshClerkPage()
    },
    // 获取部门列表
    async getDeptList() {
      this.isLoadingTaskRemind = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkDeptList, {})
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.deptData = this.$util.handleTreeData(res.list, 'dept_id')
      }
    },
    // 获取职员列表
    async getClerkPage() {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        ...this.searchForm
      }
      if (this.specifiedClerkId !== -1) {
        formObj.clerk_id = this.specifiedClerkId
      } else {
        formObj.top_dept_id = this.deptId !== -1 ? this.deptId : ''
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkPage, formObj)
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        this.pageNumber = page.pageNumber
        this.totalPage = page.totalPage
        this.totalRow = page.totalRow
        this.tableData = page.list
      }
    },
    async refreshClerkPage() {
      this.pageNumber = 1
      this.totalPage = 1
      this.getClerkPage()
    },
    // 批量转正
    async batchPositive(idList) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.systemClerkBatchPositive, {
          clerk_ids: idList.join(',')
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.positiveId = -1
        this.$message({
          showClose: true,
          message: '转正成功',
          type: 'success'
        })
        this.getClerkPage()
      }
    },
    // 批量转试用
    async batchProbation(idList) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.systemClerkBatchProbation, {
          clerk_ids: idList.join(',')
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.probationId = -1
        this.$message({
          showClose: true,
          message: '转试用成功',
          type: 'success'
        })
        this.getClerkPage()
      }
    },
    // 修改搜索关键字
    handleChangeSearchKey() {
      if (this.searchKey === '') {
        this.searchDeptList = []
        this.searchClerkList = []
        this.specifiedClerkId = -1
        this.dept_id = -1
        this.refreshClerkPage()
      } else {
        if (this.searchTimer != null) {
          clearTimeout(this.searchTimer)
        }
        this.searchTimer = setTimeout(() => {
          this.searchDeptAndClerk()
        }, 500)
      }
    },
    // 搜索部门和职员
    async searchDeptAndClerk() {
      if (this.searchKey === '') {
        return
      }
      this.isLoadingTaskRemind = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.systemSearchDeptAndClerk, {
          search_key: this.searchKey
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.searchDeptList = res.dept_list
        this.searchClerkList = res.clerk_list
      }
    },
    async handlerChangeSearchDept(e) {
      this.deptId = e.dept_id
      this.specifiedClerkId = -1
      this.refreshClerkPage()
    },
    async handlerChangeSearchClerk(e) {
      this.deptId = -1
      this.specifiedClerkId = e.clerk_id
      this.refreshClerkPage()
    }
  },
  mounted() {
    this.getClerkPage()
    this.getDeptList()
  },
  watch: {
    searchForm: {
      handler() {
        this.getClerkPage()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-pagination {
  text-align: center;
}

.arch-state {
  height: 21px;
  line-height: 21px;
  padding: 14px 20px;
  color: #999;

  &.line-bottom::after {
    left: 0;
  }
}

.arch-dept {
  width: 222px;
  height: calc(100vh - 109px);
  padding: 24px 20px;

  // ::v-deep .el-input--prefix .el-input__inner {
  //   border: 1px solid #eeeeee !important;
  // }

  &.line-right::after {
    top: 0;
  }

  .menu-title {
    cursor: pointer;
    height: 46px;
    line-height: 46px;
    align-items: center;
    color: #333;

    .menu-icon {
      font-size: 20px;
      color: #bbb;
      margin-right: 10px;
    }

    .menu-wind {
      font-size: 12px;
      margin-right: 10px;
      width: 16px;
      color: #bbb;
    }

    &.active {
      color: $main-color;
      .menu-icon {
        color: $main-color;
      }
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.arch-content {
  position: relative;
  flex: 1;
  padding: 0 30px;
  .arch-title {
    padding: 33px 0;
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    color: #333333;
  }
  .el-dropdown {
    color: #0080ff;
    height: 28px;
    line-height: 28px;
    width: 74px;
    text-align: center;
    border-radius: 4px;
    &:hover {
      background: rgba(0, 128, 255, 0.1);
    }
  }
}

.table::v-deep {
  .el-checkbox__inner {
    border: 1px solid #8c8c8c;
  }
  .el-table-column--selection .cell {
    padding-left: 10px;
  }
  th.is-leaf,
  td {
    border-bottom: 0;
  }
  &::before {
    height: 0;
  }
}
</style>
