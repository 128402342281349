<template>
  <div class="flex tabs-container" :style="{justifyContent: alignment, padding: '0 ' + tabPadding + 'px'}" style="width: 100%">
    <div @click="$emit('change', index)" :class="{'active': current === index}" v-for="(item, index) in tabList" :key="index" class="tab-item" :style="{padding: '0 ' + itemPadding + 'px'}">{{item | handleTabName(tabTitle)}}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'current',
    event: 'change'
  },
  props: {
    tabList: {
      type: Array,
      default: function() {
        return []
      }
    },
    current: {
      type: Number,
      default: 0
    },
    tabTitle: {
      type: String,
      default: ''
    },
    alignment: {
      type: String,
      default: 'space-around'
    },
    tabPadding: {
      type: Number,
      default: 0
    },
    itemPadding: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-container {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  height: 56px;

  .tab-item {
    cursor: pointer;
    height: 56px;
    line-height: 56px;
    &.active {
      position: relative;
      color: $main-color;

      &::after {
        position: absolute;
        content: '';
        height: 2px;
        width: 28px;
        bottom: 0;
        left: 50%;
        background-color: $main-color;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
