<template>
  <div class="header-container">
    <div
      class="flex header-bg"
      :style="{
        'background-image': memberInfo.background_cover_access_path
          ? 'url(' + memberInfo.background_cover_access_path + ')'
          : ''
      }"
    >
      <div class="back-button" @click="$emit('goHome', $event)">
        <i class="icon-a-arrowaltright1"></i>
        返回
      </div>
      <div class="search-container">
        <input
          type="text"
          v-model="keyword"
          placeholder="搜索圈子动态内容"
          @keyup.enter="enterKey"
          @blur="$emit('onSubmit', { value: keyword })"
        />
        <i class="icon-search"></i>
      </div>
    </div>
    <div class="member-container">
      <div class="flex member-top flex-y-end">
        <div class="member-avatar">
          <el-image :src="renderClerkAvatar">
            <div slot="error" class="flex image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="member-content flex1">
          <div class="flex flex-y-center flex-x-between">
            <!-- 名字和性别 -->
            <div class="member-name">
              <span>{{ memberInfo.clerk_alias || memberInfo.clerk_name }}</span>
              <i
                :class="
                  memberInfo.clerk_gender === 1 ? 'icon-Frame10' : '#icon-nv'
                "
              ></i>
            </div>
            <!-- 关注按钮 -->
            <div
              class="attention-button"
              @click="focusClerk"
              :class="{ active: memberInfo.is_focus === 0 }"
              v-if="memberInfo.clerk_id !== myClerkId"
            >
              <i class="icon-add1" v-show="memberInfo.is_focus == 0"></i>
              {{ memberInfo.is_focus == 0 ? '关注' : '已关注' }}
            </div>
          </div>
          <!-- 个性签名 -->
          <div class="flex member-sign">
            <!-- <span class="content-lable">个性签名：</span> -->
            <p class="text-warp">
              {{ memberInfo.clerk_motto }}
            </p>
          </div>
          <div class="flex member-other">
            <!-- <div @click="clickFans">
              <span>粉丝</span>
              <span>{{ memberInfo.fans_number }}</span>
            </div>
            <div @click="clickFocus">
              <span>关注</span>
              <span>{{ memberInfo.focus_number }}</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="member-center">
        <!-- <div class="flex member-sign">
          <span class="content-lable">个性签名：</span>
          <p class="text-warp">
            {{ memberInfo.clerk_motto }}
          </p>
        </div> -->
        <!-- <div class="flex">
          <div class="member-birthday">
            <span class="content-lable"
              >生日：{{ renderTime(memberInfo.clerk_birthday || '') }}</span
            >
            <span>{{ memberInfo.birthday }}</span>
          </div>
          <div>
            <span class="content-lable"
              >入职时间：{{ renderTime(memberInfo.join_time || '') }}</span
            >
            <span>{{ memberInfo.inductionTime }}</span>
          </div>
        </div> -->
      </div>
    </div>
    <tabs
      :tab-list="tabList"
      :current="currentTab"
      @change="$emit('changeTab', $event)"
    ></tabs>
    <tips-dialog
      @cancle="cancle"
      @confirm="confirm"
      :title="title"
      v-if="visible"
    ></tips-dialog>
  </div>
</template>

<script>
import Tabs from '@/components/common/Tabs.vue'
import { mapState } from 'vuex'
import TipsDialog from '@/components/common/TipsDialog.vue'

export default {
  components: {
    Tabs,
    TipsDialog
  },
  props: {
    memberInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tabList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      keyword: '',
      visible: false,
      title: ''
    }
  },
  computed: {
    renderClerkAvatar() {
      return this.memberInfo.clerk_avatar || ''
    },
    ...mapState(['myClerkId'])
  },
  methods: {
    // 格式化时间
    renderTime(v) {
      if (v === '' || typeof v === 'undefined') {
        return ''
      }
      return (
        this.$util.getDateStr(new Date(Date.parse(v.replace(/-/g, '/')))) || ''
      )
    },
    // 关注
    focusClerk() {
      if (this.memberInfo.is_focus === 0) {
        this.addFocus()
        return
      }
      this.visible = true
      this.title = '确定要取消关注么？'
    },
    // 添加关注
    async addFocus() {
      const formObj = {
        focus_clerk_id: this.memberInfo.clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.memberInfo, 'is_focus', 1)
      }
    },
    // 删除关注
    async deleteFocus() {
      const formObj = {
        focus_clerk_id: this.memberInfo.clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.memberInfo, 'is_focus', 0)
      }
    },
    // 取消弹窗
    cancle() {
      this.visible = false
    },
    // 取消关注
    confirm() {
      this.deleteFocus()
      this.$message({
        showClose: true,
        message: '取消关注成功！',
        type: 'success'
      })
      this.visible = false
    },
    // 跳转粉丝
    clickFans() {
      this.$router.push({
        path: `/member/info/${this.memberInfo.clerk_id}`,
        query: {
          tab: 'fans'
        }
      })
    },
    // 关注
    clickFocus() {
      this.$router.push({
        path: `/member/info/${this.memberInfo.clerk_id}`
      })
    },
    enterKey() {
      event.target.blur()
    }
  },
  mounted() {
    console.log(this.$route)
  },
  watch: {}
}
</script>

<style lang="scss">
.header-container {
  .member-avatar {
    position: absolute;
    top: -25px;

    .el-image {
      width: 90px;
      height: 90px;
      border-radius: 90px;
      border: 2px solid #fff;

      .image-slot {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #f6f6f6;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-container {
    .member-avatar {
      top: 0;
      .el-image {
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.header-container {
  background-color: #fff;
  .header-bg {
    background-image: url('../../assets/img/member-bg.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 100%;
    height: 240px;
    width: 100%;
    justify-content: space-between;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .back-button {
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      height: 24px;
      line-height: 24px;
      font-weight: bold;
      margin-left: 20px;
      margin-top: 10px;
    }

    .search-container {
      position: relative;
      margin: 8px 14px;

      input {
        outline: none;
        border: none;
        width: 160px;
        line-height: 21px;
        border-radius: 42px;
        background-color: $light-grey-bg;
        padding: 6px 15px 6px 48px;
      }

      i {
        position: absolute;
        top: 8px;
        left: 19px;
        color: $icon-color;
      }
    }
  }

  .member-container {
    position: relative;
    padding: 0 30px 4px;
    border-bottom: 1px solid #eee;

    .member-top {
      height: 66px;
      margin-bottom: 24px;
      justify-content: space-between;
      align-items: center;

      .member-content {
        margin-left: 106px;
        transform: translateY(5px);

        .member-name {
          font-size: 18px;
          height: 27px;
          line-height: 27px;
          margin: 6px 0;

          span {
            font-weight: 700;
          }

          i {
            color: #5bc4ff;
            margin-left: 10px;
          }
        }
        .member-sign {
          color: #999;
        }

        .member-other {
          div {
            cursor: pointer;
            font-szie: 14px;

            span:first-child {
              color: #999;
              margin-right: 8px;
            }

            span:last-child {
              font-weight: 400;
            }

            &:first-child {
              margin-right: 20px;
            }

            &:hover {
              span {
                color: $main-color !important;
              }
            }
          }
        }
      }

      .attention-button {
        cursor: pointer;
        height: 30px;
        line-height: 28px;
        font-weight: 500;
        padding: 0px 15px;
        border-radius: 36px;
        border: 1px solid #eee;
        color: #bbb;

        &:hover {
          background-color: #eee;
        }

        &.active {
          color: #fff;
          background-color: $main-color;

          &:hover {
            background-color: rgba($color: $main-color, $alpha: 0.8);
          }
        }
      }
    }
  }

  .member-center {
    font-size: 14px;
    color: #999;

    .content-lable {
      &:first-child {
        width: 72px;
      }
    }

    .member-sign {
      margin-bottom: 16px;
      p {
        flex: 1;
        line-height: 21px;
      }
    }

    div {
      .member-birthday {
        margin-right: 100px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-container {
    .header-bg {
      background-size: cover;
    }

    .member-container {
      position: inherit;
      padding-bottom: 0;
      .member-top {
        position: absolute;
        margin-bottom: 0;
        top: 128px;
        align-items: flex-start;

        .member-content {
          margin-left: 75px;
          transform: translateY(0);

          .member-name {
            color: #fff;
          }

          .member-sign {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
