var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appwap"},[_c('div',{staticClass:"knowledge-base flex"},[_c('knowledge-tree',{staticClass:"knowledge-base-left pc",on:{"showTrainer":_vm.showTrainer,"showFolder":_vm.showFolder,"showFile":_vm.showFile}}),_c('div',{staticClass:"folder-top flex flex-x-between flex-y-center show"},[_c('div',{staticClass:"base-path flex flex-y-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.wapMenuTitle)+" "),_c('i',{staticClass:"el-icon-caret-bottom",on:{"click":_vm.knowledgebtn}})])])]),(_vm.folderVisiblewap)?_c('div',{staticClass:"knowledge-base-left wap knowledgebaseleft",on:{"click":function($event){_vm.folderVisiblewap = false}}},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"item",on:{"click":function($event){_vm.showFolder({
              folder_id: 1,
              folder_type: 1
            })
            _vm.folderVisiblewap = false
            _vm.wapMenuTitle = '公司知识库'}}},[_c('p',[_vm._v("公司知识库")])]),_c('div',{staticClass:"item",on:{"click":function($event){_vm.showFolder({
              folder_id: 2,
              folder_type: 2
            })
            _vm.folderVisiblewap = false
            _vm.wapMenuTitle = '团队知识库'}}},[_c('p',[_vm._v("团队知识库")])]),_c('div',{staticClass:"item",on:{"click":function($event){_vm.showFolder({
              folder_id: 0,
              collect_mode: true
            })
            _vm.folderVisiblewap = false
            _vm.wapMenuTitle = '我的收藏'}}},[_c('p',[_vm._v("我的收藏")])])])]):_vm._e(),_c('div',{staticClass:"knowledge-base-right flex flex-y-positive"},[_c('div',{staticClass:"base-right-content"},[(_vm.fileVisible)?_c('knowledge-file-content',{attrs:{"fileId":_vm.fileId,"collectMode":_vm.isCollectFile},on:{"toFolder":_vm.showFolder}}):_vm._e(),(_vm.folderVisible)?_c('knowledge-file-folder',{attrs:{"folderId":_vm.folderId,"collectMode":_vm.isCollectFolder},on:{"toFolder":_vm.showFolder,"toFile":_vm.showFile}}):_vm._e(),(_vm.trainerVisible)?_c('knowledge-trainer'):_vm._e()],1)])],1),(false)?_c('knowledge-permissions'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }