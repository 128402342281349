<template>
  <div>
    <message-dialog
      class="departure-dialog"
      :visible="visible"
      :mask="false"
      @close="$emit('close')"
      @click="outClick"
    >
      <template #header>
        <h3 style="margin-top: 18px; margin-bottom: 8px">离职操作</h3>
        <p style="color: #999">将成员当前的工作资源转移给其他成员</p>
      </template>
      <template #content>
        <!-- 成员信息 -->
        <div class="flex member-info">
          <div>
            <el-image :src="info.clerk_avatar">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div>
            <p>{{ info.clerk_alias || info.clerk_name }}</p>
            <p>{{ info.main_dept_name }}</p>
          </div>
        </div>

        <!-- 用户资源类型选择 -->
        <div class="resources-container">
          <h3 class="titla-bar">选择要转移的资源</h3>

          <!-- 资源类型选择 -->
          <el-checkbox-group
            v-model="checkList"
            @change="handleChangeResources"
          >
            <el-checkbox
              v-for="(item, index) in checkOptions"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>

          <!-- 资源转移提示 -->
          <div
            class="resources-tips"
            v-if="checkList.length < checkOptions.length"
          >
            <div class="tips-title">
              <i class="icon-a-InfoCircle"></i>
              系统将
            </div>
            <template v-for="(item, index) in checkOptions">
              <p
                v-if="!checkList.includes(item.id)"
                :key="index"
                class="tips-item"
              >
                {{ item.tip }}
              </p>
            </template>
          </div>
        </div>

        <!-- 设置资源接收人 -->
        <div class="receive-container">
          <div class="receive-title">设置资源接收人</div>
          <div class="receive-table line-bottom">
            <div class="flex table-header">
              <div class="receive-resources">资源</div>
              <div class="receive-describe">描述</div>
              <div class="receive-people">接收方</div>
            </div>
            <div class="table-main">
              <template v-for="(item, index) in checkOptions">
                <div
                  class="flex flex-y-center table-item"
                  :key="index"
                  v-if="checkList.includes(item.id)"
                >
                  <div class="receive-resources">
                    <i
                      :class="
                        item.id === 'meeting' ? 'icon-Calendar' : 'icon-Frame7'
                      "
                    ></i>
                    {{ item.name }}
                  </div>
                  <div class="receive-describe">
                    <p>{{ item.describe }}</p>
                    <p style="color: #bbb">{{ item.notes }}</p>
                  </div>
                  <div class="flex flex-x-between flex-y-center receive-people">
                    <div class="flex">
                      <template v-if="item.clerk_id">
                        <el-image :src="item.clerk_avatar">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                        <div>
                          <p>{{ item.clerk_alias || item.clerk_name }}</p>
                          <p style="color: #bbb">
                            {{ item.main_dept_name || '' }}
                          </p>
                        </div>
                      </template>
                      <div
                        v-if="item.showChooseClerk"
                        class="choose-person-box"
                        @click.stop=""
                      >
                        <search-person
                          :isShow="item.showChooseClerk"
                          @choose-person="chooseClerk($event, index)"
                        ></search-person>
                        <member-tree
                          :treeNode="treeNode"
                          v-for="(treeNode, cindex) in clerkTree"
                          :key="cindex"
                          @clerk-choose="chooseClerk($event, index)"
                        ></member-tree>
                      </div>
                    </div>
                    <div
                      class="edit-button"
                      @click.stop="item.showChooseClerk = true"
                    >
                      修改
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- <receive-table :table-data="receiveData"></receive-table> -->
        </div>

        <!-- 操作按钮 -->
        <div class="flex action-bar" style="margin-top: 30px">
          <el-button type="primary" size="medium" @click="showCheck">确定</el-button>
          <el-button size="medium" @click="$emit('close')">取消</el-button>
        </div>
      </template>
    </message-dialog>
    <el-dialog
      :title="`确定要将 “${
        info.clerk_alias || info.clerk_name
      }” 的账号设为离职吗？`"
      :visible="checkVisible"
      width="500px"
      @close="checkVisible = false"
    >
      <span>离职后，该账号将无法使用，且无法查看该员工历史工作信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MessageDialog from '@/components/circle/MessageDialog.vue'
import MemberTree from '@/components/common/MemberTree.vue'
import SearchPerson from '@/components/common/SearchPerson.vue'
import { mapState } from 'vuex'
// import ReceiveTable from './ReceiveTable.vue'
export default {
  components: {
    MessageDialog,
    MemberTree,
    SearchPerson
    // ReceiveTable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      checkList: ['task', 'target', 'meeting'],
      checkOptions: [
        {
          id: 'task',
          name: '任务',
          tip: '保留该员工创建的任务（包含独立的个人任务）',
          describe: '转让该员工创建的未来会议',
          notes: '',
          clerk_id: '',
          clerk_avatar: '',
          clerk_alias: '',
          clerk_name: '',
          main_dept_name: '',
          showChooseClerk: false
        },
        {
          id: 'target',
          name: '目标',
          tip: '保留该员工创建的目标（包含独立的个人目标）',
          describe: '转让该员工创建的目标',
          notes: '包含独立的个人目标',
          clerk_id: '',
          clerk_avatar: '',
          clerk_alias: '',
          clerk_name: '',
          main_dept_name: '',
          showChooseClerk: false
        },
        {
          id: 'meeting',
          name: '未来会议',
          tip: '删除该员工创建的未来会议',
          describe: '转让该员工创建的任务',
          notes: '包含独立的个人目标',
          clerk_id: '',
          clerk_avatar: '',
          clerk_alias: '',
          clerk_name: '',
          main_dept_name: '',
          showChooseClerk: false
        }
      ],
      clerkTree: [],
      // 确认展示
      checkVisible: false
    }
  },
  computed: {
    ...mapState(['clickBodyIndex'])
  },
  methods: {
    handleChangeResources(e) {
      console.log(e)
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    chooseClerk(info, index) {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (info.clerk_id === this.info.clerk_id) {
        errmsg('不能选择离职人')
        return
      }
      this.$set(this.checkOptions[index], 'clerk_id', info.clerk_id)
      this.$set(this.checkOptions[index], 'clerk_avatar', info.clerk_avatar)
      this.$set(this.checkOptions[index], 'clerk_alias', info.clerk_alias)
      this.$set(this.checkOptions[index], 'clerk_name', info.clerk_name)
      this.$set(this.checkOptions[index], 'main_dept_name', info.main_dept_name)
      this.$set(this.checkOptions[index], 'showChooseClerk', false)
    },
    outClick() {
      this.checkOptions.forEach((e, i) => {
        this.$set(this.checkOptions[i], 'showChooseClerk', false)
      })
    },
    // 确认
    async confirm() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.systemClerkLeave, {
          clerk_id: this.info.clerk_id,
          task_clerk_id: this.checkOptions.find((e) => e.id === 'task')
            .clerk_id,
          objective_clerk_id: this.checkOptions.find((e) => e.id === 'target')
            .clerk_id,
          meeting_clerk_id: this.checkOptions.find((e) => e.id === 'meeting')
            .clerk_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.checkVisible = false
        this.$message({
          showClose: true,
          message: '离职操作成功',
          type: 'success'
        })
        this.$emit('success')
      }
    },
    showCheck() {
      for (const e of this.checkList) {
        const f = this.checkOptions.find((oe) => oe.id === e)
        if (f.clerk_id === '') {
          this.$message({
            showClose: true,
            message: `请选择${f.name}交接人`,
            type: 'error'
          })
          return
        }
      }
      this.checkVisible = true
    }
  },
  mounted() {
    this.getClerkTree()
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
  },
  watch: {
    clickBodyIndex() {
      this.outClick()
    },
    info() {
      this.checkList = ['task', 'target', 'meeting']
      this.$set(this, 'checkOptions', [
        {
          id: 'task',
          name: '任务',
          tip: '保留该员工创建的任务（包含独立的个人任务）',
          describe: '转让该员工创建的未来会议',
          notes: '',
          clerk_id: '',
          clerk_avatar: '',
          clerk_alias: '',
          clerk_name: '',
          main_dept_name: '',
          showChooseClerk: false
        },
        {
          id: 'target',
          name: '目标',
          tip: '保留该员工创建的目标（包含独立的个人目标）',
          describe: '转让该员工创建的目标',
          notes: '包含独立的个人目标',
          clerk_id: '',
          clerk_avatar: '',
          clerk_alias: '',
          clerk_name: '',
          main_dept_name: '',
          showChooseClerk: false
        },
        {
          id: 'meeting',
          name: '未来会议',
          tip: '删除该员工创建的未来会议',
          describe: '转让该员工创建的任务',
          notes: '包含独立的个人目标',
          clerk_id: '',
          clerk_avatar: '',
          clerk_alias: '',
          clerk_name: '',
          main_dept_name: '',
          showChooseClerk: false
        }
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
.member-info {
  margin: 24px 0 36px;

  .el-image {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    margin-right: 14px;
  }

  p {
    padding: 2px 0;

    &:last-child {
      color: #999;
    }
  }
}

.resources-container {
  .titla-bar {
    position: relative;
    font-weight: normal;
    padding-left: 16px;
    margin-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 17px;
      background-color: #0080ff;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-radius: 4px;
    }
  }

  .el-checkbox-group {
    padding-left: 16px;
    margin-bottom: 21px;
  }

  .resources-tips {
    width: 100%;
    padding: 14px 18px;
    background-color: #feead2;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    margin-bottom: 44px;

    div {
      line-height: 21px;
      i {
        color: #ff8a00;
      }
    }

    p {
      margin-left: 20px;
      line-height: 21px;
    }
  }
}

.receive-container {
  .receive-title {
    margin-bottom: 14px;
  }
}

.action-bar {
  .el-button {
    width: 84px;
    &:first-child {
      background-color: $main-color;
    }
  }
}

.receive-table {
  position: relative;
  width: 100%;
  transform: none;

  .table-header {
    height: 34px;
    line-height: 34px;
    padding: 0 12px;
    background-color: #f6f6f6;
  }

  .table-main {
    position: relative;
  }

  .receive-resources {
    width: 100px;
  }

  .receive-describe {
    flex: 1;
  }

  .receive-people {
    position: relative;
    width: 180px;
    .el-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      background-color: #f5f5f5;
      margin-right: 14px;
    }

    .edit-button {
      position: relative;
      cursor: pointer;
      color: $main-color;
    }
  }

  .table-main {
    padding: 0 12px;
    .table-item {
      height: 70px;
    }
  }
}

.choose-person-box {
  position: absolute;
  top: 20px;
  left: -100px;
  z-index: 2;
  margin: 10px 0;
  width: 200px;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  border-radius: 4px;

  ::v-deep {
    .search-line + div {
      max-height: 280px;
    }
  }
}
.departure-dialog ::v-deep {
  .dialog-main .dialog-container {
    height: calc(100vh - 80px);
  }
}
</style>
