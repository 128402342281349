/*
 * @Author: hzh
 * @Date: 2021-12-02 09:51:24
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-02 11:14:24
 */

export default {
  install(Vue) {
    Vue.mixin({
      created() {
        if (this.$options.events) {
          Object.keys(this.$options.events).forEach(key => {
            let handler = this.$options.events[key]
            if (typeof handler === 'string') {
              handler = this[handler]
            }
            this[`${key}::handler`] = handler.bind(this)
            this.$root.$on(`global::${key}`, this[`${key}::handler`])
          })
        }
      },
      beforeDestroy() {
        if (this.$options.events) {
          Object.keys(this.$options.events).forEach(key => {
            this.$root.$off(`global::${key}`, this[`${key}::handler`])
          })
        }
      }
    })

    Vue.prototype.$globalEmit = function (key, data) {
      this.$root.$emit(`global::${key}`, data)
    }
  }
}
