<!--
 * @Author: hzh
 * @Date: 2021-08-11 19:51:59
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-19 18:05:01
-->
<template>
  <div class="topic-video">
    <video controls="controls" :src="url"></video>
    <i class="icon-decline delete-button" @click="$emit('onDelete')"></i>
  </div>
</template>

<script>
export default {
  props: ['url']
}
</script>

<style lang="scss" scoped>
.topic-video {
  position: relative;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 24px;

  video {
    width: 100%;
    height: 340px;
  }

  .delete-button {
    position: absolute;
    padding: 6px;
    color: $main-color;
    font-weight: 400;
    font-size: 14px;
    background: #e6f3ff;
    border-radius: 0 5px 0 5px;
    right: 0;
    z-index: 2;
  }
}

@media screen and (max-width: 767px) {
  .topic-video {
    video {
      height: 180px;
    }
  }
}
</style>
