<!--
 * @Date: 2021-07-21 23:28:00
 * @LastEditTime: 2022-07-04 10:58:54
 * @Description: 圈子
 * @Author: lyq
 * @LastEditors: hzh
-->
<template>
  <div class="flex circle-main-container">
    <!-- 圈子-动态区 -->
    <div class="left-bar">
      <div
        class="icon-Profile2 circle-mobile-button"
        v-if="isCirleIndex"
        @click="handerMenu(3)"
      ></div>
      <div
        class="icon-a-Group2031 circle-mobile-button"
        v-if="isCirleIndex"
        @click="sendMoment"
      ></div>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>

    <!-- 圈子-右侧工具栏 -->
    <div class="right-bar hidden-sm-and-down">
      <div class="member-bar">
        <!-- 圈子-用户搜索栏 -->
        <div class="search-container">
          <input
            type="text"
            v-model="searchKey"
            @keyup.enter="enterKey"
            @blur="refreshList"
            placeholder="搜索同事"
          />
          <i class="icon-search"></i>
        </div>
        <!-- 圈子-个人操作菜单 -->
        <member-menu
          :current="currentMenu"
          :data-list="menu"
          @change="handerMenu"
        ></member-menu>

        <div class="release-button" @click="sendMoment">发动态</div>
      </div>

      <!-- 圈子-部门菜单 -->
      <!-- <div class="department-bar">
        <div
          v-for="item in departmentList"
          :key="item.id"
          @click="handleChangeDept(item.id)"
        >
          <div class="flex menu-title" :class="{ active: deptId == item.id }">
            <i class="icon-a-icon11 menu-icon"></i>
            {{ item.name }}
          </div>
          <department-tree
            :active-id="deptId"
            @change="handleChangeDept"
            :data-list="item.child"
          ></department-tree>
        </div>
      </div> -->
    </div>
    <edit-message
      :visible="editVisible"
      @addComplete="addComplete"
      @editComplete="editComplete"
      :momentId="editMomentId"
      @close="closeEdit"
      @keyup.esc.native="editVisible = false"
    ></edit-message>
  </div>
</template>

<script>
// 用户菜单
import MemberMenu from '@/components/circle/MemberMenu.vue'

// 无限级部分菜单
// import DepartmentTree from '@/components/circle/DepartmentTree.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
import { EventBusEmit, EventBusOn, EventBusOff } from '@/util/EventBus.js'

import { mapState } from 'vuex'
export default {
  components: {
    MemberMenu,
    // DepartmentTree,
    EditMessage
  },
  data() {
    return {
      // 修改展示
      editVisible: false,
      currentMenu: 0,
      // 修改的id
      editMomentId: -1,
      // 我的关注
      myFocus: '',
      menu: [
        {
          activeIcon: '#icon-Duotone',
          icon: '#icon-Duotone_1',
          title: '全部动态'
        },
        {
          activeIcon: '#icon-Heart_1',
          icon: '#icon-Heart',
          title: '我的关注'
        },
        {
          activeIcon: '#icon-a-MoreCircle1',
          icon: '#icon-a-MoreCircle',
          title: '我的消息',
          name: 'home',
          badgeNum: 0
        },
        {
          activeIcon: '#icon-Profile_1',
          icon: '#icon-Profile2',
          title: '个人主页'
        }
      ],
      // 选中的部门
      deptId: '',
      // 搜索关键字
      searchKey: '',
      isCirleIndex: false,
      praiseNum: 0,
      mentionNum: 0,
      commentMentionNum: 0,
      commentNum: 0
    }
  },
  computed: {
    ...mapState(['myClerkId', 'clerkTree']),
    // 部门树
    departmentList() {
      const fn = (list) => {
        return list.map((e) => {
          const data = {
            id: `${e.dept_id}`,
            icon: 'icon-dianicon',
            name: e.dept_name
          }
          if (e.children) {
            data.child = fn(e.children)
          }
          return data
        })
      }

      return this.clerkTree ? fn(this.clerkTree) : []
    }
  },
  methods: {
    // 添加圈子回调
    addComplete() {
      this.editVisible = false
      this.refreshList()
    },
    // 修改圈子回调
    editComplete() {
      this.editVisible = false
      this.refreshList()
    },
    // 刷新列表
    async refreshList() {
      EventBusEmit('refreshMomentList', {
        my_focus: this.myFocus,
        dept_id: this.deptId,
        search_key: this.searchKey
      })
    },
    // 发动态
    sendMoment() {
      this.editMomentId = -1
      this.editVisible = true
    },
    // 关闭编辑弹窗
    closeEdit() {
      this.editVisible = false
    },
    // 处理菜单
    handerMenu(index) {
      this.currentMenu = index
      switch (index) {
        case 0: {
          const query = { ...this.$route.query }
          delete query.my_focus
          delete query.dept_id
          this.$router.push({
            name: 'circleIndex',
            query: query
          })
          break
        }
        // this.myFocus = ''
        // this.refreshList()
        case 1:
          {
            const query = { ...this.$route.query }
            delete query.dept_id
            this.$router.push({
              name: 'circleIndex',
              query: {
                ...query,
                my_focus: 1
              }
            })
          }
          break
        case 2: {
          this.$router.push({
            name: 'circleMessage'
          })
          break
        }
        case 3: {
          this.$router.push({
            name: 'memberHome',
            params: {
              clerkId: this.myClerkId
            }
          })
          // this.$router.push(`/member/home?clerk_id=${this.myClerkId}`)
          break
        }
      }
    },
    // 详情跳转修改
    detailToEdit() {
      this.editMomentId = this.detailMomentId
      this.detailMomentId = -1
      this.detailVisible = false
      this.editVisible = true
    },
    handleChangeDept(id) {
      this.currentMenu = -1
      this.myFocus = ''
      const query = { ...this.$route.query }
      delete query.dept_id
      delete query.my_focus
      this.$router.push({
        name: 'circleIndex',
        query: this.deptId !== id ? { ...query, dept_id: id } : query
      })
    },
    // 回车搜索
    enterKey() {
      event.target.blur()
    },
    // 圈子消息评论数量
    async getCommentCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const index = this.menu.findIndex((e) => {
          return e.name === 'home'
        })
        this.menu[index].badgeNum = this.menu[index].badgeNum + res.count
        this.commentNum = res.count
      }
    },
    // 圈子消息点赞数量
    async getPraiseCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getPraiseCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const index = this.menu.findIndex((e) => {
          return e.name === 'home'
        })
        this.menu[index].badgeNum = this.menu[index].badgeNum + res.count
        this.praiseNum = res.count
      }
    },

    // 圈子消息@数量
    async getMntionCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMntionCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const index = this.menu.findIndex((e) => {
          return e.name === 'home'
        })
        this.menu[index].badgeNum = this.menu[index].badgeNum + res.count
        this.mentionNum = res.count
      }
    },
    // 圈子消息@评论数量
    async getCommnetMentionCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentMntionCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const index = this.menu.findIndex((e) => {
          return e.name === 'home'
        })
        this.menu[index].badgeNum = this.menu[index].badgeNum + res.count
        this.commentMentionNum = res.count
      }
    }
  },
  mounted() {
    this.isCirleIndex = this.$route.path === '/circle/index'
    // 我的关注
    if (this.$route?.query?.my_focus) {
      this.myFocus = 1
      this.currentMenu = 1
    } else {
      this.myFocus = ''
      this.currentMenu = 0
    }
    // 部门
    if (this.$route?.query?.dept_id) {
      this.deptId = this.$route.query.dept_id
    }
    this.refreshList()
    // 获取消息数量
    this.getCommentCount()
    this.getPraiseCount()
    this.getMntionCount()
    this.getCommnetMentionCount()
    EventBusOn('getMobileTabs', (e) => {
      if (e.pageName === 'circleIndex') {
        this.handerMenu(parseInt(e.current))
      }
    })
    EventBusOn('clearMomentMessage', (e) => {
      const index = this.menu.findIndex((e) => {
        return e.name === 'home'
      })
      switch (e) {
        case 'praise': {
          const praise = this.menu[index].badgeNum - this.praiseNum
          this.menu[index].badgeNum = praise > 0 ? praise : 0
          break
        }
        case 'mention': {
          const mention = this.menu[index].badgeNum - this.mentionNum
          this.menu[index].badgeNum = mention > 0 ? mention : 0
          break
        }
        case 'comment': {
          const comment = this.menu[index].badgeNum - this.commentNum
          this.menu[index].badgeNum = comment > 0 ? comment : 0
          break
        }
        case 'commnetMention': {
          const commnetMention =
            this.menu[index].badgeNum - this.commentMentionNum
          this.menu[index].badgeNum = commnetMention > 0 ? commnetMention : 0
          break
        }
      }
    })
  },
  destroyed() {
    EventBusOff('getMobileTabs')
  },
  watch: {
    $route(val, old) {
      console.log(val.path)
      this.isCirleIndex = val.path === '/circle/index'
      let isRefreshList = false
      // 部门
      if (val?.query?.dept_id !== old?.query?.dept_id) {
        this.deptId = val?.query?.dept_id
        isRefreshList = true
      }
      // 我的关注
      if (val?.query?.my_focus !== old?.query?.my_focus) {
        if (val?.query?.my_focus) {
          this.myFocus = 1
          this.currentMenu = 1
        } else {
          this.myFocus = ''
          this.currentMenu = 0
        }
        isRefreshList = true
      }

      if (isRefreshList) {
        this.refreshList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-main-container {
  width: 920px;
  margin: 0 auto 26px;
  justify-content: space-between;
  height: calc(100vh - 90px);

  .left-bar {
    // position: relative;
    width: calc(100% - 280px);
    .circle-mobile-button {
      display: none;
    }

    @media screen and (max-width: 767px) {
      .circle-mobile-button {
        display: inherit;
        position: fixed;
        top: 15px;
        z-index: 4;
        font-size: 24px;
        color: #0080ff;

        &.icon-Profile2 {
          left: 10px;
        }

        &.icon-a-Group2031 {
          right: 20px;
        }
      }
    }
  }

  .right-bar {
    position: fixed;
    z-index: 2;
    width: 228px;
    background-color: #fff;
    border-radius: 6px;
    padding: 20px 16px;
    margin-left: 680px;
    margin-top: 24px;
    height: calc(100vh - 150px);
    overflow: hidden;

    .member-bar {
      // position: relative;
      padding-bottom: 36px;

      .search-container {
        position: relative;
        margin: 4px 0;

        input {
          outline: none;
          border: none;
          width: 165px;
          height: 22px;
          border-radius: 42px;
          background-color: $light-grey-bg;
          padding: 10px 15px 10px 48px;
        }

        i {
          position: absolute;
          top: 13px;
          left: 19px;
          color: $icon-color;
        }
      }

      .release-button {
        cursor: pointer;
        height: 21px;
        line-height: 21px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        border-radius: 42px;
        background: linear-gradient(90deg, #0075ff 0%, #00d1ff 100%);
        padding: 10px 0;
        font-weight: 500;
      }
    }

    .department-bar {
      .menu-title {
        cursor: pointer;
        height: 46px;
        line-height: 46px;
        align-items: center;
        color: #333;
        padding-left: 28px;

        .menu-icon {
          font-size: 18px;
          color: #bbb;
          margin-right: 10px;
        }

        &.active {
          color: $main-color;
          .menu-icon {
            color: $main-color;
          }
        }

        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
  }
}
</style>
