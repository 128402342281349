<!--
 * @Date: 2021-06-19 16:36:18
 * @LastEditTime: 2021-08-20 16:47:18
 * @Description: 评论组件
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <!-- 每一条评论数据 -->
  <div class="comment-item flex flex-y-start">
    <!-- 左边，头像 -->
    <div class="avatar-box">
      <avatar
        :userInfo="{
          clerk_name: comment.clerk_name,
          clerk_alias: comment.clerk_alias,
          clerk_avatar: comment.clerk_avatar
        }"
        :size="40"
        :distanceRight="0"
      ></avatar>
    </div>
    <!-- 右边，文字 -->
    <div class="flex1">
      <div
        class="flex flex-y-start flex-x-between"
        style="position: relative"
        @mouseover="showDelete"
        @mouseout="hideDelete"
      >
        <!-- 左边，用户名和时间 -->
        <div>
          <!-- 用户名 -->
          <p
            class="comment-name e"
            :title="comment.clerk_alias || comment.clerk_name"
            @click="setReplyObject(comment)"
          >
            {{ comment.clerk_alias || comment.clerk_name }}
          </p>
          <!-- 时间 -->
          <p class="comment-time">
            {{ $util.getTimeStr(comment.create_time) }}
          </p>
        </div>
        <!-- 右边，删除和点赞 -->
        <div class="flex flex-y-center handle-box">
          <!-- 删除 -->
          <div
            class="delete-text"
            v-if="comment.create_clerk_id === myClerkId"
            @click="removeComment(comment.comment_id)"
            v-show="isShowDelete"
          >
            删除
          </div>
          <!-- 点赞 -->
          <praise :comment="comment" :isTarget="isTarget"></praise>
        </div>
      </div>
      <!-- 评论的内容 -->
      <span
        class="comment-content"
        v-html="getCommentHtml(comment).replace(/\[[^\[\]]*\]/gi, emotion)"
        @click="setReplyObject(comment)"
      ></span>
      <!-- 它的回复 -->
      <div
        class="comment-item child-comment"
        @mouseover.stop="showChildDelete(index)"
        @mouseout.stop="hideChildDelete(index)"
        v-for="(child, index) in childList"
        :key="child.comment_id"
      >
        <div class="flex flex-y-center">
          <!-- 左边，头像 -->
          <div class="avatar-box">
            <avatar
              :userInfo="{
                clerk_name: child.clerk_name,
                clerk_alias: child.clerk_alias,
                clerk_avatar: child.clerk_avatar
              }"
              :size="40"
              :distanceRight="0"
            ></avatar>
          </div>
          <!-- 右边，名字和时间 -->
          <div class="flex1 flex flex-y-start flex-x-between">
            <div>
              <!-- 用户名 -->
              <p
                class="comment-name e"
                :title="child.clerk_alias || child.clerk_name"
                @click="setReplyObject(child)"
              >
                {{ child.clerk_alias || child.clerk_name }}
              </p>
              <!-- 时间 -->
              <p class="comment-time">
                {{ $util.getTimeStr(child.create_time) }}
              </p>
            </div>
            <!-- 点赞和删除 -->
            <div class="flex flex-y-center flex-x-end handle-box handle-box2">
              <!-- 删除 -->
              <div
                class="delete-text child"
                v-if="child.create_clerk_id === myClerkId"
                v-show="child.isShowDelete"
                @click="removeComment(child.comment_id)"
              >
                删除
              </div>
              <!-- 点赞 -->
              <praise :comment="child" :isTarget="isTarget"></praise>
            </div>
          </div>
        </div>
        <!-- 评论的内容 -->
        <span class="comment-content" @click="setReplyObject(child)">
          <span v-if="child.pid !== child.tid"
            >回复了{{
              child.parent_clerk_alias || child.parent_clerk_name
            }}：</span
          ><span
            v-html="getCommentHtml(child).replace(/\[[^\[\]]*\]/gi, emotion)"
          ></span>
        </span>
      </div>
      <!-- 查看更多 -->
      <div
        class="flex flex-y-center more"
        @click="loadMoreChild(childPageNumber)"
        v-if="
          comment.reply_number >= 3 && childList.length < comment.reply_number
        "
      >
        <span class="see-more">查看更多</span>
        <span class="iconfont icon-arrowaltright"></span>
      </div>
    </div>
  </div>
</template>

<script>
// 导入头像组件
import Avatar from '../common/Avatar.vue'
// 导入点赞组件
import Praise from './Praise.vue'
import { emojisMap } from '@/components/common/ExpressionBox.vue'
import { mapState } from 'vuex'
export default {
  name: 'comment-item',
  components: {
    Avatar,
    Praise
  },
  inject: ['Parent'],
  props: {
    // 评论本身
    comment: {
      type: Object
    },
    // 开始和结束时间
    startAndEndTime: {
      type: Array
    },
    // 目标id
    targetId: {
      type: Number
    },
    // 是目标评论还是周期评论（默认是周期）
    isTarget: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['myClerkId'])
  },
  data() {
    return {
      // 子评论加载到第几页
      childPageNumber: 1,
      // 子评论共几页
      childTotalPage: 1,
      chidlTotalRow: 0,
      // 子评论数据
      childList: [],
      // 子评论每页多大
      childPageSize: 10,
      // 是否展示删除
      isShowDelete: false
    }
  },
  mounted() {
    if (this.comment.child_list.length > 0) {
      this.childList = [...this.comment.child_list]
    }
  },
  methods: {
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
    },
    // 获取评论内容html
    getCommentHtml(e) {
      if (!e.clerk_list) {
        return e.comment_content || ''
      }
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = e.comment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    showDelete() {
      this.isShowDelete = true
    },
    hideDelete() {
      this.isShowDelete = false
    },
    // 设置回复对象
    setReplyObject(comment) {
      console.log('我准备回复了', comment)
      // 把评论的一些信息传给父组件
      this.$emit('to-reply', {
        clerk_id: comment.create_clerk_id,
        clerk_alias: comment.clerk_alias,
        clerk_name: comment.clerk_name,
        pid: comment.comment_id
      })
    },
    // 加载一页子评论数据
    async loadMoreChild(page = 1) {
      const formObj = {
        pageNumber: this.childPage,
        pageSize: this.childPageSize,
        tid: this.comment.comment_id
      }
      // 记录要提交的接口
      let link = ''
      // 如果是周期评论，还要传这些参数
      if (!this.isTarget) {
        // formObj.period_start_date = this.startAndEndTime[0]
        // formObj.period_end_date = this.startAndEndTime[1]
        formObj.comment_clerk_id = this.Parent.currentClerkId
        link = this.$api.targetPeriodChildPage
      } else {
        // 如果是目标评论，还要传这些参数
        formObj.objective_id = this.targetId
        link = this.$api.targetCommentChildPage
      }
      // 发请求加载
      const [err, res] = await this.$util.to(this.$http.post(link, formObj))
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 填充子评论数据
        if (page === 1) {
          this.childList = res.page.list
        } else {
          this.childList = [...this.childList, ...res.page.list]
        }
        this.childTotalPage = res.page.totalPage
        this.childPageNumber = res.page.pageNumber
      }
    },
    // 是否显示外层评论的删除按钮
    toggleMenu() {
      this.isShowMore = !this.isShowMore
    },
    // 是否显示子评论的删除按钮
    showChildDelete(index) {
      for (let i = 1; i < this.childList.length; i++) {
        this.$set(this.childList[i - 1], 'isShowDelete', false)
      }
      this.$set(this.childList[index], 'isShowDelete', true)
    },
    hideChildDelete(index) {
      for (let i = 1; i < this.childList.length; i++) {
        this.$set(this.childList[i - 1], 'isShowDelete', false)
      }
      this.$set(this.childList[index], 'isShowDelete', false)
    },
    // 删除评论
    removeComment(id) {
      let link = ''
      // 如果是周期评论，到这个接口删除数据
      if (!this.isTarget) {
        link = this.$api.deleteTargetPeriodComment
      } else {
        // 否则到这个接口删除任务评论
        link = this.$api.deleteTargetComment
      }
      this.$util.showConfirm('确定要删除这条评论吗？').then((res) => {
        if (res) {
          this.$http
            .post(link, {
              comment_id: id
            })
            .then((result) => {
              if (result.state === 'ok') {
                this.$message({
                  showClose: true,
                  message: '评论删除成功',
                  type: 'success'
                })
                this.isShowMore = false
                if (!this.isTarget) {
                  this.Parent.refreshTargetCommentData()
                } else {
                  this.Parent.refreshCommentData()
                }
              }
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/comment_style.scss';
</style>
