<template>
  <!-- 知识库左侧树形结构" -->
  <el-tree
    :data="tree"
    :indent="25"
    :load="load"
    :props="{ isLeaf: 'leaf' }"
    lazy
    :current-node-key="currentKey"
    :expand-on-click-node="false"
    @node-click="handleNodeClick"
    node-key="id"
  >
    <template #default="{ data }">
      <div class="custom-tree-node e">
        <span class="flex flex-y-center">
          <i class="iconfont" :class="data.icon"> </i>
          <p class="e">
            {{ data.label }}
          </p>
        </span>
      </div>
    </template>
  </el-tree>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'knowledge-tree',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 公司树
      companyTree: [],
      // 团队树
      teamTree: [],
      // 收藏树
      collectTree: [],
      // 当前选择的key
      currentKey: 'company'
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    // 树
    tree: {
      get() {
        return [
          {
            id: 'trainer',
            label: '培训师库',
            icon: 'icon-Profile3',
            leaf: true
          },
          {
            id: 'company',
            label: '公司知识库',
            icon: 'icon-a-icon11',
            children: this.companyTree
          },
          {
            id: 'team',
            label: '团队知识库',
            icon: 'icon-a-3User',
            children: this.teamTree
          },
          {
            id: 'collect',
            label: '我的收藏',
            icon: 'icon-Profile_11',
            children: this.collectTree
          }
          // {
          //   id: 'add',
          //   label: '创建知识库',
          //   icon: 'icon-add2'
          // }
        ]
      }
    }
  },
  methods: {
    // 点击事件
    handleNodeClick(data) {
      this.currentKey = data.id
      // 导师
      if (data.id === 'trainer') {
        this.$emit('showTrainer')
        return
      }
      // 添加
      if (data.id === 'add') {
        this.$emit('add')
        return
      }
      // 公司首页文件夹
      if (data.id === 'company') {
        this.$emit('showFolder', {
          folder_id: 1,
          folder_type: 1
        })
        return
      }
      // 公司文件夹
      if (data.id.indexOf('company_folder_') === 0) {
        this.$emit('showFolder', {
          folder_id: Number(/^company_folder_(.*)$/.exec(data.id)[1]),
          folder_type: 1
        })
        return
      }
      // 公司文件
      if (data.id.indexOf('company_file_') === 0) {
        this.$emit('showFile', {
          file_id: Number(/^company_file_(.*)$/.exec(data.id)[1])
        })
        return
      }
      // 团队首页文件夹
      if (data.id === 'team') {
        this.$emit('showFolder', {
          folder_id: 2,
          folder_type: 2
        })
        return
      }
      // 团队文件夹
      if (data.id.indexOf('team_folder_') === 0) {
        this.$emit('showFolder', {
          folder_id: Number(/^team_folder_(.*)$/.exec(data.id)[1]),
          folder_type: 2
        })
        return
      }
      // 团队文件
      if (data.id.indexOf('team_file_') === 0) {
        this.$emit('showFile', {
          file_id: Number(/^team_file_(.*)$/.exec(data.id)[1])
        })
        return
      }
      // 收藏首页文件夹
      if (data.id === 'collect') {
        this.$emit('showFolder', {
          folder_id: 0,
          collect_mode: true
        })
        return
      }
      // 收藏文件夹
      if (data.id.indexOf('collect_folder_') === 0) {
        this.$emit('showFolder', {
          folder_id: Number(/^collect_folder_(.*)$/.exec(data.id)[1]),
          collect_mode: true
        })
        return
      }
      // 收藏文件
      if (data.id.indexOf('collect_file_') === 0) {
        this.$emit('showFile', {
          file_id: Number(/^collect_file_(.*)$/.exec(data.id)[1]),
          collect_mode: true
        })
        return
      }
      console.log(data)
    },
    // 获取公司列表
    async getCompanyList(id, resolve) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlList, {
          folder_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        resolve([
          ...res.folder_list.map((e) => {
            return {
              id: `company_folder_${e.folder_id}`,
              label: e.folder_name
            }
          }),
          ...res.file_list.map((e) => {
            return {
              id: `company_file_${e.knowl_id}`,
              label: e.knowl_name,
              leaf: true
            }
          })
        ])
      }
    },
    // 获取团队列表
    async getTeamList(id, resolve) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlList, {
          folder_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        resolve([
          ...res.folder_list.map((e) => {
            return {
              id: `team_folder_${e.folder_id}`,
              label: e.folder_name
            }
          }),
          ...res.file_list.map((e) => {
            return {
              id: `team_file_${e.knowl_id}`,
              label: e.knowl_name,
              leaf: true
            }
          })
        ])
      }
    },
    // 获取收藏列表
    async getCollectList(id, resolve) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlCollectList, {
          folder_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        resolve([
          ...res.folder_list.map((e) => {
            return {
              id: `collect_folder_${e.folder_id}`,
              label: e.folder_name
            }
          }),
          ...res.file_list.map((e) => {
            return {
              id: `collect_file_${e.knowl_id}`,
              label: e.knowl_name,
              leaf: true
            }
          })
        ])
      }
    },
    // 加载
    async load(node, resolve) {
      if (node.level === 0) {
        return resolve(this.tree)
      }
      const getTopParent = (n) => {
        if (n.level !== 1) {
          return getTopParent(n.parent)
        } else {
          return n
        }
      }
      const topParent = getTopParent(node)
      switch (topParent.key) {
        // 公司
        case 'company': {
          if (node.key === 'company') {
            // 顶层
            this.getCompanyList(1, resolve)
          } else {
            // 子级
            this.getCompanyList(
              /^company_folder_(.*)$/.exec(node.key)[1],
              resolve
            )
          }
          break
        }
        // 公司
        case 'team': {
          if (node.key === 'team') {
            this.getTeamList(2, resolve)
          } else {
            this.getTeamList(/^team_folder_(.*)$/.exec(node.key)[1], resolve)
          }
          break
        }
        // 收藏
        case 'collect': {
          if (node.key === 'collect') {
            this.getCollectList(0, resolve)
          } else {
            this.getCollectList(
              /^collect_folder_(.*)$/.exec(node.key)[1],
              resolve
            )
          }
          break
        }
      }
    }
  },
  mounted() {},
  watch: {
    value(val) {}
  }
}
</script>
<style lang="scss" scoped>
.el-tree::v-deep {
  background: $light-grey-bg;
  .el-tree-node__content {
    height: 50px;
    background: $light-grey-bg;

    &:hover {
      background: #f0f0f0;
    }
  }
  .el-tree-node__children {
    .iconfont {
      display: none;
    }
    .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
      display: none;
    }
  }
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #f0f0f0 !important;
  }
  .custom-tree-node {
    width: calc(100% - 26px);

    .iconfont {
      margin-left: 6px;
      font-size: 23px;
      color: #999999;
    }
    p {
      margin-left: 6px;
      font-style: normal;
      font-size: 14px;
      color: #666666;
    }
  }
}
// 方向图标修改
.el-tree::v-deep .el-icon-caret-right:before {
  content: '\e63a'; //在引入的iconfont文件夹找到iconfont.css
  font-size: 16px;
  font-style: normal;
  font-family: 'iconfont'; //想要显示icon这个必须加
  margin-left: 20px;
}
.el-tree::v-deep
  .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  content: '\e63b';
  font-size: 6px;
  font-style: normal;
  font-family: 'iconfont';
}
.el-tree::v-deep .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.el-tree::v-deep .el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
  padding: 0;
  margin: -3px;
}
.el-tree::v-deep .el-tree-node__expand-icon.is-leaf {
  padding: 0;
  margin: 13px;
}
.el-tree::v-deep .el-tree-node__expand-icon.el-icon-caret-right {
  padding: 0;
}

@media screen and (max-width: 960px) {
  .el-tree::v-deep {
    .el-tree-node__content {
      height: 50px;
      background: #ffffff;
    }
  }
}
</style>
