<template>
  <div
    class="push-content-edit"
    v-loading.fullscreen.lock="adding"
    element-loading-background="rgba(242, 246, 252, 0.3)"
  >
    <div
      class="
        flex flex-x-between flex-y-center
        push-content-edit_header
        line-bottom
      "
    >
      <div>
        <el-button size="small" @click="back()">返回</el-button>
      </div>
      <div>
        <el-button
          class="button-delete"
          size="small"
          v-if="pushId !== -1"
          @click="deletePushuInfo"
          >删除</el-button
        >
        <el-button
          class="button-draft"
          size="small"
          v-if="pushInfo.push_status !== 3"
          @click="releaseDynamics(1)"
          >存草稿</el-button
        >
        <el-button
          class="button-release"
          size="small"
          @click="releaseDynamics(2)"
          :loading="adding"
          >{{ adding ? '提交中' : '提交' }}</el-button
        >
      </div>
    </div>
    <div class="push-content-container">
      <div class="push-content-time flex flex-x-between">
        <div>
          <span>推送时间：</span>
          <el-date-picker
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="pushInfo.push_time"
            :picker-options="pickerOptions"
            placeholder="选择日期时间"
            :disabled="pushInfo.push_status === 3"
          >
            >
          </el-date-picker>
        </div>
        <div>
          <span>推送账号：</span>
          <el-select
            filterable
            :disabled="pushInfo.push_status === 3"
            placeholder="请选择推送账号"
            v-model="clerkId"
          >
            <el-option
              style="height: 50px"
              v-for="item in clerkList"
              :key="item.clerk_id"
              :value="item.clerk_id"
              :label="item.clerk_alias || item.clerk_name"
            >
              <div class="flex flex-y-center result-item">
                <el-image :src="item.clerk_avatar || ''">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <span class="name">{{
                  item.clerk_alias || item.clerk_name
                }}</span>
                <span class="type">{{ item.main_dept_name }}</span>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>
      <input
        class="push-content-title"
        placeholder="请编辑标题（选填）"
        v-model="momentTitle"
        type="text"
      />
      <div class="push-content-content">
        <!-- <el-input
          class="textarea"
          type="textarea"
          :rows="20"
          v-model="momentContent"
          placeholder="编辑通知内容"
        >
        </el-input> -->
        <AMention v-model="momentContent"  @paste="handlerPaste" ref="contentRef"></AMention>
      </div>
      <div class="push-content-resources flex flex-shrink flex-wrap">
        <!-- 图片列表 -->
        <edit-message-image
          v-if="momentInfo.content_type === 1"
          @imageChange="imageChange($event)"
          @onDelte="onDeleteImage"
          :imageList="imageFileList"
          @editSort="editImageSort"
        ></edit-message-image>
        <!-- <div
          class="image-container"
          style="width: 120px; height: 120px"
          v-for="(item, index) in imgs"
          :key="index"
        >
          <el-image style="width: 100%; height: 100%"></el-image>
          <i class="icon-decline"></i>
        </div> -->

        <!-- 视频 -->
        <edit-message-video
          :url="renderVideoUrl"
          v-if="momentInfo.content_type === 2"
          @onDelete="onDeleteVideo"
        ></edit-message-video>
        <!-- <div class="video-container">
          <video
            style="width: 100%; height: 100%"
            src="http://bpic.588ku.com/video_listen/588ku_video/21/03/29/12/11/05/video606153592798b.mp4"
          ></video>
          <i class="icon-decline"></i>
        </div> -->
      </div>
      <div class="action-bar flex flex-x-start">
        <!-- <i class="icon-a-1"></i>
        <i class="icon-a-2"></i>
        <i class="icon-a-3"></i> -->
        <expression-box
          class="expression-edit-index"
          :isShow="isShowExpressBox"
          :expressList="$emoji.slice(0, 100)"
          :left="'0'"
          @add-express="addContentExpress($event)"
        >
          <div
            class="flex toolbar-item"
            :class="{ active: isShowExpressBox }"
            @click.stop="toggleExpressBox"
          >
            <i style="font-size: 26px" class="icon-a-1"></i>
          </div>
        </expression-box>
        <el-upload
          action="#"
          :show-file-list="false"
          multiple
          accept=".jpg,.jpeg,.bmp,.gif,.png,.heif,.heic"
          :disabled="
            momentInfoData.content_type === 3 ||
            momentInfo.content_type === 2 ||
            videoFile != null
          "
          :on-change="momentImageChange"
          :auto-upload="false"
          class="flex toolbar-item"
          :class="{
            'toolbar-disabled':
              momentInfoData.content_type === 3 ||
              momentInfo.content_type === 2 ||
              videoFile != null
          }"
        >
          <div class="flex flex-y-center">
            <i
              style="font-size: 26px"
              :style="{
                color:
                  momentInfoData.content_type === 3 ||
                  momentInfo.content_type === 2 ||
                  videoFile != null
                    ? '#dddddd'
                    : ''
              }"
              class="icon-a-2"
            ></i>
          </div>
        </el-upload>
        <el-upload
          action="#"
          :show-file-list="false"
          accept=".mp4,.m4v,.ogm,.wmv,.mpg,.webm,.ogv,.mov,.asx,.mpeg,.avi,.mkv"
          :on-change="momentVideoChange"
          :disabled="
            momentInfoData.content_type === 3 ||
            (momentInfo.content_type === 1 && imageFileList.length > 0)
          "
          :auto-upload="false"
          multiple
          class="flex toolbar-item"
          :class="{
            'toolbar-disabled':
              momentInfoData.content_type === 3 ||
              (momentInfo.content_type === 1 && imageFileList.length > 0)
          }"
        >
          <div class="flex flex-y-center">
            <i
              style="font-size: 26px"
              :style="{
                color:
                  momentInfoData.content_type === 3 ||
                  (momentInfo.content_type === 1 && imageFileList.length > 0)
                    ? '#dddddd'
                    : ''
              }"
              class="icon-a-3"
            ></i>
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import EditMessageImage from '@/components/circle/EditMessageImage.vue'
import EditMessageVideo from '@/components/circle/EditMessageVideo.vue'
import ExpressionBox from '../common/ExpressionBox.vue'
import AMention from '@/components/circle/CicleEditEditor1.vue'

export default {
  components: {
    EditMessageImage,
    EditMessageVideo,
    ExpressionBox,
    AMention
  },
  props: {
    pushId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      textarea: '',
      imgs: ['', '', '', '', '', '', '', '', ''],
      imageIndex: 0,
      momentInfo: {
        moment_title: '',
        moment_content: '',
        content_type: 1
      },
      pushInfo: {
        push_time: ''
      },
      momentInfoData: {},
      videoUrl: '',
      imageFileRaw: {},
      imageFileList: [],
      videoFile: null,
      // 是否显示表情的框
      isShowExpressBox: false,
      adding: false,
      // 推送时间
      pushTime: '',
      myInputContent: '',
      clerkList: [],
      clerkId: '',
      pickerOptions: {
        disabledDate(time) {
          let now = new Date()
          now = new Date(now.setHours(0))
          now = new Date(now.setMinutes(0))
          now = new Date(now.setSeconds(0))
          now = new Date(now.setMilliseconds(0))
          console.log(time.getTime(), now.getTime())
          return time.getTime() < now.getTime()
        }
      }
    }
  },
  computed: {
    // 视频链接
    renderVideoUrl() {
      if (this.videoUrl !== '') {
        return this.videoUrl
      } else {
        if (this.videoFile) {
          return URL.createObjectURL(this.videoFile)
        }
      }
      return ''
    },
    momentContent: {
      get() {
        return this.momentInfo.moment_content
      },
      set(val) {
        this.momentInfo.moment_content = val
      }
    },
    momentTitle: {
      get() {
        return this.momentInfo.moment_title
      },
      set(val) {
        this.momentInfo.moment_title = val
      }
    }
  },
  async mounted() {
    await this.getClerkList()
    if (this.pushId !== -1) {
      await this.getPushDetail()
    }
  },
  methods: {
    // 获取职员列表
    async getClerkList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemMomentPushClerkSelect, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.clerkList = res.list
      }
    },
    // 获取内容html
    getContenHtml(e) {
      // 提及列表
      const clerList = e.clerk_list.map((c) => {
        c.type = 1
        return c
      })
      // 话题列表
      const topicList = e.topic_list.map((t) => {
        t.type = 2
        return t
      })
      const list = clerList.concat(topicList).sort((a, b) => {
        return a.start_index - b.start_index
      })
      const content = e.moment_info.moment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name =
            e.type === 1
              ? '@' + (e.clerk_alias || e.clerk_name) + ' '
              : '#' + e.topic_name + '#' + ' '
          const id = e.type === 1 ? e.clerk_id : e.topic_id
          str +=
            content.substring(index, e.start_index) +
            `<a class="mention" data-mention="${name}" data-id="${id}" data-type="${e.type}" href="" > ${name}</a>`
          //  str +=
          // content.substring(
          //   index,
          //   index === 0 && e.start_index === 0 ? 0 : e.start_index + 1
          // ) +
          // `<a class="mention" data-mention="${name}" data-id="${id}" data-type="${e.type}" href="" >${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 获取推送详情
    async getPushDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemMomentPushDetail, {
          push_id: this.pushId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (res.info.moment_info.image_list != null) {
          this.imageFileList = res.info.moment_info.image_list.map((e) => {
            return {
              url: e.cover_access_path,
              imageId: `${e.image_id}`
            }
          })
        }
        const obj = this.getContenHtml(res.info)
        const objList = obj.split('\n')
        let str = ''
        objList.forEach((e) => {
          str += '<p>' + e + '</p>'
        })
        this.momentInfo = {
          moment_title: res.info.moment_info.moment_title,
          moment_content: str,
          content_type: res.info.moment_info.content_type
        }

        this.clerkId = res.info.moment_info.create_clerk_id
        const index = this.clerkList.findIndex((e) => {
          return e.clerk_id === this.clerkId
        })
        if (index === -1) {
          this.clerkId = ''
        }
        this.momentInfoData = res.info.moment_info
        if (res.info.moment_info.content_type === 2) {
          this.videoUrl = res.info.moment_info.video_info.cover_video_path
        }
        this.pushInfo = res.info
        console.log(res.info)
      }
    },
    // 清除数据
    clearData() {
      this.imageIndex = 0
      this.imageFileRaw = {}
      this.imageFileList = []
      this.videoFile = null
      this.videoUrl = ''
      this.momentInfo = {
        moment_title: '',
        moment_content: '',
        content_type: 1
      }
      this.pushInfo = {
        push_time: ''
      }
    },
    // 校验图片格式
    beforeAvatarUpload(file) {
      return [
        'image/jpeg',
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/gif',
        'image/heif',
        'image/heic'
      ].includes(file.type)
    },
    // 上传图片
    momentImageChange(file, fileList) {
      this.imageChange(file.raw)
    },
    // 删除图片
    onDeleteImage(index) {
      const info = this.imageFileList[index]
      this.imageFileList.splice(index, 1)
      if (info.imageId.indexOf('image_') !== -1) {
        delete this.imageFileRaw[info.imageId]
      }
    },
    // 删除视频
    onDeleteVideo() {
      this.videoFile = null
      this.momentInfo.content_type = 1
    },
    // 校验视频格式
    beforeVideoUpload(file) {
      return [
        'video/mp4',
        'video/m4v',
        'video/ogm',
        'video/wmv',
        'video/mpg',
        'video/webm',
        'video/ogv',
        'video/mov',
        'video/asx',
        'video/mpeg',
        'video/avi',
        'video/mkv'
      ].includes(file.type)
    },
    // 上传视频
    momentVideoChange(file) {
      console.log(file.raw)
      if (!this.beforeVideoUpload(file.raw)) {
        this.$message.error('视频格式有误！')
        return
      }
      this.videoFile = file.raw
      this.momentInfo.content_type = 2
      this.videoUrl = ''
    },
    // 发布
    async releaseDynamics(status) {
      if (this.adding) {
        return
      }
      if ([1, 2].indexOf(status) === -1) {
        this.$message.error('选择推送状态有误')
        return
      }
      if (status === 2) {
        if (this.pushInfo.push_time === '') {
          this.$message.error('请选择推送时间')
          return
        }
        if (this.clerkId === '') {
          this.$message.error('请选择推送人')
          return
        }
      }
      if (!this.checkMoemntInfo()) {
        this.$message.error('圈子内容不能为空')
        return
      }
      const formObj = new FormData()
      formObj.append('moment_title', this.momentInfo.moment_title)
      formObj.append('moment_content', this.momentInfo.moment_content)
      formObj.append('content_type', this.momentInfo.content_type)
      formObj.append('push_time', this.pushInfo.push_time || '')
      formObj.append('clerk_id', this.clerkId)
      formObj.append('push_status', status)
      switch (this.momentInfo.content_type) {
        // 图文
        case 1: {
          const imageKey = []
          this.imageFileList.forEach((e) => {
            imageKey.push(e.imageId)
            if (e.imageId.indexOf('image_') !== -1) {
              formObj.append(e.imageId, this.imageFileRaw[e.imageId])
            }
          })
          formObj.append('image_sign', imageKey.join(','))
          break
        }
        // 视频
        case 2:
          formObj.append('file', this.videoFile)
          break
      }
      if (this.pushId !== -1) {
        formObj.append('push_id', this.pushId)
        // 修改
        this.adding = true
        await this.editDynamics(formObj)
        this.adding = false
      } else {
        // 添加
        this.adding = true
        await this.addDynamics(formObj)
        this.adding = false
      }
    },
    // 添加圈子
    async addDynamics(obj) {
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.addSystemMomentPush, obj).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '圈子推送成功！',
          type: 'success'
        })
        this.clearData()
        this.back(true)
      }
    },
    // 修改圈子
    async editDynamics(obj) {
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.editSystemMomentPush, obj).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '圈子推送成功！',
          type: 'success'
        })
        this.clearData()
        this.back(true)
      }
    },
    // 校验数据
    checkMoemntInfo() {
      let flag = false
      if (this.momentInfo.moment_content !== '') {
        flag = true
      }
      if (this.imageFileList.length !== 0) {
        flag = true
      }
      if (this.videoUrl !== '' || this.videoFile != null) {
        flag = true
      }
      return flag
    },
    // 修改图片排序
    editImageSort(val) {
      this.imageFileList = val
    },
    // 组件添加图片
    imageChange(file) {
      if (this.imageFileList.length >= 9) {
        return
      }
      if (!this.beforeAvatarUpload(file)) {
        return
      }
      const key = 'image_' + this.imageIndex++
      this.imageFileList.push({
        url: URL.createObjectURL(file),
        imageId: key
      })
      this.imageFileRaw[key] = file
      if (this.momentInfo.content_type !== 1) {
        this.momentInfo.content_type = 1
      }
    },
    // 黏贴事件
    handlerPaste(e) {
      if (e.files.length > 0) {
        e.files.forEach((e) => {
          if (this.beforeAvatarUpload(e)) {
            this.imageChange(e)
          }
        })
      }
    },
    // 显示隐藏表情框
    toggleExpressBox() {
      this.isShowExpressBox = !this.isShowExpressBox
    },
    // 在内容里添加一个表情
    addContentExpress(obj) {
      this.momentInfo.moment_content = this.momentInfo.moment_content.replace(
        '<p>&nbsp;',
        '<p>'
      )
      const index = this.momentInfo.moment_content.lastIndexOf('</p>')
      if (index === -1) {
        this.momentInfo.moment_content = '<p>' + obj.name + '</p>'
      } else {
        this.momentInfo.moment_content =
          this.momentInfo.moment_content.substring(0, index) + obj.name + '</p>'
      }
      // 关闭白框
      this.isShowExpressBox = false
      // 让输入框重新聚焦
      setTimeout(() => {
        // 让输入框重新聚焦
        this.$refs.contentRef.setFocus()
      }, 50)
    },
    // 返回
    back(currentFlag = false) {
      this.$emit('close', currentFlag)
    },
    // 删除
    async deletePushuInfo() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemMomentPush, {
          push_id: this.pushId
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        })
        this.clearData()
        this.$emit('deleteRefresh')
      }
    }
  },
  watch: {
    pushId(val) {
      this.clearData()
      console.log(val)
      if (val !== -1) {
        this.getPushDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.push-content-resources ::v-deep {
  .topic-video video {
    width: 564px;
    height: 286px;
    border-radius: 6px;
    overflow: hidden;
  }
  .img-container {
    .add-content {
      border-radius: 3px;
      margin-right: 15px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }
    .img-content {
      img {
        border-radius: 3px;
        margin-right: 15px;
      }
      img:nth-child(5) {
        margin-right: 0;
      }

      .delete-button {
        padding: 6px;
        font-size: 13px;
        border-radius: 0 2px 0 3px;
        right: 15px;
      }
    }
  }
}
.push-content-edit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #fff;
  z-index: 2;

  .push-content-edit_header {
    position: fixed;
    width: calc(100% - 322px);
    height: 64px;
    padding: 0 50px 0 24px;
    z-index: 10;
    background-color: #fff;
    &::after {
      left: 0;
    }
    .el-button {
      width: 80px;

      &.button-delete {
        background-color: #f6f6f6;
        color: #999;
        border: none;
        &:hover {
          background-color: #ff5454;
          color: #fff;
        }
      }

      &.button-draft {
        border: none;
        color: #fff;
        background-color: #c0c0c0;
      }

      &.button-release {
        border: none;
        color: #fff;
        background-color: $main-color;
      }
    }
  }

  .push-content-container {
    padding-top: 64px;
    width: 810px;
    margin: 28px auto 0;
    overflow: scroll;

    .push-content-title {
      width: calc(100% - 24px);
      height: 56px;
      font-size: 18px;
      color: #333;
      font-weight: 600;
      border: none;
      border-radius: 6px;
      border: 1px solid #fff;
      margin-bottom: 24px;
    }

    .push-content-time {
      margin-bottom: 18px;
      ::v-deep .el-input__inner {
        border: 1px solid #fff !important;
      }
    }

    .push-content-content {
      margin-bottom: 20px;
      ::v-deep .el-textarea__inner {
        padding: 0;
        border: transparent;
        font-size: 15px !important;
      }
      &::v-deep .ck-editor__editable {
        height: 440px !important;
      }
    }

    .push-content-resources {
      .image-container {
        position: relative;
        margin-right: 23px;
        margin-bottom: 23px;
        border-radius: 6px;
        overflow: hidden;

        &:nth-child(6) {
          margin-right: 0;
        }

        i {
          position: absolute;
          top: 0;
          right: 0;
          width: 26px;
          height: 26px;
          line-height: 26px;
          color: $main-color;
          text-align: center;
          background-color: #e6f3ff;
          border-bottom-left-radius: 6px;
        }
      }

      .video-container {
        position: relative;
        width: 564px;
        height: 286px;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 25px;
        i {
          position: absolute;
          top: 0;
          right: 0;
          width: 26px;
          height: 26px;
          line-height: 26px;
          color: $main-color;
          text-align: center;
          background-color: #e6f3ff;
          border-bottom-left-radius: 6px;
        }
      }
    }

    .action-bar {
      margin-bottom: 30px;
      // i {
      //   color: #bbb;
      //   font-size: 26px;
      //   margin-right: 16px;
      // }
    }
    .toolbar-item {
      cursor: pointer;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      color: #bbbbbb;
      &.active,
      &:hover {
        color: $main-color;
        border-radius: 32px;
        background: rgba(0, 128, 255, 0.1);
      }

      &.toolbar-disabled {
        color: #ddd;
        &:hover {
          color: #ddd;
          border-radius: none;
          background: #fff;
        }
      }
    }
  }
}

.textarea ::v-deep {
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f3f4f5;
    // background: #d3d4d4;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #97999e;
  }
}
.result-item {
  height: 55px;
  .el-image {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    margin-right: 16px;
  }

  .name {
    margin-right: 20px;
  }

  .type {
    color: #bbb;
  }
}
.expression-edit-index::v-deep .expression-box {
  z-index: 2 !important;
}
</style>
