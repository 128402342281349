<!--
 * @Author: Betty
 * @Date: 2021-06-28 15:52:56
 * @LastEditors: hzh
 * @LastEditTime: 2021-10-08 14:18:38
 * @Description: 目标的筛选弹窗
-->
<template>
  <div class="select-box" v-show="isShow">
    <div class="flex main flex-y-start">
      <!-- 左边的下拉框 -->
      <div
        class="selct flex flex-y-center flex-x-between"
        @click.stop="toggleSelectType"
      >
        <p class="select-result">{{ currentData.text }}</p>
        <span class="iconfont icon-zhankai"></span>
        <!-- 下面的白色选择框 -->
        <div class="select-data-box" v-show="isChooseType">
          <div
            v-for="(item, index) in selectRangeList"
            :key="index"
            class="select-data"
          >
            <div
              v-for="(data, index2) in item"
              :key="index2"
              class="select-data-item"
              @click="chooseSelectType(index, index2)"
            >
              {{ data.text }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右边 -->
      <div class="right" @click.stop>
        <!-- 目标名称 -->
        <template v-if="currentData.key === 'target_name'">
          <input
            type="text"
            placeholder="请填写目标名称"
            class="input"
            v-model.trim="targetName"
          />
        </template>
        <!-- 创建人 -->
        <template v-if="currentData.key === 'create_clerk_id'">
          <input
            type="text"
            placeholder="请选择创建人"
            class="input"
            readonly
            :value="
              currentPerson &&
              (currentPerson.clerk_alias || currentPerson.clerk_name)
            "
            @click.stop="chooseCreatePerson"
          />
          <!-- 选择人 -->
          <div v-show="isShowPerson" class="choose-person">
            <search-person
              @choose-person="choosePerson($event)"
              :isShow="isShowPerson"
            ></search-person>
            <div class="choose-member-box">
              <div v-for="item in clerkData" :key="item.id">
                <member-tree
                  :treeNode="item"
                  @clerk-choose="choosePerson($event)"
                ></member-tree>
              </div>
            </div>
          </div>
        </template>
        <!-- 创建时间 -->
        <template v-if="currentData.key === 'create_time'">
          <el-date-picker
            v-model="timeValue"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </template>
        <!-- 完成时间 -->
        <template v-if="currentData.key === 'finish_time'">
          <el-date-picker
            v-model="timeValue"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </template>
        <!-- 开始时间 -->
        <template v-if="currentData.key === 'start_time'">
          <el-date-picker
            v-model="timeValue"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </template>
        <!-- 最终时间 -->
        <template v-if="currentData.key === 'last_time'">
          <el-date-picker
            v-model="timeValue"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </template>
        <!-- 目标类型 -->
        <template v-if="currentData.key === 'target_type'">
          <!-- 右边的下拉框 -->
          <div
            class="flex flex-y-center flex-x-between right-select"
            @click="toggleRightSelect"
          >
            <p class="select-result">
              {{
                currentRightOption === -1
                  ? '请选择类型'
                  : targetTypeList[currentRightOption].text
              }}
            </p>
            <span class="iconfont icon-zhankai"></span>
            <!-- 下拉选项 -->
            <div class="right-select-box" v-show="isShowRightSelect">
              <div
                v-for="(item, index) in targetTypeList"
                :key="index"
                class="right-select-item"
                @click="chooseRightItem(index)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </template>
        <!-- 目标状态 -->
        <template v-if="currentData.key === 'target_status'">
          <!-- 右边的下拉框 -->
          <div
            class="flex flex-y-center flex-x-between right-select"
            @click="toggleRightSelect"
          >
            <p class="select-result">
              {{
                currentRightOption === -1
                  ? '请选择目标状态'
                  : taskStatus[currentRightOption].text
              }}
            </p>
            <span class="iconfont icon-zhankai"></span>
            <!-- 下拉选项 -->
            <div class="right-select-box" v-show="isShowRightSelect">
              <div
                v-for="(item, index) in taskStatus"
                :key="index"
                class="right-select-item"
                @click="chooseRightItem(index)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </template>
        <!-- 完成进度 -->
        <template v-if="currentData.key === 'target_progress'">
          <!-- 右边的下拉框 -->
          <div
            class="flex flex-y-center flex-x-between right-select"
            @click="toggleRightSelect"
          >
            <p class="select-result">
              {{
                currentRightOption === -1
                  ? '请选择完成进度'
                  : taskProgress[currentRightOption].text
              }}
            </p>
            <span class="iconfont icon-zhankai"></span>
            <!-- 下拉选项 -->
            <div class="right-select-box" v-show="isShowRightSelect">
              <div
                v-for="(item, index) in taskProgress"
                :key="index"
                class="right-select-item"
                @click="chooseRightItem(index)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </template>
        <!-- 我参与的 -->
        <template v-if="currentData.key === 'my_participate'">
          <input
            type="text"
            placeholder="请填写目标名称"
            class="input"
            v-model.trim="targetName"
          />
        </template>
        <!-- 我创建的 -->
        <template v-if="currentData.key === 'my_create'">
          <input
            type="text"
            placeholder="请填写目标名称"
            class="input"
            v-model.trim="targetName"
          />
        </template>
        <!-- 我负责的 -->
        <template v-if="currentData.key === 'my_charge'">
          <input
            type="text"
            placeholder="请填写目标名称"
            class="input"
            v-model.trim="targetName"
          />
        </template>
        <!-- 我团队的 -->
        <template v-if="currentData.key === 'my_team'">
          <input
            type="text"
            placeholder="请填写任务名称"
            class="input"
            v-model.trim="targetName"
          />
        </template>
      </div>
    </div>
    <el-button type="primary" @click="beginSelect">筛选</el-button>
  </div>
</template>

<script>
import SearchPerson from './SearchPerson.vue'
import MemberTree from './MemberTree.vue'
export default {
  name: 'select-box',
  components: {
    MemberTree,
    SearchPerson
  },
  props: {
    // 是否显示筛选弹窗
    isShow: {
      type: Boolean,
      default: false
    },
    // 职员信息
    clerkData: {
      type: Array
    },
    // 当前筛选的是谁
    currentClerkId: {
      type: Number,
      default: -1
    },
    isComplete: {
      default: ''
    }
  },
  data() {
    return {
      // 左边的下拉菜单
      selectRangeList: [
        // 第一组
        [
          {
            text: '目标状态',
            key: 'target_status'
          },
          {
            text: '完成进度',
            key: 'target_progress'
          }
        ],
        //   第二组
        [
          {
            text: '目标名称',
            key: 'target_name'
          },
          {
            text: '创建人',
            key: 'create_clerk_id'
          },
          {
            text: '创建时间',
            key: 'create_time'
          },
          {
            text: '完成时间',
            key: 'finish_time'
          },
          {
            text: '开始时间',
            key: 'start_time'
          },
          {
            text: '最终时间',
            key: 'last_time'
          },
          {
            text: '目标类型',
            key: 'target_type'
          }
        ],
        // 第三组
        [
          {
            text: '我负责的',
            key: 'my_charge'
          },
          {
            text: '我创建的',
            key: 'my_create'
          },
          {
            text: '我参与的',
            key: 'my_participate'
          }
          // {
          //   text: '我团队的',
          //   key: 'my_team'
          // }
        ]
      ],
      // 当前选择了哪个维度
      currentList: 0,
      currentIndex: 0,
      // 是否显示左边的下拉框
      isChooseType: false,
      // 是否选择创建人
      isShowPerson: false,
      // 当前选择的人
      currentPerson: null,
      // 搜索结果
      searchResultList: [],
      // 选择日期的快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      // 选择的时间
      timeValue: '',
      // 任务名称
      targetName: '',
      // 右边目前选择的选项下标
      currentRightOption: -1,
      // 是否显示右边的输入框
      isShowRightSelect: false,
      // 任务状态
      taskStatus: [
        {
          text: '全部',
          val: 0
        },
        {
          text: '正常',
          val: 1
        },
        {
          text: '已延期',
          val: 2
        }
      ],
      // 任务进度
      taskProgress: [
        {
          text: '全部',
          val: -1
        },
        {
          text: '0-50%',
          val: 0
        },
        {
          text: '50-99%',
          val: 1
        },
        {
          text: '100%',
          val: 2
        }
      ],
      // 获取到的数据
      targetGroupsList: [],
      // 目标类型
      targetTypeList: [{
        value: 1,
        text: '周目标'
      }, {
        value: 2,
        text: '月目标'
      }, {
        value: 3,
        text: '季目标'
      }]
    }
  },
  watch: {
    // 切换筛选选项时，清空选择
    'currentData.key': function () {
      this.clearData()
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOut)
  },
  computed: {
    // 当前选择的筛选项
    currentData() {
      return this.selectRangeList[this.currentList][this.currentIndex]
    }
  },
  methods: {
    // 清空数据
    clearData() {
      this.currentPerson = null
      this.searchResultList = []
      this.timeValue = []
      this.currentRightOption = -1
      this.createTime = ''
      this.finishTime = ''
      this.lastTime = ''
      this.targetName = ''
    },
    // 显示隐藏左边的下拉框
    toggleSelectType() {
      this.isChooseType = !this.isChooseType
    },
    //  点击选择筛选维度
    chooseSelectType(index, index2) {
      this.currentList = index
      this.currentIndex = index2
    },
    // 是否显示选择创建人
    chooseCreatePerson() {
      this.isShowPerson = !this.isShowPerson
    },
    // 点击选择人
    choosePerson(data) {
      this.isShowPerson = false
      this.currentPerson = data
    },
    // 显示隐藏右边的下拉选项
    toggleRightSelect() {
      this.isShowRightSelect = !this.isShowRightSelect
    },
    // 选择优先级
    chooseRightItem(index) {
      this.currentRightOption = index
    },
    // 点击按钮，开始筛选
    async beginSelect() {
      // 要提交的数据
      const formObj = {}
      formObj.clerk_id = this.currentClerkId
      formObj.is_complete = this.isComplete
      // 根据当前的筛选项，来确定formObj的属性值
      switch (this.currentData.key) {
        // 任务名称
        case 'target_name': {
          formObj.objective_name = this.targetName
          break
        }
        // 创建人
        case 'create_clerk_id': {
          if (this.currentPerson) {
            formObj.create_clerk_id = this.currentPerson.clerk_id
          } else {
            this.$message({
              showClose: true,
              message: '请选择创建人!',
              type: 'error'
            })
            return
          }
          break
        }
        // 创建时间
        case 'create_time': {
          formObj.create_start_time = this.timeValue[0]
          formObj.create_end_time = this.timeValue[1]
          break
        }
        // 完成时间
        case 'finish_time': {
          formObj.complete_start_time = this.timeValue[0]
          formObj.complete_end_time = this.timeValue[1]
          break
        }
        // 开始时间
        case 'start_time': {
          formObj.start_start_time = this.timeValue[0]
          formObj.start_end_time = this.timeValue[1]
          break
        }
        // 最终时间
        case 'last_time': {
          formObj.end_start_time = this.timeValue[0]
          formObj.end_end_time = this.timeValue[1]
          break
        }
        // 优先级
        case 'target_type': {
          formObj.objective_type =
            this.targetTypeList[this.currentRightOption].value
          break
        }
        // 目标状态
        case 'target_status': {
          formObj.progress_status = this.taskStatus[this.currentRightOption].val
          break
        }
        // 完成进度
        case 'target_progress': {
          switch (this.currentRightOption) {
            case 0: {
              formObj.progress_start_number = 0
              formObj.progress_end_number = 100
              break
            }
            case 1: {
              formObj.progress_start_number = 0
              formObj.progress_end_number = 50
              break
            }
            case 2: {
              formObj.progress_start_number = 50
              formObj.progress_end_number = 99
              break
            }
            case 3: {
              formObj.progress_start_number = 100
              formObj.progress_end_number = 100
              break
            }
            default:
              console.log('进度不符合要求')
          }
          break
        }
        // 我参与的
        case 'my_participate': {
          formObj.task_type = 3
          formObj.objective_name = this.targetName
          break
        }
        // 我创建的
        case 'my_create': {
          formObj.task_type = 1
          formObj.objective_name = this.targetName
          break
        }
        // 我负责的
        case 'my_charge': {
          formObj.task_type = 2
          formObj.objective_name = this.targetName
          break
        }
        // 我团队的
        case 'my_team': {
          formObj.task_type = 4
          formObj.objective_name = this.targetName
          break
        }
        default: {
          console.log(formObj)
        }
      }
      // 存放请求的结果
      let taskListData = []
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        taskListData = res.list
        console.log('结果为:', taskListData)
        // 处理数据，把它整理成一个分组的数组
        const groupList = []
        taskListData.forEach((dataItem) => {
          if (groupList.length === 0) {
            groupList.push({
              group_id: dataItem.group_id,
              list: [dataItem]
            })
          } else {
            // 试图去寻找现有的组中有没有与当前数据的组id相符的
            const groupIndex = groupList.findIndex(
              (item) => item.group_id === dataItem.group_id
            )
            if (groupIndex >= 0) {
              groupList[groupIndex].list.push(dataItem)
            } else {
              groupList.push({
                group_id: dataItem.group_id,
                list: [dataItem]
              })
            }
          }
        })
        this.targetGroupsList = groupList
        // 清空数据
        this.clearData()
        this.currentList = 0
        this.currentIndex = 0
        // 通知父组件刷新列表
        this.$emit('to-refresh-list', this.targetGroupsList)
      }
    },
    clickOut() {
      this.isShowPerson = false
      this.isChooseType = false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/select_box_style.scss';
</style>
<style lang="scss">
.select-box .el-button--primary {
  background: $main-color;
}
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 298px !important;
}
</style>
