<!--
 * @Author: hzh
 * @Date: 2021-09-28 09:45:57
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-26 17:55:16
-->
<template>
  <div style="position: relative; width: 220px">
    <div
      class="share-select flex flex-x-between flex-y-center"
      @click="handeShowMenu"
    >
      <span>{{ menuList[myActive].title }}</span>
      <i class="icon-zhankai"></i>
    </div>
    <el-collapse-transition>
      <div v-if="show" id="share-menu">
        <edit-menu-item
          v-for="(item, index) in menuList"
          :key="index"
          :title="item.title"
          :is-active="myActive === index"
          :info="item.info"
          @change="handleSelect(index)"
        ></edit-menu-item>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import EditMenuItem from './EditMenuItem.vue'
export default {
  components: {
    EditMenuItem
  },
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      myActive: 0,
      menuList: [
        {
          title: '共享',
          info: '团队所有成员可查看、下载'
        },
        {
          title: '不共享',
          info: '只有特定部门、成员可查看、下载'
        }
      ]
    }
  },
  methods: {
    handeShowMenu(e) {
      this.show = !this.show
    },
    handleSelect(e) {
      this.show = false
      this.$emit('change', e)
    }
  },
  mounted() {
    this.myActive = this.active
  },
  destroyed() {
  },
  watch: {
    active(val) {
      this.myActive = val
    }
  }
}
</script>

<style lang="scss" scoped>
#share-menu {
  position: absolute;
  width: 220px;
  right: 0;
  top: 42px;
  background-color: #fff;
  z-index: 2;
  box-shadow: rgba(101, 101, 211, 0.2) 0px 7px 29px 0px;
}

.share-select {
  position: relative;
  cursor: pointer;
  width: 192px;
  height: 36px;
  padding: 0 14px;
  border: 1px solid #eee;
  border-radius: 4px;
}
</style>
