<!--
 * @Author: Betty
 * @Date: 2021-07-05 13:53:55
 * @LastEditors: Betty
 * @LastEditTime: 2021-07-14 11:08:24
 * @Description: 会议弹窗组件
-->
<template>
  <transition name="newMeeting">
    <div class="new-meeting add-modal" v-if="isShow">
      <!-- 收起按钮 -->
      <div class="modal-close-btn" @click="closeModal">
        <span class="iconfont icon-shouqianniu pc"></span>
        <i class="el-icon-arrow-left wap"></i>
      </div>
      <!-- 主要内容 -->
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'meeting-modal',
  props: {
    // 弹窗是否显示
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 通知父组件关闭弹窗
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss">
// 导入会议弹窗的样式
@import '@/assets/scss/new_meeting_style.scss';
// 导入富文本的样式
@import url('../../assets/css/ck_style.min.css');
</style>
