var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.treeNode.dept_name && _vm.treeNode.dept_name.length > 0)?_c('div',{staticClass:"label flex flex-y-center",style:({ 'padding-left': 16 * _vm.layer + 'px' })},[_c('span',{staticClass:"arrow",class:{ active: _vm.isShowChild },on:{"click":_vm.toggleChild}},[_c('span',{staticClass:"iconfont icon-playfill1"})]),_c('p',{staticClass:"label-text",on:{"click":_vm.toggleChild}},[_vm._v(_vm._s(_vm.treeNode.dept_name))])]):_c('div',{staticClass:"flex flex-y-center member",class:{ active: _vm.treeNode.clerk_id === _vm.Parent.currentClerkId },style:({ 'padding-left': 16 * _vm.layer + 20 + 'px' }),on:{"click":function($event){return _vm.chooseMember(_vm.treeNode)}}},[_c('div',{staticClass:"avatar-box"},[_c('cricle-pop',{attrs:{"info":{
            clerk_avatar: _vm.treeNode.clerk_avatar,
            clerk_alias: _vm.treeNode.clerk_alias,
            clerk_name: _vm.treeNode.clerk_name,
            clerk_id: _vm.treeNode.clerk_id
          }}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm._f("httpToHttps")(_vm.treeNode.clerk_avatar),"alt":_vm.treeNode.clerk_alias || _vm.treeNode.clerk_name}})]),(
            _vm.isMyFocus(_vm.treeNode.clerk_id) === -1 &&
            _vm.treeNode.clerk_id !== _vm.myClerkId
          )?_c('button',{staticClass:"focus-btn active",on:{"click":function($event){$event.stopPropagation();return _vm.addFocus(_vm.treeNode)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-a-Group2031"}})])]):_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeNode.clerk_id !== _vm.myClerkId),expression:"treeNode.clerk_id !== myClerkId"}],staticClass:"focus-btn",on:{"click":function($event){$event.stopPropagation();return _vm.removeFocus(_vm.treeNode)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-a-Group203"}})])])],1),_c('p',{staticClass:"name e",attrs:{"title":_vm.treeNode.clerk_alias || _vm.treeNode.clerk_name}},[_vm._v(" "+_vm._s(_vm.treeNode.clerk_alias || _vm.treeNode.clerk_name)+" ")])]),(
        (_vm.treeNode.clerk_list && _vm.treeNode.clerk_list.length > 0) ||
        (_vm.treeNode.children && _vm.treeNode.children.length > 0)
      )?[_c('el-collapse-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowChild),expression:"isShowChild"}]},[_vm._l((_vm.treeNode.clerk_list),function(child){return _c('focus-member-tree',{key:'c' + child.clerk_id,attrs:{"treeNode":child,"layer":_vm.layer + 1},on:{"clerk-choose":function($event){return _vm.chooseMember($event)}}})}),_vm._l((_vm.treeNode.children),function(child){return _c('focus-member-tree',{key:child.dept_id,attrs:{"treeNode":child,"layer":_vm.layer + 1},on:{"clerk-choose":function($event){return _vm.chooseMember($event)}}})})],2)])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }