<!--
 * @Author: Betty
 * @Date: 2021-08-10 15:35:27
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-24 14:18:11
 * @Description: 评论消息的组件
-->
<template>
  <div class="comment-message-item flex">
    <!-- 左边，用户头像 -->
    <avatar
      :size="40"
      :distanceRight="14"
      :userInfo="{
        clerk_avatar: comment.create_clerk_avatar
      }"
    ></avatar>
    <!-- 右边，提醒的数据 -->
    <div class="flex1">
      <!-- 第一行，名字和日期 -->
      <div class="flex flex-y-center flex-x-between">
        <p class="comment-name">
          {{ comment.create_clerk_alias || comment.create_clerk_name }}
        </p>
        <p class="comment-time">{{ createTime }}</p>
      </div>
      <!-- 第二行，回复的内容 -->
      <p class="comment-content" v-if="comment.reply_comment_id" >
        回复<span class="name"
          >@{{
            comment.reply_create_clerk_alias || comment.reply_create_clerk_name
          }}</span
        >
        ：<span
          v-html="getCommentHtml(comment, comment.comment_content).replace(/\[[^\[\]]*\]/gi, emotion)"
        ></span>
      </p>
      <!-- 第三行，评论的内容 -->
      <p class="comment-content" v-if="comment.reply_comment_id">
        <span class="name"
          >@{{
            comment.reply_create_clerk_alias || comment.reply_create_clerk_name
          }}</span
        >
        ：
        <span
          v-html="getCommenReplytHtml(comment, comment.reply_comment_content)"
        ></span>
      </p>
      <p class="comment-content" v-else>
        <span v-html="getCommentHtml(comment, comment.comment_content).replace(/\[[^\[\]]*\]/gi, emotion)"></span>
      </p>
      <!-- 第四行，对应的内容的标题 -->
      <div class="content-title-box flex flex-y-center" @click="toDetail">
        <h6 class="content-title e2">
          {{ comment.objective_name || comment.task_name || comment.content }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from './Avatar.vue'
import { emojisMap } from '@/components/common/ExpressionBox.vue'
export default {
  name: 'comment-message-item',
  components: {
    Avatar
  },
  props: {
    // 评论数据
    comment: {
      type: Object
    },
    // 消息的类型,如task, target
    messageType: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['getToday']),
    createTime() {
      const now = this.getToday
      const date = new Date(this.comment.create_time)
      if (date.getFullYear() !== now.getFullYear()) {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).substr(
          -2
        )}-${date.getDate()}`
      }
      if (
        date.getMonth() !== now.getMonth() ||
        date.getDate() !== now.getDate()
      ) {
        return `${('0' + (date.getMonth() + 1)).substr(-2)}-${date.getDate()}`
      }
      return `${
        date.getHours() >= 13
          ? ('0' + (date.getHours() - 12)).substr(-2)
          : date.getHours()
      }:${('0' + date.getMinutes()).substr(-2)} ${
        date.getHours() <= 12 ? 'AM' : 'PM'
      }`
    }
  },
  methods: {
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
      // const word = nane.replace(/\[|\]/gi, '')
      // const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
      // const indon = list.indexOf(word)
      // return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${indon}.gif" align="middle">`
    },
    // 获取评论内容html
    getCommentHtml(e, text) {
      if (!e.clerk_list) {
        return text
      }
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = text
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 获取评回复内容html
    getCommenReplytHtml(e, text) {
      console.log(e.pid_clerk_list)
      if (!e.pid_clerk_list) {
        return text
      }
      // 提及列表
      const list = e.pid_clerk_list.map((c) => {
        return c
      })
      const content = text
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    // 点击跳转到详情
    toDetail() {
      this.$emit('to-detail', {
        type: this.type,
        id:
          this.comment.objective_id ||
          this.comment.task_id ||
          this.comment.moment_id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 每一条消息数据
.comment-message-item {
  padding: 24px 0;
  border-bottom: 1px solid #eee;
  // 显示评论人名字
  .comment-name {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 12px;
  }
  // 评论的时间
  .comment-time {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #999;
    margin-bottom: 12px;
  }
  // 评论的内容
  .comment-content {
    margin-bottom: 16px;
    word-break: break-all;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    &:hover {
      color: $main-color;
    }
  }
  // 蓝色的名字
  .name {
    padding: 0 0 0 3px;
    color: $main-color;
  }
  // 标题显示区域
  .content-title-box {
    position: relative;
    height: 66px;
    padding: 0 20px;
    overflow: hidden;
    background: rgba(0, 128, 255, 0.05);
    border-radius: 4px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 6px;
      background: $main-color;
    }
  }
  .content-title {
    font-weight: 400;
    cursor: pointer;
    word-break: break-all;
    font-size: 14px;
    line-height: 1.5;
    max-height: 42px;
    color: #333;
    &:hover {
      color: $main-color;
    }
  }
}
</style>
