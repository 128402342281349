<template>
  <div class="form-item">
    <div class="form-lable">{{ lable }}</div>
    <div class="form-data">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    lable: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number]
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item {
  .form-lable {
    color: #bbb;
    margin-bottom: 12px;
  }

  .form-data {
    height: 44px;
    line-height: 44px;
    border-radius: 4px;
    padding: 0 14px;
    background-color: #f6f6f6;
  }
}
</style>
