<!--
 * @Date: 2021-06-01 21:30:59
 * @LastEditTime: 2021-11-23 17:39:33
 * @Description: 任务列表里的每个任务
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div class="task-item-box">
    <!-- 它自己 -->
    <div
      class="flex flex-y-center task-item"
      :class="{
        over: task.task_status === 3,
        end_over: task.task_status === 4
      }"
    >
      <div
        class="
          more
          move-task-rank
          hidden-xs-only
          flex flex-x-center flex-y-center
        "
        style="width: 20px"
        :style="{ cursor: isMe ? 'move' : 'default !important' }"
      >
        <i
          v-if="isMe"
          class="iconfont icon-tuozhuai"
          style="padding: 0 3px"
        ></i>
      </div>
      <div
        class="flex flex-y-center task-item-left"
        :style="{ 'padding-left': 16 * layer + 10 + 'px' }"
      >
        <!-- 箭头 -->
        <span
          class="iconfont arrow"
          :class="{ 'icon-shouqi': !isShowChild, 'icon-zhankai': isShowChild }"
          :style="{ left: 16 * layer + 10 - 22 + 'px' }"
          v-if="task.children && task.children.length > 0"
          @click="toggleChild"
        ></span>
        <!-- 图标和任务名称 -->
        <div class="flex flex-y-center name-box">
          <div
            class="check-box"
            :style="{ cursor: isMe && isAllowUpdate ? 'pointer' : 'default' }"
            @click.stop="modifyTaskStatus(task.task_id, task)"
          >
            <svg class="icon" aria-hidden="true">
              <use
                :xlink:href="
                  task.task_status !== 3 && task.task_status !== 4
                    ? '#icon-TickSquare'
                    : '#icon-TickSquare_1'
                "
              ></use>
            </svg>
          </div>
          <div class="task-name-wrapper">
            <div class="flex flex-y-center task-name-box">
              <p
                class="task-name e"
                @click="showDetail(task.task_id)"
                v-show="currentEditTask !== task.task_id"
                :title="task.task_name"
              >
                <!-- 标签 -->
                <span
                  class="task-label"
                  v-if="parseLabel.gth > 0"
                  :style="{
                    color: '#FF5454',
                    background: '#fff',
                    fontWeight: '600',
                    fontSize: '15px'
                  }"
                  >{{ parseLabel.gthStr }}</span
                >
                <span
                  class="task-label"
                  v-for="(item, index) in parseLabel.list"
                  :key="index"
                  @click.stop="$emit('changeLabel', item.id)"
                  >{{ item.name }}</span
                >{{ parseLabel.showName }}
                <!-- 描述子任务的完成情况 -->
                <template
                  v-if="
                    currentEditTask !== task.task_id &&
                    task.child_total_count > 0
                  "
                >
                  <p
                    class="child-info"
                    style="display: inline-block"
                    v-if="timeoutCount > 0"
                  >
                    (<span style="color: red">{{ timeoutCount }}</span
                    >/{{ task.child_complete_count }}/{{
                      task.child_total_count
                    }})
                  </p>
                  <p class="child-info" style="display: inline-block" v-else>
                    ({{ task.child_complete_count }}/{{
                      task.child_total_count
                    }})
                  </p>
                </template>
              </p>
            </div>
            <!-- 编辑输入框 -->
            <input
              type="text"
              :ref="'taskNameInput_' + task.task_id"
              v-model="tempTaskName"
              placeholder="请输入任务名"
              class="input-task-name"
              v-show="currentEditTask === task.task_id"
              @blur="handleEditTask(task)"
              @keyup.229="empty"
              @keyup.13="$event.target.blur(task)"
              @keyup.esc="
                ;(escIsShow = true),
                  (escShowRef = 'taskNameInput_' + task.task_id)
              "
            />
          </div>
          <!-- 悬浮出现三个点 -->
          <div
            class="more hidden-xs-only"
            v-if="Parent.currentClerkId === myClerkId"
            @click.stop="showMoreMenu(task.task_id)"
          >
            <span class="iconfont icon-gengduo3 more-icon"></span>
            <!-- 更多弹窗 -->
            <div
              class="more-menu-box"
              v-if="currentShowMore === task.task_id"
              @click.stop
            >
              <div
                class="more-menu-item"
                v-if="isAllowUpdate"
                @click.stop="beginEditTaskName(task)"
              >
                编辑任务
              </div>
              <div class="more-menu-item" @click.stop="readyToMove(task)">
                移动
              </div>
              <div
                class="more-menu-item red"
                v-if="task.create_clerk_id === myClerkId"
                @click.stop="removeTask(task.task_id)"
              >
                删除
              </div>
            </div>
          </div>
          <!-- 小旗子 -->
          <div
            class="flag"
            :class="[colorList[task.task_id % colorList.length]]"
            v-if="task.task_star_list.length > 0"
          >
            {{ getTaskFlagText(task.task_star_list) }}
          </div>
        </div>
      </div>
      <!-- 右边，任务的星星、优先级和完成进度 -->
      <div class="task-info-value sumbit" v-if="false">
        <!-- 星星 -->
        <div
          class="star"
          @click.stop="starTask(task.task_id)"
          :style="{ cursor: isMe ? 'pointer' : 'default' }"
        >
          <svg class="icon" aria-hidden="true">
            <use
              :xlink:href="task.is_star === 0 ? '#icon-Star1' : '#icon-Star-2'"
            ></use>
          </svg>
        </div>
      </div>
      <!-- 负责人 -->
      <div class="task-info-value leader-box e flex flex-x-center">
        <div class="flex flex-y-center">
          <img
            :src="task.director_clerk_avatar"
            class="hidden-xs-only"
            style="
              width: 23px;
              height: 23px;
              border-radius: 50%;
              margin-right: 5px;
            "
          />
          <!-- <avatar
            :isShowMe="true"
            :distanceRight="5"
            :size="23"
            :userInfo="{
              clerk_avatar: task.director_clerk_avatar,
              clerk_id: task.director_clerk_id
            }"
          ></avatar> -->
          <span class="e director-e-name">{{
            task.director_clerk_alias || task.director_clerk_name
          }}</span>
        </div>
      </div>
      <!-- 截止日期 -->
      <div class="task-info-value last-time hidden-xs-only">
        <el-date-picker
          v-model="lastTime"
          align="right"
          type="date"
          :readonly="!isAllowUpdate"
          @change="setLastTime"
          v-if="isAllowUpdate"
          placeholder=""
          size="small"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <p
          v-if="!isAllowUpdate"
          :class="{ late: isLate }"
          class="last-time-text"
        >
          {{ getLastTime }}
        </p>
      </div>
      <!-- 优先级 -->
      <div
        class="task-info-value priority hidden-xs-only"
        @click.stop="toggleChoosePriority(task.task_id)"
      >
        <!-- 优先级 -->
        <div
          class="priority-tag"
          :class="[priority[task.task_priority - 1]]"
          v-if="task.task_priority !== '1'"
          :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
        >
          {{ priorityText }}
        </div>
        <!-- 选择优先级的下拉框 -->
        <div
          class="priority-tags-box"
          v-show="
            isChoosePriority &&
            Parent.currentShowPriority === task.task_id &&
            isAllowUpdate
          "
        >
          <div
            class="priority-item"
            v-for="(item, index) in priorityList"
            :key="index"
            @click.stop="chooseTaskPriority(index)"
          >
            <div class="priority-tag" :class="[priorityList[index].color]">
              {{ priorityList[index].text }}
            </div>
          </div>
        </div>
      </div>
      <!-- 进度 -->
      <div
        class="
          task-info-value
          progress
          flex flex-y-center flex-x-center
          hidden-xs-only
        "
        @click.stop="beginSetProgress(task)"
        :style="{ cursor: isMe && isAllowUpdate ? 'pointer' : 'default' }"
      >
        <el-progress
          :color="task.progress_status === 1 ? '#0080FF' : '#FF5454'"
          type="circle"
          :percentage="task.progress_number"
          :width="20"
          :show-text="false"
          :stroke-width="2"
        ></el-progress>
        <span class="progress-text">{{ task.progress_number + '%' }}</span>
        <!-- 设置进度的弹窗 -->
        <div
          class="task-process"
          v-show="
            isShowTaskProgress &&
            Parent.currentShowProgress === task.task_id &&
            isAllowUpdate
          "
          @click.stop
        >
          <h6 class="process-title">当前进度</h6>
          <div class="flex flex flex-y-center">
            <input
              type="number"
              class="input"
              min="0"
              max="100"
              v-model="currentProgress"
              ref="editProgressRef"
              @blur="readyToEditProgress(Parent.currentShowProgress)"
              @keyup.enter="$event.target.blur(Parent.currentShowProgress)"
            />
            <span class="percent">%</span>
          </div>
          <h6 class="process-title">状态</h6>
          <div class="radio-item">
            <label
              for=""
              class="label normal flex flex-y-center"
              @click="setTaskProgressStatus(1, Parent.currentShowProgress)"
            >
              <svg class="icon radio-icon" aria-hidden="true">
                <use
                  :xlink:href="
                    currentProgressStatus === 1
                      ? '#icon-Group706'
                      : '#icon-Group592'
                  "
                ></use>
              </svg>
              <input
                type="radio"
                name="task"
                :checked="currentProgressStatus === 1"
                id=""
                class="radio"
              />
              <span>正常</span>
            </label>
          </div>
          <div class="radio-item">
            <label
              for=""
              class="label late flex flex-y-center"
              @click="setTaskProgressStatus(2, Parent.currentShowProgress)"
            >
              <svg class="icon radio-icon" aria-hidden="true">
                <use
                  :xlink:href="
                    currentProgressStatus === 2
                      ? '#icon-Group705'
                      : '#icon-Group593'
                  "
                ></use>
              </svg>
              <input
                type="radio"
                name="task"
                :checked="currentProgressStatus === 2"
                id=""
                class="radio"
              />
              <span>已延期</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- 他孩子 -->
    <div v-show="isShowChild">
      <draggable
        :list="task.children"
        animation="300"
        :scroll="true"
        :move="onMove"
        @end="choose"
        chosenClass="chosen"
        group="demoDragTree"
        handle=".move-task-rank"
      >
        <transition-group>
          <!-- 每一个孩子的结构都是当前的结构 -->
          <div v-for="child in task.children" :key="child.task_id">
            <task-item-tree
              :task="child"
              :nowTime="nowTime"
              :isMe="isMe"
              :currentShowMore="currentShowMore"
              :layer="layer + 1"
              @changeLabel="$emit('changeLabel', $event)"
            ></task-item-tree>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
// import Avatar from '../common/Avatar.vue'
export default {
  name: 'task-item-tree',
  components: {
    draggable
    // Avatar
  },
  props: {
    // 当前要展示的任务
    task: {
      type: Object,
      default: () => {
        return {}
      }
    },
    layer: {
      type: Number,
      default: 1
    },
    // 当前是否是我
    isMe: {
      type: Boolean,
      default: false
    },
    // 当前选中哪个任务
    currentShowMore: {
      type: Number
    },
    // 当前操作的是哪个职员
    currentClerkId: {
      type: Number
    },
    // 当前时间
    nowTime: {
      type: Date
    }
  },
  data() {
    return {
      // 优先级的样式名字
      priority: ['', 'green', 'blue', 'yellow', 'orange', 'red'],
      // 是否选择优先级
      isChoosePriority: false,
      // 当前选择优先级的任务id
      // currentPriorityTaskId: -1,
      // 是否展示任务进度
      isShowTaskProgress: false,
      // 当前设置谁的进度
      // currentProgressId: -1,
      // 设置进度的弹窗里面的输入
      // 当前任务的进度状态：是否延期
      currentProgressStatus: 0,
      // 当前输入的进度
      currentProgress: 0,
      // 临时记录进度
      temp: 0,
      // 当前在编辑谁的任务名
      currentEditTask: -1,
      // 临时任务名
      tempTaskName: '',
      // 是否显示移动的弹窗
      showMoveBox: false,
      // 是否展示出它的孩子
      isShowChild: false,
      // 快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '清空',
            onClick(picker) {
              picker.$emit('pick', '')
            }
          }
        ]
      },
      // 截止时间
      lastTime: '',
      // esc编辑是否启用
      escIsShow: false,
      escShowRef: '',
      currentInfo: {}
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOut)
    this.lastTime = this.task.end_time
  },
  computed: {
    ...mapState(['priorityList', 'myClerkId', 'colorList', 'screenWidth']),
    // 解析标签
    parseLabel() {
      if (!this.task) {
        return {}
      }
      const labelMap = {}
      this.task.task_label_list.forEach((e) => {
        labelMap[e.label_name] = e.label_id
      })
      const tmpName = this.task.task_name
      let showName = ''
      const list = []
      let status = 0
      let gth = 0
      let item = ''
      tmpName.split('').forEach((e) => {
        let te = e
        // 处理下中文符号
        switch (te) {
          case '！': {
            te = '!'
          }
        }
        switch (status) {
          // 开始
          case 0: {
            switch (te) {
              case '!': {
                if (gth === 0) {
                  gth++
                  status = 1
                } else {
                  showName += e
                  status = 11
                }
                break
              }
              case ' ': {
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                showName += e
                status = 11
                break
              }
            }
            break
          }
          case 1: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                status = 0
                showName += e
                break
              }
            }
            break
          }
          case 2: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              default: {
                status = 11
                showName += e
                break
              }
            }
            break
          }
          case 10: {
            switch (te) {
              case '!': {
                if (item !== '') {
                  list.push(item)
                } else {
                  showName += '#'
                }
                item = ''
                if (gth === 0) {
                  gth++
                  status = 2
                } else {
                  status = 11
                }
                break
              }
              case ' ': {
                if (item !== '') {
                  list.push(item)
                }
                item = ''
                status = 0
                break
              }
              default: {
                item += e
                break
              }
            }
            break
          }
          // 直接加
          case 11: {
            showName += e
            break
          }
        }
      })
      if (status === 10) {
        if (item.length > 0) {
          list.push(item)
        }
      }
      let gthStr = ''
      for (let i = 0; i < gth; i++) {
        gthStr += '!'
      }
      return {
        showName,
        list: list.map((e) => {
          return {
            id: labelMap[e] || '',
            name: e
          }
        }),
        gth,
        gthStr
      }
    },
    // 优先级文字
    priorityText() {
      switch (this.task.task_priority) {
        case 1:
          return ''
        case 2:
          return '最低'
        case 3:
          return '较低'
        case 4:
          return '普通'
        case 5:
          return '较高'
        case 6:
          return '最高'
        default:
          return ''
      }
    },
    // 当前任务是否延期
    isLate() {
      return this.task.progress_status === 2
    },
    // 获取当前任务的截止时间
    getLastTime() {
      return this.task.end_time && this.task.end_time.slice(0, 10)
    },
    // 超时数量
    timeoutCount() {
      if (!this.task.children) {
        return 0
      }
      let len = 0
      console.log(this.task.children)
      const count = (list) => {
        list.forEach((e) => {
          if (e.progress_status === 2) {
            len++
          }
          if (e.children) {
            count(e.children)
          }
        })
      }
      count(this.task.children)
      return len
    },
    // 是否修改任务权限
    isAllowUpdate() {
      return (
        [this.task.create_clerk_id, this.task.director_clerk_id].indexOf(
          this.myClerkId
        ) !== -1
      )
    }
  },
  inject: ['Parent'],
  watch: {
    // 如果数据的end_date改变了，那么让input框里的值也跟着改变
    'task.end_date'() {
      this.lastTime = this.task.end_date
    },
    escIsShow(val) {
      if (val) {
        this.$refs[this.escShowRef].blur()
      }
    }
  },
  methods: {
    onMove(e) {
      console.log(this.isMe)
      if (!this.isMe) {
        return false
      }
      console.log(e)
      this.currentInfo = e
    },
    choose(e) {
      if (!this.isMe) {
        return
      }
      if (Object.keys(this.currentInfo).length === 0) {
        return
      }
      this.insertBefore()
      this.currentInfo = {}
    },
    // 插入到某任务之前
    async insertBefore() {
      console.log(this.currentInfo)
      const draggedContext = this.currentInfo.draggedContext
      const relatedContext = this.currentInfo.relatedContext
      const formObj = { task_id: draggedContext.element.task_id }
      // 拖拽后相对于列表位置
      const positionIndex = relatedContext.list.findIndex((e) => {
        return e.task_id === draggedContext.element.task_id
      })
      // 判断长度是否是最后一个
      if (positionIndex === relatedContext.list.length - 1) {
        if (relatedContext.element._LEVEL_ === 1) {
          formObj.group_id = relatedContext.element.group_id
        } else {
          formObj.pid = relatedContext.element.pid
        }
      } else {
        formObj.next_task_id = relatedContext.list[positionIndex + 1].task_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.taskMove, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
      // 刷新任务
      this.Parent.refreshTaskList()
    },
    // 获取小旗子上的职员名字
    getTaskFlagText(list) {
      let str = ''
      for (const item of list) {
        str += (item.clerk_alias || item.clerk_name) + ','
      }
      return str.slice(0, str.length - 1)
    },
    // 准备修改任务名称
    beginEditTaskName(task) {
      // 设置当前编辑的任务id
      this.currentEditTask = task.task_id
      // 给input赋值
      this.tempTaskName = task.task_name
      this.Parent.currentShowMore = -1
      // console.log('编辑任务名', this.$refs[`taskNameInput_${task.task_id}`])
      this.$nextTick(() => {
        this.$refs[`taskNameInput_${task.task_id}`].focus()
      })
    },
    // 处理任务名的编辑
    async handleEditTask(task) {
      const val = this.tempTaskName.trim()
      if (val.length === 0 || val === task.task_name) {
        this.cancelEditTask()
        this.escIsShow = false
        return
      }
      if (this.escIsShow) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.cancelEditTask()
          this.escIsShow = false
          return
        }
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskName, {
          task_id: task.task_id,
          task_name: val
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务名成功',
            type: 'success'
          })
        }
        this.cancelEditTask()
        this.Parent.refreshTaskList()
      }
      this.escIsShow = false
    },
    // 取消任务名的编辑
    cancelEditTask() {
      this.tempTaskName = ''
      this.currentEditTask = -1
    },
    // 关闭小弹窗
    clickOut() {
      this.isChoosePriority = false
      this.isShowTaskProgress = false
    },
    // 开始设置任务进度
    beginSetProgress(task) {
      if (!(this.isMe && this.isAllowUpdate)) return
      if (this.Parent.currentShowProgress === task.task_id) {
        this.isShowTaskProgress = false
        this.Parent.showProgressModal(-1)
        return
      } else {
        this.isShowTaskProgress = true
        this.Parent.showProgressModal(task.task_id)
      }
      // this.currentProgressId = task.task_id
      // 根据子组件传过来的进度数据，回填到input上
      this.currentProgress = task.progress_number
      // 临时记录进度
      this.temp = task.progress_number
      // 获取一下当前任务是否延期
      this.currentProgressStatus = task.progress_status
      console.log('当前任务是否延期', this.currentProgressStatus)
    },
    // 设置它的progress
    async setTaskProgressStatus(val, id) {
      // 获取到我点击的最新值
      this.currentProgressStatus = val
      console.log(this.currentProgressStatus)
      // 发请求，修改任务的进度状态
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskProgressStatus, {
          task_id: id,
          progress_status: this.currentProgressStatus
        })
      )
      if (err) {
        console.log(res)
      } else {
        console.log(res)
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务进度状态成功！',
            type: 'success'
          })
          // 刷新任务数据
          this.Parent.refreshTaskList()
        }
      }
    },
    // 输入进度，准备修改
    async readyToEditProgress(id) {
      const progresValue = this.currentProgress
      // 假如把里面的数字删干净了，视为没有修改
      if (progresValue.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
      } else if (Number(progresValue) < 0 || Number(progresValue) > 100) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$refs.editProgressRef.blur()
      } else {
        // 假如输入正确，那就发起修改请求
        this.$refs.editProgressRef.blur()
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: this.currentProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '任务的进度修改成功！',
              showClose: true
            })
            this.Parent.refreshTaskList()
          }
        }
      }
    },
    renderTime(v) {
      if (v === null) {
        return ''
      }
      return this.$util.getDateStr(new Date(v))
    },
    // 设置截止时间
    async setLastTime() {
      if (this.lastTime === this.renderTime(this.task.end_time)) {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editEndTime, {
          task_id: this.task.task_id,
          end_time: this.lastTime
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '截止时间修改成功！',
          type: 'success'
        })
        this.$set(this.task, 'end_time', this.lastTime)
      } else {
        this.lastTime = this.task.end_time
      }
    },
    // 点击显示任务详情
    showDetail(id) {
      this.Parent.openDetailModal(id)
    },
    // 删除任务
    removeTask(id) {
      if (!(this.isMe && this.isAllowUpdate)) return
      this.Parent.removeTask(id)
    },
    // 标星任务
    starTask(id) {
      if (!this.isMe) return
      this.Parent.starTask(id)
    },
    // 显示出更多按钮
    showMoreMenu(id) {
      console.log(111)
      this.Parent.showMoreModal(id)
    },
    // 点击修改任务状态
    async modifyTaskStatus(id, task) {
      if (!this.isAllowUpdate) return
      let target = 0
      // 如果已完成，就让他未完成。否则让它已完成
      if (task.task_status === 3 || task.task_status === 4) {
        target = 2
      } else {
        target = 3
      }
      // 发送请求修改
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTaskStatus, {
          task_id: id,
          task_status: target
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res)
        this.task.task_status = target
        // 如果把状态切换到已完成，那么还要发请求修改进度值为100
        if (target === 3) {
          const res = await this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: 100
          })
          if (res.state === 'ok') {
            this.task.progress_number = 100
          }
        }
        // 修改完以后，刷新任务列表
        // this.Parent.refreshTaskList()
      }
    },
    // 点击显示
    toggleChoosePriority(id) {
      // 如果当前操作的职员是我自己，那么就可以显示出这个白框
      if (!this.isMe) return
      if (this.Parent.currentShowPriority !== id) {
        this.Parent.showPriorityModal(id)
        this.isChoosePriority = true
      } else {
        this.Parent.showPriorityModal(-1)
        this.isChoosePriority = false
      }
    },
    // 选择优先级
    async chooseTaskPriority(index) {
      this.currentPriority = index
      // 修改完当前的优先级值，然后发起请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskPriority, {
          task_id: this.task.task_id,
          task_priority: 6 - this.currentPriority
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          // 把字面上的优先级索引也修改了，让文字修改
          this.task.task_priority = 6 - this.currentPriority
          this.$message({
            showClose: true,
            message: '优先级修改成功',
            type: 'success'
          })
          this.Parent.showPriorityModal(-1)
          this.Parent.refreshTaskList()
        }
      }
    },
    // 准备移动
    readyToMove() {
      this.Parent.showMoveModal(this.task.task_id)
    },
    // 显示隐藏子元素
    toggleChild() {
      this.isShowChild = !this.isShowChild
    },
    // 空函数
    empty() {}
  }
}
</script>

<style lang="scss" scoped>
// 导入优先级样式
@import '@/assets/scss/priority_tags.scss';
// 导入任务进度样式
@import '@/assets/scss/progress_box.scss';
.chosen {
  background-color: #f6f6f6 !important;
}
.task-item {
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  height: 48px;
  &.over .task-name {
    text-decoration: line-through;
    color: #999;
  }
  &.end_over .task-name {
    text-decoration: line-through;
    color: orange;
  }
  // 第一块
  .task-item-left {
    position: relative;
    box-sizing: border-box;
    width: 54%;
  }
  // 任务名
  .task-name {
    padding-left: 10px;
    flex: 1;
    cursor: pointer;
    margin-left: 0;
  }
  .task-name-wrapper {
    margin-right: 20px;
    width: calc(100% - 22px - 40px);
    // 显示名字和子任务的完成情况
    .task-name-box {
      max-width: 100%;
      width: min-content;
    }
    p {
      line-height: 1.7;
    }
  }
  // 旗子的样式
  .flag {
    position: relative;
    margin-left: 12px;
    line-height: 20px;
    padding: 0 3px;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    transform: translateY(-5px);
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 7px;
      top: 92%;
      width: 4px;
      border-radius: 0 0 4px 4px;
    }
    &.blue::after,
    &.blue {
      background: #50b9f3;
    }
    &.green::after,
    &.green {
      background: #19caa1;
    }
    &.red::after,
    &.red {
      background: $red;
    }
    &.yellow::after,
    &.yellow {
      background: #f9c43b;
    }
    &.orange::after,
    &.orange {
      background: #ff9254;
    }
  }
  // 任务的标签
  .task-label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    width: min-content;
    padding: 0 3px;
    white-space: nowrap;
    line-height: 20px;
    color: #2b6cff;
    background: #e2ebff;
    border-radius: 3px;
    font-size: 12px;
  }
  &.layer {
    padding-left: 20px;
  }
  .task-info-value {
    position: relative;
    text-align: center;
    line-height: 1.5;
    min-height: 22px;
    color: #333;
    &.sumbit {
      width: 88px;
    }
    &.priority {
      width: 10%;
    }
    &.progress {
      display: flex;
      width: 14%;
    }
    &.last-time {
      width: 140px;
      width: 10%;
    }
    &.leader-box {
      width: 120px;
      width: 10%;
    }
  }
  &:hover {
    .more {
      opacity: 1;
    }
  }
  // 更多按钮
  .more {
    position: relative;
    width: 20px;
    height: 20px;
    padding-top: 0;
    display: block;
    opacity: 0;
    line-height: 20px;
    cursor: pointer;
    &-icon {
      vertical-align: middle;
      font-size: 20px;
      display: inline-block;
      // font-weight: 900;
      line-height: 1;
      color: #999;
    }
  }
}
// 优先级的按钮
.priority-tag {
  margin: 0 auto;
}

.progress-text {
  width: 45px;
  text-align: right;
  display: block;
}

// 更多弹窗
.more-menu-box {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 140px;
  height: auto;
  transform: none;
  background: #ffffff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.4);
  border-radius: 4px;
  .more-menu-item {
    padding: 0 18px;
    line-height: 40px;
    height: 40px;
    color: #333;
    cursor: pointer;
    &.red {
      color: $red;
    }
    &:hover {
      background: $light-grey-bg;
    }
  }
}

.priority-tags-box {
  width: 100%;
}

// 设置进度的弹窗
.task-process {
  position: absolute;
  left: 25%;
  transform: translateX(-50%);
  top: 170%;
  // @include pos-center(absolute);
  // top: 100%;
  .process-title {
    text-align: left;
  }
  &::before {
    position: absolute;
    left: 70%;
    transform: translateX(-50%);
    // @include pos-center(absolute);
  }
}

.input-task-name {
  width: 80%;
  height: 40px;
  margin-left: 10px;
  box-sizing: border-box;
  line-height: 22px;
  padding: 9px 10px;
  display: block;
  border: 1px solid $main-color;
  outline: none;
  border-radius: 4px;
}

// 任务前面的复选框
.check-box {
  display: inline-block;
  .icon {
    width: 20px;
    height: 20px;
  }
}

// 星星的样式
.star .icon {
  width: 24px;
  height: 24px;
}

.flex1 {
  width: 0;
}

.name-box {
  width: 100%;
  // width: calc(100% - 88px - 102px - 150px);
  box-sizing: border-box;
}

// 悬浮任务，名字变蓝
.task-item:hover .task-name,
.task-item:hover .child-info {
  color: $main-color;
}
.child-info {
  margin-left: 5px;
}
.task-item {
  position: relative;
  .arrow {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    left: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #999;
  }
}

// 修改选择日期组件样式
.task-info-value ::v-deep .el-date-editor.el-input {
  width: 90%;
  margin: 0 auto;
}

.task-info-value ::v-deep .el-date-editor .el-input__inner {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.task-info-value ::v-deep .el-input__icon {
  display: none;
}

// 如果已经延期了，文字设为红色
.task-info-value.late ::v-deep .el-date-editor .el-input__inner {
  color: $red;
}

.last-time-text {
  font-size: 13px;
  &.late {
    color: $red;
  }
}

.director-e-name {
  width: 45px !important;
  text-align: left !important;
}
@media screen and (max-width: 768px) {
  .task-item {
    height: auto;
    min-height: 48px;
    padding: 5px 0;
    display: flex;
    align-items: flex-start;
    .task-name {
      white-space: pre-line;
    }

    .task-item-left {
      flex: 1;
      align-items: flex-start;

      .arrow {
        margin-top: 3px;
      }

      .check-box {
        margin-top: 2px;
      }
    }

    .task-name-wrapper {
      margin-right: 0;
      width: calc(100% - 20px) !important;
      // 显示名字和子任务的完成情况
      .task-name-box {
        width: 100%;
      }
    }

    .name-box {
      align-items: flex-start;

      .task-name-box {
        align-items: flex-start;
      }
    }
  }

  .task-item .task-info-value {
    width: 60px !important;
  }
}
.last-time ::v-deep {
  .el-input--prefix .el-input__inner {
    border: 1px solid #fff;
  }
  .el-input--prefix .el-input__inner:hover,
  .el-input--prefix .el-input__inner:focus {
    border-color: $main-color;
  }
}
</style>
