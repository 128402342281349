/*
 * @Author: hzh
 * @Date: 2021-08-09 17:53:35
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-12 17:20:27
 */
import Vue from 'vue'
export const EventBus = new Vue({
  data() {
    return {
      list: []
    }
  }
})

/**
 * 事件总线-触发全局的自定义事件，附加参数都会传给监听器回调函数
 * @param {*} name 事件名称
 * @param {*} data 传输值
 */
export const EventBusEmit = (name, data) => {
  EventBus.$emit(name, data)
}

/**
 * 事件总线-监听全局的自定义事件，事件由 EventBusEmit 触发，回调函数会接收事件触发函数的传入参数
 * @param {*} name 事件名称
 * @param {*} fn 事件回调
 */
export const EventBusOn = (name, fn) => {
  EventBus.$on(name, fn)
}

/**
 * 事件总线-移除全局的自定义事件，事件由 EventBusEmit 触发，回调函数会接收事件触发函数的传入参数
 * @param {*} name 事件名称
 * @param {*} fn 事件回调
 */
export const EventBusOff = (name, fn) => {
  EventBus.$off(name, fn)
}

/**
 * 事件总线-监听全局的自定义事件，事件由 EventBusEmit 触发，但仅触发一次，在第一次触发之后移除该监听器
 * @param {*} name 事件名称
 * @param {*} fn 事件回调
 */
export const EventBusOnce = (name, fn) => {
  EventBus.$once(name, fn)
}
