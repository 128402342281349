<template>
  <div>
    <scroll-fresh
      className="message-list"
      :class="{ 'no-like': !loading && list.length == 0 }"
      dataName="message-data"
      :page="pageNumber"
      :lastPage="totalPage"
      :showNoMore="!loading && list.length > 0"
      :showBackTop="true"
      @get-next-page="getNextPage"
    >
      <el-skeleton :loading="loading" animated :count="4">
        <template slot="template">
          <div class="el-skeleton-item flex flex-y-start">
            <el-skeleton-item
              variant="image"
              style="width: 64px; height: 64px; margin-right: 14px"
            />
            <div class="el-skeleton-item-content flex1">
              <div class="el-skeleton-item-header">
                <div>
                  <el-skeleton-item
                    variant="p"
                    style="width: 50px; height: 5px; margin-bottom: 15px"
                  />
                </div>
                <div>
                  <el-skeleton-item
                    variant="p"
                    style="height: 5px; width: 130px"
                  />
                </div>
              </div>
              <div class="el-skeleton-item-text">
                <el-skeleton-item
                  variant="p"
                  style="height: 5px; margin-bottom: 15px"
                />
                <el-skeleton-item
                  variant="p"
                  style="height: 5px; margin-bottom: 15px"
                />
                <el-skeleton-item
                  variant="p"
                  style="height: 5px; margin-bottom: 15px"
                />
                <el-skeleton-item
                  variant="p"
                  style="height: 5px; margin-bottom: 15px"
                />
              </div>
              <div class="el-skeleton-item-footer flex flex-x-end">
                <el-skeleton-item
                  variant="button "
                  style="width: 40px; margin-right: 20px; height: 5px"
                /><el-skeleton-item
                  variant="button "
                  style="width: 40px; height: 5px"
                />
              </div>
            </div>
          </div>
        </template>
        <template>
          <template v-for="(item, index) in list">
            <member-message-item
              @changeMenu="changeMenu($event, index)"
              :messageInfo="item"
              @openDetail="openDetail(index)"
              @clickAvatar="clickAvatar"
              :key="item.moment_id"
              v-if="item.is_del === 0"
            ></member-message-item>
            <member-message-del
              v-else
              :key="item.moment_id"
              @cancel="cancelPraise(index)"
            ></member-message-del>
          </template>
        </template>
      </el-skeleton>
      <member-empty
        v-if="list.length == 0 && isShow"
        icon="#icon-a-Frame22"
        title="暂无喜欢动态，快去圈子首页看看吧！"
      ></member-empty>
    </scroll-fresh>
    <edit-message
      :visible="editVisible"
      @addComplete="addComplete"
      @editComplete="editComplete"
      :momentId="editMomentId"
      @close="closeEdit"
    ></edit-message>
    <message-detail-pop
      :visible="detailVisible"
      :momentId="detailMomentId"
      @close="closeDetail"
      @toEdit="detailToEdit"
    ></message-detail-pop>
  </div>
</template>

<script>
import MemberMessageItem from '@/components/member/MemberMessageItem.vue'
import MemberMessageDel from '@/components/member/MemberMessageDel.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
// 导入滚动刷新组件'../components/common/ScrollFresh.vue'
// 侧边详情
import MessageDetailPop from '@/components/circle/MessageDetailPop.vue'
import MemberEmpty from '@/components/member/MemberEmpty.vue'
import { mapState } from 'vuex'
export default {
  components: {
    MemberMessageItem,
    MemberMessageDel,
    ScrollFresh,
    EditMessage,
    MessageDetailPop,
    MemberEmpty
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalPage: 2,
      list: [],
      // 修改展示
      editVisible: false,
      // 详情展示
      detailVisible: false,
      // 修改的id
      editMomentId: -1,
      // 详情的id
      detailMomentId: -1,
      isShow: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['myClerkId'])
  },
  methods: {
    // 触发点赞
    async cancelPraise(index) {
      if (this.list[index].is_praise === 0) {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.praiseMoment, {
          moment_id: this.list[index].moment_id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.list[index], 'is_praise', res.is_praise ? 1 : 0)
        res.is_praise
          ? this.list[index].praise_number++
          : this.list[index].praise_number--
      }
    },
    // 添加圈子回调
    addComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.refreshList()
    },
    // 修改圈子回调
    editComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.refreshList()
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.loading = true
      await this.getList(true)
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    // 获取列表
    async getList(reset = false) {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        my_praise: 1,
        is_del: ''
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.list = res.page.list
        } else {
          this.list = this.list.concat(res.page.list)
        }
        this.pageNumber = res.page.pageNumber
        if (res.page.list.length < this.pageSize) {
          this.totalPage = this.pageNumber
        } else {
          this.totalPage = this.pageNumber + 1
        }
        if (this.list.length === 0) {
          this.isShow = true
        }
      }
    },
    // 获取下一页
    async getNextPage() {
      this.pageNumber++
      this.getList()
    },
    // 评论数量加 1
    incrementComment(index) {
      this.list[index].comment_number++
    },
    // 圈子Menu回调
    async changeMenu(ob, index) {
      switch (ob.type) {
        // 删除
        case 'delete': {
          this.momentDelte(index)
          break
        }
        // 复制链接
        case 'copy': {
          this.copyLink(index)
          break
        }
        // 编辑
        case 'edit': {
          this.editMomentId = this.list[index].moment_id
          this.editVisible = true
          break
        }
      }
    },
    // 圈子删除
    async momentDelte(index) {
      this.$confirm('此操作将删除该圈子, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.deleteMoment, {
            moment_id: this.list[index].moment_id
          })
        )
        if (err) {
          this.$message.error(err)
        } else if (res.state === 'ok') {
          this.list.splice(index, 1)
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
        }
      })
    },
    // 复制链接
    copyLink(index) {
      this.$copyText(
        `${this.$config.frontHome}topicInfo/${this.list[index].moment_id}`
      ).then(
        () => {
          this.$message({
            showClose: true,
            message: '已成功复制，可直接去粘贴',
            type: 'success'
          })
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // 关闭编辑弹窗
    closeEdit() {
      this.editVisible = false
    },
    // 处理菜单
    handerMenu(index) {
      this.current = index
      switch (index) {
        case 0:
          this.myFocus = ''
          this.refreshList()
          break
        case 1:
          this.myFocus = 1
          this.refreshList()
          break
        case 2:
          this.$router.push('/member/home')
          break
      }
    },
    // 打开详情
    openDetail(index) {
      this.$router.push({
        name: 'memberLike',
        query: { ...this.$route.query, detail_id: this.list[index].moment_id }
      })
    },
    // 关闭详情
    closeDetail() {
      // this.detailMomentId = -1
      // this.detailVisible = false
      const query = { ...this.$route.query }
      delete query.detail_id
      this.$router.push({
        name: 'memberLike',
        query: query
      })
    },
    // 详情跳转修改
    detailToEdit() {
      this.editMomentId = this.detailMomentId
      this.detailMomentId = -1
      this.detailVisible = false
      this.editVisible = true
    },
    // 点击头像
    clickAvatar(clerkId) {
      this.$router.push({
        name: 'memberHome',
        params: { clerkId: clerkId }
      })
    }
  },
  mounted() {
    this.refreshList()
    // 打开详情
    if (this.$route?.query?.detail_id) {
      this.detailMomentId = this.$route.query.detail_id
      this.detailVisible = true
    }
  },
  watch: {
    '$route.query'(val, old) {
      // 打开详情
      if (val?.detail_id) {
        this.detailMomentId = val?.detail_id
        this.detailVisible = true
      } else {
        this.detailMomentId = -1
        this.detailVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-list {
  height: calc(100vh - 84px);
  overflow: scroll;

  &.no-like {
    background-color: #fff;
  }
}
.empty-container {
  margin-top: 262px;
}
.el-skeleton-item {
  margin-bottom: 20px;
  padding: 19px 24px;
  background-color: #fff;
  .el-skeleton-item-content {
    position: relative;
  }
  .el-skeleton-item-header {
    margin-top: 8px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .message-list {
    position: fixed;
    top: 0;
    height: calc(100vh - 50px);
    width: 100vw;
  }
}
</style>
