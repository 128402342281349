<template>
  <div class="structure-item">
    <template v-for="item in myDataList">
      <div :key="item.id">
        <!-- 职员 -->
        <structure-child-item
          :info="item"
          v-if="item.type === 1"
          v-show="item.showClerk"
          :menuList="clerkMenuList"
          :showDelete="item.showDelete"
          :deleteDisable="level > 1"
          :isAdmin="item.isAdmin"
          @delete="$emit('delete', $event)"
          @change="$emit('change', $event)"
        ></structure-child-item>
        <!-- 部门 -->
        <div v-else>
          <div v-show="item.childIncludesSearchClerk">
            <div class="structure-sub-item flex flex-x-between flex-y-center">
              <div @click="handleUnwind(item.id)" class="structure-item-title">
                <i class="icon-Frame9" :class="{ active: item.isActive }"></i>
                <span>{{ item.dept_name }}</span>
              </div>
              <div class="flex action-bar">
                <edit-menu
                  :menuList="deptMenuList"
                  :active="item.active"
                  @change="$emit('change', { info: item, index: $event })"
                ></edit-menu>
                <div class="delete-button">
                  <i
                    :class="[level > 1 ? 'icon-a-addfill' : 'icon-trash1']"
                    v-if="item.showDelete"
                    @click="$emit('delete', { info: item, disable: level > 1 })"
                  ></i>
                </div>
              </div>
            </div>
            <el-collapse-transition>
              <div v-show="active.includes(item.id)" style="padding-left: 36px">
                <structure-tree
                  :menuList="menuList"
                  :dataList="item.children"
                  :skipDeleteDeptIdList="skipDeleteDeptIdList"
                  :skipDeleteTopDeptIdList="skipDeleteTopDeptIdList"
                  :skipDeleteClerkIdList="skipDeleteClerkIdList"
                  :adminClerkIdList="adminClerkIdList"
                  :searchClerkIdList="searchClerkIdList"
                  :parentShowDelete="item.childShowDelete"
                  :level="level + 1"
                  :isShare="isShare"
                  @delete="$emit('delete', $event)"
                  @change="$emit('change', $event)"
                ></structure-tree>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import StructureChildItem from './StructureChildItem.vue'
import EditMenu from './EditMenu.vue'
export default {
  name: 'StructureTree',
  components: {
    StructureChildItem,
    EditMenu
  },
  props: {
    // 数据列表
    dataList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 菜单列表
    menuList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 跳过删除部门
    skipDeleteDeptIdList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 跳过删除顶层部门
    skipDeleteTopDeptIdList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 跳过删除职员
    skipDeleteClerkIdList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 超级管理员职员列表
    adminClerkIdList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 搜索职员id
    searchClerkIdList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 父级展示删除按钮
    parentShowDelete: {
      type: Boolean,
      default: true
    },
    // 层级
    level: {
      type: Number,
      default: 1
    },
    isShare: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 展开的id列表
      active: [],
      menuId: ''
    }
  },
  computed: {
    myDataList() {
      return this.dataList.map((e) => {
        const info = { ...e }
        // 职员
        if (e.type === 1) {
          // 是否展示职员
          // 如果有搜索列表判断是否在搜索职员列表里 否则 直接展示
          const showClerk =
            this.searchClerkIdList.length > 0
              ? this.searchClerkIdList.includes(e.clerk_id)
              : true
          info.showClerk = showClerk

          // 是否为超级管理员
          const isAdmin = this.adminClerkIdList.includes(e.clerk_id)
          info.isAdmin = isAdmin

          // 是否展示删除按钮
          const showDelete =
            this.parentShowDelete &&
            !this.skipDeleteClerkIdList.includes(e.clerk_id) &&
            (this.level === 1 || (this.level > 1 && !this.isShare))
          info.showDelete = showDelete
        } else {
          // 部门

          // 子部门是否包含搜索职员列表内的职员
          const childIncludesSearchClerk = this.checkIncludesSrarchClerk(
            e.children
          )
          info.childIncludesSearchClerk = childIncludesSearchClerk

          // 部门是否展开
          const isActive = this.active.includes(e.id)
          info.isActive = isActive

          // 是否展示删除
          const showDelete =
            this.parentShowDelete &&
            !this.skipDeleteTopDeptIdList.includes(e.dept_id) &&
            !this.skipDeleteDeptIdList.includes(e.dept_id) &&
            (this.level === 1 || (this.level > 1 && !this.isShare))
          info.showDelete = showDelete

          // 子级
          // 父级必须展示删除按钮
          const childShowDelete =
            this.parentShowDelete &&
            !this.skipDeleteTopDeptIdList.includes(e.dept_id)
          info.childShowDelete = childShowDelete
        }
        return info
      })
    },
    clerkMenuList() {
      return this.menuList.map((e) => {
        const t = { ...e }
        if (e.clerk_disable) {
          t.disable = true
        }
        return t
      })
    },
    deptMenuList() {
      return this.menuList.map((e) => {
        const t = { ...e }
        if (e.dept_disable) {
          t.disable = true
        }
        return t
      })
    }
  },
  methods: {
    // 控制展开/收缩
    handleUnwind(e) {
      if (this.active.includes(e)) {
        this.$set(
          this,
          'active',
          this.active.filter((item) => item !== e)
        )
      } else {
        this.active.push(e)
      }
    },
    // 判断自己是否包含搜索职员
    checkIncludesSrarchClerk(list) {
      if (this.searchClerkIdList.length === 0) {
        return true
      }
      if (!list) {
        return false
      }
      const idList = []
      // 循环把下级的职员加到列表里
      const loop = (l) => {
        l.forEach((e) => {
          if (e.type === 1) {
            idList.push(e.clerk_id)
          } else {
            if (e.children) {
              loop(e.children)
            }
          }
        })
      }
      loop(list)
      const has = this.searchClerkIdList.find((e) => {
        return idList.includes(e)
      })
      return has != null
    }
  }
}
</script>

<style lang="scss" scoped>
.structure-item {
  .structure-sub-item {
    padding: 10px 0;
  }
  .action-bar {
    .edit-select {
      position: relative;
      cursor: pointer;
      padding: 4px 7px;
      margin-right: 9px;
      border-radius: 4px;
      i {
        margin-left: 13px;
      }

      &:hover {
        background-color: #ececec;
      }
    }

    .delete-button {
      padding: 4px 0 4px 20px;
      border-left: 1px solid #ddd;
      width: 20px;
      i {
        cursor: pointer;
        color: #bbb;
        &:hover {
          color: red;
        }
      }
    }
  }

  .structure-item-title {
    cursor: pointer;

    i {
      display: inline-block;
      font-size: 12px;
      padding: 0 6px;
      margin-right: 10px;
      color: #bbb;

      &.active {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
