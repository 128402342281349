<template>
  <div class="member-info-container">
    <div class="info-header">
      <div class="info-back-button" @click="back">
        <i class="icon-a-arrowaltright1"></i>
        返回
      </div>
      <div class="flex member-info">
        <div class="member-avatar">
          <el-image :src="clerkInfo.clerk_avatar">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="member-content">
          <div class="member-name">
            <span>{{
              clerkInfo.clerk_alias || clerkInfo.clerk_name || ''
            }}</span>
          </div>
          <div class="member-remark">
            {{ clerkInfo.clerk_motto || '' }}
          </div>
        </div>
      </div>
    </div>
    <div class="info-tabs">
      <tabs
        :tab-list="tabList"
        alignment="flex-start"
        :tab-padding="18"
        :item-padding="18"
        :current="currentTab"
        @change="changeTab"
      ></tabs>
    </div>

    <div class="info-attention" v-if="currentTab === 0">
      <member-attention-item
        v-for="(item, index) in focusList"
        :key="item.clerk_id"
        :clerkId="item.clerk_id"
        :button-title="item | renderButtonTitle"
        :primary="item.is_focus === 0"
        :avatar="item.clerk_avatar"
        @clickAvatar="handlerClickAvatar(item)"
        @clickButton="handlerClickButton(1, index)"
      >
        <div>
          <div class="member-name">
            {{ item.clerk_alias || item.clerk_name }}
          </div>
          <div class="new-topic text-clamp-2">{{ item | renderContent }}</div>
        </div>
      </member-attention-item>
    </div>
    <!-- <div class="info-fans" v-if="currentTab === 1">
      <member-attention-item
        v-for="(item, index) in fansList"
        :key="item.clerk_id"
        :clerkId="item.clerk_id"
        :button-title="item | renderButtonTitle"
        :primary="item.is_focus === 0"
        :avatar="item.clerk_avatar"
        @clickAvatar="handlerClickAvatar(item)"
        @clickButton="handlerClickButton(2, index)"
      >
        <div>
          <div class="member-name">
            {{ item.clerk_alias || item.clerk_name }}
          </div>
          <div class="new-topic text-clamp-1">{{ item.clerk_motto || '' }}</div>
          <div class="new-topic">粉丝 {{ item.fans_number || 0 }}</div>
        </div>
      </member-attention-item>
    </div> -->
    <tips-dialog
      @cancle="cancle"
      @confirm="confirm"
      :title="title"
      v-if="visible"
    ></tips-dialog>
  </div>
</template>

<script>
import Tabs from '@/components/common/Tabs.vue'
import MemberAttentionItem from '@/components/member/MemberAttentionItem.vue'
import TipsDialog from '@/components/common/TipsDialog.vue'
export default {
  components: {
    Tabs,
    MemberAttentionItem,
    TipsDialog
  },
  data() {
    return {
      clerkId: -1,
      clerkInfo: {},
      tabList: ['关注', '粉丝'],
      currentTab: 0,
      // 关注
      focusPageNumber: 1,
      focusPageSize: 20,
      focusTotalPage: 2,
      focusList: [],
      // 粉丝
      fansPageNumber: 1,
      fansPageSize: 20,
      fansTotalPage: 2,
      fansList: [],
      // 取消弹窗
      visible: false,
      title: '',
      cancleType: 1,
      cancleIndex: -1
    }
  },
  computed: {},
  filters: {
    // 按钮文字
    renderButtonTitle(item) {
      if (item.is_focus === 1) {
        if (item.is_fans === 1) {
          return '互相关注'
        }
        return '取消关注'
      } else {
        return '关注'
      }
    },
    // 处理文字
    renderContent(item) {
      if (item.lastest_moment_info == null) {
        return '此人特别慵懒，暂未发布圈子~'
      }
      if (item.lastest_moment_info.moment_content?.trim().length > 0) {
        return item.lastest_moment_info.moment_content
      }
      switch (item.lastest_moment_info.content_type) {
        case 1:
          return '[图片]'
        case 2:
          return '[视频]'
      }
    }
  },
  methods: {
    // 组件回调
    changeTab(e) {
      if (this.currentTab === e) {
        switch (e) {
          case 0:
            this.refreshFocusList()
            break
          case 1:
            this.refreshFansList()
            break
        }
        return
      }
      console.log(e)
      let tab = ''
      switch (e) {
        case 1:
          tab = 'fans'
          break
      }
      const query = { ...this.$route.query }
      delete query.tab
      this.$router.replace({
        name: 'memberInfo',
        query: tab ? { ...query, tab: tab } : query
      })
    },
    // 获取职员信息
    async getClerkInfo() {
      const formObj = {
        clerk_id: this.clerkId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentClerkInfo, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this, 'clerkInfo', res.info)
      }
    },
    // 获取关注列表
    async getFocusList(reset = false) {
      const formObj = {
        pageNumber: this.focusPageNumber,
        pageSize: this.focusPageSize,
        clerk_id: this.clerkId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentFocusPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.focusList = res.page.list
        } else {
          this.focusList = this.focusList.concat(res.page.list)
        }
        this.focusPageNumber = res.page.pageNumber
        this.focusTotalPage = res.page.totalPage
      }
    },
    // 获取关注下一页
    async getNextFocusPage() {
      this.focusPageNumber++
      this.getFocusList()
    },
    // 刷新关注列表
    async refreshFocusList() {
      this.focusPageNumber = 1
      this.focusTotalPage = 2
      this.focusList = []
      this.getFocusList(true)
    },
    // 获取粉丝列表
    async getFansList(reset = false) {
      const formObj = {
        pageNumber: this.fansPageNumber,
        pageSize: this.fansPageSize,
        clerk_id: this.clerkId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentFansPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.fansList = res.page.list
        } else {
          this.fansList = this.fansList.concat(res.page.list)
        }
        this.fansPageNumber = res.page.pageNumber
        this.fansTotalPage = res.page.totalPage
      }
    },
    // 获取粉丝下一页
    async getNextFansPage() {
      this.fansPageNumber++
      this.getFansList()
    },
    // 刷新粉丝列表
    async refreshFansList() {
      this.fansPageNumber = 1
      this.fansTotalPage = 2
      this.fansList = []
      this.getFansList(true)
    },
    // 刷新信息
    async refreshInfo() {
      this.getClerkInfo()
      this.refreshFocusList()
      this.refreshFansList()
    },
    // 点击头像
    handlerClickAvatar(item) {
      this.$router.push({
        name: 'memberHome',
        params: { clerkId: item.clerk_id }
      })
    },
    // 点击按钮
    async handlerClickButton(type, index) {
      if (type === 1 && this.focusList[index].is_focus === 0) {
        this.addFocus(type, index)
      } else if (type === 2 && this.fansList[index].is_focus === 0) {
        this.addFocus(type, index)
      } else {
        this.visible = true
        this.title = '确定确定要取消关注么？'
        this.cancleType = type
        this.cancleIndex = index
      }
    },
    // 添加关注
    async addFocus(type, index) {
      const formObj = {}
      if (type === 1) {
        formObj.focus_clerk_id = this.focusList[index].clerk_id
      } else if (type === 2) {
        formObj.focus_clerk_id = this.fansList[index].clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (type === 1) {
          this.$set(this.focusList[index], 'is_focus', 1)
        } else if (type === 2) {
          this.$set(this.fansList[index], 'is_focus', 1)
        }
      }
    },
    // 删除关注
    async deleteFocus(type, index) {
      const formObj = {}
      if (type === 1) {
        formObj.focus_clerk_id = this.focusList[index].clerk_id
      } else if (type === 2) {
        formObj.focus_clerk_id = this.fansList[index].clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (type === 1) {
          this.$set(this.focusList[index], 'is_focus', 0)
        } else if (type === 2) {
          this.$set(this.fansList[index], 'is_focus', 0)
        }

        this.$message({
          showClose: true,
          message: '取消关注成功！',
          type: 'success'
        })
        this.visible = false
      }
    },
    // 取消弹窗
    cancle() {
      this.visible = false
    },
    // 取消关注
    confirm() {
      this.deleteFocus(this.cancleType, this.cancleIndex)
    },
    // 返回
    back() {
      this.$router.back()
    }
  },
  mounted() {
    console.log(this.$route?.params)
    this.clerkId = this.$route.params.id
    // 切换tab
    if (this.$route?.query?.tab) {
      switch (this.$route.query.tab) {
        case 'fans':
          this.currentTab = 1
          break
        default:
          this.currentTab = 0
      }
    }
    this.refreshInfo()
  },
  watch: {
    '$route.query'(val, old) {
      // 切换tab
      if (val?.tab !== old?.tab) {
        switch (val.tab) {
          case 'fans':
            this.currentTab = 1
            break
          default:
            this.currentTab = 0
        }
        this.refreshInfo()
      }
    },
    '$route.params.id'(val) {
      this.clerkId = val
      this.refreshInfo()
    }
  }
}
</script>

<style lang="scss">
.member-info-container {
  height: calc(100vh - 64px - 24px);
  overflow: scroll;
  .info-header {
    margin-bottom: 10px;
    background-color: #fff;

    .info-back-button {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 13px 0 0 20px;
    }

    .member-info {
      position: relative;
      padding: 36px 42px;
      border-radius: 6px;

      .member-avatar {
        margin-right: 24px;

        .el-image {
          width: 88px;
          height: 88px;
          line-height: 88px;
          font-size: 24px;
          text-align: center;
          background-color: #f6f6f6;
          border-radius: 66px;
        }
      }

      .member-content {
        flex: 1;
      }

      .member-name {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 6px;
      }

      .member-remark {
        font-size: 14px;
        line-height: 21px;
        color: #999999;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  .info-tabs {
    position: sticky;
    top: 0;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: #fff;
    z-index: 99;
  }

  .info-fans,
  .info-attention {
    border-radius: 6px;
    overflow: hidden;
    .member-name {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      line-height: 24px;
    }

    .new-topic {
      color: #999;
      line-height: 21px;
    }
  }
}
</style>
