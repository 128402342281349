<template>
  <div class="comment-dialog flex flex-x-center flex-y-center" @touchmove.prevent @scroll.stop="">
    <div class="dialog-container">
      <div class="dialog-header flex flex-x-between flex-y-center line-bottom">
        <div>{{ infoData.reply_number || 0 }}条回复</div>
        <div class="close-button">
          <i class="icon-decline" @click="$emit('close', $event)"></i>
        </div>
      </div>
      <div class="comment-info line-bottom">
        <div class="flex">
          <el-image :src="infoData.clerk_avatar" v-if="infoData.clerk_avatar">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="comment-content">
            <comment-item-dialog :info="infoData">
              <div class="flex commment-bar flex-x-between">
                <div class="comment-time">
                  {{
                    infoData.create_time
                      ? getCurrentTime(infoData.create_time)
                      : ''
                  }}
                </div>
                <div class="flex operation-button flex-y-end">
                  <span class="flex flex-y-center">
                    <i class="icon-comment" @click="onReply"></i>
                  </span>
                  <span class="flex flex-y-center" @click="onPraise">
                    <i
                      :class="
                        infoData.is_praise === 0 ? 'icon-heart' : 'icon-Heart_1'
                      "
                    ></i>
                    {{ infoData.praise_number }}
                  </span>
                </div>
              </div>
            </comment-item-dialog>
          </div>
        </div>
      </div>
      <scroll-fresh
        className="comment-dialog-list"
        dataName="comment-data"
        :page="pageNumber"
        :lastPage="totalPage"
        :showBackTop="false"
        @get-next-page="getCommentNextPage"
        :showNoMore="false"
        :style="{ height: 'calc(100% - ' + (topHeight + 10) + 'px)' }"
      >
        <comment-item-dialog
          v-for="(item, index) in commentList"
          :key="item.comment_id"
          :info="item"
          :showReply="commentId !== item.pid"
        >
          <div class="flex commment-bar flex-x-between">
            <div class="comment-time flex flex-y-center">
              {{ getCurrentTime(item.create_time) }}
            </div>
            <div class="flex operation-button flex-y-center">
              <span class="flex flex-y-center">
                <i class="icon-comment" @click="onChildReply(index)"></i>
              </span>
              <span class="flex flex-y-center" @click="onChildPraise(index)">
                <i
                  :class="item.is_praise === 0 ? 'icon-heart' : 'icon-Heart_1'"
                ></i>
                {{ item.praise_number }}
              </span>
            </div>
          </div>
        </comment-item-dialog>
        <comment-no-more
          ment-no-more
          v-if="pageNumber === totalPage"
        ></comment-no-more>
      </scroll-fresh>
    </div>
    <reply-dialog
      v-if="isShowReply"
      :title="replyTitle"
      v-model="replyText"
      @close="isShowReply = false"
      @send="sendComment"
    ></reply-dialog>
  </div>
</template>

<script>
import CommentItemDialog from './CommentItemDialog.vue'
import CommentNoMore from './CommentNoMore.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
import ReplyDialog from './ReplyDialog.vue'
export default {
  components: {
    CommentItemDialog,
    CommentNoMore,
    ScrollFresh,
    ReplyDialog
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      topHeight: 0,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 2,
      commentList: [],
      loading: false,
      // 是否展示回复
      isShowReply: false,
      replyTitle: '评论',
      replyText: '',
      replyCommentId: '',
      infoData: {}
    }
  },
  computed: {
    commentId() {
      return this.infoData?.comment_id || ''
    },
    momentId() {
      return this.infoData?.moment_id || ''
    }
  },
  methods: {
    // 获取评论列表
    async getCommentList(reset = false) {
      const formObj = {
        moment_id: this.info.moment_id,
        tid: this.commentId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentChildList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.commentList = res.page.list
        } else {
          this.commentList = this.commentList.concat(res.page.list)
        }
        this.totalPage = res.page.totalPage
      }
    },
    // 获取下一页
    async getCommentNextPage() {
      this.pageNumber++
      await this.getCommentList()
    },
    // 刷新列表
    async refreshCommentList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.commentList = []
      this.loading = true
      await this.getCommentList(true)
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    // 获取创建时间格式
    getCurrentTime(time) {
      return this.$util.getTimeStr(time)
    },
    // 回复评论
    async sendComment() {
      if (this.replyText === '') {
        this.$message.error('请输入评论内容')
        return
      }
      const formObj = {
        moment_id: this.momentId,
        comment_content: this.replyText,
        pid: this.replyCommentId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentComment, formObj)
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.resetReplyData()
        this.getCommentList(true)
        this.$emit('comment')
        // this.$message({
        //   showClose: true,
        //   message: '评论成功',
        //   type: 'success'
        // })
      }
    },
    // 回复
    onReply() {
      this.isShowReply = true
      this.replyTitle = '回复 ' + this.info.clerk_name
      this.replyCommentId = this.commentId
    },
    // 回复
    onChildReply(index) {
      this.isShowReply = true
      this.replyTitle = '回复 ' + this.commentList[index].clerk_name
      this.replyCommentId = this.commentList[index].comment_id
    },
    // 重置回复数据
    resetReplyData() {
      this.replyCommentId = ''
      this.replyText = ''
      this.replyTitle = '评论'
      this.isShowReply = false
    },
    // 评论点赞
    async praise(commentId) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.praiseMomentComment, {
          comment_id: commentId
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        return res.is_praise
      }
    },
    // 父级评论点赞
    async onPraise() {
      const flag = await this.praise(this.commentId)
      this.$emit('praise', {
        comment_id: this.commentId,
        is_praise: flag ? 1 : 0
      })
    },
    // 子级评论点赞
    async onChildPraise(index) {
      const info = this.commentList[index]
      const flag = await this.praise(info.comment_id)
      flag ? info.praise_number++ : info.praise_number--
      this.$set(info, 'is_praise', flag ? 1 : 0)
    },
    replyInteractive() {
      this.$emit(
        'replyInteractive',
        this.replyInfo.clerk_name,
        this.replyInfo.comment_id
      )
    }
  },
  mounted() {
    this.$set(this, 'infoData', this.info)
    this.topHeight =
      document.getElementsByClassName('comment-info')[0].offsetHeight + 30
    this.refreshCommentList()
  },
  watch: {
    commentId(val) {
      console.log(val)
      if (val !== '') {
        this.refreshCommentList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;

  .dialog-container {
    width: 620px;
    height: 635px;
    background-color: #fff;
    border-radius: 6px;

    .dialog-header {
      height: 21px;
      padding: 11px 17px 11px 26px;
      .close-button {
        i {
          padding: 6px;
          color: $main-color;
          font-weight: 400;
          font-size: 14px;
          background: rgba(0, 128, 255, 0.1);
          border-radius: 5px;
        }
      }

      &.line-bottom::after {
        left: 0;
      }
    }

    .comment-info {
      padding: 17px 0;
      .el-image {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        background-color: $light-grey-bg;
        border-radius: 100%;
        text-align: center;
        margin-left: 26px;
        margin-right: 12px;
      }

      .comment-content {
        flex: 1;
        margin-right: 26px;

        .commment-bar {
          margin-top: 4px;
          height: 29px;
        }
      }
    }

    .comment-dialog-list {
      overflow: scroll;
      padding: 0 26px 0 78px;

      .comment-item-dialog {
        margin-top: 14px;

        .commment-bar {
          height: 25px;
          .operation-button {
            display: none;
          }

          &:hover {
            .operation-button {
              display: inherit;
            }
          }
        }
      }
    }

    .comment-time {
      color: #999;
      font-size: 12px;
      line-height: 18px;
    }

    .operation-button {
      span:first-child {
        margin-right: 25px;
      }

      i {
        font-size: 22px;
        margin-right: 8px;
      }
    }
  }
  .icon-Heart_1 {
    color: red;
  }
}
</style>
