<template>
  <div class="comment-no-more">
    没有更多内容了
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.comment-no-more {
  position: relative;
  padding: 24px 0;
  height: 21px;
  line-height: 21px;
  color: #999;
  text-align: center;

  &::before {
    position: absolute;
    content: '';
    width: 170px;
    height: 1px;
    left: 0;
    top: 50%;
    background-color: #eee;
  }

  &::after {
    position: absolute;
    content: '';
    width: 170px;
    height: 1px;
    right: 0;
    top: 50%;
    background-color: #eee;
  }
}
</style>
