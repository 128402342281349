<!--
 * @Date: 2021-05-31 20:37:13
 * @LastEditTime: 2021-09-02 11:28:50
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div>
    <div>
      <!-- 它自己 -->
      <!-- 如果有label显示名字，如果没有，显示头像和用户名 -->
      <!-- 显示部门 -->
      <div
        v-if="treeNode.dept_name && treeNode.dept_name.length > 0"
        class="label flex flex-y-center"
        :style="{ 'padding-left': 16 * (layer > 3 ? 3 : layer) + 'px' }"
      >
        <span
          class="arrow"
          :class="{ active: isShowChild }"
          @click.stop="toggleChild"
        >
          <span class="iconfont icon-playfill1"></span>
        </span>
        <p class="label-text" @click.stop="toggleChild">
          {{ treeNode.dept_name }}
        </p>
      </div>
      <!-- 显示职员 -->
      <div
        v-else
        class="flex flex-y-center member"
        :style="{ 'padding-left': 16 * (layer > 3 ? 3 : layer) + 20 + 'px' }"
        @click="chooseMember(treeNode)"
      >
        <img
          :src="treeNode.clerk_avatar"
          class="avatar"
          :alt="treeNode.clerk_name"
        />
        <p class="name e" :title="treeNode.clerk_alias ||treeNode.clerk_name">
          {{ treeNode.clerk_alias || treeNode.clerk_name }}
        </p>
      </div>
      <!-- 它孩子 -->
      <template
        v-if="
          (treeNode.clerk_list && treeNode.clerk_list.length > 0) ||
          (treeNode.children && treeNode.children.length > 0)
        "
      >
        <!-- 职员，职员id前面拼上一个"c"字母 -->
        <member-tree
          v-show="isShowChild"
          v-for="child in treeNode.clerk_list"
          :key="'c' + child.clerk_id"
          :treeNode="child"
          :layer="layer + 1"
          @clerk-choose="chooseMember($event)"
        ></member-tree>
        <!-- 部门 -->
        <member-tree
          v-show="isShowChild"
          v-for="child in treeNode.children"
          :key="child.dept_id"
          :treeNode="child"
          :layer="layer + 1"
          @clerk-choose="chooseMember($event)"
        ></member-tree>
      </template>
    </div>
  </div>
</template>

<script>
import MemberTree from './MemberTree'
export default {
  name: 'member-tree',
  components: {
    MemberTree
  },
  props: {
    // 当前节点的数据
    treeNode: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 当前显示在第几层
    layer: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      // 是否显示它的孩子
      isShowChild: false
    }
  },
  mounted() {
    if (this.treeNode.dept_id === 1) {
      this.isShowChild = true
    }
    // console.log('数据', this.dat)
  },
  methods: {
    // 显示隐藏孩子
    toggleChild() {
      this.isShowChild = !this.isShowChild
    },
    // 点击选择职员
    chooseMember(treeNode) {
      // 整理好选择的数据
      const data = {
        clerk_name: treeNode.clerk_alias || treeNode.clerk_name,
        clerk_avatar: treeNode.clerk_avatar,
        clerk_id: treeNode.clerk_id,
        main_dept_id: treeNode.main_dept_id,
        main_is_leader: treeNode.main_is_leader,
        main_dept_name: treeNode.main_dept_name
      }
      this.$emit('clerk-choose', data)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/tree_style.scss';
</style>
