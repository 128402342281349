<!--
 * @Author: hzh
 * @Date: 2021-09-27 16:33:02
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-29 20:20:46
-->
<template>
  <div class="management-container">
    <el-tabs v-model="current">
      <el-tab-pane  v-if="$hasPer(82)" label="超级管理员">
        <super-admin></super-admin>
      </el-tab-pane>
      <el-tab-pane  v-if="$hasPer(72)" label="管理员">
        <admin-list></admin-list>
      </el-tab-pane>
      <el-tab-pane  v-if="$hasPer(77)" label="权限组">
        <permissions-list></permissions-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SuperAdmin from '@/components/system/SuperAdmin.vue'
import AdminList from '@/components/system/AdminList.vue'
import PermissionsList from '@/components/system/PermissionsList.vue'
export default {
  components: {
    SuperAdmin,
    AdminList,
    PermissionsList
  },
  data() {
    return {
      current: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.management-container {
  position: relative;
  width: 100%;
  height: 100%;
  .el-tabs::v-deep {
    .el-tabs__nav-wrap {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .el-tab-pane {
      padding: 9px 30px 0;
      height: calc(100vh - 110px);
    }
  }
}
</style>
