<!--
 * @Date: 2021-06-01 15:03:02
 * @LastEditTime: 2021-12-20 15:58:06
 * @Description: 任务列表页
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <!-- 任务列表页 -->
  <el-container class="main-container">
    <!-- 这里分为左中右结构 -->
    <el-row
      :gutter="screenWidth > 767 ? 20 : 0"
      class="main"
      style="margin-left: 0; margin-right: 0"
    >
      <!-- 左边，联系人 -->
      <el-col
        :xs="{ span: 0 }"
        :span="4"
        style="padding-left: 0"
        class="contact-person"
      >
        <!-- 第一块，搜索同事 -->
        <div class="search-box flex flex-y-center hidden-xs-only">
          <span class="iconfont icon-search search-icon"></span>
          <input
            type="text"
            placeholder="搜索同事"
            class="input"
            v-model="inputSearchName"
            @input="triggerSearchClerk"
            @click.stop="isShowSearchClerkResult = !isShowSearchClerkResult"
          />
          <!-- 清空按钮 -->
          <button
            type="button"
            v-if="searchName.trim().length > 0"
            class="clear-btn"
            @click="clearSearch"
          ></button>
          <!-- 显示搜索结果 -->
          <div
            class="search-result-box"
            v-if="isShowSearchClerkResult && searchName.trim().length > 0"
          >
            <div class="search-result">
              <div
                class="member-item flex flex-y-center"
                v-for="(clerk, index) in searchResultList"
                :key="index"
                @click="setCurrentClerkId(clerk.clerk_id)"
              >
                <div class="avatar-box">
                  <!-- 关注按钮 -->
                  <button
                    class="focus-btn active"
                    v-if="
                      isMyFocus(clerk.clerk_id) === -1 &&
                      clerk.clerk_id !== myClerkId
                    "
                    @click.stop="addFocus(clerk)"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-a-Group2031"></use>
                    </svg>
                  </button>
                  <!-- 已关注按钮 -->
                  <button
                    class="focus-btn"
                    v-else
                    v-show="clerk.clerk_id !== myClerkId"
                    @click.stop="removeFocus(clerk, index)"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-a-Group203"></use>
                    </svg>
                  </button>
                  <avatar
                    :userInfo="clerk"
                    :size="30"
                    :distanceRight="10"
                  ></avatar>
                </div>
                <p class="name e">
                  {{ clerk.clerk_alias || clerk.clerk_name }}
                </p>
                <!-- 关注按钮 -->
                <!-- <button
                  class="focus-btn active"
                  v-if="isMyFocus(clerk.clerk_id) === -1"
                  @click.stop="addFocus(clerk)"
                >
                  关注
                </button>
                <span
                  class="focus-btn"
                  v-else
                  @click.stop="removeFocus(clerk, index)"
                  >已关注</span
                > -->
              </div>
              <!-- 假如没有搜索结果 -->
              <p class="no-data" v-show="searchResultList.length === 0">
                暂无搜索结果
              </p>
            </div>
          </div>
        </div>
        <div class="main-box hidden-xs-only">
          <!-- 第二块，我的名字和头像 -->
          <div
            class="flex flex-y-center my-info"
            @click="setCurrentClerkId(myClerkInfo.clerk_id)"
          >
            <avatar
              :userInfo="myClerkInfo"
              :size="26"
              :distanceRight="0"
            ></avatar>
            <p class="name e">
              {{ myClerkInfo.clerk_alias || myClerkInfo.clerk_name }}
            </p>
          </div>
          <div class="clerk-list-box">
            <!-- 第三块，我的同事 -->
            <!-- 我的直属下级 -->
            <div class="my-fellow-box">
              <!-- 头部 -->
              <div
                class="my-fellow-header flex flex-y-center flex-x-between"
                @click="toggleSubordinate"
              >
                <h6 class="my-fellow-title">我的直属团队</h6>
                <span
                  class="arrow iconfont icon-zhankai"
                  :class="{ active: isShowSubordinate }"
                ></span>
              </div>
              <!-- 同事列表 -->
              <template v-if="false">
                <div
                  class="fellow-item flex flex-y-center"
                  :class="{ active: fellow.clerk_id === currentClerkId }"
                  v-for="(fellow, index) in fellowList"
                  :key="index"
                  v-show="isShowSubordinate"
                  @click="setCurrentClerkId(fellow.clerk_id)"
                >
                  <div class="avatar-box">
                    <avatar
                      :userInfo="fellow"
                      :size="30"
                      :distanceRight="10"
                    ></avatar>
                    <!-- 关注按钮 -->
                    <button
                      class="focus-btn active"
                      v-if="
                        isMyFocus(fellow.clerk_id) === -1 &&
                        fellow.clerk_id !== myClerkId
                      "
                      @click.stop="addFocus(fellow)"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-a-Group2031"></use>
                      </svg>
                    </button>
                    <!-- 已关注按钮 -->
                    <button
                      class="focus-btn"
                      v-else
                      v-show="fellow.clerk_id !== myClerkId"
                      @click.stop="removeFocus(fellow, index)"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-a-Group203"></use>
                      </svg>
                    </button>
                  </div>
                  <p class="name e">
                    {{ fellow.clerk_alias || fellow.clerk_name }}
                  </p>
                  <!-- 关注按钮 -->
                  <!-- <button
                    class="focus-btn active"
                    v-if="isMyFocus(fellow.clerk_id) === -1"
                    @click.stop="addFocus(fellow)"
                  >
                    关注
                  </button>
                  <span
                    class="focus-btn"
                    @click.stop="removeFocus(fellow, index)"
                    v-else
                    >已关注</span
                  > -->
                </div>
              </template>
              <!-- 树形显示 -->
              <focus-member-tree
                v-for="(item, index) in fellowList"
                :key="index"
                :treeNode="item"
                v-show="isShowSubordinate"
                @clerk-choose="chooseCurrentClerk($event)"
                @click-avatar="clickAvatar"
                @hover-avatar="hoverAvatar"
              ></focus-member-tree>
            </div>
            <!-- 我的关注 -->
            <div class="my-fellow-box">
              <!-- 头部 -->
              <div
                class="my-fellow-header flex flex-y-center flex-x-between"
                @click="toggleFollow"
              >
                <h6 class="my-fellow-title">我的关注</h6>
                <span
                  class="arrow iconfont icon-zhankai"
                  :class="{ active: isShowFollow }"
                ></span>
              </div>
              <!-- 关注列表 -->
              <template v-for="(fellow, index) in focusClerkList">
                <div
                  class="fellow-item flex flex-y-center"
                  :class="{ active: fellow.clerk_id === currentClerkId }"
                  v-show="isShowFollow"
                  @click="setCurrentClerkId(fellow.clerk_id)"
                  :key="index"
                >
                  <div class="avatar-box">
                    <cricle-pop
                      :info="{
                        clerk_id: fellow.clerk_id,
                        clerk_alias: fellow.clerk_alias,
                        clerk_name: fellow.clerk_name,
                        clerk_avatar: fellow.clerk_avatar
                      }"
                      :key="index"
                    >
                      <img
                        :src="fellow.clerk_avatar | httpToHttps"
                        alt=""
                        class="avatar"
                      />
                    </cricle-pop>
                    <!-- 关注按钮 -->
                    <button
                      class="focus-btn active"
                      v-if="
                        isMyFocus(fellow.clerk_id) === -1 &&
                        fellow.clerk_id !== myClerkId
                      "
                      @click.stop="addFocus(fellow)"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-a-Group2031"></use>
                      </svg>
                    </button>
                    <!-- 已关注按钮 -->
                    <button
                      class="focus-btn"
                      v-else
                      v-show="fellow.clerk_id !== myClerkId"
                      @click.stop="removeFocus(fellow, index)"
                    >
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-a-Group203"></use>
                      </svg>
                    </button>
                  </div>
                  <p class="name e">
                    {{ fellow.clerk_alias || fellow.clerk_name }}
                  </p>
                  <!-- 关注按钮 -->
                  <!-- <button
                  class="focus-btn active"
                  v-if="isMyFocus(fellow.clerk_id) === -1"
                  @click.stop="addFocus(fellow)"
                >
                  关注
                </button>
                <span
                  class="focus-btn"
                  @click.stop="removeFocus(fellow, index)"
                  v-else
                  >已关注</span
                > -->
                </div>
              </template>
            </div>
            <!-- CC通讯录 -->
            <div class="my-fellow-box">
              <!-- 头部 -->
              <div
                class="my-fellow-header flex flex-y-center flex-x-between"
                @click="toggleAddressList"
              >
                <h6 class="my-fellow-title">CC通讯录</h6>
                <span
                  class="arrow iconfont icon-zhankai"
                  :class="{ active: isShowAddress }"
                ></span>
              </div>
              <!-- 同事列表 -->
              <div v-show="isShowAddress">
                <div class="tree-list">
                  <div v-for="item in sideClerkTree" :key="item.id">
                    <focus-member-tree
                      :treeNode="item"
                      @clerk-choose="chooseCurrentClerk($event)"
                      @click-avatar="clickAvatar"
                      @hover-avatar="hoverAvatar"
                    ></focus-member-tree>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <!-- 中间，任务列表 -->
      <el-col
        :xs="{ span: 24 }"
        :span="isShowComment || isShowRemind ? 15 : 20"
        class="task-list"
        style="padding-right: 0"
      >
        <!-- 第一行，菜单栏 -->
        <div class="menu-box flex flex-y-center flex-x-between">
          <!-- 左边，选择日期 -->
          <div class="flex flex-y-center choose-time" v-if="false">
            <span
              class="iconfont icon-arrowaltright arrow left-arrow"
              @click="getTimeRange(-1)"
            ></span>
            <div
              class="date-tab-item"
              @click="chooseTimeIndex(0)"
              :class="{ active: currentTimeIndex === 0 }"
            >
              {{ leftTimeText }}
            </div>
            <div
              class="date-tab-item"
              @click="chooseTimeIndex(1)"
              :class="{ active: currentTimeIndex === 1 }"
            >
              {{ rightTimeText }}
            </div>
            <span
              class="iconfont icon-arrowaltright arrow right-arrow"
              @click="getTimeRange(1)"
            ></span>
          </div>
          <!-- 左边，选择目标和任务 -->
          <div class="flex flex-y-center mobile-tab">
            <span
              class="option-item"
              v-for="(option, index) in optionList"
              :key="index"
              :class="{ active: currentOption === option.val }"
              @click="setOption(option.val)"
            >
              {{ option.text }}
            </span>
          </div>
          <!-- 右边，更多菜单 -->
          <div class="flex flex-y-center" style="position: relative">
            <!-- 按周查看 -->
            <div
              class="flex flex-y-center menu-btn"
              @click.stop="toggleChooseTimeRange"
              v-if="false"
            >
              <span class="iconfont icon-Calendar menu-icon"></span>
              <span class="menu-text">{{
                timeRangeList[currentTimeRange]
              }}</span>
              <!-- 选择任务时间范围 -->
              <div class="choose-task-box" v-show="isChooseTimeRange">
                <div
                  class="choose-task-item flex flex-y-center flex-x-between"
                  v-for="(item, index) in timeRangeList"
                  @click="chooseTimeRange(index)"
                  :key="index"
                >
                  <p class="range-text">{{ timeRangeList[index] }}</p>
                  <svg
                    class="icon icon-ok"
                    aria-hidden="true"
                    v-show="currentTimeRange === index"
                  >
                    <use xlink:href="#icon-confirmfill3"></use>
                  </svg>
                </div>
              </div>
            </div>
            <!-- 所有任务 -->
            <div
              class="flex flex-y-center hidden-xs-only"
              v-if="currentOption === 'task'"
            >
              <div
                class="choose-task-item choose-item-inline"
                :class="{ active: currentTaskRange === index }"
                v-for="(item, index) in taskRangeList"
                @click="chooseTaskRange(index)"
                :key="index"
              >
                <p class="range-text">{{ taskRangeList[index] }}</p>
              </div>
              <!-- <span
                class="iconfont icon-Frame61 menu-icon"
                :class="{ active: currentTaskRange === 2 }"
              ></span> -->
              <!-- <span
                class="menu-text"
                :class="{ active: currentTaskRange === 2 }"
                >{{ taskRangeList[currentTaskRange] }}</span
              > -->
              <!-- 选择任务范围弹窗 -->
              <!-- <div class="choose-task-box" v-show="isChooseTaskRange">
                <div
                  class="choose-task-item flex flex-y-center flex-x-between"
                  v-for="(item, index) in taskRangeList"
                  @click="chooseTaskRange(index)"
                  :key="index"
                >
                  <p class="range-text">{{ taskRangeList[index] }}</p>
                  <svg
                    class="icon icon-ok"
                    aria-hidden="true"
                    v-show="currentTaskRange === index"
                  >
                    <use xlink:href="#icon-confirmfill3"></use>
                  </svg>
                </div>
              </div> -->
            </div>
            <!-- 选择任务范围弹窗 -->
            <!-- <div
              class="choose-task-box" v-show="isChooseTaskRange"
              v-if="currentOption === 'task'"
            >
              <div
                class="choose-task-item flex flex-y-center flex-x-between"
                v-for="(item, index) in taskRangeList"
                @click="chooseTaskRange(index)"
                :key="index"
              >
                <p class="range-text">{{ taskRangeList[index] }}</p>
                <svg
                  class="icon icon-ok"
                  aria-hidden="true"
                  v-show="currentTaskRange === index"
                >
                  <use xlink:href="#icon-confirmfill3"></use>
                </svg>
              </div>
            </div> -->
            <!-- 所有目标 -->
            <div
              class="flex flex-y-center hidden-xs-only"
              v-if="currentOption === 'target'"
            >
              <div
                class="choose-task-item choose-item-inline"
                :class="{ active: currentTargetRange === index }"
                v-for="(item, index) in targetRangeList"
                @click="chooseTargetRange(index)"
                :key="index"
              >
                <p class="range-text">{{ taskRangeList[index] }}</p>
              </div>
              <!-- <span
                class="iconfont icon-Frame61 menu-icon"
                :class="{ active: currentTargetRange === 2 }"
              ></span>
              <span
                class="menu-text"
                :class="{ active: currentTargetRange === 2 }"
                >{{ targetRangeList[currentTargetRange] }}</span
              > -->
            </div>
            <!-- 选择目标范围弹窗 -->
            <!-- <div
              class="choose-task-box"
              v-show="isChooseTargetRange"
              v-if="currentOption === 'target'"
            >
              <div
                class="choose-task-item flex flex-y-center flex-x-between"
                v-for="(item, index) in targetRangeList"
                @click="chooseTargetRange(index)"
                :key="index"
              >
                <p class="range-text">{{ targetRangeList[index] }}</p>
                <svg
                  class="icon icon-ok"
                  aria-hidden="true"
                  v-show="currentTargetRange === index"
                >
                  <use xlink:href="#icon-confirmfill3"></use>
                </svg>
              </div>
            </div> -->
            <!-- 筛选任务 -->
            <div
              class="menu-btn flex flex-y-center flex-x-between hidden-xs-only"
              @click.stop="toggleSelect"
              v-if="currentOption === 'task'"
            >
              <span class="iconfont icon-Filter2 menu-icon"></span>
              <span class="menu-text">筛选</span>
              <!-- 筛选弹窗 -->
              <select-box
                :isShow="isShowSelectBox"
                :clerkData="clerkTree"
                :currentClerkId="currentClerkId"
                :isComplete="
                  currentTaskRange === 0
                    ? ''
                    : currentTaskRange === 1
                    ? '0'
                    : '1'
                "
                @to-refresh-list="getSelectResult($event)"
              ></select-box>
            </div>
            <!-- 筛选目标 -->
            <div
              class="menu-btn flex flex-y-center flex-x-between hidden-xs-only"
              @click.stop="toggleSelect"
              v-if="currentOption === 'target'"
            >
              <span class="iconfont icon-Filter2 menu-icon"></span>
              <span class="menu-text">筛选</span>
              <!-- 筛选弹窗 -->
              <select-target-box
                :isShow="isShowSelectBox"
                :clerkData="clerkTree"
                :currentClerkId="currentClerkId"
                :isComplete="
                  currentTargetRange === 0
                    ? ''
                    : currentTargetRange === 1
                    ? '0'
                    : '1'
                "
                @to-refresh-list="getTargetSelectResult($event)"
              ></select-target-box>
            </div>
            <!-- 评论 -->
            <div
              class="menu-btn flex flex-y-center no-text"
              v-if="false"
              @click="toggleComment"
            >
              <span class="iconfont icon-commentalt menu-icon"></span>
            </div>
            <!-- 提醒 -->
            <div
              class="menu-btn flex flex-y-center no-text hidden-xs-only"
              @click="toggleTaskRemind"
            >
              <span class="iconfont icon-Notification menu-icon"></span>
            </div>
          </div>
        </div>
        <!-- 主要部分，滚动展示数据 -->
        <div class="data-main-box">
          <!-- 第二行，数据展示 -->
          <!-- 目标组 -->
          <template v-if="currentOption === 'target'">
            <target-group-box
              v-for="(group, index) in targetGroups"
              :key="index"
              :index="index + 1"
              :group="group"
              :isMe="isMe"
              :groupName="group.list[0].group_name || '目标名称'"
              @to-fresh-target="getTargetList"
              @show-relative-modal="showTargetRelative($event)"
              @to-target-detail="showTargetDetail($event)"
              @changeLabel="changeTargetLabel"
            ></target-group-box>
            <!-- 添加目标 -->
            <div
              class="task-group-box task-padding-box flex"
              v-if="myClerkId === currentClerkId && currentOption === 'target'"
            >
              <!-- 左边 -->
              <div class="tag">
                {{ (targetGroups.length + 1) | handleNum }}
              </div>
              <div class="flex1 flex flex-y-center">
                <!-- 在这一行，新增一条任务 -->
                <div class="add-line flex flex-y-center flex1">
                  <!-- 按钮 -->
                  <button
                    class="add-btn flex flex-y-center"
                    v-if="!isNewTargetGroup"
                    @click="beginAddTarget(-1)"
                  >
                    <span class="iconfont icon-add add-icon"></span>
                    添加目标
                  </button>
                  <!-- 输入框 -->
                  <input
                    v-show="isNewTargetGroup"
                    type="text"
                    class="task-input flex1"
                    v-model="newTarget"
                    :ref="'addTargetInput_-1'"
                    placeholder="输入目标名称，按回车保存，按ESC取消"
                    @keyup.enter="toBlur"
                    @keyup.esc="cancelNewTarget"
                    @blur="handleNewTarget()"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- 任务组 -->
          <template v-if="currentOption === 'task'">
            <task-group-box
              v-for="(group, index) in taskGroupsList"
              :key="index"
              :group="group"
              :index="index + 1"
              :isMe="isMe"
              :groupName="group.list[0].group_name || '工作任务'"
              @changeLabel="changeTaskLabel"
            ></task-group-box>
            <!-- 最后再来一个新的组 -->
            <!-- 添加任务 -->
            <div
              class="task-group-box task-padding-box flex"
              v-if="myClerkId === currentClerkId && currentOption === 'task'"
            >
              <!-- 左边 -->
              <div class="tag">
                {{ (taskGroupsList.length + 1) | handleNum }}
              </div>
              <div class="flex1 flex flex-y-center">
                <!-- 在这一行，新增一条任务 -->
                <div class="add-line flex flex-y-center flex1">
                  <!-- 按钮 -->
                  <button
                    class="add-btn flex flex-y-center"
                    v-if="!isNewGroup"
                    @click="beginAddTask(-1)"
                  >
                    <span class="iconfont icon-add add-icon"></span>
                    添加任务
                  </button>
                  <!-- 输入框 -->
                  <input
                    v-show="isNewGroup"
                    type="text"
                    class="task-input flex1"
                    v-model="newTask"
                    :ref="'addTaskInput_-1'"
                    placeholder="输入任务名称，按回车保存，按ESC取消"
                    @keyup.enter="toBlur"
                    @keyup.esc="cancelNewTask"
                    @blur="handleNewTask()"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- 如果没有目标 -->
          <div class="no-data-box" v-if="isShowTip">
            <no-data :isShow="true" tipText="暂无内容"></no-data>
          </div>
        </div>
      </el-col>
      <!-- 右边，评论和提醒 -->
      <el-col :span="5" style="padding-right: 0" class="comment">
        <!-- 评论 -->
        <div class="right-box comment-box" v-show="isShowComment">
          <!-- 标题 -->
          <div class="flex flex-y-center flex-x-between box-header">
            <h6 class="box-header-title" v-if="currentOption === 'task'">
              评论（{{ commentListPageInfo.totalRow }}）
            </h6>
            <h6 class="box-header-title" v-if="currentOption === 'target'">
              评论（{{ targetCommentListPageInfo.totalRow }}）
            </h6>
            <button
              class="box-header-close"
              type="button"
              @click="closeComment"
            >
              <span class="iconfont icon-decline"></span>
            </button>
          </div>
          <!-- 任务周期评论数据 -->
          <scroll-fresh
            className="comment-data-box"
            dataName="comment-data"
            :page="commentListPageInfo.pageNumber"
            :lastPage="commentListPageInfo.totalPage"
            @get-next-page="getCommentDataNextPage"
            v-if="currentOption === 'task'"
          >
            <!-- 每一条评论数据 -->
            <comment-item
              v-for="(item, index) in commentList"
              :key="index"
              :comment="item"
              @to-reply="setReplyObject($event)"
            ></comment-item>
          </scroll-fresh>
          <!-- 目标周期评论数据 -->
          <scroll-fresh
            className="comment-data-box"
            dataName="comment-data"
            :page="targetCommentListPageInfo.pageNumber"
            :lastPage="targetCommentListPageInfo.totalPage"
            @get-next-page="getTargetCommentDataNextPage"
            v-if="currentOption === 'target'"
          >
            <!-- 每一条评论数据 -->
            <target-comment-item
              v-for="(item, index) in targetCommentList"
              :key="index"
              :comment="item"
              @to-reply="setTargetReplyObject($event)"
            ></target-comment-item>
          </scroll-fresh>
          <!-- 添加评论的区域 -->
          <div class="add-comment-box">
            <!-- 添加任务周期评论 -->
            <div
              class="add-comment-inner"
              :class="{ 'is-edit': isEditing }"
              v-if="currentOption === 'task'"
            >
              <textarea
                class="comment-textarea"
                v-model="newComment"
                @focus="beginEdit"
                :placeholder="commentPlaceholder"
                @blur="handleComment"
                @keyup.enter="addNewComment"
              ></textarea>
              <button type="button" class="send-btn" @click="addNewComment">
                发送
              </button>
            </div>
            <!-- 添加目标周期评论 -->
            <div
              class="add-comment-inner"
              :class="{ 'is-edit': isEditing }"
              v-if="currentOption === 'target'"
            >
              <textarea
                class="comment-textarea"
                @focus="beginEdit"
                v-model="newTargetComment"
                :placeholder="commentPlaceholder"
                @blur="handleTargetComment"
                @keyup.enter="addNewTargetComment"
              ></textarea>
              <button
                type="button"
                class="send-btn"
                @click="addNewTargetComment"
              >
                发送
              </button>
            </div>
          </div>
        </div>
        <!-- 任务提醒 -->
        <div class="right-box remind-box" v-show="isShowRemind">
          <!-- 任务提醒的头部 -->
          <div class="flex flex-y-center flex-x-between box-header">
            <h6 class="box-header-title" v-if="currentOption === 'task'">
              任务提醒
            </h6>
            <h6 class="box-header-title" v-if="currentOption === 'target'">
              目标提醒
            </h6>
            <button
              class="box-header-close"
              type="button"
              @click="closeTaskRemind"
            >
              <span class="iconfont icon-decline"></span>
            </button>
          </div>
          <!-- 任务提醒的内容区域 -->
          <!-- 滚动刷新的数据组件 -->
          <scroll-fresh
            className="task-remind-list"
            dataName="task-remind"
            v-if="currentOption === 'task'"
            :page="taskRemindPageInfo.pageNumber"
            :lastPage="taskRemindPageInfo.totalPage"
            @get-next-page="getRemindNextPage($event)"
          >
            <template v-slot:default>
              <!-- 显示任务提醒的数据 -->
              <task-remind-item
                v-for="item in taskRemindList"
                :key="item.reminder_id"
                :reminder="item"
                @show-task-detail="showTaskDetail($event)"
              >
              </task-remind-item>
            </template>
          </scroll-fresh>
          <!-- 目标提醒的内容区域 -->
          <!-- 滚动刷新的数据组件 -->
          <scroll-fresh
            className="task-remind-list"
            dataName="target-remind"
            v-if="currentOption === 'target'"
            :page="taskRemindPageInfo.pageNumber"
            :lastPage="targetRemindPageInfo.totalPage"
            @get-next-page="getTargetRemindNextPage($event)"
          >
            <template v-slot:default>
              <!-- 显示目标提醒的数据 -->
              <target-remind-item
                v-for="item in targetRemindList"
                :key="item.reminder_id"
                :reminder="item"
                @show-target-detail="showTargetDetail($event)"
              >
              </target-remind-item>
            </template>
          </scroll-fresh>
        </div>
      </el-col>
    </el-row>
    <!-- 右边的弹窗 -->
    <task-detail
      :isShow="isShowDetail"
      @close-detail="closeDetailModal"
      :taskId="currentTask"
      :nowTime="todayDate"
      :isMe="isMe"
      :currentTab="taskCurrentTab"
      @change-task-id="changeTaskDetail"
      @change-target-id="changeTargetDetail($event)"
    ></task-detail>
    <!-- 任务移动的弹窗 -->
    <move-task-modal
      :taskTree="taskGroupsList"
      :isShow="isShowMoveModal"
      :currentTask="currentTask"
      :taskId="currentTask"
      @close-modal="closeMoveModal"
      @to-fresh-list="refreshTaskList()"
    ></move-task-modal>
    <!-- 目标移动的弹窗 -->
    <move-target-box
      :taskTree="targetGroups"
      :isShow="isShowMoveTargetBox"
      :objectiveId="currentTargetId"
      @close-modal="closeMoveTarget"
      @to-fresh-list="getTargetList"
    ></move-target-box>
    <!-- 目标关联弹窗 -->
    <target-relative-modal
      :myTarget="currentTarget"
      :isShowRelative="isShowRelative"
      @close-modal="closeRelativeModal"
      @to-fresh-target="getTargetList"
    ></target-relative-modal>
    <!-- 目标详情弹窗 -->
    <target-detail
      :targetId="currentTargetId"
      :isShow="isShowTargetDetail"
      :nowTime="todayDate"
      :fromWhere="fromWhere"
      :currentTab="targetCurrentTab"
      @close-detail="closeTargetDetail($event)"
      @change-target-id="changeTargetDetail($event)"
      @change-task-id="changeTaskDetail"
    >
    </target-detail>

    <action-sheet
      ref="TargetActionSheet"
      :menu-list="targetRangeList"
      @change="chooseTargetRange"
    ></action-sheet>
    <action-sheet
      ref="TaskActionSheet"
      :menu-list="taskRangeList"
      @change="chooseTaskRange"
    ></action-sheet>
    <div
      v-if="screenWidth <= 768"
      class="icon-Filter2 filtter-button"
      @click="handlerShowFiltter"
    ></div>
  </el-container>
</template>

<script>
// 导入成员树组件
import FocusMemberTree from '../components/task/FocusMemberTree.vue'
// 导入任务详情弹窗
import TaskDetail from '../components/task/TaskDetail.vue'
import { mapState, mapActions } from 'vuex'
import Avatar from '../components/common/Avatar.vue'
// 导入滚动刷新组件
import ScrollFresh from '../components/common/ScrollFresh.vue'
// 导入任务提醒组件
import TaskRemindItem from '../components/task/TaskRemindItem.vue'
// 导入评论组件
import CommentItem from '../components/task/CommentItem.vue'
// 导入任务组的组件
import TaskGroupBox from '../components/task/TaskGroupBox.vue'
// 导入移动弹窗组件
import MoveTaskModal from '../components/task/MoveTaskModal.vue'
// 导入筛选弹窗
import SelectBox from '../components/common/SelectBox.vue'
// 导入目标弹窗
import SelectTargetBox from '../components/common/SelectTargetBox.vue'
// 导入目标组
import TargetGroupBox from '../components/target/TargetGroupBox.vue'
// 导入目标关联弹窗
import TargetRelativeModal from '../components/target/TargetRelativeModal.vue'
// 导入目标详情弹窗
import TargetDetail from '../components/target/TargetDetail.vue'
// 目标评论
import TargetCommentItem from '../components/target/CommentItem.vue'
// 导入目标提醒组件
import TargetRemindItem from '../components/target/TargetRemindItem.vue'
// 导入没有数据的提示
import NoData from '../components/common/NoData.vue'
// 导入目标移动的弹窗
import MoveTargetBox from '../components/target/MoveTargetModal.vue'
import CriclePop from '@/components/task/CriclePop'

import store from '@/store'

// 筛选弹窗
import ActionSheet from '@/components/common/ActionSheet.vue'
export default {
  components: {
    FocusMemberTree,
    TaskDetail,
    Avatar,
    ScrollFresh,
    TaskRemindItem,
    CommentItem,
    TaskGroupBox,
    MoveTaskModal,
    SelectBox,
    TargetGroupBox,
    TargetRelativeModal,
    TargetDetail,
    TargetCommentItem,
    TargetRemindItem,
    SelectTargetBox,
    MoveTargetBox,
    NoData,
    CriclePop,
    ActionSheet
  },
  provide() {
    return {
      Parent: this
    }
  },
  data() {
    return {
      // 是否展示详情弹窗
      isShowDetail: false,
      // 同事列表
      fellowList: [],
      // 关注的职员列表
      focusClerkList: [],
      // 是否展示下级列表
      isShowSubordinate: false,
      // 是否展示关注列表
      isShowFollow: false,
      // 是否展示通讯录
      isShowAddress: false,
      //  树形数据
      clerkTree: [],
      // 侧边职员树
      sideClerkTree: [],
      // 左边的时间
      leftTime: new Date(),
      // 右边的时间
      rightTime: new Date(),
      // 当前操作的任务
      currentTask: -1,
      // 当前的时间
      todayDate: new Date(),
      // 每一组任务
      taskGroupsList: [],
      // 目标分组的集合
      targetGroups: [],
      // 是否展示出评论
      isShowComment: false,
      // 是否展示出任务提醒
      isShowRemind: false,
      // 选择任务范围
      taskRangeList: ['所有', '未完成', '已完成', '已终止'],
      currentTaskRange: 1,
      // 是否展示选择任务的范围
      isChooseTaskRange: false,
      // 选择时间范围
      timeRangeList: ['按周查看', '按月查看', '按季度查看'],
      currentTimeRange: 0,
      // 是否展示选择任务时间范围
      isChooseTimeRange: false,
      // 填写新任务名字
      newTask: '',
      // 哪个组要新增任务
      addTaskGroup: -1,
      // 是否是新的组要添加任务
      isNewGroup: false,
      // 当前显示哪个任务的更多弹窗
      currentShowMore: -1,
      // 当前显示哪个任务的优先级弹窗
      currentShowPriority: -1,
      // 当前显示哪个任务的进度弹窗
      currentShowProgress: -1,
      // 当前展示哪个目标的类型选择
      currentShowType: -1,
      // 当前展示哪个目标的进度弹窗
      currentShowTargetProgress: -1,
      // 搜索输入的名字
      searchName: '',
      // 存放搜索结果
      searchResultList: [],
      // 当前选中的时段是第一个还是第二个
      currentTimeIndex: 0,
      // 查看谁的任务
      currentClerkIdData: -1,
      // 是否展示搜索职员结果
      isShowSearchClerkResult: false,
      // 任务提醒的分页情况
      taskRemindPageInfo: {
        lastPage: false,
        pageNumber: 1,
        totalPage: 0,
        firstPage: true,
        totalRow: 0,
        pageSize: 20
      },
      // 任务提醒的数据
      taskRemindList: [],
      // 防抖搜索
      searchClerkAntiShare: null,
      // 评论列表的分页情况
      commentListPageInfo: {
        totalPage: 0,
        totalRow: 0,
        pageSize: 20,
        firstPage: true,
        lastPage: false,
        pageNumber: 1
      },
      // 评论列表的数据
      commentList: [],
      // 添加新评论
      newComment: '',
      // 要回复谁的评论
      replyTo: null,
      // 是否展示移动任务弹窗
      isShowMoveModal: false,
      // 是否展示移动目标弹窗
      isShowMoveTargetBox: false,
      // 选中的目标id
      targetId: -1,
      // 选择的范围
      targetRange: '',
      // 是否显示筛选滚动条
      isShowSelectBox: false,
      // 选项
      optionList: [
        {
          text: '目标',
          val: 'target'
        },
        {
          text: '任务',
          val: 'task'
        }
      ],
      // 当前选项
      currentOptionData: 'task',
      // 当前查看哪个目标的弹窗
      currentTarget: null,
      // 是否显示目标关联弹窗
      isShowRelative: false,
      // 是否展示目标详情弹窗
      isShowTargetDetail: false,
      // 当前在查看详情的目标id
      currentTargetId: -1,
      // 目标评论列表的分页情况
      targetCommentListPageInfo: {
        totalPage: 0,
        totalRow: 0,
        pageSize: 20,
        firstPage: true,
        lastPage: false,
        pageNumber: 1
      },
      // 目标评论列表的数据
      targetCommentList: [],
      // 输入的目标评论
      newTargetComment: '',
      // 目标范围有哪些
      targetRangeList: ['所有', '未完成', '已完成', '已终止'],
      // 是否正在选择目标范围
      isChooseTargetRange: false,
      // 当前选择的目标范围
      currentTargetRange: 1,
      // 目标提醒的列表
      targetRemindList: [],
      // 目标评论列表的分页情况
      targetRemindPageInfo: {
        totalPage: 0,
        totalRow: 0,
        pageSize: 20,
        firstPage: true,
        lastPage: false,
        pageNumber: 1
      },
      // 是否要添加新的组
      isNewTargetGroup: false,
      // 输入的新目标
      newTarget: '',
      // 目标的来源
      fromWhere: null,
      // 文本框是否正在聚焦
      isEditing: false,
      // 评论和提醒是否正在加载
      isLoadingTaskComment: false,
      isLoadingTaskRemind: false,
      isLoadingTargetComment: false,
      isLoadingTargetRemind: false,
      // 目标当前tab
      targetCurrentTab: 'first',
      // 任务当前tab
      taskCurrentTab: 'first',
      // 任务标识
      taskType: '',
      // 进度状态
      progressStatus: '',
      // 任务标签id
      taskLabelId: '',
      // 目标标签id
      targetLabelId: ''
    }
  },
  computed: {
    // 我的信息
    ...mapState(['myClerkInfo', 'myClerkId', 'screenWidth', 'clickBodyIndex']),
    // 输入搜索名称
    inputSearchName: {
      set(v) {
        this.isShowSearchClerkResult = true
        this.searchName = v
      },
      get() {
        return this.searchName
      }
    },
    // 当前是不是我
    isMe() {
      return this.currentClerkId === this.myClerkId
    },
    // 判断这个人是否是我的关注
    isMyFocus() {
      return function (id) {
        const index = this.focusClerkList.findIndex((item) => {
          return item.clerk_id === id
        })
        return index
      }
    },
    // 计算左边的时间范围
    leftTimeText() {
      switch (this.currentTimeRange) {
        case 0: {
          const MondayDatetText = this.$util.getDateStrNoYear(
            this.$util.getMondayDate(this.leftTime)
          )
          let SundayDateText = this.$util.getDateStrNoYear(
            this.$util.getSundayDate(this.leftTime)
          )
          if (
            this.$util.getMondayDate(this.leftTime).getMonth() ===
            this.$util.getSundayDate(this.leftTime).getMonth()
          ) {
            SundayDateText = SundayDateText.slice(
              SundayDateText.indexOf('月') + 1
            )
          }
          return `${MondayDatetText}-${SundayDateText}`
        }
        case 1: {
          const firstDatetText = this.$util.getDateStrNoYear(
            this.$util.getMonthFirstDate(this.leftTime)
          )
          let lastDateText = this.$util.getDateStrNoYear(
            this.$util.getMonthLastDate(this.leftTime)
          )
          lastDateText = lastDateText.slice(lastDateText.indexOf('月') + 1)
          return `${firstDatetText}-${lastDateText}`
        }

        case 2: {
          let firstDatetText = this.$util.getDateStrNoYear(
            this.$util.getSeasonFirstDate(this.leftTime)
          )
          let lastDateText = this.$util.getDateStrNoYear(
            this.$util.getSeasonLastDate(this.leftTime)
          )
          firstDatetText = firstDatetText.slice(
            0,
            firstDatetText.indexOf('月') + 1
          )
          lastDateText = lastDateText.slice(0, lastDateText.indexOf('月') + 1)
          return `${firstDatetText}-${lastDateText}`
        }
        default:
          return '左边的日期'
      }
    },
    // 计算右边的时间范围
    rightTimeText() {
      switch (this.currentTimeRange) {
        case 0: {
          const MondayDatetText = this.$util.getDateStrNoYear(
            this.$util.getMondayDate(this.rightTime)
          )
          let SundayDateText = this.$util.getDateStrNoYear(
            this.$util.getSundayDate(this.rightTime)
          )
          if (
            this.$util.getMondayDate(this.rightTime).getMonth() ===
            this.$util.getSundayDate(this.rightTime).getMonth()
          ) {
            SundayDateText = SundayDateText.slice(
              SundayDateText.indexOf('月') + 1
            )
          }
          return `${MondayDatetText}-${SundayDateText}`
        }
        case 1: {
          const firstDatetText = this.$util.getDateStrNoYear(
            this.$util.getMonthFirstDate(this.rightTime)
          )
          let lastDateText = this.$util.getDateStrNoYear(
            this.$util.getMonthLastDate(this.rightTime)
          )
          lastDateText = lastDateText.slice(lastDateText.indexOf('月') + 1)
          return `${firstDatetText}-${lastDateText}`
        }

        case 2: {
          let firstDatetText = this.$util.getDateStrNoYear(
            this.$util.getSeasonFirstDate(this.rightTime)
          )
          let lastDateText = this.$util.getDateStrNoYear(
            this.$util.getSeasonLastDate(this.rightTime)
          )
          firstDatetText = firstDatetText.slice(
            0,
            firstDatetText.indexOf('月') + 1
          )
          lastDateText = lastDateText.slice(0, lastDateText.indexOf('月') + 1)
          return `${firstDatetText}-${lastDateText}`
        }

        default:
          return '右边的日期'
      }
    },
    // 计算当前时段的起点和终点
    currentTimeStartAndEnd() {
      let propName = ''
      // 根据当前选中的是左时间还是右时间，当前选中的时间范围来获取第一天和最后一天的日期
      if (this.currentTimeIndex === 0) {
        propName = 'leftTime'
      } else {
        propName = 'rightTime'
      }
      switch (this.currentTimeRange) {
        case 0: {
          const firstTime = this.$util.getDateStr(
            this.$util.getMondayDate(this[propName])
          )
          const lastTime = this.$util.getDateStr(
            this.$util.getSundayDate(this[propName])
          )
          return [firstTime, lastTime]
        }
        case 1: {
          const firstTime = this.$util.getDateStr(
            this.$util.getMonthFirstDate(this[propName])
          )
          const lastTime = this.$util.getDateStr(
            this.$util.getMonthLastDate(this[propName])
          )
          return [firstTime, lastTime]
        }
        case 2: {
          const firstTime = this.$util.getDateStr(
            this.$util.getSeasonFirstDate(this[propName])
          )
          const lastTime = this.$util.getDateStr(
            this.$util.getSeasonLastDate(this[propName])
          )
          return [firstTime, lastTime]
        }
      }
      return []
    },
    // 输入评论的placeholder
    commentPlaceholder() {
      if (!this.replyTo) {
        return '发表评论'
      } else {
        return `回复${this.replyTo.clerk_alias || this.replyTo.clerk_name}：`
      }
    },
    // 是否显示没有数据的提示
    isShowTip() {
      if (this.isMe) {
        return false
      }
      switch (this.currentOption) {
        case 'task': {
          return !this.taskGroupsList || this.taskGroupsList.length === 0
        }
        case 'target': {
          return !this.targetGroups || this.targetGroups.length === 0
        }
        default:
          return false
      }
    },
    currentOption: {
      get() {
        return this.currentOptionData
      },
      set(v) {
        this.$router.push({
          name: 'workspace',
          params: {
            clerkId: this.currentClerkId,
            tab: v
          }
        })
      }
    },
    currentClerkId: {
      get() {
        return this.currentClerkIdData
      },
      set(v) {
        this.$router.push({
          name: 'workspace',
          params: {
            clerkId: v,
            tab: this.currentOption
          }
        })
      }
    }
  },
  watch: {
    // 评论和任务提醒只能展示一个
    isShowComment(val) {
      if (val) {
        this.isShowRemind = false
      }
    },
    isShowRemind(val) {
      if (val) {
        this.isShowComment = false
      }
    },
    // 切换当前选择的用户
    currentClerkId(val) {
      // this.$router.push({
      //   name: 'workspace',
      //   params: {
      //     clerkId: val,
      //     tab: this.currentOption
      //   }
      // })
      // switch (this.currentOption) {
      //   // 查看任务
      //   case 'task': {
      //     this.getTaskList(val)
      //     // this.commentList = []
      //     // 获取任务周期评论
      //     // this.getCommentData()
      //     break
      //   }
      //   // 查看目标
      //   case 'target': {
      //     this.getTargetList(val)
      //     // this.targetCommentList = []
      //     // 获取目标周期评论
      //     // this.getTargetCommentData()
      //     break
      //   }
      //   default:
      //     return 0
      // }
    },
    // 切换查看的数据
    currentOption(val) {
      // this.$router.push({
      //   name: 'workspace',
      //   params: {
      //     clerkId: this.currentClerkId,
      //     tab: val
      //   }
      // })
      // this.replyTo = null
      // switch (val) {
      //   // 查看任务
      //   case 'task': {
      //     this.getTaskList(this.currentClerkId)
      //     this.commentList = []
      //     // 获取任务周期评论
      //     // this.getCommentData()
      //     // this.taskRemindList = []
      //     // 获取任务提醒
      //     // this.getTaskRemindList()
      //     break
      //   }
      //   // 查看目标
      //   case 'target': {
      //     this.getTargetList(this.currentClerkId)
      //     // this.targetCommentList = []
      //     // 获取目标周期评论
      //     // this.getTargetCommentData()
      //     // this.targetRemindList = []
      //     // 获取目标提醒
      //     // this.getTargetRemindList()
      //     break
      //   }
      //   default:
      //     return 0
      // }
      // const query = this.$route.query
      // // 给地址后面加上tab
      // this.$router.replace({
      //   name: 'workspace',
      //   query: {
      //     ...query,
      //     tab: val
      //   }
      // })
    },
    // 切换当前的完成任务状态
    currentTaskRange() {
      this.getTaskList(this.currentClerkId)
    },
    currentTargetRange() {
      this.getTargetList(this.currentClerkId)
    },
    // 监听$route，如果后退，route改变，要关闭详情弹窗
    $route(val) {
      let isRefresh = false
      if (this.currentOption !== val.params.tab) {
        isRefresh = true
      }
      this.currentOptionData = val.params.tab
      if (this.currentClerkId !== Number(val.params.clerkId)) {
        isRefresh = true
      }
      this.currentClerkIdData = Number(val.params.clerkId)
      if (isRefresh) {
        switch (this.currentOption) {
          case 'task': {
            this.getTaskList(this.currentClerkId)
            break
          }
          case 'target': {
            this.getTargetList(this.currentClerkId)
            break
          }
        }
      }
      if (!val.query.task_id) {
        this.isShowDetail = false
      }
      if (!val.query.objective_id) {
        this.isShowTargetDetail = false
      }
    },
    clickBodyIndex() {
      this.outClick()
    }
  },
  mounted() {
    // 自动打开详情
    if (this.$route.query.task_id) {
      this.$router.replace({
        name: 'taskDetail',
        params: {
          id: this.$route.query.task_id
        }
      })
      // this.currentTask = Number(this.$route.query.task_id)
      // this.isShowDetail = true
      // if (this.$route.query.detail_tab) {
      //   switch (this.$route.query.detail_tab) {
      //     case 'comment': {
      //       this.taskCurrentTab = 'third'
      //     }
      //   }
      // }
    } else if (this.$route.query.objective_id) {
      this.$router.replace({
        name: 'targetDetail',
        params: {
          id: this.$route.query.objective_id
        }
      })
      // this.currentTargetId = Number(this.$route.query.objective_id)
      // this.isShowTargetDetail = true
      // if (this.$route.query.detail_tab) {
      //   switch (this.$route.query.detail_tab) {
      //     case 'comment': {
      //       this.targetCurrentTab = 'third'
      //     }
      //   }
      // }
    }

    // 设置默认查看的职员为自己
    // this.currentClerkId = this.myClerkId
    // 获取侧边组织树
    this.getSideClerkTree()
    // 设置任务标识
    this.taskType = this.$route.params.timeType || ''
    // 获取进度状态
    this.progressStatus = this.$route.params.progress_status || ''
    // 获取服务器时间，并且比对
    this.getTodayDate()
    // 获取任务列表数据
    // this.getTaskList()
    // 获取目标列表数据
    // this.getTargetList()
    // 获取关注列表
    this.getFocusClerkList()
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
    // document.addEventListener('click', this.outClick)
    // 获取下属列表
    this.getFellowList()
    // 获取职员树形结构
    this.getClerkTree()
    // 根据跳转过来的参数，来判断当前是按周还是按月、按季度查看（默认是按周查看）
    const time = this.$route.query.time
    // 左边的时间默认是当前时间
    this.leftTime = new Date(this.todayDate)
    // 计算右边的时间
    if (!time) {
      this.currentTimeRange = 0
      this.calculateRightTime()
      console.log('右边的时间为：', this.rightTime)
    } else if (time === 'month') {
      this.currentTimeRange = 1
      this.calculateRightTime()
    } else {
      this.currentTimeRange = 2
      this.calculateRightTime()
    }
    // 获取目标提醒列表
    this.getTargetRemindList(1)
    // 获取目标周期评论
    // this.getTargetCommentData(1)
    // 获取任务提醒列表
    this.getTaskRemindList(1)
    // 获取周期评论列表
    // this.getCommentData(1)
    this.searchClerkAntiShare = this.$util.antiShare(this.searchClerkList)

    // 初始化tab
    if (this.$route.params.tab) {
      switch (this.$route.params.tab) {
        case 'task':
          this.currentOptionData = 'task'
          break
        case 'target':
          this.currentOptionData = 'target'
          break
      }
    }
    if (this.$route.params.clerkId) {
      this.currentClerkIdData = Number(this.$route.params.clerkId)
    }
    switch (this.currentOption) {
      case 'task':
        this.getTaskList(this.currentClerkId)
        break
      case 'target':
        this.getTargetList(this.currentClerkId)
        break
    }
    // if (this.$route?.query?.tab) {
    //   switch (this.$route.query.tab) {
    //     case 'task':
    //       this.currentOption = 'task'
    //       break
    //     case 'target':
    //       this.currentOption = 'target'
    //       break
    //   }
    // }
  },
  methods: {
    ...mapActions(['getToday']),
    handlerShowFiltter() {
      if (this.currentOption === 'target') {
        this.$refs.TargetActionSheet.open()
      }

      if (this.currentOption === 'task') {
        this.$refs.TaskActionSheet.open()
      }
    },
    // 获取筛选结果
    getSelectResult(list) {
      this.taskGroupsList = list
      this.isShowSelectBox = false
    },
    // 获取目标筛选结果
    getTargetSelectResult(list) {
      this.targetGroups = list
      this.isShowSelectBox = false
    },
    // 显示筛选
    toggleSelect() {
      this.isShowSelectBox = !this.isShowSelectBox
    },
    // 显示目标关联弹窗
    showTargetRelative(target) {
      this.currentTarget = target
      this.isShowRelative = true
    },
    // 关闭目标关联的弹窗
    closeRelativeModal() {
      this.isShowRelative = false
      this.currentTarget = null
    },
    // 显示目标详情弹窗
    showTargetDetail(id) {
      this.isShowTargetDetail = true
      this.currentTargetId = id
      this.openTargetDetail(id)
    },
    // 关闭目标详情弹窗
    closeTargetDetail(obj) {
      this.isShowTargetDetail = false
      this.targetCurrentTab = 'first'
      this.fromWhere = null
      if (obj.is_update_data) {
        this.getTargetList()
      }
      this.closeTarget()
    },
    // 点击切换选择目标还是任务
    setOption(val) {
      if (this.currentOption === val) {
        this.taskLabelId = ''
        this.targetLabelId = ''
        switch (this.currentOption) {
          case 'task':
            if (this.taskType !== '' || this.progressStatus !== '') {
              this.taskType = ''
              this.progressStatus = ''
              this.refreshTaskList()
            }
            break
        }
      } else {
        this.currentOption = val
      }
    },
    // 展示移动弹窗
    showMoveModal(id) {
      this.isShowMoveModal = true
      this.currentTask = id
    },
    // 关闭移动弹窗
    closeMoveModal() {
      this.isShowMoveModal = false
      this.currentTask = -1
    },
    // 选择
    chooseMoveTarget(range, id) {
      this.targetId = id
      this.targetRange = range
    },
    // 展示移动目标的弹窗
    showMoveTarget(id) {
      this.isShowMoveTargetBox = true
      this.currentTargetId = id
    },
    // 关闭移动目标的弹窗
    closeMoveTarget() {
      this.isShowMoveTargetBox = false
      this.currentTargetId = -1
    },
    // 选择移动的目标
    chooseTargetMoveTarget(range, id) {
      this.targetId = id
      this.targetRange = range
    },
    // 处理评论的输入框
    handleComment() {
      this.isEditing = false
      // 假如失去焦点后，并没有输入内容，那么设为不回复
      if (this.newComment.trim().length === 0) {
        if (this.replyTo.clerk_id !== -1) {
          this.replyTo = null
        }
      }
    },
    // 获取任务提醒列表
    async getTaskRemindList(page = 1) {
      this.isLoadingTaskRemind = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskRemindList, {
          pageNumber: page,
          pageSize: this.taskRemindPageInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        // 把数据接到我的展示数组最后
        this.taskRemindList = [...this.taskRemindList, ...page.list]
        // 除了接数据，也更新一下我记录的分页情况
        this.taskRemindPageInfo.pageNumber = page.pageNumber
        this.taskRemindPageInfo.totalPage = page.totalPage
        if (page.pageNumber === page.totalPage) {
          this.taskRemindPageInfo.lastPage = true
        }
        this.isLoadingTaskRemind = false
      } else {
        this.isLoadingTaskRemind = false
      }
    },
    // 获取任务提醒的下一页
    getRemindNextPage({ id, page }) {
      console.log('数据准备刷新', id, page)
      if (id === 'task-remind') {
        this.taskRemindPageInfo.pageNumber = page
        // 发起下一页的请求
        if (!this.isLoadingTaskRemind) {
          this.getTaskRemindList(this.taskRemindPageInfo.pageNumber)
        }
      }
    },
    // 获取目标提醒列表
    async getTargetRemindList(page = 1) {
      this.isLoadingTargetRemind = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetRemind, {
          pageNumber: page,
          pageSize: this.targetRemindPageInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        // 把数据接到我的展示数组最后
        this.targetRemindList = [...this.targetRemindList, ...page.list]
        // 除了接数据，也更新一下我记录的分页情况
        this.targetRemindPageInfo.pageNumber = page.pageNumber
        this.targetRemindPageInfo.totalPage = page.totalPage
        if (page.pageNumber === page.totalPage) {
          this.targetRemindPageInfo.lastPage = true
        }
        this.isLoadingTargetRemind = false
      } else {
        this.isLoadingTargetRemind = false
      }
    },
    // 获取目标提醒的下一页
    getTargetRemindNextPage({ id, page }) {
      console.log('数据准备刷新', id, page)
      if (id === 'target-remind') {
        this.targetRemindPageInfo.pageNumber = page
        if (!this.isLoadingTargetRemind) {
          // 发起下一页的请求
          this.getTargetRemindList(this.targetRemindPageInfo.pageNumber)
        }
      }
    },
    // 点击任务提醒，展示出任务详情弹窗
    showTaskDetail(id) {
      this.currentTask = id
      this.isShowDetail = true
      this.openTaskDetail(id)
    },
    changeTaskDetail(id) {
      this.currentTargetId = -1
      this.showTaskDetail(id)
      // if (this.isShowTargetDetail) {
      //   this.isShowTargetDetail = false
      //   this.showTaskDetail(id)
      // } else {
      //   this.currentTask = id
      // }
    },
    // 点击显示隐藏任务提醒
    toggleTaskRemind() {
      this.isShowRemind = !this.isShowRemind
    },
    // 关闭任务提醒
    closeTaskRemind() {
      this.isShowRemind = false
    },
    // 点击显示评论区域
    toggleComment() {
      this.isShowComment = !this.isShowComment
    },
    // 关闭评论区域
    closeComment() {
      this.isShowComment = false
    },
    // 获取评论数据
    async getCommentData(page = 1) {
      this.isLoadingTaskComment = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getPeriodCommentList, {
          // period_start_date: this.currentTimeStartAndEnd[0],
          // period_end_date: this.currentTimeStartAndEnd[1],
          comment_clerk_id: this.currentClerkId,
          pageNumber: page,
          pageSize: this.commentListPageInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.commentList = [...this.commentList, ...res.page.list]
        this.commentListPageInfo.totalPage = res.page.totalPage
        this.commentListPageInfo.totalRow = res.page.totalRow
        this.commentListPageInfo.pageNumber = res.page.pageNumber
        this.isLoadingTaskComment = false
      } else {
        this.isLoadingTaskComment = false
      }
    },
    // 刷新评论数据
    refreshCommentData() {
      this.commentList = []
      this.commentListPageInfo.pageNumber = 1
      if (!this.isLoadingTaskComment) {
        this.getCommentData(1)
      }
    },
    // 加载评论的下一页
    getCommentDataNextPage() {
      this.commentListPageInfo.pageNumber =
        this.commentListPageInfo.pageNumber + 1
      console.log(this.commentListPageInfo.pageNumber)
      if (!this.isLoadingTaskComment) {
        this.getCommentData(this.commentListPageInfo.pageNumber)
      }
    },
    // 添加一条评论
    async addNewComment() {
      const val = this.newComment.trim()
      this.getTodayDate()
      const formData = {
        comment_content: val,
        comment_clerk_id: this.myClerkId,
        create_clerk_id: this.myClerkId,
        period_date: this.$util.getDateAndTimeStr(this.todayDate)
      }
      if (this.replyTo && this.replyTo.pid !== -1) {
        formData.pid = this.replyTo.pid
      }
      if (val.length > 0) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addNewComment, formData)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          // 如果成功提交，就刷新评论列表数据
          this.refreshCommentData()
          this.cancelAddComment()
          this.replyTo = null
        }
      }
    },
    // 准备回复某人
    setReplyObject(obj) {
      this.newComment = ''
      this.replyTo = obj
    },
    // 清空评论输入框
    cancelAddComment() {
      this.newComment = ''
    },
    // 获取目标的周期评论数据
    async getTargetCommentData(page = 1) {
      this.isLoadingTargetComment = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetPeriodComment, {
          // period_start_date: this.currentTimeStartAndEnd[0],
          // period_end_date: this.currentTimeStartAndEnd[1],
          comment_clerk_id: this.currentClerkId,
          pageNumber: page,
          pageSize: this.targetCommentListPageInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.targetCommentList = [...this.targetCommentList, ...res.page.list]
        this.targetCommentListPageInfo.totalPage = res.page.totalPage
        this.targetCommentListPageInfo.totalRow = res.page.totalRow
        this.targetCommentListPageInfo.pageNumber = res.page.pageNumber
        this.isLoadingTargetComment = false
      } else {
        this.isLoadingTargetComment = false
      }
    },
    // 刷新目标评论数据
    refreshTargetCommentData() {
      this.targetCommentList = []
      this.targetCommentListPageInfo.pageNumber = 1
      if (!this.isLoadingTargetComment) {
        this.getTargetCommentData(1)
      }
    },
    // 加载目标评论的下一页
    getTargetCommentDataNextPage() {
      this.targetCommentListPageInfo.pageNumber =
        this.targetCommentListPageInfo.pageNumber + 1
      console.log(this.targetCommentListPageInfo.pageNumber)
      if (!this.isLoadingTargetComment) {
        this.getTargetCommentData(this.targetCommentListPageInfo.pageNumber)
      }
    },
    // 添加一条评论
    async addNewTargetComment() {
      const val = this.newTargetComment.trim()
      this.getTodayDate()
      const formData = {
        comment_content: val,
        comment_clerk_id: this.myClerkId,
        create_clerk_id: this.myClerkId,
        period_date: this.$util.getDateAndTimeStr(this.todayDate)
      }
      if (this.replyTo && this.replyTo.pid !== -1) {
        formData.pid = this.replyTo.pid
      }
      if (val.length > 0) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addTargetPeriodComment, formData)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          // 如果成功提交，就刷新评论列表数据
          this.refreshTargetCommentData()
          this.cancelAddTargetComment()
          this.replyTo = null
        }
      }
    },
    // 准备回复某人(目标周期评论)
    setTargetReplyObject(obj) {
      this.newTargetComment = ''
      this.replyTo = obj
    },
    // 清空目标评论输入框
    cancelAddTargetComment() {
      this.newTargetComment = ''
    },
    // 处理目标评论的输入框
    handleTargetComment() {
      this.isEditing = false
      // 假如失去焦点后，并没有输入内容，那么设为不回复
      if (this.newTargetComment.trim().length === 0) {
        if (this.replyTo.clerk_id !== -1) {
          this.replyTo = null
        }
      }
    },
    // 防抖搜索
    triggerSearchClerk() {
      this.searchClerkAntiShare()
    },
    // 展示出开发中的提示
    showBuilding() {
      this.$message({
        type: 'info',
        message: '功能开发中!',
        showClose: true
      })
    },
    // 关注某人
    async addFocus(clerk) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addFocusPerson, {
          focus_clerk_id: clerk.clerk_id
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '关注成功',
            type: 'success'
          })
          // 关注数组新增一个元素
          this.focusClerkList.push({
            clerk_id: clerk.clerk_id,
            clerk_name: clerk.clerk_name,
            clerk_alias: clerk.clerk_alias,
            clerk_avatar: clerk.clerk_avatar
          })
        }
      }
    },
    // 取关某人
    removeFocus(clerk, index) {
      // this.$util.showConfirm('确定要取消对他的关注吗？').then((result) => {
      //   if (result) {
      this.$http
        .post(this.$api.removeFocusPerson, {
          focus_clerk_id: clerk.clerk_id
        })
        .then((res) => {
          if (res.state === 'ok') {
            this.$message({
              showClose: true,
              message: '取消关注成功',
              type: 'success'
            })
          }
          // 关注数组删掉一个元素

          this.focusClerkList.splice(
            this.focusClerkList.findIndex((item) => {
              return item.clerk_id === clerk.clerk_id
            }),
            1
          )
        })
      //   }
      // })
    },
    // 计算当前时间
    getTodayDate() {
      this.getToday().then((res) => {
        this.todayDate = res
      })
    },
    // 获取下属同事列表
    async getFellowList() {
      // 获取线性的数据
      // const [err, res] = await this.$util.to(
      //   this.$http.post(this.$api.myChildClerk, {
      //     clerk_status: 1
      //   })
      // )
      // if (err) {
      //   console.log(err)
      // } else {
      //   if (res.state === 'ok') {
      //     console.log('获取下属信息', res)
      //     this.fellowList = res.list
      //   }
      // }
      // 获取树形的数据
      this.$util.getChildClerkTree().then((res) => {
        this.fellowList = res
        console.log('线性的下属数据', this.fellowList)
      })
    },
    // 获取关注列表
    async getFocusClerkList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.myFocusList, {
          clerk_status: 1
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.focusClerkList = res.list
        }
      }
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 获取职员列表
    async getSideClerkTree() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkTree, {
          clerk_status: 1,
          internship_check: 1
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          const clerkTree = this.$util.handleTreeData(res.list, 'dept_id')
          this.sideClerkTree = clerkTree
        }
      }
    },
    // 清空搜索
    clearSearch() {
      this.searchName = ''
      this.searchResultList = []
    },
    // 搜索同事
    async searchClerkList() {
      const val = this.searchName.trim()
      if (val.length > 0) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.getClerkList, {
            search_name: val,
            clerk_status: 1,
            internship_check: 1
          })
        )
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            this.searchResultList = [...res.list]
          }
        }
        // this.$util.searchClerkList(val).then((res) => {
        //   if (res) {
        //     console.log('搜索结果：', res)
        //     this.searchResultList = [...res]
        //   }
        // })
      }
    },
    // 点到外面，就关闭更多和进度弹窗
    outClick() {
      this.isChooseTimeRange = false
      this.isChooseTaskRange = false
      this.currentShowMore = -1
      this.currentShowPriority = -1
      this.currentShowProgress = -1
      this.isShowSearchClerkResult = false
      this.isChooseTargetRange = false
      this.isShowSelectBox = false
    },
    // 关闭任务详情
    closeDetailModal({ is_update_data: isUpdateData }) {
      this.taskCurrentTab = 'first'
      this.isShowDetail = false
      if (isUpdateData) {
        this.refreshTaskList()
      }
      this.closeTaskDetail()
    },
    // 展示任务详情 type 1点名字 2点更多菜单的编辑
    openDetailModal(id) {
      // 接收当前要展示的任务的id
      this.currentTask = id
      // 假如当前展示的是它的更多弹窗，就隐藏掉更多弹窗，并且不显示详情弹窗
      if (this.currentShowMore !== -1) {
        this.currentShowMore = -1
        return
      }
      // 否则显示出它的详情弹窗
      this.isShowDetail = true
      this.openTaskDetail(id)
    },
    // 显示隐藏下级列表
    toggleSubordinate() {
      this.isShowSubordinate = !this.isShowSubordinate
    },
    // 显示隐藏关注列表
    toggleFollow() {
      this.isShowFollow = !this.isShowFollow
    },
    toggleAddressList() {
      this.isShowAddress = !this.isShowAddress
    },
    // 设置一下当前查看哪个职员的任务
    setCurrentClerkId(id) {
      this.currentClerkId = id
      this.searchName = ''
      this.isShowSearchClerkResult = false
      // if (this.currentTimeIndex === 0) {
      //   this.getListByLeftOrRight(true)
      // } else {
      //   this.getListByLeftOrRight(false)
      // }
    },
    // 刷新任务列表
    refreshTaskList() {
      this.getTaskList(this.currentClerkId)
    },
    // 获取任务列表
    async getTaskList(clerkId = this.myClerkId) {
      // 请求任务列表
      const formObj = {
        clerk_id: clerkId
      }
      // if (typeof start === 'string') {
      //   formObj.period_start_date = start
      // } else {
      //   formObj.period_start_date = this.$util.getDateStr(start)
      // }
      // if (typeof end === 'string') {
      //   formObj.period_end_date = end
      // } else {
      //   formObj.period_end_date = this.$util.getDateStr(end)
      // }
      // 判断当前选择的是“所有任务”还是“未完成任务”或者已完成任务
      if (this.currentTaskRange === 1) {
        formObj.is_complete = 0
      } else if (this.currentTaskRange === 2) {
        formObj.is_complete = 1
      } else if (this.currentTaskRange === 3) {
        formObj.is_complete = 2
      }
      if (this.progressStatus !== '') {
        formObj.progress_status = this.progressStatus
      }
      if (this.taskLabelId) {
        formObj.label_id = this.taskLabelId
      }
      // 判断时间
      if (this.taskType !== '') {
        let startTime = ''
        let endTime = ''
        switch (this.taskType) {
          case 1:
            startTime = this.$util.getDateStr(this.todayDate)
            endTime = this.$util.getDateStr(this.todayDate)
            break
          case 2:
            startTime = this.$util.getDateStr(
              this.$util.getMondayDate(this.todayDate)
            )
            endTime = this.$util.getDateStr(
              this.$util.getSundayDate(this.todayDate)
            )
            break
          case 3:
            startTime = this.$util.getDateStr(
              this.$util.getMonthFirstDate(this.todayDate)
            )
            endTime = this.$util.getDateStr(
              this.$util.getMonthLastDate(this.todayDate)
            )
            break
        }
        if (startTime !== '' && endTime !== '') {
          formObj.period_start_date = startTime
          formObj.period_end_date = endTime
        }
      }
      console.log(formObj)
      let taskListData = []
      // 请求任务列表
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskList, formObj)
      )
      const treeList = []
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        taskListData = res
        const taskMap = new Map()
        // 处理数据，把它整理成一个map
        for (const item of taskListData.list) {
          if (!taskMap.has(item.group_id)) {
            taskMap.set(item.group_id, [item])
          } else {
            const arr = taskMap.get(item.group_id)
            taskMap.set(item.group_id, [...arr, item])
          }
        }
        // 遍历处理完的map，把它整理成树形
        for (const item of taskMap) {
          treeList.push({
            key: item[0],
            list: this.$util.handleTreeData(item[1])
          })
        }
        this.taskGroupsList = treeList
      }
      console.log('转换成了树形', treeList)
    },
    // 获取目标列表
    async getTargetList(clerkId = this.currentClerkId) {
      // 请求任务列表
      const formObj = {
        clerk_id: clerkId
      }
      // if (typeof start === 'string') {
      //   formObj.period_start_date = start
      // } else {
      //   formObj.period_start_date = this.$util.getDateStr(start)
      // }
      // if (typeof end === 'string') {
      //   formObj.period_end_date = end
      // } else {
      //   formObj.period_end_date = this.$util.getDateStr(end)
      // }
      // 判断当前选择的是“所有任务”还是“未完成任务”或者已完成任务
      if (this.currentTargetRange === 1) {
        formObj.is_complete = 0
      } else if (this.currentTargetRange === 2) {
        formObj.is_complete = 1
      } else if (this.currentTargetRange === 3) {
        formObj.is_complete = 2
      }
      if (this.targetLabelId) {
        formObj.label_id = this.targetLabelId
      }
      console.log(formObj)
      // 原始数据
      let targetRawData = []
      // 存放结果的数据
      const targetGroups = []
      // 请求目标列表
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        targetRawData = res.list
      }
      // console.log('原始的目标数据', targetRawData)
      // 遍历原始数据
      // targetRawData.forEach((dataItem) => {
      //   if (targetGroups.length === 0) {
      //     targetGroups.push({
      //       group_id: dataItem.group_id,
      //       list: [dataItem]
      //     })
      //   } else {
      //     const group = targetGroups.find(
      //       (item) => item.group_id === dataItem.group_id
      //     )
      //     if (group) {
      //       group.list.push(dataItem)
      //     } else {
      //       targetGroups.push({
      //         group_id: dataItem.group_id,
      //         list: [dataItem]
      //       })
      //     }
      //   }
      // })
      const targetMap = new Map()
      // 处理数据，把它整理成一个map
      for (const item of targetRawData) {
        if (!targetMap.has(item.group_id)) {
          targetMap.set(item.group_id, [item])
        } else {
          const arr = targetMap.get(item.group_id)
          targetMap.set(item.group_id, [...arr, item])
        }
      }
      // 遍历处理完的map，把它整理成树形
      for (const item of targetMap) {
        targetGroups.push({
          key: item[0],
          list: this.$util.handleTreeData(item[1], 'objective_id')
        })
      }
      this.targetGroups = targetGroups
      console.log('分组处理完毕', this.targetGroups)
    },
    // 点击展开收起选择任务范围
    toggleChooseTaskRange() {
      this.isChooseTaskRange = !this.isChooseTaskRange
    },
    // 点击展开收起选择目标范围
    toggleChooseTargetRange() {
      this.isChooseTargetRange = !this.isChooseTargetRange
      console.log('点击了选择目标范围')
    },
    // 选择任务范围
    chooseTaskRange(index) {
      console.log(index)
      this.taskLabelId = ''
      this.targetLabelId = ''
      console.log('选择行数', this.currentTaskRange)
      this.taskType = ''
      this.progressStatus = ''
      if (this.currentTaskRange !== index) {
        this.currentTaskRange = index
      } else {
        // 选择完，刷新一次列表
        this.refreshTaskList()
      }
    },
    // 点击选择目标范围
    chooseTargetRange(index) {
      this.taskLabelId = ''
      this.targetLabelId = ''
      if (this.currentTargetRange !== index) {
        this.currentTargetRange = index
      } else {
        // 选择完，刷新一次列表
        this.getTargetList()
      }
    },
    // 选择任务时间范围
    chooseTimeRange(index) {
      this.currentTimeRange = index
      // 获取一下当前时间
      this.getTodayDate()
      // 把高亮的那个设为左边的
      this.currentTimeIndex = 0
      // 把左边的时间切换成当前时间
      this.leftTime = this.todayDate
      // 计算右时间
      this.calculateRightTime()
      // 根据左时间来发请求，获取任务列表
      this.getListByLeftOrRight(true)
    },
    // 计算右边的时间
    calculateRightTime() {
      const dayTime = 86400000
      // 根据你切换的时间范围，来确定右边的时间
      switch (this.currentTimeRange) {
        case 0: {
          this.rightTime = new Date(this.leftTime.getTime() + 7 * dayTime)
          break
        }
        case 1: {
          const days = this.$util.getMonthDays(this.leftTime)
          this.rightTime = new Date(this.leftTime.getTime() + days * dayTime)
          break
        }
        case 2: {
          const days = this.$util.getSeasonDays(this.leftTime)
          this.rightTime = new Date(this.leftTime.getTime() + days * dayTime)
          break
        }
      }
      // 刷新周期评论列表
      this.refreshCommentData()
    },
    // 点击时间范围的左右箭头
    getTimeRange(val) {
      const dayTime = 86400000
      // 点击左箭头
      if (val === -1) {
        if (this.currentTimeIndex === 1) {
          this.currentTimeIndex = 0
          // 根据左时间来获取任务列表
          this.getListByLeftOrRight(true)
        } else {
          this.currentTimeIndex = 0
          // 判断现在的时间范围是多少
          switch (this.currentTimeRange) {
            // 如果是周，让leftTime的时间往前一周
            case 0: {
              this.leftTime = new Date(this.leftTime.getTime() - 7 * dayTime)
              break
            }
            // 如果是月，让leftTime的时间往前一个月（计算当前是几月几号，然后切换成上个月的这一号）
            case 1: {
              const month = this.leftTime.getMonth()
              const year = this.leftTime.getFullYear()
              const date = this.leftTime.getDate()
              // 如果当前是1月，那就换到去年12月
              if (month === 0) {
                this.leftTime = new Date(year - 1, 11, date)
              } else {
                this.leftTime = new Date(year, month - 1, date)
              }
              break
            }
            // 如果是季度，让left的时间往前3个月（切换成3个月前的这一号）
            case 2: {
              const month = this.leftTime.getMonth()
              const year = this.leftTime.getFullYear()
              const date = this.leftTime.getDate()
              // 假如现在在1到3月，那么要变成去年10到12月
              if (month < 2) {
                this.leftTime = new Date(year - 1, 9, date)
              } else {
                this.leftTime = new Date(year, month - 3, date)
              }
              break
            }
          }
          // 然后计算rightTime
          this.calculateRightTime()
          // 根据左时间来获取任务列表
          this.getListByLeftOrRight(true)
        }
      } else if (val === 1) {
        // 点击右箭头
        if (this.currentTimeIndex === 0) {
          this.currentTimeIndex = 1
          // 根据右时间来获取任务列表
          this.getListByLeftOrRight(false)
        } else {
          this.currentTimeIndex = 1
          // 判断现在的时间范围是多少
          switch (this.currentTimeRange) {
            // 如果是周，让leftTime的时间往后一周
            case 0: {
              this.leftTime = new Date(this.leftTime.getTime() + 7 * dayTime)
              break
            }
            // 如果是月，让leftTime的时间往后一个月（计算当前是几月几号，然后切换成上个月的这一号）
            case 1: {
              const month = this.leftTime.getMonth()
              const year = this.leftTime.getFullYear()
              const date = this.leftTime.getDate()
              // 如果当前是12月，那就换到明年1月
              if (month === 11) {
                this.leftTime = new Date(year + 1, 0, date)
              } else {
                this.leftTime = new Date(year, month + 1, date)
              }
              break
            }
            // 如果是季度，让left的时间往前3个月（切换成3个月前的这一号）
            case 2: {
              const month = this.leftTime.getMonth()
              const year = this.leftTime.getFullYear()
              const date = this.leftTime.getDate()
              // 假如现在在9到12月，那么要变成明年1到3月
              if (month > 8) {
                this.leftTime = new Date(year + 1, 0, date)
              } else {
                this.leftTime = new Date(year, month + 3, date)
              }
              break
            }
          }
          // 然后计算rightTime
          this.calculateRightTime()
          // 根据右时间来获取任务列表
          this.getListByLeftOrRight(false)
        }
      }
    },
    // 根据左/右时间来发送请求，获取任务列表
    getListByLeftOrRight(isLeft) {
      // 选中的是左边，根据左时间和当前的时间范围来计算出开始和结束时间

      // 根据时间范围，发请求获取任务列表
      this.getTaskList(this.currentClerkId)
    },
    // 选择时间范围
    chooseTimeIndex(index) {
      if (index !== this.currentTimeIndex) {
        // 设置高亮
        this.currentTimeIndex = index
        if (index === 0) {
          // 选中的是左边，根据左时间和当前的时间范围来计算出开始和结束时间
          this.getListByLeftOrRight(true)
        } else {
          this.getListByLeftOrRight(false)
        }
      }
    },
    // 点击展开收起选择任务时间范围
    toggleChooseTimeRange() {
      this.isChooseTimeRange = !this.isChooseTimeRange
    },
    // 开始新增任务
    beginAddTask(index) {
      this.addTaskGroup = index
      // 动态渲染的元素ref不一定能获取到
      console.log('index', index)
      if (index === -1) {
        this.isNewGroup = true
      } else {
        this.isNewGroup = false
      }
      this.$nextTick(() => {
        if (index === -1) {
          this.$refs[`addTaskInput_${index}`].focus()
        } else {
          this.$refs[`addTaskInput_${index}`][0].focus()
        }
      })
    },
    // 处理新输入的任务名字
    async handleNewTask(id) {
      const val = this.newTask.trim()
      if (val.length === 0) {
        this.cancelNewTask()
      } else {
        const formObj = {
          'task.task_name': val,
          'task.period_date': this.currentTimeStartAndEnd[0]
        }
        if (id) {
          formObj.group_id = id
        }
        console.log(formObj)
        // console.log('准备添加新任务', formObj)
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addNewTask, formObj)
        )
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            this.cancelNewTask()
            // 刷新任务列表
            this.refreshTaskList()
          }
        }
      }
    },
    // 取消新输入的任务名字
    cancelNewTask() {
      this.addTaskGroup = -1
      this.newTask = ''
      this.isNewGroup = false
    },
    // 开始新增目标
    beginAddTarget(index) {
      // this.addTaskGroup = index
      // 动态渲染的元素ref不一定能获取到
      console.log('index', index)
      if (index === -1) {
        this.isNewTargetGroup = true
      } else {
        this.isNewTargetGroup = false
      }
      this.$nextTick(() => {
        if (index === -1) {
          this.$refs[`addTargetInput_${index}`].focus()
        } else {
          this.$refs[`addTargetInput_${index}`][0].focus()
        }
      })
    },
    // 取消新增目标
    cancelNewTarget() {
      this.addTaskGroup = -1
      this.newTarget = ''
      this.isNewTargetGroup = false
    },
    // 处理新增目标
    async handleNewTarget() {
      const val = this.newTarget.trim()
      this.getTodayDate()
      if (val.length === 0) {
        this.cancelNewTarget()
      } else {
        const formObj = {
          'objective.objective_name': val,
          'objective.period_date': this.$util.getDateAndTimeStr(this.todayDate)
        }
        console.log(formObj)
        // console.log('准备添加新任务', formObj)
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addTarget, formObj)
        )
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            this.cancelNewTarget()
            // 刷新任务列表
            this.getTargetList()
          }
        }
      }
    },
    // 展示出某任务的更多弹窗
    showMoreModal(id) {
      this.currentShowMore = id
    },
    // 展示出某任务的优先级弹窗
    showPriorityModal(id) {
      this.currentShowPriority = id
    },
    // 展示出某任务的进度弹窗
    showProgressModal(id) {
      this.currentShowProgress = id
    },
    // 展示某目标的类型选择弹窗
    showTargetModal(id) {
      this.currentShowType = id
    },
    // 展示某个目标的进度选择弹窗
    showTargetProgress(id) {
      this.currentShowTargetProgress = id
    },
    // 点击树形结构，设置当前选中的人
    chooseCurrentClerk(data) {
      this.currentClerkId = data.clerk_id
      console.log('点击了树形的成员，名字是：', data.clerk_name)
      // if (this.currentTimeIndex === 0) {
      //   this.getListByLeftOrRight(true)
      // } else {
      //   this.getListByLeftOrRight(false)
      // }
    },
    // 删除任务
    removeTask(id) {
      console.log(id)
      // 删除之前先确认
      this.$util
        .showConfirm('此操作将永久删除该任务, 是否继续?')
        .then(async (res) => {
          if (res) {
            // 发请求，删除任务
            const [err, res] = await this.$util.to(
              this.$http.post(this.$api.removeTask, {
                task_id: id
              })
            )
            if (err) {
              console.log(err)
            } else {
              console.log(res)
              // 删除成功，给出提示
              this.$message({
                type: 'success',
                message: '删除成功!',
                showClose: true
              })
              // 刷新任务列表
              this.refreshTaskList()
            }
          } else {
          }
        })
    },
    // 标星任务
    async starTask(id) {
      console.log(id)
      // 发请求，标星任务
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.starTask, {
          task_id: id
        })
      )
      if (err) {
        console.log(err)
      } else {
        console.log(res)
        // 刷新任务列表
        this.refreshTaskList()
      }
    },
    // 改变目标详情的id
    changeTargetDetail({ id, fromWhere }) {
      this.currentTask = -1
      this.showTargetDetail(id)
      this.fromWhere = fromWhere
      // if (this.isShowDetail) {
      //   this.isShowDetail = false
      //   this.showTargetDetail(id)
      // } else {
      //   this.currentTargetId = id
      //   this.fromWhere = fromWhere
      // }
    },
    // 回车调用失去焦点
    toBlur(e, args) {
      e.target.blur(args)
    },
    // 输入评论的输入框聚焦后
    beginEdit() {
      this.isEditing = true
    },
    // 开启/关闭任务详情弹窗
    openTaskDetail(val) {
      console.log('展示任务详情')
      this.$router.push({
        name: 'workspace',
        params: this.$route.params,
        query: { ...this.$route.query, task_id: val }
      })
    },
    closeTaskDetail() {
      const query = { ...this.$route.query }
      delete query.task_id
      this.$router.push({
        name: 'workspace',
        params: this.$route.params
      })
    },
    // 开启/关闭目标详情弹窗
    openTargetDetail(val) {
      this.$router.push({
        name: 'workspace',
        params: this.$route.params,
        query: { ...this.$route.query, objective_id: val }
      })
    },
    closeTarget() {
      const query = { ...this.$route.query }
      delete query.objective_id
      this.$router.push({
        name: 'workspace',
        params: this.$route.params
      })
    },
    // 点击头像
    clickAvatar() {},
    hoverAvatar(e) {
      console.log(e)
      console.log(e.get)
    },
    // 修改任务标签
    changeTaskLabel(id) {
      this.taskLabelId = id
      this.refreshTaskList()
    },
    // 修改目标标签
    changeTargetLabel(id) {
      this.targetLabelId = id
      this.getTargetList()
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to)
    if (!to.params.clerkId) {
      next({
        name: to.name,
        params: {
          clerkId: store.state.myClerkId,
          tab: 'task'
        }
      })
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  height: auto;
}
// 主体部分，左右两列的父级
.main-container.el-container {
  // width: 0;
  height: calc(100vh - 62px);
  padding: 24px $page-padding 0 !important;
}

// 左边
.contact-person {
  height: calc(100vh - 60px - 24px);
  // 搜索
  .search-box {
    position: relative;
    margin-bottom: 20px;
    height: 42px;
    padding: 0 16px;
    background: #ffffff;
    border-radius: 6px;
    .search-icon {
      width: 20px;
      font-size: 20px;
      line-height: 1;
      color: #bbb;
    }
    .input {
      width: calc(100% - 20px);
      border: none;
      outline: none;
      height: 30px;
      padding: 4px 0;
      box-sizing: border-box;
      margin-left: 12px;
      line-height: 1.5;
      color: #333;
    }
    .clear-btn {
      height: 20px;
      width: 20px;
      border: none;
      outline: none;
    }
  }

  // 主要部分
  .main-box {
    width: 100%;
    height: calc(100% - 16px);
    padding-top: 20px;
    overflow-x: auto;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 6px;
    // &::-webkit-scrollbar {
    //   height: 12px;
    // }
    // /* 滚动槽 */
    // &::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   border-radius: 10px;
    // }
    // /* 滚动条滑块 */
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 10px;
    //   background: rgba(0, 0, 0, 0.1);
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    // }
    // &::-webkit-scrollbar-thumb:window-inactive {
    //   background: rgba(255, 0, 0, 0.4);
    // }
  }
  // 我的信息样式
  .fellow-item.active,
  .my-info {
    height: 48px;
    padding: 0 24px;
    margin-bottom: 16px;
    background: rgba(0, 128, 255, 0.1);
    .avatar {
      width: 26px;
      height: 26px;
      display: block;
      border-radius: 50%;
    }
    .name {
      margin-left: 16px;
      font-size: 16px;
      line-height: 1.5;
      color: $main-color;
      cursor: pointer;
    }
  }
  // 当前被选中的同事
  .fellow-item.active {
    margin-bottom: 0;
    .name {
      margin-left: 10px;
    }
    .avatar {
      width: 30px;
      height: 30px;
    }
  }
  // 我的同事
  .my-fellow-box {
    // 头部
    .my-fellow-header {
      height: 48px;
      padding: 0 18px;
      > .arrow {
        width: 24px;
        height: 24px;
        font-size: 24px;
        display: block;
        color: #999;
        cursor: pointer;
        transform-origin: center;
        &.active {
          transform: rotateZ(180deg);
        }
      }
    }
    // 标题
    .my-fellow-title {
      font-weight: 400;
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      color: #333;
    }
    // 每一个同事
    .fellow-item {
      position: relative;
      padding-left: 18px;
      height: 44px;
    }
    .avatar {
      width: 30px;
      height: 30px;
      display: block;
      border-radius: 50%;
    }
    .name {
      margin-left: 10px;
      line-height: 1.5;
      color: #999;
    }
  }
}

// 中间
// 第一行，菜单栏
.menu-box {
  height: 42px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 6px;
  // 选项
  .option-item {
    margin-left: 26px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    &.active {
      color: $main-color;
    }
  }
  // 箭头
  .arrow {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    font-size: 24px;
    display: block;
    color: #999;
    transform-origin: center;
    &.left-arrow {
      transform: rotateZ(180deg);
    }
  }
  // 时间tab
  .choose-time {
    position: relative;
    &::before,
    &::after {
      content: '';
      top: 0;
      bottom: 0;
      width: 1px;
      background: #eee;
    }
    &::before {
      left: 41px;
    }
    &::after {
      right: 41px;
    }
    .date-tab-item {
      padding: 0 27px;
      height: 42px;
      border-left: 1px solid #eee;
      line-height: 42px;
      cursor: pointer;
      color: #333;
      &.active {
        color: $main-color;
      }
      & + .date-tab-item {
        border-right: 1px solid #eee;
      }
    }
  }
  // 右边的每一个按钮
  .menu-btn {
    position: relative;
    cursor: pointer;
    height: 32px;
    padding: 0 11px;
    margin-right: 12px;
    border-radius: 6px;
    &:hover {
      background: #f6f6f6;
    }
    // 图标
    .menu-icon {
      width: 16px;
      display: block;
      font-size: 16px;
      color: #999;
      display: block;
      &.active {
        color: $main-color;
      }
    }
    // 文字
    .menu-text {
      margin-left: 8px;
      line-height: 1.5;
      color: #999;
      &.active {
        color: $main-color;
      }
    }
    &.no-text {
      padding: 0 8px;
    }
  }
}

// 每一组的样式
.task-group-box {
  margin-bottom: 20px;
  padding: 0 0 10px 0;
  background: #ffffff;
  border-radius: 6px;
  .tag {
    margin-top: 14px;
    margin-bottom: 14px;
    margin-right: 12px;
    width: 45px;
    line-height: 24px;
    height: 24px;
    font-weight: 700;
    text-align: center;
    background: $main-color;
    color: #fff;
    border-radius: 30px;
    & + .flex1 {
      width: 0;
    }
  }
  .group-header {
    height: 55px;
    .group-name {
      line-height: 1.5;
      color: #333;
    }
    .group-th {
      text-align: center;
      line-height: 1.5;
      color: #333;
      &.sumbit {
        width: 88px;
      }
      &.priority {
        width: 10%;
      }
      &.progress {
        width: 14%;
        margin-left: 20px;
      }
    }
  }
}
.task-group-box.task-padding-box {
  padding-left: 20px !important;
}

// 筛选按钮
.filtter-button {
  position: fixed;
  bottom: 80px;
  right: 10px;
  height: 34px;
  width: 34px;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  background-color: $main-color;
  border-radius: 34px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

// 选择任务的范围的白色弹窗
.choose-task-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 160px;
  z-index: 5;
  margin-top: 10px;
  background: #ffffff;
  /* 2 */
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.4);
  border-radius: 4px;
  // 里面的每一条数据
  .choose-task-item {
    height: 48px;
    padding: 0 16px;
    cursor: pointer;
    &:hover {
      background: $light-grey-bg;
    }
    .range-text {
      color: #333;
    }
    .icon-ok {
      width: 16px;
      height: 16px;
      display: block;
    }
  }
}

// 添加任务
.add-line {
  height: 48px;
  .add-btn {
    cursor: pointer;
    border: none;
    outline: none;
    // margin-left: 34px;
    line-height: 1.5;
    color: #999;
    background: #fff;
    .add-icon {
      width: 14px;
      font-size: 14px;
      line-height: 1;
      display: inline-block;
      margin-right: 6px;
      color: #999;
      vertical-align: middle;
    }
  }
  .task-input {
    outline: $main-color;
    margin-right: 52px;
    height: 40px;
    padding: 9px 12px;
    box-sizing: border-box;
    border: 1px solid #0080ff;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
:-ms-input-placeholder {
  color: #bbb;
}
::-webkit-input-placeholder {
  color: #bbb;
}
:-moz-placeholder {
  color: #bbb;
}
::placeholder {
  color: #bbb;
}

// 显示搜索结果的地方
.search-result-box {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  .search-result {
    padding-left: 0;
  }
  .member-item {
    padding-left: 20px;
    margin-bottom: 20px;
  }
}

// .focus-btn {
//   display: none;
// }

.fellow-item:hover {
  .focus-btn {
    display: block;
  }
}

.clerk-list-box {
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 60px - 24px - 42px - 20px - 20px - 48px - 16px - 5px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 12px;
  }
  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  // &::-webkit-scrollbar-thumb:window-inactive {
  //   background: rgba(255, 0, 0, 0.4);
  // }
}

// 数据的主要部分容器,固定最大高度
.data-main-box {
  width: 100%;
  height: calc(100vh - 60px - 24px - 42px - 28px);
  overflow-y: auto;
}

// 为了防止body滚动，去掉body的padding，改为margin
// body {
//   margin-top: 60px;
//   padding: 0 0 0;
//   height: calc(100vh - 60px);
//   overflow: hidden;
// }

.right-box {
  box-shadow: none;
  background: #fff;
  padding: 0 20px;
  height: auto;
  border-radius: 6px;
}

// 右边盒子的头部
.box-header {
  height: 58px;
  .box-header-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
  }
  .box-header-close {
    width: 26px;
    height: 26px;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    line-height: 26px;
    cursor: pointer;
    background: #fff;
    .iconfont {
      font-size: 20px;
      color: #bbb;
    }
  }
}

.remind-box {
  margin-bottom: 0;
  padding-bottom: 20px;
}
.remind-box .content-box {
  height: calc(100vh - 60px - 24px - 78px);
  overflow-y: auto;
}

.comment-box .content-box {
  height: calc(100vh - 60px - 24px - 78px - 176px);
  overflow-y: auto;
}

.main.el-row {
  margin-top: 0;
  height: calc(100vh - 60px - 24px);
}

// 发表评论的地方
.add-comment-box {
  height: 176px;
  padding: 32px 10px;
  box-sizing: border-box;
  .add-comment-inner {
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #eee;
    border-radius: 6px;
    &.is-edit {
      border-color: $main-color;
    }
  }
  .comment-textarea {
    width: 100%;
    height: 74px;
    border: none;
    outline: none;
    resize: none;
    padding: 10px 14px;
    box-sizing: border-box;
    line-height: 1.5;
    color: #333;
    background: transparent;
  }
  .send-btn {
    width: 60px;
    height: 26px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: 5px;
    font-size: 12px;
    color: #fff;
    background: $main-color;
    border-radius: 4px;
  }
}

::-webkit-input-placeholder {
  color: #bbb;
}
:-moz-placeholder {
  color: #bbb;
}
:-ms-input-placeholder {
  color: #bbb;
}
::placeholder {
  color: #bbb;
}

// 把所有、未完成、已完成并排显示
.choose-task-item.choose-item-inline {
  margin: 0 10px;
  cursor: pointer;
  color: #999;
  &:hover,
  &.active {
    color: $main-color;
  }
}

// 没有内容的提示样式
.no-data-box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #fff;
}

@media screen and (max-width: 1200px) {
  .main-container.el-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .task-group-box {
    margin-bottom: 10px !important;
  }
  .home-container {
    height: 100vh;
  }
  .data-main-box {
    background-color: #f6f6f6 !important;
  }

  .main-container.el-container {
    padding: 0 !important;
    height: calc(100vh - 62px);

    .menu-box {
      margin-bottom: 0;

      .mobile-tab {
        width: 100vw;
        justify-content: space-around;

        .option-item {
          position: relative;
          display: inline-block;
          flex: 1;
          text-align: center;
          margin-left: 0;
        }

        .option-item:first-child {
          border-right: 1px solid #f5f5f5 !important;
        }
      }
    }

    .data-main-box {
      height: calc(100vh - 105px);
    }
  }
}
</style>

<style lang="scss">
body {
  overflow-y: hidden !important;
}
</style>
