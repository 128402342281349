<template>
  <!-- 在线文档编辑页面 -->
  <div class="online-document-editor flex flex-y-positive flex-y-center">
    <div class="document-editor-header flex flex-y-center flex-x-between">
      <i class="icon-a-arrowaltdown" @click.stop="$emit('close')"></i>
      <button class="send" @click.stop="confirm">发布</button>
    </div>
    <div class="document-editor-content flex flex-x-between">
      <div class="input-box">
        <el-input
          v-model="myTitle"
          placeholder="输入标题名称"
          style="margin-bottom: 20px"
        ></el-input>
        <div class="document-input">
          <div
            ref="content"
            v-outline="{
              callback: refreshNavTree,
              selectors: selectors,
              exceptSelector
            }"
            class="content"
          >
            <div v-html="myDescribe" v-show="false"></div>
          </div>
          <online-document-editor
            ref="editor"
            :value="myDescribe"
            @input="changeDescribe"
          ></online-document-editor>
        </div>
      </div>
      <div class="catalogue">
        <div class="title">目录</div>
        <div class="catelog-content">
          <outline-tree :treeData="treeData" :selectors="selectors" class="tree" ref="tree">
            <template v-slot:default="{ data }">
              <div
                class="node-render-content"
                @click.stop="scrollIntoView(data.el)"
              >
                <span>{{ data.title }}</span>
              </div>
            </template>
          </outline-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 调用编辑器组件
import OnlineDocumentEditor from '@/components/knowledge/OnlineDocumentEditor1.vue'

export default {
  name: 'online-document',
  components: {
    OnlineDocumentEditor
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    describe: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: '',
      myTitle: '',
      // 目录树形结构
      exceptSelector: '[un-nav]',
      treeData: [],
      selectors: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      myDescribe: ''
    }
  },
  watch: {
    title(val) {
      this.myTitle = val
    },
    describe(val) {
      console.log(this.$refs.editor.setContent(val))
      this.myDescribe = val
      this.$refs.editor.setContent(val)
    }
  },
  methods: {
    // 目录树形结构更新
    refreshNavTree(treeData) {
      this.treeData = treeData
    },
    scrollIntoView(el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    },
    confirm() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (this.myTitle === '') {
        errmsg('请输入名称')
        return
      }
      const content = this.$refs.editor.getContent()
      if (content.text === '') {
        errmsg('知识库内容不可为空')
        return
      }
      this.$emit('confirm', {
        title: this.myTitle,
        content_html: content.html,
        content_text: content.text
      })
    },
    changeDescribe(val) {
      this.myDescribe = val
    }
  },
  mounted() {
    this.myTitle = this.title
    this.myDescribe = this.describe
  }
}
</script>

<style lang="scss" scoped>
.online-document-editor {
  width: 100%;
  height: 100%;
  background-color: #fff;
  // 在线文档编辑头部样式
  .document-editor-header {
    flex-shrink: 0;
    width: 100%;
    height: 70px;
    padding: 0 34px 0 34px;
    box-sizing: border-box;
    .icon-a-arrowaltdown {
      transform: rotate(180deg);
      font-size: 32px;
      color: #999999;
      cursor: pointer;
    }
    .send {
      cursor: pointer;
      width: 84px;
      height: 34px;
      background: #0080ff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 34px;
      text-align: center;
      color: #ffffff;
    }
  }
  // 在线文档编辑输入内容样式
  .document-editor-content {
    margin: 12px auto 0 auto;
    width: 1058px;
    .input-box {
      .document-input ::v-deep {
        .ck-editor__editable {
          ol,
          ul {
            list-style: decimal;
            padding-left: 30px;
          }
        }
        h1,
        h2,
        h3,
        h4,
        h4,
        h5,
        h6 {
          padding-top: 5px;
        }
        body,
        dl,
        dt,
        dd,
        ul,
        ol,
        li,
        pre,
        form,
        fieldset,
        input,
        p,
        blockquote,
        th,
        td {
          padding-top: 5px;
        }
      }
      width: 750px;
    }
    .catalogue {
      width: 180px;
      margin-top: 37px;
      .title {
        width: 180px;
        border-bottom: 1px solid #eeeeee;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        box-sizing: border-box;
        padding-bottom: 10px;
      }
      .catelog-content {
        width: 180px;
        height: 600px;
        margin-top: 12px;
        font-size: 14px;
        line-height: 21px;
        overflow: scroll;
        color: #333333;
      }
    }
  }
}

.input-box::v-deep .el-input {
  .el-input__inner {
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
}

.input-box::v-deep .quill-editor {
  margin-top: 24px;
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-direction,
  .ql-script,
  .ql-font,
  .ql-size {
    display: none;
  }
  .ql-toolbar.ql-snow {
    text-align: left;
    height: 40px;
    border: none;
  }
  .ql-formats {
    margin: 0;
  }
}

.catelog-content::v-deep .node-render-content {
  margin-top: 10px;
  cursor: pointer;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    color: #0080ff;
  }
}
</style>
