<template>
  <div class="knowledge-container">
    <el-tabs v-model="current">
      <el-tab-pane label="团队知识库">
        <knowledge-team></knowledge-team>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import KnowledgeTeam from '@/components/system/KnowledgeTeam.vue'
export default {
  components: {
    KnowledgeTeam
  },
  data() {
    return {
      current: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.knowledge-container {
  position: relative;
  width: 100%;
  height: 100%;
  .el-tabs::v-deep {
    .el-tabs__nav-wrap {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .el-tab-pane {
      padding: 9px 30px 0;
      height: calc(100vh - 110px);
    }
  }
}
</style>
