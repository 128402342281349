var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",class:{
    xs: _vm.isXs,
    sm: _vm.isSm,
    md: _vm.isMd,
    lg: _vm.isLg,
    xl: _vm.isXl
  }},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"total",attrs:{"span":[24, 24, 6, 6, 6][_vm.widthTypeIndex]}},[_c('el-row',{staticClass:"flex flex-wrap",class:['', '', 'flex-y-between', 'flex-y-between', 'flex-y-between'][
            _vm.widthTypeIndex
          ],attrs:{"type":"flex","gutter":10}},[_c('el-col',{attrs:{"span":[12, 12, 24, 24, 24][_vm.widthTypeIndex]}},[_c('el-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("本月目标 / 总目标")]),_c('el-popover',{attrs:{"placement":"top","width":"300","trigger":"click"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',{staticClass:"tip-text"},[_vm._v(" 本月目标：截止时间、完成时间在本月+截止时间在本月前未完成的延期目标 ")]),_c('p',{staticClass:"tip-text"},[_vm._v(" 总目标：本月目标+进行中的目标（截止时间在本月之后或未设置截止时间的目标） ")])]},proxy:true},{key:"reference",fn:function(){return [_c('i',{staticClass:"iconfont icon-xinxi"})]},proxy:true}])})]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.reportData.objective_cycle_total_number || 0)+"/"+_vm._s(_vm.reportData.objective_total_number || 0))]),_c('span',{staticClass:"right"},[_vm._v("较上周期："),(_vm.hasPrevReportData)?_c('span',{staticClass:"right-number",class:{
                    up: _vm.objectiveTotalNumberComparePrev > 0,
                    down: _vm.objectiveTotalNumberComparePrev < 0
                  }},[_vm._v(_vm._s(_vm.objectiveTotalNumberComparePrev)+"%")]):_vm._e()])]},proxy:true}])})],1),_c('el-col',{attrs:{"span":[12, 12, 24, 24, 24][_vm.widthTypeIndex]}},[_c('el-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("新增目标")])]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.reportData.objective_create_number || 0))]),_c('span',{staticClass:"right"},[_vm._v("较上周期："),(_vm.hasPrevReportData)?_c('span',{staticClass:"right-number",class:{
                    up: _vm.objectiveCreateNumberComparePrev > 0,
                    down: _vm.objectiveCreateNumberComparePrev < 0
                  }},[_vm._v(_vm._s(_vm.objectiveCreateNumberComparePrev)+"%")]):_vm._e()])]},proxy:true}])})],1),_c('el-col',{attrs:{"span":[12, 12, 24, 24, 24][_vm.widthTypeIndex]}},[_c('el-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("延期目标")])]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.reportData.objective_delay_number || 0))]),_c('span',{staticClass:"right"},[_vm._v("较上周期："),(_vm.hasPrevReportData)?_c('span',{staticClass:"right-number",class:{
                    up: _vm.objectiveDelayNumberComparePrev > 0,
                    down: _vm.objectiveDelayNumberComparePrev < 0
                  }},[_vm._v(_vm._s(_vm.objectiveDelayNumberComparePrev)+"%")]):_vm._e()])]},proxy:true}])})],1),_c('el-col',{attrs:{"span":[12, 12, 24, 24, 24][_vm.widthTypeIndex]}},[_c('el-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("完成目标")])]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.reportData.objective_complete_number || 0))]),_c('span',{staticClass:"right"},[_vm._v("完成率："),_c('span',{staticClass:"right-number"},[_vm._v(_vm._s(_vm.objectiveCompleteRate)+"%")])])]},proxy:true}])})],1)],1)],1),_c('el-col',{attrs:{"span":[24, 24, 9, 9, 9][_vm.widthTypeIndex]}},[_c('el-card',{staticClass:"chart",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("本月目标概况")])]},proxy:true},{key:"default",fn:function(){return [_c('div',{ref:"BZMBGK"})]},proxy:true}])})],1),_c('el-col',{attrs:{"span":[24, 24, 9, 9, 9][_vm.widthTypeIndex]}},[_c('el-card',{staticClass:"chart",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("完成进度概况")]),_c('el-popover',{attrs:{"placement":"top","width":"260","trigger":"click"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',{staticClass:"tip-text"},[_vm._v("完成进度概况：总目标的的完成进度占比")])]},proxy:true},{key:"reference",fn:function(){return [_c('i',{staticClass:"iconfont icon-xinxi"})]},proxy:true}])})]},proxy:true},{key:"default",fn:function(){return [_c('div',{ref:"WCJDGK"})]},proxy:true}])})],1),_c('el-col',{attrs:{"span":24}},[_c('el-card',{staticClass:"chart",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("完成进度明细（"+_vm._s(_vm.totalNumber)+"）")])]},proxy:true},{key:"default",fn:function(){return [_c('div',{ref:"WCJDMX"})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }