<!--
 * @Author: hzh
 * @Date: 2021-09-28 09:45:57
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-30 17:54:25
-->
<template>
  <div style="position: relative">
    <div
      class="edit-select"
      @click.stop="
        clickBody()
        currentClickBodyIndex = clickBodyIndex
        handeShowMenu()
      "
      ref="element"
    >
      {{ menuList[myActive] != null ? menuList[myActive].title : ''
      }}<i class="icon-zhankai"></i>
    </div>
    <el-collapse-transition>
      <div v-if="show" id="edit-menu" :style="{ top: height }">
        <edit-menu-item
          v-for="(item, index) in menuList"
          :key="index"
          :title="item.title"
          :is-active="myActive === index"
          :info="item.info"
          :disable="item.disable"
          @change="handleSelect(index)"
        ></edit-menu-item>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import EditMenuItem from './EditMenuItem.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    EditMenuItem
  },
  props: {
    active: {
      type: Number,
      default: 0
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      myActive: 0,
      height: 0,
      pageY: 0,
      l: 0,
      // menuList: [
      //   {
      //     title: '管理员',
      //     info: '可修改成员权限及编辑文件',
      //     value: 4
      //   },
      //   {
      //     title: '仅查看',
      //     info: '可查看、不可下载文件',
      //     value: 1
      //   },
      //   {
      //     title: '可下载',
      //     info: '可查看、下载文件',
      //     value: 2
      //   },
      //   {
      //     title: '可编辑',
      //     info: '可编辑、更新、查看、下载文件',
      //     value: 3
      //   }
      // ],
      currentClickBodyIndex: -1
    }
  },
  computed: {
    ...mapState(['clickBodyIndex', 'mouseWheelIndex'])
  },
  methods: {
    ...mapMutations(['clickBody']),
    handeShowMenu(e) {
      this.show = !this.show
      this.height = this.$refs.element.getBoundingClientRect().top + 25 + 'px'
      console.log(this.height, 1)
    },
    handleSelect(e) {
      this.show = false
      this.myActive = e
      this.$emit('change', e)
    },
    handleScrollx() {
      this.show = false
      this.pageY = this.$refs.element.getBoundingClientRect().top
    }
  },
  mounted() {
    // document.addEventListener('mousedown', e => {
    //   if (this.show) {
    //     this.show = false
    //   }
    // })
    // window.addEventListener('scroll', this.handleScrollx, true)
    this.height = this.$refs.element.offsetHeight
    this.myActive = this.active
    console.log(this.height)
  },
  destroyed() {
    // document.removeEventListener('mousedown')
  },
  watch: {
    mouseWheelIndex(val) {
      if (this.currentClickBodyIndex !== val) {
        this.show = false
      }
    },
    clickBodyIndex(val) {
      if (this.currentClickBodyIndex !== val) {
        this.show = false
      }
    },
    active(val) {
      if (val != null) {
        this.myActive = val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#edit-menu {
  position: fixed;
  width: 278px;
  right: 110px;
  background-color: #fff;
  z-index: 2;
  box-shadow: rgb(181 176 176 / 20%) 0px 0px 8px 1px;
}

.edit-select {
  position: relative;
  cursor: pointer;
  padding: 4px 7px;
  margin-right: 9px;
  border-radius: 4px;
  i {
    margin-left: 13px;
  }

  &:hover {
    background-color: #ececec;
  }
}
</style>
