<!--
 * @Date: 2021-06-16 19:33:34
 * @LastEditTime: 2021-06-22 20:23:35
 * @Description: 上传文件的进度条组件
 * @Author: Betty
 * @LastEditors: Betty
-->
<template>
  <!-- 进度条组件 -->
  <div class="upload-progress-box">
    <!-- 头部 -->
    <div class="header flex flex-y-center flex-x-between">
      <h6 class="title e">正在上传文件：{{ uploadFileName }}</h6>
      <button class="close" type="button" @click="cancelUpload">
        <svg class="icon close" aria-hidden="true">
          <use
            xlink:href="#icon-guanbi"
          ></use>
        </svg>
      </button>
    </div>
    <!-- 展示进度条 -->
    <el-progress :percentage="uploadProgress" class="#0080FF"></el-progress>
  </div>
</template>

<script>
export default {
  name: 'upload-progress',
  props: {
    // 正在上传的文件名
    uploadFileName: {
      type: String
    },
    // 上传进度
    uploadProgress: {
      type: Number,
      min: 0,
      max: 100
    },
    // 下标
    index: {
      type: Number
    },
    // 接收取消函数
    cancel: {
      require: true
    }
  },
  watch: {
    // 监听文件的上传进度，如果发现已经是100%了，要通知父组件删除这个弹窗
    uploadProgress(newValue) {
      if (newValue === 100) {
        this.$emit('close-upload-progress', this.index)
      }
    }
  },
  methods: {
    cancelUpload() {
      this.$util.showConfirm('确定要取消上传吗？').then((result) => {
        // 如果确实要上传，调用自己的cancel方法
        if (result) {
          this.cancel()
          // 关闭弹窗
          this.$emit('close-upload-progress', this.index)
        }
      })
    }
  }
}
</script>

<style lang="scss">
// 上传文件的进度条
.upload-progress-box {
  width: 400px;
  margin-top: 10px;
  padding: 0 20px 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  // 头部
  .header {
    height: 46px;
    .title {
      max-width: 80%;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.5;
      margin: 0;
      padding: 0;
    }
  }
  .close {
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    box-sizing: border-box;
  }
}
</style>
