<template>
  <div class="super-admin">
    <div class="super-admin_header flex flex-x-between flex-y-center">
      <p class="push-tips">
        管理员根据权限组授权，可管理后台及Cloud Center的部分功能
      </p>
    </div>
    <div class="action-bar flex flex-x-between">
      <el-input
        prefix-icon="el-icon-search"
        style="width: 280px"
        size="small"
        placeholder="请输入姓名/部门查找"
        v-model="searchKey"
        @input="handleChangeSearchKey"
      ></el-input>
      <el-button
        type="primary"
        size="small"
        @click="addVisible = true"
        v-if="perInfo.add"
        >添加管理员</el-button
      >
    </div>
    <div class="people-list">
      <div class="table-header flex flex-x-between">
        <div class="flex1">姓名</div>
        <div style="width: 20%">权限组</div>
        <div style="width: 30%">权限配置</div>
        <div style="width: 120px">操作</div>
      </div>
      <div class="table-main">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="table-item flex flex-x-between flex-y-center"
        >
          <div class="flex flex-y-center flex1">
            <el-image :src="item.clerk_avatar">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <p class="text-clamp-1">
              {{ item.clerk_alias || item.clerk_name }} /
              <span style="color: #bbb">{{ item.main_dept_name }}</span>
            </p>
          </div>
          <div style="width: 20%">
            {{ item.per_group_list.map((e) => e.per_group_name).join('、') }}
          </div>
          <div style="width: 30%">
            权限：{{
              getPerList(item.per_id_list)
                .map((e) => e.name)
                .join('、')
            }}
          </div>
          <div style="width: 120px">
            <el-button
              type="text"
              size="mini"
              @click="editId = item.clerk_id"
              v-if="perInfo.edit"
              >编辑</el-button
            >
            <el-button
              style="color: red"
              type="text"
              size="mini"
              @click="
                currentDeleteInfo = item
                deleteVisible = true
              "
              v-if="perInfo.delete && item.clerk_id !== myClerkId"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; position: absolute; bottom: 22px">
      <el-pagination
        background
        layout="prev, pager, next"
        hide-on-single-page
        @current-change="
          pageNumber = $event
          getPage()
        "
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :page-count="totalPage"
      >
      </el-pagination>
    </div>
    <!-- 添加 -->
    <add-admin
      v-if="addVisible"
      @close="addVisible = false"
      @confirm="addClerk"
    ></add-admin>
    <!-- 修改 -->
    <add-admin
      v-if="editId != -1"
      @close="editId = -1"
      @confirm="editClerk"
      :info="currentEditInfo"
    ></add-admin>
    <!-- 删除 -->
    <el-dialog
      title="移除超级管理员"
      :visible.sync="deleteVisible"
      width="300px"
    >
      <span
        >确定要将“{{
          currentDeleteInfo.clerk_alias || currentDeleteInfo.clerk_name
        }}”从超级管理员中移除吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteClerk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddAdmin from './AddAdmin.vue'
import { perTree } from '@/util/permission.js'
import { mapState } from 'vuex'
export default {
  components: {
    AddAdmin
  },
  data() {
    return {
      perTree: perTree,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
      list: [],
      searchKey: '',
      searchTimer: null,
      // 添加展示
      addVisible: false,
      // 删除展示
      deleteVisible: false,
      // 当前删除
      currentDeleteInfo: {},
      // 修改id
      editId: -1
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    perInfo() {
      return {
        add: this.$hasPer(73),
        edit: this.$hasPer(74),
        delete: this.$hasPer(75)
      }
    },
    currentEditInfo() {
      if (this.editId === -1) {
        return {}
      }
      const info = this.list.find((e) => e.clerk_id === this.editId)
      return {
        clerk_id: info.clerk_id,
        clerk_name: info.clerk_alias || info.clerk_name,
        per_id_list: info.per_id_list,
        per_group_id_list: info.per_group_list.map((e) => e.per_group_id)
      }
    },
    perTreeList() {
      const l = []
      const add = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            l.push({
              id: e.id,
              name: e.name,
              per_list: e.perList
            })
          }
          if (e.children) {
            add(e.children)
          }
        })
      }
      this.perTree.forEach((e) => {
        add(e.children)
      })
      return l
    }
  },
  methods: {
    // 获取包含的列表
    getPerList(list) {
      if (list.length === 0) {
        return []
      }
      return this.perTreeList.filter((e) => {
        const perList = e.per_list
        if (perList.length > list.length) {
          return false
        }

        for (const per of perList) {
          if (!list.includes(per)) {
            return false
          }
        }
        return true
      })
    },
    // 获取列表
    async getPage() {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        search_key: this.searchKey
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkAdminNormalPage, formObj)
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        this.pageNumber = page.pageNumber
        this.totalPage = page.totalPage
        this.totalRow = page.totalRow
        this.list = page.list
      }
    },
    // 刷新列表
    async refreshPage() {
      this.pageNumber = 1
      this.getPage()
    },
    // 删除职员
    async deleteClerk() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemClerkNormalAdmin, {
          clerk_id: this.currentDeleteInfo.clerk_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.currentDeleteInfo = {}
        this.deleteVisible = false
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        this.refreshPage()
      }
    },
    // 添加
    async addClerk(e) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addSystemClerkNormalAdmin, {
          clerk_id: e.clerk_id,
          per_ids: e.per_list.join(),
          per_group_ids: e.per_group_list.join()
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        this.addVisible = false
        this.refreshPage()
      }
    },
    // 修改
    async editClerk(e) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editSystemClerkNormalAdmin, {
          clerk_id: this.editId,
          per_ids: e.per_list.join(),
          per_group_ids: e.per_group_list.join()
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
        this.editId = -1
        this.refreshPage()
      }
    },
    // 监听修改搜索关键字
    handleChangeSearchKey() {
      if (this.searchTimer != null) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.refreshPage()
      }, 500)
    }
  },
  mounted() {
    this.getPage()
  }
}
</script>

<style lang="scss" scoped>
.super-admin {
  height: 100%;
  .super-admin_header {
    margin-bottom: 20px;
    .push-tips {
      color: #bbb;
    }
  }

  .action-bar {
    margin-bottom: 38px;
    // .el-input::v-deep {
    //   .el-input__inner {
    //     border: 1px solid #eee;
    //   }
    // }
  }

  .people-list {
    .table-header {
      margin-bottom: 15px;
    }
    .table-item {
      height: 66px;

      &:hover {
        background: #f6f6f6;
      }

      .el-image {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 16px;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
      }

      .delete-button {
        cursor: pointer;
        width: 68px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #ff5454;
        border-radius: 4px;

        &:hover {
          background: rgba(255, 84, 84, 0.1);
        }
      }
    }
  }
}

.el-pagination {
  text-align: center;
}
</style>
