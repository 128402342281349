<template>
  <div>
    <div class="member-info">
      <i class="icon-decline close-button" @click="$emit('close')"></i>
      <div class="member-content">
        <div class="flex flex-x-between flex-y-center member-title">
          <p>成员详情</p>
          <div>
            <el-button
              size="small"
              v-if="perInfo.positive && clerkType == 2"
              @click="batchPositive"
              >员工转正</el-button
            >
            <el-button
              size="small"
              v-if="perInfo.probation && clerkType == 1"
              @click="batchProbation"
              >员工转试用</el-button
            >
            <el-button
              size="small"
              type="danger"
              plain
              @click="departureVisible = true"
              v-if="perInfo.leave && info.is_leave === 0"
              >操作离职</el-button
            >
          </div>
        </div>
        <div class="info-container">
          <!-- 基本信息 -->
          <div class="basic-info">
            <div class="title-bar">基本信息</div>
            <div class="flex flex-x-between flex-y-end">
              <el-image :src="info.clerk_avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div class="flex flex-y-center">
                <span style="font-size: 14px; color: #333">人员类型：</span>
                <div class="status-button" :class="{ active: clerkType == 1 }">
                  {{ clerkTypeName }}
                </div>
              </div>
            </div>
            <div class="basic-content line-bottom">
              <div class="flex flex-x-between form-container">
                <form-item lable="姓名" :value="info.clerk_name"></form-item>
                <form-item lable="别名" :value="info.clerk_alias"></form-item>
              </div>
              <div class="flex flex-x-between form-container">
                <form-item
                  lable="用户ID"
                  :value="info.wechat_qy_user_id"
                ></form-item>
                <form-item lable="性别" :value="clerkGenderName"></form-item>
              </div>
              <div class="flex flex-x-between form-container">
                <form-item
                  lable="手机号"
                  :value="info.clerk_mobile"
                ></form-item>
                <form-item lable="邮箱" :value="info.clerk_email"></form-item>
              </div>
            </div>
          </div>
          <!-- 工作信息 -->
          <div class="basic-info work-info">
            <div class="title-bar">工作信息</div>
            <div class="basic-content line-bottom">
              <div class="flex flex-x-between form-container">
                <form-item
                  lable="部门"
                  :value="deptListNameList.join('; ')"
                ></form-item>
              </div>
              <div class="flex flex-x-between form-container">
                <form-item
                  lable="主部门"
                  :value="info.main_dept_name"
                ></form-item>
                <form-item
                  lable="直属上级"
                  :value="mainDeptLeaderNameList.join('; ')"
                ></form-item>
              </div>
              <div class="flex flex-x-between form-container">
                <form-item
                  lable="身份"
                  :value="leaderDeptNameList.length > 0 ? '上级' : '普通成员'"
                ></form-item>
                <form-item
                  lable="职务"
                  :value="info.clerk_position"
                ></form-item>
              </div>
              <div class="flex flex-x-between form-container">
                <form-item
                  lable="负责部门"
                  :value="leaderDeptNameList.join('; ')"
                ></form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 操作离职弹窗 -->
    <departure-dialog
      :visible="departureVisible"
      @close="departureVisible = false"
      :info="{
        main_dept_name: info.main_dept_name,
        clerk_alias: info.clerk_alias,
        clerk_name: info.clerk_name,
        clerk_avatar: info.clerk_avatar,
        clerk_id: clerkId
      }"
      @success="
        departureVisible = false
        getDetail()
      "
    ></departure-dialog>
  </div>
</template>

<script>
import FormItem from './FormItem.vue'
import DepartureDialog from '@/components/system/DepartureDialog.vue'
export default {
  components: {
    FormItem,
    DepartureDialog
  },
  props: {
    clerkId: {
      type: Number
    }
  },
  data() {
    return {
      info: {},
      // 离职展示
      departureVisible: false
    }
  },
  computed: {
    perInfo() {
      return {
        leave: this.$hasPer(48),
        positive: this.$hasPer(47)
      }
    },
    // 职员类型
    clerkType() {
      return this.info.clerk_type
    },
    // 职员类型名称
    clerkTypeName() {
      switch (this.clerkType) {
        case 1:
          return '正式员工'
        case 2:
          return '试用员工'
      }
      return ''
    },
    // 性别名称
    clerkGenderName() {
      switch (this.info.clerk_gender) {
        case 1:
          return '男'
        case 2:
          return '女'
      }
      return ''
    },
    // 负责部门名称列表
    leaderDeptNameList() {
      if (!this.info.leader_dept_list) {
        return []
      }
      return this.info.leader_dept_list.map((e) => e.dept_name)
    },
    // 所在部门名称列表
    deptListNameList() {
      if (!this.info.dept_list) {
        return []
      }
      return this.info.dept_list.map((e) => e.dept_name)
    },
    // 直属上级名称
    mainDeptLeaderNameList() {
      if (!this.info.main_dept_leader_list) {
        return []
      }
      return this.info.main_dept_leader_list.map(
        (e) => e.clerk_alias || e.clerk_name
      )
    }
  },
  methods: {
    // 获取详情
    async getDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemClerkDetail, {
          clerk_id: this.clerkId
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.info = res.info
      }
    },
    // 批量转正
    async batchPositive() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.systemClerkBatchPositive, {
          clerk_ids: this.clerkId
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '转正成功',
          type: 'success'
        })
        this.getDetail()
      }
    },
    // 批量转正
    async batchProbation() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.systemClerkBatchProbation, {
          clerk_ids: this.clerkId
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '转试用成功',
          type: 'success'
        })
        this.getDetail()
      }
    }
  },
  mounted() {
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
.member-info {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #fff;
  z-index: 1;

  .close-button {
    position: fixed;
    right: 54px;
    top: 146px;
    z-index: 2;
  }

  .member-content {
    width: 840px;
    margin: 28px auto;
    height: 900px;

    .member-title {
      margin-bottom: 30px;
      p {
        position: relative;
        padding-left: 10px;
        font-weight: bold;
        &::before {
          position: absolute;
          content: '';
          top: 50%;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: $main-color;
          transform: translateY(-50%);
        }
      }
    }

    .info-container {
      padding-left: 10px;
      padding-bottom: 128px;
      .title-bar {
        margin-bottom: 16px;
      }

      .basic-info {
        .el-image {
          display: flex;
          width: 60px;
          height: 60px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
        }

        span {
          font-size: 16px;
          color: #bbb;
          margin-right: 12px;
        }

        .status-button {
          width: 80px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          color: #fff;
          background-color: #38b7ff;
          border-radius: 25px;
          &.active {
            background-color: #ffa438;
          }
        }

        &.work-info {
          padding-top: 40px;
          .basic-content {
            padding-top: 20px;
            .form-container:last-child,
            .form-container:first-child {
              .form-item {
                width: 100%;
              }
            }
          }
        }
      }

      .basic-content {
        padding-top: 30px;
        padding-bottom: 40px;

        .form-container {
          margin-bottom: 20px;
          .form-item {
            width: calc(50% - 13px);
          }
        }
      }
    }
  }
}
</style>
