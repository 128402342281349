<!--
 * @Date: 2021-06-24 11:15:23
 * @LastEditTime: 2021-07-21 10:16:03
 * @Description: 附件组件
 * @Author: Betty
 * @LastEditors: Betty
-->
<template>
  <div class="appendix-item flex">
    <!-- 左边，图标 -->
    <div class="icon-box">
      <!-- 是图片就显示图片的内容 -->
      <img
        :src="getFileIcon(appendix)"
        alt=""
        class="icon"
        @click="imageChgange($event)"
        v-if="isPic(appendix)"
      />
      <!-- 否则显示文件类型的图标 -->
      <svg class="icon" aria-hidden="true" v-else>
        <use :xlink:href="getFileIcon(appendix)"></use>
      </svg>
    </div>
    <!-- 右边，文字信息 -->
    <div class="flex1 flex flex-y-positive flex-x-between">
      <p
        class="name e"
        :title="appendix.file_original_name"
        @click="toPreview"
        :style="{ cursor: isAllowPreview ? 'pointer' : 'default' }"
      >
        {{ appendix.file_original_name }}
      </p>
      <p class="size">{{ appendix.file_size_readable }}</p>
      <div class="flex flex-y-center flex-x-between">
        <p class="from e" :title="appendix.clerk_alias || appendix.clerk_name">
          来自 {{ appendix.clerk_alias || appendix.clerk_name }}
        </p>
        <div class="flex flex-y-center">
          <!-- 下载按钮 -->
          <a
            :href="appendix.attachment_path"
            target="_top"
            class="btn download-btn"
            v-if="isDirectDownload"
          >
            <span class="iconfont icon-Download"></span>
          </a>
          <a
            target="_top"
            class="btn download-btn"
            v-if="!isDirectDownload"
            @click="handleDownload"
          >
            <span class="iconfont icon-Download"></span>
          </a>
          <!-- 删除按钮 -->
          <button
            type="button"
            class="btn delete-btn"
            v-if="isDeletable"
            @click="removeAttachment(appendix)"
          >
            <span class="iconfont icon-Delete"></span>
          </button>
        </div>
      </div>
    </div>
    <el-image
      ref="previewImageRef"
      :preview-src-list="[previewImageSrc]"
      v-show="false"
    ></el-image>
  </div>
</template>

<script>
export default {
  name: 'attachment-item',
  data() {
    return {
      // 图片预览
      previewImageSrc: ''
    }
  },
  props: {
    // 附件信息
    appendix: {
      type: Object
    },
    // 任务id
    taskId: {
      type: Number
    },
    // 会议id
    meetingId: {
      type: Number
    },
    // 目标id
    targetId: {
      type: Number
    },
    // 通知id
    noticeId: {
      type: Number
    },
    // 附件类型
    fileType: {
      type: String,
      default: 'task'
    },
    // 是否是任务详情页的附件
    isMeetingDetail: {
      type: Boolean,
      default: false
    },
    // 是否可以删除
    isDeletable: {
      type: Boolean,
      default: true
    },
    isDirectDownload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isAllowPreview() {
      return ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'pptx'].includes(
        this.appendix.file_ext
      )
    },
    // 判断是否是图片
    isPic() {
      return function (file) {
        // 判断文件的扩展名是否是图片扩展名注意
        const type = file.file_ext
        const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
        return imgTypes.includes(type)
      }
    },
    // 获取文件的图标
    getFileIcon() {
      return function (file) {
        const type = file.file_ext
        switch (type) {
          // 如果是doc文件
          case 'doc':
          case 'docx':
            return '#icon-Frame3'
          // 如果是PDF
          case 'pdf':
            return '#icon-Frame4'
          // 如果是Excel
          case 'xls':
          case 'xlsx':
            return '#icon-Frame2'
          // 如果是图片，返回图片本身
          case 'jpg':
          case 'jpeg':
          case 'bmp':
          case 'png':
          case 'gif':
            return file.attachment_path
          // 如果是PPT
          case 'ppt':
          case 'pptx':
            return '#icon-Frame5'
          // 如果是TXT
          case 'txt':
            return '#icon-Frame12'
          // 如果是其他
          default:
            return '#icon-Frame21'
        }
      }
    }
  },
  methods: {
    // 图片预览
    imageChgange(e) {
      console.log(e.target)
      this.previewImageSrc = e.target.src
      this.$refs.previewImageRef.showViewer = true
    },
    // 跳转附件
    toPreview() {
      if (!this.isAllowPreview) {
        return
      }
      let str = ''
      switch (this.fileType) {
        case 'task':
          str += this.taskId
          break
        case 'meeting':
          str += this.meetingId
          break
        case 'meetingSpeak':
          str += this.meetingId
          break
        case 'target':
          str += this.targetId
          break
        case 'notice':
          str += this.noticeId
          break
      }
      window.open(
        this.$router.resolve({
          name: 'AttachmentPreview',
          params: {
            attachmentType: this.fileType + '_' + str,
            attachmentId: this.appendix.attachment_id
          }
        }).href,
        '_blank'
      )
    },
    handleDownload() {
      this.$emit('download', this.appendix)
    },
    // 删除附件
    removeAttachment(file) {
      if (this.fileType === 'notice') {
        this.$emit('to-delete', file.attachment_id)
        return
      }
      let link = ''
      const formObj = {
        attachment_id: file.attachment_id
      }
      // 根据附件的类型不同，指定不同的删除接口以及参数
      if (this.fileType === 'task') {
        // 任务附件
        link = this.$api.removeAttachment
        formObj.task_id = this.taskId
      } else if (this.fileType === 'meeting') {
        // 会议附件
        link = this.$api.deleteMeetingAttachment
        formObj.meeting_id = this.meetingId
      } else if (this.fileType === 'meetingSpeak') {
        // 会议发言附件
        link = this.$api.deleteMeetingSpeakAttachment
        formObj.meeting_id = this.meetingId
      } else if (this.fileType === 'target') {
        // 目标附件
        link = this.$api.deleteTargetAttachment
        formObj.objective_id = this.targetId
      }
      this.$util.showConfirm('确定要删除这个文件吗？').then((result) => {
        if (result) {
          console.log('准备发请求删除附件1')
          // 确认删除
          if (this.isMeetingDetail === false && this.fileType === 'meeting') {
            // 如果是会议编辑页的删除，只是通知父组件
            this.$emit('to-delete', file.attachment_id)
          } else {
            // 其他情况，直接发请求删除附件
            this.$http
              .post(link, formObj)
              .then((res) => {
                if (res.state === 'ok') {
                  this.$message({
                    showClose: true,
                    message: '删除成功',
                    type: 'success'
                  })
                }
                // 刷新任务列表
                this.$emit('fresh-attachment')
              })
              .catch((e) => {
                console.log(e)
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/attachment_style.scss';
@media screen and (max-width: 768px) {
  .appendix-item {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
.appendix-item:hover .download-btn.btn:hover {
  color: $main-color;
}
.name:hover{
  color: $main-color !important;
}
</style>
