<template>
  <div
    class="message-mask"
    :style="mask ? 'background-color: rgba(51, 51, 51, 0.5);' : ''"
    @click="handleMask"
  >
    <div
      class="message-box"
      :style="{ width: setWidth, top: setTop, height: setHeight }"
      @click.stop=""
    >
      <div
        class="message-box_header flex flex-x-between flex-y-center"
        :class="{ 'line-bottom': showHeaderLine }"
      >
        <div>
          {{ title }}
          <span style="font-size: 12px; color: #999999">{{ explain }}</span>
        </div>
        <div>
          <i @click="$emit('handleClose')" class="icon-decline"></i>
        </div>
      </div>
      <div class="message-main">
        <slot></slot>
      </div>
      <div class="message-box_footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '弹窗'
    },
    explain: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 578
    },
    top: {
      type: [String, Number],
      default: '30%'
    },
    mask: {
      type: Boolean,
      default: false
    },
    showHeaderLine: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    setWidth() {
      const width1 = document.body.clientWidth
      const type = typeof this.width
      return width1 > 1000
        ? type === 'string'
          ? this.width
          : this.width + 'px'
        : 100 + '%'
    },
    setTop() {
      const height1 = document.documentElement.clientHeight
      return height1 > 800 ? this.top : 0
    },
    setHeight() {
      const height1 = document.documentElement.clientHeight
      return height1 > 800 ? 'auto' : 100 + '%'
    }
  },
  methods: {
    handleMask(e) {
      if (this.mask) {
        e.stopPropagation()
        this.$emit('handleMask')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  @media only screen and (min-width: 960px) {
    .message-box {
      border-radius: 6px;
    }
  }
  .message-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    min-height: 210px;
    z-index: 99;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .message-box_header {
      height: 44px;
      padding-left: 28px;
      padding-right: 18px;
      padding-top: 8px;

      &::after {
        left: 0;
      }

      i {
        padding: 4px;
        color: $main-color;
        border-radius: 4px;
        background-color: rgba(0, 128, 255, 0.1);
      }
    }
    @media only screen and (max-width: 1080px) {
      .message-main {
        padding: 0 26px;
        max-height: 100%;
        overflow: scroll;
      }
    }
    @media only screen and (min-width: 1081px) {
      .message-main {
        padding: 0 26px;
        max-height: 605px;
        overflow: scroll;
      }
    }

    .message-box_footer {
      padding: 0 26px;
    }
  }
  .icon-decline {
    cursor: pointer;
  }
}
</style>
