<!--
 * @Author: Betty
 * @Date: 2021-07-03 11:49:59
 * @LastEditors: Betty
 * @LastEditTime: 2021-07-03 11:55:06
 * @Description: 没有数据的提示
-->
<template>
  <div
    class="flex flex-y-positive flex-y-center no-table-data"
    v-if="isShow"
    :style="styleObj"
  >
    <span class="iconfont" :class="[tipIcon]"></span>
    <p class="no-data-text">{{ tipText }}</p>
  </div>
</template>

<script>
export default {
  name: 'no-data',
  props: {
    // 是否展示没有数据的提示
    isShow: {
      type: Boolean,
      default: false
    },
    // 提示文字
    tipText: {
      type: String,
      default: '暂无数据'
    },
    // 图标
    tipIcon: {
      type: String,
      default: 'icon-Frame6'
    },
    // 样式修饰，默认为空对象，有需要就传入
    styleObj: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
.no-table-data {
  @include pos-center(absolute);
  top: 30vh;
  .iconfont {
    display: block;
    line-height: 1;
    margin-bottom: 28px;
    font-size: 114px;
    color: #efefef;
  }
  .no-data-text {
    text-align: center;
    line-height: 1.5;
    color: #bbb;
  }
}
</style>
