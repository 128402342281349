<!--
 * @Author: hzh
 * @Date: 2021-12-31 15:53:17
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-06 19:42:32
-->
<template>
  <el-popover
    :placement="placement"
    width="360"
    :trigger="trigger"
    v-model="myVisible"
  >
    <template #reference>
      <slot></slot>
    </template>
    <template #default>
      <div class="info-box">
        <div class="title">{{ data.meeting_name }}</div>
        <div class="time">
          {{ $util.toDateString(data.start_time, 'yyyy-MM-dd HH:mm') }} 至
          {{ $util.toDateString(data.end_time, 'yyyy-MM-dd HH:mm') }}
        </div>
        <div class="create-clerk flex flex-y-center">
          <span>创建人</span>
          <img
            :src="data.create_clerk_avatar"
            alt="用户头像"
            class="avatar"
          /><span>{{ data.create_clerk_name }} </span>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: 'meetingRoomOccupyMeetingInfoPopover',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    trigger: {
      type: String,
      default: 'click'
    },
    visible: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      myVisible: false
    }
  },
  computed: {},
  methods: {},
  mounted() {},
  watch: {
    visible(val) {
      this.myVisible = val
    },
    myVisible(val) {
      this.$emit('update:visible', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  padding: 10px;
  .title {
    font-weight: 700;
    color: #333333;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  .time {
    margin-bottom: 10px;
  }
  .create-clerk {
    color: #555555;
    font-size: 14px;

    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 5px;
      margin-right: 10px;
    }

    span:first-child {
      margin-right: 10px;
    }
  }
}
</style>
