/*
 * @Author: hzh
 * @Date: 2021-09-01 17:31:40
 * @LastEditors: hzh
 * @LastEditTime: 2021-10-08 15:19:03
 */
/**
 * 权限、角色控制组件
 */
import store from '@/store'

export default {
  install(Vue) {
    // 添加全局方法
    Vue.prototype.$hasAllPer = this.hasAllPer
    Vue.prototype.$hasPer = this.hasPer

    // 添加自定义指令
    Vue.directive('hasAllPer', {
      inserted: (el, binding) => {
        if (!this.hasAllPer(binding.value)) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    })
    Vue.directive('hasPer', {
      inserted: (el, binding) => {
        if (!this.hasPer(binding.value)) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    })
  },
  /**
   * 是否有某些权限
   * @param auth {Number, Array<Number>} 权限字符或字符数组
   * @returns {boolean}
   */
  hasAllPer(auth) {
    const data = store.state.perList
    return arrayHas(data, auth)
  },
  /**
   * 是否有任意权限
   * @param auth {Number, Array<Number>} 权限字符或字符数组
   * @returns {boolean}
   */
  hasPer(auth) {
    const data = store.state.perList
    return arrayHasAny(data, auth)
  }
}

/**
 * 权限树
 */
export const perTree = [{
  id: '1',
  name: '会议、圈子、知识库',
  children: [{
    id: '1-1',
    name: '会议室',
    perList: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40]
  }, {
    id: '1-2',
    name: '圈子',
    perList: [41, 42, 43]
  }, {
    id: '1-3',
    name: '知识库',
    children: [{
      id: '1-3-1',
      name: '培训师',
      perList: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    }, {
      id: '1-3-2',
      name: '公司知识库',
      perList: [2, 3, 4, 5, 6, 7, 8, 27, 29]
    }, {
      id: '1-3-3',
      name: '团队知识库',
      perList: [20, 21, 22, 23, 24, 25, 26, 28, 30, 65, 66, 67, 68, 69]
    }]
  }]
}, {
  id: '2',
  name: '管理中心',
  children: [{
    id: '2-1',
    name: '组织架构',
    perList: [44, 45, 46, 47, 48, 85]
  }, {
    id: '2-2',
    name: '通知公告',
    perList: [49, 50, 51, 52, 53, 54]
  }, {
    id: '2-3',
    name: '圈子推送',
    perList: [55, 56, 57, 58, 59, 60, 61, 62, 63, 64]
  }, {
    id: '2-4',
    name: '权限管理',
    children: [{
      id: '2-4-1',
      name: '管理员',
      perList: [71, 72, 73, 74, 75, 76, 77, 78, 79, 80]
    }, {
      id: '2-4-2',
      name: '团队文件夹',
      perList: [65, 66, 67, 68, 69]
    }]
  }]
}]

/**
 * 数组是否有某些值
 * @param array {Array<Number>} 数组
 * @param obj {Number, Array<Number>} 值
 * @returns {boolean}
 */
function arrayHas(array, obj) {
  if (!obj) {
    return true
  }
  if (!array) {
    return false
  }
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (array.indexOf(Number(obj[i])) === -1) {
        return false
      }
    }
    return true
  }
  return array.indexOf(Number(obj)) !== -1
}

/**
 * 数组是否有任意值
 * @param array {Array<Number>} 数组
 * @param obj {Number, Array<Number>} 值
 * @returns {boolean}
 */
function arrayHasAny(array, obj) {
  if (!obj) {
    return true
  }
  if (!array) {
    return false
  }
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (array.indexOf(Number(obj[i])) !== -1) {
        return true
      }
    }
    return false
  }
  return array.indexOf(Number(obj)) !== -1
}
