<!--
 * @Author: hzh
 * @Date: 2021-09-29 16:29:40
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-29 20:21:14
-->
<template>
  <div class="circle-push">
    <el-tabs v-model="current">
      <el-tab-pane v-if="$hasPer(57)" label="推送内容">
        <push-content
          ref="content"
          @add="openPushContent"
          @openDetail="openDetail"
          @deleteRefresh="deleteRefresh"
        ></push-content>
      </el-tab-pane>
      <el-tab-pane  v-if="$hasPer(62)" label="推送人">
        <push-people></push-people>
      </el-tab-pane>
    </el-tabs>
    <push-content-edit
      v-if="currentEditShow"
      @close="closeEdit"
      @deleteRefresh="deleteRefresh"
      :pushId="pushId"
    ></push-content-edit>
    <push-info
      v-if="currentDetailShow"
      @deleteRefresh="deleteRefresh"
      @back="closeDetailPop"
      @openEdit=";(currentEditShow = true), (currentDetailShow = false)"
      :pushId="pushId"
    ></push-info>
  </div>
</template>

<script>
import PushContent from '@/components/system/PushContent.vue'
import PushPeople from '@/components/system/PushPeople.vue'
import PushContentEdit from '@/components/system/PushContentEdit.vue'
import PushInfo from '@/components/system/PushInfo.vue'
export default {
  components: {
    PushContent,
    PushPeople,
    PushContentEdit,
    PushInfo
  },
  data() {
    return {
      current: 0,
      currentEditShow: false,
      currentDetailShow: false,
      pushId: -1
    }
  },
  methods: {
    openPushContent(id) {
      console.log(id)
      this.pushId = id
      this.currentEditShow = true
    },
    openDetail(id) {
      this.pushId = id
      this.currentDetailShow = true
    },
    // 关闭编辑
    closeEdit(e) {
      this.currentEditShow = false
      if (e) {
        this.$refs.content.getList(true)
      }
    },
    // 删除后关闭编辑
    deleteRefresh() {
      this.currentEditShow = false
      this.currentDetailShow = false
      this.$refs.content.refreshMomentPushList()
    },
    // 关闭详情弹窗
    closeDetailPop() {
      this.currentDetailShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-push {
  position: relative;
  width: 100%;
  height: 100%;
  .el-tabs::v-deep {
    .el-tabs__nav-wrap {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }
    .el-tab-pane {
      padding: 9px 30px 0;
      height: calc(100vh - 110px);
    }
  }
}
</style>
