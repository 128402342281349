<!--
 * @Author: Betty
 * @Date: 2021-06-28 17:57:52
 * @LastEditors: Betty
 * @LastEditTime: 2021-08-04 11:58:08
 * @Description: 搜索职员
-->
<template>
  <!-- 搜索 -->
  <div class="search-line">
    <!-- 输入搜索 -->
    <div class="search-box flex flex-y-center flex-x-between">
      <input
        type="text"
        class="search-input"
        v-model.trim="searchName"
        placeholder="查找同事"
        @input="triggerSearchDirector"
      />
      <!-- 清空按钮 -->
      <button
        type="button"
        v-if="searchName.length > 0"
        class="clear-btn iconfont icon-decline"
        @click.stop="clearSearch"
      ></button>
      <!-- 搜索按钮 -->
      <button type="button" @click="triggerSearchDirector" class="search-btn">
        <span class="iconfont icon-search"></span>
      </button>
    </div>
    <!-- 搜索结果 -->
    <!-- 改成相对于更外层定位，并且高度占满了显示数据的地方 -->
    <div class="search-result" v-if="searchName.length > 0">
      <div
        class="member-item flex flex-y-center"
        v-for="(clerk, index) in searchResultList"
        :key="index"
        @click="choosePerson(clerk)"
      >
        <avatar
          :userInfo="clerk"
          :size="30"
          :distanceRight="10"
          :isShowMe="true"
        ></avatar>
        <p class="name e">{{ clerk.clerk_alias || clerk.clerk_name }}</p>
      </div>
      <!-- 假如没有搜索结果 -->
      <p class="no-data" v-show="searchName && searchResultList.length === 0">
        暂无搜索结果
      </p>
    </div>
  </div>
</template>

<script>
import Avatar from './Avatar.vue'
export default {
  name: 'search-person',
  props: {
    // 是否显示
    isShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Avatar
  },
  data() {
    return {
      // 搜索结果
      searchResultList: [],
      // 搜索输入框输入的内容
      searchName: '',
      searchDirectorAntiShare: null
    }
  },
  mounted() {
    this.searchDirectorAntiShare = this.$util.antiShare(this.searchClerkList)
  },
  watch: {
    isShow(val) {
      if (!val) {
        this.clearSearch()
      }
    }
  },
  methods: {
    // 选择一个人
    choosePerson(clerk) {
      this.$emit('choose-person', clerk)
    },
    // 清空按钮
    clearSearch() {
      this.searchName = ''
      this.searchResultList = []
    },
    // 开始搜索
    triggerSearchDirector() {
      this.searchDirectorAntiShare()
    },
    // 搜索职员
    searchClerkList() {
      if (this.searchName.length > 0) {
        this.$util.searchClerkList(this.searchName).then((res) => {
          if (res) {
            console.log('搜索结果：', res)
            this.searchResultList = [...res]
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-line {
  // position: relative;
  padding: 10px 16px 0;
}
.search-box {
  position: relative;
  margin-bottom: 10px;
  height: 42px;
  padding: 0 16px;
  background: #ffffff;
  border-radius: 38px;
  border: 1px solid #eee;
  .search-icon {
    width: 20px;
    font-size: 20px;
    line-height: 1;
    color: #bbb;
  }
  .search-input {
    width: 80%;
    border: none;
    outline: none;
    height: 30px;
    padding: 4px 0;
    box-sizing: border-box;
    margin-left: 12px;
    line-height: 1.5;
    color: #333;
  }
  .clear-btn {
    height: 20px;
    width: 20px;
    display: block;
    font-size: 20px;
    color: #666;
    border: none;
    outline: none;
  }
  // 搜索按钮
  .search-btn {
    cursor: pointer;
    border: none;
    margin: 0;
    .iconfont {
      font-size: 20px;
      color: #bbb;
    }
  }
}
.search-line .search-result {
  // 父元素绝对定位以后不写高度，子元素无法撑大
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 54px;
  bottom: 0;
  height: 340px;
  overflow: auto;
  padding-left: 0;
  background: #fff;
}
.member-item {
  cursor: pointer;
  padding-left: 20px;
  margin-bottom: 20px;
}
</style>
