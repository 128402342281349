<template>
  <div class="system-container flex">
    <div class="nav-container">
      <el-menu :router="true" :default-active="activeMenu">
        <menu-item :data-list="menuList"></menu-item>
      </el-menu>
    </div>
    <div class="system-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MenuItem from '@/components/common/MenuItem.vue'
export default {
  components: {
    MenuItem
  },
  data() {
    return {
      activeMenu: ''
    }
  },
  computed: {
    menuList() {
      const list = []
      if (this.$hasPer(46)) {
        list.push({
          id: '1',
          name: '组织架构',
          path: '/system/achitecture',
          icon: 'icon-a-Frame14'
        })
      }
      if (this.$hasPer(51)) {
        list.push({
          id: '2',
          name: '通知公告',
          path: '/system/notice',
          icon: 'icon-a-Frame23'
        })
      }
      if (this.$hasPer(57, 62)) {
        list.push({
          id: '3',
          name: '企业文化',
          path: 'enterprise',
          icon: 'icon-a-Frame33',
          child: [
            {
              id: '5',
              name: '圈子推送',
              path: '/system/circlePush',
              icon: 'icon-a-Frame4'
            }
          ]
        })
      }
      if (this.$hasPer(72, 77, 82, 66)) {
        const child = []
        if (this.$hasPer(72, 77, 82)) {
          child.push({
            id: '6',
            name: '管理员',
            path: '/system/management',
            icon: 'icon-Frame16'
          })
        }
        if (this.$hasPer(66)) {
          child.push({
            id: '7',
            name: '团队知识库',
            path: '/system/knowledge',
            icon: 'icon-a-Frame15'
          })
        }
        if (child.length > 0) {
          list.push({
            id: '4',
            name: '权限管理',
            path: 'role',
            icon: 'icon-a-Frame41',
            child: child
          })
        }
      }
      return list
    }
  },
  mounted() {
    this.activeMenu = this.$route.path
  }
}
</script>

<style lang="scss" scoped>
.system-container {
  width: 100vw;
  .nav-container {
    width: 240px;
    height: calc(100vh - 62px);
    background-color: #fff;
    margin-right: 12px;
  }
  .system-content {
    height: calc(100vh - 62px);
    overflow: hidden;
    background-color: #fff;
    flex: 1;
  }
}
</style>
