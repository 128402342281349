<template>
  <div class="editor ck-content">
    <!-- <div id="ckeditor">
         <p contenteditable="true" id="">
           45
         </p>
    </div> -->
    <!-- <ckeditor id="ckeditor"  :editor="editor" ></ckeditor> -->
    <ckeditor
      id="ckeditor"
      v-model="myValue"
      :editor="editor"
      :config="editorConfig"
      @ready="onReady"
      class="text-place"
      rows="5"
    ></ckeditor>
    <!-- <textarea class="text" rows="10" v-model="content" ></textarea> -->
  </div>
</template>

<script>
// import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

// import '@ckeditor/ckeditor5-ui/lang/translations/zh-cn'

import viewToPlainText from '@/util/ckeditor/viewtoplaintext.js'

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import '@ckeditor/ckeditor5-code-block/build/translations/zh-cn'
import '@ckeditor/ckeditor5-basic-styles/build/translations/zh-cn'
import '@ckeditor/ckeditor5-link/build/translations/zh-cn'
import '@ckeditor/ckeditor5-heading/build/translations/zh-cn'
import '@ckeditor/ckeditor5-table/build/translations/zh-cn'
import '@ckeditor/ckeditor5-alignment/build/translations/zh-cn'
import '@ckeditor/ckeditor5-indent/build/translations/zh-cn'
import '@ckeditor/ckeditor5-image/build/translations/zh-cn'
import '@ckeditor/ckeditor5-list/build/translations/zh-cn'
import Mention from '@ckeditor/ckeditor5-mention/src/mention'
// ClassicEditor.builtinPlugins = [CodeBlock]
// import Emotion from '../common/Emotion/index.vue'
export default {
  // components: {
  //   Emotion
  //   // ckeditor: CKEditor.component
  // },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: '',
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        language: {
          ui: 'zh-cn'
        },
        forcePasteAsPlainText: true,
        extraPlugins: [this.MentionLinks],
        isContextPlugin: true,
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          Mention
        ],
        toolbar: {},
        placeholder: '编辑动态内容', //
        startupOutlineBlocks: true,
        mention: {
          feeds: [
            {
              marker: '@',
              feed: this.getClerkList,
              itemRenderer: this.customItemRenderer
            },
            {
              marker: '#',
              feed: this.getTopicList
            }
          ]
        }
      },
      myValue: '',
      instance: null
    }
  },
  methods: {
    setFocus() {
      this.instance.focus()
      this.instance.model.change((writer) => {
        console.log(writer)
        writer.setSelection(
          writer.createPositionAt(this.instance.model.document.getRoot(), 'end')
        )
      })
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
      // CodeBlock = null
    },
    getContent() {
      // console.log(this.instance.data.toView())
      return {
        html: this.instance.getData(),
        text: viewToPlainText(this.instance.editing.view.document.getRoot())
      }
    },
    setContent(val) {
      this.instance.setData(val)
    },
    onReady(e) {
      this.instance = e
      this.myValue = this.value
      this.instance.editing.view.document.on('clipboardInput', (evt, data) => {
        console.log(data)
        const dataTransfer = data.dataTransfer
        this.$emit('paste', dataTransfer)
      })
    },
    async getTopicList(e) {
      let list = []
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentTopicPage, {
          search_name: e,
          pageNumber: 1,
          pageSize: 20
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res)
        list = res.page.list.map((t) => {
          t.type_id = t.topic_id
          t.id = '#' + t.topic_name + '#'
          return t
        })
      }
      return list
    },
    async getClerkList(e) {
      let list = []
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkPage, {
          search_name: e,
          pageNumber: 1,
          pageSize: 20,
          is_leave: 0,
          clerk_status: 1
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        list = res.page.list.map((t) => {
          t.type_id = t.clerk_id
          t.id = '@' + (t.clerk_alias || t.clerk_name)
          return t
        })
      }
      return list
    },
    customItemRenderer(item) {
      const itemElement = document.createElement('span')
      const avatar = document.createElement('img')
      const userNameElement = document.createElement('span')

      itemElement.classList.add('mention__item')

      avatar.src = `${item.clerk_avatar || ''}`

      userNameElement.classList.add('mention__item__user-name')
      userNameElement.textContent = item.id

      itemElement.appendChild(avatar)
      itemElement.appendChild(userNameElement)

      return itemElement
    },
    MentionLinks(editor) {
      // The upcast converter will convert a view
      //
      //		<a href="..." class="mention" data-mention="...">...</a>
      //
      // element to the model "mention" text attribute.
      editor.conversion.for('upcast').elementToAttribute({
        view: {
          name: 'a',
          key: 'data-mention',
          classes: 'mention',
          attributes: {
            href: true
          }
        },
        model: {
          key: 'mention',
          value: (viewItem) => {
            let typeId = ''
            viewItem._attrs.forEach((e, i) => {
              if (i === 'data-id') {
                typeId = e
              }
            })
            // The mention feature expects that the mention attribute value
            // in the model is a plain object with a set of additional attributes.
            // In order to create a proper object use the toMentionAttribute() helper method:
            const mentionAttribute = editor.plugins
              .get('Mention')
              .toMentionAttribute(viewItem, {
                // Add any other properties that you need.
                type_id: typeId
              })
            return mentionAttribute
          }
        },
        converterPriority: 'high'
      })

      // Downcast the model "mention" text attribute to a view
      //
      //		<a href="..." class="mention" data-mention="...">...</a>
      //
      // element.
      editor.conversion.for('downcast').attributeToElement({
        model: 'mention',
        view: (modelAttributeValue, { writer }) => {
          // Do not convert empty attributes (lack of value means no mention).
          if (!modelAttributeValue) {
            return
          }
          // User mentions are downcasted as mailto: links. Tags become normal URLs.

          return writer.createAttributeElement(
            'a',
            {
              class: 'mention',
              'data-mention': modelAttributeValue.id,
              'data-id': modelAttributeValue.type_id,
              'data-type': modelAttributeValue.id[0] === '#' ? 2 : 1,
              href: ''
            },
            {
              // Make mention attribute to be wrapped by other attribute elements.
              priority: 20,
              // Prevent merging mentions together.
              id: modelAttributeValue.uid
            }
          )
        },
        converterPriority: 'high'
      })
    }
  },
  mounted() {
    // ClassicEditor.create(document.querySelector('#ckeditor'))
    //   .then((editor) => {
    //     this.instance = editor
    //     console.log(editor)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
    // this.editorData = this.value
  },
  watch: {
    value(val) {
      this.myValue = val || ''
    },
    myValue(val, old) {
      this.$emit('input', val || '')
    }
  }
  // components: {
  //   Emotion
  // }
}
</script>
<style lang="scss">
.ck-mentions .mention__item {
  display: block;
}

.ck-mentions .mention__item img {
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.ck-mentions .mention__item span {
  margin-left: 0.5em;
}

.ck-mentions .mention__item.ck-on span {
  color: var(--ck-color-base-background);
}

.ck-mentions .mention__item .mention__item__full-name {
  color: hsl(0, 0%, 45%);
}

.ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
  color: hsl(0, 0%, 40%);
}
.ck-body-wrapper {
  .ck-balloon-panel {
    .ck-mentions {
      button.ck-button {
        width: 300px !important;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .ck-button:hover:not(.ck-disabled) {
        background: #f1f4ff !important;
      }
      .ck-button.ck-on:hover:not(.ck-disabled) {
        background: #60b5ff !important;
      }
    }
    box-shadow: 0px 2px 6px rgb(153 153 153 / 25%);
    border: 1px solid #f7f7f7;
  }
  // .ck-button.ck-on {
  //   background: #92ccff !important;
  // }
}
// .ck-content .mention {
//   color: blue!important;
// }
</style>
<style lang="scss" scoped>
.ck-content ::v-deep {
  .ck-editor__editable {
    line-height: 26px;
    height: 300px;
    border: none !important;
  }
  .ck-editor__editable_inline {
    padding: 0;
  }
  .ck-toolbar {
    border: none;
  }

  .ck-focused {
    box-shadow: none !important;
  }
  .mention {
    color: $main-color !important;
    background: transparent !important;
  }
  .ck-placeholder,
  .ck-placeholder:before {
    color: #9999 !important;
  }
  pre[data-language] {
    overflow: scroll !important;
  }
}
</style>
