<template>
  <div class="appwap">
    <!-- <el-button type="primary"  circle icon="el-icon-plus" @change="bpan" class="doom"/> -->
    <div class="knowledge-base flex">
      <!-- 知识库左侧树形结构 -->
      <knowledge-tree
        class="knowledge-base-left pc"
        @showTrainer="showTrainer"
        @showFolder="showFolder"
        @showFile="showFile"
      ></knowledge-tree>
      <div class="folder-top flex flex-x-between flex-y-center show">
        <div class="base-path flex flex-y-center">
          <p>
            {{ wapMenuTitle }}
            <i @click="knowledgebtn" class="el-icon-caret-bottom"></i>
          </p>
        </div>
      </div>
      <!-- 头部 -->
      <div
        v-if="folderVisiblewap"
        class="knowledge-base-left wap knowledgebaseleft"
        @click="folderVisiblewap = false"
      >
        <div @click.stop="">
          <!-- <div
            class="item"
            @click="
              showTrainer()
              folderVisiblewap = false
              wapMenuTitle = '培训师库'
            "
          >
            <p>培训师库</p>
          </div> -->
          <div
            class="item"
            @click="
              showFolder({
                folder_id: 1,
                folder_type: 1
              })
              folderVisiblewap = false
              wapMenuTitle = '公司知识库'
            "
          >
            <p>公司知识库</p>
          </div>
          <div
            class="item"
            @click="
              showFolder({
                folder_id: 2,
                folder_type: 2
              })
              folderVisiblewap = false
              wapMenuTitle = '团队知识库'
            "
          >
            <p>团队知识库</p>
          </div>
          <div
            class="item"
            @click="
              showFolder({
                folder_id: 0,
                collect_mode: true
              })
              folderVisiblewap = false
              wapMenuTitle = '我的收藏'
            "
          >
            <p>我的收藏</p>
          </div>
        </div>
      </div>

      <div class="knowledge-base-right flex flex-y-positive">
        <div class="base-right-content">
          <!-- 文件详情页面 -->
          <knowledge-file-content
            :fileId="fileId"
            :collectMode="isCollectFile"
            @toFolder="showFolder"
            v-if="fileVisible"
          ></knowledge-file-content>
          <!-- 回收站页面 -->
          <!-- <div class="recycle-box" v-if="showRecycle">
          <knowledge-recycle-bin></knowledge-recycle-bin>
        </div> -->
          <!-- 文件夹页面 -->
          <knowledge-file-folder
            :folderId="folderId"
            :collectMode="isCollectFolder"
            @toFolder="showFolder"
            @toFile="showFile"
            v-if="folderVisible"
          ></knowledge-file-folder>
          <!-- 培训师库页面 -->
          <!-- <div class="trainer-box"> -->
          <!-- 培训师类型页面 -->
          <knowledge-trainer v-if="trainerVisible"> </knowledge-trainer>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <knowledge-permissions v-if="false"></knowledge-permissions>
  </div>
</template>

<script>
// 导入知识库文件树组件
import KnowledgeTree from '../components/knowledge/KnowledgeFolderTree.vue'
// import KnowledgeTree from '../components/knowledge/KnowledgeFolderTreeBox.vue'
// import KnowledgeTreeBox from '../components/knowledge/KnowledgeFolderTreeBox.vue'
// 导入知识库文件夹内容组件
import KnowledgeFileFolder from '../components/knowledge/KnowledgeFileFolder.vue'
// 导入文件内容组件
import KnowledgeFileContent from '../components/knowledge/KnowledgeFileContent.vue'
// 导入培训师库内容组件
import KnowledgeTrainer from '../components/knowledge/KnowledgeTrainer.vue'
// 权限管理弹窗
import KnowledgePermissions from '@/components/knowledge/KnowledgePermissions.vue'
export default {
  components: {
    KnowledgeTree,
    // KnowledgeTreeBox,
    KnowledgeFileFolder,
    KnowledgeFileContent,
    KnowledgeTrainer,
    KnowledgePermissions
  },
  data() {
    return {
      // 是否展示导师页
      trainerVisible: false,
      // 是否展示文件详情页面
      fileVisible: false,
      // 是否展示文件夹页面
      folderVisible: true,
      folderVisiblewap: false,
      // 是否展示收藏文件夹页面
      collectFolderVisible: true,
      // 是否为收藏文件夹
      isCollectFolder: false,
      // 是否为收藏文件
      isCollectFile: false,
      // 文件夹id
      folderId: -1,
      // 文件夹类型
      folderType: 1,
      // 文件id
      fileId: -1,
      // 手机端菜单标题
      wapMenuTitle: '公司知识库'
    }
  },
  methods: {
    knowledgebtn() {
      if (this.folderVisiblewap) {
        this.folderVisiblewap = false
      } else {
        this.folderVisiblewap = true
      }
    },
    // 隐藏右边
    hideRight() {
      this.fileId = -1
      this.fileVisible = false
      this.folderVisible = false
      this.trainerVisible = false
      this.collectFolderVisible = false
    },
    // 展示培训页面
    showTrainer() {
      // this.hideRight()
      // this.trainerVisible = true
      this.$router.push({
        name: 'knowledge',
        params: {
          type: 'tutor'
        }
      })
    },
    // 展示文件页面
    showFile({ file_id: fileId, collect_mode: collectMode = false }) {
      this.$router.push({
        name: 'knowledge',
        params: {
          type: collectMode ? 'fileCollect' : 'file',
          id: fileId
        }
      })
      // this.hideRight()
      // this.fileId = fileId
      // this.isCollectFile = collectMode
      // this.fileVisible = true
    },
    // 展示文件夹页面
    showFolder({
      folder_id: folderId,
      folder_type: folderType,
      collect_mode: collectMode = false
    }) {
      console.log(folderId)
      const params = {
        type: collectMode ? 'folderCollect' : 'folder'
      }
      if (!collectMode || (collectMode && folderId !== 0)) {
        params.id = folderId
      }
      this.$router.push({
        name: 'knowledge',
        params: params
      })
      // this.hideRight()
      // this.folderId = folderId
      // this.folderType = folderType
      // this.isCollectFolder = collectMode
      // this.folderVisible = true
    }
  },
  mounted() {
    switch (this.$route.params.type) {
      // 导师
      case 'tutorDetail':
      case 'tutor': {
        this.hideRight()
        this.trainerVisible = true
        break
      }
      // 文件夹
      case 'folderCollect':
      case 'folder': {
        this.hideRight()
        this.folderVisible = true
        if (this.$route.params.id) {
          this.folderId = Number(this.$route.params.id)
        } else {
          if (this.$route.params.type === 'folderCollect') {
            this.folderId = 0
          }
        }
        this.isCollectFolder = this.$route.params.type === 'folderCollect'
        break
      }
      // 文件
      case 'fileCollect':
      case 'file': {
        this.hideRight()
        this.fileVisible = true
        this.fileId = Number(this.$route.params.id)
        this.isCollectFile = this.$route.params.type === 'fileCollect'
        break
      }
    }
  },
  watch: {},
  // 路由进入
  beforeRouteEnter(to, from, next) {
    if (to.params.type === undefined) {
      next({
        name: 'knowledge',
        params: {
          type: 'folder',
          id: '1'
        }
      })
    } else {
      next()
    }
  },
  // 路由改变
  beforeRouteUpdate(to, from, next) {
    switch (to.params.type) {
      // 导师
      case 'tutorDetail':
      case 'tutor': {
        this.hideRight()
        this.trainerVisible = true
        next()
        break
      }
      // 文件夹
      case 'folderCollect':
      case 'folder': {
        this.hideRight()
        this.folderVisible = true
        if (to.params.id) {
          this.folderId = Number(to.params.id)
        } else {
          if (to.params.type === 'folderCollect') {
            this.folderId = 0
          }
        }
        this.isCollectFolder = to.params.type === 'folderCollect'
        next()
        break
      }
      // 文件
      case 'fileCollect':
      case 'file': {
        this.hideRight()
        this.fileVisible = true
        this.fileId = Number(to.params.id)
        this.isCollectFile = to.params.type === 'fileCollect'
        next()
        break
      }
      // 默认到公司知识库
      default: {
        next({
          name: 'knowledge',
          params: {
            type: 'folder',
            id: '1'
          }
        })
        break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .pc {
    display: block !important;
  }
  .wap {
    display: none !important;
  }
  .show {
    display: none;
  }
  // 知识库
  .knowledge-base {
    width: 100vw;
    margin: 0;
    height: calc(100vh - 62px);
    // position: fixed;
    // top: 62px;
    background: #ffffff;
    position: relative;
    // 知识库右侧
    .knowledge-base-left {
      width: 280px;
      height: 100%;
      background: $light-grey-bg;
      overflow: hidden;
      overflow-y: scroll;
      box-sizing: border-box;
      padding: 20px 0 62px 0;

      // 培训师库，我的收藏，创建知识库样式
      .trainer-box,
      .create-base,
      .my-collect {
        width: 280px;
        height: 50px;
        background: $light-grey-bg;
        padding: 0 101px 0 42px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          background: #f0f0f0;
        }
        .icon-Profile3,
        .icon-Profile_11,
        .icon-add2 {
          font-size: 23px;
          color: #999999;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #666666;
          margin-left: 6px;
        }
      }
    }
    // 知识库右侧样式
    .knowledge-base-right {
      width: calc(100vw - 280px);
      height: 100%;
      background: #ffffff;
      // 知识库右侧头部样式
      .base-right-top {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #eeeeee;
        // 文件头部样式
        .file-top {
          width: 100%;
          height: 70px;
          padding: 0 50px 0 48px;
          box-sizing: border-box;
        }
        // 回收站样式
        .recycle-top {
          width: 100%;
          height: 70px;
          padding-left: 48px;
          box-sizing: border-box;
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            color: #333333;
          }
          .iconfont {
            margin: 0 6px 0 6px;
            font-size: 22px;
            color: #999999;
          }
        }
      }
      // 知识库右侧内容样式
      .base-right-content {
        height: calc(100vh - 60px);
        width: 100%;
        box-sizing: border-box;
        position: relative;
      }
    }
  }
  .show {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none !important;
  }
  .wap {
    display: block !important;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 99999;
    width: 100% !important;
    background: #00000087 !important;
  }
  .appwap {
    width: 100%;
    // padding: 0 10px;
    //  .doom {
    //   position: fixed;
    //   left: 90%;
    //   bottom: 10%;
    //   z-index: 9;
    // }
  }
  // 知识库
  .knowledge-base {
    width: 100%;
    margin: 0;
    height: calc(100vh - 62px);
    // position: fixed;
    // top: 62px;
    background: #ffffff;
    position: relative;
    // 知识库右侧
    .knowledge-base-left {
      width: 280px;
      height: 100vh;
      background: $light-grey-bg;
      overflow: hidden;
      overflow-y: scroll;
      box-sizing: border-box;
      // padding: 0px 0 62px 0;

      // 培训师库，我的收藏，创建知识库样式
      .trainer-box,
      .create-base,
      .my-collect {
        width: 280px;
        height: 50px;
        background: $light-grey-bg;
        padding: 0 101px 0 42px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          background: #f0f0f0;
        }
        .icon-Profile3,
        .icon-Profile_11,
        .icon-add2 {
          font-size: 23px;
          color: #999999;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #666666;
          margin-left: 6px;
        }
      }
    }
    // 知识库右侧样式
    .knowledge-base-right {
      width: 100%;
      height: 100%;
      background: #ffffff;
      // 知识库右侧头部样式
      .base-right-top {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #eeeeee;
        // 文件头部样式
        .file-top {
          width: 100%;
          height: 70px;
          padding: 0 50px 0 48px;
          box-sizing: border-box;
        }
        // 回收站样式
        .recycle-top {
          width: 100%;
          height: 70px;
          padding-left: 48px;
          box-sizing: border-box;
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            color: #333333;
          }
          .iconfont {
            margin: 0 6px 0 6px;
            font-size: 22px;
            color: #999999;
          }
        }
      }
      // 知识库右侧内容样式
      .base-right-content {
        height: calc(100vh - 60px);
        width: 100%;
        box-sizing: border-box;
        position: relative;
      }
    }
  }
  .show {
    display: block;
  }

  .folder-top {
    position: absolute;
    top: 0;
    width: 100%;
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    z-index: 1;
  }

  .folder-top p {
    width: 100%;
    font-weight: bold;
    font-size: 15px;
  }
  .knowledgebaseleft {
    text-align: center;
    .item {
      line-height: 50px;
      height: 50px;
      background: #fff;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
</style>
