<!--
 * @Author: Betty
 * @Date: 2021-07-14 16:59:15
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-04 13:48:32
 * @Description: 目标关联弹窗
-->
<template>
  <el-dialog
    title="关联目标"
    :visible.sync="isShowRelative"
    :before-close="handleClose"
    :width="'600px'"
    :append-to-body="true"
  >
    <!-- 主体部分 -->
    <div class="relative-modal-main">
      <!-- 里面的每一条 -->
      <div
        v-for="(target, index) in targetList"
        :key="index"
        class="higher-target-item flex flex-y-center"
        @click="toEditParent(target.objective_id)"
      >
        <svg
          class="icon"
          aria-hidden="true"
          v-if="currentId === target.objective_id"
        >
          <use xlink:href="#icon-Group706"></use>
        </svg>
        <p
          class="e target-name"
          :class="{ active: currentId === target.objective_id }"
        >
          {{ target.objective_name }} /
          {{ target.create_clerk_alias || target.create_clerk_name }}
        </p>
      </div>
      <p v-if="targetList.length === 0" class="no-target-tip">暂无可关联目标</p>
    </div>
    <span slot="footer" class="dialog-footer flex flex-y-center">
      <el-button type="primary" @click="unbind()" class="red" v-if="isToCancel"
        >取消关联</el-button
      >
      <el-button
        type="primary"
        @click="confirmChooseParent()"
        :disabled="!isEdited"
        id="relative-btn"
        :class="{ disabled: !isEdited }"
        v-else
      >
        确认关联</el-button
      >
      <el-button @click="closeModal">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'task-relative-modal',
  props: {
    // 是否显示
    isShowRelative: {
      type: Boolean
    },
    // 我当前的目标
    task: {
      type: Object
    }
  },
  data() {
    return {
      // 目标列表
      targetList: [],
      // 当前选择的目标id
      currentId: -1,
      // 是否已经编辑过了
      isEdited: false
    }
  },
  computed: {
    taskId() {
      return this.task.task_id
    },
    // 如果当前目标的父目标存在于父目标数组里，并且当前选中的id就是我的父目标，就要显示取消关联
    isToCancel() {
      return (
        this.task &&
        this.task.objective_info &&
        this.targetList.findIndex(
          (item) => item.objective_id === this.task.objective_info.objective_id
        ) > -1 &&
        this.task.objective_info.objective_id === this.currentId
      )
    }
  },
  watch: {
    // 一把弹窗打开，就去获取数据
    isShowRelative(val) {
      if (val) {
        this.getParentTargetList()
        this.isEdited = false
      }
    }
  },
  methods: {
    // 获取目标列表
    async getParentTargetList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskObjectiveList, {
          task_id: this.taskId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.targetList = res.list
        console.log(this.targetList.length)
        // 记录一下现在的目标的父级，标一个原始高亮
        this.currentId = this.task?.objective_info?.objective_id || 0
        console.log(this.currentId > 0)
      }
    },
    handleClose() {
      this.closeModal()
    },
    // 点击选择
    // 如果点击了已经选择过的，就认为我没选，否则就认为我选择这个
    toEditParent(id) {
      this.isEdited = true
      this.currentId = id
    },
    // 关闭弹窗
    closeModal() {
      // 先清理掉之前的选择，再关闭弹窗
      this.isEdited = false
      this.currentId = 0
      this.$emit('close-modal')
    },
    // 确定选择
    async confirmChooseParent(val) {
      if (val !== 0) {
        val = this.currentId
      }
      if (this.currentId > 0) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskObjective, {
            task_id: this.taskId,
            objective_id: val
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          let tip = ''
          if (val === 0) {
            tip = '取消关联成功'
          } else {
            tip = '选择关联目标成功'
          }
          this.$message({
            showClose: true,
            message: tip,
            type: 'success'
          })
          this.closeModal()
          this.$emit('to-fresh-target')
        }
      }
    },
    // 确定选择
    async unbind() {
      if (this.currentId > 0) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.unbindTaskObjective, {
            task_id: this.taskId
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '取消关联成功',
            type: 'success'
          })
          this.closeModal()
          this.$emit('to-fresh-target')
        }
      }
    }
  }
}
</script>

<style lang="scss">
// 主要区域
.relative-modal-main {
  padding: 16px 20px;
  max-height: 414px;
  min-height: 176px;
  overflow: auto;
  .higher-target-item {
    position: relative;
    min-height: 48px;
    padding: 13px 20px 13px 40px;
    box-sizing: border-box;
    width: 100%;
    .icon {
      position: absolute;
      left: 12px;
      width: 18px;
      height: 18px;
    }
    .target-name {
      line-height: 1.5;
      color: #333;
      &.active {
        color: $main-color;
      }
    }
    &:hover {
      background: $light-grey-bg;
    }
  }
}

#relative-btn.disabled {
  border-color: #eee !important;
  color: #999 !important;
  background: #eee !important;
}

.el-button.red {
  border-color: $red !important;
  background: $red !important;
}
</style>
<style lang="scss" scoped>
.el-dialog__wrapper ::v-deep .el-dialog__body {
  padding: 0 !important;
}

// 暂无可关联目标
.no-target-tip {
  line-height: 2;
  font-weight: 700;
  color: $red;
}
</style>
