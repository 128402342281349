<!--
 * @Date: 2021-05-27 11:25:36
 * @LastEditTime: 2021-12-28 16:37:10
 * @Description: 头部组件
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div>
    <!-- 头部 -->
    <el-header
      class="header flex flex-y-center flex-x-between"
      :class="{ system: isSystem }"
    >
      <!-- 左边，logo -->
      <div class="flex flex-y-center hidden-xs-only">
        <img src="../../assets/img/logo.png" class="logo" v-if="!isSystem" />
        <img
          src="../../assets/img/system_logo.png"
          class="logo system-logo"
          v-else
        />
        <span style="color: #333; font-size: 14px; padding-left: 5px"
          >一个内部工作、交流、分享、学习的平台</span
        >
      </div>
      <!-- 中间是导航栏 -->
      <template v-if="!isSystem">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo nav-menu flex"
          mode="horizontal"
          @select="handleSelect"
          v-if="!isSystem"
        >
          <template v-for="item in navList" class="PC">
            <el-menu-item
              class="PC"
              v-if="!isSystem && screenWidth >= 768"
              :index="item.id"
              :key="`PC${item.id}`"
              >{{ item.title }}</el-menu-item
            >

            <el-menu-item
              class="WAP flex1"
              v-if="
                !isSystem &&
                (item.showMobile === undefined ||
                  (item.showMobile && screenWidth >= 768))
              "
              :index="item.id"
              :key="`WAP${item.id}`"
            >
              <a v-if="!isSystem"
                ><i :class="item.icon"></i>
                <p>{{ item.title }}</p></a
              >
            </el-menu-item>
          </template>
        </el-menu>
      </template>
      <!-- 右边，提醒和用户信息 -->
      <div class="flex flex-y-center header-right hidden-xs-only">
        <!-- 消息提醒 -->
        <div class="message-remind" v-if="!isSystem">
          <!-- 图标 -->
          <span
            class="iconfont icon-Notification1 message-icon"
            @click.stop="toggleRemind"
          >
          </span>
          <!-- 小红点，里面是未读消息数量 -->
          <div class="red-point" v-if="unreadCount > 0">{{ unreadCount }}</div>
        </div>
        <!-- 消息提醒弹窗 -->
        <message-remind-box
          :messageList="messageList"
          :isShow="isShowRemind"
          @to-detail="toShowDetail($event)"
          @click.native.stop=""
        ></message-remind-box>

        <el-tooltip
          content="管理中心"
          placement="bottom"
          v-if="!isSystem && isAdmin"
        >
          <span class="iconfont system-in-icon hidden-xs-only" @click="toSystem"
            ><svg style="width: 26px; height: 26px">
              <use :xlink:href="'#icon-Notification2'"></use></svg
          ></span>
        </el-tooltip>
        <el-tooltip content="回工作区" placement="bottom" v-if="isSystem">
          <span
            class="iconfont icon-icon7 system-out-icon hidden-xs-only"
            @click="toHome"
          ></span>
        </el-tooltip>
        <!-- 用户头像和名字 -->
        <div class="user-info" @click.stop="toggleHandle">
          <!-- 已登录的用户信息 -->
          <div class="flex flex-y-center user-box">
            <!-- <img :src="userInfo.clerk_avatar" alt="用户头像" class="avatar" /> -->
            <avatar
              :userInfo="myClerkInfo"
              :size="30"
              :distanceRight="14"
            ></avatar>
            <p class="username">
              {{ myClerkInfo.clerk_alias || myClerkInfo.clerk_name }}
            </p>
            <!-- 操作菜单 -->
            <div class="handle-menu" :class="{ active: isShowUserHandle }">
              <div class="menu-item" @click.stop="logoutFn()">退出登录</div>
            </div>
          </div>
        </div>
      </div>
    </el-header>
    <!-- 目标详情弹窗 -->
    <target-detail
      :targetId="currentTargetId"
      :isShow="isShowTargetDetail"
      currentTab="third"
      v-if="isInit"
      @close-detail="closeTargetDetail($event)"
    ></target-detail>
    <!-- 任务详情弹窗 -->
    <task-detail
      :taskId="currentTaskId"
      :isShow="isShowTaskDetail"
      currentTab="third"
      :isMe="true"
      v-if="isInit"
      @close-detail="closeTaskDetail($event)"
    ></task-detail>
  </div>
</template>

<script>
// 头像组件
import Avatar from './Avatar.vue'
// 消息提醒弹窗
import MessageRemindBox from './MessageRemindBox.vue'
// 目标详情弹窗
import TargetDetail from '../target/TargetDetail.vue'
// 任务详情弹窗
import TaskDetail from '../task/TaskDetail.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  components: { Avatar, MessageRemindBox, TargetDetail, TaskDetail },
  name: 'global-header',
  props: {
    // 用户信息
    // userInfo: {
    //   type: Object
    // }
    // // 是否展示出退出按钮
    // isShowUserHandle: {
    //   type: Boolean,
    //   default: false
    // },
    // // 当前选中了第几个导航
    // activeIndex: {
    //   type: String,
    //   default: '1'
    // }
  },
  data() {
    return {
      // 消息提醒数据列表
      messageList: [],
      // 是否展示提醒弹窗
      isShowRemind: false,
      // 当前显示详情的目标id
      currentTargetId: -1,
      // 当前显示详情的任务id
      currentTaskId: -1,
      // 是否展示目标详情
      isShowTargetDetail: false,
      // 是否展示任务详情
      isShowTaskDetail: false,
      // 控制当前的tab
      tab: 'first',
      isInit: false,
      isSystem: false,
      // 是否展示用户操作栏
      isShowUserHandle: false,

      // 当前选中的导航
      activeIndex: '/home'
    }
  },
  computed: {
    ...mapState(['screenWidth', 'myClerkId', 'myClerkInfo', 'clickBodyIndex']),
    isAdmin() {
      return this.myClerkInfo?.is_admin === 1 || false
    },
    // 未读评论的数量
    unreadCount() {
      let count = 0
      for (const message of this.messageList) {
        count += message.comment_number
      }
      if (count >= 99) {
        count = 99
      }
      return count
    },
    // 导航条
    navList() {
      return [
        {
          title: '首页',
          icon: 'iconfont icon-icon2',
          id: '/home',
          routeName: ['home']
        },
        {
          title: '工作台',
          icon: 'iconfont icon-a-icon12',
          id: `/workspace/${this.myClerkId}/task`,
          routeName: ['workspace', 'taskDetail', 'targetDetail']
        },
        {
          title: '会议',
          icon: 'iconfont icon-a-icon21',
          id: '/meeting',
          routeName: ['meeting']
        },
        {
          title: '圈子',
          icon: 'iconfont icon-a-icon31',
          id: '/circle/index',
          routeName: [
            'circle',
            'circleIndex',
            'circleMessage',
            'circleHome',
            'member',
            'memberHome',
            'memberAttention',
            'memberFans',
            'memberMessage',
            'memberLike',
            'memberSet',
            'memberInfo',
            'topicInfo'
          ]
        },
        {
          title: '知识库',
          icon: 'iconfont icon-a-icon4',
          id: '/knowledge/folder/1',
          routeName: ['knowledge']
          // showMobile: true
        },
        {
          title: '更多',
          icon: 'iconfont icon-gengduo3',
          id: '/more',
          routeName: [
            'more',
            'workReportWeek',
            'workReportMonth',
            'workReportWeekDetail',
            'workReportMonthDetail'
          ]
          // showMobile: true
        }
        // ,
        // {
        //   title: '通讯录',
        //   id: '6'
        // },
        // {
        //   title: '系统管理',
        //   id: '7'
        // }
      ]
    }
  },
  provide() {},
  mounted() {
    // 点击外面隐藏掉更多弹窗
    // document.addEventListener('click', this.clickOut)
  },
  methods: {
    ...mapActions(['getClerkInfo']),
    ...mapMutations(['logout', 'setToken', 'setOffset', 'setRoute']),
    init() {
      if (this.isInit) {
        return
      }
      this.isInit = true
      // 获取消息提醒
      this.getMessageList()
    },
    // 点击切换退出按钮的显示隐藏
    toggleHandle() {
      this.isShowUserHandle = !this.isShowUserHandle
    },
    // 导航栏选择
    handleSelect(key, keyPath) {
      // 切换导航栏的时候，刷新消息列表
      this.getMessageList()
      if (key.indexOf('/') === 0) {
        this.activeIndex = key
        this.$router.push({
          path: key
        })
      } else {
        this.$message({
          type: 'info',
          message: '功能开发中!',
          showClose: true
        })
      }
    },
    logoutFn() {
      this.logout()
      this.getClerkInfo()
    },
    // 获取消息提醒
    async getMessageList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMessageList)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.messageList = res.list
        // console.log('消息提醒列表', this.messageList)
      }
    },
    // 点击切换是否显示提示弹窗
    toggleRemind() {
      this.isShowRemind = !this.isShowRemind
      if (this.isShowRemind) {
        this.getMessageList()
      }
    },
    // 关闭目标详情弹窗
    closeTargetDetail({ is_update_data: flage }) {
      this.isShowTargetDetail = false
      // this.tab = 'first'
    },
    // 关闭任务详情弹窗
    closeTaskDetail({ is_update_data: flage }) {
      this.isShowTaskDetail = false
      // this.tab = 'first'
    },
    // 展示出对应的详情
    toShowDetail({ message, index }) {
      // 让详情显示到第三个tab（评论）
      this.tab = 'third'
      // 删除掉当前的元素
      this.messageList.splice(index, 1)
      // 判断类型，展示出对应的详情
      switch (message.object_type) {
        case 1: {
          this.showTaskDetail(message)
          break
        }
        case 2: {
          this.showTargetDetail(message)
          break
        }
        default:
          console.log('其他类型')
      }
    },
    // 展示任务详情
    showTaskDetail(obj) {
      this.isShowTaskDetail = true
      this.currentTaskId = obj.object_id
    },
    // 展示目标详情
    showTargetDetail(obj) {
      this.isShowTargetDetail = true
      this.currentTargetId = obj.object_id
    },
    clickOut() {
      this.isShowRemind = false
      this.isShowUserHandle = false
    },
    toSystem() {
      this.$router.push({ name: 'systemIndex' })
    },
    toHome() {
      this.$router.push({ name: 'home' })
    }
  },
  watch: {
    clickBodyIndex() {
      this.clickOut()
    },
    $route: {
      handler(val) {
        this.isSystem = val.matched[1].name === 'systemIndex'
        const info = this.navList.find((e) => {
          return e.routeName.includes(val.name)
        })
        if (info) {
          this.activeIndex = info.id
        } else {
          this.activeIndex = '/home'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 767px) {
  .el-menu.nav-menu.el-menu-demo {
    position: relative;
  }

  .user-info {
    padding-left: 14px;
  }

  .el-header.header {
    justify-content: center;
  }
}

@media screen and (min-width: 767px) {
  .el-header.header {
    padding: 0 30px;
    &.system {
      padding: 0 30px;
    }
  }

  .el-menu.nav-menu.el-menu-demo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }

  .user-info {
    padding-left: 14px;
  }
}

// 头部
.el-header.header {
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  height: 62px;
  background: #fff;
  // logo
  .logo {
    display: block;
    width: 240px;
    &.system-logo {
      width: 190px;
    }
  }

  // 右上角的用户信息
  .user-info {
    position: relative;
    cursor: pointer;
    height: 100%;
  }
  .user-box {
    position: relative;
    height: 100%;
  }
  .avatar {
    width: 26px;
    height: 26px;
    display: block;
  }
  .username {
    color: $main-color;
  }
  // 用户的操作菜单
  .handle-menu {
    margin-top: 14px;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    top: 100%;
    width: 88px;
    display: none;
    background: $main-color;
    border-radius: 4px;
    .menu-item {
      padding: 8px 0;
      text-align: center;
      line-height: 21px;
      cursor: pointer;
      color: #fff;
    }
    &.active {
      display: block;
    }
    &::before {
      content: '';
      @include pos-center(absolute);
      width: 0;
      height: 0;
      top: -6px;
      border-top: 0 solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid $main-color;
    }
  }
}

// 头部导航栏样式调整
// 垂直居中，水平不居中
.el-menu.nav-menu.el-menu-demo {
  // 调整每个元素的字体大小、颜色、选中样式

  > .el-menu-item {
    position: relative;
    float: left;
    height: 44px;
    padding: 0 1.56vw;
    // padding: 0 0.8vw;
    line-height: 44px;
    font-size: 16px;
    margin: 0;
    border-bottom: none;

    &:hover,
    &.is-active {
      color: $main-color;
      &::after {
        content: '';
        @include pos-center(absolute);
        bottom: 0;
        width: 16px;
        height: 2px;
        background: $main-color;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  /** 手机端CSS 代码 */
  // 头部导航栏样式调整
  // 垂直居中，水平不居中
  .PC {
    display: none;
  }
  .WAP {
    width: 20%;
  }
  .WAP a {
    display: inline-block;
    width: 58px;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    position: relative;
    padding-top: 22px;
    color: #9c9c9c;
    font-size: 16px;
    text-decoration: none;
    line-height: 25px;
    overflow: hidden;
  }
  .WAP a i {
    position: absolute;
    top: 12px;
    font-size: 26px;
    left: 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    filter: alpha(opacity=100);
    opacity: 1;
  }
  .WAP i {
    border: none;
    width: 50%;
  }

  .WAP p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .el-header {
    padding: 0px !important;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .el-menu.nav-menu.el-menu-demo > .el-menu-item {
    position: relative;
    float: left;
    height: 60px;
    padding: 0;
    line-height: 60px;
    font-size: 16px;
    margin: 0;
    border-bottom: none;
    text-align: center;
  }
  .el-menu-demo {
    width: 100%;
  }

  .el-menu.nav-menu.el-menu-demo {
    // 调整每个元素的字体大小、颜色、选中样式
    > .el-menu-item {
      color: #909399;
    }
    > .el-menu-item [class^='el-icon-'] {
      vertical-align: top;
      width: 100%;
      font-size: 28px;
    }
    > .el-menu-item.is-active::after {
      background: none;
    }
  }
}
@media screen and (min-width: 960px) {
  /** 电脑端CSS 代码 */
  /** 手机端CSS 代码 */
  .WAP {
    display: none;
  }
  // 头部导航栏样式调整
  // 垂直居中，水平不居中
  .el-menu.nav-menu.el-menu-demo {
    // 调整每个元素的字体大小、颜色、选中样式
    > .el-menu-item {
      color: $main-color;
      > i {
        display: none;
      }
    }
  }
}

// 提醒图标和弹窗
.message-remind {
  .message-icon {
    position: relative;
    font-size: 26px;
    cursor: pointer;
    color: $main-color;
    // 不带数字的点点
    &.active::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 8px;
      height: 8px;
      background: $red;
      border-radius: 50%;
    }
  }
  // 带有数字的小红点
  .red-point {
    width: 19px;
    height: 19px;
    text-align: center;
    position: absolute;
    top: -8px;
    left: 18px;
    font-size: 12px;
    line-height: 19px;
    color: #fff;
    background: $red;
    border-radius: 10px;
  }
}

.header-right {
  position: relative;
}

@media screen and (max-width: 767px) {
  .el-header.header {
    bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .el-header.header {
    top: 0;
  }
}
// 系统推出按钮
.iconfont.system-out-icon,
.iconfont.system-in-icon {
  position: relative;
  font-size: 26px;
  cursor: pointer;
  color: $main-color;
  margin-left: 15px;
}
</style>
