<!--
 * @Author: hzh
 * @Date: 2021-08-16 09:33:47
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-17 21:00:23
-->
<template>
  <div class="comment-item-dialog">
    <p class="text-warp">
      <span v-if="info.pid === 0">
        {{ info.clerk_alias || info.clerk_name }}：
      </span>
      <span v-else>
        {{ info.clerk_alias || info.clerk_name }}
        <template v-if="showReply">
          <span class="comment-reply"> @回复 </span>
          {{ info.parent_clerk_alias || info.parent_clerk_name }}
        </template>
      </span>
      {{ info.comment_content || '' }}
    </p>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showReply: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-item-dialog {
  p {
    font-size: 12px;
    line-height: 18px;
    span {
      color: $main-color;
    }

    .comment-reply {
      color: #000;
    }
  }
}
</style>
