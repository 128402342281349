<!--
 * @Date: 2021-06-11 16:47:10
 * @LastEditTime: 2021-10-11 16:20:23
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div>
    <div>
      <!-- 它自己 -->
      <!-- 如果有label显示名字，如果没有，显示头像和用户名 -->
      <!-- 显示部门 -->
      <div
        v-if="treeNode.dept_name && treeNode.dept_name.length > 0"
        class="label flex flex-y-center"
        :style="{ 'padding-left': 16 * layer + 'px' }"
      >
        <span
          class="arrow"
          :class="{ active: isShowChild }"
          @click="toggleChild"
        >
          <span class="iconfont icon-playfill1"></span>
        </span>
        <p class="label-text" @click="toggleChild">{{ treeNode.dept_name }}</p>
      </div>
      <!-- 显示职员 -->
      <div
        v-else
        class="flex flex-y-center member"
        :style="{ 'padding-left': 16 * layer + 20 + 'px' }"
        :class="{ active: treeNode.clerk_id === Parent.currentClerkId }"
        @click="chooseMember(treeNode)"
      >
        <!-- 用户 -->
        <div class="avatar-box">
          <cricle-pop
            :info="{
              clerk_avatar: treeNode.clerk_avatar,
              clerk_alias: treeNode.clerk_alias,
              clerk_name: treeNode.clerk_name,
              clerk_id: treeNode.clerk_id
            }"
          >
            <img
              :src="treeNode.clerk_avatar | httpToHttps"
              class="avatar"
              :alt="treeNode.clerk_alias || treeNode.clerk_name"
            />
          </cricle-pop>
          <!-- 关注按钮 -->
          <button
            class="focus-btn active"
            v-if="
              isMyFocus(treeNode.clerk_id) === -1 &&
              treeNode.clerk_id !== myClerkId
            "
            @click.stop="addFocus(treeNode)"
          >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-Group2031"></use>
            </svg>
          </button>
          <!-- 已关注按钮 -->
          <button
            class="focus-btn"
            v-else
            v-show="treeNode.clerk_id !== myClerkId"
            @click.stop="removeFocus(treeNode)"
          >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-Group203"></use>
            </svg>
          </button>
        </div>
        <p class="name e" :title="treeNode.clerk_alias || treeNode.clerk_name">
          {{ treeNode.clerk_alias || treeNode.clerk_name }}
        </p>
        <!-- 关注按钮 -->
        <!-- <button
          class="focus-btn active"
          v-if="
            isMyFocus(treeNode.clerk_id) === -1 &&
            treeNode.clerk_id !== this.myClerkId
          "
          @click.stop="addFocus(treeNode)"
        >
          关注
        </button>
        <span
          class="focus-btn"
          v-else
          v-show="treeNode.clerk_id !== this.myClerkId"
          @click.stop="removeFocus(treeNode, index)"
          >已关注</span
        > -->
      </div>
      <!-- 它孩子 -->
      <template
        v-if="
          (treeNode.clerk_list && treeNode.clerk_list.length > 0) ||
          (treeNode.children && treeNode.children.length > 0)
        "
      >
        <el-collapse-transition>
          <div v-show="isShowChild">
            <!-- 职员，职员id前面拼上一个"c"字母 -->
            <focus-member-tree
              v-for="child in treeNode.clerk_list"
              :key="'c' + child.clerk_id"
              :treeNode="child"
              :layer="layer + 1"
              @clerk-choose="chooseMember($event)"
            ></focus-member-tree>
            <!-- 部门 -->
            <focus-member-tree
              v-for="child in treeNode.children"
              :key="child.dept_id"
              :treeNode="child"
              :layer="layer + 1"
              @clerk-choose="chooseMember($event)"
            ></focus-member-tree>
          </div>
        </el-collapse-transition>
      </template>
    </div>
  </div>
</template>

<script>
import FocusMemberTree from './FocusMemberTree'
import CriclePop from '@/components/task/CriclePop'
import { mapState } from 'vuex'
export default {
  name: 'focus-member-tree',
  components: {
    FocusMemberTree,
    CriclePop
  },
  inject: ['Parent'],
  props: {
    // 当前节点的数据
    task: {
      type: Object
    },
    treeNode: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 当前显示在第几层
    layer: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      // 是否显示它的孩子
      isShowChild: false
    }
  },
  computed: {
    // 判断这个人是否是我的关注
    isMyFocus() {
      return function (id) {
        const index = this.Parent.focusClerkList.findIndex((item) => {
          return item.clerk_id === id
        })
        return index
      }
    },
    ...mapState(['myClerkId'])
  },
  mounted() {
    if (this.treeNode.dept_id === 1) {
      this.isShowChild = true
    }
  },
  methods: {
    // 显示隐藏孩子
    toggleChild() {
      this.isShowChild = !this.isShowChild
    },
    // 点击关注
    addFocus(task) {
      this.Parent.addFocus(task)
    },
    // 点击取关
    removeFocus(task, index) {
      this.Parent.removeFocus(task, index)
    },
    // 点击选择职员
    chooseMember(treeNode) {
      // 整理好选择的数据
      const data = {
        clerk_name: treeNode.clerk_name,
        clerk_avatar: treeNode.clerk_avatar,
        clerk_id: treeNode.clerk_id
      }
      this.$emit('clerk-choose', data)
    }
    // 跳转圈子弹窗
    // showCricle() {
    //   console.log(2)
    //   this.cricleShow = !this.cricleShow
    // }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/tree_style.scss';
</style>
