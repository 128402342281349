<template>
  <div>
    <scroll-fresh
      className="member-home"
      dataName="member-home-container"
      :showBackTop="true"
      :page="pageNumber"
      :lastPage="totalPage"
      @get-next-page="getNextPage"
      :showNoMore="!loading && list.length > 0"
      ref="scrollFreshRef"
    >
      <div class="member-home-container">
        <member-header
          @goHome="goHome"
          :tab-list="screenWidth > 767 ? tabs : mobileTabs"
          @changeTab="changeTab"
          :memberInfo="clerkInfo"
          :currentTab="currentTab"
          @onSubmit="handerConfirm"
        ></member-header>

        <div class="title-bar">全部动态（{{ totalRow }}）</div>
        <div class="message-list">
          <el-skeleton :loading="loading" animated :count="4">
            <template slot="template">
              <div class="el-skeleton-item flex flex-y-start">
                <el-skeleton-item
                  variant="image"
                  style="
                    width: 64px;
                    height: 64px;
                    margin-right: 14px;
                    border-radius: 6px;
                  "
                />
                <div class="el-skeleton-item-content flex1">
                  <div class="el-skeleton-item-header">
                    <div>
                      <el-skeleton-item
                        variant="p"
                        style="width: 50px; height: 5px; margin-bottom: 15px"
                      />
                    </div>
                    <div>
                      <el-skeleton-item
                        variant="p"
                        style="height: 5px; width: 130px"
                      />
                    </div>
                  </div>
                  <div class="el-skeleton-item-text">
                    <el-skeleton-item
                      v-for="item in 4"
                      :key="item"
                      variant="p"
                      style="height: 5px; margin-bottom: 15px"
                    />
                  </div>
                  <div class="el-skeleton-item-footer flex flex-x-end">
                    <el-skeleton-item
                      variant="button "
                      style="width: 40px; margin-right: 20px; height: 5px"
                    /><el-skeleton-item
                      variant="button "
                      style="width: 40px; height: 5px"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template>
              <member-message-item
                @searchTopic="searchTopic"
                @searchMention="searchMention"
                v-for="(item, index) in list"
                :key="item.moment_id"
                @changeMenu="changeMenu($event, index)"
                :messageInfo="item"
                @openDetail="openDetail(index)"
                @clickAvatar="clickAvatar"
                @incrementComment="incrementComment(index)"
              ></member-message-item>
            </template>
          </el-skeleton>
        </div>
        <member-empty
          class="no-member-home"
          v-if="list.length === 0"
          icon="#icon-Frame14"
          title="暂无动态~"
        ></member-empty>
      </div>
    </scroll-fresh>
    <edit-message
      :visible="editVisible"
      @addComplete="addComplete"
      @editComplete="editComplete"
      :momentId="editMomentId"
      @close="handlerCloseDialog"
    ></edit-message>
    <message-detail-pop
      @searchTopic="searchTopic"
      @searchMention="searchMention"
      :visible="detailVisible"
      :momentId="detailMomentId"
      @close="closeDetail"
      @toEdit="detailToEdit"
    ></message-detail-pop>
  </div>
</template>

<script>
import MemberHeader from '@/components/member/MemberHeader.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
// 侧边详情
import MessageDetailPop from '@/components/circle/MessageDetailPop.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
// 动态组件
import MemberMessageItem from '@/components/member/MemberMessageItem.vue'
import MemberEmpty from '@/components/member/MemberEmpty.vue'

import { EventBusOn, EventBusOff, EventBusEmit } from '@/util/EventBus.js'

import { mapState } from 'vuex'
export default {
  components: {
    MemberHeader,
    MessageDetailPop,
    EditMessage,
    ScrollFresh,
    MemberMessageItem,
    MemberEmpty
  },
  data() {
    return {
      tabs: ['全部', '图文', '视频'],
      mobileTabs: ['动态', '关注', '喜欢', '消息'],
      // 职员id
      clerkId: '',
      // 职员信息
      clerkInfo: {},
      currentTab: 0,
      pageNumber: 1,
      pageSize: 10,
      totalPage: 2,
      totalRow: 0,
      // 修改的id
      editMomentId: -1,
      // 详情的id
      detailMomentId: -1,
      // 修改展示
      editVisible: false,
      // 详情展示
      detailVisible: false,
      list: [],
      isShowComment: false,
      coPageNumber: 1,
      coPageSize: 5,
      commentList: [],
      placeholderText: '发表评论',
      showCommentDeleteId: -1,
      replyCommentId: '',
      replyText: '',
      searchKey: '',
      loading: false,
      topicId: ''
    }
  },
  computed: {
    ...mapState(['myClerkId', 'screenWidth'])
  },
  methods: {
    handerConfirm(e) {
      this.searchKey = e.value
      this.refreshList()
    },
    // 获取职员信息
    async getClerkInfo() {
      const formObj = {
        clerk_id: this.clerkId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentClerkInfo, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this, 'clerkInfo', res.info)
      }
    },
    // 评论数量加 1
    incrementComment(index) {
      this.$set(
        this.list[index],
        'comment_number',
        this.list[index].comment_number + 1
      )
    },
    // 获取圈子列表
    async getList(reset = false) {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        content_type: this.currentTab > 0 ? this.currentTab : '',
        clerk_id: this.clerkId === '' ? 0 : this.clerkId,
        search_key: this.searchKey,
        topic_id: this.topicId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res.page.list)
        if (reset) {
          this.list = res.page.list
        } else {
          this.list = this.list.concat(res.page.list)
        }

        this.totalPage = res.page.totalPage
        this.pageNumber = res.page.pageNumber
        this.totalRow = res.page.totalRow
      }
    },
    // 返回圈子首页
    goHome() {
      this.$router.back()
      // this.$router.push('/circle')
    },
    // 打开详情
    openDetail(index) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, detail_id: this.list[index].moment_id }
      })
      // this.detailMomentId = this.list[index].moment_id
      // this.detailVisible = true
    },
    // 关闭详情
    closeDetail() {
      const query = { ...this.$route.query }
      delete query.detail_id
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: query
      })
      // this.detailMomentId = -1
      // this.detailVisible = false
    },
    // 详情跳转修改
    detailToEdit() {
      this.editMomentId = this.detailMomentId
      this.detailMomentId = -1
      this.detailVisible = false
      this.editVisible = true
    },
    // 关闭编辑弹窗
    handlerCloseDialog() {
      this.editVisible = false
      this.editMomentId = -1
    },
    // 组件回调
    changeTab(e) {
      if (this.screenWidth <= 767) {
        EventBusEmit('getMobileTabs', { pageName: 'memberHome', current: e })
        return
      }

      if (this.currentTab === e) {
        if (this.$route.query.topic_id) {
          this.topicId = ''
          const query = { ...this.$route.query }
          delete query.topic_id
          this.$router.replace({
            name: this.$route.name,
            query: { ...query }
          })
        }
        this.refreshList()
        return
      }
      // this.currentTab = e
      // this.refreshList()
      let tab = ''
      switch (e) {
        case 1:
          tab = 'article'
          break
        case 2:
          tab = 'video'
          break
        case 3:
          tab = 'knowledge'
          break
      }
      const query = { ...this.$route.query }
      console.log(this.$route)
      delete query.tab
      delete query.topic_id
      this.$router.replace({
        name: this.$route.name,
        query: tab ? { ...query, tab: tab } : query
      })
      // this.currentTab = e
      // this.refreshList()
    },
    // 添加圈子回调
    addComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.refreshList()
    },
    // 修改圈子回调
    editComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.refreshList()
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.loading = true
      // this.topicId = ''
      // this.$refs.scrollFreshRef.handleBackTop()
      await this.getList(true)
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 话题刷新列表
    async topicRefreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.loading = true
      // this.$refs.scrollFreshRef.handleBackTop()
      await this.getList(true)
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 获取下一页
    async getNextPage() {
      this.pageNumber++
      this.getList()
    },
    // 圈子Menu回调
    async changeMenu(ob, index) {
      switch (ob.type) {
        // 删除
        case 'delete': {
          this.momentDelte(index)
          break
        }
        // 复制链接
        case 'copy': {
          this.copyLink(index)
          break
        }
        // 编辑
        case 'edit': {
          this.editMomentId = this.list[index].moment_id
          this.editVisible = true
          break
        }
      }
    },
    // 圈子删除
    async momentDelte(index) {
      this.$confirm('此操作将删除该圈子, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.deleteMoment, {
            moment_id: this.list[index].moment_id
          })
        )
        if (err) {
          this.$message.error(err)
        } else if (res.state === 'ok') {
          this.list.splice(index, 1)
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
        }
      })
    },
    // 复制链接
    copyLink(index) {
      this.$copyText(
        `${this.$config.frontHome}topicInfo/${this.list[index].moment_id}`
      ).then(
        () => {
          this.$message({
            showClose: true,
            message: '已成功复制，可直接去粘贴',
            type: 'success'
          })
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // 刷新列表回调
    async refreshMomentListCallback({ search_key: searchKey = '' }) {
      console.log('emit')
      this.searchKey = searchKey
      await this.refreshList()
    },
    // 点击头像
    clickAvatar(clerkId) {
      if (this.myClerkId === clerkId) {
        this.$router.push({
          name: 'memberHome',
          params: { clerkId: clerkId }
        })
      } else {
        this.$router.push({
          name: this.$route.name,
          params: { clerkId: clerkId }
        })
      }
    },
    // 搜索话题
    searchTopic(e) {
      this.closeDetail()
      this.topicId = e
      this.detailVisible = false
      const query = { ...this.$route.query }
      this.$router.replace({
        name: 'memberHome',
        params: this.$route.params,
        query: { ...query, topic_id: e }
      })
      // this.topicRefreshList()
    },
    // 进入提及人主页
    searchMention(e) {
      this.closeDetail()
      this.detailVisible = false
      if (this.myClerkId === e) {
        this.$router.push({
          name: 'memberHome',
          params: { clerkId: this.elementId }
        })
      } else {
        this.$router.push({
          name: 'circleHome',
          params: { clerkId: e }
        })
      }
    }
  },
  destroyed() {
    EventBusOff('refreshMomentList')
  },
  mounted() {
    EventBusOn('refreshMomentList', this.refreshMomentListCallback)
    // 打开详情
    if (this.$route?.query?.detail_id) {
      // this.detailMomentId = this.$route.query.detail_id
      // this.detailVisible = true
      this.$router.replace({
        name: 'topicInfo',
        params: {
          id: Number(this.$route.query.detail_id)
        }
      })
    }
    if (this.$route?.params?.clerkId) {
      this.clerkId = this.$route.params.clerkId || this.myClerkId
    } else {
      this.clerkId = this.myClerkId
    }
    // 传回话题id
    if (this.$route?.query?.topic_id) {
      this.topicId = this.$route.query.topic_id
    }
    // 切换tab
    if (this.$route?.query?.tab) {
      switch (this.$route.query.tab) {
        case 'article':
          this.currentTab = 1
          break
        case 'video':
          this.currentTab = 2
          break
        case 'knowledge':
          this.currentTab = 3
          break
      }
    }
    this.getClerkInfo()
    this.refreshList()
    EventBusOn('addMoment', (e) => {
      this.refreshList()
    })
  },
  watch: {
    $route(val, old) {
      let isRefreshList = false
      if (this.clerkId !== (val.params.clerkId || '')) {
        this.clerkId = val.params.clerkId || ''
        this.getClerkInfo()
        isRefreshList = true
      }
      // 打开详情
      if (val?.query?.detail_id) {
        this.detailMomentId = val?.query?.detail_id
        this.detailVisible = true
      } else {
        this.detailMomentId = -1
        this.detailVisible = false
      }
      if (val?.query?.topic_id) {
        this.topicId = val?.query?.topic_id
        if (val?.query?.topic_id !== old?.query?.topic_id) {
          isRefreshList = true
        }
      } else {
        this.topicId = ''
      }

      // 切换tab
      if (val?.query?.tab !== old?.query?.tab) {
        switch (val.query?.tab) {
          case 'article':
            this.currentTab = 1
            break
          case 'video':
            this.currentTab = 2
            break
          case 'knowledge':
            this.currentTab = 3
            break
          default:
            this.currentTab = 0
            break
        }
        isRefreshList = true
      }
      if (isRefreshList) {
        this.refreshList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.member-home {
  position: absolute;
  height: calc(100vh - 84px);
  overflow: auto;
  left: 0;
  top: 0;
  width: 100vw;
  padding-top: 84px;

  ::v-deep .no-more-tip {
    margin-left: -273px;
  }

  .no-member-home {
    background-color: #fff;
  }

  .title-bar {
    height: 21px;
    line-height: 21px;
    padding: 16px 0;
    color: #333333;
  }

  .member-home-container {
    position: relative;
    width: 640px;
    left: calc((100vw - 962px) / 2);
  }

  ::v-deep .message-item:last-child {
    margin-bottom: 0;
  }

  ::v-deep .back-top {
    margin-left: 500px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.empty-container {
  padding: 100px 0 30px 0;
}
.el-skeleton-item {
  margin-bottom: 20px;
  padding: 19px 24px;
  background-color: #fff;
  .el-skeleton-item-content {
    position: relative;
  }
  .el-skeleton-item-header {
    margin-top: 8px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .member-home {
    padding-top: 0;
    .member-home-container {
      width: 100vw;
      left: 0;
    }

    .title-bar {
      padding-left: 10px;
    }
  }
}
</style>
