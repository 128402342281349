<template>
  <!-- 培训师详情 -->
  <div class="trainer-detail-page" style="position: relative">
    <div
      class="trainer-detail-top flex flex-x-between flex-y-center"
      style="position: absolute; top: 0"
    >
      <button class="return" @click="$emit('close')">返回</button>
      <!-- <span>{{ info.clerk_alias || info.clerk_name }}</span> -->
      <div class="trainer-top-function flex">
        <!-- 复制 -->
        <div class="copy-box flex flex-y-center">
          <el-tooltip
            class="item"
            effect="dark"
            content="复制链接"
            placement="bottom"
          >
            <p class="id-text" @click="copyLink">#{{ trainerId }}</p>
          </el-tooltip>
          <p class="tip-text" v-if="isShowTip">链接已复制</p>
        </div>
      </div>
    </div>
    <div class="trainer-detail-scroll">
      <div class="trainer-detail-box flex flex-y-positive">
        <div class="trainer-message flex">
          <!-- 培训师头像 -->
          <img
            class="trainer-img"
            :src="
              info.cover_access_path + '?x-oss-process=style/clerk_tutor_cover'
            "
          />
          <div class="trainer-detail">
            <!-- 培训师名称 -->
            <div class="trainer-name flex flex-y-end">
              <span>{{ info.clerk_alias || info.clerk_name }}</span>
            </div>
            <!-- 培训师职位 -->
            <div class="professional-title">{{ info.main_dept_name }}</div>
            <!-- 培训师徽章 -->
            <div class="trainer-medal flex" v-if="isCloseMedal(info.type_list)">
              <div
                v-for="(cItem, cIndex) in info.type_list || []"
                :key="cIndex"
              >
                <el-popover placement="bottom-start" trigger="hover">
                  <template>
                    <img
                      :src="cItem.cover_access_path"
                      alt=""
                      style="width: 150px; height: 150px; border-radius: 50%"
                    />
                  </template>
                  <img
                    slot="reference"
                    v-if="cItem.cover_access_path"
                    :src="cItem.cover_access_path"
                    alt=""
                  />
                </el-popover>
              </div>
            </div>
            <!-- 培训师标签 -->
            <div class="trainer-tag">
              <span v-for="(item, index) in info.type_list" :key="index"
                >{{ item.type_name
                }}{{ item.type_level | renderTypeLevel }}</span
              >
            </div>
          </div>
        </div>
        <!-- 培训师介绍 -->
        <div
          class="trainer-introduction htmledit_views"
          @click="imageChgange($event)"
          v-html="info.tutor_describe_html"
        ></div>
        <el-image
          ref="previewImageRef"
          :preview-src-list="[previewImageSrc]"
          v-show="false"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'trainer-detail',
  components: {},
  props: {
    trainerId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      isShowTip: false,
      info: {},
      // 图片预览
      previewImageSrc: ''
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    // 是否关闭徽章列
    isCloseMedal() {
      return (e) => {
        let flag = true
        if (e) {
          const index = e.findIndex((t) => {
            return t.cover_access_path !== ''
          })
          if (index === -1) {
            flag = false
          }
        } else {
          flag = false
        }
        return flag
      }
    }
  },
  filters: {
    renderTypeLevel(val) {
      switch (val) {
        case 1:
          return '/初级'
        case 2:
          return '/中级'
        case 3:
          return '/高级'
      }
    }
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink = Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`)
        : url
    },
    async init() {
      await this.getDetail()
    },
    // 获取详情
    async getDetail() {
      const formObj = {
        clerk_id: this.trainerId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkTutorDetail, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.info = res.info
      }
    },
    // 复制链接
    copyLink() {
      this.$copyText(
        `${this.$config.frontHome}tutorDetail/${this.trainerId}`
      ).then(
        () => {
          // this.$message({
          //   showClose: true,
          //   message: '已成功复制，可直接去粘贴',
          //   type: 'success'
          // })
          this.isShowTip = true
          // 2秒后提示隐藏
          setTimeout(() => {
            this.isShowTip = false
          }, 2000)
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    imageChgange(e) {
      if (e.target.nodeName === 'IMG') {
        this.previewImageSrc = e.target.src
        this.$refs.previewImageRef.showViewer = true
        console.log(e.target.src)
      }
    }
  },
  mounted() {
    if (this.trainerId !== -1) {
      this.init()
    }
  },
  watch: {
    trainerId(val) {
      if (val !== -1) {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.trainer-detail-page {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-sizing: border-box;
  padding-top: 70px;

  .trainer-detail-top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 70px;
    padding: 0 34px 0 48px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;

    span {
      font-size: 18px;
      line-height: 30px;
      color: #999999;
    }
    .return {
      width: 56px;
      height: 36px;
      background: #bbb;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      margin-left: 21px;
      cursor: pointer;
    }
    .trainer-top-function {
      .copy-box {
        position: relative;
        // 提示文字
        .tip-text {
          right: 0;
          position: absolute;
          font-size: 14px;
          line-height: 1.5;
          white-space: nowrap;
          background: #fff;
          color: $main-color;
        }
        .id-text {
          margin-right: 12px;
          font-size: 14px;
          line-height: 1.5;
          cursor: pointer;
          color: #bbb;
        }
      }
    }
  }

  .trainer-detail-scroll {
    padding: 40px 10px 10px 10px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .trainer-detail-box {
    width: 820px;
    margin: 0 auto;

    .trainer-message {
      width: 820px;
      .trainer-img {
        width: 164px;
        height: 200px;
        background: #bbbbbb;
      }
      .trainer-detail {
        margin-left: 30px;
        .trainer-name {
          span {
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            color: #333333;
          }
        }
        .professional-title {
          font-size: 16px;
          line-height: 24px;
          color: #999999;
          margin-top: 10px;
        }
        .trainer-medal {
          height: 34px;
          margin-top: 12px;
          img {
            width: 34px;
            height: 34px;
            margin-right: 12px;
            border-radius: 50%;
          }
        }
        .trainer-tag {
          width: 626px;
          margin-top: 14px;
          span {
            display: inline-block;
            height: 28px;
            background: #0080ff;
            border-radius: 26px;
            box-sizing: border-box;
            padding: 6px 13px 6px 13px;
            font-size: 12px;
            color: #ffffff;
            margin-right: 12px;
          }
        }
      }
    }
    .trainer-introduction {
      margin-top: 24px;
      width: 820px;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }
  }
}
</style>
