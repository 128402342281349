<!--
 * @Author: Betty
 * @Date: 2021-07-13 11:16:10
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-23 18:41:11
 * @Description: 目标列表组件
-->
<template>
  <div class="target-box white-box">
    <!-- 盒子的头部 -->
    <div class="flex flex-y-center white-box-header flex-x-between">
      <!-- 左边 -->
      <div class="flex flex-y-center">
        <h6 class="white-box-title">目标</h6>
        <p class="count">共{{ targetListOld.length }}个</p>
      </div>
      <!-- 右边 -->
      <div class="flex flex-y-center header-right">
        <!-- <router-link class="more" :to="`/workspace`">MORE</router-link> -->
        <button type="button" class="more-btn" @click.stop="toFresh">
          <span class="iconfont icon-gengduo2"></span>
        </button>
        <!-- 更多弹窗 -->
        <div class="more-box refresh" v-show="isShowFresh">
          <div class="more-item" @click="fresh">刷新</div>
        </div>
      </div>
    </div>
    <!-- 主体部分，目标数据 -->
    <div class="main-box">
      <div class="main-box-header flex flex-y-center hidden-xs-only">
        <div class="main-header-item target-name-box">目标名称</div>
        <div class="main-header-item relative-box">目标关联</div>
        <div class="main-header-item type-box">目标类型</div>
        <div class="main-header-item time-box">截止时间</div>
        <div class="main-header-item progress-box">完成进度</div>
      </div>
      <!-- 下面的每一行 -->
      <div class="target-data-box">
        <target-item
          @to-fresh-target="getTargetList"
          v-for="(target, index) in targetList"
          :key="index"
          :target="target"
          :nowTime="nowTime"
          :currentTarget="currentTarget"
          @show-type-choose="toChooseType($event)"
          @show-progress-set="toSetProgress($event)"
          @show-more="toShowMore($event)"
          @to-choose-relative="toChooseParent($event)"
          @to-target-detail="toTargetDetail($event)"
        ></target-item>
      </div>
    </div>
    <!-- 下面的添加目标 -->
    <!-- 底部的新增任务的地方 -->
    <div class="task-add-box flex flex-y-center">
      <!-- 按钮 -->
      <button
        class="add-btn"
        type="button"
        v-show="!isInputTarget"
        @click="beginInput"
      >
        <span class="iconfont icon-add add-icon"></span>
        添加目标
      </button>
      <!-- 输入框 -->
      <input
        type="text"
        class="task-input"
        placeholder="输入目标名称，按回车保存，按ESC取消"
        v-show="isInputTarget"
        ref="targetInput"
        v-model.trim="newTarget"
        @blur="handleInputTarget"
        @keydown.13="toBlur($event)"
        @keydown.229="() => {}"
        @keyup.esc="isEscAdd = true"
      />
    </div>
    <!-- 选择关联目标 -->
    <target-relative-modal
      :isShowRelative="isChooseRelative"
      :myTarget="myTarget"
      @close-modal="closeRelativeModal"
      @to-fresh-target="getTargetList"
    ></target-relative-modal>
    <!-- <target-detail
      :targetId="detailTargetId"
      :isShow="isShowDetail"
      :fromWhere="fromWhere"
      :nowTime="nowTime"
      @close-detail="closeDetailModal($event)"
      @change-target-id="changeDetailId($event)"
    ></target-detail> -->
  </div>
</template>

<script>
// 导入单个目标的组件
import TargetItem from './TargetItem.vue'
// 导入选择上级目标关联的弹窗
import TargetRelativeModal from './TargetRelativeModal.vue'
// import TargetDetail from './TargetDetail.vue'
export default {
  name: 'TargetListBox',
  components: {
    TargetItem,
    TargetRelativeModal
    // TargetDetail
  },
  props: {
    // 当前时间
    nowTime: {
      type: Date
    },
    // 父组件当前选中的目标id
    currentTarget: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // 是否显示刷新按钮
      isShowFresh: false,
      // 目标数据
      targetList: [],
      // 目标的线性的原始数据
      targetListOld: [],
      // 是否要输入目标
      isInputTarget: false,
      // 输入的新目标
      newTarget: '',
      // 是否选择关联弹窗
      isChooseRelative: false,
      // 当前正在操作的目标
      myTarget: null,
      // 是否展示目标
      isShowDetail: false,
      // 当前展示目标详情对应的目标id
      detailTargetId: -1,
      // 目标详情的来源，默认为空
      fromWhere: null,
      // 当前要编辑的子组件
      currentChild: null,
      // 是否触发添加esc
      isEscAdd: false
    }
  },
  computed: {},
  mounted() {
    this.getTargetList()
    document.addEventListener('click', this.clickOut)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOut)
  },
  methods: {
    // 获取目标列表数据
    async getTargetList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetList, {
          is_complete: 0
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res)
        const list = res.list
        // 把它整理成树形
        this.targetList = this.$util.handleTreeData(list, 'objective_id')
        this.targetListOld = list
        console.log('目标转成树形了', this.targetList)
      }
    },
    // 触发失焦事件
    toBlur(e, arg) {
      e.target.blur(arg)
    },
    // 开始输入目标
    beginInput() {
      this.isInputTarget = true
      this.$nextTick(() => {
        this.$refs.targetInput.focus()
      })
    },
    // 确定输入目标
    async handleInputTarget() {
      if (this.newTarget.length <= 0) {
        this.hideInputTarget()
        this.isEscAdd = false
      }
      if (this.isEscAdd) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.hideInputTarget()
          this.isEscAdd = false
          return
        }
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addTarget, {
          'objective.objective_name': this.newTarget,
          'objective.period_date': this.$util.getDateStr(this.nowTime),
          is_add_first_group: 1
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 添加成功，给出提示
        this.$message({
          type: 'success',
          message: '添加目标成功!',
          showClose: true
        })
        this.hideInputTarget()
        // 刷新目标列表
        this.getTargetList()
      }
      this.isEscAdd = false
    },
    // 取消输入目标
    hideInputTarget() {
      this.isInputTarget = false
      this.newTarget = ''
    },
    // 点击显示出刷新弹窗
    toFresh() {
      this.isShowFresh = true
    },
    // 点击刷新
    fresh() {
      this.getTargetList().then(() => {
        this.isShowFresh = false
      })
    },
    // 单个目标触发的事件，在这里做一个传递的作用
    toChooseType($event) {
      this.$emit('show-type-choose', $event)
    },
    toSetProgress($event) {
      this.$emit('show-progress-set', $event)
    },
    toShowMore($event) {
      this.$emit('show-more', $event)
    },
    // 开始编辑
    readyToEdit() {
      // this.$children是一个数组，而且是树形的数组，在这里做一个深度优先遍历，去找到那个被点击的组件
      this.$children.forEach((item) => {
        // 定义一个遍历方法
        const map = (data) => {
          // 如果找到了就直接记录一下
          if (
            data.$el.className === 'target-item-box' &&
            data.$options.propsData.target &&
            data.$options.propsData.target.objective_id === this.currentTarget
          ) {
            this.currentChild = data
          } else {
            // 如果没有找到，那就接着找它的子组件
            data.$children &&
              data.$children.forEach((child) => {
                map(child)
              })
          }
        }
        map(item)
      })
      // 找到对应子组件，让子组件调用准备编辑的方法
      this.currentChild.readyToEdit()
      // const index = this.targetListOld.findIndex(
      //   (item) => item.objective_id === this.currentTarget
      // )
      // 找到是哪一个子组件，调用它的方法
      // this.$nextTick(() => {
      //   for (let i = 0, itemIndex = 0; i < this.$children.length; i++) {
      //     if (this.$children[i].$options._componentTag === 'target-item') {
      //       if (itemIndex++ === index) {
      //         this.$children[i].readyToEdit()
      //         break
      //       }
      //     }
      //   }
      // })
    },
    // 选择相关目标
    toChooseParent(target) {
      this.isChooseRelative = true
      this.myTarget = target
    },
    // 关闭关联弹窗
    closeRelativeModal() {
      this.isChooseRelative = false
    },
    // 展示出详情
    toTargetDetail(id) {
      // this.isShowDetail = true
      // this.detailTargetId = id
      this.$emit('to-target-detail', id)
    },
    // 关闭详情弹窗
    closeDetailModal({ is_update_data: flag }) {
      this.isShowDetail = false
      this.fromWhere = null
      console.log('关闭目标详情', flag)
      if (flag) {
        this.getTargetList()
      }
    },
    // 切换选择目标的详情
    changeDetailId({ id, fromWhere }) {
      this.detailTargetId = id
      this.fromWhere = fromWhere
    },
    // 点到外面
    clickOut() {
      this.isShowFresh = false
    }
  },
  watch: {
    isEscAdd(val) {
      if (val) {
        this.$refs.targetInput.blur()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/home_target_style.scss';
@media screen and (max-width: 768px) {
  .white-box.target-box {
    padding: 8px 10px 25px !important;
  }
  .relative-box {
    width: 80px !important;
  }

  .task-add-box {
    margin-left: 0 !important;
  }
}
</style>
