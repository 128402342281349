<template>
  <div>
    <template v-for="item in dataList">
      <!-- 有下级菜单 -->
      <el-submenu
        :key="item.id"
        :index="item.path"
        v-if="item.child && item.child.length > 0"
      >
        <template slot="title">
          <svg
            style="margin-right: 10px;font-size: 20px;width: 1em;height: 1em;"
          >
            <use :xlink:href="'#' + item.icon"></use>
          </svg>
          <span slot="title">{{ item.name }}</span>
        </template>
        <menu-item :data-list="item.child"></menu-item>
      </el-submenu>

      <!-- 无下级菜单 -->
      <el-menu-item
        :route="{ path: item.path }"
        :key="item.id"
        :index="item.path"
        v-else
      >
        <svg style="margin-right: 10px;font-size: 20px;width: 1em;height: 1em;">
          <use :xlink:href="'#' + item.icon"></use>
        </svg>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    dataList: {
      type: Array,
      default: function() {
        return []
      }
    }
  }
}
</script>

<style></style>
