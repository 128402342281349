<!--
 * @Date: 2021-05-24 15:17:00
 * @LastEditTime: 2021-12-11 11:17:08
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div
    id="app"
    v-watermark="{ text: watermark, textColor: 'rgba(180, 180, 180, 0.15)' }"
  >
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
// 导入并使用头部组件
import { mapState, mapMutations } from 'vuex'
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState(['myClerkId', 'myClerkInfo']),
    watermark() {
      return `${this.myClerkInfo.clerk_name}${
        this.myClerkInfo.clerk_alias
          ? '(' + this.myClerkInfo.clerk_alias + ')'
          : ''
      }${
        this.myClerkInfo.clerk_mobile
          ? this.myClerkInfo.clerk_mobile.slice(-4)
          : ''
      }`
    }
  },
  beforeCreate() {},
  created() {
    // 如果是企业微信默认重新登录
    // if (this.isWxWork()) {
    //   this.logout()
    // }
    this.setRoute(this.$route)
  },
  mounted() {
    document.addEventListener('click', this.clickBody)
    document.addEventListener('mousewheel', this.mouseWheel)
    window.addEventListener('resize', () => {
      this.$store.commit('setWindowsWidth', document.body.clientWidth)
    })
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickBody)
  },
  methods: {
    ...mapMutations(['setRoute', 'clickBody', 'mouseWheel'])
  },
  watch: {},
  destroyed() {
    document.removeEventListener('click', this.clickBody)
    document.removeEventListener('mousewheel', this.mouseWheel)
  }
}
</script>

<style lang="scss">
@import url('assets/css/flex.css');
html {
  width: 100vw;
  overflow-x: auto;
}
// 全局icon匹配
[class^='icon-'] {
  font-family: 'iconfont' !important;
  font-style: normal;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

// 超出一行/两行变成省略号
.e {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.e2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

// 蒙版
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar {
  height: 12px;
}
</style>
