<!--
 * @Author: hzh
 * @Date: 2021-12-17 17:24:13
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-24 10:30:02
-->
<template>
  <div
    class="content"
    :class="{
      xs: isXs,
      sm: isSm,
      md: isMd,
      lg: isLg,
      xl: isXl
    }"
  >
    <el-row :gutter="20">
      <el-col :span="[24, 24, 6, 6, 6][widthTypeIndex]" class="total">
        <el-row
          type="flex"
          class="flex flex-wrap"
          :gutter="10"
          :class="
            ['', '', 'flex-y-between', 'flex-y-between', 'flex-y-between'][
              widthTypeIndex
            ]
          "
        >
          <el-col :span="[12, 12, 24, 24, 24][widthTypeIndex]"
            ><el-card
              ><template #header>
                <span>本周目标 / 总目标</span>
                <el-popover placement="top" width="300" trigger="click">
                  <template #default
                    ><p class="tip-text">
                      本周目标：截止时间、完成时间在本周+截止时间在本周前未完成的延期目标
                    </p>
                    <p class="tip-text">
                      总目标：本周目标+进行中的目标（截止时间在本周之后或未设置截止时间的目标）
                    </p></template
                  >
                  <template #reference>
                    <i class="iconfont icon-xinxi"></i> </template
                ></el-popover>
              </template>
              <template #default>
                <span class="left"
                  >{{ reportData.objective_cycle_total_number || 0 }} /
                  {{ reportData.objective_total_number || 0 }}</span
                >
                <span class="right"
                  >较上周期：<span
                    class="right-number"
                    v-if="hasPrevReportData"
                    :class="{
                      up: objectiveTotalNumberComparePrev > 0,
                      down: objectiveTotalNumberComparePrev < 0
                    }"
                    >{{ objectiveTotalNumberComparePrev }}%</span
                  ></span
                >
              </template></el-card
            ></el-col
          >
          <el-col :span="[12, 12, 24, 24, 24][widthTypeIndex]"
            ><el-card
              ><template #header>
                <span>新增目标</span>
              </template>
              <template #default>
                <span class="left">{{
                  reportData.objective_create_number || 0
                }}</span>
                <span class="right"
                  >较上周期：<span
                    class="right-number"
                    v-if="hasPrevReportData"
                    :class="{
                      up: objectiveCreateNumberComparePrev > 0,
                      down: objectiveCreateNumberComparePrev < 0
                    }"
                    >{{ objectiveCreateNumberComparePrev }}%</span
                  ></span
                >
              </template></el-card
            ></el-col
          >
          <el-col :span="[12, 12, 24, 24, 24][widthTypeIndex]"
            ><el-card
              ><template #header>
                <span>延期目标</span>
              </template>
              <template #default>
                <span class="left">{{
                  reportData.objective_delay_number || 0
                }}</span>
                <span class="right"
                  >较上周期：<span
                    class="right-number"
                    v-if="hasPrevReportData"
                    :class="{
                      up: objectiveDelayNumberComparePrev > 0,
                      down: objectiveDelayNumberComparePrev < 0
                    }"
                    >{{ objectiveDelayNumberComparePrev }}%</span
                  ></span
                >
              </template></el-card
            ></el-col
          >
          <el-col :span="[12, 12, 24, 24, 24][widthTypeIndex]"
            ><el-card
              ><template #header>
                <span>完成目标</span>
              </template>
              <template #default>
                <span class="left">{{
                  reportData.objective_complete_number || 0
                }}</span>
                <span class="right"
                  >完成率：<span class="right-number"
                    >{{ objectiveCompleteRate }}%</span
                  ></span
                >
              </template></el-card
            ></el-col
          >
        </el-row>
      </el-col>
      <el-col :span="[24, 24, 9, 9, 9][widthTypeIndex]">
        <el-card class="chart">
          <template #header>
            <span>本周目标概况</span>
            <el-popover placement="top" width="260" trigger="click">
              <template #default
                ><p class="tip-text">
                  完成进度概况：总目标的的完成进度占比
                </p></template
              >
              <template #reference>
                <i class="iconfont icon-xinxi"></i> </template
            ></el-popover>
          </template>
          <template #default>
            <div ref="BZMBGK"></div>
          </template>
        </el-card>
      </el-col>
      <el-col :span="[24, 24, 9, 9, 9][widthTypeIndex]">
        <el-card class="chart">
          <template #header>
            <span>完成进度概况</span>
          </template>
          <template #default>
            <div ref="WCJDGK"></div>
          </template>
        </el-card>
      </el-col>
      <el-col :span="24">
        <el-card class="chart"
          ><template #header>
            <span>完成进度明细（{{ totalNumber }}）</span></template
          ><template #default> <div ref="WCJDMX"></div></template
        ></el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'

const WIDTH_TYPE_LIST = ['xs', 'sm', 'md', 'lg', 'xl']

export default {
  name: 'workReportWeekClerkTarget',
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          data_list: [],
          columnar_list: [],
          report_data: {},
          prev_report_data: {}
        }
      }
    },
    widthType: {
      type: String,
      default: 'lg',
      validator(val) {
        return WIDTH_TYPE_LIST.includes(val)
      }
    }
  },
  data() {
    return {
      // 本周目标概况-图表对象
      BZMBGKChart: null,
      // 完成进度概况-图表对象
      WCJDGKChart: null,
      // 完成进度明细-图表对象
      WCJDMXChart: null
    }
  },
  computed: {
    widthTypeIndex() {
      return WIDTH_TYPE_LIST.indexOf(this.widthType)
    },
    isXs() {
      return this.widthTypeIndex === 0
    },
    isSm() {
      return this.widthTypeIndex === 1
    },
    isMd() {
      return this.widthTypeIndex === 2
    },
    isLg() {
      return this.widthTypeIndex === 3
    },
    isXl() {
      return this.widthTypeIndex === 4
    },
    // 数据列表
    dataList() {
      return this.data.data_list
    },
    // 柱状数据列表
    columnarList() {
      return this.data.columnar_list
    },
    // 周期数据
    reportData() {
      return this.data.report_data
    },
    //  上周期数据
    prevReportData() {
      return this.data.prev_report_data
    },
    // 职员
    clerkId() {
      return this.reportData.clerk_id
    },
    // 总数量
    totalNumber() {
      return this.dataList.length
    },
    // 是否有上周期数据
    hasPrevReportData() {
      return this.prevReportData?.report_id != null
    },
    // 目标数量比较上周期 （本周期总目标 - 上周期总目标）/ 上周期总目标
    objectiveTotalNumberComparePrev() {
      // 暂无数据返回0
      if (this.reportData.cycle_id == null) {
        return 0
      }
      // 这周数据是0的时候
      if (this.reportData.objective_total_number === 0) {
        // 上周数据大于0
        if (this.prevReportData.objective_total_number > 0) {
          return this.$util
            .floatMul(this.prevReportData.objective_total_number, -100)
            .toFixed(2)
        }
        // 上周数据不存在或为0时
        return 0
      }
      // 上周数据大于0
      if (this.prevReportData.objective_total_number > 0) {
        // 上周数据和这周数据相等时返回0
        if (
          this.prevReportData.objective_total_number ===
          this.reportData.objective_total_number
        ) {
          return 0
        }
        return this.$util
          .floatMul(
            this.$util.floatDiv(
              this.$util.floatSub(
                this.reportData.objective_total_number,
                this.prevReportData.objective_total_number
              ),
              this.prevReportData.objective_total_number
            ),
            100
          )
          .toFixed(2)
      }
      // 上周数据等0或不存在
      return this.$util
        .floatMul(this.reportData.objective_total_number, 100)
        .toFixed(2)
    },
    // 新增目标数量比较上周期 （本周期新增目标 - 上周期新增目标）/ 上周期新增目标
    objectiveCreateNumberComparePrev() {
      // 暂无数据返回0
      if (this.reportData.cycle_id == null) {
        return 0
      }
      // 这周数据是0的时候
      if (this.reportData.objective_create_number === 0) {
        // 上周数据大于0
        if (this.prevReportData.objective_create_number > 0) {
          return this.$util
            .floatMul(this.prevReportData.objective_create_number, -100)
            .toFixed(2)
        }
        // 上周数据不存在或为0时
        return 0
      }
      // 上周数据大于0
      if (this.prevReportData.objective_create_number > 0) {
        // 上周数据和这周数据相等时返回0
        if (
          this.prevReportData.objective_create_number ===
          this.reportData.objective_create_number
        ) {
          return 0
        }
        return this.$util
          .floatMul(
            this.$util.floatDiv(
              this.$util.floatSub(
                this.reportData.objective_create_number,
                this.prevReportData.objective_create_number
              ),
              this.prevReportData.objective_create_number
            ),
            100
          )
          .toFixed(2)
      }
      // 上周数据等0或不存在
      return this.$util
        .floatMul(this.reportData.objective_create_number, 100)
        .toFixed(2)
    },
    // 延期目标数量比较上周期 （本周期延期目标 - 上周期延期目标）/ 上周期延期目标
    objectiveDelayNumberComparePrev() {
      // 暂无数据返回0
      if (this.reportData.cycle_id == null) {
        return 0
      }
      // 这周数据是0的时候
      if (this.reportData.objective_delay_number === 0) {
        // 上周数据大于0
        if (this.prevReportData.objective_delay_number > 0) {
          return this.$util
            .floatMul(this.prevReportData.objective_delay_number, -100)
            .toFixed(2)
        }
        // 上周数据不存在或为0时
        return 0
      }
      // 上周数据大于0
      if (this.prevReportData.objective_delay_number > 0) {
        // 上周数据和这周数据相等时返回0
        if (
          this.prevReportData.objective_delay_number ===
          this.reportData.objective_delay_number
        ) {
          return 0
        }
        return this.$util
          .floatMul(
            this.$util.floatDiv(
              this.$util.floatSub(
                this.reportData.objective_delay_number,
                this.prevReportData.objective_delay_number
              ),
              this.prevReportData.objective_delay_number
            ),
            100
          )
          .toFixed(2)
      }
      // 上周数据等0或不存在
      return this.$util
        .floatMul(this.reportData.objective_delay_number, 100)
        .toFixed(2)
    },
    // 目标完成率 完成目标/本周目标
    objectiveCompleteRate() {
      if (this.reportData.cycle_id == null) {
        return 0
      }
      if (this.reportData.objective_complete_number === 0) {
        return 0
      }
      if (this.reportData.objective_cycle_total_number === 0) {
        return 0
      }
      return this.$util
        .floatMul(
          this.$util.floatDiv(
            this.reportData.objective_complete_number,
            this.reportData.objective_cycle_total_number
          ),
          100
        )
        .toFixed(2)
    },
    // 本周目标概况-数据
    BZMBGKData() {
      let item1 = 0
      let item2 = 0
      let item3 = 0
      let item4 = 0
      this.dataList.forEach((e) => {
        switch (e.objective_status) {
          case 1: {
            item1++
            break
          }
          case 2: {
            item2++
            break
          }
          case 3: {
            item3++
            break
          }
          case 4: {
            item4++
            break
          }
        }
      })
      const total = item1 + item2 + item3 + item4
      const list = [
        {
          item: '待启动',
          count: item1,
          percent: Number(this.$util.floatDiv(item1, total).toFixed(3)),
          color: '#DDDDDD'
        },
        {
          item: '进行中',
          count: item2,
          percent: Number(this.$util.floatDiv(item2, total).toFixed(3)),
          color: '#FFC343'
        },
        {
          item: '已完成',
          count: item3,
          percent: Number(this.$util.floatDiv(item3, total).toFixed(3)),
          color: '#68D388'
        },
        {
          item: '已终止',
          count: item4,
          percent: Number(this.$util.floatDiv(item4, total).toFixed(3)),
          color: '#FD578E'
        }
      ]
      return { list, total }
    },
    // 完成进度概况-数据
    WCJDGKData() {
      let item1 = 0
      let item2 = 0
      let item3 = 0
      let item4 = 0
      this.dataList.forEach((e) => {
        if (e.progress_number <= 30) {
          item1++
        } else if (e.progress_number <= 60) {
          item2++
        } else if (e.progress_number <= 99) {
          item3++
        } else {
          item4++
        }
      })
      const total = item1 + item2 + item3 + item4
      const list = [
        {
          item: '0-30%',
          count: item1,
          percent: Number(this.$util.floatDiv(item1, total).toFixed(3)),
          color: '#FBD437'
        },
        {
          item: '31-60%',
          count: item2,
          percent: Number(this.$util.floatDiv(item2, total).toFixed(3)),
          color: '#3AA1FF'
        },
        {
          item: '61-99%',
          count: item3,
          percent: Number(this.$util.floatDiv(item3, total).toFixed(3)),
          color: '#36CBCB'
        },
        {
          item: '100%',
          count: item4,
          percent: Number(this.$util.floatDiv(item4, total).toFixed(3)),
          color: '#4ECB73'
        }
      ]
      return { list, total }
    },
    // 任务进度明细-数据
    WCJDMXData() {
      const list = this.dataList.map((e) => {
        return {
          ...e,
          progress_status: `${e.progress_status}`
        }
      })
      return { list }
    }
  },
  methods: {
    // 渲染图表
    async renderChart() {
      await this.$nextTick()
      this.renderChartBZMBGK()
      this.renderChartWCJDGK()
      this.renderChartWCJDMX()
    },
    // 渲染图表-本周任务概况
    renderChartBZMBGK() {
      if (this.BZMBGKChart != null) {
        this.BZMBGKChart.destroy()
        // this.BZMBGKChart.changeData(this.BZMBGKData.list)
        // return
      }
      this.BZMBGKChart = new Chart({
        container: this.$refs.BZMBGK,
        autoFit: true,
        height: [300, 300, 285, 285, 285][this.widthTypeIndex],
        appendPadding: [10, 10, 0, 10]
      })

      this.BZMBGKChart.coordinate('theta', {
        radius: [0.7, 0.7, 0.75, 0.75, 0.75][this.widthTypeIndex]
      })

      this.BZMBGKChart.data(this.BZMBGKData.list)

      this.BZMBGKChart.scale('percent', {
        formatter: (val) => {
          val = this.$util.floatMul(val, 100).toFixed(2) + '%'
          return val
        }
      })

      this.BZMBGKChart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      this.BZMBGKChart.legend('item', {
        itemName: {
          formatter: (text, item) => `${item.item} ${item.count}`
        },
        items: this.BZMBGKData.list.map((e) => {
          return {
            ...e,
            name: e.item,
            marker: {
              style: {
                fill: e.color
              }
            }
          }
        })
      })
      this.BZMBGKChart.interval()
        .position('percent')
        .color(
          'item',
          this.BZMBGKData.list.map((e) => e.color)
        )
        .label('percent', {
          content: (data) => {
            return `${data.item}: ${data.count}`
            // return `${data.item}: ${this.$util
            //   .floatMul(data.percent, 100)
            //   .toFixed(2)}%`
          }
        })
        .adjust('stack')
        .tooltip({
          fields: ['item', 'percent', 'count'],
          callback: (item, percent, count) => {
            percent = this.$util.floatMul(percent, 100).toFixed(2) + '%'
            return {
              name: item,
              value: percent,
              count
            }
          }
        })

      this.BZMBGKChart.interaction('element-selected')

      this.BZMBGKChart.render()
    },
    // 渲染图表-完成进度概况
    renderChartWCJDGK() {
      if (this.WCJDGKChart != null) {
        this.WCJDGKChart.destroy()
        // this.WCJDGKChart.changeData(this.WCJDGKData.list)
        // return
      }
      this.WCJDGKChart = new Chart({
        container: this.$refs.WCJDGK,
        autoFit: true,
        height: [300, 300, 285, 285, 285][this.widthTypeIndex],
        appendPadding: [10, 10, 0, 10]
      })
      this.WCJDGKChart.data(this.WCJDGKData.list)
      this.WCJDGKChart.scale('percent', {
        formatter: (val) => {
          val = this.$util.floatMul(val, 100).toFixed(2) + '%'
          return val
        }
      })
      this.WCJDGKChart.coordinate('theta', {
        radius: [0.7, 0.7, 0.75, 0.75, 0.75][this.widthTypeIndex],
        innerRadius: [0.7, 0.7, 0.7, 0.7, 0.7][this.widthTypeIndex]
      })
      this.WCJDGKChart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      // 辅助文本
      this.WCJDGKChart.annotation()
        .text({
          position: ['50%', '50%'],
          content: '总数',
          style: {
            fontSize: 14,
            fill: '#8c8c8c',
            textAlign: 'center'
          },
          offsetY: -10
        })
        .text({
          position: ['50%', '50%'],
          content: this.WCJDGKData.total,
          style: {
            fontSize: 20,
            fill: '#8c8c8c',
            textAlign: 'center'
          },
          offsetY: 10
        })
      this.WCJDGKChart.legend('item', {
        itemName: {
          formatter: (text, item) => `${item.item} ${item.count}`
        },
        items: this.WCJDGKData.list.map((e) => {
          return {
            ...e,
            name: e.item,
            marker: {
              style: {
                fill: e.color
              }
            }
          }
        })
      })
      this.WCJDGKChart.interval()
        .adjust('stack')
        .position('percent')
        .color(
          'item',
          this.WCJDGKData.list.map((e) => e.color)
        )
        .label('percent', (percent) => {
          return {
            content: (data) => {
              return `${data.item}: ${data.count}`
              // return `${data.item}: ${this.$util
              //   .floatMul(percent, 100)
              //   .toFixed(2)}%`
            }
          }
        })
        .tooltip({
          fields: ['item', 'percent', 'count'],
          callback: (item, percent, count) => {
            percent = this.$util.floatMul(percent, 100).toFixed(2) + '%'
            return {
              name: item,
              value: percent,
              count
            }
          }
        })

      this.WCJDGKChart.interaction('element-selected')

      this.WCJDGKChart.render()
    },
    // 渲染图表-完成进度明细
    renderChartWCJDMX() {
      if (this.WCJDMXChart != null) {
        this.WCJDMXChart.destroy()
        // this.WCJDMXChart.changeData(this.WCJDMXData.list)
        // return
      }
      this.WCJDMXChart = new Chart({
        container: this.$refs.WCJDMX,
        autoFit: true,
        height: [450, 450, 400, 400, 400][this.widthTypeIndex],
        appendPadding: [10, 0, 10, 0]
      })

      this.WCJDMXChart.data(this.WCJDMXData.list)
      // this.WCJDMXChart.scale('population', { nice: true })
      this.WCJDMXChart.coordinate().transpose()
      this.WCJDMXChart.tooltip({
        showMarkers: false
      })
      this.WCJDMXChart.option('scrollbar', {
        type: 'vertical'
      })
      this.WCJDMXChart.axis('objective_name', {
        label: {
          formatter: (v) => {
            if (v.length > [7, 15, 15, 15, 15][this.widthTypeIndex]) {
              return `${v.substr(
                0,
                [7, 15, 15, 15, 15][this.widthTypeIndex]
              )}...`
            } else {
              return v
            }
          }
        }
      })
      // 有冲突
      // this.WCJDMXChart.interaction('plot-mousewheel-scroll')
      this.WCJDMXChart.interaction('active-region')
      this.WCJDMXChart.legend('progress_status', {
        itemName: {
          formatter: (text, item) => item.value
        },
        items: [
          {
            name: '1',
            value: '正常',
            marker: {
              style: {
                fill: '#3AA1FF'
              }
            }
          },
          {
            name: '2',
            value: '延期',
            marker: {
              style: {
                fill: '#FD568E'
              }
            }
          }
        ]
      })
      this.WCJDMXChart.tooltip({
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>进度值: {value}</li>'
      })
      this.WCJDMXChart.interval()
        .size(15)
        .color('progress_status', (v) => {
          switch (v) {
            case '1':
              return '#3AA1FF'
            case '2':
              return '#FD568E'
            default:
              return '#3AA1FF'
          }
        })
        .position('objective_name*progress_number')
        .tooltip({
          fields: ['progress_number']
        })
      this.WCJDMXChart.render()
    }
  },
  mounted() {},
  watch: {
    data: {
      handler(val) {
        if (val.report_data.report_id != null) {
          // this.renderChart()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  > .el-row {
    > .el-col {
      height: 350px;
      margin-bottom: 20px;
      &:last-child {
        height: 500px;
        margin-bottom: 0;
      }
    }
    .el-col.total {
      .el-row {
        height: 100%;
      }
      .el-card {
        width: 100%;
        height: 80px;

        &::v-deep {
          .el-card__body {
            padding: 6px 10px 10px 20px;
          }
        }

        .left {
          display: inline-block;
          color: #5578eb;
          font-weight: 700;
          font-size: 20px;
          width: 100px;
        }
        .right {
          display: inline-block;
          .right-number {
            color: #5578eb;
            &.up {
              color: #00ca9d;
              &::after {
                content: '\eb0b';
                font-family: 'iconfont' !important;
              }
            }
            &.down {
              color: #ff3c3c;
              &::after {
                content: '\eb0a';
                font-family: 'iconfont' !important;
              }
            }
          }
        }
      }
    }
    .el-card {
      box-shadow: 0px 1px 4px 0px rgba(31, 35, 41, 0.05);
    }
    .el-card.chart {
      height: 100%;
      &::v-deep {
        .el-card__body {
          padding: 10px 20px;
        }
      }
    }
  }
  .el-card::v-deep .el-card__header {
    border-bottom: 0;
    padding: 15px 20px 6px;
    font-size: 14px;
    font-weight: 600;
  }
  .icon-xinxi {
    font-size: 14px;
    margin-left: 8px;
  }
}
.tip-text {
  font-size: 13px;
  line-height: 1.6;
  color: #1b1b1b;
}
</style>

<style lang="scss" scoped>
.content.xs,
.content.sm {
  > .el-row {
    > .el-col {
      height: auto;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .el-col.total {
    margin-bottom: 0;
    .el-col {
      margin-bottom: 10px;
    }
    .el-card {
      height: auto;
    }
  }
}

.content.xs {
  .el-col.total {
    .el-card::v-deep {
      .el-card__header {
        padding: 15px 10px 6px 12px;
      }
      .el-card__body {
        padding: 6px 10px 20px 12px;
      }
    }
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
      margin-top: 10px;
    }
  }
  .icon-xinxi {
    margin-left: 2px;
  }
}
</style>
