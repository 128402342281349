<!--
 * @Author: Betty
 * @Date: 2021-08-06 16:58:50
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-23 17:32:08
 * @Description: 目标详情单页面
-->
<template>
  <div class="single-box">
    <!-- 白色的窗体 -->
    <div class="modal-right-box" :class="{ 'my-target': isAllowUpdate }">
      <!-- 头部 -->
      <div class="modal-right-header flex flex-y-center flex-x-between">
        <!-- tab -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 第一块，目标详情 -->
          <el-tab-pane label="目标详情" name="first">
            <!-- 第一块，任务标题和基本信息 -->
            <div class="task-basic-info target-basic-info border-b">
              <p
                class="come-from e"
                v-if="fromWhere && fromWhere.infoLabel"
                @click="changeBack(fromWhere)"
              >
                <!-- 标签 -->
                <span
                  class="from-target-label"
                  v-if="fromWhere.infoLabel && fromWhere.infoLabel.gth > 0"
                  :style="{
                    color: '#FF5454',
                    background: '#fff',
                    fontWeight: '600',
                    fontSize: '20px'
                  }"
                  >{{ fromWhere.infoLabel.gthStr }}</span
                >
                <template v-if="fromWhere.infoLabel">
                  <span
                    class="from-target-label"
                    v-for="(item, index) in fromWhere.infoLabel.list"
                    :key="index"
                    >{{ item.name }}</span
                  >
                </template>
                {{ fromWhere.infoLabel.showName }}
              </p>
              <div class="flex flex-y-start">
                <div
                  class="check-box"
                  @click="checkTarget()"
                  :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
                >
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="
                        targetInfo.objective_status === 3 ||
                        targetInfo.objective_status === 4
                          ? '#icon-TickSquare_1'
                          : '#icon-TickSquare'
                      "
                    ></use>
                  </svg>
                </div>

                <h4
                  class="task-title target-title text-warp"
                  v-show="!isEditName"
                  @click="beginInputName"
                  :class="{
                    over:
                      targetInfo.objective_status === 3 ||
                      targetInfo.objective_status === 4
                  }"
                  :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
                  v-if="targetInfo.infoLabel"
                >
                  <!-- 标签 -->
                  <span
                    class="target-label"
                    v-if="targetInfo.infoLabel && targetInfo.infoLabel.gth > 0"
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '20px'
                    }"
                    >{{ targetInfo.infoLabel.gthStr }}</span
                  >
                  <template v-if="targetInfo.infoLabel">
                    <span
                      class="target-label"
                      v-for="(item, index) in targetInfo.infoLabel.list"
                      :key="index"
                      >{{ item.name }}</span
                    >
                  </template>
                  {{ targetInfo.infoLabel.showName || '' }}
                </h4>
                <input
                  type="text"
                  class="edit-name-input"
                  ref="inputTargetName"
                  @keydown="showCode"
                  @compositionend.prevent
                  @blur="handleInputName"
                  @keyup.13="toBlur($event)"
                  @keyup.229="empty"
                  @keyup.esc="cancelInputName"
                  v-model="currentTargetName"
                  v-show="isEditName"
                />
              </div>
              <!-- 关联父目标 -->
              <div class="to-relative flex flex-y-center">
                <span
                  class="iconfont"
                  @click="toRelativeModal"
                  :class="{
                    'icon-relatived active': targetInfo.pid > 0,
                    'icon-to_relative': targetInfo.pid === 0
                  }"
                ></span>
                <p
                  class="relative-parent e"
                  :class="{ none: !isAllowUpdate && targetInfo.pid === 0 }"
                  @click="toParentOrModal"
                >
                  <!-- 标签 -->
                  <span
                    class="parent-target-label"
                    v-if="
                      targetInfo.parent_info &&
                      targetInfo.parent_info.infoLabel &&
                      targetInfo.parent_info.infoLabel.gth > 0
                    "
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '20px'
                    }"
                    >{{ targetInfo.parent_info.infoLabel.gthStr }}</span
                  >
                  <template
                    v-if="
                      targetInfo.parent_info && targetInfo.parent_info.infoLabel
                    "
                  >
                    <span
                      class="parent-target-label"
                      v-for="(item, index) in targetInfo.parent_info.infoLabel
                        .list"
                      :key="index"
                      >{{ item.name }}</span
                    >
                  </template>
                  {{
                    targetInfo.parent_info && targetInfo.parent_info.infoLabel
                      ? targetInfo.parent_info.infoLabel.showName
                      : isAllowUpdate
                      ? '关联父级目标'
                      : '无父级目标'
                  }}
                </p>
              </div>
              <!-- 两行基本信息 -->
              <div class="flex flex-wrap detail-indent">
                <!-- 状态，点击选择状态 -->
                <div class="basic-info-item flex flex-y-center">
                  <div
                    class="flex flex-y-center"
                    @click.stop="toggleChooseState"
                    :style="{
                      cursor: isAllowUpdate ? 'pointer' : 'default'
                    }"
                  >
                    <span
                      class="iconfont basic-icon"
                      :class="[
                        stateList[currentState] && stateList[currentState].icon,
                        stateList[currentState] && stateList[currentState].color
                      ]"
                    ></span>
                    <div class="text-box">
                      <p
                        class="state"
                        :class="[
                          stateList[currentState] &&
                            stateList[currentState].color
                        ]"
                      >
                        {{
                          stateList[currentState] &&
                          stateList[currentState].title
                        }}
                      </p>
                      <p class="info">当前状态</p>
                    </div>
                  </div>

                  <!-- 选择状态的弹窗 -->
                  <div class="choose-state-box" v-show="isChooseState">
                    <p class="choose-title">选择状态</p>
                    <div
                      v-for="(item, index) in stateList"
                      :key="index"
                      class="flex flex-y-center state-item"
                      @click="chooseState(index)"
                    >
                      <span
                        class="iconfont small-icon"
                        :class="[item.smallIcon, item.color]"
                      ></span>
                      <p class="state-text">{{ item.title }}</p>
                    </div>
                  </div>
                </div>
                <!-- 开始时间 -->
                <div
                  class="basic-info-item flex flex-y-center"
                  :class="{ cursor: isAllowUpdate }"
                >
                  <span
                    class="iconfont icon-TimeCircle1 basic-icon"
                    :class="{ active: startTime }"
                  ></span>
                  <div class="text-box">
                    <p class="state" :class="{ active: startTime }">
                      {{ startTime ? startTime : '开始时间' }}
                    </p>
                    <p class="info" v-if="startTime">开始时间</p>
                  </div>

                  <!-- 选择开始时间 -->
                  <div class="dashboard-editor-container">
                    <div class="block">
                      <el-date-picker
                        v-model="startTime"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        popper-class="date-type"
                        :editable="false"
                        @blur="handleStartTime"
                        v-if="isAllowUpdate"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
                <!-- 结束时间 -->
                <div
                  class="basic-info-item flex flex-y-center"
                  :class="{ cursor: isAllowUpdate }"
                >
                  <span
                    class="iconfont icon-TimeCircle2 basic-icon"
                    :class="{ active: endTime }"
                  ></span>
                  <div class="text-box">
                    <p class="state" :class="{ active: endTime }">
                      {{ endTime ? endTime : '结束时间' }}
                    </p>
                    <p class="info" v-if="endTime">结束时间</p>
                  </div>
                  <!-- 选择开始时间 -->
                  <div class="dashboard-editor-container">
                    <div class="block">
                      <el-date-picker
                        v-model="endTime"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        popper-class="date-type"
                        :editable="false"
                        @blur="handleEndTime"
                        v-if="isAllowUpdate"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 第二块，目标信息和目标描述 -->
            <div class="task-info-box target-info-box border-b">
              <!-- 目标信息头部 -->
              <div class="info-header flex flex-y-center">
                <span class="iconfont icon-Document icon"></span>
                <h6 class="info-title">目标信息</h6>
              </div>
              <div class="flex detail-indent info-indent-between">
                <!-- 目标类型和工作量 -->
                <div class="info-content-item">
                  <p class="info-text">目标类型</p>
                  <div class="choose-parent" @click.stop="toggleChoosePriority">
                    <div
                      class="choose-target choose-box flex flex-y-center"
                      :style="{
                        border: !isAllowUpdate ? '1px solid transparent' : ''
                      }"
                    >
                      <div
                        :class="[
                          targetTypeList[currentTargetType]
                            ? targetTypeList[currentTargetType].color
                            : '',
                          {
                            'target-type-box': currentTargetType > 0
                          },
                          {
                            'no-style': targetInfo.objective_type === 1
                          }
                        ]"
                        :style="{
                          cursor: isAllowUpdate ? 'pointer' : 'default'
                        }"
                      >
                        {{ targetTypeText }}
                      </div>
                    </div>
                    <div class="priority-tags-box" v-show="isChoosePriority">
                      <div
                        class="priority-item"
                        v-for="(item, index) in targetTypeList"
                        :key="index"
                        @click.stop="chooseTargetType(item.value)"
                      >
                        <div
                          class="target-type-box"
                          :class="[
                            targetTypeList[index]
                              ? targetTypeList[index].color
                              : ''
                          ]"
                        >
                          {{ targetTypeList[index].text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-content-item">
                  <p class="info-text">工作量</p>
                  <input
                    type="text"
                    class="choose-box"
                    v-model="workload"
                    placeholder="无"
                    @keydown="showCode"
                    @compositionend.prevent
                    @blur="readySumbitWorkload"
                    @keyup.13="toBlur($event)"
                    @keyup.229="empty"
                    @keyup.esc="cancelInputWorkload"
                    :readonly="!isAllowUpdate"
                    :style="{
                      border: !isAllowUpdate ? '1px solid transparent' : ''
                    }"
                  />
                </div>
                <!-- 完成进度 -->
                <div class="info-content-item">
                  <p class="info-text">完成进度</p>
                  <div class="progress-box">
                    <div
                      class="flex flex-y-center"
                      @click.stop="toggleSetProgress"
                    >
                      <el-progress
                        :color="
                          targetInfo.progress_status === 1
                            ? '#0080FF'
                            : '#FF5454'
                        "
                        type="circle"
                        :percentage="targetInfo.progress_number || 0"
                        :width="20"
                        :show-text="false"
                        :stroke-width="2"
                      ></el-progress>
                      <span class="progress">{{
                        (targetInfo.progress_number || 0) + '%'
                      }}</span>
                    </div>
                    <!-- 设置完成进度 -->
                    <!-- 设置进度 -->
                    <div
                      class="task-process"
                      v-show="isSetProgress"
                      @click.stop
                    >
                      <h6 class="process-title">当前进度</h6>
                      <div class="flex flex flex-y-center">
                        <input
                          type="number"
                          class="input"
                          min="0"
                          max="100"
                          v-model="currentProgress"
                          :ref="`${targetInfo.objective_id}progressRef`"
                          @blur="
                            editProgress({
                              id: targetInfo.objective_id,
                              index: -1
                            })
                          "
                          @keyup.enter="
                            toBlur($event, {
                              id: targetInfo.objective_id,
                              index: -1
                            })
                          "
                        />
                        <span class="percent">%</span>
                      </div>
                      <h6 class="process-title">状态</h6>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label normal flex flex-y-center"
                          @click="
                            setTargetProgressStatus(
                              1,
                              targetInfo.objective_id,
                              -1
                            )
                          "
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                targetInfo.progress_status === 1
                                  ? '#icon-Group706'
                                  : '#icon-Group592'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="targetInfo.progress_status === 1"
                            id=""
                            class="radio"
                          />
                          <span>正常</span>
                        </label>
                      </div>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label late flex flex-y-center"
                          @click="
                            setTargetProgressStatus(
                              2,
                              targetInfo.objective_id,
                              -1
                            )
                          "
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                targetInfo.progress_status === 2
                                  ? '#icon-Group705'
                                  : '#icon-Group593'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="targetInfo.progress_status === 2"
                            id=""
                            class="radio"
                          />
                          <span>已延期</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 目标描述 -->
              <div class="task-desc-box" style="padding-bottom: 16px">
                <div
                  class="info-header flex flex-y-center"
                  style="padding-bottom: 5px"
                >
                  <span class="iconfont icon-Bookmark icon"></span>
                  <h6
                    class="info-title"
                    @click="toAddTargetDes()"
                    :style="{
                      cursor:
                        isAllowUpdate &&
                        targetInfo &&
                        !targetInfo.objective_describe_html
                          ? 'pointer'
                          : 'default'
                    }"
                  >
                    {{
                      (targetInfo && targetInfo.objective_describe_html) ||
                      !isAllowUpdate
                        ? '目标描述'
                        : '添加目标描述'
                    }}
                  </h6>
                  <span
                    class="info-title title-edit-btn"
                    @click.stop="showEditBox"
                    v-if="
                      isAllowUpdate &&
                      targetInfo &&
                      targetInfo.objective_describe_html
                    "
                    >编辑</span
                  >
                </div>
                <p
                  class="no-des detail-indent"
                  v-if="
                    !isAllowUpdate &&
                    targetInfo &&
                    !targetInfo.objective_describe_html
                  "
                >
                  暂无目标描述
                </p>
                <!-- 显示结果 -->
                <!-- <div
                  class="info-content"
                  v-if="targetInfo && targetInfo.objective_describe_html"
                >
                  <p class="pre">{{ targetInfo.objective_describe_text }}</p>
                </div> -->
                <div v-if="!isShowEdit" class=". detail-indent">
                  <div
                    v-html="
                      handlerContentToUrl(targetInfo.objective_describe_html)
                    "
                  ></div>
                </div>
                <!-- 显示编辑框 -->
                <div class="info-edit-box">
                  <!-- 富文本框 -->
                  <!-- <TinymceEditor
                    v-if="false"
                    :value="targetInfo.objective_describe_html"
                    ref="editor"
                    :plugins="plugins"
                    :toolbar="toolbar"
                  ></TinymceEditor> -->
                  <target-describe-editor
                    v-if="isShowEdit"
                    ref="editor"
                    :value="targetInfo.objective_describe_html"
                  ></target-describe-editor>
                  <!-- <p class="pre">{{ targetInfo.target_describe_text }}</p> -->
                </div>
                <!-- 显示编辑框 -->
                <div class="info-edit-box" v-show="isShowEdit">
                  <!-- 富文本框 -->
                  <!-- 文字输入 -->
                  <textarea
                    class="des"
                    v-model="targetDescVal"
                    v-if="false"
                  ></textarea>
                  <!-- 保存和取消按钮 -->
                  <div class="flex flex-y-center btns">
                    <el-button type="primary" @click="handleEditDes"
                      >保存</el-button
                    >
                    <el-button @click="cancelEditDes">取消</el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 第三块 -->
            <div class="task-info-box target-info-box border-b">
              <!-- 目标信息头部 -->
              <div class="info-header flex flex-y-center">
                <span class="iconfont icon-command icon"></span>
                <h6 class="info-title">目标分解</h6>
              </div>
              <div
                v-for="(target, index) in childrenTargetList"
                :key="index"
                class="child-target-item flex flex-y-start flex-x-between"
              >
                <p
                  class="target-name"
                  :class="{
                    over:
                      target.objective_status === 3 ||
                      target.objective_status === 4
                  }"
                  @click="toChildDetail(target.objective_id)"
                >
                  <!-- 标签 -->
                  <span
                    class="child-target-label"
                    v-if="target.infoLabel && target.infoLabel.gth > 0"
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '15px'
                    }"
                    >{{ target.infoLabel.gthStr }}</span
                  >
                  <template v-if="target.infoLabel">
                    <span
                      class="child-target-label"
                      v-for="(item, index) in target.infoLabel.list"
                      :key="index"
                      >{{ item.name }}</span
                    >
                  </template>
                  {{ target.infoLabel.showName }}
                </p>
                <div class="flex flex-y-center">
                  <!-- 进度 -->
                  <el-progress
                    :color="
                      target.progress_status === 1 ? '#0080FF' : '#FF5454'
                    "
                    type="circle"
                    class="hidden-xs-only"
                    :percentage="target.progress_number || 0"
                    :width="20"
                    :show-text="false"
                    :stroke-width="2"
                  ></el-progress>
                  <span class="progress hidden-xs-only">{{
                    (target.progress_number || 0) + '%'
                  }}</span>
                  <!-- 头像 -->
                  <avatar
                    :size="30"
                    :distanceRight="0"
                    :userInfo="{
                      clerk_avatar: target.create_clerk_avatar,
                      clerk_alias: target.create_clerk_alias,
                      clerk_name: target.create_clerk_name,
                      clerk_id: target.create_clerk_id
                    }"
                    :isShowMe="true"
                    :isShowTooltip="true"
                  ></avatar>
                </div>
              </div>
              <!-- 没有目标分解而且也不能添加的时候显示提示 -->
              <p
                class="no-child detail-indent"
                v-if="
                  childrenTargetList.length === 0 &&
                  targetInfo.is_main_leader === 0
                "
              >
                下级关联此目标后，可在此处查看
              </p>
              <!-- 添加子目标 -->
              <button
                type="button"
                v-if="targetInfo.is_main_leader === 1"
                v-show="!isAddChildTarget"
                class="add-children-btn detail-indent"
                @click="beginAddChildTarget"
              >
                添加子目标
              </button>
              <!-- 添加子任务的输入框 -->
              <input
                type="text"
                placeholder="填写子目标的名称"
                class="child-name"
                @compositionend.prevent
                @keydown="showCode"
                v-model.trim="childrenTemp"
                v-show="isAddChildTarget"
                ref="addChildTargetInput"
                @blur="handleNewChild()"
                @keyup.13="toBlur($event)"
                @keyup.229="empty"
                @keyup.esc="cancelNewChild()"
                style="width: calc(100% - 60px); margin-top: 10px"
              />
            </div>
            <!-- 子任务 -->
            <div class="task-info-box border-b">
              <!-- 头部 -->
              <div class="info-header flex flex-y-center">
                <span class="iconfont icon-Frame11 icon"></span>
                <h6
                  class="info-title"
                  @click="toAddChildTask()"
                  :style="{
                    cursor: isAllowUpdate ? 'pointer' : 'default'
                  }"
                >
                  {{
                    childrenTaskList.length > 0 || !isAllowUpdate
                      ? '子任务'
                      : '添加子任务'
                  }}
                </h6>
              </div>
              <!-- 每一个子任务 -->
              <div
                v-for="(item, index) in childrenTaskList"
                :key="index"
                class="children-task-item flex flex-y-start flex-x-between"
              >
                <!-- 左边 -->
                <div class="flex flex-y-start mobile-flex">
                  <!-- 复选框的标记 -->
                  <div
                    style="height: 34px"
                    class="flex flex-y-center"
                    @click="checkChildTask(item)"
                  >
                    <svg
                      class="icon task-checkbox"
                      aria-hidden="true"
                      :style="{
                        cursor: isAllowUpdate ? 'pointer' : 'default'
                      }"
                    >
                      <use
                        :xlink:href="
                          item.task_status === 3 || item.task_status === 4
                            ? '#icon-TickSquare_1'
                            : '#icon-TickSquare'
                        "
                      ></use>
                    </svg>
                  </div>
                  <!-- 标签 -->
                  <span
                    class="child-task-label"
                    v-if="item.infoLabel && item.infoLabel.gth > 0"
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '15px'
                    }"
                    @click="beginInputName"
                    >{{ item.infoLabel.gthStr }}</span
                  >
                  <template v-if="item.infoLabel">
                    <span
                      class="child-task-label"
                      v-for="(litem, lindex) in item.infoLabel.list"
                      :key="lindex"
                      @click="beginInputName"
                      >{{ litem.name }}</span
                    >
                  </template>
                  <!-- 子任务名字 -->
                  <p
                    class="child-name mobile-child-name flex flex-y-start"
                    :class="{
                      over: item.task_status === 3 || item.task_status === 4,
                      task_orange: item.task_status === 4
                    }"
                    v-if="currentEditChildTask !== item.task_id"
                    @click.stop="toTask(item.task_id)"
                  >
                    {{ item.infoLabel.showName }}
                  </p>
                  <input
                    type="text"
                    placeholder="填写子任务名称"
                    class="child-name"
                    v-model="childTaskTemp"
                    @keydown="showCode"
                    @compositionend.prevent
                    v-else
                    @blur="handleEditTaskChild(item)"
                    @keyup.13="toBlur($event, item)"
                    @keyup.229="empty"
                    @keyup.esc="cancelEditChild()"
                    :ref="`childTask${item.task_id}`"
                  />
                  <!-- <button
                      class="edit-btn hidden-xs-only"
                      @click="toggleChildEdit(item)"
                      v-if="isAllowUpdate"
                    >
                      <span class="iconfont icon-Edit"></span>
                    </button> -->
                </div>
                <!-- 右边，只显示一个头像，负责人 -->
                <div class="flex flex-y-center">
                  <!-- 进度 -->
                  <div class="flex flex-y-center child-progress">
                    <div
                      class="flex flex-y-center child-progress-box hidden-xs-only"
                      @click.stop="beginEditChildTaskProgress(index)"
                    >
                      <el-progress
                        :color="
                          item.progress_status === 1 ? '#0080FF' : '#FF5454'
                        "
                        type="circle"
                        :percentage="item.progress_number || 0"
                        :width="20"
                        :show-text="false"
                        :stroke-width="2"
                      ></el-progress>
                      <span class="progress">{{
                        (item.progress_number || 0) + '%'
                      }}</span>
                    </div>
                    <!-- 设置进度 -->
                    <div
                      class="task-process hidden-xs-only"
                      v-if="item.isChooseProgress"
                      @click.stop
                    >
                      <h6 class="process-title">当前进度</h6>
                      <div class="flex flex flex-y-center">
                        <input
                          type="number"
                          class="input"
                          min="0"
                          max="100"
                          v-model="currentChildTaskProgress"
                          :ref="`${item.task_id}progressRef`"
                          @blur="
                            editChildTaskProgress({ id: item.task_id, index })
                          "
                          @keyup.enter="
                            toBlur($event, { id: item.task_id, index })
                          "
                        />
                        <span class="percent">%</span>
                      </div>
                      <h6 class="process-title">状态</h6>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label normal flex flex-y-center"
                          @click="
                            setChildTaskProgressStatus(1, item.task_id, index)
                          "
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                item.progress_status === 1
                                  ? '#icon-Group706'
                                  : '#icon-Group592'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="item.progress_status === 1"
                            id=""
                            class="radio"
                          />
                          <span>正常</span>
                        </label>
                      </div>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label late flex flex-y-center"
                          @click="
                            setChildTaskProgressStatus(2, item.task_id, index)
                          "
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                item.progress_status === 2
                                  ? '#icon-Group705'
                                  : '#icon-Group593'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="item.progress_status === 2"
                            id=""
                            class="radio"
                          />
                          <span>已延期</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="child-member-box">
                    <!-- 使用头像组件 -->
                    <avatar
                      :isShowMe="true"
                      :isShowTooltip="true"
                      :userInfo="item.leader"
                      @click.native.stop="beginChooseChildTaskLeader(index)"
                    ></avatar>
                    <!-- 选择负责人组件 -->
                    <choose-leader
                      :isShow="item.isChooseLeader"
                      :taskId="item.task_id"
                      :clerkTree="clerkTree"
                      @to-close="chooseChildTaskLeader($event, index)"
                    ></choose-leader>
                  </div>
                  <!-- 添加子任务成员 -->
                  <!-- <div
                      class="img-box"
                      @click="
                        openAddMember(
                          item.task_id,
                          item.task_participant_list,
                          item.create_clerk_id
                        )
                      "
                      v-if="isAllowUpdate"
                    >
                      <svg class="icon avatar" aria-hidden="true">
                        <use xlink:href="#icon-Group1251"></use>
                      </svg>
                    </div> -->
                  <!-- 更多的按钮 -->
                  <button
                    type="button"
                    class="more-btn hidden-xs-only"
                    @click.stop="currentHandleChild = item.task_id"
                    v-if="isAllowUpdate"
                  >
                    <span class="iconfont icon-gengduo3"></span>
                  </button>
                </div>
                <!-- 子任务的操作弹窗 -->
                <div
                  class="child-handle-box"
                  v-if="currentHandleChild === item.task_id"
                >
                  <!-- 编辑 -->
                  <!-- <div class="child-handle-item" @click="showChildDetail(item)">
                    编辑
                  </div> -->
                  <!-- 删除 -->
                  <div
                    class="child-handle-item red"
                    @click="deleteChildTask(item, index)"
                  >
                    删除
                  </div>
                  <div
                    class="child-handle-item"
                    @click="toggleChildEditTask(item)"
                    v-if="isAllowUpdate"
                  >
                    编辑
                  </div>
                  <div
                    class="child-handle-item"
                    @click="unbindTask(item, index)"
                    v-if="isAllowUpdate"
                  >
                    解绑
                  </div>
                </div>
              </div>
              <!-- 如果没有子任务 -->
              <p
                v-if="childrenTaskList.length === 0 && !isAllowUpdate"
                class="no-children detail-indent"
              >
                暂无关联子任务
              </p>
              <!-- 添加子任务 -->
              <button
                type="button"
                v-if="
                  isAllowUpdate &&
                  !isAddChildTask &&
                  childrenTaskList.length > 0
                "
                class="add-children-btn detail-indent"
                @click="beginAddChildTask"
              >
                添加子任务
              </button>
              <!-- 添加子任务的输入框 -->
              <input
                type="text"
                placeholder="填写子任务名称"
                class="child-name child-task-name"
                v-model="childTaskTemp"
                @compositionend.prevent
                v-show="isAddChildTask"
                @keydown="showCode"
                ref="addChildTaskInput"
                @blur="handleNewChildTask()"
                @keyup.13="toBlur($event)"
                @keyup.229="empty"
                @keyup.esc="cancelNewChild()"
                style="width: calc(100% - 60px); margin-top: 10px"
              />
            </div>
          </el-tab-pane>
          <!-- 第二块，附件 -->
          <el-tab-pane
            :label="`附件${
              appendixList.length > 0 ? `(${appendixList.length})` : ''
            }`"
            name="second"
          >
            <!-- 附件 -->
            <div class="appendix-box">
              <div class="flex flex-wrap">
                <attachment-item
                  v-for="(attachment, index) in appendixList"
                  :targetId="targetInfo.objective_id"
                  :appendix="attachment"
                  :key="index"
                  :isDeletable="targetInfo.hasPer_create"
                  @fresh-attachment="refreshAttachmentList"
                  fileType="target"
                ></attachment-item>
              </div>
              <!-- 上传和下载 -->
              <div class="flex flex-y-center handle-line">
                <!-- 上传文件的控件 -->
                <input
                  type="file"
                  name="newAttachment"
                  ref="uploadAttachmentRef"
                  class="upload-input"
                  @change="readyUpload"
                />
                <button
                  type="button"
                  class="handle"
                  @click="toUploadAttachment()"
                  v-show="targetInfo.hasPer_create"
                >
                  上传新附件
                </button>
                <!-- <button type="button" class="handle">下载所有附件</button> -->
              </div>
            </div>
          </el-tab-pane>
          <!-- 第三块，评论 -->
          <el-tab-pane
            :label="`评论${
              (targetInfo.comment_number || 0) > 0
                ? `(${targetInfo.comment_number})`
                : ''
            }`"
            name="third"
          >
            <div class="comments">
              <scroll-fresh
                className="target-comment-list"
                dataName="targetComments"
                :page="commentListPageInfo.pageNumber"
                :lastPage="commentListPageInfo.totalPage"
                @get-next-page="getCommentNextPage"
              >
                <!-- 任务评论数据 -->
                <!-- 每一条评论数据 -->
                <comment-item
                  v-for="item in commentList"
                  :key="item.comment_id"
                  :comment="item"
                  :targetId="targetInfo.objective_id"
                  @to-reply="setReplyObject($event)"
                  :isTarget="true"
                ></comment-item>
              </scroll-fresh>
              <!-- 添加评论的区域 -->
              <div class="add-comment-box">
                <div
                  class="add-comment-innder"
                  :class="{ 'is-edit': isEditing }"
                >
                  <!-- <textarea
                    v-model="newComment"
                    class="textarea"
                    @focus="beginEdit"
                    ref="commentRef"
                    @blur="cancelFocus"
                    :placeholder="commentPlaceholder"
                    @keyup.enter="addNewComment"
                  ></textarea> -->
                  <CommentEditEditor
                    class="textarea"
                    @input="handellInput"
                    @focus="beginEdit"
                    @blur="cancelFocus"
                    :title="commentPlaceholder"
                    v-model="newComment"
                    ref="commentRef"
                  ></CommentEditEditor>
                  <div class="flex flex-y-center flex-x-end btn-box">
                    <expression-box
                      :isShow="isShowCommentExpression"
                      class="expresion-weizhi"
                      :expressList="$emoji.slice(0, 100)"
                      @add-express="addExpress($event)"
                      @toggle-express-box="toggleCommentExpression($event)"
                    ></expression-box>
                    <button
                      type="button"
                      class="send-btn"
                      @click="addNewComment"
                    >
                      发送
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第四块，记录 -->
          <el-tab-pane label="记录" name="fourth" style="display: none">
            <div class="record-box">
              <scroll-fresh
                className="record-items"
                dataName="record-data"
                :page="targetLogPageInfo.pageNumber"
                :lastPage="targetLogPageInfo.totalPage"
                @get-next-page="getLogNextPage"
              >
                <!-- 显示出任务记录列表数据 -->
                <target-log-item
                  v-for="(item, index) in targetLogList"
                  :key="index"
                  :logData="item"
                ></target-log-item>
              </scroll-fresh>
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- 关闭按钮 -->
        <!-- <button
          type="button"
          class="close-btn"
          @click="closeDetailModal"
          title="关闭"
        >
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-guanbi"></use>
          </svg>
        </button> -->
        <!-- 右上角区域，显示id/链接已复制和删除按钮 -->
        <div class="right-top-box flex flex-y-center">
          <el-tooltip
            class="item hidden-xs-only"
            effect="dark"
            content="复制链接"
            v-if="!isShowTip"
            placement="bottom"
          >
            <p class="id-text hidden-xs-only" @click="copyLink">
              #{{ targetId }}
            </p>
          </el-tooltip>
          <p
            class="tip-text hidden-xs-only"
            v-if="isShowTip"
            :style="{ right: isAllowUpdate ? '30px' : '0' }"
          >
            链接已复制
          </p>
          <!-- 删除按钮，只有目标的创建人才能看到 -->
          <div
            @click.stop="handerShowMenu"
            v-if="
              (screenWidth > 767 && targetInfo.create_clerk_id === myClerkId) ||
              screenWidth <= 767
            "
          >
            <i class="iconfont icon-gengduo3"></i>
          </div>
          <transition name="el-fade-in-linear" v-if="showMenu">
            <div class="message-menu">
              <div
                class="menu-item"
                v-if="screenWidth <= 767"
                @click="copyLink"
              >
                复制链接
              </div>
              <div
                class="menu-item delete-btn"
                v-if="targetInfo.create_clerk_id === myClerkId"
                @click="toDeleteTarget"
              >
                删除
              </div>
            </div>
          </transition>
          <!-- <button
            class="del-btn"
            v-if="targetInfo.create_clerk_id === myClerkId"
          >
            <span
              class="iconfont icon-gengduo3"
              @click.stop="popupShow1"
            ></span>
            <div class="popup" v-if="popupShow">
              <div class="popup1" @click="toDeleteTarget">
                <div class="iconfont icon-Delete"></div>
                <div class="popup-delete">删除</div>
              </div>
            </div>
          </button> -->
          <!-- <button
            class="del-btn"
            @click="toDeleteTarget"
            v-if="targetInfo.create_clerk_id === myClerkId"
          >
            <span class="iconfont icon-trash"></span>
          </button> -->
        </div>
      </div>
    </div>
    <!-- 上传文件的进度条 -->
    <div
      class="progress-area flex flex-x-end flex-y-reverse"
      v-if="uploadProgressList.length > 0"
    >
      <!-- 这里摆放进度条组件 -->
      <upload-progress
        v-for="(item, index) in uploadProgressList"
        :key="item.id"
        :uploadProgress="item.uploadProgress"
        :uploadFileName="item.uploadFileName"
        :index="index"
        :cancel="item.cancel"
        @close-upload-progress="closeUploadProgress(index)"
      ></upload-progress>
      <!-- 关联父级目标的弹窗 -->
    </div>
    <!-- 选择关联目标 -->
    <target-relative-modal
      :isShowRelative="isChooseRelative"
      :myTarget="targetInfo"
      @close-modal="closeRelativeModal"
      @to-fresh-target="changeParent"
    ></target-relative-modal>
  </div>
</template>

<script>
// 导入富文本的组件
// import TinymceEditor from './TinymceEditer.vue'
import TargetDescribeEditor from '../components/target/TargetDescribeEditor1.vue'
// 导入树形组件
// import MemberTree from '../common/MemberTree.vue'
// 导入头像组件
// import Avatar from '../common/Avatar.vue'
import { mapState, mapActions } from 'vuex'
// 上传进度条
import UploadProgress from '../components/common/UploadProgress.vue'
// 导入滚动刷新组件
import ScrollFresh from '../components/common/ScrollFresh.vue'
// 导入评论组件
import CommentItem from '../components/target/CommentItem.vue'
// 导入目标记录组件
import TargetLogItem from '../components/target/TargetLogItem.vue'
// 附件组件
import AttachmentItem from '../components/common/AttachmentItem.vue'
import Avatar from '../components/common/Avatar.vue'
// 关联父级目标
import TargetRelativeModal from '../components/target/TargetRelativeModal.vue'
// 导入表情组件
import ExpressionBox from '../components/common/ExpressionBox.vue'
// 选择负责人组件
import ChooseLeader from '@/components/common/ChooseLeader.vue'
// 导入编辑器
import CommentEditEditor from '@/components/common/CommentEditEditor1.vue'

export default {
  name: 'target-detail-page',
  props: {
    // 是否显示弹窗
    // isShow: {
    //   type: Boolean,
    //   default: false
    // },
    // 当前是哪个任务的详情
    // targetId: {
    //   type: Number,
    //   require: true
    // },
    // 现在的时间
    // nowTime: {
    //   type: Date,
    //   require: true
    // },
    // 只读
    readonly: {
      type: Boolean,
      default: false
    },
    // 目标来源
    // fromWhere: {
    //   type: Object,
    //   default: () => {}
    // },
    // 当前查看的tab
    currentTab: {
      type: String,
      default: 'first'
    }
  },
  components: {
    // TinymceEditor,
    // MemberTree,
    // Avatar,
    UploadProgress,
    ScrollFresh,
    CommentItem,
    TargetDescribeEditor,
    TargetLogItem,
    AttachmentItem,
    Avatar,
    TargetRelativeModal,
    ExpressionBox,
    ChooseLeader,
    CommentEditEditor
  },
  provide() {
    return {
      Parent: this
    }
  },
  data() {
    return {
      // 当前目标的id
      targetId: -1,
      // 当前上传了几个文件
      uploadCount: 0,
      // // 上传文件的进度
      // uploadProgress: 0,
      // // 正在上传的文件名字
      // uploadFileName: '',
      // 正在上传的进度集合
      uploadProgressList: [],
      // 临时记录开始时间和结束时间
      tempStartTimeStr: '',
      tempEndTimeStr: '',
      // 选择时间的相关数据
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < 0
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '清空',
            onClick(picker) {
              picker.$emit('pick', '')
            }
          }
        ]
      },
      // 输入的任务名称
      currentTargetName: 0,
      // 是否要输入任务的名字
      isEditName: false,
      // 开始时间
      startTime: null,
      // 结束时间
      endTime: null,
      // 当前任务的状态
      currentState: 0,
      // 工作量
      workload: '',
      // 任务的状态
      stateList: [
        {
          title: '待启动',
          color: 'grey',
          icon: 'icon-InfoCircle',
          smallIcon: 'icon-InfoCircle_1'
        },
        {
          title: '进行中',
          color: 'orange',
          icon: 'icon-TimeCircle_4',
          smallIcon: 'icon-TimeCircle_2'
        },
        {
          title: '已完成',
          color: 'green',
          icon: 'icon-confirmfill1',
          smallIcon: 'icon-confirmfill2'
        },
        {
          title: '已终止',
          color: 'red',
          icon: 'icon-InfoCircle',
          smallIcon: 'icon-InfoCircle_1'
        }
      ],
      // 当前选择的目标
      currentTargetType: 0,
      // 是否要选择任务状态
      isChooseState: false,
      // 团队成员
      // 附件数据
      appendixList: [],
      // 子任务数据
      childrenList: [],
      // 富文本
      title: '',
      p: 1,
      n: 10,
      count: 0,
      disabled: false,
      notice_data: [],
      handleSelectionChange: [],
      dialogFormVisible: false,
      dialogTitle: '',
      notice_info: {
        title: '',
        desc: ''
      },
      // 是否显示富文本输入框
      isShowEdit: false,
      // 当前选中的tab
      activeName: 'first',
      // 是否展示优先级选择弹窗
      isChoosePriority: false,
      // 当前任务信息
      targetInfo: {},
      // 是否展示子任务编辑弹窗
      isShowChild: false,
      // 当前显示的子任务弹窗是哪个子任务的
      currentChildId: -1,
      // 输入任务描述的textarea的值
      targetDescVal: {
        html: '',
        text: ''
      },
      // 当前要操作的子任务的id
      currentHandleChild: -1,
      // 当前要编辑的子任务的id
      currentEditTarget: -1,
      // 要添加成员的任务id
      currentId: -1,
      // 要添加到成员的id数组
      memberIdList: [],
      // 临时选择要添加的成员列表
      memberListTemp: [],
      // 当前任务的创建人id
      currentCreateClerkId: -1,
      // 要搜索的员工名字
      searchName: '',
      // 搜索结果列表
      searchResultList: [],
      // 是否修改数据
      isUpdateData: false,
      searchDirectorAntiShare: null,
      // 评论列表
      commentList: [],
      // 评论列表的分页情况
      commentListPageInfo: {
        totalPage: 0,
        totalRow: 0,
        pageSize: 20,
        firstPage: true,
        lastPage: false,
        pageNumber: 1
      },
      // 我要回复谁的评论
      replyTo: null,
      // 添加新评论
      newComment: '',
      // 目标记录列表
      targetLogList: [],
      // 目标记录的分页情况
      targetLogPageInfo: {
        pageNumber: 1,
        pageSize: 20,
        totalRow: 10,
        totalPage: 1
      },
      isFirstInit: true,
      // 子目标列表
      childrenTargetList: [],
      // 是否展示关联父目标弹窗
      isChooseRelative: false,
      // 临时输入的子目标名字
      childrenTemp: '',
      // 是否显示添加子目标的输入框
      isAddChildTarget: false,
      // 是否正在输入评论
      isEditing: false,
      // 是否正在加载评论和记录
      isLoading: false,
      isLoadingLog: false,
      // 现在的时间
      todayDate: new Date(),
      // 是否显示“链接已复制”的提示
      isShowTip: false,
      fromWhere: null,
      // 是否要设置进度
      isSetProgress: false,
      // 当前输入的进度值
      currentProgress: 0,
      // 临时记录进度
      temp: 0,
      // 临时记录keyCode
      keyCode: 0,
      // 是否显示表情
      isShowCommentExpression: false,
      // 子任务数据
      childrenTaskList: [],
      // 是否要新增子任务
      isAddChildTask: false,
      // 当前修改子任务
      currentEditChildTask: -1,
      // 正在编辑的子任务名字
      childTaskTemp: '',
      // 当前输入的进度 子任务
      currentChildTaskProgress: 0,
      // 临时输入的子进度
      tempChildTaskProgress: 0,
      popupShow: false,
      showMenu: false // 是否现实菜单
    }
  },
  computed: {
    ...mapState([
      'myClerkId',
      'targetTypeList',
      'screenWidth',
      'clickBodyIndex'
    ]),
    realTargetId() {
      if (this.targetId !== -1) {
        return this.targetId
      }
      return this.targetInfo.objective_id
    },
    // 是否可更新
    isAllowUpdate() {
      // 如果我是任务创建人，我可以修改任务
      return this.targetInfo.create_clerk_id === this.myClerkId
    },
    // 设置目标类型的文字
    targetTypeText() {
      if (this.targetInfo.objective_type == null) {
        return ''
      }
      return this.targetTypeList[this.targetInfo.objective_type - 1].text
    },
    // 输入评论的placeholder
    commentPlaceholder() {
      if (!this.replyTo) {
        return '发表评论'
      } else {
        return `回复${this.replyTo.clerk_alias || this.replyTo.clerk_name}：`
      }
    }
  },
  mounted() {
    // 从路由参数里拿到要获取的目标的id
    if (this.$route.params.id) {
      this.targetId = this.$route.params.id
    }
    // 从查询参数中接收detail_tab的值，把它设置给activeName，直接控制到tab的定位
    if (this.$route.query.detail_tab) {
      switch (this.$route.query.detail_tab) {
        case 'comment': {
          this.activeName = 'third'
        }
      }
    }
    // 获取日期
    this.getTodayDate()
    // 获取任务详情
    // this.getTargetDetail()
    // 获取职员列表
    this.getClerkTree()
    // 点击外面隐藏掉更多弹窗
    document.addEventListener('click', this.clickOut)
    this.searchDirectorAntiShare = this.$util.antiShare(this.searchClerkList)
    // this.commentList = []
  },
  methods: {
    ...mapActions(['getToday']),
    handerShowMenu() {
      if (this.showMenu === true) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
    },
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(
            reg,
            `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
          )
        : url
    },
    popupShow1() {
      this.popupShow = !this.popupShow
    },
    handellInput(val) {
      this.newComment = val
    },
    // 计算当前时间
    getTodayDate() {
      this.getToday().then((res) => {
        this.todayDate = res
      })
    },
    // 点击回溯
    changeBack() {
      this.targetId = this.fromWhere.objective_id
      this.fromWhere = this.targetInfo
    },
    // 获取一页目标记录
    async getTargetLogList(page = 1) {
      this.isLoadingLog = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetLog, {
          pageNumber: page,
          pageSize: this.targetLogPageInfo.pageSize,
          objective_id: this.realTargetId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log('获取到目标记录了吗', res.page.list)
        // 赋值给数组
        this.targetLogList = [...this.targetLogList, ...res.page.list]
        this.isLoadingLog = false
        // 给数据赋值
        this.targetLogPageInfo.totalPage = res.page.totalPage
        this.targetLogPageInfo.pageNumber = res.page.pageNumber
        this.targetLogPageInfo.totalRow = res.page.totalRow
      } else {
        this.isLoadingLog = false
      }
    },
    // 获取目标记录的下一页
    getLogNextPage(obj) {
      if (obj.id === 'record-data') {
        this.targetLogPageInfo.pageNumber = obj.page
        if (!this.isLoadingLog) {
          this.getTargetLogList(this.targetLogPageInfo.pageNumber)
        }
      }
    },
    // 刷新目标记录
    refreshTargetLog() {
      this.targetLogList = []
      if (!this.isLoadingLog) {
        this.getTargetLogList(1)
      }
    },
    // 设置评论的是谁
    setReplyObject(obj) {
      this.replyTo = obj
    },
    // 获取一页任务评论列表
    async getTargetCommentPage(page = 1) {
      this.isLoading = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetCommentPage, {
          objective_id: this.realTargetId,
          pageNumber: page,
          pageSize: this.commentListPageInfo.pageSize
        })
      )
      if (err) {
      } else if (res.state === 'ok') {
        this.commentList = [...this.commentList, ...res.page.list]
        this.commentListPageInfo.totalPage = res.page.totalPage
        this.commentListPageInfo.totalRow = res.page.totalRow
        this.commentListPageInfo.pageNumber = res.page.pageNumber
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    // 获取下一页目标评论列表
    getCommentNextPage() {
      this.commentListPageInfo.pageNumber++
      if (!this.isLoading) {
        this.getTargetCommentPage(this.commentListPageInfo.pageNumber)
      }
    },
    // 刷新目标评论列表
    refreshCommentData() {
      this.commentList = []
      this.commentListPageInfo.pageNumber = 1
      if (!this.isLoading) {
        this.getTargetCommentPage(1)
      }
    },
    // 取消聚焦
    cancelFocus() {
      this.isEditing = false
      if (this.replyTo && this.newComment.trim().length === 0) {
        this.replyTo = null
      }
    },
    // 添加评论
    async addNewComment() {
      this.isEditing = false
      const val = this.newComment.trim()
      if (val.length > 0) {
        const formData = {
          objective_id: this.targetInfo.objective_id,
          comment_content: val
        }
        if (this.replyTo && this.replyTo.pid !== -1) {
          formData.pid = this.replyTo.pid
        }
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addTargetComment, formData)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          // this.$message({
          //   showClose: true,
          //   message: '评论成功',
          //   type: 'success'
          // })
          // 还原输入框的状态
          this.newComment = ''
          this.replyTo = null
          // 刷新数据
          this.refreshCommentData()
        }
      }
    },
    // 点击目标名旁边的复选框
    checkTarget() {
      if (this.isAllowUpdate) {
        let val
        if (this.targetInfo.objective_status !== 3) {
          val = 3
        } else {
          val = 2
        }
        this.$http
          .post(this.$api.modifyTargetStatus, {
            objective_id: this.targetInfo.objective_id,
            objective_status: val
          })
          .then((res) => {
            if (res.state === 'ok') {
              this.isUpdateData = true
              // 换状态
              this.targetInfo.objective_status = val
              // 换当前状态的图标
              this.currentState = val - 1
            }
          })
      }
    },
    // 展示出父目标
    showParentInfo(id) {
      // this.isShowChild = true
      // this.currentChildId = id
      this.$emit('change-target-id', {
        id,
        fromWhere: this.taskInfo
      })
    },
    // 防抖搜索
    triggerSearchDirector() {
      this.searchDirectorAntiShare()
    },
    // 处理文件上传成功的事件
    closeUploadProgress(index) {
      this.uploadProgressList.splice(index, 1)
    },
    // 点击“添加子任务”
    toAddChildTarget() {
      if (this.childrenList.length === 0 && this.isAllowUpdate) {
        this.beginAddChildTarget()
      }
    },
    // 添加任务描述
    toAddTargetDes() {
      if (!this.targetInfo.objective_describe_text && this.isAllowUpdate) {
        this.isShowEdit = true
      }
    },
    // 点击上传新附件按钮
    toUploadAttachment() {
      this.$refs.uploadAttachmentRef.click()
    },
    // 上传文件
    async readyUpload(e) {
      const file = e.target.files[0]
      console.log('准备上传附件', this.targetInfo.objective_id)
      console.log('准备上传附件', file)
      // 上传进度对象
      const uploadProgress = {}
      uploadProgress.uploadFileName = file.name
      uploadProgress.uploadProgress = 0
      uploadProgress.id = ++this.uploadCount
      this.uploadProgressList.push(uploadProgress)
      // 准备一个formData对象
      const formData = new FormData()
      formData.append('file', file)
      formData.append('objective_id', this.targetInfo.objective_id)
      // 准备一个回调函数来获取上传进度
      const updateUploadProgress = (progressEvent) => {
        var complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
        uploadProgress.uploadProgress = complete
      }
      // 上传文件
      const { result, cancel } = this.$util.uploadFile(
        this.$api.uploadTargetAttachment,
        formData,
        updateUploadProgress
      )
      // 接收cancel方法
      uploadProgress.cancel = cancel
      result.then((res) => {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '上传附件成功！',
            type: 'success'
          })
          this.refreshAttachmentList()
        }
      })
    },
    // 刷新附件列表
    async refreshAttachmentList() {
      // 发请求，获取当前任务的列表
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetAttachmentList, {
          objective_id: this.targetInfo.objective_id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 覆盖掉当前显示出来的数组
        this.appendixList = [...res.list]
      }
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 获取目标详情
    async getTargetDetail(id = -1) {
      // 判断是否有targetId，如果有就使用；如果没有，就看我传入的参数
      if (this.targetId !== -1) {
        id = this.targetId
      }
      if (id !== -1) {
        // 获取任务详情
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.getTargetDetail, {
            objective_id: id
          })
        )
        this.isFirstInit = false
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            // 清空临时数据
            this.clearData()
            // 标签
            res.info.infoLabel = this.parseLabel(
              res.info.objective_name,
              res.info.objective_label_list
            )
            // 父级目标
            if (res.info.parent_info) {
              res.info.parent_info.infoLabel = this.parseLabel(
                res.info.parent_info.objective_name,
                res.info.parent_info.objective_label_list
              )
            }
            // 子目标
            if (res.info.child_list) {
              res.info.child_list = res.info.child_list.map((e) => {
                return {
                  ...e,
                  infoLabel: this.parseLabel(
                    e.objective_name,
                    e.objective_label_list
                  )
                }
              })
            }
            this.targetInfo = res.info
            // 初始化任务名称
            this.currentTargetName = this.targetInfo.objective_name
            // 初始化状态
            this.currentState = this.targetInfo.objective_status - 1
            // 初始化开始时间和结束时间
            // 如果有，转成Date类型的,否则转成null
            this.startTime = this.targetInfo.start_date || ''
            this.startTime = this.startTime.trim().slice(0, 10)
            this.endTime = this.targetInfo.end_date || ''
            this.endTime = this.endTime.trim().slice(0, 10)
            // 记录下开始时间和结束时间原始值（字符串类型）
            this.tempStartTimeStr = this.startTime
            this.tempEndTimeStr = this.endTime
            // 初始化目标类型
            this.currentTargetType = this.targetInfo.objective_type - 1
            // 初始化工作量
            this.workload = this.targetInfo.objective_workload
            // 初始化附件列表
            this.appendixList = [...this.targetInfo.attachment_list]
            console.log('拿到了任务详情', this.targetInfo)
            // 初始化父任务列表
            // if (this.targetInfo.parents_list) {
            //   this.targetInfo.parents_list.reverse()
            // }
            // 初始化子目标列表
            this.childrenTargetList = this.targetInfo.child_list

            // “自己”的头像
            const meAvatar = ''
            // 初始化子任务列表
            for (const childTask of this.targetInfo.child_task_list) {
              // 子任务的id和名字
              const task = {
                task_id: childTask.task_id,
                task_name: childTask.task_name,
                member_list: [],
                task_status: childTask.task_status,
                progress_status: childTask.progress_status,
                task_participant_list: childTask.task_participant_list,
                progress_number: childTask.progress_number
              }
              // 把子任务的负责人加上去
              task.leader = {
                clerk_id: childTask.director_clerk_id,
                clerk_avatar: childTask.director_clerk_avatar,
                clerk_alias: childTask.director_clerk_alias,
                clerk_name: childTask.director_clerk_name
              }
              // 把任务创建人放到成员列表第一个
              // 判断职员id是否是自己的id，是就换成“自己”的图片
              task.member_list.push({
                clerk_avatar:
                  childTask.create_clerk_id === this.$store.state.myClerkId
                    ? meAvatar
                    : childTask.create_clerk_avatar,
                clerk_id: childTask.create_clerk_id,
                clerk_alias: childTask.create_clerk_alias,
                clerk_name: childTask.create_clerk_name
              })
              // 判断创建人id和负责人id是否相同，如果不同，则把负责人放到成员列表的第二个
              if (childTask.create_clerk_id !== childTask.director_clerk_id) {
                task.member_list.push({
                  clerk_avatar:
                    childTask.director_clerk_id === this.$store.state.myClerkId
                      ? meAvatar
                      : childTask.director_clerk_avatar,
                  clerk_id: childTask.director_clerk_id,
                  clerk_alias: childTask.director_clerk_alias,
                  clerk_name: childTask.director_clerk_name
                })
              }
              // 接下来把任务参与者放到成员列表去
              for (let i = 0; i < childTask.task_participant_list.length; i++) {
                if (
                  childTask.task_participant_list[i].clerk_id ===
                  this.$store.state.myClerkId
                ) {
                  childTask.task_participant_list[i].clerk_avatar = meAvatar
                }
                // if (task.member_list.length < 3) {
                task.member_list.push(childTask.task_participant_list[i])
                // } else break
              }
              // 标签
              task.infoLabel = this.parseLabel(
                childTask.task_name,
                childTask.task_label_list
              )
              this.childrenTaskList.push(task)
            }
          }
        }
      }
    },
    // 开始输入目标名称
    beginInputName() {
      if (!this.isAllowUpdate) {
        return
      }
      this.isEditName = true
      this.currentTargetName = this.targetInfo.objective_name
      this.$nextTick(() => {
        this.$refs.inputTargetName.focus()
      })
    },
    // 任务名称输入完成
    handleInputName() {
      if (!this.isAllowUpdate) {
        return
      }
      const value = this.currentTargetName.trim()
      if (value.length === 0 || value === this.targetInfo.objective_name) {
        this.cancelInputName()
      } else {
        console.log('不为空，要提交。id为：', this.targetInfo.objective_id)
        this.editTargetName()
      }
    },
    // 取消输入任务名称
    cancelInputName() {
      this.isEditName = false
      this.currentTargetName = ''
    },
    // 发请求，修改任务名称
    async editTargetName() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetName, {
          objective_id: this.targetInfo.objective_id,
          objective_name: this.currentTargetName
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '任务名称修改成功！',
            type: 'success'
          })
          // 修改成功以后，把值写到页面上
          this.targetInfo.objective_name = this.currentTargetName
          this.$set(
            this.targetInfo,
            'infoLabel',
            this.parseLabel(
              this.targetInfo.objective_name,
              this.targetInfo.objective_label_list
            )
          )
          this.cancelInputName()
          this.isUpdateData = true
        }
      }
    },
    // 选择日期
    dataTimeChange(value) {
      console.log(value)
    },
    // 发请求，修改任务的开始和结束时间
    async editTargetStartOrEndTime(time, isStart) {
      // 根据是否是开始时间和时间来准备数据
      const formObj = {
        objective_id: this.targetInfo.objective_id
      }
      if (isStart) {
        formObj.start_time = this.startTime
      } else {
        formObj.end_time = this.endTime
      }
      // 发送请求
      const api = isStart
        ? this.$api.editTargetStartTime
        : this.$api.editTargetEndTime
      const [err, res] = await this.$util.to(this.$http.post(api, formObj))
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: `修改${isStart ? '开始' : '结束'}时间成功！`,
            type: 'success'
          })
          this.isUpdateData = true
          // 更新临时时间
          if (isStart) {
            this.tempStartTimeStr = formObj.start_time
          } else {
            this.tempEndTimeStr = formObj.end_time
          }
        } else {
          if (isStart) {
            this.startTime = this.tempStartTimeStr
          } else {
            this.endTime = this.tempEndTimeStr
          }
        }
      }
    },
    // 选择开始时间
    handleStartTime() {
      // 比对刚刚选择的时间和上次选择的时间
      const newTime = this.startTime
      if (
        newTime != null &&
        newTime !== this.tempStartTimeStr.trim().slice(0, 10)
      ) {
        // 发起请求
        this.editTargetStartOrEndTime(this.startTime, true)
      }
    },
    // 选择结束时间
    handleEndTime() {
      // 比对刚刚选择的时间和上次选择的时间
      const newTime = this.endTime
      if (
        newTime != null &&
        newTime !== this.tempEndTimeStr.trim().slice(0, 10)
      ) {
        // 发起请求
        this.editTargetStartOrEndTime(this.endTime, false)
      }
    },
    // 设置目标类型
    async chooseTargetType(val) {
      this.currentTargetType = val - 1
      this.isChoosePriority = false
      const formObj = {
        objective_id: this.targetInfo.objective_id,
        objective_type: val
      }
      console.log('准备修改目标类型了', formObj, val)
      // 修改完当前目标，然后发起请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetType, formObj)
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          // 把字面上的目标也修改了，让文字修改
          this.targetInfo.objective_type = this.currentTargetType + 1
          this.$message({
            showClose: true,
            message: '目标类型修改成功',
            type: 'success'
          })
          this.isUpdateData = true
        }
      }
    },
    // 准备提交工作量
    readySumbitWorkload() {
      const val = this.workload.trim()
      if (
        (this.targetInfo.objective_workload.length === 0 && val.length === 0) ||
        this.targetInfo.bjective_workload === val
      ) {
        this.cancelInputWorkload()
      } else {
        this.setTargetWorkload()
      }
    },
    // 取消工作量
    cancelInputWorkload() {
      // 还原原来的值
      this.workload = this.targetInfo.target_workload
    },
    // 设置工作量
    async setTargetWorkload() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetWorkload, {
          objective_id: this.targetInfo.objective_id,
          objective_workload: this.workload
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.targetInfo.objective_workload = this.workload
          this.$message({
            showClose: true,
            message: '修改工作量成功！',
            type: 'success'
          })
          this.isUpdateData = true
        }
      }
    },
    // 点击tab
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 显示隐藏选择优先级的弹窗
    toggleChoosePriority() {
      if (!this.isAllowUpdate) {
        return
      }
      this.clickOut()
      this.isChoosePriority = !this.isChoosePriority
    },
    // 显示出富文本编辑
    showEditBox() {
      if (!this.isAllowUpdate) {
        return
      }
      this.isShowEdit = true
      this.targetDescVal = {
        html: this.targetInfo.target_describe_html,
        text: this.targetInfo.target_describe_text
      }
    },
    // 点击保存
    async handleEditDes() {
      console.log(this.$refs.editor)
      const val = this.$refs.editor.getContent()
      // 发送请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetDescription, {
          objective_id: this.targetInfo.objective_id,
          objective_describe_html: val.html,
          objective_describe_text: val.text
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.targetInfo.objective_describe_html = val.html
          this.targetInfo.objective_describe_text = val.text
          this.cancelEditDes()
          this.$message({
            showClose: true,
            message: '修改任务描述成功!',
            type: 'success'
          })
          this.isUpdateData = true
        }
      }
    },
    // 点击取消
    cancelEditDes() {
      this.isShowEdit = false
      this.targetDescVal = {
        html: '',
        text: ''
      }
    },
    onClick(e, editor) {
      console.log('Element clicked')
    },
    // 选择当前状态
    async chooseState(index) {
      this.currentState = index
      console.log('当前目标的id是：', this.targetInfo.objective_id)
      const formObj = {
        objective_id: this.targetInfo.objective_id,
        objective_status: this.currentState + 1
      }
      console.log(formObj)
      // 发起修改任务状态请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTargetStatus, formObj)
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          // this.$message({
          //   showClose: true,
          //   message: '成功修改状态',
          //   type: 'success'
          // })
          this.isUpdateData = true
          this.isChooseState = false
          this.$set(this.targetInfo, 'target_status', this.currentState + 1)
        } else {
          this.getTargetDetail(this.targetInfo.objective_id)
        }
      }
    },
    // 点击显示隐藏选择状态的弹窗
    toggleChooseState() {
      if (!this.isAllowUpdate) {
        return
      }
      this.clickOut()
      this.isChooseState = !this.isChooseState
    },
    // 点击显示隐藏选择负责人的弹窗
    toggleChooseLeader() {
      if (!this.isAllowUpdate) {
        return
      }
      this.clickOut()
      this.isChooseLeader = !this.isChooseLeader
    },
    // 点击显示隐藏输入框
    toggleChildEdit(target) {
      if (!this.isAllowUpdate) {
        return
      }
      // 显示某个编辑任务名称的输入框，并且填入原本的任务名
      if (this.currentEditTarget !== target.target_id) {
        this.currentEditTarget = target.target_id
        this.childrenTemp = target.target_name
        this.$nextTick(() => {
          // 让它聚焦
          this.$refs[`child${target.target_id}`][0].focus()
        })
      } else {
        // 隐藏
        this.cancelEditChild()
      }
    },
    // 点到外面去，取消选择
    clickOut() {
      this.currentHandleChild = -1
      this.isChooseState = false
      this.isChoosePriority = false
      this.isChooseLeader = false
      this.isSetProgress = false
      this.isShowCommentExpression = false
    },
    // 开始输入评论
    beginEdit() {
      this.isEditing = true
    },
    // 点击删除按钮，删除一个成员id
    removeTempId(member) {
      const id = member.clerk_id
      console.log('要删除的成员id是', member.clerk_id)
      // 找到它在临时数组的下标
      const index = this.memberIdList.findIndex((item) => item === id)
      console.log('要删除的成员id下标是', index)
      // 从临时数组中删除它
      this.memberIdList.splice(index, 1)
      this.memberListTemp.splice(index, 1)
    },
    // keydown事件
    showCode(e) {
      console.log('正在输入', e.keyCode)
      // 如果是正在使用拼音输入法，不管输入什么，keyCode为229
      if (e.keyCode === 229) {
        this.keyCode = e.keyCode
      } else {
        this.keyCode = 0
      }
    },
    // 触发blur事件
    toBlur(e, args) {
      if (this.keyCode !== 229) {
        e.target.blur(args)
      }
    },
    // 把临时数据清空
    clearData() {
      this.isShowEdit = false
      this.startTime = null
      this.endTime = null
      this.leader = null
      this.childrenList = []
      this.childrenTaskList = []
      this.isAddChildTarget = false
      this.memberList = []
      this.currentId = -1
      this.memberListTemp = []
      this.memberIdList = []
      this.currentEditTarget = -1
      this.commentList = []
      this.targetLogList = []
      this.isShowCommentExpression = false
    },
    // 下载附件
    downloadAttachment(file) {
      this.$util.downloadFile(this.$api.downloadTargetAttachment, {
        target_id: this.realTargetId,
        attachment_id: file.attachment_id
      })
    },
    // 跳转到子目标的详情
    toChildDetail(id) {
      this.targetId = id
      this.fromWhere = this.targetInfo
    },
    // 点击选择关联父目标
    toRelativeModal() {
      if (this.isAllowUpdate) {
        this.isChooseRelative = true
        console.log(this.isChooseRelative)
      }
    },
    // 关闭关联弹窗
    closeRelativeModal() {
      this.isChooseRelative = false
    },
    // 换了父级目标，不仅要刷新详情数据，还要记录已经改了数据
    changeParent() {
      this.getTargetDetail()
      this.isUpdateData = true
    },
    // 判断是跳转到父目标还是弹窗
    toParentOrModal() {
      if (this.targetInfo.pid > 0) {
        this.toParent()
      } else {
        if (this.isAllowUpdate) {
          this.toRelativeModal()
        }
      }
    },
    // 跳转到父目标
    toParent() {
      this.targetId = this.targetInfo.pid
      this.fromWhere = this.targetInfo
    },
    // 开始新增子目标
    beginAddChildTarget() {
      this.isAddChildTarget = true
      this.$nextTick(() => {
        this.$refs.addChildTargetInput.focus()
      })
    },
    // 处理新增子目标
    async handleNewChild() {
      const pid = this.targetInfo.objective_id
      console.log(this)
      const formObj = {
        is_add_first_group: 1,
        'objective.objective_name': this.childrenTemp,
        'objective.period_date': this.$util.getDateStr(this.todayDate),
        pid: pid
      }
      console.log(formObj)
      if (!this.childrenTemp) {
        this.cancelNewChild()
      } else {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addTarget, formObj)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.isUpdateData = true
          this.getTargetDetail(this.targetInfo.objective_id)
          this.cancelNewChild()
        }
      }
    },
    // 取消新增子目标
    cancelNewChild() {
      this.isAddChildTarget = false
      this.childrenTemp = ''
    },
    // 删除当前目标
    async toDeleteTarget() {
      this.$util.showConfirm('确认要删除这个目标吗？').then((result) => {
        if (result) {
          this.$http
            .post(this.$api.deleteTarget, {
              objective_id: this.targetId
            })
            .then((res) => {
              if (res.state === 'ok') {
                this.$message({
                  showClose: true,
                  message: '目标删除成功',
                  type: 'success'
                })
                // 删除后跳转到首页
                this.$router.push('/')
              }
            })
        }
      })
    },
    // 复制链接
    copyLink() {
      this.$copyText(
        `${this.$config.frontHome}targetDetail/${this.targetId}`
      ).then(
        () => {
          // this.$message({
          //   showClose: true,
          //   message: '已成功复制，可直接去粘贴',
          //   type: 'success'
          // })
          this.isShowTip = true
          // 2秒后提示隐藏
          setTimeout(() => {
            this.isShowTip = false
          }, 2000)
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // 显示隐藏进度设置弹窗
    toggleSetProgress() {
      this.isSetProgress = !this.isSetProgress
      if (this.isSetProgress) {
        this.currentProgress = this.targetInfo.progress_number
        this.temp = this.currentProgress
      }
    },
    // 设置任务的进度
    async editProgress({ id, index }) {
      // 假如把里面的数字删干净了，视为没有修改
      if (this.currentProgress.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$nextTick(() => {
          if (index > -1) {
            this.$refs[`${id}progressRef`][0].blur()
          } else {
            this.$refs[`${id}progressRef`].blur()
          }
        })
      } else if (
        Number(this.currentProgress) < 0 ||
        Number(this.currentProgress) > 100
      ) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$nextTick(() => {
          if (index > -1) {
            this.$refs[`${id}progressRef`][0].blur()
          } else {
            this.$refs[`${id}progressRef`].blur()
          }
        })
      } else {
        // 假如输入正确，那就发起修改请求
        this.$nextTick(() => {
          if (index > -1) {
            this.$refs[`${id}progressRef`][0].blur()
          } else {
            this.$refs[`${id}progressRef`].blur()
          }
        })
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTargetProgressNumber, {
            objective_id: id,
            progress_number: this.currentProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '目标的进度修改成功！',
              showClose: true
            })
            // 成功后的处理
            // 区分是设置子任务的进度还是本任务的进度（index大于-1表示子任务）
            if (index > -1) {
              // 让进度弹窗消失
              this.$set(this.childrenList[index], 'isChooseProgress', false)
              // 修改进度的值
              this.$set(
                this.childrenList[index],
                'progress_number',
                +this.currentProgress
              )
            } else {
              this.isSetProgress = false
              this.targetInfo.progress_number = +this.currentProgress
            }
          }
        }
      }
    },
    // 设置目标的进度状态
    async setTargetProgressStatus(val, id, index) {
      // 发请求，修改任务的进度状态
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetProgressStatus, {
          objective_id: id,
          progress_status: val
        })
      )
      if (err) {
        console.log(res)
      } else {
        console.log(res)
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改目标进度状态成功！',
            type: 'success'
          })
          // 成功后的处理
          if (index > -1) {
            // 修改后改变状态
            this.childrenList[index].progress_status = val
            this.$set(this.childrenList[index], 'isChooseProgress', false)
          } else {
            // 如果修改的是本任务的进度状态
            this.targetInfo.progress_status = val
            this.isSetProgress = false
          }
        }
      }
    },
    // 点击表情
    addExpress(express) {
      this.newComment = this.newComment.replace('<p>&nbsp;', '<p>')
      const index = this.newComment.lastIndexOf('</p>')
      if (index === -1) {
        this.newComment = '<p>' + express.name + '</p>'
      } else {
        this.newComment =
          this.newComment.substring(0, index) + express.name + '</p>'
      }
      this.isShowCommentExpression = false
      setTimeout(() => {
        // 让输入框重新聚焦
        this.$refs.commentRef.setFocus()
      }, 50)
    },
    // 显示隐藏表情
    toggleCommentExpression(val) {
      this.isShowCommentExpression = !val
    },
    // 空函数
    empty() {},
    // 点击“添加子任务”
    toAddChildTask() {
      if (this.childrenTaskList.length === 0 && this.isAllowUpdate) {
        this.beginAddChildTask()
      }
    },
    // 准备添加子任务
    beginAddChildTask() {
      this.isAddChildTask = true
      this.currentEditChildTask = -1
      this.childTaskTemp = ''
      this.$nextTick(() => {
        this.$refs.addChildTaskInput.focus()
      })
    },
    // 设置子任务是否选中
    async checkChildTask(task) {
      if (this.isAllowUpdate) {
        let val = 0
        if (task.task_status === 3 || task.task_status === 4) {
          val = 2
        } else {
          val = 3
        }
        const formObj = {
          task_id: task.task_id,
          task_status: val
        }
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.modifyTaskStatus, formObj)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.childrenTaskList.findIndex((e, i) => {
            if (e.task_id === task.task_id) {
              this.childrenTaskList[i].task_status = val
              // 如果现在点击后是“已完成”，把进度改为100%
              if (val === 3) {
                this.childrenTaskList[i].progress_number = 100
              }
              return true
            }
          })
          // this.getTaskDetail(this.taskInfo.task_id)
        }
      }
    },
    // 开始设置子任务进度
    beginEditChildTaskProgress(index) {
      if (this.isAllowUpdate) {
        for (const task of this.childrenTaskList) {
          this.$set(task, 'isChooseProgress', false)
        }
        // 显示出对应的弹窗，并且初始化数值
        this.$set(this.childrenTaskList[index], 'isChooseProgress', true)
        this.currentChildTaskProgress =
          this.childrenTaskList[index].progress_number
        this.tempChildTaskProgress =
          this.childrenTaskList[index].progress_number
      }
    },
    // 准备设置子任务的进度
    async editChildTaskProgress({ id, index }) {
      // 假如把里面的数字删干净了，视为没有修改
      if (this.currentChildTaskProgress.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentChildTaskProgress = this.tempChildTaskProgress
        this.$nextTick(() => {
          this.$refs[`${id}progressRef`][0].blur()
        })
      } else if (
        Number(this.currentChildTaskProgress) < 0 ||
        Number(this.currentChildTaskProgress) > 100
      ) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentChildTaskProgress = this.tempChildTaskProgress
        this.$nextTick(() => {
          this.$refs[`${id}progressRef`][0].blur()
        })
      } else {
        // 假如输入正确，那就发起修改请求
        this.$nextTick(() => {
          this.$refs[`${id}progressRef`][0].blur()
        })
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: this.currentChildTaskProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '任务的进度修改成功！',
              showClose: true
            })
            // 让进度弹窗消失
            this.$set(this.childrenTaskList[index], 'isChooseProgress', false)
            // 修改进度的值
            this.$set(
              this.childrenTaskList[index],
              'progress_number',
              +this.currentChildTaskProgress
            )
            // 如果进度不是100%，且任务状态为“已完成”，则把状态改为“未完成”
            if (
              +this.currentChildTaskProgress < 100 &&
              this.childrenTaskList[index].task_status === 3
            ) {
              this.$set(this.childrenTaskList[index], 'task_status', 2)
            }
          }
        }
      }
    },
    // 设置子进度状态
    async setChildTaskProgressStatus(val, id, index) {
      // 发请求，修改任务的进度状态
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskProgressStatus, {
          task_id: id,
          progress_status: val
        })
      )
      if (err) {
        console.log(res)
      } else {
        console.log(res)
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务进度状态成功！',
            type: 'success'
          })
          // 修改后改变状态
          this.childrenTaskList[index].progress_status = val
          this.$set(this.childrenTaskList[index], 'isChooseProgress', false)
        }
      }
    },
    // 确定修改子任务名字
    async handleEditTaskChild(task) {
      const val = this.childTaskTemp.trim()
      if (val.length > 0 && val !== task.task_name) {
        console.log('不为空，需要提交。id为', task.task_id)
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskName, {
            task_id: task.task_id,
            task_name: val
          })
        )
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            task.task_name = val
            this.cancelEditChildTask()
            this.$message({
              showClose: true,
              message: '修改子任务名称成功',
              type: 'success'
            })
            this.isUpdateData = true
          }
        }
      } else {
        this.cancelEditChildTask()
      }
    },
    // 取消修改子任务
    cancelEditChildTask() {
      this.childTaskTemp = ''
      this.currentEditChildTask = -1
    },
    // 开始设置子任务的负责人
    beginChooseChildTaskLeader(index) {
      if (this.isAllowUpdate) {
        console.log('准备选择子组件的负责人', index)
        this.$set(
          this.childrenTaskList[index],
          'isChooseLeader',
          !this.childrenTaskList[index].isChooseLeader
        )
      }
    },
    // 确定选择子组件的负责人
    chooseChildTaskLeader({ leader, isUpdateData }, index) {
      const isChange =
        this.childrenTaskList[index].leader.clerk_id !== leader.clerk_id
      this.$set(this.childrenTaskList[index], 'leader', leader)
      this.$set(this.childrenTaskList[index], 'isChooseLeader', false)
      if (isChange) {
        this.childrenTaskList.splice(index, 1)
      }
      this.isUpdateData = isUpdateData
    },
    // 如果点到外面，关闭子任务的负责人组件
    closeChooseTaskLeader() {
      for (const child of this.childrenTaskList) {
        this.$set(child, 'isChooseLeader', false)
      }
    },
    // 删除子任务
    deleteChildTask(task, index) {
      this.$util
        .showConfirm('此操作将永久删除该任务, 是否继续?')
        .then(async (result) => {
          if (result) {
            // 确定要删除，那就发请求
            const [err, res] = await this.$util.to(
              this.$http.post(this.$api.removeTask, {
                task_id: task.task_id
              })
            )
            if (err) {
              console.log(err)
            } else {
              if (res.state === 'ok') {
                this.$message({
                  showClose: true,
                  message: '删除子任务成功',
                  type: 'success'
                })
                this.isUpdateData = true
                // 在页面上删除数组里的这个元素
                this.childrenTaskList.splice(index, 1)
              }
            }
          }
        })
    },
    // 点击显示隐藏输入框
    toggleChildEditTask(task) {
      if (!this.isAllowUpdate) {
        return
      }
      // 显示某个编辑任务名称的输入框，并且填入原本的任务名
      if (this.currentEditChildTask !== task.task_id) {
        this.currentEditChildTask = task.task_id
        this.childTaskTemp = task.task_name
        this.$nextTick(() => {
          console.log(this.$refs)
          // 让它聚焦
          this.$refs[`childTask${task.task_id}`][0].focus()
        })
      } else {
        // 隐藏
        this.cancelEditChildTask()
      }
    },
    // 处理新增子任务
    async handleNewChildTask() {
      const val = this.childTaskTemp.trim()
      if (val.length > 0) {
        console.log('父任务id是：', this.targetInfo.objective_id)
        // 不为空，要提交
        // 发起添加子任务的请求
        const formObj = {
          'task.task_name': val,
          'task.period_date': this.$util.getDateStr(new Date()),
          objective_id: this.targetInfo.objective_id
        }

        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addNewTask, formObj)
        )
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            this.cancelNewChildTask()
            this.isUpdateData = true
            // 刷新任务详情数据
            this.getTargetDetail(this.targetInfo.objective_id).then(() => {
              console.log('子任务新增完成')
            })
          }
        }
      } else {
        this.isAddChildTask = false
      }
    },
    // 取消新增子任务
    cancelNewChildTask() {
      this.childTaskTemp = ''
      this.isAddChildTask = false
    },
    // 跳转到父目标
    toTask(id) {
      this.$router.push(`/taskDetail/${id}`)
    },
    // 解绑子任务
    async unbindTask(task, index) {
      this.$util.showConfirm('是否确认与目标解除关联?').then(async (result) => {
        if (result) {
          // 发请求，修改任务的进度状态
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.unbindTaskObjective, {
              task_id: task.task_id
            })
          )
          if (err) {
            console.log(res)
          } else {
            console.log(res)
            if (res.state === 'ok') {
              this.$message({
                showClose: true,
                message: '子任务解绑成功！',
                type: 'success'
              })
              this.isUpdateData = true
              this.childrenTaskList.splice(index, 1)
            }
          }
        }
      })
    },
    // 解析标签
    parseLabel(name, labelList) {
      const labelMap = {}
      labelList.forEach((e) => {
        labelMap[e.label_name] = e.label_id
      })
      let showName = ''
      const list = []
      let status = 0
      let gth = 0
      let item = ''
      name.split('').forEach((e) => {
        let te = e
        // 处理下中文符号
        switch (te) {
          case '！': {
            te = '!'
          }
        }
        switch (status) {
          // 开始
          case 0: {
            switch (te) {
              case '!': {
                if (gth === 0) {
                  gth++
                  status = 1
                } else {
                  showName += e
                  status = 11
                }
                break
              }
              case ' ': {
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                showName += e
                status = 11
                break
              }
            }
            break
          }
          case 1: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                status = 0
                showName += e
                break
              }
            }
            break
          }
          case 2: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              default: {
                status = 11
                showName += e
                break
              }
            }
            break
          }
          case 10: {
            switch (te) {
              case '!': {
                if (item !== '') {
                  list.push(item)
                } else {
                  showName += '#'
                }
                item = ''
                if (gth === 0) {
                  gth++
                  status = 2
                } else {
                  status = 11
                }
                break
              }
              case ' ': {
                if (item !== '') {
                  list.push(item)
                }
                item = ''
                status = 0
                break
              }
              default: {
                item += e
                break
              }
            }
            break
          }
          // 直接加
          case 11: {
            showName += e
            break
          }
        }
      })
      if (status === 10) {
        if (item.length > 0) {
          list.push(item)
        }
      }
      let gthStr = ''
      for (let i = 0; i < gth; i++) {
        gthStr += '!'
      }
      return {
        showName,
        list: list.map((e) => {
          return {
            id: labelMap[e] || '',
            name: e
          }
        }),
        gth,
        gthStr
      }
    }
  },
  watch: {
    async targetId(newValue, oldValue) {
      // 清除回复对象
      this.replyTo = null
      // 清除评论内容
      this.newComment = ''
      if (newValue !== -1) {
        console.log('old' + oldValue + 'new' + newValue)
        // id一改变，就重定向
        this.$router.push(`/targetDetail/${newValue}`)
        await this.getTargetDetail(newValue)
        // 获取任务评论的第一页
        await this.getTargetCommentPage(1)
        // 获取任务记录第一页
        this.getTargetLogList(1)
      }
    },
    // 假如它显示了，就去获取任务详情
    async isShow(newValue) {
      if (newValue === true) {
        await this.getTargetDetail(this.targetId)
        // 获取任务评论的第一页
        await this.getTargetCommentPage(1)
        // 获取任务记录第一页
        this.getTargetLogList(1)
        this.activeName = this.currentTab
      }
    },
    currentTab: {
      deep: true,
      handler(val) {
        if (val !== 'first') {
          this.activeName = val
        }
      }
    },
    clickBodyIndex(val) {
      this.showMenu = false
    }
    // nowTime(newValue) {
    //   // console.log(newValue)
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/priority_tags.scss';
@import '@/assets/scss/choose_member_style.scss';
@import '@/assets/scss/task_detail_style.scss';
</style>
<style lang="scss">
.basic-info-item.cursor .dashboard-editor-container .el-input__inner {
  cursor: pointer !important;
}

// 目标无类型
.choose-target .target-type-box.no-style {
  padding: 0;
  font-size: 14px;
  line-height: 34px;
  color: #bbb;
}

// 关联父目标
.to-relative {
  padding-bottom: 20px;
  margin-bottom: 26px;
  border-bottom: 1px dashed #eee;
  // 图标
  .iconfont {
    cursor: pointer;
    display: block;
    font-size: 26px;
    line-height: 1;
    color: #bbb;
    &.active {
      color: $main-color;
    }
  }
  // 父目标的名字
  .relative-parent {
    cursor: pointer;
    padding-left: 10px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    &:hover {
      color: $main-color;
    }

    &.none {
      cursor: default;
      &:hover {
        color: #333;
      }
    }
  }
}

.task-title.target-title {
  margin-bottom: 21px;
  width: calc(100% - 64px);
}

.task-info-box.target-info-box.border-b,
.task-basic-info.target-basic-info.border-b {
  border-bottom: 1px dashed #eee;
}
</style>
<style lang="scss" scoped>
.message-menu {
  position: absolute;
  width: 92px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  right: -6px;
  top: 37px;
  border-radius: 4px;
  padding: 6px 0;

  .menu-item {
    cursor: pointer;
    width: 76px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    margin: 0 auto;

    &:hover {
      color: $main-color;
      background-color: rgba(0, 128, 255, 0.1);
      border-radius: 4px;
    }

    &.delete-btn {
      color: $red;

      &:hover {
        background-color: rgba(255, 84, 84, 0.1);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .right-top-box {
    right: 16px !important;
  }
  .modal-right-box::v-deep .el-tab-pane {
    max-height: calc(100vh - 62px - 24px - 24px - 60px) !important;
  }
  .comments .content-box {
    height: calc(100vh - 62px - 28px - 30px - 144px - 60px) !important;
  }
  .child-name {
    width: 260px !important;
  }
}
@media screen and (min-width: 768px) {
  .child-name {
    width: 650px !important;
    max-width: 100% !important;
    padding-right: 20px;
  }
  .child-task-name {
    width: calc(100% - 60px) !important;
  }
}
.expresion-weizhi::v-deep {
  .expression-box {
    right: -70px !important;
    left: auto !important;
    z-index: 2;
  }
}
.comments .content-box {
  height: calc(100vh - 62px - 28px - 30px - 144px - 124px);
}

.child-target-item .target-name {
  max-width: 100%;
  padding-right: 20px;
}

.target-label,
.child-target-label,
.child-task-label,
.from-target-label,
.parent-target-label {
  display: inline-block;
  vertical-align: middle;
  width: min-content;
  white-space: nowrap;
  color: #2b6cff;
  background: #e2ebff;
}

// 任务的标签
.target-label {
  margin: 0 3px 0 0;
  padding: 0 5px;
  line-height: 33px;
  border-radius: 3px;
  font-size: 20px;
}

// 任务的标签
.child-target-label {
  margin: 0 3px 0 0;
  padding: 0 3px;
  line-height: 20px;
  border-radius: 3px;
  font-size: 12px;
}

// 任务的标签
.child-task-label {
  margin: 7px 3px 0 0;
  padding: 0 3px;
  line-height: 20px;
  border-radius: 3px;
  font-size: 12px;
}

// 来源目标的标签
.from-target-label {
  margin: 0 3px 0 0;
  padding: 0 3px;
  line-height: 21px;
  color: #2b6cff;
  font-size: 12px;
}

// 父级目标的标签
.parent-target-label {
  margin: 0 3px 0 0;
  padding: 0 3px;
  line-height: 24px;
  color: #2b6cff;
  font-size: 12px;
}
.textarea::v-deep {
  .ck-focused {
    border: none !important;
  }
  .ck-editor__editable.ck-blurred {
    border: none !important;
  }
  .ck-editor__editable_inline {
    padding: 0;
  }
  .ck-editor__editable {
    line-height: normal;
  }
}
@media screen and (max-width: 766px) {
  .single-box {
    margin: 0 auto !important;
  }
  .info-indent-between {
    justify-content: space-between;
  }
}
</style>
