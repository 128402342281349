<!--
 * @Author: hzh
 * @Date: 2022-01-04 15:57:26
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-08 13:45:12
-->
<template>
  <el-popover
    :placement="placement"
    popper-class="meeting-room-occupy-meeting-timeline-popover"
    width="350"
    trigger="manual"
    v-model="myVisible"
  >
    <template #reference>
      <slot></slot>
    </template>
    <template #default>
      <div class="info-box" @click="clickBody()">
        <i class="el-icon-close close-btn" @click="close()"></i>
        <div class="header">
          {{ $util.toDateString(date, 'yyyy年MM月dd日') }}
          {{
            ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][
              date.getDay()
            ]
          }}
        </div>
        <div class="content-scroll" >
          <div class="content-box">
            <div class="timeline-list">
              <div
                class="timeline-item flex"
                v-for="(item, index) in renderData.timeIntervalList"
                :key="index"
              >
                <div
                  class="time flex flex-y-reverse flex-x-between flex-shrink"
                >
                  <div class="end-time">{{ item.end.timeStr }}</div>
                  <div class="start-time" v-if="index === 0">
                    {{ item.start.timeStr }}
                  </div>
                </div>
                <div
                  class="grid"
                  :class="{
                    before: item.isBefore
                  }"
                  :style="{
                    height: `${item.height}px`
                  }"
                ></div>
              </div>
            </div>
            <div
              class="now-line"
              v-if="nowLineTop !== -1"
              :style="{
                top: `${nowLineTop}px`
              }"
            ></div>
            <div class="meeting-list">
              <template v-for="item in renderData.meetingList">
                <info-popover
                  :key="item.meeting_id"
                  :data="item.info"
                  placement="left-start"
                >
                  <template #default>
                    <div
                      class="meeting-item"
                      @click.stop="
                        clickBody()
                        showInfo = true
                      "
                      :style="{
                        height: `${item.height}px`,
                        top: `${item.top}px`
                      }"
                    >
                      <div class="meeting-header e">
                        {{ item.info.meeting_name }}
                      </div>
                      <div class="meeting-time">
                        {{ $util.toDateString(item.info.start_time, 'HH:mm') }}
                        -
                        {{ $util.toDateString(item.info.end_time, 'HH:mm') }}
                      </div>
                    </div>
                  </template>
                </info-popover>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script>
import InfoPopover from './meeting-room-occupy-meeting-info-popover'
export default {
  name: 'meetingRoomOccupyMeetingTimelinePopover',
  components: {
    InfoPopover
  },
  props: {
    date: {
      type: Date
    },
    list: {
      type: Array,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    now: {
      type: Date,
      default: () => {
        return new Date()
      }
    },
    placement: {
      type: String,
      default: 'left-start'
    }
  },
  data() {
    return {
      myVisible: false,
      // 是否展示信息
      showInfo: false,
      // 时间间隔(分钟)
      timeInterval: 60
    }
  },
  computed: {
    nowTime() {
      return this.now.getTime()
    },
    // 时间刻度列表
    timeScaleList() {
      const list = []
      const zeroTime = new Date(this.date)
      zeroTime.setHours(0)
      zeroTime.setMinutes(0)
      zeroTime.setSeconds(0)
      zeroTime.setMilliseconds(0)
      for (let i = 0, len = 1440 / this.timeInterval; i <= len; i++) {
        const tmp = new Date(
          zeroTime.getTime() + i * this.timeInterval * 1000 * 60
        )
        list.push({
          timeStr: i === len ? '24:00' : this.$util.toDateString(tmp, 'HH:mm'),
          timeObj: tmp,
          time: tmp.getTime()
        })
      }
      return list
    },
    // 渲染数据
    renderData() {
      // 时间间隔列表
      const timeIntervalList = []
      // const defaultRatio = 1.4
      // 一行高度
      const lineHeight = 40
      // 文字最小高度
      const fontMinHeight = 40
      // 默认比例
      const defaultRatio = lineHeight / this.timeInterval
      // 会议列表
      let meetingList = []
      this.list.forEach((e) => {
        const endTime = e.end_time_obj.getTime()
        const startTime = e.start_time_obj.getTime()
        const minutes = (endTime - startTime) / 1000 / 60
        // 会议分钟时长 * 默认比例 小于 最小高度 时 默认比例要变大 最小文字高度 / 会议分钟时长
        let ratio = defaultRatio
        if (minutes * defaultRatio < fontMinHeight) {
          ratio = fontMinHeight / minutes
        }
        meetingList.push({
          info: e,
          ratio
        })
      })
      // 处理时间间隔
      let totalHeight = 0
      for (let i = 1; i < this.timeScaleList.length; i++) {
        // 比例
        let ratio = defaultRatio
        const startTime = this.timeScaleList[i - 1].time
        const endTime = this.timeScaleList[i].time - 1
        // 包含的会议
        const containMeetingList = meetingList.filter((e) => {
          const meetingStartTime = e.info.start_time_obj.getTime()
          const meetingEndTime = e.info.end_time_obj.getTime()
          return (
            (meetingStartTime >= startTime && meetingStartTime <= endTime) ||
            (meetingStartTime <= startTime && meetingEndTime >= endTime) ||
            (meetingEndTime >= startTime && meetingEndTime <= endTime)
          )
        })
        // 判断比例最大值
        containMeetingList.sort((a, b) => {
          return b.ratio - a.ratio
        })
        if (containMeetingList.length > 0) {
          if (containMeetingList[0].ratio > ratio) {
            ratio = containMeetingList[0].ratio
          }
        }
        const currentLineHeight = ratio * this.timeInterval
        // 总高度
        totalHeight += currentLineHeight
        timeIntervalList.push({
          start: this.timeScaleList[i - 1],
          end: this.timeScaleList[i],
          isBefore: endTime < this.nowTime,
          ratio,
          height: currentLineHeight,
          meetingIdList: containMeetingList.map((e) => e.info.meeting_id)
        })
      }
      // 处理会议的top和height
      meetingList = meetingList.map((me) => {
        let top = 0
        let height = 0
        const mStartTime = me.info.start_time_obj.getTime()
        const mEndTime = me.info.end_time_obj.getTime()
        // 会议开始时间和结束时间都不是今天的 直接占满高度
        if (
          mStartTime < timeIntervalList[0].start.time &&
          mEndTime > timeIntervalList[timeIntervalList.length - 1].end.time
        ) {
          return { ...me, top, height: totalHeight }
        }
        // 循环时间段计算top和height
        for (let i = 0; i < timeIntervalList.length; i++) {
          const te = timeIntervalList[i]
          const tStartTime = te.start.time
          const tEndTime = te.end.time - 1
          // 这个时间段有包含这个会议
          if (te.meetingIdList.includes(me.info.meeting_id)) {
            let hasStart = false
            let hasEnd = false
            // 开始时间在这个时间段
            if (mStartTime >= tStartTime && mStartTime <= tEndTime) {
              hasStart = true
              top += ((mStartTime - tStartTime) / 1000 / 60) * te.ratio
            }
            // 结束时间在这个时间段
            if (mEndTime >= tStartTime && mEndTime <= tEndTime) {
              hasEnd = true
              // 开始时间也在这个时间段里
              if (hasStart) {
                height += ((mEndTime - mStartTime) / 1000 / 60) * te.ratio
              } else {
                height += ((mEndTime - tStartTime) / 1000 / 60) * te.ratio
              }
            }
            // 开始和结束时间都不在这个时间段
            if (!hasStart && !hasEnd) {
              height += te.height
            } else if (hasStart && !hasEnd) {
              // 这个时间有开始时间但是没有结束时间
              height +=
                te.height - ((mStartTime - tStartTime) / 1000 / 60) * te.ratio
            }
            // 这个时间段有结束时间直接跳出去
            if (hasEnd) {
              break
            }
          } else {
            top += te.height
          }
        }
        return { ...me, top, height: height }
      })

      return { timeIntervalList, meetingList }
    },
    // 当前时间线位置
    nowLineTop() {
      // 不是今天的返回-1
      if (
        this.nowTime < this.renderData.timeIntervalList[0].start.time ||
        this.nowTime >
          this.renderData.timeIntervalList[
            this.renderData.timeIntervalList.length - 1
          ].end.time
      ) {
        return -1
      }
      let top = 0
      for (let i = 0; i < this.renderData.timeIntervalList.length; i++) {
        const e = this.renderData.timeIntervalList[i]
        if (this.nowTime >= e.end.time) {
          top += e.height
        } else {
          top +=
            ((this.nowTime - e.start.time) / (e.end.time - e.start.time)) *
            e.height
          break
        }
      }
      return top
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    },
    clickBody() {
      document.body.click()
    },
    hideInfo() {
      if (this.showInfo) {
        this.clickBody()
      }
    }
  },
  mounted() {},
  watch: {
    visible(val) {
      this.myVisible = this.visible
    }
  }
}
</script>

<style lang="scss">
.el-popover.meeting-room-occupy-meeting-timeline-popover {
  padding: 0 0 10px 0;
}
</style>

<style lang="scss" scoped>
.info-box {
  .header {
    font-size: 16px;
    color: #333;
    font-weight: 700;
    padding: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .content-scroll {
    height: 500px;
    overflow-y: scroll;
    padding: 30px 20px;

    .content-box {
      position: relative;
    }

    .timeline-list {
      position: relative;
    }

    .timeline-item {
      .time {
        position: relative;
        width: 45px;
      }
      .start-time,
      .end-time {
        position: absolute;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
      .start-time {
        top: -10px;
      }
      .end-time {
        bottom: -10px;
      }
      .grid {
        width: 100%;
        border-bottom: 1px solid #ececec;
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;
        box-sizing: border-box;

        &.before {
          background-color: #f9f9f9;
        }
      }

      &:first-child .grid {
        border-top: 1px solid #ececec;
      }
    }

    .now-line {
      position: absolute;
      width: 265px;
      height: 1px;
      right: 0;
      background-color: #5578eb;
      &::after {
        content: '';
        position: absolute;
        left: -5px;
        top: -5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #5578eb;
      }
    }

    .meeting-list {
      position: absolute;
      height: 100%;
      width: 265px;
      right: 0;
      top: 0;
      .meeting-item {
        position: absolute;
        width: 100%;
        background-color: #eef1ff;
        padding: 0 10px;
        box-sizing: border-box;
        .meeting-header,
        .meeting-time {
          color: #5578eb;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 25px;
    font-size: 20px;
  }
}
</style>
