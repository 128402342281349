<template>
  <!-- 编辑文件名弹窗 -->
  <div
    class="share-pop-mask flex flex-x-center flex-y-center"
    @click.stop="$emit('cancle')"
  >
    <div class="share-pop flex flex-y-positive flex-y-center" @click.stop>
      <div class="flex share-header flex-x-between flex-y-center">
        <div class="title">分享圈子</div>
        <div @click="$emit('cancle')" class="icon-decline close-button"></div>
      </div>
      <div class="file-info flex flex-y-center">
        <el-image
          v-if="isPic"
          :src="info.attachment_info.attachment_path"
          :preview-src-list="[info.attachment_info.attachment_path]"
          style="width: 52px; height: 52px; margin-left: 16px"
        >
        </el-image>
        <svg class="icon" aria-hidden="true" v-else>
          <use :xlink:href="fileIcon"></use>
        </svg>
        <div class="file-text-info flex flex-y-positive flex-x-between">
          <div class="file-name">{{ info.knowl_name || '' }}</div>
          <div class="flex name-time flex-y-center">
            <div class="editor-name">
              {{
                info.update_clerk_alias ||
                info.update_clerk_name ||
                info.create_clerk_alias ||
                info.create_clerk_name
              }}
            </div>
            <div class="send-time">
              发布于{{
                info.update_time || info.create_time
                  ? $util.getTimeStr(info.update_time || info.create_time)
                  : ''
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- 输入框 -->
      <el-input
        type="textarea"
        placeholder="编辑动态内容"
        ref="contentRef"
        v-model="content"
      >
      </el-input>
      <!-- 按钮 -->
      <div class="button-box flex flex-x-between">
        <expression-box
          :isShow="isShowCommentExpression"
          :expressList="$emoji.slice(0, 100)"
          @add-express="addExpress($event)"
          @toggle-express-box="toggleCommentExpression($event)"
        ></expression-box>
        <div class="share-button" @click="confirm">分享至圈子</div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入表情组件
import ExpressionBox from '../common/ExpressionBox.vue'
export default {
  name: 'knowledge-share-pop',
  components: {
    ExpressionBox
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      content: '',
      isShowCommentExpression: false
    }
  },
  computed: {
    // 附件信息
    attachmentInfo() {
      return this.info.attachment_info || {}
    },
    // 判断是否是图片
    isPic() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      return imgTypes.includes(type)
    },
    // 判断是否为图片
    isVideo() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const videoTypes = ['mp4']
      return videoTypes.includes(type)
    },
    // 获取文件的图标
    fileIcon() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      const type = file.file_ext
      switch (type) {
        // 如果是doc文件
        case 'doc':
        case 'docx':
          return '#icon-Frame3'
        // 如果是PDF
        case 'pdf':
          return '#icon-Frame4'
        // 如果是Excel
        case 'xls':
        case 'xlsx':
          return '#icon-Frame2'
        // 如果是图片，返回图片本身
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'png':
        case 'gif':
          return file.attachment_path
        // 如果是PPT
        case 'ppt':
        case 'pptx':
          return '#icon-Frame5'
        // 如果是TXT
        case 'txt':
          return '#icon-Frame12'
        // 如果是其他
        default:
          return '#icon-Frame21'
      }
    }
  },
  methods: {
    // 点击表情
    addExpress(express) {
      this.content += express.char
      this.isShowCommentExpression = false
      this.$refs.contentRef.focus()
    },
    // 显示隐藏表情
    toggleCommentExpression(val) {
      this.isShowCommentExpression = !val
    },
    // 确认
    confirm() {
      this.$emit('confirm', {
        content: this.content
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.share-pop-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  .share-pop {
    width: 620px;
    height: 442px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;

    .share-header {
      width: 620px;
      height: 44px;
      padding: 11px 18px 11px 26px;
      border-bottom: 1px solid #eeeeee;
      box-sizing: border-box;

      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
      }

      .close-button {
        cursor: pointer;
        color: $main-color;
        padding: 8px;
        font-size: 12px;
        border-radius: 6px;
        background-color: rgba(0, 128, 255, 0.1);
      }
    }

    .file-info {
      width: 568px;
      height: 84px;
      border: 1px solid #eeeeee;
      border-radius: 6px;
      margin: 20px 0 20px 0;
      .icon {
        font-size: 52px;
        margin-left: 16px;
      }
      .file-text-info {
        margin-left: 16px;
        height: 55px;
        .file-name {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          line-height: 24px;
        }
        .name-time {
          font-size: 14px;
          color: #999999;
          .editor-name {
            box-sizing: border-box;
            padding-right: 12px;
            border-right: 1px solid #dddddd;
          }
          .send-time {
            margin-left: 16px;
          }
        }
      }
    }

    .button-box {
      width: 568px;
      height: 34px;
      margin-top: 12px;
      .icon-Frame {
        color: #ffb72c;
        font-size: 28px;
      }
      .share-button {
        width: 100px;
        height: 34px;
        background: #0080ff;
        border-radius: 36px;
        font-weight: 500;
        font-size: 14px;
        line-height: 34px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.share-pop::v-deep .el-textarea {
  width: 568px;
  height: 200px;
  .el-textarea__inner {
    height: 200px;
    resize: none;
    border: 1px solid #eeeeee;
    border-radius: 6px;
  }
}
</style>
