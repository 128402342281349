<!--
 * @Author: hzh
 * @Date: 2021-11-30 18:03:27
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-30 18:19:09
-->
<template>
  <div class="outer-layer-box">
    <div class="header-preview flex flex-y-center flex-x-between">
      <div>
        <el-page-header
          @back="goBack"
          title="关闭"
          :content="objectInfo.file_original_name || ''"
        >
        </el-page-header>
      </div>
      <div class="download-icon" v-if="objectInfo.downloadPer || false">
        <a
          :href="fileInfo.attachment_path"
          :download="fileInfo.file_original_name"
          ><i class="el-icon-download"></i
        ></a>
      </div>
    </div>
    <iframe
      class="main-body"
      v-if="objectInfo.url"
      :src="objectInfo.url || ''"
    ></iframe>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      attachmentId: -1,
      attachmentType: '',
      attachmentTypeId: -1,
      objectInfo: {},
      fileInfo: {}
    }
  },
  computed: {
    ...mapState(['screenWidth'])
  },
  mounted() {},
  methods: {
    // 返回上一级
    goBack() {
      if (this.screenWidth <= 767) {
        this.$router.go(-1)
        return
      }
      window.opener = null
      window.open('about:blank', '_top').close()
    },
    // 获取url
    async getOnlinePreviewUrl() {
      if (
        this.attachmentId === -1 &&
        this.attachmentType === '' &&
        this.attachmentTypeId
      ) {
        this.$message({
          showClose: true,
          message: '请跳转正确的链接格式',
          type: 'error'
        })
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getAttachmentPreview, {
          attachment_id: this.attachmentId,
          attachment_type: this.attachmentType,
          attachment_type_id: this.attachmentTypeId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res)
        if (res.info.url.indexOf('https') === -1) {
          res.info.url = `https${res.info.url.substr(4)}`
        }
        this.objectInfo = res.info
        this.fileInfo = res.knowl_file_url
      } else {
        this.goBack()
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, oldVal) {
        this.attachmentId = val.params.attachmentId
        const list = val.params.attachmentType.split('_')
        if (list.length !== 0) {
          this.attachmentType = list[0]
          this.attachmentTypeId = list[1]
        }
        this.getOnlinePreviewUrl()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-preview {
  height: 50px;
  background: #fff;
  padding: 0 25px;
  box-shadow: 5px 5px 5px #e0dfdf;
  &::v-deep {
    .el-icon-back {
      display: none;
    }
    .el-page-header__title {
      font-size: 16px !important;
      color: $main-color !important;
    }
    .el-page-header__content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 53vw;
    }
  }
  .download-icon {
    i {
      color: $main-color !important;
      font-size: 24px !important;
      font-weight: 600 !important;
      cursor: pointer;
    }
  }
}
.main-body {
  width: 100%;
  border: none;
  height: calc(100vh - 50px);
}
</style>
