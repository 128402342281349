<template>
  <div
    class="notic-edit"
    v-loading.fullscreen.lock="loadding"
    element-loading-background="rgba(242, 246, 252, 0.3)"
  >
    <div
      class="flex flex-x-between flex-y-center notic-edit_header line-bottom"
    >
      <div>
        <el-button size="small" @click="back()">返回</el-button>
      </div>
      <div>
        <el-button
          class="button-delete"
          size="small"
          v-if="noticeId != -1"
          @click="deleteNotice"
          >删除</el-button
        >
        <el-button class="button-draft" size="small" @click="confirm(1)"
          >存草稿</el-button
        >
        <el-button class="button-release" size="small" @click="confirm(2)"
          >发布</el-button
        >
      </div>
    </div>
    <div style="height: 100%; overflow: scroll">
      <div class="notic-container">
        <input
          class="notic-title"
          v-model="noticeName"
          placeholder="请编辑通知标题"
          type="text"
        />
        <div class="notic-time">
          <span>公告时间：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            v-model="timeList"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="notic-content">
          <NoticEditEditor
            ref="editor"
            :value="describe || ''"
          ></NoticEditEditor>
          <!-- <quill-editor
            ref="editor"
            :options="option"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
          >
          </quill-editor> -->
        </div>
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-a-Frame5"></span>
            <h4 class="add-meeting-title">附件信息</h4>
          </div>
          <!-- 刚才上传的附件预览 -->
          <div class="flex flex-wrap notice-file">
            <div v-for="(attachment, index) in appendixList" :key="index">
              <!-- 旧的附件 -->
              <attachment-item
                v-if="attachment.attachment_id && attachment.attachment_id > 0"
                :appendix="attachment"
                fileType="notice"
                :noticeId="noticeId"
                :isDeletable="true"
                @to-delete="removeOldAttachment(index, $event)"
                style="margin: 12px 0 0 0"
              >
              </attachment-item>
              <new-attachment-item
                v-else
                :appendix="attachment"
                :key="index"
                @remove-file="removeAttachment(index)"
                style="margin: 12px 0 0 0"
              ></new-attachment-item>
            </div>
          </div>
          <!-- 上传附件的输入框 -->
          <input
            type="file"
            class="upload-file"
            ref="uploadInputRef"
            @change="chooseFile"
          />
          <!-- 上传附件的按钮 -->
          <span class="no-choose" @click="beginUploadFile">上传附件</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { quillEditor } from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import ImageResize from 'quill-image-resize-module'
// import Quill from 'quill'
// Quill.register('modules/imageResize', ImageResize)
// 调用编辑器
import NoticEditEditor from '@/components/system/NoticEditEditor1.vue'
import NewAttachmentItem from '@/components/common/NewAttachmentItem.vue'
import AttachmentItem from '../common/AttachmentItem.vue'
export default {
  components: {
    // quillEditor
    NoticEditEditor,
    NewAttachmentItem,
    AttachmentItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    describe: {
      type: String,
      default: ''
    },
    noticeId: {
      type: Number,
      default: -1
    },
    noticeTime: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      noticeName: '',
      timeList: [],
      loadding: false,
      pickerOptions: {
        disabledDate(time) {
          let now = new Date()
          now = new Date(now.setHours(0))
          now = new Date(now.setMinutes(0))
          now = new Date(now.setSeconds(0))
          now = new Date(now.setMilliseconds(0))
          console.log(time.getTime(), now.getTime())
          return time.getTime() < now.getTime()
        }
      },
      // 附件列表
      appendixList: [],
      // 删除的附件id
      toDeleteAttachmentId: []
    }
  },
  mounted() {
    this.noticeName = this.title
    if (this.noticeTime) {
      this.timeList = this.noticeTime[0] ? this.noticeTime : []
    } else {
      this.timeList = []
    }
    console.log(this.noticeTime)
    if (this.noticeId !== -1) {
      this.getNoticeDetail()
    }
  },
  methods: {
    // 清除数据
    clearData() {
      this.appendixList = []
      this.toDeleteAttachmentId = []
    },
    async confirm(status) {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (this.noticeName === '') {
        errmsg('请输入通知标题')
        return
      }
      const obj = new FormData()
      obj.append('notice_name', this.noticeName)
      obj.append('notice_status', status)

      const content = this.$refs.editor.getContent()
      if (status === 2) {
        if (content.html === '') {
          errmsg('请输入通知内容')
          return
        }
        if (this.timeList == null || this.timeList.length === 0) {
          errmsg('请选择公告时间')
          return
        }
      }
      console.log(this.timeList)
      // 存入富文本内容
      if (content.html !== '') {
        obj.append('notice_content_html', content.html)
        obj.append('notice_content_text', content.text)
      }
      if (this.timeList !== null && this.timeList.length > 0) {
        obj.append('start_time', this.timeList[0])
        obj.append('end_time', this.timeList[1])
      }
      this.loadding = true
      // 附件
      if (this.appendixList.length > 0) {
        for (let i = 0; i < this.appendixList.length; i++) {
          obj.append(`file${i + 1}`, this.appendixList[i])
        }
      }
      if (this.noticeId !== -1) {
        obj.append('notice_id', this.noticeId)
        this.edit(obj)
      } else {
        this.add(obj)
      }
    },
    // 添加
    async add(obj) {
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.addSystemNotice, obj).result
      )
      this.loadding = false
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加成功！',
          type: 'success'
        })
        this.back(true)
      }
    },
    // 修改
    async edit(obj) {
      console.log(obj)
      if (this.toDeleteAttachmentId.length !== 0) {
        obj.append('delete_ids', this.toDeleteAttachmentId.join(','))
      }
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.editSystemNotice, obj).result
      )
      this.loadding = false
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success'
        })
        this.back(true)
      }
    },
    // 返回
    back(currentFlag = false) {
      this.$emit('back', currentFlag)
    },
    // 删除
    async deleteNotice() {
      if (this.noticeId === -1) {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemNotice, {
          notice_id: this.noticeId
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        })
        this.$emit('deleteBack')
      }
    },
    // 删除这个新文件
    removeAttachment(index) {
      this.appendixList.splice(index, 1)
    },
    // 选择要上传的文件
    chooseFile(e) {
      const file = e.target.files[0]
      this.appendixList.push(file)
      this.$refs.uploadInputRef.value = ''
    },
    // 开始上传文件
    beginUploadFile() {
      this.$refs.uploadInputRef.click()
    },
    // 获取通知详情
    async getNoticeDetail() {
      this.clearData()
      this.loadding = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemNoticeDetailL, {
          notice_id: this.noticeId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.appendixList = res.info.notice_attachment_list
        this.loadding = false
      }
    },
    // 记录要删除的附件的id
    removeOldAttachment(index, id) {
      this.$util.showConfirm('确定要删除这个文件吗？').then((result) => {
        if (result) {
          this.toDeleteAttachmentId.push(id)
          this.appendixList.splice(index, 1)
        }
      })
      console.log(this.toDeleteAttachmentId)
    }
  },
  watch: {
    noticeId(val) {
      if (val !== -1) {
        this.getNoticeDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notic-edit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  padding-top: 64px;
  box-sizing: border-box;

  .notic-edit_header {
    height: 64px;
    padding: 0 50px 0 24px;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    &::after {
      left: 0;
    }
    .el-button {
      width: 80px;

      &.button-delete {
        background-color: #f6f6f6;
        color: #999;
        border: none;
        &:hover {
          background-color: #ff5454;
          color: #fff;
        }
      }

      &.button-draft {
        border: none;
        color: #fff;
        background-color: #c0c0c0;
      }

      &.button-release {
        border: none;
        color: #fff;
        background-color: $main-color;
      }
    }
  }

  .notic-container {
    width: 863px;
    margin: 0 auto;
    padding: 28px 0;
    .notice-file {
      &::v-deep .appendix-item {
        width: 13.625vw !important;
      }
    }
    .add-meeting-header {
      padding: 0 0 16px;
      color: #999;
      .add-meeting-title {
        margin: 0;
        padding: 0;
        padding-left: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
      }
    }
    .no-choose {
      cursor: pointer;
      color: #999;
    }
    .notic-title {
      width: calc(100% - 24px);
      height: 56px;
      font-size: 18px;
      color: #333;
      border: none;
      padding: 0 16px;
      border-radius: 6px;
      border: 1px solid #fff;
      margin-bottom: 24px;
    }

    .notic-time {
      padding: 0 16px;
      margin-bottom: 30px;
      ::v-deep .el-input__inner {
        border: 1px solid #fff !important;
      }
    }

    .notic-content ::v-deep {
      .ql-toolbar {
        &.ql-snow {
          border: 1px solid #fff !important;
        }
      }
      .ql-container {
        &.ql-snow {
          border: 1px solid #fff !important;
        }
      }
    }
  }
}
</style>
