var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('message-dialog',{attrs:{"visible":_vm.visible},on:{"close":_vm.close}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"message-title"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.momentTitle),expression:"momentTitle"}],attrs:{"placeholder":"编辑标题（选填）","type":"text"},domProps:{"value":(_vm.momentTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.momentTitle=$event.target.value}}})]),_c('div',{staticClass:"message-content"},[_c('AMention',{ref:"contentRef",on:{"paste":_vm.handlerPaste},model:{value:(_vm.momentInfo.moment_content),callback:function ($$v) {_vm.$set(_vm.momentInfo, "moment_content", $$v)},expression:"momentInfo.moment_content"}})],1),(_vm.momentInfoData.content_type === 3)?_c('message-knowledge',{attrs:{"info":_vm.momentInfoData.knowl_info}}):_vm._e(),(_vm.momentInfo.content_type === 1)?_c('edit-message-image',{attrs:{"imageList":_vm.imageFileList},on:{"imageChange":function($event){return _vm.imageChange($event)},"onDelte":_vm.onDeleteImage,"editSort":_vm.editImageSort}}):_vm._e(),(_vm.momentInfo.content_type === 2)?_c('edit-message-video',{attrs:{"url":_vm.renderVideoUrl},on:{"onDelete":_vm.onDeleteVideo}}):_vm._e()],1),_c('div',{staticClass:"flex toolbar",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"flex"},[_c('expression-box',{staticClass:"expression-edit-index",attrs:{"isShow":_vm.isShowExpressBox,"expressList":_vm.$emoji.slice(0, 100),"left":'0'},on:{"add-express":function($event){return _vm.addContentExpress($event)}}},[_c('div',{staticClass:"flex toolbar-item",class:{ active: _vm.isShowExpressBox },on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpressBox.apply(null, arguments)}}},[_c('i',{staticClass:"icon-a-1",staticStyle:{"font-size":"26px"}})])]),_c('el-upload',{staticClass:"flex toolbar-item",class:{
          'toolbar-disabled':
            _vm.momentInfoData.content_type === 3 ||
            _vm.momentInfo.content_type === 2 ||
            _vm.videoFile != null
        },attrs:{"action":"#","show-file-list":false,"multiple":"","accept":".jpg,.jpeg,.bmp,.gif,.png,.heif,.heic","disabled":_vm.momentInfoData.content_type === 3 ||
          _vm.momentInfo.content_type === 2 ||
          _vm.videoFile != null,"on-change":_vm.momentImageChange,"auto-upload":false}},[_c('div',{staticClass:"flex flex-y-center"},[_c('i',{staticClass:"icon-a-2",staticStyle:{"font-size":"26px"},style:({
              color:
                _vm.momentInfoData.content_type === 3 ||
                _vm.momentInfo.content_type === 2 ||
                _vm.videoFile != null
                  ? '#dddddd'
                  : ''
            })})])]),_c('el-upload',{staticClass:"flex toolbar-item",class:{
          'toolbar-disabled':
            _vm.momentInfoData.content_type === 3 ||
            (_vm.momentInfo.content_type === 1 && _vm.imageFileList.length > 0)
        },attrs:{"action":"#","show-file-list":false,"accept":".mp4,.m4v,.ogm,.wmv,.mpg,.webm,.ogv,.mov,.asx,.mpeg,.avi,.mkv","on-change":_vm.momentVideoChange,"disabled":_vm.momentInfoData.content_type === 3 ||
          (_vm.momentInfo.content_type === 1 && _vm.imageFileList.length > 0),"auto-upload":false,"multiple":""}},[_c('div',{staticClass:"flex flex-y-center"},[_c('i',{staticClass:"icon-a-3",staticStyle:{"font-size":"26px"},style:({
              color:
                _vm.momentInfoData.content_type === 3 ||
                (_vm.momentInfo.content_type === 1 && _vm.imageFileList.length > 0)
                  ? '#dddddd'
                  : ''
            })})])])],1),_c('el-button',{attrs:{"type":"primary","size":"small","loading":_vm.adding},on:{"click":_vm.releaseDynamics}},[_vm._v(_vm._s(_vm.adding ? '发布中' : '发布'))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }