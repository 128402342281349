<!--
 * @Date: 2021-07-23 22:53:35
 * @LastEditTime: 2021-07-23 22:53:35
 * @Description: 圈子用户个人操作菜单
 * @Author: lyq
 * @LastEditors: lyq
-->
<template>
  <div class="operation-contaier">
    <div
      class="opertion-item flex"
      :class="{ 'is-active': current === index }"
      v-for="(item, index) in dataList"
      :key="index"
      @click="handlerChange(index)"
    >
      <!-- <i :class="item.icon"></i> -->
      <div class="flex left-content">
        <svg>
          <use
            :xlink:href="current === index ? item.activeIcon : item.icon"
          ></use>
        </svg>
        <span>{{ item.title }}</span>
      </div>
      <div v-if="item.badgeNum && item.badgeNum !== 0" class="opertion-badge">
        <span>{{ item.badgeNum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'current',
    event: 'change'
  },
  props: {
    dataList: {
      type: Array,
      default: function () {
        return []
      }
    },
    current: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handlerChange(e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-contaier {
  padding: 12px 0;

  .opertion-item {
    cursor: pointer;
    padding: 12px 22px;
    height: 24px;
    line-height: 24px;
    justify-content: space-between;

    .left-content {
      svg {
        height: 24px;
        width: 24px;
        color: $icon-color;
        margin-right: 16px;
      }

      span {
        color: #999999;
        font-size: 16px;
      }
    }

    &.is-active {
      i,
      span {
        color: $main-color;
      }
    }

    .opertion-badge {
      width: 40px;
      border-radius: 24px;
      font-size: 14px;
      text-align: center;
      background-color: #ff5454;

      span {
        color: #fff !important;
        padding-top: 10px;
      }
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}
</style>
