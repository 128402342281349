<!--
 * @Author: Betty
 * @Date: 2021-08-05 17:39:30
 * @LastEditors: hzh
 * @LastEditTime: 2022-04-13 16:32:17
 * @Description: 任务详情单页面
-->
<template>
  <div class="single-box">
    <!-- 白色的窗体 -->
    <div
      class="modal-right-box"
      :class="{ 'my-task': isAllowUpdate }"
      @click="closeChooseLeader"
    >
      <!-- 头部 -->
      <div class="modal-right-header flex flex-y-center flex-x-between">
        <!-- tab -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="任务详情" name="first">
            <!-- 父任务 -->
            <div class="flex parent-task-box" v-if="taskInfo.parents_list">
              <p class="label">父任务</p>
              <div class="flex1 parent-tasks">
                <div
                  v-for="task in taskInfo.parents_list"
                  :key="task.task_id"
                  class="flex flex-y-start"
                  @click="showParentInfo(task.task_id)"
                >
                  <p class="task-name e">
                    <!-- 标签 -->
                    <span
                      class="parent-task-label"
                      v-if="task.infoLabel.gth > 0"
                      :style="{
                        color: '#FF5454',
                        background: '#fff',
                        fontWeight: '600',
                        fontSize: '20px'
                      }"
                      >{{ task.infoLabel.gthStr }}</span
                    >
                    <span
                      class="parent-task-label"
                      v-for="(item, index) in task.infoLabel.list"
                      :key="index"
                      >{{ item.name }}</span
                    >{{ task.infoLabel.showName }}
                  </p>
                  <span class="iconfont icon-arrowaltright"></span>
                </div>
              </div>
            </div>
            <!-- 第一块，任务标题和基本信息 -->
            <div class="task-basic-info border-b">
              <div
                class="flex flex-y-start border-b"
                style="margin-bottom: 13px"
              >
                <div
                  class="check-box"
                  @click="checkTask()"
                  :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
                >
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="
                        taskInfo.task_status === 3 || taskInfo.task_status === 4
                          ? '#icon-TickSquare_1'
                          : '#icon-TickSquare'
                      "
                    ></use>
                  </svg>
                </div>

                <h4
                  class="task-title"
                  v-show="!isEditName"
                  @click="beginInputName"
                  :class="{
                    over:
                      taskInfo.task_status === 3 || taskInfo.task_status === 4
                  }"
                  :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
                  v-if="taskInfo.infoLabel"
                >
                  <!-- 标签 -->
                  <span
                    class="task-label"
                    v-if="taskInfo.infoLabel.gth > 0"
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '20px'
                    }"
                    >{{ taskInfo.infoLabel.gthStr }}</span
                  >
                  <span
                    class="task-label"
                    v-for="(item, index) in taskInfo.infoLabel.list"
                    :key="index"
                    >{{ item.name }}</span
                  >{{ taskInfo.infoLabel.showName }}
                </h4>
                <input
                  type="text"
                  class="edit-name-input"
                  @keydown="showCode"
                  ref="inputTaskName"
                  @compositionend.prevent
                  @blur="handleInputName"
                  @keyup.13="toBlur($event)"
                  @keyup.229="empty"
                  @keyup.esc="cancelInputName"
                  v-model="currentTaskName"
                  v-show="isEditName"
                />
              </div>
              <!-- 关联父目标 -->
              <div class="to-relative flex flex-y-center">
                <span
                  class="iconfont"
                  @click="toRelativeModal"
                  :class="{
                    'icon-relatived active': taskInfo.objective_info != null,
                    'icon-to_relative': taskInfo.objective_info == null
                  }"
                ></span>
                <p class="relative-parent e" @click="toObjectiveOrModal">
                  <!-- 标签 -->
                  <span
                    class="task-objective-label"
                    v-if="
                      taskInfo.objective_info &&
                      taskInfo.objective_info.infoLabel &&
                      taskInfo.objective_info.infoLabel.gth > 0
                    "
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '20px'
                    }"
                    >{{ taskInfo.objective_info.infoLabel.gthStr || '' }}</span
                  >
                  <template
                    v-if="
                      taskInfo.objective_info &&
                      taskInfo.objective_info.infoLabel
                    "
                  >
                    <span
                      class="task-objective-label"
                      v-for="(item, index) in taskInfo.objective_info.infoLabel
                        .list"
                      :key="index"
                      >{{ item.name }}</span
                    >
                  </template>
                  {{
                    taskInfo.objective_info && taskInfo.objective_info.infoLabel
                      ? taskInfo.objective_info.infoLabel.showName
                      : isAllowUpdate
                      ? '关联父级目标'
                      : '无父级目标'
                  }}
                </p>
              </div>
              <!-- 基本信息 -->
              <div class="flex detail-indent">
                <!-- 状态，点击选择状态 -->
                <div class="basic-info-item flex flex-y-center">
                  <div
                    class="flex flex-y-center"
                    @click.stop="toggleChooseState"
                    :style="{
                      cursor: isAllowUpdate ? 'pointer' : 'default'
                    }"
                  >
                    <span
                      class="iconfont basic-icon"
                      :class="[
                        stateList[currentState].icon,
                        stateList[currentState].color
                      ]"
                    ></span>
                    <div class="text-box">
                      <p class="state" :class="[stateList[currentState].color]">
                        {{ stateList[currentState].title }}
                      </p>
                      <p class="info">当前状态</p>
                    </div>
                  </div>

                  <!-- 选择状态的弹窗 -->
                  <div class="choose-state-box" v-show="isChooseState">
                    <p class="choose-title">选择状态</p>
                    <div
                      v-for="(item, index) in stateList"
                      :key="index"
                      class="flex flex-y-center state-item"
                      @click="chooseState(index)"
                    >
                      <span
                        class="iconfont small-icon"
                        :class="[item.smallIcon, item.color]"
                      ></span>
                      <p class="state-text">{{ item.title }}</p>
                    </div>
                  </div>
                </div>
                <!-- 负责人，点击选择负责人 -->
                <div class="basic-info-item flex flex-y-center">
                  <div
                    class="flex flex-y-center"
                    @click.stop="toggleChooseLeader"
                    :style="{
                      cursor: isAllowUpdate ? 'pointer' : 'default'
                    }"
                  >
                    <!-- 如果有负责人，那么显示负责人的头像 -->
                    <img
                      :src="leader.clerk_avatar"
                      alt=""
                      class="basic-icon"
                      v-if="leader && leader.clerk_avatar"
                    />
                    <!-- 否则显示添加负责人的图标 -->
                    <span
                      class="iconfont icon-AddUser leader-icon basic-icon"
                      v-else
                    ></span>
                    <div class="text-box">
                      <p
                        class="state"
                        :class="{ active: leader && leader.clerk_avatar }"
                      >
                        {{
                          leader && (leader.clerk_alias || leader.clerk_name)
                            ? leader.clerk_alias || leader.clerk_name
                            : '负责人'
                        }}
                      </p>
                      <p class="info" v-if="leader && leader.clerk_avatar">
                        负责人
                      </p>
                    </div>
                  </div>
                  <!-- 选择负责人弹窗 -->
                  <choose-leader
                    :isShow="isChooseLeader"
                    :clerkTree="clerkTree"
                    :taskId="taskInfo.task_id"
                    @to-close="closeCurrentLeader($event)"
                  ></choose-leader>
                </div>
                <!-- 开始时间 -->
                <div
                  class="basic-info-item flex flex-y-center"
                  :class="{ cursor: isAllowUpdate }"
                >
                  <span
                    class="iconfont icon-TimeCircle1 basic-icon"
                    :class="{ active: startTime }"
                  ></span>
                  <div class="text-box">
                    <p class="state" :class="{ active: startTime }">
                      {{ startTime ? startTime : '开始时间' }}
                    </p>
                    <p class="info" v-if="startTime">开始时间</p>
                  </div>

                  <!-- 选择开始时间 -->
                  <div class="dashboard-editor-container">
                    <div class="block">
                      <el-date-picker
                        v-model="startTime"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        popper-class="date-type"
                        :editable="false"
                        @blur="handleStartTime"
                        v-if="isAllowUpdate"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
                <!-- 结束时间 -->
                <div
                  class="basic-info-item flex flex-y-center"
                  :class="{ cursor: isAllowUpdate }"
                >
                  <span
                    class="iconfont icon-TimeCircle2 basic-icon"
                    :class="{ active: endTime }"
                  ></span>
                  <div class="text-box">
                    <p class="state" :class="{ active: endTime }">
                      {{ endTime ? endTime : '结束时间' }}
                    </p>
                    <p class="info" v-if="endTime">结束时间</p>
                  </div>
                  <!-- 选择开始时间 -->
                  <div class="dashboard-editor-container">
                    <div class="block">
                      <el-date-picker
                        v-model="endTime"
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        popper-class="date-type"
                        :editable="false"
                        @blur="handleEndTime"
                        v-if="isAllowUpdate"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 第二块，任务信息和任务描述 -->
            <div class="task-info-box border-b">
              <!-- 任务信息头部 -->
              <div class="info-header flex flex-y-center">
                <span class="iconfont icon-Document icon"></span>
                <h6 class="info-title">任务信息</h6>
              </div>
              <div class="flex detail-indent">
                <!-- 优先级和工作量 -->
                <div class="info-content-item">
                  <p class="info-text">优先级</p>
                  <div class="choose-parent" @click.stop="toggleChoosePriority">
                    <div
                      class="choose-box flex flex-y-center"
                      :style="{
                        border: !isAllowUpdate ? '1px solid transparent' : ''
                      }"
                    >
                      <div
                        :class="[
                          priorityList[currentPriority]
                            ? priorityList[currentPriority].color
                            : '',
                          {
                            'priority-tag':
                              currentPriority < priorityList.length
                          }
                        ]"
                        :style="{
                          cursor: isAllowUpdate ? 'pointer' : 'default'
                        }"
                      >
                        {{ priorityText }}
                      </div>
                    </div>
                    <div class="priority-tags-box" v-show="isChoosePriority">
                      <div
                        class="priority-item"
                        v-for="(item, index) in priorityList"
                        :key="index"
                        @click.stop="chooseTaskPriority(index)"
                      >
                        <div
                          class="priority-tag"
                          :class="[priorityList[index].color]"
                        >
                          {{ priorityList[index].text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-content-item">
                  <p class="info-text">工作量</p>
                  <input
                    type="text"
                    class="choose-box"
                    v-model="workload"
                    @keydown="showCode"
                    @compositionend.prevent
                    placeholder="无"
                    @blur="readySumbitWorkload"
                    @keyup.13="toBlur"
                    @keyup.229="empty"
                    @keyup.esc="cancelInputWorkload"
                    :readonly="!isAllowUpdate"
                    :style="{
                      border: !isAllowUpdate ? '1px solid transparent' : ''
                    }"
                  />
                </div>
                <!-- 完成进度 -->
                <div class="info-content-item">
                  <p class="info-text">完成进度</p>
                  <div class="progress-box">
                    <div
                      class="flex flex-y-center"
                      @click.stop="toggleSetProgress"
                    >
                      <el-progress
                        :color="
                          taskInfo.progress_status === 1 ? '#0080FF' : '#FF5454'
                        "
                        type="circle"
                        :percentage="taskInfo.progress_number || 0"
                        :width="20"
                        :show-text="false"
                        :stroke-width="2"
                      ></el-progress>
                      <span class="progress">{{
                        (taskInfo.progress_number || 0) + '%'
                      }}</span>
                    </div>
                    <!-- 设置完成进度 -->
                    <!-- 设置进度 -->
                    <div
                      class="task-process"
                      v-show="isSetProgress"
                      @click.stop
                    >
                      <h6 class="process-title">当前进度</h6>
                      <div class="flex flex flex-y-center">
                        <input
                          type="number"
                          class="input"
                          min="0"
                          max="100"
                          v-model="currentProgress"
                          :ref="`${taskInfo.task_id}progressRef`"
                          @blur="
                            editProgress({ id: taskInfo.task_id, index: -1 })
                          "
                          @keyup.enter="
                            toBlur($event, { id: taskInfo.task_id, index: -1 })
                          "
                        />
                        <span class="percent">%</span>
                      </div>
                      <h6 class="process-title">状态</h6>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label normal flex flex-y-center"
                          @click="
                            setTaskProgressStatus(1, taskInfo.task_id, -1)
                          "
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                taskInfo.progress_status === 1
                                  ? '#icon-Group706'
                                  : '#icon-Group592'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="taskInfo.progress_status === 1"
                            id=""
                            class="radio"
                          />
                          <span>正常</span>
                        </label>
                      </div>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label late flex flex-y-center"
                          @click="
                            setTaskProgressStatus(2, taskInfo.task_id, -1)
                          "
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                taskInfo.progress_status === 2
                                  ? '#icon-Group705'
                                  : '#icon-Group593'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="taskInfo.progress_status === 2"
                            id=""
                            class="radio"
                          />
                          <span>已延期</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 任务描述 -->
              <div class="task-desc-box" style="padding-bottom: 16px">
                <div
                  class="info-header flex flex-y-center"
                  style="padding-bottom: 5px"
                >
                  <span class="iconfont icon-Bookmark icon"></span>
                  <h6
                    class="info-title"
                    @click="toAddTaskDes()"
                    :style="{
                      cursor:
                        isAllowUpdate &&
                        taskInfo &&
                        !taskInfo.task_describe_html
                          ? 'pointer'
                          : 'default'
                    }"
                  >
                    {{
                      (taskInfo && taskInfo.task_describe_html) ||
                      !isAllowUpdate
                        ? '任务描述'
                        : '添加任务描述'
                    }}
                  </h6>
                  <span
                    class="info-title title-edit-btn"
                    @click="showEditBox"
                    v-if="
                      isAllowUpdate && taskInfo && taskInfo.task_describe_html
                    "
                    >编辑</span
                  >
                </div>
                <p
                  class="no-des"
                  v-if="
                    !isAllowUpdate && taskInfo && !taskInfo.task_describe_html
                  "
                >
                  暂无任务描述
                </p>
                <!-- 显示结果 -->
                <!-- <div
                  class="info-content"
                  v-if="taskInfo && taskInfo.task_describe_html"
                >
                  <p class="pre">{{ taskInfo.task_describe_text }}</p>
                </div> -->
                <div v-if="!isShowEdit" class="htmledit_views detail-indent">
                  <div v-html="taskInfo.task_describe_html"></div>
                </div>
                <!-- 显示编辑框 -->
                <div class="info-edit-box">
                  <!-- 富文本框 -->
                  <!-- <TinymceEditor
                    v-if="false"
                    :value="taskInfo.task_describe_html"
                    ref="editor"
                    :plugins="plugins"
                    :toolbar="toolbar"
                  ></TinymceEditor> -->
                  <task-describe-editor
                    v-if="isShowEdit"
                    ref="editor"
                    :value="taskInfo.task_describe_html"
                  ></task-describe-editor>
                  <!-- <p class="pre">{{ taskInfo.task_describe_text }}</p> -->
                </div>
                <!-- 显示编辑框 -->
                <div class="info-edit-box" v-show="isShowEdit">
                  <!-- 富文本框 -->

                  <!-- 文字输入 -->
                  <textarea
                    class="des"
                    v-model="taskDescVal"
                    v-if="false"
                  ></textarea>
                  <!-- 保存和取消按钮 -->
                  <div class="flex flex-y-center btns">
                    <el-button type="primary" @click="handleEditDes"
                      >保存</el-button
                    >
                    <el-button @click="cancelEditDes">取消</el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 关联目标 -->
            <div class="task-info-box border-b">
              <!-- 头部 -->
              <div class="info-header flex flex-y-center">
                <span class="iconfont icon-Frame11 icon"></span>
                <h6
                  class="info-title"
                  @click="toAddChildTask()"
                  :style="{
                    cursor:
                      childrenList.length > 0 ||
                      (!isInParticipant && !isAllowUpdate)
                        ? 'default '
                        : 'pointer'
                  }"
                >
                  {{
                    childrenList.length > 0 ||
                    (!isInParticipant && !isAllowUpdate)
                      ? '子任务'
                      : '添加子任务'
                  }}
                </h6>
              </div>
              <!-- 每一个子任务 -->
              <div
                v-for="(item, index) in childrenList"
                :key="index"
                class="children-task-item flex flex-y-start flex-x-between"
              >
                <!-- 左边 -->
                <div class="flex flex-y-start">
                  <!-- 复选框的标记 -->
                  <div
                    style="height: 34px"
                    class="flex flex-y-center"
                    @click="checkChildTask(item)"
                  >
                    <svg
                      class="icon task-checkbox"
                      aria-hidden="true"
                      :style="{
                        cursor: isAllowUpdateChildTask(item)
                          ? 'pointer'
                          : 'default'
                      }"
                    >
                      <use
                        :xlink:href="
                          item.task_status === 3 || item.task_status === 4
                            ? '#icon-TickSquare_1'
                            : '#icon-TickSquare'
                        "
                      ></use>
                    </svg>
                  </div>
                  <!-- 子任务名字 -->
                  <span
                    class="child-task-label"
                    v-if="
                      currentEditTask !== item.task_id && item.infoLabel.gth > 0
                    "
                    :style="{
                      color: '#FF5454',
                      background: '#fff',
                      fontWeight: '600',
                      fontSize: '15px'
                    }"
                    >{{ item.infoLabel.gthStr }}</span
                  >
                  <template v-if="currentEditTask !== item.task_id">
                    <span
                      class="child-task-label"
                      v-for="(litem, lindex) in item.infoLabel.list"
                      :key="lindex"
                      >{{ litem.name }}</span
                    >
                  </template>
                  <p
                    class="child-name"
                    :class="{
                      over: item.task_status === 3 || item.task_status === 4
                    }"
                    v-if="currentEditTask !== item.task_id"
                    @click.stop="showChildDetail(item)"
                  >
                    {{ item.infoLabel.showName }}
                  </p>
                  <input
                    type="text"
                    placeholder="填写子任务名称"
                    class="child-name"
                    v-model="childrenTemp"
                    @keydown="showCode"
                    v-else
                    @blur="handleEditChild(item)"
                    @keyup.229="empty"
                    @keyup.13="toBlur($event, item)"
                    @keyup.esc="cancelEditChild()"
                    :ref="`child${item.task_id}`"
                  />
                  <!-- <button
                    class="edit-btn"
                    @click="toggleChildEdit(item)"
                    v-if="isAllowUpdate"
                  >
                    <span class="iconfont icon-Edit"></span>
                  </button> -->
                </div>
                <!-- 右边，只显示一个头像，负责人 -->
                <div class="flex flex-y-center">
                  <!-- 进度 -->
                  <div class="flex flex-y-center child-progress hidden-xs-only">
                    <div
                      class="flex flex-y-center child-progress-box"
                      @click.stop="beginEditProgress(index)"
                    >
                      <el-progress
                        :color="
                          item.progress_status === 1 ? '#0080FF' : '#FF5454'
                        "
                        type="circle"
                        :percentage="item.progress_number || 0"
                        :width="20"
                        :show-text="false"
                        :stroke-width="2"
                      ></el-progress>
                      <span class="progress">{{
                        (item.progress_number || 0) + '%'
                      }}</span>
                    </div>
                    <!-- 设置进度 -->
                    <div
                      class="task-process"
                      v-if="item.isChooseProgress"
                      @click.stop
                    >
                      <h6 class="process-title">当前进度</h6>
                      <div class="flex flex flex-y-center">
                        <input
                          type="number"
                          class="input"
                          min="0"
                          max="100"
                          v-model="currentProgress"
                          :ref="`${item.task_id}progressRef`"
                          @blur="editProgress({ id: item.task_id, index })"
                          @keyup.enter="
                            toBlur($event, { id: item.task_id, index })
                          "
                        />
                        <span class="percent">%</span>
                      </div>
                      <h6 class="process-title">状态</h6>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label normal flex flex-y-center"
                          @click="setTaskProgressStatus(1, item.task_id, index)"
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                item.progress_status === 1
                                  ? '#icon-Group706'
                                  : '#icon-Group592'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="item.progress_status === 1"
                            id=""
                            class="radio"
                          />
                          <span>正常</span>
                        </label>
                      </div>
                      <div class="radio-item">
                        <label
                          for=""
                          class="label late flex flex-y-center"
                          @click="setTaskProgressStatus(2, item.task_id, index)"
                        >
                          <svg class="icon radio-icon" aria-hidden="true">
                            <use
                              :xlink:href="
                                item.progress_status === 2
                                  ? '#icon-Group705'
                                  : '#icon-Group593'
                              "
                            ></use>
                          </svg>
                          <input
                            type="radio"
                            name="task"
                            :checked="item.progress_status === 2"
                            id=""
                            class="radio"
                          />
                          <span>已延期</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="child-member-box">
                    <!-- 使用头像组件 -->
                    <avatar
                      :isShowMe="true"
                      :isShowTooltip="true"
                      :userInfo="item.leader"
                      @click.native.stop="beginChooseChildLeader(index)"
                    ></avatar>
                    <!-- 选择负责人组件 -->
                    <choose-leader
                      :isShow="item.isChooseLeader"
                      :taskId="item.task_id"
                      :clerkTree="clerkTree"
                      @to-close="chooseChildLeader($event, index)"
                    ></choose-leader>
                  </div>
                  <!-- 添加子任务成员 -->
                  <!-- <div
                      class="img-box"
                      @click="
                        openAddMember(
                          item.task_id,
                          item.task_participant_list,
                          item.create_clerk_id
                        )
                      "
                      v-if="isAllowUpdate"
                    >
                      <svg class="icon avatar" aria-hidden="true">
                        <use xlink:href="#icon-Group1251"></use>
                      </svg>
                    </div> -->
                  <!-- 更多的按钮 -->
                  <button
                    type="button"
                    class="more-btn hidden-xs-only"
                    @click.stop="showChildMore(item, index)"
                  >
                    <span
                      class="iconfont icon-gengduo3"
                      v-if="isAllowUpdateChildTask(item)"
                    ></span>
                  </button>
                </div>
                <!-- 子任务的操作弹窗 -->
                <div
                  class="child-handle-box"
                  v-if="
                    currentHandleChild === item.task_id &&
                    isAllowUpdateChildTask(item)
                  "
                >
                  <!-- 编辑 -->
                  <!-- <div class="child-handle-item" @click="showChildDetail(item)">
                    编辑
                  </div> -->
                  <!-- 删除 -->
                  <div
                    class="child-handle-item red"
                    v-if="item.create_clerk_id === myClerkId"
                    @click="deleteChildTask(item, index)"
                  >
                    删除
                  </div>
                  <div class="child-handle-item" @click="toggleChildEdit(item)">
                    编辑
                  </div>
                </div>
              </div>
              <!-- 如果没有子任务 -->
              <p
                v-if="
                  childrenList.length === 0 &&
                  !isInParticipant &&
                  !isAllowUpdate
                "
                class="no-children"
              >
                无
              </p>
              <!-- 添加子任务 -->
              <button
                type="button"
                v-if="
                  (isAllowUpdate || isInParticipant) &&
                  !isAddChildTask &&
                  childrenList.length > 0
                "
                class="add-children-btn detail-indent"
                @click="beginAddChildTask"
              >
                添加子任务
              </button>
              <!-- 添加子任务的输入框 -->
              <input
                type="text"
                placeholder="填写子任务名称"
                class="child-name"
                @compositionend.prevent
                @keydown="showCode"
                v-model="childrenTemp"
                v-show="isAddChildTask"
                ref="addChildTaskInput"
                @blur="handleNewChild()"
                @keyup.229="empty"
                @keyup.13="toBlur($event)"
                @keyup.esc="cancelNewChild()"
                style="width: calc(100% - 60px); margin-top: 10px"
              />
            </div>
            <!-- 任务成员 -->
            <div class="task-info-box">
              <!-- 头部 -->
              <div class="info-header flex flex-y-center">
                <span class="iconfont icon-2User icon"></span>
                <h6 class="info-title">任务成员</h6>
              </div>
              <!-- 任务成员 -->
              <div class="flex flex-y-center avatars flex-wrap detail-indent">
                <!-- 遍历任务成员列表，显示出头像 -->
                <div v-for="item in memberList" :key="item.clerk_id">
                  <avatar
                    :userInfo="item"
                    :isShowTooltip="true"
                    :isShowMe="true"
                  ></avatar>
                </div>
                <!-- 添加成员 -->
                <div
                  class="member-box"
                  @click="
                    openAddMember(
                      taskInfo.task_id,
                      taskInfo.participant_list,
                      taskInfo.create_clerk_id
                    )
                  "
                  v-if="isAllowUpdate || isInParticipant"
                >
                  <svg class="icon avatar" aria-hidden="true">
                    <use xlink:href="#icon-Group1251"></use>
                  </svg>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="`附件${
              appendixList.length > 0 ? `(${appendixList.length})` : ''
            }`"
            name="second"
          >
            <!-- 附件 -->
            <div class="appendix-box">
              <div class="flex flex-wrap">
                <attachment-item
                  v-for="(attachment, index) in appendixList"
                  :taskId="taskInfo.task_id"
                  :appendix="attachment"
                  :key="index"
                  :isDirectDownload="true"
                  :isDeletable="renderHasPer"
                  @download="downloadAttachment"
                  @fresh-attachment="refreshAttachmentList"
                ></attachment-item>
              </div>
              <!-- 上传和下载 -->
              <div class="flex flex-y-center handle-line">
                <!-- 上传文件的控件 -->
                <input
                  type="file"
                  name="newAttachment"
                  ref="uploadAttachmentRef"
                  class="upload-input"
                  @change="readyUpload"
                />
                <button
                  type="button"
                  class="handle"
                  v-show="renderHasPer"
                  @click="toUploadAttachment()"
                >
                  上传新附件
                </button>
                <!-- <button type="button" class="handle">下载所有附件</button> -->
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="`评论${
              (taskInfo.comment_number || 0) > 0
                ? `(${taskInfo.comment_number})`
                : ''
            }`"
            name="third"
          >
            <div class="comments">
              <scroll-fresh
                className="task-comment-list"
                dataName="taskComments"
                :page="commentListPageInfo.pageNumber"
                :lastPage="commentListPageInfo.totalPage"
                @get-next-page="getCommentNextPage"
              >
                <!-- 任务评论数据 -->
                <!-- 每一条评论数据 -->
                <comment-item
                  v-for="item in commentList"
                  :key="item.comment_id"
                  :comment="item"
                  :taskId="taskInfo.task_id"
                  @to-reply="setReplyObject($event)"
                  :isTask="true"
                ></comment-item>
              </scroll-fresh>
              <!-- 添加评论的区域 -->
              <div class="add-comment-box">
                <div
                  class="add-comment-innder"
                  :class="{ 'is-edit': isEditing }"
                >
                  <!-- <textarea
                    v-model="newComment"
                    class="textarea"
                    @focus="beginEdit"
                    ref="commentRef"
                    :placeholder="commentPlaceholder"
                    @blur="cancelFocus"
                    @keyup.enter="addNewComment"
                  ></textarea> -->
                  <CommentEditEditor
                    class="textarea"
                    @input="handellInput"
                    @focus="beginEdit"
                    @blur="cancelFocus"
                    :title="commentPlaceholder"
                    v-model="newComment"
                    ref="commentRef"
                  ></CommentEditEditor>
                  <div class="flex flex-y-center flex-x-end btn-box">
                    <expression-box
                      :isShow="isShowCommentExpression"
                      class="expresion-weizhi"
                      :expressList="$emoji.slice(0, 100)"
                      @add-express="addExpress($event)"
                      @toggle-express-box="toggleCommentExpression($event)"
                    ></expression-box>
                    <button
                      type="button"
                      class="send-btn"
                      @click="addNewComment"
                    >
                      发送
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="记录" name="fourth">
            <div class="record-box">
              <scroll-fresh
                className="record-items"
                dataName="record-data"
                :page="taskLogPageInfo.pageNumber"
                :lastPage="taskLogPageInfo.totalPage"
                @get-next-page="getLogNextPage"
              >
                <!-- 显示出任务记录列表数据 -->
                <task-log-item
                  v-for="item in taskLogList"
                  :key="item.change_log_id"
                  :logData="item"
                ></task-log-item>
              </scroll-fresh>
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- 关闭按钮 -->
        <!-- <button
          type="button"
          class="close-btn"
          @click="closeDetailModal"
          title="关闭"
        >
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-guanbi"></use>
          </svg>
        </button> -->
        <!-- 右上角区域，显示id/链接已复制和删除按钮 -->
        <div class="right-top-box flex flex-y-center">
          <el-tooltip
            class="item hidden-xs-only"
            effect="dark"
            content="复制链接"
            placement="bottom"
          >
            <p class="id-text hidden-xs-only" @click="copyLink">
              #{{ taskId }}
            </p>
          </el-tooltip>
          <p
            class="tip-text hidden-xs-only"
            v-if="isShowTip"
            :style="{ right: isAllowUpdate ? '30px' : '0' }"
          >
            链接已复制
          </p>
          <!-- 删除按钮，只有任务的创建人和负责人才能看到 -->
          <div
            @click.stop="handerShowMenu"
            v-if="
              (screenWidth > 767 &&
                (taskInfo.create_clerk_id === myClerkId ||
                  taskInfo.director_clerk_id === myClerkId)) ||
              screenWidth <= 767
            "
          >
            <i class="iconfont icon-gengduo3"></i>
          </div>
          <transition name="el-fade-in-linear" v-if="showMenu">
            <div class="message-menu">
              <div
                class="menu-item"
                v-if="screenWidth <= 767"
                @click="copyLink"
              >
                复制链接
              </div>
              <div
                class="menu-item delete-btn"
                v-if="
                  taskInfo.create_clerk_id === myClerkId ||
                  taskInfo.director_clerk_id === myClerkId
                "
                @click="toDeleteTask"
              >
                删除
              </div>
            </div>
          </transition>
          <!-- <button
            class="del-btn"
            v-if="
              taskInfo.create_clerk_id === myClerkId ||
              taskInfo.director_clerk_id === myClerkId
            "
          >
            <span
              class="iconfont icon-gengduo3"
              @click.stop="popupShow1"
            ></span>
            <div class="popup" v-if="popupShow">
              <div class="popup1" @click="toDeleteTask">
                <div class="popup-delete">删除</div>
              </div>
            </div>
          </button> -->
        </div>
      </div>
    </div>
    <!-- 添加成员的弹窗 -->
    <add-member-by-dept
      :isShowAddMember="isShowAddMember"
      :dataList="clerkTree"
      :memberList="memberListTemp"
      @to-add-member="readyAddMember($event)"
      @close-add-member="closeAddMember"
      @confirm-add-member="addMember($event)"
      @ready-remove-member="removeTempId($event)"
    ></add-member-by-dept>
    <!-- 选择关联目标 -->
    <task-relative-modal
      :isShowRelative="isChooseRelative"
      :task="taskInfo"
      @close-modal="isChooseRelative = false"
      @to-fresh-target="
        getTaskDetail()
        isUpdateData = true
      "
    ></task-relative-modal>
    <!-- 再放一个子任务的编辑弹窗，跟自己一样 -->
    <!-- 子任务的编辑弹窗如果要显示，那么不仅是“要显示子任务弹窗”，而且是“确定要显示id为几的子任务弹窗” -->
    <!-- <task-detail
      :isShow="isShowChild && currentChildId !== -1"
      :taskId="currentChildId"
      :nowTime="nowTime"
      @close-detail="isShowChild = false"
    ></task-detail> -->
    <!-- 上传文件的进度条 -->
    <div
      class="progress-area flex flex-x-end flex-y-reverse"
      v-if="uploadProgressList.length > 0"
    >
      <!-- 这里摆放进度条组件 -->
      <upload-progress
        v-for="(item, index) in uploadProgressList"
        :key="item.id"
        :uploadProgress="item.uploadProgress"
        :uploadFileName="item.uploadFileName"
        :index="index"
        :cancel="item.cancel"
        @close-upload-progress="closeUploadProgress(index)"
      ></upload-progress>
    </div>
  </div>
</template>

<script>
// 导入添加成员的弹窗
import AddMemberByDept from '../components/common/AddMemberByDept.vue'
// 导入富文本的组件
// import TinymceEditor from './TinymceEditer.vue'
import TaskDescribeEditor from '../components/task/TaskDescribeEditor1.vue'
// 导入头像组件
import Avatar from '../components/common/Avatar.vue'
import { mapState, mapActions } from 'vuex'
// 上传进度条
import UploadProgress from '../components/common/UploadProgress.vue'
// 导入滚动刷新组件
import ScrollFresh from '../components/common/ScrollFresh.vue'
// 导入评论组件
import CommentItem from '../components/task/CommentItem.vue'
// 导入任务记录组件
import TaskLogItem from '../components/task/TaskLogItem.vue'
// 导入任务记录组件
import TaskRelativeModal from '../components/task/TaskRelativeModal.vue'
// 附件组件
import AttachmentItem from '../components/common/AttachmentItem.vue'
// 选择负责人组件
import ChooseLeader from '../components/common/ChooseLeader.vue'
// 导入表情组件
import ExpressionBox from '../components/common/ExpressionBox.vue'
// 导入编辑器
import CommentEditEditor from '@/components/common/CommentEditEditor1.vue'
export default {
  name: 'task-detail-page',
  props: {
    // // 是否显示弹窗
    // isShow: {
    //   type: Boolean,
    //   default: false
    // },
    // 当前是哪个任务的详情
    // taskId: {
    //   type: Number,
    //   require: true
    // },
    // 现在的时间
    // nowTime: {
    //   type: Date,
    //   require: true
    // },
    // 只读
    readonly: {
      type: Boolean,
      default: false
    },
    // 会议id
    meetingId: {
      type: Number,
      default: -1
    },
    // 当前是否是我
    // isMe: {
    //   type: Boolean,
    //   default: false
    // },
    // 当前查看的tab
    currentTab: {
      type: String,
      default: 'first'
    }
  },
  components: {
    // TinymceEditor,
    AddMemberByDept,
    Avatar,
    UploadProgress,
    ScrollFresh,
    CommentItem,
    TaskDescribeEditor,
    TaskLogItem,
    AttachmentItem,
    ChooseLeader,
    ExpressionBox,
    TaskRelativeModal,
    CommentEditEditor
  },
  provide() {
    return {
      Parent: this
    }
  },
  data() {
    return {
      popupShow: false,
      // 当前任务的id
      taskId: -1,
      // 当前上传了几个文件
      uploadCount: 0,
      // 是否显示“链接已复制”的提示语
      isShowTip: false,
      // // 上传文件的进度
      // uploadProgress: 0,
      // // 正在上传的文件名字
      // uploadFileName: '',
      // 正在上传的进度集合
      uploadProgressList: [],
      // 临时记录开始时间和结束时间
      tempStartTimeStr: '',
      tempEndTimeStr: '',
      // 选择时间的相关数据
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < 0
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '清空',
            onClick(picker) {
              picker.$emit('pick', '')
            }
          }
        ]
      },
      // 输入的任务名称
      currentTaskName: 0,
      // 是否要输入任务的名字
      isEditName: false,
      // 开始时间
      startTime: '',
      // 结束时间
      endTime: '',
      // 负责人
      leader: null,
      // 当前任务的状态
      currentState: 0,
      // 工作量
      workload: '',
      // 任务的状态
      stateList: [
        {
          title: '待启动',
          color: 'grey',
          icon: 'icon-InfoCircle',
          smallIcon: 'icon-InfoCircle_1'
        },
        {
          title: '进行中',
          color: 'orange',
          icon: 'icon-TimeCircle_4',
          smallIcon: 'icon-TimeCircle_2'
        },
        {
          title: '已完成',
          color: 'green',
          icon: 'icon-confirmfill1',
          smallIcon: 'icon-confirmfill2'
        },
        {
          title: '已终止',
          color: 'red',
          icon: 'icon-InfoCircle',
          smallIcon: 'icon-InfoCircle_1'
        }
      ],
      // 是否要选择任务状态
      isChooseState: false,
      // 是否要选择负责人
      isChooseLeader: false,
      // 是否显示选择成员的弹窗
      isShowAddMember: false,
      //  树形数据
      clerkTree: [],
      // 团队成员
      memberList: [],
      // 附件数据
      appendixList: [],
      // 子任务数据
      childrenList: [],
      // 富文本
      title: '',
      p: 1,
      n: 10,
      count: 0,
      disabled: false,
      notice_data: [],
      handleSelectionChange: [],
      dialogFormVisible: false,
      dialogTitle: '',
      notice_info: {
        title: '',
        desc: ''
      },
      // 是否显示富文本输入框
      isShowEdit: false,
      // 当前选中的tab
      activeName: 'first',
      // 是否展示优先级选择弹窗
      isChoosePriority: false,
      // 正在编辑的子任务名字
      childrenTemp: '',
      // 当前任务信息
      taskInfo: {},
      // 当前选择的优先级下标
      currentPriority: 0,
      // 是否展示子任务编辑弹窗
      isShowChild: false,
      // 当前显示的子任务弹窗是哪个子任务的
      currentChildId: -1,
      // 输入任务描述的textarea的值
      taskDescVal: {
        html: '',
        text: ''
      },
      // 当前要操作的子任务的id
      currentHandleChild: -1,
      // 当前要编辑的子任务的id
      currentEditTask: -1,
      // 是否要新增子任务
      isAddChildTask: false,
      // 要添加成员的任务id
      currentId: -1,
      // 要添加到成员的id数组
      memberIdList: [],
      // 临时选择要添加的成员列表
      memberListTemp: [],
      // 当前任务的创建人id
      currentCreateClerkId: -1,
      // 要搜索的员工名字
      searchName: '',
      // 搜索结果列表
      searchResultList: [],
      // 是否修改数据
      isUpdateData: false,
      // 评论列表
      commentList: [],
      // 评论列表的分页情况
      commentListPageInfo: {
        totalPage: 0,
        totalRow: 0,
        pageSize: 20,
        firstPage: true,
        lastPage: false,
        pageNumber: 1
      },
      // 我要回复谁的评论
      replyTo: null,
      // 添加新评论
      newComment: '',
      // 任务记录列表
      taskLogList: [],
      // 任务记录的分页情况
      taskLogPageInfo: {
        pageNumber: 1,
        pageSize: 20,
        totalRow: 10,
        totalPage: 1
      },
      isFirstInit: true,
      // 是否正在输入评论
      isEditing: false,
      // 是否正在加载评论和记录列表
      isLoading: false,
      isLoadingLog: false,
      // 当前输入的进度
      currentProgress: 0,
      // 临时输入的进度
      temp: 0,
      // 是否正在设置进度
      isSetProgress: false,
      // 今天的日期
      todayDate: new Date(),
      // 临时记录keyCode
      keyCode: 0,
      // 是否显示评论的表情
      isShowCommentExpression: false,
      // 是否选择关联目标
      isChooseRelative: false,
      showMenu: false // 是否现实菜单
    }
  },
  computed: {
    ...mapState(['myClerkId', 'priorityList', 'clickBodyIndex', 'screenWidth']),
    realTaskId() {
      if (this.taskId !== -1) {
        return this.taskId
      }
      return this.taskInfo.task_id
    },
    // 是否可更新
    isAllowUpdate() {
      // 如果我是任务创建人、负责人、参与人之一，我可以修改任务
      // const index = this.memberList.findIndex(
      //   (item) => item.clerk_id === this.myClerkId
      // )
      // return index >= 0
      return (
        this.taskInfo.create_clerk_id === this.myClerkId ||
        this.taskInfo.director_clerk_id === this.myClerkId
      )
    },
    // 是否是在协同成员
    isInParticipant() {
      const index = this.memberList.findIndex((e) => {
        return this.myClerkId === e.clerk_id
      })
      return index !== -1
    },
    // 设置优先级的文字
    priorityText() {
      if (this.taskInfo.task_priority == null) {
        return ''
      }
      if (this.taskInfo.task_priority === 1) {
        return '无'
      }
      return this.priorityList[6 - this.taskInfo.task_priority].text
    },
    // 输入评论的placeholder
    commentPlaceholder() {
      if (!this.replyTo) {
        return '发表评论'
      } else {
        return `回复${this.replyTo.clerk_alias || this.replyTo.clerk_name}：`
      }
    },
    // 当前任务的URL
    currentURL() {
      return this.$config.frontHome + 'taskDetail/' + this.taskId
    },
    // 判断是否有权限
    renderHasPer() {
      const data = this.taskInfo
      return (
        data.hasPer_create ||
        data.hasPer_director ||
        data.hasPer_partici ||
        data.hasPer_star
      )
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.taskId = this.$route.params.id
      // 获取任务详情
      // this.getTaskDetail(this.taskId)
      // console.log('任务详情进来了', this.$route.params.id)
    }
    // 从查询参数中接收detail_tab的值，把它设置给activeName，直接控制到tab的定位
    if (this.$route.query.detail_tab) {
      switch (this.$route.query.detail_tab) {
        case 'comment': {
          this.activeName = 'third'
        }
      }
    }
    // 获取职员列表
    this.getClerkTree()
    // 点击外面隐藏掉更多弹窗
    document.addEventListener('click', this.clickOut)
    // this.searchDirectorAntiShare = this.$util.antiShare(this.searchClerkList)
    // this.commentList = []
  },
  methods: {
    ...mapActions(['getToday']),
    handerShowMenu() {
      if (this.showMenu === true) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
    },
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(
            reg,
            `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`
          )
        : url
    },
    // 是否可以修改子任务
    isAllowUpdateChildTask(task) {
      return (
        task.director_clerk_id === this.myClerkId ||
        task.create_clerk_id === this.myClerkId
      )
    },
    handellInput(val) {
      this.newComment = val
    },
    // 计算当前时间
    getTodayDate() {
      this.getToday().then((res) => {
        this.todayDate = res
      })
    },
    // 获取一页任务记录
    async getTaskLogList(page = 1) {
      this.isLoadingLog = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskLog, {
          pageNumber: page,
          pageSize: this.taskLogPageInfo.pageSize,
          task_id: this.realTaskId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 赋值给数组
        this.taskLogList = [...this.taskLogList, ...res.page.list]
        // 给数据赋值
        this.taskLogPageInfo.totalPage = res.page.totalPage
        this.taskLogPageInfo.pageNumber = res.page.pageNumber
        this.taskLogPageInfo.totalRow = res.page.totalRow
        this.isLoadingLog = false
      } else {
        this.isLoadingLog = false
      }
    },
    // 获取任务记录的下一页
    getLogNextPage(obj) {
      if (obj.id === 'record-data') {
        this.taskLogPageInfo.pageNumber = obj.page
        if (!this.isLoadingLog) {
          this.getTaskLogList(this.taskLogPageInfo.pageNumber)
        }
      }
    },
    // 刷新任务记录
    refreshTaskLog() {
      this.taskLogList = []
      if (!this.isLoadingLog) {
        this.getTaskLogList(1)
      }
    },
    // 设置评论的是谁
    setReplyObject(obj) {
      this.replyTo = obj
    },
    // 获取一页任务评论列表
    async getTaskCommentPage(page = 1) {
      this.isLoading = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskCommentList, {
          task_id: this.realTaskId,
          pageNumber: page,
          pageSize: this.commentListPageInfo.pageSize
        })
      )
      if (err) {
      } else if (res.state === 'ok') {
        if (page === 1) {
          this.commentList = [...res.page.list]
        } else {
          this.commentList = [...this.commentList, ...res.page.list]
        }
        this.commentListPageInfo.totalPage = res.page.totalPage
        this.commentListPageInfo.totalRow = res.page.totalRow
        this.commentListPageInfo.pageNumber = res.page.pageNumber
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    // 获取下一页任务评论列表
    getCommentNextPage() {
      this.commentListPageInfo.pageNumber++
      if (!this.isLoading) {
        this.getTaskCommentPage(this.commentListPageInfo.pageNumber)
      }
    },
    // 刷新任务评论列表
    refreshCommentData() {
      // this.commentList = []
      this.commentListPageInfo.pageNumber = 1
      if (!this.isLoading) {
        this.getTaskCommentPage(1)
      }
    },
    beginEdit() {
      this.isEditing = true
    },
    // 添加评论
    async addNewComment() {
      this.isEditing = false
      const val = this.newComment.trim()
      if (val.length > 0) {
        const formData = {
          task_id: this.taskInfo.task_id,
          comment_content: val
        }
        if (this.replyTo && this.replyTo.pid !== -1) {
          formData.pid = this.replyTo.pid
        }
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.addTaskComment, formData)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          // this.$message({
          //   showClose: true,
          //   message: '评论成功',
          //   type: 'success'
          // })
          // 还原输入框的状态
          this.newComment = ''
          this.replyTo = null
          // 刷新数据
          this.refreshCommentData()
        }
      }
    },
    // 取消聚焦
    cancelFocus() {
      this.isEditing = false
      if (this.replyTo && this.newComment.trim().length === 0) {
        this.replyTo = null
      }
    },
    // 点击任务名旁边的复选框
    checkTask() {
      if (this.isAllowUpdate) {
        if (this.taskInfo.task_status !== 3) {
          this.$http
            .post(this.$api.modifyTaskStatus, {
              task_id: this.taskInfo.task_id,
              task_status: 3
            })
            .then((res) => {
              if (res.state === 'ok') {
                this.isUpdateData = true
                this.taskInfo.task_status = 3
                this.currentState = 2
              }
            })
        } else {
          this.$http
            .post(this.$api.modifyTaskStatus, {
              task_id: this.taskInfo.task_id,
              task_status: 2
            })
            .then((res) => {
              if (res.state === 'ok') {
                this.isUpdateData = true
                this.taskInfo.task_status = 2
                this.currentState = 1
              }
            })
        }
      }
    },
    popupShow1() {
      this.popupShow = !this.popupShow
    },
    // 展示出父任务
    showParentInfo(id) {
      // this.isShowChild = true
      // this.currentChildId = id
      this.taskId = id
      // this.$emit('change-task-id', id)
    },
    // 处理文件上传成功的事件
    closeUploadProgress(index) {
      this.uploadProgressList.splice(index, 1)
    },
    // 点击“添加子任务”
    toAddChildTask() {
      if (
        this.childrenList.length === 0 &&
        (this.isInParticipant || this.isAllowUpdate)
      ) {
        this.beginAddChildTask()
      }
    },
    // 添加任务描述
    toAddTaskDes() {
      if (!this.taskInfo.task_describe_text && this.isAllowUpdate) {
        this.isShowEdit = true
      }
    },
    // 点击上传新附件按钮
    toUploadAttachment() {
      this.$refs.uploadAttachmentRef.click()
    },
    // 上传文件
    async readyUpload(e) {
      const file = e.target.files[0]
      console.log('准备上传附件', this.taskInfo.task_id)
      console.log('准备上传附件', file)
      // 上传进度对象
      const uploadProgress = {}
      uploadProgress.uploadFileName = file.name
      uploadProgress.uploadProgress = 0
      uploadProgress.id = ++this.uploadCount
      this.uploadProgressList.push(uploadProgress)
      // 准备一个formData对象
      const formData = new FormData()
      formData.append('file', file)
      formData.append('task_id', this.taskInfo.task_id)
      // 准备一个回调函数来获取上传进度
      const updateUploadProgress = (progressEvent) => {
        var complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
        uploadProgress.uploadProgress = complete
      }
      // 上传文件
      const { result, cancel } = this.$util.uploadFile(
        this.$api.uploadAttachment,
        formData,
        updateUploadProgress
      )
      // 接收cancel方法
      uploadProgress.cancel = cancel
      result.then((res) => {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '上传附件成功！',
            type: 'success'
          })
          this.refreshAttachmentList()
        }
      })
    },
    // 刷新附件列表
    async refreshAttachmentList() {
      // 发请求，获取当前任务的列表
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getAttachmentList, {
          task_id: this.taskInfo.task_id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 覆盖掉当前显示出来的数组
        this.appendixList = [...res.list]
      }
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 获取任务详情
    async getTaskDetail(id = -1) {
      // 判断是否有taskId，如果有就使用；如果没有，就看我传入的参数
      if (this.taskId !== -1) {
        id = this.taskId
      }
      if (id !== -1) {
        // 获取任务详情
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.taskDetail, {
            task_id: id
          })
        )
        this.isFirstInit = false
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            // 等到获取到了新数据再清空临时数据
            this.clearData(false)
            // 标签
            res.info.infoLabel = this.parseLabel(
              res.info.task_name,
              res.info.task_label_list
            )
            // 关联目标的标签
            if (res.info.objective_info) {
              res.info.objective_info.infoLabel = this.parseLabel(
                res.info.objective_info.objective_name,
                res.info.objective_info.objective_label_list
              )
            }
            // 父级任务的标签
            if (res.info.parents_list) {
              res.info.parents_list = res.info.parents_list.map((e) => {
                return {
                  ...e,
                  infoLabel: this.parseLabel(e.task_name, e.task_label_list)
                }
              })
            }
            this.taskInfo = res.info
            // 初始化任务名称
            this.currentTaskName = this.taskInfo.task_name
            // 初始化任务详情
            this.currentState = this.taskInfo.task_status - 1
            // 初始化开始时间和结束时间
            // 如果有，转成Date类型的,否则转成null
            this.startTime = this.taskInfo.start_date || ''
            this.startTime = this.startTime.trim().slice(0, 10)
            this.endTime = this.taskInfo.end_date || ''
            this.endTime = this.endTime.trim().slice(0, 10)
            // 记录下开始时间和结束时间原始值（字符串类型）
            this.tempStartTimeStr = this.startTime
            this.tempEndTimeStr = this.endTime
            // 初始化优先级
            this.currentPriority = 6 - this.taskInfo.task_priority
            // 初始化工作量
            this.workload = this.taskInfo.task_workload
            // 初始化负责人
            this.leader = {
              clerk_alias: this.taskInfo.director_clerk_alias,
              clerk_name: this.taskInfo.director_clerk_name,
              clerk_avatar: this.taskInfo.director_clerk_avatar
            }
            // “自己”的头像
            const me = ''
            // 初始化任务描述，不需要额外处理，赋值就可以
            // 初始化子任务列表
            for (const childTask of this.taskInfo.child_list) {
              // 子任务的id和名字
              const task = {
                task_id: childTask.task_id,
                task_name: childTask.task_name,
                member_list: [],
                task_status: childTask.task_status,
                progress_status: childTask.progress_status,
                task_participant_list: childTask.task_participant_list,
                progress_number: childTask.progress_number,
                director_clerk_id: childTask.director_clerk_id,
                create_clerk_id: childTask.create_clerk_id
              }
              // 把子任务的负责人加上去
              task.leader = {
                clerk_id: childTask.director_clerk_id,
                clerk_avatar: childTask.director_clerk_avatar,
                clerk_alias: childTask.director_clerk_alias,
                clerk_name: childTask.director_clerk_name
              }
              // 把任务创建人放到成员列表第一个
              // 判断职员id是否是自己的id，是就换成“自己”的图片
              task.member_list.push({
                clerk_avatar:
                  childTask.create_clerk_id === this.$store.state.myClerkId
                    ? me
                    : childTask.create_clerk_avatar,
                clerk_id: childTask.create_clerk_id,
                clerk_alias: childTask.create_clerk_alias,
                clerk_name: childTask.create_clerk_name
              })
              // 判断创建人id和负责人id是否相同，如果不同，则把负责人放到成员列表的第二个
              if (childTask.create_clerk_id !== childTask.director_clerk_id) {
                task.member_list.push({
                  clerk_avatar:
                    childTask.director_clerk_id === this.$store.state.myClerkId
                      ? me
                      : childTask.director_clerk_avatar,
                  clerk_id: childTask.director_clerk_id,
                  clerk_alias: childTask.director_clerk_alias,
                  clerk_name: childTask.director_clerk_name
                })
              }
              // 接下来把任务参与者放到成员列表去
              for (let i = 0; i < childTask.task_participant_list.length; i++) {
                if (
                  childTask.task_participant_list[i].clerk_id ===
                  this.$store.state.myClerkId
                ) {
                  childTask.task_participant_list[i].clerk_avatar = me
                }
                // if (task.member_list.length < 3) {
                task.member_list.push(childTask.task_participant_list[i])
                // } else break
              }
              // 标签
              task.infoLabel = this.parseLabel(
                childTask.task_name,
                childTask.task_label_list
              )
              this.childrenList.push(task)
              console.log('这个子任务的id是：', task.task_id)
            }
            // 初始化项目成员列表，注意第一个是任务的创建者，要判断头像是否是自己的
            const createClerkInfo = {
              clerk_id: this.taskInfo.create_clerk_id,
              clerk_avatar:
                this.$store.state.myClerkId === this.taskInfo.create_clerk_id
                  ? me
                  : this.taskInfo.create_clerk_avatar,
              clerk_alias: this.taskInfo.create_clerk_alias,
              clerk_name: this.taskInfo.create_clerk_name
            }
            this.memberList.push(createClerkInfo)
            // 判断创建人和负责人是否是同一个人，如果不是，就把负责人也添加进去
            if (
              this.taskInfo.director_clerk_id > 0 &&
              this.taskInfo.director_clerk_id !== this.taskInfo.create_clerk_id
            ) {
              const directorClerkInfo = {
                clerk_id: this.taskInfo.director_clerk_id,
                clerk_avatar:
                  this.$store.state.myClerkId ===
                  this.taskInfo.director_clerk_id
                    ? me
                    : this.taskInfo.director_clerk_avatar,
                clerk_alias: this.taskInfo.director_clerk_alias,
                clerk_name: this.taskInfo.director_clerk_name
              }
              this.memberList.push(directorClerkInfo)
            }
            // 把任务的参与者加到成员列表里去
            for (const member of this.taskInfo.participant_list) {
              if (member.clerk_id === this.$store.state.myClerkId) {
                member.clerk_avatar = me
              }
              this.memberList.push(member)
            }
            // 初始化附件列表
            this.appendixList = [...this.taskInfo.attachment_list]
            console.log('拿到了任务详情', this.taskInfo)
            // 初始化父任务列表
            if (this.taskInfo.parents_list) {
              this.taskInfo.parents_list.reverse()
            }
          }
        }
      }
    },
    // 开始输入任务名称
    beginInputName() {
      if (!this.isAllowUpdate) {
        return
      }
      this.isEditName = true
      this.currentTaskName = this.taskInfo.task_name
      this.$nextTick(() => {
        this.$refs.inputTaskName.focus()
      })
    },
    // 任务名称输入完成
    handleInputName() {
      if (!this.isAllowUpdate) {
        return
      }
      const value = this.currentTaskName.trim()
      if (value.length === 0 || value === this.taskInfo.task_name) {
        this.cancelInputName()
      } else {
        console.log('不为空，要提交。id为：', this.taskInfo.task_id)
        this.editTaskName()
      }
    },
    // 取消输入任务名称
    cancelInputName() {
      this.isEditName = false
      this.currentTaskName = ''
    },
    // 发请求，修改任务名称
    async editTaskName() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskName, {
          task_id: this.taskInfo.task_id,
          task_name: this.currentTaskName
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '任务名称修改成功！',
            type: 'success'
          })
          // 修改成功以后，把值写到页面上
          this.taskInfo.task_name = this.currentTaskName
          this.$set(
            this.taskInfo,
            'infoLabel',
            this.parseLabel(
              this.taskInfo.task_name,
              this.taskInfo.task_label_list
            )
          )
          this.cancelInputName()
          this.isUpdateData = true
        }
      }
    },
    // 选择日期
    dataTimeChange(value) {
      console.log(value)
    },
    // 发请求，修改任务的开始和结束时间
    async editTaskStartOrEndTime(time, isStart) {
      // 根据是否是开始时间和时间来准备数据
      const formObj = {
        task_id: this.taskInfo.task_id
      }
      if (isStart) {
        formObj.start_time = this.startTime
      } else {
        formObj.end_time = this.endTime
      }
      // 发送请求
      const api = isStart ? this.$api.editStartTime : this.$api.editEndTime
      const [err, res] = await this.$util.to(this.$http.post(api, formObj))
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: `修改${isStart ? '开始' : '结束'}时间成功！`,
            type: 'success'
          })
          this.isUpdateData = true
          // 更新临时时间
          if (isStart) {
            this.tempStartTimeStr = formObj.start_time
          } else {
            this.tempEndTimeStr = formObj.end_time
          }
        } else {
          if (isStart) {
            this.startTime = this.tempStartTimeStr
          } else {
            this.endTime = this.tempEndTimeStr
          }
        }
      }
    },
    // 选择开始时间
    handleStartTime() {
      // 比对刚刚选择的时间和上次选择的时间
      const newTime = this.startTime
      if (
        newTime != null &&
        newTime !== this.tempStartTimeStr.trim().slice(0, 10)
      ) {
        // 发起请求
        this.editTaskStartOrEndTime(this.startTime, true)
      }
    },
    // 选择结束时间
    handleEndTime() {
      // 比对刚刚选择的时间和上次选择的时间
      const newTime = this.endTime
      if (
        newTime != null &&
        newTime !== this.tempEndTimeStr.trim().slice(0, 10)
      ) {
        // 发起请求
        this.editTaskStartOrEndTime(this.endTime, false)
      }
    },
    // 选择当前任务的负责人
    closeCurrentLeader({ leader, isUpdateData }) {
      // 记录当前选择的负责人
      this.leader = leader
      this.isUpdateData = isUpdateData
      // 关闭选择负责人的弹窗
      this.isChooseLeader = false
      // 刷新数据
      this.getTaskDetail(this.taskInfo.task_id)
    },
    // 设置优先级
    async chooseTaskPriority(index) {
      this.currentPriority = index
      this.isChoosePriority = false
      // 修改完当前的优先级值，然后发起请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskPriority, {
          task_id: this.taskInfo.task_id,
          task_priority: 6 - this.currentPriority
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          // 把字面上的优先级索引也修改了，让文字修改
          this.taskInfo.task_priority = 6 - this.currentPriority
          this.$message({
            showClose: true,
            message: '优先级修改成功',
            type: 'success'
          })
          this.isUpdateData = true
        }
      }
    },
    // 准备提交工作量
    readySumbitWorkload() {
      const val = this.workload.trim()
      if (
        (this.taskInfo.task_workload.length === 0 && val.length === 0) ||
        this.taskInfo.task_workload === val
      ) {
        this.cancelInputWorkload()
      } else {
        this.setTaskWorkload()
      }
    },
    // 取消工作量
    cancelInputWorkload() {
      // 还原原来的值
      this.workload = this.taskInfo.task_workload
    },
    // 设置工作量
    async setTaskWorkload() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskWorkload, {
          task_id: this.taskInfo.task_id,
          task_workload: this.workload
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.taskInfo.task_workload = this.workload
          this.$message({
            showClose: true,
            message: '修改工作量成功！',
            type: 'success'
          })
          this.isUpdateData = true
        }
      }
    },
    // 点击tab
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 显示隐藏选择优先级的弹窗
    toggleChoosePriority() {
      if (!this.isAllowUpdate) {
        return
      }
      this.clickOut()
      this.isChoosePriority = !this.isChoosePriority
    },
    // 显示出富文本编辑
    showEditBox() {
      if (!this.isAllowUpdate) {
        return
      }
      this.isShowEdit = true
      this.taskDescVal = {
        html: this.taskInfo.task_describe_html,
        text: this.taskInfo.task_describe_text
      }
    },
    // 点击保存
    async handleEditDes() {
      console.log(this.$refs.editor)
      const val = this.$refs.editor.getContent()
      // 发送请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskDescription, {
          task_id: this.taskInfo.task_id,
          task_describe_html: val.html,
          task_describe_text: val.text
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.taskInfo.task_describe_html = val.html
          this.taskInfo.task_describe_text = val.text
          this.cancelEditDes()
          this.$message({
            showClose: true,
            message: '修改任务描述成功!',
            type: 'success'
          })
          this.isUpdateData = true
        }
      }
    },
    // 点击取消
    cancelEditDes() {
      this.isShowEdit = false
      this.taskDescVal = {
        html: '',
        text: ''
      }
    },
    onClick(e, editor) {
      console.log('Element clicked')
    },
    // 显示添加成员
    openAddMember(id, list, createClerkId) {
      if (!(this.isAllowUpdate || this.isInParticipant)) {
        return
      }
      this.isShowAddMember = true
      // 记录现在要添加哪个任务的成员
      this.currentId = id
      // 初始化要添加成员的id集合，把当前任务的每个成员的id加进去
      this.memberIdList = []
      // 当前任务的创建人id
      this.currentCreateClerkId = createClerkId
      // 初始化临时的成员列表，把已有的成员信息放进来
      this.memberListTemp = [...list]
      console.log('准备添加任务的成员了：', this.memberListTemp.length)
      // 初始化临时的成员id列表，根据成员列表来取id
      for (let i = 0; i < this.memberListTemp.length; i++) {
        this.memberIdList.push(this.memberListTemp[i].clerk_id)
      }
      console.log('当前任务参与者的id集合是：', this.memberIdList)
    },
    // 关闭添加成员
    closeAddMember() {
      this.isShowAddMember = false
      // 把成员id集合清空
      this.memberIdList = []
      // 去掉当前要添加成员的任务id
      this.currentId = -1
      // 清空临时成员列表
      this.memberListTemp = []
    },
    // 选择当前状态
    async chooseState(index) {
      this.currentState = index
      console.log('当前任务的id是：', this.taskInfo.task_id)
      // 发起修改任务状态请求
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTaskStatus, {
          task_id: this.taskInfo.task_id,
          task_status: this.currentState + 1
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          // this.$message({
          //   showClose: true,
          //   message: '成功修改状态',
          //   type: 'success'
          // })
          this.isUpdateData = true
          this.isChooseState = false
          this.$set(this.taskInfo, 'task_status', this.currentState + 1)
        } else {
          this.getTaskDetail(this.taskInfo.task_id)
        }
      }
    },
    // 点击显示隐藏选择状态的弹窗
    toggleChooseState() {
      if (!this.isAllowUpdate) {
        return
      }
      this.clickOut()
      this.isChooseState = !this.isChooseState
    },
    // 点击显示隐藏选择负责人的弹窗
    toggleChooseLeader() {
      if (!this.isAllowUpdate) {
        return
      }
      this.clickOut()
      this.isChooseLeader = !this.isChooseLeader
    },
    // 点击显示隐藏输入框
    toggleChildEdit(task) {
      if (!this.isAllowUpdate) {
        return
      }
      // 显示某个编辑任务名称的输入框，并且填入原本的任务名
      if (this.currentEditTask !== task.task_id) {
        this.currentEditTask = task.task_id
        this.childrenTemp = task.task_name
        this.$nextTick(() => {
          // 让它聚焦
          this.$refs[`child${task.task_id}`][0].focus()
        })
      } else {
        // 隐藏
        this.cancelEditChild()
      }
    },
    // 确定修改子任务名字
    async handleEditChild(task) {
      const val = this.childrenTemp.trim()
      if (val.length > 0 && val !== task.task_name) {
        console.log('不为空，需要提交。id为', task.task_id)
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskName, {
            task_id: task.task_id,
            task_name: val
          })
        )
        if (err) {
          console.log(err)
        } else {
          if (res.state === 'ok') {
            task.task_name = val
            this.cancelEditChild()
            this.$message({
              showClose: true,
              message: '修改子任务名称成功',
              type: 'success'
            })
            this.isUpdateData = true
          }
        }
        this.getTaskDetail()
      } else {
        this.cancelEditChild()
      }
    },
    // 取消修改子任务
    cancelEditChild() {
      this.childrenTemp = ''
      this.currentEditTask = -1
    },
    // 展示出子任务的更多弹窗
    showChildMore(task) {
      this.currentHandleChild = task.task_id
    },
    // 点到外面去，取消选择
    clickOut() {
      this.currentHandleChild = -1
      this.isChooseState = false
      this.isChoosePriority = false
      this.isChooseLeader = false
      this.isSetProgress = false
      this.isShowCommentExpression = false
      // 关闭所有的进度选择弹窗
      for (const task of this.childrenList) {
        this.$set(task, 'isChooseProgress', false)
      }
    },
    // 删除子任务
    deleteChildTask(task, index) {
      this.$util
        .showConfirm('此操作将永久删除该任务, 是否继续?')
        .then(async (result) => {
          if (result) {
            // 确定要删除，那就发请求
            const [err, res] = await this.$util.to(
              this.$http.post(this.$api.removeTask, {
                task_id: task.task_id
              })
            )
            if (err) {
              console.log(err)
            } else {
              if (res.state === 'ok') {
                this.$message({
                  showClose: true,
                  message: '删除子任务成功',
                  type: 'success'
                })
                this.isUpdateData = true
                // 在页面上删除数组里的这个元素
                this.childrenList.splice(index, 1)
              }
            }
          }
        })
    },
    // 准备添加子任务
    beginAddChildTask() {
      this.isAddChildTask = true
      this.currentEditTask = -1
      this.childrenTemp = ''
      this.$nextTick(() => {
        this.$refs.addChildTaskInput.focus()
      })
    },
    // 处理新增子任务
    handleNewChild() {
      const val = this.childrenTemp.trim()
      if (val.length > 0) {
        console.log('父任务id是：', this.taskInfo.task_id)
        // 当前的时间是
        console.log('当前的时间是', this.todayDate)
        let meetingId = -1
        // 如果是要添加会议任务，就带上会议id
        if (this.meetingId && this.meetingId !== -1) {
          meetingId = this.meetingId
        }
        // 不为空，要提交
        this.$util
          .addChildTaskOf(this.taskInfo.task_id, val, this.todayDate, meetingId)
          .then(() => {
            this.cancelNewChild()
            this.isUpdateData = true
            // 刷新任务详情数据
            this.getTaskDetail(this.taskInfo.task_id).then(() => {
              console.log('子任务新增完成')
            })
          })
      } else {
        this.isAddChildTask = false
      }
    },
    // 开始设置子任务的负责人
    beginChooseChildLeader(index) {
      if (this.isAllowUpdateChildTask(this.childrenList[index])) {
        this.$set(
          this.childrenList[index],
          'isChooseLeader',
          !this.childrenList[index].isChooseLeader
        )
      }
    },
    // 确定选择子组件的负责人
    chooseChildLeader({ leader, isUpdateData }, index) {
      this.$set(this.childrenList[index], 'leader', leader)
      this.isUpdateData = isUpdateData
      this.$set(this.childrenList[index], 'isChooseLeader', false)
    },
    // 如果点到外面，关闭子任务的负责人组件
    closeChooseLeader() {
      for (const child of this.childrenList) {
        this.$set(child, 'isChooseLeader', false)
      }
    },
    // 取消新增子任务
    cancelNewChild() {
      this.childrenTemp = ''
      this.isAddChildTask = false
    },
    // 关闭详情弹窗
    closeDetailModal() {
      this.clearData()
      // 关闭后，选中的tab恢复为第一个
      this.activeName = 'first'
      this.isFirstInit = true
      this.$emit('close-detail', {
        is_update_data: this.isUpdateData
      })
    },
    // 点击头像，准备添加任务成员
    readyAddMember(clerkInfo) {
      console.log(this.memberIdList.includes(clerkInfo.clerk_id))
      if (clerkInfo.clerk_id === this.currentCreateClerkId) {
        this.$message({
          showClose: true,
          message: '请不要添加任务的创建者！',
          type: 'error'
        })
        return
      }
      if (clerkInfo.clerk_id === this.taskInfo.director_clerk_id) {
        this.$message({
          showClose: true,
          message: '请不要添加任务的负责人！',
          type: 'error'
        })
        return
      }
      // 判断id是否出现过
      if (!this.memberIdList.includes(clerkInfo.clerk_id)) {
        console.log('没有出现过')
        // 追加id
        this.memberIdList.push(clerkInfo.clerk_id)
        // 把数据追加到数组最后
        this.memberListTemp.push(clerkInfo)
      } else {
        this.$message({
          showClose: true,
          message: '请不要添加重复的成员！',
          type: 'error'
        })
      }
      console.log('准备添加到成员的id集合：', this.memberIdList)
    },
    // 点击删除按钮，删除一个成员id
    removeTempId(member) {
      const id = member.clerk_id
      console.log('要删除的成员id是', member.clerk_id)
      // 找到它在临时数组的下标
      const index = this.memberIdList.findIndex((item) => item === id)
      console.log('要删除的成员id下标是', index)
      // 从临时数组中删除它
      this.memberIdList.splice(index, 1)
      this.memberListTemp.splice(index, 1)
    },
    // 发送请求，添加任务成员
    async addMember({ list, idList }) {
      // 把子组件传递过来的值记录下来，过滤掉创建人和负责人再来提交
      this.memberTempList = [...list].filter(
        (item) =>
          item.clerk_id !== this.taskInfo.director_clerk_id &&
          item.clerk_id !== this.taskInfo.create_clerk_id
      )
      this.memberTempIdList = [...idList].filter(
        (item) =>
          item !== this.taskInfo.director_clerk_id &&
          item !== this.taskInfo.create_clerk_id
      )
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskMember, {
          task_id: this.currentId,
          clerk_ids: this.memberTempIdList.join()
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务成员成功！',
            type: 'success'
          })
          this.isUpdateData = true
          // 关闭添加成员的弹窗
          this.closeAddMember()
          this.getTaskDetail(this.taskInfo.task_id)
        } else {
          // console.log('添加失败')
          // this.openAddMember(
          //   this.taskInfo.task_id,
          //   this.taskInfo.participant_list,
          //   this.taskInfo.create_clerk_id
          // )
        }
      }
    },
    // 把临时数据清空
    clearData(flag = true) {
      this.isShowEdit = false
      this.startTime = null
      this.endTime = null
      this.leader = null
      this.childrenList = []
      this.isAddChildTask = false
      this.memberList = []
      this.currentId = -1
      this.memberListTemp = []
      this.memberIdList = []
      this.currentEditTask = -1
      if (flag) {
        this.commentList = []
      }
      this.taskLogList = []
      this.isShowCommentExpression = false
    },
    // 搜索子任务详情
    showChildDetail(task) {
      // this.isShowChild = true
      // this.currentChildId = task.task_id
      this.taskId = task.task_id
      // this.getTaskDetail(this.currentChildId)
    },
    downloadAttachment(file) {
      this.$util.downloadFile('/task/attachment/downloadAll', {
        // this.$util.downloadFile(this.$api.downloadTaskAttachment, {
        task_id: this.realTaskId,
        attachment_id: file.attachment_id
      })
    },
    // 设置子任务是否选中
    async checkChildTask(task) {
      if (this.isAllowUpdateChildTask(task)) {
        let val = 0
        if (task.task_status === 3) {
          val = 2
        } else {
          val = 3
        }
        const formObj = {
          task_id: task.task_id,
          task_status: val
        }
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.modifyTaskStatus, formObj)
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.childrenList.findIndex((e, i) => {
            if (e.task_id === task.task_id) {
              this.childrenList[i].task_status = val
              // 如果现在点击后是“已完成”，把进度改为100%
              if (val === 3) {
                this.childrenList[i].progress_number = 100
              }
              return true
            }
          })
          // this.getTaskDetail(this.taskInfo.task_id)
        }
      }
    },
    // 开始设置页面进度
    beginEditProgress(index) {
      if (this.isAllowUpdateChildTask(this.childrenList[index])) {
        for (const task of this.childrenList) {
          this.$set(task, 'isChooseProgress', false)
        }
        // 显示出对应的弹窗，并且初始化数值
        this.$set(this.childrenList[index], 'isChooseProgress', true)
        this.currentProgress = this.childrenList[index].progress_number
        this.temp = this.childrenList[index].progress_number
      }
    },
    // 设置任务的进度
    async editProgress({ id, index }) {
      // 假如把里面的数字删干净了，视为没有修改
      if (this.currentProgress.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$nextTick(() => {
          if (index > -1) {
            this.$refs[`${id}progressRef`][0].blur()
          } else {
            this.$refs[`${id}progressRef`].blur()
          }
        })
      } else if (
        Number(this.currentProgress) < 0 ||
        Number(this.currentProgress) > 100
      ) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$nextTick(() => {
          if (index > -1) {
            this.$refs[`${id}progressRef`][0].blur()
          } else {
            this.$refs[`${id}progressRef`].blur()
          }
        })
      } else {
        // 假如输入正确，那就发起修改请求
        this.$nextTick(() => {
          console.log(this.$refs)
          if (index > -1) {
            this.$refs[`${id}progressRef`][0].blur()
          } else {
            this.$refs[`${id}progressRef`].blur()
          }
        })
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: this.currentProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '任务的进度修改成功！',
              showClose: true
            })
            // 成功后的处理
            // 区分是设置子任务的进度还是本任务的进度（index大于-1表示子任务）
            if (index > -1) {
              // 让进度弹窗消失
              this.$set(this.childrenList[index], 'isChooseProgress', false)
              // 修改进度的值
              this.$set(
                this.childrenList[index],
                'progress_number',
                +this.currentProgress
              )
              // 如果进度不是100%，且任务状态为“已完成”，则把状态改为“未完成”
              if (
                +this.currentProgress < 100 &&
                this.childrenList[index].task_status === 3
              ) {
                this.$set(this.childrenList[index], 'task_status', 2)
              }
            } else {
              this.isSetProgress = false
              this.taskInfo.progress_number = +this.currentProgress
            }
          }
        }
      }
    },
    // 设置进度状态
    async setTaskProgressStatus(val, id, index) {
      // 发请求，修改任务的进度状态
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskProgressStatus, {
          task_id: id,
          progress_status: val
        })
      )
      if (err) {
        console.log(res)
      } else {
        console.log(res)
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务进度状态成功！',
            type: 'success'
          })
          // 成功后的处理
          if (index > -1) {
            // 修改后改变状态
            this.childrenList[index].progress_status = val
            this.$set(this.childrenList[index], 'isChooseProgress', false)
          } else {
            // 如果修改的是本任务的进度状态
            this.taskInfo.progress_status = val
            this.isSetProgress = false
          }
        }
      }
    },
    // 显示隐藏进度设置弹窗
    toggleSetProgress() {
      if (!this.isAllowUpdate) {
        return
      }
      this.isSetProgress = !this.isSetProgress
      if (this.isSetProgress) {
        this.currentProgress = this.taskInfo.progress_number
        this.temp = this.currentProgress
      }
    },
    // 删除当前任务
    async toDeleteTask() {
      this.$util.showConfirm('确认要删除这个任务吗？').then((result) => {
        if (result) {
          this.$http
            .post(this.$api.removeTask, {
              task_id: this.taskId
            })
            .then((res) => {
              if (res.state === 'ok') {
                this.$message({
                  showClose: true,
                  message: '任务删除成功',
                  type: 'success'
                })
                // 删除后跳转到首页
                this.$router.push('/')
              }
            })
        }
      })
    },
    // 复制链接
    copyLink() {
      this.$copyText(`${this.$config.frontHome}taskDetail/${this.taskId}`).then(
        () => {
          // this.$message({
          //   showClose: true,
          //   message: '已成功复制，可直接去粘贴',
          //   type: 'success'
          // })
          this.isShowTip = true
          // 2秒后提示隐藏
          setTimeout(() => {
            this.isShowTip = false
          }, 2000)
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // keydown事件
    showCode(e) {
      console.log('正在输入', e.keyCode)
      // 如果是正在使用拼音输入法，不管输入什么，keyCode为229
      if (e.keyCode === 229) {
        this.keyCode = e.keyCode
      } else {
        this.keyCode = 0
      }
    },
    // 触发blur事件
    toBlur(e, args) {
      if (this.keyCode !== 229) {
        e.target.blur(args)
      }
    },
    // 点击表情
    addExpress(express) {
      this.newComment = this.newComment.replace('<p>&nbsp;', '<p>')
      const index = this.newComment.lastIndexOf('</p>')
      if (index === -1) {
        this.newComment = '<p>' + express.name + '</p>'
      } else {
        this.newComment =
          this.newComment.substring(0, index) + express.name + '</p>'
      }
      this.isShowCommentExpression = false
      setTimeout(() => {
        // 让输入框重新聚焦
        this.$refs.commentRef.setFocus()
      }, 50)
    },
    // 显示隐藏表情
    toggleCommentExpression(val) {
      this.isShowCommentExpression = !val
    },
    // 空函数
    empty() {},
    // 点击选择关联父目标
    toRelativeModal() {
      if (this.taskInfo.director_clerk_id === this.myClerkId) {
        this.isChooseRelative = true
      }
    },
    // 判断是跳转到父目标还是弹窗
    toObjectiveOrModal() {
      if (this.taskInfo.objective_info != null) {
        this.toObjective()
      } else {
        if (this.taskInfo.director_clerk_id === this.myClerkId) {
          this.toRelativeModal()
        }
      }
    },
    // 跳转到父目标
    toObjective() {
      this.$router.push(
        `/targetDetail/${this.taskInfo.objective_info.objective_id}`
      )
    },
    // 解析标签
    parseLabel(name, labelList) {
      const labelMap = {}
      labelList.forEach((e) => {
        labelMap[e.label_name] = e.label_id
      })
      let showName = ''
      const list = []
      let status = 0
      let gth = 0
      let item = ''
      name.split('').forEach((e) => {
        let te = e
        // 处理下中文符号
        switch (te) {
          case '！': {
            te = '!'
          }
        }
        switch (status) {
          // 开始
          case 0: {
            switch (te) {
              case '!': {
                if (gth === 0) {
                  gth++
                  status = 1
                } else {
                  showName += e
                  status = 11
                }
                break
              }
              case ' ': {
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                showName += e
                status = 11
                break
              }
            }
            break
          }
          case 1: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                status = 0
                showName += e
                break
              }
            }
            break
          }
          case 2: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              default: {
                status = 11
                showName += e
                break
              }
            }
            break
          }
          case 10: {
            switch (te) {
              case '!': {
                if (item !== '') {
                  list.push(item)
                } else {
                  showName += '#'
                }
                item = ''
                if (gth === 0) {
                  gth++
                  status = 2
                } else {
                  status = 11
                }
                break
              }
              case ' ': {
                if (item !== '') {
                  list.push(item)
                }
                item = ''
                status = 0
                break
              }
              default: {
                item += e
                break
              }
            }
            break
          }
          // 直接加
          case 11: {
            showName += e
            break
          }
        }
      })
      if (status === 10) {
        if (item.length > 0) {
          list.push(item)
        }
      }
      let gthStr = ''
      for (let i = 0; i < gth; i++) {
        gthStr += '!'
      }
      return {
        showName,
        list: list.map((e) => {
          return {
            id: labelMap[e] || '',
            name: e
          }
        }),
        gth,
        gthStr
      }
    }
  },
  watch: {
    async taskId(newValue, oldValue) {
      // 清除回复对象
      this.replyTo = null
      // 清除评论内容
      this.newComment = ''
      if (newValue !== -1) {
        this.$router.push(`/taskDetail/${newValue}`)
        await this.getTaskDetail(newValue)
        // this.commentList = []
        // this.taskLogList = []
        // 获取任务评论的第一页
        await this.getTaskCommentPage(1)
        // 获取任务记录第一页
        await this.getTaskLogList(1)
      }
    },
    // 假如它显示了，就去获取任务详情
    // async isShow(newValue) {
    //   if (newValue === true) {
    //     await this.getTaskDetail(this.taskId)
    //     // this.commentList = []
    //     // this.taskLogList = []
    //     // 获取任务评论的第一页
    //     await this.getTaskCommentPage(1)
    //     // 获取任务记录第一页
    //     await this.getTaskLogList(1)
    //     this.activeName = this.currentTab
    //   }
    // },
    // 假如父组件指定的tab不是第一个，那么让tab设为父组件指定的tab
    currentTab: {
      deep: true,
      handler(val) {
        if (val !== 'first') {
          this.activeName = val
        }
      }
    },
    clickBodyIndex(val) {
      this.popupShow = false
      this.showMenu = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/priority_tags.scss';
@import '@/assets/scss/choose_member_style.scss';
@import '@/assets/scss/task_detail_style.scss';
@import '@/assets/scss/progress_box.scss';
</style>
<style lang="scss">
.popup1 {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.popup1:hover {
  background-color: #e5f2ff;
  color: #4aafff;
}
.popup {
  z-index: 100;
  position: absolute;
  top: 30px;
  right: -6px;
  width: 80px;
  padding: 5px 0;
  background-color: #fff;
  box-shadow: 0px 0px 14px 4px rgb(153 153 153 / 20%);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 6px 12px;
  cursor: auto;
}

.basic-info-item.cursor .dashboard-editor-container .el-input__inner {
  cursor: pointer !important;
}
.icon-gengduo3 {
  text-align: end;
  width: 6%;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 21px;
  color: #999999;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.message-menu {
  position: absolute;
  width: 92px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  right: -6px;
  top: 37px;
  border-radius: 4px;
  padding: 6px 0;

  .menu-item {
    cursor: pointer;
    width: 76px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    margin: 0 auto;

    &:hover {
      color: $main-color;
      background-color: rgba(0, 128, 255, 0.1);
      border-radius: 4px;
    }

    &.delete-btn {
      color: $red;

      &:hover {
        background-color: rgba(255, 84, 84, 0.1);
      }
    }
  }
}
.htmledit_views::v-deep a {
  color: $main-color !important;
  cursor: text;
  font-weight: 600;
}
.expresion-weizhi::v-deep {
  .expression-box {
    right: -70px !important;
    left: auto !important;
    z-index: 2;
  }
}
.child-name {
  width: 100%;
  padding-right: 20px;
}
.comments .content-box {
  height: calc(100vh - 62px - 28px - 30px - 144px - 124px);
}
@media screen and (max-width: 960px) {
  body {
    padding-bottom: 0 !important;
  }
  .el-tab-pane {
    max-height: none !important;
  }
  .single-box {
    margin: auto !important;
  }
  .info-content-item {
    width: 50% !important;
  }
  .child-name {
    width: 100% !important;
    // padding: 0 !important;
    margin: 0 !important;
  }
  .children-task-item {
    box-sizing: border-box !important;
  }
  .flex-y-center {
    padding-left: -20px;
  }
  * {
    box-sizing: border-box !important;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 50px !important;
  }
  body {
    width: 100%;
  }
  .child-member-box {
    width: 40px;
  }
  .modal-right-box .children-task-item .more-btn {
    width: 16px !important;
  }
}
@media screen and (max-width: 766px) {
  .comments .content-box {
    height: calc(100vh - 62px - 28px - 30px - 144px - 60px) !important;
  }
  .right-top-box {
    right: 16px !important;
  }
  .modal-right-box.el-tab-pane {
    padding: 20px 15px 15px !important;
  }
}

.task-label,
.child-task-label,
.task-objective-label,
.parent-task-label {
  display: inline-block;
  vertical-align: middle;
  color: #2b6cff;
  background: #e2ebff;
  width: min-content;
  white-space: nowrap;
}

// 任务的标签
.task-label {
  margin: 0 3px 0 0;
  padding: 0 5px;
  line-height: 33px;
  border-radius: 3px;
  font-size: 18px;
}

// 任务的标签
.child-task-label {
  margin: 7px 3px 0 0;
  padding: 0 3px;
  line-height: 20px;
  border-radius: 3px;
  font-size: 12px;
}

//关联目标的标签
.task-objective-label {
  margin: 0 3px 0 0;
  padding: 0 3px;
  line-height: 20px;
  border-radius: 3px;
  font-size: 12px;
}

// 父级任务的标签
.parent-task-label {
  margin: 0 3px 0 0;
  padding: 0 3px;
  line-height: 22px;
  border-radius: 3px;
  font-size: 12px;
}
.textarea::v-deep {
  .ck-focused {
    border: none !important;
  }
  .ck-editor__editable.ck-blurred {
    border: none !important;
  }
  .ck-editor__editable_inline {
    padding: 0;
  }
  .ck-editor__editable {
    line-height: normal;
  }
}
</style>
