<!--
 * @Date: 2021-05-31 20:37:13
 * @LastEditTime: 2021-11-22 13:59:09
 * @Description: 树形多选
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div>
    <div>
      <!-- 它自己 -->
      <!-- 如果有label显示名字，如果没有，显示头像和用户名 -->
      <!-- 显示部门 -->
      <div
        v-if="isDept"
        class="label flex flex-y-center department-box"
        :style="{ 'padding-left': 16 * layer + 'px' }"
      >
        <!-- 全选按钮 -->
        <el-checkbox
          class="check-all-btn"
          :value="isCheck"
          :disabled="disableDeptIdList.includes(treeNode.dept_id)"
          @change="chooseNode()"
        ></el-checkbox>
        <span
          class="arrow"
          :class="{ active: isShowChild }"
          @click.stop="toggleChild"
        >
          <span class="iconfont icon-playfill1"></span>
        </span>
        <p class="label-text" @click.stop="toggleChild">
          {{ treeNode.dept_name }}
        </p>
      </div>
      <!-- 显示职员 -->
      <div
        v-else
        class="flex flex-y-center member"
        :style="{ 'padding-left': 16 * layer + 20 + 'px' }"
      >
        <el-checkbox
          class="check-all-btn"
          :value="isCheck"
          :disabled="disableClerkIdList.includes(treeNode.clerk_id)"
          @change="chooseNode()"
        ></el-checkbox>
        <img
          :src="treeNode.clerk_avatar"
          class="avatar"
          :alt="treeNode.clerk_alias || treeNode.clerk_name"
        />
        <p class="name e" :title="treeNode.clerk_alias || treeNode.clerk_name">
          {{ treeNode.clerk_alias || treeNode.clerk_name }}
        </p>
      </div>
      <!-- 它孩子 -->
      <template
        v-if="
          (treeNode.clerk_list && treeNode.clerk_list.length > 0) ||
          (treeNode.children && treeNode.children.length > 0)
        "
      >
        <el-collapse-transition>
          <div v-show="isShowChild">
            <!-- 职员，职员id前面拼上一个"c"字母 -->
            <member-tree-and-dept
              v-for="child in treeNode.clerk_list"
              :key="'c' + child.clerk_id"
              :treeNode="child"
              :layer="layer + 1"
              :selectClerkIdList="selectClerkIdList"
              :selectDeptIdList="selectDeptIdList"
              :disableClerkIdList="disableClerkIdList"
              :disableDeptIdList="disableDeptIdList"
              @to-add-members="$emit('to-add-members', $event)"
              @to-remove-member="$emit('to-remove-member', $event)"
            ></member-tree-and-dept>
            <!-- 部门 -->
            <member-tree-and-dept
              v-for="child in treeNode.children"
              :key="child.dept_id"
              :treeNode="child"
              :layer="layer + 1"
              :selectClerkIdList="selectClerkIdList"
              :selectDeptIdList="selectDeptIdList"
              :disableClerkIdList="disableClerkIdList"
              :disableDeptIdList="disableDeptIdList"
              @to-add-members="$emit('to-add-members', $event)"
              @to-remove-member="$emit('to-remove-member', $event)"
            >
              <!-- 全选按钮 -->
              <!-- <el-checkbox
              class="check-all-btn"
              v-model="isCheckAll"
              v-if="isShowCheckAll"
            ></el-checkbox> -->
            </member-tree-and-dept>
          </div>
        </el-collapse-transition>
      </template>
    </div>
  </div>
</template>

<script>
import MemberTreeAndDept from './MemberTreeAndDept'
export default {
  name: 'member-tree-and-dept',
  components: {
    MemberTreeAndDept
  },
  props: {
    // 当前节点的数据
    treeNode: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 当前显示在第几层
    layer: {
      type: Number,
      default: 1
    },
    // 已选职员
    selectClerkIdList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 已选部门
    selectDeptIdList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 禁用职员id列表
    disableClerkIdList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 禁用部门id列表
    disableDeptIdList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // 是否显示它的孩子
      isShowChild: false
    }
  },
  computed: {
    // 判断是部门还是员工
    isDept() {
      return !this.treeNode.clerk_id
    },
    // 控制节点的复选框是否选中
    isCheck() {
      if (this.isDept) {
        return this.selectDeptIdList.includes(this.treeNode.dept_id)
      } else {
        return this.selectClerkIdList.includes(this.treeNode.clerk_id)
      }
    }
  },
  mounted() {
    if (this.treeNode.dept_id === 1) {
      this.isShowChild = true
    }
    // console.log('数据', this.dat)
  },
  methods: {
    // 显示隐藏孩子
    toggleChild() {
      this.isShowChild = !this.isShowChild
    },
    // 点击复选框，如果当前已经是选中状态了，那就让父组件删除当前节点的所有id；否则加上当前节点的所有id
    chooseNode() {
      const info = {
        is_dept: this.isDept
      }
      if (this.isDept) {
        info.dept_id = this.treeNode.dept_id
      } else {
        info.clerk_id = this.treeNode.clerk_id
      }
      if (this.isCheck) {
        this.$emit('to-remove-member', info)
      } else {
        this.$emit('to-add-members', info)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/tree_style.scss';
</style>
<style scoped>
.department-box .check-all-btn.el-checkbox {
  margin-right: 5px;
}

.member .check-all-btn.el-checkbox {
  margin-right: 10px;
}
</style>
