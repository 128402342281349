<template>
  <scroll-fresh
    className="topic-container"
    :page="pageNumber"
    :lastPage="totalPage"
    :showNoMore="false"
    @get-next-page="getNextCommentPage"
  >
    <div class="push-info">
      <div
        class="flex flex-x-between flex-y-center push-edit_header line-bottom"
      >
        <div>
          <el-button size="small" @click="back">返回</el-button>
        </div>
        <div>
          <el-button
            class="button-delete"
            size="small"
            @click="deletePushInfo"
            v-if="perInfo.delete"
            >删除</el-button
          >
          <el-button
            class="button-release"
            size="small"
            @click="openEdit"
            v-if="perInfo.edit"
            >编辑</el-button
          >
        </div>
      </div>
      <div class="push-container">
        <div class="push-info-header line-bottom">
          <div style="margin-bottom: 18px" class="flex flex-x-between">
            <div>
              <span>推送账号：</span>
              {{
                messageInfo.create_clerk_alias || messageInfo.create_clerk_name
              }}
            </div>
            <div>
              <span>推送时间：</span>
              <div style="display: inline-block; min-width: 130px">
                {{ pushInfo.push_time || '' }}
              </div>
            </div>
          </div>
          <div class="flex flex-x-between">
            <div>
              <span>最近编辑：</span>
              {{
                messageInfo.update_clerk_alias || messageInfo.update_clerk_name
              }}
            </div>
            <div>
              <span>编辑时间：</span>
              <div style="display: inline-block; min-width: 130px">
                {{ messageInfo.update_time || '' }}
              </div>
            </div>
          </div>
        </div>
        <div class="push-article">
          <h1
            style="font-size: 24px"
            class="article-title"
            v-if="messageInfo.moment_title"
            v-html="renderMomentTitle"
          ></h1>
          <p
            class="article-content text-warp"
            v-if="messageInfo.moment_content"
            v-html="
              handlerContentToUrl(getContenHtml(pushInfo)).replace(
                /\[[^\[\]]*\]/gi,
                emotion
              )
            "
          ></p>
          <!-- 图片 -->
          <message-image
            class="image-list-pop"
            :imgs="messageInfo.image_list"
            v-if="messageInfo.content_type == 1"
          ></message-image>
          <!-- 视频 -->
          <message-video
            :url="messageInfo.video_info.cover_video_path"
            v-if="messageInfo.content_type == 2"
          ></message-video>
          <div
            v-if="pushInfo.push_status === 3"
            class="flex flex-x-between"
            style="margin-bottom: 13px"
          >
            <div style="color: #999999">
              {{ messageInfo.create_time || '' }}
            </div>
            <message-interactive
              :browseNum="messageInfo.visit_number"
              :commentNum="messageInfo.comment_number"
              :likeNum="messageInfo.praise_number"
              :isPraise="messageInfo.is_praise"
            ></message-interactive>
          </div>
          <div v-if="pushInfo.push_status === 3" class="topic-comment">
            <div class="title-bar" @click="showComment = !showComment">
              评论
            </div>
            <div class="comment-list">
              <div
                class="comment-item"
                v-for="(item, index) in commentList"
                :key="item.comment_id"
              >
                <comment-member
                  :avatar="item.clerk_avatar"
                  :name="item.clerk_alias || item.clerk_name"
                  :time="item.create_time"
                  :likeNum="item.praise_number"
                  :isLike="item.is_praise == 1"
                  :replyShow="false"
                  @onDelete="onDelete(item.comment_id)"
                  :showDelete="item.comment_id === showCommentDeleteId"
                  @mouseenter.native="showCommentDelete(item)"
                  @mouseleave.native="hideDelete()"
                ></comment-member>
                <div class="comment-content">
                  <span v-html="getCommentHtml(item).replace(/\[[^\[\]]*\]/gi, emotion)"></span>
                  <comment-reply
                    class="comment-hide-appenHtml"
                    v-for="(cItem, cIndex) in item.child_list_pack_up
                      ? item.child_list.slice(
                          0,
                          3 + item.show_child_list_offset
                        )
                      : item.child_list"
                    :key="cItem.comment_id"
                    :replyShow="false"
                    :show-reply-to-name="cItem.pid !== cItem.tid"
                    :replyInfo="cItem"
                    :showDelete="showCommentDeleteId == cItem.comment_id"
                    @onDelete="onChildDelete(index, cIndex)"
                    @onPraise="commentChildPraise(index, cIndex)"
                    @mouseenter.native="showCommentDelete(cItem)"
                    @mouseleave.native="hideDelete()"
                  ></comment-reply>
                  <template
                    v-if="item.reply_number - item.show_child_list_offset > 3"
                  >
                    <comment-total-bar
                      v-if="item.child_list_pack_up"
                      :replyNumber="item.reply_number"
                      :lastestName="
                        item.lastest_clerk_alias || item.lastest_clerk_name
                      "
                      @open="getCommentChildNextPage(index)"
                    ></comment-total-bar>
                    <div
                      class="line-top flex flex-x-between comment-pack-load-bar"
                      v-if="!item.child_list_pack_up"
                    >
                      <span
                        style="color: #0080ff; cursor: pointer"
                        @click="closeChildCommentList(index)"
                      >
                        <i class="icon-zhankai pack-up"></i>
                        收起
                      </span>
                      <span
                        style="color: #999; cursor: pointer"
                        @click.stop="getCommentChildNextPage(index)"
                        v-show="
                          item.child_list_page_number <
                          item.child_list_total_page
                        "
                      >
                        <i class="icon-zhankai"></i>
                        加载更多
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <comment-no-more></comment-no-more>
          </div>
        </div>
      </div>
    </div>
  </scroll-fresh>
</template>

<script>
import MessageImage from '@/components/circle/MessageImage.vue'
import MessageInteractive from '@/components/circle/MessageInteractive.vue'
import MessageVideo from '@/components/circle/MessageVideo.vue'
import CommentMember from '@/components/circle/CommentMember.vue'
import CommentReply from '@/components/circle/CommentReply.vue'
import CommentTotalBar from '@/components/circle/CommentTotalBar.vue'
import CommentNoMore from '@/components/circle/CommentNoMore.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
import { emojisMap } from '../common/ExpressionBox.vue'
export default {
  components: {
    MessageImage,
    MessageInteractive,
    MessageVideo,
    CommentReply,
    CommentTotalBar,
    CommentMember,
    ScrollFresh,
    CommentNoMore
  },
  props: {
    pushId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      messageInfo: {},
      pushInfo: {},
      isShowComment: false,
      pageNumber: 0,
      pageSize: 3,
      totalPage: 2,
      placeholderText: '发表评论',
      showCommentDeleteId: -1,
      replyCommentId: '',
      replyText: '',
      contentHeight: 0,
      isShowCommentExpression: false,
      commentDetail: -1,
      // 是否展示回复
      isShowReply: false,
      replyTitle: '评论',
      replyChildIndex: -1,
      // 刷新的评论列表
      commentList: [],
      childCommentLoading: false,
      commentLoading: false,
      // 是否收起
      packUp: true,
      // 一级评论数量
      topMomentCount: 0,
      // 偏移
      queryOffset: 0,
      // 展示偏移
      showOffset: 0
    }
  },
  computed: {
    perInfo() {
      return {
        add: this.$hasPer(58),
        edit: this.$hasPer(59),
        delete: this.$hasPer(60)
      }
    },
    // renderMomentConten() {
    //   const content = this.messageInfo.moment_content
    //   let str = ''
    //   let index = 0
    //   if (this.pushInfo.topic_list.length !== 0) {
    //     this.pushInfo.topic_list.forEach((e, i) => {
    //       str +=
    //         content.substring(index, e.start_index) +
    //         `<a data-id="${e.topic_id}" data-name="${e.topic_name}" style=" color: #0080FF;font-weight:600">#${e.topic_name}#</a>`
    //       index = e.end_index + 1
    //       if (i === this.pushInfo.topic_list.length - 1) {
    //         str += content.slice(index)
    //       }
    //     })
    //   } else {
    //     str = content
    //   }
    //   console.log(str)
    //   return str
    // },
    renderMomentTitle() {
      const content = this.messageInfo.moment_title
      let str = ''
      let index = 0
      if (this.pushInfo.topic_title_list.length !== 0) {
        this.pushInfo.topic_title_list.forEach((e, i) => {
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${e.topic_id}" data-name="${e.topic_name}" style=" color: #0080FF;font-weight:600">#${e.topic_name}#</a>`
          index = e.end_index + 1
          if (i === this.pushInfo.topic_title_list.length - 1) {
            str += content.slice(index)
          }
        })
      } else {
        str = content
      }
      console.log(str)
      return str
    }
  },
  methods: {
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
    },
    // 获取推送详情
    async getPushDetail() {
      if (this.pushId === -1) {
        this.$message.error('请选择圈子推送')
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemMomentPushDetail, {
          push_id: this.pushId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.messageInfo = res.info.moment_info
        this.pushInfo = res.info
        this.reloadCommentList()
      }
    },
    // 获取下一页
    async getNextCommentPage() {
      this.pageNumber++
      await this.getCommentList()
    },
    // 获取评论内容html
    getCommentHtml(e) {
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = e.comment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink =
        Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`) : url
    },
    // 获取评论列表
    async getCommentList(reset = false) {
      const formObj = {
        moment_id: this.messageInfo.moment_id,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        offset: this.queryOffset
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const list = res.page.list.map((e, i) => {
          if (e.child_list) {
            return {
              ...e,
              show_child_list_offset: 0,
              query_child_list_offset: e.child_list.length,
              child_list_pack_up: true,
              child_list_page_number: 0
            }
          }
          return e
        })
        if (reset) {
          this.commentList = list
        } else {
          this.commentList = this.commentList.concat(list)
        }

        this.totalPage = res.page.totalPage
        this.pageNumber = res.page.pageNumber
        this.topMomentCount = res.page.totalRow + this.queryOffset
      }
    },
    // 获取下一页
    async getCommentNextPage() {
      if (this.commentLoading) {
        return
      }
      if (this.packUp) {
        this.packUp = false
        if (this.commentList.length - this.showOffset > 3) {
          return
        }
      }
      this.pageNumber++
      this.commentLoading = true
      await this.getCommentList()
      this.commentLoading = false
    },
    // 刷新列表
    async reloadCommentList() {
      if (this.commentLoading) {
        return
      }
      this.packUp = true
      this.pageNumber = 1
      this.totalPage = 2
      this.queryOffset = 0
      this.showOffset = 0
      // this.commentList = []
      this.commentLoading = true
      await this.getCommentList(true)
      this.commentLoading = false
    },
    // 显示删除按钮
    showCommentDelete(item) {
      this.showCommentDeleteId = item.comment_id
    },
    // 隐藏删除按钮
    hideDelete(index) {
      this.showCommentDeleteId = -1
    },
    // 删除评论
    async onDelete(index) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.adminDeleteComment, {
          comment_id: this.commentList[index].comment_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.reloadCommentList()
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 删除子级评论
    async onChildDelete(index, cIndex) {
      console.log(this.commentList)
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.adminDeleteComment, {
          comment_id: this.commentList[index].child_list[cIndex].comment_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.reloadCommentChildList(index)
        this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 处理头部事件
    headlerHeadChage(type) {
      this.$emit('changeMenu', type)
    },
    // 评论点赞
    async praise(commentId) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.praiseMomentComment, {
          comment_id: commentId
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        console.log(res.is_praise)
        return res.is_praise
      }
    },
    // 子级评论点赞
    async commentChildPraise(index, cIndex) {
      const info = this.commentList[index].child_list[cIndex]
      const flag = await this.praise(info.comment_id)
      flag ? info.praise_number++ : info.praise_number--
      this.$set(info, 'is_praise', flag ? 1 : 0)
    },
    // 打开详情
    openDetail() {
      this.$emit('openDetail')
    },
    // 处理回复移除
    handlerReplyBlur() {
      if (this.replyText === '') {
        this.placeholderText = '发表评论'
        this.replyCommentId = ''
        this.replyText = ''
      }
    },
    // 获取评论列表
    async getCommentChildList(index, reset = false) {
      const formObj = {
        moment_id: this.messageInfo.moment_id,
        tid: this.commentList[index].comment_id,
        pageNumber: this.commentList[index].child_list_page_number || 1,
        pageSize: 5,
        offset: this.commentList[index].query_child_list_offset || 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCommentChildPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.$set(this.commentList[index], 'child_list', res.page.list)
        } else {
          this.$set(
            this.commentList[index],
            'child_list',
            (this.commentList[index].child_list || []).concat(res.page.list)
          )
        }
        this.$set(
          this.commentList[index],
          'child_list_page_number',
          res.page.pageNumber
        )
        this.$set(
          this.commentList[index],
          'child_list_total_page',
          res.page.totalPage
        )
      }
    },
    // 获取下一页
    async getCommentChildNextPage(index) {
      if (this.childCommentLoading) {
        return
      }
      const info = this.commentList[index]
      if (info.child_list_pack_up) {
        this.$set(this.commentList[index], 'child_list_pack_up', false)
        if (info.child_list.length - info.show_child_list_offset > 3) {
          return
        }
      }
      this.$set(
        this.commentList[index],
        'child_list_page_number',
        (info.child_list_page_number || 0) + 1
      )
      this.childCommentLoading = true
      await this.getCommentChildList(index)
      this.childCommentLoading = false
    },
    // 刷新列表
    async reloadCommentChildList(index) {
      this.$set(this.commentList[index], 'child_list_page_number', 1)
      this.$set(this.commentList[index], 'child_list_total_page', 2)
      this.$set(this.commentList[index], 'query_child_list_offset', 0)
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      // this.$set(this.commentList[index], 'childList', [])
      this.childCommentLoading = true
      await this.getCommentChildList(index, true)
      this.childCommentLoading = false
    },
    // 打开下级评论列表
    openChildCommentList(index) {
      // 清空子列表展示偏移数量
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      if (this.commentList[index].child_list_pack_up == null) {
        this.getCommentChildList(index)
      }
      this.$set(this.commentList[index], 'child_list_pack_up', false)
    },
    // 收起下级评论列表
    closeChildCommentList(index) {
      this.$set(this.commentList[index], 'show_child_list_offset', 0)
      this.$set(this.commentList[index], 'child_list_pack_up', true)
    },
    // 点击收起
    clickPackUp() {
      this.packUp = true
      this.showOffset = 0
    },
    // 返回
    back() {
      this.$emit('back')
    },
    openEdit() {
      this.$emit('openEdit')
    },
    // 删除
    async deletePushInfo() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemMomentPush, {
          push_id: this.pushInfo.push_id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        })
        this.$emit('deleteRefresh')
      }
    },
    // 获取内同html
    getContenHtml(e) {
      // 提及列表
      const clerList = e.clerk_list.map((c) => {
        c.type = 1
        return c
      })
      // 话题列表
      const topicList = e.topic_list.map((t) => {
        t.type = 2
        return t
      })
      const list = clerList.concat(topicList).sort((a, b) => {
        return a.start_index - b.start_index
      })
      const content = e.moment_info.moment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name =
            e.type === 1
              ? '@' + (e.clerk_alias || e.clerk_name) + ' '
              : '#' + e.topic_name + '#' + ' '
          str +=
            content.substring(index, e.start_index) +
            `<a style=" color: #0080FF;font-weight:600"> ${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    }
  },
  mounted() {
    this.getPushDetail()
  }
}
</script>

<style lang="scss" scoped>
.push-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  overflow: scroll;

  .push-edit_header {
    position: fixed;
    width: calc(100% - 322px);
    height: 64px;
    padding: 0 50px 0 24px;
    z-index: 10;
    background-color: #fff;
    &::after {
      left: 0;
    }
    .el-button {
      width: 80px;

      &.button-delete {
        background-color: #f6f6f6;
        color: #999;
        border: none;
        &:hover {
          background-color: #ff5454;
          color: #fff;
        }
      }

      &.button-draft {
        border: none;
        color: #fff;
        background-color: #c0c0c0;
      }

      &.button-release {
        border: none;
        color: #fff;
        background-color: $main-color;
      }
    }
  }

  .push-container {
    padding-top: 64px;
    width: 700px;
    margin: 6px auto 0;
    .push-info-header {
      padding: 34px 43px;
      &::after {
        left: 0;
      }

      span {
        color: #bbb;
      }
    }

    .push-article {
      padding: 34px 43px;
      .image-list-pop::v-deep .image-item {
        width: calc((493px - 3 * -26px) / 3) !important;
        height: calc((493px - 3 * -26px) / 3) !important;
      }
      .article-title {
        margin-bottom: 12px;
      }

      .article-content {
        color: #666;
        margin-bottom: 12px;
      }

      .topic-comment {
        position: relative;
        padding: 0 12px;
        font-size: 14px;
        line-height: 24px;
        color: #333333;

        &::after {
          position: absolute;
          content: '';
          height: 20px;
          width: 4px;
          top: 2px;
          left: 0;
          background-color: #0080ff;
        }

        .title-bar {
          margin-bottom: 24px;
        }

        .comment-list {
          .comment-item {
            .comment-content {
              cursor: auto;
              margin-left: 48px;
              white-space: inherit;
              .comment-pack-load-bar {
                padding: 5px 0;
                height: 32px;
                line-height: 32px;
                margin: 10px 0;

                .pack-up {
                  display: inline-block;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  ::v-deep .emoji-img{
    width: 24px;
    position: relative;
    top: -6px;
  }
}
.comment-hide-appenHtml ::v-deep {
  .text-warp a {
    cursor: text !important;
  }
}
</style>
