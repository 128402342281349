<template>
  <div class="push-content">
    <div class="circle-push-header flex flex-x-between">
      <el-form inline size="small">
        <el-form-item>
          <el-input
            prefix-icon="el-icon-search"
            v-model="searchKey"
            @keyup.enter.native="enterKey"
            @blur="refreshMomentPushList"
            placeholder="输入标题/动态名称/账号关键字查询"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            type="daterange"
            range-separator="-"
            v-model="pushTime"
            @change="getMomentPush($event)"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="pushStatus"
            placeholder="请选择推送状态"
            @change="refreshMomentPushList"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="待推送" value="2"></el-option>
            <el-option label="已推送" value="3"></el-option>
            <el-option label="草稿" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="danger" size="small" @click="batchDelete"
          >删除</el-button
        >
        <el-button type="primary" size="small" @click="add()" v-if="perInfo.add"
          >创建推送</el-button
        >
      </div>
    </div>
    <el-table
      ref="multipleTable"
      class="table"
      :data="tableData"
      tooltip-effect="dark"
      :height="'calc(100vh - 300px)'"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="moment_title" label="标题" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="push_time" label="推送时间" width="180">
      </el-table-column>
      <el-table-column prop="creator" label="推送账号" width="120">
        <template slot-scope="scope">
          {{ scope.row.create_clerk_alias || scope.row.create_clerk_name }}
        </template>
      </el-table-column>
      <el-table-column label="推送状态" width="120">
        <template slot-scope="scope">
          <div
            class="status-tag"
            :style="{ backgroundColor: setStatusColor(scope.row.push_status) }"
          >
            {{ scope.row.push_status | setStatus }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-dropdown
            trigger="click"
            @command="handleCommand($event, scope.row)"
          >
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="perInfo.edit" command="edit"
                >编辑</el-dropdown-item
              >
              <el-dropdown-item command="info">查看详情</el-dropdown-item>
              <el-dropdown-item
                style="color: red"
                command="delete"
                v-if="perInfo.delete"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="
        width: calc(100%);
        position: absolute;
        bottom: 80px;
        text-align: center;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="totalPage <= 1"
        :current-page.sync="pageNumber"
        :page-size="11"
        @current-change="handleCurrentChange"
        :total="totalRow"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: '',
      tableData: [],
      pageSize: 11,
      pageNumber: 1,
      totalRow: 1,
      totalPage: 1,
      pushStatus: '',
      pushTime: null,
      searchKey: '',
      multipleSelection: []
    }
  },
  computed: {
    perInfo() {
      return {
        add: this.$hasPer(58),
        edit: this.$hasPer(59),
        delete: this.$hasPer(60)
      }
    }
  },
  filters: {
    setStatus(val) {
      let statusText = ''
      switch (val) {
        case 2:
          statusText = '待推送'
          break
        case 3:
          statusText = '已推送'
          break
        case 1:
          statusText = '草稿'
          break
      }
      return statusText
    }
  },
  methods: {
    // 通知列表
    async getList(rescet = false) {
      const obj = {
        push_status: this.pushStatus,
        pageNumber: rescet ? this.pageNumber : ++this.pageNumber,
        pageSize: this.pageSize,
        is_del: 0,
        search_key: this.searchKey
      }
      if (this.pushTime !== null) {
        obj.start_time = this.pushTime[0]
        obj.end_time = this.pushTime[1]
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemMomentPushPage, obj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.totalPage = res.page.totalPage
        this.totalRow = res.page.totalRow
        if (rescet) {
          this.tableData = res.page.list
        } else {
          this.tableData = this.tableData.concat(res.page.list)
        }
      }
    },
    // 刷新记录列表
    refreshMomentPushList() {
      this.pageNumber = 1
      this.getList(true)
    },
    setStatusColor(val) {
      let statusColor = ''
      switch (val) {
        case 2:
          statusColor = '#37D3CA'
          break
        case 3:
          statusColor = '#46B1FF'
          break
        case 1:
          statusColor = '#DDDDDD'
          break
      }
      return statusColor
    },
    handleCommand(e, row) {
      switch (e) {
        case 'edit':
          this.add(row.push_id)
          break
        case 'info':
          this.openDetail(row.push_id)
          break
        case 'delete':
          this.deletePushuInfo(row.push_id)
          break
      }
    },
    handleSelectionChange(val) {
      const idList = []
      val.map((e) => {
        idList.push(e.push_id)
      })
      this.multipleSelection = idList
    },
    // 回车搜索
    enterKey() {
      event.target.blur()
    },
    getMomentPush(e) {
      this.refreshMomentPushList()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getList(true)
    },
    add(currentId = -1) {
      this.$emit('add', currentId)
    },
    openDetail(currentId = -1) {
      this.$emit('openDetail', currentId)
    },
    // 删除
    async deletePushuInfo(id) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemMomentPush, {
          push_id: id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        })
        this.$emit('deleteRefresh')
      }
    },
    // 批量删除
    async batchDelete() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择圈子推送')
        return
      }
      this.deletePushuInfo(this.multipleSelection.join(','))
    }
  },
  mounted() {
    this.refreshMomentPushList()
  }
}
</script>

<style lang="scss" scoped>
.push-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;

  .el-input {
    width: 260px;
  }

  // ::v-deep .el-input--prefix .el-input__inner {
  // border: 1px solid #dcdfe6 !important;
  // }

  .status-tag {
    width: 56px;
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  .el-dropdown {
    cursor: pointer;
    color: $main-color;
    height: 28px;
    line-height: 28px;
    width: 74px;
    text-align: center;
    border-radius: 4px;
    &:hover {
      background: rgba(0, 128, 255, 0.1);
    }
  }
}
.table.el-table::v-deep {
  .el-checkbox__inner {
    border: 1px solid #8c8c8c;
  }
  .el-table-column--selection .cell {
    padding-left: 10px;
  }
  td {
    border-bottom: 0;
  }
  .is-leaf {
    border: 0;
  }
  &::before {
    height: 0;
  }
}
</style>
