<!--
 * @Date: 2021-06-22 15:17:32
 * @LastEditTime: 2021-07-21 14:55:54
 * @Description: 任务记录
 * @Author: Betty
 * @LastEditors: Betty
-->
<template>
  <!-- 记录数据 -->
  <div class="record-item flex flex-y-start">
    <div class="img-box">
      <avatar
        :userInfo="
          logData && {
            clerk_id: logData.create_clerk_id,
            clerk_alias: logData.create_clerk_alias,
            clerk_name: logData.create_clerk_name,
            clerk_avatar: logData.create_clerk_avatar
          }
        "
        :size="40"
        :distanceRight="0"
      ></avatar>
    </div>
    <div class="flex1">
      <!-- 名字 -->
      <p class="name">
        {{
          logData && (logData.create_clerk_alias || logData.create_clerk_name)
        }}
      </p>
      <!-- 时间 -->
      <p class="time">{{ $util.getTimeStr(logData.create_time) }}</p>
      <!-- 类型为1时，创建目标 -->
      <template v-if="logData.change_type === 1">
        <!-- 文字 -->
        <p class="record-text">创建了本目标</p>
      </template>
      <!-- 类型为2时，修改目标名称 -->
      <template v-if="logData.change_type === 2">
        <!-- 文字 -->
        <p class="record-text">修改了目标名称</p>
        <!-- 任务名称改变 -->
        <div class="change-box">
          <div class="grey-text long">{{ logData.before_content }}</div>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <div class="grey-text long">{{ logData.after_content }}</div>
        </div>
      </template>
      <!-- 类型为3时，修改目标状态 -->
      <template v-if="logData.change_type === 3">
        <!-- 文字 -->
        <p class="record-text">修改了状态</p>
        <!-- 状态改变 -->
        <div class="change-box flex flex-y-center">
          <div
            class="status"
            :class="[getStatusInfo(logData.before_status).className]"
          >
            {{ getStatusInfo(logData.before_status).text }}
          </div>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <div
            class="status"
            :class="[getStatusInfo(logData.after_status).className]"
          >
            {{ getStatusInfo(logData.after_status).text }}
          </div>
        </div>
        <div class="grey-text long" v-if="logData.after_status == 4">
          {{ logData.after_content }}
        </div>
      </template>
      <!-- 类型为4时，修改任务优先级 -->
      <template v-if="logData.change_type === 4">
        <!-- 文字 -->
        <p class="record-text">修改了优先级</p>
        <!-- 优先级改变 -->
        <div class="change-box flex flex-y-center">
          <div
            class="priority-tag"
            v-if="getPriorityInfo(logData.before_status)"
            :class="[getPriorityInfo(logData.before_status).color]"
          >
            {{ getPriorityInfo(logData.before_status).text }}
          </div>
          <p class="grey-text" v-else>无</p>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <div
            class="priority-tag"
            :class="[getPriorityInfo(logData.after_status).color]"
          >
            {{ getPriorityInfo(logData.after_status).text }}
          </div>
        </div>
      </template>
      <!-- 类型为5时，修改进度值 -->
      <template v-if="logData.change_type === 5">
        <!-- 文字 -->
        <p class="record-text">修改了进度值</p>
        <!-- 进度改变 -->
        <div class="change-box flex flex-y-center">
          <p class="grey-text">{{ logData.before_content }}%</p>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <p class="grey-text">{{ logData.after_content }}%</p>
        </div>
      </template>
      <!-- 类型为6时，修改进度状态 -->
      <template v-if="logData.change_type === 6">
        <!-- 文字 -->
        <p class="record-text">修改了进度状态</p>
        <!-- 状态改变 -->
        <div class="change-box flex flex-y-center">
          <p class="grey-text">
            {{ logData.before_status === 1 ? '正常' : '已延期' }}
          </p>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <p class="grey-text">
            {{ logData.after_status === 1 ? '正常' : '已延期' }}
          </p>
        </div>
      </template>
      <!-- 类型为7时，修改开始时间 -->
      <template v-if="logData.change_type === 7">
        <!-- 文字 -->
        <p class="record-text">修改开始时间</p>
        <!-- 状态改变 -->
        <div class="change-box flex flex-y-center">
          <p class="grey-text">
            {{
              (logData.before_time && logData.before_time.slice(0, 10)) || '无'
            }}
          </p>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <p class="grey-text">
            {{ logData.after_time && logData.after_time.slice(0, 10) }}
          </p>
        </div>
      </template>
      <!-- 类型为8时，修改结束时间 -->
      <template v-if="logData.change_type === 8">
        <!-- 文字 -->
        <p class="record-text">修改结束时间</p>
        <!-- 状态改变 -->
        <div class="change-box flex flex-y-center">
          <p class="grey-text">
            {{
              (logData.before_time && logData.before_time.slice(0, 10)) || '无'
            }}
          </p>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <p class="grey-text">
            {{ logData.after_time && logData.after_time.slice(0, 10) }}
          </p>
        </div>
      </template>
      <!-- 类型为9时，上传附件 -->
      <template v-if="logData.change_type === 9">
        <!-- 文字 -->
        <p class="record-text">上传了附件</p>
        <!-- 灰字 -->
        <p class="grey-text">
          {{ logData.file_original_name }}
        </p>
      </template>
      <!-- 类型为10时，删除附件 -->
      <template v-if="logData.change_type === 10">
        <!-- 文字 -->
        <p class="record-text">删除了附件</p>
        <!-- 灰字 -->
        <p class="grey-text">
          {{ logData.file_original_name }}
        </p>
      </template>
      <!-- 类型为11时，修改了目标描述 -->
      <template v-if="logData.change_type === 11">
        <!-- 文字 -->
        <p class="record-text">修改了目标描述</p>
      </template>
      <!-- 类型为12时，修改工作量 -->
      <template v-if="logData.change_type === 12">
        <!-- 文字 -->
        <p class="record-text">修改了工作量</p>
        <!-- 工作量的改变 -->
        <div class="change-box">
          <div class="grey-text long">{{ logData.before_content || '无' }}</div>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <div class="grey-text long">{{ logData.after_content }}</div>
        </div>
      </template>
      <!-- 类型为13时，修改目标类型 -->
      <template v-if="logData.change_type === 13">
        <!-- 文字 -->
        <p class="record-text">修改了目标类型</p>
        <!-- 优先级改变 -->
        <div class="change-box flex flex-y-center">
          <div
            class="target-type-box"
            v-if="logData.before_status > 1"
            :class="[getTargetTypeInfo(logData.before_status).color]"
          >
            {{ getTargetTypeInfo(logData.before_status).text }}
          </div>
          <p class="grey-text" v-else>无</p>
          <!-- 箭头 -->
          <span class="iconfont icon-Arrow-Right arrow"></span>
          <div
            class="target-type-box"
            :class="[getTargetTypeInfo(logData.after_status).color]"
          >
            {{ getTargetTypeInfo(logData.after_status).text }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Avatar from '../common/Avatar.vue'
import { mapState } from 'vuex'
export default {
  name: 'task-log-item',
  components: {
    Avatar
  },
  computed: {
    // 获取优先级和目标类型
    ...mapState(['priorityList', 'targetTypeList']),
    // 获取任务状态的文字和颜色
    getStatusInfo() {
      return function (status) {
        return this.statusList.find((item) => status === item.value)
      }
    },
    // 获取优先级的文字和颜色
    getPriorityInfo() {
      return function (priority) {
        return this.priorityList.find((item) => priority === item.value)
      }
    },
    // 获取当前目标的类型对应的文字和颜色
    getTargetTypeInfo() {
      return function (type) {
        return this.targetTypeList.find((item) => type === item.value)
      }
    }
  },
  props: {
    // 记录数据
    logData: {
      type: Object
    }
  },
  data() {
    return {
      // 状态的颜色和文字
      statusList: [
        {
          value: 1,
          text: '待启动',
          className: 'no-start'
        },
        {
          value: 2,
          text: '进行中',
          className: 'in-progress'
        },
        {
          value: 3,
          text: '已完成',
          className: 'has-finished'
        },
        {
          value: 4,
          text: '已终止',
          className: 'end'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/priority_tags.scss';
@import '@/assets/scss/log_item_style.scss';
</style>
