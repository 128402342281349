<!--
 * @Author: hzh
 * @Date: 2021-08-05 10:41:58
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-25 17:45:38
-->
<template>
  <div
    class="message-dialog"
    :class="{ mask: mask }"
    @mousemove.stop
    v-if="visible"
    @click="$emit('close', $event)"
  >
    <div class="dialog-main" @click.stop="$emit('click', $event)">
      <div class="dialog-header">
        <i class="icon-decline" @click="$emit('close', $event)"></i>
      </div>
      <div style="poistion: relative; height: 50px;">
        <slot name="header"></slot>
      </div>
      <div class="dialog-container">
        <slot name="content"></slot>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mask: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.message-dialog {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  width: 640px;
  height: 100vh;
  overflow: auto;

  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

  &.mask {
    width: 100vw;
    box-shadow: initial;
  }

  .dialog-main {
    position: relative;
    float: right;
    width: 584px;
    min-height: 100vh;
    background-color: #fff;
    padding: 0 28px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

    .dialog-header {
      position: fixed;
      padding: 18px 0;
      text-align: right;
      width: 100%;
      height: 36px;
      z-index: 99;
      top: 0;
      left: -28px;

      i {
        padding: 6px;
        color: $main-color;
        font-weight: 400;
        font-size: 14px;
        background: rgba(0, 128, 255, 0.1);
        border-radius: 5px;
      }
    }

    .dialog-container {
      position: relative;
      max-height: calc(100vh - 50px);
      overflow-y: scroll;
    }
  }

  .icon-decline {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .message-dialog {
    width: 100vw;
    .dialog-main {
      width: calc(100vw - 56px);
      .dialog-container {
        width: 100%;
      }
    }
  }
}
</style>
