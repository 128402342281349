<!--
 * @Author: Betty
 * @Date: 2021-07-12 20:08:58
 * @LastEditors: Betty
 * @LastEditTime: 2021-07-18 13:59:12
 * @Description: 会议室详情
-->
<template>
  <div>
    <div class="modal-bg" v-if="isShow" @click="closeModal"></div>
    <!-- 白色弹窗 -->
    <transition name="meetingRoom">
      <div class="create-room-box" v-if="isShow">
        <!-- 头部，标题和关闭按钮 -->
        <div class="flex flex-y-center flex-x-between box-header">
          <h6 class="box-title">会议室详情</h6>
          <button type="button" class="close-btn" @click="closeModal">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
          </button>
        </div>
        <!-- 主体部分 -->
        <div class="box-main">
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label>会议室名称</label>
            <p class="value">{{ currentRoomName }}</p>
          </div>
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label>容量</label>
            <p class="value">{{ roomVolume }}</p>
          </div>
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label>城市</label>
            <p class="value">{{ cityResult }}</p>
          </div>
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label>详细位置</label>
            <p class="value">{{ detailAddress }}</p>
          </div>
          <!-- 设备信息 -->
          <div class="create-box-line">
            <label>设备信息</label>
            <div class="device-info-box value">
              <div class="flex flex-wrap">
                <!-- 里面的每一个设备 -->
                <!-- <div
                  class="device-item flex flex-y-center no-handle"
                  v-for="(device, index) in deviceList"
                  :key="index"
                >
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="
                        deviceIdList.includes(device.device_id)
                          ? '#icon-TickSquare_1'
                          : '#icon-TickSquare'
                      "
                    ></use>
                  </svg>
                </div> -->
                <p class="device-name">{{ deviceNameList.join(' / ') }}</p>
              </div>
            </div>
          </div>
          <!-- 状态 -->
          <div class="create-box-line">
            <label>状态</label>
            <div class="flex flex-y-center">
              <!-- 里面的每一个状态 -->
              <div
                class="state-item flex flex-y-center normal no-handle"
                :class="{ active: roomState === 1 }"
              >
                <svg class="icon" aria-hidden="true">
                  <use
                    :xlink:href="
                      roomState === 1
                        ? '#icon-a-confirmfill2'
                        : '#icon-a-confirmfill21'
                    "
                  ></use>
                </svg>
                <span class="state-text">正常</span>
              </div>
              <!-- 里面的每一个状态 -->
              <div
                class="state-item flex flex-y-center disable no-handle"
                :class="{ active: roomState === 0 }"
              >
                <svg class="icon" aria-hidden="true">
                  <use
                    :xlink:href="
                      roomState === 0
                        ? '#icon-a-confirmfill3'
                        : '#icon-a-confirmfill11'
                    "
                  ></use>
                </svg>
                <span class="state-text">禁用</span>
              </div>
            </div>
          </div>
          <!-- 备注 -->
          <div class="create-box-line">
            <label>备注</label>
            <p class="value remark-text">{{ roomRemark }}</p>
          </div>
          <!-- 保存和取消按钮 -->
          <!-- 最后一行，关闭按钮 -->
          <div class="button-line flex flex-y-center pc">
            <button class="meeting-btn close" type="button" @click="closeModal">
              关闭
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'meeting-room-detail',
  props: {
    // 是否显示详情弹窗
    isShow: {
      type: Boolean,
      default: false
    },
    // 当前查看的会议室id
    roomId: {
      type: Number,
      default: -1
    },
    // 设备列表
    deviceList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 会议室名称
      currentRoomName: '',
      // 容量
      roomVolume: 0,
      // 城市
      cityResult: '',
      // 详细位置
      detailAddress: '',
      // 当前会议室现有的设备id集合
      deviceIdList: [],
      // 会议室状态
      roomState: 1,
      // 备注
      roomRemark: '',
      // 详情
      roomDetail: null
    }
  },
  computed: {
    deviceNameList() {
      return this.deviceList.map((e) => {
        return e.device_name
      })
    }
  },
  mounted() {
    this.getRoomDetail()
  },
  watch: {
    // id改变了就重新获取数据
    roomId: {
      immediate: true,
      handler(val) {
        if (this.isShow) {
          if (val !== -1) {
            this.getRoomDetail()
          }
        }
      }
    }
  },
  methods: {
    // 关闭弹窗
    closeModal() {
      this.$emit('close-modal')
    },
    // 请求会议室详情
    async getRoomDetail() {
      if (this.roomId !== -1) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.getMeetingRoomDetail, {
            room_id: this.roomId
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.roomDetail = res.info
          // 初始化
          // 名字
          this.currentRoomName = this.roomDetail.room_name
          // 容量
          this.roomVolume = this.roomDetail.room_galleryful
          // 省份和城市
          this.cityResult = `${this.roomDetail.province_name}-${this.roomDetail.city_name}`
          // 地址
          this.detailAddress = this.roomDetail.room_address
          // 状态
          this.roomState = this.roomDetail.is_enable
          // 设备信息
          this.deviceIdList = this.roomDetail.device_list.map(
            (item) => item.device_id
          )
          // 备注
          this.roomRemark = this.roomDetail.remark
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/meeting_room_modal.scss';
.box-main .create-box-line .device-info-box.value {
  padding-left: 0;
}

@media screen and (min-width: 767px) {
  .wap {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
  .create-room-box .box-main label {
    color: #989898;
  }
  .create-room-box .box-main .state-item.normal.active {
    background: rgb(17 82 255 / 0%);
  }
  .create-room-box .box-main .state-item.normal.active .state-text {
    color: rgb(17, 82, 255);
  }
  .create-room-box .box-main .state-item {
    background: rgb(17 82 255 / 0%);
  }
  .create-room-box .box-main .state-item.disable.active {
    background: rgb(17 82 255 / 0%);
  }
  .create-room-box .box-header {
    height: 44px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
  }
}
</style>
