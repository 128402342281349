<template>
  <div class="notic-page">
    <div class="notic-header flex flex-x-between">
      <el-form inline size="small">
        <el-form-item>
          <el-input
            prefix-icon="el-icon-search"
            v-model="searchKey"
            @keyup.enter.native="enterKey"
            @blur="refreshNoticeList"
            placeholder="输入通知名称/发布人关键字查询"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            type="daterange"
            range-separator="-"
            v-model="noticeTime"
            @change="getNoticeTime($event)"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="noticeStatus"
            @change="refreshNoticeList"
            placeholder="请选择发布状态"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未发布" value="1"></el-option>
            <el-option label="待发布" value="2"></el-option>
            <el-option label="发布中" value="3"></el-option>
            <el-option label="已结束" value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="danger" size="small" @click="openDeleteDialog"
          >删除</el-button
        >
        <el-button type="primary" size="small" @click="addNotice"
          >添加通知公告</el-button
        >
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      class="table"
      tooltip-effect="dark"
      style="width: 100%"
      :height="'calc(100vh - 300px)'"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="notice_name"
        min-width="800"
        label="标题"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="release_time" label="发布时间" width="300">
      </el-table-column>
      <el-table-column label="发布人" width="180">
        <template slot-scope="scope">
          {{ scope.row.create_clerk_alias || scope.row.release_clerk_name }}
        </template>
      </el-table-column>
      <el-table-column label="发布状态" width="180">
        <template #default="{ row }">
          <div
            class="status-tag"
            :style="{
              backgroundColor: setStatusColor(row.notice_status)
            }"
          >
            {{ row.notice_status | setStatus }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-dropdown
            trigger="click"
            @command="handleCommand($event, scope.row)"
          >
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="
                  scope.row.notice_status !== 4 && scope.row.notice_status !== 3
                "
                command="edit"
                >编辑</el-dropdown-item
              >
              <el-dropdown-item command="info">查看详情</el-dropdown-item>
              <el-dropdown-item style="color: red" command="delete"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="
        width: calc(100% - 60px);
        position: absolute;
        bottom: 50px;
        text-align: center;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="totalPage <= 1"
        :current-page.sync="pageNumber"
        :page-size="11"
        @current-change="handleCurrentChange"
        :total="totalRow"
      >
      </el-pagination>
    </div>
    <!-- 通知创建/编辑 -->
    <notic-edit
      v-if="currentEditShow"
      @back="closeNotice"
      @deleteBack="deleteBack"
      :describe="describe"
      :title="title"
      :noticeId="noticeId"
      :noticeTime="proTime"
    ></notic-edit>
    <!-- 通知详情 -->
    <notic-info
      @back="closeNotice"
      @deleteBack="deleteBack"
      :noticeInfo="noticeInfo"
      @edit="editNotice"
      :noticeId="noticeId"
      v-if="currentDetailShow"
    ></notic-info>
    <!-- 删除 -->
    <el-dialog title="删除通知" :visible.sync="deleteVisible" width="300px">
      <span>确定要将{{ multipleSelection.length }}条通知删除吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NoticEdit from '@/components/system/NoticEdit.vue'
import NoticInfo from '@/components/system/NoticInfo.vue'
export default {
  components: { NoticEdit, NoticInfo },
  data() {
    return {
      pageSize: 11,
      pageNumber: 1,
      noticeStatus: '',
      noticeTime: null,
      searchKey: '',
      tableData: [],
      totalRow: 1,
      totalPage: 1,
      multipleSelection: [], // 表格选中列
      currentEditShow: false,
      noticeId: -1,
      describe: '',
      title: '',
      proTime: [],
      // 详情开关
      currentDetailShow: false,
      // 详情信息
      noticeInfo: {},
      deleteVisible: false
    }
  },
  filters: {
    setStatus(val) {
      let statusText = ''
      switch (val) {
        case 1:
          statusText = '未发布'
          break
        case 2:
          statusText = '待发布'
          break
        case 3:
          statusText = '发布中'
          break
        case 4:
          statusText = '已结束'
          break
      }
      return statusText
    }
  },
  methods: {
    // 通知列表
    async getList(rescet = false) {
      const obj = {
        notice_status: this.noticeStatus,
        pageNumber: rescet ? this.pageNumber : ++this.pageNumber,
        pageSize: this.pageSize,
        search_key: this.searchKey,
        is_del: 0
      }
      if (this.noticeTime !== null) {
        obj.start_time = this.noticeTime[0]
        obj.end_time = this.noticeTime[1]
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemNoticePage, obj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.totalPage = res.page.totalPage
        this.totalRow = res.page.totalRow
        if (rescet) {
          this.tableData = res.page.list
        } else {
          this.tableData = this.tableData.concat(res.page.list)
        }
      }
    },
    // 刷新记录列表
    refreshNoticeList() {
      this.pageNumber = 1
      this.getList(true)
    },
    // 回车搜索
    enterKey() {
      event.target.blur()
    },
    setStatusColor(val) {
      let statusColor = ''
      switch (val) {
        case 1:
          statusColor = '#e6a23c'
          break
        case 2:
          statusColor = '#37D3CA'
          break
        case 3:
          statusColor = '#46B1FF'
          break
        case 4:
          statusColor = '#DDDDDD'
          break
      }
      return statusColor
    },
    handleCommand(e, row) {
      switch (e) {
        // 修改
        case 'edit': {
          this.noticeId = row.notice_id
          this.describe = row.notice_content_html
          this.title = row.notice_name
          // 开始时间与结束时间
          this.proTime = [row.start_date, row.end_date]
          this.currentEditShow = true
          break
        }
        case 'delete':
          this.deleteNotice(row.notice_id)
          break
        case 'info':
          this.noticeId = row.notice_id
          this.describe = row.notice_content_html
          this.title = row.notice_name
          // 开始时间与结束时间
          this.proTime = [row.start_date, row.end_date]
          this.noticeInfo = row
          this.currentDetailShow = true
          break
      }
    },
    handleSelectionChange(val) {
      const idList = []
      val.map((e) => {
        idList.push(e.notice_id)
      })
      this.multipleSelection = idList
    },
    getNoticeTime(e) {
      this.refreshNoticeList()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getList(true)
    },
    // 添加通知
    addNotice() {
      this.noticeId = -1
      this.describe = ''
      this.title = ''
      this.proTime = null
      this.currentEditShow = true
    },
    // 回调
    closeNotice(flag) {
      this.currentEditShow = false
      this.currentDetailShow = false
      if (flag) {
        this.getList(true)
      }
    },
    // 删除回调刷列表
    deleteBack() {
      this.currentEditShow = false
      this.currentDetailShow = false
      this.refreshNoticeList()
    },
    // 删除通知
    async deleteNotice(id) {
      if (id === '') {
        this.$message.error('请选择通知')
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteSystemNotice, {
          notice_id: id
        })
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        })
        this.refreshNoticeList()
      }
    },
    // 详情编辑回调
    editNotice() {
      this.currentDetailShow = false
      this.currentEditShow = true
    },
    // 批量删除
    batchDelete() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择通知')
        return
      }
      this.deleteNotice(this.multipleSelection.join(','))
      this.deleteVisible = false
    },
    openDeleteDialog() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择通知')
        return
      }
      this.deleteVisible = true
    }
  },
  mounted() {
    this.refreshNoticeList()
  }
}
</script>

<style lang="scss" scoped>
.notic-page {
  position: relative;
  padding: 24px 30px;
  box-sizing: border-box;
  // width: calc(100% - 60px);
  height: 100%;
  overflow: scroll;

  .el-button {
    // background-color: $main-color;
  }

  .el-input {
    width: 260px;
  }

  ::v-deep .el-input--prefix .el-input__inner {
    // border: 1px solid #dcdfe6 !important;
  }

  .status-tag {
    width: 56px;
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  .el-dropdown {
    cursor: pointer;
    color: $main-color;
    height: 28px;
    line-height: 28px;
    width: 74px;
    text-align: center;
    border-radius: 4px;
    &:hover {
      background: rgba(0, 128, 255, 0.1);
    }
  }
}
.table::v-deep {
  .el-checkbox__inner {
    border: 1px solid #8c8c8c;
  }
  .el-table-column--selection .cell {
    padding-left: 10px;
  }
  td {
    border-bottom: 0;
  }
  .is-leaf {
    border: 0;
  }
  &::before {
    height: 0;
  }
}
</style>
