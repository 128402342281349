<template>
  <div class="cricle-pop">
    <!-- 跳转圈子弹窗 -->
    <el-popover
      placement="right-start"
      trigger="hover"
      popper-class="cricle-pop-box"
      @show="show"
      @hide="hide"
      @after-leave="afterLeave"
    >
      <template #default>
        <div
          class="cricle-pop-content flex flex-y-positive flex-y-between"
          element-loading-background="#333"
          v-loading="loading"
        >
          <div class="flex flex-y-center flex-x-center">
            <img :src="info.clerk_avatar | httpToHttps" alt="" />
            <div class="name-cricle flex flex-y-positive flex-x-between">
              <p class="namec e">
                {{ info.clerk_alias || info.clerk_name }}
              </p>
              <span>动态 {{ count }}</span>
            </div>
          </div>
          <button class="cricle-btn" @click.stop="toCricle">
            查看TA的圈子
          </button>
        </div>
      </template>
      <template #reference>
        <slot></slot>
      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'cricle-pop',
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      count: 0,
      loading: true,
      timer: null
    }
  },
  computed: {},
  mounted() {},
  methods: {
    show() {
      if (!this.loading) {
        return
      }
      if (this.timer != null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.loading = false
        this.getCount()
      }, 300)
    },
    hide() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = null
    },
    afterLeave() {},
    // 获取统计
    async getCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentCount, {
          clerk_id: this.info.clerk_id
        })
      )
      if (err) {
      } else if (res.state === 'ok') {
        this.count = res.count
      }
    },
    toCricle() {
      this.$router.push({
        name: 'memberHome',
        query: {
          clerk_id: this.info.clerk_id
        }
      })
    }
  }
}
</script>

<style lang="scss">
.el-popover.cricle-pop-box {
  background-color: #333;
  box-shadow: 0 0 3px 1px #999;
  border: 0;
  padding: 0;
  .popper__arrow::after {
    border-right-color: #333 !important;
  }
  .cricle-pop-content {
    width: 190px;
    height: 116px;
    padding: 15px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: block;
      border: 2px solid #fff;
    }
    .name-cricle {
      height: 50px;
      margin-left: 16px;
      .namec {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        text-align: left;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #ffffff;
      }
    }
    .cricle-btn {
      width: 135px;
      height: 35px;
      background: #0080ff;
      border-radius: 28px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      margin: 20px auto 0 auto;
      cursor: pointer;
    }
  }
}
</style>
