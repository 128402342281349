<!--
 * @Author: hzh
 * @Date: 2021-09-07 13:59:45
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-09 16:36:28
-->
<template>
  <!-- 编辑弹窗 -->
  <div class="batch-operation flex flex-y-center flex-x-between">
    <span>已选择{{ number }}项</span>
    <div class="button-box flex flex-y-center">
      <el-button type="primary" @click="$emit('move')" v-if="showMove"
        >批量移动</el-button
      >
      <el-button type="danger" @click="$emit('delete')" v-if="showDelete"
        >批量删除</el-button
      >
      <el-button @click="$emit('cancel')">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'batch-operation-pop',
  components: {},
  props: {
    // 数量
    number: {
      type: Number,
      default: 0
    },
    // 展示删除
    showDelete: {
      type: Boolean,
      default: true
    },
    // 展示移动
    showMove: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.batch-operation {
  position: absolute;
  left: 33px;
  background: white;
  bottom: 45px;
  z-index: 50;
  width: 400px;
  height: 60px;
  box-shadow: 0 12px 16px 0 rgb(0 0 0 / 12%);
  padding: 12px 18px;
  border-radius: 8px;
  box-sizing: border-box;
}
.button-box::v-deep .el-button.el-button--default {
  margin-left: 10px;
  width: 64px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  font-size: 14px;
  color: #999999;
  &:hover {
    color: #0080ff;
  }
}
.button-box::v-deep .el-button.el-button--danger {
  width: 80px;
  height: 35px;
  padding: 0;
  span {
    line-height: 35px;
  }
}
.button-box::v-deep .el-button.el-button--primary {
  width: 80px;
  height: 35px;
  padding: 0;
  span {
    line-height: 35px;
  }
}
</style>
