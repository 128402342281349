<!--
 * @Author: Betty
 * @Date: 2021-07-30 16:40:55
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-03 17:58:50
 * @Description: 消息提醒的弹窗
-->
<template>
  <!-- 弹窗 -->
  <div class="message-remind-box" v-show="isShow">
    <!-- 头部 -->
    <div class="message-remind-header">
      <p class="message-remind-title">消息通知</p>
    </div>
    <!-- 主要部分 -->
    <div class="message-remind-body">
      <!-- 暂无通知 -->
      <div
        class="no-message flex flex-y-positive flex-y-center flex-x-center"
        v-if="messageList.length === 0"
      >
        <span class="iconfont icon-Notification_1 no-message-icon"></span>
        <p class="no-message-text">暂无新消息通知~</p>
      </div>
      <template v-else>
        <!-- 每一条提醒 -->
        <div
          class="message-item flex flex-y-center"
          v-for="(message, index) in messageList"
          :key="index"
          @click="showDetail(message, index)"
        >
          <div class="flex1 message-item-content flex flex-y-center">
            <!-- 左边，文字 -->
            <p class="e2 message-text">
              <span
                class="message-type"
                :class="[types[message.object_type - 1].color]"
                >【{{ types[message.object_type - 1].text }}】</span
              >
              {{ message.object_name }}
            </p>
          </div>
          <!-- 右边，数字 -->
          <div class="num-box flex flex-y-center flex-x-center">
            <div class="num">{{ message.comment_number }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'message-remind-box',
  props: {
    // 消息提醒数据
    messageList: {
      type: Array,
      default: () => []
    },
    // 是否显示弹窗
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 不同类型的颜色和文字的对应关系
      types: [
        {
          color: 'orange',
          text: '任务'
        },
        {
          color: 'blue',
          text: '目标'
        },
        {
          color: 'red',
          text: '系统'
        }
      ]
    }
  },
  methods: {
    // 准备展示详情
    showDetail(message, index) {
      this.$emit('to-detail', {
        message,
        index
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 消息提醒的弹窗
.message-remind-box {
  position: absolute;
  width: 460px;
  right: -20px;
  top: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(153, 153, 153, 0.25);
  border-radius: 4px;
  // 弹窗头部
  .message-remind-header {
    height: 44px;
    padding: 0 26px;
    border-bottom: 1px solid #eee;
    // 头部文字
    .message-remind-title {
      font-size: 14px;
      line-height: 44px;
      color: #333;
    }
  }
  // 弹窗主要部分
  .message-remind-body {
    height: 264px;
    overflow-y: auto;
    // 没有消息
    .no-message {
      width: 100%;
      height: 100%;
    }
    // 没有提醒的图标
    .no-message-icon {
      font-size: 44px;
      color: #ddd;
    }
    // 没有提醒的文字
    .no-message-text {
      margin-top: 16px;
      font-size: 14px;
      color: #bbb;
    }
  }
}

// 数据的样式
.message-item {
  padding: 16px 0 16px 16px;
  cursor: pointer;
  &:hover {
    background: $light-grey-bg;
  }
  // 左半边
  .message-item-content {
    height: 42px;
    // 表示消息类型的标签
    .message-type {
      &.blue {
        color: $main-color;
      }
      &.orange {
        color: $orange;
      }
      &.red {
        color: $red;
      }
    }
    .message-text {
      line-height: 21px;
      max-height: 42px;
    }
  }
  // 右半边
  .num-box {
    width: 66px;
    .num {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      background: $red;
      border-radius: 50%;
    }
  }
}
</style>
