<!--
 * @Date: 2021-06-25 17:01:49
 * @LastEditTime: 2021-08-26 13:56:48
 * @Description: 显示移动任务的弹窗
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <el-dialog
    title="移动任务"
    :visible.sync="isShow"
    width="30%"
    :before-close="handleClose"
    class="move-box"
  >
    <!-- 里面，显示任务树形结构 -->
    <div class="data-box">
      <move-tree-node
        v-for="(item, index) in taskTree"
        :key="index"
        :index="index"
        :treeNode="item"
        :targetId="targetId"
        :targetRange="targetRange"
        @choose-node="getCurrentChoose($event)"
      ></move-tree-node>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">取 消</el-button>
      <el-button
        type="primary"
        @click="insertBefore"
        v-if="targetRange === 'task'"
        >插入到该任务前面</el-button
      >
      <el-button type="primary" @click="insertChild">{{
        targetRange === 'task' ? '插入为子任务' : '插入到该组'
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MoveTreeNode from './MoveTreeNode.vue'
export default {
  name: 'move-task-modal',
  components: {
    MoveTreeNode
  },
  props: {
    // 里面展示的数据
    taskTree: {
      type: Array
    },
    // 是否显示弹窗
    isShow: {
      type: Boolean,
      default: false
    },
    // 当前要移动哪个任务
    taskId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // 当前选择的目标id
      targetId: -1,
      // 当前选择的目标是组还是任务
      targetRange: '',
      // 当前要处理的目标
      currentData: null
    }
  },
  methods: {
    // 取消
    closeModal() {
      this.$emit('close-modal')
      // 重置掉当前的选择
      this.targetId = -1
      this.targetRange = ''
      this.currentData = null
    },
    // 插入到某任务之前
    async insertBefore() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.taskMove, {
          task_id: this.taskId,
          next_task_id: this.targetId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 关闭弹窗
        this.closeModal()
        // 发送提示
        this.$message({
          showClose: true,
          message: '排序成功',
          type: 'success'
        })
        // 通知父任务更新
        this.$emit('to-fresh-list')
      }
    },
    // 插入为子任务或插入到组
    async insertChild() {
      const formObj = {
        task_id: this.taskId
      }
      if (this.targetRange === 'group') {
        formObj.group_id = this.currentData.key
      } else {
        formObj.pid = this.currentData.task_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.taskMove, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 关闭弹窗
        this.closeModal()
        // 发送提示
        this.$message({
          showClose: true,
          message: '排序成功',
          type: 'success'
        })
        // 通知父任务更新
        this.$emit('to-fresh-list')
      }
      this.closeModal()
    },
    // 点击关闭按钮
    handleClose() {
      this.closeModal()
    },
    // 获取子元素当前选择到的东西
    getCurrentChoose({ range, id, item }) {
      this.targetId = id
      this.targetRange = range
      this.currentData = item
    }
  }
}
</script>

<style lang="scss">
.data-box {
  height: 400px;
}
.move-box .el-dialog__body {
  overflow: auto;
}
// 导入移动节点样式
@import '@/assets/scss/move_modal_style.scss';
</style>
