<template>
  <div class="circle-home">
    <!-- 圈子-顶部tab组件 -->
    <tabs
      style="position: fixed; z-index: 3"
      :style="
        screenWidth > 767
          ? 'top: 60px;width: 600px;padding: 13px 20px;border-top: 26px solid rgb(246, 246, 246);'
          : 'width: 100vw;top: 0;padding: 13px 0;justify-content:center;'
      "
      :current="currentTab"
      @change="changeTab"
      :data-list="screenWidth > 767 ? tabs : mobileTabs"
    ></tabs>

    <!-- 动态容器 -->
    <scroll-fresh
      className="message-list"
      dataName="message-data"
      :page="pageNumber"
      :lastPage="totalPage"
      @get-next-page="getNextPage"
      :showNoMore="list.length > 0"
      ref="scrollFreshRef"
    >
      <div class="message-list">
        <div
          :class="{ 'no-moment': !loading && list.length === 0 && isShow }"
          style="margin: 0 auto; position: relative"
          :style="screenWidth > 767 ? 'left: -140px;width: 640px;' : ''"
        >
          <el-skeleton :loading="loading" animated :count="2">
            <template slot="template">
              <div
                class="el-skeleton-item"
                :class="
                  screenWidth > 767 ? 'flex flex-y-start flex-x-between' : ''
                "
              >
                <el-skeleton-item
                  variant="image"
                  :style="
                    screenWidth > 767
                      ? 'width: 64px; height: 64px'
                      : 'position: absolute;width: 42px; height: 42px;border-radius: 42px;margin-top: 10px;left: 10px;z-index: 3;'
                  "
                />
                <div class="el-skeleton-item-content">
                  <div class="el-skeleton-item-header">
                    <el-skeleton-item
                      variant="p"
                      style="width: 50px; margin-right: 20px; height: 5px"
                    />
                    <el-skeleton-item
                      variant="p"
                      style="height: 5px; width: 130px"
                    />
                  </div>
                  <div class="el-skeleton-item-text">
                    <el-skeleton-item
                      v-for="item in 12"
                      :key="item"
                      variant="p"
                      style="height: 5px; margin-bottom: 15px"
                    />
                  </div>
                  <div class="el-skeleton-item-footer flex flex-x-end">
                    <el-skeleton-item
                      variant="button "
                      style="width: 40px; margin-right: 20px; height: 5px"
                    />
                    <el-skeleton-item
                      variant="button "
                      style="width: 40px; height: 5px"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template>
              <message-item
                @searchTopic="searchTopic"
                @searchMention="searchMention"
                @changeMenu="changeMenu($event, index)"
                v-for="(item, index) in list"
                :key="item.moment_id"
                :messageInfo="item"
                @incrementComment="incrementComment(index)"
                @openDetail="openDetail(index)"
                @clickAvatar="clickAvatar"
              ></message-item>
            </template>
          </el-skeleton>
          <member-empty
            v-if="list.length === 0 && isShow"
            icon="#icon-Frame14"
            title="暂无动态~"
          ></member-empty>
        </div>
      </div>
    </scroll-fresh>

    <message-detail-pop
      @searchTopic="searchTopic"
      @searchMention="searchMention"
      :visible="detailVisible"
      :momentId="detailMomentId"
      @close="closeDetail"
      @toEdit="detailToEdit"
    ></message-detail-pop>
    <edit-message
      :visible="editVisible"
      @addComplete="addComplete"
      @editComplete="editComplete"
      :momentId="editMomentId"
      @close="closeEdit"
    ></edit-message>
  </div>
</template>

<script>
// 顶部tab组件
import Tabs from '@/components/circle/Tabs.vue'
// 动态组件
import MessageItem from '@/components/circle/MessageItem.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
// 侧边详情
import MessageDetailPop from '@/components/circle/MessageDetailPop.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
import MemberEmpty from '@/components/member/MemberEmpty.vue'
import { EventBusOff, EventBusOn, EventBusEmit } from '@/util/EventBus.js'
import { mapState } from 'vuex'

export default {
  name: 'circleIndex',
  components: {
    Tabs,
    MessageItem,
    ScrollFresh,
    MessageDetailPop,
    EditMessage,
    MemberEmpty
  },
  data() {
    return {
      tabs: ['全部', '图文', '视频'],
      mobileTabs: ['全部', '关注'],
      // 当前tab
      currentTab: 0,
      // 分页
      pageNumber: 1,
      pageSize: 5,
      totalPage: 2,
      list: [],
      // 搜素key
      searchKey: '',
      // 详情展示
      detailVisible: false,
      // 详情的id
      detailMomentId: -1,
      // 修改展示
      editVisible: false,
      // 修改的id
      editMomentId: -1,
      // 我的关注
      myFocus: '',
      // 选中的部门
      deptId: '',
      isShow: false,
      loading: false,
      // 话题id
      topicId: '',
      loadingNextPage: false
    }
  },
  computed: {
    ...mapState(['myClerkId', 'screenWidth'])
  },
  activated(e) {
    console.log('进入缓存', e)
  },
  methods: {
    // 组件回调
    changeTab(e) {
      if (this.screenWidth <= 767) {
        this.currentTab = e
        EventBusEmit('getMobileTabs', { pageName: 'circleIndex', current: e })
        return
      }

      if (this.currentTab === e) {
        if (this.$route.query.topic_id) {
          this.topicId = ''
          const query = { ...this.$route.query }
          delete query.detail_id
          delete query.topic_id
          this.$router.replace({
            name: 'circleIndex',
            query: { ...query }
          })
        }
        this.refreshList()
        return
      }
      // this.currentTab = e
      // this.refreshList()
      let tab = ''
      switch (e) {
        case 1:
          tab = 'article'
          break
        case 2:
          tab = 'video'
          break
        case 3:
          tab = 'knowledge'
          break
      }
      const query = { ...this.$route.query }
      delete query.tab
      delete query.detail_id
      delete query.topic_id
      this.$router.replace({
        name: 'circleIndex',
        query: tab ? { ...query, tab: tab } : query
      })
    },
    // 获取下一页
    async getNextPage() {
      if (this.pageNumber >= this.totalPage) {
        return
      }
      if (this.loadingNextPage) {
        return
      }
      this.loadingNextPage = true
      this.pageNumber++
      await this.getList()
      this.loadingNextPage = false
    },
    // 圈子Menu回调
    async changeMenu(ob, index) {
      switch (ob.type) {
        // 删除
        case 'delete': {
          this.momentDelte(index)
          break
        }
        // 复制链接
        case 'copy': {
          this.copyLink(index)
          break
        }
        // 编辑
        case 'edit': {
          this.editMomentId = this.list[index].moment_id
          this.editVisible = true
          break
        }
      }
    },
    // 评论数量加 1
    incrementComment(index) {
      this.$set(
        this.list[index],
        'comment_number',
        this.list[index].comment_number + 1
      )
    },
    // 获取列表
    async getList(reset = false) {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        content_type: this.currentTab > 0 ? this.currentTab : '',
        top_create_clerk_dept_id: this.deptId,
        my_focus: this.myFocus,
        search_key: this.searchKey,
        topic_id: this.topicId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.list = res.page.list
        } else {
          this.list = this.list.concat(res.page.list)
        }
        this.pageNumber = res.page.pageNumber
        if (res.page.list.length < this.pageSize) {
          this.totalPage = this.pageNumber
        } else {
          this.totalPage = this.pageNumber + 1
        }
        if (this.list.length === 0) {
          this.isShow = true
        }
      }
    },
    // 圈子删除
    async momentDelte(index) {
      this.$confirm('此操作将删除该圈子, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.deleteMoment, {
            moment_id: this.list[index].moment_id
          })
        )
        if (err) {
          this.$message.error(err)
        } else if (res.state === 'ok') {
          this.list.splice(index, 1)
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
        }
      })
    },
    // 复制链接
    copyLink(index) {
      this.$copyText(
        `${this.$config.frontHome}topicInfo/${this.list[index].moment_id}`
      ).then(
        () => {
          this.$message({
            showClose: true,
            message: '已成功复制，可直接去粘贴',
            type: 'success'
          })
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    // 打开详情
    openDetail(index) {
      // this.detailMomentId = this.list[index].moment_id
      // this.detailVisible = true
      this.$router.push({
        name: 'circleIndex',
        query: { ...this.$route.query, detail_id: this.list[index].moment_id }
      })
    },
    // 关闭详情
    closeDetail() {
      // this.detailMomentId = -1
      // this.detailVisible = false
      const query = { ...this.$route.query }
      delete query.detail_id
      this.$router.push({
        name: 'circleIndex',
        query: query
      })
    },
    // 详情跳转修改
    detailToEdit() {
      this.editMomentId = this.detailMomentId
      this.detailMomentId = -1
      this.detailVisible = false
      this.editVisible = true
    },
    // 关闭编辑弹窗
    closeEdit() {
      this.editVisible = false
      this.editMomentId = -1
    },
    // 添加圈子回调
    addComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.refreshList()
    },
    // 修改圈子回调
    editComplete() {
      this.editVisible = false
      this.editMomentId = -1
      this.refreshList()
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.loading = true
      // this.$refs.scrollFreshRef.handleBackTop()
      await this.getList(true)
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    // 话题刷新列表
    async topicRefreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.loading = true
      // this.$refs.scrollFreshRef.handleBackTop()
      await this.getList(true)
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    // 点击头像
    clickAvatar(clerkId) {
      if (this.myClerkId === clerkId) {
        this.$router.push({
          name: 'memberHome',
          params: { clerkId: clerkId }
        })
      } else {
        this.$router.push({
          name: 'circleHome',
          params: { clerkId: clerkId }
        })
      }
    },
    // 刷新列表回调
    async refreshMomentListCallback({
      dept_id: deptId = '',
      my_focus: myFocus = '',
      search_key: searchKey = ''
    }) {
      this.myFocus = myFocus
      this.deptId = deptId
      this.searchKey = searchKey
      await this.refreshList()
    },
    // 搜索话题
    searchTopic(e) {
      this.closeDetail()
      this.topicId = e
      this.detailVisible = false
      const query = { ...this.$route.query }
      this.$router.replace({
        name: 'circleIndex',
        query: { ...query, topic_id: e }
      })
    },
    // 进入提及人主页
    searchMention(e) {
      this.closeDetail()
      this.detailVisible = false
      if (this.myClerkId === e) {
        this.$router.push({
          name: 'memberHome',
          params: { clerkId: this.elementId }
        })
      } else {
        this.$router.push({
          name: 'circleHome',
          params: { clerkId: e }
        })
      }
    }
  },

  destroyed() {
    EventBusOff('refreshMomentList')
  },
  mounted() {
    // this.refreshMomentListCallback
    EventBusOn('refreshMomentList', this.refreshMomentListCallback)
    // 切换tab
    if (this.$route?.query?.tab) {
      switch (this.$route.query.tab) {
        case 'article':
          this.currentTab = 1
          break
        case 'video':
          this.currentTab = 2
          break
        case 'knowledge':
          this.currentTab = 3
          break
      }
    }
    // 打开详情
    if (this.$route?.query?.detail_id) {
      // this.detailMomentId = this.$route.query.detail_id
      // this.detailVisible = true
      this.$router.replace({
        name: 'topicInfo',
        params: {
          id: Number(this.$route.query.detail_id)
        }
      })
    }

    // 传回话题id
    if (this.$route?.query?.topic_id) {
      this.topicId = this.$route.query.topic_id
    }

    if (this.$route?.query?.my_focus) {
      this.currentTab = 1
    }
    this.refreshList()
    window.onscroll = () => {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      console.log(scrollTop + windowHeight === scrollHeight)
      if (scrollTop + windowHeight >= scrollHeight - 100) {
        // 到了这个就可以进行业务逻辑加载后台数据了
        this.getNextPage()
      }
    }
  },
  watch: {
    '$route.query'(val, old) {
      // 打开详情
      if (val?.detail_id) {
        this.detailMomentId = val?.detail_id
        this.detailVisible = true
      } else {
        this.detailMomentId = -1
        this.detailVisible = false
      }
      let isRefreshList = false
      if (val?.topic_id) {
        this.topicId = val?.topic_id
        if (val?.topic_id !== old?.topic_id) {
          isRefreshList = true
        }
      } else {
        this.topicId = ''
      }
      // 切换tab
      if (val?.tab !== old?.tab) {
        switch (val.tab) {
          case 'article':
            this.currentTab = 1
            break
          case 'video':
            this.currentTab = 2
            break
          case 'knowledge':
            this.currentTab = 3
            break
          default:
            this.currentTab = 0
            break
        }
        isRefreshList = true
      }
      if (isRefreshList) {
        this.refreshList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-home {
  .message-list {
    position: absolute;
    // margin-top: 20px;
    overflow: auto;
    min-height: calc(100vh - 100px);
    margin-top: 80px;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #f6f6f6;

    ::v-deep .back-top {
      margin-left: 500px;
      left: 51%;
      transform: translateX(-50%);
    }

    .no-moment {
      background-color: #fff;
      height: calc(100% - 230px);
      padding-top: 208px;
    }

    ::v-deep .no-more-tip {
      margin-left: -273px;
    }

    ::v-deep .message-item:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
      ::v-deep .no-more-tip {
        margin-left: 0;
      }
    }
  }
}
.el-skeleton-item {
  margin-bottom: 20px;
  .el-skeleton-item-content {
    position: relative;
    width: 493px;
    margin-left: 20px;
    padding: 18px 24px;
    background-color: #fff;
    &::after {
      position: absolute;
      content: '';
      height: 0px;
      width: 0px;
      top: 0;
      left: -15px;
      border-right: 15px solid #fff;
      border-bottom: 15px solid #f6f6f6;
    }
  }
  .el-skeleton-item-header {
    margin-bottom: 20px;
  }
  .el-skeleton-item-text {
  }
}

@media screen and (max-width: 767px) {
  .circle-home {
    .message-list {
      position: absolute;
      overflow: auto;
      min-height: calc(100vh - 120px);
      margin-top: 26px;
      top: 0;
      left: 0;
      width: 100vw;
      padding-bottom: 70px;
    }
  }

  .el-skeleton-item {
    .el-skeleton-item-content {
      width: 100vw;
      margin-left: 0;
    }
  }
}
</style>
