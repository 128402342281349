<!--
 * @Date: 2021-07-25 23:28:00
 * @LastEditTime: 2021-08-20 17:04:01
 * @Description: 评论回复展示组件
 * @Author: lyq
 * @LastEditors: hzh
-->
<template>
  <div class="reply-comment">
    <comment-member
      :name="replyInfo.clerk_alias || replyInfo.clerk_name || ''"
      :time="replyInfo.create_time"
      :avatar="replyInfo.clerk_avatar"
      :showDelete="showDelete"
      :replyShow="replyShow"
      :likeNum="replyInfo.praise_number"
      :isLike="replyInfo.is_praise == 1"
      @onReply="replyInteractive"
      @clickAvatar="clickAvatar"
      @onPraise="$emit('onPraise', $event)"
      @onDelete="$emit('onDelete', replyInfo.comment_id)"
    ></comment-member>
    <span v-if="showReplyToName">回复了</span>
    <span v-if="showReplyToName" class="replay-to">{{
      replyInfo.parent_clerk_alias || replyInfo.parent_clerk_name || ''
    }}</span>
    <span
      class="text-warp"
      @click="addLink"
      v-html="getCommentHtml(replyInfo).replace(/\[[^\[\]]*\]/gi, emotion)"
    ></span>
  </div>
</template>

<script>
import CommentMember from './CommentMember.vue'
import { emojisMap } from '../common/ExpressionBox.vue'
export default {
  components: {
    CommentMember
  },
  props: {
    showReplyToName: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    replyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    replyShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    emotion(name) {
      if (emojisMap[name] != null) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
      // const word = nane.replace(/\[|\]/gi, '')
      // const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
      // const indon = list.indexOf(word)
      // return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${indon}.gif" align="middle">`
    },
    replyInteractive() {
      this.$emit('onReply')
    },
    clickAvatar() {
      this.$emit('clickAvatar', this.replyInfo.create_clerk_id)
    },
    // 获取评论内容html
    getCommentHtml(e) {
      // 提及列表
      const list = e.clerk_list.map((c) => {
        return c
      })
      const content = e.comment_content || ''
      let str = ''
      let index = 0
      if (list.length !== 0) {
        list.forEach((e, i) => {
          const name = '@' + (e.clerk_alias || e.clerk_name) + ' '
          const id = e.clerk_id
          str +=
            content.substring(index, e.start_index) +
            `<a data-id="${id}" data-name="${name}" data-class="mention" style=" color: #0080FF;cursor: pointer;font-weight:600">${name}</a>`
          index = e.end_index + 1
          if (i === list.length - 1) {
            str += content.slice(index)
          }
        })
        return str
      } else {
        return content
      }
    },
    addLink(e) {
      this.$emit('appenLink', e)
    }
  }
}
</script>

<style lang="scss">
.reply-comment {
  margin-top: 20px;
  line-height: 21px;

  span {
    font-size: 14px;
  }

  .replay-to {
    color: #999999;
    margin: 0 5px;
  }

  .delete-show {
    display: none;
  }

  &:hover {
    .delete-show {
      display: initial;
    }
  }
}

@media screen and (max-width: 767px) {
  .reply-comment {
    margin-top: 10px;
  }
}
</style>
