var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-popover',{attrs:{"placement":_vm.placement,"popper-class":"meeting-room-occupy-meeting-timeline-popover","width":"350","trigger":"manual"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_vm._t("default")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"info-box",on:{"click":function($event){return _vm.clickBody()}}},[_c('i',{staticClass:"el-icon-close close-btn",on:{"click":function($event){return _vm.close()}}}),_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$util.toDateString(_vm.date, 'yyyy年MM月dd日'))+" "+_vm._s(['周日', '周一', '周二', '周三', '周四', '周五', '周六'][ _vm.date.getDay() ])+" ")]),_c('div',{staticClass:"content-scroll"},[_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"timeline-list"},_vm._l((_vm.renderData.timeIntervalList),function(item,index){return _c('div',{key:index,staticClass:"timeline-item flex"},[_c('div',{staticClass:"time flex flex-y-reverse flex-x-between flex-shrink"},[_c('div',{staticClass:"end-time"},[_vm._v(_vm._s(item.end.timeStr))]),(index === 0)?_c('div',{staticClass:"start-time"},[_vm._v(" "+_vm._s(item.start.timeStr)+" ")]):_vm._e()]),_c('div',{staticClass:"grid",class:{
                  before: item.isBefore
                },style:({
                  height: ((item.height) + "px")
                })})])}),0),(_vm.nowLineTop !== -1)?_c('div',{staticClass:"now-line",style:({
              top: (_vm.nowLineTop + "px")
            })}):_vm._e(),_c('div',{staticClass:"meeting-list"},[_vm._l((_vm.renderData.meetingList),function(item){return [_c('info-popover',{key:item.meeting_id,attrs:{"data":item.info,"placement":"left-start"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"meeting-item",style:({
                      height: ((item.height) + "px"),
                      top: ((item.top) + "px")
                    }),on:{"click":function($event){$event.stopPropagation();_vm.clickBody()
                      _vm.showInfo = true}}},[_c('div',{staticClass:"meeting-header e"},[_vm._v(" "+_vm._s(item.info.meeting_name)+" ")]),_c('div',{staticClass:"meeting-time"},[_vm._v(" "+_vm._s(_vm.$util.toDateString(item.info.start_time, 'HH:mm'))+" - "+_vm._s(_vm.$util.toDateString(item.info.end_time, 'HH:mm'))+" ")])])]},proxy:true}],null,true)})]})],2)])])])]},proxy:true}],null,true),model:{value:(_vm.myVisible),callback:function ($$v) {_vm.myVisible=$$v},expression:"myVisible"}})}
var staticRenderFns = []

export { render, staticRenderFns }