/*
 * @Date: 2021-06-01 13:52:26
 * @LastEditTime: 2021-08-23 17:19:28
 * @Description: 过滤器
 * @Author: Betty
 * @LastEditors: hzh
 */
import Vue from 'vue'
// 处理日期的过滤器
Vue.filter('handleDate', function (val) {
  if (val) {
    return `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`
  }
  return null
})

Vue.filter('handleNum', function (val) {
  if (typeof val === 'number') {
    return val < 10 ? '0' + val : val
  } else return val
})

Vue.filter('handleTabName', function (val, attribute) {
  if (typeof (val) === 'object') {
    return val[attribute]
  } else {
    return val
  }
})

Vue.filter('httpToHttps', function (val) {
  if (val?.indexOf('http:') === 0) {
    return `https:${val.substring(5)}`
  }
  return val
})
