<!--
 * @Author: hzh
 * @Date: 2021-12-22 21:22:24
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-29 14:34:49
-->
<template>
  <div class="comment-box flex flex-y-positive">
    <div
      class="flex1 list-box"
      v-infinite-scroll="loadNext"
      infinite-scroll-disabled="disabled"
      :infinite-scroll-immediate="false"
    >
      <div class="item flex" v-for="item in list" :key="item.comment_id">
        <div class="left flex-shrink">
          <img :src="item.clerk_avatar" alt="用户头像" class="avatar" />
        </div>
        <div class="flex1">
          <div class="header flex flex-x-between">
            <span class="name">{{ item.clerk_alias || item.clerk_name }}</span>
            <span class="time">{{ item.create_time }}</span>
          </div>
          <div class="content">
            <span class="reply" v-if="item.pid !== 0"
              >回复了
              {{ item.parent_clerk_alias || item.parent_clerk_name }}：</span
            ><span
              v-html="item.comment_content.replace(/\[[^\[\]]*\]/gi, emotion)"
            ></span>
          </div>
          <div class="footer flex flex-y-center">
            <div class="btn reply-btn" @click.stop="toReply(item)">
              <i class="icon-comment"></i>
              <span>回复</span>
            </div>
            <div
              class="btn delete-btn"
              v-show="myClerkId === item.create_clerk_id"
              @click="toDelete(item)"
            >
              <i class="icon-trash1"></i>
              <span>删除</span>
            </div>
          </div>
        </div>
      </div>
      <div class="noMore" v-if="noMore">没有更多了</div>
    </div>
    <div class="flex-shrink editor-box">
      <work-report-comment-editor
        style="margin: 10px 0"
        @input="handellInput"
        @blur="cancelReply"
        :title="placeholder"
        :value="commentContent"
        ref="commentRef"
      ></work-report-comment-editor>
      <div class="flex flex-y-center">
        <div class="send-btn" @click="save">发布</div>
        <expression-box
          :isShow="isShowExpressBox"
          :expressList="$emoji.slice(0, 100)"
          :left="'0'"
          @add-express="addContentExpress($event)"
        >
          <div
            class="icon-a-1 icon-button"
            @click.stop="isShowExpressBox = !isShowExpressBox"
          ></div>
        </expression-box>
      </div>
    </div>
  </div>
</template>

<script>
// 导入表情组件
import ExpressionBox, { emojisMap } from '@/components/common/ExpressionBox.vue'
import WorkReportCommentEditor from './work-report-comment-editor'

import { mapState } from 'vuex'
export default {
  name: 'workReportComment',
  components: {
    ExpressionBox,
    WorkReportCommentEditor
  },
  props: {
    // 报告id
    reportId: Number
  },
  data() {
    return {
      // 展示选图标
      isShowExpressBox: false,
      pageSize: 10,
      pageNumber: 1,
      totalNumber: 2,
      list: [],
      // 评论内容
      commentContent: '',
      placeholder: '请输入内容',
      // 回复id
      replyId: null,
      loading: false
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    noMore() {
      return this.pageNumber >= this.totalNumber
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  methods: {
    // 获取列表
    async getList(reset = false) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportCommentPage, {
          report_id: this.reportId,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          if (reset) {
            this.list = res.page.list
          } else {
            this.list = this.list.concat(res.page.list)
          }
          this.totalNumber = res.page.totalPage
          this.pageNumber = res.page.pageNumber
        }
      }
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalNumber = 2
      this.getList(true)
    },
    // 监听输入
    handellInput(val) {
      this.commentContent = val
    },
    // 在内容里添加一个表情
    addContentExpress(obj) {
      this.commentContent = this.commentContent.replace('<p>&nbsp;', '<p>')
      const index = this.commentContent.lastIndexOf('</p>')
      if (index === -1) {
        this.commentContent = '<p>' + obj.name + '</p>'
      } else {
        this.commentContent =
          this.commentContent.substring(0, index) + obj.name + '</p>'
      }
      this.$emit('input', this.commentContent)
      // 关闭白框
      this.isShowExpressBox = false
      setTimeout(() => {
        // 让输入框重新聚焦
        this.$refs.contentRef.setFocus()
      }, 50)
    },
    // 保存
    async save() {
      if (!this.commentContent) {
        this.$message({
          showClose: true,
          message: '请输入评论内容',
          type: 'error'
        })
        return
      }
      const fromObj = {
        report_id: this.reportId,
        comment_content: this.commentContent
      }
      if (this.replyId != null) {
        fromObj.pid = this.replyId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addWorkReportComment, fromObj)
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.isShowExpressBox = false
          this.commentContent = ''
          this.placeholder = '请输入内容'
          this.replyId = null
          this.refreshList()
        }
      }
    },
    // 处理表情包
    emotion(name) {
      if (emojisMap[name]) {
        return `<img src="${emojisMap[name]}" align="middle" class="emoji-img" style="width: 24px">`
      } else {
        return name
      }
    },
    // 去回复
    toReply(info) {
      this.replyId = info.comment_id
      this.placeholder = `回复${info.clerk_alias || info.clerk_name}:`
      // 让输入框重新聚焦
      setTimeout(() => {
        this.$refs.commentRef.setFocus()
      }, 50)
    },
    toDelete(info) {
      this.$util.showConfirm('确定要删除这条评论吗').then(async (result) => {
        if (result) {
          // 确定要删除，那就发请求
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.deleteWorkReportComment, {
              comment_id: info.comment_id
            })
          )
          if (err) {
            console.log(err)
          } else {
            if (res.state === 'ok') {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              })
              this.list.splice(
                this.list.findIndex((e) => e.comment_id === info.comment_id),
                1
              )
            }
          }
        }
      })
    },
    // 取消回复
    cancelReply() {
      if (!this.commentContent) {
        this.replyId = null
        this.placeholder = '请输入内容'
      }
    },
    async loadNext() {
      if (this.noMore) {
        return false
      }
      this.pageNumber++
      this.loading = true
      await this.getList()
      this.loading = false
    }
  },
  mounted() {},
  watch: {
    reportId: {
      handler(val) {
        this.isShowExpressBox = false
        this.commentContent = ''
        this.placeholder = '请输入内容'
        if (val != null) {
          this.refreshList()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-box {
  height: 100%;
}
.list-box {
  overflow-y: scroll;
  .item {
    border-bottom: 1px solid #e5e8ee;
    padding: 15px 0;
    .left {
      margin-right: 15px;
    }
    .avatar {
      width: 35px;
      height: 35px;
      border-radius: 5px;
    }

    .header {
      padding: 0 0 6px 0;
    }
    .name {
      font-size: 14px;
      color: #333;
      font-weight: 700;
      line-height: 1.5;
    }

    .time {
      color: #7f7f7f;
    }

    .content {
      .reply {
        color: #5384ff;
      }
      span {
        display: inline-block;
      }
      &::v-deep {
        .emoji-img {
          position: relative;
          top: -6px;
        }
      }
    }

    .footer {
      padding: 10px 0 0 0;
      .btn {
        cursor: pointer;
        color: #aaa;
        margin-right: 20px;
        line-height: 16px;
        &.delete-btn {
          color: #ff5454;
        }
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
.editor-box {
  .send-btn {
    width: 65px;
    height: 32px;
    border-radius: 5px;
    background-color: rgba(0, 102, 255, 1);
    box-sizing: border-box;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    line-height: 32px;
    margin-right: 20px;
  }
  .icon-button {
    cursor: pointer;
    color: #ffb72c;
    font-size: 28px;
  }
  &::v-deep {
    .expression-box {
      left: -85px;
    }
  }
}
.noMore{
  height: 50px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  color: #999;
}
</style>
