<!--
 * @Author: Betty
 * @Date: 2021-07-02 17:42:16
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-08 13:57:51
 * @Description: 新建会议的弹窗组件
-->
<template>
  <el-tabs v-model="addMeetingActive">
    <el-tab-pane label="我的会议" name="addMeeting">
      <div class="add-meeting">
        <!-- 第一行，会议主题 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-Filter"></span>
            <h4 class="add-meeting-title">会议主题</h4>
          </div>
          <input
            type="text"
            class="form-input"
            placeholder="编辑会议主题"
            v-model="meetingTopic"
          />
        </div>
        <!-- 第二行，会议地点 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-Location"></span>
            <h4 class="add-meeting-title">会议地点</h4>
            <el-tooltip
              effect="dark"
              content="会议室日程"
              placement="top-start"
            >
              <i
                class="iconfont icon-richeng meeting-room-occupy-btn"
                @click="meetingRoomOccupyVisible = true"
              ></i>
            </el-tooltip>
          </div>
          <div class="radio-box">
            <!-- 单选框 -->
            <el-radio v-model="meetingPostion" :label="1">内部</el-radio>
            <el-radio v-model="meetingPostion" :label="2">外部</el-radio>
          </div>
          <!-- 选择会议室 -->
          <div v-if="meetingPostion === 1">
            <span class="no-choose" v-show="isShowRoomTip">选择会议室</span>
            <div v-show="!isShowRoomTip" class="choose-room">
              <input
                type="text"
                class="form-input"
                readonly
                placeholder="选择会议室"
                v-model="meetingRoom"
                @click.stop="toggleChooseRoom"
              />
              <!-- 选择会议室 -->
              <div class="choose-meeting-room" v-show="isChooseRooms">
                <div
                  class="meeting-room-item"
                  v-for="(item, index) in meetRoomList"
                  :key="index"
                  @click="chooseRoom(item)"
                >
                  {{ item.room_name }}
                </div>
              </div>
            </div>
          </div>
          <!-- 选择会议地点 -->
          <div v-else>
            <input
              type="text"
              class="form-input"
              placeholder="编辑会议地址"
              v-model="meetingAddress"
            />
          </div>
        </div>
        <!-- 第三行，会议时间 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-a-TimeCircle"></span>
            <h4 class="add-meeting-title">会议时间</h4>
          </div>
          <!-- 选择会议开始和结束时间 -->
          <div class="flex flex-y-center">
            <el-date-picker
              v-model="meetingStartTime"
              type="datetime"
              placeholder="会议开始时间"
              align="right"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
            <span class="time-tip">至</span>
            <el-date-picker
              v-model="meetingEndTime"
              type="datetime"
              placeholder="会议结束时间"
              align="right"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 第四行，选择会议主持 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-a-Frame1"></span>
            <h4 class="add-meeting-title">会议主持</h4>
          </div>
          <!-- 显示选择的人员 -->
          <!-- 如果还没有选择 -->
          <div
            class="person-result form-input people-input"
            :class="{ 'no-choose': hostList.length === 0 }"
            @click="beginChooseHost"
          >
            <span v-if="hostList.length === 0">选择会议主持</span>
            <div v-else>
              <span
                v-for="(host, index) in hostList"
                :key="index"
                class="people-name"
              >
                {{ host.clerk_alias || host.clerk_name }}
                <span
                  class="delete-btn iconfont icon-a-addfill"
                  @click.stop="deleteHost(host.clerk_id)"
                ></span>
              </span>
            </div>
          </div>
          <!-- 选择会议主持的弹窗 -->
          <add-member
            :isShowAddMember="isChooseHost"
            :dataList="clerkTree"
            :memberList="hostTempList"
            modalTitle="选择主持人"
            @to-add-member="chooseHost($event)"
            @ready-remove-member="removeTempHost($event)"
            @confirm-add-member="chooseHostConfirm($event)"
            @close-add-member="closeHostModal"
          ></add-member>
        </div>
        <!-- 第五行，会议记录 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-a-Frame2"></span>
            <h4 class="add-meeting-title">会议记录</h4>
          </div>
          <!-- 显示选择的人员 -->
          <div
            class="person-result form-input people-input"
            :class="{ 'no-choose': !recorder }"
            @click.stop="beginChooseRecorder"
          >
            {{
              recorder
                ? recorder.clerk_alias || recorder.clerk_name
                : '选择会议记录员'
            }}
          </div>
          <!-- 选择会议记录人员弹窗 -->
          <div
            v-show="isChooseRecorder"
            @click.stop=""
            class="choose-person-box"
          >
            <search-person
              :isShow="isChooseRecorder"
              @choose-person="chooseRecorder($event)"
            ></search-person>
            <member-tree
              :treeNode="treeNode"
              v-for="(treeNode, index) in clerkTree"
              :key="index"
              @clerk-choose="chooseRecorder($event)"
            ></member-tree>
          </div>
        </div>
        <!-- 第六行，选择会议与会人员 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-a-Frame3"></span>
            <h4 class="add-meeting-title">与会人员</h4>
          </div>
          <!-- 显示选择的人员 -->
          <div
            class="person-result form-input people-input"
            :class="{ 'no-choose': memberList.length === 0 }"
            @click="beginChooseMember"
            style="width: 100%"
          >
            <span v-if="memberList.length === 0">选择参会人员</span>
            <div v-else>
              <span
                v-for="(member, index) in memberList"
                :key="index"
                class="people-name"
              >
                {{ member.clerk_alias || member.clerk_name }}
                <span
                  class="delete-btn iconfont icon-a-addfill"
                  @click.stop="deleteMember(member.clerk_id)"
                ></span>
              </span>
            </div>
          </div>
          <!-- 选择参会人员的弹窗 -->
          <add-member-by-dept
            :isShowAddMember="isChooseMember"
            :dataList="clerkTree"
            :memberList="memberTempList"
            modalTitle="选择参会人员"
            @to-add-member="chooseMember($event)"
            @ready-remove-member="removeTempMember($event)"
            @confirm-add-member="chooseMemberConfirm($event)"
            @close-add-member="closeMemberModal"
          ></add-member-by-dept>
        </div>
        <!-- 第七行，会议内容 -->
        <div class="add-meeting-item meeting-content-box">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-Frame7"></span>
            <h4 class="add-meeting-title">会议内容</h4>
          </div>
          <meeting-describe-editor
            :value="meetingContent || ''"
            ref="meetingContent"
            @input="handlerDescribeInput"
          ></meeting-describe-editor>
        </div>
        <!-- 第八行，会议附件 -->
        <div class="add-meeting-item">
          <div class="add-meeting-header flex flex-y-center">
            <span class="iconfont icon-a-Frame5"></span>
            <h4 class="add-meeting-title">会议附件</h4>
          </div>
          <!-- 刚才上传的附件预览 -->
          <div class="flex flex-wrap">
            <new-attachment-item
              v-for="(attachment, index) in appendixList"
              :appendix="attachment"
              :key="index"
              @remove-file="removeAttachment(index)"
            ></new-attachment-item>
          </div>
          <!-- 上传附件的输入框 -->
          <input
            type="file"
            class="upload-file"
            ref="uploadInputRef"
            @change="chooseFile"
          />
          <!-- 上传附件的按钮 -->
          <span class="no-choose" @click="beginUploadFile">上传附件</span>
        </div>
        <!-- 最后一行，创建会议等按钮 -->
        <div class="button-line flex flex-y-center">
          <button
            class="meeting-btn active"
            type="button"
            @click="createMeeting(2)"
          >
            创建会议
          </button>
          <button class="meeting-btn" type="button" @click="createMeeting(1)">
            保存草稿
          </button>
          <button
            class="meeting-btn cancel"
            type="button"
            @click="closeAddMeeting"
          >
            取消
          </button>
        </div>
      </div>
    </el-tab-pane>
    <meeting-room-occupy :visible.sync="meetingRoomOccupyVisible" />
  </el-tabs>
</template>

<script>
import MeetingDescribeEditor from '../meeting/MeetingDescribeEditor1.vue'
import MemberTree from '../common/MemberTree.vue'
import SearchPerson from '../common/SearchPerson.vue'
import NewAttachmentItem from '../common/NewAttachmentItem.vue'
import AddMember from '../common/AddMember.vue'
import AddMemberByDept from '../common/AddMemberByDept.vue'
import MeetingRoomOccupy from '@/views/Meeting/components/meeting-room-occupy'
export default {
  name: 'new-meeting-modal',
  components: {
    MemberTree,
    SearchPerson,
    MeetingDescribeEditor,
    NewAttachmentItem,
    AddMemberByDept,
    AddMember,
    MeetingRoomOccupy
  },
  props: {
    // 是否显示新建会议的弹窗
    isNewMeeting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 输入的会议标题
      meetingTopic: '',
      // 添加会议
      addMeetingActive: 'addMeeting',
      // 会议时间的开始时间
      meetingStartTime: '',
      // 会议时间的结束时间
      meetingEndTime: '',
      // 选择日期组件的快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 选择地点是内部还是外部
      meetingPostion: 1,
      // 选择的会议室
      meetingRoom: null,
      // 选择的会议室id
      meetingRoomId: -1,
      // 会议室列表
      meetRoomList: [],
      // 是否展示会议室列表
      isChooseRooms: false,
      // 是否展示选择会议室的提示
      isShowRoomTip: false,
      // 会议地址
      meetingAddress: '',
      // 选择的会议主持人
      hostList: [],
      // 是否在选择主持人
      isChooseHost: false,
      // 职员数据
      clerkTree: [],
      // 选择主持人的临时数据，用来预览
      hostTempList: [],
      // 选择主持人的临时id集合，用来提交
      hostTempIdList: [],
      // 选择的记录人
      recorder: null,
      // 是否在选择记录人
      isChooseRecorder: false,
      // 选择的参会人员
      memberList: [],
      // 临时选择的参会人员
      memberTempList: [],
      // 选择的参会人员id集合
      memberTempIdList: [],
      // 是否在选择参会人员
      isChooseMember: false,
      // 会议内容
      meetingContent: '',
      // 附件列表
      appendixList: [],
      // 是否提交中
      isSubmiting: false,
      // 会议室日程
      meetingRoomOccupyVisible: false
    }
  },
  mounted() {
    // 获取职员数据
    this.getClerkTree()
    // 获取会议室列表
    this.getRoomList()
    document.addEventListener('click', this.clickOut)
    const cache = window.localStorage.getItem(
      'add_meeting_describe_content_cache'
    )
    if (cache) {
      this.meetingContent = cache
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOut)
  },
  methods: {
    // 获取会议室列表
    async getRoomList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingRommList, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.meetRoomList = res.list
      }
    },
    // 点击取消
    closeAddMeeting() {
      this.$emit('close-modal')
    },
    // 展开收起选择会议室的弹窗
    toggleChooseRoom() {
      this.isChooseRooms = !this.isChooseRooms
    },
    // 点击选择会议室
    chooseRoom(room) {
      this.meetingRoom = room.room_name
      this.isChooseRooms = false
      // 记录选择的会议室id，并且关掉弹窗
      this.meetingRoomId = room.room_id
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 开始选择主持人
    beginChooseHost() {
      this.isChooseHost = true
      this.hostTempList = [...this.hostList]
      this.hostTempIdList = this.hostList.map((item) => item.clerk_id)
    },
    // 点击名字，选择主持人
    chooseHost(clerk) {
      console.log('马上要添加主持人啦', clerk)
      if (clerk instanceof Array === false) {
        // 添加单个职员
        if (!this.hostTempIdList.includes(clerk.clerk_id)) {
          this.hostTempList.push(clerk)
          this.hostTempIdList.push(clerk.clerk_id)
        }
      } else {
        // 添加多个职员
        clerk.forEach((item) => {
          // 添加单个职员
          if (!this.hostTempIdList.includes(item.clerk_id)) {
            this.hostTempList.push(item)
            this.hostTempIdList.push(item.clerk_id)
          }
        })
      }
      console.log('主持人选好了', this.hostTempList)
    },
    // 点击删除一个主持人
    removeTempHost(clerk) {
      if (clerk instanceof Array === false) {
        // 删除单个
        const index = this.hostTempIdList.indexOf(clerk.clerk_id)
        this.hostTempList.splice(index, 1)
        this.hostTempIdList.splice(index, 1)
      } else {
        clerk.forEach((item) => {
          // 删除多个
          console.log('要删除的主持人id为', item.clerk_id)
          const index = this.hostTempIdList.indexOf(item.clerk_id)
          console.log(index)
          this.hostTempList.splice(index, 1)
          this.hostTempIdList.splice(index, 1)
          console.log(this.hostTempIdList)
        })
      }
    },
    // 删除一个主持人
    deleteHost(id) {
      const index = this.hostTempIdList.indexOf(id)
      this.hostList.splice(index, 1)
      this.hostTempIdList.splice(index, 1)
    },
    // 删除一个参会人员
    deleteMember(id) {
      const index = this.memberTempIdList.indexOf(id)
      this.memberList.splice(index, 1)
      this.memberTempIdList.splice(index, 1)
    },
    // 确定选择这些主持人
    chooseHostConfirm() {
      this.hostList = [...this.hostTempList]
      this.closeHostModal()
    },
    // 开始选择记录人
    beginChooseRecorder() {
      this.isChooseRecorder = true
    },
    // 关闭主持人弹窗
    closeHostModal() {
      this.isChooseHost = false
    },
    // 选择记录人员
    chooseRecorder(clerk) {
      this.recorder = clerk
      this.isChooseRecorder = false
    },
    // 开始选择参会成员
    beginChooseMember() {
      this.isChooseMember = true
      this.memberTempList = [...this.memberList]
      this.memberTempIdList = this.memberList.map((item) => item.clerk_id)
    },
    // 点击选择成员
    chooseMember(clerk) {
      if (!this.memberTempIdList.includes(clerk.clerk_id)) {
        this.memberTempList.push(clerk)
        this.memberTempIdList.push(clerk.clerk_id)
      }
    },
    // 点击删除一个已选择的成员
    removeTempMember(clerk) {
      const index = this.memberTempIdList.indexOf(clerk.clerk_id)
      this.memberTempIdList.splice(index, 1)
      this.memberTempList.splice(index, 1)
    },
    // 确定选择参会人员
    chooseMemberConfirm({ list, idList }) {
      this.memberList = [...list]
      this.memberTempIdList = [...idList]
      this.closeMemberModal()
    },
    // 关闭选择参会人员弹窗
    closeMemberModal() {
      this.isChooseMember = false
    },
    // 开始上传文件
    beginUploadFile() {
      this.$refs.uploadInputRef.click()
    },
    // 选择要上传的文件
    chooseFile(e) {
      const file = e.target.files[0]
      this.appendixList.push(file)
      this.$refs.uploadInputRef.value = ''
    },
    // 删除这个新文件
    removeAttachment(index) {
      this.appendixList.splice(index, 1)
    },
    // 点击按钮，创建会议（传入1表示草稿，2表示发布）
    async createMeeting(val) {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      const isRelease = val === 2
      const formObj = new FormData()
      // 会议名称
      if (this.meetingTopic.trim() === '') {
        errmsg('请输入会议名称')
        return
      }
      formObj.append('info.meeting_name', this.meetingTopic)
      // 会议状态
      formObj.append('info.meeting_status', val)
      // 地点类型
      formObj.append('info.meeting_place_type', this.meetingPostion)
      // 会议室和会议地点
      if (this.meetingPostion === 1) {
        if (this.meetingRoomId === -1) {
          if (isRelease) {
            console.log(isRelease)
            console.log('请选择会议室')
            errmsg('请选择会议室')
            return
          }
        } else {
          formObj.append('info.meeting_room_id', this.meetingRoomId)
        }
      } else {
        if (this.meetingAddress.trim() === '') {
          if (isRelease) {
            errmsg('请输入会议地点')
            return
          }
        } else {
          formObj.append('detail.meeting_address', this.meetingAddress)
        }
      }
      // 会议开始时间和结束时间
      if (this.meetingStartTime.trim() === '') {
        if (isRelease) {
          errmsg('请选择开始时间')
          return
        }
      } else {
        formObj.append('info.start_time', this.meetingStartTime)
      }
      if (this.meetingEndTime.trim() === '') {
        if (isRelease) {
          errmsg('请选择结束时间')
          return
        }
      } else {
        formObj.append('info.end_time', this.meetingEndTime)
      }
      // 会议主持人id
      if (this.hostTempIdList.length === 0) {
        if (isRelease) {
          errmsg('请选择主持人')
          return
        }
      } else {
        formObj.append('compere_clerk_ids', this.hostTempIdList.join())
      }
      // 会议记录者
      if (this.recorder == null) {
        if (isRelease) {
          errmsg('请选择会议记录者')
          return
        }
      } else {
        formObj.append('info.upload_minutes_clerk_id', this.recorder.clerk_id)
      }
      // 会议与会人员id
      if (this.memberTempIdList.length === 0) {
        if (isRelease) {
          errmsg('请选择会议与会人员')
          return
        }
      } else {
        formObj.append('participant_clerk_ids', this.memberTempIdList.join())
      }
      const content = this.$refs.meetingContent.getContent()
      // 会议内容
      if (!(content != null && content.text !== '')) {
        if (isRelease) {
          errmsg('请输入会议内容')
          return
        }
      } else {
        formObj.append('detail.meeting_content_html', content.html)
        formObj.append('detail.meeting_content_text', content.text)
      }
      // 会议附件
      if (this.appendixList.length > 0) {
        for (let i = 0; i < this.appendixList.length; i++) {
          formObj.append(`file${i + 1}`, this.appendixList[i])
        }
      }
      // 发请求，添加会议
      if (this.isSubmiting) {
        errmsg('请勿重复提交')
        return
      }
      this.isSubmiting = true
      // console.log('准备创建会议', formObj.get('participant_clerk_ids'), '主持人：', formObj.get('compere_clerk_ids'))
      const { result } = this.$util.uploadFile(this.$api.addMeeting, formObj)
      result.then((res) => {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '创建会议成功！',
            type: 'success'
          })
          window.localStorage.removeItem('add_meeting_describe_content_cache')
          // 通知父组件，关闭弹窗，刷新会议列表
          this.$emit('add-success')
        }
        this.isSubmiting = false
      })
    },
    // 关闭掉弹窗
    clickOut() {
      this.isChooseRooms = false
      this.isChooseRecorder = false
    },
    // 监听描述变化
    handlerDescribeInput(val) {
      if (val !== '') {
        window.localStorage.setItem('add_meeting_describe_content_cache', val)
      } else {
        window.localStorage.removeItem('add_meeting_describe_content_cache')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-room-occupy-btn {
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
}
</style>
