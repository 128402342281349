<template>
  <div class="super-admin">
    <div class="super-admin_header flex flex-x-between flex-y-center">
      <p class="push-tips">
        可设置团队知识库个人团队文件夹权限，授权后团队知识库生成个人团队文件夹，可编辑管理个人团队文件。
      </p>
    </div>
    <div class="action-bar flex flex-x-between">
      <el-input
        prefix-icon="el-icon-search"
        style="width: 280px"
        size="small"
        v-model="searchKey"
        @input="handlerChangeSearchKey"
        placeholder="请输入姓名/部门查找"
      ></el-input>
      <el-button type="primary" size="small" @click="addVisible = true" v-if="perInfo.add"
        >添加团队</el-button
      >
    </div>
    <div class="people-list">
      <div class="table-header flex flex-x-between">
        <div style="width: 20%">团队名称</div>
        <div class="flex1">姓名</div>
        <div style="width: 30%">创建时间</div>
        <div style="width: 120px">操作</div>
      </div>
      <div class="table-main">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="table-item flex flex-x-between flex-y-center"
        >
          <div style="width: 20%">{{ item.folder_name }}</div>
          <div class="flex flex-y-center flex1">
            <template v-if="item.team_clerk_id">
              <el-image :src="item.team_clerk_avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <p class="text-clamp-1">
                {{ item.team_clerk_alias || item.team_clerk_name }} /
                <span style="color: #bbb">{{ item.team_dept_name }}</span>
              </p>
            </template>
          </div>
          <div style="width: 30%">{{ item.create_time }}</div>
          <div style="width: 120px">
            <el-button
              class="edit-button"
              type="text"
              size="mini"
              @click="editId = item.folder_id"
              v-if="perInfo.edit"
              >编辑</el-button
            >
            <el-button
              class="delete-button"
              style="color: red"
              type="text"
              size="mini"
              @click="
                currentUnbindInfo = item
                unbindVisible = true
              "
              v-if="perInfo.unbind"
              >解绑</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; position: absolute; bottom: 22px">
      <el-pagination
        background
        layout="prev, pager, next"
        hide-on-single-page
        @current-change="
          pageNumber = $event
          getPage()
        "
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :page-count="totalPage"
      >
      </el-pagination>
    </div>
    <!-- 添加团队 -->
    <add-team
      v-if="addVisible"
      @close="addVisible = false"
      @confirm="addClerk"
    ></add-team>
    <!-- 修改团队 -->
    <add-team
      v-if="editId !== -1"
      :info="currentEditInfo"
      @close="editId = -1"
      @confirm="editClerk"
    ></add-team>
    <!-- 解绑 -->
    <el-dialog
      title="解绑团队管理员"
      :visible.sync="unbindVisible"
      width="300px"
    >
      <span
        >确定要解绑“{{
          currentUnbindInfo.team_clerk_alias ||
          currentUnbindInfo.team_clerk_name
        }}”团队管理员权限么？解绑后可重新授权管理员，无管理员的团队文件夹，企业授权管理员可将文件移动至其它文件夹目录下。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="unbindVisible = false">取 消</el-button>
        <el-button type="primary" @click="unbindClerk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddTeam from './AddTeam.vue'
export default {
  components: {
    AddTeam
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
      list: [],
      // 添加展示
      addVisible: false,
      // 解绑展示
      unbindVisible: false,
      // 当前解绑信息
      currentUnbindInfo: {},
      // 修改的id
      editId: -1,
      searchKey: '',
      searchTimer: null
    }
  },
  computed: {
    perInfo() {
      return {
        add: this.$hasPer(67),
        edit: this.$hasPer(68),
        unbind: this.$hasPer(69)
      }
    },
    currentEditInfo() {
      if (this.editId === -1) {
        return {}
      }
      const info = this.list.find((e) => e.folder_id === this.editId)
      return {
        clerk_id: info.team_clerk_id || '',
        clerk_name: info.team_clerk_alias || info.team_clerk_name || '',
        folder_name: info.folder_name,
        folder_id: info.folder_id
      }
    }
  },
  filters: {},
  methods: {
    // 获取列表
    async getPage() {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        search_key: this.searchKey
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getSystemKnowlTeamPage, formObj)
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        this.pageNumber = page.pageNumber
        this.totalPage = page.totalPage
        this.totalRow = page.totalRow
        this.list = page.list
      }
    },
    // 刷新列表
    async refreshPage() {
      this.pageNumber = 1
      this.getPage()
    },
    // 删除职员
    async unbindClerk() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.unbindSystemKnowlTeam, {
          clerk_id: this.currentUnbindInfo.clerk_id,
          folder_id: this.currentUnbindInfo.folder_id
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.currentUnbindInfo = {}
        this.unbindVisible = false
        this.$message({
          showClose: true,
          message: '解绑成功',
          type: 'success'
        })
        this.refreshPage()
      }
    },
    // 添加
    async addClerk({ folder_name: folderName, clerk }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addSystemKnowlTeam, {
          clerk_id: clerk.clerk_id,
          folder_name: folderName
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        this.addVisible = false
        this.refreshPage()
      }
    },
    // 修改
    async editClerk({ folder_name: folderName, clerk }) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editSystemKnowlTeam, {
          clerk_id: clerk.clerk_id,
          folder_name: folderName,
          folder_id: this.editId
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
        this.editId = -1
        this.refreshPage()
      }
    },
    // 修改搜索关键字
    handlerChangeSearchKey() {
      if (this.searchTimer != null) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.refreshPage()
      }, 500)
    }
  },
  mounted() {
    this.getPage()
  }
}
</script>

<style lang="scss" scoped>
.super-admin {
  height: 100%;
  .super-admin_header {
    margin-bottom: 20px;
    .push-tips {
      color: #bbb;
    }
  }

  .action-bar {
    margin-bottom: 38px;
    // .el-input::v-deep {
    //   .el-input__inner {
    //     border: 1px solid #eee;
    //   }
    // }
  }

  .people-list {
    .table-header {
      margin-bottom: 15px;
    }
    .table-item {
      height: 66px;

      &:hover {
        background: #f6f6f6;
      }

      .el-image {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 16px;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
      }

      .el-button {
        width: 40px;
        border-radius: 4px;
        &.delete-button:hover {
          background: rgba(255, 84, 84, 0.1);
        }
        &.edit-button:hover {
          background: rgba(0, 128, 255, 0.1);
        }
      }
    }
  }
}

.el-pagination {
  text-align: center;
}
</style>
