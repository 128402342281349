var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment-item flex flex-y-start"},[_c('div',{staticClass:"avatar-box"},[_c('avatar',{attrs:{"userInfo":{
        clerk_name: _vm.comment.clerk_name,
        clerk_alias: _vm.comment.clerk_alias,
        clerk_avatar: _vm.comment.clerk_avatar
      },"size":40,"distanceRight":0}})],1),_c('div',{staticClass:"flex1"},[_c('div',{staticClass:"flex flex-y-start flex-x-between",staticStyle:{"position":"relative"},on:{"mouseover":_vm.showDelete,"mouseout":_vm.hideDelete}},[_c('div',[_c('p',{staticClass:"comment-name e",attrs:{"title":_vm.comment.clerk_alias || _vm.comment.clerk_name},on:{"click":function($event){return _vm.setReplyObject(_vm.comment)}}},[_vm._v(" "+_vm._s(_vm.comment.clerk_alias || _vm.comment.clerk_name)+" ")]),_c('p',{staticClass:"comment-time"},[_vm._v(" "+_vm._s(_vm.$util.getTimeStr(_vm.comment.create_time))+" ")])]),_c('div',{staticClass:"flex flex-y-center handle-box"},[(_vm.comment.create_clerk_id === _vm.myClerkId)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDelete),expression:"isShowDelete"}],staticClass:"delete-text",on:{"click":function($event){return _vm.removeComment(_vm.comment.comment_id)}}},[_vm._v(" 删除 ")]):_vm._e(),_c('praise',{attrs:{"comment":_vm.comment,"isTarget":_vm.isTarget}})],1)]),_c('span',{staticClass:"comment-content",domProps:{"innerHTML":_vm._s(_vm.getCommentHtml(_vm.comment).replace(/\[[^\[\]]*\]/gi, _vm.emotion))},on:{"click":function($event){return _vm.setReplyObject(_vm.comment)}}}),_vm._l((_vm.childList),function(child,index){return _c('div',{key:child.comment_id,staticClass:"comment-item child-comment",on:{"mouseover":function($event){$event.stopPropagation();return _vm.showChildDelete(index)},"mouseout":function($event){$event.stopPropagation();return _vm.hideChildDelete(index)}}},[_c('div',{staticClass:"flex flex-y-center"},[_c('div',{staticClass:"avatar-box"},[_c('avatar',{attrs:{"userInfo":{
              clerk_name: child.clerk_name,
              clerk_alias: child.clerk_alias,
              clerk_avatar: child.clerk_avatar
            },"size":40,"distanceRight":0}})],1),_c('div',{staticClass:"flex1 flex flex-y-start flex-x-between"},[_c('div',[_c('p',{staticClass:"comment-name e",attrs:{"title":child.clerk_alias || child.clerk_name},on:{"click":function($event){return _vm.setReplyObject(child)}}},[_vm._v(" "+_vm._s(child.clerk_alias || child.clerk_name)+" ")]),_c('p',{staticClass:"comment-time"},[_vm._v(" "+_vm._s(_vm.$util.getTimeStr(child.create_time))+" ")])]),_c('div',{staticClass:"flex flex-y-center flex-x-end handle-box handle-box2"},[(child.create_clerk_id === _vm.myClerkId)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(child.isShowDelete),expression:"child.isShowDelete"}],staticClass:"delete-text child",on:{"click":function($event){return _vm.removeComment(child.comment_id)}}},[_vm._v(" 删除 ")]):_vm._e(),_c('praise',{attrs:{"comment":child,"isTarget":_vm.isTarget}})],1)])]),_c('span',{staticClass:"comment-content",on:{"click":function($event){return _vm.setReplyObject(child)}}},[(child.pid !== child.tid)?_c('span',[_vm._v("回复了"+_vm._s(child.parent_clerk_alias || child.parent_clerk_name)+"：")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getCommentHtml(child).replace(/\[[^\[\]]*\]/gi, _vm.emotion))}})])])}),(
        _vm.comment.reply_number >= 3 && _vm.childList.length < _vm.comment.reply_number
      )?_c('div',{staticClass:"flex flex-y-center more",on:{"click":function($event){return _vm.loadMoreChild(_vm.childPageNumber)}}},[_c('span',{staticClass:"see-more"},[_vm._v("查看更多")]),_c('span',{staticClass:"iconfont icon-arrowaltright"})]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }