<template>
    <div>
        <div class="attention-header-container">
            <div class="flex attention-header">
                <div>全部关注（{{ totalRow }}）</div>
                <div class="search-container">
                    <input type="text" v-model="searchKey" placeholder="搜索同事" />
                    <i class="icon-search"></i>
                </div>
            </div>
        </div>
        <div class="attention-container">
            <scroll-fresh className="attention-list" dataName="attention-data" :page="pageNumber" :lastPage="totalPage" :showBackTop="true" @get-next-page="getNextPage" :showNoMore="!loading && list.length > 0">
                <div class="perch"></div>
                <div class="attention-list-container">
                    <el-skeleton :loading="loading" animated :count="8">
                        <template slot="template">
                            <div class="el-skeleton-item flex flex-y-center">
                                <el-skeleton-item variant="image" style="width: 64px; height: 64px; margin-right: 14px;border-radius: 100%;" />
                                <div class="flex1">
                                    <div>
                                        <el-skeleton-item variant="p" style="height: 5px; width: 130px;" />
                                    </div>
                                    <div>
                                        <el-skeleton-item variant="p" style="height: 5px; width: 300px;" />
                                    </div>
                                </div>
                                <el-skeleton-item variant="button " style="height: 38px; width: 94px; border-radius: 36px" />
                            </div>
                        </template>
                        <template>
                            <member-attention-item v-for="(item, index) in list" :key="item.clerk_id" :clerkId="item.clerk_id" :button-title="item | renderButtonTitle" :primary="item.is_focus === 0" :avatar="item.clerk_avatar" @clickAvatar="handlerClickAvatar(item)" @clickButton="handlerClickButton(index)">
                                <div>
                                    <div class="member-name">
                                        {{ item.clerk_alias || item.clerk_name }}
                                    </div>
                                    <div class="new-topic text-clamp-2">
                                        {{ item | renderContent }}
                                    </div>
                                </div>
                            </member-attention-item>
                        </template>
                    </el-skeleton>
                    <member-empty v-if="list.length === 0 && isShow" icon="#icon-a-Frame13" title="暂无关注，快去关注你喜欢的人吧！"></member-empty>
                </div>
            </scroll-fresh>
        </div>
        <tips-dialog @cancle="cancle" @confirm="confirm" :title="title" v-if="visible"></tips-dialog>
    </div>
</template>

<script>
import MemberAttentionItem from '@/components/member/MemberAttentionItem.vue'
import MemberEmpty from '@/components/member/MemberEmpty.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
import { mapState } from 'vuex'
import TipsDialog from '@/components/common/TipsDialog.vue'
export default {
  components: {
    MemberAttentionItem,
    MemberEmpty,
    ScrollFresh,
    TipsDialog
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 20,
      totalPage: 2,
      totalRow: 0,
      searchKeyData: '',
      searchTimer: null,
      visible: false,
      title: '',
      cancleIndex: -1,
      isShow: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    searchKey: {
      get() {
        return this.searchKeyData
      },
      set(val) {
        this.searchKeyData = val
        if (this.searchTimer != null) {
          window.clearTimeout(this.searchTimer)
        }
        this.searchTimer = window.setTimeout(() => {
          this.refreshList()
        }, 500)
      }
    }
  },
  filters: {
    // 处理文字
    renderContent(item) {
      if (item.lastest_moment_info == null) {
        return '此人特别慵懒，暂未发布圈子~'
      }
      if (item.lastest_moment_info.moment_content?.trim().length > 0) {
        return item.lastest_moment_info.moment_content
      }
      switch (item.lastest_moment_info.content_type) {
        case 1:
          return '[图片]'
        case 2:
          return '[视频]'
      }
    },
    // 按钮文字
    renderButtonTitle(item) {
      if (item.is_focus === 1) {
        if (item.is_fans === 1) {
          return '互相关注'
        }
        return '取消关注'
      } else {
        if (item.is_fans === 1) {
          return '回粉'
        }
        return '关注'
      }
    }
  },
  methods: {
    // 获取列表
    async getList(reset = false) {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        clerk_id: this.myClerkId,
        clerk_name: this.searchKey
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentFocusPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.list = res.page.list
        } else {
          this.list = this.list.concat(res.page.list)
        }
        if (this.list.length === 0) {
          this.isShow = true
        }
        this.pageNumber = res.page.pageNumber
        this.totalPage = res.page.totalPage
        this.totalRow = res.page.totalRow
      }
    },
    // 获取下一页
    async getNextPage() {
      this.pageNumber++
      this.getList()
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.loading = true
      await this.getList(true)
      setTimeout(() => {
        this.loading = false
      }, 800)
    },
    // 点击头像
    handlerClickAvatar(item) {
      this.$router.push({
        name: 'memberHome',
        params: { clerkId: item.clerk_id }
        // params: { is_click: true }
      })
    },
    // 添加关注
    async addFocus(index) {
      const formObj = {
        focus_clerk_id: this.list[index].clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.list[index], 'is_focus', 1)
      }
    },
    // 删除关注
    async deleteFocus(index) {
      const formObj = {
        focus_clerk_id: this.list[index].clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.list[index], 'is_focus', 0)
        this.$message({
          showClose: true,
          message: '取消关注成功！',
          type: 'success'
        })
        this.visible = false
      }
    },
    // 点击按钮
    async handlerClickButton(index) {
      if (this.list[index].is_focus === 0) {
        this.addFocus(index)
      } else {
        this.visible = true
        this.title = '确定确定要取消关注么？'
        this.cancleIndex = index
      }
    },
    // 取消弹窗
    cancle() {
      this.visible = false
    },
    // 取消关注
    confirm() {
      this.deleteFocus(this.cancleIndex)
    }
  },
  mounted() {
    this.refreshList()
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
    .attention-header-container {
        background-color: #f6f6f6;
        position: relative;
        z-index: 2;

        .attention-header {
            height: 54px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            border-radius: 6px;
            padding: 0 14px 0 20px;
            margin-bottom: 20px;

            .search-container {
                position: relative;
                margin: 4px 0;

                input {
                    outline: none;
                    border: none;
                    width: 165px;
                    height: 22px;
                    border-radius: 42px;
                    background-color: $light-grey-bg;
                    padding: 10px 15px 10px 48px;
                }

                i {
                    position: absolute;
                    top: 13px;
                    left: 19px;
                    color: $icon-color;
                }
            }
        }
    }

    .attention-container {
        height: calc(100vh - 158px);
        overflow: hidden;

        ::v-deep .back-top {
            margin-left: 500px;
            left: 50%;
            transform: translateX(-50%);
        }

        ::v-deep .no-more-tip {
            background-color: #fff;
            width: 614px;
            margin: 0 auto 35px;
            transform: translateX(-153px);
        }

        .perch {
            position: fixed;
            left: 50%;
            top: 60px;
            width: 614px;
            height: 94px;
            background-color: #f6f6f6;
            z-index: 2;
            transform: translateX(-460px);
        }

        .attention-list-container {
            width: 614px;
            background-color: #fff;
            margin: 0 auto;
            transform: translateX(-153px);
        }

        .attention-list {
            position: fixed;
            height: calc(100% - 138px);
            width: 100vw;
            width: 100vw;
            border-radius: 6px;
            overflow: scroll;
            left: 0;
            top: 0;
            padding-top: 160px;

            .member-name {
                font-size: 16px;
                color: #333;
                font-weight: 500;
                line-height: 24px;
            }

            .new-topic {
                color: #999;
                line-height: 21px;
            }

            .empty-container {
                padding-top: 208px;
                background-color: #fff;
                height: calc(100vh - 393px);
            }
        }
    }

    .el-skeleton-item {
        padding: 20px;
    }

    @media screen and (max-width: 767px) {
      .attention-header-container {
        position: fixed;
        width: 100vw;
        top: 0;

        .attention-header {
          margin-bottom: 10px;
        }
      }

      .attention-container {
        padding-top: 120px;

        ::v-deep .no-more-tip {
            background-color: #fff;
            width: 100vw;
            margin: 0 auto;
            transform: translateX(0);
        }

        .perch {
          display: none;
        }

        .attention-list {
          padding-top: 60px;
          background-color: #fff;
          height: calc(100% - 108px);
          .attention-list-container {
            width: 100vw;
            transform: translateX(0);
          }
        }
      }
    }
</style>
