/*
 * @Date: 2021-05-24 15:17:00
 * @LastEditTime: 2021-12-17 10:16:29
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import permission from './util/permission'
// 引入elementUI
import ElementUI from 'element-ui'
// 导入主题样式
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
// 导入需要使用的过滤器和方法
import '@/util/filter.js'
import util from '@/util/method.js'
// 导入封装的axios实例
import service from '@/util/my_axios.js'
import api from '@/util/api.js'
import config from '@/util/config.js'
// 导入iconfont
import '@/assets/font/iconfont.css'
import '@/assets/font/iconfont.js'

import '@/assets/css/ckeditor.css'

import CKEditor from '@ckeditor/ckeditor5-vue2'
// 复制链接插件
import VueClipboard from 'vue-clipboard2'
// 导入水印的指令
import '@/util/directives'
// 导入emoji
import emoji from '@/assets/expression/emoji.json'
// 导入目录插件
import outline from 'vue-outline-directory'
import globalEvent from '@/util/globalEvent.js'
// 自定义的时间选择器样式，需要加popper-class="date-type"
import '@/assets/css/datePicker.css'
// 引入全局方法
Vue.prototype.$config = config
Vue.prototype.$util = util
Vue.prototype.$api = api
// 获取当前视窗宽度
// let screenWidth = document.body.clientWidth
// window.addEventListener('resize', () => {
//   screenWidth = document.body.clientWidth
// })
// Vue.prototype.screenWidth = screenWidth

Vue.prototype.$emoji = emoji

// 如果是企业微信的先清除token
const ua = navigator.userAgent.toLowerCase()

if (/WxWork/i.test(ua)) {
  window.localStorage.removeItem('token')
}

Vue.config.productionTip = false

// 配置ElementUI插件
Vue.use(ElementUI)

Vue.use(CKEditor)

Vue.use(outline)
// 权限
Vue.use(permission)

Vue.use(globalEvent)

// 复制链接插件
Vue.use(VueClipboard)

// 把axios挂载到Vue原型上，以便使用
Vue.prototype.$http = service
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
