<template>
  <div class="flex main-container">
    <div class="left-content">
      <router-view></router-view>
    </div>
    <div class="right-bar hidden-xs-only">
      <div class="member-bar">
        <!-- 圈子-个人操作菜单 -->
        <member-menu
          :data-list="menu"
          :current="currentMenu"
          @change="change"
        ></member-menu>
        <div class="release-button" @click="sendMoment">发动态</div>
      </div>
    </div>
    <edit-message
      :visible="editVisible"
      @addComplete="addComplete"
      :momentId="editMomentId"
      @close="handlerCloseDialog"
    ></edit-message>
  </div>
</template>

<script>
// 用户菜单
import MemberMenu from '@/components/circle/MemberMenu.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
import { EventBusOn, EventBusEmit } from '@/util/EventBus.js'
import { mapState } from 'vuex'
export default {
  components: {
    MemberMenu,
    EditMessage
  },
  data() {
    return {
      currentMenu: 0,
      // 修改展示
      editVisible: false,
      // 修改的id
      editMomentId: -1,
      praiseNum: 0,
      mentionNum: 0,
      commentMentionNum: 0,
      commentNum: 0
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    menu() {
      return [
        {
          activeIcon: '#icon-Home1',
          icon: '#icon-Home',
          title: '我的主页',
          name: 'home',
          url: `/member/${this.myClerkId}/home`
        },
        {
          activeIcon: '#icon-a-AddUser1',
          icon: '#icon-a-AddUser',
          title: '我的关注',
          name: 'focus',
          url: '/member/attention'
        },
        // {
        //   activeIcon: '#icon-a-2User1',
        //   icon: '#icon-a-2User',
        //   title: '我的粉丝',
        //   name: 'fans',
        //   url: '/member/fans'
        // },
        {
          activeIcon: '#icon-a-heartfill1',
          icon: '#icon-a-heartfill',
          title: '我的喜欢',
          name: 'praise',
          url: '/member/like'
        },
        {
          activeIcon: '#icon-a-MoreCircle1',
          icon: '#icon-a-MoreCircle',
          title: '我的消息',
          name: 'message',
          url: '/member/message',
          badgeNum: this.praiseNum + this.mentionNum + this.commentMentionNum + this.commentNum
        },
        {
          activeIcon: '#icon-Setting1',
          icon: '#icon-Setting',
          title: '圈子设置',
          name: 'set',
          url: '/member/set'
        }
      ]
    }
  },
  methods: {
    // 添加圈子回调
    addComplete() {
      this.editVisible = false
      EventBusEmit('addMoment', '')
    },
    // 发动态
    sendMoment() {
      this.editMomentId = -1
      this.editVisible = true
    },
    // 关闭编辑弹窗
    handlerCloseDialog() {
      this.editVisible = false
    },
    // 动态选项
    change(e) {
      this.currentMenu = e
      // this.menu.url
      this.$router.push(this.menu[e].url)
    },
    // 圈子消息评论数量
    async getCommentCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.commentNum = res.count
      }
    },
    // 圈子消息点赞数量
    async getPraiseCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getPraiseCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.praiseNum = res.count
      }
    },
    // 圈子消息@数量
    async getMntionCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMntionCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.mentionNum = res.count
      }
    },
    // 圈子消息@评论数量
    async getCommentMntionCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentMntionCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.commentMentionNum = res.count
      }
    }
  },
  mounted() {
    this.getPraiseCount()
    this.getCommentCount()
    this.getMntionCount()
    this.getCommentMntionCount()
    EventBusOn('clearMomentMessage', (e) => {
      const index = this.menu.findIndex((e) => {
        return e.name === 'message'
      })
      switch (e) {
        case 'praise': {
          const praise = this.menu[index].badgeNum - this.praiseNum
          this.menu[index].badgeNum = praise > 0 ? praise : 0
          break
        }
        case 'mention': {
          const mention = this.menu[index].badgeNum - this.mentionNum
          this.menu[index].badgeNum = mention > 0 ? mention : 0
          break
        }
        case 'comment': {
          const comment = this.menu[index].badgeNum - this.commentNum
          this.menu[index].badgeNum = comment > 0 ? comment : 0
          break
        }
        case 'commnetMention': {
          const commnetMention =
            this.menu[index].badgeNum - this.commentMentionNum
          this.menu[index].badgeNum = commnetMention > 0 ? commnetMention : 0
          break
        }
      }
    })

    EventBusOn('getMobileTabs', (e) => {
      if (e.pageName === 'memberHome') {
        this.change(e.current)
      }
    })
  },
  watch: {
    // $route: {
    //   handler(val, old) {
    //     if (val.name === 'memberHome' && old?.matched[0].name !== 'member') {
    //       if (`${this.$route?.query?.clerk_id}` !== `${this.myClerkId}`) {
    //         this.$router.replace({
    //           name: 'circleHome',
    //           query: {
    //             clerk_id: this.$route?.query?.clerk_id
    //           }
    //         })
    //       }
    //     }
    //   },
    //   immediate: true
    // }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  width: 920px;
  margin: 24px auto 0;

  .left-content {
    margin-right: 24px;
    border-radius: 6px;
    width: 640px;
    overflow: hidden;
    height: calc(100vh - 90px);
  }

  .right-bar {
    width: 260px;
    background-color: #fff;
    border-radius: 6px;
    padding: 20px 16px;
    height: calc(100vh - 150px);
    overflow: auto;
    z-index: 2;

    .member-bar {
      // position: relative;
      .release-button {
        cursor: pointer;
        height: 21px;
        line-height: 21px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        border-radius: 42px;
        background: linear-gradient(90deg, #0075ff 0%, #00d1ff 100%);
        padding: 10px 0;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-container {
    width: 100vw;
    margin-top: 0;

    .left-content {
      width: 100vw;
      margin-right: 0;
    }
  }
}
</style>
