<!--
 * @Date: 2021-05-24 15:17:00
 * @LastEditTime: 2021-12-28 16:36:46
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <el-container
    class="home-container"
    :class="{
      'hide-header': isHideHeader
    }"
  >
    <!-- 头部，使用头部组件 -->
    <global-header ref="header" v-if="!isHideHeader"></global-header>
    <router-view />
  </el-container>
</template>

<script>
// 导入并使用头部组件
import GlobalHeader from '@/components/common/GlobalHeader.vue'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    GlobalHeader
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['myClerkId', 'myClerkInfo']),
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl']),
    // 是否隐藏头
    isHideHeader() {
      // 手机模式
      if (this.isXs) {
        switch (this.$route.name) {
          // 周报独立页
          // 月报独立页
          case 'workReportWeekDetail':
          case 'workReportMonthDetail':
            return true
        }
        // 更多的子级不要
        if (
          this.$route.matched.length > 2 &&
          this.$route.matched[1].name === 'more'
        ) {
          return true
        }
      }
      return false
    }
  },
  created() {
    // 如果是企业微信默认重新登录
    // if (this.isWxWork()) {
    //   this.logout()
    // }
    console.log('created')
    this.setRoute(this.$route)
  },
  mounted() {
    if (this.myClerkId !== -1) {
      this.$refs.header.init()
    }
  },
  methods: {
    ...mapActions(['getClerkInfo']),
    ...mapMutations(['setToken', 'setOffset', 'setRoute']),
    clickOut() {},
    // 是否为企业微信
    isWxWork() {
      const ua = navigator.userAgent.toLowerCase()
      return ua.match(/WxWork/i) === 'wxwork'
    }
  },
  watch: {
    clickBodyIndex() {
      this.clickOut()
    },
    myClerkId(val) {
      if (val !== -1) {
        this.$refs.header.init()
      }
    }
    // $route(val) {
    //   this.setRoute(val)
    // }
  }
}
</script>
<style lang="scss">
@import url('../assets/css/reset.css');
@import url('../assets/css/line.css');
@import url('../assets/css/text.css');
</style>
<style lang="scss">
@media screen and (min-width: 960px) {
  .el-icon-s-home::before {
    content: '';
    display: none;
  }

  .home-container {
    padding-top: 62px;
    background: #f6f6f6;
  }

  .el-menu.el-menu--horizontal {
    border: none !important;
    box-shadow: none !important;
  }

  // 关注按钮
  // 已关注
  // .focus-btn {
  //   @include pos-middle(absolute);
  //   border: none;
  //   outline: none;
  //   display: inline-block;
  //   width: 46px;
  //   line-height: 22px;
  //   right: 15px;
  //   font-size: 12px;
  //   text-align: center;
  //   border-radius: 4px;
  //   color: #999;
  //   background: transparent;

  //   // 未关注
  //   &.active {
  //     cursor: pointer;
  //     color: #fff;
  //     background: $main-color;
  //   }
  // }

  //   目标类型
  .target-type-box {
    line-height: 22px;
    cursor: pointer;
    padding: 0 9px;
    width: min-content;
    font-size: 12px;
    white-space: nowrap;
    color: #fff;
    border-radius: 4px;
    margin: 0 auto;
    &.week {
      background: $red;
    }
    &.month {
      background: #ffcf54;
    }
    &.quarter {
      background: #9554ff;
    }
  }

  // 关注按钮新样式
  .avatar-box {
    position: relative;
  }

  .focus-btn {
    position: absolute;
    display: block;
    cursor: pointer;
    top: -5px;
    right: -5px;
    background: transparent;
    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .search-result .avatar-box .focus-btn {
    top: -2px;
    right: 5px;
    z-index: 2;
  }

  // .member:hover {
  //     .focus-btn {
  //         margin-left: 15px;
  //         display: block;
  //         position: static;
  //         transform: none;
  //     }
  // }

  [class*='icon-gengduo']:hover {
    color: $main-color !important;
  }

  .emoji {
    font-size: 16px;
  }

  .htmledit_views {
    [class^='language-'] {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f3f4f5;
        // background: #d3d4d4;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #97999e;
      }
    }
  }

  .van-overlay.van-image-preview__overlay {
    background-color: #000;
  }
}

@media screen and (max-width: 960px) {
  .el-menu.el-menu--horizontal {
    border: none !important;
    box-shadow: none !important;
  }

  // 关注按钮
  // 已关注
  // .focus-btn {
  //   @include pos-middle(absolute);
  //   border: none;
  //   outline: none;
  //   display: inline-block;
  //   width: 46px;
  //   line-height: 22px;
  //   right: 15px;
  //   font-size: 12px;
  //   text-align: center;
  //   border-radius: 4px;
  //   color: #999;
  //   background: transparent;

  //   // 未关注
  //   &.active {
  //     cursor: pointer;
  //     color: #fff;
  //     background: $main-color;
  //   }
  // }

  //   目标类型
  .target-type-box {
    line-height: 22px;
    cursor: pointer;
    padding: 0 9px;
    width: min-content;
    font-size: 12px;
    white-space: nowrap;
    color: #fff;
    border-radius: 4px;
    margin: 0 auto;
    &.week {
      background: $red;
    }
    &.month {
      background: #ffcf54;
    }
    &.quarter {
      background: #9554ff;
    }
  }

  // 关注按钮新样式
  .avatar-box {
    position: relative;
  }

  .focus-btn {
    position: absolute;
    display: block;
    cursor: pointer;
    top: -5px;
    right: -5px;
    background: transparent;
    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .search-result .avatar-box .focus-btn {
    top: -2px;
    right: 5px;
    z-index: 2;
  }

  // .member:hover {
  //     .focus-btn {
  //         margin-left: 15px;
  //         display: block;
  //         position: static;
  //         transform: none;
  //     }
  // }

  [class*='icon-gengduo']:hover {
    color: $main-color !important;
  }

  .emoji {
    font-size: 16px;
  }

  .htmledit_views {
    [class^='language-'] {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f3f4f5;
        // background: #d3d4d4;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #97999e;
      }
    }
  }

  .van-overlay.van-image-preview__overlay {
    background-color: #000;
  }
  .home-container {
    padding-top: 0;
    padding-bottom: 64px;
    &.hide-header {
      padding-bottom: 0;
    }
  }
  .el-message-box {
    width: calc(100vw - 20px) !important;
  }
  .el-message {
    border: 1px solid red;
  }
}
</style>
