<!--
 * @Date: 2021-06-18 15:33:20
 * @LastEditTime: 2021-07-28 17:04:53
 * @Description: 单个任务提醒消息
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <div
    class="remind-item flex"
    @click="showTargetDetail(reminder.objective_id)"
  >
    <div class="remind-img-box">
      <avatar
        :userInfo="{
          clerk_avatar: reminder.create_clerk_avatar,
          clerk_alias: reminder.create_clerk_alias,
          clerk_name: reminder.create_clerk_name
        }"
        :size="40"
        :distanceRight="0"
      ></avatar>
    </div>
    <div class="flex1">
      <div class="flex flex-y-center flex-x-between remind-header">
        <!-- 部门和名字 -->
        <p class="remind-name" v-if="reminder && reminder.create_clerk_dept_name">
          {{ reminder.create_clerk_dept_name }}，{{
            reminder.create_clerk_alias || reminder.create_clerk_name
          }}
        </p>
        <p class="remind-name" v-else>
          系统消息
        </p>
        <!-- 时间 -->
        <p class="remind-time">{{ createTime }}</p>
      </div>
      <!-- 内容 -->
      <p class="remind-content">{{ reminder.reminder_content }}</p>
    </div>
  </div>
</template>

<script>
// 导入头像组件
import Avatar from '../common/Avatar.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'target-remind-item',
  components: {
    Avatar
  },
  props: {
    // 一个提醒的数据
    reminder: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['getToday']),
    createTime() {
      const now = this.getToday
      const date = new Date(this.reminder.create_time_timestamp)
      if (date.getFullYear() !== now.getFullYear()) {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).substr(
          -2
        )}-${date.getDate()}`
      }
      if (
        date.getMonth() !== now.getMonth() ||
        date.getDate() !== now.getDate()
      ) {
        return `${('0' + (date.getMonth() + 1)).substr(-2)}-${date.getDate()}`
      }
      return `${
        date.getHours() >= 13
          ? ('0' + (date.getHours() - 12)).substr(-2)
          : date.getHours()
      }:${('0' + date.getMinutes()).substr(-2)} ${
        date.getHours() <= 12 ? 'AM' : 'PM'
      }`
    }
  },
  methods: {
    // 点击展示目标详情
    showTargetDetail(id) {
      this.$emit('show-target-detail', id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/remind_style.scss';
</style>
