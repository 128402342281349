<!--
 * @Author: hzh
 * @Date: 2021-09-29 22:02:11
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-15 09:40:13
-->
<template>
  <massage-box
    :title="currentInfo.type === 2 ? '通知公告' : '会议公告'"
    :mask="true"
    :show-header-line="false"
    @handleClose="$emit('close')"
    top="10%"
  >
    <h1 style="margin-bottom: 24px">{{ currentInfo.title || '' }}</h1>
    <div
      style="line-height: 1.7"
      class="htmledit_views"
      v-html="currentInfo.content_html || ''"
    ></div>
    <!-- 刚才上传的附件预览 -->
    <div
      class="flex flex-wrap notice-file"
      v-if="currentInfo.type === 2 && appendixList.size !== 0"
    >
      <div v-for="(attachment, index) in appendixList" :key="index">
        <!-- 旧的附件 -->
        <attachment-item
          v-if="attachment.attachment_id && attachment.attachment_id > 0"
          :appendix="attachment"
          fileType="notice"
          :noticeId="currentInfo.id"
          :isDeletable="false"
          style="margin: 12px 0 0 0"
        >
        </attachment-item>
      </div>
    </div>
  </massage-box>
</template>

<script>
import MassageBox from './MessageBox.vue'
import AttachmentItem from '../common/AttachmentItem.vue'
export default {
  components: {
    MassageBox,
    AttachmentItem
  },
  props: {
    headerShow: {
      type: Boolean,
      default: false
    },
    currentInfo: {
      typeof: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      // 附件列表
      appendixList: []
    }
  },
  mounted() {
    if (this.currentInfo.type === 2) {
      this.getNoticeDetail()
    }
  },
  methods: {
    handlerContentToUrl(url) {
      const reg = new RegExp(
        '(http|https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
      const urlRes = reg.exec(url)
      const hasLink = Object.prototype.toString.call(urlRes) === '[object Array]'
      return hasLink
        ? url.replace(reg, `<a style="cursor: pointer;color: #0080FF;" href="${urlRes[0]}" target="_blank" >${urlRes[0]}</a>`)
        : url
    },
    // 获取通知详情
    async getNoticeDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getNoticeDetailL, {
          notice_id: this.currentInfo.id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.appendixList = res.info.notice_attachment_list
      }
    }
  },
  watch: {
    currentInfo(val) {
      this.appendixList = []
      if (val.type === 2) {
        this.getNoticeDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-file {
  margin-bottom: 20px;
  > div {
    width: 100%;
  }
}
.notice-file::v-deep .appendix-item {
  width: 100%;
  box-sizing: border-box;
  svg {
    width: 50px;
    height: 50px;
  }
  img {
    width: 50px;
    height: 50px;
  }
  .e {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}
@media screen and (max-width: 768px) {
  // .appendix-item {
  // width: 358px !important;
  // height: 60px !important;
  // }

  .notice-file {
    padding-bottom: 70px;
  }
}
</style>
