<!--
 * @Author: hzh
 * @Date: 2021-08-11 11:29:22
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-11 14:05:36
-->
<template>
  <div class="del-message-container">
    <div class="message-tips line-bottom">
      抱歉，此动态已被删除，无法查看。
    </div>
    <div class="center-like-container">
      <span @click="$emit('cancel')">取消喜欢</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.del-message-container {
  padding: 0 24px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 18px;

  .message-tips {
    font-size: 16px;
    line-height: 28px;
    padding: 30px 0;
    text-align: center;

    &.line-bottom::after {
      left: 0;
    }
  }

  .center-like-container {
    color: #999;
    padding: 24px 0;
    text-align: center;
  }
}
</style>
