<!--
 * @Author: hzh
 * @Date: 2021-12-23 18:01:30
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-27 11:39:35
-->
<template>
  <el-drawer
    :visible="visible"
    :append-to-body="true"
    :show-close="false"
    :size="450"
    :withHeader="false"
    :modal="false"
    @close="close"
    custom-class="work-report-week-dept-detail"
  >
    <template #default>
      <div class="content-box flex flex-y-positive">
        <div class="header-box flex flex-y-center flex-x-between">
          <span class="title-text">评论消息</span>
          <button type="button" class="close-btn" title="关闭" @click="close">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
          </button>
        </div>
        <div class="flex1 comment">
          <work-report-comment :reportId="myReportId" />
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import workReportComment from './work-report-comment'
export default {
  name: 'workReportCommentDrawer',
  components: {
    workReportComment
  },
  props: {
    // 展示
    visible: Boolean,
    // 报告id
    reportId: Number
  },
  data() {
    return {
      myReportId: null
    }
  },
  computed: {
    watchVisibleReportObj() {
      return {
        report_id: this.reportId,
        visible: this.visible
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    }
  },
  mounted() {},
  watch: {
    myReportId(val) {
      console.log(val)
    },
    watchVisibleReportObj: {
      handler({ visible, report_id: reportId }) {
        if (visible) {
          this.myReportId = reportId
        } else {
          this.myReportId = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  height: 100%;
}
.header-box {
  height: 60px;
  border-bottom: 1px solid #e5e8ee;
  padding: 0 30px;

  .title-text {
    color: #333333;
    font-size: 18px;
  }

  .close-btn,
  .close-btn .icon {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
}
.comment {
  padding: 20px 25px;
  overflow-y: scroll;
}
</style>
