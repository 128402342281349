<template>
  <message-box
    :title="`${info.per_group_id != null ? '修改' : '添加'}权限组`"
    top="8%"
    :mask="true"
    @handleClose="$emit('close')"
  >
    <div class="premission-message-box">
      <!-- 管理员搜索 -->
      <div class="premission-search">
        <div class="premission-label">
          名称<span style="color: red; margin-left: 2px">*</span>
        </div>
        <el-input
          placeholder="请输入权限组名称"
          maxlength="30"
          show-word-limit
          v-model="myName"
        ></el-input>
      </div>
      <!-- 权限配置 -->
      <div class="premission-set">
        <div class="premission-label">描述</div>
        <div class="permission-group">
          <el-input
            placeholder="请输入权限组描述"
            type="textarea"
            :rows="3"
            resize="none"
            v-model="myDescribe"
          ></el-input>
        </div>
        <div class="premission-list">
          <div class="title">权限配置</div>
          <div
            class="group-table"
            v-for="(item, index) in perTree"
            :key="index"
          >
            <div class="table-title">{{ item.name }}</div>
            <el-tree
              show-checkbox
              default-expand-all
              :expand-on-click-node="false"
              :data="item.children"
              node-key="id"
              :ref="`perTreeRef_${index}`"
            >
              <template #default="{ data }">
                <span class="table-item">
                  {{ data.name }}
                </span>
              </template>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="box-footer flex flex-x-end" style="padding: 20px 0">
        <el-button size="small" @click="$emit('close')">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </div>
    </template>
  </message-box>
</template>

<script>
import MessageBox from '@/components/common/MessageBox.vue'
import { perTree } from '@/util/permission.js'
export default {
  components: {
    MessageBox
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      perTree: perTree,
      myName: '',
      myDescribe: ''
    }
  },
  computed: {
    perTreeMap() {
      const map = {}
      const add = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            map[e.id] = {
              id: e.id,
              name: e.name,
              per_list: e.perList
            }
          }
          if (e.children) {
            add(e.children)
          }
        })
      }
      this.perTree.forEach((e) => {
        add(e.children)
      })
      return map
    },
    perTreeList() {
      const l = []
      const add = (list) => {
        list.forEach((e) => {
          if (e.perList) {
            l.push({
              id: e.id,
              name: e.name,
              per_list: e.perList
            })
          }
          if (e.children) {
            add(e.children)
          }
        })
      }
      this.perTree.forEach((e) => {
        add(e.children)
      })
      return l
    }
  },
  methods: {
    confirm() {
      const errmsg = (msg) => {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
      }
      if (this.myName === '') {
        errmsg('请输入权限组名称')
        return
      }
      let list = []
      this.perTree.forEach((e, i) => {
        this.$refs[`perTreeRef_${i}`][0].getCheckedKeys().forEach((ee, ii) => {
          if (this.perTreeMap[ee]) {
            list = list.concat(this.perTreeMap[ee].per_list || [])
          }
        })
      })
      this.$emit('confirm', {
        name: this.myName,
        describe: this.myDescribe,
        per_list: [...new Set(list)]
      })
    },
    // 获取包含的列表
    getPerList(list) {
      if (list.length === 0) {
        return []
      }
      return this.perTreeList.filter((e) => {
        const perList = e.per_list
        if (perList.length > list.length) {
          return false
        }

        for (const per of perList) {
          if (!list.includes(per)) {
            return false
          }
        }
        return true
      })
    }
  },
  mounted() {
    if (this.info.per_group_id != null) {
      this.myName = this.info.per_group_name || ''
      this.myDescribe = this.info.per_group_describe || ''
      const perList = this.getPerList(this.info.per_id_list).map((e) => e.id)
      this.perTree.forEach((e, i) => {
        this.$refs[`perTreeRef_${i}`][0].setCheckedKeys(perList)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.premission-message-box {
  height: 644px;
  padding: 14px 0 0;
  overflow: scroll;

  .premission-label {
    margin-bottom: 10px;
  }

  .el-input::v-deep {
    margin: 0 0 24px;
    width: 100%;
    .el-input__inner {
      width: 100%;
      height: 50px;
      line-height: 50px;
      border: 1px solid #eee;
    }
  }

  .el-textarea::v-deep {
    .el-textarea__inner {
      border: 1px solid #eee;
    }
  }

  .permission-group {
    margin-bottom: 20px;
  }

  .premission-list {
    border: 1px solid #eee;
    height: 100%;
    .title {
      height: 49px;
      line-height: 49px;
      padding: 0 24px;
    }

    .group-table {
      .table-title {
        height: 42px;
        line-height: 42px;
        padding: 0 24px;
        color: #999999;
        background-color: #f6f6f6;
      }

      .el-tree::v-deep {
        .el-icon-caret-right {
          display: none;
        }
        .el-tree-node__content {
          height: 42px;
          padding-left: 24px !important;
        }

        .el-tree-node__children {
          .el-tree-node__content {
            padding-left: 42px !important;
          }
        }
      }
    }
  }
}

.box-footer {
  .el-button {
    width: 80px;
  }
}
</style>
