<!--
 * @Author: hzh
 * @Date: 2021-09-11 12:28:29
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-25 10:06:43
-->
<template>
  <div class="flex share-knowledge" @click="toDetail">
    <div>
      <el-image
        v-if="isPic"
        :src="info.attachment_info.attachment_path"
        :preview-src-list="[info.attachment_info.attachment_path]"
        style="width: 52px; height: 52px; margin: 0 19px 0 16px"
        @click.native.stop=""
      >
      </el-image>
      <svg class="knowledge-icon" aria-hidden="true" v-else>
        <use :xlink:href="fileIcon"></use>
      </svg>
    </div>
    <div>
      <div class="knowledge-title">{{ info.knowl_name || '' }}</div>
      <div class="flex knowledeg-info">
        <span class="knowledeg-author line-right">
          {{
            info.update_clerk_alias ||
            info.update_clerk_name ||
            info.create_clerk_alias ||
            info.create_clerk_name
          }}</span
        >
        <span class="knowledeg-time"
          >发布于{{
            info.update_time || info.create_time
              ? $util.getTimeStr(info.update_time || info.create_time)
              : ''
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    // 附件信息
    attachmentInfo() {
      return this.info.attachment_info || {}
    },
    // 判断是否是图片
    isPic() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      return imgTypes.includes(type)
    },
    // 判断是否为图片
    isVideo() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      // 判断文件的扩展名是否是图片扩展名注意
      const type = file.file_ext
      const videoTypes = ['mp4']
      return videoTypes.includes(type)
    },
    // 获取文件的图标
    fileIcon() {
      if (!this.info.attachment_info) {
        return ''
      }
      const file = this.info.attachment_info
      const type = file.file_ext
      switch (type) {
        // 如果是doc文件
        case 'doc':
        case 'docx':
          return '#icon-Frame3'
        // 如果是PDF
        case 'pdf':
          return '#icon-Frame4'
        // 如果是Excel
        case 'xls':
        case 'xlsx':
          return '#icon-Frame2'
        // 如果是图片，返回图片本身
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'png':
        case 'gif':
          return file.attachment_path
        // 如果是PPT
        case 'ppt':
        case 'pptx':
          return '#icon-Frame5'
        // 如果是TXT
        case 'txt':
          return '#icon-Frame12'
        // 如果是其他
        default:
          return '#icon-Frame21'
      }
    }
  },
  methods: {
    // 跳转详情
    toDetail() {}
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.share-knowledge {
  background-color: #f6f6f6;
  min-height: 52px;
  width: calc(100% - 38px);
  border-radius: 6px;
  padding: 19px;
  margin-bottom: 10px;

  .knowledge-icon {
    width: 52px;
    height: 52px;
    margin-right: 19px;
  }

  .knowledge-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .knowledeg-info {
    color: #999999;
    font-size: 14px;

    .knowledeg-author {
      position: relative;
      padding-right: 12px;

      &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% - 6px);
        top: 3px;
        right: 0;
        background-color: #eee;
      }
    }

    .knowledeg-time {
      padding-left: 10px;
    }
  }
}
</style>
