<!--
 * @Date: 2021-05-24 16:22:52
 * @LastEditTime: 2021-12-31 10:29:14
 * @Description: 首页
 * @Author: Betty
 * @LastEditors: hzh
-->
<template>
  <!-- 首页 -->
  <el-container class="main-container">
    <el-row
      :gutter="screenWidth >= 768 ? 20 : 0"
      class="main"
      ref="mainRef"
      style="margin-left: 0; margin-right: 0"
    >
      <!-- 目标类型选择 -->
      <div
        v-show="isShowTargetTypes && currentTarget !== -1"
        :style="{
          left: targetTypeLeft + 'px',
          top: targetTypeTop + 'px'
        }"
        class="target-types"
      >
        <div
          class="target-type-item"
          v-for="(item, index) in targetTypeList"
          :key="index"
        >
          <div
            class="target-type-box"
            :class="[item.color]"
            @click="chooseTargetType(item.value)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <!-- 任务的更多弹窗 -->
      <div
        class="task-more-box"
        :style="{ left: taskMoreLeft + 'px', top: taskMoreTop + 'px' }"
        v-show="isShowTaskMore && currentTask !== -1"
      >
        <div class="more-box-item" @click="readyToAddChild(currentTask)">
          添加子任务
        </div>
        <div class="more-box-item" @click="readyEditTaskName(currentTask)">
          编辑任务
        </div>
        <div
          class="more-box-item red"
          v-if="currentTaskIsMe"
          @click="removeTask(currentTask)"
        >
          删除
        </div>
      </div>
      <!-- 任务的进度弹窗 -->
      <div
        class="task-process"
        v-show="isShowTaskProgress && currentTask !== -1"
        :style="{
          left: taskProgressLeft + 'px',
          top: taskProgressTop + 'px'
        }"
        @click.stop
      >
        <h6 class="process-title">当前进度</h6>
        <div class="flex flex flex-y-center">
          <input
            type="number"
            class="input"
            min="0"
            max="100"
            v-model="currentProgress"
            ref="editProgressRef"
            @blur="readyToEditProgress(currentTask)"
            @keyup.enter="toBlur($event, currentTask)"
          />
          <span class="percent">%</span>
        </div>
        <h6 class="process-title">状态</h6>
        <div class="radio-item">
          <label
            for=""
            class="label normal flex flex-y-center"
            @click="setTaskProgressStatus(1, currentTask)"
          >
            <svg class="icon radio-icon" aria-hidden="true">
              <use
                :xlink:href="
                  currentProgressStatus === 1
                    ? '#icon-Group706'
                    : '#icon-Group592'
                "
              ></use>
            </svg>
            <input
              type="radio"
              name="task"
              :checked="currentProgressStatus === 1"
              id=""
              class="radio"
            />
            <span>正常</span>
          </label>
        </div>
        <div class="radio-item">
          <label
            for=""
            class="label late flex flex-y-center"
            @click="setTaskProgressStatus(2, currentTask)"
          >
            <svg class="icon radio-icon" aria-hidden="true">
              <use
                :xlink:href="
                  currentProgressStatus === 2
                    ? '#icon-Group705'
                    : '#icon-Group593'
                "
              ></use>
            </svg>
            <input
              type="radio"
              name="task"
              :checked="currentProgressStatus === 2"
              id=""
              class="radio"
            />
            <span>已延期</span>
          </label>
        </div>
      </div>
      <!-- 目标的进度弹窗 -->
      <div
        class="task-process"
        v-show="isShowTargetProgress && currentTarget !== -1"
        :style="{
          left: targetProgressLeft + 'px',
          top: targetProgressTop + 'px'
        }"
        @click.stop
      >
        <h6 class="process-title">当前进度</h6>
        <div class="flex flex flex-y-center">
          <input
            type="number"
            class="input"
            min="0"
            max="100"
            v-model="currentTargetProgress"
            ref="editTargetProgressRef"
            @blur="readyToEditTargetProgress(currentTarget)"
            @keyup.enter="toBlur($event, currentTarget)"
          />
          <span class="percent">%</span>
        </div>
        <h6 class="process-title">状态</h6>
        <div class="radio-item">
          <label
            for=""
            class="label normal flex flex-y-center"
            @click="setTargetProgressStatus(1, currentTarget)"
          >
            <svg class="icon radio-icon" aria-hidden="true">
              <use
                :xlink:href="
                  currentTargetProgressStatus === 1
                    ? '#icon-Group706'
                    : '#icon-Group592'
                "
              ></use>
            </svg>
            <input
              type="radio"
              name="task"
              :checked="currentTargetProgressStatus === 1"
              id=""
              class="radio"
            />
            <span>正常</span>
          </label>
        </div>
        <div class="radio-item">
          <label
            for=""
            class="label late flex flex-y-center"
            @click="setTargetProgressStatus(2, currentTarget)"
          >
            <svg class="icon radio-icon" aria-hidden="true">
              <use
                :xlink:href="
                  currentTargetProgressStatus === 2
                    ? '#icon-Group705'
                    : '#icon-Group593'
                "
              ></use>
            </svg>
            <input
              type="radio"
              name="task"
              :checked="currentTargetProgressStatus === 2"
              id=""
              class="radio"
            />
            <span>已延期</span>
          </label>
        </div>
      </div>
      <!-- 目标的更多弹窗 -->
      <div
        class="task-more-box"
        :style="{ left: targetMoreLeft + 'px', top: targetMoreTop + 'px' }"
        v-show="isShowTargetMore && currentTarget !== -1"
      >
        <div
          class="more-box-item"
          @click.stop="readyEditTargetName(currentTarget)"
        >
          编辑目标
        </div>
        <div
          class="more-box-item red"
          v-if="currentTargetIsMe"
          @click.stop="removeTarget"
        >
          删除
        </div>
      </div>
      <!-- 左半边 -->
      <el-col
        :lg="{ span: 18 }"
        :sm="{ span: 24 }"
        :style="{
          paddingRight: screenWidth <= 768 ? '10px' : '10px',
          paddingLeft: screenWidth <= 768 ? '10px' : 0
        }"
      >
        <!-- 左边第一块，日期和当前剩余任务 -->
        <div class="white-box">
          <el-row>
            <!-- 左边，显示日期 -->
            <el-col :sm="{ span: 24 }" :lg="{ span: 5 }" class="weather">
              <p class="date-text">{{ todayText }}</p>
              <p class="hello">{{ helloText }}</p>
            </el-col>
            <!-- 右半边 -->
            <el-col :sm="{ span: 24 }" :lg="{ span: 19 }" class="rest-task">
              <!-- 下半部分 -->
              <div class="task-data flex flex-y-start">
                <div
                  class="flex1 task-data-item"
                  v-for="(item, index) in taskData"
                  :style="{ paddingBottom: screenWidth >= 768 ? '15px' : ' 0' }"
                  :key="index"
                >
                  <p class="task-data-title">{{ item.title }}</p>
                  <em
                    class="num"
                    :class="{ 'em-num': item.type < 4 }"
                    @click="toTaskPage(item.type)"
                    >{{ item.num }}</em
                  >
                  <!-- 延期的标志 -->
                  <div
                    class="delay-tag"
                    v-if="item.lateCount > 0"
                    @click="toTaskDelayPage(item.type)"
                  >
                    已延期 {{ item.lateCount }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 通知公告 -->
        <div
          class="notice right-box"
          style="margin-top: 20px"
          v-if="screenWidth <= 1200"
        >
          <!-- 头部 -->
          <div class="right-box-header flex flex-y-center flex-x-between">
            <h5 class="right-box-title">
              {{
                noticeList[currentNoticeIndex] &&
                noticeList[currentNoticeIndex].type === 1
                  ? '会议公告'
                  : '通知公告'
              }}
            </h5>
            <!-- 更多按钮 -->
            <button class="more-btn" type="button" @click.stop="showNoticeMenu">
              <span class="iconfont icon-gengduo2"></span>
            </button>
            <!-- 更多弹窗 -->
            <div class="more-box" v-show="isShowNoticeMenu">
              <div class="more-box-item" @click="freshNotice">刷新</div>
            </div>
          </div>
          <div class="content-box">
            <!-- 通知内容 -->
            <el-carousel
              ref="noticeCarousel"
              trigger="click"
              height="150px"
              arrow="never"
              indicator-position="none"
              @change="currentNoticeIndex = $event"
            >
              <el-carousel-item
                v-for="(item, index) in noticeList"
                :key="index"
              >
                <div class="notice-item">
                  <h6
                    class="notice-title e"
                    @click="openNoticeDetail(item.key)"
                  >
                    {{ item.title }}
                  </h6>
                  <!-- 通知内容 -->
                  <p
                    class="notice-content e2"
                    @click="openNoticeDetail(item.key)"
                  >
                    {{ item.content }}
                  </p>
                  <!-- 通知的日期 -->
                  <div class="notice-date">
                    {{
                      noticeList[currentNoticeIndex] &&
                      noticeList[currentNoticeIndex].type === 1
                        ? item.date
                        : $util.getDateStr(new Date(item.date))
                    }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 左边第二大块，目标列表 -->
        <target-list-box
          :nowTime="todayDate"
          ref="targetBox"
          :currentTarget="currentTarget"
          @show-type-choose="beginChooseTargetType($event)"
          @show-progress-set="beginSetTargetProgress($event)"
          @show-more="showTargetMore($event)"
          @to-target-detail="showTargetDetail($event)"
        ></target-list-box>
        <!-- 左边第三大块，任务列表 -->
        <task-home-box
          :nowTime="todayDate"
          ref="taskBox"
          :currentTask="currentTask"
        ></task-home-box>
        <!-- 左边第四块，完成统计 -->
        <div class="white-box complete-box">
          <!-- 头部 -->
          <div class="complete-header flex flex-y-center flex-x-between">
            <h5 class="complete-title">完成统计</h5>
            <button
              class="more"
              type="button"
              @click.stop="toggleCompleteFresh"
            >
              <span
                class="iconfont icon-gengduo3"
                style="font-size: 22px"
              ></span>
            </button>
            <!-- 更多弹窗 -->
            <div class="more-box refresh" v-show="isShowCompleteFresh">
              <div class="more-item">刷新</div>
            </div>
          </div>
          <!-- 主要部分 -->
          <el-row style="padding-top: 10px">
            <!-- 左边，大的进度条 -->
            <el-col :lg="{ span: 11 }" :sm="{ span: 24 }">
              <!-- 上半部分，进度条 -->
              <div class="complete-progress flex flex-x-center">
                <div class="data">已完成</div>
                <div class="percent">{{ totalPercent }}</div>
                <el-progress
                  type="circle"
                  :show-text="false"
                  :stroke-width="14"
                  :width="135"
                  :percentage="totalPercent"
                  color="#0080ff"
                ></el-progress>
              </div>
              <!-- 下半部分，提示 -->
              <div class="flex flex-y-end flex-x-center progress-tip">
                <!-- 总任务 -->
                <div class="progress-tip-item">
                  <em class="progress-tip-num">{{ totalTaskCount }}</em>
                  <p class="progress-tip-title">总任务</p>
                </div>
                <!-- 已完成 -->
                <div class="progress-tip-item">
                  <i class="progress-tip-color active"></i>
                  <em class="progress-tip-num">{{ finishedTaskCount }}</em>
                  <p class="progress-tip-title">已完成</p>
                </div>
                <!-- 未完成 -->
                <div class="progress-tip-item">
                  <i class="progress-tip-color grey"></i>
                  <em class="progress-tip-num">{{ noFinishTaskCount }}</em>
                  <p class="progress-tip-title">未完成</p>
                </div>
              </div>
            </el-col>
            <!-- 右边，昨天任务的完成情况 -->
            <el-col :lg="{ span: 13 }" :sm="{ span: 24 }">
              <!-- 第一行，昨天完成的任务 -->
              <div class="flex complete-num flex-y-center">
                <!-- 左边，进度条 -->
                <div class="flex1 progress-box">
                  <!-- 图标和文字 -->
                  <div class="flex flex-y-center icon-line">
                    <svg class="icon check-icon" aria-hidden="true">
                      <use xlink:href="#icon-confirmfill"></use>
                    </svg>
                    <p class="complete-info">
                      昨日完成任务数：<em class="num">{{ yesterdayFinish }}</em>
                    </p>
                  </div>
                  <!-- 进度条 -->
                  <el-progress
                    :percentage="yesterdayPercent"
                    :stroke-width="10"
                    :show-text="false"
                    color="#0080ff"
                  ></el-progress>
                </div>
                <!-- 右边 -->
                <div>
                  <p class="task-num">总任务：{{ yesterdayTotalCount }}</p>
                  <p class="task-num">
                    未完成：{{ yesterdayTotalCount - yesterdayFinish }}
                  </p>
                </div>
              </div>
              <!-- 第二行，本周完成的任务 -->
              <div class="flex complete-num flex-y-center">
                <!-- 左边，进度条 -->
                <div class="flex1 progress-box">
                  <!-- 图标和文字 -->
                  <div class="flex flex-y-center icon-line">
                    <svg class="icon check-icon" aria-hidden="true">
                      <use xlink:href="#icon-confirmfill"></use>
                    </svg>
                    <p class="complete-info">
                      本周完成任务数：<em class="num">{{ weekTotalFinish }}</em>
                    </p>
                  </div>
                  <!-- 进度条 -->
                  <el-progress
                    :percentage="weekPercent"
                    :stroke-width="10"
                    :show-text="false"
                    color="#0080ff"
                  ></el-progress>
                </div>
                <!-- 右边 -->
                <div>
                  <p class="task-num">总任务：{{ weekTotalCount }}</p>
                  <p class="task-num">
                    未完成：{{ weekTotalCount - weekTotalFinish }}
                  </p>
                </div>
              </div>
              <!-- 第三行，本月完成的任务 -->
              <div class="flex complete-num flex-y-center">
                <!-- 左边，进度条 -->
                <div class="flex1 progress-box">
                  <!-- 图标和文字 -->
                  <div class="flex flex-y-center icon-line">
                    <svg class="icon check-icon" aria-hidden="true">
                      <use xlink:href="#icon-confirmfill"></use>
                    </svg>
                    <p class="complete-info">
                      本月完成任务数：<em class="num">{{
                        monthTotalFinish
                      }}</em>
                    </p>
                  </div>
                  <!-- 进度条 -->
                  <el-progress
                    :percentage="monthPercent"
                    :stroke-width="10"
                    :show-text="false"
                    color="#0080ff"
                  ></el-progress>
                </div>
                <!-- 右边 -->
                <div>
                  <p class="task-num">总任务：{{ monthTotalCount }}</p>
                  <p class="task-num">
                    未完成：{{ monthTotalCount - monthTotalFinish }}
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <!-- 右半边 -->
      <el-col
        :lg="{ span: 6 }"
        :sm="{ span: 24 }"
        :style="{
          paddingLeft: screenWidth <= 768 ? '10px' : '10px',
          paddingRight: screenWidth <= 768 ? '10px' : 0,
          marginLeft: screenWidth <= 1200 && screenWidth > 768 ? '-10px' : 0
        }"
      >
        <!-- 第一块，通知公告 -->
        <div class="notice right-box" v-if="screenWidth > 1200">
          <!-- 头部 -->
          <div class="right-box-header flex flex-y-center flex-x-between">
            <h5 class="right-box-title">
              {{
                noticeList[currentNoticeIndex] &&
                noticeList[currentNoticeIndex].type === 1
                  ? '会议公告'
                  : '通知公告'
              }}
            </h5>
            <!-- 更多按钮 -->
            <button class="more-btn" type="button" @click.stop="showNoticeMenu">
              <span class="iconfont icon-gengduo2"></span>
            </button>
            <!-- 更多弹窗 -->
            <div class="more-box" v-show="isShowNoticeMenu">
              <div class="more-box-item" @click="freshNotice">刷新</div>
            </div>
          </div>
          <div class="content-box">
            <!-- 通知内容 -->
            <el-carousel
              ref="noticeCarousel"
              trigger="click"
              height="150px"
              arrow="never"
              indicator-position="none"
              @change="currentNoticeIndex = $event"
            >
              <el-carousel-item
                v-for="(item, index) in noticeList"
                :key="index"
              >
                <div class="notice-item">
                  <h6
                    class="notice-title e"
                    @click="openNoticeDetail(item.key)"
                  >
                    {{ item.title }}
                  </h6>
                  <!-- 通知内容 -->
                  <p
                    class="notice-content e2"
                    @click="openNoticeDetail(item.key)"
                  >
                    {{ item.content }}
                  </p>
                  <!-- 通知的日期 -->
                  <div class="notice-date">
                    {{ item.date }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 第二块，工作提醒 -->
        <div class="task-remind right-box">
          <!-- 头部 -->
          <div class="right-box-header flex flex-y-center flex-x-between">
            <h5 class="right-box-title">工作提醒</h5>
            <!-- 更多按钮 -->
            <button
              class="more-btn"
              type="button"
              @click.stop="toggleShowRemindMenu"
            >
              <span class="iconfont icon-gengduo2"></span>
            </button>
            <!-- 更多弹窗 -->
            <div class="more-box" v-show="isShowRemindMenu">
              <div class="more-box-item" @click="refreshRemindMenu">刷新</div>
            </div>
          </div>
          <!-- 滚动刷新的数据组件 -->
          <scroll-fresh
            className="task-remind-data"
            dataName="task-remind"
            :page="taskRemindPageInfo.pageNumber"
            :lastPage="taskRemindPageInfo.totalPage"
            @get-next-page="getRemindNextPage($event)"
          >
            <template v-slot:default>
              <!-- 显示任务提醒的数据 -->
              <div
                v-for="item in taskRemindList"
                :key="`${item.reminder_type}${item.reminder_id}`"
              >
                <task-remind-item
                  :reminder="item"
                  @show-task-detail="showTaskDetail($event)"
                  v-if="item.reminder_type === 1"
                >
                </task-remind-item>
                <target-remind-item
                  :reminder="item"
                  v-if="item.reminder_type === 2"
                  @show-target-detail="showTargetDetail($event)"
                >
                </target-remind-item>
              </div>
            </template>
          </scroll-fresh>
        </div>
        <!-- 第三块，评论消息 -->
        <div class="comment-news right-box">
          <!-- 头部 -->
          <div class="flex flex-y-start flex-x-between">
            <!-- tab栏 -->
            <el-tabs
              v-model="commentActiveTab"
              @tab-click="chooseMessageType"
              style="width: 100%"
            >
              <el-tab-pane label="目标" name="target">
                <span slot="label"
                  >目标<i class="label-badge" v-show="targetCommentCount > 0">{{
                    targetCommentCount | renderCommentNumber
                  }}</i></span
                >
                <scroll-fresh
                  className="target-comment-box"
                  dataName="targetComment"
                  :page="getTargetCommentInfo.pageNumber"
                  :lastPage="getTargetCommentInfo.totalPage"
                  @get-next-page="getTargetCommentNextPage($event)"
                >
                  <!-- 每一条评论提醒 -->
                  <comment-message-item
                    v-for="(item, index) in targetMessageList"
                    :key="index"
                    :comment="item"
                    type="target"
                    @to-detail="toTargetDetail($event)"
                  ></comment-message-item>
                </scroll-fresh>
              </el-tab-pane>
              <el-tab-pane label="任务" name="task">
                <span slot="label"
                  >任务<i class="label-badge" v-show="taskCommentCount > 0">{{
                    taskCommentCount | renderCommentNumber
                  }}</i></span
                >
                <div class="content-box">
                  <scroll-fresh
                    className="task-comment-box"
                    dataName="taskComment"
                    :page="getTaskCommentInfo.pageNumber"
                    :lastPage="getTaskCommentInfo.totalPage"
                    @get-next-page="getTaskCommentNextPage($event)"
                  >
                    <!-- 每一条评论提醒 -->
                    <comment-message-item
                      v-for="(item, index) in taskMessageList"
                      :key="index"
                      :comment="item"
                      type="task"
                      @to-detail="toTaskDetail($event)"
                    ></comment-message-item>
                  </scroll-fresh>
                </div>
              </el-tab-pane>
              <el-tab-pane label="圈子" name="circle">
                <span slot="label"
                  >圈子<i class="label-badge" v-show="circleCommentCount > 0">{{
                    circleCommentCount | renderCommentNumber
                  }}</i></span
                >
                <div class="content-box">
                  <scroll-fresh
                    className="circle-comment-box"
                    dataName="circleComment"
                    :page="getCircleCommentInfo.pageNumber"
                    :lastPage="getCircleCommentInfo.totalPage"
                    @get-next-page="getCircleCommentNextPage($event)"
                  >
                    <!-- 每一条评论提醒 -->
                    <comment-message-item
                      v-for="(item, index) in circleMessageList"
                      :key="index"
                      :comment="item"
                      type="circle"
                      @to-detail="toCircleDetail($event)"
                    ></comment-message-item>
                  </scroll-fresh>
                </div>
              </el-tab-pane>
            </el-tabs>
            <!-- <h5 class="right-box-title"></h5> -->
            <!-- 更多按钮 -->
            <button
              class="more-btn"
              type="button"
              @click.stop="toggleShowCommentMenu"
            >
              <span class="iconfont icon-gengduo2"></span>
            </button>
            <!-- 更多弹窗 -->
            <div class="more-box" v-show="isShowCommentMenu">
              <div class="more-box-item" @click="refreshCommentdMenu">刷新</div>
            </div>
          </div>
        </div>
        <!-- 第四块，圈子动态 -->
        <div class="ring-dynamic right-box" v-if="false">
          <!-- 头部 -->
          <div class="right-box-header flex flex-y-center flex-x-between">
            <h5 class="right-box-title">圈子动态</h5>
            <!-- 更多按钮 -->
            <button class="more-btn" type="button">
              <span class="iconfont icon-gengduo2"></span>
            </button>
            <!-- 更多弹窗 -->
            <div class="more-box" v-show="isShowRingMenu">
              <div class="more-box-item">刷新</div>
            </div>
          </div>
          <!-- 展示圈子动态 -->
          <div class="content-box">
            <div
              v-for="(item, index) in ringDynamicList"
              :key="index"
              class="flex ring-dynamic-item"
            >
              <div class="img-box">
                <avatar
                  :userInfo="item.clerk"
                  :size="40"
                  :distanceRight="0"
                ></avatar>
              </div>
              <!-- 右边，展示动态内容 -->
              <div class="flex1">
                <!-- 发布人名字 -->
                <p class="name">{{ item.clerk_alias || item.clerk_name }}</p>
                <!-- 发布时间 -->
                <p class="dynamic-time">{{ item.time }}</p>
                <!-- 内容 -->
                <p class="dynamic-content pre">
                  {{ item.content }}
                </p>
                <!-- 图片 -->
                <div
                  class="flex flex-wrap dynamic-imgs"
                  v-if="item.imgList && item.imgList.length > 0"
                >
                  <div
                    class="img-item-box"
                    v-for="(img, index2) in img.imgList"
                    :key="index2"
                  >
                    <img :src="img" alt="" class="img-item" />
                  </div>
                </div>
                <!-- 点赞和评论 -->
                <div class="flex flex-x-end flex-y-center love-comment-box">
                  <!-- 点赞 -->
                  <div class="flex flex-y-center btn-item">
                    <svg class="icon dynamic-handle-icon" aria-hidden="true">
                      <use xlink:href="#icon-heart"></use>
                    </svg>
                    <p class="num">{{ item.loveCount }}</p>
                  </div>
                  <!-- 评论 -->
                  <div class="flex flex-y-center btn-item">
                    <svg class="icon dynamic-handle-icon" aria-hidden="true">
                      <use xlink:href="#icon-comment"></use>
                    </svg>
                    <p class="num">{{ item.commentCount }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 右边的弹窗 -->
    <!-- 任务详情弹窗 -->
    <task-detail
      :isShow="isShowTaskDetail"
      @close-detail="closeDetailModal($event)"
      :taskId="currentTaskDetail"
      :nowTime="todayDate"
      :isMe="true"
      :currentTab="taskCurrentTab"
      @change-task-id="changeTaskDetail($event)"
      @change-target-id="changeTargetDetail($event)"
    ></task-detail>
    <!-- 目标详情弹窗 -->
    <target-detail
      :isShow="isShowTargetDetail"
      @close-detail="closeTargetDetailModal($event)"
      :targetId="currentTargetDetail"
      :nowTime="todayDate"
      :fromWhere="fromWhere"
      :currentTab="targetCurrentTab"
      @change-target-id="changeTargetDetail($event)"
      @change-task-id="changeTaskDetail"
    >
    </target-detail>
    <message-detail-pop
      @searchTopic="searchTopic"
      @searchMention="searchMention"
      :visible="isShowCircleDetail"
      :momentId="currentCircle"
      @close="closeCircleDetail"
      @toEdit="circleDetailToEdit"
    ></message-detail-pop>
    <edit-message
      :visible="isShowCircleEdit"
      @editComplete="isShowcircleEdit = false"
      :momentId="currentEditCircle"
      @close="closeCircleEdit"
    ></edit-message>
    <!-- 通知弹窗 -->
    <Announcements
      v-if="currentNoticeKey !== ''"
      :currentInfo="currentNoticeInfo"
      @close="closeNoticePop"
    ></Announcements>
  </el-container>
</template>

<script>
// 导入任务详情弹窗
import TaskDetail from '../components/task/TaskDetail.vue'
// 导入目标详情弹窗
import TargetDetail from '../components/target/TargetDetail.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
// 导入头像组件
import Avatar from '../components/common/Avatar.vue'
// 导入滚动刷新组件
import ScrollFresh from '../components/common/ScrollFresh.vue'
// 导入任务提醒组件
import TaskRemindItem from '../components/task/TaskRemindItem.vue'
// 导入目标提醒组件
import TargetRemindItem from '../components/target/TargetRemindItem.vue'
// 导入目标组件
import TargetListBox from '../components/target/TargetListBox.vue'
// 导入任务组件
import TaskHomeBox from '../components/task/TaskHomeBox.vue'
// 导入评论消息组件
import CommentMessageItem from '../components/common/CommentMessageItem.vue'
// 侧边详情
import MessageDetailPop from '@/components/circle/MessageDetailPop.vue'
import EditMessage from '@/components/circle/EditMessage.vue'
import Announcements from '@/components/common/Announcements.vue'
export default {
  components: {
    TaskDetail,
    Avatar,
    ScrollFresh,
    TaskRemindItem,
    TargetListBox,
    TaskHomeBox,
    TargetRemindItem,
    TargetDetail,
    CommentMessageItem,
    MessageDetailPop,
    EditMessage,
    Announcements
  },
  data() {
    return {
      // 天气
      weather: {
        pic: require('../assets/img/weather.png'),
        text: '28-36℃'
      },
      // 当前在编辑谁的名字
      editTaskId: -1,
      // 当前在编辑哪个面板
      currentPanel: '',
      // 是否显示完成情况的刷新
      isShowCompleteFresh: false,
      // 今日任务长度
      todayLength: 0,
      // 今日延期的任务
      todayLateCount: 0,
      thisWeekLength: 0,
      thisWeekLateCount: 0,
      thisMonthLength: 0,
      thisMonthLateCount: 0,
      thisQuarterLength: 0,
      thisQuarterLateCount: 0,
      // 当前操作的任务id
      currentTask: -1,
      currentTaskDetail: -1,
      // 任务的更多弹窗的位置
      taskMoreTop: 0,
      taskMoreLeft: 0,
      // 是否显示任务的更多弹窗
      isShowTaskMore: false,
      // 任务的进度弹窗的位置
      taskProgressTop: 0,
      taskProgressLeft: 0,
      // 是否显示任务的进度弹窗
      isShowTaskProgress: false,
      // window滚动到了哪里
      windowScrollTop: 0,
      // main与页面的距离
      mainTop: 84,
      mainLeft: 110,
      // 当前任务是否延期
      currentProgressStatus: 1,
      // 是否显示任务详情
      isShowTaskDetail: false,
      // 今天的日期
      todayDate: new Date(),
      // 周一和周日的日期
      MondayDate: null,
      SundayDate: null,
      // 本月的第一天和最后一天
      monthFirstDate: null,
      monthLastDate: null,
      // 本季度的第一天和最后一天
      quarterFirstDate: null,
      quarterLastDate: null,
      // 当前任务的进度
      currentProgress: 0,
      // 临时记录进度
      temp: 0,
      // 已完成任务总数
      finishedTaskCount: 0,
      // 未完成任务总数
      noFinishTaskCount: 0,
      // 昨天总任务
      yesterdayTotalCount: 0,
      // 昨天完成
      yesterdayFinish: 0,
      // 本周总任务
      weekTotalCount: 0,
      // 本周完成
      weekTotalFinish: 0,
      // 本月总任务
      monthTotalCount: 0,
      // 本月完成
      monthTotalFinish: 0,
      // 当前正要添加子任务的任务id
      currentPid: 0,
      // 会议通知动态
      meetingNoticeList: [],
      // 普通通知
      normalNoticeList: [],
      // 是否展示通知公告这里的更多菜单
      isShowNoticeMenu: false,
      // 任务提醒的数据
      taskRemindList: [],
      // 任务提醒的分页情况
      taskRemindPageInfo: {
        lastPage: false,
        pageNumber: 1,
        totalPage: 0,
        firstPage: true,
        totalRow: 0,
        pageSize: 20
      },
      // 是否展示出提醒的更多菜单
      isShowRemindMenu: false,
      // 是否展示出圈子动态的更多
      isShowRingMenu: false,
      // 圈子动态数据
      ringDynamicList: [
        {
          clerk: {
            clerk_id: 100,
            clerk_avatar: require('../assets/img/img2.png')
          },
          clerk_alias: '张三',
          clerk_name: '小王',
          time: '今天11:00',
          content: '固执不可怕，可怕的是盲目的不听劝阻的固执！分分钟被气晕！',
          loveCount: 100,
          commentCount: 10
        }
      ],
      // 当前是几点钟
      currentHour: 0,
      // 定时器
      t: null,
      // 目标的数据
      // 是否显示目标类型选项
      isShowTargetTypes: false,
      // 当前操作的目标id
      currentTarget: -1,
      currentTargetDetail: -1,
      // 目标类型选择的弹窗的left位置
      targetTypeLeft: 0,
      // 目标类型选择的弹窗的top位置
      targetTypeTop: 0,
      // 当前目标原本的类型
      currentTargetOldType: 1,
      // 是否展示目标进度弹窗
      isShowTargetProgress: false,
      // 目标进度弹窗的left位置
      targetProgressLeft: 0,
      // 目标进度弹窗的top位置
      targetProgressTop: 0,
      // 当前目标进度
      currentTargetProgress: 0,
      // 当前目标是否延期
      currentTargetProgressStatus: 0,
      // 目标更多弹窗的left位置
      targetMoreLeft: 0,
      // 目标更多弹窗的top位置
      targetMoreTop: 0,
      // 是否展示目标的更多弹窗
      isShowTargetMore: false,
      // 当前目标的创建人
      currentTargetCreate: -1,
      // 本月未完成任务数量
      monthToFinish: 0,
      // 本周未完成的任务数量
      weekToFinish: 0,
      // 今天未完成任务数量
      todayToFinish: 0,
      // 今日会议
      todayMeetingCount: 0,
      // 本周会议
      thisWeekMeetingCount: 0,
      // 是否展示出目标详情
      isShowTargetDetail: false,
      // 目标详情从哪里来
      fromWhere: null,
      // 是否正在加载记录
      isLoadingLog: false,
      // 目标当前tab
      targetCurrentTab: 'first',
      // 任务当前tab
      taskCurrentTab: 'first',
      // 是否展示评论的刷新菜单
      isShowCommentMenu: false,
      // 当前评论提醒的tab
      commentActiveTab: 'target',
      // 评论提醒的列表
      taskMessageList: [],
      targetMessageList: [],
      circleMessageList: [],
      // 是否正在加载目标评论
      isLoadingTargetComment: false,
      // 获取目标评论的分页情况
      getTargetCommentInfo: {
        pageNumber: 1,
        pageSize: 10,
        totalPage: 0
      },
      // 是否正在加载任务评论
      isLoadingTaskComment: false,
      // 获取任务评论的分页情况
      getTaskCommentInfo: {
        pageNumber: 1,
        pageSize: 10,
        totalPage: 0
      },
      // 是否正在加载圈子评论
      isLoadingCircleComment: false,
      // 获取圈子评论的分页情况
      getCircleCommentInfo: {
        pageNumber: 1,
        pageSize: 10,
        totalPage: 0
      },
      // 任务评论统计
      taskCommentCount: 0,
      // 目标评论统计
      targetCommentCount: 0,
      // 圈子评论统计
      circleCommentCount: 0,
      // 是否显示圈子详情
      isShowCircleDetail: false,
      // 是否显示圈子编辑
      isShowCircleEdit: false,
      // 圈子详情
      currentCircle: -1,
      // 圈子修改id
      currentEditCircle: -1,
      // 当前通知展示
      currentNoticeIndex: 0,

      // 通知弹窗id
      currentNoticeKey: ''
    }
  },
  computed: {
    ...mapState([
      'myClerkId',
      'timeOffset',
      'targetTypeList',
      'screenWidth',
      'clickBodyIndex'
    ]),
    // 当前任务是否为自己的
    currentTaskIsMe() {
      let find
      if (this.currentTask === -1) {
        return false
      }
      // 对任务的查找不分块了，只查一块的数据
      if (this.$refs.taskBox && this.$refs.taskBox.getData()) {
        const taskList = this.$refs.taskBox.getData().taskListData
        find = taskList.find((item) => {
          return item.task_id === this.currentTask
        })
      }
      // if (this.$refs.quarterBox) {
      //   if (find == null) {
      //     find = this.$refs.quarterBox.getData().find((item) => {
      //       return item.task_id === this.currentTask
      //     })
      //   }
      // }
      if (find != null) {
        return find.create_clerk_id === this.myClerkId
      }
      return false
    },
    // 判断当前目标是否是自己的
    currentTargetIsMe() {
      return this.currentTargetCreate === this.myClerkId
    },
    // 把今天日期转成年月日格式
    todayText() {
      const today = this.todayDate
      return `${today.getFullYear()}年${
        today.getMonth() + 1
      }月${today.getDate()}日`
    },
    // 任务的类型和数量
    taskData() {
      return [
        {
          title: '今日任务',
          num: this.todayToFinish,
          lateCount: this.todayLateCount,
          type: 1
        },
        {
          title: '本周任务',
          num: this.weekToFinish,
          lateCount: this.thisWeekLateCount,
          type: 2
        },
        {
          title: '本月任务',
          num: this.monthToFinish,
          lateCount: this.thisMonthLateCount,
          type: 3
        },
        {
          title: '今日会议',
          num: this.todayMeetingCount,
          lateCount: 0,
          type: 4
        },
        {
          title: '本周会议',
          num: this.thisWeekMeetingCount,
          lateCount: 0,
          type: 5
        }
      ]
    },
    // 总任务的数量
    totalTaskCount() {
      return this.finishedTaskCount + this.noFinishTaskCount
    },
    // 总任务完成的百分比
    totalPercent() {
      if (this.totalTaskCount === 0) {
        return 0
      } else {
        return (
          Math.floor(
            ((this.finishedTaskCount || 0) / (this.totalTaskCount || 0)) * 100
          ) || 0
        )
      }
    },
    // 昨天任务完成的百分比
    yesterdayPercent() {
      if (this.yesterdayTotalCount === 0) {
        return 0
      } else {
        return (
          Math.floor((this.yesterdayFinish / this.yesterdayTotalCount) * 100) ||
          0
        )
      }
    },
    // 本周任务完成的百分比
    weekPercent() {
      if (this.weekTotalCount === 0) {
        return 0
      }
      return Math.floor((this.weekTotalFinish / this.weekTotalCount) * 100) || 0
    },
    // 本月任务完成的百分比
    monthPercent() {
      if (this.monthTotalCount === 0) {
        return 0
      }
      return (
        Math.floor((this.monthTotalFinish / this.monthTotalCount) * 100) || 0
      )
    },
    // 打招呼
    helloText() {
      if (this.currentHour < 5) {
        return '深夜了~'
      } else if (this.currentHour < 11) {
        return '早上好！'
      } else if (this.currentHour < 14) {
        return '中午好！'
      } else if (this.currentHour < 18) {
        return '下午好！'
      } else {
        return '晚上好'
      }
    },
    // 合并通知列表
    noticeList() {
      let list = []
      // 会议通知
      list = list.concat(
        this.meetingNoticeList.map((e) => {
          return {
            type: 1,
            title: e.meeting_name,
            content: e.meeting_content_text,
            date: e.start_time,
            key: `1_${e.meeting_id}`,
            id: e.meeting_id,
            content_html: e.meeting_content_html
          }
        })
      )
      // 普通通知
      list = list.concat(
        this.normalNoticeList.map((e) => {
          return {
            type: 2,
            title: e.notice_name,
            content: e.notice_content_text,
            date: this.$util.getDateStr(
              new Date(e.start_time.replace(/-/g, '/'))
            ),
            key: `2_${e.notice_id}`,
            id: e.notice_id,
            content_html: e.notice_content_html
          }
        })
      )
      //   [
      //   {
      //     title: '本周五举办大型招商会议',
      //     content:
      //       '全体招商部门参加，设计部门提前准备广告素材，周三前制作完成，招商部门注意邀约客户，下午2点30分准时参加...',
      //     date: '2021-06-20'
      //   }
      // ]
      return list
    },
    // 通知弹窗信息
    currentNoticeInfo() {
      return this.noticeList.find((e) => e.key === this.currentNoticeKey)
    }
  },
  filters: {
    renderCommentNumber(v) {
      return v > 99 ? '99' : v
    }
  },
  watch: {
    clickBodyIndex() {
      this.outClick()
    },
    noticeList(newVal) {
      if (newVal.length > 0) {
        this.$refs.noticeCarousel.setActiveItem(0)
      }
    },
    // 切换评论提醒的tab
    commentActiveTab(val) {
      switch (val) {
        case 'target': {
          this.targetMessageList = []
          this.getTargetCommentList(1)
          break
        }
        case 'task': {
          this.taskMessageList = []
          this.getTaskCommentList(1)
          break
        }
        case 'circle': {
          this.circleMessageList = []
          this.getCircleCommentList(1)
          if (this.circleCommentCount > 0) {
            this.clearCircleCommentCount()
          }
          break
        }
        default: {
          console.log('暂无实现')
        }
      }
    },
    // 监听$route，如果后退，route改变，要关闭详情弹窗
    $route(val) {
      if (!val?.query?.task_id) {
        this.isShowTaskDetail = false
      }
      if (!val?.query?.objective_id) {
        this.isShowTargetDetail = false
      }
      if (!val?.query?.circle_id) {
        this.isShowCircleDetail = false
      }
      if (!val?.query?.notice_id) {
        this.currentNoticeKey = ''
      }
      if (val?.query?.notice_id) {
        this.currentNoticeKey = `2_${val?.query?.notice_id}`
      }
    }
  },
  created() {
    // 获取当前时间
    this.getTodayDate()
    // 获取周一的日期
    this.MondayDate = this.$util.getMondayDate(this.todayDate)
    // 获取周日的日期
    this.SundayDate = this.$util.getSundayDate(this.todayDate)
    // 获取本月第一天的日期
    this.monthFirstDate = this.$util.getMonthFirstDate(this.todayDate)
    // 获取本月最后一天的日期
    this.monthLastDate = this.$util.getMonthLastDate(this.todayDate)
    // 获取本季第一天日期
    this.quarterFirstDate = this.$util.getSeasonFirstDate(this.todayDate)
    // 获取本季最后一天日期
    this.quarterLastDate = this.$util.getSeasonLastDate(this.todayDate)
  },
  mounted() {
    console.log(this.$router)
    // 访问任务列表，并转成树形结构
    this.refreshTaskList()
    // 获取会议通知列表
    this.getMeetingNoticeList()
    // 获取普通通知列表
    this.getNoticeList().then(() => {
      if (this.$route.query.notice_id) {
        const info = this.normalNoticeList.find((e) => {
          return this.$route.query.notice_id === `${e.notice_id}`
        })
        if (info != null) {
          this.openNoticeDetail(`2_${info.notice_id}`)
        }
      }
    })
    // 获取已完成和未完成的任务总数
    // this.getTaskCountData()
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
    // document.addEventListener('click', this.outClick)
    // 监听屏幕滚动事件
    window.addEventListener('scroll', this.scrollToTop)
    // 获取任务提醒列表
    this.getTaskRemindList(this.taskRemindPageInfo.pageNumber)
    // 每个小时判断一下打招呼语句
    this.t = setInterval(() => {
      this.getTodayDate()
    }, 60000)

    // 获取目标评论数据
    this.getTargetCommentList(1)

    // 获取目标评论统计
    this.getTargetCommentCount()
    // 获取任务评论统计
    this.getTaskCommentCount()
    // 获取圈子评论统计
    this.getCircleCommentCount()

    // 自动打开详情
    if (this.$route.query.task_id) {
      this.currentTaskDetail = Number(this.$route.query.task_id)
      this.isShowTaskDetail = true
      if (this.$route.query.detail_tab) {
        switch (this.$route.query.detail_tab) {
          case 'comment': {
            this.taskCurrentTab = 'third'
          }
        }
      }
    } else if (this.$route.query.objective_id) {
      this.currentTargetDetail = Number(this.$route.query.objective_id)
      this.isShowTargetDetail = true
      if (this.$route.query.detail_tab) {
        switch (this.$route.query.detail_tab) {
          case 'comment': {
            this.targetCurrentTab = 'third'
          }
        }
      }
    } else if (this.$route.query.circle_id) {
      this.currentCircle = Number(this.$route.query.circle_id)
      this.isShowCircleDetail = true
    }
  },
  beforeDestroy() {
    this.formEditor && this.formEditor.destroy()
    // 清空定时器
    clearInterval(this.t)
  },
  provide() {
    return {
      Parent: this
    }
  },
  methods: {
    ...mapActions(['getToday']),
    ...mapMutations(['setOffset']),
    // 搜索话题
    searchTopic(e) {
      this.closeCircleDetail()
      this.$router.push({
        name: 'circleIndex',
        query: { topic_id: e }
      })
    },
    // 进入提及人主页
    searchMention(e) {
      this.closeCircleDetail()
      if (this.myClerkId === e) {
        this.$router.push({
          name: 'memberHome',
          query: { clerk_id: this.elementId }
        })
      } else {
        this.$router.push({
          name: 'circleHome',
          query: { clerk_id: e }
        })
      }
    },
    getTodayCount() {
      this.todayLength = this.$refs.todayBox.getCount().taskCount
      this.todayLateCount = this.$refs.todayBox.getCount().lateCount
    },
    getWeekCount() {
      this.thisWeekLength = this.$refs.weekBox.getCount().taskCount
      this.thisWeekLateCount = this.$refs.weekBox.getCount().lateCount
    },
    getMonthCount() {
      this.thisMonthLength = this.$refs.monthBox.getCount().taskCount
      this.thisMonthLateCount = this.$refs.monthBox.getCount().lateCount
    },
    // 删除任务
    removeTask(id) {
      this.$refs.taskBox.removeTask(id)
    },
    // 获取任务提醒列表
    async getTaskRemindList(page = 1) {
      this.isLoadingLog = true
      console.log('准备')
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskAndTargetRemind, {
          pageNumber: page,
          pageSize: this.taskRemindPageInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        const { page } = res
        // 把数据接到我的展示数组最后
        this.taskRemindList = [...this.taskRemindList, ...page.list]
        // 除了接数据，也更新一下我记录的分页情况
        this.taskRemindPageInfo.pageNumber = page.pageNumber
        this.taskRemindPageInfo.totalPage = page.totalPage
        if (page.pageNumber === page.totalPage) {
          this.taskRemindPageInfo.lastPage = true
        }
        this.isLoadingLog = false
      } else {
        this.isLoadingLog = false
      }
    },
    // 获取任务提醒的下一页
    getRemindNextPage({ id, page }) {
      console.log('数据准备刷新', id, page)
      if (id === 'task-remind') {
        this.taskRemindPageInfo.pageNumber = page
        if (!this.isLoadingLog) {
          // 发起下一页的请求
          this.getTaskRemindList(this.taskRemindPageInfo.pageNumber)
        }
      }
    },
    // 是否展示出任务提醒的刷新按钮
    toggleShowRemindMenu() {
      this.isShowRemindMenu = !this.isShowRemindMenu
    },
    // 刷新任务提醒
    refreshRemindMenu() {
      // 要让它显示出第一页的数据，是只显示第一页，而不是把第一页追加到最后
      // 所以清空数组
      this.taskRemindList = []
      // 然后请求第一页数据
      if (!this.isLoadingLog) {
        this.getTaskRemindList(1)
      }
      this.isShowRemindMenu = false
    },
    // 点击任务提醒，展示出任务详情弹窗
    showTaskDetail(id) {
      this.currentTaskDetail = id
      this.isShowTaskDetail = true
      this.openTaskDetail(id)
    },
    // 点击目标提醒，展示出目标详情弹窗
    showTargetDetail(id) {
      this.currentTargetDetail = id
      this.isShowTargetDetail = true
      this.openTargetDetail(id)
    },
    changeTaskDetail(id) {
      this.currentTargetDetail = -1
      this.showTaskDetail(id)
      // if (this.isShowTargetDetail) {
      //   this.isShowTargetDetail = false
      //   this.showTaskDetail(id)
      // } else {
      //   this.currentTaskDetail = id
      // }
    },
    // 关闭任务面板的输入框
    closeInputEdit() {
      this.editTaskId = -1
    },
    // 计算当前时间
    getTodayDate() {
      this.getToday().then((res) => {
        this.todayDate = res
        // 获取一下当前是几点钟
        this.currentHour = this.todayDate.getHours()
      })
    },
    // 准备编辑任务名
    readyEditTaskName(id) {
      console.log('准备编辑任务了，id是', id)
      this.editTaskId = id
    },
    // 刷新任务列表
    refreshTaskList() {
      // 想办法把任务未完成的有多少个，有几个延期拿到手
      this.$refs.taskBox.getTaskList()
      // 重新统计任务的完成情况
      this.getTaskCountData()
    },
    closeFresh() {
      // 是否显示完成情况的刷新
      this.isShowCompleteFresh = false
    },
    // 显示隐藏任务完成情况的刷新
    toggleCompleteFresh() {
      this.closeFresh()
      this.isShowCompleteFresh = !this.isShowCompleteFresh
    },
    // 展示出任务的更多弹窗
    showTaskMore({ left, top, id }) {
      this.isShowTaskMore = true
      this.isShowTaskProgress = false
      console.log(left, top)
      // 给它赋值当前操作的id，以及位置
      this.currentTask = id
      this.taskMoreTop = top - this.mainTop
      this.taskMoreLeft = left - this.mainLeft
      console.log(this.taskMoreTop, this.taskMoreLeft)
    },
    // 触发blur事件
    toBlur(e, args) {
      e.target.blur(args)
    },
    // 点到外面，就关闭更多和进度弹窗
    outClick() {
      this.currentTask = -1
      this.currentTarget = -1
      this.isShowTaskMore = false
      this.isShowTaskProgress = false
      this.isShowTargetTypes = false
      this.isShowTargetProgress = false
      this.isShowTargetMore = false
      // 是否显示完成情况的刷新
      if (this.isShowCompleteFresh) {
        this.isShowCompleteFresh = false
      }
      // 是否显示完成情况的刷新
      if (this.isShowRemindMenu) {
        this.isShowRemindMenu = false
      }
      // 是否显示完成情况的刷新
      if (this.isShowNoticeMenu) {
        this.isShowNoticeMenu = false
      }
      if (this.isShowCommentMenu) {
        this.isShowCommentMenu = false
      }
    },
    // 开始选择目标类型
    beginChooseTargetType({ top, left, id, oldVal, clerkId }) {
      this.isShowTargetTypes = true
      this.currentTarget = id
      this.currentTargetCreate = clerkId
      this.targetTypeTop = top - this.mainTop
      this.targetTypeLeft = left - this.mainLeft
      this.currentTargetOldType = oldVal
    },
    // 点击选择目标类型
    async chooseTargetType(val) {
      if (val !== this.currentTargetOldType) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTargetType, {
            objective_id: this.currentTarget,
            objective_type: val
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '目标类型设置成功',
            type: 'success'
          })
          this.closeChooseTargetType()
          // 刷新目标数据
          this.$refs.targetBox.getTargetList()
        }
      } else {
        this.closeChooseTargetType()
      }
    },
    // 关闭选择目标类型的弹窗
    closeChooseTargetType() {
      this.isShowTargetTypes = false
      this.currentTarget = -1
      this.currentTargetCreate = -1
      this.currentTargetOldType = 1
    },
    // 展示出任务的进度弹窗
    showTaskProgress({ left, top, id, progress, status }) {
      this.isShowTaskProgress = true
      this.isShowTaskMore = false
      // 给它赋值当前操作的id，以及位置
      this.currentTask = id
      this.taskProgressTop = top - this.mainTop + 15
      this.taskProgressLeft = left - this.mainLeft - 25
      console.log(this.taskProgressTop, this.taskProgressLeft)
      // 根据子组件传过来的进度数据，回填到input上
      this.currentProgress = progress
      // 临时记录进度
      this.temp = progress
      // 获取一下当前任务是否延期
      this.currentProgressStatus = status
      console.log('当前任务是否延期', this.currentProgressStatus)
    },
    // 准备提交新的任务进度
    async readyToEditProgress(id) {
      const progresValue = this.currentProgress
      // 假如把里面的数字删干净了，视为没有修改
      if (progresValue.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$refs.editProgressRef.blur()
      } else if (Number(progresValue) < 0 || Number(progresValue) > 100) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentProgress = this.temp
        this.$refs.editProgressRef.blur()
      } else {
        // 假如输入正确，那就发起修改请求
        this.$refs.editProgressRef.blur()
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTaskProgress, {
            task_id: id,
            progress_number: this.currentProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '任务的进度修改成功！',
              showClose: true
            })
            // 刷新任务列表
            this.refreshTaskList()
            // 让进度弹窗消失
            this.currentTask = -1
          }
        }
      }
    },
    // 到数组里找到现在在修改的任务，修改它的进度
    updateTaskProgress(id) {
      const progressNumber = +this.currentProgress
      const todayIndex = this.todayTaskData.findIndex(
        (item) => item.task_id === id
      )
      if (todayIndex !== -1) {
        this.$set(
          this.todayTaskData[todayIndex],
          'progress_number',
          progressNumber
        )
      }
      const weekIndex = this.thisWeekTaskData.findIndex(
        (item) => item.task_id === id
      )
      if (weekIndex !== -1) {
        this.$set(
          this.thisWeekTaskData[weekIndex],
          'progress_number',
          progressNumber
        )
      }
      const monthIndex = this.thisMonthTaskData.findIndex(
        (item) => item.task_id === id
      )
      if (monthIndex !== -1) {
        this.$set(
          this.thisMonthTaskData[monthIndex],
          'progress_number',
          progressNumber
        )
      }
      const quarterIndex = this.thisQuarterTaskData.findIndex(
        (item) => item.task_id === id
      )
      if (quarterIndex !== -1) {
        this.$set(
          this.thisQuarterTaskData[quarterIndex],
          'progress_number',
          progressNumber
        )
      }
      // 修改完数据以后，把数据转为树形数据
      this.todayTaskList = this.$util.handleTreeData(this.todayTaskData)
      this.thisWeekTaskList = this.$util.handleTreeData(this.thisWeekTaskData)
      this.thisMonthTaskList = this.$util.handleTreeData(this.thisMonthTaskData)
      this.thisQuarterTaskList = this.$util.handleTreeData(
        this.thisQuarterTaskData
      )
    },
    // 点击单选框，修改任务的进度状态
    async setTaskProgressStatus(val, id) {
      // 获取到我点击的最新值
      this.currentProgressStatus = val
      console.log(this.currentProgressStatus)
      // 发请求，修改任务的进度状态
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskProgressStatus, {
          task_id: id,
          progress_status: this.currentProgressStatus
        })
      )
      if (err) {
        console.log(res)
      } else {
        console.log(res)
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改任务进度状态成功！',
            type: 'success'
          })
          this.isShowTaskProgress = false
          // 刷新任务数据
          this.refreshTaskList()
        }
      }
    },
    // 开始修改目标进度
    beginSetTargetProgress({ left, top, id, progress, status, clerkId }) {
      this.isShowTargetProgress = true
      this.currentTarget = id
      this.isShowTargetMore = false
      this.currentTargetCreate = clerkId
      // 设置目标进度弹窗的位置
      this.targetProgressTop = top - this.mainTop + 15
      this.targetProgressLeft = left - this.mainLeft - 25
      // 记录当前目标的进度
      this.currentTargetProgress = progress
      this.temp = progress
      this.currentTargetProgressStatus = status
    },
    // 准备修改目标进度
    async readyToEditTargetProgress(id) {
      const progresValue = this.currentTargetProgress
      // 假如把里面的数字删干净了，视为没有修改
      if (progresValue.length === 0) {
        // 还原成本来的数据，并失去焦点
        this.currentTargetProgress = this.temp
        this.$refs.editTargetProgressRef.blur()
      } else if (Number(progresValue) < 0 || Number(progresValue) > 100) {
        // 假如数字小于0或者大于100，给出提示
        this.$message({
          type: 'error',
          message: '进度的范围必须在0-100之间，请输入正确的进度！',
          showClose: true
        })
        // 还原成本来的数据，并失去焦点
        this.currentTargetProgress = this.temp
        this.$refs.editTargetProgressRef.blur()
      } else {
        // 假如输入正确，那就发起修改请求
        this.$refs.editTargetProgressRef.blur()
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.editTargetProgressNumber, {
            objective_id: id,
            progress_number: this.currentTargetProgress
          })
        )
        if (err) {
          console.log(err)
        } else {
          console.log(res)
          if (res.state === 'ok') {
            this.$message({
              type: 'success',
              message: '目标的进度修改成功！',
              showClose: true
            })
            // 刷新目标列表
            this.$refs.targetBox.getTargetList()
            // 让进度弹窗消失
            this.currentTarget = -1
            this.currentTargetCreate = -1
          }
        }
      }
    },
    // 展示出目标的更多弹窗
    showTargetMore({ top, left, id, clerkId, target }) {
      this.isShowTargetMore = true
      this.isShowTargetProgress = false
      this.currentTarget = id
      this.currentTargetCreate = clerkId
      this.tempTargetName = target.objective_name
      // 设置目标进度弹窗的位置
      this.targetMoreTop = top - this.mainTop + 15
      this.targetMoreLeft = left - this.mainLeft - 25
    },
    // 准备编辑目标名称
    readyEditTargetName() {
      this.$refs.targetBox.readyToEdit()
      this.isShowTargetMore = false
    },
    // 删除目标
    removeTarget() {
      this.isShowTargetMore = false
      const formObj = {
        objective_id: this.currentTarget
      }
      this.$util.showConfirm('确定要删除这个目标吗？').then(async (result) => {
        if (result) {
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.deleteTarget, formObj)
          )
          if (err) {
            console.log(err)
          } else if (res.state === 'ok') {
            this.$message({
              showClose: true,
              message: '删除目标成功',
              type: 'success'
            })
            this.$refs.targetBox.getTargetList()
          }
        }
      })
    },
    // 设置目标的进度状态是延期还是正常
    async setTargetProgressStatus(val, id) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetProgressStatus, {
          objective_id: id,
          progress_status: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改目标进度状态成功！',
          type: 'success'
        })
        this.currentTargetProgressStatus = val
        // 刷新目标列表
        this.$refs.targetBox.getTargetList()
      }
    },
    // 页面滚动的时候，同步计算父级相对于窗口的距离
    scrollToTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // 记录这一次的滚动位置，以便赋值
      this.windowScrollTop = scrollTop
      console.log()
      const el = this.$refs.mainRef ? this.$refs.mainRef.$el : ''
      if (el) {
        // 获取一下main距离页面顶端和左端的距离
        this.mainTop =
          el.getBoundingClientRect().top + document.documentElement.scrollTop
        this.mainLeft =
          el.getBoundingClientRect().left + document.documentElement.scrollLeft
      }
    },
    // 关闭任务详情
    closeDetailModal({ is_update_data: flag }) {
      this.taskCurrentTab = 'first'
      this.isShowTaskDetail = false
      if (flag) {
        // 如果确实有修改了任务里面的内容，那就刷新任务列表
        this.refreshTaskList()
      }
      this.closeTaskDetail()
    },
    // 展示任务详情
    openDetailModal(id) {
      this.isShowTaskDetail = true
      this.currentTaskDetail = id
      this.openTaskDetail(id)
    },
    // 统计任务，返回任务数量
    async getTaskCount({
      status,
      beginDate,
      endDate,
      completeStartDate,
      completeEndDate,
      ProgressStatus
    }) {
      // 要统计的是已完成还是未完成
      const formObj = {}
      if (status !== -1) {
        formObj.is_complete = status
      }
      // 如果有传入开始/结束日期，就加到表单数据里去
      if (beginDate) {
        formObj.period_start_date = this.$util.getDateStr(beginDate)
      }
      if (endDate) {
        formObj.period_end_date = this.$util.getDateStr(endDate)
      }
      if (completeStartDate) {
        formObj.complete_start_time = this.$util.getDateStr(completeStartDate)
      }
      if (completeEndDate) {
        formObj.complete_end_time = this.$util.getDateStr(completeEndDate)
      }
      // 如果统计的是已延期任务
      if (ProgressStatus) {
        formObj.progress_status = ProgressStatus
      }
      // 数据准备好了就提交
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.taskCount, formObj)
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          return res.count
        }
      }
    },
    // 获取你要的所有任务的统计
    async getTaskCountData() {
      // 获取总的已完成任务
      const [err1, res1] = await this.$util.to(this.getTaskCount({ status: 1 }))
      if (err1) {
        console.log(err1)
      } else {
        this.finishedTaskCount = res1
      }
      // 获取总的未完成任务
      const [err2, res2] = await this.$util.to(this.getTaskCount({ status: 0 }))
      if (err2) {
        console.log(err2)
      } else {
        this.noFinishTaskCount = res2
      }
      // 获取昨天的总任务
      const yesterday = new Date(this.todayDate - 86400000)
      const [err3, res3] = await this.$util.to(
        this.getTaskCount({
          status: -1,
          beginDate: yesterday,
          endDate: yesterday
        })
      )
      if (err3) {
        console.log(err3)
      } else {
        this.yesterdayTotalCount = res3
      }
      // 获取昨天的已完成
      const [err4, res4] = await this.$util.to(
        this.getTaskCount({
          status: 1,
          beginDate: yesterday,
          endDate: yesterday,
          completeStartDate: yesterday,
          completeEndDate: yesterday
        })
      )
      if (err4) {
        console.log(err4)
      } else {
        this.yesterdayFinish = res4
      }
      // 获取本周的任务
      const Monday = this.$util.getMondayDate(this.todayDate)
      const Sunday = this.$util.getSundayDate(this.todayDate)
      const [err5, res5] = await this.$util.to(
        this.getTaskCount({
          status: -1,
          beginDate: Monday,
          endDate: Sunday
        })
      )
      if (err5) {
        console.log(err5)
      } else {
        this.weekTotalCount = res5
      }
      // 获取本周的任务
      const [err6, res6] = await this.$util.to(
        this.getTaskCount({
          status: 1,
          beginDate: Monday,
          endDate: Sunday,
          completeStartDate: Monday,
          completeEndDate: Sunday
        })
      )
      if (err6) {
        console.log(err6)
      } else {
        this.weekTotalFinish = res6
      }
      // 本周未完成任务
      this.weekToFinish = res5 - res6
      // 获取本月的总任务
      const firstDate = this.$util.getMonthFirstDate(this.todayDate)
      const lastDate = this.$util.getMonthLastDate(this.todayDate)
      const [err7, res7] = await this.$util.to(
        this.getTaskCount({
          status: -1,
          beginDate: firstDate,
          endDate: lastDate
        })
      )
      if (err7) {
        console.log(err7)
      } else {
        this.monthTotalCount = res7
      }
      // 获取本月的已完成任务
      const [err8, res8] = await this.$util.to(
        this.getTaskCount({
          status: 1,
          beginDate: firstDate,
          endDate: lastDate,
          completeStartDate: firstDate,
          completeEndDate: lastDate
        })
      )
      if (err8) {
        console.log(err8)
      } else {
        this.monthTotalFinish = res8
      }
      // 获取本月的未完成任务
      this.monthToFinish = res7 - res8
      // 获取今天未完成任务
      const [err9, res9] = await this.$util.to(
        this.getTaskCount({
          status: 0,
          beginDate: this.todayDate,
          endDate: this.todayDate
        })
      )
      if (err9) {
        console.log(err9)
      } else {
        this.todayToFinish = res9
      }
      // 获取今天的延期任务
      const [err10, res10] = await this.$util.to(
        this.getTaskCount({
          status: 0,
          beginDate: this.todayDate,
          endDate: this.todayDate,
          ProgressStatus: 2
        })
      )
      if (err10) {
        console.log(err10)
      } else {
        this.todayLateCount = res10
      }
      // 获取本周的延期任务
      const [err11, res11] = await this.$util.to(
        this.getTaskCount({
          status: 0,
          beginDate: Monday,
          endDate: Sunday,
          ProgressStatus: 2
        })
      )
      if (err10) {
        console.log(err11)
      } else {
        this.thisWeekLateCount = res11
      }
      // 获取本月的延期任务
      const [err12, res12] = await this.$util.to(
        this.getTaskCount({
          status: 0,
          beginDate: Monday,
          endDate: Sunday,
          ProgressStatus: 2
        })
      )
      if (err12) {
        console.log(err12)
      } else {
        this.thisMonthLateCount = res12
      }
      // 获取今日会议未开始数量
      const [err13, res13] = await this.$util.to(
        this.getMeetingCount(this.todayDate, this.todayDate)
      )
      if (err13) {
        console.log(err13)
      } else if (res13) {
        this.todayMeetingCount = res13
      }
      // 获取本周会议未开始数量
      const [err14, res14] = await this.$util.to(
        this.getMeetingCount(Monday, Sunday)
      )
      if (err14) {
        console.log(err14)
      } else if (res14) {
        this.thisWeekMeetingCount = res14
      }
    },
    // 获取未开始的会议数量
    async getMeetingCount(start, end) {
      const formObj = {
        start_start_time: this.$util.getDateStr(start),
        start_end_time: this.$util.getDateStr(end),
        meeting_status: 2,
        is_cancel: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        return res.count
      }
    },
    // 点击显示添加子元素的输入框
    readyToAddChild(id) {
      this.currentPid = id
    },
    // 隐藏添加子元素的输入框
    hindInputChild() {
      this.currentPid = 0
    },
    // 获取会议通知列表
    async getMeetingNoticeList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingNoticeList, {})
      )
      if (err) {
        console.log(err)
        console.log('发请求出错')
      } else if (res.state === 'ok') {
        this.meetingNoticeList = res.list
      }
    },
    // 关闭目标弹窗详情
    closeTargetDetailModal({ is_update_data: flag }) {
      this.targetCurrentTab = 'first'
      this.isShowTargetDetail = false
      this.fromWhere = null
      if (flag) {
        this.$refs.targetBox.getTargetList()
      }
      this.closeTargetDetail()
    },
    // 切换目标详情
    changeTargetDetail({ id, fromWhere }) {
      this.currentTaskDetail = -1
      this.showTargetDetail(id)
      this.fromWhere = fromWhere
      // if (this.isShowTaskDetail) {
      //   this.isShowTaskDetail = false
      //   this.showTargetDetail(id)
      // } else {
      //   this.currentTargetDetail = id
      //   this.fromWhere = fromWhere
      // }
    },
    // 展示通知刷新按钮
    showNoticeMenu() {
      this.isShowNoticeMenu = true
    },
    // 刷新通知
    freshNotice() {
      this.getMeetingNoticeList()
      this.getNoticeList()
    },
    // 显示隐藏评论提醒的刷新
    toggleShowCommentMenu() {
      this.isShowCommentMenu = !this.isShowCommentMenu
    },
    // 获取目标评论提醒
    async getTargetCommentList(page = 1) {
      this.isLoadingTargetComment = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetComment, {
          pageNumber: page,
          pageSize: this.getTargetCommentInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
      } else {
        this.isLoadingTargetComment = false
        if (res.state === 'ok') {
          console.log(res.page.list)
          // 如果获取到的数据个数不为0个，那就把数据接到后面去
          // 把数据对接到后面
          this.targetMessageList = [...this.targetMessageList, ...res.page.list]
          if (res.page.list.length < this.getTargetCommentInfo.pageSize) {
            this.getTargetCommentInfo.totalPage =
              this.getTargetCommentInfo.pageNumber
          }
        }
      }
    },
    // 获取目标评论的下一页
    getTargetCommentNextPage({ page }) {
      if (
        this.getTargetCommentInfo.pageNumber ===
        this.getTargetCommentInfo.totalPage
      ) {
        return
      }
      this.getTargetCommentInfo.pageNumber = page
      if (!this.isLoadingTargetComment) {
        this.getTargetCommentList(page)
      }
    },
    // 点击评论查看目标详情
    toTargetDetail({ id }) {
      this.isShowTargetDetail = true
      this.currentTargetDetail = id
      this.openTargetDetail(id)
      this.targetCurrentTab = 'third'
    },
    // 获取任务评论提醒
    async getTaskCommentList(page) {
      this.isLoadingTaskComment = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskComment, {
          pageNumber: page,
          pageSize: this.getTaskCommentInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
      } else {
        this.isLoadingTaskComment = false
        if (res.state === 'ok') {
          // 如果获取到的数据个数不为0个，那就把数据接到后面去
          // 把数据对接到后面
          this.taskMessageList = [...this.taskMessageList, ...res.page.list]
          if (res.page.list.length < this.getTaskCommentInfo.pageSize) {
            this.getTaskCommentInfo.totalPage =
              this.getTaskCommentInfo.pageNumber
          }
        }
      }
    },
    // 获取任务评论的下一页
    getTaskCommentNextPage({ page }) {
      console.log('要加载吗', page)
      if (
        this.getTaskCommentInfo.pageNumber === this.getTaskCommentInfo.totalPage
      ) {
        return
      }
      this.getTaskCommentInfo.pageNumber = page
      if (!this.isLoadingTaskComment) {
        this.getTaskCommentList(page)
      }
    },
    // 点击评论查看任务详情
    toTaskDetail({ id }) {
      this.isShowTaskDetail = true
      this.currentTaskDetail = id
      this.openTaskDetail(id)
      this.taskCurrentTab = 'third'
    },
    // 刷新评论提醒
    refreshCommentdMenu() {
      this.taskMessageList = []
      this.targetMessageList = []
      this.circleMessageList = []
      this.getTargetCommentList(1)
      this.getTaskCommentList(1)
      this.getCircleCommentList(1)
      // 获取目标评论统计
      this.getTargetCommentCount()
      // 获取任务评论统计
      this.getTaskCommentCount()
      // 获取圈子评论统计
      this.getCircleCommentCount()
    },
    // 切换消息tab
    chooseMessageType(e) {
      console.log('切换tab', e)
    },
    // 开启任务详情弹窗
    openTaskDetail(val) {
      this.$router.push({
        name: 'home',
        query: { ...this.$route.query, task_id: val }
      })
    },
    // 关闭任务详情
    closeTaskDetail() {
      const query = { ...this.$route.query }
      delete query.task_id
      this.$router.push({
        name: 'home',
        query: query
      })
    },
    // 开启/关闭目标详情弹窗
    openTargetDetail(val) {
      console.log('展示目标详情')
      this.$router.push({
        name: 'home',
        query: { ...this.$route.query, objective_id: val }
      })
    },
    // 关闭目标详情
    closeTargetDetail() {
      const query = { ...this.$route.query }
      delete query.objective_id
      this.$router.push({
        name: 'home',
        query: query
      })
    },
    // 获取目标评论统计
    async getTargetCommentCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetCommentCount, {
          is_read: 0
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.targetCommentCount = res.count
      }
    },
    // 获取任务评论统计
    async getTaskCommentCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTargetCommentCount, {
          is_read: 0
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.taskCommentCount = res.count
      }
    },
    // 圈子消息评论数量
    async getCircleCommentCount() {
      const formObj = {
        is_read: 0
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentCount, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.circleCommentCount = res.count
        if (this.commentActiveTab === 'circle' && this.circleCommentCount > 0) {
          this.clearCircleCommentCount()
        }
      }
    },
    // 清除圈子消息评论统计
    async clearCircleCommentCount() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.clearMomentMessageCommentCount, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.circleCommentCount = 0
      }
    },
    // 获取圈子评论消息
    async getCircleCommentList(page) {
      this.isLoadingCircleComment = true
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getCommentList, {
          pageNumber: page,
          pageSize: this.getCircleCommentInfo.pageSize
        })
      )
      if (err) {
        console.log(err)
      } else {
        this.isLoadingCircleComment = false
        if (res.state === 'ok') {
          // 如果获取到的数据个数不为0个，那就把数据接到后面去
          // 把数据对接到后面
          this.circleMessageList = [
            ...this.circleMessageList,
            ...res.page.list.map((e) => {
              let content = e.moment_content
              if (content.length === 0) {
                switch (e.content_type) {
                  case 1:
                    content = '[图片]'
                    break
                  case 2:
                    content = '[视频]'
                    break
                }
              }
              if (e.reply_comment_id) {
                return {
                  moment_id: e.moment_id,
                  create_time: e.create_time,
                  create_clerk_avatar: e.clerk_avatar,
                  create_clerk_alias: e.clerk_alias,
                  create_clerk_name: e.clerk_name,
                  content: content,
                  comment_content: e.comment_content,
                  reply_comment_id: e.reply_comment_id,
                  reply_create_clerk_alias: e.reply_clerk_alias,
                  reply_create_clerk_name: e.reply_clerk_name,
                  reply_comment_content: e.reply_comment_content,
                  clerk_list: e.clerk_list,
                  pid_clerk_list: e.pid_clerk_list
                }
              } else {
                return {
                  moment_id: e.moment_id,
                  create_time: e.create_time,
                  create_clerk_avatar: e.clerk_avatar,
                  create_clerk_alias: e._clerk_alias,
                  create_clerk_name: e.clerk_name,
                  content: content,
                  comment_content: e.comment_content,
                  clerk_list: e.clerk_list,
                  pid_clerk_list: e.pid_clerk_list
                }
              }
            })
          ]
          if (res.page.list.length < this.getCircleCommentInfo.pageSize) {
            this.getCircleCommentInfo.totalPage =
              this.getCircleCommentInfo.pageNumber
          }
        }
      }
    },
    // 获取圈子评论的下一页
    getCircleCommentNextPage({ page }) {
      if (
        this.getCircleCommentInfo.pageNumber ===
        this.getCircleCommentInfo.totalPage
      ) {
        return
      }
      this.getCircleCommentInfo.pageNumber = page
      if (!this.isLoadingCircleComment) {
        this.getCircleCommentList(page)
      }
    },
    // 关闭编辑弹窗
    closeCircleEdit() {
      this.isShowCircleEdit = false
      this.currentEditCircle = -1
    },
    // 点击详情
    toCircleDetail({ id }) {
      this.currentCircle = id
      this.isShowCircleDetail = true
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, circle_id: id }
      })
    },
    // 关闭详情
    closeCircleDetail() {
      this.currentCircle = -1
      this.isShowCircleDetail = false
      const query = { ...this.$route.query }
      delete query.circle_id
      this.$router.push({
        name: this.$route.name,
        query: query
      })
    },
    // 详情跳转修改
    circleDetailToEdit() {
      this.currentEditCircle = this.currentCircle
      this.isShowCircleEdit = true
      this.closeCircleDetail()
    },
    // 首页未完成跳转任务
    toTaskPage(type) {
      if (type < 4) {
        this.$router.push({
          name: 'workspace',
          params: { timeType: type, clerkId: this.myClerkId, tab: 'task' }
        })
      }
    },
    // 首页未完成延期跳转任务
    toTaskDelayPage(type) {
      if (type < 4) {
        this.$router.push({
          name: 'workspace',
          params: {
            timeType: type,
            progress_status: 2,
            clerkId: this.myClerkId,
            tab: 'task'
          }
        })
      }
    },
    // 获取通知列表
    async getNoticeList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getNoticeList, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.normalNoticeList = res.list
        return Promise.resolve()
      }
    },
    openNoticeDetail(e) {
      this.currentNoticeKey = e
      if (e.indexOf('2_') === 0) {
        this.$router.push({
          name: 'home',
          query: {
            notice_id: `${e}`.substr(2)
          }
        })
      }
    },
    closeNoticePop() {
      this.currentNoticeKey = ''
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-carousel {
  position: relative;
  z-index: 0;
}
</style>
<style lang="scss">
// 导入输入任务的样式
@import '@/assets/scss/input_task.scss';
// 导入任务进度样式
@import '@/assets/scss/progress_box.scss';
.el-row.main {
  position: relative;
  width: 100%;
}

// 主体部分，左右两列的父级
.main-container.el-container {
  overflow: hidden;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 24px $page-padding 0;

  .complete-box {
    margin-bottom: 50px;
  }

  .rest-task {
    padding-left: 16px;
  }
}

// 每一块的共同样式
.white-box {
  background: #fff;
  box-shadow: 0 0 12px 4px rgba(153, 153, 153, 0.1);
  border-radius: 6px;
  &:hover {
    box-shadow: 0 4px 12px 2px rgba(51, 51, 51, 0.1);
  }
}

// 天气
.weather {
  position: relative;
  padding: 38px 0;
  text-align: center;
  // 日期和打招呼
  .date-text {
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 16px;
    color: #333;
  }
  .hello {
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
    color: $main-color;
  }
  &::after {
    content: '';
    @include pos-middle(absolute);
    right: 0;
    width: 1px;
    height: 90px;
    background: #eee;
  }
}

@media screen and (max-width: 767px) {
  .main-container.el-container {
    // overflow: hidden !important;
    padding: 14px 0 0;

    .complete-box {
      margin-bottom: 20px;
    }

    .rest-task {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 18px;
    }

    .task-data {
      flex-wrap: wrap;
      .task-data-item {
        width: 33.3%;
        flex: inherit;
      }
    }
  }

  .weather {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;

    .date-text {
      margin-bottom: 0;
      margin-right: 10px;
    }

    &::after {
      width: 0;
      height: 0;
    }
  }

  .progress-tip {
    padding-bottom: 28px;
  }
}

// 剩余任务的列表
.rest-task {
  padding-top: 18px;
  // padding-left: 16px;
  .date-work {
    padding-left: 16px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
    color: #999;
    em {
      padding-left: 14px;
      font-style: normal;
      color: #333;
    }
  }
}

// 今日会议等5个
.task-data-item {
  text-align: center;
  padding-top: 14px;
  &:hover .num {
    color: $main-color;
  }
  // 标题
  .task-data-title {
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
  }
  // 数量
  .num {
    padding-bottom: 10px;
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #333;
  }
  .em-num {
    cursor: pointer;
  }
  // 延期数量
  .delay-tag {
    width: min-content;
    padding: 0 6px;
    margin: 0 auto;
    border: 1px solid $red;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    color: $red;
    border-radius: 4px;
    background: #fff4f4;
    cursor: pointer;
  }
}

// 任务的更多弹窗
.task-more-box {
  position: absolute;
  z-index: 4;
  width: 140px;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  border-radius: 4px;
  .more-box-item {
    padding: 0 18px;
    height: 40px;
    line-height: 40px;
    color: #333;
    cursor: pointer;
    &.red {
      color: $red;
    }
    &:hover {
      background: #f6f6f6;
    }
  }
}

// 左边任务的完成情况
.complete-box {
  padding: 0 20px 26px;
  margin-top: 20px;
  margin-bottom: 20px;
  .refresh {
    position: absolute;
    top: 80%;
    right: 0;
    width: 78px;
    color: #333;
    background: #fff;
    border-radius: 4px;
    .more-item {
      padding: 0;
      text-align: center;
    }
  }
  // 头部
  .complete-header {
    height: 56px;
    position: relative;
    .complete-title {
      font-weight: 400;
      margin: 0;
      font-size: 14px;
    }
    .more {
      border: none;
      outline: none;
      margin-right: -4px;
      width: 20px;
      display: block;
      font-size: 20px;
      color: #999;
    }
  }
  // 左边，进度数据
  .complete-progress {
    position: relative;
    .data {
      @include pos-center(absolute);
      top: 38px;
      width: 200px;
      font-size: 14px;
      line-height: 21px;
      color: #999;
      text-align: center;
    }
    .percent {
      @include pos-center(absolute);
      top: 60px;
      width: 200px;
      font-size: 26px;
      line-height: 39px;
      color: #333;
      text-align: center;
      &::after {
        content: '%';
        font-size: 14px;
      }
    }
  }
  // 进度图例
  .progress-tip {
    padding-top: 28px;
    .progress-tip-item {
      margin: 0 20px;
      text-align: center;
      line-height: 0;
    }
    // 颜色
    .progress-tip-color {
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      &.active {
        background: $main-color;
      }
      &.grey {
        background: #e5e9f2;
      }
    }
    // 数字
    .progress-tip-num {
      padding-top: 4px;
      display: block;
      font-size: 16px;
      line-height: 19px;
      color: #333;
    }
    // 标题
    .progress-tip-title {
      padding-top: 4px;
      font-size: 14px;
      line-height: 21px;
      color: #999;
    }
  }
  // 右边的每一块
  .complete-num {
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 10px;
    }
    // 左半边
    .icon-line {
      margin-bottom: 10px;
    }
    .check-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-right: 12px;
    }
    .check-info {
      line-height: 21px;
      color: #999;
      .num {
        color: #333;
      }
    }
    // 右边
    .task-num {
      margin: 0;
      width: 90px;
      text-align: right;
      line-height: 21px;
      color: #333;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
// 更多弹窗
.more-box {
  position: absolute;
  z-index: 2;
  width: 7.3vw;
  background: #fff;
  box-shadow: 0px 0px 5px 2px rgba(153, 153, 153, 0.3);
  border-radius: 4px;
  .more-item {
    cursor: pointer;
    padding: 0 18px;
    line-height: 40px;
    color: #333;
    &:hover {
      background: #f6f6f6;
    }
  }
  &.refresh {
    top: 80%;
    right: 0;
    width: 78px;
    .more-item {
      padding: 0;
      text-align: center;
    }
  }
}

body {
  overflow-y: auto;
}

// 右边的盒子
.right-box {
  padding: 0 20px;
  margin-bottom: 20px;
  background: #fff;
  height: auto;
  box-shadow: 0px 0px 12px 4px rgba(153, 153, 153, 0.1);
  border-radius: 6px;
  &:hover {
    box-shadow: 0px 4px 12px 2px rgba(51, 51, 51, 0.1);
  }
  // 右边的盒子头部
  .right-box-header {
    position: relative;
    height: 58px;
    .right-box-title {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: #333;
    }
    .more-btn {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: block;
      border: none;
      outline: none;
      .iconfont {
        margin-left: 12px;
        font-size: 20px;
        font-weight: normal;
        color: #999;
      }
    }
  }
  .more-box {
    top: 100%;
    right: 6px;
    width: 78px;
    .more-box-item {
      margin: 0;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}

// 右边第一块，通知公告
.notice {
  .content-box {
    height: 142px;
  }
  .notice-item {
    padding-bottom: 20px;
  }
  .notice-title {
    margin: 0;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #333;
    cursor: pointer;
  }
  .notice-content {
    margin: 0;
    margin-bottom: 21px;
    line-height: 1.5;
    color: #999;
    cursor: pointer;
  }
  .notice-date {
    width: min-content;
    white-space: nowrap;
    line-height: 26px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 13px;
    color: $main-color;
    background: #ecf5ff;
    border-radius: 4px;
  }
}

// 右边第二块，任务提醒的样式
.task-remind {
  line-height: 1.5;
  padding-bottom: 30px;
  .content-box {
    height: 322px;
    overflow: auto;
  }
}

// 右边第三块，圈子动态
.ring-dynamic {
  padding-bottom: 30px;
  .content-box {
    height: 422px;
    overflow: auto;
  }
  // 里面的每一条动态
  .ring-dynamic-item {
    line-height: 1.5;
    .img-box {
      padding-right: 14px;
    }
    // 名字和时间
    .name {
      font-weight: 500;
      color: #333;
    }
    .dynamic-time {
      font-size: 12px;
      margin-bottom: 12px;
      color: #999;
    }
    // 文字和图片内容
    .dynamic-content {
      color: #333;
    }
    .pre {
      white-space: pre-wrap;
    }
    .img-item-box {
      margin-top: 10px;
      margin-right: 10px;
    }
    .img-item {
      display: block;
      width: 86px;
      height: 86px;
      border-radius: 6px;
    }
    // 点赞和评论这一行
    .love-comment-box {
      padding-right: 2;
      height: 62px;
    }
    .btn-item {
      margin-right: 12px;
    }
    .dynamic-handle-icon {
      width: 1.5em;
      height: 1.5em;
    }
    .num {
      margin-left: 6px;
      color: #999;
    }
  }
}

:-moz-placeholder {
  color: #bbb;
}
:-ms-input-placeholder {
  color: #bbb;
}
::-webkit-input-placeholder {
  color: #bbb;
}
::placeholder {
  color: #bbb;
}

// 选择目标类型的弹窗
.target-types {
  position: absolute;
  z-index: 2;
  width: 140px;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  border-radius: 4px;
  .target-type-item {
    line-height: 22px;
    height: 40px;
    padding: 9px 18px;
    box-sizing: border-box;
    .target-type-box {
      margin: 0;
    }
    &:hover {
      background: $light-grey-bg;
    }
  }
  .text {
    font-size: 14px;
    color: #333;
  }
}

.white-box em {
  font-style: normal;
}

// 外层不显示“无”，内层显示
.target-type-box.none {
  font-size: 0;
}

.target-types .target-type-box.none {
  font-size: 14px;
  color: #333;
}
</style>
<style lang="scss" scoped>
// 评论提醒这一块的样式
.comment-news ::v-deep {
  .el-tabs__nav-wrap::after {
    background: #fff;
  }
  .el-tabs__item {
    height: 32px;
    line-height: 32px;
  }
  .el-tabs__header {
    padding-top: 15px;
    margin-bottom: 0;
  }
  .el-tabs__item:hover,
  .el-tabs__item.is-active {
    color: $main-color;
  }
  .el-tabs__active-bar {
    background-color: $main-color;
  }
}

// 评论消息里面
.comment-news {
  position: relative;

  .label-badge {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    background: #ff5454;
    border-radius: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }

  .content-box {
    height: 473px;
    padding-bottom: 20px;
    overflow: auto;
  }
  // 里面的更多按钮
  .more-btn {
    margin-top: 20px;
    cursor: pointer;
    .iconfont {
      font-size: 22px;
      color: #999;
    }
  }
  .more-box {
    top: 60px;
    right: 20px;
  }
}
</style>
