/*
 * @Date: 2021-06-03 11:04:39
 * @LastEditTime: 2021-09-27 11:21:57
 * @Description: axios封装
 * @Author: Betty
 * @LastEditors: hzh
 */
import axios from 'axios'
import Vue from 'vue'
// 导入进度条组件
// import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store/index.js'
import config from './config.js'

const qs = require('qs')

const showStatus = (status) => {
  let message = ''
  switch (status) {
    case 400:
      message = '请求错误(400)'
      break
    case 401:
      message = '未授权，请重新登录(401)'
      break
    case 403:
      message = '拒绝访问(403)'
      break
    case 404:
      message = '请求出错(404)'
      break
    case 408:
      message = '请求超时(408)'
      break
    case 500:
      message = '服务器错误(500)'
      break
    case 501:
      message = '服务未实现(501)'
      break
    case 502:
      message = '网络错误(502)'
      break
    case 503:
      message = '服务不可用(503)'
      break
    case 504:
      message = '网络超时(504)'
      break
    case 505:
      message = 'HTTP版本不受支持(505)'
      break
    default:
      message = `连接出错(${status})!`
  }
  return `${message}，请检查网络或联系管理员！`
}

// 配置请求投和超时
const instance = axios.create({
  baseURL: `${config.baseUrl}/api`,
  timeout: 0
})

// 对头部字段进行配置
instance.defaults.headers['x-requested-with'] = 'XMLHttpRequest'

// 请求拦截器
instance.interceptors.request.use(function (config) {
  // if (!store.state.lastActiveTime) {
  //   // 如果请求时间差超过了30分钟，就删除掉token和用户信息
  //   if (new Date().getTime() - store.state.lastActiveTime > 60 * 60 * 1000) {
  //     store.commit('logout')
  //     return
  //   }
  // }
  // 请求的同时，实时设置活跃时间
  store.commit('setLastActiveTime')
  // NProgress.start()
  if (config.method === 'post') {
    // headers: { 'Content-Type': 'multipart/form-data' }
    if (config.headers['Content-Type'] !== 'multipart/form-data') {
      config.data = qs.stringify(config.data)
    }
    config.headers.Token = store.state.token || ''
  }
  return config
})

// 响应拦截器
instance.interceptors.response.use(function (response) {
  // NProgress.done()
  // 拿到状态码
  const status = response.status
  let tip = ''
  if (status < 200 || status >= 300) {
    // 处理http错误，抛到业务代码
    tip = showStatus(status)
    Vue.prototype.$message({
      showClose: true,
      message: tip,
      type: 'error'
    })
  }
  if (response.data.state === 'fail') {
    // 如果需要登录，那就跳转到某个页面去
    if (response.data.need_operate === 'login') {
      if (response.data.redirect_url) {
        window.localStorage.removeItem('token')
        console.log(`axios:${response.data.redirect_url}?redirect_url=${encodeURIComponent(encodeURIComponent(store.state.toFullPath))}`)
        window.location.href = `${response.data.redirect_url}?redirect_url=${encodeURIComponent(encodeURIComponent(store.state.toFullPath))}`
      }
    }
    if (response.data.errcode.indexOf('-100') === -1) {
      Vue.prototype.$message({
        showClose: true,
        message: response.data.errmsg,
        type: 'error'
      })
    }
  }
  console.log(response.data)
  return response.data
}, function (error) {
  // 错误抛到业务代码
  error.data = {}
  error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'

  Vue.prototype.$message({
    showClose: true,
    message: error.data.msg,
    type: 'error'
  })

  return Promise.resolve(error)
})

// 下载专属
export const downloadInstance = axios.create({
  baseURL: `${config.baseUrl}/api`,
  timeout: 0
})

// 对头部字段进行配置
downloadInstance.defaults.headers['x-requested-with'] = 'XMLHttpRequest'

// 请求拦截器
downloadInstance.interceptors.request.use(function (config) {
  // NProgress.start()
  if (config.method === 'post') {
    // headers: { 'Content-Type': 'multipart/form-data' }
    if (config.headers['Content-Type'] !== 'multipart/form-data') {
      config.data = qs.stringify(config.data)
    }
    config.headers.Token = store.state.token || ''
  }
  return config
})

// 响应拦截器
downloadInstance.interceptors.response.use(function (response) {
  // NProgress.done()
  // 拿到状态码
  const status = response.status
  let tip = ''
  if (status < 200 || status >= 300) {
    // 处理http错误，抛到业务代码
    tip = showStatus(status)
    Vue.prototype.$message({
      showClose: true,
      message: tip,
      type: 'error'
    })
  }
  if (response.data.state === 'fail') {
    // 如果需要登录，那就跳转到某个页面去
    if (response.data.need_operate === 'login') {
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url
      }
    }
    Vue.prototype.$message({
      showClose: true,
      message: response.data.errmsg,
      type: 'error'
    })
  }
  console.log(response)
  return response
}, function (error) {
  // 错误抛到业务代码
  error.data = {}
  error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'

  Vue.prototype.$message({
    showClose: true,
    message: error.data.msg,
    type: 'error'
  })

  return Promise.resolve(error)
})

// 导出实例
export default instance
