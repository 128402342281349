<!--
 * @Author: hzh
 * @Date: 2021-09-06 17:21:50
 * @LastEditors: hzh
 * @LastEditTime: 2021-10-18 11:40:42
-->
<template>
  <div class="file-state-box">
    <div class="file-state flex flex-y-start pc">
      <div class="num">{{ changeKey }}</div>
      <div class="upload-date flex-shrink">
        {{
          changeInfo.create_time ? $util.getTimeStr(changeInfo.create_time) : ''
        }}
      </div>
      <div class="upload-author flex-shrink">
        {{ changeInfo.create_clerk_alias || changeInfo.create_clerk_name }}
      </div>
      <!-- 创建文本知识库  -->
      <template v-if="changeInfo.change_type === 1">
        <div class="upload-name">创建知识库</div>
      </template>
      <template v-if="changeInfo.change_type === 2 && isAllowPreview">
        <div class="upload-name flex-shrink">上传文件：</div>
        <div class="upload-name">
          <a @click="onlineExamineFile">{{ changeInfo.file_original_name }}</a>
        </div>
      </template>
      <template v-if="changeInfo.change_type === 2 && !isAllowPreview">
        <div class="upload-name flex-shrink">上传文件：</div>
        <div class="upload-name">
          <a
            :href="changeInfo.change_attachment_path"
            :download="changeInfo.file_original_name"
            >{{ changeInfo.file_original_name }}</a
          >
        </div>
      </template>
      <template v-if="changeInfo.change_type === 3">
        <div class="upload-name">修改知识库内容</div>
      </template>
      <template v-if="changeInfo.change_type === 4 && isAllowPreview">
        <div class="upload-name flex-shrink">上传文件：</div>
        <div class="upload-name">
          <a @click="onlineExamineFile">{{ changeInfo.file_original_name }}</a>
        </div>
      </template>
      <template v-if="changeInfo.change_type === 4 && !isAllowPreview">
        <div class="upload-name flex-shrink">上传文件：</div>
        <div class="upload-name">
          <a
            :href="changeInfo.change_attachment_path"
            :download="changeInfo.file_original_name"
            >{{ changeInfo.file_original_name }}</a
          >
        </div>
      </template>
      <template v-if="changeInfo.change_type === 5">
        <div class="upload-name">
          修改知识库名称：{{ changeInfo.after_content }}
        </div>
      </template>
      <template v-if="changeInfo.change_type === 6">
        <div class="upload-name">
          修改著作人：{{ changeInfo.change_after_clerk_name }}
        </div>
      </template>
    </div>

    <div class="file-state flex flex-y-center wap">
      <div class="num">{{ changeKey }}</div>
      <div class="upload-date">
        {{
          changeInfo.create_time ? $util.getTimeStr(changeInfo.create_time) : ''
        }}
      </div>
      <div class="upload-author">
        {{ changeInfo.create_clerk_alias || changeInfo.create_clerk_name }}
      </div>
    </div>
    <div class="file-state flex flex-y-center wap">
      <!-- 创建文本知识库  -->
      <template v-if="changeInfo.change_type === 1">
        <div class="upload-name">创建知识库</div>
      </template>
      <template v-if="changeInfo.change_type === 2">
        <div class="upload-name" v-if="changeInfo.change_type === 2">
          上传文件：<a
            :href="changeInfo.change_attachment_path"
            :download="changeInfo.file_original_name"
            >{{ changeInfo.file_original_name }}</a
          >
        </div>
      </template>
      <template v-if="changeInfo.change_type === 3">
        <div class="upload-name">修改知识库内容</div>
      </template>
      <template v-if="changeInfo.change_type === 4">
        <div class="upload-name">
          上传文件：<a
            :href="changeInfo.change_attachment_path"
            :download="changeInfo.file_original_name"
            >{{ changeInfo.file_original_name }}</a
          >
        </div>
      </template>
      <template v-if="changeInfo.change_type === 5">
        <div class="upload-name">
          修改知识库名称：{{ changeInfo.after_content }}
        </div>
      </template>
      <template v-if="changeInfo.change_type === 6">
        <div class="upload-name">
          修改著作人：{{ changeInfo.change_after_clerk_name }}
        </div>
      </template>
    </div>
    <el-image
      ref="previewImageRef"
      :preview-src-list="[previewImageSrc]"
      v-show="false"
    ></el-image>
  </div>
</template>

<script>
export default {
  props: ['changeInfo', 'changeKey'],
  data() {
    return {
      // 图片预览
      previewImageSrc: ''
    }
  },
  computed: {
    isAllowPreview() {
      return [
        'pdf',
        'doc',
        'docx',
        'xlsx',
        'xls',
        'pptx',
        'jpg',
        'jpeg',
        'png',
        'gif',
        'bmp'
      ].includes(this.changeInfo.file_ext)
    },
    // 判断是否是图片
    isPic() {
      // 判断文件的扩展名是否是图片扩展名注意
      const type = this.changeInfo.file_ext
      const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      return imgTypes.includes(type)
    }
  },
  methods: {
    onlineExamineFile() {
      if (this.isPic) {
        this.previewImageSrc = this.changeInfo.change_attachment_path
        this.$refs.previewImageRef.showViewer = true
        return
      }
      window.open(
        this.$router.resolve({
          name: 'Preview',
          params: {
            id: this.changeInfo.knowl_id
          },
          query: {
            attachment_id: this.changeInfo.attachment_id
          }
        }).href,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .wap {
    display: none !important;
  }
  .show {
    display: none;
  }
  .file-state-box {
    .file-state {
      margin-top: 20px;
      .num {
        width: 26px;
        height: 26px;
        background: #ff9854;
        border-radius: 50%;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
      }
      .upload-date {
        margin-left: 16px;
        font-size: 14px;
        line-height: 21px;
        color: #999999;
      }
      .upload-author {
        margin-left: 12px;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
      }
      .upload-name {
        margin-left: 12px;
        font-size: 14px;
        line-height: 21px;
        color: #999999;
        a {
          cursor: pointer;
          &:hover {
            color: $main-color !important;
            text-decoration: underline;
          }
          &:link {
            color: #999999;
          }
          &:visited {
            text-decoration: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none !important;
  }
  .file-state-box {
    .file-state {
      margin-top: 0px;
      padding-bottom: 5px;
      .num {
        width: 22px;
        height: 22px;
        background: #ff9854;
        border-radius: 50%;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
      }
      .upload-date {
        margin-left: 16px;
        font-size: 14px;
        line-height: 21px;
        color: #999999;
      }
      .upload-author {
        margin-left: 12px;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
      }
      .upload-name {
        width: 80%;
        margin-left: 36px;
        font-size: 12px;
        line-height: 21px;
        color: #999999;
        a {
          cursor: pointer;
          &:hover {
            color: $main-color !important;
            text-decoration: underline;
          }
          &:link {
            color: #999999;
          }
          &:visited {
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
