<!--
 * @Date: 2021-06-24 11:51:04
 * @LastEditTime: 2021-08-03 16:27:34
 * @Description: 点赞
 * @Author: Betty
 * @LastEditors: Betty
-->
<template>
  <!-- 点赞 -->
  <div class="praise flex flex-y-center">
    <div @click="pariseComment(comment.comment_id)">
      <svg class="icon praise-icon" aria-hidden="true">
        <use
          :xlink:href="comment.is_praise ? '#icon-heart1' : '#icon-heart'"
        ></use>
      </svg>
    </div>
    <span class="praise-num" v-if="comment.praise_number > 0">{{
      comment.praise_number
    }}</span>
  </div>
</template>

<script>
export default {
  name: 'praise',
  props: {
    // 对应的评论
    comment: {
      type: Object
    },
    // 是否是目标的评论
    isTarget: {
      type: Boolean
    }
  },
  methods: {
    // 给评论点赞
    async pariseComment(id) {
      let link = ''
      if (!this.isTarget) {
        link = this.$api.pariseTargetPeriodComment
      } else {
        link = this.$api.pariseTargetRaise
      }
      console.log('准备点赞', id)
      const [err, res] = await this.$util.to(
        this.$http.post(link, {
          comment_id: id
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // 确定点赞成功以后，不刷新整体数据，只是更改点赞的状态和点赞的数量
        this.comment.is_praise = !this.comment.is_praise
        if (this.comment.is_praise) {
          this.comment.praise_number++
        } else {
          this.comment.praise_number--
        }
        console.log(this.comment)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parise_style.scss';
</style>
