/*
 * @Date: 2021-05-24 15:17:00
 * @LastEditTime: 2021-12-28 10:15:11
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 路由
    route: null,
    // 跳转的路由
    toFullPath: '/',
    // 我的职员id
    myClerkId: -1,
    // 我的职员信息
    myClerkInfo: {},
    // token
    token: '',
    // 权限列表
    perList: [],
    // 本地时间与系统时间的偏移量
    timeOffset: null,
    // 优先级数据
    priorityList: [
      {
        color: 'red',
        text: '最高',
        value: 6
      },
      {
        color: 'orange',
        text: '较高',
        value: 5
      },
      {
        color: 'yellow',
        text: '普通',
        value: 4
      },
      {
        color: 'blue',
        text: '较低',
        value: 3
      },
      {
        color: 'green',
        text: '最低',
        value: 2
      }
    ],
    // 目标类型数据
    targetTypeList: [
      {
        color: 'none',
        text: '无',
        value: 1
      },
      {
        color: 'week',
        text: '周目标',
        value: 2
      },
      {
        color: 'month',
        text: '月目标',
        value: 3
      },
      {
        color: 'quarter',
        text: '季目标',
        value: 4
      }
    ],
    colorList: [
      'blue', 'green', 'yellow', 'orange', 'red'
    ],
    // 省列表
    provinceList: [],
    // 市列表
    cityListMap: {},
    // 职员树
    clerkTree: null,
    // 下属职员树
    childClerkTree: null,
    // 最后活跃时间
    lastActiveTime: null,
    // 屏幕宽度
    screenWidth: document.body.clientWidth,
    // 职员导师类型列表
    clerkTutorTypeList: [],
    // 点击body计数
    clickBodyIndex: 1,
    // 鼠标滚动计数
    mouseWheelIndex: 1

  },
  mutations: {
    clickBody(state) {
      if (state.clickBodyIndex === 100000000) {
        state.clickBodyIndex = 1
      } else {
        state.clickBodyIndex = state.clickBodyIndex + 1
      }
    },
    mouseWheel(state) {
      if (state.mouseWheelIndex === 100000000) {
        state.mouseWheelIndex = 1
      } else {
        state.mouseWheelIndex = state.mouseWheelIndex + 1
      }
    },
    setToFullPath(state, path) {
      state.toFullPath = path
    },
    // 设置路由
    setRoute(state, route) {
      state.route = route
    },
    // 设置职员树
    setClerkTree(state, tree) {
      state.clerkTree = tree
    },
    // 设置下属职员树
    setChildClerkTree(state, tree) {
      state.childClerkTree = tree
    },
    // 设置职员信息和id
    setClerkInfo(state, obj) {
      console.log('拿到职员信息了，现在正在赋值', obj)
      state.myClerkInfo = obj
      state.myClerkId = obj.clerk_id
    },
    // 退出
    logout(state) {
      state.myClerkId = -1
      state.myClerkInfo = null
      state.token = ''
      window.localStorage.removeItem('token')
    },
    // 设置token
    setToken(state, token) {
      state.token = token
    },
    // 设置权限列表
    setPerList(state, list) {
      state.perList = list
    },
    // 设置偏移量
    setOffset(state, offset) {
      state.timeOffset = offset
    },
    // 设置省列表
    setProvinceList(state, provinceList) {
      state.provinceList = provinceList
    },
    // 设置市列表
    setCityList(state, { id, list }) {
      Vue.set(state.cityListMap, id, list)
    },
    // 设置最后活跃时间
    setLastActiveTime(state) {
      state.lastActiveTime = new Date().getTime()
    },
    // 设置屏幕宽度
    setWindowsWidth(state, width) {
      state.screenWidth = width
    },
    // 设置职员导师类型
    setClerkTutorTypeList(state, list) {
      Vue.set(state, 'clerkTutorTypeList', list)
    }
  },
  getters: {
    getToday(state) {
      return new Date(new Date().getTime() - state.timeOffset)
    },
    isXs(state) {
      return state.screenWidth < 768
    },
    isSm(state) {
      return state.screenWidth >= 768 && state.screenWidth < 992
    },
    isMd(state) {
      return state.screenWidth >= 992 && state.screenWidth < 1200
    },
    isLg(state) {
      return state.screenWidth >= 1200 && state.screenWidth < 1920
    },
    isXl(state) {
      return state.screenWidth >= 1920
    }
  },
  actions: {
    // 获取当前登录的职员信息
    async getClerkInfo({
      commit
    }) {
      const [err, res] = await Vue.prototype.$util.to(Vue.prototype.$http.post(Vue.prototype.$api.getCurrentUserInfo, {}))
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          commit('setClerkInfo', res.info)
          commit('setPerList', res.per_list)
        }
      }
    },
    async getToday({
      state,
      commit
    }) {
      if (state.timeOffset == null) {
        // 获取服务器时间，并且比对
        const [err, res] = await Vue.prototype.$util.to(Vue.prototype.$http.post(Vue.prototype.$api.getServiceTime), {})
        if (err) {
          console.log('获取时间的时候报错')
        } else {
          const offset = new Date().getTime() - res.timestamp
          commit('setOffset', offset)
          console.log('偏移量', offset)
        }
      }
      // 计算当前时间
      return new Date(new Date().getTime() - state.timeOffset)
    },
    // 请求省份列表
    async getProvinceList({
      state,
      commit,
      dispatch
    }) {
      if (state.provinceList.length > 0) {
        return state.provinceList
      }
      const [err, res] = await Vue.prototype.$util.to(
        Vue.prototype.$http.post(Vue.prototype.$api.getProvinceList)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        commit('setProvinceList', res.list)
        if (state.cityListMap[res.list[0].province_id] == null) {
          dispatch('getCityList', res.list[0].province_id)
        }
        return res.list
      }
    },
    // 请求省份列表
    async getCityList({
      state,
      commit
    }, provinceId) {
      if (state.cityListMap[provinceId] != null) {
        return state.cityListMap[provinceId]
      }
      const [err, res] = await Vue.prototype.$util.to(
        Vue.prototype.$http.post(Vue.prototype.$api.getCityList, { province_id: provinceId })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        commit('setCityList', { id: provinceId, list: res.list })
        return res.list
      }
    },
    // 获取职员导师类型列表
    async getClerkTutorTypeList({
      state,
      dispatch
    }) {
      if (state.clerkTutorTypeList.length > 0) {
        return state.clerkTutorTypeList
      }
      const [, res] = await dispatch('refreshClerkTutorTypeList')
      if (res) {
        return res
      }
    },
    // 刷新职员导师类型列表
    async refreshClerkTutorTypeList({
      commit
    }) {
      const [err, res] = await Vue.prototype.$util.to(Vue.prototype.$http.post(Vue.prototype.$api.getClerkTutorTypeList, {}))
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          commit('setClerkTutorTypeList', res.list)
          return res.list
        }
      }
    }
  }
})
