<!--
 * @Date: 2021-06-25 17:32:44
 * @LastEditTime: 2021-07-29 10:37:59
 * @Description: 移动弹窗的树形组件（目标）
 * @Author: Betty
 * @LastEditors: Betty
-->
<template>
  <div class="node-item">
    <!-- 展开收起子元素 -->
    <span
      class="more-arrow iconfont icon-zhankai"
      @click.stop="toggleChildren"
      :class="{ active: isShowChild }"
      :style="{ left: 12 * (layer - 1) + 0 + 'px' }"
      v-if="
        (treeNode.children && treeNode.children.length > 0) ||
        (treeNode.list && treeNode.list.length > 0)
      "
    ></span>
    <!-- 它自己 -->
    <div
      class="flex flex-y-center line"
      :style="{ 'padding-left': 12 * layer + 10 + 'px' }"
    >
      <p
        class="name flex1 e"
        @click.stop="chooseNode(treeNode)"
        :class="{
          active: isChoosen(treeNode)
        }"
      >
        {{ nodeName | handleNum }} {{nodeTaskName}}
      </p>
    </div>
    <!-- 它孩子 -->
    <div v-show="isShowChild">
      <move-tree-node
        v-for="(child, index) in treeNode.list
          ? treeNode.list
          : treeNode.children"
        :key="index"
        :treeNode="child"
        :index="index"
        :layer="layer + 1"
        :targetId="targetId"
        :targetRange="targetRange"
        @choose-node="chooseNode($event)"
      ></move-tree-node>
    </div>
  </div>
</template>

<script>
import MoveTreeNode from './MoveTreeNode.vue'
export default {
  name: 'move-tree-node',
  components: {
    MoveTreeNode
  },
  props: {
    // 数据
    treeNode: {
      type: Object
    },
    index: {
      type: Number
    },
    layer: {
      type: Number,
      default: 1
    },
    // 当前选择的插入目标
    targetId: {
      type: Number,
      default: -1
    },
    targetRange: {
      type: String,
      default: ''
    }
  },
  computed: {
    // 组的名字，暂时用第几组来说
    nodeName() {
      if (this.treeNode.objective_id) {
        return this.treeNode.objective_name
      } else {
        switch (this.treeNode.list[0].group_type) {
          case 2:
            return '团队'
          case 3:
            return '协同'
        }
        return this.index + 1
      }
    },
    // 判断当前任务或组是否选中
    isChoosen() {
      return function (item) {
        // console.log(
        //   '当前选择的是任务还是组',
        //   this.targetRange,
        //   this.targetId,
        //   item
        // )
        if (this.targetRange === 'group') {
          return this.targetId === item.key
        } else if (this.targetRange === 'target') {
          return this.targetId === item.objective_id
        }
      }
    },
    // 放入卡片名称
    nodeTaskName() {
      if (this.treeNode.objective_id) {
        return ''
      } else {
        return this.treeNode.list[0].group_name || '工作任务'
      }
    }
  },
  data() {
    return {
      // 是否显示子元素
      isShowChild: false
    }
  },
  methods: {
    // 切换子元素的显示隐藏
    toggleChildren() {
      this.isShowChild = !this.isShowChild
    },
    // 选择
    chooseNode(item) {
      if (!item.range) {
        // 说明选择的是组
        if (item.key) {
          this.$emit('choose-node', {
            range: 'group',
            id: item.key,
            item
          })
          // this.Parent.chooseMoveTarget('group', item.key)
        } else {
          // this.Parent.chooseMoveTarget('task', item.task_id)
          this.$emit('choose-node', {
            range: 'target',
            id: item.objective_id,
            item
          })
        }
      } else {
        this.$emit('choose-node', item)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
