<!--
 * @Date: 2021-07-23 22:53:35
 * @LastEditTime: 2021-09-30 15:53:49
 * @Description: 无限级menu
 * @Author: lyq
 * @LastEditors: hzh
-->
<template>
  <div class="menu-tree">
    <template v-for="item in dataList">
      <div :key="item.id" @click.stop="$emit('change', item.dept_id)">
        <div
          class="flex menu-item"
          @mouseenter="showDelete(item)"
          @mouseleave="hideDelete()"
          :class="{ active: activeId == item.dept_id }"
          :style="{ paddingLeft: menuIndex * 20 + 8 + 'px' }"
        >
          <i
            :class="{
              'iconfont icon-playfill': item.children,
              active: !active.includes(item.dept_id)
            }"
            class="menu-wind"
            @click.stop="handleWind(item.dept_id)"
          ></i>
          <i class="icon-Frame15 menu-icon"> </i>
          <div class="flex1 e" :title="item.dept_name">
          {{ item.dept_name}}
          </div>
          <i
            v-show="showDeleteId === item.dept_id"
            class="icon-Frame17 menu-icon"
            @click.stop="openDept(item.dept_id)"
          >
          </i>
        </div>
        <el-collapse-transition>
          <div v-if="item.children && active.includes(item.dept_id)">
            <department-tree
              @change="$emit('change', $event)"
              :active-id="activeId"
              :data-list="item.children"
              :menu-index="menuIndex + 1"
            ></department-tree>
          </div>
        </el-collapse-transition>
      </div>
    </template>
    <set-superior
      @handleClose="closeDept"
      v-if="isShow"
      :deptId="deptId"
    ></set-superior>
  </div>
</template>

<script>
import SetSuperior from './SetSuperior.vue'
export default {
  components: { SetSuperior },
  name: 'DepartmentTree',
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    menuIndex: {
      type: Number,
      default: 1
    },
    activeId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      active: [],
      isShow: false,
      deptId: -1,
      showDeleteId: -1
    }
  },
  methods: {
    handleWind(e) {
      if (this.active.includes(e)) {
        this.active = this.active.filter((item) => item !== e)
      } else {
        this.active.push(e)
      }
      this.$forceUpdate()
    },
    // 打开设置上级
    openDept(id) {
      this.isShow = true
      this.deptId = id
    },
    // 关闭置上级
    closeDept() {
      this.isShow = false
    },
    // 显示删除按钮
    showDelete(item) {
      this.showDeleteId = item.dept_id
    },
    // 隐藏删除按钮
    hideDelete() {
      this.showDeleteId = -1
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-tree {
  .menu-item {
    cursor: pointer;
    height: 46px;
    line-height: 46px;
    align-items: center;
    color: #333;
    .icon-Frame17 {
      &:hover {
        color: #13d9bc !important;
      }
    }
    .menu-wind {
      font-size: 12px;
      margin-right: 10px;
      width: 16px;
      color: #bbb;

      &.active {
        transform: rotate(-90deg);
      }
    }

    .menu-icon {
      font-size: 20px;
      color: #bbb;
      margin-right: 10px;
    }

    &.active {
      color: $main-color;
      .menu-icon {
        color: $main-color;
      }
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}
</style>
