<template>
  <!-- 编辑文件名弹窗 -->
  <div
    class="edit-pop-mask flex flex-x-center flex-y-center"
    @click.stop="$emit('cancle')"
  >
    <div class="edit-pop flex flex-y-positive flex-y-between" @click.stop>
      <!-- 输入框 -->
      <el-input v-model="input" placeholder="输入知识库标题"></el-input>
      <!-- 按钮 -->
      <div class="button-box flex">
        <button class="save" @click="confirm">保 存</button>
        <el-button @click="$emit('cancle')">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'knowledge-edit-pop',
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
    confirm() {
      if (this.input === '') {
        this.$message({
          showClose: true,
          message: '请输入知识库名称',
          type: 'error'
        })
        return
      }
      this.$emit('confirm', this.input)
    }
  },
  mounted() {
    this.input = this.value
  },
  watch: {
    value(val) {
      this.input = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-pop-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  .edit-pop {
    width: 500px;
    height: 160px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 26px 26px 28px 26px;
    .button-box {
      margin-top: 24px;
      height: 32px;
      .save {
        width: 80px;
        height: 32px;
        background: #0080ff;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.button-box::v-deep .el-button {
  margin-left: 10px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  font-size: 14px;
  color: #999999;
  &:hover {
    color: #0080ff;
  }
}
.edit-pop::v-deep .el-input {
  width: 448px;
  height: 50px;
  .el-input__inner {
    height: 50px;
  }
}
</style>
