<!--
 * @Author: hzh
 * @Date: 2021-08-20 15:22:04
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-20 15:31:08
-->
<template>
  <div>
    <div class="more-contaier" :style="customStyle" v-show="showUnwind">
      <el-button type="text" @click="$emit('unwind')">
        <i class="icon-zhankai"></i>
        {{ title }}
      </el-button>
    </div>
    <div v-show="showPackUp">
      <span class="pack-up" @click="$emit('packUp')">
        <i class="icon-zhankai"></i>
        收起
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '更多评论'
    },
    windTitle: {
      type: String,
      default: '收起'
    },
    height: {
      type: Number,
      default: 21
    },
    customStyle: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showUnwind: {
      type: Boolean,
      default: true
    },
    showPackUp: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.more-contaier {
  width: 100%;
  text-align: center;
  color: #999;
  background-color: #f6f6f6;
}

.pack-up {
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  color: #0080ff;

  i {
    display: inline-block;
    transform: rotate(180deg);
  }
}
</style>
