<!--
 * @Author: Betty
 * @Date: 2021-07-14 15:06:38
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-23 17:31:47
 * @Description: 目标子组件，每一个目标
-->
<template>
  <div class="target-item-box">
    <!-- 它自己 -->
    <div class="target-item flex flex-y-center target-home">
      <!-- 展开收起子元素 -->
      <span
        class="show-child iconfont icon-zhankai"
        @click.stop="toggleChildren"
        :class="{ active: isShowChild }"
        :style="{ left: layer * 20 + 'px' }"
        v-if="target.children && target.children.length > 0"
      ></span>
      <!-- 目标名称 -->
      <div
        class="target-item-td target-name-box flex flex-y-center name-box"
        :style="layer * 20 + 28 + 'px'"
      >
        <div @click="setTargetStatus(target.objective_id)">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-TickSquare"></use>
          </svg>
        </div>
        <div class="target-name-main">
          <!-- 编辑目标名字 -->
          <input
            type="text"
            placeholder="请输入目标名称"
            :ref="editNameRef"
            v-model="tempName"
            v-if="currentTarget === target.objective_id && isEditTarget"
            @click.stop=""
            class="edit-input"
            @blur="handleEditTarget"
            @keyup.229="empty"
            @keyup.13="toBlur($event)"
            @keyup.esc="isEscAdd = true"
          />
          <template v-else>
            <div class="flex flex-y-center target-name-text">
              <p
                class="target-name e"
                @click="toTargetDetail(target.objective_id)"
              >
                <!-- 标签 -->
                <span
                  class="target-label"
                  v-if="infoLabel.gth > 0"
                  :style="{
                    color: '#FF5454',
                    background: '#fff',
                    fontWeight: '600',
                    fontSize: '15px'
                  }"
                  >{{ infoLabel.gthStr }}</span
                >
                <span
                  class="target-label"
                  v-for="(item, index) in infoLabel.list"
                  :key="index"
                  >{{ item.name }}</span
                >{{ infoLabel.showName }}
              </p>
              <template v-if="target.child_total_count > 0">
                <p
                  class="child-info"
                  style="display: inline-block"
                  v-if="timeoutCount > 0"
                >
                  (<span style="color: red">{{ timeoutCount }}</span
                  >/{{ target.child_complete_count }}/{{
                    target.child_total_count
                  }})
                </p>
                <p class="child-info" style="display: inline-block" v-else>
                  ({{ target.child_complete_count }}/{{
                    target.child_total_count
                  }})
                </p>
              </template>
            </div>
          </template>
        </div>
      </div>
      <!-- 目标关联 -->
      <div class="target-item-td relative-box hidden-xs-only">
        <span
          class="iconfont relative-icon"
          :class="[
            { active: target.pid > 0 },
            target.pid === 0 ? 'icon-to_relative' : 'icon-relatived'
          ]"
          @click="toChooseRelative(target)"
        ></span>
      </div>
      <!-- 目标类型 -->
      <div
        class="target-item-td type-box hidden-xs-only"
        @click.stop="toChooseTargetType(target, $event)"
      >
        <div
          class="target-type-box"
          :class="[getTargetTypeInfo(target.objective_type).color]"
          v-if="getTargetTypeInfo(target.objective_type)"
        >
          {{ getTargetTypeInfo(target.objective_type).text }}
        </div>
      </div>
      <!-- 截止时间 -->
      <div
        class="target-item-td time-box hidden-xs-only"
        :class="{ 'is-late': isLate }"
      >
        <!-- <p class="target-date">{{ getLastTime(target) }}</p> -->
        <el-date-picker
          v-model="lastTime"
          align="right"
          type="date"
          size="small"
          @blur="setLastTime(target)"
          placeholder=""
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <!-- 完成进度 -->
      <div
        class="
          hidden-xs-only
          target-item-td
          flex flex-y-center flex-x-center
          progress-box
        "
        @click.stop="toSetTargetProgress(target, $event)"
      >
        <el-progress
          :color="target.progress_status === 1 ? '#0080FF' : '#FF5454'"
          type="circle"
          :percentage="target.progress_number"
          :width="20"
          :show-text="false"
          :stroke-width="2"
        ></el-progress>
        <span class="progress">{{ target.progress_number + '%' }}</span>
        <!-- 更多 -->
        <div class="item-more" @click.stop="toShowMore(target, $event)">
          <span class="iconfont icon-gengduo3"></span>
        </div>
      </div>
    </div>
    <!-- 它孩子 -->
    <div v-show="isShowChild">
      <target-item
        v-for="(child, index) in target.children"
        :key="index"
        :target="child"
        :layer="layer + 1"
        :currentTarget="currentTarget"
        @show-more="showMore($event)"
        @to-fresh-target="toFresh"
        @show-type-choose="toType($event)"
        @to-target-detail="toTargetDetail($event)"
        @to-choose-relative="toRelative($event)"
        @show-progress-set="toProgress($event)"
      ></target-item>
    </div>
  </div>
</template>

<script>
import TargetItem from './TargetItem.vue'
import { mapState } from 'vuex'
export default {
  name: 'target-item',
  components: {
    TargetItem
  },
  props: {
    // 当前目标数据
    target: {
      type: Object
    },
    // 首页当前操作的目标id
    currentTarget: {
      type: Number,
      default: -1
    },
    // 层级
    layer: {
      type: Number,
      default: 0
    },
    // 当前时间
    nowTime: {
      type: Date
    }
  },
  data() {
    return {
      // 编辑的时候输入的任务名字
      tempName: '',
      // 是否正在编辑
      isEditTarget: false,
      // 快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 截止时间
      lastTime: '',
      // 是否展示出子目标
      isShowChild: false,
      // 是否触发添加esc
      isEscAdd: false
    }
  },
  mounted() {
    this.lastTime = this.target.end_date
  },
  computed: {
    // 解析标签
    infoLabel() {
      if (!this.target || !this.target.objective_id) {
        return {}
      }
      return this.parseLabel(
        this.target.objective_name,
        this.target.objective_label_list
      )
    },
    // 获取截止时间
    getLastTime() {
      return function (target) {
        return target.end_date && target.end_date.slice(0, 10)
      }
    },
    // 编辑输入框ref
    editNameRef() {
      return `editTarget${this.target.objective_id}`
    },
    // 获取当前目标的类型对应的文字和颜色
    getTargetTypeInfo() {
      return function (type) {
        return this.targetTypeList.find((item) => type === item.value)
      }
    },
    // 是否延期
    isLate() {
      return this.target.progress_status === 2
    },
    // 超时数量
    timeoutCount() {
      if (!this.target.children) {
        return 0
      }
      let len = 0
      console.log(this.target.children)
      const count = (list) => {
        list.forEach((e) => {
          if (e.progress_status === 2) {
            len++
          }
          if (e.children) {
            count(e.children)
          }
        })
      }
      count(this.target.children)
      return len
    },
    ...mapState(['targetTypeList', 'screenWidth'])
  },
  watch: {
    // 如果数据的end_date改变了，那么让input框里的值也跟着改变
    'target.end_date'() {
      this.lastTime = this.target.end_date
    },
    isEscAdd(val) {
      if (val) {
        this.$refs[this.editNameRef].blur()
      }
    }
  },
  methods: {
    // 点击开始选择目标的类型
    toChooseTargetType(target, e) {
      // 获取点击的位置的坐标
      const { pageX, pageY } = e
      // 把点击的位置和任务数据传给父组件
      const typeBoxInfo = {
        top: pageY + 30,
        left: pageX - 20,
        id: target.objective_id,
        oldVal: target.objective_type,
        clerkId: target.create_clerk_id
      }
      this.toType(typeBoxInfo)
    },
    // 触发失焦事件
    toBlur(e, arg) {
      e.target.blur(arg)
    },
    // 以下方法把事件向外传递，不做别的操作
    // 让父级刷新
    toFresh() {
      this.$emit('to-fresh-target')
    },
    // 让父级显示目标类型选择
    toType(obj) {
      this.$emit('show-type-choose', obj)
    },
    // 让父级选择关联目标
    toRelative(obj) {
      this.$emit('to-choose-relative', obj)
    },
    // 设置进度
    toProgress(obj) {
      this.$emit('show-progress-set', obj)
    },
    // 点击开始设置目标进度
    toSetTargetProgress(target, e) {
      // 获取点击的位置的坐标
      const { pageX, pageY } = e
      // 把点击的位置和任务数据传给父组件
      const progressBoxInfo = {
        top: pageY + 10,
        left: pageX - 100,
        id: target.objective_id,
        status: target.progress_status,
        progress: target.progress_number,
        clerkId: target.create_clerk_id
      }
      this.toProgress(progressBoxInfo)
    },
    // 准备展示出目标的更多弹窗
    toShowMore(target, e) {
      // 获取点击的位置的坐标
      const { pageX, pageY } = e
      // 把点击的位置和任务数据传给父组件
      const moreBoxInfo = {
        top: pageY + 10,
        left: pageX + 10,
        id: target.objective_id,
        clerkId: target.create_clerk_id,
        target
      }
      this.showMore(moreBoxInfo)
    },
    // 监听自己的事件，向外扩散
    showMore(obj) {
      this.$emit('show-more', obj)
    },
    // 开始编辑
    readyToEdit() {
      // 让对应的输入框显示出来
      this.isEditTarget = true
      this.tempName = this.target.objective_name
      // 让输入框回填和聚焦
      this.$nextTick(() => {
        this.$refs[this.editNameRef].focus()
      })
    },
    // 处理编辑目标名字
    async handleEditTarget() {
      if (this.tempName === this.target.objective_name) {
        // 如果字没有改变，直接关闭，不发请求
        this.cancelEditTarget()
        this.isEscAdd = false
        return
      }
      if (this.isEscAdd) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.cancelEditTarget()
          this.isEscAdd = false
          return
        }
      }
      console.log(this.currentTarget)
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetName, {
          objective_id: this.target.objective_id,
          objective_name: this.tempName
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '目标名字修改成功',
          type: 'success'
        })
        this.cancelEditTarget()
        this.$emit('to-fresh-target')
      }
      this.isEscAdd = false
    },
    // 取消编辑目标名字
    cancelEditTarget() {
      this.isEditTarget = false
      this.tempName = ''
    },
    // 准备选择关联
    toChooseRelative(target) {
      this.toRelative(target)
    },
    // 点击复选框
    async setTargetStatus(id) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTargetStatus, {
          objective_id: id,
          objective_status: 3
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$emit('to-fresh-target')
      }
    },
    // 去详情
    toTargetDetail(id) {
      this.$emit('to-target-detail', id)
    },
    // 设置截止时间
    async setLastTime() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTargetEndTime, {
          objective_id: this.target.objective_id,
          end_time: this.lastTime
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '修改截止日期成功',
          type: 'success'
        })
      }
    },
    // 展开收起子元素
    toggleChildren() {
      this.isShowChild = !this.isShowChild
    },
    // 空函数
    empty() {},
    // 解析标签
    parseLabel(name, labelList) {
      const labelMap = {}
      labelList.forEach((e) => {
        labelMap[e.label_name] = e.label_id
      })
      let showName = ''
      const list = []
      let status = 0
      let gth = 0
      let item = ''
      name.split('').forEach((e) => {
        let te = e
        // 处理下中文符号
        switch (te) {
          case '！': {
            te = '!'
          }
        }
        switch (status) {
          // 开始
          case 0: {
            switch (te) {
              case '!': {
                if (gth === 0) {
                  gth++
                  status = 1
                } else {
                  showName += e
                  status = 11
                }
                break
              }
              case ' ': {
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                showName += e
                status = 11
                break
              }
            }
            break
          }
          case 1: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                status = 0
                showName += e
                break
              }
            }
            break
          }
          case 2: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              default: {
                status = 11
                showName += e
                break
              }
            }
            break
          }
          case 10: {
            switch (te) {
              case '!': {
                if (item !== '') {
                  list.push(item)
                } else {
                  showName += '#'
                }
                item = ''
                if (gth === 0) {
                  gth++
                  status = 2
                } else {
                  status = 11
                }
                break
              }
              case ' ': {
                if (item !== '') {
                  list.push(item)
                }
                item = ''
                status = 0
                break
              }
              default: {
                item += e
                break
              }
            }
            break
          }
          // 直接加
          case 11: {
            showName += e
            break
          }
        }
      })
      if (status === 10) {
        if (item.length > 0) {
          list.push(item)
        }
      }
      let gthStr = ''
      for (let i = 0; i < gth; i++) {
        gthStr += '!'
      }
      return {
        showName,
        list: list.map((e) => {
          return {
            id: labelMap[e] || '',
            name: e
          }
        }),
        gth,
        gthStr
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.target-item ::v-deep .el-date-editor .el-input__inner {
  padding: 0;
  text-align: center;
}

.target-item ::v-deep .el-input__icon {
  display: none;
}

.target-item {
  position: relative;
}

// 超时变红
.time-box.is-late ::v-deep .el-date-editor .el-input__inner {
  color: $red;
}
.time-box ::v-deep {
  .el-input--prefix .el-input__inner {
    border: 1px solid #fff;
  }
  .el-input--prefix .el-input__inner:hover,
  .el-input--prefix .el-input__inner:focus {
    border-color: $main-color;
  }
}

// 标签
.target-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: min-content;
  padding: 0 3px;
  white-space: nowrap;
  line-height: 21px;
  color: #2b6cff;
  background: #e2ebff;
  border-radius: 3px;
  font-size: 12px;
}
</style>
