<!--
 * @Author: hzh
 * @Date: 2021-12-30 19:15:13
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-08 11:57:40
-->
<template>
  <div class="calendar-box flex flex-y-positive">
    <div class="header-box flex flex-y-center flex-x-between">
      <div class="left">{{ $util.toDateString(startDate, 'yyyy年MM月') }}</div>
      <div class="right">
        <el-button-group>
          <el-button type="plain" size="mini" @click="prevMonth"
            >上个月</el-button
          >
          <el-button type="plain" size="mini" @click="toNow">今天</el-button>
          <el-button type="plain" size="mini" @click="nextMonth"
            >下个月</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="table-box flex1 flex flex-y-positive">
      <div class="table-header flex">
        <div class="flex1">一</div>
        <div class="flex1">二</div>
        <div class="flex1">三</div>
        <div class="flex1">四</div>
        <div class="flex1">五</div>
        <div class="flex1">六</div>
        <div class="flex1">日</div>
      </div>
      <div class="table-body flex1 flex flex-wrap">
        <div
          class="item"
          :class="{
            before: item.isBefore
          }"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <timeline-popover
            :date="item.dateObj"
            :list="item.list"
            :now="now"
            :visible.sync="timelineShowMap[item.dateStr]"
            :placement="`${index % 7 < 3 ? 'right' : 'left'}${
              index < 7 ? '-start' : index > 34 ? '-end' : ''
            }`"
          >
            <template #default>
              <div
                class="time"
                @click="
                  closeAllMoreList()
                  closeAllTimeline()
                  timelineShowMap[item.dateStr] = !timelineShowMap[item.dateStr]
                "
              >
                <span
                  class="text"
                  :class="{
                    'next-month': item.isNextMonth,
                    'prev-month': item.isPrevMonth,
                    today: item.isToday
                  }"
                  >{{ item.date }}</span
                >
              </div>
            </template>
          </timeline-popover>
          <div class="meeting-list">
            <template
              v-for="mitem in item.list.length <= 3
                ? item.list
                : item.list.slice(0, 2)"
              ><info-popover
                :key="mitem.meeting_id"
                :data="mitem"
                :placement="`${index % 7 < 3 ? 'right' : 'left'}${
                  index < 7 ? '-start' : index > 34 ? '-end' : ''
                }`"
              >
                <template #default>
                  <div
                    class="meeting-item flex flex-y-center"
                    @click="
                      closeAllMoreList()
                      closeAllTimeline()
                    "
                  >
                    <div class="dot"></div>
                    <div class="meeting-name flex1">
                      {{ mitem.meeting_name.substr(0, 5) }}
                    </div>
                  </div>
                </template>
              </info-popover>
            </template>
            <list-popover
              v-if="item.list.length >= 4"
              :date="item.dateObj"
              :list="item.list"
              :visible.sync="moreListShowMap[item.dateStr]"
              :placement="`${index % 7 < 3 ? 'right' : 'left'}${
                index < 14 ? '-start' : index > 27 ? '-end' : ''
              }`"
            >
              <template #default>
                <div
                  class="meeting-item"
                  @click="
                    closeAllMoreList()
                    closeAllTimeline()
                    moreListShowMap[item.dateStr] =
                      !moreListShowMap[item.dateStr]
                  "
                >
                  还有{{ item.list.length - 2 }}个日程
                </div>
              </template>
            </list-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoPopover from './meeting-room-occupy-meeting-info-popover'
import ListPopover from './meeting-room-occupy-meeting-list-popover'
import TimelinePopover from './meeting-room-occupy-meeting-timeline-popover'
export default {
  name: 'meetingRoomOccupyCalendar',
  components: {
    InfoPopover,
    ListPopover,
    TimelinePopover
  },
  props: {
    roomId: Number
  },
  data() {
    const startDate = new Date()
    startDate.setDate(1)
    return {
      meetingList: [],
      now: new Date(),
      // 当前月的1号
      startDate: startDate,
      // 更多列表展示map
      moreListShowMap: {},
      // 时间轴展示map
      timelineShowMap: {}
    }
  },
  computed: {
    nowStr() {
      return this.$util.toDateString(this.now, 'yyyy-MM-dd')
    },
    nowTime() {
      return this.now.getTime()
    },
    // 这个月的天数
    monthDayNumber() {
      const endDate = new Date(this.startDate.getTime())
      endDate.setMonth(endDate.getMonth() + 1)
      endDate.setDate(0)
      return endDate.getDate()
    },
    // 周期开始时间
    rangeStartDate() {
      const startDate = new Date(this.startDate.getTime())
      const startDay = [7, 1, 2, 3, 4, 5, 6][startDate.getDay()]
      const prevDayNumber = startDay - 1
      if (prevDayNumber === 0) {
        return startDate
      }
      startDate.setDate(0)
      console.log(startDate.toDateString())
      startDate.setDate(startDate.getDate() - prevDayNumber + 1)
      return startDate
    },
    // 周期结束时间
    rangeEndDate() {
      const startDate = new Date(this.startDate.getTime())
      const startDay = [7, 1, 2, 3, 4, 5, 6][startDate.getDay()]
      const prevDayNumber = startDay - 1
      const nextDayNumber = 42 - this.monthDayNumber - prevDayNumber
      startDate.setMonth(startDate.getMonth() + 1)
      startDate.setDate(nextDayNumber)
      return startDate
    },
    tableData() {
      const startDate = new Date(this.startDate.getTime())
      const startDay = [7, 1, 2, 3, 4, 5, 6][startDate.getDay()]
      const prevDayNumber = startDay - 1
      const nextDayNumber = 42 - this.monthDayNumber - prevDayNumber
      const list = []
      // 上月最后一天
      const prevMonthEndDate = new Date(this.startDate.getTime())
      prevMonthEndDate.setDate(0)
      // 下个月第一天
      const nextMonthStartDate = new Date(this.startDate.getTime())
      nextMonthStartDate.setMonth(nextMonthStartDate.getMonth() + 1)
      nextMonthStartDate.setDate(1)

      // 每项的数据
      const getData = (date) => {
        const dateStr = this.$util.toDateString(date, 'yyyy-MM-dd')
        // 当天的开始时间
        const startDate = new Date(date)
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)
        const startTime = startDate.getTime()
        // 当天的结束时间
        const endDate = new Date(date)
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)
        const endTime = endDate.getTime()
        return {
          dateObj: new Date(date.getTime()),
          dateStr: dateStr,
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          date: date.getDate(),
          list: this.meetingList.filter((e) => {
            const meetingStartTime = e.start_time_obj.getTime()
            const meetingEndTime = e.end_time_obj.getTime()
            // 判断会议的开始和结束时间是否包含当天
            return (
              (meetingStartTime >= startTime && meetingStartTime <= endTime) ||
              (meetingStartTime <= startTime && meetingEndTime >= endTime) ||
              (meetingEndTime >= startTime && meetingEndTime <= endTime)
            )
          }),
          isToday: dateStr === this.nowStr,
          isBefore: endTime < this.nowTime,
          isAfter: startTime > this.nowTime
        }
      }
      // 上个月
      for (
        let i = 0, endDateNumber = prevMonthEndDate.getDate();
        i < prevDayNumber;
        i++
      ) {
        prevMonthEndDate.setDate(endDateNumber - i)
        list.unshift({ ...getData(prevMonthEndDate), isPrevMonth: true })
      }
      // 这个月
      for (let i = 0; i < this.monthDayNumber; i++) {
        startDate.setDate(1 + i)
        list.push({ ...getData(startDate) })
      }
      // 下个月
      for (let i = 0; i < nextDayNumber; i++) {
        nextMonthStartDate.setDate(1 + i)
        list.push({ ...getData(nextMonthStartDate), isNextMonth: true })
      }
      return list
    }
  },
  methods: {
    // 获取数据
    async getData() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingRoomOccupyList, {
          room_id: this.roomId,
          start_time: this.$util.toDateString(this.rangeStartDate),
          end_time: this.$util.toDateString(this.rangeEndDate)
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.meetingList = res.list.map((e) => {
            const startTime = new Date(e.start_time.replaceAll('-', '/'))
            const endTime = new Date(e.end_time.replaceAll('-', '/'))
            return {
              ...e,
              more_visible: false,
              start_time_obj: startTime,
              end_time_obj: endTime
            }
          })
        }
      }
    },
    // 刷新数据
    async refreshData() {
      await this.getData()
    },
    // 上个月
    prevMonth() {
      const startDate = new Date(this.startDate.getTime())
      startDate.setMonth(startDate.getMonth() - 1)
      this.startDate = startDate
      this.refreshData()
    },
    // 跳到今天
    toNow() {
      const startDate = new Date(this.now.getTime())
      startDate.setDate(1)
      this.startDate = startDate
      this.refreshData()
    },
    // 下个月
    nextMonth() {
      const startDate = new Date(this.startDate.getTime())
      startDate.setMonth(startDate.getMonth() + 1)
      this.startDate = startDate
      this.refreshData()
    },
    // 关闭所有更多列表
    closeAllMoreList() {
      for (const key in this.moreListShowMap) {
        this.moreListShowMap[key] = false
      }
    },
    // 关闭所有时间轴
    closeAllTimeline() {
      for (const key in this.timelineShowMap) {
        this.timelineShowMap[key] = false
      }
    }
  },
  mounted() {},
  watch: {
    roomId: {
      handler(val) {
        if (this.roomId) {
          this.toNow()
        }
      },
      immediate: true
    },
    tableData(val) {
      const showMap = {}
      val.forEach((e) => {
        showMap[e.dateStr] = false
      })
      this.moreListShowMap = { ...showMap }
      this.timelineShowMap = { ...showMap }
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-box {
  height: 100%;
}
.header-box {
  height: 60px;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 20px;
  .right {
    .el-button {
      padding: 5px 10px;
    }
    .el-button-group > .el-button:not(:last-child) {
      margin-right: -1px;
    }
    .el-button-group > .el-button + .el-button {
      margin-left: 0;
    }
  }
}
.table-box {
  padding: 5px 20px;

  .table-header {
    div {
      height: 40px;
      line-height: 40px;
      color: #333;
      text-align: center;
    }
  }
  .table-body {
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    box-sizing: border-box;

    .item {
      width: calc(100% / 7);
      height: calc(100% / 6);
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      box-sizing: border-box;
      &.before {
        background-color: #f9f9f9;
      }
      .time {
        padding: 0 8px;
        line-height: 30px;
        cursor: pointer;
        .text {
          font-size: 14px;
          color: #333333;
          &.next-month,
          &.prev-month {
            color: #d7d7d7;
          }
          &.today {
            display: inline-block;
            width: 22px;
            color: #fff;
            border-radius: 50%;
            background: #409eff;
            line-height: 22px;
            text-align: center;
          }
        }
      }

      .meeting-list {
        .meeting-item {
          padding: 0 8px;
          width: 100%;
          line-height: 20px;
          box-sizing: border-box;

          cursor: pointer;
          &:hover {
            background-color: #f7f7f7;
          }
          .dot {
            width: 8px;
            height: 8px;
            margin: 0 8px 0 0;
            background-color: #c6dbff;
            box-sizing: border-box;
            border-radius: 50%;
          }
          .meeting-name {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
