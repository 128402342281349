<!--
 * @Author: Betty
 * @Date: 2021-07-12 11:34:45
 * @LastEditors: hzh
 * @LastEditTime: 2021-10-08 16:50:29
 * @Description: 编辑会议室的弹窗组件
-->
<template>
  <div>
    <div class="modal-bg" v-if="isShow" @click="closeModal"></div>
    <!-- 白色弹窗 -->
    <transition name="meetingRoom">
      <div class="create-room-box" v-if="isShow">
        <!-- 头部，标题和关闭按钮 -->
        <div class="flex flex-y-center flex-x-between box-header">
          <h6 class="box-title">{{ modalTitle }}</h6>
          <button type="button" class="close-btn" @click="closeModal">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
          </button>
        </div>
        <!-- 主体部分 -->
        <div class="box-main">
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label class="required">会议室名称</label>
            <input
              type="text"
              class="input"
              v-model="currentRoomName"
              placeholder="请输入会议室名称"
            />
          </div>
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label class="required">容量</label>
            <input
              type="number"
              class="input"
              v-model="roomVolume"
              min="0"
              placeholder="请输入会议室人员容量"
            />
          </div>
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label class="required">城市</label>
            <input
              type="text"
              class="input"
              v-model="cityResult"
              placeholder="请选择会议室所在的城市"
              @click="beginChooseCity"
              readonly
            />
          </div>
          <!-- 表单里面的每一行 -->
          <div class="create-box-line">
            <label class="required">详细位置</label>
            <input
              type="text"
              class="input"
              v-model="detailAddress"
              placeholder="请输入关键位置信息"
            />
          </div>
          <!-- 设备信息 -->
          <div class="create-box-line">
            <label>设备信息</label>
            <div class="device-info-box">
              <div class="flex flex-wrap">
                <!-- 里面的每一个设备 -->
                <div
                  class="device-item flex flex-y-center"
                  v-for="(device, index) in deviceList"
                  :key="index"
                  @click="chooseDevice(device.device_id)"
                >
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="
                        deviceIdList.includes(device.device_id)
                          ? '#icon-TickSquare_1'
                          : '#icon-TickSquare'
                      "
                    ></use>
                  </svg>
                  <p class="device-name">{{ device.device_name }}</p>
                </div>
              </div>
              <!-- 添加设备类型 -->
              <button
                class="flex flex-y-center add-device-btn"
                @click="toAddDevice"
                v-if="perInfo.addDevice"
              >
                <span class="iconfont icon-add"></span>
                <span class="add-text">添加设备类型</span>
              </button>
            </div>
          </div>
          <!-- 状态 -->
          <div class="create-box-line">
            <label>状态</label>
            <div class="flex flex-y-center">
              <!-- 里面的每一个状态 -->
              <div
                class="state-item flex flex-y-center normal"
                :class="{ active: roomState === 1 }"
                @click="setRoomState(1)"
              >
                <svg class="icon" aria-hidden="true">
                  <use
                    :xlink:href="
                      roomState === 1
                        ? '#icon-a-confirmfill2'
                        : '#icon-a-confirmfill21'
                    "
                  ></use>
                </svg>
                <span class="state-text">正常</span>
              </div>
              <!-- 里面的每一个状态 -->
              <div
                class="state-item flex flex-y-center disable"
                :class="{ active: roomState === 0 }"
                @click="setRoomState(0)"
              >
                <svg class="icon" aria-hidden="true">
                  <use
                    :xlink:href="
                      roomState === 0
                        ? '#icon-a-confirmfill3'
                        : '#icon-a-confirmfill11'
                    "
                  ></use>
                </svg>
                <span class="state-text">禁用</span>
              </div>
            </div>
          </div>
          <!-- 备注 -->
          <div class="create-box-line">
            <label>备注</label>
            <textarea
              placeholder="请输入备注信息"
              class="textarea"
              v-model="roomRemark"
            ></textarea>
          </div>
          <!-- 保存和取消按钮 -->
          <!-- 最后一行，创建会议等按钮 -->
          <div class="button-line flex flex-y-center">
            <button
              class="meeting-btn active"
              type="button"
              @click="confirmAddRoom"
            >
              保存
            </button>
            <button
              class="meeting-btn cancel"
              type="button"
              @click="closeModal"
            >
              取消
            </button>
          </div>
        </div>
        <!-- 添加设备的弹窗 -->
        <el-dialog
          title="添加设备"
          :visible.sync="isShowAddDevice"
          width="510"
          class="add-device-modal"
          :append-to-body="true"
        >
          <div class="add-device-input">
            <input
              type="text"
              class="input"
              placeholder="编辑设备名称"
              v-model="deviceName"
            />
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelAddDevice">取 消</el-button>
            <el-button type="primary" @click="confirmAddDevice"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <!-- 选择城市的弹窗 -->
        <el-dialog
          title="选择城市"
          class="choose-city-modal"
          :visible.sync="isShowChooseCity"
          :append-to-body="true"
          :width="screenWidth > 767 ? 480 : '100vw'"
        >
          <div>
            <!-- 第一块，选择结果 -->
            <div class="choose-result">
              已选择: <span class="result">{{ currentCity }}</span>
            </div>
            <!-- 第二块，选择省份和城市 -->
            <div class="choose-table">
              <div class="choose-table-header flex flex-y-center">
                <div class="flex1 table-header-item">省份</div>
                <div class="flex1 table-header-item">城市</div>
              </div>
              <!-- 选择省份和城市 -->
              <div class="flex choose-city">
                <!-- 左边，选择省份 -->
                <div class="flex1 place-box" id="provinceBox">
                  <div
                    class="place-item"
                    :class="{ active: currentProvinceIndex === index }"
                    v-for="(item, index) in provinceList"
                    :key="index"
                    @click="chooseProvince(index)"
                  >
                    {{ item.province_name }}
                  </div>
                </div>
                <!-- 右边，选择城市 -->
                <div class="flex1 place-box" id="cityBox">
                  <div
                    class="place-item"
                    v-for="(item, index) in cityList"
                    :class="{ active: currentCityIndex === index }"
                    :key="index"
                    @click="chooseCity(index)"
                  >
                    {{ item.city_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelChooseCity">取 消</el-button>
            <el-button type="primary" @click="confirmChooseCity"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'edit-meeting-room-info',
  props: {
    // 是否显示弹窗
    isShow: {
      type: Boolean,
      default: false
    },
    // 弹窗标题
    modalTitle: {
      type: String,
      default: '添加会议室'
    },
    // 当前编辑的会议室id
    roomId: {
      type: Number,
      default: -1
    },
    // 设备列表
    deviceList: {
      type: Array,
      default: () => []
    }
    // 省份列表
    // provinceList: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      // 会议室详情
      roomDetail: null,
      // 输入的会议室名称
      currentRoomName: '',
      // 会议室容量
      roomVolume: '',
      // 会议室的详细地址
      detailAddress: '',
      // 会议室备注
      roomRemark: '',
      // 会议室状态
      roomState: 1,
      // 是否显示添加设备弹窗
      isShowAddDevice: false,
      // 输入的设备名字
      deviceName: '',
      // 选择的设备id列表
      deviceIdList: [],
      //  是否展示选择城市的弹窗
      isShowChooseCity: false,
      // 城市列表
      // cityList: [],
      // 当前选择的省份下标
      currentProvinceIndex: 0,
      // 当前选择的城市下标
      currentCityIndex: 0,
      // 城市选择结果
      cityResult: ''
    }
  },
  computed: {
    ...mapState(['provinceList', 'cityListMap', 'screenWidth']),
    perInfo() {
      return {
        addDevice: this.$hasPer(38)
      }
    },
    cityList() {
      if (this.cityListMap[this.currentProvinceId] == null) {
        return []
      }
      return this.cityListMap[this.currentProvinceId]
    },
    // 当前选择的省份id
    currentProvinceId() {
      return (
        this.provinceList[this.currentProvinceIndex] &&
        this.provinceList[this.currentProvinceIndex].province_id
      )
    },
    // 当前选择的省份名字
    currentProvinceName() {
      return (
        this.provinceList[this.currentProvinceIndex] &&
        this.provinceList[this.currentProvinceIndex].province_name
      )
    },
    // 当前选择的城市id
    currentCityId() {
      return (
        this.cityList[this.currentCityIndex] &&
        this.cityList[this.currentCityIndex].city_id
      )
    },
    // 当前选择的城市名字
    currentCityName() {
      return (
        this.cityList[this.currentCityIndex] &&
        this.cityList[this.currentCityIndex].city_name
      )
    },
    // 当前所在城市
    currentCity() {
      return `${this.currentProvinceName}-${this.currentCityName || ''}`
    }
  },
  watch: {
    // 根据会议室id，获取会议室详情
    roomId: {
      immediate: true,
      handler(val) {
        if (this.isShow) {
          // 如果当前是编辑，就想去先去获取会议室详情，否则清空表单
          if (val !== -1) {
            this.getRoomDetail()
          }
        }
      }
    },
    // 当前选择的省份id改变了，马上去请求它的城市列表
    currentProvinceId(val) {
      this.currentCityIndex = 0
      this.$nextTick(() => {
        if (document.querySelector('#cityBox')) {
          document.querySelector('#cityBox').scrollTop = 0
          this.getCityList(val)
        }
      })
    },
    // 打开的时候看一下要不要清空数据
    isShow: {
      immediate: true,
      handler(val) {
        if (val && this.roomId === -1) {
          this.clearData()
        }
      }
    }
  },
  mounted() {
    this.getProvinceList()
    // if (this.cityList.length === 0) {
    // this.getCityList(this.provinceList[this.currentProvinceIndex].province_id)
    // }
  },
  methods: {
    ...mapActions(['getProvinceList', 'getCityList']),
    // 请求城市列表
    // async getCityList(id) {
    //   const [err, res] = await this.$util.to(
    //     this.$http.post(this.$api.getCityList, {
    //       province_id: id
    //     })
    //   )
    //   if (err) {
    //     console.log(err)
    //   } else if (res.state === 'ok') {
    //     this.cityList = res.list
    //   }
    // },
    // 获取会议室详情
    async getRoomDetail() {
      if (this.roomId !== -1) {
        const [err, res] = await this.$util.to(
          this.$http.post(this.$api.getMeetingRoomDetail, {
            room_id: this.roomId
          })
        )
        if (err) {
          console.log(err)
        } else if (res.state === 'ok') {
          this.roomDetail = res.info
          // 初始化，回填表单
          // 名字
          this.currentRoomName = this.roomDetail.room_name
          // 容量
          this.roomVolume = this.roomDetail.room_galleryful
          // 省份和城市
          this.currentProvinceIndex = this.provinceList.findIndex(
            (item) => item.province_id === this.roomDetail.province_id
          )
          // this.$nextTick(() => {
          this.getCityList(this.currentProvinceId).then((list) => {
            this.currentCityIndex = list.findIndex(
              (item) => item.city_id === this.roomDetail.city_id
            )
            this.cityResult = `${this.currentProvinceName}-${
              this.currentCityName || ''
            }`
          })
          // })
          // 地址
          this.detailAddress = this.roomDetail.room_address
          // 状态
          this.roomState = this.roomDetail.is_enable
          // 设备信息
          this.deviceIdList = this.roomDetail.device_list.map(
            (item) => item.device_id
          )
          // 备注
          this.roomRemark = this.roomDetail.remark
        }
      }
    },
    // 清空表单的数据
    clearData() {
      console.log('准备清空')
      this.roomDetail = null
      this.currentRoomName = ''
      this.roomVolume = ''
      this.detailAddress = ''
      this.roomRemark = ''
      this.roomState = 1
      this.deviceIdList = []
      this.currentProvinceIndex = 0
      this.currentCityIndex = 0
      this.cityResult = ''
    },
    // 点击关闭弹窗
    closeModal() {
      this.$emit('close-modal')
    },
    // 准备添加设备
    toAddDevice() {
      this.isShowAddDevice = true
    },
    // 确定添加设备
    async confirmAddDevice() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addRoomDevice, {
          device_name: this.deviceName
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '添加设备成功',
          type: 'success'
        })
        // 关闭弹窗
        this.cancelAddDevice()
        // 重新获取设备列表
        this.$emit('fresh-device-list')
      }
    },
    // 取消添加设备
    cancelAddDevice() {
      this.isShowAddDevice = false
      this.deviceName = ''
    },
    // 点击选择设备
    chooseDevice(id) {
      const index = this.deviceIdList.indexOf(id)
      if (index > -1) {
        this.deviceIdList.splice(index, 1)
      } else {
        this.deviceIdList.push(id)
      }
    },
    // 设置会议室状态
    setRoomState(val) {
      this.roomState = val
    },
    // 开始选择城市
    beginChooseCity() {
      this.isShowChooseCity = true
      // 让之前选择的省和市显示到顶部
      this.$nextTick(() => {
        if (this.currentProvinceIndex > 4) {
          document.querySelector('#provinceBox').scrollTop =
            this.currentProvinceIndex * 39
        }
        if (this.currentCityIndex > 4) {
          document.querySelector('#cityBox').scrollTop =
            this.currentCityIndex * 39
        }
      })
    },
    // 取消选择城市
    cancelChooseCity() {
      this.isShowChooseCity = false
    },
    // 确定选择城市
    confirmChooseCity() {
      this.cancelChooseCity()
      this.cityResult = this.currentCity
    },
    // 点击选择省份
    chooseProvince(index) {
      this.currentProvinceIndex = index
    },
    // 点击选择城市
    chooseCity(index) {
      this.currentCityIndex = index
    },
    // 确定创建/保存会议
    async confirmAddRoom() {
      // 收集表单里的数据
      const formObj = {
        // 会议室的名字
        'room.room_name': this.currentRoomName,
        // 地址、选择的省市
        'room.room_address': this.detailAddress,
        'room.province_id': this.currentProvinceId,
        'room.city_id': this.currentCityId,
        // 容量
        'room.room_galleryful': this.roomVolume,
        // 备注
        'room.remark': this.roomRemark,
        // 是否可用
        'room.is_enable': this.roomState,
        // 设备id
        device_ids: this.deviceIdList.join()
      }
      let link = ''
      let tip = ''
      // 创建会议
      if (this.roomId === -1) {
        link = this.$api.addMeetingRoom
        tip = '会议室创建成功'
      } else {
        // 修改会议
        formObj['room.room_id'] = this.roomId
        link = this.$api.editMeetingRoom
        tip = '会议室编辑成功'
      }
      const [err, res] = await this.$util.to(this.$http.post(link, formObj))
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: tip,
          type: 'success'
        })
        // 成功以后关闭弹窗，并且让父组件刷新会议室列表
        this.closeModal()
        this.$emit('fresh-room-list')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/meeting_room_modal.scss';
</style>
