<!--
 * @Author: hzh
 * @Date: 2021-12-17 17:24:13
 * @LastEditors: hzh
 * @LastEditTime: 2021-12-31 18:03:43
-->
<template>
  <div class="clerk-box">
    <template v-if="!isXs">
      <div class="flex flex-x-between" style="margin-bottom: 20px">
        <div>
          <el-select
            v-model="currentCycleId"
            id="select-roa"
            style="margin-right: 10px"
            size="small"
          >
            <el-option
              v-for="item in cycleList"
              :key="item.cycle_id"
              :value="item.cycle_id"
              :label="`${item.start_date} - ${item.end_date}`"
            >
              {{ item.start_date }} - {{ item.end_date }}
            </el-option>
          </el-select>
          <el-select v-model="currentTab" placeholder="请选择" size="small" style="width:120px">
            <el-option label="任务" value="task"></el-option>
            <el-option label="目标" value="target"></el-option>
          </el-select>
        </div>
        <div>
          <el-button
            plain
            id="select-roc"
            @click.stop="showComment = true"
            type="primary"
            style="margin-left: 16px"
            size="small"
          >
            <i class="el-icon-s-comment"></i>
            <span>评论</span>
          </el-button>
        </div>
      </div>
    </template>
    <template v-if="isXs">
      <div class="header-box flex flex-y-center flex-x-between">
        <div class="flex flex-y-center">
          <i class="icon-a-arrowaltright1 back-btn" @click="back()"></i>
          <span>周期 {{ month }}</span>
        </div>
        <i
          class="icon-shaixuan screen-btn"
          @click="showVantPickerCycle = true"
        ></i>
      </div>
      <div class="tab-box flex flex-y-center flex-x-between" v-if="isXs">
        <div
          class="tab-item flex1"
          :class="{ active: currentTab === 'task' }"
          @click="currentTab = 'task'"
        >
          <span class="text">任务</span>
        </div>
        <div
          class="tab-item flex1"
          :class="{ active: currentTab === 'target' }"
          @click="currentTab = 'target'"
        >
          <span class="text">目标</span>
        </div>
        <div
          class="tab-item flex1"
          :class="{ active: currentTab === 'comment' }"
          @click="currentTab = 'comment'"
        >
          <span class="text">评论</span>
        </div>
      </div>
    </template>
    <div class="content-box" :class="[currentTab]">
      <!-- 任务tab -->
      <work-report-month-clerk-task
        ref="workReportMonthClerkTask"
        :data="{
          report_data: reportData,
          prev_report_data: prevReportData,
          data_list: taskDataList,
          columnar_list: taskColumnarList
        }"
        :widthType="isXs ? 'xs' : 'lg'"
        v-show="currentTab === 'task'"
      />
      <!-- 目标tab -->
      <work-report-month-clerk-target
        ref="workReportMonthClerkTarget"
        :data="{
          report_data: reportData,
          prev_report_data: prevReportData,
          data_list: objectiveDataList,
          columnar_list: objectiveColumnarList
        }"
        :widthType="isXs ? 'xs' : 'lg'"
        v-show="currentTab === 'target'"
      />
      <!-- 评论tab -->
      <work-report-comment
        v-if="isXs"
        :reportId="reportId"
        v-show="currentTab === 'comment'"
      />
    </div>
    <!-- 评论侧边弹窗 -->
    <work-report-comment-drawer
      v-if="!isXs"
      :reportId="reportId"
      :visible.sync="showComment"
    />
    <!-- 选择周期 -->
    <vant-popup
      v-if="isXs"
      v-model="showVantPickerCycle"
      round
      position="bottom"
    >
      <vant-picker
        show-toolbar
        :columns="vantPickerCycleColumns"
        @confirm="
          currentCycleId = cycleList[arguments[1]].cycle_id
          showVantPickerCycle = false
        "
        :default-index="
          cycleList.findIndex((e) => e.cycle_id === currentCycleId)
        "
        @cancel="showVantPickerCycle = false"
      ></vant-picker>
    </vant-popup>
  </div>
</template>

<script>
import WorkReportMonthClerkTask from './work-report-month-clerk-task'
import WorkReportMonthClerkTarget from './work-report-month-clerk-target'
import WorkReportCommentDrawer from './work-report-comment-drawer'
import WorkReportComment from './work-report-comment'
import { mapState, mapGetters } from 'vuex'
import { Picker as VantPicker, Popup as VantPopup } from 'vant'

import 'vant/lib/picker/style'
import 'vant/lib/popup/style'
export default {
  name: 'workReportMonthClerk',
  components: {
    WorkReportMonthClerkTask,
    WorkReportMonthClerkTarget,
    WorkReportCommentDrawer,
    WorkReportComment,
    VantPicker,
    VantPopup
  },
  props: {},
  data() {
    return {
      // 类型 1任务 2目标
      currentTab: 'task',
      // 周期列表
      cycleList: [],
      currentCycleId: null,
      // 路由报告id
      routeReportId: '',
      // 图表数据
      taskDataList: [],
      taskColumnarList: [],
      objectiveDataList: [],
      objectiveColumnarList: [],
      reportData: {},
      prevReportData: {},
      // 评论数量
      commentCount: 0,
      // 展示评论
      showComment: false,
      // 展示周期
      showVantPickerCycle: false
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl']),
    // 周期id
    reportId() {
      return this.reportData?.report_id
    },
    // 当前周期
    currentCycle() {
      return (
        this.cycleList.find((e) => e.cycle_id === this.currentCycleId) || {}
      )
    },
    // 月
    month() {
      if (!this.currentCycle.start_date) {
        return ''
      }
      const startDate = new Date(
        this.currentCycle.start_date.replaceAll('-', '/')
      )
      return this.$util.toDateString(startDate, 'yyyy/MM')
    },
    // vant选择周期内容列表
    vantPickerCycleColumns() {
      return this.cycleList.map((e) => {
        return `${this.$util.toDateString(
          e.start_date,
          'MM月dd日'
        )} - ${this.$util.toDateString(e.end_date, 'MM月dd日')}`
      })
    }
  },
  methods: {
    // 获取周期列表
    async getCycleList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportCyclePage, {
          cycle_type: 2,
          clerk_id: this.myClerkId
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.cycleList = res.page.list.map((e) => {
            return {
              ...e,
              start_date: e.start_date.split(' ')[0],
              end_date: e.end_date.split(' ')[0]
            }
          })
          // 暂时这么写
          if (this.routeReportId !== '') {
            this.currentCycleId = res.page.list.find(
              (e) => `${e.report_id}` === this.routeReportId
            ).cycle_id
          } else {
            if (res.page.list.length > 0) {
              this.currentCycleId = res.page.list[0].cycle_id
            }
          }
        }
      }
    },
    // 刷新周期列表
    async refreshCycleList() {
      await this.getCycleList()
      // await this.refreshData()
    },
    // 获取数据
    async getData() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportClerkData, {
          // cycle_id: this.currentCycleId
          report_id: this.routeReportId
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.taskDataList = res.task_data_list
          this.taskColumnarList = res.task_columnar_list
          this.objectiveDataList = res.objective_data_list
          this.objectiveColumnarList = res.objective_columnar_list
          this.reportData = res.report || {}
          this.prevReportData = res.prev_report || {}
          this.commentCount = res.comment_count || 0
          this.currentCycleId = res.report.cycle_id
        }
      }
    },
    // 刷新数据
    async refreshData() {
      await this.getData()
      this.renderChart()
    },
    // 渲染图表
    renderChart() {
      switch (this.currentTab) {
        case 'task': {
          this.$refs.workReportMonthClerkTask.renderChart()
          break
        }
        case 'target': {
          this.$refs.workReportMonthClerkTarget.renderChart()
          break
        }
      }
    },
    // 返回
    back() {
      this.$router.push({
        name: 'more'
      })
    }
  },
  mounted() {
    this.refreshCycleList()
  },
  watch: {
    routeReportId() {
      this.refreshData()
    },
    // 切换周期
    currentCycleId() {
      // this.refreshData()
      this.routeReportId = `${this.currentCycle.report_id}`
      if (this.$route.params.tab === 'clerk') {
        this.$router.push({
          name: this.$route.name,
          params: {
            tab: 'clerk',
            clerkReportId: `${this.myClerkId}_${this.routeReportId}`
          }
        })
      }
    },
    // 切换类型
    currentTab(val) {
      switch (val) {
        case 'task':
        case 'target': {
          this.renderChart()
          break
        }
      }
    },
    $route: {
      handler(val) {
        if (val.params.tab === 'clerk') {
          const clerkReportId = val.params.clerkReportId
          if (clerkReportId.indexOf('_') !== -1) {
            this.routeReportId = clerkReportId.split('_')[1]
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .clerk-box {
    padding: 100px 0 0 0;
    box-sizing: border-box;
    height: 100%;
    background-color: #f6f6f6;
  }
  .header-box {
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: 10;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #fff;
    .back-btn {
      height: 32px;
      width: 20px;
      line-height: 32px;
      margin-right: 5px;
      text-align: center;
      display: block;
    }
    .screen-btn {
      height: 32px;
      width: 32px;
      line-height: 32px;
      text-align: center;
      display: block;
    }
  }
  .tab-box {
    position: fixed;
    top: 50px;
    height: 50px;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(31, 35, 41, 0.1);

    .tab-item {
      color: #333333;
      text-align: center;
      font-size: 14px;
      border-top: 0;
      border-left: 0;
      border-bottom: 0;
      border-right: 2px solid #eee;
      border-radius: 0;
      background-color: #fff;
      margin-right: 0;

      &:last-child {
        border-right: 0;
      }
      .text {
        position: relative;
      }

      &.active {
        color: #5578eb;
        background-color: #fff;
        .text::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -8px;
          left: -5px;
          width: calc(100% + 10px);
          height: 3px;
          background-color: #5578eb;
          border-radius: 2px;
        }
      }
    }
  }
  .content-box {
    padding: 10px 15px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    &.comment {
      background-color: #fff;
    }
  }
  .comment-box::v-deep {
    .expression-box {
      left: -73px !important;
    }
  }
}
</style>
