<template>
  <div class="set">
    <image-crop-dialog
      title="编辑主页封面"
      :autoCropWidth="480"
      :autoCropHeight="187"
      @close="visible = false"
      @confirm="onSave($event)"
      v-if="visible"
    ></image-crop-dialog>
    <!-- 圈子个性设计 -->
    <div class="personal-set">
      <div class="personal-set-box">
        <div class="personal-set-title">圈子个性设置</div>
        <div class="personal-set-img" @click="visible = true">
          <!-- 鼠标经过背景图样式 -->
          <div class="filter flex flex-y-center flex-x-center">
            <i class="icon-xiangji"></i>
          </div>
          <img
            src="../../assets/img/member-bg.png"
            v-if="clerkInfo.background_cover_access_path === ''"
          />
          <img
            v-if="clerkInfo.background_cover_access_path !== ''"
            :src="clerkInfo.background_cover_access_path"
          />
        </div>
        <div class="signature-box flex flex-y-positive flex-x-between">
          <div class="signature-input flex flex-y-center">
            <span>个性签名</span>
            <el-input
              placeholder="来个不一样的介绍吧~（限制30字）"
              v-model="clerkInfo.clerk_motto"
              maxlength="30"
              show-word-limit
              @blur="editClerkMotto"
            >
            </el-input>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <!-- 个人基本资料 -->
    <!-- <div class="personal-info">
      <div class="personal-info-title">个人基本资料</div>
      <div class="personal-info-box flex flex-y-positive flex-x-between">
        <li class="flex flex-y-center flex-x-end">
          <span>生日</span>
          <el-date-picker
            v-model="clerkInfo.clerk_birthday"
            type="date"
            @change="birthdayChange"
            align="right"
            value-format="yyyy-MM-dd"
            placeholder="请选择生日"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </li>
        <li class="flex flex-y-center flex-x-end">
          <span>入职时间</span>
          <el-date-picker
            v-model="clerkInfo.join_time"
            type="date"
            @change="joinChange"
            value-format="yyyy-MM-dd"
            placeholder="请选择入职时间"
          >
          </el-date-picker>
        </li>
      </div>
    </div> -->
  </div>
</template>

<script>
import ImageCropDialog from '@/components/common/ImageCropDialog.vue'
export default {
  components: {
    ImageCropDialog
  },
  data() {
    return {
      visible: false,
      clerkInfo: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getClerkInfo()
  },
  methods: {
    // 获取职员信息
    async getClerkInfo() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentClerkInfo, {})
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.clerkInfo = res.info
      }
    },
    // 修改座右铭
    async editClerkMotto() {
      const formObj = {
        clerk_motto: this.clerkInfo.clerk_motto
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editClerkMotto, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 修改生日
    async birthdayChange(e) {
      const formObj = {
        clerk_birthday: e || ''
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editClerkBirthday, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 入职时间
    async joinChange(e) {
      const formObj = {
        join_time: e || ''
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editJoinTime, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
      }
    },
    // 修改背景图
    async onSave({ file }) {
      const formObj = new FormData()
      formObj.append('file', file)
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.editBackgroundCover, formObj).result
      )
      if (err) {
        this.$message.error(err)
      } else if (res.state === 'ok') {
        this.visible = false
        this.getClerkInfo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.set {
  background: #ffffff;
  .personal-set {
    padding: 30px 24px 0 24px;
    .personal-set-box {
      padding-bottom: 50px;
      .personal-set-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $main-color;
      }
      .personal-set-img {
        width: 480px;
        height: 187px;
        margin-left: 72px;
        margin-top: 24px;
        border-radius: 4px;
        position: relative;
        .filter {
          display: none;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;

          .icon-xiangji {
            font-size: 48px;
          }
        }

        &:hover {
          .filter {
            display: inline-flex;
          }
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .signature-box {
        margin-top: 30px;
        width: 592px;
        .signature-input {
          span {
            display: inline-block;
            font-size: 14px;
            line-height: 21px;
            color: #333333;
            margin-right: 16px;
          }
          .el-input {
            width: 480px;
            .el-input__count {
              display: none;
            }
            .el-input__inner {
              resize: none;
              border-radius: 6px;
              padding-left: 12px;
            }
          }
        }
        p {
          font-size: 14px;
          line-height: 21px;
          color: #bbbbbb;
          margin-left: 72px;
          margin-top: 12px;
        }
      }
    }
  }
  .personal-info {
    padding: 30px 306px 313px 24px;
    .personal-info-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $main-color;
    }
    .personal-info-box {
      height: 96px;
      margin-top: 24px;
      li {
        height: 38px;
        span {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          margin-right: 6px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .set {
    .personal-set {
      padding: 15px;

      .personal-set-box {
        padding-bottom: 0;
        .personal-set-img {
          width: calc(100vw - 30px);
          height: auto;
          margin-left: 0;
        }
      }
    }

    .signature-box {
      margin-top: 15px !important;

      .signature-input {
        .el-input {
          width: calc(100vw - 102px) !important;
        }
      }
    }
  }
}

// @media screen and (max-width: 767px) {
//   .set {
//     position: fixed;
//     top: 0;
//     width: 100vw;
//   }
// }
</style>
