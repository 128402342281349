<template>
  <!-- 培训师页面 -->
  <div class="knowledge-trainer">
    <div v-show="listVisible">
      <div class="trainer-top flex flex-x-between flex-y-center">
        <span>培训师库</span>
        <div class="trainer-top-function flex">
          <!-- 复制 -->
          <div class="copy-box flex flex-y-center">
            <el-tooltip
              class="item"
              effect="dark"
              content="复制链接"
              placement="bottom"
            >
              <p class="id-text" @click="copyLink">复制链接</p>
            </el-tooltip>
            <p class="tip-text" v-if="isShowTip">链接已复制</p>
          </div>
          <!-- 创建培训师按钮 -->
          <button id="create-trainer" v-hasPer="15" @click.stop="showAdd">
            创建培训师
          </button>
        </div>
      </div>
      <!-- 培训师类型页面 -->
      <div class="knowledge-trainer-box flex">
        <!-- 左侧培训师导航栏 -->
        <div class="knowledge-trainer-type">
          <div class="title">培训师类型</div>
          <div>
            <li
              class="flex flex-y-center"
              v-for="(item, index) in showClerkTutorTypeList"
              :key="item.type_id"
              :class="{ active: currentTypeIndex === index }"
              @click="typeClick(index)"
            >
              <div class="dot"></div>
              <span>{{ item.type_name }}</span>
              <i
                class="icon-gengduo3"
                v-hasPer="[12, 13]"
                v-if="item.type_id"
                @click.stop="showMoreTypeVisibleKey = item.type_id"
              ></i>
              <!-- 培训师类型设置弹窗 -->
              <div
                class="
                  type-operator-pop
                  flex flex-y-positive flex-x-center flex-y-center
                "
                @click.stop="outClick"
                v-if="showMoreTypeVisibleKey === item.type_id"
              >
                <span
                  v-hasPer="12"
                  class="flex flex-x-center flex-y-center"
                  @click="showEditType(item)"
                  >编辑</span
                >
                <p
                  v-hasPer="13"
                  class="flex flex-x-center flex-y-center"
                  @click="showDeleteType(item)"
                >
                  删除
                </p>
              </div>
            </li>
          </div>
          <div
            class="add-type flex flex-y-center"
            v-hasPer="11"
            @click.stop="showAddType"
          >
            <i class="icon-add4"></i>
            <span>添加类型</span>
          </div>
        </div>
        <div v-loading="loading">
          <!-- 右侧培训师内容 -->
          <scroll-fresh
            v-if="list.length > 0"
            className="trainer-content"
            :page="pageNumber"
            :lastPage="totalPage"
            @get-next-page="getNextPage"
            :showNoMore="false"
            ref="scrollFreshRef"
          >
            <div
              class="flex flex-wrap flex-section-start"
              style="max-width: 1220px; margin: 0 auto"
            >
              <li
                class="trainer-item"
                v-for="item in list"
                :key="item.clerk_id"
                @click.stop="showDetail(item.clerk_id)"
              >
                <div class="flex">
                  <!-- 培训师头像 -->
                  <img
                    class="trainer-img"
                    :src="
                      item.cover_access_path +
                      '?x-oss-process=style/clerk_tutor_cover'
                    "
                  />
                  <div class="trainer-detail">
                    <!-- 培训师名称 -->
                    <div class="trainer-name flex flex-y-end">
                      <span>{{ item.clerk_alias || item.clerk_name }}</span>
                      <!-- 设置按钮 -->
                      <i
                        class="icon-gengduo3"
                        v-hasPer="[16, 17, 18]"
                        @click.stop="showMoreVisibleKey = item.clerk_id"
                      ></i>
                      <!-- 更多弹窗 -->
                      <div
                        v-hasPer="[16, 17, 18]"
                        class="
                          gengduo-pop
                          flex flex-y-positive flex-x-center flex-y-center
                        "
                        v-if="showMoreVisibleKey === item.clerk_id"
                      >
                        <span
                          v-hasPer="16"
                          class="flex flex-x-center flex-y-center"
                          @click.stop="showEditClerk(item.clerk_id)"
                          >编辑</span
                        >
                        <span
                          v-hasPer="18"
                          class="flex flex-x-center flex-y-center"
                          @click.stop=""
                          >隐藏</span
                        >
                        <p
                          v-hasPer="17"
                          class="flex flex-x-center flex-y-center"
                          @click.stop="showDeleteClerk(item.clerk_id)"
                        >
                          删除
                        </p>
                      </div>
                    </div>
                    <!-- 培训师职位 -->
                    <div class="professional-title">
                      {{ item.main_dept_name || '' }}
                    </div>
                    <!-- 培训师徽章 -->
                    <div
                      class="trainer-medal flex"
                      v-if="isCloseMedal(item.type_list)"
                    >
                      <div
                        v-for="(cItem, cIndex) in item.type_list || []"
                        :key="cIndex"
                      >
                        <el-popover placement="bottom-start" trigger="hover">
                          <template>
                            <img
                              :src="cItem.cover_access_path"
                              alt=""
                              style="
                                width: 150px;
                                height: 150px;
                                border-radius: 50%;
                              "
                            />
                          </template>
                          <img
                            slot="reference"
                            v-if="cItem.cover_access_path"
                            :src="cItem.cover_access_path"
                            alt=""
                          />
                        </el-popover>
                      </div>
                    </div>
                    <!-- 培训师标签 -->
                    <div class="trainer-tag">
                      <span
                        v-for="(item, index) in item.type_list || []"
                        :key="index"
                        >{{ item.type_name
                        }}{{ item.type_level | renderTypeLevel }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 培训师介绍 -->
                <div class="trainer-introduction">
                  {{ item.tutor_describe_text || '暂无描述' }}
                </div>
              </li>
            </div>
          </scroll-fresh>
          <!-- 培训师内容为空 -->
          <div class="trainer-none flex flex-y-positive flex-y-center" v-else>
            <i class="icon-Profile3"></i>
            <span>暂无培训师，快去创建吧~</span>
          </div>
        </div>
      </div>
    </div>
    <trainer-detail
      v-show="detailClerkId !== -1"
      :trainerId="detailClerkId"
      @close="
        $router.push({
          name: 'knowledge',
          params: {
            type: 'tutor'
          }
        })
      "
    ></trainer-detail>
    <trainer-edit
      v-if="editClerkVisible"
      :trainerId="editClerkId"
      @close="
        editClerkId = -1
        editClerkVisible = false
      "
      @success="editSuccess"
    ></trainer-edit>
    <!-- 添加导师类型弹窗 -->
    <add-type
      v-if="addTypeVisible"
      @cancle="addTypeVisible = false"
      @confirm="addType"
    ></add-type>
    <!-- 编辑导师类型弹窗 -->
    <edit-type
      v-if="editTypeVisible"
      @cancle="
        editTypeItem = null
        editTypeVisible = false
      "
      @confirm="editType"
      :content="editTypeItem.type_name"
      :typeId="editTypeItem.type_id"
    ></edit-type>
    <!-- 删除导师类型弹窗 -->
    <knowledge-delete-pop
      v-if="deleteTypeVisible"
      msg="确定要删除类型么？"
      @confirm="deleteType"
      @cancle="
        deleteTypeItem = null
        deleteTypeVisible = false
      "
    ></knowledge-delete-pop>
    <knowledge-delete-pop
      v-if="deleteClerkVisible"
      msg="确定要删除培训师么？"
      @confirm="deleteClerk"
      @cancle="
        deleteClerkId = -1
        deleteClerkVisible = false
      "
    ></knowledge-delete-pop>
  </div>
</template>

<script>
// 导入添加导师类型弹窗组件
import addType from '@/components/knowledge/AddTrainerType.vue'
// 导入编辑导师类型弹窗组件
import editType from '@/components/knowledge/EditTrainerType.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
// 导师详情
import TrainerDetail from '@/components/knowledge/TrainerDetail.vue'
// 修改导师
import TrainerEdit from '@/components/knowledge/TrainerEdit.vue'
// 导入删除弹窗组件
import KnowledgeDeletePop from '@/components/knowledge/KnowledgeDeletePop.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'knowledge-trainer-content',
  components: {
    addType,
    editType,
    ScrollFresh,
    TrainerDetail,
    TrainerEdit,
    KnowledgeDeletePop
  },
  props: {},
  data() {
    return {
      isShowTip: false,
      // 展示详情id
      detailClerkId: -1,
      currentTypeIndex: 0,
      // 是否展示添加导师类型弹窗
      addTypeVisible: false,
      // 是否展示编辑导师类型弹窗
      editTypeVisible: false,
      // 是否展示删除导师类型弹窗
      deleteTypeVisible: false,
      // 修改类型对象
      editTypeItem: null,
      // 删除类型的对象
      deleteTypeItem: null,
      pageNumber: 1,
      pageSize: 12,
      totalPage: 2,
      list: [],
      loading: false,
      // 修改展示
      editClerkVisible: false,
      // 修改id
      editClerkId: -1,
      // 是否展示删除导师弹窗
      deleteClerkVisible: false,
      // 删除id
      deleteClerkId: -1,
      // 显示更多弹窗
      showMoreVisibleKey: -1,
      // 显示培训师类型更多弹窗
      showMoreTypeVisibleKey: -1
    }
  },
  computed: {
    ...mapState(['myClerkId', 'clerkTutorTypeList', 'clickBodyIndex']),
    // 展示职员导师类型列表
    showClerkTutorTypeList() {
      return [{ type_id: '', type_name: '全部' }, ...this.clerkTutorTypeList]
    },
    currentTypeType() {
      return this.showClerkTutorTypeList[this.currentTypeIndex].type_id
    },
    // 列表展示
    listVisible() {
      return this.detailClerkId === -1 && !this.editClerkVisible
    },
    // 是否关闭徽章列
    isCloseMedal() {
      return (e) => {
        let flag = true
        if (e) {
          const index = e.findIndex((t) => {
            return t.cover_access_path !== ''
          })
          if (index === -1) {
            flag = false
          }
        } else {
          flag = false
        }
        return flag
      }
    }
  },
  filters: {
    renderTypeLevel(val) {
      switch (val) {
        case 1:
          return '/初级'
        case 2:
          return '/中级'
        case 3:
          return '/高级'
      }
    }
  },
  methods: {
    ...mapActions(['getClerkTutorTypeList', 'refreshClerkTutorTypeList']),
    // 类型选择
    typeClick(index) {
      this.currentTypeIndex = index
      // this.refreshList()
    },
    // 展示添加导师类型弹窗
    showAddType() {
      this.addTypeVisible = true
    },
    // 展示添加
    showAdd() {
      this.editClerkId = -1
      this.editClerkVisible = true
    },
    // 展示编辑导师类型弹窗
    showEditType(item) {
      this.editTypeVisible = true
      this.editTypeItem = item
    },
    // 展示删除导师类型弹窗
    showDeleteType(item) {
      this.deleteTypeVisible = true
      this.deleteTypeItem = item
    },
    // 展示培训师详情页
    showDetail(id) {
      this.detailClerkId = id
    },
    // 添加类型
    async addType(content, imageRawInfo) {
      const formObj = new FormData()
      const imageKey = []
      formObj.append('type_name', content)
      if (imageRawInfo) {
        if (imageRawInfo[1]) {
          imageKey.push(1)
          formObj.append('image_sign1', imageRawInfo[1])
        }
        if (imageRawInfo[2]) {
          imageKey.push(2)
          formObj.append('image_sign2', imageRawInfo[2])
        }
        if (imageRawInfo[3]) {
          imageKey.push(3)
          formObj.append('image_sign3', imageRawInfo[3])
        }
      }
      formObj.append('image_sign', imageKey.join(','))
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.addClerkTutorType, formObj).result
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.addTypeVisible = false
          this.refreshClerkTutorTypeList()
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          })
        }
      }
    },
    // 修改类型
    async editType(content, imageRawInfo, deImageType) {
      const formObj = new FormData()
      const imageKey = []
      formObj.append('type_name', content)
      formObj.append('type_id', this.editTypeItem.type_id)
      if (imageRawInfo) {
        if (imageRawInfo[1]) {
          imageKey.push(1)
          formObj.append('image_sign1', imageRawInfo[1])
        }
        if (imageRawInfo[2]) {
          imageKey.push(2)
          formObj.append('image_sign2', imageRawInfo[2])
        }
        if (imageRawInfo[3]) {
          imageKey.push(3)
          formObj.append('image_sign3', imageRawInfo[3])
        }
      }
      formObj.append('image_sign', imageKey.join(','))
      if (deImageType.length !== 0) {
        formObj.append('delete_image_sign', deImageType.join(','))
      }
      const [err, res] = await this.$util.to(
        this.$util.uploadFile(this.$api.editClerkTutorType, formObj).result
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.editTypeItem = null
          this.editTypeVisible = false
          this.refreshClerkTutorTypeList()
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          this.refreshList()
        }
      }
    },
    // 删除类型
    async deleteType() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteClerkTutorType, {
          type_id: this.deleteTypeItem.type_id
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.deleteTypeItem = null
          this.deleteTypeVisible = false
          this.refreshClerkTutorTypeList()
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
        }
      }
    },
    // 获取列表
    async getList(reset = false) {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type_id: this.currentTypeType
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkTutorPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.list = res.page.list
        } else {
          this.list = this.list.concat(res.page.list)
        }
        this.pageNumber = res.page.pageNumber
        this.totalPage = res.page.totalPage
      }
    },
    // 获取下一页
    async getNextPage() {
      if (this.loading) {
        return
      }
      this.pageNumber++
      // this.loading = true
      await this.getList()
      // setTimeout(() => {
      // this.loading = false
      // }, 100)
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      // this.loading = true
      await this.getList(true)
      // setTimeout(() => {
      //   this.loading = false
      // }, 100)
    },
    // 复制链接
    copyLink() {
      this.$copyText(
        `${this.$config.frontHome}tutor${
          this.currentTypeType ? '/' + this.currentTypeType : ''
        }`
      ).then(
        () => {
          // this.$message({
          //   showClose: true,
          //   message: '已成功复制，可直接去粘贴',
          //   type: 'success'
          // })
          this.isShowTip = true
          // 2秒后提示隐藏
          setTimeout(() => {
            this.isShowTip = false
          }, 2000)
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败',
            type: 'error'
          })
        }
      )
    },
    outClick() {
      this.showMoreVisibleKey = -1
      this.showMoreTypeVisibleKey = -1
    },
    // 展示修改职员
    showEditClerk(id) {
      this.editClerkId = id
      this.editClerkVisible = true
      this.showMoreVisibleKey = -1
    },
    // 展示删除职员
    showDeleteClerk(id) {
      this.deleteClerkId = id
      this.deleteClerkVisible = true
      this.showMoreVisibleKey = -1
    },
    // 删除职员
    async deleteClerk() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteClerkTutor, {
          clerk_id: this.deleteClerkId
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.deleteClerkId = -1
          this.deleteClerkVisible = false
          this.refreshList()
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
        }
      }
    },
    // 编辑后刷新定位列表
    async editSuccess() {
      // 获取详情
      const formObj = {
        clerk_id: this.editClerkId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getClerkTutorDetail, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        const index = this.list.findIndex((e) => {
          return e.clerk_id === this.editClerkId
        })
        if (index !== -1) {
          this.list[index] = res.info
        }
        this.editClerkVisible = false
        this.editClerkId = -1
      }
    }
  },
  async mounted() {
    // 让document监听点击事件，点到弹窗的外面关闭弹窗
    // document.addEventListener('click', this.outClick)
    // 默认展示详情
    // if (this.showDetailId !== -1) {
    //   this.detailClerkId = this.showDetailId
    // }
    await this.getClerkTutorTypeList()
    switch (this.$route.params.type) {
      case 'tutor': {
        if (this.$route.params.id) {
          this.currentTypeIndex = this.showClerkTutorTypeList.findIndex(
            (e) => e.type_id === Number(this.$route.params.id)
          )
        } else {
          this.currentTypeIndex = 0
        }
        this.refreshList()
        break
      }
      case 'tutorDetail': {
        this.detailClerkId = Number(this.$route.params.id)
      }
    }

    // this.loading = true
    this.getList(true).then(() => {
      // setTimeout(() => {
      //   this.loading = false
      // }, 100)
    })
  },
  watch: {
    clickBodyIndex() {
      this.outClick()
    },
    currentTypeIndex(val) {
      const params = {
        type: 'tutor'
      }
      if (val !== 0) {
        params.id = this.showClerkTutorTypeList[this.currentTypeIndex].type_id
      }
      this.$router.push({
        name: 'knowledge',
        params: params
      })
      this.refreshList()
    },
    detailClerkId(val) {
      if (val !== -1) {
        this.$router.push({
          name: 'knowledge',
          params: {
            type: 'tutorDetail',
            id: val
          }
        })
      }
    },
    $route(val) {
      switch (val.params.type) {
        case 'tutor': {
          this.detailClerkId = -1
          if (val.params.id) {
            this.currentTypeIndex = this.showClerkTutorTypeList.findIndex(
              (e) => e.type_id === Number(val.params.id)
            )
          } else {
            this.currentTypeIndex = 0
          }
          break
        }
        case 'tutorDetail': {
          this.detailClerkId = Number(val.params.id)
          break
        }
      }
    }
    // showDetailId(val) {
    //   if (val !== -1) {
    //     this.detailClerkId = val
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.knowledge-trainer {
  // background: $light-grey-bg;
  background-color: #fff;
  width: calc(100vw - 280px);
  height: calc(100vh - 60px);
  .trainer-top {
    width: 100%;
    height: 70px;
    padding: 0 34px 0 48px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    span {
      font-size: 18px;
      line-height: 30px;
      color: #999999;
    }
    .trainer-top-function {
      #create-trainer {
        width: 98px;
        height: 36px;
        background: #0080ff;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
        margin-left: 21px;
        cursor: pointer;
      }
      .copy-box {
        position: relative;
        // 提示文字
        .tip-text {
          right: 0;
          position: absolute;
          font-size: 14px;
          line-height: 1.5;
          white-space: nowrap;
          background: #fff;
          color: $main-color;
        }
        .id-text {
          margin-right: 12px;
          font-size: 14px;
          line-height: 1.5;
          cursor: pointer;
          color: #bbb;
        }
      }
    }
  }

  .knowledge-trainer-box {
    &::v-deep .back-top {
      margin-left: 0px;
      right: 20px !important;
    }
    .knowledge-trainer-type {
      width: 270px;
      height: calc(100vh - 60px);
      background: #ffffff;
      padding-top: 46px;
      box-sizing: border-box;
      border-right: 1px solid #eee;

      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        margin-left: 48px;
        margin-bottom: 16px;
      }
      li {
        width: 100%;
        height: 44px;
        padding-left: 48px;
        box-sizing: border-box;
        list-style: none;
        position: relative;
        cursor: pointer;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          font-size: 10px;
          background: #666666;
        }
        span {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          margin-left: 12px;
        }
        .icon-gengduo3 {
          position: absolute;
          right: 26px;
          display: none;
          font-size: 22px;
        }
        .type-operator-pop {
          z-index: 100;
          position: absolute;
          top: 33px;
          right: 35px;
          width: 100px;
          // height: 80px;
          padding: 5px 0;
          background: #ffffff;
          box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
          border-radius: 4px;
          span {
            width: 76px;
            height: 33px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #333333;
            margin: 0;
            &:hover {
              background: rgba(0, 128, 255, 0.1);
              border-radius: 4px;
              color: #0080ff;
            }
          }
          p {
            width: 76px;
            height: 33px;
            color: #ff5454;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            &:hover {
              background: rgba(0, 128, 255, 0.1);
              border-radius: 4px;
              color: #0080ff;
            }
          }
        }
        &:hover {
          background: $light-grey-bg;
          .icon-gengduo3 {
            display: block;
          }
        }
      }
      .active {
        .dot {
          background: #0080ff;
        }
        span {
          font-weight: 500;
          color: #0080ff;
        }
        &:hover {
          background: #ffffff;
        }
      }
    }
    .add-type {
      width: 100%;
      height: 44px;
      padding-left: 46px;
      box-sizing: border-box;
      cursor: pointer;
      .icon-add4 {
        font-size: 15px;
        color: #0080ff;
      }
      span {
        font-size: 14px;
        line-height: 21px;
        color: #0080ff;
        margin-left: 10px;
      }
      &:hover {
        background: $light-grey-bg;
      }
    }
    // 右侧培训师内容样式
    .trainer-content {
      width: calc(100vw - 280px - 270px);
      height: calc(100vh - 60px);
      box-sizing: border-box;
      padding: 10px 0 130px 10px;
      // padding: 10px 0 133px 125px;
      overflow: hidden;
      overflow-y: scroll;
      li {
        list-style: none;
        // margin-top: 40px;
        width: 520px;
        height: 334px;
        box-sizing: border-box;
        padding: 18px 24px 0 20px;
        background: #ffffff;
        // margin-right: 80px;
        position: relative;
        margin: 20px 40px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
        .trainer-img {
          width: 120px;
          height: 146px;
          background: #bbbbbb;
        }
        .trainer-detail {
          margin-left: 16px;
          // height: 210px;
          .trainer-name {
            span {
              font-weight: bold;
              font-size: 20px;
              line-height: 30px;
              color: #333333;
            }
            .icon-gengduo3 {
              font-size: 28px;
              color: #999999;
              position: absolute;
              top: 18px;
              right: 22px;
              display: none;
            }
            // 更多弹窗
            .gengduo-pop {
              z-index: 100;
              position: absolute;
              top: 48px;
              right: 35px;
              width: 100px;
              // height: 120px;
              padding: 5px 0;
              background: #ffffff;
              box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
              border-radius: 4px;
              span {
                width: 76px;
                height: 33px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
                &:hover {
                  background: rgba(0, 128, 255, 0.1);
                  border-radius: 4px;
                  color: #0080ff;
                }
              }
              p {
                width: 76px;
                height: 33px;
                color: #ff5454;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                &:hover {
                  background: rgba(0, 128, 255, 0.1);
                  border-radius: 4px;
                  color: #0080ff;
                }
              }
            }
          }
          .professional-title {
            font-size: 16px;
            line-height: 24px;
            color: #999999;
            margin-top: 6px;
          }
          .trainer-medal {
            height: 34px;
            margin-top: 12px;
            img {
              width: 34px;
              height: 34px;
              margin-right: 12px;
              border-radius: 50%;
            }
          }
          .trainer-tag {
            width: 340px;
            margin-top: 2px;
            span {
              margin-top: 10px;
              display: inline-block;
              height: 28px;
              background: #0080ff;
              border-radius: 26px;
              box-sizing: border-box;
              padding: 6px 13px 6px 13px;
              font-size: 12px;
              color: #ffffff;
              margin-right: 12px;
            }
          }
        }
        .trainer-introduction {
          margin-top: 5px;
          width: 100%;
          height: 96px;
          font-size: 14px;
          line-height: 24px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          // padding: 0 24px 0 20px;
          box-sizing: border-box;
          /*控制在4行*/
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          white-space: pre-line;
          // position: absolute;
          // left: 0;
        }
        &:hover {
          .trainer-detail {
            .icon-gengduo3 {
              display: block;
            }
          }
        }
      }
    }
    // 培训师内容为空样式
    .trainer-none {
      width: calc(100vw - 280px - 270px);
      height: calc(100vh - 60px);
      .icon-Profile3 {
        margin-top: 220px;
        font-size: 114px;
        color: #ececec;
      }
      span {
        margin-top: 28px;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #bbbbbb;
      }
    }
  }
}
</style>
