<template>
    <div class="empty-container">
        <div>
            <svg class="empty-icon">
                <use :xlink:href="icon"></use>
            </svg>
        </div>
        <div>{{title}}</div>
    </div>
</template>

<script>
export default {
  props: ['icon', 'title']
}
</script>

<style lang="scss" scoped>
.empty-container {
  text-align: center;

  .empty-icon {
    width: 210px;
  }

  div:last-child {
    color: #BBBBBB;
  }
}
</style>
