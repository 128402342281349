<template>
  <div>
    <div class="flex attention-header">
      <div>全部粉丝（{{ totalRow }}）</div>
      <div class="search-container">
        <input type="text" v-model="searchKey" placeholder="搜索好友" />
        <i class="icon-search"></i>
      </div>
    </div>
    <div class="attention-container">
      <scroll-fresh
        className="attention-list"
        dataName="attention-data"
        :page="pageNumber"
        :lastPage="totalPage"
        :showBackTop="true"
        @get-next-page="getNextPage"
        :showNoMore="list.length > 0"
      >
        <member-attention-item
          v-for="(item, index) in list"
          :key="item.clerk_id"
          :clerkId="item.clerk_id"
          :button-title="item | renderButtonTitle"
          :primary="item.is_focus === 0"
          :avatar="item.clerk_avatar"
          @clickAvatar="handlerClickAvatar(item)"
          @clickButton="handlerClickButton(index)"
        >
          <div>
            <div class="member-name">
              {{ item.clerk_alias || item.clerk_name }}
            </div>
            <div class="new-topic text-clamp-1">
              {{ item.clerk_motto || '' }}
            </div>
            <div class="new-topic">粉丝 {{ item.fans_number || 0 }}</div>
          </div>
        </member-attention-item>
        <member-empty
          v-if="list.length === 0 && isShow"
          icon="#icon-a-Frame13"
          title="暂无粉丝，快去多多发表动态吧！"
        ></member-empty>
      </scroll-fresh>
    </div>
    <tips-dialog
      @cancle="cancle"
      @confirm="confirm"
      :title="title"
      v-if="visible"
    ></tips-dialog>
  </div>
</template>

<script>
import MemberAttentionItem from '@/components/member/MemberAttentionItem.vue'
import MemberEmpty from '@/components/member/MemberEmpty.vue'
// 导入滚动刷新组件
import ScrollFresh from '@/components/common/ScrollFresh.vue'
import TipsDialog from '@/components/common/TipsDialog.vue'
import { mapState } from 'vuex'
export default {
  components: {
    MemberAttentionItem,
    MemberEmpty,
    ScrollFresh,
    TipsDialog
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 20,
      totalPage: 2,
      totalRow: 0,
      searchKeyData: '',
      searchTimer: null,
      visible: false,
      title: '',
      cancleIndex: -1,
      isShow: false
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    searchKey: {
      get() {
        return this.searchKeyData
      },
      set(val) {
        this.searchKeyData = val
        if (this.searchTimer != null) {
          window.clearTimeout(this.searchTimer)
        }
        this.searchTimer = window.setTimeout(() => {
          this.refreshList()
        }, 500)
      }
    }
  },
  filters: {
    // 按钮文字
    renderButtonTitle(item) {
      if (item.is_focus === 1) {
        if (item.is_fans === 1) {
          return '互相关注'
        }
        return '取消关注'
      } else {
        if (item.is_fans === 1) {
          return '回粉'
        }
        return '关注'
      }
    }
  },
  methods: {
    // 获取列表
    async getList(reset = false) {
      const formObj = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        clerk_id: this.myClerkId,
        clerk_name: this.searchKey
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMomentFansPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        if (reset) {
          this.list = res.page.list
        } else {
          this.list = this.list.concat(res.page.list)
        }
        if (this.list.length === 0) {
          this.isShow = true
        }
        this.pageNumber = res.page.pageNumber
        this.totalPage = res.page.totalPage
        this.totalRow = res.page.totalRow
      }
    },
    // 获取下一页
    async getNextPage() {
      this.pageNumber++
      this.getList()
    },
    // 刷新列表
    async refreshList() {
      this.pageNumber = 1
      this.totalPage = 2
      this.list = []
      this.getList(true)
    },
    // 点击头像
    handlerClickAvatar(item) {
      this.$router.push({
        name: 'memberHome',
        params: { clerkId: item.clerk_id }
      })
    },
    // 添加关注
    async addFocus(index) {
      const formObj = {
        focus_clerk_id: this.list[index].clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.list[index], 'is_focus', 1)
        this.list[index].fans_number++
      }
    },
    // 删除关注
    async deleteFocus(index) {
      const formObj = {
        focus_clerk_id: this.list[index].clerk_id
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMomentFocus, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this.list[index], 'is_focus', 0)
        this.list[index].fans_number--
        this.$message({
          showClose: true,
          message: '取消关注成功！',
          type: 'success'
        })
        this.visible = false
      }
    },
    // 点击按钮
    async handlerClickButton(index) {
      if (this.list[index].is_focus === 0) {
        this.addFocus(index)
      } else {
        this.visible = true
        this.title = '确定确定要取消关注么？'
        this.cancleIndex = index
      }
    },
    // 取消弹窗
    cancle() {
      this.visible = false
    },
    // 取消关注
    confirm() {
      this.deleteFocus(this.cancleIndex)
    }
  },
  mounted() {
    this.getList()
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.attention-header {
  height: 54px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  padding: 0 14px 0 20px;
  margin-bottom: 20px;

  .search-container {
    position: relative;
    margin: 4px 0;

    input {
      outline: none;
      border: none;
      width: 165px;
      height: 22px;
      border-radius: 42px;
      background-color: $light-grey-bg;
      padding: 10px 15px 10px 48px;
    }

    i {
      position: absolute;
      top: 13px;
      left: 19px;
      color: $icon-color;
    }
  }
}

.attention-container {
  height: calc(100vh - 158px);
  overflow: scroll;

  .attention-list {
    height: calc(100% - 14px);
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;

    .member-name {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      line-height: 24px;
    }

    .new-topic {
      color: #999;
      line-height: 21px;
    }

    .empty-container {
      margin-top: 208px;
    }
  }
}
</style>
