<!--
 * @Author: Betty
 * @Date: 2021-08-03 10:19:10
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-24 11:04:30
 * @Description: 选择负责人弹窗组件
-->
<template>
  <div class="add-member-box choose-leader" v-if="isShow">
    <div class="left-box" @click.stop>
      <!-- 查找联系人的弹窗 -->
      <div class="search-box flex flex-y-center flex-x-between">
        <input
          type="text"
          class="input"
          v-model.trim="searchName"
          placeholder="查找联系人"
          @input="triggerSearchDirector"
        />
        <!-- 清空按钮 -->
        <button
          type="button"
          v-if="searchName.length > 0"
          class="clear-btn iconfont icon-decline"
          @click="clearSearch"
        ></button>
        <button
          type="button"
          class="search-btn"
          @click="triggerSearchDirector"
        ></button>
      </div>
      <div class="tree-list">
        <!-- 树形控件显示出结构 -->
        <template v-if="searchName.length === 0">
          <div v-for="item in clerkTree" :key="item.clerk_id || item.dept_id">
            <member-tree
              :treeNode="item"
              @clerk-choose="toAddLeader($event)"
            ></member-tree>
          </div>
        </template>
        <!-- 列表显示出搜索结果 -->
        <template v-else>
          <div class="search-result">
            <div
              class="member-item flex flex-y-center"
              v-for="(clerk, index) in searchResultList"
              :key="index"
              @click="toAddLeader(clerk)"
            >
              <avatar
                :userInfo="clerk"
                :size="30"
                :distanceRight="10"
                :isShowMe="true"
              ></avatar>
              <p class="name e">
                {{ clerk.clerk_alias || clerk.clerk_name }}
              </p>
            </div>
            <!-- 假如没有搜索结果 -->
            <p class="no-data" v-show="searchResultList.length === 0">
              暂无搜索结果，请换个关键词吧。
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// 显示职员数据组件
import MemberTree from './MemberTree.vue'
// 头像组件
import Avatar from './Avatar.vue'
export default {
  name: 'choose-leader',
  components: {
    MemberTree,
    Avatar
  },
  props: {
    // 是否显示弹窗
    isShow: {
      type: Boolean,
      default: false
    },
    // 树形的员工数据
    clerkTree: {
      type: Array
    },
    // 当前任务的id
    taskId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // 搜索联系人输入的内容
      searchName: '',
      // 搜索结果列表
      searchResultList: [],
      // 防抖搜索
      searchDirectorAntiShare: null
    }
  },
  mounted() {
    // 防抖的搜索函数
    this.searchDirectorAntiShare = this.$util.antiShare(this.searchClerkList)
  },
  methods: {
    // 清空搜索
    clearSearch() {
      this.searchName = ''
      this.searchResultList = []
    },
    // 搜索同事
    searchClerkList() {
      const val = this.searchName.trim()
      if (val.length > 0) {
        this.$util.searchClerkList(val).then(res => {
          if (res) {
            console.log('搜索结果：', res)
            this.searchResultList = [...res]
          }
        })
      }
    },
    // 触发搜索
    triggerSearchDirector() {
      this.searchDirectorAntiShare()
    },
    // 点击选择负责人
    async toAddLeader(info) {
      // this.$util.showConfirm('确定要让ta作为负责人吗?').then(async (result) => {
      // if (result) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.setDdirectorClert, {
          task_id: this.taskId,
          director_clerk_id: info.clerk_id
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.$message({
            showClose: true,
            message: '修改负责人成功',
            type: 'success'
          })
          // 清空搜索结果和关键词
          this.searchName = ''
          this.searchResultList = []
          // 关闭弹窗，并且把值传递给父组件
          this.$emit('to-close', {
            leader: info,
            isUpdateData: true
          })
        }
      }
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
// 选择负责人弹窗
.choose-leader {
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 0;
  width: 15.625vw;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.4);
  border-radius: 4px;
  .left-box {
    width: 100%;
    padding: 10px 0 0 0;
    margin-bottom: 0;
    .search-box {
      margin: 0 1.56vw;
      padding: 0 1vw;
    }
    .input {
      width: 80%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  ::placeholder {
    color: #bbb;
  }
  // 补充搜索列表左边的距离
  .member-item {
    padding-left: 20px;
  }
}

@media screen and (max-width: 750px) {
  .choose-leader {
    width: 250px;
  }
}
</style>
