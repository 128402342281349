<!--
 * @Author: hzh
 * @Date: 2021-08-27 11:27:13
 * @LastEditors: hzh
 * @LastEditTime: 2021-09-02 19:39:42
-->
<template>
  <!-- 编辑弹窗 -->
  <div
    class="delete-pop-mask flex flex-x-center flex-y-center"
    @click.stop="$emit('cancle')"
  >
    <div class="delete-pop flex flex-y-positive flex-y-between" @click.stop>
      <span>{{ msg }}</span>
      <div class="button-box flex">
        <button class="sure" @click="$emit('confirm')">确 定</button>
        <el-button @click="$emit('cancle')">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'knowledge-delete-pop',
  components: {},
  props: {
    msg: {
      type: String,
      default: '确定要删除文件么？'
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .wap {
    display: none !important;
  }
  .show {
    display: none;
  }
  .delete-pop-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    .delete-pop {
      width: 510px;
      height: 131px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 26px 0 28px 26px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
      }
      .button-box {
        margin-top: 24px;
        height: 32px;
        .sure {
          width: 80px;
          height: 32px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
  }
}
@media screen and (max-width: 960px) {
  .pc {
    display: none !important;
  }
  .delete-pop-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    .delete-pop {
      width: 90%;
      height: 131px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 26px 0 28px 26px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
      }
      .button-box {
        margin-top: 24px;
        height: 32px;
        .sure {
          width: 80px;
          height: 32px;
          background: #0080ff;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .button-box::v-deep .el-button {
    margin-left: 10px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    color: #999999;
    &:hover {
      color: #0080ff;
    }
  }
}
</style>
