/*
 * @Date: 2021-06-03 13:52:38
 * @LastEditTime: 2022-01-08 17:54:25
 * @Description: file content
 * @Author: Betty
 * @LastEditors: hzh
 */
export default {
  // 获取任务列表
  getTaskList: '/task/list',
  // 修改任务状态
  modifyTaskStatus: '/task/editTaskStatus',
  // 快速添加任务
  addNewTask: '/task/fastAdd',
  // 删除任务
  removeTask: '/task/delete',
  // 设置任务进度
  editTaskProgress: '/task/editProgressNumber',
  // 设置任务进度状态
  editTaskProgressStatus: '/task/editProgressStatus',
  // 获取任务详情
  taskDetail: '/task/detail',
  // 获取任务数量
  taskCount: '/task/count',
  // 获取服务器时间
  getServiceTime: '/index/serviceTime',
  // 修改任务名称
  editTaskName: '/task/editTaskName',
  // 修改任务开始时间
  editStartTime: '/task/editStartTime',
  // 修改任务结束时间
  editEndTime: '/task/editEndTime',
  // 修改任务的优先级
  editTaskPriority: '/task/editTaskPriority',
  // 修改工作量
  editTaskWorkload: '/task/editTaskWorkload',
  // 标星任务
  starTask: '/task/star',
  // 获取职员列表
  getClerkTree: '/clerk/tree',
  // 设置负责人
  setDdirectorClert: '/task/editDirectorClerk',
  // 修改任务描述
  editTaskDescription: '/task/editTaskDescribe',
  // 修改任务成员
  editTaskMember: '/task/editParticipant',
  // 搜索职员列表
  getClerkList: '/clerk/list',
  // 获取当前登录的职员的信息
  getCurrentUserInfo: '/clerk/info',
  // 获取下属员工列表
  myChildClerk: '/clerk/childList',
  // 我的关注
  myFocusList: '/task/focus/clerk/list',
  // 添加关注
  addFocusPerson: '/task/focus/clerk/add',
  // 取消关注
  removeFocusPerson: '/task/focus/clerk/delete',
  // 上传附件
  uploadAttachment: '/task/attachment/add',
  // 获取附件列表
  getAttachmentList: '/task/attachment/list',
  // 删除附件
  removeAttachment: '/task/attachment/delete',
  // 获取任务提醒
  getTaskRemindList: '/task/reminder/page',
  // 获取周期评论列表
  getPeriodCommentList: '/task/period/comment/page',
  // 添加周期评论
  addNewComment: '/task/period/comment/add',
  // 周期子评论分页
  getPeriodChildComment: '/task/period/comment/childPage',
  // 删除周期评论
  deletePeriodComment: '/task/period/comment/delete',
  // 给周期评论点赞
  parisePeriodComment: '/task/period/comment/praise',
  // 获取任务评论列表
  getTaskCommentList: '/task/comment/page',
  // 添加任务评论
  addTaskComment: '/task/comment/add',
  // 任务子级评论分页
  getTaskChildComment: '/task/comment/childPage',
  // 删除任务评论
  deleteTaskComment: '/task/comment/delete',
  // 给任务评论点赞
  pariseTaskComment: '/task/comment/praise',
  // 获取任务记录
  getTaskLog: '/task/change/log/page',
  // 上传任务描述富文本文件
  uploadTaskDescribeRichTextFile: '/task/uploadDescribeRichTextFile',
  // 任务排序
  taskMove: '/task/move',
  // 获取会议分页
  getMeetingList: '/meeting/page',
  // 取消会议
  cancelMeeting: '/meeting/cancel',
  // 获取会议室列表
  getMeetingRommList: '/meeting/room/selectList',
  // 添加会议
  addMeeting: '/meeting/add',
  // 获取会议详情
  getMeetingDetail: '/meeting/detail',
  // 修改会议信息
  editMeetingInfo: '/meeting/edit',
  // 删除会议附件
  deleteMeetingAttachment: '/meeting/attachment/delete',
  // 修改会议名称
  editMeetingName: '/meeting/editMeetingName',
  // 修改会议开始时间
  editMeetingStartTime: '/meeting/editStartTime',
  // 修改会议结束时间
  editMeetingEndTime: '/meeting/editEndTime',
  // 修改会议地点
  editMeetingAddress: '/meeting/editMeetingAddress',
  // 修改会议主持人
  editMeetingHost: '/meeting/editCompere',
  // 修改与会人员
  editMeetingMember: '/meeting/editParticipant',
  // 上传一个会议附件
  uploadMeetingAttachment: '/meeting/attachment/add',
  // 修改会议内容
  editMeetingContent: '/meeting/editMeetingContent',
  // 获取会议发言的职员列表
  getMeetingSpeakClerk: '/meeting/speech/clerkList',
  // 获取某职员的会议发言
  getSomeMeetingSpeak: '/meeting/speech/detail',
  // 修改我的发言
  editMyMeetingSpeak: '/meeting/speech/edit',
  // 发布会议
  publishMeeting: '/meeting/release',
  // 获取会议发言附件
  getMeetingSpeckAttachment: '/meeting/speech/attachment/list',
  // 上传会议发言附件
  uploadMeetingSpeckAttachment: '/meeting/speech/attachment/add',
  // 获取会议附件列表
  getMeetingAttachmentList: '/meeting/attachment/list',
  // 删除会议发言附件
  deleteMeetingSpeakAttachment: '/meeting/speech/attachment/delete',
  // 获取会议任务
  getMeetingTaskList: '/meeting/task/list',
  // 上传会议内容富文本文件
  uploadMeetingContentRichTextFile: '/meeting/uploadMeetingContentRichTextFile',
  // 上传会议纪要富文本文件
  uploadMeetingMinutesRichTextFile: '/meeting/uploadMeetingMinutesRichTextFile',
  // 上传会议发言富文本文件
  uploadMeetingSpeechRichTextFile: '/meeting/speech/uploadRichTextFile',
  // 修改会议纪要
  editMeetingMinute: '/meeting/editMeetingMinutes',
  // 删除会议
  deleteMeeting: '/meeting/delete',
  // 获取会议室列表
  getMeetingRoomPage: '/meeting/room/page',
  // 获取会议操作日志分页
  getMeetingLogPage: '/meeting/change/log/page',
  // 启用/禁用会议室
  enableMeetingRoom: '/meeting/room/editEnable',
  // 获取省列表
  getProvinceList: '/index/provinceList',
  // 获取城市列表
  getCityList: '/index/cityList',
  // 获取会议室设备列表
  getRoomDeviceList: '/dict/meeting/room/device/list',
  // 会议室设备的添加
  addRoomDevice: '/dict/meeting/room/device/add',
  // 添加会议室
  addMeetingRoom: '/meeting/room/add',
  // 获取会议室详情
  getMeetingRoomDetail: '/meeting/room/detail',
  // 修改会议室
  editMeetingRoom: '/meeting/room/edit',
  // 删除会议室
  deleteMeetingRoom: '/meeting/room/delete',
  // 获取目标列表
  getTargetList: '/objective/list',
  // 快速添加目标
  addTarget: '/objective/fastAdd',
  // 修改目标类型
  editTargetType: '/objective/editObjectiveType',
  // 修改目标进度
  editTargetProgressNumber: '/objective/editProgressNumber',
  // 修改目标进度状态
  editTargetProgressStatus: '/objective/editProgressStatus',
  // 修改目标的名字
  editTargetName: '/objective/editObjectiveName',
  // 删除目标
  deleteTarget: '/objective/delete',
  // 获取上级目标列表
  getHigherLevelTarget: '/objective/mainLeaderList',
  // 修改目标父级id
  editTargetPid: '/objective/editPid',
  // 下载任务附件
  downloadTaskAttachment: '/task/attachment/download',
  // 修改目标状态
  modifyTargetStatus: '/objective/editObjectiveStatus',
  // 目标详情
  getTargetDetail: '/objective/detail',
  // 修改目标开始时间
  editTargetStartTime: '/objective/editStartTime',
  // 修改目标结束时间
  editTargetEndTime: '/objective/editEndTime',
  // 上传目标描述的富文本
  uploadTargetDescribeRichTextFile: '/objective/uploadDescribeRichTextFile',
  // 修改目标的工作量
  editTargetWorkload: '/objective/editObjectiveWorkload',
  // 获取会议通知列表
  getMeetingNoticeList: '/meeting/noticeList',
  // 修改目标描述
  editTargetDescription: '/objective/editObjectiveDescribe',
  // 获取目标附件列表
  getTargetAttachmentList: '/objective/attachment/list',
  // 上传目标附件
  uploadTargetAttachment: '/objective/attachment/add',
  // 删除目标附件
  deleteTargetAttachment: '/objective/attachment/delete',
  // 获取目标评论分页
  getTargetCommentPage: '/objective/comment/page',
  // 添加目标评论
  addTargetComment: '/objective/comment/add',
  // 删除目标评论
  deleteTargetComment: '/objective/comment/delete',
  // 点赞目标评论
  pariseTargetRaise: '/objective/comment/praise',
  // 目标评论的子级分页
  targetCommentChildPage: '/objective/comment/childPage',
  // 获取目标记录的分页
  getTargetLog: '/objective/change/log/page',
  // 会议统计
  getMeetingCount: '/meeting/count',
  // 获取目标周期评论分页
  getTargetPeriodComment: '/objective/period/comment/page',
  // 添加目标周期评论
  addTargetPeriodComment: '/objective/period/comment/add',
  // 删除目标周期评论
  deleteTargetPeriodComment: '/objective/period/comment/delete',
  // 点赞目标周期评论
  pariseTargetPeriodComment: '/objective/period/comment/praise',
  // 获取目标周期评论的子级分页
  targetPeriodChildPage: '/objective/period/comment/childPage',
  // 获取目标提醒的内容
  getTargetRemind: '/objective/reminder/page',
  // 获取目标和任务的提醒（合在一起）
  getTaskAndTargetRemind: '/reminder/page',
  // 修改会议纪要人
  editMeetingUploadMinutesClerk: '/meeting/editUploadMinutesClerk',
  // 修改目标的排序
  trgetMove: '/objective/move',
  // 获取下属员工树
  getSubordinateTree: '/clerk/childTree',
  // 获取消息提醒
  getMessageList: '/comment/unreadList',
  // 获取圈子列表
  getMomentList: '/moment/page',
  // 添加圈子
  addMoment: '/moment/add',
  // 修改圈子
  editMoment: '/moment/edit',
  // 删除圈子
  deleteMoment: '/moment/delete',
  // 圈子点赞
  praiseMoment: '/moment/praise',
  // 圈子详情
  getMomentInfo: '/moment/detail',
  // 获取圈子评论分页
  getMomentCommentPage: '/moment/comment/page',
  // 超级管理删除
  adminDeleteComment: '/moment/comment/adminDelete',
  // 圈子评论添加
  addMomentComment: '/moment/comment/add',
  // 圈子评论删除
  deleteMomentComment: '/moment/comment/delete',
  // 圈子评论点赞
  praiseMomentComment: '/moment/comment/praise',
  // 圈子评论子级分页
  getMomentCommentChildPage: '/moment/comment/childPage',
  // 圈子职员信息
  getMomentClerkInfo: '/moment/clerk/info',
  // 圈子关注列表
  getMomentFocusPage: '/moment/focus/clerk/focusPage',
  // 圈子粉丝列表
  getMomentFansPage: '/moment/focus/clerk/fansPage',
  // 添加圈子关注
  addMomentFocus: '/moment/focus/clerk/add',
  // 删除圈子关注
  deleteMomentFocus: '/moment/focus/clerk/delete',
  // 目标评论分页
  getTargetComment: '/comment/objectivePage',
  // 任务评论分页
  getTaskComment: '/comment/taskPage',
  // 圈子消息点赞
  getPraiseList: '/moment/message/praisePage',
  // 圈子@消息分页
  getMentionList: '/moment/message/mentionPage',
  // 圈子@评论消息分页
  getCommentMentionList: '/moment/message/commentMentionPage',
  // 圈子消息评论
  getCommentList: '/moment/message/commentPage',
  // 圈子消息评论数量
  getCommentCount: '/moment/message/commentCount',
  // 圈子消息点赞数量
  getPraiseCount: '/moment/message/praiseCount',
  // @消息数量
  getMntionCount: '/moment/message/mentionCount',
  // @评论消息数量
  getCommentMntionCount: '/moment/message/commentMentionCount',
  // 修改座右铭
  editClerkMotto: '/clerk/editClerkMotto',
  // 修改生日
  editClerkBirthday: '/clerk/editClerkBirthday',
  // 修改加入时间
  editJoinTime: '/clerk/editJoinTime',
  // 修改背景图
  editBackgroundCover: '/clerk/editBackgroundCover',
  // 清除圈子消息评论统计
  clearMomentMessageCommentCount: '/moment/message/clearCommentCount',
  // 清除圈子消息点赞统计
  clearMomentMessagePraiseCount: '/moment/message/clearPraiseCount',
  // 清除圈子@统计
  clearMomentMessageMentionCount: '/moment/message/clearMentionCount',
  // 清除@我的评论统计
  clearCommentMessageMentionCount: '/moment/message/clearCommentMentionCount',
  // 目标评论统计
  getTargetCommentCount: '/comment/objectiveCount',
  // 任务评论统计
  getTaskCommentCount: '/comment/taskCount',
  // 圈子统计
  getMomentCount: '/moment/count',
  // 获取圈子评论列表
  getMomentCommentList: '/moment/comment/list',
  // 圈子评论子级列表
  getMomentCommentChildList: '/moment/comment/childList',
  // 获取职员导师类型列表
  getClerkTutorTypeList: '/dict/clerk/tutor/type/list',
  // 添加职员导师类型
  addClerkTutorType: '/dict/clerk/tutor/type/add',
  // 职员导师类型详情
  getClerkTutorTypeInfo: '/dict/clerk/tutor/type/detail',
  // 修改职员导师类型
  editClerkTutorType: '/dict/clerk/tutor/type/edit',
  // 删除职员导师类型
  deleteClerkTutorType: '/dict/clerk/tutor/type/delete',
  // 获取职员导师分页
  getClerkTutorPage: '/clerk/tutor/page',
  // 添加职员导师
  addClerkTutor: '/clerk/tutor/add',
  // 修改职员导师
  editClerkTutor: '/clerk/tutor/edit',
  // 删除职员导师
  deleteClerkTutor: '/clerk/tutor/delete',
  // 获取职员导师详情
  getClerkTutorDetail: '/clerk/tutor/detail',
  // 上传职员导师描述富文本文件
  updateClerkTutorDescribeRichTextFile: '/clerk/tutor/uploadDescribeRichTextFile',
  // 获取知识库列表
  getKnowlList: '/knowl/list',
  // 获取知识库详情
  getKnowlDetail: '/knowl/detail',
  // 修改知识库名称
  editKnowlName: '/knowl/editName',
  // 修改知识库导师
  editKnowlAuthorClerk: '/knowl/editAuthorClerk',
  // 移动知识库
  // moveKnowl: '/knowl/move',
  // 删除知识库
  // deleteKnowl: '/knowl/delete',
  // 收藏知识库
  collectKnowl: '/knowl/collect',
  // 添加知识库内容
  addKnowlContent: '/knowl/addContent',
  // 修改知识库内容
  editKnowlContent: '/knowl/editContent',
  // 添加知识库附件
  addKnowlAttachment: '/knowl/addAttachment',
  // 修改知识库附件
  editKnowlAttachment: '/knowl/editAttachment',
  // 批量删除知识库
  batchDeleteKnowl: '/knowl/batchDelete',
  // 批量移动知识库
  batchMoveKnowl: '/knowl/batchMove',
  // 获取知识库允许移动文件夹列表
  getKnowlAllowMoveList: '/knowl/allowMoveList',
  // 获取知识库收藏文件夹详情
  getKnowlCollectFolderDetail: '/knowl/collect/folder/detail',
  // 删除知识库收藏文件夹
  deleteKnowlCollectFolder: '/knowl/collect/folder/delete',
  // 添加知识库收藏文件夹
  addKnowlCollectFolder: '/knowl/collect/folder/add',
  // 修改知识库收藏文件夹
  editKnowlCollectFolder: '/knowl/collect/folder/edit',
  // 获取知识库收藏列表
  getKnowlCollectList: '/knowl/collect/list',
  // 添加知识库文件夹
  addKnowlFolder: '/knowl/folder/add',
  // 修改知识库文件夹
  editKnowlFolder: '/knowl/folder/edit',
  // 获取知识库文件夹详情
  getKnowlFolderDetail: '/knowl/folder/detail',
  // 删除知识库文件夹
  // deleteKnowlFolder: '/knowl/folder/delete',
  // 移动知识库文件夹
  // moveKnowlFolder: '/knowl/folder/move',
  // 获取知识库文件夹允许移动列表
  // getKnowlFolderAllowMoveList: '/knowl/folder/allowMoveList',
  // 上传知识库文件夹描述富文本文件
  updateKnowlFolderDescribeRichTextFile: '/knowl/folder/uploadDescribeRichTextFile',
  // 获取知识库修改记录分页
  getKnowlChangeLogPage: '/knowl/change/log/page',
  // 获取知识库评论列表
  getKnowlCommentPage: '/knowl/comment/page',
  // 添加知识库评论
  addKnowComment: '/knowl/comment/add',
  // 上传知识库内容描述富文本文件
  updateKnowlContentRichTextFile: '/knowl/uploadContentRichTextFile',
  // 获取通知列表
  getNoticeList: '/notice/list',
  // 获取通知详情
  getNoticeDetailL: '/notice/detail',
  // 修改任务组名称
  editTaskGroupName: '/task/group/editName',
  // 修改目标组名称
  editObjectiveGroupName: '/objective/group/editName',
  // 获取知识库文件夹团队共享详情
  getKnowlFolderTeamShareDetail: '/knowl/folder/team/share/detail',
  // 修改知识库文件夹团队共享状态
  editKnowlFolderTeamShare: '/knowl/folder/team/share/edit',
  // 修改知识库文件夹团队共享状态
  editKnowlFolderTeamShareStatus: '/knowl/folder/editTeamShare',
  // 获取知识库文件夹团队权限详情
  getKnowlFolderTeamPerDetail: '/knowl/folder/team/per/detail',
  // 修改知识库文件夹团队权限状态
  editKnowlFolderTeamPer: '/knowl/folder/team/per/edit',
  // 添加知识库文件夹团队权限状态
  addKnowlFolderTeamPer: '/knowl/folder/team/per/add',
  // 删除知识库文件夹团队权限状态
  deleteKnowlFolderTeamPer: '/knowl/folder/team/per/delete',
  // 文件预览
  getOnlinePreviewUrl: '/knowl/onlinePreview',
  // 话题分页
  getMomentTopicPage: '/moment/topic/page',
  // 职员分页
  getClerkPage: '/clerk/page',
  // 搜索知识库文件夹团队权限职员
  searchKnowlFolderTeamPerClerk: '/knowl/folder/team/per/searchClerk',
  // 获取任务目标关联 目标列表
  getTaskObjectiveList: '/task/objective/objectiveList',
  // 修改任务目标关联
  editTaskObjective: '/task/objective/edit',
  // 解绑任务目标关联
  unbindTaskObjective: '/task/objective/unbind',
  // 附件预览
  getAttachmentPreview: '/attachment/preview',
  // 获取工作报告周期列表
  getWorkReportCyclePage: '/workreport/cycle/page',
  // 获取工作报告任务数据
  getWorkReportTaskData: '/workreport/taskData',
  // 获取工作报告目标数据
  getWorkReportObjectiveData: '/workreport/objectiveData',
  // 获取工作报告职员数据
  getWorkReportClerkData: '/workreport/clerkData',
  // 获取工作报告部门数据
  getWorkReportDeptData: '/workreport/deptData',
  // 获取职员领导部门列表
  getClerkLeaderDeptList: '/clerk/leaderDeptList',
  // 获取工作报告评论分页
  getWorkReportCommentPage: '/workreport/comment/page',
  // 获取工作报告部门列表
  getWorkReportCycleDeptLeaderList: '/workreport/cycle/dept/leaderList',
  // 添加工作报告评论
  addWorkReportComment: '/workreport/comment/add',
  // 删除工作报告评论
  deleteWorkReportComment: '/workreport/comment/delete',
  // 获取会议室占用列表
  getMeetingRoomOccupyList: '/meeting/room/occupyList',
  // ------------------------系统设置------------------------
  // 获取系统职员部门列表
  getSystemClerkDeptList: '/system/clerk/deptList',
  // 通知列表
  getSystemNoticePage: '/system/notice/page',
  // 通知详情
  getSystemNoticeDetailL: '/system/notice/detail',
  // 添加通知
  addSystemNotice: '/system/notice/add',
  // 修改通知
  editSystemNotice: '/system/notice/edit',
  // 删除通知
  deleteSystemNotice: '/system/notice/delete',
  // 上传通知富文本文件
  uploadSystemNoticeRichTextFile: '/system/notice/uploadRichTextFile',
  // 获取系统职员分页
  getSystemClerkPage: '/system/clerk/page',
  // 获取系统职员列表
  getSystemClerkList: '/system/clerk/list',
  // 获取系统职员详情
  getSystemClerkDetail: '/system/clerk/detail',
  // 系统职员离职
  systemClerkLeave: '/system/clerk/leave',
  // 系统职员批量转正
  systemClerkBatchPositive: '/system/clerk/batchPositive',
  // 系统职员批量转试用
  systemClerkBatchProbation: '/system/clerk/batchProbation',
  // 系统搜索部门和职员
  systemSearchDeptAndClerk: '/system/clerk/searchDeptAndClerk',
  // 获取系统圈子推送职员分页
  getSystemMomentPushClerkPage: '/system/moment/push/clerk/page',
  // 删除系统圈子推送职员
  deleteSystemMomentPushClerk: '/system/moment/push/clerk/delete',
  // 添加系统圈子推送职员
  addSystemMomentPushClerk: '/system/moment/push/clerk/add',
  // 获取系统圈子推送职员下拉
  getSystemMomentPushClerkSelect: '/system/moment/push/clerk/selectList',
  // 获取系统超级管理员分页
  getSystemClerkAdminSuperPage: '/system/clerk/admin/superPage',
  // 删除系统超级管理员
  deleteSystemClerkSuperAdmin: '/system/clerk/admin/superDelete',
  // 添加系统超级管理员
  addSystemClerkSuperAdmin: '/system/clerk/admin/superAdd',
  // 获取系统管理员分页
  getSystemClerkAdminNormalPage: '/system/clerk/admin/normalPage',
  // 删除系统管理员
  deleteSystemClerkNormalAdmin: '/system/clerk/admin/normalDelete',
  // 添加系统管理员
  addSystemClerkNormalAdmin: '/system/clerk/admin/normalAdd',
  // 修改系统管理员
  editSystemClerkNormalAdmin: '/system/clerk/admin/normalEdit',
  // 系统通知记录分页
  getSystemNoticeChangePage: '/system/notice/change/log/page',
  // 系统圈子推送分页
  getSystemMomentPushPage: '/system/moment/push/page',
  // 添加系统圈子推送
  addSystemMomentPush: '/system/moment/push/add',
  // 修改系统圈子推送
  editSystemMomentPush: '/system/moment/push/edit',
  // 系统圈子推送详情
  getSystemMomentPushDetail: '/system/moment/push/detail',
  // 系统圈子推送删除
  deleteSystemMomentPush: '/system/moment/push/delete',
  // 获取系统职员权限组分页
  getSystemClerkPerGroupPage: '/system/clerk/per/group/page',
  // 添加系统职员权限组
  addSystemClerkPerGroup: '/system/clerk/per/group/add',
  // 修改系统职员权限组
  editSystemClerkPerGroup: '/system/clerk/per/group/edit',
  // 删除系统职员权限组
  deleteSystemClerkPerGroup: '/system/clerk/per/group/delete',
  // 获取系统职员权限组选择列表
  getSystemClerkPerGroupSelectList: '/system/clerk/per/group/selectList',
  // 获取系统知识库团队分页
  getSystemKnowlTeamPage: '/system/knowl/team/page',
  // 解绑系统知识库团队
  unbindSystemKnowlTeam: '/system/knowl/team/unbind',
  // 添加系统知识库团队
  addSystemKnowlTeam: '/system/knowl/team/add',
  // 修改系统知识库团队
  editSystemKnowlTeam: '/system/knowl/team/edit',
  // 部门领导列表
  getSystemDeptLeaderList: '/system/dept/leaderList',
  // 部门列表
  getSystemDeptList: '/system/dept/leader/list',
  // 添加上级
  addSystemLeader: '/system/dept/leader/edit'
}
