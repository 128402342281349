<!--
 * @Author: hzh
 * @Date: 2021-11-30 18:03:27
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-30 18:19:09
-->
<template>
  <div class="outer-layer-box">
    <div class="header-preview flex flex-y-center flex-x-between">
      <div>
        <el-page-header @back="goBack" :content="knowlInfo.knowl_name || ''">
        </el-page-header>
      </div>
      <div class="download-icon" v-if="knowlInfo.downloadPer || false">
        <a
          :href="fileInfo.attachment_path"
          :download="fileInfo.file_original_name"
          ><i class="el-icon-download"></i
        ></a>
      </div>
    </div>
    <iframe
      class="main-body"
      v-if="knowlInfo.url"
      :src="knowlInfo.url || ''"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      knowlId: -1,
      knowlInfo: {},
      fileId: '',
      fileInfo: {}
    }
  },
  mounted() {
    this.knowlId = this.$route.params.id
    this.fileId = this.$route.query.attachment_id
  },
  methods: {
    // 返回上一级
    goBack() {
      this.$router.push({
        path: this.$route.path.substr(0, this.$route.path.lastIndexOf('/'))
      })
    },
    // 获取url
    async getOnlinePreviewUrl() {
      const formobj = {
        knowl_id: this.knowlId
      }
      if (this.fileId !== '') {
        formobj.attachment_id = this.fileId
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getOnlinePreviewUrl, formobj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res)
        if (res.info.url.indexOf('https') === -1) {
          res.info.url = `https${res.info.url.substr(4)}`
        }
        this.knowlInfo = res.info
        this.fileInfo = res.knowl_file_url.attachment_info
      } else {
        this.goBack()
      }
    }
  },
  watch: {
    knowlId(val) {
      if (val !== -1) {
        this.getOnlinePreviewUrl()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-preview {
  height: 50px;
  background: #fff;
  padding: 0 25px;
  box-shadow: 5px 5px 5px #e0dfdf;
  &::v-deep {
    .el-icon-back {
      font-size: 20px !important;
    }
    .el-page-header__title {
      font-size: 16px !important;
    }
  }
  &::v-deep {
    .el-page-header__content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 53vw;
    }
    .el-icon-back {
      color: $main-color !important;
    }
    .el-page-header__title {
      color: $main-color !important;
    }
  }
  .download-icon {
    i {
      color: $main-color !important;
      font-size: 24px !important;
      font-weight: 600 !important;
      cursor: pointer;
    }
  }
}
.main-body {
  width: 100%;
  border: none;
  height: calc(100vh - 50px);
}
</style>
