<!--
 * @Author: hzh
 * @Date: 2021-12-31 18:21:32
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-08 13:45:06
-->
<template>
  <el-popover
    :placement="placement"
    width="250"
    trigger="manual"
    v-model="myVisible"
  >
    <template #reference>
      <slot></slot>
    </template>
    <template #default>
      <div class="info-box" @click="clickBody()">
        <i class="el-icon-close close-btn" @click="close()"></i>
        <div class="header flex flex-x-center flex-y-center">
          <div class="time">{{ $util.toDateString(date, 'MM-dd') }}</div>
          <div class="week">
            {{
              ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][
                date.getDay()
              ]
            }}
          </div>
        </div>
        <div class="list-box" >
          <template v-for="(item, index) in list">
            <info-popover :key="index" :data="item">
              <template #default>
                <div
                  class="list-item flex flex-y-center"
                  @click.stop="
                    clickBody()
                    showInfo = true
                  "
                >
                  <div class="dot"></div>
                  <div class="name flex1 e">
                    {{ item.meeting_name }}
                  </div>
                </div>
              </template>
            </info-popover>
          </template>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script>
import InfoPopover from './meeting-room-occupy-meeting-info-popover'
export default {
  name: 'meetingRoomOccupyMeetingListPopover',
  components: {
    InfoPopover
  },
  props: {
    date: {
      type: Date
    },
    list: {
      type: Array,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      myVisible: false,
      showInfo: false
    }
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    },
    clickBody() {
      document.body.click()
    },
    hideInfo() {
      if (this.showInfo) {
        this.clickBody()
      }
    }
  },
  mounted() {},
  watch: {
    visible(val) {
      this.myVisible = this.visible
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  .header {
    .time {
      font-size: 24px;
      color: #333;
      font-weight: 700;
      padding: 0 10px;
    }
    .week {
      font-size: 18px;
      color: #333;
      padding: 0 10px;
    }
  }
  .list-box {
    max-height: 200px;
    overflow-y: scroll;
    cursor: pointer;
    margin-top: 10px;
    .list-item {
      line-height: 24px;
    }
    .dot {
      width: 8px;
      height: 8px;
      margin: 0 8px 0 0;
      background-color: #c6dbff;
      box-sizing: border-box;
      border-radius: 50%;
    }
    .name {
      color: #7f7f7f;
      font-size: 13px;
    }
  }
  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 25px;
    font-size: 20px;
  }
}
</style>
