<!--
 * @Author: Betty
 * @Date: 2021-06-29 21:08:59
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-08 14:20:46
 * @Description: 会议
-->
<template>
  <div class="meeting main-container" :style="haveZIndex ? 'z-index: 6' : ''">
    <!-- 中间，白色的区域 -->
    <div class="main-box">
      <el-tabs v-model="currentTab" @tab-click="changeCurrentTab">
        <!-- 第一块，我的会议 -->
        <el-tab-pane label="我的会议" name="first">
          <!-- 第一行 -->
          <div class="flex flex-y-center flex-x-between handle-line pc">
            <!-- 左边 -->
            <!-- 下拉框 -->
            <div
              class="select flex flex-y-center"
              @click.stop="toggleChooseMeetingState"
            >
              <span class="tip-text">状态</span>
              <p class="result">
                {{ meetingStateList[currentMeetingState].text }}
              </p>
              <span class="iconfont icon-zhankai"></span>
              <!-- 下拉选项 -->
              <div class="menu-box" v-show="isSelectMeetingState">
                <div
                  class="menu-box-item"
                  v-for="(item, index) in meetingStateList"
                  :key="index"
                  @click="selectState(item.value, index)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
            <!-- 右边，筛选按钮和创建会议的按钮 -->
            <div class="flex flex-y-center">
              <el-tooltip
                effect="dark"
                content="会议室日程"
                placement="top-start"
              >
                <i
                  class="iconfont icon-richeng meeting-room-occupy-btn"
                  @click="showMeetingRoomOccupy"
                ></i>
              </el-tooltip>
              <div class="select-btn-box">
                <!-- 筛选按钮 -->
                <button
                  class="select-btn"
                  type="button"
                  @click.stop="toggleSelect"
                >
                  <span class="iconfont icon-Filter2"></span>
                </button>
                <!-- 筛选下拉框 -->
                <div class="select-menu" v-show="isSelectRecord">
                  <div
                    v-for="(item, index) in recordStateList"
                    :key="index"
                    class="select-menu-item"
                    @click="chooseRecordState(item)"
                  >
                    {{ item.text }}
                  </div>
                </div>
              </div>
              <!-- 添加会议按钮 -->
              <button class="add-btn" type="button" @click="toAddMeeting">
                <span class="iconfont icon-add"></span>
                <span class="add-text">{{
                  screenWidth > 767 ? '创建会议' : '创建'
                }}</span>
              </button>
            </div>
          </div>
          <!-- 数据表格 -->
          <el-table
            :data="meetingList"
            style="
              width: 100%;
              height: calc(100vh - 60px - 24px - 70px - 85px - 150px);
            "
            class="meeting customer-table pc"
          >
            <!-- //我的会议的开头 -->
            <el-table-column label="会议主题" width="25%">
              <template slot-scope="scope">
                <div class="meeting-name" @click="openDetailBox(scope.row)">
                  {{ scope.row.meeting_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="会议地点" width="15%">
              <template slot-scope="scope">
                {{
                  scope.row.meeting_place_type === 1
                    ? scope.row.meeting_room_name
                    : scope.row.meeting_address
                }}
              </template>
            </el-table-column>
            <el-table-column label="会议时间" width="20%">
              <template slot-scope="scope">
                <template v-if="scope.row.start_time && scope.row.end_time">
                  {{ getDateAndTimeStr(scope.row.start_time) }} 至
                  {{ getDateAndTimeStr(scope.row.end_time) }}
                </template>
              </template>
            </el-table-column>
            <el-table-column label="任务统计" width="10%">
              <template slot-scope="scope">
                <div v-if="scope.row.task_total_count > 0">
                  <p
                    class="child-info"
                    style="display: inline-block"
                    v-if="scope.row.task_delay_count > 0"
                  >
                    <el-tooltip content="已延期" placement="top">
                      <span style="color: red">{{
                        scope.row.task_delay_count
                      }}</span> </el-tooltip
                    >/<el-tooltip content="已完成" placement="top"
                      ><span>{{
                        scope.row.task_complete_count
                      }}</span></el-tooltip
                    >/<el-tooltip content="任务总数" placement="top"
                      ><span>{{ scope.row.task_total_count }}</span></el-tooltip
                    >
                  </p>
                  <p class="child-info" style="display: inline-block" v-else>
                    <el-tooltip content="已完成" placement="top"
                      ><span>{{
                        scope.row.task_complete_count
                      }}</span></el-tooltip
                    >/<el-tooltip content="任务总数" placement="top"
                      ><span>{{ scope.row.task_total_count }}</span></el-tooltip
                    >
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="会议状态" width="10%">
              <template slot-scope="scope">
                <!-- 如果已经取消了，显示“已取消 -->
                <div
                  class="state-tag"
                  v-if="scope.row.is_cancel === 1"
                  :class="[stateColor[4].className]"
                >
                  {{ stateColor[4].text }}
                </div>
                <!-- 其他的正常显示 -->
                <div
                  class="state-tag"
                  v-else
                  :class="[stateColor[scope.row.meeting_status - 1].className]"
                >
                  {{ stateColor[scope.row.meeting_status - 1].text }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="纪要状态" width="15%">
              <template slot-scope="scope">
                <!-- 未上传 -->
                <div
                  class="record-tag flex flex-y-center"
                  :class="[recordStateList[1].className]"
                  v-if="
                    scope.row.is_upload_minutes === 0 &&
                    scope.row.meeting_status === 4
                  "
                >
                  <svg class="icon" aria-hidden="true">
                    <use :xlink:href="recordStateList[1].icon"></use>
                  </svg>
                  <span class="text">{{ recordStateList[1].text }}</span>
                </div>
                <!-- 已上传 -->
                <div
                  class="record-tag flex flex-y-center"
                  :class="[recordStateList[2].className]"
                  v-else-if="scope.row.is_upload_minutes === 1"
                >
                  <svg class="icon" aria-hidden="true">
                    <use :xlink:href="recordStateList[2].icon"></use>
                  </svg>
                  <span class="text">{{ recordStateList[2].text }}</span>
                </div>
                <!-- 无 -->
                <div
                  class="record-tag flex flex-y-center"
                  :class="[recordStateList[0].className]"
                  v-else
                >
                  <svg class="icon" aria-hidden="true">
                    <use :xlink:href="recordStateList[0].icon"></use>
                  </svg>
                  <span class="text">{{ recordStateList[0].text }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="5%">
              <template slot-scope="scope">
                <div class="handle-td">
                  <el-dropdown>
                    <span class="iconfont icon-gengduo3 more-btn"></span>
                    <!-- 操作菜单 -->
                    <el-dropdown-menu slot="dropdown" class="handle-menu">
                      <el-dropdown-item>
                        <div
                          class="handle-menu-item"
                          @click="showCancelConfirm(scope.row)"
                          v-if="
                            scope.row.is_cancel === 0 &&
                            scope.row.meeting_status < 3
                          "
                        >
                          取消会议
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          class="handle-menu-item"
                          @click="showDetailModal(scope.row)"
                        >
                          会议详情
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          class="handle-menu-item"
                          @click="beginEditMeeting(scope.row.meeting_id)"
                          v-if="
                            scope.row.meeting_status < 3 &&
                            scope.row.is_cancel === 0
                          "
                        >
                          编辑会议
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          class="handle-menu-item"
                          v-if="
                            scope.row.meeting_status === 4 &&
                            scope.row.is_upload_minutes === 0
                          "
                          @click="toMeetingTasks(scope.row)"
                        >
                          发布任务
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          class="handle-menu-item"
                          v-if="isShowToUploadMinute(scope.row)"
                          @click="toUploadMinute(scope.row)"
                        >
                          上传纪要
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div
                          class="handle-menu-item"
                          style="color: red"
                          @click="deleteMettingInfo(scope.row)"
                          v-if="
                            (scope.row.is_cancel === 1 ||
                              scope.row.meeting_status === 1) &&
                            scope.row.is_create_clerk === 1
                          "
                        >
                          删除会议
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- wap -->
          <el-table
            :data="meetingList"
            style="width: 100%; height: auto; margin-bottom: 50px"
            class="meeting customer-table wap"
          >
            <el-table-column label="">
              <template slot-scope="scope">
                <!-- 右下角 加+ -->
                <div @click="toAddMeeting" class="eliconcircleplus wap">
                  <i class="el-icon-circle-plus"></i>
                </div>
                <!-- 未上传 -->
                <table>
                  <tr>
                    <td style="width: 5%; vertical-align: top">
                      <div
                        class="record-tag flex flex-y-center"
                        :class="[recordStateList[1].className]"
                        v-if="
                          scope.row.is_upload_minutes === 0 &&
                          scope.row.meeting_status === 4
                        "
                      >
                        <i class="el-icon-caret-right"></i>
                      </div>
                      <!-- 已上传 -->
                      <div
                        class="record-tag flex flex-y-center"
                        :class="[recordStateList[2].className]"
                        v-else-if="scope.row.is_upload_minutes === 1"
                      >
                        <i class="el-icon-caret-right"></i>
                      </div>
                      <!-- 无 -->
                      <div
                        class="record-tag flex flex-y-center"
                        :class="[recordStateList[0].className]"
                        v-else
                      >
                        <svg class="icon" aria-hidden="true">
                          <use :xlink:href="recordStateList[0].icon"></use>
                        </svg>
                      </div>
                    </td>
                    <td style="width: 80%; padding-left: 25px">
                      <div
                        class="meeting-name"
                        @click="openDetailBox(scope.row)"
                      >
                        <p
                          style="
                            width: 200px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                          "
                        >
                          {{ scope.row.meeting_name }}
                        </p>
                        <p style="font-size: 10px">
                          <template
                            v-if="scope.row.start_time && scope.row.end_time"
                          >
                            {{ getDateAndTimeStr(scope.row.start_time) }} -
                            {{ getDateAndTimeStr(scope.row.end_time) }}
                          </template>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="handle-td">
                        <el-dropdown>
                          <span class="iconfont icon-gengduo3 more-btn"></span>
                          <!-- 操作菜单 -->
                          <el-dropdown-menu slot="dropdown" class="handle-menu">
                            <el-dropdown-item>
                              <div
                                class="handle-menu-item"
                                @click="showCancelConfirm(scope.row)"
                                v-if="
                                  scope.row.is_cancel === 0 &&
                                  scope.row.meeting_status < 3
                                "
                              >
                                取消会议
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <div
                                class="handle-menu-item"
                                @click="showDetailModal(scope.row)"
                              >
                                会议详情
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <div
                                class="handle-menu-item"
                                @click="beginEditMeeting(scope.row.meeting_id)"
                                v-if="
                                  scope.row.meeting_status < 3 &&
                                  scope.row.is_cancel === 0
                                "
                              >
                                编辑会议
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <div
                                class="handle-menu-item"
                                style="color: red"
                                @click="deleteMettingInfo(scope.row)"
                                v-if="
                                  (scope.row.is_cancel === 1 ||
                                    scope.row.meeting_status === 1) &&
                                  scope.row.is_create_clerk === 1
                                "
                              >
                                删除会议
                              </div>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </td>
                  </tr>
                </table>
              </template>
            </el-table-column>
          </el-table>

          <!-- 如果没有数据 -->
          <no-data
            :isShow="meetingList.length === 0"
            tipText="暂无会议列表信息"
          ></no-data>
          <!-- 分页组件 -->
          <el-pagination
            v-show="meetingList.length > 0"
            v-if="meetingPageInfo.totalPage > 1"
            @current-change="handleCurrentChange"
            :current-page="meetingPageInfo.pageNumber"
            background
            :page-size="meetingPageInfo.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="meetingPageInfo.totalRow"
          >
          </el-pagination>
          <!-- 取消会议弹窗 -->
          <el-dialog
            title="确认取消"
            :visible.sync="isConfirmCancel"
            width="21.14%"
            class="cancel-meeting"
            :append-to-body="true"
            top="15%"
          >
            <p class="cancel-confirm-text">是否确认取消会议？</p>
            <p class="cancel-tip">取消会议后，可重新创建发布新的会议。</p>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeCancel">取 消</el-button>
              <el-button type="primary" @click="cancelMeeting()"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </el-tab-pane>

        <!-- 第二款，会议室 -->
        <el-tab-pane label="会议室" name="second">
          <!-- 第一行 -->
          <div class="flex flex-y-center flex-x-between handle-line">
            <!-- 左边 -->
            <!-- 下拉框 -->
            <div
              class="select flex flex-y-center"
              @click.stop="toggleChooseRoomState"
            >
              <span class="tip-text">状态</span>
              <p class="result">{{ roomStateList[currentRoomState].text }}</p>
              <span class="iconfont icon-zhankai"></span>
              <!-- 下拉选项 -->
              <div class="menu-box" v-show="isSelectRoomState">
                <div
                  class="menu-box-item"
                  v-for="(item, index) in roomStateList"
                  :key="index"
                  @click="selectRoomState(item.value, index)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
            <!-- 右边，创建会议室的按钮 -->
            <div class="flex flex-y-center">
              <el-tooltip
                effect="dark"
                content="会议室日程"
                placement="top-start"
              >
                <i
                  class="iconfont icon-richeng meeting-room-occupy-btn"
                  @click="showMeetingRoomOccupy"
                ></i>
              </el-tooltip>
              <button class="add-btn" @click="toAddRoom">
                <span class="iconfont icon-add"></span>
                <span class="add-text">{{
                  screenWidth > 767 ? '创建会议室' : '创建'
                }}</span>
              </button>
            </div>
          </div>
          <!-- 数据表格 -->
          <el-table
            :visible.sync="isEditRoomInfo"
            class="meeting customer-table pc"
            :data="meetingRoomList"
            style="
              width: 100%;
              height: calc(100vh - 60px - 24px - 70px - 85px - 150px);
            "
          >
            <el-table-column label="会议室名称" width="30%">
              <template slot-scope="scope">
                <div
                  class="meeting-name"
                  @click="showRoomDetail(scope.row.room_id)"
                >
                  {{ scope.row.room_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="位置" width="16%">
              <template slot-scope="scope">
                <p class="city">{{ scope.row.city_name }}</p>
                <p class="address">{{ scope.row.room_address }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="设备信息" width="21%">
              <template slot-scope="scope">
                <p class="device-list">{{ GetDeviceList(scope.row) }}</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="room_galleryful"
              label="标准人数"
              width="12%"
              align="center"
            >
            </el-table-column>
            <el-table-column label="当前状态" width="15%">
              <template slot-scope="scope">
                <div
                  class="room-state-tag"
                  :class="[meetingRoomStateList[scope.row.is_enable].className]"
                >
                  <svg class="icon" aria-hidden="true">
                    <use
                      :xlink:href="
                        meetingRoomStateList[scope.row.is_enable].icon
                      "
                    ></use>
                  </svg>
                  <span class="state-text">{{
                    meetingRoomStateList[scope.row.is_enable].text
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="6%">
              <template slot-scope="scope">
                <div class="handle-td">
                  <!-- 操作菜单 -->
                  <el-dropdown>
                    <span class="iconfont icon-gengduo3 more-btn"></span>
                    <div class="handle-menu">
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <div
                            class="handle-menu-item"
                            @click="toEditRoom(scope.row.room_id)"
                          >
                            编辑
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div
                            class="handle-menu-item"
                            v-if="scope.row.is_enable === 0"
                            @click="
                              toEnableRoom(
                                scope.row.room_id,
                                scope.row.room_name
                              )
                            "
                          >
                            启用
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div
                            class="handle-menu-item"
                            v-if="scope.row.is_enable === 1"
                            @click="
                              toDisableRoom(
                                scope.row.room_id,
                                scope.row.room_name
                              )
                            "
                          >
                            禁用
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div
                            class="handle-menu-item"
                            @click="showRoomDetail(scope.row.room_id)"
                          >
                            查看详情
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item class="delete-btn"
                          ><div
                            class="handle-menu-item delete"
                            @click="deleteRoom(scope.row.room_id)"
                          >
                            删除
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </div>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            class="meeting customer-table wap"
            :data="meetingRoomList"
            style="
              width: 100%;
              height: calc(100vh - 60px - 24px - 70px - 85px - 150px);
            "
          >
            <el-table-column label="" width="30%">
              <template slot-scope="scope">
                <!-- 右下角 加+ -->
                <div @click="toAddRoom" class="eliconcircleplus wap">
                  <i class="el-icon-circle-plus"></i>
                </div>
                <table>
                  <tr>
                    <td style="width: 8%; vertical-align: top">
                      <div
                        class="room-state-tag"
                        :class="[
                          meetingRoomStateList[scope.row.is_enable].className
                        ]"
                      >
                        <svg class="icon" aria-hidden="true">
                          <use
                            :xlink:href="
                              meetingRoomStateList[scope.row.is_enable].icon
                            "
                          ></use>
                        </svg>
                      </div>
                    </td>
                    <td style="width: 80%; padding-left: 25px">
                      <div
                        class="meeting-name"
                        @click="showRoomDetail(scope.row.room_id)"
                      >
                        <p
                          style="
                            width: 200px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                          "
                        >
                          {{ scope.row.room_name }} ({{
                            scope.row.room_galleryful
                          }})
                        </p>
                        <p class="city address" style="font-size: 10px">
                          {{ scope.row.city_name }} {{ scope.row.room_address }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="handle-td">
                        <!-- 操作菜单 -->
                        <el-dropdown>
                          <span class="iconfont icon-gengduo3 more-btn"></span>
                          <div class="handle-menu">
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>
                                <div
                                  class="handle-menu-item"
                                  @click="toEditRoom(scope.row.room_id)"
                                >
                                  编辑
                                </div>
                              </el-dropdown-item>
                              <el-dropdown-item>
                                <div
                                  class="handle-menu-item"
                                  v-if="scope.row.is_enable === 0"
                                  @click="
                                    toEnableRoom(
                                      scope.row.room_id,
                                      scope.row.room_name
                                    )
                                  "
                                >
                                  启用
                                </div>
                              </el-dropdown-item>
                              <el-dropdown-item>
                                <div
                                  class="handle-menu-item"
                                  v-if="scope.row.is_enable === 1"
                                  @click="
                                    toDisableRoom(
                                      scope.row.room_id,
                                      scope.row.room_name
                                    )
                                  "
                                >
                                  禁用
                                </div>
                              </el-dropdown-item>
                              <el-dropdown-item class="delete-btn"
                                ><div
                                  class="handle-menu-item delete"
                                  @click="deleteRoom(scope.row.room_id)"
                                >
                                  删除
                                </div>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </div>
                        </el-dropdown>
                      </div>
                    </td>
                  </tr>
                </table>
              </template>
            </el-table-column>
          </el-table>
          <!-- 如果没有数据 -->
          <no-data
            :isShow="meetingRoomList.length === 0"
            tipText="暂无会议室列表信息"
          ></no-data>
          <!-- 分页组件 -->
          <el-pagination
            v-show="meetingRoomList.length > 0"
            v-if="meetingRoomPageInfo.totalPage > 1"
            @current-change="handleRoomCurrentChange"
            :current-page="meetingRoomPageInfo.pageNumber"
            background
            :page-size="meetingRoomPageInfo.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="meetingRoomPageInfo.totalRow"
          >
          </el-pagination>
          <!-- 确认启用弹窗 -->
          <el-dialog
            :visible.sync="isConfirmEnable"
            width="21.14%"
            class="cancel-meeting"
            :append-to-body="true"
            top="15%"
          >
            <template slot="title">
              <div class="title">
                启用--<span class="room-name">会议室名称</span>
              </div>
            </template>
            <p class="cancel-confirm-text">
              会议室启用后，用户可预定并使用此会议室，是否继续？
            </p>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeEnable">取 消</el-button>
              <el-button type="primary" @click="confirmEnable()"
                >确 定</el-button
              >
            </span>
          </el-dialog>
          <!-- 确认禁用弹窗 -->
          <el-dialog
            :visible.sync="isConfirmDisable"
            width="21.14%"
            class="cancel-meeting"
            :append-to-body="true"
            top="15%"
          >
            <template slot="title">
              <div class="title">
                禁用--<span class="room-name">{{ handleRoomName }}</span>
              </div>
            </template>
            <p class="cancel-confirm-text">
              禁用后，当前的会议室预定将被取消，用户将无法预定并使用此会议室
            </p>
            <!-- 是否给预定了该会议室的员工发消息 -->
            <div class="flex flex-y-center" @click="chooseIsTellClerks">
              <!-- 复选框的图标 -->
              <div>
                <svg class="icon confrim-checkbox" aria-hidden="true">
                  <use
                    :xlink:href="
                      isTellClerks ? '#icon-TickSquare_1' : '#icon-TickSquare'
                    "
                  ></use>
                </svg>
              </div>
              <p class="disabled-tip">发送通知给预定了该会议室的员工</p>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeDisable">取 消</el-button>
              <el-button type="primary" @click="confrimDisable()"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
      <!-- 新建会议的弹窗 -->
      <meeting-modal :isShow="isNewMeeting" @close-modal="closeAddMeeting">
        <new-meeting-modal
          @add-success="addMeetingSuccess"
          @close-modal="closeAddMeeting"
        ></new-meeting-modal>
      </meeting-modal>
      <!-- 编辑会议的弹窗 -->
      <meeting-modal :isShow="isEditMeeting" @close-modal="closeEditMeeting">
        <edit-meeting-modal>
          <edit-meeting-form
            :meetingId="currentEditMeeting"
            @edit-success="editMeetingSuccess"
            @close-modal="closeEditMeeting"
          ></edit-meeting-form>
        </edit-meeting-modal>
      </meeting-modal>
      <!-- 会议详情的弹窗 -->
      <meeting-modal :isShow="isShowDetail" @close-modal="closeDetailModal">
        <meeting-detail
          :meetingId="currentDetailId"
          :defaultActive="meetingDetailTab"
          @showTaskDetail="haveZIndex = true"
          @closeTaskDetail="haveZIndex = false"
        ></meeting-detail>
      </meeting-modal>
      <!-- 创建/编辑会议室弹窗 -->
      <edit-meeting-room-info
        :isShow="isEditRoomInfo"
        :roomId="currentRoomId"
        :modalTitle="addOrEdit"
        :provinceList="provinceList"
        :deviceList="deviceList"
        @close-modal="cancelAddRoom"
        @fresh-room-list="getMeetingRoomList"
        @fresh-device-list="getDeviceList"
      ></edit-meeting-room-info>
      <!-- 会议室详情 -->
      <meeting-room-detail
        :isShow="isShowRoomDetail"
        :roomId="currentRoomId"
        :deviceList="deviceList"
        @close-modal="closeRoomDetail"
      ></meeting-room-detail>
    </div>
    <meeting-room-occupy :visible.sync="meetingRoomOccupyVisible" />
  </div>
</template>

<script>
// 编辑弹窗表单部分
import EditMeetingForm from '../components/meeting/EditMeetingForm.vue'
// 编辑弹窗外层
import EditMeetingModal from '../components/meeting/EditMeetingModal.vue'
// 详情弹窗
import MeetingDetail from '../components/meeting/MeetingDetail.vue'
// 会议弹窗外层
import MeetingModal from '../components/meeting/MeetingModal.vue'
// 新建会议弹窗
import NewMeetingModal from '../components/meeting/NewMeetingModal.vue'
// 暂无数据的样式提示
import NoData from '../components/common/NoData.vue'
// 新建会议室弹窗
import EditMeetingRoomInfo from '../components/meeting/EditMeetingRoomInfo.vue'
// 会议室详情弹窗
import MeetingRoomDetail from '../components/meeting/MeetingRoomDetail.vue'
import MeetingRoomOccupy from '@/views/Meeting/components/meeting-room-occupy'
import { mapState } from 'vuex'
export default {
  components: {
    NewMeetingModal,
    EditMeetingModal,
    NoData,
    MeetingModal,
    MeetingDetail,
    EditMeetingForm,
    EditMeetingRoomInfo,
    MeetingRoomDetail,
    MeetingRoomOccupy
  },
  data() {
    return {
      // 是否展示任务详情
      isShowDetail: false,
      // 当前选中的是第几个
      currentTab: 'first',
      // 会议数据
      meetingList: [],
      // 状态类名集合
      stateColor: [
        {
          className: 'no-publish',
          text: '未发布'
        },
        {
          className: 'no-start',
          text: '未开始'
        },
        {
          className: 'in-progress',
          text: '进行中'
        },
        {
          className: 'over',
          text: '已结束'
        },
        {
          className: 'cancel',
          text: '已取消'
        }
      ],
      // 会议状态的菜单选项
      meetingStateList: [
        {
          text: '全部',
          value: 0
        },
        {
          text: '未开始',
          value: 2
        },
        {
          text: '未结束',
          value: 3
        },
        {
          text: '已结束',
          value: 4
        },
        {
          text: '已取消',
          value: 5
        },
        {
          text: '草稿',
          value: 1
        }
      ],
      // 是否筛选会议状态
      isSelectMeetingState: false,
      // 当前选择的状态下标
      currentMeetingState: 0,
      // 是否展示出筛选会议纪要的弹窗
      isSelectRecord: false,
      // 会议纪要状态
      recordStateList: [
        {
          className: 'null',
          value: 0,
          icon: '#icon-a-confirmfill',
          text: '无'
        },
        {
          className: 'not-upload',
          icon: '#icon-a-confirmfill1',
          value: 1,
          text: '未上传'
        },
        {
          className: 'has-uploaded',
          icon: '#icon-a-confirmfill2',
          value: 2,
          text: '已上传'
        }
      ],
      // 当前会议纪要状态下标
      currentRecordState: 0,
      // 会议的分页情况
      meetingPageInfo: {
        pageSize: 10,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 2
      },
      // 是否显示确认取消会议的弹窗
      isConfirmCancel: false,
      // 准备取消的会议的id
      toCancelMeeting: -1,
      // 是否要新建会议
      isNewMeeting: false,
      // 是否要编辑会议
      isEditMeeting: false,
      // 现在正在编辑的会议id
      currentEditMeeting: -1,
      // 现在正在查看详情的会议id
      currentDetailId: -1,
      // 当前在调用的子组件
      currentChild: null,
      // 任务详情打开哪个tab
      meetingDetailTab: 'meetingInfo',
      // 会议室的数据
      meetingRoomList: [],
      // 会议室的分页情况
      meetingRoomPageInfo: {
        pageSize: 10,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 1
      },
      // 会议室的状态
      meetingRoomStateList: [
        {
          className: 'disable',
          icon: '#icon-a-confirmfill3',
          text: '禁用'
        },
        {
          className: 'able',
          icon: '#icon-a-confirmfill2',
          text: '正常'
        }
      ],
      // 会议室的筛选选项
      roomStateList: [
        {
          text: '全部',
          value: -1
        },
        {
          text: '正常',
          value: 1
        },
        {
          text: '禁用',
          value: 0
        }
      ],
      // 是否正在选择会议室的状态
      isSelectRoomState: false,
      // 当前选择的会议室状态
      currentRoomState: 0,
      // 筛选会议室状态对应的值，点击下拉框选项的时候记录
      roomSelect: -1,
      // 是否在确认是否启用
      isConfirmEnable: false,
      // 是否在确认是否禁用
      isConfirmDisable: false,
      // 是否通知员工
      isTellClerks: false,
      // 当前要启用/禁用的会议室id
      handleRoomId: -1,
      // 当前要启用/禁用的会议室名字
      handleRoomName: '',
      // 是否显示创建会议室的弹窗
      isEditRoomInfo: false,
      // 当前编辑的会议室id
      currentRoomId: -1,
      // 设置编辑会议室弹窗标题
      addOrEdit: '创建会议室',
      // 省份列表
      provinceList: [],
      // 会议室设备列表
      deviceList: [],
      // 是否展示会议室详情
      isShowRoomDetail: false,
      // 会议室日程展示
      meetingRoomOccupyVisible: false,
      // 控制是否需要z-index
      haveZIndex: false
    }
  },
  computed: {
    ...mapState(['screenWidth', 'clickBodyIndex']),
    // 获取每个会议室的设备有哪些
    GetDeviceList() {
      return function (item) {
        const list = item.device_list.map((device) => device.device_name)
        return list.join('，')
      }
    }
    // currentTabModel: {
    //   get() {
    //     return this.currentTab
    //   },
    //   set(val) {
    //     console.log('val', val)
    //     this.changeCurrentTab(val)
    //   }
    // }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOut)
  },
  methods: {
    getDateAndTimeStr(str) {
      return this.$util.getDateAndTimeStr(new Date(str.replace(/-/g, '/')))
    },
    // 删除会议
    async deleteMettingInfo(e) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteMeeting, { meeting_id: e.meeting_id })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '删除会议成功',
          type: 'success'
        })
        // 重新加载当前页
        this.getMeetingPage(this.meetingPageInfo.pageNumber)
        this.isShowDetail = false
      }
    },
    // 获取会议数据
    async getMeetingPage(page = 1) {
      // 确定分页情况
      const formObj = {
        pageSize: this.meetingPageInfo.pageSize,
        pageNumber: page
      }
      // 判断我是否有筛选会议状态
      if (this.currentMeetingState !== 0 && this.currentMeetingState !== 4) {
        formObj.meeting_status =
          this.meetingStateList[this.currentMeetingState].value
        console.log(
          '正在筛选会议',
          this.meetingStateList[this.currentMeetingState].value,
          this.meetingStateList[this.currentMeetingState].text
        )
      }
      // 针对是否取消的判断，4表示已取消，就带上已取消的参数
      if (this.currentMeetingState === 4) {
        formObj.is_cancel = 1
      } else if (this.currentMeetingState !== 0) {
        // 如果筛选的是全部，那么不能带上“是否取消”的参数
        formObj.is_cancel = 0
      }
      // 判断我是否有筛选会议纪要的上传状态
      if (this.currentRecordState !== 0) {
        formObj.is_upload_minutes =
          this.recordStateList[this.currentRecordState].value - 1
      }
      console.log(formObj)
      // 获取数据
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingList, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.meetingList = res.page.list
        this.meetingPageInfo.totalRow = res.page.totalRow
        this.meetingPageInfo.totalPage = res.page.totalPage
      }
    },
    // 打开会议详情弹窗
    openDetailBox(item) {
      this.$router.push({
        name: 'meeting',
        params: {
          id: item.meeting_id
        }
      })
      // this.isShowDetail = true
      // this.currentDetailId = item.meeting_id
    },
    // 显示详情弹窗
    showDetailModal(item) {
      this.$router.push({
        name: 'meeting',
        params: {
          id: item.meeting_id
        }
      })
      // this.$router.push({
      //   name: 'meeting',
      //   query: {
      //     ...this.$route.query,
      //     meeting_id: item.meeting_id,
      //     meeting_detail_tab: 'meetingInfo'
      //   }
      // })
      // this.openDetailBox(item)
      // this.meetingDetailTab = 'meetingInfo'
    },
    // 点击“发布任务”，打开会议详情的任务板块
    toMeetingTasks(item) {
      this.$router.push({
        name: 'meeting',
        params: {
          id: item.meeting_id
        },
        query: {
          meeting_detail_tab: 'meetingTask'
        }
      })
      // this.openDetailBox(item)
      // this.meetingDetailTab = 'meetingTask'
    },
    // 判断菜单是否展示“上传纪要”按钮
    isShowToUploadMinute(item) {
      if (item.is_upload_minutes === 1) {
        return false
      }
      if (item.is_cancel === 1) {
        return false
      }
      switch (item.meeting_status || 0) {
        // 进行中
        case 3:
          if (item.is_upload_minutes_clerk === 1) {
            return true
          }
          return false
        // 已结束
        case 4:
          if (item.is_create_clerk === 1) {
            return true
          }
          if (item.is_compere_clerk === 1) {
            return true
          }
          if (item.is_upload_minutes_clerk === 1) {
            return true
          }
          return false
      }
      return false
    },
    // 点击“上传纪要”，打开会议详情的纪要板块
    toUploadMinute(item) {
      this.$router.push({
        name: 'meeting',
        params: { id: item.meeting_id },
        query: {
          meeting_detail_tab: 'meetingMinute'
        }
      })
      // this.openDetailBox(item)
      // this.meetingDetailTab = 'meetingMinute'
    },
    // 关闭详情弹窗
    closeDetailModal() {
      this.$router.push({
        name: 'meeting'
      })
      // this.isShowDetail = false
      // 关闭详情弹窗需要刷新会议列表数据
      // this.getMeetingPage(this.meetingPageInfo.pageNumber)
    },
    // 添加成功，关闭添加弹窗，刷新数据
    addMeetingSuccess() {
      this.closeAddMeeting()
      this.getMeetingPage()
    },
    // 编辑会议成功
    editMeetingSuccess() {
      this.closeEditMeeting()
      this.getMeetingPage()
    },
    // 点击tab
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 显示隐藏左上角的会议筛选下拉框
    toggleChooseMeetingState() {
      this.isSelectMeetingState = !this.isSelectMeetingState
    },
    // 筛选会议
    selectState(val, index) {
      this.currentMeetingState = index
      // 重新获取会议数据
      this.getMeetingPage()
    },
    // 是否筛选右边的弹窗
    toggleSelect() {
      this.isSelectRecord = !this.isSelectRecord
    },
    // 点击筛选会议纪要
    chooseRecordState(item) {
      this.currentRecordState = item.value
      this.isSelectRecord = false
      // 重新获取会议数据
      this.getMeetingPage()
    },
    // 对状态的过滤函数
    filterMeetState(value, row, column) {
      console.log(value, row, column)
      if (value === 0) {
        return true
      } else {
        return row.meeting_status === value
      }
    },
    // 显示新建wd会议弹窗
    toAddMeeting() {
      this.isNewMeeting = true
    },
    // 收起新建会议的弹窗
    closeAddMeeting() {
      this.isNewMeeting = false
    },
    // 开始编辑会议
    beginEditMeeting(id) {
      // 关闭详情弹窗
      if (this.isShowDetail) {
        this.isShowDetail = false
      }
      this.isEditMeeting = true
      this.currentEditMeeting = id
    },
    // 收起新建会议的弹窗
    closeEditMeeting() {
      this.isEditMeeting = false
      this.currentEditMeeting = -1
    },
    // 显示隐藏会议的操作菜单
    // showHandle(item) {
    //   console.log(item)
    //   if (item.isShowHandle) {
    //     this.$set(item, 'isShowHandle', false)
    //   } else {
    //     for (const meeting of this.meetingList) {
    //       this.$set(meeting, 'isShowHandle', false)
    //     }
    //     this.$set(item, 'isShowHandle', true)
    //   }
    // },
    // 关闭会议的操作菜单
    closeHandle(item) {
      this.$set(item, 'isShowHandle', false)
    },
    // 分页的函数
    handleCurrentChange(val) {
      // 请求当前页的数据
      this.getMeetingPage(val)
    },
    // 准备取消会议
    showCancelConfirm(meeting, child) {
      this.isConfirmCancel = true
      this.toCancelMeeting = meeting.meeting_id
      this.closeHandle(meeting)
      if (child) {
        this.currentChild = child
      }
    },
    // 不取消会议
    closeCancel() {
      this.isConfirmCancel = false
      this.toCancelMeeting = -1
      this.currentChild = null
    },
    // 取消会议
    async cancelMeeting() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.cancelMeeting, {
          meeting_id: this.toCancelMeeting
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '取消会议成功',
          type: 'success'
        })
        // 重新加载当前页
        this.getMeetingPage(this.meetingPageInfo.pageNumber)
        // 如果是子组件的，就刷新一下子组件的数据
        if (this.currentChild) {
          this.currentChild.getMeetingDetail()
        }
        this.closeCancel()
      }
    },
    // 会议室的方法
    // 请求省份列表
    async getProvinceList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getProvinceList)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.provinceList = res.list
      }
    },
    // 获取设备列表
    async getDeviceList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getRoomDeviceList)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.deviceList = res.list
      }
    },
    // 获取会议室列表
    async getMeetingRoomList(page = 1) {
      const formObj = {
        pageNumber: page,
        pageSize: this.meetingRoomPageInfo.pageSize
      }
      // 带上筛选条件
      if (this.roomSelect !== -1) {
        formObj.is_enable = this.roomSelect
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getMeetingRoomPage, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.meetingRoomList = res.page.list
        // 更新分页情况
        this.meetingRoomPageInfo.totalRow = res.page.totalRow
        this.meetingRoomPageInfo.pageNumber = res.page.pageNumber
        this.meetingRoomPageInfo.totalPage = res.page.totalPage
      }
    },
    // 会议室列表页面跳转
    handleRoomCurrentChange(val) {
      this.getMeetingRoomList(val)
    },
    // 显示隐藏选择会议室状态
    toggleChooseRoomState() {
      this.isSelectRoomState = !this.isSelectRoomState
    },
    // 点击选择会议室状态
    selectRoomState(val, index) {
      this.currentRoomState = index
      this.roomSelect = val
      this.getMeetingRoomList(1)
    },
    // 准备启用
    toEnableRoom(id, name) {
      this.isConfirmEnable = true
      this.handleRoomId = id
      this.handleRoomName = name
    },
    // 取消启用
    closeEnable() {
      this.isConfirmEnable = false
      this.handleRoomId = -1
      this.handleRoomName = ''
    },
    // 确定启用
    confirmEnable() {
      this.handleMeetingRoom(1)
    },
    // 启用/禁用
    async handleMeetingRoom(val) {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.enableMeetingRoom, {
          room_id: this.handleRoomId,
          is_enable: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        let tip = ''
        if (val === 0) {
          tip = '禁用会议室成功'
          this.closeDisable()
        } else {
          tip = '启用会议室成功'
          this.closeEnable()
        }
        this.$message({
          showClose: true,
          message: tip,
          type: 'success'
        })
        // 提示完毕，刷新数据，仍然请求当前页
        this.getMeetingRoomList(this.meetingRoomPageInfo.pageNumber)
      }
    },
    // 准备禁用
    toDisableRoom(id, name) {
      this.isConfirmDisable = true
      this.handleRoomId = id
      this.handleRoomName = name
    },
    // 点击选择是否通知员工
    chooseIsTellClerks() {
      this.isTellClerks = !this.isTellClerks
    },
    // 取消禁用
    closeDisable() {
      this.isConfirmDisable = false
      this.handleRoomId = -1
      this.handleRoomName = ''
    },
    // 确定禁用
    async confrimDisable() {
      this.handleMeetingRoom(0)
    },
    // 开始创建会议室
    toAddRoom() {
      this.currentRoomId = -1
      this.isEditRoomInfo = true
      this.visible = true
      // this.$notify({
      //   duration: 0
      // })
    },
    // 取消创建会议室
    cancelAddRoom() {
      this.isEditRoomInfo = false
      // 把当前在编辑的会议室id去掉，标题还原
      this.currentRoomId = -1
      console.log('把当前会议室id改成-1了')
      this.addOrEdit = '创建会议室'
    },
    // 准备编辑会议室
    toEditRoom(id) {
      this.currentRoomId = id
      this.addOrEdit = '编辑会议室'
      this.isEditRoomInfo = true
    },
    // 查看会议室详情
    showRoomDetail(id) {
      this.$router.push(`/meetingRoom/${id}`)
      // this.currentRoomId = id
      // this.isShowRoomDetail = true
    },
    // 关闭会议室详情弹窗
    closeRoomDetail() {
      this.$router.push('/meetingRoom')
      // this.isShowRoomDetail = false
      // this.currentRoomId = -1
    },
    // 点到外面
    clickOut() {
      this.isSelectRecord = false
      this.isSelectMeetingState = false
      this.isSelectRoomState = false
    },
    // 删除会议室
    deleteRoom(id) {
      this.$util
        .showConfirm('确认要删除这个会议室吗？')
        .then(async (result) => {
          if (result) {
            const [err, res] = await this.$util.to(
              this.$http.post(this.$api.deleteMeetingRoom, { room_id: id })
            )
            if (err) {
              console.log(err)
            } else if (res.state === 'ok') {
              this.$message({
                showClose: true,
                message: '删除会议室成功',
                type: 'success'
              })
              // 刷新数据
              this.getMeetingRoomList()
            }
          }
        })
    },
    showMeetingRoomOccupy() {
      this.meetingRoomOccupyVisible = true
    },
    // 切换tab
    changeCurrentTab(e) {
      console.log(e)
      switch (e.name) {
        case 'first': {
          this.$router.push({
            name: 'meeting'
          })
          break
        }
        case 'second': {
          this.$router.push('/meetingRoom')
          break
        }
      }
      // console.log(e)
      // let tab = ''
      // switch (e) {
      //   case 'second':
      //     tab = 'room'
      //     break
      // }
      // const query = { ...this.$route.query }
      // delete query.tab
      // this.$router.replace({
      //   name: 'meeting',
      //   query: tab ? { ...query, tab: tab } : query
      // })
    }
  },
  mounted() {
    // 获取会议数据
    this.getMeetingPage()
    // 获取会议室数据
    this.getMeetingRoomList()
    // 获取省份数据
    this.getProvinceList()
    // 获取设备数据
    this.getDeviceList()
    // document.addEventListener('click', this.clickOut)
    if (this.$route.path.indexOf('/meetingRoom') === 0) {
      this.currentTab = 'second'
      console.log('-------------', this.$route.params.id)
      if (this.$route.params.id) {
        // 打开会议室详情
        this.currentRoomId = Number(this.$route.params.id)
        this.isShowRoomDetail = true
      }
    } else {
      if (this.$route.params.id) {
        // 打开会议详情
        this.currentDetailId = Number(this.$route.params.id)
        this.isShowDetail = true
      }
    }
    // // 切换tab
    // if (this.$route?.query?.tab) {
    //   switch (this.$route.query.tab) {
    //     case 'room':
    //       this.currentTab = 'second'
    //       break
    //   }
    // }
    // 打开详情
    // if (this.$route?.query?.meeting_id) {
    //   this.currentDetailId = this.$route?.query?.meeting_id
    //   this.isShowDetail = true
    //   if (this.$route?.query?.meeting_detail_tab) {
    //     this.meetingDetailTab = this.$route?.query?.meeting_detail_tab
    //   }
    // } else if (this.$route?.query?.room_id) {
    //   // 打开会议室详情
    //   this.currentRoomId = this.$route?.query?.room_id
    //   this.isShowRoomDetail = true
    // } else {
    //   // 关闭会议详情
    //   this.currentDetailId = -1
    //   this.isShowDetail = false
    //   this.meetingDetailTab = 'meetingInfo'
    //   // 关闭会议室详情
    //   this.isShowRoomDetail = false
    //   this.currentRoomId = -1
    // }

    // esc关闭
    this.$nextTick(() => {
      document.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          if (this.isShowDetail) {
            this.closeDetailModal()
          }
          if (this.isShowRoomDetail) {
            this.closeRoomDetail()
          }
        }
      })
    })
  },
  watch: {
    currentTabModel() {
      console.log('123123123')
    },
    clickBodyIndex() {
      this.clickOut()
    },
    $route(val) {
      const init = () => {
        this.currentTab = 'first'
        this.currentRoomId = -1
        this.isShowRoomDetail = false
        this.currentDetailId = -1
        this.isShowDetail = false
        this.meetingDetailTab = 'meetingInfo'
      }
      if (val.path.indexOf('/meetingRoom') === 0) {
        init()
        this.currentTab = 'second'
        if (val.params.id) {
          // 打开会议室详情
          this.currentRoomId = Number(val.params.id)
          this.isShowRoomDetail = true
        }
      } else {
        init()
        this.currentTab = 'first'
        if (val.params.id) {
          this.currentDetailId = Number(val.params.id)
          this.isShowDetail = true
          if (val?.query?.meeting_detail_tab) {
            this.meetingDetailTab = val.query.meeting_detail_tab
          }
        }
      }
    }
    // '$route.query'(val, old) {
    //   // // 切换tab
    //   // if (val?.tab !== old?.tab) {
    //   //   switch (val.tab) {
    //   //     case 'room':
    //   //       this.currentTab = 'second'
    //   //       break
    //   //     default:
    //   //       this.currentTab = 'first'
    //   //       break
    //   //   }
    //   // }
    //   // 打开会议详情
    //   if (val?.meeting_id) {
    //     this.currentDetailId = val?.meeting_id
    //     this.isShowDetail = true
    //     if (val?.meeting_detail_tab) {
    //       this.meetingDetailTab = val?.meeting_detail_tab
    //     }
    //   } else if (val?.room_id) {
    //     // 打开会议室详情
    //     this.currentRoomId = val?.room_id
    //     this.isShowRoomDetail = true
    //   } else {
    //     // 关闭会议详情
    //     this.currentDetailId = -1
    //     this.isShowDetail = false
    //     this.meetingDetailTab = 'meetingInfo'
    //     // 关闭会议室详情
    //     this.isShowRoomDetail = false
    //     this.currentRoomId = -1
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.child-info {
  span {
    cursor: default;
    padding: 0 2px;
  }
}
// 主要区域
.main-box {
  position: relative;
  padding: 0 50px;
  box-sizing: border-box;
  height: calc(100vh - 60px - 30px);
  overflow: auto;
  background: #fff;
  border-radius: 6px;
  button {
    cursor: pointer;
  }
}

.main-container {
  margin-top: 0;
  margin-bottom: 0;
  padding: 24px $page-padding 0;
  flex: 1;
}

// 对tab的样式穿透
.main-box ::v-deep .el-tabs__item.is-top {
  height: 70px;
  line-height: 70px;
  font-size: 16px;
  color: #999;
  &.is-active {
    color: $main-color;
  }
}

.main-box ::v-deep .el-tabs__active-bar.is-top {
  background: $main-color;
}

.main-box ::v-deep .el-tabs__header.is-top {
  margin-bottom: 20px;
}

// 第一块，下拉框
.main-box .handle-line {
  padding-bottom: 20px;
}
.select {
  position: relative;
  z-index: 2;
  height: 38px;
  padding: 0 16px;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 6px;
  .tip-text {
    margin-right: 18px;
    line-height: 1.5;
    color: #999;
  }
  .result {
    margin-right: 28px;
    color: #333;
  }
  .iconfont {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    line-height: 1;
    font-size: 20px;
    color: #999;
  }
  // 左上角的状态选择下拉框
  .menu-box {
    position: absolute;
    top: 50px;
    left: 62px;
    width: 120px;
    background: #fff;
    box-shadow: 0px 2px 6px rgba(153, 153, 153, 0.25);
    border-radius: 4px;
    .menu-box-item {
      cursor: pointer;
      line-height: 36px;
      padding: 0 16px;
      color: #333;
    }
  }
}

.select-btn-box {
  position: relative;
  z-index: 1;
  // 筛选按钮
  .select-btn {
    width: 38px;
    height: 38px;
    margin-left: 16px;
    vertical-align: middle;
    border: 1px solid #eee;
    box-sizing: border-box;
    line-height: 38px;
    border-radius: 4px;
    .iconfont {
      font-size: 24px;
      color: #bbb;
    }
  }
  // 筛选弹窗
  .select-menu {
    position: absolute;
    top: 50px;
    right: 0;
    width: 120px;
    background: #ffffff;
    box-shadow: 0 0 14px 4px rgba(153, 153, 153, 0.2);
    border-radius: 4px;
    .select-menu-item {
      cursor: pointer;
      padding: 0 16px;
      line-height: 36px;
      color: #95a7bc;
    }
  }
}

// 添加会议按钮
.add-btn {
  margin-left: 16px;
  height: 38px;
  width: 126px;
  line-height: 38px;
  vertical-align: middle;
  background: $main-color;
  border-radius: 4px;
  .iconfont {
    font-size: 12px;
    color: #fff;
  }
  .add-text {
    padding-left: 6px;
    line-height: 38px;
    color: #fff;
  }
}

// 表示会议状态的图标
.state-tag {
  width: min-content;
  padding: 0 10px;
  white-space: nowrap;
  line-height: 26px;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  &.no-publish {
    background: #37d3ca;
  }
  &.no-start {
    background: #f13b72;
  }
  &.in-progress {
    background: #ff9f46;
  }
  &.cancel {
    color: #999;
    background: #f6f6f6;
  }
  &.over {
    background: #2edb9c;
  }
}

// 会议纪要标签
.record-tag {
  width: 80px;
  padding: 0 6px;
  line-height: 26px;
  border-radius: 13px;
  .text {
    font-size: 12px;
    padding-left: 8px;
  }
  .icon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  &.null {
    color: #ff934b;
    background: rgba(255, 147, 75, 0.1);
  }
  &.not-upload {
    color: #ff3e3e;
    background: rgba(255, 62, 62, 0.1);
  }
  &.has-uploaded {
    color: #1152ff;
    background: rgba(17, 82, 255, 0.1);
  }
}

// 操作
.handle-td {
  position: relative;
  .more-btn.iconfont {
    margin-left: -15px;
    font-size: 40px;
    width: 40px;
    // font-weight: 700;
    color: #999;
    line-height: 1;
    display: block;
    cursor: pointer;
    transform: scale(0.5);
    background: transparent;
  }
  // 更多的操作菜单
  .handle-menu {
    position: absolute;
    top: 44px;
    right: 0;
    z-index: 2;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  }
  .handle-menu-item {
    width: 140px;
    cursor: pointer;
    text-align: center;
    color: #95a7bc;
    line-height: 40px;
    &:hover {
      color: $main-color;
    }
    &.delete {
      color: $red;
    }
  }
}

// 取消会议弹窗
.cancel-confirm-text {
  line-height: 1.5;
  margin-bottom: 12px;
  color: #333;
}

.cancel-tip {
  line-height: 1.5;
  color: #999;
}

// 会议室表格样式
// 城市和地址
.city {
  padding-bottom: 4px;
  line-height: 1.5;
  color: #333;
}

.address {
  font-size: 12px;
  line-height: 1.5;
  color: #999;
}

// 设备列表
.device-list {
  line-height: 1.5;
  color: #333;
}
@media screen and (min-width: 767px) {
  // 会议室状态标签
  .room-state-tag {
    width: 70px;
    padding-left: 6px;
    line-height: 26px;
    border-radius: 13px;
    .icon {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    .state-text {
      padding-left: 8px;
      font-size: 12px;
      line-height: 26px;
    }
    // 禁用状态样式
    &.disable {
      background: rgba(255, 62, 62, 0.1);
      .state-text {
        color: #ff3e3e;
      }
    }
    // 启用状态样式
    &.able {
      background: rgba(17, 82, 255, 0.1);
      .state-text {
        color: #1152ff;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  // 会议室状态标签
  .room-state-tag {
    width: 30px;
    height: 30px;
    padding-left: 6px;
    line-height: 26px;
    border-radius: 13px;
    .icon {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    .state-text {
      padding-left: 8px;
      font-size: 12px;
      line-height: 26px;
    }
    // 禁用状态样式
    &.disable {
      background: rgba(255, 62, 62, 0.1);
      .state-text {
        color: #ff3e3e;
      }
    }
    // 启用状态样式
    &.able {
      background: rgba(17, 82, 255, 0.1);
      .state-text {
        color: #1152ff;
      }
    }
  }
  .handle-line {
    display: none;
  }
  .main-container {
    padding: 0;
    width: calc(100%);

    .main-box ::v-deep .el-tabs__header.is-top {
      margin-bottom: 10px;
    }

    .main-box::v-deep .el-tabs__item.is-top {
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      color: #999;
    }

    .main-box {
      width: calc(100%);
      padding: 0;
      box-sizing: inherit;

      .customer-table table {
        width: 100% !important;
      }

      .select {
        padding: 0;
        .tip-text {
          margin-right: 0;
          padding: 0 8px;
        }
        .result {
          margin-right: 0;
          padding: 0 10px;
        }
      }

      .add-btn {
        width: 80px;
      }
    }
  }
}

.meeting.customer-table ::v-deep {
  .el-table__header-wrapper .has-gutter .gutter {
    display: initial !important;
  }
}
.meeting-room-occupy-btn {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #eee;
  color: #bbb;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 16px;
  font-size: 24px;
  box-sizing: border-box;
}
</style>

<style lang="scss">
.meeting.main-container {
  // 表格样式调整
  // 去掉表格单元格边框
  .customer-table table {
    table-layout: fixed;
    color: #333;
  }

  .customer-table th {
    border: none;
    color: #999;
  }
  .customer-table td,
  .customer-table th.is-leaf {
    border: none;
  }

  .el-table th {
    background-color: #fff;
    color: #333;
  }

  .el-table tr:hover,
  .el-table tr:hover > td {
    background-color: #fff !important;
  }

  .el-table tr:hover .meeting-name {
    color: #0080ff;
  }

  .customer-table th:first-child,
  .customer-table td:first-child {
    // padding-left: 50px;
  }

  // 表格最外边框
  .el-table--border,
  .el-table--group {
    border: none;
  }
  // 头部边框
  .customer-table thead tr th.is-leaf {
    border: none;
  }
  .customer-table thead tr th:nth-last-of-type(2) {
    border: none;
  }
  .customer-table .el-table__row td {
    height: 60px;
    border: none;
  }
  // 表格最外层边框-底部边框
  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .customer-table::before {
    width: 0;
  }
  .customer-table .el-table__fixed-right::before,
  .el-table__fixed::before {
    width: 0;
  }
  // 表格有滚动时表格头边框
  .el-table--border th.gutter:last-of-type {
    border: none;
  }

  .customer-table table {
    width: 100% !important;
  }

  .customer-table.el-table .cell {
    overflow: visible;
  }

  .el-pagination {
    padding: 46px 0 56px;
    margin: 0 auto;
    border: none;
    display: flex;
    justify-content: center;
  }

  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    display: none;
  }

  // 调整取消会议弹窗的样式
  .cancel-meeting .el-dialog__body {
    height: 150px;
    padding: 36px 26px;
    box-sizing: border-box;
  }

  .el-table__body-wrapper.is-scrolling-none {
    height: calc(100% - 47px);
    overflow: auto;
  }

  .el-table__body-wrapper.is-scrolling-none table {
    position: relative;
    z-index: 0;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active,
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #fff;
    background: $main-color;
  }

  // 暂无数据
  .el-table__empty-block {
    display: none !important;
  }

  .el-input--prefix .el-input__inner {
    border: 1px solid #fff;
  }

  .el-input--prefix .el-input__inner:hover,
  .el-input--prefix .el-input__inner:focus {
    border-color: $main-color;
  }

  // 对删除按钮的修改
  .delete-btn {
    color: $red;
    &:hover {
      color: $red !important;
      background: rgba($red, 0.1) !important;
    }
  }

  // 禁用弹窗的样式调整
  .title .room-name {
    color: $red;
  }

  .disabled-tip {
    padding-top: 10px;
    cursor: pointer;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.5;
    user-select: none;
    color: #333;
  }

  .confrim-checkbox {
    margin-top: 10px;
    width: 22px;
    height: 22px;
    display: block;
    cursor: pointer;
  }

  .meeting-name {
    cursor: pointer;
  }

  // 最后一行，按钮
  .button-line {
    padding-bottom: 30px;
    .meeting-btn {
      cursor: pointer;
      margin-right: 18px;
      line-height: 24px;
      padding: 5px 10px;
      box-sizing: border-box;
      font-size: 14px;
      color: #999;
      background: #f6f6f6;
      border-radius: 4px;
      &.active {
        color: #fff;
        background: $main-color;
      }
      &.cancel {
        color: #999;
        background: #fff;
      }
    }
    // 在编辑状态下显示的
    &.edit-box {
      padding: 30px 0 0 0;
    }
  }
  .select .menu-box .menu-box-item:hover,
  .select-btn-box .select-menu .select-menu-item:hover {
    background: $light-grey-bg;
  }

  @media screen and (max-width: 767px) {
    .pc {
      display: none;
    }
    .customer-table table {
      width: 100% !important;
    }

    .customer-table th:first-child,
    .customer-table td:first-child {
      padding: 5px;
    }
    .el-dialog {
      width: 90% !important;
      box-sizing: border-box;
    }
    .el-dialog {
      width: 90% !important;
    }
    .el-pagination {
      // width: -webkit-min-content;
      // width: -moz-min-content;
      // width: min-content;
      padding: 6px 0 6px;
      border: none;
      position: fixed !important;
      bottom: 60px;
      background: #ffffff;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .customer-table th.is-leaf {
      border: none;
      display: none;
    }
    td {
      height: auto !important;
      border: none;
    }
    .record-tag {
      width: 26px;
      padding: 6px;
      line-height: 26px;
      border-radius: 13px;
    }
    .is-scrolling-none {
      // height: calc(100% - 47px);
      overflow: hidden !important;
    }
    .el-table .cell,
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0px;
    }
    .el-tabs__nav-wrap {
      overflow: scroll !important;
      margin-bottom: -1px;
      position: relative;
      padding: 0 10px;
    }

    .el-table tr:hover,
    .el-table tr:hover > td {
      background-color: transparent !important;
    }
    .el-pagination span:nth-child(1) {
      display: none;
    }
    .eliconcircleplus {
      position: fixed;
      right: 20px;
      bottom: 110px;
      z-index: 2;
      i {
        font-size: 44px;
        color: rgb(0, 143, 238);
      }
    }
    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      margin: 0 5px;
      background-color: #fff;
      color: #606266;
      min-width: 30px;
      border-radius: 2px;
    }

    .el-pagination.is-background .el-pager li:not(.disabled).active,
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #5ba2ec;
      background: #ffffff;
    }
    .el-tabs__content {
      height: 100%;
      overflow: scroll;
    }
    .el-input.is-disabled .el-input__inner {
      background-color: #ffffff;
      border-color: #ffffff;
      color: #c0c4cc;
      cursor: not-allowed;
    }
    .el-input__prefix {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .wap {
      display: none;
    }
    .button-line {
      padding-left: 30px;
      .meeting-btn {
        width: 94px;
      }
    }
    .el-icon-circle-plus {
      display: none;
    }
  }
}
</style>
