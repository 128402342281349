import { render, staticRenderFns } from "./KnowledgePermissions.vue?vue&type=template&id=78a70112&scoped=true&"
import script from "./KnowledgePermissions.vue?vue&type=script&lang=js&"
export * from "./KnowledgePermissions.vue?vue&type=script&lang=js&"
import style0 from "./KnowledgePermissions.vue?vue&type=style&index=0&id=78a70112&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a70112",
  null
  
)

export default component.exports