<!--
 * @Author: hzh
 * @Date: 2021-12-17 17:24:13
 * @LastEditors: hzh
 * @LastEditTime: 2022-01-14 16:47:48
-->
<template>
  <div class="dept-box flex flex-y-positive">
    <template v-if="!isXs">
      <el-card class="flex1 flex flex-y-positive">
        <div class="flex flex-y-center" style="margin-bottom: 20px">
          <el-select
            v-model="currentCycleId"
            style="margin-right: 10px"
            size="small"
          >
            <el-option
              v-for="item in cycleList"
              :key="item.cycle_id"
              :value="item.cycle_id"
              :label="`${item.start_date} - ${item.end_date}`"
            >
              {{ item.start_date }} - {{ item.end_date }}
            </el-option>
          </el-select>
          <el-select
            v-model="currentDeptId"
            placeholder="请选择"
            size="small"
            style="margin-right: 10px"
          >
            <el-option
              v-for="item in deptList"
              :key="item.dept_id"
              :value="item.dept_id"
              :label="item.dept_name"
            >
            </el-option>
          </el-select>
          <el-popover placement="top" width="340" trigger="click">
            <template #default
              ><p class="tip-text">部门：仅显示负责的部门</p>
              <p class="tip-text">
                成员：仅可查看主部门为当前部门及一级子部门的成员
              </p></template
            >
            <template #reference>
              <i class="iconfont icon-xinxi" style="font-size: 20px"></i>
            </template>
          </el-popover>
        </div>
        <el-table
          :data="dataList"
          class="table-box flex1"
          @row-click="handlerRowClick"
          @sort-change="handlerSortChange"
        >
          <el-table-column prop="clerk_name" label="姓名" min-width="200">
            <template #default="{ row }">
              <div class="flex flex-y-center">
                <img
                  :src="row.clerk_avatar"
                  alt="用户头像"
                  class="avatar"
                /><span class="clerk-name"
                  >{{ row.clerk_alias || row.clerk_name }} /
                  {{ row.main_dept_name }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="objective_total_number"
            label="总目标"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag>{{ row.objective_total_number }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="objective_complete_number"
            label="完成目标"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag type="success">{{
                row.objective_complete_number
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="objective_delay_number"
            label="延期目标"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag type="danger">{{ row.objective_delay_number }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="task_total_number"
            label="总任务"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag>{{ row.task_total_number }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="task_complete_number"
            label="完成任务"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag type="success">{{ row.task_complete_number }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="task_delay_number"
            label="延期任务"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag type="danger">{{ row.task_delay_number }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="task_terminated_number"
            label="终止任务"
            min-width="75"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag :color="'#f2e2ff'" style="color: #8400ff">{{
                row.task_terminated_number
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="task_complete_rate"
            label="任务完成率"
            min-width="80"
            sortable="custom"
          >
            <template #default="{ row }">
              <el-tag style="width: 70px">{{ row.task_complete_rate }}%</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>
    <template v-if="isXs">
      <div class="header-box flex flex-y-center flex-x-between">
        <div class="flex flex-y-center">
          <i class="icon-a-arrowaltright1 back-btn" @click="back()"></i>
          <span>周期 {{ month }} {{ currentDept.dept_name || '' }}</span>
        </div>
        <vant-popover
          trigger="click"
          placement="bottom-end"
          v-model="showVantPopoverScreen"
          :actions="[{ text: '部门' }, { text: '周期' }]"
          @select="handlerVantPopoverScreen"
        >
          <template #reference>
            <i class="icon-shaixuan screen-btn"></i>
          </template>
        </vant-popover>
      </div>
      <div class="list-box">
        <div
          class="list-item flex flex-y-positive"
          v-for="(row, index) in dataList"
          :key="index"
          @click="handlerRowClick(row)"
        >
          <div class="item-header flex flex-y-center flex-x-between">
            <div class="left flex flex-y-center">
              <img :src="row.clerk_avatar" alt="用户头像" class="avatar" />
              <div class="flex flex-y-positive">
                <span class="clerk-name">{{
                  row.clerk_alias || row.clerk_name
                }}</span>
                <span class="dept-name">{{ row.main_dept_name }}</span>
              </div>
            </div>
            <div class="right flex flex-y-center">
              <div class="flex flex-y-positive flex-y-center target">
                <span class="number">{{ row.objective_total_number }}</span>
                <span class="text">总目标</span>
              </div>
              <div class="flex flex-y-positive flex-y-center task">
                <span class="number">{{ row.task_total_number }}</span>
                <span class="text">总任务</span>
              </div>
              <i class="icon-a-arrowaltdown"></i>
            </div>
          </div>
          <div class="column-list">
            <div
              class="
                column-list-item
                flex flex-y-center flex-x-between
                objective-complete-rate
              "
            >
              <span class="name">目标完成率</span>
              <span class="value">{{ row.objective_complete_rate }}%</span>
            </div>
            <div
              class="
                column-list-item
                flex flex-y-center flex-x-between
                task-complete-rate
              "
            >
              <span class="name">任务完成率</span>
              <span class="value">{{ row.task_complete_rate }}%</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 详情 -->
    <work-report-month-dept-detail
      :visible.sync="showDetail"
      :specified.sync="detailReport"
      :reportList="detailReportList"
    />
    <!-- 选择周期 -->
    <vant-popup
      v-if="isXs"
      v-model="showVantPickerCycle"
      round
      position="bottom"
    >
      <vant-picker
        show-toolbar
        :columns="vantPickerCycleColumns"
        @confirm="
          currentCycleId = cycleList[arguments[1]].cycle_id
          showVantPickerCycle = false
        "
        :default-index="
          cycleList.findIndex((e) => e.cycle_id === currentCycleId)
        "
        @cancel="showVantPickerCycle = false"
      ></vant-picker>
    </vant-popup>
    <!-- 选择部门 -->
    <vant-popup
      v-if="isXs"
      v-model="showVantPickerDept"
      round
      position="bottom"
    >
      <vant-picker
        show-toolbar
        :columns="vantPickerDeptColumns"
        @confirm="
          currentDeptId = deptList[arguments[1]].dept_id
          showVantPickerDept = false
        "
        :default-index="deptList.findIndex((e) => e.dept_id === currentDeptId)"
        @cancel="showVantPickerDept = false"
      ></vant-picker>
    </vant-popup>
  </div>
</template>

<script>
import WorkReportMonthDeptDetail from './work-report-month-dept-detail.vue'
import { mapState, mapGetters } from 'vuex'
import {
  Popover as VantPopover,
  Picker as VantPicker,
  Popup as VantPopup
} from 'vant'

import 'vant/lib/popover/style'
import 'vant/lib/picker/style'
import 'vant/lib/popup/style'
export default {
  name: 'workReportMonthDept',
  components: {
    WorkReportMonthDeptDetail,
    VantPopover,
    VantPicker,
    VantPopup
  },
  props: {},
  data() {
    return {
      // 周期列表
      cycleList: [],
      currentCycleId: null,
      // 部门列表
      deptList: [],
      currentDeptId: null,
      // 数据列表
      originalDataList: [],
      // 详情报告
      detailReport: null,
      // 展示详情
      showDetail: false,
      // 展示筛选
      showVantPopoverScreen: false,
      // 展示周期
      showVantPickerCycle: false,
      // 展示部门
      showVantPickerDept: false,
      // 排序字段
      orderProp: '',
      // 排序类型
      orderType: ''
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    ...mapGetters(['isXs', 'isSm', 'isMd', 'isLg', 'isXl']),
    // 当前周期
    currentCycle() {
      return (
        this.cycleList.find((e) => e.cycle_id === this.currentCycleId) || {}
      )
    },
    // 月
    month() {
      if (!this.currentCycle.start_date) {
        return ''
      }
      const startDate = new Date(
        this.currentCycle.start_date.replaceAll('-', '/')
      )
      return this.$util.toDateString(startDate, 'yyyy/MM')
    },
    // 当前部门
    currentDept() {
      return this.deptList.find((e) => e.dept_id === this.currentDeptId) || {}
    },
    // 详情监听对象
    watchDetailIndexObj() {
      return {
        detail_report: this.detailReport,
        show_detail: this.showDetail
      }
    },
    // 职员id列表
    clerkIdList() {
      return this.dataList.map((e) => e.clerk_id)
    },
    // 报告id列表
    detailReportList() {
      return this.dataList.map((e) => {
        return {
          report_id: e.report_id,
          clerk_id: e.clerk_id
        }
      })
    },
    // vant选择周期内容列表
    vantPickerCycleColumns() {
      return this.cycleList.map((e) => {
        return `${this.$util.toDateString(
          e.start_date,
          'MM月dd日'
        )} - ${this.$util.toDateString(e.end_date, 'MM月dd日')}`
      })
    },
    // vant选择周期内容列表
    vantPickerDeptColumns() {
      return this.deptList.map((e) => {
        return e.dept_name
      })
    },
    dataList() {
      const list = [
        ...this.originalDataList.map((e) => {
          let taskCompleteRate = 0
          if (e.task_cycle_total_number > 0) {
            taskCompleteRate = this.$util.floatMul(
              this.$util.floatDiv(
                e.task_complete_number,
                e.task_cycle_total_number
              ),
              100
            )
            if (taskCompleteRate > 0) {
              taskCompleteRate = taskCompleteRate.toFixed(2)
            }
          }
          let objectiveCompleteRate = 0
          if (e.objective_cycle_total_number > 0) {
            objectiveCompleteRate = this.$util.floatMul(
              this.$util.floatDiv(
                e.objective_complete_number,
                e.objective_cycle_total_number
              ),
              100
            )
            if (objectiveCompleteRate > 0) {
              objectiveCompleteRate = objectiveCompleteRate.toFixed(2)
            }
          }
          return {
            ...e,
            task_complete_rate: taskCompleteRate,
            objective_complete_rate: objectiveCompleteRate
          }
        })
      ]
      if (this.orderType !== '') {
        list.sort((a, b) => {
          switch (this.orderType) {
            case 'ascending':
              return a[this.orderProp] - b[this.orderProp]
            case 'descending':
              return b[this.orderProp] - a[this.orderProp]
          }
        })
      }
      return list
    }
  },
  methods: {
    // 获取周期列表
    async getCycleList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportCyclePage, {
          cycle_type: 2
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.cycleList = res.page.list.map((e) => {
            return {
              ...e,
              start_date: e.start_date.split(' ')[0],
              end_date: e.end_date.split(' ')[0]
            }
          })
          if (res.page.list.length > 0) {
            this.currentCycleId = res.page.list[0].cycle_id
          }
        }
      }
    },
    // 获取部门列表
    async getDeptList() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportCycleDeptLeaderList, {
          clerk_id: this.clerkId,
          cycle_id: this.currentCycleId
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.deptList = res.list
          if (res.list.length > 0) {
            this.currentDeptId = res.list[0].dept_id
          } else {
            this.currentDeptId = null
          }
        }
      }
    },
    // 刷新周期列表
    async refreshCycleList() {
      this.getCycleList()
    },
    // 刷新部门列表
    async refreshDeptList() {
      this.getDeptList()
    },
    // 获取数据
    async getData() {
      if (this.currentDeptId == null || this.currentCycleId == null) {
        this.dataList = []
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getWorkReportDeptData, {
          dept_id: this.currentDeptId,
          cycle_id: this.currentCycleId
        })
      )
      if (err) {
        console.log(err)
      } else {
        if (res.state === 'ok') {
          this.originalDataList = res.list
        }
      }
    },
    // 刷新数据
    async refreshData() {
      this.getData()
    },
    // 监听行选择
    handlerRowClick(row) {
      this.$set(this, 'detailReport', {
        report_id: row.report_id,
        clerk_id: row.clerk_id
      })
      this.showDetail = true
    },
    // 返回
    back() {
      this.$router.push({
        name: 'more'
      })
    },
    // 监听筛选
    handlerVantPopoverScreen(data, index) {
      switch (index) {
        case 0: {
          this.showVantPickerDept = true
          break
        }
        case 1: {
          this.showVantPickerCycle = true
          break
        }
      }
    },
    // 监听排序
    handlerSortChange({ prop, order }) {
      this.orderProp = prop
      this.orderType = order
    }
  },
  mounted() {
    this.refreshCycleList()
  },
  watch: {
    currentCycleId() {
      this.currentDeptId = null
      this.refreshDeptList()
    },
    currentDeptId() {
      this.refreshData()
    },
    watchDetailIndexObj({
      detail_report: detailReport,
      show_detail: showDetail
    }) {
      if (showDetail) {
        this.$router.push({
          name: this.$route.name,
          params: {
            tab: 'dept',
            clerkReportId: `${detailReport.clerk_id}_${detailReport.report_id}`
          }
        })
      } else {
        this.$router.push({
          name: this.$route.name,
          params: {
            tab: 'dept'
          }
        })
      }
    },
    $route: {
      handler(val) {
        if (val.params.tab === 'dept') {
          const clerkReportId = val.params.clerkReportId
          if (clerkReportId != null && clerkReportId.indexOf('_') !== -1) {
            this.$set(this, 'detailReport', {
              report_id: Number(clerkReportId.split('_')[1]),
              clerk_id: Number(clerkReportId.split('_')[0])
            })
            this.showDetail = true
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dept-box {
  .el-card {
    box-shadow: 0px 1px 4px 0px rgba(31, 35, 41, 0.05);
  }
}
.table-box {
  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-right: 15px;
  }
  .el-tag {
    border: 0;
    height: 32px;
    width: 45px;
    line-height: 32px;
    text-align: center;
  }
}
.tip {
  font-size: 13px;
  line-height: 1.6;
  color: #1b1b1b;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .dept-box {
    padding: 50px 0 0 0;
    box-sizing: border-box;
    height: 100%;
    background-color: #f6f6f6;
  }
  .header-box {
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: 10;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #fff;
    .back-btn {
      height: 32px;
      width: 20px;
      line-height: 32px;
      margin-right: 5px;
      text-align: center;
      display: block;
    }
    .screen-btn {
      height: 32px;
      width: 32px;
      line-height: 32px;
      text-align: center;
      display: block;
    }
  }
  .list-box {
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    .list-item {
      width: 100%;
      padding: 15px 20px;
      margin-bottom: 10px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      .item-header {
        margin-bottom: 10px;
        .avatar {
          width: 35px;
          height: 35px;
          border-radius: 5px;
          margin-right: 15px;
        }
        .clerk-name {
          color: #333333;
        }
        .dept-name {
          color: #999999;
        }
        .right {
          .task,
          .target {
            margin-right: 20px;
            .number {
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 5px;
            }
            .text {
              color: #999999;
            }
          }
          .target .number {
            color: #5578eb;
          }
          .task .number {
            color: #00ca9d;
          }
        }
      }
      .column-list {
        width: 100%;
        padding: 0 15px;
        border-radius: 5px;
        background-color: #f4f4f4;
        box-sizing: border-box;
        .column-list-item {
          padding: 10px 0;
          width: 100%;
          border-bottom: 1px solid #d7d7d7;

          &:last-child {
            border-bottom: 0;
          }
          &.objective-complete-rate .value {
            color: #5578eb;
          }

          &.task-complete-rate .value {
            color: #00ca9d;
          }

          .name {
            color: #999999;
          }
          .value {
            color: #5578eb;
          }
        }
      }
    }
  }
}
</style>
