<!--
 * @Date: 2021-07-23 22:53:35
 * @LastEditTime: 2021-08-12 20:58:51
 * @Description: 动态交互栏
 * @Author: lyq
 * @LastEditors: hzh
-->
<template>
  <div class="flex interactive-bar">
    <!-- <div class="flex interactive-button" @click="handlerChange('browse')">
      <i class="icon-Show"></i>
      <span>{{ browseNum }}</span>
    </div> -->
    <div class="flex interactive-button" @click.stop="handlerChange('comment')">
      <i class="icon-comment"></i>
      <span>{{ commentNum }}</span>
    </div>
    <div class="flex interactive-button" @click="handlerChange('like')">
      <i :class="isPraise == 0 ? 'icon-heart' : 'icon-Heart_1'"></i>
      <span>{{ likeNum }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    browseNum: {
      type: [String, Number],
      default: 0
    },
    commentNum: {
      type: [String, Number],
      default: 0
    },
    likeNum: {
      type: [String, Number],
      default: 0
    },
    isPraise: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    handlerChange(type) {
      this.$emit('trigger', { type })
    }
  }
}
</script>

<style lang="scss" scoped>
.interactive-bar {
  justify-content: flex-end;
  margin-top: -10px;
  padding: 10px 0;
  color: #999;

  .interactive-button {
    cursor: pointer;
    margin-right: 22.75px;
    height: 21px;
    align-items: center;

    .icon-Heart_1 {
      color: red;
    }

    i {
      font-size: 20px;
      margin-right: 8px;
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      font-size: 14px;
    }
  }
}
</style>
