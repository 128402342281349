<template>
  <div class="search-item">
    <p class="search-type">{{ type | setType }}</p>
    <template v-if="type === 'dept'">
      <div
        class="search-dept active"
        v-for="item in dataList"
        :key="item.dept_id"
        @click="$emit('change', item)"
      >
        <i class="icon-Frame15 content-icon"></i>
        <span>{{ item.dept_name }}</span>
      </div>
    </template>
    <template v-if="type === 'people'">
      <div
        class="flex flex-y-center search-people"
        v-for="item in dataList"
        :key="item.clerk_id"
        @click="$emit('change', item)"
      >
        <el-image :src="item.clerk_avatar">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div>
          <p>{{ item.clerk_alias || item.clerk_name }}</p>
          <p>{{ item.main_dept_name }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'dept' // 部门 dept，员工 people
    },
    dataList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  filters: {
    setType(val) {
      if (val === 'dept') {
        return '部门'
      }

      if (val === 'people') {
        return '员工'
      }
    }
  }
}
</script>

<style lang="scss" scpoed>
.search-item {
  .search-type {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    background: #f6f6f6;
  }

  .search-dept {
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    .content-icon {
      margin-right: 10px;
    }
  }

  .search-people {
    height: 56px;
    cursor: pointer;
    .el-image {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      border-radius: 36px;
      margin-right: 10px;
    }

    p:last-child {
      font-size: 12px;
      color: #bbb;
    }
  }

  .search-dept,
  .search-people {
    padding: 0 20px;
    &.active {
      color: $main-color;
      background-color: rgba(0, 128, 255, 0.1);

      p {
        color: $main-color;
      }
    }
  }
}
</style>
