<!--
 * @Author: Betty
 * @Date: 2021-07-16 15:37:46
 * @LastEditors: hzh
 * @LastEditTime: 2021-11-23 17:38:59
 * @Description: 首页的单个任务
-->
<template>
  <!-- 每个任务 -->
  <div class="task-item">
    <!-- 它自己 -->
    <div
      :level="task.level"
      :class="{ over: task.task_status === 3 }"
      class="flex flex-y-center task-item-content"
    >
      <div
        class="task-item-name"
        :style="{ 'padding-left': 22 * (layer > 3 ? 3 : layer) + 28 + 'px' }"
      >
        <!-- 展开收起子元素 -->
        <span
          class="show-more iconfont icon-zhankai"
          @click.stop="toggleChildren"
          :class="{ active: isShowChild }"
          v-if="task.children && task.children.length > 0"
        ></span>
        <!-- 左边，复选框和任务名称 -->
        <label class="flex flex-y-center">
          <!-- 复选框，点击修改任务状态 -->
          <div class="check-box" @click="updateTaskState(task.task_id)">
            <svg
              class="icon checkbox-icon"
              aria-hidden="true"
              :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
            >
              <use
                :xlink:href="
                  task.task_status === 3
                    ? '#icon-TickSquare_1'
                    : '#icon-TickSquare'
                "
              ></use>
            </svg>
          </div>
          <input type="checkbox" :checked="task.task_status === 3" />
          <div class="task-left">
            <div
              class="task-name-box flex flex-y-center"
              v-if="!(Parent.editTaskId === task.task_id)"
            >
              <p
                class="task-name e"
                @click="showDetail(task.task_id)"
                :title="task.task_name"
              >
                <!-- 标签 -->
                <span
                  class="task-label"
                  v-if="infoLabel.gth > 0"
                  :style="{
                    color: '#FF5454',
                    background: '#fff',
                    fontWeight: '600',
                    fontSize: '15px'
                  }"
                  >{{ infoLabel.gthStr }}</span
                >
                <span
                  class="task-label"
                  v-for="(item, index) in infoLabel.list"
                  :key="index"
                  >{{ item.name }}</span
                >{{ infoLabel.showName }}
              </p>
              <template v-if="task.child_total_count > 0">
                <p class="child-info" v-if="timeoutCount > 0">
                  (<span style="color: red">{{ timeoutCount }}</span
                  >/{{ task.child_complete_count }}/{{
                    task.child_total_count
                  }})
                </p>
                <p class="child-info" v-else>
                  ({{ task.child_complete_count }}/{{ task.child_total_count }})
                </p>
              </template>
              <!-- <template> -->
            </div>
            <template v-if="Parent.editTaskId === task.task_id">
              <!-- 编辑任务名字 -->
              <input
                type="text"
                placeholder="请输入任务名称"
                :ref="`editTask${task.task_id}`"
                v-model="tempName"
                class="edit-input"
                @blur="handleEditTask"
                @keyup.13="toBlur($event)"
                @keyup.229="empty"
                @keyup.esc="
                  ;(isEscEdit = true), (escEditRef = `editTask${task.task_id}`)
                "
              />
            </template>
          </div>
        </label>
      </div>
      <div class="time-box hidden-xs-only" :class="{ 'is-late': isLate }">
        <!-- 中间，截止时间 -->
        <el-date-picker
          v-model="lastTime"
          align="right"
          :readonly="!isAllowUpdate"
          type="date"
          v-if="isAllowUpdate"
          placeholder=""
          size="small"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          @change="editLastTime"
        >
        </el-date-picker>
        <p
          v-if="!isAllowUpdate"
          :class="{ late: task.progress_status === 2 }"
          class="last-time-text"
        >
          {{ getLastTime }}
        </p>
      </div>
      <!-- 右边，任务的进度 -->
      <div
        class="hidden-xs-only flex flex-y-center progress-box flex-x-center"
        :style="{ cursor: isAllowUpdate ? 'pointer' : 'default' }"
        @click.stop="showTaskProgress(task, $event)"
      >
        <el-progress
          :color="task.progress_status === 1 ? '#0080FF' : '#FF5454'"
          type="circle"
          :percentage="task.progress_number"
          :width="20"
          :show-text="false"
          :stroke-width="2"
        ></el-progress>
        <span class="progress">{{ task.progress_number + '%' }}</span>
      </div>
      <!-- 更多按钮 -->
      <span
        class="iconfont icon-gengduo3 more-btn"
        v-if="isAllowUpdate"
        @click.stop="showTaskMore(task, $event)"
      ></span>
    </div>
    <!-- 它孩子 -->
    <div v-show="isShowChild">
      <!-- 每一个孩子的结构都是当前的结构 -->
      <div v-for="child in task.children" :key="child.task_id">
        <task-home-item
          :task="child"
          :layer="layer + 1"
          :currentPid="currentPid"
        ></task-home-item>
      </div>
      <!-- 孩子的最后，加上一行添加新任务的元素 -->
      <!-- 底部的新增任务的地方 -->
      <div class="task-add-box flex flex-y-center" v-if="isInputChildTask">
        <!-- 按钮 -->
        <!-- <button
          class="add-btn"
          type="button"
          v-show="!isInput"
          @click="beginInputChild"
        >
          <span class="iconfont icon-add add-icon"></span>
          添加任务
        </button> -->
        <!-- 输入框 -->
        <input
          type="text"
          class="task-input"
          placeholder="输入任务名称，按回车保存，按ESC取消"
          v-show="isInput"
          :ref="`${task.task_id}child`"
          v-model="childTask"
          @blur="handleInputTask(task.task_id)"
          @keyup.13="$event.target.blur(task.task_id)"
          @keyup.229="empty"
          @keyup.esc=";(isEscAdd = true), (escAddRef = `${task.task_id}child`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TaskHomeItem from './TaskHomeItem'
import { mapState } from 'vuex'
export default {
  name: 'task-home-item',
  components: { TaskHomeItem },
  props: {
    // 对应的任务
    task: {
      type: Object
    },
    // 任务的层级
    layer: {
      type: Number,
      default: 0
    },
    // 当前要给谁添加子元素
    currentPid: {
      type: Number,
      default: 0
    },
    // 当前时间
    nowTime: {
      type: Date
    },
    // 当前编辑的元素的id
    editTaskId: {
      type: Number
    }
  },
  mounted() {
    this.lastTime = this.task.end_date
  },
  data() {
    return {
      // 是否展示出孩子节点
      isShowChild: false,
      // 要添加的子任务的名字
      childTask: '',
      // 在展示出添加任务节点后，是否显示输入框
      isInput: false,
      // 临时输入的任务名
      tempName: '',
      // 当前任务的截止时间
      lastTime: '',
      // 快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '清空',
            onClick(picker) {
              picker.$emit('pick', '')
            }
          }
        ]
      },
      // 是否触发编辑esc
      isEscEdit: false,
      escEditRef: '',
      // 是否触发添加子集任务esc
      isEscAdd: false,
      escAddRef: ''
    }
  },
  computed: {
    ...mapState(['myClerkId']),
    // 解析标签
    infoLabel() {
      if (!this.task) {
        return {}
      }
      return this.parseLabel(this.task.task_name, this.task.task_label_list)
    },
    // 是否要添加子任务
    isInputChildTask() {
      if (this.currentPid === this.task.task_id) {
        return true
      } else {
        return false
      }
    },
    // 是否修改任务权限
    isAllowUpdate() {
      return (
        [this.task.create_clerk_id, this.task.director_clerk_id].indexOf(
          this.myClerkId
        ) !== -1
      )
    },
    // 输入框ref的名字
    editNameRef() {
      return `editTask${this.task.task_id}`
    },
    // 当前任务是否延期
    isLate() {
      return this.task.progress_status === 2
    },
    // 获取当前任务的截止时间
    getLastTime() {
      return this.task.end_time && this.task.end_time.slice(0, 10)
    },
    // 超时数量
    timeoutCount() {
      if (!this.task.children) {
        return 0
      }
      let len = 0
      console.log(this.task.children)
      const count = (list) => {
        list.forEach((e) => {
          if (e.progress_status === 2) {
            len++
          }
          if (e.children) {
            count(e.children)
          }
        })
      }
      count(this.task.children)
      return len
    }
  },
  watch: {
    // 如果要输入它的子任务，就先展开它的子任务
    isInputChildTask(newValue) {
      // 让子任务展示出来
      this.isShowChild = true
      // 直接让添加子任务的input聚焦
      this.beginInputChild()
    },
    // 让对应的input显示并聚焦
    editTaskId: {
      immediate: true,
      handler(newValue) {
        if (newValue === this.task.task_id) {
          this.tempName = this.task.task_name
          this.$nextTick(() => {
            this.$refs[this.editNameRef].focus()
          })
        }
      }
    },
    // 监听截止日期属性的改变
    'task.end_date'() {
      this.lastTime = this.task.end_date
    },
    isEscEdit(val) {
      if (val) {
        this.$refs[this.escEditRef].blur()
      }
    },
    isEscAdd(val) {
      if (val) {
        this.$refs[this.escAddRef].blur()
      }
    }
  },
  // 孙子节点接收爷爷provide中定义的test
  // inject: ['Parent', 'TaskBox'],
  inject: {
    Parent: {
      default: () => ({})
    },
    TaskBox: {
      default: () => ({})
    }
  },
  methods: {
    // 切换是否展示子节点
    toggleChildren() {
      console.log(1)
      this.isShowChild = !this.isShowChild
    },
    toBlur(e, args) {
      e.target.blur(args)
    },
    // 点击更多按钮
    showTaskMore(task, e) {
      // 获取我当前点击的是哪一个面板
      // 获取点击的位置的坐标
      const { pageX, pageY } = e
      // 调用父组件的相关方法
      this.Parent.showTaskMore({
        top: pageY + 10,
        left: pageX,
        id: task.task_id
      })
    },
    // 点击任务的进度
    showTaskProgress(task, e) {
      if (!this.isAllowUpdate) {
        return
      }
      console.log(e)
      // 获取点击的位置的坐标
      const { pageX, pageY } = e
      // 把点击的位置和任务数据传给父组件
      // 调用父组件的相关方法
      this.Parent.showTaskProgress({
        top: pageY + 10,
        left: pageX - 140,
        id: task.task_id,
        // 任务进行到百分之几
        progress: task.progress_number,
        // 任务是正常还是延期
        status: task.progress_status
      })
    },
    // 点击显示任务详情
    showDetail(id) {
      this.Parent.openDetailModal(id)
    },
    // 点击任务复选框，修改任务的状态
    async updateTaskState(id) {
      if (!this.isAllowUpdate) {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.modifyTaskStatus, {
          task_id: id,
          task_status: 3
        })
      )
      if (err) {
        console.log(err)
      } else {
        console.log(res)
        // 修改完以后，刷新任务列表
        this.Parent.refreshTaskList()
      }
    },
    // 显示输入子任务的输入框
    beginInputChild() {
      this.isInput = true
      this.$nextTick(() => {
        this.$refs[`${this.task.task_id}child`].focus()
      })
    },
    // 对子任务的处理
    async handleInputTask(id) {
      const childTask = this.childTask.trim()
      if (childTask.length === 0) {
        this.hideInputChild()
        this.isEscAdd = false
        return
      }
      if (this.isEscAdd) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.hideInputChild()
          this.isEscAdd = false
          return
        }
      }
      // 添加子任务
      this.$util.addChildTaskOf(id, childTask, this.nowTime).then((res) => {
        if (res) {
          this.hideInputChild()
          this.$message({
            showClose: true,
            message: '成功添加子任务',
            type: 'success'
          })
          // 刷新任务列表
          this.Parent.refreshTaskList()
        }
      })
      this.isEscAdd = false
    },
    // 隐藏子任务的输入框
    hideInputChild() {
      this.childTask = ''
      this.isInput = false
      // 调用父组件方法，去掉当前要添加子元素的任务id
      this.Parent.hindInputChild()
    },
    // 开始编辑任务名字
    beginEditName() {
      // 让对应的input聚焦
      this.$nextTick(() => {
        this.$refs[this.editNameRef].focus()
      })
    },
    // 处理对任务名的编辑
    async handleEditTask() {
      const val = this.tempName.trim()
      if (val.length === 0 || val === this.task.task_name) {
        this.cancelEditTask()
        this.isEscEdit = false
        return
      }
      if (this.isEscEdit) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.cancelEditTask()
          this.isEscEdit = false
          return
        }
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editTaskName, {
          task_id: this.task.task_id,
          task_name: val
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.cancelEditTask()
        this.$message({
          showClose: true,
          message: '任务名修改成功',
          type: 'success'
        })
        this.Parent.refreshTaskList()
      }
      this.isEscEdit = false
    },
    // 取消编辑
    cancelEditTask() {
      this.tempName = ''
      this.Parent.closeInputEdit()
    },
    // 准备编辑任务名
    readyEditTaskName() {
      this.$refs[`editTask${this.task.task_id}`].focus()
    },
    renderTime(v) {
      if (v === null) {
        return ''
      }
      return this.$util.getDateStr(new Date(v))
    },
    // 编辑截止日期
    async editLastTime() {
      if (this.lastTime === this.renderTime(this.task.end_time)) {
        return
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editEndTime, {
          task_id: this.task.task_id,
          end_time: this.lastTime
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$message({
          showClose: true,
          message: '截止时间修改成功！',
          type: 'success'
        })
        this.$set(this.task, 'end_time', this.lastTime)
      } else {
        this.lastTime = this.task.end_time
      }
    },
    // 空函数
    empty() {},
    // 解析标签
    parseLabel(name, labelList) {
      const labelMap = {}
      labelList.forEach((e) => {
        labelMap[e.label_name] = e.label_id
      })
      let showName = ''
      const list = []
      let status = 0
      let gth = 0
      let item = ''
      name.split('').forEach((e) => {
        let te = e
        // 处理下中文符号
        switch (te) {
          case '！': {
            te = '!'
          }
        }
        switch (status) {
          // 开始
          case 0: {
            switch (te) {
              case '!': {
                if (gth === 0) {
                  gth++
                  status = 1
                } else {
                  showName += e
                  status = 11
                }
                break
              }
              case ' ': {
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                showName += e
                status = 11
                break
              }
            }
            break
          }
          case 1: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              case '#': {
                status = 10
                break
              }
              default: {
                status = 0
                showName += e
                break
              }
            }
            break
          }
          case 2: {
            switch (te) {
              case '!': {
                gth++
                break
              }
              default: {
                status = 11
                showName += e
                break
              }
            }
            break
          }
          case 10: {
            switch (te) {
              case '!': {
                if (item !== '') {
                  list.push(item)
                } else {
                  showName += '#'
                }
                item = ''
                if (gth === 0) {
                  gth++
                  status = 2
                } else {
                  status = 11
                }
                break
              }
              case ' ': {
                if (item !== '') {
                  list.push(item)
                }
                item = ''
                status = 0
                break
              }
              default: {
                item += e
                break
              }
            }
            break
          }
          // 直接加
          case 11: {
            showName += e
            break
          }
        }
      })
      if (status === 10) {
        if (item.length > 0) {
          list.push(item)
        }
      }
      let gthStr = ''
      for (let i = 0; i < gth; i++) {
        gthStr += '!'
      }
      return {
        showName,
        list: list.map((e) => {
          return {
            id: labelMap[e] || '',
            name: e
          }
        }),
        gth,
        gthStr
      }
    }
  }
}
</script>

<style lang="scss">
// 导入输入任务的样式
@import '@/assets/scss/input_task.scss';
// 每一个任务的样式
.task-item {
  position: relative;
  overflow: visible !important;
  width: 100%;
  height: auto;
  // 展示更多的箭头
  .show-more {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 16px;
    height: 16px;
    font-size: 16px;
    // font-weight: 700;
    color: #999;
    cursor: pointer;
    transform-origin: center;
    transform: rotate(-90deg);
    &.active {
      transform: rotate(0deg);
    }
  }
}

// 任务的内容区域
.task-item-content {
  position: relative;
  z-index: 0;
  height: 48px;
  padding-right: 0;
  box-sizing: border-box;
  > div:first-child {
    width: 76%;
    position: relative;
    box-sizing: border-box;
  }
  &.child {
    padding-left: 52px;
  }
  // 更多按钮，悬浮任务时才显示
  .more-btn {
    position: absolute;
    top: 50%;
    right: 2px;
    line-height: 1;
    display: none;
    font-size: 20px;
    // font-weight: 700;
    cursor: pointer;
    color: #999;
    transform-origin: center;
    transform: translateY(-50%);
  }
  &:hover .more-btn {
    display: block;
  }
}

// 复选框
.checkbox-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
}
input[type='checkbox'] {
  width: 0;
  height: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
// 任务名字
.task-name {
  display: inline-block;
  cursor: pointer;
  width: 80%;
  margin-left: 10px;
  vertical-align: middle;
  color: #333;
}
// 任务进度
.progress {
  display: block;
  width: 45px;
  text-align: right;
  color: #333;
}

.edit-input {
  display: inline-block;
  width: 200px;
  height: 32px;
  margin-left: 10px;
  outline: none;
  padding: 4px 10px;
  line-height: 24px;
  border: 1px solid $main-color;
  border-radius: 4px;
  box-sizing: border-box;
}

.icon-add .add-icon {
  font-size: 14px;
  color: #999;
}

.icon.checkbox-icon {
  width: 20px;
  height: 20px;
}

// 任务的更多弹窗
.task-more-box {
  position: absolute;
  z-index: 4;
  top: 44px;
  left: 95%;
  width: 140px;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(153, 153, 153, 0.2);
  border-radius: 4px;
  .more-box-item {
    padding: 0 18px;
    height: 40px;
    line-height: 40px;
    color: #333;
    cursor: pointer;
    &.red {
      color: $red;
    }
    &:hover {
      background: #f6f6f6;
    }
  }
}

// 截至时间
.task-item .time-box {
  width: 10%;
  text-align: center;
  .task-date {
    font-size: 14px;
    line-height: 1.5;
  }
}

.task-item .progress-box {
  width: 14%;
}

// 悬浮变灰
.task-item-content:hover {
  background: $light-grey-bg;
}
@media screen and (max-width: 767px) {
  .task-item-content {
    > div:first-child {
      width: 100%;
      position: relative;
      box-sizing: border-box;
    }

    .time-box {
      width: 100px;
    }
  }
}
</style>
<style lang="scss" scoped>
.last-time-text {
  font-size: 13px;
  &.late {
    color: $red;
  }
}
.task-item ::v-deep .el-date-editor {
  width: 100%;
}

.task-item ::v-deep .el-date-editor .el-input__inner {
  padding: 0;
  text-align: center;
  width: 100%;
}

.task-item ::v-deep .el-input__icon {
  display: none;
}

.time-box.is-late ::v-deep .el-date-editor .el-input__inner {
  color: $red;
}

.task-item:hover .task-left p {
  // color: $main-color;
}

// 任务名样式调整
.task-left {
  width: calc(100% - 40px);
  // 显示任务名和子任务的完成情况
  .task-name-box {
    max-width: 100%;
    width: min-content;
    p {
      font-size: 14px;
      line-height: 1.5;
      color: #333;
    }
    .task-name {
      flex: 1;
    }
    .child-info {
      margin-left: 5px;
    }
  }
}
.time-box ::v-deep {
  .el-input--prefix .el-input__inner {
    border: 1px solid #fff;
  }
  .el-input--prefix .el-input__inner:hover,
  .el-input--prefix .el-input__inner:focus {
    border-color: $main-color;
  }
}

// 任务的标签
.task-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: min-content;
  padding: 0 3px;
  white-space: nowrap;
  line-height: 21px;
  color: #2b6cff;
  background: #e2ebff;
  border-radius: 3px;
  font-size: 12px;
}
</style>
