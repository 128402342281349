<template>
  <div>
    <message-dialog visible @close="$emit('cancel')" @click="clickBody()">
      <template #header>
        <div class="dialog-title">权限管理</div>
      </template>
      <template #content>
        <p>允许成员新建、编辑、删除、共享文件，管理团队空间知识库。</p>
        <div
          class="flex flex-x-between"
          style="padding-top: 20px; margin-bottom: 16px"
        >
          <span>团队成员</span>
          <span
            class="add-button"
            v-if="!teamDept.dept_id"
            @click="addMemberVisible = true"
            >添加成员</span
          >
        </div>
        <div class="list-container" v-loading="loading">
          <el-input
            prefix-icon="el-icon-search"
            placeholder="请输入团队成员名称搜索"
            v-if="false"
          ></el-input>
          <div style="width: 100%">
            <el-autocomplete
              popper-class="search-input"
              size="medium"
              v-model="searchClerkName"
              :fetch-suggestions="querySearch"
              placeholder="请输入姓名/别名"
              @select="handleSelect"
              style="width: 100%"
            >
              <i class="el-icon-search el-input__icon" slot="prefix"> </i>
              <template #default="{ item }">
                <div class="flex flex-y-center result-item">
                  <el-image :src="item.clerk_avatar">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <span class="name">{{
                    item.clerk_alias || item.clerk_name
                  }}</span>
                  <span class="type">{{ item.main_dept_name }}</span>
                </div>
              </template>
            </el-autocomplete>
          </div>
          <div class="list-content">
            <structure-tree
              :data-list="dataList"
              :menuList="menuList"
              :skipDeleteDeptIdList="skipDeleteDeptIdList"
              :skipDeleteTopDeptIdList="skipDeleteTopDeptIdList"
              :skipDeleteClerkIdList="skipDeleteClerkIdList"
              :adminClerkIdList="adminClerkIdList"
              :searchClerkIdList="searchClerkIdList"
              @delete="handleDelete"
              @change="handleChange"
            ></structure-tree>
          </div>
        </div>
      </template>
    </message-dialog>
    <add-member-and-dept
      @close-add-member="addMemberVisible = false"
      :isShowAddMember="addMemberVisible"
      :dataList="clerkTree"
      @confirm-add-member="chooseMember"
    ></add-member-and-dept>
  </div>
</template>

<script>
import AddMemberAndDept from '@/components/common/AddMemberAndDept.vue'
import MessageDialog from '@/components/circle/MessageDialog.vue'
import StructureTree from './StructureTree.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    MessageDialog,
    StructureTree,
    AddMemberAndDept
  },
  props: {
    folderId: {
      type: Number
    }
  },
  data() {
    return {
      info: {},
      // 禁止职员id列表
      disableClerkIdList: [],
      // 禁止部门id列表
      disableDeptIdList: [],
      // 职员信息
      clerkList: [],
      // 部门信息
      deptList: [],
      // 添加展示
      addMemberVisible: false,
      // 职员数据
      clerkTree: [],
      menuList: [
        {
          title: '管理员',
          info: '可修改成员权限及编辑文件',
          value: 4
        },
        {
          title: '仅查看',
          info: '可查看、不可下载文件',
          value: 1
        },
        {
          title: '可下载',
          info: '可查看、下载文件',
          value: 2
        },
        {
          title: '可编辑',
          info: '可编辑、更新、查看、下载文件',
          value: 3
        }
      ],
      dataList: [],
      loading: false,
      // 团队部门
      teamDept: {},
      // 团队职员
      teamClerk: {},
      // 跳过删除
      skipDeleteDeptIdList: [],
      skipDeleteTopDeptIdList: [],
      skipDeleteClerkIdList: [],
      // 管理员职员
      adminClerkList: [],
      // 搜索职员名称
      searchClerkName: '',
      searchClerkInfo: {}
    }
  },
  computed: {
    adminClerkIdList() {
      return this.adminClerkList.map((e) => e.clerk_id)
    },
    clerkIdList() {
      return this.clerkList.map((e) => e.clerk_id) || []
    },
    clerkMap() {
      const map = {}
      this.clerkList.forEach((e) => {
        map[e.clerk_id] = e
      })
      return map
    },
    deptIdList() {
      return this.deptList.map((e) => e.dept_id) || []
    },
    deptMap() {
      const map = {}
      this.deptList.forEach((e) => {
        map[e.dept_id] = e
      })
      return map
    },
    menuIndexMap() {
      const map = {}
      this.menuList.forEach((e, i) => {
        map[e.value] = i
      })
      return map
    },
    searchClerkId() {
      if (this.searchClerkInfo.clerk_id) {
        return this.searchClerkInfo.clerk_id
      }
      return 0
    },
    searchClerkIdList() {
      if (this.searchClerkId !== 0) {
        return [this.searchClerkId]
      }
      return []
    },
    allClerkList() {
      const list = []
      const idList = []
      // 管理员
      this.adminClerkList.forEach((e) => {
        idList.push(e.clerk_id)
        list.push({
          clerk_id: e.clerk_id,
          clerk_name: e.clerk_name,
          clerk_status: e.clerk_status,
          clerk_avatar: e.clerk_avatar,
          clerk_alias: e.clerk_alias,
          clerk_mobile: e.clerk_mobile,
          clerk_name_py: e.clerk_name_py,
          clerk_alias_py: e.clerk_alias_py,
          clerk_type: e.clerk_type
        })
      })
      // 职员
      this.clerkList.forEach((e) => {
        if (!idList.includes(e.clerk_id)) {
          idList.push(e.clerk_id)
          list.push({
            clerk_id: e.clerk_id,
            clerk_name: e.clerk_name,
            clerk_status: e.clerk_status,
            clerk_avatar: e.clerk_avatar,
            clerk_alias: e.clerk_alias,
            clerk_mobile: e.clerk_mobile,
            clerk_name_py: e.clerk_name_py,
            clerk_alias_py: e.clerk_alias_py,
            clerk_type: e.clerk_type
          })
        }
      })

      const eachDept = (dept) => {
        dept.clerk_list.forEach((e) => {
          if (!idList.includes(e.clerk_id)) {
            idList.push(e.clerk_id)
            list.push({
              clerk_id: e.clerk_id,
              clerk_name: e.clerk_name,
              clerk_status: e.clerk_status,
              clerk_avatar: e.clerk_avatar,
              clerk_alias: e.clerk_alias,
              clerk_mobile: e.clerk_mobile,
              clerk_name_py: e.clerk_name_py,
              clerk_alias_py: e.clerk_alias_py,
              clerk_type: e.clerk_type
            })
          }
        })
        if (dept.children) {
          dept.children.forEach((e) => {
            eachDept(e)
          })
        }
      }
      this.deptList.forEach((e) => {
        eachDept(e)
      })
      return list
    }
  },
  methods: {
    ...mapMutations(['clickBody']),
    // 渲染data列表
    renderDataList() {
      let list = []
      // 管理员
      if (this.teamDept.dept_id) {
        list = list.concat(
          this.adminClerkList.map((e) => {
            return {
              id: `c_${e.clerk_id}`,
              type: 1,
              active: this.menuIndexMap[1],
              per_type: 1,
              clerk_id: e.clerk_id,
              clerk_name: e.clerk_name,
              clerk_avatar: e.clerk_avatar,
              clerk_alias: e.clerk_alias
            }
          })
        )
      }
      // 职员
      list = list.concat(
        this.clerkList
          .filter((e) => {
            return !this.adminClerkIdList.includes(e.clerk_id)
          })
          .map((e) => {
            return {
              id: `c_${e.clerk_id}`,
              type: 1,
              active: this.menuIndexMap[e.per_type],
              per_type: e.per_type,
              clerk_id: e.clerk_id,
              clerk_name: e.clerk_name,
              clerk_avatar: e.clerk_avatar,
              clerk_alias: e.clerk_alias
            }
          })
      )
      // 部门
      // 获取子级列表
      const getDeptChildren = (e) => {
        let clist = []
        // 获取职员列表
        const getClerkList = (clerkList, perType) => {
          return clerkList
            .filter((fe) => !this.disableClerkIdList.includes(fe.clerk_id))
            .map((cce) => {
              const info = {
                id: `dc_${cce.clerk_id}`,
                type: 1,
                clerk_id: cce.clerk_id,
                clerk_name: cce.clerk_name,
                clerk_avatar: cce.clerk_avatar,
                clerk_alias: cce.clerk_alias
              }
              // 有独立设置的读取独立设置
              if (this.clerkMap[cce.clerk_id] != null) {
                info.active =
                  this.menuIndexMap[this.clerkMap[cce.clerk_id].per_type]
                info.per_type = this.clerkMap[cce.clerk_id].per_type
              } else {
                info.active = this.menuIndexMap[perType]
                info.per_type = perType
              }
              return info
            })
        }
        // 部门下的职员
        if (e.clerk_list) {
          clist = clist.concat(getClerkList(e.clerk_list, e.per_type))
        }
        // 有下级部门的
        if (e.children) {
          const getChildrenDeptLoop = (pid, perType) => {
            const dlist = e.children
              .filter((fe) => fe.pid === pid)
              .map((fe) => {
                const info = {
                  id: `d_${fe.dept_id}`,
                  type: 2,
                  dept_id: fe.dept_id,
                  dept_name: fe.dept_name
                }
                // 有独立设置的读取独立设置
                if (this.deptMap[fe.dept_id] != null) {
                  info.active =
                    this.menuIndexMap[this.deptMap[fe.dept_id].per_type]
                  info.per_type = this.deptMap[fe.dept_id].per_type
                } else {
                  info.active = this.menuIndexMap[perType]
                  info.per_type = perType
                }
                // 下属
                let cclist = []
                if (fe.clerk_list) {
                  cclist = cclist.concat(
                    getClerkList(fe.clerk_list, info.per_type)
                  )
                }
                if (e.children.findIndex((e) => e.pid === fe.dept_id) !== -1) {
                  cclist = cclist.concat(
                    getChildrenDeptLoop(fe.dept_id, info.per_type)
                  )
                }
                if (cclist.length > 0) {
                  info.children = cclist
                }
                return info
              })
            return dlist
          }
          clist = clist.concat(getChildrenDeptLoop(e.dept_id, e.per_type))
        }
        return clist
      }
      list = list.concat(
        this.deptList.map((e) => {
          const info = {
            id: `d_${e.dept_id}`,
            type: 2,
            active: this.menuIndexMap[e.per_type],
            per_type: e.per_type,
            dept_id: e.dept_id,
            dept_name: e.dept_name
          }
          const clist = getDeptChildren(e)
          if (clist.length > 0) {
            info.children = clist
          }
          return info
        })
      )
      this.$set(this, 'dataList', list)
    },
    // 获取详情
    async getDetail() {
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getKnowlFolderTeamPerDetail, {
          folder_id: this.folderId
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        this.$set(this, 'clerkList', res.clerk_list)
        this.$set(this, 'deptList', res.dept_list)
        this.$set(this, 'disableClerkIdList', res.disable_clerk_id_list)
        this.teamDept = res.team_dept || {}
        this.teamClerk = res.team_clerk || {}
        this.adminClerkList = res.admin_clerk_list
        if (this.teamDept.dept_id) {
          this.skipDeleteDeptIdList = [this.teamDept.dept_id]
          this.skipDeleteTopDeptIdList = [this.teamDept.dept_id]
        }
        if (this.teamClerk.clerk_id) {
          this.skipDeleteClerkIdList = [this.teamClerk.clerk_id]
        }
        this.renderDataList()
      }
    },
    // 获取职员列表
    async getClerkTree() {
      this.$util.getClerkTree().then((res) => {
        this.clerkTree = res
      })
    },
    // 选择职员
    async chooseMember({ clerkIdList, deptIdList }) {
      console.log(clerkIdList)
      console.log(deptIdList)
      // 职员
      // const clerkList = this.clerkList.map((e) => {
      //   return {
      //     clerk_id: e.clerk_id,
      //     per_type: e.per_type
      //   }
      // })
      // clerkIdList.forEach((e) => {
      //   if (!this.clerkIdList.includes(e)) {
      //     clerkList.push({
      //       clerk_id: e,
      //       per_type: 1
      //     })
      //   }
      //   if (this.disableClerkIdList.includes(e)) {
      //     this.disableClerkIdList.splice(this.disableClerkIdList.indexOf(e, 1))
      //   }
      // })

      // // 部门
      // const deptList = this.deptList.map((e) => {
      //   return {
      //     dept_id: e.dept_id,
      //     per_type: e.per_type
      //   }
      // })
      // deptIdList.forEach((e) => {
      //   if (!this.deptIdList.includes(e)) {
      //     deptList.push({
      //       dept_id: e,
      //       per_type: 1
      //     })
      //   }
      // })

      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addKnowlFolderTeamPer, {
          folder_id: this.folderId,
          clerk_ids: clerkIdList.join(),
          dept_ids: deptIdList.join()
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // // 职员信息
        // this.clerkList = []
        // // 部门信息
        // this.deptList = []
        await this.getDetail()
        this.$forceUpdate()
      }
      this.addMemberVisible = false
    },
    // 删除
    async handleDelete({ info, disable }) {
      // 职员
      // const clerkList = this.clerkList
      //   .filter((e) => {
      //     if (info.type === 1 && e.clerk_id === info.clerk_id) {
      //       return false
      //     }
      //     return true
      //   })
      //   .map((e) => {
      //     return {
      //       clerk_id: e.clerk_id,
      //       per_type: e.per_type
      //     }
      //   })

      // if (info.type === 1 && !this.disableClerkIdList.includes(info.clerk_id)) {
      //   this.disableClerkIdList.push(info.clerk_id)
      // }

      // // 部门
      // const deptList = this.deptList
      //   .filter((e) => {
      //     if (info.type === 2 && e.dept_id === info.dept_id) {
      //       return false
      //     }
      //     return true
      //   })
      //   .map((e) => {
      //     return {
      //       dept_id: e.dept_id,
      //       per_type: e.per_type
      //     }
      //   })

      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.deleteKnowlFolderTeamPer, {
          folder_id: this.folderId,
          clerk_ids: info.type === 1 ? info.clerk_id : '',
          dept_ids: info.type === 2 ? info.dept_id : '',
          disable: disable ? '1' : ''
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // // 职员信息
        // this.clerkList = []
        // // 部门信息
        // this.deptList = []
        await this.getDetail()
        this.$forceUpdate()
      }
    },
    // 更新
    async handleChange({ info, index }) {
      // 职员
      // const clerkList = this.clerkList.map((e) => {
      //   const cinfo = { clerk_id: e.clerk_id }
      //   if (info.type === 1 && e.clerk_id === info.clerk_id) {
      //     cinfo.per_type = this.menuList[index].value
      //   } else {
      //     cinfo.per_type = e.per_type
      //   }
      //   return cinfo
      // })
      // // 新增单独职员
      // if (info.type === 1 && this.clerkMap[info.clerk_id] == null) {
      //   clerkList.push({
      //     clerk_id: info.clerk_id,
      //     per_type: this.menuList[index].value
      //   })
      // }
      // // 部门
      // const deptList = this.deptList.map((e) => {
      //   const cinfo = { dept_id: e.dept_id }
      //   if (info.type === 2 && e.dept_id === info.dept_id) {
      //     cinfo.per_type = this.menuList[index].value
      //   } else {
      //     cinfo.per_type = e.per_type
      //   }
      //   return cinfo
      // })
      // // 新增单独部门
      // if (info.type === 2 && this.deptMap[info.dept_id] == null) {
      //   deptList.push({
      //     dept_id: info.dept_id,
      //     per_type: this.menuList[index].value
      //   })
      // }

      const clerkList = []
      const deptList = []
      if (info.type === 1) {
        clerkList.push({
          clerk_id: info.clerk_id,
          per_type: this.menuList[index].value
        })
      }
      if (info.type === 2) {
        deptList.push({
          dept_id: info.dept_id,
          per_type: this.menuList[index].value
        })
      }
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.editKnowlFolderTeamPer, {
          folder_id: this.folderId,
          clerk_list: JSON.stringify(clerkList),
          dept_list: JSON.stringify(deptList)
        })
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        // this.disableClerkIdList = []
        // this.clerkList = []
        // this.deptList = []
        await this.getDetail()
        this.$forceUpdate()
      }
    },
    async querySearch(quertString, cb) {
      if (quertString === '') {
        const empty = []
        cb(empty)
        return
      }
      // const [, res] = await this.$util.to(
      //   this.$http.post(this.$api.searchKnowlFolderTeamPerClerk, {
      //     folder_id: this.folderId,
      //     search_key: quertString
      //   })
      // )
      cb(
        this.allClerkList.filter((e) => {
          if (e?.clerk_name?.indexOf(quertString) !== -1) {
            return true
          }
          if (e?.clerk_name_py?.indexOf(quertString) !== -1) {
            return true
          }
          if (e?.clerk_alias?.indexOf(quertString) !== -1) {
            return true
          }
          if (e?.clerk_alias_py?.indexOf(quertString) !== -1) {
            return true
          }
          return false
        })
      )
    },
    handleSelect(item) {
      this.searchClerkName = item.clerk_alias || item.clerk_name
      this.searchClerkInfo = item
    }
  },
  mounted() {
    this.getDetail()
    this.getClerkTree()
  },
  watch: {
    searchClerkName(val) {
      if (this.searchClerkName === '') {
        this.searchClerkInfo = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.dialog-sub-title {
  color: #bbb;
}

.add-button {
  cursor: pointer;
  color: $main-color;
}

.list-container {
  background-color: #f6f6f6;
  border-radius: 6px;
  height: calc(100vh - 320px);
  margin-bottom: 20px;
  padding: 18px 24px 0;

  .list-content {
    height: calc(100% - 40px);
    overflow: scroll;
  }

  .el-input {
    margin-bottom: 8px;
  }
}

.el-button {
  width: 84px;
}

.result-item {
  height: 55px;
  .el-image {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    margin-right: 16px;
  }

  .name {
    margin-right: 20px;
  }

  .type {
    color: #bbb;
  }
}
</style>
