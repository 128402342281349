<!--
 * @Author: hzh
 * @Date: 2021-08-16 16:48:53
 * @LastEditors: hzh
 * @LastEditTime: 2021-08-16 17:32:24
-->
<template>
  <div class="comment-total-bar" @click="$emit('open')">
    <span>{{ lastestName }}</span>
    等人
    <span>共{{ replyNumber }}条回复</span>
    <i class="icon-zhankai" ></i>
  </div>
</template>

<script>
export default {
  props: {
    lastestName: {
      type: String,
      default: ''
    },
    replyNumber: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-total-bar {
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  margin-top: 14px;

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 2px;
    background-color: $main-color;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .icon-zhankai {
    margin-left: 10px;
    color: $main-color;
  }

  span {
    color: $main-color;
  }
}

@media screen and (max-width: 767px) {
  .comment-total-bar {
    margin-top: 5px;
  }
}
</style>
