<!--
 * @Author: Betty
 * @Date: 2021-07-16 14:06:32
 * @LastEditors: Betty
 * @LastEditTime: 2021-08-14 16:16:06
 * @Description: 首页任务组件外层
-->
<template>
  <div class="target-box white-box">
    <!-- 盒子的头部 -->
    <div class="flex flex-y-center white-box-header flex-x-between">
      <!-- 左边 -->
      <div class="flex flex-y-center">
        <h6 class="white-box-title">任务</h6>
        <p class="count">共{{ taskLength }}个</p>
      </div>
      <!-- 右边 -->
      <div class="flex flex-y-center header-right">
        <!-- <router-link class="more" :to="`/workspace?tab=task`">MORE</router-link> -->
        <button type="button" class="more-btn" @click.stop="toFresh">
          <span class="iconfont icon-gengduo2"></span>
        </button>
        <!-- 更多弹窗 -->
        <div class="more-box refresh" v-show="isShowFresh">
          <div class="more-item" @click="fresh">刷新</div>
        </div>
      </div>
    </div>
    <!-- 主体部分，任务数据 -->
    <div class="main-box">
      <div class="main-box-header flex flex-y-center hidden-xs-only">
        <div class="main-header-item name-box task-name-box">任务名称</div>
        <div class="main-header-item time-box">截止时间</div>
        <div class="main-header-item progress-box">完成进度</div>
      </div>
      <!-- 下面的每一行 -->
      <div class="target-data-box">
        <!-- 里层任务组件 -->
        <task-home-item
          v-for="(task, index) in taskTree"
          :key="index"
          :task="task"
          :currentPid="Parent.currentPid"
          :editTaskId="Parent.editTaskId"
          :nowTime="nowTime"
        ></task-home-item>
      </div>
    </div>
    <!-- 下面的添加目标 -->
    <!-- 底部的新增任务的地方 -->
    <div class="task-add-box flex flex-y-center">
      <!-- 按钮 -->
      <button
        class="add-btn"
        type="button"
        v-show="!isInputTask"
        @click="beginInput"
      >
        <span class="iconfont icon-add add-icon"></span>
        添加任务
      </button>
      <!-- 输入框 -->
      <input
        type="text"
        class="task-input"
        placeholder="输入任务名称，按回车保存，按ESC取消"
        v-show="isInputTask"
        ref="taskInput"
        v-model.trim="newTask"
        @blur="handleInputTask"
        @keyup.13="toBlur($event)"
        @keyup.229="() => {}"
        @keyup.esc="isEscShow = true"
      />
    </div>
  </div>
</template>

<script>
import TaskHomeItem from './TaskHomeItem.vue'
export default {
  name: 'task-home-box',
  components: {
    TaskHomeItem
  },
  props: {
    // 当前时间
    nowTime: {
      type: Date
    },
    // 父组件当前选中的任务id
    currentTask: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // 任务的数量（任务的原始数量）
      taskLength: 0,
      // 是否显示刷新弹窗
      isShowFresh: false,
      // 是否要输入任务
      isInputTask: false,
      // 输入的新任务
      newTask: '',
      // 树形任务
      taskTree: [],
      // 记录数据
      dataInfo: null,
      // 是否触发添加esc
      isEscShow: false
    }
  },
  mounted() {
    this.getTaskList()
    document.addEventListener('click', this.clickOut)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOut)
  },
  inject: ['Parent'],
  methods: {
    // 回车触发失去焦点的事件
    toBlur(e, args) {
      e.target.blur(args)
    },
    // 显示刷新弹窗
    toFresh() {
      this.isShowFresh = true
    },
    // 刷新
    fresh() {
      this.isShowFresh = false
      this.getTaskList()
    },
    // 获取任务列表
    async getTaskList() {
      // 请求任务列表
      const formObj = {
        // 设置任务的状态
        is_complete: 0
      }
      console.log(formObj)
      // 请求任务列表
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.getTaskList, formObj)
      )
      // 获取请求结果
      let taskListData
      // 延期数量
      let lateCount
      // 树形结果
      let treeList = []
      if (err) {
        console.log(err)
      } else {
        taskListData = res.list
        if (taskListData && taskListData.length > 0) {
          // 在这里计算出延期任务有几个
          lateCount = taskListData.filter(
            (item) => item.progress_status === 2
          ).length
          // 把它整理成树形
          treeList = this.$util.handleTreeData(taskListData)
        }
      }
      this.taskLength = (taskListData && taskListData.length) || 0
      this.taskTree = treeList
      const result = {
        taskListData,
        treeList,
        length: (taskListData && taskListData.length) || 0,
        lateCount
      }
      // 把结果传递给父级
      this.$emit('load-task', result)
      this.dataInfo = result
      return result
    },
    // 获取任务数据
    getData() {
      return this.dataInfo
    },
    // 开始输入任务
    beginInput() {
      this.isInputTask = true
      this.$nextTick(() => {
        if (this.$refs.taskInput) {
          this.$refs.taskInput.focus()
        }
      })
    },
    // 处理输入的函数
    async handleInputTask() {
      if (this.newTask.length === 0) {
        this.hideInputTask()
        this.isEscShow = false
        return
      }
      if (this.isEscShow) {
        try {
          await this.$confirm('当前编辑未保存，请确定是否保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        } catch (error) {
          this.hideInputTask()
          this.isEscShow = false
          return
        }
      }
      // 提交请求，添加任务
      await this.addNewTask(this.newTask, this.nowTime)
      this.hideInputTask()
      this.getTaskList()
      this.isEscShow = false
    },
    // 添加新任务
    async addNewTask(taskName, firstDate) {
      // 把日期转成字符串，然后发起请求提交
      const formObj = {
        'task.task_name': taskName,
        'task.period_date': this.$util.getDateStr(firstDate),
        is_add_first_group: 1
      }
      console.log('要添加任务了', formObj)
      const [err, res] = await this.$util.to(
        this.$http.post(this.$api.addNewTask, formObj)
      )
      if (err) {
        console.log(err)
      } else if (res.state === 'ok') {
        console.log(res)
        // 添加成功，给出提示
        this.$message({
          type: 'success',
          message: '添加任务成功!',
          showClose: true
        })
        // 刷新任务列表
        this.getTaskList()
      }
    },
    // 取消输入
    hideInputTask() {
      this.newTask = ''
      this.$refs.taskInput.blur()
      this.isInputTask = false
    },
    // 删除任务
    removeTask(id) {
      this.$util.showConfirm('确定删除这个任务吗？').then(async (result) => {
        if (result) {
          const [err, res] = await this.$util.to(
            this.$http.post(this.$api.removeTask, {
              task_id: id
            })
          )
          if (err) {
            console.log(err)
          } else if (res.state === 'ok') {
            // 删除成功，给出提示
            this.$message({
              type: 'success',
              message: '删除任务成功!',
              showClose: true
            })
            // 刷新任务列表
            this.getTaskList()
          }
        }
      })
    },
    clickOut() {
      this.isShowFresh = false
    }
  },
  watch: {
    isEscShow(val) {
      if (val) {
        this.$refs.taskInput.blur()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/home_target_style.scss';
</style>
