/*
 * @Date: 2021-05-24 15:17:00
 * @LastEditTime: 2022-01-11 17:20:27
 * @Description: 路由配置文件
 * @Author: Betty
 * @LastEditors: hzh
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import Home from '../views/Home.vue'
import Click from '../views/Click/index.vue'
import Index from '../views/Index.vue'
import TaskList from '../views/TaskList.vue'
import Meeting from '../views/Meeting.vue'
import Circle from '../views/Circle.vue'
import CircleIndex from '../views/Circle/index.vue'
import TopicInfo from '../views/TopicInfo.vue'
import MemberHome from '../views/Member/Home.vue'
import MemberIndex from '../views/Member/Index.vue'
import MemberAttention from '../views/Member/Attention.vue'
import MemberFans from '../views/Member/Fans.vue'
import MemberMessage from '../views/Member/Message.vue'
import MemberSet from '../views/Member/Set.vue'
import MemberLike from '../views/Member/Like.vue'
import MemberInfo from '../views/Member/Info.vue'
import TaskDetailPage from '../views/TaskDetailPage'
import TargetDetailPage from '../views/TargetDetailPage'
import Knowledge from '../views/Knowledge'
import SystemIndex from '../views/System/index.vue'
import SystemAchitecture from '../views/System/architecture.vue'
import SystemNotice from '../views/System/notice.vue'
import SystemCirclePush from '../views/System/CirclePush.vue'
import SystemManagement from '../views/System/Management.vue'
import SystemKnowledge from '../views/System/Knowledge.vue'
import AttachmentPreview from '../views/AttachmentPreview.vue'
import More from '../views/More.vue'
import WorkReportWeek from '../views/WorkReport/Week.vue'
import WorkReportWeekDetail from '../views/WorkReport/WeekDetail.vue'
import WorkReportMonth from '../views/WorkReport/Month.vue'
import WorkReportMonthDetail from '../views/WorkReport/MonthDetail.vue'
import Preview from '@/components/knowledge/OnlinePreview.vue'

// const Home = () => import('@/views/Home.vue')
// const Click = () => import('@/views/Click/index.vue')
// // const HoClickme = () => import('@/views/Click/index.vue')
// const Index = () => import('@/views/Index.vue')
// const TaskList = () => import('@/views/TaskList.vue')
// const Meeting = () => import('@/views/Meeting.vue')
// const Circle = () => import('@/views/Circle.vue')
// const CircleIndex = () => import('@/views/Circle/index.vue')
// const TopicInfo = () => import('@/views/TopicInfo.vue')

// const MemberHome = () => import('@/views/Member/Home.vue')
// const MemberIndex = () => import('@/views/Member/Index.vue')
// const MemberAttention = () => import('@/views/Member/Attention.vue')
// const MemberFans = () => import('@/views/Member/Fans.vue')
// const MemberMessage = () => import('@/views/Member/Message.vue')
// const MemberSet = () => import('@/views/Member/Set.vue')
// const MemberLike = () => import('@/views/Member/Like.vue')
// const MemberInfo = () => import('@/views/Member/Info.vue')
// const TaskDetailPage = () => import('@/views/TaskDetailPage.vue')
// const TargetDetailPage = () => import('@/views/TargetDetailPage.vue')
// const Knowledge = () => import('@/views/Knowledge.vue')
// const SystemIndex = () => import('@/views/System/index.vue')
// const SystemAchitecture = () => import('@/views/System/architecture.vue')
// const SystemNotice = () => import('@/views/System/notice.vue')
// const SystemCirclePush = () => import('@/views/System/CirclePush.vue')
// const SystemManagement = () => import('@/views/System/Management.vue')
// const SystemKnowledge = () => import('@/views/System/Knowledge.vue')
// const AttachmentPreview = () => import('@/views/AttachmentPreview.vue')
// const More = () => import('@/views/More.vue')
// const WorkReportWeek = () => import('@/views/WorkReport/Week.vue')
// const WorkReportWeekDetail = () => import('@/views/WorkReport/WeekDetail.vue')
// const WorkReportMonth = () => import('@/views/WorkReport/Month.vue')
// const WorkReportMonthDetail = () => import('@/views/WorkReport/MonthDetail.vue')
// const Preview = () => import('@/components/knowledge/OnlinePreview.vue')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

// 路由列表
const routes = [
  {
    path: '/knowledge/file/:id/preview',
    name: 'Preview',
    component: Preview,
    meta: {
      title: '文档在线预览'
    }
  },
  {
    path: '/attachment/:attachmentType/:attachmentId',
    name: 'AttachmentPreview',
    component: AttachmentPreview,
    meta: {
      title: '附件在线预览'
    }
  },
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: { title: 'Cloud Center' },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { title: 'Cloud Center' }
      },
      {
        path: '/workspace/:clerkId?/:tab?',
        name: 'workspace',
        component: TaskList,
        meta: { title: '工作台-Cloud Center' }
      },
      {
        path: '/meeting/:id?',
        name: 'meeting',
        alias: '/meetingRoom/:id?',
        component: Meeting,
        meta: { title: '会议-Cloud Center' }
      },
      {
        path: '/knowledge/:type?/:id?',
        name: 'knowledge',
        component: Knowledge,
        meta: { title: '知识库-Cloud Center' }
      },
      {
        path: '/click/index/:id?',
        name: 'click',
        component: Click,
        meta: { title: '更多应用-Cloud Center' }
      },
      {
        path: '/circle',
        name: 'circle',
        component: Circle,
        redirect: '/circle/index',
        children: [
          {
            path: '/circle/index',
            name: 'circleIndex',
            component: CircleIndex,
            meta: { title: '圈子-Cloud Center' }
          },
          {
            path: '/circle/message',
            name: 'circleMessage',
            component: MemberMessage,
            meta: { title: '我的消息-Cloud Center' }
          },
          {
            path: '/circle/:clerkId/home',
            name: 'circleHome',
            component: MemberHome,
            meta: { title: '个人首页-Cloud Center' }
          }
        ]
      },
      {
        path: '/topicInfo/:id',
        name: 'topicInfo',
        component: TopicInfo,
        meta: { title: '动态详情-Cloud Center' }
      },
      {
        path: '/member',
        name: 'member',
        component: MemberIndex,
        redirect: '/member/home',
        children: [
          {
            path: '/member/:clerkId/home',
            name: 'memberHome',
            component: MemberHome,
            meta: { title: '个人首页-Cloud Center' }
          },
          {
            path: '/member/attention',
            name: 'memberAttention',
            component: MemberAttention,
            meta: { title: '我的关注-Cloud Center' }
          },
          {
            path: '/member/fans',
            name: 'memberFans',
            component: MemberFans,
            meta: { title: '我的粉丝-Cloud Center' }
          },
          {
            path: '/member/message',
            name: 'memberMessage',
            component: MemberMessage,
            meta: { title: '我的消息-Cloud Center' }
          },
          {
            path: '/member/like',
            name: 'memberLike',
            component: MemberLike,
            meta: { title: '我的喜欢-Cloud Center' }
          },
          {
            path: '/member/set',
            name: 'memberSet',
            component: MemberSet,
            meta: { title: '圈子设置-Cloud Center' }
          },
          {
            path: '/member/info/:id',
            name: 'memberInfo',
            component: MemberInfo,
            meta: { title: '用户详情-Cloud Center' }
          }
        ]
      },
      {
        path: '/taskDetail/:id',
        name: 'taskDetail',
        component: TaskDetailPage,
        meta: { title: '任务详情-Cloud Center' }
      },
      {
        path: '/targetDetail/:id',
        name: 'targetDetail',
        component: TargetDetailPage,
        meta: { title: '目标详情-Cloud Center' }
      },
      {
        path: '/system/index',
        name: 'systemIndex',
        component: SystemIndex,
        meta: { title: '系统管理-Cloud Center' },
        redirect: '/system/achitecture',
        children: [
          {
            path: '/system/achitecture',
            name: 'systemAchitecture',
            component: SystemAchitecture,
            meta: { title: '组织架构-Cloud Center' }
          },
          {
            path: '/system/notice',
            name: 'systemNotice',
            component: SystemNotice,
            meta: { title: '组织架构-Cloud Center' }
          },
          {
            path: '/system/circlePush',
            name: 'systemCirclePush',
            component: SystemCirclePush,
            meta: { title: '圈子推送-Cloud Center' }
          },
          {
            path: '/system/management',
            name: 'systemManagement',
            component: SystemManagement,
            meta: { title: '管理员-Cloud Center' }
          },
          {
            path: '/system/knowledge',
            name: 'systemKnowledge',
            component: SystemKnowledge,
            meta: { title: '团队知识库-Cloud Center' }
          }
        ]
      },
      {
        path: '/more',
        name: 'more',
        component: More,
        replace: '/workReport/week',
        children: [
          {
            path: '/workReport/week/:tab?/:clerkReportId?',
            name: 'workReportWeek',
            component: WorkReportWeek,
            meta: { title: '周报-Cloud Center' }
          },
          {
            path: '/workReport/month/:tab?/:clerkReportId?',
            name: 'workReportMonth',
            component: WorkReportMonth,
            meta: { title: '月报-Cloud Center' }
          }
        ]
      },
      {
        path: '/workReport/WDetail/:clerkReportId',
        name: 'workReportWeekDetail',
        component: WorkReportWeekDetail,
        meta: { title: '周报-Cloud Center' }
      },
      {
        path: '/workReport/MDetail/:clerkReportId',
        name: 'workReportMonthDetail',
        component: WorkReportMonthDetail,
        meta: { title: '月报-Cloud Center' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
  base: '/pc/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

// 路由守卫
router.beforeEach((to, from, next) => {
  handleKeepAlive(to)
  // next()
  const redirectUrl = to.query.redirect_url
  store.commit('setToFullPath', to.fullPath)
  // 如果有拿到token，保存到localStorage
  const token = to.query.token
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (token) {
    window.localStorage.setItem('token', token)
    store.commit('setToken', token)
    // 去掉token参数
    const newQuery = JSON.parse(JSON.stringify(to.query)) // 先拷贝一个一模一样的对象

    delete newQuery.token // 再删除token
    router.replace({
      name: to.name,
      query: newQuery
    }) // 再把新的替换了
  } else {
    console.log('router')
    // 否则从store那里拿token
    const token = window.localStorage.getItem('token')
    if (token) {
      store.commit('setToken', token)
    }
  }
  // 获取当前登录的职员信息
  if (store.state.myClerkId === -1) {
    store.dispatch('getClerkInfo').then(() => {
      if (store.state.myClerkId !== -1) {
        if (redirectUrl) {
          next(redirectUrl)
        } else {
          next()
        }
      }
    })
  } else {
    if (redirectUrl) {
      next(redirectUrl)
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  console.log(to)
})

async function handleKeepAlive(to) {
  if (to.matched && to.matched.length > 2) {
    for (let index = 0; index < to.matched.length; index++) {
      const element = to.matched[index]
      if (element.components.default.name === 'layout') {
        to.matched.splice(index, 1)
        await handleKeepAlive(to)
      }

      if (typeof element.components.default === 'function') {
        await element.components.default()
        await handleKeepAlive(to)
      }
    }
  }
}

export default router
